import React, { CSSProperties, useCallback, useMemo, useState } from 'react'
import { IconButton } from 'components/IconButton'
import { Icon } from 'components/Icon'
import { Menu } from 'components/Menu'
import * as L from 'layouts'
import { Chip, Typo } from 'components'
import clsx from 'clsx'
import styled from './AppNotifications.module.css'
import { Badge } from 'components/Badge'
import { Notification_Type_Enum, useMarkNotificationAsReadMutation, useNotificationsSubscription } from 'gql/generated'
import { useTranslation } from 'react-i18next'
import { DateFns } from 'utils'
import { useEzAuth } from '@lib/ezauth'
import { composeVehicleId } from '../ListConfig/Vehicles/List'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'

type NotificationFamily = 'assetLink' | 'ticket'
export const AppNotifications: React.FC = () => {
  const [state] = useEzAuth()
  const { data } = useNotificationsSubscription({ variables: { id: state.user.id } })
  const markAsRead = useMarkAsRead()
  const { t } = useTranslation()

  const [showNotifications, setShowNotifications] = useState<boolean>(false)

  const { openDetailView } = useDetailView()

  const [notifications, numberOfUnread] = useMemo(() => {
    const notifs = data?.notifications?.map((notification) => {
      let content: React.ReactNode
      let onClick: () => void
      let family: NotificationFamily
      switch (notification.type) {
        case Notification_Type_Enum.AssetWatchdogLinkUpdated:
          content = (
            <span>
              {notification.content} <strong>{notification.asset_watchdog_link?.name}</strong>
            </span>
          )
          onClick = () =>
            openDetailView(DetailViewEnum.AssetLinkDetail, { assetLinkId: notification.asset_watchdog_link.id })
          family = 'assetLink'
          break
        case Notification_Type_Enum.TicketAssigneeUpdated:
        case Notification_Type_Enum.TicketInserted:
        case Notification_Type_Enum.TicketDepartmentUpdated:
        case Notification_Type_Enum.TicketLocationUpdated:
          const vehicle = notification.ticket.vehicle
          content = (
            <span>
              {notification.content} <strong>{notification.ticket.title}</strong>
              {(vehicle.vin || vehicle.job_number) && (
                <>
                  <br />
                  Für das Fahrzeug{' '}
                  <strong>{`${
                    vehicle.vin ? `FIN: ${vehicle.vin}` : vehicle.job_number ? `Bestell#: ${vehicle.job_number}` : ''
                  }`}</strong>
                </>
              )}
            </span>
          )
          const composedId = composeVehicleId(vehicle)
          onClick = () => openDetailView(DetailViewEnum.VehicleDetail, { id: composedId })
          family = 'ticket'
          break
        default:
          content = null
          break
      }
      const created_at = DateFns.formatDistance(new Date(notification.created_at), new Date())
      return { ...notification, content, created_at, onClick, family }
    })
    const numberOfUnread = notifs?.filter((notif) => notif.status === 'UNREAD')?.length ?? 0
    return [notifs, numberOfUnread]
  }, [openDetailView, data?.notifications])

  return (
    <>
      <Badge max={10} content={numberOfUnread} color="danger">
        <Menu>
          <Menu.IconButton
            aria-label="Show unread notifications"
            onClick={() => setShowNotifications((prev) => !prev)}
            color={showNotifications ? 'primary' : undefined}
            className="p-0"
          >
            <Icon name="notifications" />
          </Menu.IconButton>
          <Menu.ItemList onClose={() => setShowNotifications(false)}>
            {notifications?.map((notification) => (
              <Notification
                key={notification.id}
                onClick={async () => {
                  await markAsRead(notification.id, notification.family)
                  setShowNotifications(false)
                  notification.onClick()
                }}
                onRead={async (evt) => {
                  evt.stopPropagation()
                  await markAsRead(notification.id, notification.family)
                }}
                title={notification.title}
                type={t(notification.type)}
                createdAt={`Vor ${notification.created_at}`}
                className={clsx(
                  notification.status === 'UNREAD' && 'bg-background-default',
                  notification.status === 'READ' && 'bg-background-paper'
                )}
              >
                {notification.content}
              </Notification>
            ))}
            {!notifications?.length && (
              <Menu.Item onClick={() => setShowNotifications(false)} style={{ width: 500 }}>
                <Typo variant="body1">No notifications</Typo>
              </Menu.Item>
            )}
          </Menu.ItemList>
        </Menu>
      </Badge>
    </>
  )
}

interface NotificationProps {
  onClick: () => void
  onRead: React.MouseEventHandler<HTMLButtonElement>
  title: string
  type: string
  createdAt: string
  children: React.ReactNode
  className?: string
  style?: CSSProperties
}

const Notification = (props: NotificationProps) => {
  return (
    <Menu.Item
      style={{ ...props.style, maxHeight: 250, width: 500 }}
      className={clsx(styled.notification, 'justify-between', props.className)}
      onClick={props.onClick}
    >
      <L.Vertical className="whitespace-normal" spacing={1}>
        <Typo variant="body1">
          <strong>{props.title}</strong>
        </Typo>
        <Typo variant="body2">{props.children}</Typo>
        <L.Horizontal>
          <Chip key="type" color="primary">
            {props.type}
          </Chip>
          <Typo variant="caption">{props.createdAt}</Typo>
        </L.Horizontal>
      </L.Vertical>
      <L.Horizontal spacing={0.5} className="invisible">
        <IconButton tooltip="View details" aria-label="Show details" size="large" onClick={props.onClick}>
          <Icon name="view"></Icon>
        </IconButton>
        <IconButton tooltip="Mark as read" aria-label="Mark as read" size="large" onClick={props.onRead}>
          <Icon name="check"></Icon>
        </IconButton>
      </L.Horizontal>
    </Menu.Item>
  )
}

const useMarkAsRead = () => {
  const [mark] = useMarkNotificationAsReadMutation()

  return useCallback(
    async (id: string, family: NotificationFamily) => {
      return await mark({
        variables: {
          id,
          assetLinkFamily: family === 'assetLink',
          ticketFamily: family === 'ticket',
        },
      })
    },
    [mark]
  )
}
