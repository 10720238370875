import React from 'react'
import LoginForm from './LoginForm'
import { useNewPasswordRequired } from 'services/auth/auth-utils'
import { NewPwForm } from './NewPwForm'

export const LoginPage: React.FC = () => {
  const [newPwRequired, newPwResponse] = useNewPasswordRequired()

  return <LoginShell>{newPwRequired ? <NewPwForm submit={newPwResponse.resolve} /> : <LoginForm />}</LoginShell>
}

export const LoginShell: React.FC = (props) => {
  return (
    <div className="min-h-screen bg-primary-dark flex">
      <div
        className="w-72 min-h-full bg-background-default bg-opacity-70 p-10 md:ml-64 my-0 mx-auto"
        style={{ paddingTop: '26vh' }}
      >
        {props.children}
      </div>
    </div>
  )
}

export default LoginPage
