import React from 'react'

import ClickAwayListener from '@mui/material/ClickAwayListener'

export interface ClickOutsideProps<T extends Element = Element> {
  onClickOutside: React.MouseEventHandler<T>
}

export const ClickOutsideListener: React.FC<ClickOutsideProps<any>> = (props) => {
  return <ClickAwayListener onClickAway={props.onClickOutside as any}>{props.children as any}</ClickAwayListener>
}
