import React, { createContext, PropsWithChildren, ReactElement, useContext, useState } from 'react'
import { Checkbox, Icon, Menu, TextField, Typo } from '../../components'
import * as L from 'layouts'
import clsx from 'clsx'
import styles from './ListManipulation.module.css'
import { DatePicker } from '../../components/DatePicker'
import { RadioGroup as RadioGroupCmp } from '../../components'
import { EntityType, General_Bool_Exp } from './useFilterOperations'

export const defaultValue = {
  field: undefined,
  setField: () => {},
  parentExpression: undefined,
  setParentExpression: () => {},
  highlightIndex: undefined,
  setHighlightIndex: () => {},
  leadingExpression: undefined,
  setLeadingExpression: () => {},
}

export type ListManipulationCtxProps<T extends EntityType> = {
  field: string
  setField: React.Dispatch<React.SetStateAction<string>>
  parentExpression: General_Bool_Exp<T>
  setParentExpression: React.Dispatch<React.SetStateAction<General_Bool_Exp<T>>>
  highlightIndex: number
  setHighlightIndex: React.Dispatch<React.SetStateAction<number>>
  leadingExpression: General_Bool_Exp<T>
  setLeadingExpression: React.Dispatch<React.SetStateAction<General_Bool_Exp<T>>>
}

export const ListManipulationCtx = createContext<ListManipulationCtxProps<any>>(defaultValue)

export const ListManipulation = <T extends EntityType>(props: PropsWithChildren<{}>) => {
  const [field, setField] = useState<string>()
  const [parentExpression, setParentExpression] = useState<General_Bool_Exp<T>>()
  const [highlightIndex, setHighlightIndex] = useState<number>()
  const [leadingExpression, setLeadingExpression] = useState<General_Bool_Exp<T>>()

  return (
    <ListManipulationCtx.Provider
      value={{
        parentExpression,
        setParentExpression,
        field,
        setField,
        highlightIndex,
        setHighlightIndex,
        leadingExpression,
        setLeadingExpression,
      }}
    >
      {props.children}
    </ListManipulationCtx.Provider>
  )
}

export const useListManipilationContext = <T extends EntityType>() => {
  const ctx = useContext<ListManipulationCtxProps<T>>(
    (ListManipulationCtx as unknown) as React.Context<ListManipulationCtxProps<T>>
  )
  if (!ctx) {
    throw new Error('useListManipulationContext must be used under ListManipulation')
  }
  return ctx
}

export interface ListManipulationUnitGroupProps {
  onDelete: () => void
  variant?: 'filled' | 'outlined'
  color?: 'default' | 'danger' | 'success' | 'warning' | 'grey-dark' | 'grey' | 'grey-light'
}

const ListManipulationUnitGroup: React.FC<ListManipulationUnitGroupProps> = (props) => {
  return (
    <div
      className={clsx(
        'cursor-pointer group overflow-hidden gap-1 h-6 relative inline-flex items-center justify-center whitespace-nowrap outline-none align-middle',
        styles.group,
        { [styles.filled_default]: props.variant === 'filled' && props.color === 'default' },
        { [styles.filled_danger]: props.variant === 'filled' && props.color === 'danger' },
        { [styles.filled_success]: props.variant === 'filled' && props.color === 'success' },
        { [styles.filled_warning]: props.variant === 'filled' && props.color === 'warning' },
        { [styles.filled_grey]: props.variant === 'filled' && props.color === 'grey' },
        { [styles.filled_grey_light]: props.variant === 'filled' && props.color === 'grey-light' },
        { [styles.filled_grey_dark]: props.variant === 'filled' && props.color === 'grey-dark' }
      )}
    >
      {props.children}
      <div
        className={clsx(
          'group-hover:border-action-hover border h-6 border-divider transition-colors duration-200 rounded-r-2xl align-middle items-center justify-center flex'
        )}
        onClick={props.onDelete}
      >
        <Icon
          name={'close'}
          className={clsx('text-current text-base group-hover:text-text-primary transition-colors duration-200 mx-1')}
        />
      </div>
    </div>
  )
}
ListManipulation.Group = ListManipulationUnitGroup

interface ListManipulationUnitProps {
  label: string | ReactElement
  onClick?: () => void
}

const ListManipulationUnit: React.FC<ListManipulationUnitProps> = (props) => {
  return (
    <Menu>
      <Menu.UnstyledButton
        className={clsx(
          'h-6 text-xs px-1 text-grey-700 group-hover:text-text-primary border border-divider transition-colors duration-200 align-middle items-center justify-center flex group-hover:border-action-hover'
        )}
        onClick={props.onClick}
      >
        {props.label}
      </Menu.UnstyledButton>
      {props.children}
    </Menu>
  )
}
ListManipulation.GroupUnit = ListManipulationUnit

const ListManipulationUnitOptions: React.FC<{ label?: string }> = (props) => {
  const { setField, setParentExpression } = useListManipilationContext()
  return (
    <Menu.ItemList
      onClose={() => {
        setField(undefined)
        setParentExpression(undefined)
      }}
    >
      {props.label && (
        <Typo variant={'h5'} component={'p'} className={'p-2'}>
          {props.label}
        </Typo>
      )}

      <hr className={'text-divider'} />
      {props.children}
    </Menu.ItemList>
  )
}
ListManipulation.UnitOptions = ListManipulationUnitOptions

interface LMUDateInputOptionProps {
  label: string
  value: Date
  onChange: (date: Date) => void
}

const LMUDateInputOption = (props: LMUDateInputOptionProps) => {
  return (
    <div className={'p-4'}>
      <DatePicker {...props} />
    </div>
  )
}
ListManipulation.DateInputOption = LMUDateInputOption

interface LMUInputOptionProps {
  value: string
  placeholder: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

const LMUInputOption = (props: LMUInputOptionProps) => {
  return <TextField className={'p-4'} value={props.value} onChange={props.onChange} placeholder={props.placeholder} />
}
ListManipulation.InputOption = LMUInputOption

interface LMUCheckableOptionProps {
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const LMUCheckableOption: React.FC<LMUCheckableOptionProps> = (props) => {
  return (
    <L.Horizontal>
      <Checkbox checked={props.checked} onChange={props.onChange} />
      {props.children}
    </L.Horizontal>
  )
}
ListManipulation.CheckableOption = LMUCheckableOption

interface LMUItemOptionProps {
  onClick: () => void
  disabled?: boolean
  checked?: boolean
  className?: string
}

const LMUItemOption: React.FC<LMUItemOptionProps> = (props) => {
  if (!props.onClick) return null
  return (
    <Menu.Item
      className={clsx(props.checked && 'bg-action-selected', props.className)}
      suppressCloseOnClick
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Typo variant={'body1'} className={'pr-4'}>
        {props.children}
      </Typo>
    </Menu.Item>
  )
}
ListManipulation.ItemOption = LMUItemOption

interface LMURadioGroupOptionProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  options: { value: string; label: string }[]
  value: string
}

const LMURadioGroupOption = (props: LMURadioGroupOptionProps) => {
  return (
    <RadioGroupCmp
      className={'px-4'}
      name={'radio-group'}
      aria-label={'radio-group-filter'}
      row
      value={props.value}
      onChange={props.onChange}
    >
      {props.options.map((o, idx) => (
        <RadioGroupCmp.Option key={`radio-group-option-${idx}`} value={o.value} label={o.label} />
      ))}
    </RadioGroupCmp>
  )
}
ListManipulation.RadioGroupOption = LMURadioGroupOption
