import React, { CSSProperties } from 'react'
import { Ticket_Template_Location_Ref, useTicketTemplateLocationSelectDataQuery } from '../../gql'
import { Select } from '../../components'
import { useTranslation } from 'react-i18next'

interface AssignReferenceLocationSelectProps {
  value: string
  onSelect: (location: Ticket_Template_Location_Ref) => void
  className?: string
  style?: CSSProperties
}

export const AssignReferenceLocationSelect = (props: AssignReferenceLocationSelectProps) => {
  const { t } = useTranslation()
  const query = useTicketTemplateLocationSelectDataQuery({ fetchPolicy: 'network-only' })

  const locationRef = query?.data?.ticket_template_location_ref ?? []
  const options = locationRef.map((ref) => ({ id: ref.name, name: ref.name }))
  const handleChange = async (evt) => {
    let id = evt.target.value
    props.onSelect(locationRef.find((ref) => ref.name === id) as Ticket_Template_Location_Ref)
  }
  options.sort((a, b) => (a.name < b.name ? -1 : 1))
  const loading = query.loading

  return (
    <Select
      loading={loading}
      value={props.value}
      onChange={handleChange}
      className={props.className}
      style={props.style}
    >
      {options.map((o) => (
        <Select.Option key={o.id} value={o.id}>
          {t(`TicketTemplateLocation.${o.name}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
