import React, { CSSProperties } from 'react'
import MuiBox, { BoxProps as MuiBoxProps } from '@mui/material/Box'

export interface CompProps {
  component?: MuiBoxProps['component']
  color?: MuiBoxProps['color']
  className?: string
  style?: CSSProperties
}

export const Comp: React.FC<CompProps> = (props) => <MuiBox {...props} />
