import React from 'react'
import { NetworkSimulatorLink } from './NetworkSimulatorLink'
import { LinkRegistry } from '../ApolloLinkRegistry'
import NetworkSimulatorHud from './NetworkSimulatorHud'

interface NetworkSimulatorConfig {
  showHUD: boolean
}

LinkRegistry.add('networksimulator', {
  createLink: () => new NetworkSimulatorLink(),
  createComponent: (config) => {
    if ((config as NetworkSimulatorConfig).showHUD) {
      return <NetworkSimulatorHud />
    }
    return null
  },
  order: 20,
})

export { NetworkSimulatorLink, NetworkSimulatorHud }
