import React, { useMemo, useState } from 'react'
import { Ticket_Priority_Enum, Ticket_State_Enum, useTicketDetailsSubscription, useUpdateTicketMutation } from 'gql'
import { DetailWindow } from '../../../../components/DetailWindow'
import * as L from 'layouts'
import { DataBlock } from '../../../../components/DataBlock'
import { DataValue } from 'components/DataValue'
import { FormRow } from 'components/FormRow'
import { useFormFields } from '@w2solutions/react-hooks'
import { FormActions, TextField, Typo } from '../../../../components'
import { PrioritySelect } from '../../../PrioritySelect'
import { UserSelect } from '../../../UserSelect'
import { TicketStateSelect } from '../../../TicketStateSelect'
import { DatePicker } from '../../../../components/DatePicker'
import { useFormatter } from '../../../../hooks'
import { DepartmentSelect } from '../../../DepartmentSelect'
import { LocationSelect } from '../../../LocationSelect'
import { TicketNotes } from './notes'

interface TicketDetailProps {
  id: string
  onClose: () => void
}

export const TicketDetail = React.forwardRef<HTMLDivElement, TicketDetailProps>((props, ref) => {
  const ticketSubscription = useTicketDetailsSubscription({ variables: { id: props.id } })
  const [update] = useUpdateTicketMutation()
  const ticket = useMemo(() => ticketSubscription.data?.ticket, [ticketSubscription.data?.ticket])
  const { formatDateTimeString } = useFormatter()
  const [editMode, setEditMode] = useState(false)

  const { bindings, values, setValue } = useFormFields(
    {
      title: ticket?.title ?? '',
      description: ticket?.description ?? '',
      assigneeId: ticket?.assignee_id,
      departmentId: ticket?.department_id,
      locationId: ticket?.location_id,
      state: ticket?.state ?? Ticket_State_Enum.Todo,
      priority: ticket?.priority ?? ('' as Ticket_Priority_Enum),
      due_to: ticket?.due_to ?? (null as string),
    },
    { resetOnInitialValueChange: true }
  )

  const handleSave = async () => {
    await update({
      variables: {
        id: props.id,
        _set: {
          assignee_id: values.assigneeId,
          department_id: values.departmentId,
          description: values.description,
          due_to: values.due_to,
          location_id: values.locationId,
          priority: values.priority,
          state: values.state,
          title: values.title,
        },
      },
    })
    props.onClose()
  }

  return (
    <DetailWindow ref={ref} width={976} onClose={props.onClose} title={'Aufgabe'}>
      <L.Vertical spacing={4} className={'w-full p-4 h-full'}>
        <L.Horizontal spacing={4} style={{ alignItems: 'flex-start' }}>
          <L.Vertical spacing={2} className={'flex-3'}>
            <DataBlock title={'Details'}>
              {!editMode ? (
                <DataValue label={'Titel'} onEdit={() => setEditMode(true)} className={'mb-4'}>
                  <Typo variant={'h3'} component={'p'}>
                    {ticket?.title}
                  </Typo>
                </DataValue>
              ) : (
                <FormRow label={'Titel'}>
                  <TextField {...bindings.title} multiline />
                </FormRow>
              )}
              {!editMode ? (
                <DataValue label={'Beschreibung'} onEdit={() => setEditMode(true)}>
                  {ticket?.description}
                </DataValue>
              ) : (
                <FormRow label={'Beschreibung'}>
                  <TextField {...bindings.description} multiline />
                </FormRow>
              )}
              {/*  Vehicle details container */}
            </DataBlock>
            <DataBlock className={'relative pt-8 w-2/3 mx-auto'} title="Notizen">
              <TicketNotes ticketId={props.id} />
            </DataBlock>
          </L.Vertical>
          <L.Vertical spacing={2} className={'flex-1'}>
            <DataBlock title={'Metadaten'}>
              <DataValue label={'Erstellt am'}>{formatDateTimeString(ticket?.created_at)}</DataValue>
              <DataValue label={'Erstellt von'}>
                {ticket?.created_by?.full_name || ticket?.created_by?.email || '-'}
              </DataValue>
              <DataValue label={'Zuletzt aktualisiert am'}>{formatDateTimeString(ticket?.updated_at)}</DataValue>
              <FormRow label={'Status'}>
                <TicketStateSelect value={values.state} onSelect={(state) => setValue('state', state)} />
              </FormRow>
              <FormRow label={'Priorität'}>
                <PrioritySelect value={values.priority} onChange={(priority) => setValue('priority', priority)} />
              </FormRow>
              <FormRow label={'Abteilungen'}>
                <DepartmentSelect
                  value={values.departmentId}
                  onSelect={(department) => setValue('departmentId', department.id)}
                />
              </FormRow>
              <FormRow label={'Zuweisung'}>
                <UserSelect value={values.assigneeId} onSelect={(user) => setValue('assigneeId', user.id)} />
              </FormRow>
              <FormRow label={'Standort'}>
                <LocationSelect hideLabel value={values.locationId} onSelect={(id) => setValue('locationId', id)} />
              </FormRow>
              <FormRow label={'Fertigstellung'}>
                <DatePicker value={values.due_to} onChange={(date) => setValue('due_to', date.toISOString())} />
              </FormRow>
            </DataBlock>
          </L.Vertical>
        </L.Horizontal>
      </L.Vertical>
      <FormActions
        onCancel={() => {
          setEditMode(false)
          props.onClose()
        }}
        onSave={handleSave}
      />
    </DetailWindow>
  )
})
