import React, { CSSProperties } from 'react'
import MuiCircularProgress, {
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material/CircularProgress'
import clsx from 'clsx'

interface SpinnerProps {
  color?: MuiCircularProgressProps['color']
  size?: MuiCircularProgressProps['size']
  centered?: boolean
  className?: string
  style?: CSSProperties
}

export const Spinner: React.FC<SpinnerProps> = (props) => {
  const { centered, className, style, ...rest } = props
  return (
    <div
      className={clsx(centered && 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2', className)}
      style={style}
    >
      <MuiCircularProgress {...rest} />
    </div>
  )
}
