import { Chip, ChipProps } from 'components'
import { After_Sales_Status_Enum } from 'gql'
import React from 'react'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

interface AfterSalesStatusChipProps {
  status: After_Sales_Status_Enum
  className?: string
  style?: CSSProperties
}

export const AfterSalesStatusChip = (props: AfterSalesStatusChipProps) => {
  const { t } = useTranslation()
  const status = props.status
  let color: ChipProps['color'] = 'default'
  switch (status) {
    case After_Sales_Status_Enum.CustomerNotReached:
      color = 'danger'
      break
    case After_Sales_Status_Enum.CustomerWillCallBack:
      color = 'warning'
      break
    case After_Sales_Status_Enum.AppointmentBooked:
    case After_Sales_Status_Enum.Done:
      color = 'success'
      break
    case After_Sales_Status_Enum.Open:
      color = 'primary'
      break
  }
  return (
    <Chip color={color} style={props.style} className={props.className}>
      {t(`AfterSalesStatus.${status}`)}
    </Chip>
  );
}
