import React, { ChangeEvent, useState } from 'react'

import {
  LocationGroup,
  useBrandsQuery,
  useAdminLocationDetailQuery,
  useAddLocationGroupMutation,
  LocationBrandReference,
  useUpdateLocationGroupMutation,
  useRemoveLocationGroupMutation,
  useSetLocationBrandRefsMutation,
  useUpdateLocationShortNameMutation,
} from 'gql'
import {
  Select,
  Form,
  Typo,
  TextField,
  Chip,
  Icon,
  IconButton,
  LoadingOverlay,
  MultiSelect,
  Checkbox,
  Tabs,
  Card,
} from 'components'
import * as L from 'layouts'
import { useFormFields } from '@w2solutions/react-hooks'
import { LocationDmsReferences } from './LocationDmsReferences'
import { DataValue } from 'components/DataValue'

interface LocationDetailsProps {
  locationId: string
}

export const AdminLocationDetail: React.FC<LocationDetailsProps> = (props) => {
  const query = useAdminLocationDetailQuery({ variables: { id: props.locationId }, fetchPolicy: 'no-cache' })

  const groups = query.data?.location?.locationGroups ?? []
  const brandRefs = query.data?.location?.brandReferences ?? []

  const [showAddGroup, setShowAddGroup] = useState(false)
  const [addGroup] = useAddLocationGroupMutation()
  const handleAdd = async (values: LocationGroupFormValues) => {
    try {
      await addGroup({
        variables: {
          object: {
            location_id: query.data?.location.id,
            name: values.name,
            location_group_brands: {
              data: values.brandIds.map((id) => ({ brand_id: id })),
            },
          },
        },
      })
      setShowAddGroup(false)
    } catch {}
  }

  return (
    <Tabs>
      <Tabs.TabList>
        <Tabs.Tab label="Details" />
        <Tabs.Tab label="Marken" />
        <Tabs.Tab label="DMS" />
      </Tabs.TabList>
      <Card className="p-4 relative">
        <Tabs.TabPanes>
          <Tabs.TabPane>
            <div>
              <L.Vertical spacing={6}>
                <Form>
                  <L.Vertical>
                    <Typo variant="h3">Standort</Typo>
                    <DataValue className="w-full" label="Name" children={query.data?.location?.name ?? ''} />
                    <LocationShortName
                      locationId={props.locationId}
                      short_name={query.data?.location.short_name ?? ''}
                    />
                  </L.Vertical>
                </Form>

                <L.Vertical>
                  <Typo variant="h3">Standort-Gruppen</Typo>
                  {groups.map((group) => (
                    <LocationGroupListItem key={group.id} group={group} locationId={props.locationId} />
                  ))}
                  {showAddGroup ? (
                    <LocationGroupForm
                      group={{ name: '', brandIds: [] }}
                      onSave={handleAdd}
                      onCancel={() => setShowAddGroup(false)}
                    />
                  ) : (
                    <L.Horizontal>
                      <div style={{ flexGrow: 1 }} />
                      <IconButton
                        aria-label="add group"
                        color="primary"
                        variant="contained"
                        size="medium"
                        onClick={() => setShowAddGroup(true)}
                      >
                        <Icon name="add" />
                      </IconButton>
                    </L.Horizontal>
                  )}
                </L.Vertical>
              </L.Vertical>
              {query.loading && <LoadingOverlay />}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane>
            <L.Vertical>
              <Brands brandReferences={brandRefs as any} locationId={props.locationId} />
            </L.Vertical>
          </Tabs.TabPane>
          <Tabs.TabPane>
            <L.Vertical>
              <LocationDmsReferences
                locationId={props.locationId}
                dmsReferences={query.data?.location?.dmsReferences ?? []}
                customerDmsReferences={query.data?.location?.customerDmsReferences ?? []}
                supplierDmsReferences={query.data?.location?.supplierDmsReferences ?? []}
              />
            </L.Vertical>
          </Tabs.TabPane>
        </Tabs.TabPanes>
      </Card>
    </Tabs>
  )
}

interface LocationShortNameProps {
  locationId: string
  short_name: string
}

const LocationShortName = (props: LocationShortNameProps) => {
  const { short_name, locationId } = props
  const [editMode, setEditMode] = useState(false)

  const { bindings, values } = useFormFields({ short_name: short_name }, { resetOnInitialValueChange: true })

  const [update] = useUpdateLocationShortNameMutation()

  const handleSave = async () => {
    try {
      await update({ variables: { pk_columns: { id: locationId }, _set: { short_name: values.short_name } } })
      setEditMode(false)
    } catch (error) {}
  }

  if (editMode) {
    return (
      <L.Horizontal>
        <TextField label="Abkürzung" {...bindings.short_name} />
        <IconButton aria-label="save" type="submit" color="primary" onClick={handleSave} size="large">
          <Icon name="check" />
        </IconButton>
        <IconButton aria-label="cancel" onClick={() => setEditMode(false)} size="large">
          <Icon name="close" />
        </IconButton>
      </L.Horizontal>
    )
  }
  return (
    <DataValue className="w-full" onEdit={() => setEditMode(true)} label="Abkürzung" children={values.short_name} />
  )
}

interface BrandsProps {
  brandReferences: Pick<LocationBrandReference, 'after_sales' | 'brand' | 'isPrimaryDealer' | 'sales'>[]
  locationId: string
}

const Brands: React.FC<BrandsProps> = (props) => {
  const [editMode, setEditMode] = useState(false)
  const [update] = useSetLocationBrandRefsMutation()
  const { setValue, values, reset } = useFormFields(
    {
      brandRefs: props.brandReferences.map((br) => ({
        brandId: br.brand.id,
        sales: br.sales,
        afterSales: br.after_sales,
        isPrimaryDealer: br.isPrimaryDealer,
      })),
    },
    { resetOnInitialValueChange: true }
  )
  const handleAddBrandRef = () => {
    setValue('brandRefs', [
      ...values.brandRefs,
      {
        brandId: '',
        sales: false,
        afterSales: false,
        isPrimaryDealer: false,
      },
    ])
  }
  const handleSave = async () => {
    // TODO: impl
    // const changesOccured = !equals(new Set(brands.map((brand) => brand.id)), new Set(values.brands))
    const changesOccured = true
    if (changesOccured) {
      try {
        await update({
          variables: {
            locationId: props.locationId,
            brandIds: values.brandRefs.map((bref) => bref.brandId),
            objects: values.brandRefs.map((bref) => ({
              location_id: props.locationId,
              brand_id: bref.brandId,
              after_sales: bref.afterSales,
              sales: bref.sales,
              isPrimaryDealer: bref.isPrimaryDealer,
            })),
          },
        })
        setEditMode(false)
      } catch {}
    } else {
      setEditMode(false)
    }
  }

  const handleCancel = () => {
    setEditMode(false)
    reset()
  }

  return (
    <>
      <L.Horizontal>
        <Typo variant="h3">{'Marken'}</Typo>
        <div>
          {editMode ? (
            <IconButton aria-label="add" onClick={handleAddBrandRef} size={'small'}>
              <Icon name="add" />
            </IconButton>
          ) : (
            <IconButton aria-label="edit" onClick={() => setEditMode(true)} size={'small'}>
              <Icon name="edit" />
            </IconButton>
          )}
        </div>
      </L.Horizontal>
      <Form>
        <L.Vertical spacing={1} className="py-2 px-0">
          <L.Grid cols={5} style={{ gridTemplateColumns: '200px 100px 100px 100px 50px' }}>
            <Typo variant={'subtitle2'}>Marke</Typo>
            <Typo variant={'subtitle2'}>After Sales</Typo>
            <Typo variant={'subtitle2'}>Sales</Typo>
            <Typo variant={'subtitle2'}>Haupthändler</Typo>
            <div />
            {values.brandRefs.map((br, idx) => (
              <BrandRef
                key={br.brandId}
                brandRef={br}
                onChange={(newValue) => {
                  const brandRefs = [...values.brandRefs]
                  brandRefs[idx] = newValue
                  setValue('brandRefs', brandRefs)
                }}
                onDelete={() => {
                  const brandRefs = [...values.brandRefs]
                  brandRefs.splice(idx, 1)
                  setValue('brandRefs', brandRefs)
                }}
                readOnly={!editMode}
              />
            ))}
          </L.Grid>
          {editMode && (
            <L.Horizontal spacing={1}>
              <IconButton aria-label="save" type="submit" color="primary" onClick={handleSave} size="large">
                <Icon name="check" />
              </IconButton>
              <IconButton aria-label="cancel" onClick={handleCancel} size="large">
                <Icon name="close" />
              </IconButton>
            </L.Horizontal>
          )}
        </L.Vertical>
      </Form>
    </>
  )
}

interface BrandFormFields {
  brandId: string
  sales: boolean
  afterSales: boolean
  isPrimaryDealer: boolean
}

interface BrandRefProps {
  brandRef: BrandFormFields
  onChange: (data: BrandFormFields) => void
  onDelete: () => void
  readOnly: boolean
}

const BrandRef = (props: BrandRefProps) => {
  const query = useBrandsQuery()
  const brands = query.data?.brands ?? []
  if (props.readOnly) {
    return (
      <>
        <div>{brands.find((brand) => brand.id === props.brandRef.brandId)?.name}</div>
        <div>{props.brandRef.afterSales && <Icon name={'check'} />}</div>
        <div>{props.brandRef.sales && <Icon name={'check'} />}</div>
        <div>{props.brandRef.isPrimaryDealer && <Icon name={'check'} />}</div>
        <div />
      </>
    )
  }

  const handleCheckboxChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = { ...props.brandRef, [evt.target.name]: evt.target.checked }
    if (!value.sales) {
      value.isPrimaryDealer = false
    }
    props.onChange(value)
  }
  return (
    <>
      <Select
        value={props.brandRef.brandId}
        style={{ width: 200 }}
        onChange={(event) => {
          props.onChange({ ...props.brandRef, brandId: event.target.value })
        }}
      >
        {brands.map((b) => (
          <Select.Option key={b.id} value={b.id}>
            {b.name}
          </Select.Option>
        ))}
      </Select>
      <div>
        <Checkbox checked={props.brandRef.afterSales} name={'afterSales'} onChange={handleCheckboxChange} />
      </div>
      <div>
        <Checkbox checked={props.brandRef.sales} name={'sales'} onChange={handleCheckboxChange} />
      </div>
      <div>
        <Checkbox
          checked={props.brandRef.isPrimaryDealer}
          name={'isPrimaryDealer'}
          onChange={handleCheckboxChange}
          disabled={!props.brandRef.sales}
        />
      </div>
      <div>
        <IconButton aria-label={'remove'} size={'small'} onClick={() => props.onDelete()}>
          <Icon name={'delete'} />
        </IconButton>
      </div>
    </>
  )
}

interface LocationGroupListItemProps {
  locationId: string
  group: Pick<LocationGroup, 'id' | 'name' | 'brands'>
}

const LocationGroupListItem: React.FC<LocationGroupListItemProps> = (props) => {
  const group = props.group

  const [editMode, setEditMode] = useState(false)

  const [update] = useUpdateLocationGroupMutation()
  const handleSave = async (values: LocationGroupFormValues) => {
    try {
      await update({
        variables: {
          groupUpdate: {
            location_id: props.locationId,
            name: values.name,
          },
          brandIds: values.brandIds,
          groupBrandRefObj: values.brandIds.map((bid) => ({
            brand_id: bid,
            location_group_id: group.id,
          })),
          id: group.id,
        },
      })
      setEditMode(false)
    } catch {}
  }

  const [remove] = useRemoveLocationGroupMutation()
  const handleDelete = async () => {
    try {
      await remove({
        variables: {
          id: props.group.id,
        },
      })
    } catch {}
  }

  if (editMode) {
    return (
      <LocationGroupForm
        group={{
          name: group.name,
          brandIds: group.brands.map((brand) => brand.id),
        }}
        onSave={handleSave}
        onCancel={() => setEditMode(false)}
        onDelete={handleDelete}
      />
    )
  }

  return (
    <L.Horizontal className="py-2 px-0">
      <div>{group.name}</div>
      <L.Horizontal spacing={1} style={{ flexGrow: 1 }}>
        {group.brands.map((brand) => (
          <Chip color="primary" key={brand.name}>
            {brand.name}
          </Chip>
        ))}
      </L.Horizontal>
      <div>
        <IconButton aria-label="edit" onClick={() => setEditMode(true)} size="large">
          <Icon name="edit" />
        </IconButton>
      </div>
    </L.Horizontal>
  )
}

interface LocationGroupFormValues {
  name: string
  brandIds: string[]
}

interface LocationGroupFormProps {
  group: LocationGroupFormValues
  onCancel: () => void
  onSave: (values: LocationGroupFormValues) => void
  onDelete?: () => void
}

const LocationGroupForm: React.FC<LocationGroupFormProps> = (props) => {
  const query = useBrandsQuery()
  const brands = query.data?.brands ?? []
  const { bindings, setValue, values } = useFormFields(props.group)

  return (
    <Form>
      <L.Vertical spacing={1} className="py-2 px-0">
        <L.Horizontal>
          <TextField label="Name" {...bindings.name} />
          <MultiSelect
            value={values.brandIds}
            name="brandIds"
            onChange={(evt) => setValue('brandIds', evt.value)}
            renderValueItem={(brandId) => brands.find(({ id }) => brandId === id)?.name ?? brandId}
            label="Marken"
            style={{ flexGrow: 1 }}
          >
            {brands.map((brand) => (
              <MultiSelect.Option key={brand.id} value={brand.id}>
                {brand.name}
              </MultiSelect.Option>
            ))}
          </MultiSelect>
        </L.Horizontal>
        <L.Horizontal spacing={1}>
          <IconButton aria-label="save" type="submit" color="primary" onClick={() => props.onSave(values)} size="large">
            <Icon name="check" />
          </IconButton>
          <IconButton aria-label="cancel" onClick={props.onCancel} size="large">
            <Icon name="close" />
          </IconButton>
          <div style={{ flexGrow: 1 }} />
          {props.onDelete && (
            <IconButton aria-label="delete" onClick={props.onDelete} color="secondary" size="large">
              <Icon name="delete" />
            </IconButton>
          )}
        </L.Horizontal>
      </L.Vertical>
    </Form>
  )
}
