import React from 'react'
import { useAppLocationId } from 'clientState'
import { useEzAuth } from '@lib/ezauth'
import { LocationSelect } from 'containers/LocationSelect'

export const AppLocationSelect = () => {
  const [locationId, setLocationId] = useAppLocationId()

  const [{ initialized, authenticated }] = useEzAuth()

  return (
    <LocationSelect
      value={locationId}
      onSelect={(locationId) => setLocationId(locationId)}
      hideLabel
      showDefaultValue={initialized && authenticated && !locationId}
      style={{ minWidth: 120 }}
    />
  )
}
