import { useCallback, useState } from 'react'
import { User } from '@lib/ezauth'

export type NewPasswordChallengeFn = (data: { user: User }) => Promise<{ password: string }>
export type ForgotPasswordFn = (data: { username: string }) => Promise<{ otp: string; newPassword: string }>
let registeredNewPwChallengeFn: NewPasswordChallengeFn
let registeredForgotPwFn: ForgotPasswordFn
const registerNewPasswordChallenge = (callback: NewPasswordChallengeFn) => {
  registeredNewPwChallengeFn = callback
}
const registerForgotPasswordCb = (callback: ForgotPasswordFn) => {
  registeredForgotPwFn = callback
}

export const execNewPasswordChallenge: NewPasswordChallengeFn = (data) => {
  if (registeredNewPwChallengeFn) {
    return registeredNewPwChallengeFn(data)
  }
  throw new Error('no new password challenge implemented')
}
export const execForgotPassword: ForgotPasswordFn = (data) => {
  if (registeredForgotPwFn) {
    return registeredForgotPwFn(data)
  }
  throw new Error('no forgot password implemented')
}

export const useNewPasswordRequired = () => {
  const [newPwPromise, setNewPwPromise] = useState<null | {
    resolve: (data: { password: string }) => void
    reject: () => void
  }>(null)
  const newPwCb = useCallback(() => {
    const promise = new Promise<{ password: string }>((resolve, reject) => {
      setNewPwPromise({ resolve, reject })
    })
    promise.finally(() => {
      setNewPwPromise(null)
    })
    return promise
  }, [])
  registerNewPasswordChallenge(newPwCb)

  const requireNewPassword = !!newPwPromise
  return [requireNewPassword, newPwPromise] as const
}

export const useForgotPassword = () => {
  const [forgotPwPromise, setForgotPwPromise] = useState<null | {
    resolve: (data: { otp: string; newPassword: string }) => void
    reject: () => void
  }>(null)
  const forgotPwCb = useCallback(() => {
    const promise = new Promise<{ otp: string; newPassword: string }>((resolve, reject) => {
      setForgotPwPromise({ resolve, reject })
    })
    promise.finally(() => {
      setForgotPwPromise(null)
    })
    return promise
  }, [])
  registerForgotPasswordCb(forgotPwCb)
  const forgotPasswordPending = !!forgotPwPromise
  return [forgotPasswordPending, forgotPwPromise] as const
}
