import React, { CSSProperties, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { GroupContent, GroupedVirtuoso, GroupItemContent } from 'react-virtuoso'
import { Typo } from '../Typo'
import { TitleBar } from './TitleBar'
import { ClickOutsideListener } from '../ClickOutsideListener'
import styled from './Yalc2.module.css'
import { Menu } from '../Menu'
import { Icon } from '../Icon'
import Select from '../Select'
import { Chip } from '../Chip'
import { PaginationNavigation, PaginationNavigationProps } from '../PaginationNavigation'
import { ScrollWindow, useVirtualizedWindow } from '../ScrollWindow'
import { RowHighlight } from '../../containers/ListConfig'
import { Spinner } from 'components/Spinner'

type OnActionFn<Action = string, Payload = any> = (action: Action, payload: Payload) => void

interface Yalc2CellProps<T> {
  item: T
  emitAction?: OnActionFn
}

interface Yalc2ColumnDefinition<T extends { id: string }> {
  key?: string
  title?: React.ReactNode
  sortColumn?: keyof T
  hide?: boolean
  width: string | number
  cell: React.FC<Yalc2CellProps<T>>
}

export interface Yalc2RowDefinition<T extends { id: string }> {
  columns: Yalc2ColumnDefinition<T>[]
  highlights?: RowHighlight<T>[]
}

interface Yalc2Props<T extends { id: string }> {
  data: T[]
  groups?: { title?: string; count: number }[]
  title?: string
  disabledHeaderRow?: boolean
  rowDefinition?: Yalc2RowDefinition<T>
  className?: string
  onClickOutsideList?: (event: React.MouseEvent) => void
  classes?: {
    listWrapper?: string
    item?: string
  }
  selectedIds?: string[]
  onSelectionChanged?: (ids: string[]) => void
  customIdProp?: string
  actionBar?: React.ReactNode
  options?: React.ReactNode
  onSort?: (col: keyof T, direction: 'asc' | 'desc') => void | Promise<void>
  orderedColumn?: { col: keyof T; direction: 'asc' | 'desc' }
  onBulkEdit?: () => void
  paginationNavigation?: PaginationNavigationProps
  style?: CSSProperties
  loading?: boolean
}

const noop = () => {}
export const Yalc2 = <T extends { id: string }>(props: Yalc2Props<T>) => {
  const selectedIdsRef = useRef(props.selectedIds)
  selectedIdsRef.current = props.selectedIds ?? []
  const [rowDef, setRowDef] = useState<Yalc2RowDefinition<T>>(null)
  useEffect(() => setRowDef(props.rowDefinition), [props.rowDefinition])

  return (
    <ScrollWindow>
      <ClickOutsideListener onClickOutside={props.onClickOutsideList ?? noop}>
        <List {...props} setRowDefinition={setRowDef} rowDefinition={rowDef} selectedIds={selectedIdsRef.current} />
      </ClickOutsideListener>
    </ScrollWindow>
  )
}

const List = <T extends { id: string }>(
  props: Yalc2Props<T> & { setRowDefinition: React.Dispatch<React.SetStateAction<Yalc2RowDefinition<T>>> }
) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [ref] = useVirtualizedWindow<HTMLDivElement>(wrapperRef)

  const width = useMemo(() => {
    const widths = props.rowDefinition?.columns?.filter((col) => !col.hide)?.map((rowDef) => rowDef.width) ?? []
    return Number(widths.reduce((prev, curr) => Number(prev) + Number(curr) + 8, 0)) + 8
  }, [props.rowDefinition?.columns])

  const ids = useMemo(() => props.data.map((item) => item[props.customIdProp ?? 'id']), [
    props.customIdProp,
    props.data,
  ])

  const handleItemClick = useHandleClick(ids, props.selectedIds, props.onSelectionChanged)

  const ItemContent: GroupItemContent<T, any> = (index) => {
    const item = props.data[index]
    if (!item) return undefined
    return (
      <ListItem
        rowDefinition={props.rowDefinition as any}
        item={item}
        onClick={handleItemClick}
        selected={props.selectedIds?.indexOf(item?.[props.customIdProp ?? 'id']) >= 0}
        className={clsx(props.classes?.item, 'm-0')}
        idProp={props.customIdProp ?? 'id'}
      />
    )
  }

  const groupContent: GroupContent = (index) => {
    if ((props?.groups && !props?.groups?.[index].count) || !props.data.length) return null
    return (
      <div className="bg-background-default z-appBar mb-4">
        {(props?.groups?.[index].title || props.title) && (
          <Typo variant="h3" decorator>
            {props?.groups?.[index].title || props.title}
          </Typo>
        )}
        <TitleBar {...props} rowDefinition={props.rowDefinition as any} selectedIds={props.selectedIds} />
      </div>
    )
  }
  return (
    <>
      <ActionBar {...props}>{props.actionBar}</ActionBar>
      <ClickOutsideListener onClickOutside={props.onClickOutsideList ?? noop}>
        <div
          ref={ref}
          className={clsx('p-0 mt-0 mx-0 mb-6 h-auto', props.className)}
          style={{ height: '100vh', ...props.style }}
        >
          <div style={{ minWidth: width, width: '100%' }}>
            {props.loading && <Spinner />}
            <GroupedVirtuoso
              overscan={500}
              useWindowScroll
              style={{ backgroundColor: 'white', padding: 0 }}
              components={{ EmptyPlaceholder: NoDataText }}
              groupCounts={props.groups?.map((g) => g.count) ?? [props.data.length]}
              itemContent={ItemContent}
              groupContent={groupContent}
            />
          </div>
          {props.paginationNavigation && (
            <div className="flex flex-row p-2 sticky bottom-0 ">
              <div className="flex-1 " />
              <PaginationNavigation
                {...props.paginationNavigation}
                className="opacity-40 rounded-md hover:opacity-90 hover:bg-grey-700 hover:text-white"
              />
            </div>
          )}
        </div>
      </ClickOutsideListener>
    </>
  )
}

type OnSelectionChangedFn = (ids: string[]) => void
export const useHandleClick = (
  keys: string[],
  selected: string[],
  onSelectionChanged: OnSelectionChangedFn
): HandleClickFn => {
  // TODO: check if we can just use the selectedIds array instead, which would implocate that the invariant "selectedIds is always sorted by selectedAt" exists
  const prevSelectedIds = useRef<string[]>([])
  useEffect(() => {
    // remove deselected items from prevSelected Stack
    prevSelectedIds.current = prevSelectedIds.current?.filter((id) => selected?.indexOf(id) >= 0)
  }, [selected, keys])

  // performanceReasons
  const keysRef = useRef(keys)
  keysRef.current = keys

  // performanceReasons
  const selectedIdsRef = useRef(selected)
  selectedIdsRef.current = selected ?? []

  // performanceReasons
  const selectionChangedRef = useRef(onSelectionChanged)
  selectionChangedRef.current = (...args) => {
    onSelectionChanged && onSelectionChanged(...args)
  }

  return useCallback((event: React.MouseEvent<HTMLElement>, clickedId: string) => {
    // skip when clicked in an input or button within a list item
    if (event.target) {
      const target = event.target as Element
      if (target.tagName === 'INPUT' || target.tagName === 'BUTTON') {
        return
      }
    }

    const selectedIds = selectedIdsRef.current
    const keys = keysRef.current
    const isSelected = !!selectedIds && selectedIds.indexOf(clickedId) >= 0
    const handleCtrlKey = () => {
      if (isSelected) {
        selectionChangedRef.current(selectedIds.filter((id) => id !== clickedId))
      } else {
        selectionChangedRef.current([...selectedIds, clickedId])
        prevSelectedIds.current.push(clickedId)
      }
    }

    const handleShiftKey = () => {
      const prevId = prevSelectedIds.current[prevSelectedIds.current.length - 1]
      const prevIdx = keys.findIndex((id) => id === prevId)
      const toIdx = keys.findIndex((id) => id === clickedId)
      let idRange = keys.slice(Math.min(prevIdx, toIdx), Math.max(prevIdx, toIdx) + 1)
      if (isSelected) {
        selectionChangedRef.current(selectedIds.filter((id) => idRange.indexOf(id) < 0))
      } else {
        idRange = idRange.filter((id) => id !== prevId)
        selectionChangedRef.current([...selectedIds, ...idRange])
        prevSelectedIds.current.push(...(prevIdx < toIdx ? idRange : idRange.reverse()))
      }
    }

    if (prevSelectedIds.current.length) {
      if (event.ctrlKey || event.metaKey) {
        return handleCtrlKey()
      } else if (event.shiftKey) {
        return handleShiftKey()
      }
    }
    selectionChangedRef.current([clickedId])
    prevSelectedIds.current.push(clickedId)
  }, [])
}

interface Yalc2CellProps<T> {
  item: T
}

type HandleClickFn = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => void

interface YalcListItemProps<T extends { id: string }> {
  rowDefinition: Yalc2RowDefinition<T>
  item: T
  selected?: boolean
  onClick?: HandleClickFn
  className?: string
  idProp: string
  style?: CSSProperties
}

const ListItem = React.memo(<T extends { id: string }>(props: YalcListItemProps<T>) => {
  const cellProps: Yalc2CellProps<T> = { item: props.item }

  const handleCLick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.onClick && props.onClick(evt, props.item[props.idProp])
  }

  const highlights = props.rowDefinition?.highlights?.filter((highlight) => highlight?.filter(props.item))

  return (
    <div
      style={props.style}
      className={clsx(styled.listItem, props.className, {
        'bg-primary-light text-white': highlights?.some((highlight) => highlight?.color === 'default'),
        'bg-error-light text-white': highlights?.some((highlight) => highlight?.color === 'danger'),
        'bg-success-light text-white': highlights?.some((highlight) => highlight?.color === 'success'),
        'bg-warning-light': highlights?.some((highlight) => highlight?.color === 'warning'),
        'bg-grey-200': highlights?.some((highlight) => highlight?.color === 'grey'),
        'bg-grey-100': highlights?.some((highlight) => highlight?.color === 'grey-light'),
      })}
      aria-selected={!!props.selected}
      onClick={handleCLick}
      tabIndex={-1}
    >
      {props.rowDefinition?.columns
        ?.filter((col) => !col.hide)
        ?.map((col, idx) => {
          const Cell = col?.cell
          return (
            <div style={{ minWidth: col?.width, width: col?.width }} className={styled.root} key={idx}>
              <Cell {...cellProps} />
            </div>
          )
        })}
    </div>
  )
})

type ActionBarProps<T extends { id: string }> = Pick<
  Yalc2Props<T>,
  'onBulkEdit' | 'rowDefinition' | 'selectedIds' | 'options' | 'onSort' | 'orderedColumn'
> & { setRowDefinition?: React.Dispatch<React.SetStateAction<Yalc2RowDefinition<T>>> }

const ActionBar = <T extends { id: string }>(props: PropsWithChildren<ActionBarProps<T>>) => {
  const handleChangeColumnVisibility = useCallback(
    (colName: React.ReactNode) => {
      const modifiedColumns = props.rowDefinition.columns.map((col) => {
        return col.title !== colName
          ? col
          : {
              ...col,
              hide: !col.hide,
            }
      })
      props.setRowDefinition({ columns: modifiedColumns })
    },
    [props]
  )
  const disableBulkEditButton = useMemo(
    () => !props.onBulkEdit || !props.selectedIds || !props.selectedIds.length || props.selectedIds.length < 2,
    [props.onBulkEdit, props.selectedIds]
  )
  const sortableColumns = useMemo(() => props.rowDefinition?.columns?.filter((col) => !!col.sortColumn), [
    props.rowDefinition?.columns,
  ])
  return (
    <div className="flex flex-row w-full justify-between mb-2 gap-2 items-end">
      {props.children}
      {!props.children && <div className="flex-1" />}
      <Menu>
        <Menu.Button aria-label="More options" className={'w-32'} endIcon={<Icon name="dropdown" />}>
          Ansicht
        </Menu.Button>
        <Menu.ItemList>
          {props.onSort && sortableColumns?.length > 0 && (
            <Select
              label="Sortieren nach"
              style={{ maxWidth: '50%' }}
              className="m-2"
              value={props.orderedColumn?.col?.toString()}
              name="Sort"
              onChange={(evt) => props.onSort(evt.target.value as keyof T, 'asc')}
            >
              <Select.Option key={`sort-col-null`} value={undefined}>
                Keine Sortierung
              </Select.Option>
              {sortableColumns?.map((col, idx) => (
                <Select.Option key={`sort-col-option-${idx}`} value={col.sortColumn.toString()}>
                  {col.title}
                </Select.Option>
              ))}
            </Select>
          )}
          <Menu.Item disabled={disableBulkEditButton} onClick={props.onBulkEdit}>
            Editieren
          </Menu.Item>
          {props.options}
          {props.rowDefinition &&
            props.rowDefinition.columns &&
            props.rowDefinition.columns.length &&
            props.rowDefinition.columns.length > 0 && (
              <div>
                <hr className="text-divider pb-2" />
                <div className="flex flex-row flex-wrap" style={{ maxWidth: 300 }}>
                  {props.rowDefinition.columns
                    .filter((col) => !!col.title)
                    .map((col, idx) => (
                      <Chip
                        key={`chip-of-column-${idx}`}
                        onClick={() => handleChangeColumnVisibility(col.title)}
                        className="m-1"
                        color="primary"
                        variant={col.hide ? 'outlined' : 'filled'}
                      >
                        {col.title}
                      </Chip>
                    ))}
                </div>
              </div>
            )}
        </Menu.ItemList>
      </Menu>
    </div>
  )
}

const NoDataText: React.FC = () => {
  return (
    <Typo variant="h4" className={'block text-center pt-4'}>
      {'Keine Daten vorhanden.'}
    </Typo>
  )
}
