import React, { CSSProperties } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import MuiLink from '@mui/material/Link'

export interface LinkProps {
  to: string
  underline?: 'none' | 'hover' | 'always'
  size?: 'small' | 'default'
  className?: string
  style?: CSSProperties
}

export const Link: React.FC<LinkProps> = React.forwardRef((props, ref) => {
  const { size, ...rest } = props
  let variant = 'inherit' as 'inherit' | 'body2'
  if (size === 'small') {
    variant = 'body2'
  }
  return <MuiLink component={RouterLink} variant={variant} {...rest} ref={ref as any} />
})

Link.defaultProps = {
  underline: 'always',
  size: 'default',
}
