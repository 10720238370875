import React, { CSSProperties } from 'react'
import MuiList from '@mui/material/List'
import MuiListItem, { ListItemProps as MuiListItemProps } from '@mui/material/ListItem'
import { Spinner } from 'components/Spinner'
import clsx from 'clsx'
import styled from './List.module.css'

export interface ListProps {
  loading?: boolean
  style?: CSSProperties
  className?: string
  children?: React.ReactNode
}

export const List = (props: ListProps) => {
  const { children, loading, ...rest } = props

  return (
    <MuiList {...rest} className={clsx('bg-background-paper', props.className)}>
      {loading && (
        <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <Spinner />
        </div>
      )}
      {children}
    </MuiList>
  )
}

export interface ListItemProps {
  selected?: boolean
  onClick?: MuiListItemProps['onClick']
  style?: CSSProperties
  className?: string
  component?: MuiListItemProps['ContainerComponent']
}

export const ListItem: React.FC<ListItemProps> = (props) => {
  const { children, component: comp, ...rest } = props

  const isButton = !!props.onClick

  return (
    <MuiListItem
      divider
      button={isButton as any}
      {...rest}
      className={clsx(styled.listItem, isButton && styled.listItemButton, props.className)}
      aria-selected={props.selected}
      ContainerComponent={comp}
    >
      {children}
    </MuiListItem>
  )
}
List.Item = ListItem

interface ListCellProps {
  width?: number
  className?: string
  style?: CSSProperties
}

const ListCell: React.FC<ListCellProps> = (props) => {
  return (
    <div
      className={clsx('py-2 px-1 flex flex-col justify-center', props.className)}
      style={{ width: props.width, ...props.style }}
    >
      {props.children}
    </div>
  )
}
List.Cell = ListCell

const ListPrimaryText: React.FC = (props) => {
  return <div className="typo-body1 pb-0 mb-0">{props.children}</div>
}
List.PrimaryText = ListPrimaryText
const ListSecondaryText: React.FC = (props) => {
  return <div className="typo-overline leading-none text-grey-700">{props.children}</div>
}
List.SecondaryText = ListSecondaryText
