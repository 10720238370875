import { Avatar } from 'components'
import { useBrandsQuery } from 'gql'
import React from 'react'

export interface BrandAvatarProps {
  brandId: string
}

export const BrandAvatar = (props: BrandAvatarProps) => {
  const query = useBrandsQuery()
  const allBrands = query.data?.brands ?? []

  const brand = allBrands.find(({ id }) => id === props.brandId)

  const abr = brand?.name?.substr(0, 1) ?? '?'

  // TODO: impl custom avatar
  return (
    <Avatar alt={brand?.name} >
      {abr}
    </Avatar>
  )
}

// const strToHex = (str: string) => {
//   var hash = 0
//   if (str.length === 0) return hash
//   for (let i = 0; i < str.length; i++) {
//     hash = str.charCodeAt(i) + ((hash << 5) - hash)
//     hash = hash & hash
//   }
//   var color = '#'
//   for (let i = 0; i < 3; i++) {
//     // var value = (hash >> (i * 8)) & 255
//     var value = (hash >> (i * 8)) & 200
//     color += ('00' + value.toString(16)).substr(-2)
//   }
//   return color
// }

// const strToColor = (str: string) => {
//   var colors = [
//     '#e51c23',
//     '#e91e63',
//     '#9c27b0',
//     '#673ab7',
//     '#3f51b5',
//     '#5677fc',
//     '#03a9f4',
//     '#00bcd4',
//     '#009688',
//     '#259b24',
//     '#8bc34a',
//     '#afb42b',
//     '#ff9800',
//     '#ff5722',
//     '#795548',
//     '#607d8b',
//   ]

//   var hash = 0
//   if (str.length === 0) return hash
//   for (var i = 0; i < str.length; i++) {
//     hash = str.charCodeAt(i) + ((hash << 5) - hash)
//     hash = hash & hash
//   }
//   hash = ((hash % colors.length) + colors.length) % colors.length
//   return colors[hash]
// }
