import { SiteMetadata } from 'components'
import React, { useEffect } from 'react'
import { VehiclesListConfig, VehiclesListType } from '../../../../containers/ListConfig/Vehicles/List/Config'
import { VehiclesList } from '../../../../containers/ListConfig/Vehicles/List'
import { useVehiclesStore } from '../../../../containers/ListConfig/Vehicles/hooks/useData'

export const VehiclesWithSyncErrors = () => {
  const { reset } = useVehiclesStore()
  useEffect(() => reset(), [reset])
  return (
    <>
      <SiteMetadata title="Datenqualität - Fahrzeuge mit den Synchronisationsfehlern" />
      <VehiclesListConfig type={VehiclesListType.DATA_QUALITY}>
        <VehiclesList />
      </VehiclesListConfig>
    </>
  )
}
