import MuiBadge from '@mui/material/Badge'
import React from 'react'

interface BadgeProps {
  content: number | string
  color: 'danger'
  max: number
}

export const Badge: React.FC<BadgeProps> = (props) => {
  const color = useMuiBadgeColor(props)
  return (
    <MuiBadge color={color} max={props.max} badgeContent={props.content}>
      {props.children}
    </MuiBadge>
  )
}

type MuiBadgeColor = 'default' | 'error' | 'primary' | 'secondary'
const useMuiBadgeColor = (props: BadgeProps): MuiBadgeColor => {
  switch (props.color) {
    case 'danger':
      return 'error'
    default:
      throw new Error('bade color not impl')
  }
}
