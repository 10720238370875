import AdapterDateFns from '@mui/lab/AdapterDateFns'
import MuiDatePicker, { DatePickerProps as MuiDatePickerProps } from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { TextField } from '@mui/material'
import React from 'react'
import deLocale from 'date-fns/locale/de'

export interface DatePickerProps {
  value: MuiDatePickerProps<Date>['value']
  onChange?: MuiDatePickerProps<Date>['onChange']
  disabled?: boolean
  label?: string
}

export const DatePicker = (props: DatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <MuiDatePicker
        {...props}
        value={props.value ?? null}
        onChange={props.onChange ?? (() => {})}
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        renderInput={(p) => (
          <TextField
            {...p}
            size="small"
            fullWidth
            disabled={props.disabled}
            label={props.label}
            sx={{
              '& input:disabled ': {
                color: 'text.primary',
                WebkitTextFillColor: 'unset',
              },
              '& input:disabled ~ fieldset': {
                border: 'none',
              },
              '& input:disabled ~ div button': {
                display: 'none',
              },
              '.error & input ~ fieldset': {
                borderColor: 'error.main',
              },
            }}
          />
        )}
        disabled={props.disabled}
      />
    </LocalizationProvider>
  )
}
