import React, { CSSProperties } from 'react'
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip'
import clsx from 'clsx'
import styled from './Chip.module.css'
export interface ChipProps {
  children: MuiChipProps['label']
  onClick?: MuiChipProps['onClick']
  variant?: MuiChipProps['variant']
  size?: MuiChipProps['size']
  color?: 'primary' | 'secondary' | 'default' | 'danger' | 'success' | 'warning'
  className?: string
  style?: CSSProperties
}

export const Chip: React.FC<ChipProps> = (props) => {
  const { children, color, ...rest } = props
  const chipColor = props.color === 'danger' ? 'error' : props.color ?? 'default'
  return (
    <MuiChip
      clickable={!!props.onClick}
      label={children}
      {...rest}
      className={clsx(
        'px-1',
        styled.root,
        props.variant === 'filled' && styled[`dft-${chipColor}`],
        props.variant === 'outlined' && styled[`out-${chipColor}`],
        props.className
      )}
      size="small"
    />
  )
}

Chip.defaultProps = {
  variant: 'outlined',
  size: 'small',
}
