import React, { CSSProperties, useMemo } from 'react'
import { List, ListItem } from '../List'
import clsx from 'clsx'
import { Typo } from '../Typo'
import { Icon } from '../Icon'
import { Brand, useBrandsQuery } from '../../gql'
import { useBrandId } from '../../containers/ListConfig/Vehicles/hooks/useBrandId'
import { ClickOutsideListener } from '../ClickOutsideListener'
import { useCollapsable } from './index'

interface BookmarkOption {
  title: string
  value: string
  brandsFilter?: (brand: Brand) => boolean
}

interface BookmarkCollapsableProps {
  title: string
  options: BookmarkOption[]
  onSelect: (value: string) => void
  selectedBrandId?: string
  onBrandSelect?: (value: string) => void
  selected?: string
  suppressBrands?: boolean
  className?: string
  style?: CSSProperties
}

export const BookmarkCollapsable: React.FC<BookmarkCollapsableProps> = (props) => {
  const { suppressBrands = false } = props
  const { ref, state, dispatcher } = useCollapsable(false)

  const query = useBrandsQuery()
  const data = useMemo(() => {
    let result = query.data?.brands ?? []
    result = result.filter((b) => b.is_house_brand)
    return result
  }, [query.data?.brands])

  const { get: getBrandId, set: setBrandId } = useBrandId()
  const selectedBrandId = useMemo(() => getBrandId(), [getBrandId])

  return (
    <ClickOutsideListener onClickOutside={() => dispatcher.setIsCollapsed(true)}>
      <div
        className={clsx('block cursor-pointer rounded-b-xl w-64', props.className)}
        style={props.style}
        onClick={() => dispatcher.setIsCollapsed((prev) => !prev)}
      >
        <div
          ref={ref}
          className={clsx('transition-all duration-500 ease-in-out flex-full h-0 overflow-hidden ')}
          style={{ height: state.bodyHeight }}
        >
          <List>
            {props.options.map((o) => (
              <div key={`bookmark-option-${o.value}`}>
                <Option option={o} selected={props.selected === o.value} onSelect={props.onSelect} />
                {!suppressBrands && props.selected === o.value && (
                  <div className={'p-4'}>
                    <Typo className={'p-2 font-bold'} variant={'body1'}>
                      Marken
                    </Typo>
                    {data.filter(o.brandsFilter ? o.brandsFilter : () => true).map((brand) => (
                      <Option
                        key={`bookmark-option-brand-${brand.id}`}
                        selected={props.selectedBrandId === brand.id || selectedBrandId === brand.id}
                        onSelect={props.onBrandSelect ? props.onBrandSelect : (value) => setBrandId(value)}
                        option={{ value: brand.id, title: brand.name }}
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </List>
        </div>
        <div className="bg-background-darkDefault text-white flex mx-auto rounded-b-xl justify-between p-2 w-32">
          <Typo variant="body1">{props.title}</Typo>
          <Icon name={state.bodyHeight ? 'down' : 'up'} />
        </div>
      </div>
    </ClickOutsideListener>
  )
}

const Option = (props: { onSelect: (value: string) => void; option: BookmarkOption; selected?: boolean }) => {
  return (
    <ListItem
      onClick={(evt) => {
        evt.stopPropagation()
        props.onSelect(props.option.value)
      }}
      className={clsx(props.selected && 'bg-action-selected')}
    >
      {props.option.title}
    </ListItem>
  )
}
