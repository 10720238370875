import React from 'react'
import MuiLinearProgress from '@mui/material/LinearProgress'
import { Typo } from 'components/Typo'

interface LinearProgressProps {
  value: number
  variant?: 'determinate' | 'indeterminate'
  showLabel?: boolean
}

export const LinearProgress = (props: LinearProgressProps) => {
  const { variant = 'determinate', showLabel = true, value } = props
  return (
    <div className="flex items-center">
      <div className="w-full mr-4">
        <MuiLinearProgress variant={variant} value={value} />
      </div>
      {showLabel && (
        <div style={{ minWidth: 35 }}>
          <Typo variant="body1">{`${Math.round(value)}%`}</Typo>
        </div>
      )}
    </div>
  )
}
