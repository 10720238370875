import React, { MouseEventHandler, useMemo, useState } from 'react'
import * as L from 'layouts'
import {
  AdminBrandEmbeddedPagesSubscriptionResult,
  useAdminBrandEmbeddedPagesSubscription,
  useDeleteEmbeddedPageMutation,
} from '../../gql'
import { Spinner } from 'components/Spinner'
import { Yalc2, Yalc2RowDefinition } from '../../components/Yalc2'
import { Yalc2Cell } from '../../components/Yalc2/cells'
import { AdminEmbeddedPageDetail } from '../AdminEmbeddedPageDetail'
import { Menu } from 'components/Menu'
import { IconButton } from 'components/IconButton'
import { Icon } from 'components/Icon'
import clsx from 'clsx'

type AdminBrandEmbeddedPagesList = AdminBrandEmbeddedPagesSubscriptionResult['data']['embedded_pages']
export type AdminBrandEmbeddedPagesListItem = ArrayElement<AdminBrandEmbeddedPagesList>
export const AdminBrandEmbeddedPages = (props: { brandId: string }) => {
  const { brandId } = props

  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [showAddNewBrandEmbeddedPage, setShowAddNewBrandEmbeddedPage] = useState<boolean>(false)

  const subscription = useAdminBrandEmbeddedPagesSubscription({ variables: { brandId } })
  const embeddedPages = useMemo(() => subscription.data?.embedded_pages ?? [], [subscription.data?.embedded_pages])
  const rowDefinition = useRowDefinition()
  return (
    <L.Vertical spacing={3}>
      {subscription.loading && <Spinner size={'1rem'} />}
      <Yalc2<AdminBrandEmbeddedPagesListItem>
        selectedIds={selectedIds}
        onSelectionChanged={setSelectedIds}
        rowDefinition={rowDefinition}
        data={embeddedPages}
        classes={{ item: 'group' }}
        options={<Menu.Item onClick={() => setShowAddNewBrandEmbeddedPage(true)}>Neue Externe Seite</Menu.Item>}
      />
      {selectedIds.length === 1 && (
        <AdminEmbeddedPageDetail
          title={'Externe Seite bearbeiten'}
          onClose={() => setSelectedIds([])}
          brandId={props.brandId}
          embeddedPage={embeddedPages.find((page) => page.id === selectedIds[0])}
        />
      )}
      {showAddNewBrandEmbeddedPage && (
        <AdminEmbeddedPageDetail
          brandId={props.brandId}
          title={'Neue Externe Seite'}
          onClose={() => setShowAddNewBrandEmbeddedPage(false)}
        />
      )}
    </L.Vertical>
  )
}

const useRowDefinition = (): Yalc2RowDefinition<AdminBrandEmbeddedPagesListItem> => {
  const [deleteEmbeddedPage] = useDeleteEmbeddedPageMutation()
  return useMemo(
    () => ({
      columns: [
        {
          title: 'Name',
          width: 250,
          cell: function NameCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.name}</Yalc2Cell.SimpleCell>
          },
        },
        {
          title: 'Url',
          width: 250,
          cell: function UrlCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.url}</Yalc2Cell.MaxWidthCell>
          },
        },
        {
          title: '',
          width: 50,
          cell: function QuickActionsCell(props) {
            const handleDelete: MouseEventHandler<HTMLButtonElement> = async (evt) => {
              evt.stopPropagation()
              await deleteEmbeddedPage({ variables: { id: props.item.id } })
            }
            return (
              <Yalc2Cell.SimpleCell className={clsx('hidden items-end group-hover:flex')}>
                <IconButton aria-label={'delete-brand-embedded-page'} onClick={handleDelete}>
                  <Icon name={'delete'} />
                </IconButton>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
      ],
    }),
    [deleteEmbeddedPage]
  )
}
