import { CognitoAuthenticator } from './impl/CognitoAuthenticator'
import { MockedAuthenticator } from './impl/MockedAuthenticator'
import { InitFn, SignInFn, SignOutFn, ForgotPasswordFn } from '@lib/ezauth'

export interface Authenticator {
  init: InitFn
  signIn: SignInFn
  signOut: SignOutFn
  forgotPassword: ForgotPasswordFn
}

interface ProviderConfig {
  protocol: string
  options: {
    [key: string]: string | boolean | number
  }
}

export const createAuthenticator = (provider: ProviderConfig): Authenticator => {
  if (!provider) {
    throw new Error('no authenticator link provided')
  }

  switch (provider.protocol) {
    case 'mock':
      return new MockedAuthenticator()
    case 'cognito':
      return new CognitoAuthenticator(provider.options as any)
    default:
      throw new Error(`unable to create authenticator for '${provider.protocol}', not implemented`)
  }
}
