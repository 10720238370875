import { mapVehicleFormValues, VehicleDetail, VehicleFormValues } from 'containers/VehicleForm'
import { GeneralData } from 'containers/VehicleForm/01_GeneralData'
import { HistoryData } from 'containers/VehicleForm/04_HistoryData'
import {
  Vehicle_Set_Input,
  useVehicleDetailsSubscription,
  useUpdateVehicleMutation,
  useVehicleMetafieldsLazyQuery, useUpdateModelsMutation,
} from 'gql'
import React, { useCallback, useEffect } from 'react'
import { splitVehicleId } from '../List'
import { TicketsData } from '../../../VehicleForm/07_TicketsData'
import { PricesData } from '../../../VehicleForm/03_PricesData'

export interface DealerVehicleDetailsProps {
  id: string
  onClose?: () => void
}

export const DealerVehicleDetails = React.forwardRef<HTMLDivElement, DealerVehicleDetailsProps>((props, ref) => {
  const [id, , businessCaseNumber]: any[] = splitVehicleId(props.id)
  const query = useVehicleDetailsSubscription({ variables: { id, business_case_number: businessCaseNumber } })
  const [metafieldsQuery, metafieldsResponse] = useVehicleMetafieldsLazyQuery()

  useEffect(() => {
    if (!query.data) return
    metafieldsQuery({ variables: { brandId: query.data.vehicle.brand.id } })
  }, [metafieldsQuery, query.data])

  const [save] = useHandleSave(id, businessCaseNumber)
  return (
    <VehicleDetail
      ref={ref}
      data={query.data?.vehicle as any}
      loading={query.loading}
      onClose={props.onClose}
      metadata={{ metafields: metafieldsResponse.data?.brand_vehicle_metafield }}
      panes={{
        general: <GeneralData onSave={save} />,
        prices: <PricesData />,
        history: <HistoryData />,
        tickets: <TicketsData />,
      }}
    />
  )
})

type SaveFn = (data: VehicleFormValues) => Promise<void>
export const useHandleSave = (id: string, business_case_number: number) => {
  const [update, state] = useUpdateVehicleMutation()
  const [updateModels] = useUpdateModelsMutation()
  const save: SaveFn = useCallback(
    async (data) => {
      await update({ variables: { id, business_case_number, input: mapFormData(data) } })
      if (data.model?.sales_model_code && data.model?.model_group_release_year && data.model?.modelGroup?.id) {
        await updateModels({
          variables: {
            set: {
              sales_model_code: data.model?.sales_model_code,
              model_group_id: data.model?.modelGroup?.id,
              model_group_release_year: data.model?.model_group_release_year,
            },
            where: {
              id: { _eq: data.model.id },
            },
          },
        })
      }
    },
    [business_case_number, id, update, updateModels]
  )
  return [save, state] as const
}

const mapFormData = (data: VehicleFormValues): Vehicle_Set_Input => {
  return mapVehicleFormValues<Vehicle_Set_Input>(data, {}, ([key, value]) => {
    switch (key) {
      default:
        return [key, value]
    }
  })
}
