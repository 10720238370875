import { Select } from 'components'
import {Role_Enum, useDepartmentRolesLazyQuery} from 'gql'
import React, {useEffect, useMemo} from 'react'
import { useTranslation } from 'react-i18next'

interface UserRoleSelectProps {
  value: Role_Enum
  onSelect: (role: Role_Enum) => void
  name?: string
  label?: string
  disabled?: boolean
  required?: boolean
  departmentId?: string
}

export const UserRoleSelect = (props: UserRoleSelectProps) => {
  const { t } = useTranslation()
  const [query, queryResponse] = useDepartmentRolesLazyQuery()
  useEffect(() => {
    if (!props.departmentId) {
      return;
    }
    query({variables: {departmentId: props.departmentId}})
  }, [props.departmentId, query])

  const options = useMemo(() => {
    if (!props.departmentId) {
      return Object.values(Role_Enum)
    } else {
      return queryResponse.data?.department_role?.map((relation) => relation.role) ?? []
    }
  }, [props.departmentId, queryResponse.data?.department_role])
  return (
    <Select
      label={props.label ?? 'Rolle'}
      name={props.name ?? 'userRoleSelect'}
      value={props.value}
      onChange={(evt) => {
        evt.stopPropagation()
        props.onSelect(evt.target.value as Role_Enum)
      }}
      renderSelectValueItem={(role) => t(`UserRole.${role}`)}
      disabled={props.disabled}
      required={props.required}
    >
      {options.map((role) => (
        <Select.Option key={role} value={role}>
          {t(`UserRole.${role}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
