enum StoreKey {
  DELAY = 'DELAY',
  RANDOMIZE_DELAY = 'RANDOMIZE_DELAY',
  ERROR_RATE = 'ERROR_RATE',
}

const KEY_PREFIX = 'W2S_NSL_'

interface Config {
  delay: number
  randomizeDelay: boolean
  errorRate: number
}

const defaultValues: Config = {
  delay: 2000,
  randomizeDelay: true,
  errorRate: 0,
}

class NetworkSiimulatorLinkStorage {
  public getDefault() {
    return defaultValues
  }

  public getDelay() {
    return Number(this.getItem(StoreKey.DELAY) ?? defaultValues.delay)
  }

  public setDelay(delay: number) {
    this.setItem(StoreKey.DELAY, String(delay))
  }

  public getRandomizeDelay() {
    return JSON.parse(this.getItem(StoreKey.RANDOMIZE_DELAY) ?? String(defaultValues.randomizeDelay))
  }

  public setRandomizeDelay(val: boolean) {
    this.setItem(StoreKey.RANDOMIZE_DELAY, String(val))
  }

  public getErrorRate() {
    return Number(this.getItem(StoreKey.ERROR_RATE) ?? defaultValues.errorRate)
  }

  public setErrorRate(errorRate: number) {
    this.setItem(StoreKey.ERROR_RATE, String(errorRate))
  }

  private setItem(key: StoreKey, value: string) {
    localStorage.setItem(`${KEY_PREFIX}${key}`, value)
  }

  private getItem(key: StoreKey) {
    return localStorage.getItem(`${KEY_PREFIX}${key}`)
  }
}

export const ConfigStore = new NetworkSiimulatorLinkStorage()
