import React, { useState, useEffect } from 'react'
import { Button, List, Typo } from 'components'
import { ModelGroup, useAdminModelGroupListQuery, useUpdateModelsMutation } from 'gql'
import { DetailWindow } from 'components/DetailWindow'
import * as L from 'layouts'
import { ModelGroupReleaseYearSelect, ModelReleaseYear } from '../../ModelGroupReleaseYearSelect'

interface AssignModelGroupDetailProps {
  brandId: string
  modelId: string
  onClose: () => void
}

export const AssignModelGroupDetail = (props: AssignModelGroupDetailProps) => {
  const groupsQuery = useAdminModelGroupListQuery({ variables: { brandId: props.brandId } })
  const [selectedGroup, setSelectedGroup] = useState<
    Pick<ModelGroup, 'id' | 'name'> & { releaseYears: { release_year: number }[] }
  >()
  const [rlsYear, setRlsYear] = useState<number | null>(ModelReleaseYear.UNKNOWN)
  useEffect(() => {
    setRlsYear(ModelReleaseYear.UNKNOWN) // reset on group change
  }, [selectedGroup])
  const valid = !!rlsYear && !!selectedGroup
  const [update, updateResponse] = useUpdateModelsMutation()
  const handleSave = async () => {
    if (valid) {
      await update({
        variables: {
          set: { model_group_id: selectedGroup.id, model_group_release_year: rlsYear },
          where: { id: { _eq: props.modelId } },
        },
      })
      props.onClose()
    }
  }

  const loading = groupsQuery.loading || updateResponse.loading
  return (
    <DetailWindow loading={loading} onClose={props.onClose} title={'Gruppe zuweisen'} width={600}>
      {!loading && (
        <L.Vertical style={{ alignItems: 'flex-start' }}>
          <Typo variant={'h4'}>Gruppen</Typo>
          <List style={{ alignSelf: 'stretch' }}>
            {groupsQuery.data.model_groups.map((group) => (
              <List.Item
                key={group.id}
                onClick={() => setSelectedGroup(group)}
                selected={selectedGroup?.id === group.id}
              >
                {group.name}
              </List.Item>
            ))}
          </List>
          <ModelGroupReleaseYearSelect
            group={selectedGroup}
            style={{ minWidth: 200 }}
            value={rlsYear}
            onSelect={(year) => setRlsYear(year)}
          ></ModelGroupReleaseYearSelect>
          <Button variant={'contained'} color={'primary'} disabled={!valid} onClick={handleSave}>
            Speichern
          </Button>
        </L.Vertical>
      )}
    </DetailWindow>
  )
}
