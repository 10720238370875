import React, { CSSProperties, useCallback, useMemo, useState } from 'react'
import * as L from 'layouts'
import { Chip, Icon, IconButton, Menu, SiteMetadata, Table, Typo } from 'components'
import {
  Task_Category_Enum,
  TaskTypesQuery,
  useAddTaskTypeCategoryMutation,
  useRemoveTaskTypeCategoryMutation,
  useTaskTypesQuery,
} from 'gql'
import { TextField } from '@mui/material'
import { useDebounce } from 'hooks'
import { useTranslation } from 'react-i18next'

export const AdminTaskTypePage = () => {
  const [queryText, setQueryText] = useState('')
  const { taskTypes, pageInfo, nextPage, prevPage } = useTaskTypes(queryText)
  const [selectedItems, setSelectedItems] = useState<typeof taskTypes>([])

  return (
    <L.Vertical>
      <SiteMetadata title="Arbeitsnummern" />
      <L.Horizontal>
        <Typo variant="h4">{'Suche: '}</Typo>
        <TextField value={queryText} onChange={(evt) => setQueryText(evt.currentTarget.value)} style={{ width: 260 }} />
        <div style={{ flexGrow: 1 }} />
        {pageInfo && (
          <div>
            <IconButton
              aria-label="back"
              disabled={!pageInfo.hasPreviousPage}
              onClick={() => {
                prevPage()
              }}
              size="large"
            >
              <Icon name="before" />
            </IconButton>
            <IconButton
              aria-label="next"
              disabled={!pageInfo.hasNextPage}
              onClick={() => {
                nextPage()
              }}
              size="large"
            >
              <Icon name="next" />
            </IconButton>
          </div>
        )}
      </L.Horizontal>

      <Table data={taskTypes} keyField="code">
        <Table.Header>
          {selectedItems.length === 0 ? (
            <Typo variant="h5">Arbeitsnummern</Typo>
          ) : (
            <L.Horizontal>
              <Typo variant="body1">{selectedItems.length} Arbeitsnummern ausgewählt</Typo>
              <div style={{ flexGrow: 1 }}></div>
              <AddCategoryMenu
                style={{ position: 'absolute', right: 4 }}
                selectedCodes={selectedItems.map((item) => item.code)}
                onComplete={() => setSelectedItems([])}
              />
            </L.Horizontal>
          )}
        </Table.Header>
        <Table.RowSelect selectedItems={selectedItems} onChange={(items) => setSelectedItems(items)} />
        <Table.Column field="code" title="Arbeitsnummer" />
        <Table.Column field="group" title="Gruppe" />
        <Table.Column field="description" title="Beschreibung" />
        <Table.Column title="Kategorie" render={CategoryCell} />
      </Table>
    </L.Vertical>
  )
}

interface ActionMenuProps {
  style?: CSSProperties
  selectedCodes: string[]
  onComplete: () => void
}

const AddCategoryMenu = (props: ActionMenuProps) => {
  const { t } = useTranslation()
  const [add] = useAddTaskTypeCategoryMutation()

  const handleAddCategory = async (category: Task_Category_Enum) => {
    await add({
      variables: {
        taskTypeCategoryRefObj: props.selectedCodes?.map((code) => ({
          task_type_code: code,
          task_category_value: category,
        })),
      },
    })
    props.onComplete()
  }

  const [remove] = useRemoveTaskTypeCategoryMutation()
  const handleRemoveCategory = async (category: Task_Category_Enum) => {
    await remove({
      variables: {
        category,
        codes: props.selectedCodes,
      },
    })
    props.onComplete()
  }

  return (
    <L.Horizontal spacing={0} style={props.style}>
      <Menu>
        <Menu.IconButton aria-label="actions">
          <Icon name="add" />
        </Menu.IconButton>
        <Menu.ItemList>
          <Menu.Item onClick={() => handleAddCategory(Task_Category_Enum.InspectionSticker)}>
            {t(`TaskCategory.${Task_Category_Enum.InspectionSticker}`)}
          </Menu.Item>
          <Menu.Item onClick={() => handleAddCategory(Task_Category_Enum.Service)}>
            {t(`TaskCategory.${Task_Category_Enum.Service}`)}
          </Menu.Item>
        </Menu.ItemList>
      </Menu>
      <Menu>
        <Menu.IconButton aria-label="actions">
          <Icon name="remove" />
        </Menu.IconButton>
        <Menu.ItemList>
          <Menu.Item onClick={() => handleRemoveCategory(Task_Category_Enum.InspectionSticker)}>
            {t(`TaskCategory.${Task_Category_Enum.InspectionSticker}`)}
          </Menu.Item>
          <Menu.Item onClick={() => handleRemoveCategory(Task_Category_Enum.Service)}>
            {t(`TaskCategory.${Task_Category_Enum.Service}`)}
          </Menu.Item>
        </Menu.ItemList>
      </Menu>
    </L.Horizontal>
  )
}

const CategoryCell: React.FC<{ item: ArrayElement<TaskTypesQuery['task_types']> }> = (props) => {
  const { t } = useTranslation()
  return (
    <>
      {(props.item.categoryReferences ?? []).map((category) => (
        <Chip color="default" key={category.task_category_value}>
          {t(`TaskCategory.${category.task_category_value}`)}
        </Chip>
      ))}
    </>
  )
}

const PAGE_SIZE = 100
const useTaskTypes = (queryText: string) => {
  const [pageIndex, setPageIndex] = useState(0)
  const offset = pageIndex * PAGE_SIZE
  const query = useDebounce(queryText)

  const response = useTaskTypesQuery({
    variables: {
      limit: PAGE_SIZE,
      offset,
      where: {
        _or: [
          {
            code: {
              _ilike: `%${query}%`,
            },
          },
          {
            description: {
              _ilike: `%${query}%`,
            },
          },
          {
            group: {
              _ilike: `%${query}%`,
            },
          },
        ],
      },
    },
  })
  const taskTypes = response.data?.task_types ?? []
  const loading = response.loading
  const totalCount = response.data?.task_types_aggregate?.aggregate?.count
  const pageInfo = useMemo(() => {
    return {
      hasPreviousPage: pageIndex > 0,
      hasNextPage: totalCount > offset + PAGE_SIZE,
    }
  }, [offset, pageIndex, totalCount])
  const maxPageIndex = Math.ceil(totalCount / PAGE_SIZE) - 1
  const nextPage = useCallback(() => {
    setPageIndex((prev) => Math.min(maxPageIndex, prev + 1))
  }, [maxPageIndex])
  const prevPage = useCallback(() => {
    setPageIndex((prev) => Math.max(0, prev - 1))
  }, [])

  return { taskTypes, loading, pageInfo, refetch: response.refetch, nextPage, prevPage }
}
