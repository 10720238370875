import { Label } from 'components'
import React, { CSSProperties } from 'react'

export interface ValueTextProps {
  label?: string
  required?: boolean
  className?: string
  style?: CSSProperties
}

/**
 * DEPRECATED, ValueText Box
 * @param props 
 * @returns 
 * @deprecated Use DataBlock with DataValue instead
 */
export const ValueText: React.FC<ValueTextProps> = (props) => {
  return (
    <div className={props.className} style={props.style}>
      {props.label && <Label required={props.required} className="font-normal">{props.label}</Label>}
      <div className="font-medium leading-0.8">{props.children}</div>
    </div>
  )
}
