import React, { CSSProperties } from 'react'
import MuiToolTip from '@mui/material/Tooltip'
import clsx from 'clsx'
interface TooltipProps {
  content: React.ReactNode
  disabled?: boolean
  className?: string
  style?: CSSProperties
  children?: React.ReactNode
}

export const Tooltip = (props: TooltipProps) => {
  const { content, disabled, ...rest } = props

  const title = (
    <div className={clsx(props.className)} style={{ fontSize: '0.8571428571428571rem' }}>
      {content}
    </div>
  )

  return (
    <MuiToolTip title={title} disableHoverListener={disabled} {...rest}>
      <div>{props.children}</div>
    </MuiToolTip>
  )
}

export default Tooltip
