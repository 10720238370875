import React, { CSSProperties } from 'react'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContentText from '@mui/material/DialogContentText'

interface DialogProps {
  maxWidth?: MuiDialogProps['maxWidth']
  onClose: (event?: React.SyntheticEvent<any, Event>) => void
  onClick?: React.MouseEventHandler
  className?: string
  style?: CSSProperties
}

interface DialogComponent extends React.FC<DialogProps> {
  Title: React.FC
  Content: React.FC<{ style?: CSSProperties}>
  Text: React.FC
  Actions: React.FC
}

export const Dialog: DialogComponent = (props) => {
  return <MuiDialog open {...props} />
}

Dialog.Title = MuiDialogTitle
Dialog.Content = MuiDialogContent
Dialog.Text = MuiDialogContentText
Dialog.Actions = MuiDialogActions
