import React from 'react'
import { ListConfig } from '../../index'
import { useTicketListConfigs } from './list-configs'

export enum TicketsListType {
  ASSIGNED_ME = 'ASSIGNED_ME',
  VEHICLE = 'VEHICLE',
}

interface TicketsListConfigProps {
  type: TicketsListType
}

export const TicketsListConfig: React.FC<TicketsListConfigProps> = (props) => {
  const ticketListConfigs = useTicketListConfigs()
  return (
    <ListConfig type={props.type} {...ticketListConfigs[props.type]}>
      {props.children}
    </ListConfig>
  )
}
