import {useContext} from "react";
import {ListConfigContext} from "../../index";
import {Vehicle_Bool_Exp} from "../../../../gql";

export const useSearch = () => {
    const { setFilter } = useContext(ListConfigContext)

    const set = (queryText: string) => {
        setFilter((prev) => {
            const filter: Vehicle_Bool_Exp = prev ? JSON.parse(prev) : {}
            const boolExpArray = filter._and ?? []
            const boolExpArrayWoSearchQuery = boolExpArray.filter((exp) => !exp._or?.some((nestedExp) => nestedExp.vin))
            const searchQueryExp = queryText ? {
                _or: [{ vin: { _ilike: `%${queryText}%` } }, { job_number: { _ilike: `%${queryText}%` } }],
            } : undefined
            searchQueryExp && boolExpArrayWoSearchQuery.push(searchQueryExp)
            return JSON.stringify({ _and: boolExpArrayWoSearchQuery })
        })
    }

    return { set }
}

