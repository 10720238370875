import Popover from '@mui/material/Popover'
import { Typo } from 'components'
import React from 'react'
import ReportIcon from '@mui/icons-material/Report'

export type ErrorBlockProps = {
  errors: string[]
}

export const ErrorBlock = (props: ErrorBlockProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  if (!props.errors || props.errors.length === 0) {
    return null
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return (
    <>
      <div className="inline-block" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        <ReportIcon fontSize="large" color="error" />
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>
          <div className="text-white bg-error-main py-4 px-6">
            <Typo variant="h5">Validierungsfehler</Typo>
          </div>
          <ul className="px-6 pl-10 py-4 flex flex-col gap-1 list-disc">
            {props.errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </div>
      </Popover>
    </>
  )
}
