import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import {
  DepartmentsListSubscriptionResult,
  useDeleteDepartmentMutation,
  useDepartmentsListSubscription,
} from '../../gql'
import { Yalc2, Yalc2RowDefinition } from '../../components/Yalc2'
import { Yalc2Cell } from '../../components/Yalc2/cells'
import { Chip, Icon, IconButton, Menu } from '../../components'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'

type Departments = DepartmentsListSubscriptionResult['data']['departments']
type Department = ArrayElement<Departments>

export const DepartmentsList = () => {
  const departmentsSubscription = useDepartmentsListSubscription()
  const departments = useMemo(() => departmentsSubscription.data?.departments ?? [], [
    departmentsSubscription.data?.departments,
  ])

  const {openDetailView} = useDetailView()

  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const rowDefinition = useRowDefinition()
  const resetSelection = () => setSelectedIds([])

  useEffect(() => {
    if (selectedIds.length === 1) {
      openDetailView(DetailViewEnum.DepartmentDetail, {
        id: selectedIds[0],
        onSave: resetSelection,
        onClose: resetSelection,
      })
    }
  }, [openDetailView, selectedIds])

  return (
    <>
      <Yalc2
        classes={{ item: 'group' }}
        data={departments}
        rowDefinition={rowDefinition}
        selectedIds={selectedIds}
        onSelectionChanged={setSelectedIds}
        options={<Menu.Item onClick={() => openDetailView(DetailViewEnum.DepartmentDetail)}>Neuer Bereich</Menu.Item>}
      />
    </>
  )
}

const useRowDefinition = (): Yalc2RowDefinition<Department> => {
  const [deleteDepartment] = useDeleteDepartmentMutation()
  return useMemo(
    () => ({
      columns: [
        {
          title: 'Name',
          width: 100,
          cell: function NameCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.name}</Yalc2Cell.SimpleCell>
          },
        },
        {
          title: 'Beschreibung',
          width: 200,
          cell: function DescriptionCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.description}</Yalc2Cell.SimpleCell>
          },
        },
        {
          title: 'Verfügbare Rollen',
          width: 400,
          cell: function RolesCell(props) {
            const { t } = useTranslation()
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.roles.map((role) => (
                  <Chip className={'mr-2'} key={`role-${role.role}-department-${props.item.id}`}>
                    {t(`UserRole.${role.role}`)}
                  </Chip>
                ))}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          title: '',
          width: 50,
          cell: function QuickActionsCell(props) {
            const handleDelete: MouseEventHandler<HTMLButtonElement> = async (evt) => {
              evt.stopPropagation()
              await deleteDepartment({ variables: { id: props.item.id } })
            }
            return (
              <Yalc2Cell.SimpleCell className={clsx('hidden items-end group-hover:flex')}>
                <IconButton aria-label={'delete-department'} onClick={handleDelete}>
                  <Icon name={'delete'} />
                </IconButton>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
      ],
    }),
    [deleteDepartment]
  )
}
