import faker from 'faker/locale/de'
import { Brand, Location, LocationGroup } from 'gql'
import { randomList, randomArrayItems } from 'mocks/common'
import { BrandStore } from 'mocks/brands'

const createLocation = (
  brands?: Pick<Brand, 'id' | 'name'>[]
): Pick<Location, 'id' | 'name'> & { locationGroups: any } => {
  let locationGroups = []
  if (brands && brands.length) {
    locationGroups = randomList(1, 4, () => createLocationGroup(brands))
  }
  return {
    id: faker.random.uuid(),
    name: faker.company.companyName(),
    locationGroups,
  }
}

const createLocationGroup = (
  brands: Pick<Brand, 'id' | 'name'>[]
): Pick<LocationGroup, 'id' | 'name'> & { brands: any } => {
  const randomBrands = randomArrayItems(brands, 1, 3)
  return {
    id: faker.random.uuid(),
    name: faker.company.catchPhraseNoun(),
    brands: randomBrands as any,
  }
}

const defaultLocations = randomList(7, 12, () => createLocation(BrandStore.brands))

export class LocationStore {
  public static locations = defaultLocations
}

export const mocks = {}
export const resolvers = {}
