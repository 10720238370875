import React, { useCallback, useContext, useMemo } from 'react'
import { DataSource } from '../../../components/DataValue'
import { VehicleDetailContext, VehicleFormData } from '../index'
import { useBrandBoniTypesQuery, useInsertPricesAndBoniMutation } from '../../../gql'
import { TextField } from 'components/TextField'
import { useFormFields } from '@w2solutions/react-hooks'
import { useFormatter } from '../../../hooks'

export const useVehiclePricesFormular = () => {
  const { data } = useContext(VehicleDetailContext)
  const [insert] = useInsertPricesAndBoniMutation()
  const brandBoniTypes = useBrandBoniTypesQuery({ variables: { brand_id: data.brand.id } })
  const types = useMemo(
    () => brandBoniTypes.data?.brand_boni_types?.map((brandBonyType) => brandBonyType.type) ?? [],
    [brandBoniTypes.data?.brand_boni_types]
  )

  const { formatNumber } = useFormatter()

  const [{ bindings, values, setValue }, initialValues] = useValues(data, types)

  const boniFields = types.map((bonusType) => ({
    source: DataSource.PCORE,
    name: bonusType,
    label: bonusType,
    placeholder:
      formatNumber(data?.boni?.find((bonus) => bonus?.type === bonusType)?.value, {
        style: 'currency',
        currency: 'EUR',
      }) ?? '-',
    FormComponent: (
      <TextField
        min={0}
        type={'number'}
        {...bindings?.[bonusType]}
        onChange={({ currentTarget }) => setValue(bonusType, currentTarget.value ? Number(currentTarget.value) : null)}
      />
    ),
  }))

  const onSubmit = useCallback(async () => {
    const insertBase = {
      vehicle_id: data.id,
      vehicle_version: data.version,
      vehicle_business_case_number: data.business_case_number,
    }

    const { list_price, cash_price, ...rest } = values

    const boni = Object.entries(rest)
      .map(([type, value]) => (value !== initialValues?.[type] ? { ...insertBase, value, type } : undefined))
      .filter((bonus) => !!bonus)

    await insert({
      variables: {
        id: insertBase.vehicle_id,
        business_case_number: insertBase.vehicle_business_case_number,
        list_price: values.list_price,
        cash_price: values.cash_price,
        boni,
      },
    })
  }, [initialValues, data?.business_case_number, data?.id, data?.version, insert, values])
  return useMemo(
    () => ({
      sections: [
        {
          name: 'prices',
          label: 'Preise',
          column: 1,
          fields: [
            {
              source: DataSource.PCORE,
              name: 'list_price',
              label: 'Listenpreis',
              placeholder: formatNumber(data.list_price, { style: 'currency', currency: 'EUR' }),
              FormComponent: (
                <TextField
                  min={0}
                  type={'number'}
                  {...bindings?.list_price}
                  onChange={({ currentTarget }) =>
                    setValue('list_price', currentTarget.value ? Number(currentTarget.value) : null)
                  }
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'cash_price',
              label: 'Barpreis',
              placeholder: formatNumber(data?.cash_price, { style: 'currency', currency: 'EUR' }) ?? '-',
              FormComponent: (
                <TextField
                  min={0}
                  type={'number'}
                  {...bindings?.cash_price}
                  onChange={({ currentTarget }) =>
                    setValue('cash_price', currentTarget.value ? Number(currentTarget.value) : null)
                  }
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'advertising_price',
              label: 'Werbepreis',
              disabled: true,
              placeholder:
                formatNumber(data?.advertising_price, {
                  style: 'currency',
                  currency: 'EUR',
                }) ?? '-',
              FormComponent: <TextField disabled value={data?.advertising_price} />,
            },
          ],
        },
        {
          name: 'boni',
          label: 'Boni',
          column: 2,
          fields: boniFields,
        },
      ],
      onSubmit,
    }),
    [
      data?.list_price,
      data?.cash_price,
      data?.advertising_price,
      bindings?.list_price,
      bindings?.cash_price,
      formatNumber,
      boniFields,
      onSubmit,
      setValue,
    ]
  )
}

interface FormValues {
  list_price?: number
  cash_price?: number
  [key: string]: number
}

const useValues = (data: VehicleFormData, types: string[]) => {
  const initialValues: FormValues = useMemo(() => {
    return types.reduce(
      (prev, curr) => {
        prev[curr] = data?.boni?.find((bonus) => bonus.type === curr)?.value ?? (null as number)
        return prev
      },
      { list_price: data?.list_price ?? (null as number), cash_price: data?.cash_price ?? (null as number) } as any
    )
  }, [data?.boni, data?.cash_price, data?.list_price, types])
  return [useFormFields(initialValues, { resetOnInitialValueChange: true }), initialValues] as const
}
