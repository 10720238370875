import { DetailWindow } from 'components/DetailWindow'
import { Spinner } from 'components/Spinner'
import {
  AfterSalesPotential,
  Customer,
  PhoneNumber,
  After_Sales_Status_Enum,
  Contact_Note_Type_Enum,
  Phone_Number_Type_Enum,
  useAddAfterSalesPotentialNoteMutation,
  CustomerVehicle,
  Brand,
  Model,
  useAfterSalesPotentialDetailsSubscription,
  Vehicle,
  Notes,
} from 'gql'
import React, { useCallback, useState } from 'react'
import * as L from 'layouts'
import { AfterSalesStatusChip } from 'containers/AfterSalesStatusChip'
import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Typo } from 'components/Typo'
import { ValueText } from 'components/ValueText'
import { useFormatter } from 'hooks/useFormatter'
import { Label } from 'components/Label'
import { List } from 'components/List'
import { PhoneNoLink } from 'components/PhoneNoLink'
import { useFormFields } from '@w2solutions/react-hooks'
import { Dialog } from 'components/Dialog'
import { TextField } from 'components/TextField'
import { NoDataText } from 'components/NoDataText'
import { useAlert } from 'components/Alert'
import styled from './AfterSalesPotentialDetail.module.css'
import { ClickOutsideListener } from 'components/ClickOutsideListener'
import gql from 'graphql-tag'

type Potential = Pick<
  AfterSalesPotential,
  | 'id'
  | 'first_registration_date'
  | 'warranty_start'
  | 'warranty_end'
  | 'mileage'
  | 'mileage_date'
  | 'license_plate_number'
  | 'status'
> & {
  customer: Pick<Customer, 'id' | 'customer_no' | 'name' | 'salutation' | 'zip_code' | 'city'> & {
    phone_numbers?: Pick<PhoneNumber, 'number' | 'type' | 'description'>[]
    notes?: Pick<Notes, 'id' | 'created_at' | 'note'>[]
  }
  customer_vehicle: Pick<CustomerVehicle, 'id' | 'vin' | 'model_description'> & {
    brand: Pick<Brand, 'id' | 'name'>
    model?: Pick<Model, 'id' | 'description'>
  }
  vehicle: Pick<Vehicle, 'id' | 'business_case_number' | 'version' | 'vin' | 'model_name'> & {
    brand: Pick<Brand, 'id' | 'name'>
  }
}

interface AfterSalesPotentialDetailProps {
  potentialId: string
  onClose: () => void
  onAlreadyDone: (id: string) => void
  onNotRequired: (id: string) => void
  onClickOutside?: (evt: React.MouseEvent) => void
}

export const AfterSalesPotentialDetail = React.forwardRef<HTMLDivElement, AfterSalesPotentialDetailProps>(
  (props: AfterSalesPotentialDetailProps, ref) => {
    const query = useAfterSalesPotentialDetailsSubscription({
      variables: { id: props.potentialId },
    })

    const potential = query.data?.after_sales_potential
    if (!potential) {
      return (
        <ClickOutsideListener onClickOutside={props.onClickOutside}>
          <DetailWindow ref={ref} onClose={props.onClose} width={620} variant={'persistent'}>
            <div style={{ margin: '80px auto' }}>
              <Spinner />
            </div>
          </DetailWindow>
        </ClickOutsideListener>
      )
    }
    return (
      <ClickOutsideListener onClickOutside={props.onClickOutside}>
        <DetailWindow ref={ref} onClose={props.onClose} width={620} variant={'persistent'}>
          <L.Vertical spacing={5} className="p-8">
            <StatusActions
              status={potential.status}
              potentialId={potential.id}
              onNotRequired={props.onNotRequired}
              onAlreadyDone={props.onAlreadyDone}
            />
            <VehicleDetails potential={potential as Potential} />
            <CustomerDetails
              customer={potential?.customer}
              potentialId={potential?.id}
              vehicleId={potential?.vehicle?.id}
              customerVehicleId={potential?.customer_vehicle?.id}
              notes={potential?.customer?.notes ?? []}
            />
          </L.Vertical>
        </DetailWindow>
      </ClickOutsideListener>
    )
  }
)

// #######################
// #    StatusActions    #
// #######################

interface StatusActionsProps {
  status: After_Sales_Status_Enum
  onNotRequired: (id: string) => void
  onAlreadyDone: (id: string) => void
  potentialId: string
}

const StatusActions = (props: StatusActionsProps) => {
  const done =
    props.status === After_Sales_Status_Enum.AlreadyDone ||
    props.status === After_Sales_Status_Enum.NotRequired ||
    props.status === After_Sales_Status_Enum.Done
  return (
    <L.Horizontal style={{ marginBottom: 12 }} spacing={1}>
      <AfterSalesStatusChip status={props.status} />
      <div style={{ flexGrow: 1 }}></div>
      {!done && (
        <>
          <Button onClick={() => props.onNotRequired(props.potentialId)}>
            <L.Horizontal spacing={1}>
              <Icon name="schedule" />
              <span>Nicht fällig</span>
            </L.Horizontal>
          </Button>
          <Button onClick={() => props.onAlreadyDone(props.potentialId)}>
            <L.Horizontal spacing={1}>
              <Icon name="check" />
              <span>Bereits erledigt</span>
            </L.Horizontal>
          </Button>
        </>
      )}
    </L.Horizontal>
  )
}

// #######################
// #    VehicleDetails   #
// #######################

interface VehicleDetailsProps {
  potential: Potential
}

export const VehicleDetails = (props: VehicleDetailsProps) => {
  const { potential } = props
  const { formatDateString, formatNumber } = useFormatter()
  return (
    <L.Vertical spacing={3}>
      <div>
        <Typo variant="h1" component="h1" style={{ fontWeight: 500 }}>
          {potential.vehicle?.model_name ||
            potential.customer_vehicle?.model_description ||
            potential.customer_vehicle?.model?.description}
        </Typo>
        <Typo variant="h3" style={{ lineHeight: 0.7 }}>
          {potential.vehicle?.brand?.name || potential.customer_vehicle?.brand?.name}
        </Typo>
      </div>
      <L.Grid>
        <ValueText label="FIN">{potential.vehicle?.vin || potential.customer_vehicle?.vin}</ValueText>
        <ValueText label="Kennzeichen">{potential.license_plate_number}</ValueText>
        <ValueText label="Erstzulassung">{formatDateString(potential.first_registration_date)}</ValueText>
        <ValueText label="Garantie">
          {formatDateString(potential.warranty_start)} - {formatDateString(potential.warranty_end)}
        </ValueText>
        <ValueText label="KM">{formatNumber(potential.mileage)} km</ValueText>
        <ValueText label="KM - Ablesedatum">{formatDateString(potential.mileage_date)}</ValueText>
      </L.Grid>
    </L.Vertical>
  )
}

// #######################
// #    CustomerDetails  #
// #######################

interface CustomerDetailProps {
  potentialId: string
  vehicleId?: string
  customerVehicleId?: string
  customer: Potential['customer']
  notes: Potential['customer']['notes']
}

const CustomerDetails = (props: CustomerDetailProps) => {
  const { customer, notes } = props
  const { formatDateString } = useFormatter()

  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false)

  return (
    <L.Vertical spacing={3}>
      <div>
        <Label style={{ fontSize: '1rem' }}>{customer.salutation}</Label>
        <Typo variant="h1" component="h1" style={{ fontWeight: 500, lineHeight: 0.9 }}>
          {customer.name}
        </Typo>
        <Typo variant="h5" style={{ lineHeight: 0.9 }}>
          {customer.customer_no}
        </Typo>
      </div>
      <L.Grid>
        <ValueText label="Adresse" style={{ gridColumn: '1/3' }}>
          {customer.zip_code} {customer.city}
        </ValueText>
        <ValueText label="Telefon Nummern" style={{ gridColumn: '1/3' }}>
          {customer.phone_numbers && customer.phone_numbers.length ? (
            <List>
              {customer.phone_numbers.map((phone, idx) => (
                <List.Item className={styled.phoneNoListItem} key={`${idx}`}>
                  <Icon name={phone.type === Phone_Number_Type_Enum.Landline ? 'phone' : 'mobilePhone'} />
                  <div>
                    <PhoneNoLink className="block" phoneNo={phone.number}>
                      {phone.number}
                    </PhoneNoLink>
                    {phone.description && (
                      <Label className="typo-overline leading-none text-grey-700 block mt-1.5 mx-0 mb-0">
                        {phone.description}
                      </Label>
                    )}
                  </div>

                  <PhoneNoActions
                    customerId={customer.id}
                    potentialId={props.potentialId}
                    vehicleId={props.vehicleId}
                    customerVehicleId={props.customerVehicleId}
                    phoneNo={phone.number}
                    className={styled.phoneNoActions}
                  />
                </List.Item>
              ))}
            </List>
          ) : (
            <span>-</span>
          )}
        </ValueText>
        <ValueText label="Kontakt Notizen" style={{ gridColumn: '1/3' }}>
          <L.Vertical>
            {(notes ?? []).map((note) => (
              <L.Horizontal key={note.id}>
                <div style={{ width: 85 }}>{formatDateString(note.created_at)}</div>
                <div style={{ gridColumn: '2/5' }}>{note.note}</div>
              </L.Horizontal>
            ))}
            {!notes?.length && (
              <NoDataText size="small" style={{ marginTop: 16, alignSelf: 'flex-start' }}>
                Keine Notizen vorhanden.
              </NoDataText>
            )}
            <Button
              style={{ alignSelf: 'flex-start' }}
              onClick={() => {
                setShowAddNoteDialog(true)
              }}
            >
              <L.Horizontal spacing={1}>
                <Icon name="comment" />
                <span>{'Notiz hinzufügen'}</span>
              </L.Horizontal>
            </Button>
            {showAddNoteDialog && (
              <AddNoteDialog
                type={Contact_Note_Type_Enum.Generic}
                customerId={customer.id}
                vehicleId={props.vehicleId}
                customerVehicleId={props.customerVehicleId}
                potentialId={props.potentialId}
                onClose={() => setShowAddNoteDialog(false)}
              />
            )}
          </L.Vertical>
        </ValueText>
      </L.Grid>
    </L.Vertical>
  )
}

// #######################
// #    PhoneNoActions   #
// #######################

interface PhoneNoActionsProps {
  phoneNo: string
  customerId: string
  potentialId: string
  vehicleId?: string
  customerVehicleId?: string
  className?: string
}

const PhoneNoActions = (props: PhoneNoActionsProps) => {
  const [type, setType] = useState<Contact_Note_Type_Enum | null>(null)

  const handleCloseDialog = () => {
    setType(null)
  }

  const handleClickNotReached = () => {
    setType(Contact_Note_Type_Enum.CustomerNotReached)
  }

  const handleClickWillCallback = () => {
    setType(Contact_Note_Type_Enum.CustomerWillCallBack)
  }

  return (
    <>
      <L.Horizontal className={props.className} spacing={1}>
        <Button color="secondary" onClick={handleClickNotReached}>
          Nicht erreicht
        </Button>
        <Button color="secondary" onClick={handleClickWillCallback}>
          Kunde ruft zurück
        </Button>
      </L.Horizontal>
      {type && (
        <AddNoteDialog
          type={type}
          customerId={props.customerId}
          potentialId={props.potentialId}
          vehicleId={props.vehicleId}
          customerVehicleId={props.customerVehicleId}
          defaultNote={
            type === Contact_Note_Type_Enum.CustomerNotReached
              ? `Unter der Nummer ${props.phoneNo} nicht erreicht.`
              : 'Kunde ruft zurück'
          }
          onClose={handleCloseDialog}
        />
      )}
    </>
  )
}

// #######################
// #    AddNoteDialog    #
// #######################

interface AddNoteDialogProps {
  type: Contact_Note_Type_Enum
  customerId: string
  potentialId: string
  defaultNote?: string
  vehicleId?: string
  customerVehicleId?: string
  onClose: () => void
}

const AddNoteDialog = (props: AddNoteDialogProps) => {
  const { bindings, values } = useFormFields({ note: props.defaultNote ?? '' })

  const [addNote] = useAddCustomNote({
    customerId: props.customerId,
    potentialId: props.potentialId,
    vehicleId: props.vehicleId,
    customerVehicleId: props.customerVehicleId,
  })
  const handleAddNote = async () => {
    await addNote(values.note, props.type)
    props.onClose()
  }

  let title = 'Kunden Notiz hinzufügen'
  if (props.type === Contact_Note_Type_Enum.CustomerNotReached) {
    title = 'Kunde nicht erreicht'
  }
  if (props.type === Contact_Note_Type_Enum.CustomerWillCallBack) {
    title = 'Kunde ruft zurück'
  }

  return (
    <Dialog
      onClose={props.onClose}
      onClick={(evt) => {
        evt.stopPropagation()
      }}
    >
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Content>
        <TextField {...bindings.note} label="Notiz" style={{ minWidth: 360 }} />
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={props.onClose}>Abbrechen</Button>
        <Button onClick={handleAddNote} color="primary">
          Speichern
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}

// #######################
// #    useAddCustomNote #
// #######################

interface AddNoteOptions {
  customerId: string
  potentialId: string
  customerVehicleId?: string
  vehicleId?: string
}

const useAddCustomNote = (options: AddNoteOptions) => {
  const [addNote] = useAddAfterSalesPotentialNoteMutation({
    update: (cache, { data }) => {
      if (!data?.insert_note) {
        return
      }
      cache.modify({
        fields: {
          after_sales_potentials() {
            cache.updateFragment(
              {
                id: options.potentialId,
                fragment: gql`
                  fragment AfterSalesPotentialLastNote on AfterSalesPotential {
                    last_note
                  }
                `,
              },
              () => ({
                last_note: data.insert_note.note,
              })
            )
          },
        },
      })
    },
  })
  const [alert] = useAlert()

  const handleAddNote = useCallback(
    async (note: string, type: Contact_Note_Type_Enum) => {
      if (!type) {
        throw new Error('no type set')
      }
      const res = await addNote({
        variables: {
          type,
          note,
          ...options,
          businessCaseNumber: options.vehicleId ? -1 : undefined,
          version: options.vehicleId ? 0 : undefined,
        },
        // TODO: improve performance by impl optimistic update fn
      })
      if (res.errors) {
        alert.error('Fehler beim speichern der Notiz', res.errors[0])
      }
    },
    [addNote, alert, options]
  )

  return [handleAddNote]
}
