import { useFormFields } from '@w2solutions/react-hooks'
import { Form, Select, TextField } from 'components'
import { DetailWindow } from 'components/DetailWindow'
import { FormRow } from 'components/FormRow'
import { useCreateStorageLocationMutation, useStorageLocationSelectDataQuery } from 'gql'
import { deualtJoiMessages, useJoi } from 'hooks/useJoi'
import Joi from 'joi'
import React, { CSSProperties, useCallback, useRef } from 'react'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'

export interface StorageLocationSelectProps {
  value: string
  onSelect: (id: string, sloc: SLocation) => void
  className?: string
  style?: CSSProperties
}

export const StorageLocationSelect = (props: StorageLocationSelectProps) => {
  const query = useStorageLocationSelectDataQuery({ fetchPolicy: 'network-only' })
  const newStorageLocation = useNewStorageLocation()

  const storageLocations = query.data?.storage_locations ?? []

  const options = storageLocations.map((sl) => ({ id: sl.id, name: sl.name }))
  options.push(
    ...(query.data?.locations ?? [])
      .filter((l) => !storageLocations.some((sl) => sl.location_id === l.id))
      .map((l) => ({ id: l.id, name: l.name }))
  )
  options.push(
    ...(query.data?.subdealers ?? [])
      .filter((sd) => !storageLocations.some((sl) => sl.subdealer_id === sd.id))
      .map((sd) => ({ id: sd.id, name: sd.name }))
  )

  const [create, createResponse] = useCreateStorageLocationMutation()
  const handleChange = async (evt) => {
    let id = evt.target.value
    let data = query.data
    const loc = (data.locations ?? []).find((l) => l.id === id)
    if (loc) {
      // location selected
      const res = await create({
        variables: {
          input: {
            name: loc.name,
            location_id: loc.id,
          },
        },
      })
      const { data: newData } = await query.refetch()
      data = newData
      id = res.data?.insert_storage_location?.id
    }
    const subdealer = (query.data.subdealers ?? []).find((sd) => sd.id === id)
    if (subdealer) {
      // subdealer selected
      const res = await create({
        variables: {
          input: {
            name: subdealer.name,
            subdealer_id: subdealer.id,
          },
        },
      })
      const { data: newData } = await query.refetch()
      data = newData
      id = res.data?.insert_storage_location?.id
    }
    if (id === 'NEW') {
      try {
        const location = await newStorageLocation()
        const { data: newData } = await query.refetch()
        data = newData
        id = location.id
      } catch {
        return
      }
    }
    const newLocation = data.storage_locations.find((sloc) => sloc.id === id)
    props.onSelect(id, newLocation)
  }

  options.sort((a, b) => (a.name < b.name ? -1 : 1))

  const loading = query.loading || createResponse.loading
  return (
    <>
      <Select
        loading={loading}
        renderSelectValueItem={(val) => (val ? getName(options, val) : val)}
        value={props.value}
        onChange={handleChange}
        className={props.className}
        style={props.style}
      >
        <Select.Option key={'NEW'} value="NEW">
          Neuen Lagerplatz anlegen...
        </Select.Option>
        {options.map((o) => (
          <Select.Option key={o.id} value={o.id}>
            {o.name}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

const getName = (values: { id: string; name: string }[], id) => {
  return values.find((v) => v.id === id)?.name
}

type SLocation = { id: string; name: string; default_note?: string }

const useNewStorageLocation = () => {
  const resolveRef = useRef<(data: SLocation) => void>(null)
  const rejectRef = useRef<() => void>(null)
  const {openNestedDetailView, closeDetailView} = useDetailView()
  return useCallback(async () => {
    const prom = new Promise<SLocation>((res, rej) => {
      resolveRef.current = res
      rejectRef.current = rej
    }).finally(() => closeDetailView(DetailViewEnum.NewStorageLocation))
    openNestedDetailView(DetailViewEnum.NewStorageLocation, {
      onAbort: () => rejectRef.current && rejectRef.current(),
      onSuccess: (sloc) => resolveRef.current && resolveRef.current(sloc),
    })
    return prom
  }, [closeDetailView, openNestedDetailView])
}

type CreateNewStorageLocationProps = {
  onSuccess?: (sloc: SLocation) => void
  onAbort?: () => void
}

export const CreateNewStorageLocation = (props: CreateNewStorageLocationProps) => {
  const { bindings, values } = useFormFields({ name: '', defaultNote: '' })
  const [create] = useCreateStorageLocationMutation()
  const { isValid, errors, validate } = useJoi(validationScheme, values)
  const handleSave = async () => {
    await validate()
    if (isValid) {
      const res = await create({ variables: { input: { name: values.name, default_note: values.defaultNote } } })
      props.onSuccess(res.data?.insert_storage_location)
    }
  }

  return (
    <DetailWindow
      variant="temporary"
      title="Neuen Lagerort erstellen"
      onClose={props.onAbort}
      styles={{ container: { padding: 0 } }}
    >
      <Form>
        <div className="p-6">
          <FormRow label="Name" error={errors.name}>
            <TextField {...bindings.name} />
          </FormRow>
          <FormRow label="Standard Notiz">
            <TextField {...bindings.defaultNote} />
          </FormRow>
        </div>
        <Form.Actions onCancel={props.onAbort} onSave={handleSave} />
      </Form>
    </DetailWindow>
  )
}

const validationScheme = Joi.object<{ name: string }>({
  name: Joi.string().required().label('Name'),
}).messages(deualtJoiMessages)
