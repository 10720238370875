import React, { useContext, useMemo } from 'react'
import * as L from 'layouts'
import { Select, Typo } from 'components'
import { ExcelImportContext, ExcelImportListItem } from '..'
import { useTranslation } from 'react-i18next'
import {
  Import_Job_Status_Enum,
  useAssignVehicleColumnToFieldMutation,
  useUnassignVehicleColumnFromFieldMutation,
} from '../../../gql'

interface ChooseAssignmentsProps {
  i18nPrefix: string
  dvKeys: (keyof ExcelImportListItem)[]
  assignColumnToField: ReturnType<typeof useAssignVehicleColumnToFieldMutation>
  unassignColumnFromField: ReturnType<typeof useUnassignVehicleColumnFromFieldMutation>
  entityName: string
}

export const ChooseAssignments = (props: ChooseAssignmentsProps) => {
  const { state, importJob } = useContext(ExcelImportContext)
  const { t } = useTranslation()

  const [assignColumnToField] = props.assignColumnToField
  const [unassignColumnFromField] = props.unassignColumnFromField

  const findKey = useMemo(() => {
    const assignments = importJob?.import_template?.assignments
    const parentAssignments = importJob?.import_template?.parent_template?.assignments

    return (col: string) =>
      assignments?.find((assignment) => assignment.header_cell === col)?.key ||
      parentAssignments?.find((assignment) => assignment.header_cell === col)?.key ||
      ''
  }, [importJob?.import_template?.assignments, importJob?.import_template?.parent_template?.assignments])

  const cols = useMemo(
    () => importJob?.upload_file_excel_definition?.filter((def) => def !== 'EMPTY'),
    [importJob?.upload_file_excel_definition]
  )

  return (
    <>
      <L.Horizontal className="ml-4">
        <Typo className="font-bold" style={{ width: 200 }}>
          Excel Spalte
        </Typo>
        <Typo className="font-bold">{`${props.entityName}feld`}</Typo>
      </L.Horizontal>
      {cols?.map((col, idx) => (
        <L.Horizontal className="ml-4" key={`field-assignment-row-${idx}`}>
          <Typo style={{ width: 200 }}>{`${col}:`}</Typo>
          <Select
            name={'columnsSelect'}
            value={findKey(col)}
            onChange={async (evt) => {
              const key = evt.target.value
              if (key) {
                await assignColumnToField({ variables: { templateId: state.importTemplateId, key, col } })
              } else {
                await unassignColumnFromField({ variables: { templateId: state.importTemplateId, col } })
              }
            }}
            style={{ width: 200 }}
            renderSelectValueItem={(val) => (val ? t(`${props.i18nPrefix}.${val}`) : '')}
            disabled={importJob?.status !== Import_Job_Status_Enum.InProgress}
          >
            <Select.Option value={undefined}>{'-'}</Select.Option>
            {props.dvKeys.map((dvKey) => (
              <Select.Option key={`option-${idx}-${dvKey}`} value={dvKey}>
                {t(`${props.i18nPrefix}.${dvKey}`)}
              </Select.Option>
            ))}
          </Select>
        </L.Horizontal>
      ))}
    </>
  )
}
