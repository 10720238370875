import React from 'react'
import Helmet from 'react-helmet'

export interface SiteMetadataProps {
  title?: string
}

export const SiteMetadata: React.FC<SiteMetadataProps> = (props) => {
  return (
    <Helmet titleTemplate="%s | TG Pulse">
      {props.title && <title>{props.title}</title>}
      {props.title && <meta name="custom:subtitle" content={props.title} />}
    </Helmet>
  )
}
