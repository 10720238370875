import clsx from 'clsx'
import React, { CSSProperties } from 'react'

export interface LabelProps {
  required?: boolean
  style?: CSSProperties
  className?: string
}

export const Label: React.FC<LabelProps> = (props) => {
  return (
    <>
      <label style={props.style} className={clsx('typo-subtitle2 font-normal ', props.className)}>
        {props.children}
      </label>
      {props.required && <sup className={'text-error-main'}>*</sup>}
    </>
  )
}
