import { Chip, ChipProps } from 'components/Chip'
import { Order_State_Enum } from 'gql'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

interface OrderStateChipProps {
  state: Order_State_Enum
  style?: CSSProperties
  className?: string
}

export const OrderStateChip = (props: OrderStateChipProps) => {
  const { t } = useTranslation()
  const color = useColor(props)
  if (!props.state) {
    return <>{'-'}</>
  }
  return <Chip color={color}>{t(`OrderState.${props.state}`)}</Chip>
}

const useColor = (props: OrderStateChipProps): ChipProps['color'] => {
  switch (props.state) {
    case Order_State_Enum.InTransport:
    case Order_State_Enum.InInternalTransport:
      return 'warning'
    case Order_State_Enum.InStock:
      return 'success'
    case Order_State_Enum.Handover:
      return 'danger'
  }
}
