import React from 'react'
import { Typography } from '@mui/material'
import { useFormFields, useAsync } from '@w2solutions/react-hooks'

import * as L from 'layouts'
import { TextField, Link, Button, Form, AlertBox } from 'components'
import { useEzAuth } from '@lib/ezauth'

export const LoginForm: React.FC = () => {
  const { bindings, values } = useFormFields({ username: '', password: '' })

  const [, authFn] = useEzAuth()
  const [signIn, { loading, error }] = useAsync(authFn.signIn)

  const handleSubmit = async () => {
    signIn(values.username, values.password)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <L.Vertical spacing={6}>
        <div>
          <Typography variant="h3">Log In</Typography>
          <Typography variant="subtitle1">Lorem ipsum dolor sit amet.</Typography>
        </div>
        {error && (
          <AlertBox type="error" noTitle style={{ width: '100%' }}>
            {'E-Mail oder Passwort ungültig'}
          </AlertBox>
        )}
        <L.Vertical spacing={2}>
          <TextField label="E-Mail" {...bindings.username} disabled={loading} />
          <TextField label="Passwort" type="password" {...bindings.password} disabled={loading} />
          <Button variant="contained" color="primary" type="submit" loading={loading}>
            Anmelden
          </Button>
          <Link to="/forgot-password">Passwort vergessen?</Link>
        </L.Vertical>
      </L.Vertical>
    </Form>
  )
}

export default LoginForm
