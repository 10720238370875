import {useContext} from "react";
import {ListConfigContext} from "../../index";
import {Vehicle_Bool_Exp} from "../../../../gql";

export const useBrandId = () => {
    const { filter: filterStr, setFilter } = useContext(ListConfigContext)
    const get = (): string | undefined => {
        const filter: Vehicle_Bool_Exp = filterStr ? JSON.parse(filterStr) : {}
        const filterExp = filter?._and?.find((exp) => exp.brand_id)
        return filterExp?.brand_id?._eq
    }

    const set = (brandId: string) => {
        setFilter((prev) => {
            const filter: Vehicle_Bool_Exp = prev ? JSON.parse(prev) : {}
            const boolExpArray = filter._and ?? []
            const boolExpArrayWoBrandId = boolExpArray.filter((exp) => !exp.brand_id)
            const brandIdExp = { brand_id: { _eq: brandId } }
            boolExpArrayWoBrandId.push(brandIdExp)
            return JSON.stringify({ _and: boolExpArrayWoBrandId })
        })
    }

    return { get, set }
}
