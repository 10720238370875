import React from 'react'
import { ListManipulation, useListManipilationContext } from '../../unit'

export const TicketDefaultOptions = () => {
  const { setField } = useListManipilationContext<'Ticket'>()
  return (
    <ListManipulation.UnitOptions>
      <ListManipulation.ItemOption onClick={() => setField('state')}>Status</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('priority')}>Priorität</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('team')}>Team</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('assignee_id')}>Zugewiesen zu</ListManipulation.ItemOption>
      <hr className={'text-divider'} />
      <ListManipulation.ItemOption onClick={() => setField('created_at')}>Erstellt am</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('updated_at')}>
        Zuletzt Aktualisiert am
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('due_to')}>Zu erledigen bis</ListManipulation.ItemOption>
    </ListManipulation.UnitOptions>
  )
}
