import React, { useEffect, useMemo, useState } from 'react'
import * as L from 'layouts'
import { Icon, IconButton, Menu, SiteMetadata } from 'components'
import {
  Asset_Watchdog_Link_Status_Enum,
  useAssetsLinksListSubscription,
  useDeleteAssetLinkMutation,
} from 'gql/generated'
import styled from './AssetLinksPage.module.css'
import { useConfirm } from 'components/Confirm'
import { StatusLight } from 'containers/StatusLight'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { Yalc2, Yalc2RowDefinition } from '../../../components/Yalc2'
import { DetailViewEnum, useDetailView } from '../../../components/DetailWindow/useDetailView'

export const AssetLinksPage = () => {
  const { data } = useAssetsLinksListSubscription()
  const assetsLinks =
    data?.asset_watchdog_links?.map((node) => ({
      id: node.id,
      name: node.name,
      url: node.url,
      sourceUrl: node.source_url,
      status: node.status,
    })) ?? []

  const [removing, setRemoving] = useState<boolean>(false)
  const [ConfirmDialog, confirm] = useConfirm()
  const [del] = useDeleteAssetLinkMutation()

  const deleteItem = async (id: string) => {
    const { hide } = await confirm()
    setRemoving(true)
    await del({ variables: { id } })
    setRemoving(false)
    hide()
  }
  return (
    <>
      <SiteMetadata title="Links zu den Dateien Verwalten" />
      <L.Vertical>
        <AssetsLinksList assetsLinks={assetsLinks} onItemRemove={deleteItem} />
        <ConfirmDialog loading={removing} confirmButtonText="Löschen">
          Mit dieser Aktion wird der Link unwiederrufflich gelöscht.
        </ConfirmDialog>
      </L.Vertical>
    </>
  )
}

interface AssetsLinksListProps {
  assetsLinks: AssetLinkElement[]
  onItemRemove: (id: string) => Promise<void>
}

interface AssetLinkElement {
  id: string
  name: string
  url: string
  sourceUrl: string
  status: Asset_Watchdog_Link_Status_Enum
}

const AssetsLinksList = (props: AssetsLinksListProps) => {
  const [selectedIds, setSelectedIds] = useState([])

  const assetLinkToEdit = selectedIds?.length === 1 ? selectedIds[0] : null

  const rowDefinition = useRowDefinition({ onItemRemove: props.onItemRemove })

  const {openDetailView} = useDetailView()
  useEffect(() => {
    if (assetLinkToEdit) {
      openDetailView(DetailViewEnum.AssetLinkDetail, {
        onClose: () => setSelectedIds([]),
        assetLinkId: assetLinkToEdit,
      })
    }
  }, [openDetailView, assetLinkToEdit])

  return (
    <div>
      <Yalc2<AssetLinkElement>
        data={props.assetsLinks}
        rowDefinition={rowDefinition}
        onSelectionChanged={setSelectedIds}
        selectedIds={selectedIds}
        classes={{ listWrapper: styled.assetsLinksList }}
        options={<Menu.Item onClick={() => openDetailView(DetailViewEnum.AssetLinkDetail)}>Neuer Link</Menu.Item>}
      />
    </div>
  )
}

interface RowDefinitionProps {
  onItemRemove: (id: string) => Promise<void>
}

const useRowDefinition = (props: RowDefinitionProps): Yalc2RowDefinition<AssetLinkElement> => {
  const { onItemRemove } = props
  return useMemo(
    () => ({
      columns: [
        {
          width: 25,
          title: '',
          cell: function AssetLinkState(props) {
            let status: 'success' | 'warning' | 'error'
            switch (props.item.status) {
              case Asset_Watchdog_Link_Status_Enum.Ok:
                status = 'success'
                break
              case Asset_Watchdog_Link_Status_Enum.Updated:
                status = 'warning'
                break
              default:
                status = 'error'
                break
            }
            return <StatusLight status={status} />
          },
        },
        {
          title: 'Name',
          width: 260,
          cell: ({ item }) => <Yalc2Cell.SimpleCell>{item.name}</Yalc2Cell.SimpleCell>,
        },
        {
          title: 'Datei URL',
          width: 300,
          cell: ({ item }) => <Yalc2Cell.MaxWidthCell>{item.url}</Yalc2Cell.MaxWidthCell>,
        },
        {
          title: 'Quelle',
          width: 300,
          cell: ({ item }) => <Yalc2Cell.MaxWidthCell>{item.sourceUrl}</Yalc2Cell.MaxWidthCell>,
        },
        {
          title: '',
          width: 240,
          cell: (props) => (
            <div className={'hidden'}>
              <IconButton
                aria-label="remove asset link"
                onClick={(evt) => {
                  evt.stopPropagation()
                  onItemRemove(props.item.id)
                }}
                size="small"
                tooltip="Link zur Datei löschen"
                color="primary"
                style={{ padding: '0 4px' }}
              >
                <Icon name="delete"></Icon>
              </IconButton>
            </div>
          ),
        },
      ],
    }),
    [onItemRemove]
  )
}
