import React, { CSSProperties } from 'react'
import { Import_Job_Status_Enum } from '../../gql'
import { useTranslation } from 'react-i18next'
import {Chip, ChipProps} from '../../components'

interface ImportJobStatusChipProps {
  state: Import_Job_Status_Enum
  style?: CSSProperties
  className?: string
}

export const ImportJobStatusChip = (props: ImportJobStatusChipProps) => {
  const { t } = useTranslation()
  const color = useColor(props)

  if (!props.state) {
    return <>{'-'}</>
  }

  return (
      <Chip color={color} style={props.style} className={props.className}>
        {t(`ImportJobStatus.${props.state}`)}
      </Chip>
  )
}

const useColor = (props: ImportJobStatusChipProps): ChipProps['color'] => {
  switch (props.state) {
    case Import_Job_Status_Enum.Done:
      return 'primary'
    case Import_Job_Status_Enum.Initialized:
    case Import_Job_Status_Enum.InProgress:
      return 'secondary'
    case Import_Job_Status_Enum.PreviewDone:
      return 'danger'
    case Import_Job_Status_Enum.PreviewInProgress:
    case Import_Job_Status_Enum.ExecutionInProgress:
      return 'warning'
  }
}
