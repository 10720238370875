import React, { useEffect, useState } from 'react'
import { VehicleFormData, VehicleFormValues } from '../index'
import { Order_State_Enum } from '../../../gql'
import { useVehicleFormValues } from './index'
import { DetailWindow } from 'components/DetailWindow'
import { Form } from 'components/Form'
import * as L from 'layouts'
import { OrderStateSelect } from '../../OrderStateSelect'
import { DatePicker } from '../../../components/DatePicker'

interface ChangeOrderStateModalProps {
  onClose: () => void
  onSave: (data: VehicleFormValues) => Promise<void>
  data: VehicleFormData
  desiredState?: Order_State_Enum
}

export const ChangeOrderStateModal = (props: ChangeOrderStateModalProps) => {
  const [desiredState, setDesiredState] = useState(props.desiredState ?? null)
  const [{ values, setValue }] = useVehicleFormValues(props.data)
  useEffect(() => {
    setValue('order_state', desiredState)
  }, [desiredState, setValue])

  const handleSave = () => {
    // TODO: validate
    props.onSave(values)
  }

  return (
    <DetailWindow title="Transportstatus ändern" size="small" onClose={props.onClose}>
      <Form className="flex flex-col h-full">
        <L.Container className="flex-1 flex flex-col gap-4">
          <OrderStateSelect
            value={desiredState}
            onSelect={(state) => setDesiredState(state)}
            label={'Neuer Transportstatus'}
            disabled={!!props.desiredState}
          />
          {desiredState === Order_State_Enum.Handover && (
            <DatePicker
              label={'Erfassungsdatum'}
              value={values?.handover_date}
              onChange={(date) => setValue('handover_date', date.toISOString())}
            />
          )}
        </L.Container>
        <Form.Actions onSave={handleSave} onCancel={props.onClose} />
      </Form>
    </DetailWindow>
  )
}
