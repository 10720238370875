import React, { useEffect, useMemo, useRef } from 'react'
import { Checkbox, Chip, Icon, IconButton, MultiSelect, Select, Spinner, Switch, TextField } from 'components'
import { Typo } from 'components/Typo'
import {
  AdminBrandDetailsQueryResult,
  Brand_Vehicle_Metafield_Insert_Input,
  useAdminBrandSupplierListQuery,
  useAdminDealerVehiclesSyncStatusLazyQuery,
  useAdminVehicleMetafieldsQuery,
  useSetBrandAfterSalesSuppliersMutation,
  useSetBrandSalesSuppliersMutation,
  useSetVehicleMetafieldsMutation,
  useToggleDealerVehiclesSyncStatusMutation,
  Vehicle_Metafields_Enum,
} from 'gql'
import * as L from 'layouts'
import { useState } from 'react'
import { useFormFields } from '@w2solutions/react-hooks'
import { FormControlLabel } from '@mui/material'
interface BrandDetailsProps {
  brand: AdminBrandDetailsQueryResult['data']['brand']
  loading?: boolean
}

export const BrandDetails = (props: BrandDetailsProps) => {
  const { suppliers, loading: suppliersLoading } = useSupplierList()
  const {
    status: syncStatus,
    loading: statusLoading,
    toggle: toggleSyncStatus,
    refetch: refetchSyncStatus,
  } = useSyncStatus(props.brand?.id)
  const [saveMetafields] = useSetVehicleMetafieldsMutation()
  const [setSalesSuppliers, { loading: setSalesSuppliersLoading }] = useSetBrandSalesSuppliersMutation()
  const [setAfterSalesSuppliers, { loading: setAfterSalesSuppliersLoading }] = useSetBrandAfterSalesSuppliersMutation()
  const [editSalesSuppliers, setEditSalesSuppliers] = useState(false)
  const [editAfterSalesSuppliers, setEditAfterSalesSuppliers] = useState(false)
  const [editVehicleMetafields, setEditVehicleMetafields] = useState<boolean>(false)
  const [changingSyncStatus, setChangingSyncStatus] = useState<boolean>(false)
  const brand = props.brand

  const defaultVehicleMetafields = useMemo(
    () => ({
      array: brand?.vehicle_metafields.map((meta) => ({
        name: meta?.vehicle_metafield?.column_name,
        required_for_import: meta?.required_for_import,
        default_value: meta?.default_value,
      })),
    }),
    [brand?.vehicle_metafields]
  )

  const {
    values: vehicleMetafields,
    setValue: setVehicleMetafields,
    reset: resetVehicleMetafields,
  } = useFormFields(defaultVehicleMetafields, { resetOnInitialValueChange: true })

  if (props.loading || suppliersLoading || !brand) {
    return <Spinner />
  }

  const handleSaveSalesSuppliers = async (ids: string[]) => {
    await setSalesSuppliers({
      variables: {
        brandId: brand.id,
        supplierIds: ids,
        objects: ids.map((id) => ({
          brand_id: brand.id,
          supplier_id: id,
          is_sales_supplier: true,
        })),
      },
    })
    setEditSalesSuppliers(false)
  }

  const handleSaveAfterSalesSuppliers = async (ids: string[]) => {
    await setAfterSalesSuppliers({
      variables: {
        brandId: brand.id,
        supplierIds: ids,
        objects: ids.map((id) => ({
          brand_id: brand.id,
          supplier_id: id,
          is_after_sales_supplier: true,
        })),
      },
    })
    setEditAfterSalesSuppliers(false)
  }

  const handleAddMetafield = () =>
    setVehicleMetafields('array', [
      ...vehicleMetafields.array,
      {
        default_value: '',
        required_for_import: false,
        name: '',
      },
    ])

  const handleRemoveMetafield = (index: number) => {
    const newValue = [...vehicleMetafields.array]
    newValue.splice(index, 1)
    setVehicleMetafields('array', newValue)
  }

  const handleChangeMetafield = (
    index: number,
    data: {
      name: string
      required_for_import: boolean
      default_value: string
    }
  ) => {
    const newValue = [...vehicleMetafields.array]
    newValue[index] = data
    setVehicleMetafields('array', newValue)
  }

  const handleSaveMetadata = async () => {
    const objects: Brand_Vehicle_Metafield_Insert_Input[] = vehicleMetafields.array?.map(({ name, ...meta }) => ({
      ...meta,
      brand_id: brand.id,
      vehicle_metafield_name: name as Vehicle_Metafields_Enum,
    }))
    try {
      await saveMetafields({
        variables: {
          brandId: brand.id,
          objects,
        },
      })
      setEditVehicleMetafields(false)
    } catch (error) {}
  }

  const handleCancelEditingMetafields = () => {
    resetVehicleMetafields()
    setEditVehicleMetafields(false)
  }

  return (
    <L.Vertical spacing={2}>
      <Typo variant="h1">{brand.name}</Typo>
      <Switch
        labelPlacement="end"
        label={`Lagerfahrzeuge der Marke ${brand.name} mit Locosoft synchronisieren`}
        onChange={async (e) => {
          const enabled = e.target.checked
          setChangingSyncStatus(true)
          await toggleSyncStatus({ variables: { id: brand.id, enabled } })
          setChangingSyncStatus(false)
          refetchSyncStatus()
        }}
        disabled={statusLoading || changingSyncStatus}
        checked={syncStatus}
      />
      <SupplierTitle title="Sales Lieferanten" enableEditMode={() => setEditSalesSuppliers(true)} />
      {editSalesSuppliers ? (
        <SupplierForm
          suppliers={suppliers}
          selectedSupplierIds={brand.salesSuppliers.map((supplier) => supplier.id)}
          onSave={handleSaveSalesSuppliers}
          onDelete={() => handleSaveSalesSuppliers([])}
          onCancel={() => setEditSalesSuppliers(false)}
          disableActionBtns={setSalesSuppliersLoading}
        />
      ) : (
        <SupplierList suppliers={brand.salesSuppliers} />
      )}

      <SupplierTitle title="Aftersales Lieferanten" enableEditMode={() => setEditAfterSalesSuppliers(true)} />
      {editAfterSalesSuppliers ? (
        <SupplierForm
          suppliers={suppliers}
          selectedSupplierIds={brand.afterSalesSuppliers.map((supplier) => supplier.id)}
          onSave={handleSaveAfterSalesSuppliers}
          onDelete={() => handleSaveAfterSalesSuppliers([])}
          onCancel={() => setEditAfterSalesSuppliers(false)}
          disableActionBtns={setAfterSalesSuppliersLoading}
        />
      ) : (
        <SupplierList suppliers={brand.afterSalesSuppliers} />
      )}
      <VehiclesMetafieldsForm
        onAdd={handleAddMetafield}
        onRemove={handleRemoveMetafield}
        onChange={handleChangeMetafield}
        brandId={brand.id}
        metafields={vehicleMetafields.array}
        editMode={editVehicleMetafields}
        setEditMode={setEditVehicleMetafields}
        onSave={handleSaveMetadata}
        onCancel={handleCancelEditingMetafields}
      />
    </L.Vertical>
  )
}

interface SupplierListProps {
  suppliers: AdminBrandDetailsQueryResult['data']['brand']['afterSalesSuppliers']
}

const SupplierList: React.FC<SupplierListProps> = (props) => {
  return (
    <L.Horizontal spacing={1}>
      {props.suppliers.map((supplier) => (
        <Chip color="primary" key={supplier.id}>
          {supplier.name}
        </Chip>
      ))}
    </L.Horizontal>
  )
}

interface SupplierFormProps {
  suppliers: Suppliers
  selectedSupplierIds: string[]
  disableActionBtns?: boolean
  onSave: (ids: string[]) => void
  onDelete: () => void
  onCancel: () => void
}

const SupplierForm: React.FC<SupplierFormProps> = (props) => {
  const [selectedSupplierIds, setSupplierIds] = useState<string[]>(props.selectedSupplierIds)
  const { suppliers, disableActionBtns, onSave, onCancel, onDelete } = props

  return (
    <L.Vertical spacing={0}>
      <MultiSelect
        name="supplierIds"
        value={selectedSupplierIds}
        onChange={(evt) => setSupplierIds(evt.value)}
        renderValueItem={(supplierId) => suppliers.find((supplier) => supplier.id === supplierId)?.name ?? ''}
        label={'Lieferanten'}
      >
        {props.suppliers.map((supplier) => (
          <MultiSelect.Option key={supplier.id} value={supplier.id}>
            {supplier.name}
          </MultiSelect.Option>
        ))}
      </MultiSelect>
      <L.Horizontal spacing={1}>
        <IconButton
          aria-label="save"
          type="submit"
          color="primary"
          disabled={disableActionBtns}
          onClick={() => onSave(selectedSupplierIds)}
          size="large"
        >
          <Icon name="check" />
        </IconButton>
        <IconButton aria-label="cancel" disabled={disableActionBtns} onClick={onCancel} size="large">
          <Icon name="close" />
        </IconButton>
        <div style={{ flexGrow: 1 }} />
        <IconButton aria-label="delete" onClick={onDelete} disabled={disableActionBtns} color="secondary" size="large">
          <Icon name="delete" />
        </IconButton>
      </L.Horizontal>
    </L.Vertical>
  )
}

interface SupplierTitleProps {
  title: string
  enableEditMode: () => void
}

const SupplierTitle: React.FC<SupplierTitleProps> = (props) => {
  const { title, enableEditMode } = props
  return (
    <L.Horizontal spacing={1}>
      <Typo variant="h3" component="h2">
        {title}
      </Typo>
      <IconButton aria-label="edit" onClick={enableEditMode} size="large">
        <Icon name="edit" />
      </IconButton>
    </L.Horizontal>
  )
}

interface VehiclesMetafieldsFormProps {
  brandId: string
  metafields: {
    name: string
    required_for_import: boolean
    default_value: string
  }[]
  onAdd: () => void
  onRemove: (index: number) => void
  onChange: (
    idx: number,
    reference: {
      name: string
      required_for_import: boolean
      default_value: string
    }
  ) => void
  onSave: () => Promise<void>
  onCancel: () => void
  editMode: boolean
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

const VehiclesMetafieldsForm = (props: VehiclesMetafieldsFormProps) => {
  return (
    <>
      <L.Horizontal>
        <Typo variant="h3" component="h2">
          {'Extrafelder von Lagerfahrzeugen'}
        </Typo>
        {!props.editMode ? (
          <IconButton aria-label="edit" onClick={() => props.setEditMode(true)} size="large">
            <Icon name="edit" />
          </IconButton>
        ) : (
          <IconButton tooltip={'Extrafeld hinzufügen'} aria-label="add" onClick={props.onAdd} size="large">
            <Icon name="add" />
          </IconButton>
        )}
      </L.Horizontal>
      {props.metafields?.map((metafield, index) => (
        <L.Horizontal key={`meta-${index}`}>
          <VehicleMetafield
            onChange={(data) => props.onChange(index, data)}
            readOnly={!props.editMode}
            metafield={metafield}
          />
          {props.editMode && (
            <IconButton
              aria-label={'remove'}
              color={'secondary'}
              onClick={() => {
                props.onRemove(index)
              }}
            >
              <Icon name="delete" />
            </IconButton>
          )}
        </L.Horizontal>
      ))}
      {props.editMode && (
        <L.Horizontal spacing={1}>
          <IconButton aria-label="save" type="submit" color="primary" onClick={props.onSave} size="large">
            <Icon name="check" />
          </IconButton>
          <IconButton aria-label="cancel" onClick={props.onCancel} size="large">
            <Icon name="close" />
          </IconButton>
        </L.Horizontal>
      )}
    </>
  )
}

interface VehicleMetafieldProps {
  readOnly: boolean
  metafield: ArrayElement<VehiclesMetafieldsFormProps['metafields']>
  onChange: (data: ArrayElement<VehiclesMetafieldsFormProps['metafields']>) => void
}

const VehicleMetafield = (props: VehicleMetafieldProps) => {
  const metafields = useAdminVehicleMetafieldsQuery()
  const { bindings, values, setValue } = useFormFields(props.metafield, { resetOnInitialValueChange: true })

  const changeRef = useRef(props.onChange)
  changeRef.current = props.onChange
  useEffect(() => {
    changeRef.current?.call(null, values)
  }, [values])

  if (!metafields.data) return null
  return (
    <L.Grid cols={3}>
      <FormControlLabel
        classes={{ label: 'text-xs text-grey-700' }}
        value={bindings.name}
        label="Name"
        labelPlacement="top"
        className="items-start"
        control={
          props.readOnly ? (
            <TextField
              {...bindings.name}
              value={metafields.data?.vehicle_metafields?.find((meta) => meta?.column_name === values.name)?.title}
              readOnly={true}
            />
          ) : (
            <Select readOnly={props.readOnly} {...bindings.name} onChange={(evt) => setValue('name', evt.target.value)}>
              {metafields.data?.vehicle_metafields?.map((meta) => (
                <Select.Option value={meta?.column_name}>{meta?.title}</Select.Option>
              ))}
            </Select>
          )
        }
      />
      <FormControlLabel
        classes={{ label: 'text-xs text-grey-700' }}
        value={bindings.default_value}
        label="Default Wert"
        className="items-start"
        labelPlacement="top"
        control={<TextField {...bindings.default_value} readOnly={props.readOnly} />}
      />

      <FormControlLabel
        value={bindings.required_for_import.value}
        label="Notwendig für Import?"
        className="justify-end items-start"
        classes={{ label: 'text-xs text-grey-700' }}
        control={
          props.readOnly ? (
            <div>{values.required_for_import && <Icon name={'check'} />}</div>
          ) : (
            <Checkbox
              {...bindings.required_for_import}
              checked={bindings.required_for_import.value}
              onChange={(evt) => setValue('required_for_import', evt.target.checked)}
            />
          )
        }
        labelPlacement="top"
      />
    </L.Grid>
  )
}

type Suppliers = {
  id: string
  name: string
}[]

const useSupplierList = () => {
  const result = useAdminBrandSupplierListQuery()
  const suppliers: Suppliers =
    result.data?.suppliers?.map((sup) => ({
      id: sup.id,
      name: sup.name,
    })) ?? []
  return { suppliers, loading: result?.loading }
}

const useSyncStatus = (id: string) => {
  const [query, result] = useAdminDealerVehiclesSyncStatusLazyQuery({ fetchPolicy: 'no-cache' })
  const [toggle] = useToggleDealerVehiclesSyncStatusMutation()

  useEffect(() => {
    if (id) query({ variables: { id } })
  }, [id, query])

  const status = useMemo(() => result.data?.dealerVehicleBrandSyncStatus?.enabled ?? false, [
    result.data?.dealerVehicleBrandSyncStatus?.enabled,
  ])

  const loading = useMemo(() => result.loading, [result.loading])

  return { status, loading, refetch: result?.refetch, toggle }
}
