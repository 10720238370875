import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Chip, Icon, Menu, SiteMetadata } from '../../../../components'
import * as L from 'layouts'
import { Yalc2, Yalc2RowDefinition } from '../../../../components/Yalc2'
import { TicketTemplateListFieldsFragment, useTicketTemplatesListSubscription } from '../../../../gql'
import { PriorityIndicator } from '../../../../containers/PriorityIndicator'
import { useTranslation } from 'react-i18next'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { DetailViewEnum, useDetailView } from '../../../../components/DetailWindow/useDetailView'

interface TicketTemplatesPageProps {
  context: {
    entity: 'vehicle'
  }
}

export const TicketTemplatesPage = (props: TicketTemplatesPageProps) => {
  const ticketTemplatesSubscription = useTicketTemplatesListSubscription()
  const ticketTemplates = useMemo(() => ticketTemplatesSubscription.data?.ticket_templates ?? [], [
    ticketTemplatesSubscription.data?.ticket_templates,
  ])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const rowDef = useRowDefinition()

  const detailRef = useRef<HTMLDivElement>()

  const {openDetailView} = useDetailView()

  useEffect(() => {
    if (selectedIds.length === 1) {
      openDetailView(DetailViewEnum.TicketTemplateDetail, {
        ref: detailRef,
        templateId: selectedIds[0],
        onClose: () => setSelectedIds([]),
      })
    }
  }, [openDetailView, selectedIds])

  return (
    <>
      <SiteMetadata title={'Fahrzeug Automatisierungen'} />
      <L.Vertical>
        <Yalc2<TicketTemplateListFieldsFragment>
          data={ticketTemplates}
          rowDefinition={rowDef}
          groups={[{ title: 'Aufgaben Vorlagen', count: ticketTemplates.length }]}
          selectedIds={selectedIds}
          onSelectionChanged={setSelectedIds}
          options={
            <Menu.Item onClick={() => openDetailView(DetailViewEnum.TicketTemplateDetail, { ref: detailRef })}>
              Neue Aufgaben Vorlage
            </Menu.Item>
          }
        ></Yalc2>
      </L.Vertical>
    </>
  )
}

const useRowDefinition = (): Yalc2RowDefinition<TicketTemplateListFieldsFragment> => {
  return useMemo(
    () => ({
      columns: [
        {
          title: 'Vorlagen Titel',
          width: 150,
          cell: function TemplateTitleCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.template_title}</Yalc2Cell.MaxWidthCell>
          },
        },
        {
          title: 'Aufgaben Titel',
          width: 150,
          cell: function TicketTitleCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.ticket_title}</Yalc2Cell.MaxWidthCell>
          },
        },
        {
          title: 'Aufgaben Beschreibung',
          width: 200,
          cell: function DescriptionCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.ticket_description}</Yalc2Cell.MaxWidthCell>
          },
        },
        {
          title: 'Priorität',
          width: 100,
          cell: function PriorityCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <PriorityIndicator priority={props.item.priority} />
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          title: 'Bereich',
          width: 200,
          cell: function DepartmentCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.department_id ? <Chip>{props.item.department?.name}</Chip> : '-'}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          title: 'Automatisch zuweisen zum Nutzer',
          width: 200,
          cell: function AssignToCell(props) {
            const { t } = useTranslation()
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.assign_to ? t(`TicketTemplateAssignee.${props.item.assign_to}`) : '-'}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          title: 'Automatisch zuweisen zum Standort',
          width: 200,
          cell: function LocationCell(props) {
            const { t } = useTranslation()
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.location ? t(`TicketTemplateLocation.${props.item.location}`) : '-'}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          title: 'Manueller Auslöser?',
          width: 150,
          cell: function AutomatedTaskCell(props) {
            if (props.item.automated_task) return null
            return (
              <Yalc2Cell.SimpleCell>
                <Icon name={'check'} />
              </Yalc2Cell.SimpleCell>
            )
          },
        },
      ],
    }),
    []
  )
}
