import React, { CSSProperties } from 'react'
import { Sales_Type_Enum } from '../../gql'
import { useTranslation } from 'react-i18next'
import Select from '../../components/Select'

interface SalesTypeProps {
  value: Sales_Type_Enum
  onSelect: (type: Sales_Type_Enum) => void
  name?: string
  label?: string
  disabled?: boolean
  placeholder?: string
  style?: CSSProperties
  className?: string
}

export const SalesTypeSelect = (props: SalesTypeProps) => {
  const { t } = useTranslation()

  return (
    <Select
      name={props.name ?? 'salesTypeSelect'}
      label={props.label}
      value={props.value}
      onChange={(evt) => {
        evt.stopPropagation()
        props.onSelect(evt.target.value as Sales_Type_Enum)
      }}
      renderSelectValueItem={(type) => (type ? t(`SalesType.${type}`) : '')}
      disabled={props.disabled}
      placeholder={props.placeholder}
      style={props.style}
      className={props.className}
    >
      {Object.keys(Sales_Type_Enum).map((type) => (
        <Select.Option key={type} value={Sales_Type_Enum[type]}>
          {t(`SalesType.${Sales_Type_Enum[type]}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
