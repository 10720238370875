import * as L from 'layouts'
import { Icon, IconButton, MaxWidthText, Menu, SiteMetadata, Spinner, TextField } from 'components'
import routes from 'config/routes'
import {
  After_Sales_Status_Enum,
  AfterSalesPotential_Bool_Exp,
  AfterSalesPotentialsExportType,
  Task_Category_Enum,
  useAfterSalesPotentialLetterListDownloadLazyQuery,
  useAfterSalesPotentialListLazyQuery,
  useLocationWithGroupsLazyQuery,
} from 'gql'
import { NotFoundPage } from 'pages/404'
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useAppLocationId } from 'clientState'
import { LocationGroupBtnGrp } from 'containers/LocationGroupBtnGrp'
import { YearSelect } from 'containers/YearSelect'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { MonthSelectPane } from 'containers/MonthSelectPane'
import { useDebounce } from 'hooks/useDebounce'
import { DateFns } from 'utils/date-fns'
import { useSetAfterSalesStatus } from './useSetAfterSalesStatus'
import { download } from 'utils'
import usePrevious from '@w2solutions/react-hooks/dist/usePrevious'
import { Yalc2, Yalc2RowDefinition } from '../../../components/Yalc2'
import { Yalc2Cell } from '../../../components/Yalc2/cells'
import { AfterSalesStatusChip, BrandAvatar } from '../../../containers'
import { clipboard } from '../../../utils/clipboard'
import { useFormatter } from '../../../hooks'
import { DetailViewEnum, useDetailView } from '../../../components/DetailWindow/useDetailView'

export const AfterSalesPotentialsPage = () => {
  return (
    <Switch>
      <Route exact path={routes.afterSales.customerVehicles.service()}>
        <AfterSalesPotentialsRoot taskCategory={Task_Category_Enum.Service} />
      </Route>
      <Route exact path={routes.afterSales.customerVehicles.inspectionSticker()}>
        <AfterSalesPotentialsRoot taskCategory={Task_Category_Enum.InspectionSticker} />
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  )
}

interface AfterSalesPotentialsRootProps {
  taskCategory: Task_Category_Enum.InspectionSticker | Task_Category_Enum.Service
}

interface AfterSalesPotentialsListItem {
  id: string
  vin: string
  brandId: string
  brandName: string
  modelDescription?: string

  licensePlate: string
  mileage?: number
  mileageDate?: string
  customerCustomerNo: string
  customerName?: string
  customerCity?: string
  customerZipCode?: string
  lastServiceDate?: string
  lastInspectionStickerDate?: string
  lastNote?: string

  firstRegistrationDate?: string
  warrantyStart?: string
  warrantyEnd?: string

  status: After_Sales_Status_Enum
}

const AfterSalesPotentialsRoot = (props: AfterSalesPotentialsRootProps) => {
  const { t } = useTranslation()
  const title = `Kunden Fahrzeuge - ${t(props.taskCategory)}`

  const [searchQuery, setSearchQuery] = useState('')
  const [pageFilter, pageFilterFn] = usePageFilter()

  const potentialFilter = usePotentialsFilter(props.taskCategory, pageFilter)
  const [potentials, potentialsLink] = usePotentials(potentialFilter)
  const groups = useGroupedPotentials(potentials, potentialFilter, props.taskCategory, searchQuery)

  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [detailId, setDetailId] = useState<string>(null)
  const startOfSelectedMonth = useMemo(
    () => new Date(pageFilter.year, pageFilter.monthIndex, 1),
    [pageFilter.monthIndex, pageFilter.year]
  )

  useEffect(() => {
    const ids = selectedIds
    setDetailId(ids.length === 1 ? ids[0] : null)
  }, [selectedIds])

  useEffect(() => {
    setDetailId(null)
    setSelectedIds([])
  }, [pageFilter])

  const {openDetailView} = useDetailView()

  const [saving, setSaving] = useState(false)

  const alreadyDone = useSetAfterSalesStatus(After_Sales_Status_Enum.AlreadyDone)
  const notRequired = useSetAfterSalesStatus(After_Sales_Status_Enum.NotRequired)

  const handleAlreadyDone = useCallback(
    async (ids: string[]) => {
      setSaving(true)
      const promise = alreadyDone(ids)
      setSelectedIds((prev) => prev.filter((id) => ids.indexOf(id) < 0))
      await promise
      setSaving(false)
    },
    [alreadyDone]
  )

  const handleNotRequired = useCallback(
    async (ids: string[]) => {
      setSaving(true)
      const promise = notRequired(ids)
      setSelectedIds((prev) => prev.filter((id) => ids.indexOf(id) < 0))
      await promise
      setSaving(false)
    },
    [notRequired]
  )

  const handleItemAlreadyDone = useCallback(
    async (id: string) => {
      await handleAlreadyDone([id])
    },
    [handleAlreadyDone]
  )

  const handleItemNotRequired = useCallback(
    async (id: string) => {
      await handleNotRequired([id])
    },
    [handleNotRequired]
  )

  const loading = useDebounce(!potentialFilter.initialized || potentialsLink.loading || saving, 50)

  const warrantyListRef = useRef<HTMLDivElement>()
  const todoListRef = useRef<HTMLDivElement>()
  const doneListRef = useRef<HTMLDivElement>()
  const detailRef = useRef<HTMLDivElement>()
  const handleClickOutsideSelection = useCallback((evt: React.MouseEvent) => {
    const clickedInside = (ref: React.MutableRefObject<Element>, evt: React.MouseEvent) => {
      return ref.current?.contains(evt.target as any)
    }
    if (
      !clickedInside(warrantyListRef, evt) &&
      !clickedInside(todoListRef, evt) &&
      !clickedInside(doneListRef, evt) &&
      !clickedInside(detailRef, evt) &&
      document.activeElement.tagName === 'BODY'
    ) {
      setSelectedIds([])
    }
  }, [])

  useEffect(() => {
    if (detailId) {
      openDetailView(DetailViewEnum.AfterSalesPotentialDetail, {
        ref: detailRef,
        potentialId: detailId,
        onClickOutside: handleClickOutsideSelection,
        onAlreadyDone: handleItemAlreadyDone,
        onNotRequired: handleItemNotRequired,
        onClose: () => setSelectedIds([]),
      })
    }
  }, [
    detailId,
    handleClickOutsideSelection,
    handleItemAlreadyDone,
    handleItemNotRequired,
    openDetailView,
  ])

  const yalcRowDefinition = useRowDefinition({ taskCategory: props.taskCategory, startOfSelectedMonth })
  return (
    <div className="h-full">
      <SiteMetadata title={title} />
      <L.Vertical style={{ height: '100%' }}>
        <Yalc2<AfterSalesPotentialsListItem>
          selectedIds={selectedIds}
          onSelectionChanged={setSelectedIds}
          onClickOutsideList={handleClickOutsideSelection}
          data={groups.warranty.concat(groups.todos).concat(groups.done)}
          groups={[
            { title: 'Garantie', count: groups.warranty.length },
            {
              title: 'Fahrzeuge',
              count: groups.todos.length,
            },
            { title: 'Erledigt', count: groups.done.length },
          ]}
          rowDefinition={yalcRowDefinition}
          actionBar={
            <>
              <Filter
                year={pageFilter.year}
                onYearChange={pageFilterFn.setYear}
                locationGroupId={pageFilter.locationGroupId}
                onLocationGroupIdChange={pageFilterFn.setLocationGroupId}
                searchQuery={searchQuery}
                onSearchQueryChange={setSearchQuery}
                style={{ flexGrow: 1 }}
              />
              <L.Horizontal spacing={1} className="flex-1 justify-end">
                {loading && <Spinner size="32px" />}
              </L.Horizontal>
            </>
          }
          options={
            <OptionMenu
              filter={potentialFilter}
              disableAlreadyDone={!selectedIds.length || true}
              disableNotRequired={!selectedIds.length || true}
              onAlreadyDone={() => {
                // noinspection JSIgnoredPromiseFromCall
                handleAlreadyDone(selectedIds)
              }}
              onNotRequired={() => {
                // noinspection JSIgnoredPromiseFromCall
                handleNotRequired(selectedIds)
              }}
            />
          }
        />
      </L.Vertical>
      <MonthSelectPane
        monthIndex={pageFilter.monthIndex}
        onChange={pageFilterFn.setMonthIndex}
        className="absolute bottom-0 left-0 w-full bg-white z-appBar border-t border-solid border-divider"
        disableIndex={(idx) => {
          const upperLimit = DateFns.addMonths(new Date(), 6)
          const date = new Date(pageFilter.year, idx, 1)
          return upperLimit.getTime() < date.getTime()
        }}
      />
    </div>
  )
}

const useRowDefinition = (props: {
  taskCategory: Task_Category_Enum.Service | Task_Category_Enum.InspectionSticker
  startOfSelectedMonth: Date
}): Yalc2RowDefinition<AfterSalesPotentialsListItem> => {
  const taskCategory = props.taskCategory
  const startOfSelectedMonth = props.startOfSelectedMonth
  return useMemo(
    () => ({
      columns: [
        {
          title: 'Modell',
          width: 240,
          cell: function ModelCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                {Yalc2Cell.horizontal(
                  <>
                    <BrandAvatar brandId={props.item.brandId} />
                    <div style={{ overflow: 'hidden' }}>
                      <MaxWidthText>{props.item.modelDescription}</MaxWidthText>
                      <Yalc2Cell.SubCell>{props.item.brandName}</Yalc2Cell.SubCell>
                    </div>
                  </>
                )}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          title: 'Kunde',
          width: 180,
          cell: function CustomerCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <MaxWidthText>{props.item.customerName}</MaxWidthText>
                <Yalc2Cell.SubCell>
                  {props.item.customerZipCode} {props.item.customerCity}
                </Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          title: 'Status',
          width: 110,
          cell: (props) => {
            return <AfterSalesStatusChip status={props.item.status} />
          },
        },
        {
          title: 'Kennzeichen',
          width: 165,
          cell: (props) => {
            return (
              <>
                <Yalc2Cell.SimpleCell>{props.item.licensePlate}</Yalc2Cell.SimpleCell>
                <Yalc2Cell.SubCell>
                  {Yalc2Cell.horizontal(
                    <>
                      <span style={{ marginRight: 6 }}>{props.item.vin}</span>
                      <IconButton
                        aria-label="copy vin"
                        onClick={(event) => {
                          event.stopPropagation()
                          clipboard.write(props.item.vin)
                        }}
                        size="small"
                        tooltip="FIN kopieren"
                        style={{ margin: -4 }}
                      >
                        <Icon name="copy" fontSize="small" style={{ height: 12 }} />
                      </IconButton>
                    </>
                  )}
                </Yalc2Cell.SubCell>
              </>
            )
          },
        },
        {
          title: 'Erstzulassung',
          width: 140,
          cell: function DatesCell(props) {
            const { formatDateString } = useFormatter()
            let warnWarrantyOffset = false
            if (props.item.warrantyStart) {
              if (props.item.warrantyStart !== props.item.firstRegistrationDate) {
                warnWarrantyOffset = true
              }
            }
            return (
              <>
                <Yalc2Cell.SimpleCell>
                  {Yalc2Cell.horizontal(
                    <>
                      <span>{formatDateString(props.item.firstRegistrationDate)}</span>
                      {warnWarrantyOffset && (
                        <Icon
                          name="info"
                          color="warning"
                          tooltip="Garantie-Start abweichend zu Erstzulassung"
                          fontSize="small"
                          style={{ height: 12 }}
                        />
                      )}
                    </>
                  )}
                </Yalc2Cell.SimpleCell>
                <Yalc2Cell.SubCell>
                  {formatDateString(props.item.warrantyStart)} - {formatDateString(props.item.warrantyEnd)}
                </Yalc2Cell.SubCell>
              </>
            )
          },
        },
        {
          title: 'KM',
          width: 90,
          cell: function MilageCell(props) {
            const { formatDateString, formatNumber } = useFormatter()
            return (
              <>
                <Yalc2Cell.SimpleCell style={{ textAlign: 'right' }}>
                  {formatNumber(props.item.mileage)} km
                </Yalc2Cell.SimpleCell>
                <Yalc2Cell.SubCell style={{ textAlign: 'right' }}>
                  {formatDateString(props.item.mileageDate)}
                </Yalc2Cell.SubCell>
              </>
            )
          },
        },
        {
          title: function TaskCategoryTitle() {
            const { t } = useTranslation()
            return <>{t(taskCategory)}</>
          },
          width: 150,
          cell: function LastWorkDateCell(props) {
            const { t } = useTranslation()
            const { formatDateString } = useFormatter()

            const serviceDate = props.item.lastServiceDate
            const inspectionStickerDate = props.item.lastInspectionStickerDate

            let mainDate: string
            let subDate: string
            let subTitle = ''
            let warningMonthOffset = 6
            if (taskCategory === Task_Category_Enum.Service) {
              mainDate = serviceDate
              subDate = inspectionStickerDate
              subTitle = 'Pickerl'
              warningMonthOffset = 3
            } else {
              mainDate = inspectionStickerDate
              subDate = serviceDate
              subTitle = 'Service'
            }
            let warnAlreadyDone = false
            const warnThreashold = DateFns.addMonths(startOfSelectedMonth, warningMonthOffset * -1)
            if (DateFns.isBetween(new Date(mainDate), warnThreashold, startOfSelectedMonth, '[)')) {
              warnAlreadyDone = true
            }

            const formatDate = (date) => {
              return date ? formatDateString(date) : t('N/A')
            }
            return (
              <>
                <Yalc2Cell.SimpleCell>
                  {Yalc2Cell.horizontal(
                    <>
                      <span style={{ marginRight: 4 }}>{formatDate(mainDate)}</span>
                      {warnAlreadyDone && (
                        <Icon
                          name="info"
                          color="warning"
                          tooltip="Bereits erledigt?"
                          fontSize="small"
                          style={{ height: 12 }}
                        />
                      )}
                    </>
                  )}
                </Yalc2Cell.SimpleCell>
                <Yalc2Cell.SubCell>
                  {subTitle}: {formatDate(subDate)}
                </Yalc2Cell.SubCell>
              </>
            )
          },
        },
        {
          title: 'Letzte Notiz',
          width: 240,
          cell: function LastNoteCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.lastNote}</Yalc2Cell.MaxWidthCell>
          },
        },
      ],
    }),
    [startOfSelectedMonth, taskCategory]
  )
}

type PotentialsFilterObj = { filter: AfterSalesPotential_Bool_Exp; initialized: boolean }

function usePotentialsFilter(taskCategory: Task_Category_Enum, pageFilter: PageFilter): PotentialsFilterObj {
  const [locationId] = useAppLocationId()
  const [queryLocation, locationResponse] = useLocationWithGroupsLazyQuery()
  useEffect(() => {
    if (locationId) {
      // noinspection JSIgnoredPromiseFromCall
      queryLocation({ variables: { id: locationId } })
    }
  }, [queryLocation, locationId])

  return useMemo(() => {
    const groups = locationResponse.data?.location?.locationGroups ?? []
    const brandIds = groups.find((group) => group.id === pageFilter.locationGroupId)?.brands?.map((b) => b.id)

    const result: PotentialsFilterObj = {
      filter: {
        task_category: { _eq: taskCategory },
        year: { _eq: pageFilter.year },
        month: { _eq: pageFilter.monthIndex },
        location_id: { _eq: locationId },
        _or: [
          {
            vehicle: {
              brand_id: { _in: brandIds },
            },
          },
          {
            customer_vehicle: {
              brand_id: { _in: brandIds },
            },
          },
        ],
      },
      initialized: !!locationId && !!locationResponse.data?.location,
    }
    return result
  }, [
    locationId,
    locationResponse.data?.location,
    pageFilter.locationGroupId,
    pageFilter.monthIndex,
    pageFilter.year,
    taskCategory,
  ])
}

const BATCH_FETCH_SIZE = 250

function usePotentials(obj: PotentialsFilterObj) {
  const [query, response] = useAfterSalesPotentialListLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  })

  const variables = useMemo(
    () => ({
      where: obj.filter,
    }),
    [obj.filter]
  )

  useEffect(() => {
    if (obj.initialized) {
      // noinspection JSIgnoredPromiseFromCall
      query({ variables: { limit: BATCH_FETCH_SIZE, ...variables } })
    }
  }, [obj.initialized, query, variables, response.called])

  const fetchMore = response?.fetchMore
  const totalCount = response.data?.after_sales_potentials_aggregate.aggregate.count
  const [fetchMoreError, setFetchMoreError] = useState<Error | null>(null)
  useEffect(() => {
    if (response.error || fetchMoreError) {
      console.error('query error occurred')
      return
    }
    if (!response.loading && response.called && !!response.data?.after_sales_potentials) {
      const offset = response.data?.after_sales_potentials.length ?? 0
      if (offset < totalCount) {
        fetchMore({ variables: { limit: BATCH_FETCH_SIZE, offset, ...variables } }).catch((err) =>
          setFetchMoreError(err)
        )
      }
    }
  }, [
    fetchMore,
    fetchMoreError,
    query,
    response.error,
    response.loading,
    response.called,
    response.data?.after_sales_potentials,
    totalCount,
    variables,
  ])

  const potentials: PotentialListItem[] = useMemo(() => {
    return (
      response.data?.after_sales_potentials?.map((p) => ({
        id: p.id,
        vin: p.vehicle?.vin || p.customer_vehicle?.vin,
        brandId: p.vehicle?.brand?.id || p.customer_vehicle?.brand?.id,
        brandName: p.vehicle?.brand?.name || p.customer_vehicle?.brand?.name,
        modelDescription:
          p.vehicle?.model_name ||
          p.customer_vehicle.model_description ||
          p.customer_vehicle.model?.description,

        licensePlate: p.license_plate_number,
        mileage: p.mileage,
        mileageDate: p.mileage_date,
        customerCustomerNo: p.customer.customer_no,
        customerName: p.customer_name,
        customerCity: p.customer_city,
        customerZipCode: p.customer_zip_code,
        lastServiceDate: p.last_service_date,
        lastInspectionStickerDate: p.last_inspection_sticker_date,
        lastNote: p.last_note ?? '',

        firstRegistrationDate: p.first_registration_date,
        warrantyStart: p.warranty_start,
        warrantyEnd: p.warranty_end,

        status: p.status,
      })) ?? []
    )
  }, [response.data?.after_sales_potentials])
  return [potentials, response] as const
}

type PotentialListItem = AfterSalesPotentialsListItem

const insensitiveContains = (search: string, text: Optional<string>) => {
  return (text ?? '').trim().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0
}
const filterFn = (potential: PotentialListItem, queryText: string) => {
  const queries = queryText.replace(/,/g, ' ').split(/\s+/)
  return queries.every((query) => {
    return (
      insensitiveContains(query, potential.customerName) ||
      insensitiveContains(query, potential.vin) ||
      insensitiveContains(query, potential.licensePlate) ||
      insensitiveContains(query, potential.modelDescription) ||
      insensitiveContains(query, potential.brandName) ||
      insensitiveContains(query, potential.customerZipCode) ||
      insensitiveContains(query, potential.customerCity) ||
      insensitiveContains(query, potential.customerCustomerNo)
    )
  })
}

const sortPotentials = (potentials: PotentialListItem[]) => {
  return potentials.sort((a, b) => {
    if (a.brandName > b.brandName) {
      return 1
    }
    if (a.brandName < b.brandName) {
      return -1
    }
    if (a.firstRegistrationDate > b.firstRegistrationDate) {
      return 1
    }
    return a.id < b.id ? -1 : 1
  })
}

const useGroupedPotentials = (
  potentials: PotentialListItem[],
  filter: PotentialsFilterObj,
  taskCategory: Task_Category_Enum,
  searchQuery
) => {
  const queryText = useDebounce(searchQuery, 150)
  return useMemo(() => {
    const todos: PotentialListItem[] = []
    const done: PotentialListItem[] = []
    const warranty: PotentialListItem[] = []

    const sorted = sortPotentials(potentials.filter((p) => filterFn(p, queryText)))
    const isWarrantyEnding = (p: PotentialListItem) =>
      p.warrantyEnd && DateFns.getYear(new Date(p.warrantyEnd)) === filter.filter.year._eq

    sorted.forEach((potential) => {
      switch (potential.status) {
        case After_Sales_Status_Enum.AlreadyDone:
        case After_Sales_Status_Enum.NotRequired:
        case After_Sales_Status_Enum.Done:
          done.push(potential)
          break
        default:
          if (taskCategory === Task_Category_Enum.Service && isWarrantyEnding(potential)) {
            warranty.push(potential)
          } else {
            todos.push(potential)
          }
      }
    })
    return {
      warranty,
      todos,
      done,
    }
  }, [filter.filter.year, potentials, queryText, taskCategory])
}

//////////////////////////////////////////
////////////    Filter      //////////////
//////////////////////////////////////////

interface FilterProps {
  locationGroupId: string
  onLocationGroupIdChange: (id: string) => void
  year: number
  onYearChange: (year: number) => void
  searchQuery: string
  onSearchQueryChange: (val: string) => void
  style?: CSSProperties
  className?: string
}

const Filter = (props: FilterProps) => {
  const [locationId] = useAppLocationId()
  const maxYear = DateFns.addMonths(new Date(), 6).getFullYear()
  return (
    <L.Horizontal className={props.className} style={props.style}>
      <LocationGroupBtnGrp
        locationId={locationId}
        locationGroupId={props.locationGroupId}
        onChange={props.onLocationGroupIdChange}
      />
      <YearSelect value={props.year} onChange={props.onYearChange} minValue={2021} maxValue={maxYear} />
      <TextField
        value={props.searchQuery}
        onChange={(evt) => props.onSearchQueryChange(evt.currentTarget.value)}
        style={{ minWidth: 360, maxWidth: 512 }}
        placeholder="FIN, Kunde, Kennzeichen, Modell, Marke, DMS Nummer"
      />
    </L.Horizontal>
  )
}

interface PageFilter {
  monthIndex: number
  year: number
  locationGroupId: string
}

interface RouteRawParams {
  filter?: string
}

interface RouteParams {
  monthIndex?: string
  year?: string
  locationGroupId?: string
}

const usePageFilter = () => {
  const [params, queryActions] = useSearchQuery<RouteRawParams>()
  let routeParams: RouteParams = {}
  if (params.filter) {
    try {
      const json = atob(params.filter)
      routeParams = JSON.parse(json)
    } catch (error) {
      console.warn('parsing search query filter failed', error)
    }
  }
  const [year, setYear] = useState(Number(routeParams.year ?? new Date().getFullYear()))
  const [monthIndex, setMonthIndex] = useState(Number(routeParams.monthIndex ?? new Date().getMonth()))

  const [locationGroupId, setLocationGroupId] = useState(routeParams.locationGroupId ?? '')

  const prevYear = usePrevious(year)
  useEffect(() => {
    const now = new Date()
    if (prevYear && prevYear !== year && year > now.getFullYear()) {
      setMonthIndex(0)
    }
  }, [year, prevYear])

  const filter: PageFilter = useMemo(() => {
    return {
      year,
      monthIndex,
      locationGroupId,
    }
  }, [locationGroupId, monthIndex, year])

  const history = useHistory()
  useEffect(() => {
    const json = JSON.stringify(filter)
    const base64 = btoa(json)
    queryActions.setValue('filter', base64)
  }, [filter, history, queryActions])

  const functions = { setMonthIndex, setYear, setLocationGroupId }

  return [filter, functions] as const
}

//////////////////////////////////////////
////////////    Menu        //////////////
//////////////////////////////////////////

interface OptionsMenuProps {
  filter: PotentialsFilterObj
  disableAlreadyDone: boolean
  disableNotRequired: boolean
  onAlreadyDone: () => void
  onNotRequired: () => void
  style?: CSSProperties
}

const OptionMenu = (props: OptionsMenuProps) => {
  const dl = useDownload(props.filter)
  return (
    <>
      <Menu.Item onClick={() => dl()}>
        <L.Horizontal spacing={1}>
          <Icon name="save" />
          <span>Briefliste herunterladen</span>
        </L.Horizontal>
      </Menu.Item>
      <Menu.Item onClick={() => props.onNotRequired()} disabled={props.disableNotRequired}>
        <L.Horizontal spacing={1}>
          <Icon name="schedule" />
          <span>Nicht fällig</span>
        </L.Horizontal>
      </Menu.Item>
      <Menu.Item onClick={() => props.onAlreadyDone()} disabled={props.disableAlreadyDone}>
        <L.Horizontal spacing={1}>
          <Icon name="check" />
          <span>Bereits erledigt</span>
        </L.Horizontal>
      </Menu.Item>
    </>
  )
}

const useDownload = (filter: PotentialsFilterObj) => {
  const [query, result] = useAfterSalesPotentialLetterListDownloadLazyQuery({ fetchPolicy: 'no-cache' })
  const dlUrl = result?.data?.exportAfterSalesPotentials.download_url
  useEffect(() => {
    if (dlUrl && !result.loading) {
      // noinspection JSIgnoredPromiseFromCall
      download(dlUrl)
    }
  }, [dlUrl, result.loading])

  return () => {
    const brandIds = filter.filter._or.find((expression) => !!expression.vehicle)?.vehicle?.brand_id?._in
    return query({
      variables: {
        input: {
          taskCategory: filter.filter.task_category._eq,
          year: filter.filter.year._eq,
          month: filter.filter.month._eq,
          locationId: filter.filter.location_id._eq,
          brandIds,
          type: AfterSalesPotentialsExportType.LetterListV2,
        },
      },
    })
  }
}
