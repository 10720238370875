import React, { useContext, CSSProperties, useState, useCallback, useEffect } from 'react'
import { v4 as uuid } from 'uuid'

import MuiTabs from '@mui/material/Tabs'
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab'

export const TabsContext = React.createContext({
  activeIndex: 0,
  selectTab: (() => {}) as (tabIndex: number) => void,
  tabsId: 'tabsId',
})

export interface TabsProps {
  defaultActiveIndex?: number
  activeIndex?: number
  onChange?: (index: number) => void
  children?: React.ReactNode
  className?: string
  style?: CSSProperties
}

export const Tabs = (props: TabsProps) => {
  const [activeIndex, setActiveIndex] = useState(props.defaultActiveIndex ?? 0)

  const isControlled = props.activeIndex !== undefined
  const selectTab = useCallback(
    (index: number) => {
      if (!isControlled) {
        setActiveIndex(index)
      }
      if (props.onChange) {
        props.onChange(index)
      }
    },
    [isControlled, props]
  )

  const [tabsId, setTabsId] = useState<string>()
  useEffect(() => {
    setTabsId(uuid())
  }, [])

  const contextState = {
    activeIndex: isControlled ? (props.activeIndex as number) : activeIndex,
    selectTab,
    // tabsId: tabsIdRef.current,
    tabsId,
  }

  return (
    <TabsContext.Provider value={contextState}>
      <div className={props.className} style={props.style}>
        {props.children}
      </div>
    </TabsContext.Provider>
  )
}

export interface TabListProps {
  variant?: 'standard' | 'scrollable'
  style?: CSSProperties
  className?: string
  ref?: React.Ref<HTMLDivElement>
}

export const TabList: React.FC<TabListProps> = React.forwardRef<HTMLDivElement, TabListProps>((props, ref) => {
  const { activeIndex, selectTab } = useContext(TabsContext)

  const children = React.Children.map(props.children, (child, index) => {
    // @ts-ignore
    return React.cloneElement(child, {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    })
  })

  return (
    <MuiTabs
      ref={ref as any}
      value={activeIndex}
      onChange={(evt, idx) => {
        selectTab(idx)
      }}
      scrollButtons="auto"
      variant={props.variant}
      aria-label="tabs"
      style={props.style}
      className={props.className}
    >
      {children}
    </MuiTabs>
  )
})
//@ts-ignore
TabList.muiName = MuiTabs.muiName
Tabs.TabList = TabList

interface TabProps {
  label?: string
  icon?: MuiTabProps['icon']
  disabled?: MuiTabProps['disabled']
}

export const Tab = (props: TabProps) => {
  return <MuiTab {...props} />
}
// @ts-ignore
Tab.muiName = MuiTab.muiName

Tabs.Tab = Tab

export const TabPanes: React.FC = (props) => {
  const children = React.Children.map(props.children, (child, index) => {
    // @ts-ignore
    return React.cloneElement(child, {
      index,
    })
  })

  return <>{children}</>
}

Tabs.TabPanes = TabPanes

interface TabPaneProps {
  index?: any
  style?: CSSProperties
  className?: string
}

export const TabPane: React.FC<TabPaneProps> = (props) => {
  const { activeIndex } = useContext(TabsContext)
  if (activeIndex !== props.index) {
    return null
  }
  return (
    <div
      role="tabpanel"
      hidden={activeIndex !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </div>
  )
}

Tabs.TabPane = TabPane
