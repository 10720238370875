import React from 'react'
import { Typo } from '../../../../components'

export const ListPreviewCard = (props: { name: string; description?: string; onClick: () => void }) => {
  return (
    <div
      className={
        'p-4 cursor-pointer rounded-xl w-96 flex flex-col gap-y-2 bg-primary text-white hover:bg-primary-dark shadow-19'
      }
      onClick={props.onClick}
    >
      <Typo variant={'h4'}>{props.name}</Typo>
      {props.description && <Typo variant={'body1'}>{props.description}</Typo>}
    </div>
  )
}
