import { DataBlock } from 'components/DataBlock'
import { DataValue } from 'components/DataValue'
import { isArray, isObject } from 'lodash'
import React from 'react'

interface LogEntryBlockProps {
  logObject: any
  title: string
  idx?: number
}

export const LogEntryBlock = (props: LogEntryBlockProps) => {
  if (isObject(props.logObject)) {
    return (
      <DataBlock title={props.title} className="mb-4 border border-grey-700 border-opacity-30 p-2">
        {Object.entries(props.logObject).map(([key, val]) => {
          return <LogEntryBlock title={key} logObject={val} />
        })}
      </DataBlock>
    )
  }
  if (isArray(props.logObject)) {
    return (
      <DataBlock title={props.title} className="my-4 border border-grey-700 border-opacity-30 p-2">
        {props.logObject.map((item, idx) => {
          return <LogEntryBlock title={`item-${idx}`} logObject={item} idx={idx} />
        })}
      </DataBlock>
    )
  }
  return (
    <DataValue key={`item-title-${props?.title}`} label={props.title}>
      {props.logObject}
    </DataValue>
  )
}
