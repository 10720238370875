import { Chip } from 'components'
import { In_Buy_Channel_Enum } from 'gql'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

interface InBuyChannelChipProps {
  channel: In_Buy_Channel_Enum
  className?: string
  style?: CSSProperties
}

export const InBuyChannelChip = (props: InBuyChannelChipProps) => {
  const { t } = useTranslation()
  const color = useColor(props.channel)
  if (!props.channel) return <>{'-'}</>
  return <Chip color={color}>{t(`InBuyChannel.${props.channel}`)}</Chip>
}

const useColor = (channel: In_Buy_Channel_Enum) => {
  switch (channel) {
    case In_Buy_Channel_Enum.Import:
      return 'success'
    case In_Buy_Channel_Enum.UnknownUsedCarBuyIn:
      return 'default'
    default:
      return 'primary'
  }
}
