import React from 'react'
import { useFilterOperations } from '../../useFilterOperations'
import { TicketTeamChip } from '../Chips/TeamChip'
import { UserChip } from '../../Chips/UserChip'
import { DateChip } from 'containers/ListManipulation/Chips/DateChip'
import { EnumChip } from 'containers/ListManipulation/Chips/EnumChip'

export const Chips = () => {
  const operations = useFilterOperations<'Ticket'>()
  return (
    <>
      <EnumChip<'Ticket'> field={'state'} operations={operations} />
      <EnumChip<'Ticket'> field={'priority'} operations={operations} />
      <TicketTeamChip operations={operations} />
      <UserChip<'Ticket'> field={'assignee_id'} operations={operations} />
      <DateChip<'Ticket'> field={'created_at'} operations={operations} />
      <DateChip<'Ticket'> field={'updated_at'} operations={operations} />
      <DateChip<'Ticket'> field={'due_to'} operations={operations} />
    </>
  )
}
