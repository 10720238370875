import React, { useRef } from 'react'
import { EzAuthProvider } from '@lib/ezauth'

import { useConfig } from 'config'

import { createAuthenticator } from './Authenticator'

export const AuthProvider: React.FC = (props) => {
  const { auth } = useConfig()
  const authenticator = useRef(createAuthenticator(auth.provider))

  return (
    <EzAuthProvider
      init={authenticator.current.init}
      signIn={authenticator.current.signIn}
      signOut={authenticator.current.signOut}
      forgotPassword={authenticator.current.forgotPassword}
      {...props}
    />
  )
}
