import React, { CSSProperties } from 'react'
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem'
import { Icon } from 'components/Icon'
import { IconButton } from 'components/IconButton'

interface SelectProps {
  value?: string | number
  onChange?: MuiTextFieldProps['onChange']
  autoFocus?: boolean
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  label?: MuiTextFieldProps['label']
  renderSelectValueItem?: (value: string | number) => React.ReactNode | string
  loading?: boolean
  placeholder?: string
  children?: MuiTextFieldProps['children']
  name?: string
  error?: string
  className?: string
  style?: CSSProperties
  onReset?: () => void
  margin?: boolean
}

export const Select = (props: SelectProps) => {
  const { error, loading, onReset, margin, ...rest } = props
  const value = loading ? 'loading' : props.value
  const disabled = props.disabled || loading
  const endAdornment =
    !!onReset && props.value ? (
      <IconButton
        onClick={props.onReset}
        aria-label="clear"
        style={{ position: 'absolute', right: '1.65rem', padding: '0.0rem', alignSelf: 'center' }}
        disabled={!props.value || props.disabled}
        size="large"
      >
        <Icon name="clear" style={{ padding: '0.2rem' }} />
      </IconButton>
    ) : null
  return (
    <MuiTextField
      {...rest}
      helperText={error}
      error={!!error}
      select
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        renderValue: props.renderSelectValueItem,
        placeholder: rest.placeholder,
        endAdornment,
      }}
      disabled={disabled}
      value={loading ? 'loading' : value ?? ''}
      size="small"
      margin={margin ? 'dense' : 'none'}
      fullWidth
      sx={{
        '& [aria-disabled="true"]': {
          color: 'text.primary',
          WebkitTextFillColor: 'unset',
        },
        '& [aria-disabled="true"] ~ svg': {
          display: 'none',
        },
        '& [aria-disabled="true"] ~ fieldset': {
          border: 'none',
        },
        '.error & input ~ fieldset': {
          borderColor: 'error.main',
        },
      }}
    >
      {loading && <Select.Option value="loading">{'Lade Daten...'}</Select.Option>}
      {props.children}
    </MuiTextField>
  )
}
interface SelectOptionProps {
  value: MuiMenuItemProps['value']
  children: MuiMenuItemProps['children']
  disabled?: boolean
  className?: string
  style?: CSSProperties
}

const SelectOption = React.forwardRef((props: SelectOptionProps, ref: React.RefObject<HTMLDivElement>) => {
  return <MuiMenuItem {...props} />
})

Select.Option = SelectOption
export default Select
