import React from 'react'
import { Chip, ChipProps } from 'components'

interface StatusLightProps {
  status: 'success' | 'warning' | 'error' | 'primary'
  variant?: 'outlined' | 'filled'
}

export const StatusLight: React.FC<StatusLightProps> = (props) => {
  const { variant = 'outlined' } = props
  let color: ChipProps['color'] = 'default'
  switch (props.status) {
    case 'success':
      color = 'success'
      break
    case 'warning':
      color = 'warning'
      break
    case 'error':
      color = 'danger'
      break
    case 'primary':
      color = 'primary'
      break
  }
  return (
    <Chip variant={variant} color={color} style={{ width: 10, height: 10, padding: 0 }} className="flex-shrink-0">
      {}
    </Chip>
  )
}
