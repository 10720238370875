import React, {
  CSSProperties,
  ReactElement,
  useContext,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
  useEffect,
  useRef,
} from 'react'
import { equals } from 'ramda'
import styled from './Table.module.css'
import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
} from '@mui/material'

import { TableElementType, TableElement } from './types'
import { TableHeaderRow } from './HeaderRow'
import { TablePagination, TablePaginationProps } from './TablePagination'
import { Card, Spinner, Checkbox } from 'components'

type TableColumnElement<T extends {}> = ReactElement<TableColumnProps<T>, TableElement<TableColumnProps<T>>>
type TablePaginationElement<T extends {}> = ReactElement<TablePaginationProps, TableElement<TableColumnProps<T>>>
type TableHeaderElement<T extends {}> = ReactElement<{}, TableElement<TableColumnProps<T>>>

interface TableProps<T extends {}> {
  data: T[]
  keyField: keyof T
  children: (TableColumnElement<T> | TablePaginationElement<T>)[]
  loading?: boolean
  className?: string
  style?: CSSProperties
}

/**
 * DEPRECATED
 * @param props 
 * @returns 
 * @deprecated use Yalc instead
 */
export const Table = <T extends {}>(props: TableProps<T>): ReactElement<any, any> | null => {
  const data = props.data
  const { columns, columnsMetadata, pagination: paginationChild, header: headerChild } = useTableElementParser(
    props.children
  )

  const [pagination, setPagination] = useState<TableContextValue<T>['pagination']>(undefined)

  const [cellRenderer, setCellRenderer] = useState<CellRenderFn<T>[]>([])
  const registerCellRenderer = useCallback((colIdx: number, renderFn: CellRenderFn<T>) => {
    setCellRenderer((prev) => {
      const newState = [...prev]
      newState[colIdx] = renderFn
      return newState
    })
    return () =>
      setCellRenderer((prev) => {
        const newState = [...prev]
        newState[colIdx] = undefined
        return newState
      })
  }, [])

  const [selectedItems, setSelectedItems] = useState<T[]>([])

  const [rowDecorators, setRowDecorators] = useState<RowDecorator<T>[]>([])
  const registerRowDecorator = useCallback((decorator: RowDecorator<T>) => {
    setRowDecorators((prev) => [...prev, decorator])
    return () => setRowDecorators((prev) => prev.filter((dec) => dec !== decorator))
  }, [])

  const toggleSelection = useCallback((item: T) => {
    setSelectedItems((prev) => {
      return prev.indexOf(item) >= 0 ? prev.filter((selected) => selected !== item) : [...prev, item]
    })
  }, [])

  const contextValue: TableContextValue<T> = useMemo(
    () => ({
      data,
      columns,
      columnsMetadata,
      pagination,
      setPagination,
      cellRenderer,
      registerCellRenderer,
      cellRendererComplete: cellRenderer.filter((cr) => !!cr).length === columns.length,
      rowDecorators,
      registerRowDecorator,
      selectedItems,
      setSelectedItems,
      toggleSelection,
    }),
    [
      data,
      columns,
      columnsMetadata,
      pagination,
      cellRenderer,
      registerCellRenderer,
      rowDecorators,
      registerRowDecorator,
      selectedItems,
      toggleSelection,
    ]
  )

  return (
    <TableContext.Provider value={contextValue}>
      <Card className={styled.card} style={props.style}>
        <div
          style={
            {
              /*display: 'inline-block' */
            }
          }
        >
          {headerChild}
          <MuiTableContainer
            style={
              {
                /*width: 'auto', overflow: 'unset'*/
              }
            }
          >
            <div>
              <MuiTable size="small">
                <TableHeaderRow />
                {columns.map((col, idx) =>
                  React.cloneElement(col, {
                    ...col.props,
                    index: idx,
                    key: idx,
                  })
                )}
                <TableBody {...props} />
              </MuiTable>
              {props.loading && !data?.length && (
                <div className={styled.spinner}>
                  <Spinner />
                </div>
              )}
            </div>
          </MuiTableContainer>
          {paginationChild}
        </div>
      </Card>
    </TableContext.Provider>
  )
}

Table.Pagination = TablePagination

const TableBody = <T extends {}>(props: TableProps<T>) => {
  const { pagination, cellRenderer, cellRendererComplete, rowDecorators, selectedItems, toggleSelection } = useContext(
    TableContext
  )
  let rowItems = props.data
  if (pagination) {
    const { page, pageSize } = pagination
    rowItems = props.data.slice(page * pageSize, (page + 1) * pageSize)
  }

  const rowDecorations = useMemo(() => {
    return rowItems.map((item, rowIndex) => {
      const decorations = rowDecorators.map((decorator) =>
        decorator({
          item,
          index: rowIndex,
          selected: selectedItems.indexOf(item) >= 0,
        })
      )
      return Object.assign.apply(Object, [{}].concat(decorations))
    })
  }, [rowDecorators, rowItems, selectedItems])

  if (!cellRendererComplete) {
    return null
  }

  return (
    <MuiTableBody>
      {rowItems.map((item, rowIndex) => {
        return (
          <MemorizedRow
            key={`row-${item[props.keyField]}`}
            item={item}
            keyField={props.keyField}
            rowIndex={rowIndex}
            data={rowItems}
            cells={cellRenderer}
            rowSelected={selectedItems.indexOf(item) >= 0}
            toggleSelection={toggleSelection}
            {...rowDecorations[rowIndex]}
          />
        )
      })}
    </MuiTableBody>
  )
}

interface RowProps<T extends {}> extends RowDecoration {
  item: T
  keyField: keyof T
  rowIndex: number
  data: T[]
  cells: CellRenderFn<T>[]
  rowSelected: boolean
  toggleSelection: (item: T) => void
}

const MemorizedRow = React.memo(<T extends {}>(props: RowProps<T>) => {
  const { item, keyField, rowIndex, data, cells, rowSelected, toggleSelection, ...rowProps } = props
  return (
    <MuiTableRow hover key={`row-${item[keyField]}`} {...rowProps}>
      {cells.map((Cell, idx) => {
        return (
          <Cell
            key={`cell-${item[keyField]}-${idx}`}
            index={rowIndex}
            data={data}
            item={item}
            rowSelected={rowSelected}
            toggleSelection={toggleSelection}
          />
        )
      })}
    </MuiTableRow>
  )
})

const useTableElementParser = <T extends {}>(children: TableProps<T>['children']) => {
  const columns = children.filter((child) => child.type.elementType === TableElementType.Column) as TableColumnElement<
    T
  >[]
  const columnsMetadata = columns.map((col) => col.props)
  const pagination = (children.find((child) => child.type.elementType === TableElementType.Pagination) ??
    null) as TablePaginationElement<T>
  const header = (children.find((child) => child.type.elementType === TableElementType.Header) ??
    null) as TableHeaderElement<T>
  return { columns, columnsMetadata, pagination, header }
}

interface TableColumnMetadataProps {
  title?: string
  index?: number
}

interface TableColumnProps<T extends {}> extends TableColumnMetadataProps {
  field?: keyof T
  format?: (value: any) => React.ReactNode
  render?: CellRenderFn<T>
  children?: CellRenderFn<T>
  align?: MuiTableCellProps['align']
}

/**
 * 
 * @param props 
 * @returns 
 * @deprecated use Yalc instead
 */
const TableColumn = <T extends {}>(props: TableColumnProps<T>): ReactElement<any, any> | null => {
  const format = props.format
  const defaultRenderFn = useCallback(
    ({ item }: CellRenderProps<T>) => <span>{format ? format(item[props.field]) : item[props.field]}</span>,
    [props.field, format]
  )
  const render: CellRenderFn<T> = props.render ?? props.children ?? defaultRenderFn

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const renderCell: CellRenderFn<T> = useCallback(
    (params) => (
      <MuiTableCell align={props.align} className={styled.cell}>
        {render(params)}
      </MuiTableCell>
    ),
    [props.align, render]
  )

  useRegisterCellRenderer(props.index, renderCell)

  return null
}

TableColumn.defaultProps = {
  align: 'left',
}
TableColumn.elementType = TableElementType.Column
Table.Column = TableColumn

interface RowSelectColumnProps<T extends {}> extends TableColumnMetadataProps {
  selectedItems: T[]
  onChange: (items: T[]) => void
}

const RowSelectCell = React.memo(({ item, rowSelected, toggleSelection }: any) => {
  const checked = rowSelected
  const toggle = () => toggleSelection(item)
  return (
    <MuiTableCell className={styled.cell} padding="checkbox">
      <Checkbox checked={checked} onChange={toggle} />
    </MuiTableCell>
  )
})

const RowSelect = <T extends {}>(props: RowSelectColumnProps<T>) => {
  const { selectedItems, setSelectedItems } = useContext(TableContext)

  useEffect(() => {
    if (!equals(selectedItems, props.selectedItems)) {
      setSelectedItems(props.selectedItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedItems, setSelectedItems])

  const onChangeRef = useRef(props.onChange)
  onChangeRef.current = props.onChange
  useEffect(() => {
    onChangeRef.current(selectedItems)
  }, [selectedItems])

  useRegisterCellRenderer(props.index, RowSelectCell)

  const decorator: RowDecorator<T> = useCallback(({ item, selected }) => {
    if (selected) {
      return {
        selected,
        'aria-checked': selected,
      }
    }
    return null
  }, [])
  useRegisterRowDecorator(decorator)

  return null
}
RowSelect.elementType = TableElementType.Column
Table.RowSelect = RowSelect

export const useRegisterRowDecorator = <T extends {}>(decorator: RowDecorator<T>) => {
  const { registerRowDecorator } = useContext(TableContext)
  useLayoutEffect(() => {
    const unregister = registerRowDecorator(decorator)
    return () => unregister()
  }, [decorator, registerRowDecorator])
}

export const useRegisterCellRenderer = <T extends {}>(index: number, renderer: CellRenderFn<T>) => {
  const { registerCellRenderer } = useContext(TableContext)
  useLayoutEffect(() => {
    const unregister = registerCellRenderer(index, renderer)
    return () => unregister()
  }, [index, registerCellRenderer, renderer])
}

interface TableHeaderProps {
  children?: React.ReactNode
}

export const TableHeader = (props: TableHeaderProps) => {
  return <Card.Header>{props.children}</Card.Header>
}
TableHeader.elementType = TableElementType.Header
Table.Header = TableHeader

////// CONTEXT

interface CellRenderProps<T extends {}> {
  index: number
  item: T
  data: T[]
  rowSelected: boolean
  toggleSelection: (item: T) => void
}

type CellRenderFn<T extends {}> = (props: CellRenderProps<T>) => ReactElement<any, any> | null

interface RowDecoration {
  style?: CSSProperties
  selected?: boolean
  'aria-checked'?: boolean
}
interface RowDecoratorProps<T extends {}> {
  item: T
  index: number
  selected: boolean
}
type RowDecorator<T extends {}> = (props: RowDecoratorProps<T>) => RowDecoration | null

type UnregisterFn = () => void

interface TableContextValue<T extends {}> {
  data: T[]
  selectedItems: T[]
  setSelectedItems: (newState: T[] | ((prev: T[]) => T[])) => void
  toggleSelection: (item: T) => void
  columns: ReactElement<TableColumnMetadataProps>[]
  columnsMetadata: TableColumnMetadataProps[]
  cellRenderer: CellRenderFn<T>[]
  registerCellRenderer: (columnIndex: number, renderFn: CellRenderFn<T>) => UnregisterFn
  cellRendererComplete: boolean
  rowDecorators: RowDecorator<T>[]
  registerRowDecorator: (decorator: RowDecorator<T>) => UnregisterFn
  pagination?: {
    pageSize: number
    page: number
  }
  setPagination: (options: TableContextValue<T>['pagination']) => void
}

export const TableContext = React.createContext<TableContextValue<any>>(null)
