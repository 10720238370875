import React, { CSSProperties } from 'react'
import { useLocationsListQuery } from 'gql'
import { Select } from 'components'
import { useEffect } from 'react'

interface LocationSelectProps {
  value: string
  onSelect: (id: string) => void
  name?: string
  hideLabel?: boolean
  label?: string
  disabled?: boolean
  showDefaultValue?: boolean
  style?: CSSProperties  
}

export const LocationSelect:React.FC<LocationSelectProps> = props => {
  const { value, onSelect, name, label, style, hideLabel, disabled, showDefaultValue } = props
  const [locations, {loading}] = useLocations()

  useEffect(() => {
    if(showDefaultValue && locations.length && !value) {
      onSelect(locations[0].id)
    }
  }, [showDefaultValue, locations, onSelect, value])


  return (
    <Select 
      value={value} 
      onChange={(evt) => onSelect(evt.target.value)}
      disabled={disabled}
      name={name ?? 'locationSelect'}
      label={hideLabel ? null : label ?? 'Standort'}
      style={style}
      loading={loading}
    >
      {locations.map((loc) => (
        <Select.Option key={loc.id} value={loc.id}>
          {loc.name}
        </Select.Option>
      ))}
    </Select>
  )
}

const useLocations = () => {
  const query = useLocationsListQuery()
  const locations = query.data?.locations ?? []
  return [locations, {loading:query.loading}] as const
}
