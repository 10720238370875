import Joi from 'joi'
import { isArray } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

export type ValidationErrors<T> = {
  [K in keyof T]?: T[K] extends Date ? string : T[K] extends object ? ValidationErrors<T[K]> : string
}
type ValidationResult<T> = {
  errors: ValidationErrors<T>
  isValid: boolean
}

type ValidateOptions = {
  silent?: boolean
  abortEarly?: boolean
}
const defaultValidateOpts: ValidateOptions = {
  silent: false,
  abortEarly: false,
}

type UseJoiResponse<T> = ValidationResult<T> & {
  validate: (options?: ValidateOptions) => Promise<ValidationResult<T>>
}

export type UseJoiOptions = {
  allowUnknown?: boolean
  autoRun?: boolean
}
const defaultJoiOpts: UseJoiOptions = {
  allowUnknown: true,
}
export const useJoi = <T>(
  schema: Joi.ObjectSchema<T>,
  input: T | T[],
  options: UseJoiOptions = {}
): UseJoiResponse<T> => {
  const values = useMemo(() => (isArray(input) ? input : [input]), [input])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hookOpts = useMemo(() => ({ ...defaultJoiOpts, ...options }), [JSON.stringify(options)])
  const [autoUpdate, setAutoUpdate] = useState(options.autoRun ?? false)

  const [errors, setErrors] = useState<ValidationErrors<T>>({})
  const [runOnce, setRunOnce] = useState(false)
  const isValid = runOnce && !hasErrors(errors)

  const validate = useCallback(
    async (opts: ValidateOptions = {}) => {
      const { silent, ...joiOpts } = { ...defaultValidateOpts, ...opts }
      const validationOptions: Joi.AsyncValidationOptions = {
        ...hookOpts,
        ...joiOpts,
      }
      let errors: ValidationErrors<T> = {}
      await Promise.all(values.map(async (value) => {
        try {
          await schema.validateAsync(value, validationOptions)
        } catch (error) {
          if (error instanceof Joi.ValidationError) {
            errors = transformJoiErrors(error)
          } else {
            throw error
          }
        }
        if (!silent) setErrors(errors)
      }))

      if (!silent) {
        setAutoUpdate(true)
        setRunOnce(true)
      }

      return {
        errors,
        isValid: !hasErrors(errors),
      }
    },
    [hookOpts, schema, values]
  )
  useEffect(() => {
    if (autoUpdate) {
      validate()
    }
  }, [autoUpdate, validate, values])

  return {
    errors,
    isValid,
    validate,
  }
}

function transformJoiErrors<T>(joiError: Joi.ValidationError): ValidationErrors<T> {
  const errors: ValidationErrors<T> = {}

  for (let err of joiError.details) {
    const key = err.path[0] as any
    errors[key] = err.message
  }

  return errors
}

function hasErrors<T>(errorObj: ValidationErrors<T>): boolean {
  return Object.keys(errorObj).length > 0
}

// https://github.com/sideway/joi/blob/master/lib/types/string.js#L688
export const deualtJoiMessages = {
  'any.required': '{{#label}} ist ein Pflichtfeld',
  'string.alphanum': '{{#label}} must only contain alpha-numeric characters',
  'string.base': '{{#label}} ist ein Pflichtfeld',
  'string.base64': '{{#label}} must be a valid base64 string',
  'string.creditCard': '{{#label}} must be a credit card',
  'string.dataUri': '{{#label}} must be a valid dataUri string',
  'string.domain': '{{#label}} must contain a valid domain name',
  'string.email': '{{#label}} must be a valid email',
  'string.empty': '{{#label}} ist ein Pflichtfeld',
  'string.guid': '{{#label}} must be a valid GUID',
  'string.hex': '{{#label}} must only contain hexadecimal characters',
  'string.hexAlign': '{{#label}} hex decoded representation must be byte aligned',
  'string.hostname': '{{#label}} must be a valid hostname',
  'string.ip': '{{#label}} must be a valid ip address with a {{#cidr}} CIDR',
  'string.ipVersion':
    '{{#label}} must be a valid ip address of one of the following versions {{#version}} with a {{#cidr}} CIDR',
  'string.isoDate': '{{#label}} must be in iso format',
  'string.isoDuration': '{{#label}} must be a valid ISO 8601 duration',
  'string.length': '{{#label}} length must be {{#limit}} characters long',
  'string.lowercase': '{{#label}} must only contain lowercase characters',
  'string.max': '{{#label}} length must be less than or equal to {{#limit}} characters long',
  'string.min': '{{#label}} length must be at least {{#limit}} characters long',
  'string.normalize': '{{#label}} must be unicode normalized in the {{#form}} form',
  'string.token': '{{#label}} must only contain alpha-numeric and underscore characters',
  'string.pattern.base': '{{#label}} with value {:[.]} fails to match the required pattern: {{#regex}}',
  'string.pattern.name': '{{#label}} with value {:[.]} fails to match the {{#name}} pattern',
  'string.pattern.invert.base': '{{#label}} with value {:[.]} matches the inverted pattern: {{#regex}}',
  'string.pattern.invert.name': '{{#label}} with value {:[.]} matches the inverted {{#name}} pattern',
  'string.trim': '{{#label}} must not have leading or trailing whitespace',
  'string.uri': '{{#label}} must be a valid uri',
  'string.uriCustomScheme': '{{#label}} must be a valid uri with a scheme matching the {{#scheme}} pattern',
  'string.uriRelativeOnly': '{{#label}} must be a valid relative uri',
  'string.uppercase': '{{#label}} must only contain uppercase characters',
  'number.base': '{{#label}} ist ein Pflichtfeld',
}

export const defaultJoiUnlabledMessages = {
  'any.required': 'Pflichtfeld',
  'string.base': 'Pflichtfeld',
}
