import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Form, Spinner } from '../../../components'
import {
  Customer_Type_Enum,
  Dealer_Vehicle_Type_Enum,
  Sales_Type_Enum,
  useInsertSalesReportMutation,
} from '../../../gql'
import { mergeDeepWith } from 'ramda'
import { useFormFields } from '@w2solutions/react-hooks'
import { useSalesReportGeneralFormular } from './useFormular'
import { SalesReport, SalesReportContext } from '../SalesReportContext'
import { PulseForm } from '../../PulseForm'
import { useEzAuth } from '@lib/ezauth'

export type SalesReportGeneralFormData = Pick<
  SalesReport,
  | 'customer_type'
  | 'customer_name'
  | 'customer_number'
  | 'zip_code'
  | 'sales_type'
  | 'list_price'
  | 'selling_price'
  | 'price_discussed_with'
  | 'vehicle_type'
  | 'job_number'
  | 'vin'
  | 'brand_id'
  | 'model_name'
  | 'handover_planned_date'
  | 'exchange_vin'
  | 'exchange_brand_id'
  | 'exchange_model_name'
  | 'exchange_price'
  | 'expected_sales_price'
  | 'seller_id'
>

interface GeneralDataProps {
  onClose: () => void
}

export const GeneralData = (props: GeneralDataProps) => {
  const { data, loading } = useContext(SalesReportContext)

  const [insert] = useInsertSalesReportMutation()

  const [editMode, setEditMode] = useState<boolean>(!data)

  useEffect(() => {
    setEditMode(!data)
  }, [data])

  const save = useCallback(
    async (values: SalesReportGeneralFormData) => {
      if (!data) {
        await insert({ variables: { object: values } })
      }
    },
    [data, insert]
  )
  const config = useSalesReportGeneralFormular(save, data)

  const handleSubmit = useCallback(async () => {
    try {
      await config.onSubmit()
      setEditMode(false)
      props.onClose()
    } catch (error) {
      console.warn('Could not save the sales report', error)
    }
  }, [config, props])

  if (loading) {
    return <Spinner />
  }
  return (
    <Form>
      <PulseForm sections={config.sections} disableNotes editMode={editMode} handleEdit={() => setEditMode(true)} />
      {!loading && editMode && (
        <Form.Actions
          onCancel={() => setEditMode(false)}
          onSave={handleSubmit}
          errors={Object.values(config?.errors).map(String)}
        />
      )}
    </Form>
  )
}

const defaultValues: SalesReportGeneralFormData = {
  customer_type: Customer_Type_Enum.Unknown,
  customer_name: null,
  customer_number: null,
  zip_code: null,
  sales_type: Sales_Type_Enum.Unknown,
  list_price: null,
  selling_price: null,
  price_discussed_with: null,
  vehicle_type: Dealer_Vehicle_Type_Enum.Unknown,
  job_number: null,
  vin: null,
  handover_planned_date: null,
  brand_id: null,
  model_name: null,
  exchange_vin: null,
  exchange_brand_id: null,
  exchange_model_name: null,
  exchange_price: null,
  expected_sales_price: null,
  seller_id: null,
}

export const useSalesReportGeneralFormValues = (data?: SalesReport) => {
  const [state] = useEzAuth()
  const initialValues: SalesReport = useMemo(() => {
    const val: SalesReportGeneralFormData = {
      customer_type: data?.customer_type,
      customer_name: data?.customer_name,
      customer_number: data?.customer_number,
      zip_code: data?.zip_code,
      sales_type: data?.sales_type,
      list_price: data?.list_price,
      selling_price: data?.selling_price,
      price_discussed_with: data?.price_discussed_with,
      vehicle_type: data?.vehicle_type,
      job_number: data?.job_number,
      vin: data?.vin,
      brand_id: data?.brand_id,
      model_name: data?.model_name,
      exchange_vin: data?.exchange_vin,
      exchange_brand_id: data?.exchange_brand_id,
      exchange_model_name: data?.exchange_model_name,
      exchange_price: data?.exchange_price,
      expected_sales_price: data?.expected_sales_price,
      seller_id: data?.seller_id ?? state.user.id,
      handover_planned_date: data?.handover_planned_date,
    }
    return mergeDeepWith((a, b) => a ?? b, val, defaultValues)
  }, [state.user.id, data])
  return useFormFields(initialValues, { resetOnInitialValueChange: true })
}
