import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { mapVehicleFormValues, VehicleDetailContext } from '../index'
import {
  Customer_Type_Enum,
  Dealer_Vehicle_State_Enum,
  Dealer_Vehicle_Type_Enum,
  In_Buy_Channel_Enum,
  Order_State_Enum,
  Registration_State_Enum,
  useUpdateManyVehiclesMutation,
  Vehicle_Set_Input,
} from '../../../gql'
import { splitVehicleId } from '../../ListConfig/Vehicles/List'
import { useFormFields } from '@w2solutions/react-hooks'
import { DataSource } from '../../../components/DataValue'
import { DealerVehicleStateSelect } from '../../DealerVehicleStateSelect'
import { OrderStateSelect } from '../../OrderStateSelect'
import { TextField } from '../../../components'
import { StorageLocationSelect } from '../../StorageLocationSelect'
import { InBuyChannelSelect } from '../../InBuyChannel/Select'
import { useEzAuthUserHasRoles, UserRole } from '@lib/ezauth'
import { DealerVehicleTypeSelect } from '../../DealerVehicleTypeSelect'
import { RegistrationStateSelect } from '../../RegistrationStateSelect'
import { UserSelect } from '../../UserSelect'
import { DatePicker } from '../../../components/DatePicker'
import { CustomerTypeSelect } from '../../CustomerTypeSelect'

export const useVehicleBulkFormular = (ids: string[]) => {
  const { metadata } = useContext(VehicleDetailContext)
  const [update] = useUpdateManyVehiclesMutation()
  const { bindings, values, setValue, reset } = useFormFields(initialData)

  useEffect(() => {
    reset()
  }, [ids, reset])

  const isAdmin = useEzAuthUserHasRoles(UserRole.ADMIN)
  const onSubmit = useCallback(async () => {
    if (!ids || !ids.length || ids.length === 0) return
    const _set = mapVehicleFormValues<Vehicle_Set_Input>(values, {}, ([key, value]) => {
      if (value !== '' && value !== null) return [key, value]
      return [key, undefined]
    })
    await update({
      variables: {
        updates: ids.map((composedId) => {
          const [id, , business_case_number]: any[] = splitVehicleId(composedId)
          return {
            _set,
            where: { id: { _eq: id }, version: { _eq: 0 }, business_case_number: { _eq: business_case_number } },
          }
        }),
      },
    })
  }, [ids, update, values])

  return useMemo(
    () => ({
      sections: [
        {
          name: 'state',
          label: 'Status',
          column: 1,
          fields: [
            {
              source: DataSource.PCORE,
              label: 'Status',
              name: 'state',
              FormComponent: (
                <DealerVehicleStateSelect value={values.state} onSelect={(state) => setValue('state', state)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'order_state',
              label: 'Transportstatus',
              FormComponent: (
                <OrderStateSelect value={values.order_state} onSelect={(state) => setValue('order_state', state)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'handover_planned_date',
              label: 'Auslieferungsdatum',
              FormComponent: (
                <DatePicker
                  value={values.handover_planned_date}
                  onChange={(date) => setValue('handover_planned_date', date.toISOString())}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'handover_date',
              label: 'Erfassungsdatum',
              FormComponent: (
                <DatePicker
                  value={values.handover_date}
                  onChange={(date) => setValue('handover_date', date.toISOString())}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'handover_by',
              label: 'Ausgeliefert von',
              FormComponent: (
                <UserSelect value={values.handover_by_id} onSelect={(user) => setValue('handover_by_id', user.id)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'delivery_date_str',
              label: 'Lieferdatum',
              FormComponent: <TextField {...bindings.delivery_date_str} />,
            },
            {
              source: DataSource.PCORE,
              name: 'storage_location',
              label: 'Lagerort',
              FormComponent: (
                <StorageLocationSelect
                  value={values.storage_location_id}
                  onSelect={(id, { default_note }) => {
                    setValue('storage_location_id', id)
                    setValue('storage_location_note', default_note ?? '')
                  }}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'storage_location_note',
              label: 'Lagerort Notiz',
              FormComponent: <TextField {...bindings.storage_location_note} />,
            },
            {
              source: DataSource.PCORE,
              name: 'delivery_location',
              label: 'Lieferort',
              FormComponent: (
                <StorageLocationSelect
                  value={values.delivery_location_id}
                  onSelect={(id) => setValue('delivery_location_id', id)}
                />
              ),
            },
          ],
        },
        {
          name: 'info',
          label: 'Fahrzeuginformation',
          column: 1,
          fields: [
            {
              source: DataSource.PCORE,
              name: 'specs',
              label: 'Ausstattung',
              FormComponent: <TextField multiline {...bindings.specs} maxRows={3} minRows={3} />,
            },
            {
              source: DataSource.PCORE,
              name: 'spec_level',
              label: 'Ausstattungslevel',
              FormComponent: <TextField {...bindings.spec_level} />,
            },
            {
              source: DataSource.PCORE,
              name: 'type',
              label: 'Typ',
              FormComponent: <DealerVehicleTypeSelect value={values.type} onSelect={(val) => setValue('type', val)} />,
            },
            {
              source: DataSource.PCORE,
              name: 'min_holding_period',
              label: 'Haltefrist',
              FormComponent: (
                <TextField
                  {...bindings.min_holding_period}
                  className="flex-1"
                  type="number"
                  onChange={({ currentTarget }) => {
                    setValue('min_holding_period', currentTarget.value ? Number(currentTarget.value) : null)
                  }}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'registration_state',
              label: 'Zulassungs Status',
              FormComponent: (
                <RegistrationStateSelect
                  value={values.registration_state}
                  onSelect={(state) => setValue('registration_state', state)}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'registration_owner',
              label: 'ZL Besitzer',
              FormComponent: <TextField {...bindings.registration_owner} />,
            },
            {
              source: DataSource.PCORE,
              name: 'key_kabinet_number',
              label: 'Schlüsselschrank Nr',
              FormComponent: <TextField {...bindings.key_kabinet_number} />,
            },
          ],
        },
        {
          name: 'sell',
          label: 'Verkauf',
          column: 2,
          fields: [
            {
              source: DataSource.PCORE,
              name: 'sold_by',
              label: 'Verkäufer',
              FormComponent: (
                <UserSelect value={values.sold_by_id} onSelect={(user) => setValue('sold_by_id', user.id)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'customer',
              label: 'Kunde',
              FormComponent: <TextField {...bindings.customer} />,
            },
            {
              source: DataSource.PCORE,
              name: 'customer_type',
              label: 'Kundentyp',
              FormComponent: (
                <CustomerTypeSelect value={values.customer_type} onSelect={(type) => setValue('customer_type', type)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'sold_at',
              label: 'Datum',
              FormComponent: (
                <DatePicker value={values.sold_at} onChange={(date) => setValue('sold_at', date.toISOString())} />
              ),
            },
          ],
        },
        {
          name: 'reservation',
          column: 2,
          label: 'Reservierung',
          fields: [
            {
              source: DataSource.PCORE,
              name: 'reserved_by',
              label: 'Reserviert von',
              FormComponent: (
                <UserSelect value={values.reserved_by_id} onSelect={(user) => setValue('reserved_by_id', user.id)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'reserved_for',
              label: 'Reserviert für',
              FormComponent: <TextField {...bindings.reserved_for} />,
            },
            {
              source: DataSource.PCORE,
              name: 'reserved_for_customer_type',
              label: 'Reserviert für (Typ)',
              FormComponent: (
                <CustomerTypeSelect
                  value={values.reserved_for_customer_type}
                  onSelect={(type) => setValue('reserved_for_customer_type', type)}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'reserved_at',
              label: 'Reserviert am',
              FormComponent: (
                <DatePicker
                  value={values.reserved_at}
                  onChange={(date) => setValue('reserved_at', date.toISOString())}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'reserved_until',
              label: 'Reserviert bis',
              FormComponent: (
                <DatePicker
                  value={values.reserved_until}
                  onChange={(date) => setValue('reserved_until', date.toISOString())}
                />
              ),
            },
          ],
        },
        {
          name: 'purchase',
          column: 2,
          label: 'Zukauf',
          fields: [
            {
              source: DataSource.PCORE,
              name: 'in_buy_channel',
              label: 'Zukaufs Kanal',
              FormComponent: (
                <InBuyChannelSelect
                  value={values.in_buy_channel}
                  onSelect={(val) => setValue('in_buy_channel', val)}
                  disabled={!isAdmin}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'in_buy_contract_date',
              label: 'Auftragsdatum / Einkaufsvertragsdatum',
              FormComponent: (
                <DatePicker
                  value={values.in_buy_contract_date}
                  onChange={(date) => setValue('in_buy_contract_date', date.toISOString())}
                />
              ),
            },
          ],
        },
        {
          name: 'metafields',
          label: 'Markenspezifische Informationen',
          column: 2,
          fields: metadata?.metafields?.map((metafield) => ({
            source: DataSource.PCORE,
            name: metafield.vehicle_metafield.column_name,
            label: metafield.vehicle_metafield.title,
            FormComponent: (
              <TextField
                {...bindings[metafield.vehicle_metafield.column_name]}
                onChange={(evt) => setValue(metafield.vehicle_metafield.column_name as any, evt.target.value)}
              />
            ),
          })),
        },
      ],
      onSubmit,
    }),
    [bindings, isAdmin, metadata?.metafields, onSubmit, setValue, values]
  )
}

const initialData = {
  in_buy_channel: null as In_Buy_Channel_Enum,
  state: null as Dealer_Vehicle_State_Enum,
  order_state: null as Order_State_Enum,
  delivery_date_str: '',
  storage_location_id: '',
  storage_location_note: '',
  delivery_location_id: '',
  specs: '',
  spec_level: '',
  type: null as Dealer_Vehicle_Type_Enum,
  min_holding_period: null as number,
  registration_state: null as Registration_State_Enum,
  registration_owner: '',
  key_kabinet_number: '',
  sold_by_id: '',
  sold_at: null as string,
  customer: '',
  customer_type: null as Customer_Type_Enum,

  control_number: '',
  accessories_mounted: null as boolean,
  order_code: '',
  reserved_by_id: '',
  reserved_for: '',
  reserved_for_customer_type: null as Customer_Type_Enum,
  handover_date: null as string,
  handover_planned_date: null as string,
  handover_by_id: null as string,
  reserved_at: null as string,
  reserved_until: null as string,
  in_buy_contract_date: null as string,
}
