import React, { CSSProperties } from 'react'
import { Ticket_State_Enum } from '../../gql'
import { useTranslation } from 'react-i18next'
import { Select } from '../../components'

interface TicketStateSelectProps {
  value: Ticket_State_Enum
  onSelect: (state: Ticket_State_Enum) => void
  className?: string
  style?: CSSProperties
}

export const TicketStateSelect = (props: TicketStateSelectProps) => {
  const { t } = useTranslation()

  return (
    <Select
      name={'ticketStateSelect'}
      value={props.value}
      onChange={(evt) => {
        evt.stopPropagation()
        props.onSelect(evt.target.value as Ticket_State_Enum)
      }}
      renderSelectValueItem={(state) => t(`TicketState.${state}`)}
      style={props.style}
      className={props.className}
    >
      {Object.keys(Ticket_State_Enum).map((state) => (
        <Select.Option key={state} value={Ticket_State_Enum[state]}>
          {t(`TicketState.${Ticket_State_Enum[state]}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
