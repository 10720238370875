import { Chip, ChipProps } from 'components'
import { Asset_Watchdog_Link_Status_Enum } from 'gql/generated'
import React from 'react'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

interface AssetWatchdogStatusChipProps {
  status: Asset_Watchdog_Link_Status_Enum
  className?: string
  style?: CSSProperties
}

export const AssetWatchdogStatusChip = (props: AssetWatchdogStatusChipProps) => {
  const { t } = useTranslation()
  const { status } = props
  let color: ChipProps['color'] = 'default'
  switch (status) {
    case Asset_Watchdog_Link_Status_Enum.Dead:
      color = 'danger'
      break
    case Asset_Watchdog_Link_Status_Enum.Ok:
      color = 'success'
      break
    case Asset_Watchdog_Link_Status_Enum.Updated:
      color = 'warning'
      break
  }
  return (
    <Chip variant="filled" color={color} style={props.style} className={props.className}>
      {t(`AssetWatchdogLinkStatus.${status}`)}
    </Chip>
  )
}
