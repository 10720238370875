import React, { CSSProperties } from 'react'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

export interface ButtonProps {
  onClick?: MuiButtonProps['onClick']
  type?: MuiButtonProps['type']
  variant?: 'contained' | 'outlined' | 'text'
  color?: 'primary' | 'secondary' | 'inherit' | 'error'
  size?: 'small' | 'medium'
  disabled?: boolean
  loading?: boolean
  startIcon?: MuiButtonProps['startIcon']
  endIcon?: MuiButtonProps['endIcon']
  className?: string
  style?: CSSProperties
  'aria-label'?: string
  'aria-controls'?: string
  'aria-haspopup'?: boolean | 'dialog' | 'menu' | 'grid' | 'true' | 'false' | 'listbox' | 'tree'
}

export const Button: React.FC<ButtonProps> & { muiName: string } = (props) => {
  const { children, loading, ...rest } = props
  return (
    <MuiButton {...rest} disabled={rest.disabled || loading}>
      {children}
      {loading && (
        <CircularProgress
          disableShrink={true}
          size={24}
          className={'absolute top-1/2 left-1/2 -ml-3 -mt-3'}
        />
      )}
    </MuiButton>
  )
}
Button.muiName = (MuiButton as any).muiName

Button.defaultProps = {
  variant: 'outlined',
  type: 'button',
  size: 'medium',
}
