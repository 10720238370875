import faker from 'faker/locale/de'
// import { DateResolver, DateTimeResolver, EmailAddressResolver } from 'graphql-scalars'
import { mergeResolvers } from '@graphql-tools/merge'

import * as brands from './brands'
import * as locations from './locations'

export const mocks = {
  ...brands.mocks,
  ...locations.mocks,
  Date: () => faker.date.recent(),
  DateTime: () => faker.date.recent(),
  EmailAddress: () => faker.internet.email(),
}

const commonResolver = {
  // Date: DateResolver,
  // DateTime: DateTimeResolver,
  // EmailAddress: EmailAddressResolver,
}

export const resolvers = mergeResolvers([commonResolver, brands.resovlers, locations.resolvers])
