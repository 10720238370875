import React, { CSSProperties } from 'react'
import { Ticket_Priority_Enum } from '../../gql'
import { useTranslation } from 'react-i18next'
import { Select } from '../../components'

interface PrioritySelectProps {
  value: Ticket_Priority_Enum
  onChange: (val: Ticket_Priority_Enum) => void
  className?: string
  style?: CSSProperties
}

export const PrioritySelect = (props: PrioritySelectProps) => {
  const { t } = useTranslation()

  const options = [
    { id: 'URGENT', name: Ticket_Priority_Enum.Urgent },
    {
      id: 'HIGH',
      name: Ticket_Priority_Enum.High,
    },
    { id: 'MEDIUM', name: Ticket_Priority_Enum.Medium },
    { id: 'LOW', name: Ticket_Priority_Enum.Low },
  ]
  const handleChange = async (evt) => {
    props.onChange(evt.target.value)
  }

  return (
    <Select value={props.value} onChange={handleChange} className={props.className} style={props.style}>
      {options.map((o) => (
        <Select.Option key={o.id} value={o.id}>
          {t(`TicketPriority.${o.name}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
