import { LocosoftSyncStatesPayload, LogLevel, MetricType, SyncPhase, SyncType } from 'gql'
import React, { createContext, Dispatch } from 'react'
import { DateFns } from 'utils'
import { AdminMonitoringEntitiesList } from './EntitiesList'
import { AdminMonitoringLogEntriesList } from './LogEntriesList'
import { AdminMonitoringMetricDataVisualisation } from './MetricDataVisualisation'
import { AdminMonitoringProgressCard } from './ProgressCard'

type SelectEntity = {
  type: 'selected-entity'
  payload: { entity: LocosoftSyncStatesPayload }
}

type SelectMetricSyncType = {
  type: 'selected-metric-sync-type'
  payload: { syncType: SyncType }
}

type SelectMetricDateFrom = {
  type: 'selected-metric-date-from'
  payload: { date: Date }
}

type SelectMetricDateUntil = {
  type: 'selected-metric-date-until'
  payload: { date: Date }
}

type SelectMetricType = {
  type: 'selected-metric-type'
  payload: { type: MetricType }
}

type SelectMetricPeriod = {
  type: 'selected-metric-period'
  payload: { period: number }
}

type SelectMetricPreset = {
  type: 'selected-metric-preset'
  payload: { minutes: number }
}

type FetchingMetricData = {
  type: 'fetching-metric-data'
}

type FetchedMetricData = {
  type: 'fetched-metric-data'
}

type SelectLogsDateFrom = {
  type: 'selected-logs-date-from'
  payload: { date: Date }
}

type SelectLogsDateUntil = {
  type: 'selected-logs-date-until'
  payload: { date: Date }
}

type SelectedLogsSyncType = {
  type: 'selected-logs-sync-type'
  payload: { syncType: SyncType }
}

type SelectedLogsLevel = {
  type: 'selected-logs-level'
  payload: { level: LogLevel }
}

type SelectLogsPreset = {
  type: 'selected-logs-preset'
  payload: { minutes: number }
}

type SelectMetricPhase = {
  type: 'selected-metric-phase'
  payload: { phase: SyncPhase }
}

type FetchingLogs = {
  type: 'fetching-logs'
}

type FetchedLogs = {
  type: 'fetched-logs'
}

type AdoptMetricFilterParamsForLogs = {
  type: 'adopt-metric-params'
  payload?: { level?: LogLevel }
}

type SelectLogsPhase = {
  type: 'selected-logs-phase'
  payload: { phase: SyncPhase }
}

type MonitoringReducerAction =
  | SelectEntity
  | SelectMetricSyncType
  | SelectMetricDateFrom
  | SelectMetricDateUntil
  | SelectMetricType
  | SelectMetricPeriod
  | SelectMetricPreset
  | FetchingMetricData
  | FetchedMetricData
  | SelectLogsDateFrom
  | SelectLogsDateUntil
  | SelectedLogsSyncType
  | SelectedLogsLevel
  | SelectLogsPreset
  | SelectMetricPhase
  | FetchingLogs
  | FetchedLogs
  | AdoptMetricFilterParamsForLogs
  | SelectLogsPhase

interface AdminMonitoringState {
  selectedSyncEntity: LocosoftSyncStatesPayload
  metricSyncType: SyncType
  metricDateFrom: Date
  metricDateUntil: Date
  metricPeriod: number
  metricType: MetricType
  metricPreset: number
  logsDateFrom: Date
  logsDateUntil: Date
  logsSyncType: SyncType
  logsLevel: LogLevel
  logsPreset: number
  graphShown: boolean
  graphLoading: boolean
  logsShown: boolean
  logsLoading: boolean
  metricPhase: SyncPhase
  logsPhase: SyncPhase
}

const monitoringReducer = (state: AdminMonitoringState, action: MonitoringReducerAction): AdminMonitoringState => {
  switch (action.type) {
    case 'selected-entity':
      return {
        ...state,
        selectedSyncEntity: action.payload.entity,
        graphShown: false,
        graphLoading: false,
        logsShown: false,
        logsLoading: false,
        metricPhase: action.payload.entity.phases === 2 ? SyncPhase.Prep : SyncPhase.Sync,
        logsPhase: null,
      }
    case 'selected-metric-sync-type':
      return {
        ...state,
        metricSyncType: action.payload.syncType,
        graphShown: false,
        graphLoading: false,
      }
    case 'selected-metric-phase':
      return {
        ...state,
        metricPhase: action.payload.phase,
        graphLoading: false,
        graphShown: false,
      }
    case 'selected-metric-date-from':
      return {
        ...state,
        metricDateFrom: action.payload.date,
        metricPreset: null,
        graphShown: false,
        graphLoading: false,
      }
    case 'selected-metric-date-until':
      return {
        ...state,
        metricDateUntil: action.payload.date,
        metricPreset: null,
        graphShown: false,
        graphLoading: false,
      }
    case 'selected-metric-type':
      return {
        ...state,
        metricType: action.payload.type,
        graphShown: false,
        graphLoading: false,
      }
    case 'selected-metric-period':
      return {
        ...state,
        metricPeriod: action.payload.period,
        graphShown: false,
        graphLoading: false,
      }
    case 'selected-metric-preset':
      return {
        ...state,
        metricPreset: action.payload.minutes,
        metricDateFrom: DateFns.subMinutes(new Date(), action.payload.minutes),
        metricDateUntil: new Date(),
        graphShown: false,
        graphLoading: false,
      }
    case 'fetching-metric-data':
      return {
        ...state,
        graphLoading: true,
        graphShown: false,
      }
    case 'fetched-metric-data':
      return {
        ...state,
        graphLoading: false,
        graphShown: true,
      }
    case 'selected-logs-date-from':
      return {
        ...state,
        logsDateFrom: action.payload.date,
        logsPreset: null,
        logsShown: false,
        logsLoading: false,
      }
    case 'selected-logs-date-until':
      return {
        ...state,
        logsDateUntil: action.payload.date,
        logsPreset: null,
        logsShown: false,
        logsLoading: false,
      }
    case 'selected-logs-sync-type':
      return {
        ...state,
        logsSyncType: action.payload.syncType,
        logsShown: false,
        logsLoading: false,
      }
    case 'selected-logs-level':
      return {
        ...state,
        logsLevel: action.payload.level,
        logsShown: false,
        logsLoading: false,
      }
    case 'selected-logs-preset':
      return {
        ...state,
        logsPreset: action.payload.minutes,
        logsDateFrom: DateFns.subMinutes(new Date(), action.payload.minutes),
        logsDateUntil: new Date(),
        logsShown: false,
        logsLoading: false,
      }
    case 'selected-logs-phase':
      return {
        ...state,
        logsShown: false,
        logsLoading: false,
        logsPhase: action.payload.phase,
      }
    case 'fetching-logs':
      return {
        ...state,
        logsLoading: true,
        logsShown: false,
      }
    case 'fetched-logs':
      return {
        ...state,
        logsLoading: false,
        logsShown: true,
      }
    case 'adopt-metric-params':
      return {
        ...state,
        logsDateFrom: state.metricDateFrom,
        logsDateUntil: state.metricDateUntil,
        logsSyncType: state.metricSyncType,
        logsPreset: state.metricPreset,
        logsShown: false,
        logsLoading: false,
        logsPhase: state.metricPhase,
        logsLevel: action.payload?.level ?? state.logsLevel,
      }
  }
}

const initialMonitoringState: AdminMonitoringState = {
  selectedSyncEntity: null,
  metricSyncType: SyncType.Full,
  metricDateFrom: null,
  metricDateUntil: null,
  metricPeriod: 300,
  metricType: null,
  metricPreset: null,
  logsDateFrom: null,
  logsDateUntil: null,
  logsSyncType: null,
  logsLevel: null,
  logsPreset: null,
  graphShown: false,
  graphLoading: false,
  logsShown: false,
  logsLoading: false,
  metricPhase: null,
  logsPhase: null,
}

interface MonitoringFilterContextProps {
  state: AdminMonitoringState
  dispatch: Dispatch<MonitoringReducerAction>
}

export const MonitoringFilterContext = createContext<MonitoringFilterContextProps>({
  state: initialMonitoringState,
  dispatch: () => {},
})

interface AdminMonitoringComponents {
  EntitiesList: React.FC
  ProgressCard: React.FC
  DataPlot: React.FC
  LogEntries: React.FC
}

export const AdminMonitoringFilter: React.FC & AdminMonitoringComponents = ({ children }) => {
  const [state, dispatch] = React.useReducer(monitoringReducer, initialMonitoringState)
  return <MonitoringFilterContext.Provider value={{ state, dispatch }}>{children}</MonitoringFilterContext.Provider>
}

AdminMonitoringFilter.EntitiesList = AdminMonitoringEntitiesList
AdminMonitoringFilter.ProgressCard = AdminMonitoringProgressCard
AdminMonitoringFilter.DataPlot = AdminMonitoringMetricDataVisualisation
AdminMonitoringFilter.LogEntries = AdminMonitoringLogEntriesList
