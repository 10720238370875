import React, { CSSProperties, useCallback, useRef } from 'react'
import { Department, useDepartmentSelectDataQuery } from '../../gql'
import { Select } from '../../components'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'

interface DepartmentSelectProps {
  value: string
  onSelect: (department: Department) => void
  className?: string
  style?: CSSProperties
  withLabel?: boolean
}

export const DepartmentSelect = (props: DepartmentSelectProps) => {
  const query = useDepartmentSelectDataQuery({ fetchPolicy: 'network-only' })
  const openNewDepartmentDetailView = useNewDepartment()

  let data = query.data
  const options = data?.departments.map((department) => ({ id: department.id, name: department.name })) ?? []

  options?.sort((a, b) => (a.name < b.name ? -1 : 1))

  const handleChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value === 'NEW') {
      try {
        const department = await openNewDepartmentDetailView()
        const { data: newData } = await query.refetch()
        data = newData
        evt.target.value = department.id
      } catch {
        return
      }
    }
    let id = evt.target.value
    props.onSelect(data?.departments.find((department) => department.id === id) as Department)
  }
  return (
    <>
      <Select
        value={props.value}
        onChange={handleChange}
        className={props.className}
        style={props.style}
        label={props.withLabel && 'Bereich'}
        renderSelectValueItem={(id) => data?.departments.find((dep) => dep.id === id)?.name}
      >
        <Select.Option key={'NEW'} value="NEW">
          Neuen Bereich anlegen...
        </Select.Option>
        {options.map((o) => (
          <Select.Option key={o.id} value={o.id}>
            {o.name}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

const useNewDepartment = () => {
  const resolveRef = useRef<(data: Omit<Department, 'roles_aggregate'>) => void>(null)
  const rejectRef = useRef<() => void>(null)
  const {openNestedDetailView, closeDetailView} = useDetailView()
  return useCallback(async () => {
    const prom = new Promise<Omit<Department, 'roles_aggregate'>>((res, rej) => {
      resolveRef.current = res
      rejectRef.current = rej
    }).finally(() => closeDetailView(DetailViewEnum.DepartmentDetail))
    openNestedDetailView(DetailViewEnum.DepartmentDetail, {
      onClose: () => rejectRef.current && rejectRef.current(),
      onSave: (dep) => resolveRef.current && resolveRef.current(dep),
    })
    return prom
  }, [closeDetailView, openNestedDetailView])
}
