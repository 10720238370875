import React, { CSSProperties } from 'react'
import { Icon, Menu, TextField } from 'components'
import * as L from 'layouts'


interface ActionBarProps {
  searchQuery: string
  onSearchQueryChange: (query: string) => void
  menuDisabled?: boolean
}

export const ActionBar: React.FC<ActionBarProps> = props => {
  const { searchQuery, onSearchQueryChange } = props
  return (
    <L.Horizontal style={{ justifyContent: 'space-between' }}>
      <TextField
        placeholder={'Beschreibung, Marken, Modell Code'}
        value={searchQuery}
        onChange={(evt) => onSearchQueryChange(evt.currentTarget.value)}
        style={{ width: 260 }}
        autoFocus
      />
    </L.Horizontal>
  )
}

interface ActionMenuProps {
  onBulkEdit: () => void
  disabled?: boolean
  style?: CSSProperties
}

export const ActionMenu: React.FC<ActionMenuProps> = props => {
  return (
    <Menu>
      <Menu.IconButton aria-label="actions" style={props.style}>
        <Icon name="options" />
      </Menu.IconButton>
      <Menu.ItemList>
        <Menu.Item disabled={props.disabled} onClick={props.onBulkEdit}>
          <Icon name="edit" />
          <span>{'Alle bearbeiten'}</span>
        </Menu.Item>
      </Menu.ItemList>
    </Menu>
  )
}