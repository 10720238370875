import React, { CSSProperties } from 'react'
import { Select } from 'components'
import { Order_State_Enum } from 'gql'
import { useTranslation } from 'react-i18next'

interface OrderStateSelectProps {
  value: Order_State_Enum
  onSelect: (type: Order_State_Enum) => void
  name?: string
  label?: string
  disabled?: boolean
  style?: CSSProperties
}

export const OrderStateSelect: React.FC<OrderStateSelectProps> = (props) => {
  const { value, onSelect, name, style, disabled } = props
  const { t } = useTranslation()

  return (
    <Select
      name={name ?? 'orderStateSelect'}
      value={value}
      label={props.label}
      onChange={(evt) => {
        evt.stopPropagation()
        onSelect(evt.target.value as Order_State_Enum)
      }}
      renderSelectValueItem={(orderState) => t(`OrderState.${orderState}`)}
      disabled={disabled}
      style={style}
    >
      {Object.keys(Order_State_Enum).map((orderState) => (
        <Select.Option key={orderState} value={Order_State_Enum[orderState]}>
          {t(`OrderState.${Order_State_Enum[orderState]}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
