import React from 'react'
import { TicketsListConfig, TicketsListType } from '../../containers/ListConfig/Tickets/List/Config'
import { TicketsList } from '../../containers/ListConfig/Tickets/List'

export const HomePage = () => {
  return (
    <>
      <TicketsListConfig type={TicketsListType.ASSIGNED_ME}>
        <TicketsList />
      </TicketsListConfig>
    </>
  )
}
