import React, { CSSProperties } from 'react'
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton'
import Fab from '@mui/material/Fab'
import { Tooltip } from 'components'

export interface IconButtonProps {
  'aria-label': string
  type?: MuiIconButtonProps['type']
  color?: 'primary' | 'secondary' | 'inherit'
  variant?: 'contained' | 'default'
  onClick?: MuiIconButtonProps['onClick']
  size?: MuiIconButtonProps['size']
  disabled?: boolean
  className?: string
  style?: CSSProperties
  tooltip?: string
  children: React.ReactNode
}

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const { variant, tooltip, className, style, ...rest } = props
  let icon: JSX.Element

  const btnProps: Pick<IconButtonProps, 'className' | 'style'> = {}
  if (!tooltip) {
    btnProps.className = props.className
    btnProps.style = props.style
  }
  switch (variant) {
    case 'default':
      icon = <MuiIconButton {...rest} {...btnProps} />
      break
    case 'contained':
      icon = <Fab {...rest} {...btnProps} />
      break
    default:
      throw new Error(`IconButton variant '${variant}' not implementd`)
  }

  if (tooltip) {
    icon = (
      <div className={props.className} style={props.style}>
        <Tooltip content={tooltip}>{icon}</Tooltip>
      </div>
    )
  }

  return icon
}

IconButton.defaultProps = {
  variant: 'default',
  type: 'button',
}

// @ts-ignore
IconButton.muiName = MuiIconButton.muiName
