import { SalesReportGeneralFormData } from './index'
import { useMemo } from 'react'
import Joi from 'joi'
import {deualtJoiMessages, useJoi} from '../../../hooks/useJoi'
import { Customer_Type_Enum, Dealer_Vehicle_Type_Enum, Sales_Type_Enum } from '../../../gql'

export const useSalesReportGeneralValidationSchema = (values: SalesReportGeneralFormData) => {
  const validationSchema = useMemo(() => {
    return Joi.object({
      vehicle_type: Joi.string()
        .label('Fahzeugtyp')
        .required()
        .valid(...Object.values(Dealer_Vehicle_Type_Enum)),
      brand_id: Joi.string().label('Marke').required(),
      model_name: Joi.string().label('Modell').required(),
      vin: Joi.string().label('FIN').required(),
      list_price: Joi.number().label('Listenpreis').required(),
      selling_price: Joi.number().label('Verkaufspreis').required(),
      expected_sales_price: Joi.number().label('Voraussichtlicher Verkaufspreis').required(),
      seller_id: Joi.string().guid().label('Verkäufer').required(),
      sales_type: Joi.string()
        .label('Geschäftstyp')
        .required()
        .valid(...Object.values(Sales_Type_Enum)),
      customer_type: Joi.string()
        .label('Kundentyp')
        .required()
        .valid(...Object.values(Customer_Type_Enum)),
      customer_name: Joi.string().label('Kundenname').required(),
      zip_code: Joi.string().label('PLZ').required(),
    }).messages(deualtJoiMessages)
  }, [])
  return useJoi(validationSchema, values)
}
