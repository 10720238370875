import React, { CSSProperties } from 'react'
import { default as MuiAutocomplete, createFilterOptions } from '@mui/material/Autocomplete'
import { FilterOptionsState, TextField } from '@mui/material'

interface OptionType {
  value: string
  inputValue?: string
}

interface AutocompleteProps {
  value: string
  onChange: (value: string) => void
  options: OptionType[]
  className?: string
  style?: CSSProperties
}

const filter = createFilterOptions<OptionType | null>()

export const Autocomplete = (props: AutocompleteProps) => {
  const handleChange = (event: React.SyntheticEvent, value: string | OptionType) => {
    if (typeof value === 'string') {
      props.onChange(value)
    } else if (value && value.inputValue) {
      props.onChange(value.inputValue)
    } else {
      props.onChange(value?.value)
    }
  }

  const filterOptions = (options: OptionType[], state: FilterOptionsState<OptionType>) => {
    const filtered = filter(options, state)
    const { inputValue } = state
    const isExisting = options.some((option) => inputValue === option.value)
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        value: inputValue,
      })
    }

    return filtered
  }
  const getOptionLabel = (option: string | OptionType) => {
    if (typeof option === 'string') {
      return option
    }
    if (option.inputValue) {
      return option.inputValue
    }
    return option.value
  }
  return (
    <MuiAutocomplete
      className={props.className}
      style={props.style}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text"
      value={props.value}
      onChange={handleChange}
      filterOptions={filterOptions}
      options={props.options}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option: OptionType) => <li {...props}>{option.value}</li>}
      freeSolo
      renderInput={(params) => <TextField {...params} />}
    />
  )
}
