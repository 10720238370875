import React, { useEffect, useMemo, useState } from 'react'
import * as L from 'layouts'
import { mergeDeepWith } from 'ramda'
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  User,
  Role_Enum,
  useUpdateUserMutation,
  useUserDetailLazyQuery,
} from 'gql'
import { useFormFields } from '@w2solutions/react-hooks'
import { Button, Form, Spinner, Switch, Tabs, TextField } from 'components'
import { UserRoleSelect } from './UserRoleSelect'
import { DataValue } from 'components/DataValue'
import { useFormatter } from 'hooks'
import { useConfirm } from 'components/Confirm'
import { TeamsList } from './TeamsList'
import { DetailWindow } from 'components/DetailWindow'

interface UserDetailProps {
  title: string
  onClose: () => void
  userId?: string
}

export const UserDetail = (props: UserDetailProps) => {
  const [load, loadResponse] = useUserDetailLazyQuery()
  useEffect(() => {
    if (props.userId) {
      load({ variables: { id: props.userId } })
    }
  }, [load, props.userId])
  const user = useMemo(() => loadResponse.data?.user, [loadResponse.data?.user])
  return (
    <DetailWindow onClose={props.onClose} width={700} title={props.title}>
      <Tabs>
        <Tabs.TabList>
          <Tabs.Tab label={'Grunddaten'} />
          <Tabs.Tab label={'Teams'} />
        </Tabs.TabList>
        <Tabs.TabPanes>
          <Tabs.TabPane>
            <UserDetailGeneralPane loading={loadResponse.loading} user={user} onClose={props.onClose} />
          </Tabs.TabPane>
          <Tabs.TabPane>{user && <UserTeamsPane userId={user.id} />}</Tabs.TabPane>
        </Tabs.TabPanes>
      </Tabs>
    </DetailWindow>
  )
}

// ##############################
// # UserDetailGeneralPane      #
// ##############################

interface UserDetailGeneralPaneProps {
  user?: Omit<User, 'teams' | 'teams_aggregate'>
  loading: boolean
  onClose: () => void
}

const UserDetailGeneralPane = (props: UserDetailGeneralPaneProps) => {
  const [deleteUser] = useDeleteUserMutation()

  const [ConfirmDialog, confirm] = useConfirm()

  const { formatDateTimeString } = useFormatter()
  const [saving, setSaving] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)

  const userToFormData = (user?: Omit<User, 'teams' | 'teams_aggregate'>): FormData => {
    if (!user) return initialData
    const val: FormData = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      isActive: user.isActive,
      abbreviation: user.abbreviation,
      primary_role: user.primary_role as Role_Enum,
    }
    return mergeDeepWith((a, b) => a ?? b, val, initialData)
  }

  const initialValues = useMemo(() => {
    return userToFormData(props.user)
  }, [props.user])

  const [save] = useSave(props.user?.id)
  const submit = async () => {
    setSaving(true)
    await save(values)
    setSaving(false)
    props.onClose()
  }

  const { bindings, values, setValue } = useFormFields(initialValues, { resetOnInitialValueChange: true })

  const deleteUserHandler = async () => {
    const { hide } = await confirm()
    setDeleting(true)
    await deleteUser({ variables: { id: props.user?.id } })
    setDeleting(false)
    hide()
    props.onClose()
  }

  if (props.loading || saving) {
    return <Spinner centered />
  }
  return (
    <div className="relative h-full">
      <L.Vertical spacing={4} className="p-8">
        {props.user && <DataValue label="Letzte Anmeldung">{formatDateTimeString(props.user?.last_login)}</DataValue>}

        <Switch
          className="place-self-start ml-0"
          label="Ist der Account aktiv?"
          labelPlacement="start"
          checked={values.isActive}
          onChange={(evt) => setValue('isActive', evt.target.checked)}
          name="isActiveSwitch"
        />

        <TextField required label="Email" {...bindings.email}></TextField>
        <L.Horizontal spacing={4}>
          <TextField label="Vorname" {...bindings.first_name}></TextField>
          <TextField label="Nachname" {...bindings.last_name}></TextField>
        </L.Horizontal>
        <L.Horizontal spacing={4}>
          <TextField label="Abkürzung" {...bindings.abbreviation}></TextField>
          <UserRoleSelect
            label="Primäre Rolle"
            required
            value={values.primary_role}
            onSelect={(role) => setValue('primary_role', role)}
          />
        </L.Horizontal>
        <L.Horizontal spacing={2}>
          <Button color={'primary'} variant="contained" onClick={submit} disabled={!values.email}>
            Speichern
          </Button>
          <Button variant="outlined" onClick={props.onClose}>
            Abbrechen
          </Button>
        </L.Horizontal>
      </L.Vertical>
      {props.user && (
        <Form.ActionBar>
          <Button disabled variant="contained" color="error" onClick={deleteUserHandler}>
            Account löschen
          </Button>
        </Form.ActionBar>
      )}
      <ConfirmDialog loading={deleting} confirmButtonText="Löschen">
        Mit dieser Aktion wird der User unwiderrufflich gelöscht
      </ConfirmDialog>
    </div>
  )
}

const initialData = {
  first_name: '',
  last_name: '',
  email: '',
  isActive: true,
  primary_role: Role_Enum.User,
  abbreviation: '',
}

type FormData = typeof initialData

const useSave = (id?: string) => {
  const [create] = useCreateUserMutation()
  const [update] = useUpdateUserMutation()

  const save = async (data: Partial<User>) => {
    if (id) {
      await update({
        variables: {
          id,
          _set: {
            first_name: data.first_name,
            last_name: data.last_name,
            isActive: data.isActive,
            email: data.email,
            abbreviation: data.abbreviation,
          },
          role: data.primary_role as Role_Enum,
        },
      })
    } else {
      await create({
        variables: {
          input: {
            role: data.primary_role,
            isActive: data.isActive,
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            abbreviation: data.abbreviation,
          },
        },
      })
    }
  }
  return [save] as const
}

// #################
// # UserTeamsPane #
// #################

interface UserTeamsPaneProps {
  userId: string
}

const UserTeamsPane = (props: UserTeamsPaneProps) => {
  return (
    <div className={'relative h-full'}>
      <TeamsList userId={props.userId} />
    </div>
  )
}
