import React, { CSSProperties } from 'react'

import MuiRadio from '@mui/material/Radio'
import MuiRadioGroup from '@mui/material/RadioGroup'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import MuiFormControl from '@mui/material/FormControl'
import MuiFormLabel from '@mui/material/FormLabel'

interface RadioGroupProps {
  value?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  name: string
  label?: string
  ['aria-label']: string
  className?: string
  style?: CSSProperties
  row?: boolean
}

interface RadioGroupComponent extends React.FC<RadioGroupProps> {
  Option: React.FC<RadioGroupOptionProps>
}

export const RadioGroup: RadioGroupComponent = (props) => {
  return (
    <MuiFormControl component="fieldset" className={props.className} style={props.style}>
      {props.label && <MuiFormLabel component="legend">{props.label}</MuiFormLabel>}
      <MuiRadioGroup row={props.row} aria-label={props['aria-label']} name={props.name} value={props.value} onChange={props.onChange}>
        {props.children}
      </MuiRadioGroup>
    </MuiFormControl>
  )
}

interface RadioGroupOptionProps {
  value?: string
  label?: string
  children?: never
  disabled?: boolean
}

const RadioGroupOption: React.FC<RadioGroupOptionProps> = (props) => {
  return (
    // @ts-ignore
    <MuiFormControlLabel control={<MuiRadio />} {...props} />
  )
}

RadioGroup.Option = RadioGroupOption
