import { ListConfig } from '../../index'
import { useVehicleListConfigs } from './list-configs'
import React from 'react'
import { Vehicle } from 'gql'

export enum VehiclesListType {
  IMPORT_NEW = 'importNew',
  IMPORT_USED = 'importUsed',
  NEW = 'new',
  LAST_SELLS = 'lastSells',
  MY_SELLS = 'mySells',
  MY_OPEN_HANDED_OVER = 'myOpenHandedOver',
  MY_HANDED_OVER = 'myHandedOver',
  MY_RESERVATIONS = 'myReservations',
  SORTED_BY_DAYS_SINCE_PURCHASE = 'sortedByDaysSincePurchase',
  DATA_QUALITY = 'dataQuality',
  MY_PREPARATIONS = 'myPreparations',
  MARKED_FOR_DELETE = 'markedForDelete'
}

interface VehiclesListConfigProps {
  type: VehiclesListType
}

export const VehiclesListConfig: React.FC<VehiclesListConfigProps> = (props) => {
  const vehicleListConfigs = useVehicleListConfigs()
  return (
    <ListConfig<Vehicle> type={props.type} {...vehicleListConfigs[props.type]}>
      {props.children}
    </ListConfig>
  )
}
