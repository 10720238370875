import { Icon } from 'components/Icon'
import { IconButton } from 'components/IconButton'
import { PaginationNavigationProps } from 'components/PaginationNavigation'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { Yalc2, Yalc2RowDefinition } from 'components/Yalc2'
import { useUpdateModelsMutation } from 'gql'
import React, { MouseEventHandler, useMemo, useState } from 'react'
import { DetailViewEnum, useDetailView } from '../../../components/DetailWindow/useDetailView'
import { SalesModelCodeInput } from './SalesModelCodeInput'

interface ModelListItem {
  id: string
  brandId: string
  brandModelCode: string
  salesModelCode?: string
  description: string
  modelGroupName?: string
}

interface AdminBrandModelListProps {
  title?: string
  data: ModelListItem[]
  selectedModelIds: string[]
  onSelectionChanged: (ids: string[]) => void
  onClickOutsideList?: () => void
  actionBar: React.ReactNode
  paginationNavigation: PaginationNavigationProps
}

export const AdminBrandModelList = (props: AdminBrandModelListProps) => {
  const rowDef = useRowDefinition()

  return (
    <Yalc2<ModelListItem>
      data={props.data}
      rowDefinition={rowDef}
      title={props.title}
      actionBar={props.actionBar}
      selectedIds={props.selectedModelIds}
      onSelectionChanged={props.onSelectionChanged}
      onClickOutsideList={props.onClickOutsideList}
      paginationNavigation={props.paginationNavigation}
    />
  )
}

const useRowDefinition = (): Yalc2RowDefinition<ModelListItem> => {
  return useMemo(() => {
    return {
      columns: [
        {
          width: 320,
          title: 'Beschreibung',
          cell: function Description(props) {
            return <Yalc2Cell.SimpleCell>{props.item.description}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Marken Modell Code',
          cell: function Description(props) {
            return (
              <Yalc2Cell.SimpleCell>
                {Yalc2Cell.horizontal(
                  <>
                    <span>{props.item.brandModelCode}</span>
                  </>
                )}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          width: 180,
          title: 'Sales Modell Code',
          cell: function Description(props) {
            const [update, response] = useUpdateModelsMutation()
            const [salesModelCode, setSalesModelCode] = useState('')
            const saveSalesModelCode = async (code: string) => {
              await update({
                variables: {
                  set: { sales_model_code: code },
                  where: { id: { _eq: props.item.id } },
                },
              })
            }
            const handleKeyPress = async (evt: React.KeyboardEvent) => {
              if (evt.key === 'Enter') {
                await saveSalesModelCode(salesModelCode)
              }
            }
            const copyToSalesModelCode: MouseEventHandler<HTMLButtonElement> = async (evt) => {
              evt.stopPropagation()
              await saveSalesModelCode(props.item.brandModelCode)
            }
            if (props.item.salesModelCode) {
              return <Yalc2Cell.SimpleCell>{props.item.salesModelCode}</Yalc2Cell.SimpleCell>
            }
            const loading = response.loading
            return (
              <Yalc2Cell.SimpleCell>
                <SalesModelCodeInput
                  onClick={copyToSalesModelCode}
                  value={salesModelCode}
                  onChange={(evt) => setSalesModelCode(evt.currentTarget.value)}
                  onKeyDown={handleKeyPress}
                  disabled={loading}
                />
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          width: 180,
          title: 'Gruppe',
          cell: function Description(props) {
            const { openNestedDetailView } = useDetailView()

            if (props.item.modelGroupName) {
              return <Yalc2Cell.SimpleCell>{props.item.modelGroupName}</Yalc2Cell.SimpleCell>
            }

            return (
              <div>
                <IconButton
                  aria-label="todo"
                  size="small"
                  tooltip="Gruppe zuweisen"
                  onClick={(evt) => {
                    evt.stopPropagation()
                    openNestedDetailView(DetailViewEnum.AssignModelGroupDetail, {
                      brandId: props.item.brandId,
                      modelId: props.item.id,
                    })
                  }}
                >
                  <Icon name="add" />
                </IconButton>
              </div>
            )
          },
        },
      ],
    }
  }, [])
}
