import React, {useEffect} from 'react'
import { useFormFields } from '@w2solutions/react-hooks'
import { Button, TextField, Typo, ValueText } from 'components'
import { DetailWindow } from 'components/DetailWindow'
import { useAdminModelGroupListQuery, useUpdateModelsMutation } from 'gql'
import * as L from 'layouts'
import styled from './ModelDetails.module.css'
import {ModelGroupReleaseYearSelect, ModelReleaseYear} from '../../ModelGroupReleaseYearSelect'
import { ModelGroupSelect } from '../../ModelGroupSelect'
import usePrevious from "@w2solutions/react-hooks/dist/usePrevious";

interface ModelBulkEditProps {
  modelIds: string[]
  brandId: string
  onClose: () => void
}

export const ModelBulkEdit = (props: ModelBulkEditProps) => {
  const groupsQuery = useAdminModelGroupListQuery({ variables: { brandId: props.brandId } })

  const { bindings, values, setValue, isDirty } = useFormFields({
    salesModelCode: '',
    modelGroupId: '',
    modelGroupReleaseYear: '',
  })
  const groups = groupsQuery.data?.model_groups ?? []
  const selectedGroup = groups.find((g) => g.id === values.modelGroupId)
  const prevModelGroupId = usePrevious(values.modelGroupId)
  useEffect(() => {
    if (prevModelGroupId !== values.modelGroupId && values.modelGroupId) {
      setValue('modelGroupReleaseYear', ModelReleaseYear.UNKNOWN.toString())
    }
  }, [
    setValue,
    values.modelGroupId,
    prevModelGroupId,
    selectedGroup?.default_release_year,
    values.modelGroupReleaseYear,
  ])

  const invalid = !!values.modelGroupId && !!!values.modelGroupReleaseYear
  const [update, updateReponse] = useUpdateModelsMutation()
  const handleSave = async () => {
    await update({
      variables: {
        set: {
          sales_model_code: values.salesModelCode,
          model_group_id: values.modelGroupId,
          model_group_release_year: Number(values.modelGroupReleaseYear),
        },
        where: { id: { _in: props.modelIds } },
      },
    })
    props.onClose()
  }
  const loading = updateReponse.loading
  return (
    <DetailWindow
      title="Modelle bearbeiten"
      onClose={props.onClose}
      width={620}
      className={styled.root}
      loading={loading}
      variant="persistent"
    >
      {!loading && (
        <L.Vertical spacing={5}>
          <Typo variant="h1" style={{ fontWeight: 500 }}>
            {'Selektierten Modelle bearbeiten'}
          </Typo>
          <L.Grid>
            <ValueText label="Sales Modell Code">
              <TextField {...bindings.salesModelCode} />
            </ValueText>
          </L.Grid>
          <L.Grid>
            <ValueText label="Modell Gruppe">
              <ModelGroupSelect
                groups={groups}
                hideLabel
                style={{ display: 'block', width: '100%' }}
                value={values.modelGroupId}
                onSelect={(modelGroupId) => setValue('modelGroupId', modelGroupId)}
              />
            </ValueText>
            <ValueText label={'Release Jahr'}>
              <ModelGroupReleaseYearSelect
                hideLabel
                group={selectedGroup}
                value={Number(values.modelGroupReleaseYear)}
                onSelect={(year) => setValue('modelGroupReleaseYear', String(year))}
              />
            </ValueText>
          </L.Grid>
          <div>
            <Button variant="contained" color="primary" onClick={handleSave} disabled={invalid || !isDirty}>
              Speichern
            </Button>
          </div>
        </L.Vertical>
      )}
    </DetailWindow>
  )
}
