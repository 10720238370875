import React, { CSSProperties } from 'react'
import * as L from 'layouts'
import { IconButton } from 'components/IconButton'
import { Icon } from 'components/Icon'
import { Typo } from 'components/Typo'
import clsx from 'clsx'

export interface PaginationNavigationProps {
  content?: {
    from: number
    to: number
    total: number
  }
  hasPreviousPage?: boolean
  hasNextPage?: boolean
  fetchPreviousPage: () => void
  fetchNextPage: () => void
  style?: CSSProperties
  className?: string
}

export const PaginationNavigation: React.FC<PaginationNavigationProps> = (props) => {
  const { hasPreviousPage, hasNextPage, fetchPreviousPage, fetchNextPage } = props

  return (
    <L.Horizontal className={clsx("p-2", props.className)}>
      <IconButton
        aria-label="previous-page"
        size="small"
        tooltip="vorherige Seite"
        onClick={fetchPreviousPage}
        disabled={!hasPreviousPage}
      >
        <Icon name="before" />
      </IconButton>
      <IconButton
        aria-label="next-page"
        size="small"
        tooltip="nächste Seite"
        disabled={!hasNextPage}
        onClick={fetchNextPage}
      >
        <Icon name="next" />
      </IconButton>
      {!!props?.content?.to && (
        <Typo
          className="whitespace-nowrap"
          variant="body2"
        >{`( ${props.content.from} - ${props.content.to} ) / ${props.content.total}`}</Typo>
      )}
    </L.Horizontal>
  )
}
