import React, { CSSProperties, ReactNode } from 'react'
import MuiAlert from '@mui/material/Alert'
import MuiAlertTitle from '@mui/material/AlertTitle'

import clsx from 'clsx'

type AlertBoxType = 'error' | 'warning' | 'success' | 'info'
type AlertBoxProps = {
  type: AlertBoxType
  children: ReactNode
  onClose?: () => void
  noTitle?: boolean
  noIcon?: boolean
  className?: string
  style?: CSSProperties
  action?: ReactNode
}

const titleMap = {
  error: 'Fehler',
  warning: 'Warnung',
  info: 'Info',
  success: 'Erfolgreich',
}

export const AlertBox = (props: AlertBoxProps) => {
  const { type, noTitle, noIcon, children: message, className, ...rest } = props
  const title = titleMap[type]

  return (
    <MuiAlert
      severity={type}
      className={clsx('py-2 px-4', className)}
      {...rest}
      icon={noIcon ? false : undefined}
      variant="standard"
      classes={{ message: 'flex-1' }}
    >
      {!noTitle && <MuiAlertTitle>{title}</MuiAlertTitle>}
      {message}
    </MuiAlert>
  )
}

export default AlertBox
