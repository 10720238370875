import React, { useEffect, useMemo, useCallback } from 'react'
import { DetailWindow } from '../../components/DetailWindow'
import { Form, TextField } from '../../components'
import { FormRow } from '../../components/FormRow'
import { UserRolesSelect } from '../UserRolesSelect'
import { useFormFields } from '@w2solutions/react-hooks'
import {
  DepartmentFieldsFragment,
  Role_Enum,
  useCreateNewDepartmentMutation,
  useDepartmentDetailLazyQuery,
  useUpdateDepartmentMutation,
} from '../../gql'
import { deualtJoiMessages, useJoi } from '../../hooks/useJoi'
import Joi from 'joi'

interface AdminDepartmentDetailProps {
  id?: string
  onSave?: (department: DepartmentFieldsFragment) => void
  onClose?: () => void
}

export const AdminDepartmentDetail = (props: AdminDepartmentDetailProps) => {
  const [query, queryResponse] = useDepartmentDetailLazyQuery()

  useEffect(() => {
    if (props.id) {
      query({ variables: { id: props.id } })
    }
  }, [props.id, query])

  const initialValue = useMemo(
    () =>
      queryResponse.data?.department
        ? {
            ...queryResponse.data?.department,
            roles: queryResponse.data?.department?.roles?.map((role) => role.role) ?? [],
          }
        : { name: '', description: '', roles: [] as Role_Enum[] },
    [queryResponse.data?.department]
  )

  const { bindings, values, setValue } = useFormFields(initialValue, { resetOnInitialValueChange: true })
  const [create] = useCreateNewDepartmentMutation()
  const [update] = useUpdateDepartmentMutation()
  const { isValid, errors, validate } = useJoi(validationSchema, values)
  const handleSave = useCallback(async () => {
    await validate()
    if (isValid) {
      if (props.id) {
        await update({
          variables: {
            id: props.id,
            _set: {
              name: values.name,
              description: values.description,
            },
            objects: values.roles.map((role) => ({
              department_id: props.id,
              role: role,
            })),
          },
        })
      } else {
        const res = await create({
          variables: {
            input: {
              name: values.name,
              description: values.description,
              roles: { data: values.roles.map((role) => ({ role })) },
            },
          },
        })
        props.onSave && props.onSave(res.data?.insert_department)
      }
    }
  }, [create, isValid, props, update, validate, values.description, values.name, values.roles])

  return (
    <DetailWindow
      variant="temporary"
      title={props.id ? 'Bereich bearbeiten' : 'Neuen Bereich erstellen'}
      onClose={props.onClose}
      width={700}
      styles={{ container: { padding: 0 } }}
    >
      <Form>
        <div className="p-6">
          <FormRow label="Name" error={errors.name} required>
            <TextField {...bindings.name} />
          </FormRow>
          <FormRow label="Beschreibung" error={errors.description} required>
            <TextField multiline {...bindings.description} />
          </FormRow>
          <FormRow label={'Mögliche Nutzer Rollen von dem Bereich'}>
            <UserRolesSelect value={values.roles} onChange={(roles) => setValue('roles', roles)} />
          </FormRow>
        </div>
        <Form.Actions onCancel={props.onClose} onSave={handleSave} />
      </Form>
    </DetailWindow>
  )
}

const validationSchema = Joi.object<{ name: string; description: string }>({
  name: Joi.string().required().label('Name'),
  description: Joi.string().required().label('Beschreibung'),
}).messages(deualtJoiMessages)
