import React, { useCallback } from 'react'
import {Icon, Menu} from '../../components'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'
import { VehicleItem } from '../ListConfig/Vehicles/List'
import {
  Dealer_Vehicle_State_Enum,
  Order_State_Enum,
  useDropVehicleReservationMutation,
} from '../../gql'
import { useHandleSave as useHandleSaveVehicle } from '../ListConfig/Vehicles/DetailViews'

interface VehicleActionsProps {
  vehicle: VehicleItem
}

export const VehicleActions = (props: VehicleActionsProps) => {
  const {openDetailView, closeDetailView} = useDetailView()

  const [save] = useHandleSaveVehicle(props.vehicle?.id, props.vehicle?.business_case_number)
  const [dropReservation] = useDropVehicleReservationMutation()

  const openStateModal = useCallback(
    (desiredState?: Dealer_Vehicle_State_Enum) => {
      openDetailView(DetailViewEnum.VehicleChangeState, {
        data: props.vehicle,
        onSave: async (data) => {
          await save(data)
          closeDetailView(DetailViewEnum.VehicleChangeState)
        },
        onClose: () => closeDetailView(DetailViewEnum.VehicleChangeState),
        desiredState,
      })
    },
    [closeDetailView, openDetailView, props.vehicle, save]
  )

  const openOrderStateModal = useCallback(
    (desiredState?: Order_State_Enum) => {
      openDetailView(DetailViewEnum.VehicleChangeOrderState, {
        data: props.vehicle,
        onSave: async (data) => {
          await save(data)
          closeDetailView(DetailViewEnum.VehicleChangeOrderState)
        },
        onClose: () => closeDetailView(DetailViewEnum.VehicleChangeOrderState),
        desiredState,
      })
    },
    [closeDetailView, openDetailView, props.vehicle, save]
  )

  const soldStates = [
    Dealer_Vehicle_State_Enum.Sold,
    Dealer_Vehicle_State_Enum.SoldByBroker,
    Dealer_Vehicle_State_Enum.SoldByPartner,
    Dealer_Vehicle_State_Enum.SoldToPartner,
  ]

  const showSoldTransition = [Dealer_Vehicle_State_Enum.Available, Dealer_Vehicle_State_Enum.Reserved]

  if (!props.vehicle) {
    return null
  }

  return (
    <>
      <Menu.Item onClick={() => openStateModal()}>Status ändern <Icon className={'ml-2'} name={'edit'} /></Menu.Item>
      {props.vehicle.state === Dealer_Vehicle_State_Enum.Available && (
        <Menu.Item onClick={() => openStateModal(Dealer_Vehicle_State_Enum.Reserved)}>Reservieren <Icon className={'ml-2'} name={'schedule'} /></Menu.Item>
      )}
      {props.vehicle.state === Dealer_Vehicle_State_Enum.Reserved && (
        <Menu.Item
          onClick={async () =>
            await dropReservation({
              variables: {
                id: props.vehicle.id,
                business_case_number: props.vehicle.business_case_number,
              },
            })
          }
        >
          Reservierung auflösen <Icon className={'ml-2'} name={'clear'} />
        </Menu.Item>
      )}
      {showSoldTransition.includes(props.vehicle.state) && (
        <Menu.Item onClick={() => openStateModal(Dealer_Vehicle_State_Enum.Sold)}>Fahrzeug verkauft <Icon className={'ml-2'} name={'sell'} /></Menu.Item>
      )}
      {soldStates.includes(props.vehicle.state) && (
        <>
          {/*TODO: define more detailed <Menu.Item onClick={() => {}}>Verkauf stornieren</Menu.Item>*/}
          <Menu.Item onClick={() => openOrderStateModal(Order_State_Enum.Handover)}>Fahrzeug ausgeliefert <Icon className={'ml-2'} name={'send'} /></Menu.Item>
        </>
      )}
    </>
  )
}
