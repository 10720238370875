import { Chip, ChipProps } from 'components/Chip'
import { Dealer_Vehicle_State_Enum } from 'gql'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

interface DealerVehicleStateChipProps {
  state: Dealer_Vehicle_State_Enum
  style?: CSSProperties
  className?: string
}

export const DealerVehicleStateChip = (props: DealerVehicleStateChipProps) => {
  const { t } = useTranslation()
  const color = useColor(props)
  if (!props.state) {
    return <>{'-'}</>
  }

  return (
    <Chip color={color} style={props.style} className={props.className}>
      {t(`DealerVehicleState.${props.state}`)}
    </Chip>
  )
}

const useColor = (props: DealerVehicleStateChipProps): ChipProps['color'] => {
  switch (props.state) {
    case Dealer_Vehicle_State_Enum.Available:
      return 'primary'
    case Dealer_Vehicle_State_Enum.Reserved:
      return 'secondary'
    case Dealer_Vehicle_State_Enum.Sold:
      return 'danger'
    case Dealer_Vehicle_State_Enum.SoldToPartner:
      return 'warning'
  }
}
