import { BulkDataForm } from 'containers/VehicleForm/01_GeneralData/BulkDataForm'
import { VehicleDetail } from 'containers/VehicleForm'
import { useVehicleMetafieldsLazyQuery, useVehiclesErrorStateQuery } from 'gql'
import React, { useEffect, useMemo } from 'react'
import { uniq } from 'lodash'
import { splitVehicleId } from '../List'

interface VehiclesBulkEditViewProps {
  ids: string[]
  onClose: () => void
}

export const VehiclesBulkEditView = React.forwardRef<HTMLDivElement, VehiclesBulkEditViewProps>((props, ref) => {
  const [metafieldsQuery, metafieldsQueryResponse] = useVehicleMetafieldsLazyQuery()

  const errorStateQuery = useVehiclesErrorStateQuery({
    variables: {
      where: {
        _or: props.ids.map((composedId: any) => {
          const [id, , business_case_number]: any[] = splitVehicleId(composedId)
          return {
            _and: [
              { id: { _eq: id } },
              { version: { _eq: 0 } },
              { business_case_number: { _eq: business_case_number } },
            ],
          }
        }),
      },
    },
  })

  const brandId = useMemo(() => {
    const vehicles = errorStateQuery.data?.vehicles
    if (!vehicles || !vehicles.length || vehicles.length === 0) return null
    return uniq(vehicles.map((v) => v.brand_id)).length === 1 ? vehicles[0].brand_id : null
  }, [errorStateQuery.data?.vehicles])

  useEffect(() => {
    if (!brandId) return
    metafieldsQuery({ variables: { brandId } })
  }, [brandId, metafieldsQuery])

  const metafields = useMemo(() => metafieldsQueryResponse.data?.brand_vehicle_metafield, [
    metafieldsQueryResponse.data?.brand_vehicle_metafield,
  ])

  const canEdit = useMemo(() => errorStateQuery.data?.vehicles?.every((v) => !v.sync_error), [
    errorStateQuery.data?.vehicles,
  ])

  const loading = metafieldsQueryResponse.loading || errorStateQuery.loading

  return (
    <VehicleDetail
      ref={ref}
      onClose={props.onClose}
      loading={loading}
      metadata={{ metafields }}
      panes={{
        general: <BulkDataForm ids={props.ids} canImport={false} canEdit={canEdit} onClose={props.onClose} />,
      }}
    ></VehicleDetail>
  )
})
