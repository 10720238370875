import { Button, Card, Select, Spinner, Tabs, Typo } from 'components'
import { DateTimePicker } from 'components/DateTimePicker'
import { LogLevel, MetricType, SyncEntity, SyncPhase, SyncType, useCloudwatchMetricDataLazyQuery } from 'gql'
import * as L from 'layouts'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { MonitoringFilterContext } from './MonitoringFilter'
import Chart from 'react-apexcharts'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { SplitButton } from 'components/SplitButton'

export const AdminMonitoringMetricDataVisualisation = () => {
  const { state, dispatch } = useContext(MonitoringFilterContext)

  const [load, loadResponse] = useCloudwatchMetricDataLazyQuery({ fetchPolicy: 'no-cache' })

  const handleSyncTypeSelect = (index: number) => {
    if (index === 0) {
      dispatch({ type: 'selected-metric-sync-type', payload: { syncType: SyncType.Full } })
    } else if (index === 1) {
      dispatch({ type: 'selected-metric-sync-type', payload: { syncType: SyncType.Delta } })
    }
  }

  const [splitButtonIndex, setSplitButtonIndex] = useState<0 | 1>(0)
  const splitButtonOptions = ['Zeige Log Einträge', 'Ziege Fehlermeldungen']

  const handleShowLogs = () => {
    switch (splitButtonIndex) {
      case 0:
        dispatch({ type: 'adopt-metric-params' })
        break
      case 1:
        dispatch({ type: 'adopt-metric-params', payload: { level: LogLevel.Error } })
        break
    }
  }

  const invalidGraphParams = useMemo(() => !state.metricType || !state.metricDateFrom || !state.metricDateUntil, [
    state.metricDateFrom,
    state.metricDateUntil,
    state.metricType,
  ])

  const getMetricData = useCallback(() => {
    if (!!state.selectedSyncEntity) {
      load({
        variables: {
          input: {
            start: state.metricDateFrom.toISOString(),
            end: state.metricDateUntil.toISOString(),
            entity: state.selectedSyncEntity?.syncEntity as SyncEntity,
            metricType: state.metricType as MetricType,
            syncType: state.metricSyncType as SyncType,
            period: state.metricPeriod,
            phase: state.metricPhase,
          },
        },
      })
      dispatch({ type: 'fetching-metric-data' })
    }
  }, [
    dispatch,
    load,
    state.metricDateFrom,
    state.metricDateUntil,
    state.metricPeriod,
    state.metricSyncType,
    state.metricType,
    state.selectedSyncEntity,
    state.metricPhase,
  ])

  const data = useMemo(() => {
    const metricData = loadResponse.data?.metric_data
    if (!!metricData) dispatch({ type: 'fetched-metric-data' })
    return metricData?.x.map((date, idx) => ({
      x: date,
      y: metricData?.y[idx],
    }))
  }, [dispatch, loadResponse.data?.metric_data])

  if (!state.selectedSyncEntity) return null
  return (
    <L.Vertical style={{ width: '70%' }} spacing={2}>
      <Typo variant="h2" decorator>
        {'Visualisierung'}
      </Typo>
      <Tabs
        onChange={(index) => {
          handleSyncTypeSelect(index)
        }}
      >
        <L.Horizontal>
          <Tabs.TabList>
            <Tabs.Tab label="Tägliche Synchronisation"></Tabs.Tab>
            <Tabs.Tab label="Delta Synchronisation"></Tabs.Tab>
          </Tabs.TabList>
          <div className="flex-1"></div>
          <ToggleButtonGroup
            value={state.metricPreset}
            onChange={(_, minutes) => dispatch({ type: 'selected-metric-preset', payload: { minutes } })}
            exclusive
          >
            <ToggleButton value={5} className="whitespace-nowrap">
              5 Min
            </ToggleButton>
            <ToggleButton value={30} className="whitespace-nowrap">
              30 Min
            </ToggleButton>
            <ToggleButton value={60} className="whitespace-nowrap">
              1 Std
            </ToggleButton>
            <ToggleButton value={180} className="whitespace-nowrap">
              3 Std
            </ToggleButton>
            <ToggleButton value={720} className="whitespace-nowrap">
              12 Std
            </ToggleButton>
          </ToggleButtonGroup>
        </L.Horizontal>
        <Card className="p-4 relative" style={{ minHeight: '600px' }}>
          <L.Horizontal spacing={2} className="mb-4">
            <Select
              required
              placeholder="Ansicht wählen"
              label="Graphendarstellung"
              value={state.metricType}
              onChange={(evt) =>
                dispatch({ type: 'selected-metric-type', payload: { type: evt.target.value as MetricType } })
              }
            >
              {metrics.map((metric, idx) => (
                <Select.Option key={`metric-${idx}`} value={metric.value}>
                  {metric.label}
                </Select.Option>
              ))}
            </Select>
            <Select
              label="Datenpunkte anzeigen alle"
              value={state.metricPeriod}
              onChange={(evt) =>
                dispatch({ type: 'selected-metric-period', payload: { period: Number(evt.target.value) } })
              }
            >
              <Select.Option key={`metric-period-1m`} value={60}>
                60 Sekunden
              </Select.Option>
              <Select.Option key={`metric-period-5m`} value={300}>
                5 Minuten
              </Select.Option>
            </Select>
            <DateTimePicker
              required
              maxDateTime={state.metricDateUntil ?? new Date()}
              value={state.metricDateFrom}
              onChange={(date) => dispatch({ type: 'selected-metric-date-from', payload: { date } })}
              label={'Von'}
            />
            <DateTimePicker
              required
              minDateTime={state.metricDateFrom}
              maxDateTime={new Date()}
              value={state.metricDateUntil}
              onChange={(date) => dispatch({ type: 'selected-metric-date-until', payload: { date } })}
              label={'Bis'}
            />
          </L.Horizontal>
          <div className="m-8 relative" style={{ minHeight: 400 }}>
            {state.graphLoading && <Spinner centered />}
            {state.graphShown && (
              <Chart
                type="area"
                height={650}
                series={[{ name: 'Wert', data }]}
                options={{
                  ...chartOptions,
                  yaxis: {
                    title: {
                      text:
                        state.metricType === MetricType.Progress || state.metricType === MetricType.SuccessRate
                          ? 'Prozent'
                          : 'Anzahl',
                    },
                  },
                  title: {
                    text: loadResponse.data?.metric_data?.label,
                    align: 'center',
                  },
                }}
              />
            )}
          </div>
          {state.selectedSyncEntity.phases === 2 && (
            <L.Horizontal className="absolute bottom-0 left-0 m-4">
              <ToggleButtonGroup
                value={state.metricPhase}
                onChange={(_, phase) => dispatch({ type: 'selected-metric-phase', payload: { phase } })}
                exclusive
                color="primary"
              >
                <ToggleButton value={SyncPhase.Prep} className="whitespace-nowrap">
                  Vorbereitung
                </ToggleButton>
                <ToggleButton value={SyncPhase.Sync} className="whitespace-nowrap">
                  Synchronisation
                </ToggleButton>
              </ToggleButtonGroup>
            </L.Horizontal>
          )}

          <L.Horizontal className="absolute bottom-0 right-0 m-4">
            <Button
              variant="contained"
              disabled={invalidGraphParams || state.graphShown || state.graphLoading}
              onClick={getMetricData}
            >
              Graph anzeigen
            </Button>
            <SplitButton
              variant="outlined"
              options={splitButtonOptions}
              onClick={handleShowLogs}
              aria-label="send note"
              selectedIndex={splitButtonIndex}
              setSelectedIndex={setSplitButtonIndex}
              disabled={!state.graphShown}
            />
          </L.Horizontal>
        </Card>
      </Tabs>
    </L.Vertical>
  )
}

const metrics = [
  {
    label: 'Gesamtanzahl der Objekte',
    value: MetricType.TotalCount,
  },
  {
    label: 'Erfolgsrate der Synchronisation',
    value: MetricType.SuccessRate,
  },
  {
    label: 'Anzahl der Aktualisierungen',
    value: MetricType.UpdatesCount,
  },
  {
    label: 'Anzahl der Neuerstellungen',
    value: MetricType.InsertsCount,
  },
  {
    label: 'Fortschritt der Synchronisation',
    value: MetricType.Progress,
  },
  {
    label: 'Fehleranzahl',
    value: MetricType.ErrorCount,
  },
]

const chartOptions: ApexCharts.ApexOptions = {
  xaxis: { type: 'datetime' },
  chart: {
    zoom: { type: 'x', enabled: true, autoScaleYaxis: true },
    toolbar: { autoSelected: 'zoom' },
    animations: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: { size: 1 },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,
    },
  },
  tooltip: {
    x: {
      format: 'dd.MM.yyyy HH:mm',
    },
  },
}
