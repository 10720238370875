import { ListConfigInterface } from '../../index'
import { Ticket } from '../../../../gql'
import { useContext, useMemo } from 'react'
import { VehicleDetailContext } from '../../../VehicleForm'
import { useEzAuth } from '@lib/ezauth'

export const useTicketListConfigs = (): { [key: string]: ListConfigInterface<Ticket> } => {
  const [state] = useEzAuth()
  const userId = state.user.id
  const { data: vehicle } = useContext(VehicleDetailContext)
  return useMemo(
    () => ({
      ASSIGNED_ME: {
        name: 'Zu mir zugewiesene Aufgaben',
        description: 'Liste der Aufgaben, die mir zugewiesen sind.',
        id: '0',
        filter: JSON.stringify({
          _and: [
            {
              _or: [
                {
                  _and: [{ assignee_id: { _eq: userId } }],
                },
              ],
            },
          ],
        }),
        columns: [
          'priority',
          'vehicleState',
          'vehicleImportState',
          'vehicleMarkedForDelete',
          'vehicleJobNumberAdvanced',
          'title',
          'vehicleBrand',
          'vehicleModel',
          'vehicleType',
          'vehicleRegistrationState',
          'vehicleFirstRegistration',
          'vehicleLicensePlate',
          'vehicleColor',
          'quickActions',
        ],
        grouping: 'state',
        highlights: [],
      },
      VEHICLE: {
        name: 'Fahrzeugaufgaben',
        description: 'Aufgaben des Fahrzeugs',
        id: '1',
        filter: JSON.stringify({
          _and: [
            {
              _or: [
                {
                  _and: vehicle
                    ? [
                        { vehicle_id: { _eq: vehicle.id } },
                        { vehicle_business_case_number: { _eq: vehicle.business_case_number } },
                      ]
                    : [],
                },
              ],
            },
          ],
        }),
        columns: [
          'priority',
          'state',
          'title',
          'description',
          'department',
          'location',
          'createdAt',
          'assignedTo',
          'dueTo',
          'quickActions',
        ],
        grouping: 'state',
        highlights: [],
      },
    }),
    [userId, vehicle]
  )
}
