import React, { useCallback, useContext, useMemo, useState } from 'react'
import { SyncErrorState, VehicleDetailContext } from '../index'
import { PulseForm } from 'containers/PulseForm'
import { Form } from '../../../components'
import { useVehiclePricesFormular } from './useFormular'
import { getErrorState } from '../01_GeneralData'

export const PricesData = () => {
  const { data, loading } = useContext(VehicleDetailContext)
  const [editMode, setEditMode] = useState(false)

  const formConfig = useVehiclePricesFormular()

  const errorState = useMemo(() => getErrorState(data), [data])
  const handleEdit = errorState !== SyncErrorState.UNRESOLVED ? () => setEditMode(true) : undefined
  const handleSubmit = useCallback(async () => {
    await formConfig.onSubmit()
    setEditMode(false)
  }, [formConfig])
  if (!data) return null

  return (
    <>
      <PulseForm sections={formConfig?.sections} editMode={editMode} handleEdit={handleEdit} disableNotes={true} />
      {!loading && editMode && <Form.Actions onCancel={() => setEditMode(false)} onSave={handleSubmit} />}
    </>
  )
}
