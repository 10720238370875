import React, { useState, useMemo } from 'react'

const { REACT_APP_AUTH_PROVIDER, REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENVIRONMENT, REACT_APP_PREVIEW } = process.env

const apolloLinks = Object.keys(process.env)
  .filter((key: string) => key.startsWith('REACT_APP_APOLLO_LINK'))
  .map((key) => parseUri(process.env[key]))

interface ConfigStore {
  preview: boolean
  apollo: {
    links: ParsedUri[]
  }
  auth: {
    provider: ParsedUri
  }
  app: {
    title: string
  }
  sentry: {
    dsn: string
    environment: string
  }
}

const ConfigContext = React.createContext<ConfigStore>(null)

export const ConfigProvider: React.FC = (props) => {
  const [preview] = useState(REACT_APP_PREVIEW === 'true')
  const [apollo] = useState<ConfigStore['apollo']>({
    links: apolloLinks,
  })
  const [auth] = useState<ConfigStore['auth']>({
    provider: parseUri(REACT_APP_AUTH_PROVIDER),
  })
  const [sentry] = useState<ConfigStore['sentry']>({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_SENTRY_ENVIRONMENT,
  })

  const value = useMemo(
    () => ({
      preview,
      apollo,
      auth,
      sentry,
      app: {
        title: 'Trawöger Pulse',
      },
    }),
    [apollo, auth, preview, sentry]
  )

  return <ConfigContext.Provider value={value} {...props} />
}

export const useConfig = () => React.useContext(ConfigContext)

interface ParsedUri {
  protocol: string
  options: {
    [key: string]: string | boolean | number
  }
}

function parseUri(uri: string): ParsedUri {
  const url = new URL(uri)
  const options = Array.from(url.searchParams.entries()).reduce((acc, entry) => {
    const [key, value] = entry
    if (value === 'true' || value === 'false') {
      acc[key] = value === 'true'
    } else if (!Number.isNaN(Number(value))) {
      acc[key] = Number(value)
    } else {
      acc[key] = value
    }
    return acc
  }, {} as ParsedUri['options'])
  options.uri = url.origin + url.pathname
  return {
    protocol: url.protocol.slice(0, -1),
    options,
  }
}
