import React, { useState } from 'react'
import { Collapse, Drawer, List, ListItem, ListItemText, StyledEngineProvider, ThemeProvider } from '@mui/material'
import DesktopMacIcon from '@mui/icons-material/DesktopMac'
import { Icon, IconProps, Link, Typo } from 'components'
import { routes, useConfig } from 'config'
import * as L from 'layouts'
import { darkTheme } from 'themes'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { useEzAuth, UserRole } from '@lib/ezauth'
import styled from './AppMenu.module.css'
import { intersection } from 'lodash'

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

export interface AppMenuProps {
  variant?: 'permanent' | 'temporary'
  isOpen?: boolean
  onClose?: () => void
}

export const AppMenu = (props: AppMenuProps) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={darkTheme}>
        <Drawer
          variant={props.variant}
          anchor="left"
          className="w-appMenu"
          classes={{
            paper: 'w-appMenu bg-none',
          }}
          open={props.isOpen}
          onClose={props.onClose}
        >
          <DrawerContent />
        </Drawer>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

AppMenu.defaultProps = {
  variant: 'permanent',
}

const DrawerContent = () => {
  const { app } = useConfig()
  const sections = useMenu()
  return (
    <>
      <ListItem className="h-16 bg-background-appMenuTitle text-color-darkPrimary" component={Link} to={'/'}>
        <L.Horizontal spacing={2}>
          <DesktopMacIcon />
          <Typo component="span" variant="h5">
            {app.title}
          </Typo>
        </L.Horizontal>
      </ListItem>

      {sections.map((section) => (
        <React.Fragment key={section.key}>
          <Typo className="pt-6 px-4 pb-1">{section.title}</Typo>
          <List className="p-0">
            {section.items.map((item) => (
              <MenuItem item={item} key={item.key} />
            ))}
          </List>
        </React.Fragment>
      ))}
    </>
  )
}

const MenuItem: React.FC<{ item: MenuItemDefinition }> = (props) => {
  const item = props.item

  // TODO: improve active detection / path match
  let location = useLocation()
  const hasChildren = !!item.subItems?.length
  const hasActiveChild = hasChildren && item.subItems.some((sub) => location.pathname === sub.path)
  const [open, setOpen] = useState(hasActiveChild)

  const handleClick = () => {
    if (hasChildren) {
      setOpen((prev) => !prev)
    }
  }

  return (
    <>
      <ListItem
        button
        key={item.key}
        className={clsx(styled.listItem, location.pathname === item.path && styled.active)}
        onClick={handleClick}
        component={item.path ? Link : 'div'}
        to={item.path}
      >
        <L.Horizontal style={{ width: '100%' }}>
          <Icon name={item.icon} className="opacity-40" fontSize="small" />
          <ListItemText className="text-left text-text-darkPrimary ml-4">{item.name}</ListItemText>
          {hasChildren && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
        </L.Horizontal>
      </ListItem>
      {hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subItems.map((sub) => (
              <ListItem
                component={Link}
                to={sub.path || '/'}
                button
                className={clsx(styled.listItem, styled.nested, location.pathname === sub.path && styled.active)}
                key={sub.key}
              >
                <ListItemText className="text-left text-text-darkPrimary ml-4">{sub.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

interface MenuSectionDefinition {
  key: string
  title: string
  items: MenuItemDefinition[]
  isPreview?: boolean
  onlyRoles?: UserRole[]
}

interface MenuItemDefinition {
  key: string
  name: string
  icon: IconProps['name']
  path?: string
  subItems: Omit<MenuItemDefinition, 'icon' | 'subItems'>[]
  isPreview?: boolean
  onlyRoles?: UserRole[]
}

const useMenuStructure = () => {
  const menuDefinition: Array<MenuSectionDefinition> = [
    {
      title: 'After Sales',
      items: [
        {
          name: 'Lagerfahrzeuge',
          isPreview: true,
          icon: 'none',
          subItems: [
            {
              name: 'Service',
              path: routes.afterSales.dealerVehicles.serivce(),
            },
            {
              name: 'Pickerl',
              path: routes.afterSales.dealerVehicles.inspectionSticker(),
            },
          ],
        },
        {
          name: 'Kundenfahrzeuge',
          icon: 'none',
          subItems: [
            {
              name: 'Service',
              path: routes.afterSales.customerVehicles.service(),
            },
            {
              name: 'Pickerl',
              path: routes.afterSales.customerVehicles.inspectionSticker(),
            },
          ],
        },
      ],
    },
    {
      title: 'Kommunikation',
      isPreview: true,
      items: [
        {
          name: 'After Sales',
          icon: 'none',
          path: routes.communcation.afterSales(),
        },
      ],
    },
    {
      title: 'Disposition',
      onlyRoles: [UserRole.ADMIN, UserRole.SALES_MANAGER, UserRole.VEHICLE_DISPATCHER, UserRole.SALES],
      items: [
        {
          isPreview: true,
          name: 'Verkaufsberichte',
          icon: 'none',
          path: routes.disposition.salesReports(),
        },
        {
          name: 'Fahrzeug Import',
          onlyRoles: [UserRole.ADMIN, UserRole.SALES_MANAGER, UserRole.VEHICLE_DISPATCHER],
          icon: 'none',
          path: routes.disposition.dealerVehicleImport(),
        },
        {
          name: 'Lager Fahrzeuge',
          icon: 'none',
          path: routes.disposition.dealerVehicles(),
        },
      ],
    },
    {
      title: 'Datenqualität',
      isPreview: true,
      onlyRoles: [UserRole.ADMIN],
      items: [
        {
          name: 'Fahrzeuge',
          icon: 'none',
          subItems: [
            {
              key: 'data-quality-vehicles-with-sync-errors',
              name: 'Synchronisationsfehler',
              path: routes.dataQualityVehicles.syncErrors(),
            },
            {
              key: 'data-quality-vehicles-with-mixed-up-histories',
              name: 'Rechnungsfehler',
              path: routes.dataQualityVehicles.mixedUpHistories(),
            },
            {
              key: 'data-quality-marked-for-delete-vehicles',
              name: 'Löschungen',
              path: routes.dataQualityVehicles.markedForDelete(),
            },
          ],
          onlyRoles: [UserRole.ADMIN],
        },
      ],
    },
    {
      title: 'Administration',
      onlyRoles: [UserRole.ADMIN, UserRole.SALES_MANAGER, UserRole.VEHICLE_DISPATCHER],
      items: [
        {
          name: 'Users',
          icon: 'none',
          path: routes.admin.users(),
          onlyRoles: [UserRole.ADMIN],
        },
        {
          name: 'Standorte',
          icon: 'none',
          path: routes.admin.locations(),
          onlyRoles: [UserRole.ADMIN, UserRole.SALES_MANAGER, UserRole.VEHICLE_DISPATCHER],
        },
        {
          name: 'Marken',
          icon: 'none',
          path: routes.admin.brands(),
          isPreview: true,
          onlyRoles: [UserRole.ADMIN, UserRole.SALES_MANAGER, UserRole.VEHICLE_DISPATCHER],
        },
        {
          name: 'Lieferanten',
          icon: 'none',
          path: routes.admin.supplier(),
          isPreview: true,
          onlyRoles: [UserRole.ADMIN, UserRole.SALES_MANAGER, UserRole.VEHICLE_DISPATCHER],
        },
        {
          name: 'Subhändler',
          icon: 'none',
          path: routes.admin.subDealer(),
          isPreview: true,
          onlyRoles: [UserRole.ADMIN, UserRole.SALES_MANAGER, UserRole.VEHICLE_DISPATCHER],
        },
        {
          name: 'Arbeitsnummern',
          icon: 'none',
          path: routes.admin.taskTypes(),
          onlyRoles: [UserRole.ADMIN],
        },
        {
          name: 'Externe Seiten',
          icon: 'none',
          path: routes.admin.embeddedPages(),
          onlyRoles: [UserRole.ADMIN],
        },
        {
          name: 'Datei-Links',
          icon: 'none',
          path: routes.admin.assetLinks(),
          onlyRoles: [UserRole.ADMIN],
        },
        {
          name: 'Automatisierung',
          icon: 'none',
          subItems: [
            {
              key: 'vehicles-automation',
              name: 'Fahrzeuge',
              path: routes.automation.vehicles(),
            },
          ],
          onlyRoles: [UserRole.ADMIN],
        },
        {
          name: 'Monitoring',
          icon: 'none',
          onlyRoles: [UserRole.ADMIN],
          subItems: [
            {
              key: 'locosoft-sync-monitoring',
              name: 'Locosoft Sync',
              path: routes.monitoring.locosoftSync(),
            },
          ],
        },
        {
          name: 'Excel Imports',
          icon: 'none',
          onlyRoles: [UserRole.ADMIN],
          subItems: [
            {
              key: 'import-jobs-vehicles',
              name: 'Fahrzeuge',
              path: routes.importJobs.vehicles(),
            },
          ],
        },
      ],
    },
  ].map((section, sectionIdx) => {
    // generate keys for react mapping
    return {
      ...section,
      key: `${section.title}-${sectionIdx}`,
      // @ts-ignore
      items: section.items.map((item, itemIdx) => ({
        ...item,
        key: `${item.name}-${itemIdx}`,
        subItems: item.subItems?.map((sub, subIdx) => ({
          ...sub,
          key: `${sub.name}-${subIdx}`,
        })),
      })),
    } as MenuSectionDefinition
  })

  return menuDefinition
}

const useMenuFilter = (sections: MenuSectionDefinition[]): MenuSectionDefinition[] => {
  const { preview } = useConfig()
  const [auth] = useEzAuth()
  const roles = auth.user.roles
  let filtered = sections
  if (!preview && !auth.user?.showPreview) {
    filtered = filtered
      .map((section) => {
        return {
          ...section,
          items: section.items
            .map((item) => {
              const res = {
                ...item,
              }
              if (item.subItems) {
                res.subItems = item.subItems.filter((si) => !si.isPreview)
              }
              return res
            })
            .filter((i) => !i.isPreview),
        }
      })
      .filter((s) => !s.isPreview)
  }

  return filtered
    .map((section) => ({
      ...section,
      items: section.items.filter((item) => {
        if (!item.onlyRoles) return true
        if (item.onlyRoles && intersection(item.onlyRoles, roles as any[]).length > 0) return true

        return false
      }),
    }))
    .filter((s) => {
      if (!s.onlyRoles) return true
      if (s.onlyRoles && intersection(s.onlyRoles, roles as any[]).length > 0) return true

      return false
    })
}

const useMenu = () => {
  const menu = useMenuStructure()
  return useMenuFilter(menu)
}
