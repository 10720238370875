import React, { CSSProperties } from 'react'
import { Comp, CompProps } from 'components'
import clsx from 'clsx'
import styled from './Horizontal.module.css'

interface HorizontalProps {
  spacing?: 0 | 0.5 | 1 | 2 | 3 | 4 | 5
  align?: 'top' | 'center' | 'bottom'
  wrap?: 'wrap'
  component?: CompProps['component']
  style?: CSSProperties
  className?: string
}

export const Horizontal: React.FC<HorizontalProps> = (props) => {
  return (
    <Comp
      style={props.style}
      className={clsx(
        'flex',
        styled[`m${props.spacing ?? 3}`],
        props.wrap && 'flex-wrap',
        styled[`align-${props.align ?? 'center'}`],
        props.className,
      )}
      children={props.children}
    />
  )
}

Horizontal.defaultProps = {
  spacing: 3,
  align: 'center',
}
