import React, { CSSProperties } from 'react'
import { MaxWidthText } from 'components/MaxWidthText'
import styled from './Yalc2.module.css'
import clsx from 'clsx'

const MaxWidthCell: React.FC = (props: { children: string | string[] }) => {
  return <MaxWidthText className={styled.main}>{props.children || '-'}</MaxWidthText>
}

const SimpleCell: React.FC<{ style?: CSSProperties; className?: string }> = ({ children, style, className }) => {
  return (
    <div className={clsx(styled.main, className)} style={style}>
      {children || '-'}
    </div>
  )
}

const SubCell: React.FC<{ style?: CSSProperties }> = ({ children, style }) => {
  return (
    <div className={styled.sub} style={style}>
      {children}
    </div>
  )
}
const horizontal = (cell: React.ReactNode) => {
  return <div className={styled.vertical}>{cell}</div>
}
export const Yalc2Cell = {
  /**
   * Decorates MaxWidthText to be used within Yalc
   */
  MaxWidthCell,
  /**
   * Default typography
   */
  SimpleCell,
  SubCell,
  horizontal,
}
