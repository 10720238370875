import { useContext } from 'react'
import React from 'react'
import { ListConfigContext } from '../../../ListConfig'
import { ListManipulation, useListManipilationContext } from '../../unit'

export const VehicleDefaultOptions = () => {
  const { setField } = useListManipilationContext<'Vehicle'>()
  const { type } = useContext(ListConfigContext)
  const usedCarsList = type?.toLowerCase()?.includes('used')
  return (
    <ListManipulation.UnitOptions>
      <ListManipulation.ItemOption onClick={() => setField('state')}>Status</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('order_state')}>
        Transport Status
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('type')}>Typ</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('registration_state')}>
        Zulassungs Status
      </ListManipulation.ItemOption>
      {usedCarsList && (
        <ListManipulation.ItemOption onClick={() => setField('in_buy_channel')}>
          Zukaufskanal
        </ListManipulation.ItemOption>
      )}
      <ListManipulation.ItemOption onClick={() => setField('storage_location_id')}>
        Lagerort
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('delivery_location_id')}>
        Lieferort
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('dms_assigned_location_id')}>
        Betreuende Betriebsstätte
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('seller_location_id')}>
        Verkaufende Betriebsstätte
      </ListManipulation.ItemOption>
      <hr className={'text-divider'} />
      <ListManipulation.ItemOption onClick={() => setField('sold_at')}>Verkaufsdatum</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('sold_by_id')}>Verkäufer</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('customer_type')}>Kundentyp</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('reserved_at')}>Reserviert am</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('reserved_until')}>
        Reserviert bis
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('reserved_by_id')}>
        Reserviert von
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('reserved_for_customer_type')}>
        Reserviert für (Typ)
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('days_since_purchase')}>
        Standtage
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('first_registration_date')}>
        Erstzulassung
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('handover_planned_date')}>
        Auslieferungsdatum
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('handover_date')}>
        Erfassungsdatum
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('handover_by_id')}>
        Ausgeliefert von
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('updated_at')}>
        Zuletzt aktualisiert am
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('warranty_end')}>
        Garantie Ablaufsdatum
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('warranty_start')}>
        Garrantiestart
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setField('invoice_date')}>Rechnungsdatum</ListManipulation.ItemOption>
    </ListManipulation.UnitOptions>
  )
}
