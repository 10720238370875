import React, { CSSProperties } from 'react'
import Paper from '@mui/material/Paper'
import clsx from 'clsx'

interface CardProps {
  className?: string
  style?: CSSProperties
  children?: React.ReactNode
}

export const Card = (props: CardProps) => {
  return <Paper variant="outlined" {...props} />
}

interface CardHeaderProps {
  className?: string
  style?: CSSProperties
}

const CardHeader: React.FC<CardHeaderProps> = (props) => {
  return <div {...props} className={clsx('p-4 border-b border-solid border-divider', props.className)} />
}
Card.Header = CardHeader
