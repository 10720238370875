import React, { useMemo } from 'react'
import { Select } from 'components'

export interface YearSelectProps {
  value: number
  onChange: (year: number) => void
  label?: string
  minValue?: number
  maxValue?: number
}

export const YearSelect = (props: YearSelectProps) => {
  const maxValue: number = props.maxValue ?? new Date().getFullYear()
  const years = useMemo(() => {
    return new Array(20)
      .fill(1)
      .map((_, idx) => maxValue - idx)
      .filter((v) => typeof props.minValue === 'undefined' || v >= props.minValue)
  }, [maxValue, props.minValue])

  return (
    <Select
      label={props.label}
      value={props.value}
      onChange={(evt) => props.onChange(Number(evt.target.value))}
      style={{ minWidth: 86, width: 86 }}
    >
      {years.map((year) => (
        <Select.Option value={year} key={year}>
          {year}
        </Select.Option>
      ))}
    </Select>
  )
}
