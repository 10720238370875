import {useEffect, useState} from 'react'
import {useSearchQuery} from "./useSearchQuery";
import {useHistory} from "react-router-dom";

interface RouteRawParams {
    filter?: string
}

export const usePageFilter = <F extends {}>(defaultValues?: F) => {
    const [params, queryActions] = useSearchQuery<RouteRawParams>()
    let routeParams: F = Object.create(Object.prototype)
    if (params.filter) {
        try {
            routeParams = JSON.parse(atob(params.filter))
        } catch (error) {
            console.warn('parsing page query filter failed', error)
        }
    }
    const [filter, setFilter] = useState<F>({...defaultValues, ...routeParams})


    const history = useHistory()
    useEffect(() => {
        const json = JSON.stringify(filter)
        const base64 = btoa(json)
        queryActions.setValue('filter', base64)
    }, [filter, history, queryActions])

    const mutate = (key: keyof F, value: string | number) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }

    return [filter, mutate] as const
}
