import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SyncErrorState, VehicleDetailContext, VehicleFormData, VehicleFormValues } from '..'
import { Dealer_Vehicle_State_Enum, Dealer_Vehicle_Type_Enum, Order_State_Enum, Registration_State_Enum } from 'gql'
import { mergeDeepWith } from 'ramda'
import { useFormFields } from '@w2solutions/react-hooks'
import { Form } from 'components'
import { useVehicleFormular } from './useVehicleFormular'
import { PulseForm } from '../../PulseForm'

export interface GeneralDataProps {
  onEditModeChange?: (val: boolean) => void
  onSave: (data: VehicleFormValues) => Promise<void>
  disableValidationOnSave?: boolean
  customActions?: React.ReactNode
}

export const GeneralData: React.FC<GeneralDataProps> = (props) => {
  const { data, loading } = useContext(VehicleDetailContext)
  const [editMode, setEditMode] = useState(false)

  const errorState = useMemo(() => getErrorState(data), [data])

  const handleEdit = errorState !== SyncErrorState.UNRESOLVED ? () => setEditMode(true) : undefined

  useEffect(() => {
    props.onEditModeChange?.call(null, editMode)
  }, [editMode, props.onEditModeChange])

  const formConfig = useVehicleFormular(props.disableValidationOnSave, props.onSave)

  const handleSubmit = useCallback(async () => {
    await formConfig.onSubmit()
    setEditMode(false)
  }, [formConfig])
  if (!data) return null

  return (
    <>
      <PulseForm sections={formConfig?.sections} editMode={editMode} handleEdit={handleEdit} />
      {!loading && !editMode && props.customActions && <Form.ActionBar>{props.customActions}</Form.ActionBar>}
      {!loading && editMode && (
        <Form.Actions
          onCancel={() => setEditMode(false)}
          onSave={handleSubmit}
          errors={Object.values(formConfig?.errors).map(String)}
        />
      )}
    </>
  )
}

export const getErrorState = (data: { sync_errors: { id: string; resolved?: string }[] }): SyncErrorState => {
  if (!data?.sync_errors || data?.sync_errors?.length === 0) {
    return SyncErrorState.CLEAN
  }
  if (data?.sync_errors?.every((err) => !!err?.resolved)) {
    return SyncErrorState.RESOLVED
  }
  return SyncErrorState.UNRESOLVED
}

const initialFormData = {
  state: null as Dealer_Vehicle_State_Enum,
  order_state: null as Order_State_Enum,
  delivery_date_str: '',
  delivery_location_id: null,
  storage_location_id: null,
  storage_location_note: '',

  type: null as Dealer_Vehicle_Type_Enum,
  min_holding_period: null as number,
  registration_state: null as Registration_State_Enum,
  registration_owner: '',
  key_kabinet_number: '',
  // TODO: standtage

  sold_by_id: null,
  sold_at: null as string,
  customer: '',
  specs: '',
  spec_level: '',
}

export const useVehicleFormValues = (data: VehicleFormData) => {
  const initialValues: VehicleFormValues = useMemo(() => {
    const val = {
      state: data?.state,
      order_state: data?.order_state,
      delivery_date_str: data?.delivery_date_str ?? initialFormData.delivery_date_str,
      delivery_location_id: data?.delivery_location?.id,
      storage_location_id: data?.storage_location?.id,
      seller_location_id: data?.seller_location?.id,
      storage_location_note: data?.storage_location_note ?? initialFormData.storage_location_note,

      type: data?.type,
      min_holding_period: data?.min_holding_period,
      registration_state: data?.registration_state,
      registration_owner: data?.registration_owner,
      key_kabinet_number: data?.key_kabinet_number,
      // TODO: standtage

      sold_by_id: data?.sold_by_id,
      reserved_by_id: data?.reserved_by_id,
      reserved_at: data?.reserved_at,
      reserved_until: data?.reserved_until,
      reserved_for: data?.reserved_for,
      reserved_for_customer_type: data?.reserved_for_customer_type,
      sold_at: data?.sold_at,
      customer: data?.customer ?? initialFormData.customer,
      customer_type: data?.customer_type,
      handover_date: data?.handover_date,
      handover_planned_date: data?.handover_planned_date,
      handover_by_id: data?.handover_by_id,
      model: data?.model,
      color_exterior_name: data?.color_exterior_name,
      color_exterior_code: data?.color_exterior_code,
      color_interior_name: data?.color_interior_name,
      color_interior_code: data?.color_interior_code,
      specs: data?.specs,
      spec_level: data?.spec_level,
      control_number: data?.control_number,
      accessories_mounted: data?.accessories_mounted,
      order_code: data?.order_code,
      in_buy_channel: data?.in_buy_channel,
      purchased_by_id: data?.purchased_by_id,
      in_buy_contract_date: data?.in_buy_contract_date,
      keys_count: data?.keys_count,
      co2_nefz: data?.co2_nefz,
      co2_wltp: data?.co2_wltp,
    }
    return mergeDeepWith((a, b) => a ?? b, val, initialFormData)
  }, [data])
  return [useFormFields(initialValues, { resetOnInitialValueChange: true }), initialValues] as const
}
