import React, { CSSProperties } from 'react'
import { Comp, CompProps } from 'components'
import clsx from 'clsx'
import styled from './Vertical.module.css'

interface VerticalProps {
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6
  component?: CompProps['component']
  style?: CSSProperties
  className?: string
}

export const Vertical: React.FC<VerticalProps> = ({ spacing, ...props }) => {
  return (
    <Comp
      style={props.style}
      className={clsx('flex flex-col', styled[`m${spacing ?? 2}`], props.className)}
      children={props.children}
    />
  )
}
