import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { Avatar, Icon, IconButton, Menu, SiteMetadata, Spinner, TextField, Typo } from '../../../components'
import { Yalc2, Yalc2RowDefinition } from '../../../components/Yalc2'
import { MonthSelectPane, YearSelect } from '../../../containers'
import * as L from '../../../layouts'
import { DateFns } from '../../../utils'
import {
  Dealer_Vehicle_Type_Enum,
  Sales_Reports_Bool_Exp,
  SalesReportsListSubscriptionResult,
  useSalesReportsListSubscription,
} from '../../../gql'
import { useDebounce, useFormatter } from '../../../hooks'
import { usePageFilter } from '../../../hooks/usePageFilter'
import { DetailViewEnum, useDetailView } from '../../../components/DetailWindow/useDetailView'
import { Yalc2Cell } from '../../../components/Yalc2/cells'
import Tooltip from '../../../components/Tooltip'
import { useTranslation } from 'react-i18next'
import { deriveUserAbbreviation } from '../../../containers/AdminUser/UsersList'
import { clipboard } from '../../../utils/clipboard'
import { StatusLight } from '../../../containers/StatusLight'

interface FilterProps {
  className?: string
  style?: CSSProperties
  year: number
  onYearChange: (year: number) => void
  searchQuery?: string | number
  onSearchQueryChange: (value: string) => void
}

const Filter = (props: FilterProps) => {
  const maxYear = DateFns.addMonths(new Date(), 6).getFullYear()
  return (
    <L.Horizontal className={props.className} style={props.style}>
      <YearSelect value={props.year} onChange={props.onYearChange} minValue={2021} maxValue={maxYear} />
      <TextField
        value={props.searchQuery}
        onChange={(evt) => props.onSearchQueryChange(evt.currentTarget.value)}
        style={{ minWidth: 360, maxWidth: 512 }}
        placeholder="FIN, Kunde, Modell, Marke"
      />
    </L.Horizontal>
  )
}

const OptionMenu = () => {
  const { openDetailView, closeDetailView } = useDetailView()
  return (
    <>
      <Menu.Item
        onClick={() =>
          openDetailView(DetailViewEnum.SalesReportEditView, {
            onClose: () => closeDetailView(DetailViewEnum.SalesReportEditView),
          })
        }
      >
        Neuer Verkaufsbericht
      </Menu.Item>
      <Menu.Item disabled>Bericht herunterladen</Menu.Item>
    </>
  )
}

type SalesReports = SalesReportsListSubscriptionResult['data']['sales_reports']
type SalesReport = ArrayElement<SalesReports>

interface RouteParams {
  monthIndex?: string
  year?: string
}

export const SalesReportsPage = () => {
  const [searchQuery, setSearchQuery] = useState('')

  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth()

  const [pageFilter, pageFilterFn] = usePageFilter<RouteParams>({
    year: String(currentYear),
    monthIndex: String(currentMonth),
  })
  const salesReportFilter = useSalesReportFilter(pageFilter, searchQuery)

  const subscription = useSalesReportsListSubscription({ variables: { where: salesReportFilter } })
  const salesReports = useMemo(() => subscription.data?.sales_reports ?? [], [subscription.data?.sales_reports])

  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const loading = useDebounce(() => subscription.loading, 50)

  const rowDefintion = useRowDefinition()

  const { openDetailView, closeDetailView } = useDetailView()

  useEffect(() => {
    if (selectedIds.length === 1) {
      openDetailView(DetailViewEnum.SalesReportEditView, {
        id: selectedIds[0],
        onClose: () => {
          closeDetailView(DetailViewEnum.SalesReportEditView)
          setSelectedIds([])
        },
      })
    }
  }, [closeDetailView, openDetailView, selectedIds])

  return (
    <div className={'h-full'}>
      <SiteMetadata title={'Verkaufsberichte'} />
      <Yalc2<SalesReport>
        title={'Berichte'}
        rowDefinition={rowDefintion}
        selectedIds={selectedIds}
        onSelectionChanged={setSelectedIds}
        style={{ height: '100%' }}
        data={salesReports}
        options={<OptionMenu />}
        actionBar={
          <>
            <Filter
              year={pageFilter.year ? Number(pageFilter.year) : currentYear}
              onYearChange={(year) => pageFilterFn('year', year)}
              searchQuery={searchQuery}
              onSearchQueryChange={setSearchQuery}
              style={{ flexGrow: 1 }}
            />
            <L.Horizontal spacing={1} className="flex-1 justify-end">
              {loading && <Spinner size="32px" />}
            </L.Horizontal>
          </>
        }
      />
      <MonthSelectPane
        monthIndex={pageFilter.monthIndex ? Number(pageFilter.monthIndex) : currentMonth}
        onChange={(monthIndex) => pageFilterFn('monthIndex', monthIndex)}
        className="absolute bottom-0 left-0 w-full bg-white z-appBar border-t border-solid border-divider"
      />
    </div>
  )
}

const useSalesReportFilter = (pageFilter: RouteParams, searchQuery: string): Sales_Reports_Bool_Exp => {
  const range = useMemo(() => {
    if (!pageFilter.year || !pageFilter.monthIndex) {
      return
    }
    let from = DateFns.withoutTimezoneOffset(
      DateFns.startOfMonth(new Date(Number(pageFilter.year), Number(pageFilter.monthIndex)))
    )
    return { from, to: DateFns.addMonths(from, 1) }
  }, [pageFilter.monthIndex, pageFilter.year])

  const _ilike = useDebounce(`%${searchQuery}%`, 150)

  return useMemo(
    () => ({
      date: range ? { _gte: range.from, _lt: range.to } : undefined,
      _or: [
        {
          vin: { _ilike },
        },
        {
          customer_name: { _ilike },
        },
        {
          model_name: { _ilike },
        },
        {
          brand: {
            name: { _ilike }
          },
        },
      ],
    }),
    [_ilike, range]
  )
}

const useRowDefinition = (): Yalc2RowDefinition<SalesReport> => {
  const { formatDateString, formatNumber } = useFormatter()
  const { t } = useTranslation()
  return useMemo(
    () => ({
      columns: [
        {
          key: 'seller',
          width: 60,
          title: 'VK',
          sortColumn: 'seller_id',
          cell: function SellerCell(props) {
            const abbreviation = deriveUserAbbreviation(props.item.seller)
            const TooltipContent = (
              <>
                <p>{`Verkäufer: ${props.item.seller.full_name || props.item.seller.email || '-'}`}</p>
              </>
            )
            return (
              <Tooltip content={TooltipContent}>
                <Yalc2Cell.SimpleCell>
                  <Avatar alt={`Abkürzung: ${abbreviation}`} className={'bg-primary-main'}>
                    {abbreviation}
                  </Avatar>
                </Yalc2Cell.SimpleCell>
              </Tooltip>
            )
          },
        },
        {
          key: 'customer',
          width: 150,
          title: 'Kunde',
          sortColumn: 'customer_name',
          cell: function CustomerCell(props) {
            const TooltipContent = (
              <>
                <p>{`Kundentyp: ${t(`CustomerType.${props.item.customer_type}`)}`}</p>
                <p>{`Kundennummer: ${props.item.customer_number ?? '-'}`}</p>
                <p>{`PLZ: ${props.item.zip_code ?? '-'}`}</p>
              </>
            )
            return (
              <Tooltip content={TooltipContent}>
                <Yalc2Cell.SimpleCell>
                  <Yalc2Cell.MaxWidthCell>{props.item.customer_name}</Yalc2Cell.MaxWidthCell>
                  <Yalc2Cell.SubCell>{formatDateString(props.item.date)}</Yalc2Cell.SubCell>
                </Yalc2Cell.SimpleCell>
              </Tooltip>
            )
          },
        },
        {
          key: 'vehicle_type',
          title: 'Typ',
          width: 60,
          sortColumn: 'vehicle_type',
          cell: function VehicleType(props) {
            const abbr = useMemo(() => {
              switch (props.item.vehicle_type) {
                case Dealer_Vehicle_Type_Enum.DemonstrationCar:
                  return 'VFW'
                case Dealer_Vehicle_Type_Enum.NewCar:
                  return 'NW'
                case Dealer_Vehicle_Type_Enum.UsedCar:
                  return 'GW'
                case Dealer_Vehicle_Type_Enum.Unknown:
                  return '?'
              }
            }, [props.item.vehicle_type])
            return (
              <Yalc2Cell.SimpleCell>
                <Typo variant="h6">{abbr}</Typo>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'model_name',
          title: 'Modell',
          width: 150,
          sortColumn: 'model_name',
          cell: function ModelNameCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <Yalc2Cell.MaxWidthCell>{props.item.brand.name ?? '-'}</Yalc2Cell.MaxWidthCell>
                <Yalc2Cell.SubCell>{props.item.model_name ?? '-'}</Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'job_number',
          title: 'Auftrags-Nr.',
          width: 120,
          cell: function JobNumberCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <Yalc2Cell.MaxWidthCell>{props.item.job_number ?? '-'}</Yalc2Cell.MaxWidthCell>
                <Yalc2Cell.SubCell>
                  {Yalc2Cell.horizontal(
                    <>
                      <span style={{ marginRight: 6 }}>{`...${props.item.vin.slice(-7)}`}</span>
                      <IconButton
                        aria-label="copy vin"
                        onClick={async (event) => {
                          event.stopPropagation()
                          await clipboard.write(props.item.vin)
                        }}
                        size="small"
                        tooltip="FIN kopieren"
                        style={{ margin: -4 }}
                      >
                        <Icon name="copy" fontSize="small" style={{ height: 12 }} />
                      </IconButton>
                    </>
                  )}
                </Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'handover_planned_date',
          title: 'Auslieferungsdatum',
          width: 150,
          sortColumn: 'handover_planned_date',
          cell: function HandoverPlannedDateCell(props) {
            return <Yalc2Cell.SimpleCell>{formatDateString(props.item.handover_planned_date)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'selling_price',
          title: 'Verkaufspreis',
          width: 300,
          sortColumn: 'selling_price',
          cell: function PriceCell(props) {
            return (
              <Yalc2Cell.SimpleCell className={'flex flex-col'}>
                <span>{`Listenpreis: ${formatNumber(props.item.list_price, {
                  style: 'currency',
                  currency: 'EUR',
                })}`}</span>
                <b>{`Verkaufspreis: ${formatNumber(props.item.selling_price, {
                  style: 'currency',
                  currency: 'EUR',
                })}`}</b>
                <span>{`Voraussichtlicher Verkaufspreis: ${formatNumber(props.item.expected_sales_price, {
                  style: 'currency',
                  currency: 'EUR',
                })}`}</span>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'aid',
          title: 'Förderung',
          width: 120,
          sortColumn: 'aid',
          cell: function AidCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.aid ?? '-'}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'winter_tires',
          title: 'Winterräder',
          width: 150,
          sortColumn: 'winter_tires',
          cell: function WinterTires(props) {
            return (
              <Yalc2Cell.SimpleCell>
                {Yalc2Cell.horizontal(
                  <>
                    <StatusLight status={props.item.winter_tires ? 'success' : 'error'} />
                    <Yalc2Cell.SubCell>
                      {formatNumber(props.item.winter_tires_price, {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </Yalc2Cell.SubCell>
                  </>
                )}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'insurance',
          title: 'Versicherung',
          width: 150,
          sortColumn: 'insurance_provider_name',
          cell: function InsuranceCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <Yalc2Cell.MaxWidthCell>{props.item.insurance_provider_name}</Yalc2Cell.MaxWidthCell>
                <Yalc2Cell.SubCell>{props.item.insurance_product_name}</Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'exchange',
          title: 'Eintausch',
          width: 150,
          cell: function ExchangeCell(props) {
            const TooltipContent = (
              <>
                <p>{`Marke: ${props.item.brand.name ?? '-'}`}</p>
                <p>{`FIN: ${props.item.exchange_vin ?? '-'}`}</p>
              </>
            )
            return (
              <Tooltip content={TooltipContent}>
                <Yalc2Cell.SimpleCell>
                  <Yalc2Cell.MaxWidthCell>{props.item.exchange_model_name}</Yalc2Cell.MaxWidthCell>
                  <Yalc2Cell.SubCell>
                    {formatNumber(props.item.exchange_price, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </Yalc2Cell.SubCell>
                </Yalc2Cell.SimpleCell>
              </Tooltip>
            )
          },
        },
        {
          key: 'extra',
          title: 'Zubehör',
          width: 150,
          cell: function ExtrasCell() {
            return <Yalc2Cell.SimpleCell></Yalc2Cell.SimpleCell>
          },
        },
      ],
    }),
    [formatNumber, formatDateString, t]
  )
}
