import { Form, TextField } from 'components'
import { DatePicker } from 'components/DatePicker'
import { DetailWindow } from 'components/DetailWindow'
import { DealerVehicleStateSelect } from 'containers/DealerVehicleStateSelect'
import { Dealer_Vehicle_State_Enum, In_Buy_Channel_Enum } from 'gql'
import React, { useEffect, useMemo, useState } from 'react'
import { VehicleFormData, VehicleFormValues } from '..'
import { useVehicleFormValues } from '.'
import * as L from 'layouts'
import { UserSelect } from 'containers/UserSelect'
import { CustomerTypeSelect } from '../../CustomerTypeSelect'

interface ChangeStateModalProps {
  onClose: () => void
  onSave: (data: VehicleFormValues) => Promise<void>
  data: VehicleFormData
  desiredState?: Dealer_Vehicle_State_Enum
}

export const ChangeStateModal = (props: ChangeStateModalProps) => {
  const [desiredState, setDesiredState] = useState<null | Dealer_Vehicle_State_Enum>(props.desiredState ?? null)
  const usedCar = useMemo(() => props.data?.in_buy_channel !== In_Buy_Channel_Enum.Import, [props.data?.in_buy_channel])
  // const [authData] = useEzAuth()
  const currentState = props.data.state
  const [{ bindings, values, setValue }] = useVehicleFormValues(props.data)
  useEffect(() => {
    setValue('state', desiredState)
  }, [desiredState, setValue])

  const handleSave = () => {
    // TODO: validate
    props.onSave(values)
  }

  const filterStates = (val: Dealer_Vehicle_State_Enum): boolean => {
    switch (val) {
      case currentState:
        return false
      case Dealer_Vehicle_State_Enum.Reserved:
        return currentState === Dealer_Vehicle_State_Enum.Available
      case Dealer_Vehicle_State_Enum.Sold:
      case Dealer_Vehicle_State_Enum.SoldToPartner:
      case Dealer_Vehicle_State_Enum.SoldByBroker:
        return [Dealer_Vehicle_State_Enum.Available, Dealer_Vehicle_State_Enum.Reserved].indexOf(currentState) >= 0
      case Dealer_Vehicle_State_Enum.SoldByPartner:
        return (
          [
            Dealer_Vehicle_State_Enum.Available,
            Dealer_Vehicle_State_Enum.Reserved,
            Dealer_Vehicle_State_Enum.SoldToPartner,
          ].indexOf(currentState) >= 0
        )
      case Dealer_Vehicle_State_Enum.Other:
      case Dealer_Vehicle_State_Enum.Available:
        return true
    }
  }

  const showSoldBy =
    [
      Dealer_Vehicle_State_Enum.Sold,
      Dealer_Vehicle_State_Enum.SoldByBroker,
      Dealer_Vehicle_State_Enum.SoldToPartner,
    ].indexOf(desiredState) >= 0
  const showCustomer =
    [Dealer_Vehicle_State_Enum.Sold, Dealer_Vehicle_State_Enum.SoldByBroker].indexOf(desiredState) >= 0
  const showSoldAt = showSoldBy || showCustomer
  const showReservedFields = desiredState === Dealer_Vehicle_State_Enum.Reserved

  return (
    <DetailWindow title="Status ändern" size="small" onClose={props.onClose}>
      <Form className="flex flex-col h-full">
        <L.Container className="flex-1 flex flex-col gap-4">
          <DealerVehicleStateSelect
            value={desiredState}
            onSelect={(state) => setDesiredState(state)}
            label="Neuer Status"
            filterValue={filterStates}
            disabled={!!props.desiredState}
          />
          {showSoldBy && (
            <UserSelect
              label={'Verkäufer'}
              value={values.sold_by_id}
              onSelect={(user) => setValue('sold_by_id', user.id)}
            />
          )}
          {showCustomer && (
            <>
              <TextField {...bindings.customer} label="Kunde" />
              <CustomerTypeSelect
                label={'Kundentyp'}
                value={values.customer_type}
                onSelect={(type) => setValue('customer_type', type)}
              />
              <DatePicker
                label={usedCar ? 'Einkaufsvertragsdatum' : 'Auftragsdatum'}
                value={values.in_buy_contract_date}
                onChange={(date) => setValue('in_buy_contract_date', date.toISOString())}
              />
            </>
          )}
          {showSoldAt && (
            <>
              <DatePicker
                value={values.sold_at}
                onChange={(date) => setValue('sold_at', date.toISOString())}
                label="Verkaufsdatum"
              />
              <DatePicker
                label={'Auslieferungsdatum'}
                value={values?.handover_planned_date}
                onChange={(date) => setValue('handover_planned_date', date.toISOString())}
              />
            </>
          )}
          {showReservedFields && (
            <>
              <UserSelect
                label={'Reserviert von'}
                value={values.reserved_by_id}
                onSelect={(user) => setValue('reserved_by_id', user.id)}
              />
              <TextField
                {...bindings.reserved_for}
                label={'Reserviert für'}
                onChange={(evt) => setValue('reserved_for', evt.target.value)}
              />
              <CustomerTypeSelect
                label={'Kundentyp'}
                value={values.reserved_for_customer_type}
                onSelect={(type) => setValue('reserved_for_customer_type', type)}
              />
              <DatePicker
                label={'Reserviert am'}
                value={values.reserved_at}
                onChange={(date) => setValue('reserved_at', date.toISOString())}
              />
              <DatePicker
                label={'Reserviert bis'}
                value={values.reserved_until}
                onChange={(date) => setValue('reserved_until', date.toISOString())}
              />
            </>
          )}
        </L.Container>
        <Form.Actions onSave={handleSave} onCancel={props.onClose} />
      </Form>
    </DetailWindow>
  )
}
