import React, { useEffect } from 'react'
import { useFormFields } from '@w2solutions/react-hooks'
import { Button, Typo, ValueText } from 'components'
import { DetailWindow } from 'components/DetailWindow'
import { useAdminModelDetailsQuery, useAdminModelGroupListQuery, useUpdateModelsMutation } from 'gql'
import * as L from 'layouts'
import styled from './ModelDetails.module.css'
import { ModelGroupSelect } from '../../ModelGroupSelect'
import { ModelGroupReleaseYearSelect, ModelReleaseYear } from '../../ModelGroupReleaseYearSelect'
import usePrevious from '@w2solutions/react-hooks/dist/usePrevious'
import { SalesModelCodeInput } from './SalesModelCodeInput'

interface ModelDetailViewProps {
  modelId: string
  brandId: string
  onClose: () => void
  usedCar: boolean
  modal?: boolean
}

export const ModelDetailView = (props: ModelDetailViewProps) => {
  const query = useAdminModelDetailsQuery({ variables: { modelId: props.modelId } })
  const groupsQuery = useAdminModelGroupListQuery({ variables: { brandId: props.brandId } })
  const model = query.data?.model

  const { bindings, values, setValue } = useFormFields(
    {
      salesModelCode: model?.sales_model_code ?? '',
      modelGroupId: model?.modelGroup?.id ?? '',
      modelGroupReleaseYear: `${model?.model_group_release_year}` ?? '',
    },
    { resetOnInitialValueChange: true }
  )
  const groups = groupsQuery.data?.model_groups ?? []
  const selectedGroup = groups.find((g) => g.id === values.modelGroupId)
  const prevModelGroupId = usePrevious(values.modelGroupId)
  useEffect(() => {
    if (
      prevModelGroupId !== values.modelGroupId &&
      values.modelGroupId &&
      model?.model_group_release_year?.toString() !== values.modelGroupReleaseYear
    ) {
      setValue('modelGroupReleaseYear', ModelReleaseYear.UNKNOWN.toString())
    }
  }, [
    setValue,
    values.modelGroupId,
    prevModelGroupId,
    selectedGroup?.default_release_year,
    values.modelGroupReleaseYear,
    model?.model_group_release_year,
  ])

  const valid = (props.usedCar || !!values.salesModelCode) && !!values.modelGroupId && !!values.modelGroupReleaseYear
  const [update, updateReponse] = useUpdateModelsMutation()
  const handleSave = async () => {
    await update({
      variables: {
        set: {
          sales_model_code: !props.usedCar ? values.salesModelCode : undefined,
          model_group_id: values.modelGroupId,
          model_group_release_year: Number(values.modelGroupReleaseYear),
        },
        where: { id: { _eq: props.modelId } },
      },
    })
    props.onClose()
  }

  const copySalesModelCode = () => {
    setValue('salesModelCode', model?.model_code)
  }

  const loading = query.loading || !query.data?.model || groupsQuery.loading || updateReponse.loading
  return (
    <DetailWindow
      title="Modell Details"
      onClose={props.onClose}
      width={620}
      className={styled.root}
      loading={loading}
      variant={props.modal ? 'temporary' : 'persistent'}
    >
      {!loading && (
        <L.Vertical spacing={5}>
          <Typo variant="h1" style={{ fontWeight: 500 }}>
            {model.description}
          </Typo>
          <L.Grid>
            <ValueText label="Marken Modell Code">{model.model_code}</ValueText>
            {!props.usedCar && (
              <ValueText label="Sales Modell Code">
                <SalesModelCodeInput onClick={copySalesModelCode} {...bindings.salesModelCode} disabled={loading} />
              </ValueText>
            )}
          </L.Grid>
          <L.Grid>
            <ValueText label="Modell Gruppe">
              <ModelGroupSelect
                groups={groups}
                hideLabel
                style={{ display: 'block', width: '100%' }}
                value={values.modelGroupId}
                onSelect={(modelGroupId) => setValue('modelGroupId', modelGroupId)}
              />
            </ValueText>
            <ValueText label={'Release Jahr'}>
              <ModelGroupReleaseYearSelect
                group={selectedGroup}
                hideLabel
                value={Number(values.modelGroupReleaseYear)}
                onSelect={(year) => setValue('modelGroupReleaseYear', String(year))}
                style={{ display: 'block', width: '100%' }}
              />
            </ValueText>
          </L.Grid>
          <div>
            <Button variant="contained" color="primary" onClick={handleSave} disabled={!valid}>
              Speichern
            </Button>
          </div>
        </L.Vertical>
      )}
    </DetailWindow>
  )
}
