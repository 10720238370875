import { ReactNode, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'

let body: HTMLElement
// @ts-ignore
if (process.browser) {
  body = document.body
}

type Props = {
  children: ReactNode
  mountElement?: HTMLElement
  name?: string
}

export const Portal = (props: Props) => {
  const mountElement = props.mountElement || body
  const name = props.name || 'portal'

  const ownerDocument = mountElement ? mountElement.ownerDocument : null

  const rootRef = useRef(ownerDocument ? ownerDocument.createElement(name) : null)

  useEffect(() => {
    const rootElement = rootRef.current
    if (mountElement && rootElement) {
      mountElement.appendChild(rootElement)
    }
    return () => {
      rootElement && rootElement.remove()
    }
  }, [mountElement])

  if (!rootRef.current) {
    return null
  }
  return ReactDOM.createPortal(props.children, rootRef.current)
}
