import React, { CSSProperties } from 'react'

interface IframeProps {
  title: string
  url?: string
  className?: string
  style?: CSSProperties
}

export const Iframe = (props: IframeProps) => {
  if (!props.url) return null
  return <iframe className={props.className} style={props.style} title={props.title} src={props.url}></iframe>
}
