import { DetailWindow } from 'components/DetailWindow'
import { Spinner } from 'components/Spinner'
import * as L from 'layouts'
import {
  useAdminModelGroupLazyQuery,
  useAdminModelGroupListQuery,
  useCreateModelGroupMutation,
  useUpdateModelGroupMutation,
} from 'gql'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Chip, Menu, TextField, Typo } from 'components'
import { useFormFields } from '@w2solutions/react-hooks'
import { DateFns } from 'utils/date-fns'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { Yalc2, Yalc2RowDefinition } from '../../components/Yalc2'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'

interface AdminModelGroupProps {
  brandId: string
}

export const AdminModelGroups = (props: AdminModelGroupProps) => {
  const query = useAdminModelGroupListQuery({ variables: { brandId: props.brandId } })

  const groups = query.data?.model_groups ?? []

  const [groupId, setGroupId] = useState<string[]>([])
  const handleCloseDetailView = () => {
    setGroupId(null)
  }
  const detailViewProps = useMemo(
    () => ({
      refetch: query.refetch,
      onClose: handleCloseDetailView,
      groupId: groupId?.[0],
      brandId: props.brandId,
    }),
    [groupId, props.brandId, query.refetch]
  )
  const {openDetailView} = useDetailView()
  useEffect(() => {
    if (groupId && groupId.length === 1) {
      openDetailView(DetailViewEnum.ModelGroupDetail, detailViewProps)
    }
  }, [openDetailView, detailViewProps, groupId])

  const rowDefinition = useRowDefinition()
  if (query.loading) {
    return <Spinner />
  }

  return (
    <>
      <Yalc2<GroupListItem>
        rowDefinition={rowDefinition}
        data={groups}
        selectedIds={groupId}
        onSelectionChanged={(ids) => {
          setGroupId(ids.length ? ids : null)
        }}
        options={
          <Menu.Item onClick={() => openDetailView(DetailViewEnum.ModelGroupDetail, detailViewProps)}>
            Neue Gruppe
          </Menu.Item>
        }
      />
    </>
  )
}

interface GroupListItem {
  id: string
  name: string
  default_release_year?: number
}

const useRowDefinition = (): Yalc2RowDefinition<GroupListItem> => {
  return useMemo(
    () => ({
      columns: [
        {
          title: 'Name',
          width: 200,
          cell: (props) => {
            return <Yalc2Cell.SimpleCell>{props.item.name}</Yalc2Cell.SimpleCell>
          },
        },
        {
          title: 'aktuelles Release Jahr',
          width: 200,
          cell: (props) => {
            return <Yalc2Cell.SimpleCell>{props.item.default_release_year}</Yalc2Cell.SimpleCell>
          },
        },
      ],
    }),
    []
  )
}

interface DetailViewPorps {
  groupId: string | null
  brandId: string
  refetch: () => any
  onClose: () => void
}

interface GroupFormType {
  name: string
  releaseYears: number[]
}

export const DetailView = (props: DetailViewPorps) => {
  const [group, meta] = useDetailGroup(props.groupId)

  const { bindings, setValue, values } = useFormFields(group, { resetOnInitialValueChange: true })

  const [save, { loading: saving }] = useSave(props.brandId)
  const handleSave = async () => {
    await save(props.groupId, values)
    props.refetch()
    props.onClose()
  }

  const [rlsYearStr, setRlsYear] = useState('')
  const rlsYear = Number(rlsYearStr)
  const validRlsYear =
    !Number.isNaN(rlsYear) &&
    Number.isSafeInteger(rlsYear) &&
    rlsYear > 1950 &&
    rlsYear <= DateFns.addMonths(new Date(), 3).getFullYear()
  const handleAddReleaseYear = () => {
    if (validRlsYear) {
      setValue('releaseYears', Array.from(new Set([...values.releaseYears, rlsYear].sort())))
      setRlsYear('')
    }
  }

  const loading = meta.loading || saving
  return (
    <DetailWindow title="Modell Gruppe Details" onClose={props.onClose} width="620px">
      {loading ? (
        <Spinner />
      ) : (
        <L.Vertical spacing={5}>
          <L.Vertical style={{ maxWidth: 320 }}>
            <Typo variant="h1" style={{ fontWeight: 500 }}>
              {props.groupId ? 'Details' : 'Neu Gruppe'}
            </Typo>

            <L.Vertical spacing={3}>
              <TextField label="Name" {...bindings.name} />
              <L.Vertical spacing={1}>
                <L.Horizontal>
                  <TextField
                    label="Release Jahr hinzufügen"
                    type="number"
                    value={rlsYearStr}
                    onChange={(evt) => {
                      setRlsYear(evt.currentTarget.value)
                    }}
                    onKeyDown={(evt) => {
                      // keyCode 13 = Enter Key
                      if (evt.keyCode === 13) {
                        handleAddReleaseYear()
                      }
                    }}
                    style={{ width: 160, flexGrow: 1 }}
                  />
                  <Button onClick={handleAddReleaseYear} variant="contained" disabled={!validRlsYear}>
                    {'Hinzufügen'}
                  </Button>
                </L.Horizontal>
                <L.Horizontal spacing={0.5} wrap="wrap">
                  {values.releaseYears.map((year) => (
                    <Chip key={year}>{year}</Chip>
                  ))}
                </L.Horizontal>
              </L.Vertical>
            </L.Vertical>

            <div>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Speichern
              </Button>
            </div>
          </L.Vertical>
        </L.Vertical>
      )}
    </DetailWindow>
  )
}

const useDetailGroup = (groupId: string | null): [GroupFormType, { loading: boolean }] => {
  const [query, response] = useAdminModelGroupLazyQuery()
  useEffect(() => {
    if (groupId) {
      query({ variables: { id: groupId } })
    }
  }, [groupId, query])
  if (!groupId) {
    return [{ name: '', releaseYears: [] }, { loading: false }]
  }
  const formData = {
    name: response.data?.model_group?.name ?? '',
    releaseYears: response.data?.model_group?.releaseYears?.map((ref) => ref.release_year) ?? [],
  }
  return [formData, response]
}

type SaveFn = (groupId: string | null, data: GroupFormType) => Promise<void>
const useSave = (brandId: string): [SaveFn, { loading: boolean }] => {
  const [create, createResponse] = useCreateModelGroupMutation()
  const [update, updateResponse] = useUpdateModelGroupMutation()

  const fn = useCallback(
    async (groupId: string | null, data: GroupFormType) => {
      if (groupId) {
        await update({
          variables: {
            modelGroupId: groupId,
            modelGroup: { name: data.name },
            releaseYears: data.releaseYears ?? [],
            releaseYearReferences: (data.releaseYears ?? []).map((release_year) => ({
              model_group_id: groupId,
              release_year,
            })),
          },
        })
      } else {
        await create({
          variables: {
            object: {
              brand_id: brandId,
              name: data.name,
              releaseYears: {
                data: data.releaseYears.map((year) => ({
                  release_year: year,
                })),
              },
            },
          },
        })
      }
    },
    [brandId, create, update]
  )
  const extState = useMemo(
    () => ({
      loading: createResponse.loading || updateResponse.loading,
    }),
    [createResponse.loading, updateResponse.loading]
  )
  return [fn, extState]
}
