import React, { CSSProperties } from 'react'
import { Select } from 'components'
import { Registration_State_Enum } from 'gql'
import { useTranslation } from 'react-i18next'

interface RegistrationStateSelectProps {
  value: Registration_State_Enum
  onSelect: (state: Registration_State_Enum) => void
  name?: string
  label?: string
  disabled?: boolean
  style?: CSSProperties
}

export const RegistrationStateSelect: React.FC<RegistrationStateSelectProps> = (props) => {
  const { value, onSelect, name, label, style, disabled } = props
  const { t } = useTranslation()

  return (
    <Select
      name={name ?? 'registrationStateSelect'}
      label={label}
      value={value}
      onChange={(evt) => {
        evt.stopPropagation()
        onSelect(evt.target.value as Registration_State_Enum)
      }}
      renderSelectValueItem={(state) => t(`RegistrationState.${state}`)}
      disabled={disabled}
      style={style}
    >
      {Object.keys(Registration_State_Enum).map((state) => (
        <Select.Option key={state} value={Registration_State_Enum[state]}>
          {t(`RegistrationState.${Registration_State_Enum[state]}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
