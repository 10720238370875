import React, { CSSProperties } from 'react'
import MuiToolbar from '@mui/material/Toolbar'

interface ToolbarProps {
  className?: string
  style?: CSSProperties
}

export const Toolbar: React.FC<ToolbarProps> = (props) => {
  return <MuiToolbar {...props} disableGutters />
}
