import { VehicleItem } from './index'
import React, { useContext, useMemo } from 'react'
import { DealerVehicleStateAvatar } from '../../../DealerVehicleState/avatar'
import { useTranslation } from 'react-i18next'
import {
  Dealer_Vehicle_Type_Enum,
  Import_Type_Enum,
  Order_State_Enum,
  VehiclesMetafieldsAllBrandsQueryResult,
} from '../../../../gql'
import { StatusLight } from '../../../StatusLight'
import { Avatar, Icon, IconButton, MaxWidthText, Tooltip, Typo } from '../../../../components'
import { Yalc2Cell } from '../../../../components/Yalc2/cells'
import { clipboard } from '../../../../utils/clipboard'
import { useFormatter } from '../../../../hooks'
import { DateFns } from '../../../../utils'
import { useVehicleValidationSchema } from '../../../VehicleForm'
import { ListConfigContext } from '../../index'
import { OwnerTypeAvatar } from '../../../OwnerType/Avatar'
import { InBuyChannelChip } from '../../../InBuyChannel/Chip'
import { Yalc2RowDefinition } from '../../../../components/Yalc2'
import clsx from 'clsx'
import { VehicleOptions } from '../../../VehicleOptions'
import { deriveUserAbbreviation } from '../../../AdminUser/UsersList'

export const useRowDefinition = (
  columns: string[],
  metadata: { metafields: VehiclesMetafieldsAllBrandsQueryResult['data']['brand_vehicle_metafield'] }
): Yalc2RowDefinition<VehicleItem> => {
  const { highlights } = useContext(ListConfigContext)
  const allColumns: Yalc2RowDefinition<VehicleItem> = useMemo(() => {
    const metafields = metadata.metafields
    return {
      columns: [
        {
          key: 'importState',
          width: 25,
          title: '',
          cell: function ImportState(props) {
            const { isValid } = useVehicleValidationSchema(
              props.item as any,
              props.item.brand.id,
              { metafields },
              { autoRun: true }
            )
            const { isValid: isPartiallyValid } = useVehicleValidationSchema(
              props.item as any,
              props.item.brand.id,
              { metafields, importType: Import_Type_Enum.Partial },
              { autoRun: true }
            )
            return (
              <StatusLight variant="filled" status={isValid ? 'success' : isPartiallyValid ? 'warning' : 'error'} />
            )
          },
        },
        {
          key: 'state',
          title: 'Status',
          sortColumn: 'state',
          width: 80,
          cell: function StatusCell(props) {
            return (
              <div>
                <DealerVehicleStateAvatar state={props.item.state} />
              </div>
            )
          },
        },
        {
          key: 'inBuyChannel',
          title: 'Zukaufs Kanal',
          width: 200,
          cell: function InBuyChannelCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <InBuyChannelChip channel={props.item.in_buy_channel} />
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'invalidity',
          width: 0,
          title: '',
          cell: function Invalidity(props) {
            if (!props.item?.sync_error) return null
            return (
              <svg height="20" width="20" className="absolute left-0 bottom-0">
                <polygon points="0,20 20,20 0,0" className="stroke-1" style={{ fill: 'red', stroke: 'transparent' }} />
              </svg>
            )
          },
        },
        {
          key: 'markedForDelete',
          width: 0,
          title: '',
          cell: function MarkedForDelete(props) {
            if (props.item.marked_for_delete)
              return <div className="bg-background-default opacity-60 absolute left-0 h-full w-full"></div>
            return null
          },
        },
        {
          key: 'brand',
          sortColumn: 'brand_id',
          title: 'Marke',
          width: 100,
          cell: function BrandCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.brand.name}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'owner',
          title: 'Besitzer',
          width: 200,
          cell: function OwnerCell(props) {
            let name = '-'
            let type: 'location' | 'supplier' | 'customer' | undefined = undefined
            if (props.item.owner_location_id) {
              type = 'location'
              name = props.item.owner_location.short_name || props.item.owner_location.name || '-'
            } else if (props.item.owner_customer_id) {
              type = 'customer'
              name = props.item.owner_customer.name || '-'
            } else if (props.item.owner_supplier_id) {
              type = 'supplier'
              name = props.item.owner_supplier.name || '-'
            }
            return (
              <Yalc2Cell.SimpleCell>
                {Yalc2Cell.horizontal(
                  <>
                    <OwnerTypeAvatar type={type} />
                    <span>{name}</span>
                  </>
                )}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'previousOwner',
          title: 'Vorbesitzer',
          width: 200,
          cell: function PreviousOwnerCell(props) {
            let name = '-'
            let type: 'location' | 'supplier' | 'customer' | undefined = undefined
            if (props.item.previous_owner_location_id) {
              name = props.item.previous_location.short_name || props.item.previous_location.name || '-'
              type = 'location'
            } else if (props.item.previous_owner_customer_id) {
              type = 'customer'
              name = props.item.previous_customer.name || '-'
            } else if (props.item.previous_owner_supplier_id) {
              type = 'supplier'
              name = props.item.previous_supplier.name || '-'
            }
            return (
              <Yalc2Cell.SimpleCell>
                {Yalc2Cell.horizontal(
                  <>
                    <OwnerTypeAvatar type={type} />
                    <span>{name}</span>
                  </>
                )}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'deliveryLocation',
          title: 'Lagerort',
          width: 100,
          cell: function StorageLocation(props) {
            const { t } = useTranslation()
            const name =
              props.item.order_state === Order_State_Enum.InStock
                ? props.item.storage_location?.location?.short_name || props.item.storage_location?.name || '-'
                : props.item.delivery_location?.location?.short_name || props.item.delivery_location?.name || '-'
            const TooltipContent = (
              <>
                <p>
                  {`Lagerstatus: `}
                  {props.item.order_state ? (
                    <>
                      <StatusLight
                        status={
                          props.item.order_state === Order_State_Enum.InStock
                            ? 'success'
                            : props.item.order_state === Order_State_Enum.Handover
                            ? 'error'
                            : 'warning'
                        }
                        variant="filled"
                      />
                      {` ${t(`OrderState.${props.item.order_state}`)}`}
                    </>
                  ) : (
                    '-'
                  )}
                </p>
                <p>{`Lagerort: ${props.item.storage_location?.name || '-'}`}</p>
                <p>{`Lieferort: ${props.item.delivery_location?.name || '-'}`}</p>
                <p>{`Lieferdatum: ${props.item.delivery_date_str || '-'}`}</p>
              </>
            )
            return (
              <Tooltip content={TooltipContent}>
                <Yalc2Cell.SimpleCell>
                  {Yalc2Cell.horizontal(
                    <>
                      {props.item.order_state && (
                        <StatusLight
                          status={
                            props.item.order_state === Order_State_Enum.InStock
                              ? 'success'
                              : props.item.order_state === Order_State_Enum.Handover
                              ? 'error'
                              : 'warning'
                          }
                          variant="filled"
                        />
                      )}
                      <div className={'overflow-hidden overflow-ellipsis whitespace-nowrap'} style={{ maxWidth: 75 }}>
                        {name}
                      </div>
                    </>
                  )}
                </Yalc2Cell.SimpleCell>
              </Tooltip>
            )
          },
        },
        {
          key: 'jobNumber',
          width: 100,
          title: 'Bestell#',
          sortColumn: 'job_number',
          cell: function JobNumber(props) {
            return <Yalc2Cell.SimpleCell>{props.item.job_number ?? '-'}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'vin',
          width: 180,
          title: 'FIN',
          sortColumn: 'vin',
          cell: function Vin(props) {
            return <Yalc2Cell.SimpleCell>{props.item.vin}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'jobNumberAdvanced',
          title: 'Auftrags-Nr.',
          width: 120,
          sortColumn: 'job_number',
          cell: function JobNumberAdvanced(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <MaxWidthText>{props.item.job_number ?? '-'}</MaxWidthText>
                <Yalc2Cell.SubCell>
                  {Yalc2Cell.horizontal(
                    <>
                      <span style={{ marginRight: 6 }}>{`...${props.item.vin.slice(-7)}`}</span>
                      <IconButton
                        aria-label="copy vin"
                        onClick={(event) => {
                          event.stopPropagation()
                          clipboard.write(props.item.vin)
                        }}
                        size="small"
                        tooltip="FIN kopieren"
                        style={{ margin: -4 }}
                      >
                        <Icon name="copy" fontSize="small" style={{ height: 12 }} />
                      </IconButton>
                    </>
                  )}
                </Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'type',
          title: 'Typ',
          width: 60,
          sortColumn: 'type',
          cell: function VehicleType(props) {
            const abbr = useMemo(() => {
              switch (props.item.type) {
                case Dealer_Vehicle_Type_Enum.DemonstrationCar:
                  return 'VFW'
                case Dealer_Vehicle_Type_Enum.NewCar:
                  return 'NW'
                case Dealer_Vehicle_Type_Enum.UsedCar:
                  return 'GW'
              }
            }, [props.item.type])
            return (
              <div>
                <Typo variant="h6">{abbr}</Typo>
              </div>
            )
          },
        },
        {
          key: 'registrationState',
          title: 'Zulassungs Status',
          width: 150,
          sortColumn: 'registration_state',
          cell: function RegistrationState(props) {
            const { t } = useTranslation()
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.registration_state ? t(`RegistrationState.${props.item.registration_state}`) : '-'}
                <Yalc2Cell.SubCell>
                  <MaxWidthText>{`ZL Besitzer: ${props.item.registration_owner ?? '-'}`}</MaxWidthText>
                </Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'modelUsedCar',
          width: 230,
          title: 'Modell',
          sortColumn: 'model_name',
          cell: function ModellDescriptionUsedCar(props) {
            const TooltipContent = (
              <>
                <p>{`Modell Gruppe: ${props.item.model.modelGroup?.name ?? '-'}`}</p>
                <p>{`Modell Release Jahr: ${props.item.model.model_group_release_year}`}</p>
              </>
            )
            return (
              <Tooltip content={TooltipContent}>
                <Yalc2Cell.MaxWidthCell>{props.item.model_name}</Yalc2Cell.MaxWidthCell>
              </Tooltip>
            )
          },
        },
        {
          key: 'model',
          width: 230,
          title: 'Modell',
          sortColumn: 'model_name',
          cell: function ModellDescription(props) {
            const TooltipContent = (
              <>
                <p>{`Modell Gruppe: ${props.item.model.modelGroup?.name ?? '-'}`}</p>
                <p>{`Modell Code: ${props.item.model.sales_model_code ?? '-'}`}</p>
                <p>{`Modell Release Jahr: ${props.item.model.model_group_release_year ?? '-'}`}</p>
              </>
            )
            return (
              <Tooltip content={TooltipContent}>
                <Yalc2Cell.SimpleCell>
                  <div className={'overflow-hidden overflow-ellipsis whitespace-nowrap'}>{props.item.model_name}</div>
                  <Yalc2Cell.SubCell>{props.item.model.sales_model_code}</Yalc2Cell.SubCell>
                </Yalc2Cell.SimpleCell>
              </Tooltip>
            )
          },
        },
        {
          key: 'daysSincePurchase',
          width: 100,
          title: 'Standtage',
          sortColumn: 'days_since_purchase',
          cell: function DaysSincePurchaseCell(props) {
            const { formatNumber } = useFormatter()
            return <Yalc2Cell.SimpleCell>{formatNumber(props.item.days_since_purchase)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'error',
          width: 300,
          title: 'Fehlermeldung',
          cell: function ErrorMessage(props) {
            const TooltipContent = (
              <>
                {props.item.sync_errors &&
                  props.item.sync_errors.length > 0 &&
                  props.item.sync_errors.map((err) => <li key={err.id}>{err.message}</li>)}
              </>
            )
            return (
              <Tooltip content={TooltipContent}>
                <Yalc2Cell.SimpleCell className={'overflow-hidden overflow-ellipsis whitespace-nowrap'}>
                  {props.item.sync_errors?.[0]?.message}
                </Yalc2Cell.SimpleCell>
              </Tooltip>
            )
          },
        },
        {
          key: 'specs',
          title: 'Ausstattung',
          width: 200,
          sortColumn: 'spec_level',
          cell: function EquipmentCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.spec_level}</Yalc2Cell.MaxWidthCell>
          },
        },
        {
          key: 'color',
          title: 'Farbe',
          width: 200,
          sortColumn: 'color_exterior_name',
          cell: function ModelCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                {`${props.item.color_exterior_name || '-'} (${props.item.color_exterior_code || '-'})`}
                <Yalc2Cell.SubCell>
                  {`${props.item.color_interior_name || '-'} (${props.item.color_interior_code || '-'})`}
                </Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'firstRegistration',
          title: 'EZL',
          width: 120,
          sortColumn: 'first_registration_date',
          cell: function VehicleDatesCell(props) {
            const { formatDateString, formatNumber } = useFormatter()
            return (
              <Yalc2Cell.SimpleCell>
                {Yalc2Cell.horizontal(
                  <>
                    <span>{formatDateString(props.item.first_registration_date)}</span>
                    {props.item.warranty_start && (
                      <Icon
                        name="info"
                        color="inherit"
                        tooltip={`Garantiebeginn: ${props.item.warranty_start}`}
                        fontSize="small"
                        style={{ height: 12 }}
                      />
                    )}
                  </>
                )}
                <Yalc2Cell.SubCell>{formatNumber(props.item.mileage)} km</Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'licensePlate',
          title: 'KZ',
          width: 100,
          sortColumn: 'license_plate_number',
          cell: function LicencePlateCell(props) {
            const { formatDateString } = useFormatter()
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.license_plate_number || '-'}
                <Yalc2Cell.SubCell>
                  {props.item.min_holding_period
                    ? formatDateString(
                        DateFns.addDays(
                          new Date(props.item.first_registration_date),
                          props.item.min_holding_period ?? 0
                        )
                      )
                    : '-'}
                </Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'note',
          title: 'Notiz',
          width: 150,
          cell: function ModelCell() {
            return <Yalc2Cell.SimpleCell>{'-'}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'soldBy',
          title: 'VK',
          width: 150,
          cell: function SoldByCell(props) {
            const abbreviation = deriveUserAbbreviation(props.item.sold_by)
            return (
              <Yalc2Cell.SimpleCell>
                {abbreviation && (
                  <Avatar alt={`Abkürzung: ${abbreviation}`} className="bg-primary-main">
                    {abbreviation}
                  </Avatar>
                )}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'purchasedBy',
          title: 'Zukauf',
          width: 150,
          cell: function PurchasedByCell(props) {
            const { formatDateString } = useFormatter()
            const abbreviation = deriveUserAbbreviation(props.item.purchased_by)
            return (
              <Yalc2Cell.SimpleCell>
                {Yalc2Cell.horizontal(
                  <>
                    {abbreviation && (
                      <Avatar alt={`Abkürzung: ${abbreviation}`} className="bg-primary-main">
                        {abbreviation}
                      </Avatar>
                    )}
                    <span>{formatDateString(props.item.in_buy_contract_date)}</span>
                  </>
                )}
              </Yalc2Cell.SimpleCell>
            )
          },
        },

        {
          key: 'customer',
          title: 'Kunde',
          width: 100,
          sortColumn: 'customer',
          cell: function CustomerCell(props) {
            const { formatDateString } = useFormatter()
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.customer || '-'}
                <Yalc2Cell.SubCell>{formatDateString(props.item.sold_at)}</Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'deliveryDate',
          title: 'Lieferungsdatum',
          width: 130,
          cell: function DeliveryDateCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.delivery_date_str}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'handoverDate',
          title: 'Auslieferungsdatum',
          width: 200,
          cell: function HandoverDateCell(props) {
            const { formatDateString } = useFormatter()
            return (
              <Yalc2Cell.SimpleCell>
                {formatDateString(props.item.handover_planned_date)}
                <Yalc2Cell.SubCell>Erfassung: {formatDateString(props.item.handover_date)}</Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'keysCount',
          title: 'Schlüsseln',
          width: 80,
          cell: function KeysCountCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.keys_count}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'co2',
          title: 'CO2',
          width: 200,
          cell: function CO2Cell(props) {
            const { formatCO2Measurement } = useFormatter()
            return (
              <Yalc2Cell.SimpleCell>
                {`CO2 NEFZ (NEDC): ${formatCO2Measurement(props.item.co2_nefz)}`}
                <Yalc2Cell.SubCell>{`CO2 WLTP (WLTC): ${formatCO2Measurement(props.item.co2_wltp)}`}</Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'listPrice',
          title: 'Listenpreis',
          width: 100,
          cell: function ListPriceCell(props) {
            const { formatNumber } = useFormatter()
            return (
              <Yalc2Cell.SimpleCell>
                {formatNumber(props.item.list_price, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'cashPrice',
          title: 'Barpreis',
          width: 100,
          cell: function CashPriceCell(props) {
            const { formatNumber } = useFormatter()
            return (
              <Yalc2Cell.SimpleCell>
                {formatNumber(props.item.cash_price, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'boni',
          title: 'Boni',
          width: 250,
          cell: function BoniCell(props) {
            const { formatNumber } = useFormatter()
            return (
              <Yalc2Cell.SimpleCell className={'flex flex-col'}>
                {props.item.boni.map((bonus) => (
                  <span>
                    {bonus.type}:{' '}
                    {formatNumber(bonus.value, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </span>
                ))}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'quickActions',
          title: 'Optionen',
          width: 100,
          cell: function CreateTasksCell(props) {
            return (
              <Yalc2Cell.SimpleCell className={clsx('hidden items-end group-hover:flex')}>
                <VehicleOptions vehicle={props.item} />
              </Yalc2Cell.SimpleCell>
            )
          },
        },
      ],
    }
  }, [metadata.metafields])
  return useMemo(
    () => ({ columns: allColumns.columns.filter((col) => columns?.includes(col.key)), highlights }),
    [allColumns.columns, columns, highlights]
  )
}
