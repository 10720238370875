import React, { useEffect, useState } from 'react'
import * as L from 'layouts'
import { Chip, Menu, SiteMetadata, Spinner } from '../../../components'
import { Brand, useAdminSubDealerListQuery } from '../../../gql'
import { Yalc2, Yalc2RowDefinition } from 'components/Yalc2'
import { DetailViewEnum, useDetailView } from '../../../components/DetailWindow/useDetailView'

export const AdminsubDealersPage = () => {
  const subDealersConnection = useAdminSubDealerListQuery()
  const subDealers: SubDealerListElement[] =
    subDealersConnection.data?.subdealers?.map((node) => ({
      id: node.id,
      name: node.name,
      address: node.address.fullAddress,
      brands: node.brands?.map((b) => ({ id: b.id, name: b.name })),
    })) ?? []
  const {openDetailView} = useDetailView()
  return (
    <>
      <SiteMetadata title="Subhändler Verwalten" />
      <L.Vertical>
        <L.Horizontal>
          <div style={{ flexGrow: 1 }}></div>
          {subDealersConnection.loading && <Spinner />}
        </L.Horizontal>
        <SubDealerList
          onAdd={() =>
            openDetailView(DetailViewEnum.SubdealerDetail, {
              title: 'Neuer Subhändler',
              onClose: () => subDealersConnection.refetch(),
            })
          }
          subDealers={subDealers}
        />
      </L.Vertical>
    </>
  )
}

interface SubDealerListProps {
  subDealers: SubDealerListElement[]
  onAdd: () => void
}

interface SubDealerListElement {
  id: string
  name: string
  address: string
  brands: Pick<Brand, 'id' | 'name'>[]
}

const SubDealerList = (props: SubDealerListProps) => {
  const [selectedIds, setSelectedIds] = useState([])
  const subDealerToEdit = selectedIds.length === 1 ? selectedIds[0] : null

  const {openDetailView} = useDetailView()
  useEffect(() => {
    if (subDealerToEdit) {
      openDetailView(DetailViewEnum.SubdealerDetail, {
        title: 'Subhändler bearbeiten',
        onClose: () => setSelectedIds([]),
        subDealerId: subDealerToEdit,
      })
    }
  }, [openDetailView, subDealerToEdit])

  return (
    <div>
      <Yalc2<SubDealerListElement>
        data={props.subDealers}
        rowDefinition={rowDefinitions}
        onSelectionChanged={setSelectedIds}
        selectedIds={selectedIds}
        options={<Menu.Item onClick={props.onAdd}>Neuer Subhändler</Menu.Item>}
      />
    </div>
  )
}

const rowDefinitions: Yalc2RowDefinition<SubDealerListElement> = {
  columns: [
    {
      title: 'Name',
      width: 260,
      cell: ({ item }) => <>{item.name}</>,
    },
    {
      title: 'Adresse',
      width: 260,
      cell: ({ item }) => <>{item.address}</>,
    },
    {
      title: 'Marken',
      width: 660,
      cell: ({ item }) => <BrandCell brands={item.brands} />,
    },
  ],
}

interface BrandCellProps {
  brands: Pick<Brand, 'id' | 'name'>[]
}

const BrandCell: React.FC<BrandCellProps> = (props) => {
  return (
    <L.Horizontal spacing={1} style={{ flexWrap: 'wrap' }}>
      {props.brands.map((brand) => (
        <Chip color="primary" key={brand.id}>
          {brand.name}
        </Chip>
      ))}
    </L.Horizontal>
  )
}
