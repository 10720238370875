import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Ticket_Priority_Enum,
  Ticket_Template_Assignee_Ref_Enum,
  Ticket_Template_Location_Ref_Enum,
  useCreateTicketTemplateMutation,
  useTicketTemplateDetailLazyQuery,
  useUpdateTicketTemplateMutation,
} from '../../gql'
import { DetailWindow } from 'components/DetailWindow'
import * as L from 'layouts'
import { FormActions, TextField } from '../../components'
import { useFormFields } from '@w2solutions/react-hooks'
import { FormRow } from '../../components/FormRow'
import { DepartmentSelect } from '../DepartmentSelect'
import Joi from 'joi'
import { deualtJoiMessages, useJoi } from '../../hooks/useJoi'
import { Switch } from '@mui/material'
import { AssignReferenceUserSelect } from '../AssignReferenceSelect/User'
import { TriggerFilter } from './TriggerFilter'
import { FormSet } from 'components/FormSet'
import { PrioritySelect } from 'containers/PrioritySelect'
import { AssignReferenceLocationSelect } from '../AssignReferenceSelect/Location'

interface AdminTicketTemplateDetailProps {
  onClose: () => void
  templateId?: string
}

export const AdminTicketTemplateDetail = React.forwardRef<HTMLDivElement, AdminTicketTemplateDetailProps>(
  (props, ref) => {
    const [query, response] = useTicketTemplateDetailLazyQuery()
    const [create] = useCreateTicketTemplateMutation()
    const [update] = useUpdateTicketTemplateMutation()

    useEffect(() => {
      if (!props.templateId) return
      query({ variables: { id: props.templateId } })
    }, [props.templateId, query])

    const template = useMemo(() => response.data?.ticket_template, [response.data?.ticket_template])

    const [filter, setFilter] = useState(JSON.stringify(template?.trigger_filter ?? {}))

    useEffect(() => setFilter(JSON.stringify(template?.trigger_filter ?? {})), [template?.trigger_filter])

    const { bindings, values, setValue } = useFormFields(
      {
        templateTitle: template?.template_title ?? '',
        ticketTitle: template?.ticket_title ?? '',
        ticketDescription: template?.ticket_description ?? '',
        assignTo: template?.assign_to ?? (null as Ticket_Template_Assignee_Ref_Enum),
        location: template?.location ?? (null as Ticket_Template_Location_Ref_Enum),
        departmentId: template?.department_id ?? null,
        manualTask: !template?.automated_task,
        showDoneConditions: false,
        priority: template?.priority ?? (null as Ticket_Priority_Enum),
      },
      { resetOnInitialValueChange: true }
    )
    const { isValid, errors, validate } = useJoi(validationSchema, values)

    const handleSave = useCallback(async () => {
      await validate()
      if (!isValid) return
      if (props.templateId) {
        await update({
          variables: {
            id: props.templateId,
            set: {
              ticket_title: values.ticketTitle,
              template_title: values.templateTitle || values.ticketTitle,
              ticket_description: values.ticketDescription,
              automated_task: !values.manualTask,
              assign_to: values.assignTo,
              location: values.location,
              trigger_filter: JSON.parse(filter),
              priority: values.priority,
              department_id: values.departmentId,
            },
          },
        })
      } else {
        await create({
          variables: {
            input: {
              ticket_title: values.ticketTitle,
              template_title: values.templateTitle || values.ticketTitle,
              ticket_description: values.ticketDescription,
              department_id: values.departmentId,
              automated_task: !values.manualTask,
              assign_to: values.assignTo,
              location: values.location,
              trigger_filter: JSON.parse(filter),
              priority: values.priority,
            },
          },
        })
      }
      props.onClose()
    }, [create, filter, isValid, props, update, validate, values])
    return (
      <DetailWindow
        ref={ref}
        width={976}
        onClose={props.onClose}
        title={props.templateId ? 'Aufgaben Vorlage' : 'Neue Aufgaben Vorlage'}
        variant={'persistent'}
      >
        <L.Vertical spacing={4} className={'w-full p-4 h-full'}>
          <FormSet title={'Vorlage'} className={'w-1/2'}>
            <FormRow label={'Titel'}>
              <TextField {...bindings.templateTitle} />
            </FormRow>
          </FormSet>
          <FormSet title={'Aufgabe'} className={'w-1/2'}>
            <FormRow label={'Titel'} required error={errors.ticketTitle}>
              <TextField {...bindings.ticketTitle} />
            </FormRow>
            <FormRow label={'Beschreibung'} required error={errors.ticketDescription}>
              <TextField multiline {...bindings.ticketDescription} />
            </FormRow>
            <FormRow label={'Aufgaben Priorität'}>
              <PrioritySelect value={values.priority} onChange={(priority) => setValue('priority', priority)} />
            </FormRow>
            <FormRow label={'Bereich'}>
              <DepartmentSelect
                value={values.departmentId}
                onSelect={(department) => setValue('departmentId', department.id)}
              />
            </FormRow>
            <FormRow label={'Automatisch zuweisen zum Nutzer'}>
              <AssignReferenceUserSelect
                value={values.assignTo}
                onSelect={(assignTo) => setValue('assignTo', assignTo.name as Ticket_Template_Assignee_Ref_Enum)}
              />
            </FormRow>
            <FormRow label={'Automatisch zuweisen zum Standort'}>
              <AssignReferenceLocationSelect
                value={values.location}
                onSelect={(location) => setValue('location', location.name as Ticket_Template_Location_Ref_Enum)}
              />
            </FormRow>
          </FormSet>
          <FormSet title={'Auslöser'}>
            <FormRow label={'Manuell?'} className={'w-1/2'}>
              <Switch
                {...bindings.manualTask}
                checked={values.manualTask}
                onChange={(evt) => setValue('manualTask', evt.target.checked)}
              />
            </FormRow>
            {!values.manualTask && (
              <TriggerFilter className={'w-full'} filter={filter} onChange={(filter) => setFilter(filter)} />
            )}
          </FormSet>
          <FormSet title={'Aufgabe als fertig markieren wenn'} className={'w-1/2'}>
            <FormRow label={'Manuell?'}>
              <Switch
                disabled
                {...bindings.showDoneConditions}
                checked={!values.showDoneConditions}
                onChange={(evt) => setValue('showDoneConditions', !evt.target.checked)}
              />
            </FormRow>
          </FormSet>
        </L.Vertical>
        <FormActions onCancel={() => props.onClose()} onSave={handleSave}></FormActions>
      </DetailWindow>
    )
  }
)

const validationSchema = Joi.object<{ ticketTitle: string; ticketDescription: string }>({
  ticketTitle: Joi.string().required().label('Aufgaben Titel'),
  ticketDescription: Joi.string().required().label('Aufgaben Beschreibung'),
}).messages(deualtJoiMessages)
