import React, { useContext, useEffect, useRef, useState } from 'react'
import { ListConfigContext, ListConfigContextProps } from '../../index'
import * as L from 'layouts'
import {
  ExportDealerVehiclesVariant,
  useVehiclesMetafieldsAllBrandsQuery,
  VehiclesListQueryResult,
} from '../../../../gql'
import { useRowDefinition } from './useRowDefinition'
import { ActionBar } from './ActionBar'
import { useBrandId } from '../hooks/useBrandId'
import { useData, useVehiclesStore } from '../hooks/useData'
import { Yalc2 } from '../../../../components/Yalc2'
import Select from '../../../../components/Select'
import { Menu } from 'components/Menu'
import { VehicleExcelImport } from '../../../ExcelImport/excel-import-impl'
import { Button } from 'components/Button'
import { useDownload } from '../hooks/useDownload'
import { Icon } from 'components/Icon'
import { VehiclesHighlights } from '../../../ListManipulation/Vehicles/Highlights'
import { ListManipulation } from '../../../ListManipulation/unit'
import { DetailViewEnum, useDetailView } from '../../../../components/DetailWindow/useDetailView'

type VehicleItemList = (ArrayElement<VehiclesListQueryResult['data']['vehicles']> & { composedId: string })[]
export type VehicleItem = ArrayElement<VehicleItemList>

export const VehiclesList = (props: { brandId?: string; className?: string }) => {
  const { columns, groupBy, type, filter, order, setOrder } = useContext<ListConfigContextProps<VehicleItem>>(
    ListConfigContext
  )
  const importView = type?.startsWith('import')
  const { set: setBrandId } = useBrandId()

  useEffect(() => {
    if (!props.brandId) return
    setBrandId(props.brandId)
  }, [props.brandId, setBrandId])

  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [showExcelImport, setShowExcelImport] = useState<boolean>(false)

  useEffect(() => {
    setSelectedIds([])
  }, [filter, groupBy])

  const metafieldsQuery = useVehiclesMetafieldsAllBrandsQuery()
  const metafields = metafieldsQuery.data?.brand_vehicle_metafield

  const { pageInfo, nextPage, prevPage, cancelFetch } = useData({ order })

  const { initialized, init, vehicles, groups } = useVehiclesStore()
  useEffect(() => {
    if (!initialized) {
      cancelFetch()
      init()
    }
  }, [cancelFetch, init, initialized])

  const rowDefinition = useRowDefinition(columns, { metafields })

  const handleSort = (col: keyof ArrayElement<VehicleItemList>, direction: 'asc' | 'desc') =>
    setOrder({ col, direction })

  const detailRef = useRef()
  const importRef = useRef()
  const bulkImportRef = useRef()
  const bulkRef = useRef()

  const downloadExcel = useDownload()

  const {openDetailView, closeDetailView} = useDetailView()
  const handleBulkEdit = () => {
    if (importView) {
      openDetailView(DetailViewEnum.ImportVehiclesBulkEdit, {
        ref: bulkImportRef,
        ids: selectedIds,
        onClose: () => setSelectedIds([]),
      })
    } else {
      openDetailView(DetailViewEnum.VehiclesBulkEdit, {
        ref: bulkRef,
        ids: selectedIds,
        onClose: () => setSelectedIds([]),
      })
    }
  }

  useEffect(() => {
    if (selectedIds.length === 1 && importView) {
      openDetailView(DetailViewEnum.ImportVehicleDetail, {
        ref: importRef,
        inboxItemId: selectedIds[0],
        onClose: () => setSelectedIds([]),
      })
    } else if (selectedIds.length === 1 && !importView) {
      openDetailView(DetailViewEnum.VehicleDetail, {
        ref: detailRef,
        id: selectedIds[0],
        onClose: () => setSelectedIds([]),
      })
    } else if (selectedIds.length !== 1 && importView) {
      closeDetailView(DetailViewEnum.ImportVehicleDetail)
    } else if (selectedIds.length !== 1 && !importView) {
      closeDetailView(DetailViewEnum.VehicleDetail)
    }
  }, [closeDetailView, importView, openDetailView, selectedIds])

  if (!initialized) return null

  return (
    <L.Vertical spacing={4} className={props.className}>
      {!showExcelImport && (
        <ListManipulation>
          <Yalc2<VehicleItem>
            classes={{ item: 'group' }}
            style={{ height: 'calc(100vh - 200px)' }}
            rowDefinition={rowDefinition}
            selectedIds={selectedIds}
            onSelectionChanged={setSelectedIds}
            customIdProp={'composedId'}
            data={vehicles as any}
            groups={groups}
            actionBar={<ActionBar />}
            options={
              <>
                <Menu.Item onClick={() => setShowExcelImport(true)}>
                  Excel Import <Icon name={'upload'} />
                </Menu.Item>
                <Menu.Item onClick={() => downloadExcel(ExportDealerVehiclesVariant.Import)}>
                  Excel Export (Import Liste) <Icon name={'download'} />
                </Menu.Item>
                <Menu.Item onClick={() => downloadExcel(ExportDealerVehiclesVariant.Hyundai)}>
                  Excel Export (Hyundai) <Icon name={'download'} />
                </Menu.Item>
                <Menu.Item onClick={() => downloadExcel(ExportDealerVehiclesVariant.Mitsubishi)}>
                  Excel Export (Mitsubishi) <Icon name={'download'} />
                </Menu.Item>
                <GroupBySelect />
                <VehiclesHighlights />
              </>
            }
            orderedColumn={!groupBy && order}
            onSort={!groupBy && handleSort}
            onBulkEdit={handleBulkEdit}
            paginationNavigation={{
              content: {
                from: pageInfo?.pageStartIndex,
                to: pageInfo?.pageEndIndex,
                total: pageInfo?.totalCount,
              },
              hasNextPage: pageInfo?.hasNextPage,
              hasPreviousPage: pageInfo?.hasPreviousPage,
              fetchNextPage: () => nextPage(),
              fetchPreviousPage: () => prevPage(),
            }}
          />
        </ListManipulation>
      )}
      {showExcelImport && (
        <>
          <L.Horizontal>
            <div className={'flex-1'} />
            <Button startIcon={<Icon name={'before'} />} variant={'outlined'} onClick={() => setShowExcelImport(false)}>
              Liste anzeigen
            </Button>
          </L.Horizontal>
          <VehicleExcelImport />
        </>
      )}
    </L.Vertical>
  )
}

export const composeVehicleId = (item: { id: string; business_case_number: number }) =>
  `${item.id}#0#${item.business_case_number}`
export const splitVehicleId = (composedId: string) =>
  composedId.split('#').map((part, idx) => (idx !== 0 ? Number(part) : part))

const GroupBySelect = () => {
  const { groupBy, setGroupBy } = useContext(ListConfigContext)
  return (
    <Select
      label="Gruppieren nach"
      style={{ maxWidth: '50%' }}
      className="m-2"
      value={groupBy}
      name="Group by"
      onChange={(evt) => setGroupBy(evt.target.value)}
    >
      <Select.Option key={`group_by-null`} value={undefined}>
        Keine Gruppierung
      </Select.Option>
      <Select.Option key={`group_by-model_group_name`} value={'modelGroupName'}>
        Nach Modell Gruppe
      </Select.Option>
      <Select.Option key={`group_by-state`} value={'state'}>
        Nach Status
      </Select.Option>
      <Select.Option key={`group_by-order_state`} value={'order_state'}>
        Nach Transport Status
      </Select.Option>
      <Select.Option key={`group_by-storage_location_id`} value={'storage_location_id'}>
        Nach Lagerort
      </Select.Option>
      <Select.Option key={`group_by-delivery_location_id`} value={'delivery_location_id'}>
        Nach Lieferort
      </Select.Option>
      <Select.Option key={`group_by-dms_assigned_location_id`} value={'dms_assigned_location_id'}>
        Nach betreuenden Betriebsstätte
      </Select.Option>
      <Select.Option key={`group_by-seller_location_id`} value={'seller_location_id'}>
        Nach verkaufenden Betriebsstätte
      </Select.Option>
      <Select.Option key={`group_by-registration_state`} value={'registration_state'}>
        Nach Zulassungs Status
      </Select.Option>
      <Select.Option key={`group_by-type`} value={'type'}>
        Nach Typ
      </Select.Option>
      <Select.Option key={`group_by-sold_by`} value={'sold_by'}>
        Nach Verkäufer
      </Select.Option>
      <Select.Option key={`group_by-handover_by`} value={'handover_by'}>
        Nach Ausgeliefert von
      </Select.Option>
      <Select.Option key={`group_by-customer_type`} value={'customer_type'}>
        Nach Kundentyp
      </Select.Option>
      <Select.Option key={`group_by-reserved_by`} value={'reserved_by'}>
        Nach Reserviert von
      </Select.Option>
      <Select.Option key={`group_by-reserved_for_customer_type`} value={'reserved_for_customer_type'}>
        Nach Reserviert für (Typ)
      </Select.Option>
    </Select>
  )
}
