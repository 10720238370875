import React, { useCallback } from 'react'
import { useConfirm } from '../components/Confirm'
import { useRecoverVehiclesHistoriesMutation } from '../gql'

export const useResyncVehicleHistory = (vehicleIds: string[]) => {
  const [ConfirmDialog, confirm] = useConfirm()
  const [recover, recoverResponse] = useRecoverVehiclesHistoriesMutation()

  const handleResync = useCallback(async () => {
    const { hide } = await confirm()
    await recover({ variables: { vehicleIds } })
    hide()
  }, [confirm, recover, vehicleIds])

  const Dialog = useCallback(
    () => (
      <ConfirmDialog loading={recoverResponse.loading} confirmButtonText={'Ja'}>
        {`Mit dieser Aktion ${
          vehicleIds.length > 1 ? 'werden Historien der ausgewählten Fahrzeugen' : 'wird die Historie des Fahrzeugs'
        } gelöscht und neu synchronisiert.${vehicleIds.length >= 10 ? ' Der Vorgang könnte einige Minuten dauern.' : ''} Willst du fortfahren?`}
      </ConfirmDialog>
    ),
    [ConfirmDialog, recoverResponse.loading, vehicleIds.length]
  )

  return [Dialog, handleResync] as const
}
