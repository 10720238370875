import { Spinner } from 'components'
import { DetailWindow } from 'components/DetailWindow'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { Excel_Import_Assignments, useExcelImportAssignmentsLazyQuery } from 'gql'
import React, { useEffect, useMemo } from 'react'
import {ExcelImportType} from '../index'
import { useTranslation } from 'react-i18next'
import { Yalc2, Yalc2RowDefinition } from '../../../components/Yalc2'

interface ExcelImportTemplateDetailProps {
  templateId: string
  title: string
  onClose: () => void
  i18nPrefix: string
  variant: ExcelImportType
}

export const ExcelImportTemplateDetail = (props: ExcelImportTemplateDetailProps) => {
  const [loadAssignments, loadAssignmentsResult] = useExcelImportAssignmentsLazyQuery()

  const vehicle_variant = useMemo(() => props.variant === 'vehicle', [props.variant])

  useEffect(() => {
    if (!!props.templateId) {
      loadAssignments({ variables: { templateId: props.templateId, vehicle_variant } })
    }
  }, [loadAssignments, props.templateId, vehicle_variant])
  if (loadAssignmentsResult.loading) {
    return (
      <DetailWindow width={350} title={props.title} styles={{ container: { padding: 0 } }} onClose={props.onClose}>
        <Spinner centered />
      </DetailWindow>
    )
  }
  return (
    <DetailWindow width={350} title={props.title} styles={{ container: { padding: 0 } }} onClose={props.onClose}>
      <AssignmentsList
        i18nPrefix={props.i18nPrefix}
        data={loadAssignmentsResult?.data?.excel_import_assignments ?? []}
      />
    </DetailWindow>
  )
}

export const AssignmentsList = (props: {
  data: Excel_Import_Assignments[]
  i18nPrefix: string
  className?: string
}) => {
  const yalcRowDefinition = useRowDefinition(props.i18nPrefix)
  return (
    <Yalc2<Excel_Import_Assignments>
      className={props.className}
      data={props.data ?? []}
      rowDefinition={yalcRowDefinition}
    />
  )
}

const useRowDefinition = (i18nPrefix: string): Yalc2RowDefinition<Excel_Import_Assignments> => {
  const { t } = useTranslation()
  return {
    columns: [
      {
        title: 'Excel Überschrift',
        width: 170,
        cell: function HeaderCell(props) {
          return <Yalc2Cell.MaxWidthCell>{props.item.header_cell}</Yalc2Cell.MaxWidthCell>
        },
      },
      {
        title: 'Feld',
        width: 170,
        cell: function FieldCell(props) {
          return <Yalc2Cell.MaxWidthCell>{t(`${i18nPrefix}.${props.item.key}`)}</Yalc2Cell.MaxWidthCell>
        },
      },
    ],
  }
}
