import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ListConfigContext, ListConfigContextProps } from '../../index'
import { useVehiclesMetafieldsAllBrandsQuery } from '../../../../gql'
import { TicketItem, useRowDefinition } from './useRowDefinition'
import * as L from 'layouts'
import { Yalc2 } from '../../../../components/Yalc2'
import { TicketsListType } from './Config'
import { composeVehicleId } from '../../Vehicles/List'
import { TicketsGroupBySelect } from '../../../ListManipulation/Tickets/GroupBy/Options'
import { useData } from '../hooks/useData'
import { ActionBar } from './ActionBar'
import { ListManipulation } from '../../../ListManipulation/unit'
import { TicketsHighlights } from '../../../ListManipulation/Tickets/Highlights'
import { VehicleDetailContext } from '../../../VehicleForm'
import { VehicleTasksOptionsDropdown } from '../../../VehicleOptions'
import { DetailViewEnum, useDetailView } from '../../../../components/DetailWindow/useDetailView'

export const TicketsList = () => {
  const { columns, type, groupBy, order, setOrder } = useContext<ListConfigContextProps<TicketItem>>(ListConfigContext)
  const { data: vehicle } = useContext(VehicleDetailContext)

  const { tickets, groups } = useData()

  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const selectedVehicleId = useMemo(() => {
    const vehicle =
      selectedIds.length === 1 ? tickets.find((ticket) => ticket.id === selectedIds[0])?.vehicle : undefined
    if (!vehicle) {
      return undefined
    }
    return composeVehicleId(vehicle)
  }, [selectedIds, tickets])

  const metafieldsQuery = useVehiclesMetafieldsAllBrandsQuery()
  const metafields = metafieldsQuery.data?.brand_vehicle_metafield
  const handleSort = (col: keyof TicketItem, direction: 'asc' | 'desc') => setOrder({ col, direction })

  const rowDefinition = useRowDefinition(columns, { metafields })

  const { openNestedDetailView } = useDetailView()
  useEffect(() => {
    if (selectedIds.length === 1 && type === TicketsListType.VEHICLE) {
      openNestedDetailView(DetailViewEnum.TicketDetail, { id: selectedIds[0], onClose: () => setSelectedIds([]) })
    }
    if (selectedVehicleId && type === TicketsListType.ASSIGNED_ME) {
      openNestedDetailView(DetailViewEnum.VehicleDetail, { id: selectedVehicleId, onClose: () => setSelectedIds([]) })
    }
  }, [openNestedDetailView, selectedIds, selectedVehicleId, type])

  return (
    <L.Vertical spacing={4}>
      <ListManipulation>
        <Yalc2<TicketItem>
          classes={{ item: 'group' }}
          data={tickets}
          rowDefinition={rowDefinition}
          selectedIds={selectedIds}
          onSelectionChanged={setSelectedIds}
          orderedColumn={!groupBy && order}
          onSort={!groupBy && handleSort}
          options={
            <>
              {vehicle && (
                <VehicleTasksOptionsDropdown
                  vehicle={vehicle}
                />
              )}
              <hr className={'text-divider mb-2'} />
              <TicketsGroupBySelect />
              <TicketsHighlights />
            </>
          }
          groups={groups}
          actionBar={<ActionBar />}
        />
      </ListManipulation>
    </L.Vertical>
  )
}
