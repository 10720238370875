import { Tabs } from 'components'
import React, { CSSProperties } from 'react'
import { DateFns } from 'utils'

interface MonthSelectPaneProps {
  monthIndex: number
  onChange: (monthIndex: number) => void
  disableIndex?: (index: number) => boolean
  style?: CSSProperties
  className?: string
  ref?: React.Ref<HTMLDivElement>
}

const months = DateFns.monthShortNames
export const MonthSelectPane: React.FC<MonthSelectPaneProps> = React.forwardRef<HTMLDivElement, MonthSelectPaneProps>(
  (props, ref) => {
    return (
      <Tabs onChange={props.onChange} activeIndex={props.monthIndex}>
        <Tabs.TabList variant="scrollable" ref={ref} style={props.style} className={props.className}>
          {months.map((month, idx) => (
            <Tabs.Tab label={month} key={month} disabled={props.disableIndex ? props.disableIndex(idx) : false} />
          ))}
        </Tabs.TabList>
      </Tabs>
    )
  }
)
