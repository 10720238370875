import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import deLocale from 'date-fns/locale/de'
import MuiDateTimePicker, { DateTimePickerProps as MuiDateTimePickerProps } from '@mui/lab/DateTimePicker'
import React from 'react'
import { TextField } from '@mui/material'

interface DateTimePickerProps {
  value: MuiDateTimePickerProps<Date>['value']
  onChange: MuiDateTimePickerProps<Date>['onChange']
  label?: string
  disabled?: boolean
  minDateTime?: Date
  maxDateTime?: Date
  required?: boolean
}

export const DateTimePicker = (props: DateTimePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <MuiDateTimePicker
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}
        inputFormat="dd.MM.yyyy HH:mm"
        mask="__.__.____ __:__"
        minDateTime={props.minDateTime}
        maxDateTime={props.maxDateTime}
        renderInput={(p) => (
          <TextField
            {...p}
            disabled={props.disabled}
            label={props.label}
            required={props.required}
            size="small"
            fullWidth
            sx={{
              '& input:disabled ': {
                color: 'text.primary',
                WebkitTextFillColor: 'unset',
              },
              '& input:disabled ~ fieldset': {
                border: 'none',
              },
              '& input:disabled ~ div button': {
                display: 'none',
              },
              '.error & input ~ fieldset': {
                borderColor: 'error.main',
              },
            }}
          />
        )}
        label={props.label}
      ></MuiDateTimePicker>
    </LocalizationProvider>
  )
}
