import { ButtonGroup } from '@mui/material'
import { Button, ButtonProps } from 'components/Button'
import { Icon } from 'components/Icon'
import { Menu } from 'components/Menu'
import React, { CSSProperties, useRef, useState } from 'react'

interface SplitButtonProps {
  variant?: 'contained' | 'outlined'
  'aria-label'?: string
  onClick: () => void
  options: string[]
  endIcon?: ButtonProps['endIcon']
  className?: string
  style?: CSSProperties
  disabled?: boolean
  disabledOptions?: boolean[]
  selectedIndex: number
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
  loading?: boolean
}

export const SplitButton = (props: SplitButtonProps) => {
  const ref = useRef<HTMLDivElement>()
  const [open, setOpen] = useState(false)
  return (
    <ButtonGroup
      className={props.className}
      style={props.style}
      ref={ref}
      variant={props.variant}
      aria-label={props['aria-label']}
      disabled={props.disabled}
    >
      <Button loading={props.loading} endIcon={props.endIcon} onClick={props.onClick} variant={props.variant} disabled={props.disabled || props.disabledOptions?.[props.selectedIndex]}>
        {props.options[props.selectedIndex]}
      </Button>
      <Button
        onClick={() => setOpen((prev) => !prev)}
        variant={props.variant}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-haspopup="menu"
        className="p-0"
      >
        <Menu>
          <Menu.IconButton aria-label="split button icon" color="inherit" className="p-0">
            <Icon name="dropdown" />
          </Menu.IconButton>
          <Menu.ItemList onClose={() => setOpen(false)}>
            {props.options.map((option, index) => (
              <Menu.Item
                key={`send-option-${option}`}
                onClick={() => {
                  props.setSelectedIndex(index)
                  setOpen(false)
                }}
              >
                {option}
              </Menu.Item>
            ))}
          </Menu.ItemList>
        </Menu>
      </Button>
    </ButtonGroup>
  )
}
