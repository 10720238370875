import { DataBlock } from 'components/DataBlock'
import { DataValue } from 'components/DataValue'
import { DetailWindow } from 'components/DetailWindow'
import { useFormatter } from 'hooks'
import * as L from 'layouts'
import React from 'react'
import { LogEntryBlock } from './LogEntryBlock'

interface AdminMonitoringLogDetailProps {
  onClose: () => void
  log: {
    timestamp: string
    logObject: any
  }
}

export const AdminMonitoringLogDetail = (props: AdminMonitoringLogDetailProps) => {
  const { formatDetailedDateTimeString } = useFormatter()
  return (
    <DetailWindow onClose={props.onClose} width={620} title="Log Eintrag">
      <L.Vertical className="p-4" spacing={5}>
        <DataBlock title="Metadaten">
          <DataValue label="Zeitstempel">{formatDetailedDateTimeString(props.log.timestamp)}</DataValue>
        </DataBlock>
        <LogEntryBlock logObject={props.log.logObject} title="Details" />
      </L.Vertical>
    </DetailWindow>
  )
}
