import React, {ChangeEventHandler, CSSProperties, FocusEventHandler} from 'react'
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'

interface TextFieldProps {
  label?: string
  type?: MuiTextFieldProps['type']
  value?: string | number
  autoFocus?: boolean
  onChange?: MuiTextFieldProps['onChange']
  onBlur?: MuiTextFieldProps['onBlur']
  onFocus?: MuiTextFieldProps['onFocus']
  onKeyDown?: MuiTextFieldProps['onKeyDown']
  placeholder?: string
  name?: string
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  error?: string
  className?: string
  style?: CSSProperties
  multiline?: boolean
  variant?: MuiTextFieldProps['variant']
  margin?: boolean
  maxRows?: number
  minRows?: number
  min?: number
  max?: number
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ error, variant = 'standard', margin, min, max, ...props }, ref) => {

    const handleBlur: FocusEventHandler<HTMLInputElement> = (evt) => {
      const { value } = evt.currentTarget;
      // trim whitespace if exists
      if (value.length && value.match(/(^\s)|(\s$)/)) {
        evt.currentTarget.value = value.trim();
        // trigger onChange to notify about the changeEvent
        props.onChange?.call(null, evt);
      }
      props.onBlur?.call(null, evt);
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
      const { value } = evt.currentTarget;
      // prevent to start with white space
      evt.currentTarget.value = value.trimStart();
      props.onChange?.call(null, evt);
    };

    return (
      <MuiTextField
        {...props}
        inputRef={ref}
        InputProps={{ inputProps: { min, max } }}
        helperText={error}
        error={!!error}
        disabled={props.disabled || props.readOnly}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        margin={margin ? 'dense' : 'none'}
        fullWidth
        sx={{
          '& input:disabled ': {
            color: 'text.primary',
            WebkitTextFillColor: 'unset',
          },
          '& input:disabled ~ fieldset': {
            border: 'none',
          },
          '.error & input ~ fieldset': {
            borderColor: 'error.main',
          },
        }}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    )
  }
)
TextField.displayName = 'TextField'

TextField.defaultProps = {
  type: 'text',
}
