import React, { useCallback, useMemo, useState } from 'react'
import { useUserSelectDataQuery } from '../../../gql'
import { Typo } from '../../../components'
import { LMChipProps, LMOptionsProps } from '../Vehicles/Filter/Chips'
import { HighlightsColorOptions } from '../ColorOptions'
import { ListManipulation, useListManipilationContext } from '../unit'
import { useEzAuth } from '@lib/ezauth'
import { EntityType, General_Bool_Exp } from '../useFilterOperations'

export const UserOptions = <T extends EntityType>(
  props: LMOptionsProps<T> & { expressions?: General_Bool_Exp<T>[] }
) => {
  const { field, setField, parentExpression, leadingExpression } = useListManipilationContext<T>()
  const {
    setStandalone,
    getStandalone,
    getChildren,
    deleteChild,
    setChild,
    setAlternativeExpression,
    deleteStandalone,
  } = props.operations

  const [state] = useEzAuth()
  const me = useMemo(() => state.user, [state.user])

  const usersQuery = useUserSelectDataQuery()
  const users = useMemo(() => usersQuery.data?.users?.filter((user) => user.id !== me.id) ?? [], [
    me.id,
    usersQuery.data?.users,
  ])
  const [searchQuery, setSearchQuery] = useState('')
  const { fieldLabel, nullOptionValue, meLabel } = useMemo(() => {
    switch (field) {
      case 'sold_by_id':
        return { fieldLabel: 'Verkäufer', nullOptionValue: 'Kein Verkäufer', meLabel: 'Ich' }
      case 'reserved_by_id':
        return { fieldLabel: 'Reserviert von', nullOptionValue: 'Reserviert von Keinem', meLabel: 'Mir' }
      case 'assignee_id':
        return { fieldLabel: 'Zugewiesen zu', nullOptionValue: 'Zugewiesen zu Keinem', meLabel: 'Mir' }
      case 'handover_by_id':
        return { fieldLabel: 'Ausgeliefert von', nullOptionValue: 'Ausgeliefert von Keinem', meLabel: 'Mir' }
      default:
        return { fieldLabel: '', nullOptionValue: '', meLabel: '' }
    }
  }, [field])
  const expressions = useMemo(
    () =>
      props.expressions
        ? props.expressions
        : !parentExpression
        ? getStandalone(field, props.highlightIndex)
        : getChildren(field, parentExpression, props.highlightIndex),
    [field, getChildren, getStandalone, parentExpression, props.expressions, props.highlightIndex]
  )
  const checked = useCallback((userId: string) => expressions?.some((u) => u?.[field]?._eq === userId), [
    expressions,
    field,
  ])
  const checkedNull = useMemo(() => expressions?.some((exp) => exp?.[field]?._is_null), [expressions, field])
  const handleChange = useCallback(
    (_eq: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) {
        if (!parentExpression) {
          deleteStandalone({ [field]: { _eq } })
        } else {
          deleteChild({ [field]: { _eq } }, parentExpression)
        }
      }
      if (checked) {
        if (parentExpression) {
          setChild({ [field]: { _eq } }, parentExpression)
        } else if (leadingExpression) {
          setAlternativeExpression({ [field]: { _eq } }, leadingExpression)
        } else {
          setStandalone({ [field]: { _eq } })
        }
      }
    },
    [
      deleteChild,
      deleteStandalone,
      field,
      leadingExpression,
      parentExpression,
      setAlternativeExpression,
      setChild,
      setStandalone,
    ]
  )

  const handleChangeNull = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const expression = { [field]: { _is_null: true } }
      if (!checked) {
        if (!parentExpression) {
          deleteStandalone(expression)
        } else {
          deleteChild(expression, parentExpression)
        }
      }
      if (checked) {
        if (parentExpression) {
          setChild(expression, parentExpression)
        } else if (leadingExpression) {
          setAlternativeExpression(expression, leadingExpression)
        } else {
          setStandalone(expression)
        }
      }
    },
    [
      deleteChild,
      deleteStandalone,
      field,
      leadingExpression,
      parentExpression,
      setAlternativeExpression,
      setChild,
      setStandalone,
    ]
  )

  return (
    <ListManipulation.UnitOptions label={fieldLabel}>
      <ListManipulation.InputOption
        value={searchQuery}
        placeholder={fieldLabel}
        onChange={(evt) => setSearchQuery(evt.currentTarget.value)}
      />
      <hr className={'text-divider'} />
      <ListManipulation.CheckableOption checked={checked(me?.id)} onChange={handleChange(me?.id)}>
        <Typo variant={'body1'} className={'pr-4'}>
          {meLabel}
        </Typo>
      </ListManipulation.CheckableOption>
      {users
        .filter((u) =>
          searchQuery
            ? u.full_name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
              u.email?.toLowerCase()?.includes(searchQuery.toLowerCase())
            : true
        )
        .map((u) => (
          <ListManipulation.CheckableOption checked={checked(u.id)} onChange={handleChange(u.id)}>
            <Typo variant={'body1'} className={'pr-4'}>
              {u.full_name ?? u.email}
            </Typo>
          </ListManipulation.CheckableOption>
        ))}
      <hr className={'text-divider'} />
      <ListManipulation.CheckableOption checked={checkedNull} onChange={handleChangeNull}>
        <Typo variant={'body1'} className={'pr-4'}>
          {nullOptionValue}
        </Typo>
      </ListManipulation.CheckableOption>
      {props.type === 'highlight' && (
        <>
          <hr className={'text-divider'} />
          <ListManipulation.ItemOption disabled={!expressions.length} onClick={() => setField('color')}>
            Farbe auswählen
          </ListManipulation.ItemOption>
        </>
      )}
    </ListManipulation.UnitOptions>
  )
}

export const UserChip = <T extends EntityType>(
  props: LMChipProps<T> & {
    field: 'sold_by_id' | 'reserved_by_id' | 'assignee_id' | 'handover_by_id'
    expressions?: General_Bool_Exp<T>[]
  }
) => {
  const { field: userField } = props
  const { getStandalone, deleteAll, deleteExpressions } = props.operations
  const { setField, field } = useListManipilationContext<T>()

  const [state] = useEzAuth()
  const me = useMemo(() => state.user, [state.user])

  const usersQuery = useUserSelectDataQuery()
  const users = useMemo(() => usersQuery.data?.users?.filter((user) => user.id !== me.id) ?? [], [
    me.id,
    usersQuery.data?.users,
  ])
  const { fieldLabel, nullOptionValue, meLabel } = useMemo(() => {
    switch (userField) {
      case 'sold_by_id':
        return { fieldLabel: 'Verkäufer', nullOptionValue: 'Kein Verkäufer', meLabel: 'Ich' }
      case 'reserved_by_id':
        return { fieldLabel: 'Reserviert von', nullOptionValue: 'Reserviert von Keinem', meLabel: 'Mir' }
      case 'assignee_id':
        return { fieldLabel: 'Zugewiesen zu', nullOptionValue: 'Zugewiesen zu Keinem', meLabel: 'Mir' }
      case 'handover_by_id':
        return { fieldLabel: 'Ausgeliefert von', nullOptionValue: 'Ausgeliefert von Keinem', meLabel: 'Mir' }
      default:
        return { fieldLabel: '', nullOptionValue: '', meLabel: '' }
    }
  }, [userField])
  const expressions = useMemo(() => props.expressions ?? getStandalone(userField, props.highlightIndex), [
    props.expressions,
    props.highlightIndex,
    userField,
    getStandalone,
  ])
  const label = useMemo(
    () =>
      expressions.length === 1
        ? `${fieldLabel}: ${
            expressions[0][userField]._is_null
              ? nullOptionValue
              : expressions[0][userField]?._eq === me.id
              ? meLabel
              : users?.find((u) => u.id === expressions[0][userField]?._eq)?.full_name ??
                users?.find((u) => u.id === expressions[0][userField]?._eq)?.email
          }`
        : `${expressions.length} ${fieldLabel} ausgewählt`,
    [expressions, fieldLabel, userField, nullOptionValue, me.id, meLabel, users]
  )

  const handleDelete = useCallback(() => {
    props.expressions ? deleteExpressions(props.expressions) : deleteAll(userField, props.highlightIndex)
  }, [deleteAll, deleteExpressions, props.expressions, props.highlightIndex, userField])

  if (!Boolean(expressions.length)) return null
  return (
    <ListManipulation.Group
      variant={props.type !== 'highlight' || props.color ? 'filled' : 'outlined'}
      color={props.type === 'highlight' ? props.color : 'default'}
      onDelete={handleDelete}
    >
      <ListManipulation.GroupUnit
        onClick={() => {
          props.onClick && props.onClick()
          setField(userField)
        }}
        label={label}
      >
        {field !== 'color' && (
          <UserOptions
            type={props.type === 'highlight' ? 'highlight' : 'filter'}
            operations={props.operations}
            highlightIndex={props.highlightIndex}
          />
        )}
        {field === 'color' && <HighlightsColorOptions />}
      </ListManipulation.GroupUnit>
    </ListManipulation.Group>
  )
}
