import React, { useEffect, useRef, useState } from 'react'
import * as L from 'layouts'
import { useDebounce } from '../../../../hooks'
import { TextField } from 'components'
import { useSearch } from '../hooks/useSearch'
import { VehiclesFilter } from '../../../ListManipulation/Vehicles/Filter'

interface ActionBarProps {}

export const ActionBar: React.FC<ActionBarProps> = () => {
  const inputRef = useRef<HTMLInputElement>()
  window.addEventListener('keydown', function (e) {
    if (e.metaKey && e.code === 'KeyF' && inputRef.current) {
      e.preventDefault()
      inputRef.current.focus()
    }
  })
  const { set: setSearchQuery } = useSearch()

  const [queryText, setQueryText] = useState<string>()
  const debouncedQueryText = useDebounce(queryText)
  useEffect(() => setSearchQuery(debouncedQueryText), [debouncedQueryText, setSearchQuery])
  return (
    <L.Horizontal className={'w-full'} style={{ justifyContent: 'space-between' }}>
      <VehiclesFilter />
      <TextField
        ref={inputRef}
        value={queryText}
        onChange={(evt) => setQueryText(evt.currentTarget.value)}
        style={{ minWidth: 360, maxWidth: 512 }}
        placeholder="FIN, Bestellnummer"
      />
    </L.Horizontal>
  )
}
