export const blue = {
  '50': '#DCEEFB',
  '100': '#B6E0FE',
  '200': '#84C5F4',
  '300': '#62B0E8',
  '400': '#4098D7',
  '500': '#2680C2',
  '600': '#186FAF',
  '700': '#0F609B',
  '800': '#0A558C',
  '900': '#003E6B',

  A100: '#0000ff',
  A200: '#0000ff',
  A400: '#0000ff',
  A700: '#0000ff',
}

export const grey = {
  '50': '#F0F4F8',
  '100': '#D9E2EC',
  '200': '#BCCCDC',
  '300': '#9FB3C8',
  '400': '#829AB1',
  '500': '#627D98',
  '600': '#486581',
  '700': '#334E68',
  '800': '#243B53',
  '900': '#102A43',

  A100: 'red',
  A200: 'red',
  A400: 'red',
  A700: 'red',
}

export const teal = {
  '50': '#F0FCF9',
  '100': '#C6F7E9',
  '200': '#8EEDD1',
  '300': '#5FE3C0',
  '400': '#2DCCA7',
  '500': '#17B897',
  '600': '#079A82',
  '700': '#048271',
  '800': '#016457',
  '900': '#004440',

  A100: 'teal',
  A200: 'teal',
  A400: 'teal',
  A700: 'teal',
}

export const red = {
  '50': '#FFEEEE',
  '100': '#FACDCD',
  '200': '#F29B9B',
  '300': '#E66A6A',
  '400': '#D64545',
  '500': '#BA2525',
  '600': '#A61B1B',
  '700': '#911111',
  '800': '#780A0A',
  '900': '#610404',
}

export const yellow = {
  '50': '#FFFAEB',
  '100': '#FCEFC7',
  '200': '#F8E3A3',
  '300': '#F9DA8B',
  '400': '#F7D070',
  '500': '#E9B949',
  '600': '#C99A2E',
  '700': '#A27C1A',
  '800': '#7C5E10',
  '900': '#513C06',
}
