import React, { useContext } from 'react'
import { ListConfigContext } from '../ListConfig'
import { Typo } from '../../components'
import {
  ListManipulation,
  ListManipulationUnitGroupProps,
  useListManipilationContext,
} from 'containers/ListManipulation/unit'

export const HighlightsColorOptions = () => {
  return (
    <ListManipulation.UnitOptions>
      <ColorOption label={'Blau'} color={'default'} className={'bg-primary-light text-white'} />
      <ColorOption label={'Red'} color={'danger'} className={'bg-error-light text-white'} />
      <ColorOption label={'Grün'} color={'success'} className={'bg-success-light text-white'} />
      <ColorOption label={'Gelb'} color={'warning'} className={'bg-warning-light'} />
      <ColorOption label={'Grau'} color={'grey'} className={'bg-grey-200'} />
      <ColorOption label={'Hellgrau'} color={'grey-light'} className={'bg-grey-100'} />
    </ListManipulation.UnitOptions>
  )
}

const ColorOption = (props: { className?: string; label: string; color: ListManipulationUnitGroupProps['color'] }) => {
  const { setHighlights, highlights } = useContext(ListConfigContext)
  const { highlightIndex } = useListManipilationContext()
  return (
    <ListManipulation.ItemOption
      className={props.className}
      onClick={() => {
        const value = Object.assign([], highlights)
        const item = value.splice(highlightIndex, 1)[0]
        value.splice(highlightIndex, 0, { ...item, color: props.color })
        setHighlights(value)
      }}
    >
      <Typo variant={'body1'}>{props.label}</Typo>
    </ListManipulation.ItemOption>
  )
}
