import React from 'react'
import { Avatar, Icon } from '../../components'

interface OwnerTypeAvatarInterface {
  type?: 'location' | 'customer' | 'supplier'
  size?: 'small' | 'default'
  className?: string
}

export const OwnerTypeAvatar = (props: OwnerTypeAvatarInterface) => {
  const bindings = useBindings(props.type)
  return <Avatar className={props.className} alt={`owner type: ${props.type}`} size={props.size} {...bindings} />
}

const useBindings = (type: OwnerTypeAvatarInterface['type']) => {
  switch (type) {
    case 'location':
      return { className: 'bg-success-light', children: <Icon name={'warehouse'} /> }
    case 'customer':
      return { className: 'bg-grey-200', children: <Icon name={'person'} /> }
    case 'supplier':
      return { className: 'bg-warning-light', children: <Icon name={'localShipping'} /> }
    default:
      return { className: 'bg-grey-100', children: '?' }
  }
}
