import React, { CSSProperties, useContext } from 'react'
import MuiButtonGroup from '@mui/material/ButtonGroup'
import { Button } from '../Button'
import clsx from 'clsx'
import styled from './ButtonGroup.module.css'
// import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup'
// import ToggleButton from '@mui/lab/ToggleButton'

interface ButtonGroupProps {
  value: string
  onChange: (value: string) => void
  'aria-label'?: string
  children: React.ReactNode
}

export const ButtonGroup = (props: ButtonGroupProps) => {
  return (
    <Ctx.Provider value={{ value: props.value, onChange: props.onChange }}>
      <MuiButtonGroup>{props.children}</MuiButtonGroup>
    </Ctx.Provider>
  )
}

interface ButtonGroupButtonProps {
  value: string
  'aria-label'?: string
  children?: React.ReactNode
  className?: string
  style?: CSSProperties
}

export const ButtonGroupButton = (props: ButtonGroupButtonProps) => {
  const { onChange, value: currentValue } = useContext(Ctx)
  const isActive = props.value === currentValue

  return (
    <Button
      className={clsx(styled.btnGroupBtn, isActive && styled.active, props.className, 'whitespace-nowrap')}
      style={props.style}
      onClick={(evt) => {
        onChange(props.value)
        evt.stopPropagation()
      }}
      color="primary"
    >
      {props.children}
    </Button>
  )
}

ButtonGroupButton.muiName = (Button as any).muiName

ButtonGroup.Button = ButtonGroupButton

interface ButtonGroupCtx {
  value: string
  onChange: (value: string) => void
}

const Ctx = React.createContext<ButtonGroupCtx>({
  value: '',
  onChange: () => {},
})
