import React, { ChangeEventHandler, KeyboardEventHandler, MouseEventHandler } from 'react'
import { Icon, IconButton, TextField } from '../../../components'

interface SalesModelCodeInputProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  value: string
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>
  disabled?: boolean
}

export const SalesModelCodeInput = (props: SalesModelCodeInputProps) => {
  return (
    <div className={'flex flex-row gap-2 items-center'}>
      <IconButton
        size={'small'}
        aria-label={'copy model code to sales model code'}
        tooltip={'Kopiere Marken Modell Code zum Sales Modell Code'}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <Icon name={'copy'}></Icon>
      </IconButton>
      <TextField onKeyDown={props.onKeyDown} value={props.value} onChange={props.onChange} disabled={props.disabled} />
    </div>
  )
}
