import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { Tooltip } from 'components'

interface MaxWidthTextProps {
  width?: string
  tooltip?: string
  className?: string
  style?: CSSProperties
  children: string | string[]
}

export const MaxWidthText: React.FC<MaxWidthTextProps> = (props) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const elementRef = useRef<HTMLDivElement>()

  const compareElementWidth = () => {
    const isElippsis = elementRef?.current?.scrollWidth > elementRef?.current?.clientWidth
    setShowTooltip(isElippsis)
  }

  useEffect(() => {
    compareElementWidth()
    // TODO: add resize, though dont attach resize event listener for every usage!!
    // window.addEventListener('resize', compareElementWidth)

    // return () => {
    //   window.removeEventListener('resize', compareElementWidth)
    // }
  }, [])

  const width = props.width ?? '100%'
  const tooltip = props.tooltip || (Array.isArray(props.children) ? props.children.join('') : props.children)

  const element = (
    <div
      ref={elementRef}
      style={{ width: width, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', ...props.style }}
      className={props.className}
    >
      {props.children}
    </div>
  )
  if (showTooltip) {
    // just render tooltop when required, also using disabled flag has worse performance
    return <Tooltip content={tooltip}>{element}</Tooltip>
  } else {
    return <>{element}</>
  }
}
