export enum TableElementType {
  Header = 'header',
  Column = 'column',
  Pagination = 'pagination',
  NoDataFallback = 'nodata-fallback',
}

export interface TableElement<P = {}> extends React.FC<P> {
  elementType: TableElementType
}
