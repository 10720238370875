import React, { useContext, useEffect, useMemo } from 'react'
import { Card } from 'components'
import * as L from 'layouts'
import {
  Import_Job_Status_Enum,
  useAddVehicleImportTemplateMutation,
  useAssignVehicleColumnToFieldMutation,
  useAssignVehicleImportTemplateToImportJobMutation,
  useCreateVehicleImportJobMutation,
  useDeleteVehicleImportTemplateMutation,
  useImportJobSubscription,
  useInheritFromVehicleImportTemplateMutation,
  useRemoveParentVehicleImportTemplateMutation,
  useResetVehicleExcelAssignmentsMutation,
  useUnassignVehicleColumnFromFieldMutation,
  Vehicle,
} from 'gql'
import { DVImportPreviewList, VehicleImportPreviewListItem } from '../DealerVehicleExcelImport/import-preview-list'
import { ExcelImport, ExcelImportContext, ExcelImportType, withExcelImportProvider } from 'containers/ExcelImport'

const ExcelImportUnwrapped = (props: { variant?: ExcelImportType; brandId?: string }) => {
  const { mappingKeys, createImportJob, ...bindings } = useExcelImportBindings(props.variant)

  const { importJob, setVariant, variant, dispatch } = useContext(ExcelImportContext)
  useEffect(() => setVariant(props.variant), [props.variant, setVariant])

  useEffect(() => {
    dispatch({ type: 'reset' })
  }, [dispatch])

  if (!variant) return null

  return (
    <L.Vertical spacing={4}>
      <L.Horizontal style={{ alignItems: 'start' }}>
        <Card className="m-4 p-4" style={{ width: '50%', minHeight: '400px' }}>
          <L.Vertical spacing={6}>
            <ExcelImport.ChooseFile
              entityNamePlural={bindings.entityNamePlural}
              mappingKeys={mappingKeys}
              createImportJob={createImportJob}
            />
            <ExcelImport.Assignments {...bindings} />
          </L.Vertical>
        </Card>
        <ExcelImport.Logs
          style={{ width: '50%', maxHeight: '70vh' }}
          entityNamePlural={bindings.entityNamePlural}
          mappingKeys={mappingKeys}
        />
      </L.Horizontal>
      <div>
        {importJob?.status === Import_Job_Status_Enum.PreviewDone && (
          <L.Vertical>{props.variant === 'vehicle' && <DVImportPreviewList />}</L.Vertical>
        )}
      </div>
    </L.Vertical>
  )
}

export const VehicleExcelImport = withExcelImportProvider(ExcelImportUnwrapped, 'vehicle')

const useExcelImportBindings = (variant: ExcelImportType) => {
  const { state, setImportJob } = useContext(ExcelImportContext)

  // Vehicle specific mutations
  const insertVehicleImportTemplate = useAddVehicleImportTemplateMutation()
  const deleteVehicleImportTemplate = useDeleteVehicleImportTemplateMutation()
  const inheritFromVehicleImportTemplate = useInheritFromVehicleImportTemplateMutation()
  const removeParentVehicleImportTemplate = useRemoveParentVehicleImportTemplateMutation()
  const assignVehicleColumnToField = useAssignVehicleColumnToFieldMutation()
  const unassignVehicleColumnFromField = useUnassignVehicleColumnFromFieldMutation()
  const createVehicleImportJob = useCreateVehicleImportJobMutation()
  const assignVehicleImportTemplateToImportJob = useAssignVehicleImportTemplateToImportJobMutation()
  const resetVehicleExcelAssignments = useResetVehicleExcelAssignmentsMutation()

  const importJobSubscription = useImportJobSubscription({
    variables: {
      id: state.importJobId,
      vehicle_variant: variant === 'vehicle',
    },
  })
  useEffect(() => setImportJob(importJobSubscription.data?.import_jobs_by_pk), [
    importJobSubscription.data?.import_jobs_by_pk,
    setImportJob,
  ])

  return useMemo(() => {
    switch (variant) {
      case 'vehicle':
        return {
          excelImportKey: 'vehicle_import_template_id',
          entityName: 'Fahrzeug',
          entityNamePlural: 'Fahrzeuge',
          i18nPrefix: 'DealerVehicle',
          dvKeys: [
            'type',
            'state',
            'order_state',
            'sold_at',
            'customer',
            'min_holding_period',
            'key_kabinet_number',
            'registration_owner',
            'registration_state',
            'delivery_date_str',
            'vin',
            'job_number',
            'specs',
            'spec_level',
            'handover_date',
            'handover_planned_date',
            'in_buy_contract_date',
          ] as (keyof VehicleImportPreviewListItem)[],
          mappingKeys: [
            { key: 'vin', value: 'FIN' },
            { key: 'job_number', value: 'Bestellnummer' },
          ] as {
            key: keyof Vehicle
            value: string
          }[],
          insertImportTemplate: insertVehicleImportTemplate,
          deleteImportTemplate: deleteVehicleImportTemplate,
          inheritFromImportTemplate: inheritFromVehicleImportTemplate,
          removeParentImportTemplate: removeParentVehicleImportTemplate,
          assignColumnToField: assignVehicleColumnToField,
          unassignColumnFromField: unassignVehicleColumnFromField,
          createImportJob: createVehicleImportJob,
          assignImportTemplateToImportJob: assignVehicleImportTemplateToImportJob,
          resetAssignments: resetVehicleExcelAssignments,
        }
    }
  }, [
    variant,
    insertVehicleImportTemplate,
    deleteVehicleImportTemplate,
    inheritFromVehicleImportTemplate,
    removeParentVehicleImportTemplate,
    assignVehicleColumnToField,
    unassignVehicleColumnFromField,
    createVehicleImportJob,
    assignVehicleImportTemplateToImportJob,
    resetVehicleExcelAssignments,
  ])
}
