import { ApolloLink, Observable } from '@apollo/client'
import { ConfigStore } from './config-store'

// max valid timeout for setTimeout
const MAX_TIMEOUT = Math.pow(2, 32) / 2 - 1

export class NetworkSimulatorLink extends ApolloLink {
  request(operation, forward) {
    if (this.shouldThrowError()) {
      return new Observable((observer) => {
        observer.next({
          errors: [{ message: 'Unspecified error from NetworkSimulatorLink.' }],
        })
        observer.complete()
      })
    }

    const observable = forward(operation)
    const delay = this.getDelay()

    return new Observable((observer) => {
      let subscription
      setTimeout(() => {
        subscription = observable.subscribe({
          next: (result) => {
            observer.next(result)
          },
          error: (error) => {
            observer.error(error)
          },
          complete: observer.complete.bind(observer),
        })
      }, Math.min(delay, MAX_TIMEOUT))

      return () => {
        if (subscription) {
          subscription.unsubscribe()
        }
      }
    })
  }

  private getDelay(): number {
    const randomized = ConfigStore.getRandomizeDelay()
    const delay = ConfigStore.getDelay()
    return randomized ? Math.random() * delay : delay
  }

  private shouldThrowError() {
    const errRate = ConfigStore.getErrorRate()
    return Math.random() < errRate
  }
}

export default NetworkSimulatorLink
