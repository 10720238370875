import React, { useContext } from 'react'
import { useHighlightOperations } from '../../useFilterOperations'
import { Ticket } from '../../../../gql'
import { useListManipilationContext } from '../../unit'
import { ListConfigContext, RowHighlight } from '../../../ListConfig'
import { TicketTeamChip } from '../Chips/TeamChip'
import { UserChip } from 'containers/ListManipulation/Chips/UserChip'
import { LMChipProps } from '../../Vehicles/Filter/Chips'
import { DateChip } from 'containers/ListManipulation/Chips/DateChip'
import { EnumChip } from '../../Chips/EnumChip'

export const TicketHighlightsChips = () => {
  const operations = useHighlightOperations<'Ticket'>()
  const { setHighlightIndex } = useListManipilationContext<'Ticket'>()
  const { highlights } = useContext(ListConfigContext)

  const enumFields = ['state', 'priority']
  const dateFields = ['created_at', 'updated_at', 'due_to']

  const bindings = (
    highlight: RowHighlight<Ticket>,
    highlightIndex: number
  ): LMChipProps<'Ticket'> & { field: any } => ({
    color: highlight.color,
    type: 'highlight',
    operations,
    onClick: () => setHighlightIndex(highlightIndex),
    highlightIndex,
    field: highlight.entryField,
  })

  return (
    <>
      {React.Children.toArray(
        highlights?.map((highlight, idx) => (
          <div>
            {highlight.entryField === 'department_id' && <TicketTeamChip {...bindings(highlight, idx)} />}
            {highlight.entryField === 'assignee_id' && <UserChip<'Ticket'> {...bindings(highlight, idx)} />}
            {enumFields.includes(highlight.entryField) && <EnumChip<'Ticket'> {...bindings(highlight, idx)} />}
            {dateFields.includes(highlight.entryField) && <DateChip<'Ticket'> {...bindings(highlight, idx)} />}
          </div>
        ))
      )}
    </>
  )
}
