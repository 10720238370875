import { ExportDealerVehiclesVariant, useVehiclesExportLazyQuery } from '../../../../gql'
import { useContext, useEffect } from 'react'
import { download } from '../../../../utils'
import { ListConfigContext } from '../../index'

export function useDownload() {
  const { filter } = useContext(ListConfigContext)
  const [getDlLink, dlResponse] = useVehiclesExportLazyQuery({ fetchPolicy: 'network-only' })
  const handleDl = async (variant: ExportDealerVehiclesVariant) => {
    getDlLink({ variables: { variant, where: JSON.parse(filter) } })
  }
  const dlLink = dlResponse.data?.exportVehicles?.download_url
  useEffect(() => {
    if (dlLink) {
      download(dlLink)
    }
  }, [dlLink])
  return handleDl
}
