import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import clsx from 'clsx'
import { AlertBox, Button, Typo } from 'components'
import { Collapsable } from 'components/Collapsable'
import { DataBlock } from 'components/DataBlock'
import { useFormatter } from 'hooks'
import { groupBy } from 'lodash'
import React, { useContext } from 'react'
import { VehicleDetailContext } from '..'
import { useEzAuthUserHasRoles, UserRole } from '@lib/ezauth'
import { useResyncVehicleHistory } from '../../../hooks/useResyncVehicleHistory'

type OwnerInformation = {
  id: string
  holder_location?: {
    id: string
    name: string
  }
  holder_supplier?: {
    id: string
    name: string
  }
  holder_customer?: {
    id: string
    name: string
  }
  owner_location?: {
    id: string
    name: string
  }
  owner_supplier?: {
    id: string
    name: string
  }
  owner_customer?: {
    id: string
    name: string
  }
  generated_by: {
    job_number?: string
    cancelled_date?: string
    invoice_date?: string
    invoice_number?: string
    cancelled_transaction_id?: string
    valid_transaction?: {
      invoice_number?: string
      invoice_date?: string
    }
  }[]
}

export const HistoryData: React.FC = () => {
  const { data } = useContext(VehicleDetailContext)
  const { formatDateTimeString } = useFormatter()
  const isAdmin = useEzAuthUserHasRoles(UserRole.ADMIN)

  const getHolderInformationType = (owner: OwnerInformation) => {
    if (!!owner?.holder_location) return 'Standort'
    if (!!owner?.holder_customer) return 'Kunde'
    if (!!owner?.holder_supplier) return 'Lieferant'
    return ''
  }

  const getOwnerInformationType = (owner: OwnerInformation) => {
    if (!!owner?.owner_location) return 'Standort'
    if (!!owner?.owner_customer) return 'Kunde'
    if (!!owner?.owner_supplier) return 'Lieferant'
    return ''
  }

  const getHolderInformationName = (owner: OwnerInformation) => {
    if (!!owner?.holder_location) return owner.holder_location.name
    if (!!owner?.holder_customer) return owner.holder_customer.name
    if (!!owner?.holder_supplier) return owner.holder_supplier.name
    return ''
  }

  const getOwnerInformationName = (owner: OwnerInformation) => {
    if (!!owner?.owner_location) return owner.owner_location.name
    if (!!owner?.owner_customer) return owner.owner_customer.name
    if (!!owner?.owner_supplier) return owner.owner_supplier.name
    return ''
  }
  const isCancelled = (owner: OwnerInformation) => !!owner.generated_by?.[0]?.cancelled_date

  const [ResyncDialog, handleResync] = useResyncVehicleHistory([data?.id])

  if (!data) return null
  const businessCases = groupBy(data.history, 'business_case_number')

  return (
    <div className="p-6 pb-8 flex flex-col flex-wrap gap-y-5">
      <DataBlock title="Fahrzeughistorie">
        <Timeline position="right" className="float-left w-3/4">
          <TimelineItem key="header-item" position="right" sx={{ ':before': { content: 'none' }, minHeight: '70px' }}>
            <TimelineSeparator>
              <TimelineDot className="invisible" />
              <TimelineConnector className="invisible" />
            </TimelineSeparator>
            <TimelineContent className="flex flex-row justify-between">
              <Typo variant="body1" className="font-bold">
                Fahrzeughalter
              </Typo>
              <Typo variant="body1" className="font-bold">
                {'Besitzer (falls vom Halter unterschiedlich)'}
              </Typo>
            </TimelineContent>
          </TimelineItem>
          {Object.values(businessCases)?.map((bc, idx) => (
            <div key={`bc-${idx}`} className="p-4 border border-grey-200 border-dotted relative">
              <Typo variant="caption" className="absolute right-2 top-2">{`Geschäftsfall Nr. ${idx + 1}${
                data.business_case_number === idx + 1 ? ' (Aktuelle Daten)' : ''
              }`}</Typo>
              {bc?.map((owner, idx) => (
                <TimelineItem
                  key={`owner-${owner.id}`}
                  position="right"
                  sx={{ ':before': { content: 'none' }, minHeight: '80px' }}
                >
                  <TimelineSeparator>
                    <TimelineDot variant={isCancelled(owner) ? 'outlined' : 'filled'} />
                    {idx !== bc.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent className="flex flex-row justify-between">
                    <div className="block">
                      {owner.generated_by?.[0]?.invoice_date && (
                        <Typo variant="caption">{`Rechnungsdatum: ${owner.generated_by?.[0]?.invoice_date}`}</Typo>
                      )}
                      <Typo variant="body1" className={clsx(isCancelled(owner) && 'line-through')}>
                        {getHolderInformationName(owner) || getOwnerInformationName(owner)}
                      </Typo>
                      <Typo variant="body2" className={clsx(isCancelled(owner) && 'line-through')}>
                        {getHolderInformationType(owner) || getOwnerInformationType(owner)}
                      </Typo>
                    </div>
                    {!!getHolderInformationName(owner) &&
                      getHolderInformationName(owner) !== getOwnerInformationName(owner) && (
                        <div className="block">
                          <Typo variant="body1" className={clsx(isCancelled(owner) && 'line-through')}>
                            {getOwnerInformationName(owner)}
                          </Typo>
                          <Typo variant="body2" className={clsx(isCancelled(owner) && 'line-through')}>
                            {getOwnerInformationType(owner)}
                          </Typo>
                        </div>
                      )}
                  </TimelineContent>
                </TimelineItem>
              ))}
            </div>
          ))}

          {(!data?.history || data?.history?.length === 0) && (
            <div className="text-center">Keine frühere Besitzer eingetragen.</div>
          )}
        </Timeline>
      </DataBlock>
      {isAdmin && (
        <Button className={'w-max'} variant={'contained'} color={'error'} onClick={handleResync}>
          Historie löschen und erneut synchronisieren
        </Button>
      )}
      <ResyncDialog />
      <DataBlock title="Bemerkungen">
        {data.sync_errors
          .filter((err) => !!err.transaction_id && err.resolved)
          ?.map((error) => (
            <AlertBox key={error.id} type={'info'}>
              {error?.cause ? (
                <Collapsable title={error.message}>
                  {
                    <div className="space-y-2">
                      <Typo variant="body2">
                        <span className="font-bold">Aufgelöst am: </span>
                        {formatDateTimeString(error.resolved)}
                      </Typo>
                      <Typo variant="body2">
                        <span className="font-bold">Details: </span>
                        {error.cause}
                      </Typo>
                      <Typo variant="body2">
                        <span className="font-bold">Erschienen zum ersten mal: </span>
                        {formatDateTimeString(error.first_occured)}
                      </Typo>
                    </div>
                  }
                </Collapsable>
              ) : (
                error.message
              )}
            </AlertBox>
          ))}
      </DataBlock>
    </div>
  )
}
