const randomValue = (min: number, max: number) => {
  return Math.ceil(Math.random() * (max - min)) + min
}

export const randomList = <T extends {}>(min: number, max: number, creator?: () => T): T[] => {
  const length = randomValue(min, max)
  return new Array(length).fill(1).map(creator || (() => ({} as T)))
}

export const randomArrayItem = <T>(arr: T[]): T => {
  if (!arr.length) {
    return null
  }
  const idx = Math.round(Math.random() * (arr.length - 1))
  return arr[idx]
}

export const randomArrayItems = <T>(arr: T[], min: number, max: number): T[] => {
  const result = []
  const size = randomValue(Math.min(min, arr.length), Math.min(max, arr.length))
  while (result.length < size) {
    const item = randomArrayItem(arr)
    if (result.indexOf(item) < 0) {
      result.push(item)
    }
  }
  return result
}
