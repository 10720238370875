import React, { useCallback, useMemo } from 'react'
import { ListManipulation, useListManipilationContext } from '../../unit'
import { UserTeamsSubscriptionResult, useUserTeamsSubscription } from '../../../../gql'
import { useEzAuth } from '@lib/ezauth'
import { Typo } from 'components/Typo'
import { HighlightsColorOptions } from '../../ColorOptions'
import { useTranslation } from 'react-i18next'
import { uniqBy } from 'lodash'
import { TFunction } from 'i18next'
import { LMChipProps, LMOptionsProps } from '../../Vehicles/Filter/Chips'

type Team = ArrayElement<UserTeamsSubscriptionResult['data']['team_assignments']>
export const TicketTeamOptions = (props: LMOptionsProps<'Ticket'>) => {
  const { setField } = useListManipilationContext<'Ticket'>()
  const { setStandalones, getAll, deleteExpressions, getChildren } = props.operations

  const [state] = useEzAuth()
  const me = useMemo(() => state.user, [state.user])

  const teamsSubscription = useUserTeamsSubscription({ variables: { userId: me.id } })

  const teams = useMemo(
    () => teamsSubscription.data?.team_assignments?.filter((team) => team.department_id && team.location_id) ?? [],
    [teamsSubscription.data?.team_assignments]
  )

  const expressions = useMemo(() => {
    const departmentExpressions = getAll('department_id', props.highlightIndex)
    const uniqueDepartmentExpressions = uniqBy(departmentExpressions, (o) => o.department_id?._eq)
    const expressions = []
    uniqueDepartmentExpressions.forEach((departmentExpression) => {
      const locationExpressions = getChildren('location_id', departmentExpression, props.highlightIndex)
      locationExpressions.forEach((locationExpression) => {
        expressions.push({ ...departmentExpression, ...locationExpression })
      })
    })
    return expressions
  }, [getAll, getChildren, props.highlightIndex])

  const checked = useCallback(
    (team: Team) =>
      expressions?.some(
        (exp) => exp?.department_id?._eq === team.department_id && exp?.location_id?._eq === team.location_id
      ),
    [expressions]
  )
  const checkedNull = useMemo(
    () => expressions?.some((exp) => exp?.department_id?._is_null && exp?.location_id?._is_null),
    [expressions]
  )

  const handleChange = useCallback(
    (team: Team) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const expressions = [
        { department_id: team.department_id ? { _eq: team.department_id } : { _is_null: true } },
        { location_id: team.location_id ? { _eq: team.location_id } : { _is_null: true } },
      ]
      if (!checked) {
        deleteExpressions(expressions)
      } else {
        setStandalones(expressions)
      }
    },
    [deleteExpressions, setStandalones]
  )

  const handleChangeNull = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const expressions = [{ department_id: { _is_null: true } }, { location_id: { _is_null: true } }]
      if (!checked) {
        deleteExpressions(expressions)
      } else {
        setStandalones(expressions)
      }
    },
    [deleteExpressions, setStandalones]
  )

  const { t } = useTranslation()

  return (
    <ListManipulation.UnitOptions label={'Teams'}>
      {teams.map((team) => (
        <ListManipulation.CheckableOption checked={checked(team)} onChange={handleChange(team)}>
          <Typo variant={'body1'} className={'pr-4'}>
            {renderTeam(t, team)}
          </Typo>
        </ListManipulation.CheckableOption>
      ))}
      <hr className={'text-divider'} />
      <ListManipulation.CheckableOption checked={checkedNull} onChange={handleChangeNull}>
        <Typo variant={'body1'} className={'pr-4'}>
          {'Kein Team'}
        </Typo>
      </ListManipulation.CheckableOption>
      {props.type === 'highlight' && (
        <>
          <hr className={'text-divider'} />
          <ListManipulation.ItemOption disabled={!expressions.length} onClick={() => setField('color')}>
            Farbe auswählen
          </ListManipulation.ItemOption>
        </>
      )}
    </ListManipulation.UnitOptions>
  )
}

export const TicketTeamChip = (props: LMChipProps<'Ticket'>) => {
  const { getAll, deleteAll, getChildren } = props.operations
  const { setField, field } = useListManipilationContext<'Ticket'>()

  const [state] = useEzAuth()
  const me = useMemo(() => state.user, [state.user])

  const { t } = useTranslation()

  const teamsSubscription = useUserTeamsSubscription({ variables: { userId: me.id } })

  const expressions = useMemo(() => {
    const departmentExpressions = getAll('department_id', props.highlightIndex)
    const uniqueDepartmentExpressions = uniqBy(departmentExpressions, (o) => o.department_id?._eq)
    const expressions = []
    uniqueDepartmentExpressions.forEach((departmentExpression) => {
      const locationExpressions = getChildren('location_id', departmentExpression, props.highlightIndex)
      locationExpressions.forEach((locationExpression) => {
        expressions.push({ ...departmentExpression, ...locationExpression })
      })
    })
    return expressions
  }, [getAll, getChildren, props.highlightIndex])

  const teams = useMemo(() => teamsSubscription.data?.team_assignments, [teamsSubscription.data?.team_assignments])

  const label = useMemo(
    () =>
      expressions.length === 1
        ? `Team: ${
            expressions[0]?.department_id?._is_null
              ? 'Kein Team'
              : renderTeam(
                  t,
                  teams?.find((team) =>
                    expressions[0]?.department_id?._is_null
                      ? !team.department_id
                      : team.department_id === expressions[0]?.department_id?._eq &&
                        expressions[0]?.location_id?._is_null
                      ? !team.location_id
                      : team.location_id === expressions[0]?.location_id?._eq
                  )
                )
          }`
        : `${expressions.length} Teams ausgewählt`,
    [expressions, t, teams]
  )

  const handleDelete = useCallback(() => {
    deleteAll('department_id', props.highlightIndex)
  }, [deleteAll, props.highlightIndex])

  if (!Boolean(expressions.length)) return null

  return (
    <ListManipulation.Group
      variant={props.type !== 'highlight' || props.color ? 'filled' : 'outlined'}
      color={props.type === 'highlight' ? props.color : 'default'}
      onDelete={handleDelete}
    >
      <ListManipulation.GroupUnit
        onClick={() => {
          props.onClick && props.onClick()
          setField('team')
        }}
        label={label}
      >
        {field === 'team' && (
          <TicketTeamOptions
            operations={props.operations}
            type={props.type === 'highlight' ? 'highlight' : 'filter'}
            highlightIndex={props.highlightIndex}
          />
        )}
        {field === 'color' && <HighlightsColorOptions />}
      </ListManipulation.GroupUnit>
    </ListManipulation.Group>
  )
}

const renderTeam = (t: TFunction, team?: Team) =>
  team
    ? `${team.department?.name} - ${team.location?.short_name} :: ${t(`UserRole.${team.role}`)} (Brands: ${
        team.brand?.name ?? 'Alle'
      }, Zukaufskanäle: ${team.in_buy_channel ? t(`InBuyChannel.${team.in_buy_channel}`) : `Alle`})`
    : 'Kein Team'
