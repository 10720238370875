import React, { useCallback, useContext } from 'react'
import { useListManipilationContext } from '../../unit'
import { ListConfigContext } from '../../../ListConfig'
import { useHighlightOperations } from '../../useFilterOperations'
import { Menu } from 'components/Menu'
import { Icon } from 'components/Icon'
import { TicketDefaultOptions } from '../Chips/TicketDefaultOptions'
import { HighlightsColorOptions } from '../../ColorOptions'
import { TicketTeamOptions } from '../Chips/TeamChip'
import { TicketHighlightsChips } from './Chips'
import { UserOptions } from 'containers/ListManipulation/Chips/UserChip'
import { DateOptions } from 'containers/ListManipulation/Chips/DateChip'
import { EnumOptions } from '../../Chips/EnumChip'

export const TicketsHighlights = () => {
  const { field, setField, highlightIndex, setHighlightIndex } = useListManipilationContext<'Ticket'>()
  const { highlights } = useContext(ListConfigContext)

  const handleAddHighlight = useCallback(() => {
    setField(undefined)
    setHighlightIndex(highlights?.length ?? 0)
  }, [highlights?.length, setField, setHighlightIndex])

  const operations = useHighlightOperations<'Ticket'>()
  const bindings = { operations, type: 'highlight', highlightIndex } as const

  return (
    <div className={'flex gap-x-2 item-center'} style={{ flex: '0 1 100%' }}>
      <TicketHighlightsChips />
      <Menu>
        <Menu.Button
          onClick={handleAddHighlight}
          className={'whitespace-nowrap'}
          variant={'text'}
          startIcon={<Icon name={'add'} />}
        >
          Markierung Hinzufügen
        </Menu.Button>
        {!field && <TicketDefaultOptions />}
        {field === 'color' && <HighlightsColorOptions />}
        {(field === 'state' || field === 'priority') && <EnumOptions<'Ticket'> {...bindings} />}
        {(field === 'created_at' || field === 'updated_at' || field === 'due_to') && (
          <DateOptions<'Ticket'> {...bindings} />
        )}
        {field === 'assignee_id' && <UserOptions {...bindings} />}
        {field === 'team' && <TicketTeamOptions {...bindings} />}
      </Menu>
    </div>
  )
}
