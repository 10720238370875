import Drawer from '@mui/material/Drawer'
import * as L from 'layouts'
import { AppBar } from 'components/AppBar'
import React, { CSSProperties } from 'react'
import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Toolbar } from 'components/Toolbar'
import { Typo } from 'components/Typo'
import clsx from 'clsx'
import { Spinner } from '../Spinner'
import { Portal } from '../Portal'

interface DetailWindowProps {
  title?: string
  open?: boolean
  children?: React.ReactNode
  onClose?: () => void
  width?: string | number
  className?: string
  classes?: {
    container?: string
  }
  styles?: {
    container?: CSSProperties
  }
  loading?: boolean
  variant?: 'temporary' | 'persistent'
  size?: 'large' | 'small'
}

export const DetailWindow = React.forwardRef<HTMLDivElement, DetailWindowProps>((props: DetailWindowProps, ref) => {
  const rootDiv = document.getElementById('root')

  let width = props.size ? (props.size === 'large' ? 900 : 400) : props.width

  return (
    <Portal mountElement={rootDiv}>
      <Drawer
        anchor="right"
        open={props.open}
        variant={props.variant || 'temporary'}
        className={clsx(props.className)}
        PaperProps={{
          className: 'shadow-19 bg-background-default h-full',
          style: {
            width: width,
          },
        }}
        ref={ref}
      >
        <div className="h-full flex flex-col">
          <AppBar position="relative" className="bg-background-paper text-text-primary">
            <L.Horizontal spacing={0.5}>
              <Button className="self-stretch border-none" onClick={props.onClose}>
                <Icon name="next" fontSize="large" />
              </Button>
              <Toolbar>
                <Typo variant="h2" component="h1">
                  {props.title}
                </Typo>
              </Toolbar>
            </L.Horizontal>
          </AppBar>
          <div className={clsx(props.classes?.container, 'flex-1')} style={props.styles?.container ?? {}}>
            {props.loading ? <Spinner /> : props.children}
          </div>
        </div>
        <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: -1 }} />
      </Drawer>
    </Portal>
  )
})

DetailWindow.defaultProps = {
  title: 'Detail',
  open: true,
}
