import React, { CSSProperties } from 'react'
import { Select } from 'components'
import { Dealer_Vehicle_Type_Enum } from 'gql'
import { useTranslation } from 'react-i18next'

interface DealerVehicleTypeSelectProps {
  value: Dealer_Vehicle_Type_Enum
  onSelect: (type: Dealer_Vehicle_Type_Enum) => void
  name?: string
  label?: string
  disabled?: boolean
  placeHolder?: string
  style?: CSSProperties
}

export const DealerVehicleTypeSelect: React.FC<DealerVehicleTypeSelectProps> = (props) => {
  const { value, onSelect, name, label, style, disabled, placeHolder } = props
  const { t } = useTranslation()

  return (
    <Select
      name={name ?? 'dealerVehicleTypeSelect'}
      label={label}
      value={value}
      onChange={(evt) => {
        evt.stopPropagation()
        onSelect(evt.target.value as Dealer_Vehicle_Type_Enum)
      }}
      renderSelectValueItem={(type) => (type ? t(`DealerVehicleType.${type}`) : '')}
      disabled={disabled}
      placeholder={placeHolder}
      style={style}
    >
      {Object.keys(Dealer_Vehicle_Type_Enum).map((type) => (
        <Select.Option key={type} value={Dealer_Vehicle_Type_Enum[type]}>
          {t(`DealerVehicleType.${Dealer_Vehicle_Type_Enum[type]}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
