import { Icon } from 'components/Icon'
import { ScrollSyncPane } from 'components/ScrollSync/pane'
import { Typo } from 'components/Typo'
import React, { useRef } from 'react'
import { YalcRowDefinition } from '../Yalc'
import styled from './Yalc2.module.css'
import { Checkbox } from 'components/Checkbox'

interface TitleBarProps<T extends { id: string }> {
  data: T[]
  selectedIds?: string[]
  rowDefinition?: YalcRowDefinition<T>
  onSort?: (col: keyof T, direction: 'asc' | 'desc') => void | Promise<void>
  orderedColumn?: { col: keyof T; direction: 'asc' | 'desc' }
  onSelectionChanged?: (ids: string[]) => void
  customIdProp?: string
}

export const TitleBar = <T extends { id: string }>(props: TitleBarProps<T>) => {
  const titleBarRef = useRef()

  return (
    <ScrollSyncPane attachTo={titleBarRef.current}>
      <div className={styled.titleBar} ref={titleBarRef}>
        {!props.selectedIds || !props.selectedIds.length || props.selectedIds.length === 0 ? (
          <ColumnTitles {...props} />
        ) : (
          <SelectBar {...props} />
        )}
      </div>
    </ScrollSyncPane>
  )
}

// ##################
// #   ColumnTitles #
// ##################

const ColumnTitles = <T extends { id: string }>(props: TitleBarProps<T>) => {
  const revertDirection = (direction: 'asc' | 'desc') => {
    switch (direction) {
      case 'asc':
        return 'desc'
      case 'desc':
        return 'asc'
    }
  }

  return (
    <>
      {props.rowDefinition?.columns
        ?.filter((col) => !col.hide)
        ?.map((col, idx) => {
          const Title = col.title
          let title = typeof Title === 'function' ? <Title /> : Title
          return props.onSort && col.sortColumn ? (
            <button
              style={{ minWidth: col.width, width: col.width }}
              className="py-2 px-1"
              key={idx}
              onClick={() =>
                props.onSort(
                  col.sortColumn,
                  props.orderedColumn && props.orderedColumn.col === col.sortColumn
                    ? revertDirection(props.orderedColumn.direction)
                    : 'asc'
                )
              }
            >
              <Typo variant="h6" className="inline">
                {title}
              </Typo>
              {props.orderedColumn && props.orderedColumn.col === col.sortColumn && (
                <>{props.orderedColumn.direction === 'asc' ? <Icon name="down" /> : <Icon name="up" />}</>
              )}
            </button>
          ) : (
            <Typo variant="h6" style={{ minWidth: col.width, width: col.width }} className="py-2 px-1" key={idx}>
              {title}
            </Typo>
          )
        })}
    </>
  )
}

// ##################
// #   SelectBar    #
// ##################

const SelectBar = <T extends { id: string }>(props: TitleBarProps<T>) => {
  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      props.onSelectionChanged(props.data.map((item) => item[props.customIdProp]))
    } else {
      props.onSelectionChanged([])
    }
  }
  const allItemsSelected = props.selectedIds.length === props.data.length

  return (
    <div className="flex flex-row py-2 pl-2 items-center">
      {!allItemsSelected && <Typo variant="caption">Alle auswählen?</Typo>}
      <Checkbox checked={allItemsSelected} onChange={handleChange} name="Select all" />
      <Typo variant="body1">{allItemsSelected ? 'Alle ausgewählt' : `${props.selectedIds.length} ausgewählt`}</Typo>
    </div>
  )
}
