import { FormControlLabel } from '@mui/material'
import { Button, Checkbox, Dialog, TextField } from 'components'
import React, { useContext } from 'react'
import { ExcelImportContext } from '..'

interface SaveTemplateDialogProps {
  onSave: () => Promise<void>
  nameBindings: {
    name: string
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    value: string
  }
  values: {
    strict: boolean
    name: string
  }
  setValue: (key: 'name' | 'strict', value: string | boolean) => void
}

export const SaveTemplateDialog = (props: SaveTemplateDialogProps) => {
  const { state, dispatch } = useContext(ExcelImportContext)
  return (
    <Dialog onClose={() => dispatch({ type: 'template:cancelled_saving' })} onClick={(evt) => evt.stopPropagation()}>
      <Dialog.Title>Vorlage speichern</Dialog.Title>
      <Dialog.Content style={{ paddingTop: '0.5rem', minWidth: 360 }}>
        <TextField {...props.nameBindings} label="Vorlagenname" className="mb-2" />
        <FormControlLabel
          value="start"
          label="Soll die Vorlage nur mit genau diesem Schema der Datei verwendbar sein? Wen das Kästchen angehakt ist, kann die Vorlage nur dann verwendet werden, wenn alle Spalten bei der Upload-Datei gleich sind."
          labelPlacement="start"
          className="ml-0 w-full"
          control={
            <Checkbox
              name={'strict'}
              checked={props.values.strict}
              onChange={(evt) => props.setValue('strict', evt.target.checked)}
            />
          }
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={() => {
            dispatch({ type: 'template:cancelled_saving' })
          }}
        >
          Abbrechen
        </Button>
        <Button
          disabled={!props.values.name}
          variant="contained"
          onClick={props.onSave}
          loading={state.templateLoading}
        >
          Speichern
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}
