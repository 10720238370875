import { SyntheticEvent } from 'react'

export const wrapEvent = (
  theirHandler: React.EventHandler<SyntheticEvent<any>>,
  ourHandler: React.EventHandler<SyntheticEvent<any>>
) => (event: SyntheticEvent<any>) => {
  event.persist()
  const result = theirHandler && theirHandler(event)

  // @ts-ignore
  if (result && result instanceof Promise) {
    // @ts-ignore
    ;(result as Promise<void>).then(() => {
      if (!event.defaultPrevented) {
        return ourHandler(event)
      }
    })
  } else {
    if (!event.defaultPrevented) {
      return ourHandler(event)
    }
  }
}

export default wrapEvent
