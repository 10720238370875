import React from 'react'
import { Spinner } from 'components'
import Backdrop from '@mui/material/Backdrop'
import styled from './LoadingOverlay.module.css'

interface LoadingOverlayProps {}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {
  return (
    <Backdrop open className={styled.backdrop}>
      <Spinner />
    </Backdrop>
  )
}
