import React, { useState } from 'react'
import { useLocationsListQuery } from 'gql'
import * as L from 'layouts'
import { Typo, Card, List, ListItem, NoDataText, SiteMetadata } from 'components'
import { AdminLocationDetail } from 'containers/AdminLocationDetail'

export const AdminLocationsPage = () => {
  const [selectedLocationId, setSelectedLocationId] = useState<string>()

  return (
    <>
      <L.Vertical>
        <SiteMetadata title="Standort Verwaltung" />
        <L.Horizontal spacing={5} style={{ alignItems: 'flex-start' }}>
          <L.Vertical className="flex-1">
            <Typo variant="h2" decorator>
              {'Standorte'}
            </Typo>
            <LocationList onSelect={(id) => setSelectedLocationId(id)} />
          </L.Vertical>

          <L.Vertical className="flex-3" spacing={selectedLocationId ? 0 : undefined}>
            <Typo variant="h2" decorator>
              {'Details'}
            </Typo>
            {selectedLocationId ? (
              <AdminLocationDetail locationId={selectedLocationId} />
            ) : (
              <Card className="p-4" style={{ minHeight: 400 }}>
                <NoDataText>{'Bitte wähle einen Standort aus.'}</NoDataText>
              </Card>
            )}
          </L.Vertical>
        </L.Horizontal>
      </L.Vertical>
    </>
  )
}

interface LocationListProps {
  onSelect?: (locationId: string) => void
}

const LocationList: React.FC<LocationListProps> = (props) => {
  const query = useLocationsListQuery()
  const locations = query.data?.locations

  const [selectedLocationIndex, setSelectedLocationIndex] = useState<number>()

  const handleItemSelected = (_, index: number) => {
    setSelectedLocationIndex(index)
    props.onSelect(locations[index].id)
  }

  return (
    <Card>
      <List loading={query.loading} style={{ minHeight: 250 }} className="overflow-y-scroll">
        {locations?.map((location, index) => {
          return (
            <ListItem
              key={location.id}
              selected={index === selectedLocationIndex}
              onClick={(event) => handleItemSelected(event, index)}
            >
              {location.name}
            </ListItem>
          )
        })}
        {(!locations || !locations.length) && (
          <>
            <NoDataText>{query.loading ? 'Lade Daten...' : 'Keine Standorte verfügbar.'}</NoDataText>
          </>
        )}
      </List>
    </Card>
  )
}
