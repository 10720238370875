import React, { useMemo, useState } from 'react'
import { SiteMetadata, Typo } from 'components'
import { VehiclesListConfig, VehiclesListType } from '../../../containers/ListConfig/Vehicles/List/Config'
import { VehiclesList } from '../../../containers/ListConfig/Vehicles/List'
import { useVehicleListConfigs } from '../../../containers/ListConfig/Vehicles/List/list-configs'
import { BookmarkCollapsable } from '../../../components/Collapsable/Bookmark'
import { ListPreviewCard } from '../../../containers/ListConfig/Vehicles/List/PreviewCard'
import { useVehiclesStore } from '../../../containers/ListConfig/Vehicles/hooks/useData'
import * as L from 'layouts'
import { Brand, useEmbeddedPagesQuery } from '../../../gql'
import { Iframe } from 'components/Iframe'
import { BrandTabBar } from '../../../containers/BrandSelect/TabBar'
import { uniqBy } from 'lodash'

export const DealerVehiclesPage = () => {
  const [listType, setListType] = useState<VehiclesListType>()
  const [embeddedPageName, setEmbeddedPageName] = useState<string>()
  const [embeddedPageBrandId, setEmbeddedPageBrandId] = useState<string>()
  const { reset } = useVehiclesStore()

  const types = [
    VehiclesListType.NEW,
    VehiclesListType.SORTED_BY_DAYS_SINCE_PURCHASE,
    VehiclesListType.LAST_SELLS,
    VehiclesListType.MY_SELLS,
    VehiclesListType.MY_OPEN_HANDED_OVER,
    VehiclesListType.MY_HANDED_OVER,
    VehiclesListType.MY_RESERVATIONS,
    VehiclesListType.MY_PREPARATIONS,
  ]
  const vehicleListConfigs = useVehicleListConfigs()
  const options = types.map((type) => ({ title: vehicleListConfigs[type].name, value: type }))

  const embeddedPagesQuery = useEmbeddedPagesQuery()
  const embeddedPages = useMemo(() => embeddedPagesQuery.data?.embedded_pages ?? [], [
    embeddedPagesQuery.data?.embedded_pages,
  ])
  const embeddedPagesUniqByName = useMemo(() => uniqBy(embeddedPages, 'name'), [embeddedPages])

  const embeddedPagesOptions = useMemo(
    () =>
      embeddedPagesUniqByName.map((uniquePage) => ({
        title: uniquePage.name,
        value: uniquePage.name,
        brandsFilter: (brand: Brand) =>
          embeddedPages.some((source) => source.brand_id === brand.id && source.name === uniquePage.name),
      })),
    [embeddedPages, embeddedPagesUniqByName]
  )

  const handleSelect = (listType: VehiclesListType) => {
    reset()
    setListType(listType)
    setEmbeddedPageName(null)
    setEmbeddedPageBrandId(null)
  }

  const handleEmbeddedPageNameSelect = (name: string) => {
    reset()
    setListType(null)
    setEmbeddedPageBrandId(null)
    setEmbeddedPageName(name)
  }

  const handleEmbeddedPageBrandIdSelect = (brandId: string) => {
    setEmbeddedPageBrandId(brandId)
  }

  return (
    <>
      <SiteMetadata title={vehicleListConfigs[listType]?.name} />
      {!listType && !embeddedPageName && (
        <L.Vertical spacing={4} className={'relative top-8'}>
          <Typo decorator variant={'h2'}>
            Listen
          </Typo>
          <div className={'flex flex-row flex-wrap gap-4'}>
            {types.map((type) => (
              <ListPreviewCard
                name={vehicleListConfigs[type].name}
                description={vehicleListConfigs[type].description}
                onClick={() => handleSelect(type)}
              />
            ))}
          </div>
          <Typo decorator variant={'h2'}>
            Externe Links
          </Typo>
          <div className={'flex flex-row flex-wrap gap-4'}>
            {embeddedPagesUniqByName.map((source) => (
              <ListPreviewCard name={source.name} onClick={() => handleEmbeddedPageNameSelect(source.name)} />
            ))}
          </div>
        </L.Vertical>
      )}
      {embeddedPageName && embeddedPageBrandId && (
        <Iframe
          className={'mt-4 w-full h-full'}
          title={`Externe Datenquelle ${embeddedPageName}`}
          url={
            embeddedPages.find((source) => source.brand_id === embeddedPageBrandId && source.name === embeddedPageName)
              ?.url
          }
        />
      )}
      <VehiclesListConfig type={listType}>
        <L.Horizontal className="absolute top-0 left-0 align-top z-drawer" style={{ alignItems: 'flex-start' }}>
          {(listType || embeddedPageName) && (
            <>
              <BookmarkCollapsable
                title={'Externe Links'}
                selected={embeddedPageName}
                options={embeddedPagesOptions}
                onSelect={(type) => handleEmbeddedPageNameSelect(type)}
                selectedBrandId={embeddedPageBrandId}
                onBrandSelect={(brandId: string) => handleEmbeddedPageBrandIdSelect(brandId)}
              />
              <BookmarkCollapsable
                selected={listType}
                title={'Listen'}
                options={options}
                onSelect={(listType: VehiclesListType) => handleSelect(listType)}
                suppressBrands
              />
              <BrandTabBar onlyHouseBrands />
            </>
          )}
        </L.Horizontal>
        {listType && <VehiclesList className={'mt-8'} />}
      </VehiclesListConfig>
    </>
  )
}
