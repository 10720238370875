import { Spinner, Tabs } from 'components'
import { DetailWindow } from 'components/DetailWindow'
import React, { createContext, useMemo } from 'react'
import {
  Dealer_Vehicle_State_Enum,
  Dealer_Vehicle_Type_Enum,
  Order_State_Enum,
  Registration_State_Enum,
  VehicleMetafieldsQueryResult,
  Import_Type_Enum,
  In_Buy_Channel_Enum,
  Customer_Type_Enum,
} from 'gql/generated'
import Joi from 'joi'
import { deualtJoiMessages, useJoi, UseJoiOptions } from 'hooks/useJoi'
import { StatusBar } from './StatusBar'
import { BulkFormData } from './01_GeneralData/BulkDataForm'
import { useEzAuthUserHasRoles, UserRole } from '@lib/ezauth'

interface SynchronisationError {
  id: string
  message: string
  first_occured: string
  latest_ocurrence: string
  cause?: string
  resolved?: string
  transaction_id?: string
}

export interface VehicleFormData {
  id: string
  version: number
  business_case_number: number
  max_version: number
  min_non_imported_business_case: number
  vin?: string
  model_name?: string
  state?: Dealer_Vehicle_State_Enum
  order_state: Order_State_Enum
  type: Dealer_Vehicle_Type_Enum
  min_holding_period?: number
  customer?: string
  customer_type?: Customer_Type_Enum
  sold_at?: string
  sold_by_id?: string
  sold_by?: {
    id: string
    full_name?: string
    email: string
  }
  reserved_by_id?: string
  reserved_by?: {
    id: string
    full_name?: string
    email: string
  }
  reserved_for?: string
  reserved_for_customer_type?: Customer_Type_Enum
  reserved_at?: string
  reserved_until?: string
  invoice_date?: string
  handover_date?: string
  handover_planned_date?: string
  handover_by_id?: string
  handover_by?: {
    id: string
    full_name?: string
    email: string
  }
  days_since_purchase?: number
  purchased_by_id?: string
  purchased_by?: {
    id: string
    full_name?: string
    email: string
  }
  in_buy_contract_date?: string
  co2_nefz?: number
  co2_wltp?: number
  keys_count?: number
  key_kabinet_number?: string
  registration_owner?: string
  registration_state: Registration_State_Enum
  mileage: number
  mileage_date?: string
  color_exterior_code?: string
  color_exterior_name?: string
  color_interior_code?: string
  color_interior_name?: string
  job_number?: string
  storage_location_id?: string
  storage_location_note?: string
  storage_location?: { id: string; name: string }
  delivery_location_id?: string

  dms_assigned_location_id?: string

  dms_assigned_location?: { id: string; name: string }
  seller_location_id?: string

  seller_location?: { id: string; name: string }
  delivery_date_str?: string
  in_buy_channel?: In_Buy_Channel_Enum
  delivery_location?: {
    id: string
    name: string
  }
  brand: { id: string; name: string }
  model: {
    id: string
    modelGroup?: { id: string; name: string }
    model_group_release_year?: number
    sales_model_code?: string
    model_code: string
    model_group_id?: string
  }
  sync_errors: SynchronisationError[]
  first_registration_date?: string
  license_plate_number?: string
  warranty_end?: string
  warranty_start?: string

  specs?: string
  spec_level?: string
  order_code?: string
  control_number?: string
  accessories_mounted?: boolean
  sync_error?: boolean
  history?: {
    id: string
    version: number
    business_case_number: number
    holder_location?: {
      id: string
      name: string
    }
    holder_supplier?: {
      id: string
      name: string
    }
    holder_customer?: {
      id: string
      name: string
    }
    owner_location?: {
      id: string
      name: string
    }
    owner_supplier?: {
      id: string
      name: string
    }
    owner_customer?: {
      id: string
      name: string
    }
    generated_by: {
      id: string
      job_number?: string
      cancelled_data?: string
      invoice_date?: string
    }[]
  }[]

  advertising_price?: number
  cash_price?: number
  list_price?: number
  boni: {
    id: string
    value: number
    type: string
  }[]
}

export enum SyncErrorState {
  RESOLVED = 'RESOLVED',
  UNRESOLVED = 'UNRESOLVED',
  CLEAN = 'CLEAN',
}

export const VehicleDetailContext = createContext<{
  data?: VehicleFormData
  loading?: boolean
  metadata?: {
    metafields: VehicleMetafieldsQueryResult['data']['brand_vehicle_metafield']
    importType?: Import_Type_Enum
  }
}>({
  data: null,
  loading: false,
  metadata: null,
})

interface VehicleDetailProps {
  onClose: () => void
  data?: VehicleFormData
  loading?: boolean
  metadata?: {
    metafields: VehicleMetafieldsQueryResult['data']['brand_vehicle_metafield']
    importType?: Import_Type_Enum
  }
  panes: {
    general: React.ReactNode
    registration?: React.ReactNode
    prices?: React.ReactNode
    history?: React.ReactNode
    market?: React.ReactNode
    customer?: React.ReactNode
    tickets?: React.ReactNode
  }
}

export const VehicleDetail = React.forwardRef<HTMLDivElement, VehicleDetailProps>((props, ref) => {
  const { children, onClose, ...rest } = props

  return (
    <VehicleDetailContext.Provider value={rest}>
      <DetailWindow
        width={976}
        onClose={props.onClose}
        title="Fahrzeug Datenblatt"
        styles={{ container: { padding: 0 } }}
        ref={ref}
        variant={'persistent'}
      >
        <StatusBar />
        <Tabs>
          <Tabs.TabList>
            <Tabs.Tab label="Grunddaten" />
            <Tabs.Tab label="Zulassung & Retail" disabled />
            <Tabs.Tab label="Preise & Boni" disabled={!props.panes.prices} />
            <Tabs.Tab label="History" disabled={!props.panes.history} />
            <Tabs.Tab label="Börse" disabled />
            <Tabs.Tab label="Kunde & CRM" disabled />
            <Tabs.Tab label="Aufgaben" disabled={!props.panes.tickets} />
          </Tabs.TabList>
          <Tabs.TabPanes>
            {withinTabPane(props, props.panes.general)}
            {withinTabPane(props, props.panes.registration)}
            {withinTabPane(props, props.panes.prices)}
            {withinTabPane(props, props.panes.history)}
            {withinTabPane(props, props.panes.market)}
            {withinTabPane(props, props.panes.customer)}
            {withinTabPane(props, props.panes.tickets)}
          </Tabs.TabPanes>
        </Tabs>
      </DetailWindow>
    </VehicleDetailContext.Provider>
  )
})

export const withinTabPane = (props: {loading?: boolean}, node?: React.ReactNode): JSX.Element => {
  return (
    <Tabs.TabPane>
      {props.loading && (
        <div className="p-6">
          <Spinner />
        </div>
      )}
      {node}
    </Tabs.TabPane>
  )
}

// TODO: merge initial form data

type RequiredModelData = Pick<
  VehicleFormData['model'],
  'id' | 'modelGroup' | 'model_group_release_year' | 'sales_model_code'
>
type FormData = { model: RequiredModelData } & Pick<
  VehicleFormData,
  | 'color_exterior_code'
  | 'color_exterior_name'
  | 'color_interior_code'
  | 'color_interior_name'
  | 'order_code'
  | 'accessories_mounted'
  | 'control_number'
  | 'state'
  | 'order_state'
  | 'delivery_date_str'
  | 'delivery_location_id'
  | 'storage_location_id'
  | 'storage_location_note'
  | 'type'
  | 'min_holding_period'
  | 'registration_state'
  | 'registration_owner'
  | 'key_kabinet_number'
  | 'sold_at'
  | 'reserved_at'
  | 'reserved_until'
  | 'reserved_for'
  | 'reserved_for_customer_type'
  | 'sold_by_id'
  | 'reserved_by_id'
  | 'customer'
  | 'customer_type'
  | 'handover_date'
  | 'handover_planned_date'
  | 'handover_by_id'
  | 'specs'
  | 'spec_level'
  | 'in_buy_channel'
  | 'seller_location_id'
  | 'purchased_by_id'
  | 'in_buy_contract_date'
  | 'co2_nefz'
  | 'co2_wltp'
  | 'keys_count'
>
export type VehicleFormValues = FormData

///////// ///////// /////////
///////// MAPPING
///////// ///////// /////////
const filteredKeys: (keyof VehicleFormValues)[] = [
  'model',
  'color_interior_code',
  'color_interior_name',
  'color_exterior_code',
  'color_exterior_name',
]
type MappingKey = keyof Omit<
  VehicleFormValues,
  'model' | 'color_interior_code' | 'color_interior_name' | 'color_exterior_code' | 'color_exterior_name'
>
// TODO: check K typing
type MapperFn<Target extends {}, K extends keyof Target> = (
  item: [MappingKey, VehicleFormValues[MappingKey]],
  target: Target
) => [K, Target[K]] | null

export const mapVehicleFormValues = <T extends {}, K extends keyof T = keyof T>(
  source: VehicleFormValues | BulkFormData,
  target: T,
  mapper: MapperFn<T, K>
): T => {
  const keys = Object.keys(source).filter((key) => filteredKeys.indexOf(key as any) < 0) as MappingKey[]
  keys.forEach((key) => {
    const mapped = mapper([key, source[key]], target)
    if (mapped) {
      const [tKey, tVal] = mapped
      target[tKey] = tVal
    }
  })
  return target
}
///////// ///////// /////////
///////// END OF MAPPING
///////// ///////// /////////

export const useVehicleValidationSchema = (
  item: FormData | FormData[],
  brandId?: string,
  metadata?: VehicleDetailProps['metadata'],
  options?: UseJoiOptions
) => {
  const validationSchema = useMemo(() => {
    const partialImportValidationObject = {
      in_buy_channel: Joi.string()
        .label('Zukaufs Kanal')
        .required()
        .valid(...Object.values(In_Buy_Channel_Enum)),
      sold_by_id: Joi.string().guid().label('Verkauft von').required(),
      sold_at: Joi.string().isoDate().label('Verkauft am').required(),
    }

    const fullImportInitialValidationObject = {
      state: Joi.string()
        .label('Status')
        .required()
        .valid(...Object.values(Dealer_Vehicle_State_Enum)),
      order_state: Joi.string()
        .required()
        .valid(...Object.values(Order_State_Enum))
        .label('Transportstatus'),
      type: Joi.string()
        .required()
        .valid(...Object.values(Dealer_Vehicle_Type_Enum))
        .label('Typ'),
      delivery_location_id: Joi.string().guid().required().label('Lieferort'),
      delivery_date_str: Joi.string().required().label('Lieferdatum'),
      storage_location_id: Joi.when('order_state', {
        is: Joi.equal(Order_State_Enum.InStock),
        then: Joi.string().label('Lagerort').guid().required(),
      }),
      registration_state: Joi.string()
        .valid(...Object.values(Registration_State_Enum))
        .label('Zulassungs Status'),
      registration_owner: Joi.when('registration_state', {
        is: Joi.equal(Registration_State_Enum.Registered),
        then: Joi.string().required().label('ZL Besitzer'),
      }),
      model: Joi.alternatives().conditional('in_buy_channel', {
        is: In_Buy_Channel_Enum.Import,
        then: Joi.object<RequiredModelData>({
          modelGroup: Joi.object({ id: Joi.string().required().label('Modell Gruppe') })
            .required()
            .label('Modell Gruppe'),
          sales_model_code: Joi.string().required().label('Modell Code'),
          model_group_release_year: Joi.number().required().label('Modell Release Jahr'),
        })
          .required()
          .label('Modell'),
        otherwise: Joi.object<RequiredModelData>({
          modelGroup: Joi.object({ id: Joi.string().required().label('Modell Gruppe') })
            .required()
            .label('Modell Gruppe'),
          model_group_release_year: Joi.number().required().label('Modell Release Jahr'),
        })
          .required()
          .label('Modell'),
      }),
      color_exterior_name: Joi.string().required().label('Exterior Name'),
      color_exterior_code: Joi.string().required().label('Exterior Code'),
      color_interior_name: Joi.string().required().label('Interior Name'),
      color_interior_code: Joi.string().required().label('Interior Code'),
    }

    const fullImportValidationObject =
      metadata?.metafields
        ?.filter((meta) => meta.brand_id === brandId)
        ?.reduce((acc, curr) => {
          if (curr.required_for_import) {
            return Object.assign(acc, {
              [curr.vehicle_metafield.column_name]: Joi.required().label(curr.vehicle_metafield.title),
            })
          }
          return acc
        }, fullImportInitialValidationObject) ?? fullImportInitialValidationObject

    const validtionObject =
      metadata?.importType === Import_Type_Enum.Partial ? partialImportValidationObject : fullImportValidationObject
    return Joi.object(validtionObject as any).messages(deualtJoiMessages)
  }, [brandId, metadata?.importType, metadata?.metafields])
  const response = useJoi(validationSchema, item, options)
  const isAdmin = useEzAuthUserHasRoles(UserRole.ADMIN)
  return { ...response, isValid: isAdmin || metadata.importType === Import_Type_Enum.Full ? response.isValid : false }
}
