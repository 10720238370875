import { DetailWindow } from 'components/DetailWindow'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import * as L from 'layouts'
import { Button } from 'components/Button'
import { Dialog } from 'components/Dialog'
import { Typo } from 'components/Typo'
import { IconButton } from 'components/IconButton'
import { Icon } from 'components/Icon'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { Yalc2, Yalc2RowDefinition } from '../../../components/Yalc2'
import { DetailViewEnum, useDetailView } from '../../../components/DetailWindow/useDetailView'
import {ExcelImportType} from "../index";

interface ExcelImportTemplate {
  id: string
  name: string
  strict?: boolean
  excel_definition?: string[]
}

interface ExcelImportTemplatesListProps {
  onClose: () => void
  importTemplates?: ExcelImportTemplate[]
  onChoose: (id: string) => Promise<void>
  onItemRemove: (id: string) => Promise<void>
  i18nPrefix: string
  variant: ExcelImportType
}

export const ExcelImportTemplatesList = (props: ExcelImportTemplatesListProps) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [removeImportTemplate, setRemoveImportTemplate] = useState<ExcelImportTemplate>()
  const [templateDetails, setTemplateDetails] = useState<ExcelImportTemplate>()

  const listRef = useRef<HTMLDivElement>()

  const handleClickOutsideSelection = useCallback((evt: React.MouseEvent) => {
    const clickedInside = (ref: React.MutableRefObject<Element>, evt: React.MouseEvent) => {
      return ref.current?.contains(evt.target as any)
    }
    if (!clickedInside(listRef, evt) && document.activeElement.tagName === 'DIV') {
      setSelectedIds([])
    }
  }, [])

  const yalcRowDefinition = useRowDefinition({
    onItemRemove: (id) => setRemoveImportTemplate(props.importTemplates?.find(({ id: tId }) => tId === id)),
    setTemplateDetails,
  })

  const { openNestedDetailView } = useDetailView()
  useEffect(() => {
    if (!!templateDetails) {
      openNestedDetailView(DetailViewEnum.ExcelImportTemplateDetail, {
        title: templateDetails.name,
        templateId: templateDetails.id,
        onClose: () => setTemplateDetails(null),
        i18nPrefix: props.i18nPrefix,
        variant: props.variant
      })
    }
  }, [openNestedDetailView, props.i18nPrefix, templateDetails, props.variant])
  return (
    <DetailWindow width={700} title="Vorlagen" styles={{ container: { padding: 0 } }} onClose={props.onClose}>
      <Yalc2<ExcelImportTemplate>
        data={props.importTemplates ?? []}
        rowDefinition={yalcRowDefinition}
        selectedIds={selectedIds}
        onSelectionChanged={setSelectedIds}
        onClickOutsideList={handleClickOutsideSelection}
        classes={{ item: 'group' }}
      />
      <L.Horizontal className="absolute bottom-0 bg-white px-6 py-4 justify-end z-10 w-full">
        <Button
          onClick={async () => {
            await props.onChoose(selectedIds[0])
            props.onClose()
          }}
          variant="contained"
          disabled={selectedIds.length === 0 || !selectedIds}
        >
          Übernehmen
        </Button>
      </L.Horizontal>
      {removeImportTemplate && (
        <Dialog
          onClose={() => setRemoveImportTemplate(null)}
          onClick={(evt) => {
            evt.stopPropagation()
          }}
        >
          <Dialog.Title>Vorlage löschen</Dialog.Title>
          <Dialog.Content>
            <Typo variant="body1">
              Möchtest du wirklich die Vorlage{' '}
              <mark className="bg-background-darkDefault bg-opacity-30 p-1">{removeImportTemplate.name}</mark>
              löschen?
            </Typo>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onClick={() => setRemoveImportTemplate(null)}>Nein</Button>
            <Button
              onClick={async () => {
                await props.onItemRemove(removeImportTemplate.id)
                setRemoveImportTemplate(null)
              }}
              variant="contained"
            >
              Ja
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </DetailWindow>
  )
}

interface RowDefinitionProps {
  onItemRemove: (id: string) => void
  setTemplateDetails: React.Dispatch<React.SetStateAction<ExcelImportTemplate>>
}

const useRowDefinition = (props: RowDefinitionProps): Yalc2RowDefinition<ExcelImportTemplate> => {
  const { onItemRemove, setTemplateDetails } = props
  return useMemo(
    () => ({
      columns: [
        {
          title: 'Name',
          width: 100,
          cell: function NameCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.name}</Yalc2Cell.MaxWidthCell>
          },
        },
        {
          title: 'Nur für Dateien mit den Spalten',
          width: 450,
          cell: function DefintionCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.excel_definition?.join(', ')}</Yalc2Cell.MaxWidthCell>
          },
        },
        {
          title: '',
          width: 150,
          cell: (props) => (
            <div className={'hidden items-end group-hover:flex'}>
              <IconButton
                aria-label="show import template"
                onClick={(evt) => {
                  evt.stopPropagation()
                  setTemplateDetails(props.item)
                }}
                size="small"
                tooltip="Feldzuordnungen ansehen"
                color="primary"
                style={{ padding: '0 4px' }}
              >
                <Icon name="view"></Icon>
              </IconButton>
              <IconButton
                aria-label="remove import template"
                onClick={(evt) => {
                  evt.stopPropagation()
                  onItemRemove(props.item.id)
                }}
                size="small"
                tooltip="Vorlage löschen"
                color="primary"
                style={{ padding: '0 4px' }}
              >
                <Icon name="delete"></Icon>
              </IconButton>
            </div>
          ),
        },
      ],
    }),
    [onItemRemove, setTemplateDetails]
  )
}
