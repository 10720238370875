import React from 'react'
import { Card, SiteMetadata } from '../../components'
import * as L from '../../layouts'
import { Link } from '@mui/material'

export const NotFoundPage = () => {
  return (
    <>
      <SiteMetadata title="Seite nicht gefunden" />
      <Card className={'p-4'}>
        <L.Vertical spacing={4}>
          <Link href={'/'}>Zurück an den Start</Link>
        </L.Vertical>
      </Card>
    </>
  )
}
