import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ListConfigContext } from '../../index'
import {
  Order_By,
  Vehicle_Bool_Exp,
  VehicleListFieldsFragment,
  useVehiclesListLazyQuery,
  VehiclesListQueryResult,
  useVehiclesCountLazyQuery,
  useChangedVehiclesListSubscription,
} from '../../../../gql'
import { countBy, sortBy } from 'lodash'
import { composeVehicleId, VehicleItem } from '../List'
import { useGroupName } from 'containers/ListManipulation/Vehicles/GroupBy/Options'
import create from 'zustand'
import { deriveFilterFromHasuraRepresentation } from '../../../ListManipulation/useFilterOperations'

const PAGE_SIZE = 50
export const useData = (options?: { order?: { col?: keyof VehicleItem; direction?: 'asc' | 'desc' } }) => {
  const { filter, columns, groupBy: grouping } = useContext(ListConfigContext)
  const [pageIndex, setPageIndex] = useState(0)
  const where: Vehicle_Bool_Exp = useMemo(() => (filter ? JSON.parse(filter) : {}), [filter])
  const filterFn = useMemo(
    () => (filter ? deriveFilterFromHasuraRepresentation<'Vehicle'>(filter) : () => true),
    [filter]
  )
  const {
    merge,
    totalCount,
    initialized,
    setTotalCount,
    loading,
    setLoading,
    clean,
    update: updateVehiclesState,
    remove: removeVehicleFromState,
  } = useVehiclesStore()

  const { render: renderGroupName, orderBy, iteratee } = useGroupName()
  const order_by = useMemo(() => {
    if (grouping) {
      return [orderBy]
    }
    if (options?.order && options?.order?.col) {
      return [{ [options.order.col]: options.order.direction }]
    }
    return [{ id: Order_By.Asc }]
  }, [grouping, options.order, orderBy])
  const with_sync_errors = useMemo(() => columns?.includes('error') ?? false, [columns])
  const [updated_at] = useState(new Date())

  const [query, response] = useVehiclesListLazyQuery({ notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' })
  const [countQuery] = useVehiclesCountLazyQuery({ notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (!initialized) return
    countQuery({ variables: { where } }).then((res) =>
      setTotalCount(res.data?.vehicles_aggregate?.aggregate?.count ?? 0)
    )
  }, [countQuery, initialized, setTotalCount, where])

  // Page fetch when no grouping is set
  useEffect(() => {
    if (grouping || !initialized) return
    fetchRef.current?.cancel()
    // noinspection JSIgnoredPromiseFromCall
    query({ variables: { where, order_by, with_sync_errors, limit: PAGE_SIZE, offset: pageIndex * PAGE_SIZE } })
      .then((res) => {
        merge(res, renderGroupName, iteratee, true)
      })
      .catch(() => setLoading(false))
  }, [
    grouping,
    initialized,
    iteratee,
    merge,
    order_by,
    pageIndex,
    query,
    renderGroupName,
    setLoading,
    where,
    with_sync_errors,
  ])

  const fetchRef = useRef<{ execute: () => Promise<void>; cancel: () => void }>(undefined)

  const paginatedFetch = useCallback(() => {
    let didCancel = false
    return {
      execute: async () => {
        didCancel = false
        setLoading(true)
        try {
          const res = await query({
            variables: {
              where,
              order_by,
              with_sync_errors,
              limit: PAGE_SIZE,
              offset: pageIndex * PAGE_SIZE,
            },
          })
          !didCancel && merge(res, renderGroupName, iteratee, true)
          setLoading(false)
        } catch (e) {
          setLoading(false)
        }
      },
      cancel: () => {
        didCancel = true
      },
    }
  }, [iteratee, merge, order_by, pageIndex, query, renderGroupName, setLoading, where, with_sync_errors])

  const nonPaginatedFetch = useCallback(() => {
    let didCancel = false
    return {
      execute: async () => {
        didCancel = false
        setLoading(true)
        clean()
        let offset = 0
        while (offset < totalCount && !didCancel) {
          const res = await query({
            variables: {
              where,
              order_by,
              with_sync_errors,
              offset,
              limit: PAGE_SIZE * 10,
            },
          })
          !didCancel && merge(res, renderGroupName, iteratee)
          offset = offset + (res.data?.vehicles?.length ?? 0)
        }
        setLoading(false)
      },
      cancel: () => {
        didCancel = true
      },
    }
  }, [clean, iteratee, merge, order_by, query, renderGroupName, setLoading, totalCount, where, with_sync_errors])

  const changedVehiclesFilter: Vehicle_Bool_Exp = useMemo(
    () => ({_and: where?._and?.filter((cond) => cond.version || cond.business_case_number)}),
    [where?._and]
  )

  // Subscribe to changes
  useChangedVehiclesListSubscription({
    variables: { with_sync_errors, updated_at, batch_size: PAGE_SIZE, where: changedVehiclesFilter },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData?.data?.Vehicle_stream?.length === PAGE_SIZE) {
        fetchRef.current?.cancel()
        if (grouping) {
          fetchRef.current = nonPaginatedFetch()
        } else {
          fetchRef.current = paginatedFetch()
        }
        fetchRef.current?.execute()
      } else {
        subscriptionData?.data?.Vehicle_stream?.forEach((vehicle) => {
          if (filterFn(vehicle)) {
            updateVehiclesState(vehicle, renderGroupName, iteratee)
          } else {
            const composedId = composeVehicleId(vehicle)
            removeVehicleFromState([composedId], renderGroupName, iteratee)
          }
        })
      }
    },
  })

  // Filtered items fetch when grouping is set
  useEffect(() => {
    if (!grouping || !initialized || loading) return
    fetchRef.current?.cancel()
    fetchRef.current = nonPaginatedFetch()
    fetchRef.current?.execute()
  }, [grouping, initialized, loading, nonPaginatedFetch])

  const pageInfo = useMemo(() => {
    return {
      pageStartIndex: !grouping ? pageIndex * PAGE_SIZE + 1 : 1,
      pageEndIndex: !grouping ? Math.min(pageIndex * PAGE_SIZE + PAGE_SIZE, totalCount) : totalCount,
      totalCount,
      hasPreviousPage: !grouping ? pageIndex > 0 : false,
      hasNextPage: !grouping ? totalCount > pageIndex * PAGE_SIZE + PAGE_SIZE : false,
    }
  }, [grouping, pageIndex, totalCount])
  const maxPageIndex = Math.ceil(totalCount / PAGE_SIZE) - 1

  const nextPage = useCallback(() => {
    setPageIndex((prev) => Math.min(maxPageIndex, prev + 1))
  }, [maxPageIndex])
  const prevPage = useCallback(() => {
    setPageIndex((prev) => Math.max(0, prev - 1))
  }, [])

  useEffect(() => {
    setPageIndex(0)
  }, [filter])
  return {
    loading: response.loading,
    cancelFetch: () => fetchRef.current?.cancel(),
    pageInfo,
    nextPage,
    prevPage,
  }
}

interface VehiclesState {
  vehicles: (VehicleListFieldsFragment & { composedId: string })[]
  nonPaginatedOffset: number
  groups: { title: string; count: number }[]
  totalCount: number
  initialized: boolean
  loading: boolean
}

export const useVehiclesStore = create<
  VehiclesState & {
    merge: (
      result: VehiclesListQueryResult,
      renderGroupName: (groupName: string) => string,
      iteratee?: (item: any) => any,
      clean?: boolean
    ) => void
    reset: () => void
    clean: () => void
    init: () => void
    update: (
      item: VehicleListFieldsFragment,
      renderGroupName: (groupName: string) => string,
      iteratee?: (item: any) => any
    ) => void
    remove: (
      composedIds: string[],
      renderGroupName: (groupName: string) => string,
      iteratee?: (item: any) => any
    ) => void
    setTotalCount: (totalCount: number) => void
    setLoading: (loading: boolean) => void
  }
>((set) => {
  return {
    nonPaginatedOffset: 0,
    totalCount: 0,
    vehicles: [],
    groups: [{ title: 'Fahrzeuge', count: 0 }],
    initialized: false,
    loading: false,
    setTotalCount: (totalCount) => set((state) => ({ ...state, totalCount })),
    setLoading: (loading) => set((state) => ({ ...state, loading })),
    clean: () =>
      set((state) => ({
        vehicles: [],
        groups: [{ title: 'Fahrzeuge', count: 0 }],
        totalCount: state.totalCount,
        initialized: true,
        loading: false,
        nonPaginatedOffset: 0,
      })),
    reset: () =>
      set({
        vehicles: [],
        groups: [{ title: 'Fahrzeuge', count: 0 }],
        totalCount: 0,
        initialized: false,
        loading: false,
        nonPaginatedOffset: 0,
      }),
    init: () =>
      set({
        vehicles: [],
        groups: [{ title: 'Fahrzeuge', count: 0 }],
        totalCount: 0,
        initialized: true,
        loading: false,
        nonPaginatedOffset: 0,
      }),
    remove: (composedIds, renderGroupName, iteratee) =>
      set((state) => {
        const vehicles = state.vehicles.filter((v) => !composedIds.some((composedId) => composedId === v.composedId))
        return {
          ...state,
          vehicles,
          groups: iteratee
            ? Object.entries(countBy(vehicles, iteratee)).map(([title, count]) => ({
                title: renderGroupName(title),
                count,
              }))
            : [{ title: 'Fahrzeuge', count: vehicles?.length }],
        }
      }),
    update: (item, renderGroupName, iteratee) =>
      set((state) => {
        const composedId = composeVehicleId(item)
        let vehicles = []
        if (!state.vehicles.some((v) => v.composedId === composedId)) {
          vehicles = sortBy([...state.vehicles, { ...item, composedId }], iteratee)
        } else {
          vehicles = sortBy(
            state.vehicles.map((v) => (v.composedId === composedId ? { ...v, ...item } : v)),
            iteratee
          )
        }
        return {
          ...state,
          vehicles,
          groups: iteratee
            ? Object.entries(countBy(vehicles, iteratee)).map(([title, count]) => ({
                title: renderGroupName(title),
                count,
              }))
            : [{ title: 'Fahrzeuge', count: vehicles?.length }],
        }
      }),
    merge: (result, renderGroupName, iteratee, clean) =>
      set((state) => {
        const prevVehicles = clean ? [] : state.vehicles
        const array = [
          ...prevVehicles,
          ...result?.data?.vehicles?.map(
            (v) =>
              ({
                ...v,
                composedId: composeVehicleId(v),
              } ?? [])
          ),
        ]
        return {
          initialized: true,
          loading: false,
          vehicles: array as any,
          groups: iteratee
            ? Object.entries(countBy(array, iteratee)).map(([title, count]) => ({
                title: renderGroupName(title),
                count,
              }))
            : [{ title: 'Fahrzeuge', count: array?.length }],
          totalCount: state.totalCount,
          nonPaginatedOffset: state.nonPaginatedOffset + (result?.data?.vehicles?.length ?? 0),
        }
      }),
  }
})
