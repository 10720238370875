import React, { CSSProperties } from 'react'
import { Select } from 'components'
import { Dealer_Vehicle_State_Enum } from 'gql'
import { useTranslation } from 'react-i18next'

interface DealerVehicleStateSelectProps {
  value: Dealer_Vehicle_State_Enum
  onSelect: (type: Dealer_Vehicle_State_Enum) => void
  filterValue?: (value: Dealer_Vehicle_State_Enum) => boolean
  name?: string
  label?: string
  disabled?: boolean
  style?: CSSProperties
}

export const DealerVehicleStateSelect: React.FC<DealerVehicleStateSelectProps> = (props) => {
  const { value, onSelect, name, label, style, disabled } = props
  const { t } = useTranslation()
  const filterFn = props.filterValue ? (key) => props.filterValue(Dealer_Vehicle_State_Enum[key]) : () => true

  return (
    <Select
      name={name ?? 'dealerVehicleStateSelect'}
      label={label}
      value={value}
      onChange={(evt) => {
        evt.stopPropagation()
        onSelect(evt.target.value as Dealer_Vehicle_State_Enum)
      }}
      renderSelectValueItem={(state) => t(`DealerVehicleState.${state}`)}
      disabled={disabled}
      style={style}
    >
      {Object.keys(Dealer_Vehicle_State_Enum)
        .filter(filterFn)
        .map((state) => (
          <Select.Option key={state} value={Dealer_Vehicle_State_Enum[state]}>
            {t(`DealerVehicleState.${Dealer_Vehicle_State_Enum[state]}`)}
          </Select.Option>
        ))}
    </Select>
  )
}
