import React, { useEffect, useMemo, useState, useCallback } from 'react'
import * as L from 'layouts'
import { Yalc2, Yalc2RowDefinition } from '../../components/Yalc2'
import { useVehiclesWithMixedUpInvoicesLazyQuery, VehiclesWithMixedUpInvoicesQueryResult } from '../../gql'
import { Yalc2Cell } from '../../components/Yalc2/cells'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'
import { composeVehicleId } from '../ListConfig/Vehicles/List'
import { Icon, IconButton, Menu } from 'components'
import clsx from 'clsx'
import { useResyncVehicleHistory } from '../../hooks/useResyncVehicleHistory'
import { PaginationNavigationProps } from '../../components/PaginationNavigation'

interface DataQualityMixedUpInvoicesListProps {
  className?: string
}

type VehicleItems = VehiclesWithMixedUpInvoicesQueryResult['data']['vehiclesWithMixedUpInvoices']['vehicles']
type VehicleItem = ArrayElement<VehicleItems>

export const DataQualityMixedUpInvoicesList = (props: DataQualityMixedUpInvoicesListProps) => {
  const [query, queryResult] = useVehiclesWithMixedUpInvoicesLazyQuery()

  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [pageBreaks, setPageBreaks] = useState<number[]>([0])
  const [pageNumber, setPageNumber] = useState<number>(0)

  useEffect(() => {
    void query()
  }, [query])

  const loading = useMemo(() => queryResult.loading, [queryResult.loading])
  const { vehicles, cursor } = useMemo(() => {
    const nextCursor = queryResult.data?.vehiclesWithMixedUpInvoices?.cursor?.next
    if (nextCursor && !pageBreaks.includes(nextCursor)) {
      setPageBreaks((prev) => prev.concat(nextCursor))
    }
    return {
      vehicles: queryResult.data?.vehiclesWithMixedUpInvoices?.vehicles ?? [],
      cursor: queryResult.data?.vehiclesWithMixedUpInvoices?.cursor,
    }
  }, [
    pageBreaks,
    queryResult.data?.vehiclesWithMixedUpInvoices?.cursor,
    queryResult.data?.vehiclesWithMixedUpInvoices?.vehicles,
  ])

  const fetchNextPage = useCallback(async () => {
    setPageNumber((prev) => prev + 1)
    await query({ variables: { offset: cursor?.next } })
  }, [cursor?.next, query])

  const fetchPreviousPage = useCallback(async () => {
    console.log(pageBreaks, pageNumber)
    const offset = pageBreaks[pageNumber - 1]
    setPageNumber((prev) => prev - 1)
    await query({ variables: { offset } })
  }, [pageBreaks, pageNumber, query])

  const pagination: PaginationNavigationProps = useMemo(
    () => ({
      hasNextPage: cursor?.hasNextPage,
      hasPreviousPage: pageNumber > 0,
      fetchNextPage,
      fetchPreviousPage,
    }),
    [cursor?.hasNextPage, fetchNextPage, fetchPreviousPage, pageNumber]
  )

  const rowDefinition = useRowDefinition()

  const { openDetailView, closeDetailView } = useDetailView()

  const disableRecoverOption = !selectedIds.length

  useEffect(() => {
    if (selectedIds.length === 1) {
      openDetailView(DetailViewEnum.VehicleDetail, {
        id: composeVehicleId({ id: selectedIds[0], business_case_number: 0 }),
        onClose: () => setSelectedIds([]),
      })
    } else if (selectedIds.length !== 1) {
      closeDetailView(DetailViewEnum.VehicleDetail)
    }
  }, [closeDetailView, openDetailView, selectedIds])

  const [ResyncDialog, handleResync] = useResyncVehicleHistory(selectedIds)

  return (
    <L.Vertical spacing={4} className={props.className}>
      <Yalc2<VehicleItem>
        classes={{ item: 'group' }}
        selectedIds={selectedIds}
        onSelectionChanged={setSelectedIds}
        loading={loading}
        data={vehicles}
        rowDefinition={rowDefinition}
        paginationNavigation={pagination}
        options={
          <Menu.Item disabled={disableRecoverOption} onClick={handleResync}>
            Historie wiederherstellen <Icon name={'sync'} />
          </Menu.Item>
        }
      />
      <ResyncDialog />
    </L.Vertical>
  )
}

const useRowDefinition = (): Yalc2RowDefinition<VehicleItem> => {
  return useMemo(
    () => ({
      columns: [
        {
          width: 250,
          title: 'FIN',
          cell: function Vin(props) {
            return <Yalc2Cell.SimpleCell>{props.item.vin}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 100,
          title: 'Aktionen',
          cell: function Actions(props) {
            const [ResyncDialog, handleResync] = useResyncVehicleHistory([props.item.id])
            return (
              <Yalc2Cell.SimpleCell className={clsx('hidden items-end group-hover:flex')}>
                <IconButton
                  tooltip={'Historie wiederherstellen'}
                  onClick={async (evt) => {
                    evt.stopPropagation()
                    await handleResync()
                  }}
                  aria-label={'recover-vehicles-histories'}
                >
                  <Icon name={'sync'} />
                </IconButton>
                <ResyncDialog />
              </Yalc2Cell.SimpleCell>
            )
          },
        },
      ],
    }),
    []
  )
}
