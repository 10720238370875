import { Icon, IconButton, MaxWidthText, SiteMetadata } from '../../../../components'
import * as L from 'layouts'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Import_Jobs, useDeleteImportJobMutation, useVehicleImportJobsListSubscription } from 'gql'
import { useFormatter } from '../../../../hooks'
import { useTranslation } from 'react-i18next'
import { ImportJobStatusChip } from '../../../../containers/ImportJobStatusChip'
import clsx from 'clsx'
import { useEzAuthUserHasRoles, UserRole } from '@lib/ezauth'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { Yalc2, Yalc2RowDefinition } from 'components/Yalc2'
import { DetailViewEnum, useDetailView } from '../../../../components/DetailWindow/useDetailView'

type ImportJob = Pick<
  Import_Jobs,
  'id' | 'created_at' | 'status' | 'upload_file_name' | 'matching_field' | 'upload_file_excel_definition'
>

export const VehiclesImportJobs = () => {
  const vehicleImportJobsSubscription = useVehicleImportJobsListSubscription()
  const [deleteImportJob] = useDeleteImportJobMutation()
  const handleDeleteImportJob = async (id: string) => {
    await deleteImportJob({ variables: { id } })
  }
  const rowDef = useRowDefinition(handleDeleteImportJob)
  const importJobs = useMemo(() => vehicleImportJobsSubscription.data?.import_jobs, [
    vehicleImportJobsSubscription.data?.import_jobs,
  ])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const listRef = useRef<HTMLDivElement>()
  const detailRef = useRef<HTMLDivElement>()

  const handleClickOutsideSelection = useCallback(
    (evt: React.MouseEvent) => {
      if (selectedIds.length === 1) return
      const clickedInside = (ref: React.MutableRefObject<Element>, evt: React.MouseEvent) => {
        return ref.current?.contains(evt.target as any)
      }
      if (!clickedInside(listRef, evt) && !clickedInside(detailRef, evt) && document.activeElement.tagName === 'BODY') {
        setSelectedIds([])
      }
    },
    [selectedIds.length]
  )

  const { openDetailView } = useDetailView()
  useEffect(() => {
    if (selectedIds.length === 1) {
      openDetailView(DetailViewEnum.VehicleImportJobDetail, {
        onClose: () => setSelectedIds([]),
        id: selectedIds[0],
      })
    }
  }, [openDetailView, selectedIds])

  return (
    <>
      <SiteMetadata title="Excel Imports - Fahrzeuge" />
      <L.Vertical spacing={4}>
        <Yalc2<ImportJob>
          data={importJobs ?? []}
          rowDefinition={rowDef}
          selectedIds={selectedIds}
          onSelectionChanged={setSelectedIds}
          onClickOutsideList={handleClickOutsideSelection}
          classes={{ item: 'group' }}
        />
      </L.Vertical>
    </>
  )
}

const useRowDefinition = (onItemDelete: (id: string) => Promise<void>): Yalc2RowDefinition<ImportJob> => {
  const { formatDateTimeString } = useFormatter()
  const { t } = useTranslation()
  return useMemo(() => {
    return {
      columns: [
        {
          title: 'Erstellt am',
          width: 150,
          cell: function CreatedAtCell(props) {
            return <Yalc2Cell.SimpleCell>{formatDateTimeString(props.item.created_at)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          title: 'Status',
          width: 200,
          cell: function StateCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <ImportJobStatusChip state={props.item.status} />
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          title: 'Datei Name',
          width: 150,
          cell: function FileNameCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <MaxWidthText>{props.item.upload_file_name}</MaxWidthText>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          title: 'Zuordnungs Feld',
          width: 150,
          cell: function MatchingKey(props) {
            return <Yalc2Cell.SimpleCell>{t(`DealerVehicle.${props.item.matching_field}`)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          title: 'Datei Spalten',
          width: 300,
          cell: function DefinitionCell(props) {
            const cols = props.item.upload_file_excel_definition?.filter((col) => col !== 'EMPTY').join(', ')
            return (
              <Yalc2Cell.SimpleCell>
                <MaxWidthText>{cols}</MaxWidthText>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          title: '',
          width: 50,
          cell: function ActionsCell(props) {
            const isAdmin = useEzAuthUserHasRoles(UserRole.ADMIN)
            if (!isAdmin) return null
            return (
              <Yalc2Cell.SimpleCell className={clsx('hidden items-end group-hover:flex')}>
                <IconButton
                  aria-label={'delete import job'}
                  onClick={async (evt) => {
                    evt.stopPropagation()
                    await onItemDelete(props.item.id)
                  }}
                  size={'small'}
                  tooltip={'Details löschen'}
                  color={'primary'}
                >
                  <Icon name={'delete'} />
                </IconButton>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
      ],
    }
  }, [formatDateTimeString, onItemDelete, t])
}
