import React, { CSSProperties } from 'react'
import MuiAvatar from '@mui/material/Avatar'
import clsx from 'clsx'

export interface AvatarProps {
  size?: 'small' | 'default'
  alt: string
  className?: string
  style?: CSSProperties
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  return (
    <MuiAvatar
      className={clsx(props.size === 'small' && 'h-6 w-6', props.className)}
      alt={props.alt}
      style={props.style}
    >
      {props.children}
    </MuiAvatar>
  )
}
