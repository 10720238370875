import React, { useContext, useMemo } from 'react'
import * as L from 'layouts'
import { Button, Icon, IconButton, Select } from 'components'
import { ExcelImportContext } from '..'
import {
  Import_Job_Status_Enum,
  useDeleteVehicleImportTemplateMutation,
  useResetVehicleExcelAssignmentsMutation,
} from 'gql'
import { DetailViewEnum, useDetailView } from '../../../components/DetailWindow/useDetailView'

interface ChooseTemplateProps {
  onTemplateChange: (templateId?: string) => Promise<void>
  importTemplates?: {
    id: string
    name: string
  }[]
  i18nPrefix: string
  deleteImportTemplate: ReturnType<typeof useDeleteVehicleImportTemplateMutation>
  resetAssignments: ReturnType<typeof useResetVehicleExcelAssignmentsMutation>
}

export const ChooseTemplate = (props: ChooseTemplateProps) => {
  const { importJob, variant, state } = useContext(ExcelImportContext)

  const {openDetailView} = useDetailView()

  const [deleteImportTemplate] = props.deleteImportTemplate
  const [resetAssignments] = props.resetAssignments
  const parentTemplateId = useMemo(() => {
    switch (variant) {
      case 'vehicle':
        return importJob?.import_template?.parent_template?.id || ''
    }
  }, [importJob?.import_template?.parent_template?.id, variant])

  const detailViewProps = useMemo(
    () => ({
      onItemRemove: async (id: string) => await deleteImportTemplate({ variables: { id } }),
      importTemplates: props?.importTemplates,
      onChoose: async (id: string) => await props.onTemplateChange(id),
      i18nPrefix: props.i18nPrefix,
      variant
    }),
    [variant, deleteImportTemplate, props]
  )

  return (
    <>
      <L.Horizontal className="ml-4">
        <Select
          disabled={
            props.importTemplates?.length === 0 ||
            !props.importTemplates ||
            importJob?.status !== Import_Job_Status_Enum.InProgress
          }
          value={parentTemplateId}
          name={'importTemplateSelect'}
          onChange={(evt) => props.onTemplateChange(evt.target.value)}
          style={{ width: 200 }}
        >
          <Select.Option value={''}>{'-'}</Select.Option>
          {props.importTemplates?.map((template) => (
            <Select.Option key={template.id} value={template.id}>
              {template.name}
            </Select.Option>
          ))}
        </Select>
        <Button
          disabled={
            props.importTemplates?.length === 0 ||
            !props.importTemplates ||
            importJob?.status !== Import_Job_Status_Enum.InProgress
          }
          onClick={() => openDetailView(DetailViewEnum.ExcelImportTemplates, detailViewProps)}
        >
          Vorlagen verwalten
        </Button>
        <IconButton
          disabled={!importJob?.import_template?.parent_template}
          onClick={() => resetAssignments({ variables: { templateId: state.importTemplateId } })}
          aria-label={'Zurücksetzen'}
        >
          <Icon name="undo" />
        </IconButton>
      </L.Horizontal>
    </>
  )
}
