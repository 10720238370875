import React, { useCallback, useMemo, useState } from 'react'
import { LMChipProps, LMOptionsProps } from '../Filter/Chips'
import { HighlightsColorOptions } from '../../ColorOptions'
import { Vehicle_Bool_Exp } from '../../../../gql'
import { ListManipulation, useListManipilationContext } from 'containers/ListManipulation/unit'

const simpleOptions = [
  { label: '1 Tag', value: 1 },
  { label: '2 Tage', value: 2 },
  { label: '3 Tage', value: 3 },
  { label: '1 Woche', value: 7 },
  { label: '2 Wochen', value: 14 },
  { label: '1 Monat', value: 30 },
  { label: '3 Monate', value: 90 },
  { label: 'halbes Jahr', value: 182 },
  { label: '1 Jahr', value: 365 },
]

export const VehicleIntOptions = (props: LMOptionsProps<'Vehicle'> & { expressions?: Vehicle_Bool_Exp[] }) => {
  const { field, setField, parentExpression, leadingExpression } = useListManipilationContext<'Vehicle'>()
  const {
    getChildren,
    getStandalone,
    cleanSetChildren,
    cleanSetStandalones,
    deleteAllStandalones,
    setAlternativeExpression,
  } = props.operations
  const fieldLabel = useMemo(() => {
    switch (field) {
      case 'days_since_purchase':
        return 'Standtage'
      default:
        return ''
    }
  }, [field])

  const [operator, setOperator] = useState<'_lt' | '_gt'>()

  const handleClick = useCallback(
    (value: number) => () => {
      const expression = { [field]: { [operator]: value } }
      if (parentExpression) {
        cleanSetChildren([expression], parentExpression)
      } else if (leadingExpression) {
        deleteAllStandalones(field)
        setAlternativeExpression(expression, leadingExpression)
      } else {
        cleanSetStandalones([expression])
      }
    },
    [
      cleanSetChildren,
      cleanSetStandalones,
      deleteAllStandalones,
      field,
      leadingExpression,
      operator,
      parentExpression,
      setAlternativeExpression,
    ]
  )

  const expressions = useMemo(
    () =>
      props.expressions
        ? props.expressions
        : parentExpression
        ? getChildren(field, parentExpression, props.highlightIndex)
        : getStandalone(field, props.highlightIndex),
    [field, getChildren, getStandalone, parentExpression, props.expressions, props.highlightIndex]
  )
  const disabled = !operator

  return (
    <ListManipulation.UnitOptions label={fieldLabel}>
      <ListManipulation.RadioGroupOption
        value={operator}
        options={[
          { value: '_lt', label: 'Weniger als' },
          { value: '_gt', label: 'Mehr als' },
        ]}
        onChange={(evt) => setOperator(evt.currentTarget.value as '_lt' | '_gt')}
      />
      {simpleOptions.map((o) => (
        <ListManipulation.ItemOption onClick={handleClick(o.value)} disabled={disabled}>
          {o.label}
        </ListManipulation.ItemOption>
      ))}
      {props.type === 'highlight' && (
        <>
          <hr className={'text-divider'} />
          <ListManipulation.ItemOption disabled={!expressions.length} onClick={() => setField('color')}>
            Farbe auswählen
          </ListManipulation.ItemOption>
        </>
      )}
    </ListManipulation.UnitOptions>
  )
}

export const VehicleIntChip = (
  props: LMChipProps<'Vehicle'> & { field: 'days_since_purchase'; expressions?: Vehicle_Bool_Exp[] }
) => {
  const { field: intField } = props
  const { getStandalone, deleteAll, deleteExpressions } = props.operations
  const { setField, field } = useListManipilationContext<'Vehicle'>()
  const fieldLabel = useMemo(() => {
    switch (intField) {
      case 'days_since_purchase':
        return 'Standtage'
    }
  }, [intField])
  const expressions = useMemo(() => props.expressions ?? getStandalone(intField, props.highlightIndex), [
    props.expressions,
    props.highlightIndex,
    getStandalone,
    intField,
  ])
  const label = useMemo(() => {
    if (expressions.length === 1) {
      const operator = Object.keys(expressions[0][intField])[0]
      return `${operator === '_lt' ? '<' : operator === '_gt' ? '>' : ''} ${expressions[0][intField][operator]} Tage`
    } else {
      return ''
    }
  }, [expressions, intField])

  const handleDelete = useCallback(() => {
    props.expressions ? deleteExpressions(props.expressions) : deleteAll(intField, props.highlightIndex)
  }, [deleteAll, deleteExpressions, intField, props.expressions, props.highlightIndex])
  if (!Boolean(expressions.length)) return null
  return (
    <ListManipulation.Group variant={props.color ? 'filled' : 'outlined'} color={props.color} onDelete={handleDelete}>
      <ListManipulation.GroupUnit
        label={`${fieldLabel}: ${label}`}
        onClick={() => {
          props.onClick && props.onClick()
          setField(intField)
        }}
      >
        {field !== 'color' && (
          <VehicleIntOptions
            type={props.type === 'highlight' ? 'highlight' : 'filter'}
            operations={props.operations}
            highlightIndex={props.highlightIndex}
          />
        )}
        {field === 'color' && <HighlightsColorOptions />}
      </ListManipulation.GroupUnit>
    </ListManipulation.Group>
  )
}
