import faker from 'faker/locale/de'
import { Brand, ModelGroup } from 'gql'
import { randomList } from 'mocks/common'

const defaultBrands: Pick<Brand, 'id' | 'name'>[] = [
  {
    id: faker.random.uuid(),
    name: 'Audi',
  },
  {
    id: faker.random.uuid(),
    name: 'Citroën',
  },
  {
    id: faker.random.uuid(),
    name: 'Hyundai',
  },
  {
    id: faker.random.uuid(),
    name: 'Mazda',
  },
  {
    id: faker.random.uuid(),
    name: 'Mercedes Benz',
  },
  {
    id: faker.random.uuid(),
    name: 'Mitsubishi',
  },
  {
    id: faker.random.uuid(),
    name: 'Opel',
  },
  {
    id: faker.random.uuid(),
    name: 'Peugeot',
  },
  {
    id: faker.random.uuid(),
    name: 'Smart',
  },
  {
    id: faker.random.uuid(),
    name: 'Volkswagen',
  },
]

const createVehicleModelGroup = (
  brand: Pick<Brand, 'id' | 'name'>
): Pick<ModelGroup, 'id' | 'name' | 'brand_id' | 'releaseYears'> => {
  const id = faker.random.uuid()
  return {
    id,
    name: faker.lorem.word(),
    brand_id: brand.id,
    releaseYears: [{ model_group_id: id, release_year: 2018 }],
  }
}

const defaultModelGroups: ModelGroup[] = [].concat.apply(
  [],
  defaultBrands.map((brand) => randomList(5, 10, () => createVehicleModelGroup(brand)))
)
export class BrandStore {
  public static brands = defaultBrands
  public static vehicleModelGroups = defaultModelGroups
  public static vehicleModels = []
}

export const mocks = {}
export const resovlers = {}
