import {
  Dealer_Vehicle_State_Enum,
  Dealer_Vehicle_Type_Enum,
  ImportJobSubscription,
  Order_State_Enum,
  Registration_State_Enum,
  Vehicle,
} from 'gql'
import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Assignments, AssignmentsProps } from './assignments'
import { ChooseFile, ChooseFileProps } from './choose-file'
import { ExcelImportLogs, ExcelImportLogsProps } from './logs'

interface DVImportPreviewListItem {
  rowNumber: number
  id: string
  vin: string
  job_number: string
  type: Dealer_Vehicle_Type_Enum
  state: Dealer_Vehicle_State_Enum
  order_state: Order_State_Enum
  sold_at: string
  customer: string
  min_holding_period: number
  key_kabinet_number: string
  registration_owner: string
  registration_state: Registration_State_Enum
  delivery_date_str: string
  specs: string
  spec_level: string
}

export type ExcelImportEntity = Vehicle
export type ExcelImportListItem = DVImportPreviewListItem

type Reset = {
  type: 'reset'
}

type Importing = {
  type: 'importing'
}

type ChangeLogTab = {
  type: 'change-log-tab'
  payload: { idx: number }
}

type InitializedSavingTemplate = {
  type: 'template:init_saving'
}

type SavingTemplate = {
  type: 'template:saving'
}

type SavedTemplate = {
  type: 'template:saved'
  payload: { id: string }
}

type OverwritingTemplate = {
  type: 'template:overwriting'
}

type CancelledSavingTemplate = {
  type: 'template:cancelled_saving'
}

type ImportReducerAction =
  | Reset
  | Importing
  | ChangeLogTab
  | SavingTemplate
  | SavedTemplate
  | OverwritingTemplate
  | CancelledSavingTemplate
  | InitializedSavingTemplate

export enum LogType {
  'VALIDATION_ERROR',
  'UPDATE_ERROR',
}

export interface ImportState {
  importTemplateId: string
  activeLogTab: number
  templateSaving: boolean
  templateLoading: boolean
  templateOverwriting: boolean
  importJobId: string
}

const importReducer = (state: ImportState, action: ImportReducerAction): ImportState => {
  switch (action.type) {
    case 'template:init_saving':
      return {
        ...state,
        templateSaving: true,
      }
    case 'template:cancelled_saving':
      return {
        ...state,
        templateLoading: false,
        templateOverwriting: false,
        templateSaving: false,
      }
    case 'template:saving':
      return {
        ...state,
        templateLoading: true,
      }
    case 'template:saved':
      return {
        ...state,
        templateLoading: false,
        templateOverwriting: false,
        templateSaving: false,
        importTemplateId: action.payload.id,
      }
    case 'template:overwriting':
      return {
        ...state,
        templateLoading: true,
        templateOverwriting: true,
      }
    case 'reset':
      return { ...initialImportState, importJobId: uuid(), importTemplateId: uuid() }
    case 'importing':
      return {
        ...state,
        activeLogTab: 1,
      }
    case 'change-log-tab':
      return {
        ...state,
        activeLogTab: action.payload.idx,
      }
  }
}

interface ExcelImportContextProps {
  state: ImportState
  dispatch: React.Dispatch<ImportReducerAction>
  importJob: ImportJobSubscription['import_jobs_by_pk']
  setImportJob: React.Dispatch<React.SetStateAction<ImportJobSubscription['import_jobs_by_pk']>>
  variant: ExcelImportType | undefined
  setVariant: React.Dispatch<React.SetStateAction<ExcelImportType>>
}

const initialImportState: ImportState = {
  importTemplateId: uuid(),
  activeLogTab: 0,
  templateSaving: false,
  templateLoading: false,
  templateOverwriting: false,
  importJobId: uuid(),
}

export const ExcelImportContext = React.createContext<ExcelImportContextProps>({
  state: initialImportState,
  dispatch: () => {},
  variant: undefined,
  setVariant: () => {},
  importJob: undefined,
  setImportJob: () => {},
})

interface ExcelImportComponents {
  ChooseFile: React.FC<ChooseFileProps>
  Assignments: React.FC<AssignmentsProps>
  Logs: React.FC<ExcelImportLogsProps>
}

export const ExcelImport: React.FC & ExcelImportComponents = ({ children }) => {
  const [state, dispatch] = React.useReducer(importReducer, initialImportState)

  const [importJob, setImportJob] = useState<ImportJobSubscription['import_jobs_by_pk']>()
  const [variant, setVariant] = useState<ExcelImportType>()

  return (
    <ExcelImportContext.Provider value={{ state, dispatch, importJob, setImportJob, variant, setVariant }}>
      {children}
    </ExcelImportContext.Provider>
  )
}

ExcelImport.ChooseFile = ChooseFile
ExcelImport.Assignments = Assignments
ExcelImport.Logs = ExcelImportLogs

export type ExcelImportType = 'vehicle'

export const withExcelImportProvider = <P extends { variant?: ExcelImportType }>(
  Component: React.ComponentType<P>,
  variant: ExcelImportType
) => {
  return (props: P) => (
    <ExcelImport>
      <Component {...props} variant={variant} />
    </ExcelImport>
  )
}
