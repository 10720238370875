import React, { useEffect, useState } from 'react'
import { LoginShell } from './login'
import { newPasswordSchema } from './login/NewPwForm'
import { useForgotPassword } from '../services/auth/auth-utils'
import { AlertBox, Button, Form, Link, TextField, Typo, useAlert } from '../components'
import * as L from 'layouts'
import { useAsync, useFormFields } from '@w2solutions/react-hooks'
import useYup from '@w2solutions/use-yup'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { ezAuthEventHub, EzAuthEventType, useEzAuth } from '@lib/ezauth'

export const ForgotPasswordPage = () => {
  const [, authFn] = useEzAuth()
  const [forgotPassword, { loading, error }] = useAsync(authFn.forgotPassword)
  const [requestSubmitted, forgotPwResponse] = useForgotPassword()

  const [validationError, setValidationError] = useState<string | null>(null)

  const { bindings, values } = useFormFields({ username: '', otp: '', newPassword: '', newPasswordRepeat: '' })
  const validation = useYup(values, validationSchema)

  const history = useHistory()
  const [alert] = useAlert()

  useEffect(() => {
    ezAuthEventHub.subscribe((event) => {
      switch (event.type) {
        case EzAuthEventType.PASSWORD_RESET:
          history.push('/')
          alert.success('Das Passwort wurde erfolgreich geändert')
      }
    })
  }, [alert, history])

  const handleStartProcess = async () => {
    forgotPassword(values.username)
  }

  const handleSubmit = async () => {
    if (values.newPassword !== values.newPasswordRepeat) {
      setValidationError('Passwörter müssen identisch sein.')
      return
    }
    const { isValid, errors } = await validation.validate()

    if (!isValid) {
      setValidationError(errors.newPassword || errors.otp)
      return
    }

    setValidationError(null)
    forgotPwResponse.resolve({ newPassword: values.newPassword, otp: values.otp })
  }

  return (
    <LoginShell>
      <L.Vertical spacing={6}>
        {error && (
          <AlertBox type="error" noTitle style={{ width: '100%' }}>
            {error.message}
          </AlertBox>
        )}
        {validationError && (
          <AlertBox type="error" noTitle style={{ width: '100%' }}>
            {validationError}
          </AlertBox>
        )}
        {!requestSubmitted && (
          <>
            <div>
              <Typo variant="h3">Passwort vergessen?</Typo>
              <Typo variant="subtitle1">Gib deine Email Adresse unten ein</Typo>
            </div>
            <Form onSubmit={handleStartProcess}>
              <L.Vertical spacing={2}>
                <TextField label="E-Mail" {...bindings.username} disabled={loading} />
                <Button variant="contained" color="primary" type="submit" loading={loading}>
                  Übermitteln
                </Button>
              </L.Vertical>
            </Form>
          </>
        )}
        {requestSubmitted && (
          <>
            <div>
              <Typo variant="h3">Neues Passwort festlegen</Typo>
              <Typo variant="subtitle1">
                Gib den Code, den du auf deinem E-mail Postfach findest, und wähle das neue Passwort
              </Typo>
            </div>
            <Form onSubmit={handleSubmit}>
              <L.Vertical spacing={2}>
                <TextField label="Code" {...bindings.otp} />
                <TextField label="Neues Passwort" type="password" {...bindings.newPassword} />
                <TextField label="Wiederhole Passwort" type="password" {...bindings.newPasswordRepeat} />
                <Button variant="contained" color="primary" type="submit">
                  Bestätigen
                </Button>
              </L.Vertical>
            </Form>
          </>
        )}
        <Link to="/">Zurück</Link>
      </L.Vertical>
    </LoginShell>
  )
}

const validationSchema = yup.object({
  newPassword: newPasswordSchema,
  otp: yup.string().required().min(6).max(6),
})
