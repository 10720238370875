import {
  isBefore,
  isAfter,
  format,
  formatISO,
  setYear,
  setMonth,
  setDate,
  formatDistance,
  startOfMonth,
  endOfMonth,
  addMonths,
  addYears,
  addDays,
  getMonth,
  getYear,
  addMilliseconds,
  subHours,
  subMinutes,
  isSameDay,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  addWeeks,
  startOfDay,
} from 'date-fns'

import { de } from 'date-fns/locale'

type Inclusivity = '()' | '[)' | '[]' | '(]'

class DateFnsImpl {
  private locale = de
  private defaultOptions = {
    locale: this.locale,
  }

  get monthNames() {
    return new Array(12).fill(1).map((_, index) => this.locale.localize.month(index))
  }

  get monthShortNames() {
    return new Array(12).fill(1).map((_, index) => this.locale.localize.month(index, { width: 'abbreviated' }))
  }

  public isBefore(date: Date, dateToCompare: Date) {
    return isBefore(date, dateToCompare)
  }

  public isAfter(date: Date, dateToCompare: Date) {
    return isAfter(date, dateToCompare)
  }

  public addDays(date: Date, amount: number) {
    return addDays(date, amount)
  }

  public addMonths(date: Date, amount: number) {
    return addMonths(date, amount)
  }

  public addYears(date: Date, amount: number) {
    return addYears(date, amount)
  }

  public addMilliseconds(date: Date, amount: number) {
    return addMilliseconds(date, amount)
  }

  public formatDistance(from: Date, to: Date) {
    return formatDistance(from, to, this.defaultOptions)
  }

  public setYear(date: Date, year: number) {
    return setYear(date, year)
  }

  public setMonth(date: Date, month: number) {
    return setMonth(date, month)
  }

  public setDate(date: Date, dayOfMonth: number) {
    return setDate(date, dayOfMonth)
  }

  public startOfMonth(date: Date) {
    return startOfMonth(date)
  }

  public startOfDay(date: Date) {
    return startOfDay(date)
  }
  public endOfMonth(date: Date) {
    return endOfMonth(date)
  }

  public format(date: Date, formatString: string) {
    return format(date, formatString, this.defaultOptions)
  }

  public withoutTimezoneOffset(date: Date): Date {
    const offset = date.getTimezoneOffset() * 60000
    return new Date(date.getTime() - offset)
  }

  public serializeDate(date: Date) {
    return formatISO(date, { representation: 'date' })
  }

  public serializeDateTime(date: Date) {
    return formatISO(date)
  }

  public getMonth(date: Date) {
    return getMonth(date)
  }

  public getMonthName(date: Date) {
    const index = getMonth(date)
    return this.locale.localize.month(index)
  }

  public getYear(date: Date) {
    return getYear(date)
  }

  public isBetween(date: Date, from: Date, to: Date, inclusivity: Inclusivity = '()') {
    let fromDate = from
    let toDate = to

    if (inclusivity[0] === '[') {
      fromDate = this.addMilliseconds(from, -1)
    }
    if (inclusivity[1] === ']') {
      toDate = this.addMilliseconds(to, 1)
    }

    return isAfter(date, fromDate) && isBefore(date, toDate)
  }

  public subMinutes(date: Date, amount: number) {
    return subMinutes(date, amount)
  }

  public subHours(date: Date, amount: number) {
    return subHours(date, amount)
  }

  public subDays(date: Date, amount: number) {
    return subDays(date, amount)
  }

  public isSameDay(dateA: Date, dateB: Date) {
    return isSameDay(dateA, dateB)
  }

  public subWeeks(date: Date, amount: number) {
    return subWeeks(date, amount)
  }

  public addWeeks(date: Date, amount: number) {
    return addWeeks(date, amount)
  }

  public subMonths(date: Date, amount: number) {
    return subMonths(date, amount)
  }

  public subYears(date: Date, amount: number) {
    return subYears(date, amount)
  }

  public differenceInDays(dateA: Date, dateB: Date) {
    return differenceInDays(dateA, dateB)
  }

  public differenceInWeeks(dateA: Date, dateB: Date) {
    return differenceInWeeks(dateA, dateB)
  }

  public differenceInMonths(dateA: Date, dateB: Date) {
    return differenceInMonths(dateA, dateB)
  }
}

export const DateFns = new DateFnsImpl()
