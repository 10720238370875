import {
  useUpdateVehiclesMutation,
  Dealer_Vehicle_State_Enum,
  useVehicleDetailsSubscription,
  Order_State_Enum,
  UpdateVehiclesMutationVariables,
  useVehicleMetafieldsLazyQuery,
  useImportBusinessCasesMutation,
  Import_Type_Enum,
  useUpdateModelsMutation,
} from 'gql'
import React, { useEffect, useMemo, useState } from 'react'
import { Button } from 'components'
import { useVehicleValidationSchema, VehicleDetail, VehicleFormData, VehicleFormValues } from 'containers/VehicleForm'
import { mergeDeepLeft } from 'ramda'
import { ErrorBlock } from 'components/ErrorBlock'
import { useEzAuthUserHasRoles, UserRole } from '@lib/ezauth'
import { SplitButton } from 'components/SplitButton'
import { GeneralData } from 'containers/VehicleForm/01_GeneralData'
import { HistoryData } from 'containers/VehicleForm/04_HistoryData'
import { splitVehicleId } from '../List'
import { useVehiclesStore } from '../hooks/useData'
import { useGroupName } from '../../../ListManipulation/Vehicles/GroupBy/Options'
import { TicketsData } from 'containers/VehicleForm/07_TicketsData'
import { PricesData } from '../../../VehicleForm/03_PricesData'

interface DealerVehicleImportDetailViewProps {
  inboxItemId: string
  onClose: () => void
}

const importDefaultValues: Partial<VehicleFormData> = {
  state: Dealer_Vehicle_State_Enum.Available,
  order_state: Order_State_Enum.InTransport,
  notes: [],
}

export const DealerVehicleImportDetailView = React.forwardRef<HTMLDivElement, DealerVehicleImportDetailViewProps>(
  (props, ref) => {
    const [splitButtonIndex, setSlitButtonIndex] = useState<0 | 1>(0)
    const { remove: removeFromVehiclesState } = useVehiclesStore()
    const { render, iteratee } = useGroupName()

    const importType = useMemo(() => {
      return splitButtonIndex === 0 ? Import_Type_Enum.Full : Import_Type_Enum.Partial
    }, [splitButtonIndex])
    const [vehicleId, version, business_case_number]: any[] = splitVehicleId(props.inboxItemId)
    const query = useVehicleDetailsSubscription({ variables: { id: vehicleId, business_case_number } })
    const [metafieldsQuery, metafieldsResponse] = useVehicleMetafieldsLazyQuery()

    useEffect(() => {
      if (!query.data) return
      metafieldsQuery({ variables: { brandId: query.data.vehicle.brand.id } })
    }, [metafieldsQuery, query.data])

    const id = query.data?.vehicle?.id

    useEffect(() => {
      setSlitButtonIndex(0)
    }, [id])

    const data: any = useMemo(() => {
      if (!query.data?.vehicle) {
        return null
      }
      return mergeDeepLeft(query.data?.vehicle ?? {}, importDefaultValues)
    }, [query.data?.vehicle])

    const [update, updateRes] = useUpdateVehiclesMutation()
    const [updateModels, updateModelsRes] = useUpdateModelsMutation()

    const handleUpdate = async (data: VehicleFormValues) => {
      const set = formToInput(data)
      if (!id) {
        throw new Error('no valid id to update data')
      }
      await update({
        variables: {
          set,
          where: { id: { _eq: id }, version: { _eq: 0 }, business_case_number: { _eq: 0 } },
        },
      })
      if (data.model?.sales_model_code && data.model?.model_group_release_year && data.model?.modelGroup?.id) {
        await updateModels({
          variables: {
            set: {
              sales_model_code: data.model?.sales_model_code,
              model_group_id: data.model?.modelGroup?.id,
              model_group_release_year: data.model?.model_group_release_year,
            },
            where: {
              id: { _eq: data.model.id },
            },
          },
        })
      }
    }

    const { isValid: isFullImportValid, errors: fullImportErrors } = useVehicleValidationSchema(
      data,
      data?.brand?.id,
      { metafields: metafieldsResponse.data?.brand_vehicle_metafield },
      { autoRun: true }
    )

    const { isValid: isPartialImportValid, errors: partialImportErrors } = useVehicleValidationSchema(
      data,
      data?.brand?.id,
      { metafields: metafieldsResponse.data?.brand_vehicle_metafield, importType: Import_Type_Enum.Partial },
      { autoRun: true }
    )

    const errors = useMemo(() => {
      return importType === Import_Type_Enum.Full ? fullImportErrors : partialImportErrors
    }, [fullImportErrors, importType, partialImportErrors])

    const [importBCs, importBCsRes] = useImportBusinessCasesMutation({
      onCompleted: () => {
        removeFromVehiclesState([props.inboxItemId], render, iteratee)
      },
    })
    const handleImport = async () => {
      if (!id) {
        throw new Error('no valid id to import')
      }
      const isValid = importType === Import_Type_Enum.Full ? isFullImportValid : isPartialImportValid
      if (!isValid) {
        throw new Error('unable to import invalid vehicle')
      }
      await importBCs({
        variables: {
          ids: [id],
          imported: new Date(),
          importType,
        },
        update: (cache) => {
          const normalizedId = cache.identify({ id, version, business_case_number, __typename: 'Vehicle' })
          cache.evict({ id: normalizedId })
          cache.gc()
        },
      })
      props.onClose()
    }

    const isAdmin = useEzAuthUserHasRoles(UserRole.ADMIN)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const loading = query.loading || updateRes.loading || updateModelsRes.loading || importBCsRes.loading
    return (
      <VehicleDetail
        ref={ref}
        onClose={props.onClose}
        loading={loading}
        data={data}
        metadata={{ metafields: metafieldsResponse.data?.brand_vehicle_metafield, importType }}
        panes={{
          general: (
            <GeneralData
              onSave={handleUpdate}
              disableValidationOnSave
              customActions={
                <>
                  <ErrorBlock errors={Object.values(errors).map(String)} />
                  {isAdmin ? (
                    <SplitButton
                      aria-label="import vehicle"
                      variant="contained"
                      options={['Import', 'Teil-Import']}
                      onClick={handleImport}
                      selectedIndex={splitButtonIndex}
                      setSelectedIndex={setSlitButtonIndex}
                      disabledOptions={[!isFullImportValid, !isPartialImportValid]}
                    />
                  ) : (
                    <Button variant="contained" color="primary" onClick={handleImport} disabled={!isFullImportValid}>
                      Import
                    </Button>
                  )}
                </>
              }
            />
          ),
          prices: <PricesData />,
          history: <HistoryData />,
          tickets: <TicketsData />,
        }}
      />
    )
  }
)

type UpdateOp = UpdateVehiclesMutationVariables['set']

// TODO: use mapVehicleFormValues fn
const formToInput = (data: VehicleFormValues): UpdateOp => {
  const update: UpdateOp = {}
  setDefined(update, 'state', data.state)
  setDefined(update, 'order_state', data.order_state)
  setDefined(update, 'delivery_location_id', data.delivery_location_id)
  setDefined(update, 'storage_location_id', data.storage_location_id)
  setDefined(update, 'storage_location_note', data.storage_location_note)
  setDefined(update, 'delivery_date_str', data.delivery_date_str)
  setDefined(update, 'type', data.type)
  setDefined(update, 'min_holding_period', data.min_holding_period)
  setDefined(update, 'registration_state', data.registration_state)
  setDefined(update, 'registration_owner', data.registration_owner)
  setDefined(update, 'key_kabinet_number', data.key_kabinet_number)
  setDefined(update, 'sold_at', data.sold_at)
  setDefined(update, 'sold_by_id', data.sold_by_id)
  setDefined(update, 'reserved_by_id', data.reserved_by_id)
  setDefined(update, 'reserved_at', data.reserved_at)
  setDefined(update, 'reserved_until', data.reserved_until)
  setDefined(update, 'reserved_for', data.reserved_for)
  setDefined(update, 'reserved_for_customer_type', data.reserved_for_customer_type)
  setDefined(update, 'customer', data.customer)
  setDefined(update, 'customer_type', data.customer_type)
  setDefined(update, 'handover_date', data.handover_date)
  setDefined(update, 'handover_planned_date', data.handover_planned_date)
  setDefined(update, 'handover_by_id', data.handover_by_id)
  setDefined(update, 'order_code', data.order_code)
  setDefined(update, 'accessories_mounted', data.accessories_mounted)
  setDefined(update, 'control_number', data.control_number)
  setDefined(update, 'specs', data.specs)
  setDefined(update, 'spec_level', data.spec_level)
  setDefined(update, 'in_buy_channel', data.in_buy_channel)
  setDefined(update, 'purchased_by_id', data.purchased_by_id)
  setDefined(update, 'in_buy_contract_date', data.in_buy_contract_date)
  setDefined(update, 'keys_count', data.keys_count)
  setDefined(update, 'co2_nefz', data.co2_nefz)
  setDefined(update, 'co2_wltp', data.co2_wltp)
  setDefined(update, 'seller_location_id', data.seller_location_id)
  return update
}

const setDefined = <T extends {}, K extends keyof T>(target: T, key: K, value: T[K]) => {
  value !== null && (target[key] = value || null)
}
