import React, { useCallback, useMemo, useState } from 'react'
import { useEzAuth, UserRole } from '@lib/ezauth'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import {
  TicketNotesSubscriptionResult,
  useAddTicketNoteMutation,
  useDeleteTicketNoteMutation,
  useTicketNotesSubscription,
} from 'gql/generated'
import { Card } from 'components/Card'
import * as L from 'layouts'
import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { useFormatter } from 'hooks/useFormatter'
import { Typo } from 'components/Typo'
import { IconButton } from 'components/IconButton'
import { TextField } from 'components/TextField'
import Tooltip from 'components/Tooltip'

interface TicketNotesProps {
  ticketId: string
}

export const TicketNotes = (props: TicketNotesProps) => {
  const notesSubscription = useTicketNotesSubscription({ variables: { ticketId: props.ticketId } })
  const [addNote, addNoteResponse] = useAddTicketNoteMutation()
  const [deleteNote] = useDeleteTicketNoteMutation()

  const [authData] = useEzAuth()

  const notes = useMemo(() => notesSubscription.data?.ticket_notes ?? [], [notesSubscription.data?.ticket_notes])
  const [note, setNote] = useState('')

  const handleAddNote = async () => {
    await addNote({
      variables: {
        note: {
          ticket_id: props.ticketId,
          user_id: authData.user.id,
          note,
        },
      },
    })
    setNote('')
  }
  const handleDeleteNote = async (id: string) => {
    await deleteNote({ variables: { id } })
  }

  return (
    <>
      <Timeline position={'right'}>
        {notes.map((note) => (
          <Note note={note} onDelete={() => handleDeleteNote(note.id)}></Note>
        ))}
        {notes.length === 0 && <div className="text-center">Keine Notizen verfügbar.</div>}
      </Timeline>
      <Card className="flex flex-col">
        <L.Horizontal className="flex-1" spacing={0}>
          <TextField
            multiline
            variant="outlined"
            className="flex-1"
            value={note}
            onChange={(evt) => setNote(evt.currentTarget.value)}
          />
        </L.Horizontal>
      </Card>
      <Button
        className="float-right mt-2"
        variant="contained"
        color="primary"
        endIcon={<Icon name="send" />}
        onClick={handleAddNote}
        loading={addNoteResponse.loading}
      >
        Senden
      </Button>
    </>
  )
}

interface NoteProps {
  note: ArrayElement<TicketNotesSubscriptionResult['data']['ticket_notes']>
  onDelete: () => void
}

const Note = (props: NoteProps) => {
  const { note } = props
  const { formatDateTimeString } = useFormatter()

  const [authState] = useEzAuth()
  const isAdmin = authState.user.roles.includes(UserRole.ADMIN)

  const canDelete = useCallback(
    (note: ArrayElement<TicketNotesSubscriptionResult['data']['ticket_notes']>) => {
      return authState.user.id === note.user_id || isAdmin
    },
    [authState.user.id, isAdmin]
  )

  return (
    <TimelineItem position={'right'} sx={{ ':before': { content: 'none' }, minHeight: '70px' }} className="group">
      <TimelineSeparator style={{ minWidth: 30 }}>
        <TimelineDot className="self-center" />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <div className="flex flex-row">
          <div className="flex-1">
            <Typo variant={'body1'}>{note.note}</Typo>
            <Typo variant={'body2'}>
              {formatDateTimeString(note.created_at)}
              {' - '}
              {note.user?.full_name ||
                `${note.user?.abbreviation ? `${note.user?.abbreviation}, ` : ''}${note.user?.email ?? ''}`}
            </Typo>
          </div>
          <div className={'flex item-center justify-end'} style={{ minWidth: 85 }}>
            <Tooltip content="Löschen">
              {canDelete(note) && (
                <IconButton aria-label="delete" size="small" onClick={props.onDelete}>
                  <Icon name="delete" fontSize="small" />
                </IconButton>
              )}
            </Tooltip>
          </div>
        </div>
      </TimelineContent>
    </TimelineItem>
  )
}
