import { SiteMetadata, Typo } from 'components'
import { AdminMonitoringFilter } from 'containers/AdminMonitoring/MonitoringFilter'
import * as L from 'layouts'
import React from 'react'

export const MonitoringLocosoftSyncPage = () => {
  return (
    <AdminMonitoringFilter>
      <SiteMetadata title="Monitoring - Locosoft Synchronisation" />
      <L.Vertical spacing={4}>
        <L.Horizontal style={{ alignItems: 'start' }} spacing={4}>
          <L.Vertical style={{ width: '30%', minWidth: '400px' }} spacing={2}>
            <Typo variant="h2" decorator>
              {'Synchonisationsvorgänge für'}
            </Typo>
            <AdminMonitoringFilter.EntitiesList />
            <AdminMonitoringFilter.ProgressCard />
          </L.Vertical>
          <AdminMonitoringFilter.DataPlot />
        </L.Horizontal>
        <AdminMonitoringFilter.LogEntries />
      </L.Vertical>
    </AdminMonitoringFilter>
  )
}
