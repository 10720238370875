import React from 'react'
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox'

export interface CheckboxProps {
  checked?: boolean
  onChange?: MuiCheckboxProps['onChange']
  disabled?: boolean
  name?: string
  size?: 'medium' | 'small'
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  return <MuiCheckbox {...props} />
}
