import { useCallback, useMemo, useState } from 'react'
import { useStorageLocationSelectDataQuery, Vehicle_Bool_Exp } from '../../../../gql'
import React from 'react'
import { Typo } from '../../../../components'
import { LMChipProps, LMOptionsProps } from '../Filter/Chips'
import { HighlightsColorOptions } from '../../ColorOptions'
import { ListManipulation, useListManipilationContext } from '../../unit'

export const VehicleLocationOptions = (props: LMOptionsProps<'Vehicle'> & { expressions?: Vehicle_Bool_Exp[] }) => {
  const { field, setField, leadingExpression } = useListManipilationContext<'Vehicle'>()
  const { setStandalone, setAlternativeExpression, deleteStandalone, getStandalone } = props.operations
  const storageLocationsQuery = useStorageLocationSelectDataQuery()

  const locations = useMemo(() => {
    if (field === 'delivery_location_id' || field === 'storage_location_id') {
      return storageLocationsQuery?.data?.storage_locations ?? []
    } else if (field === 'dms_assigned_location_id' || field === 'seller_location_id') {
      return storageLocationsQuery?.data?.locations ?? []
    }
  }, [field, storageLocationsQuery?.data?.locations, storageLocationsQuery?.data?.storage_locations])
  const [searchQuery, setSearchQuery] = useState('')

  const { fieldLabel, nullOptionValue } = useMemo(() => {
    switch (field) {
      case 'delivery_location_id':
        return { fieldLabel: 'Lieferort', nullOptionValue: 'Kein Lieferort' }
      case 'storage_location_id':
        return { fieldLabel: 'Lagerort', nullOptionValue: 'Kein Lagerort' }
      case 'dms_assigned_location_id':
        return { fieldLabel: 'Betreuende Betriebsstätte', nullOptionValue: 'Keine Betriebsstätte' }
      case 'seller_location_id':
        return { fieldLabel: 'Verkaufende Betriebsstätte', nullOptionValue: 'Keine Betriebsstätte' }
      default:
        return { fieldLabel: '', nullOptionValue: '' }
    }
  }, [field])

  const expressions = useMemo(() => props.expressions ?? getStandalone(field, props.highlightIndex), [
    field,
    getStandalone,
    props.expressions,
    props.highlightIndex,
  ])
  const checked = useCallback((locationId: string) => expressions?.some((s) => s?.[field]?._eq === locationId), [
    expressions,
    field,
  ])
  const checkedNull = useMemo(() => expressions?.some((exp) => exp?.[field]?._is_null), [expressions, field])
  const handleChange = useCallback(
    (_eq: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) {
        deleteStandalone({ [field]: { _eq } })
      }
      if (checked) {
        if (leadingExpression) {
          setAlternativeExpression({ [field]: { _eq } }, leadingExpression)
        } else {
          setStandalone({ [field]: { _eq } })
        }
      }
    },
    [deleteStandalone, field, leadingExpression, setAlternativeExpression, setStandalone]
  )
  const handleChangeNull = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const expression = { [field]: { _is_null: true } }
      if (!checked) {
        deleteStandalone(expression)
      }
      if (checked) {
        if (leadingExpression) {
          setAlternativeExpression(expression, leadingExpression)
        } else {
          setStandalone(expression)
        }
      }
    },
    [deleteStandalone, field, leadingExpression, setAlternativeExpression, setStandalone]
  )

  return (
    <ListManipulation.UnitOptions label={fieldLabel}>
      <ListManipulation.InputOption
        value={searchQuery}
        placeholder={fieldLabel}
        onChange={(evt) => setSearchQuery(evt.currentTarget.value)}
      />
      <hr className={'text-divider'} />
      {locations
        // @ts-ignore
        .filter((sl) => (searchQuery ? sl.name.toLowerCase().includes(searchQuery.toLowerCase()) : true))
        .map((sl) => (
          <ListManipulation.CheckableOption checked={checked(sl.id)} onChange={handleChange(sl.id)}>
            <Typo variant={'body1'} className={'pr-4'}>
              {sl.name}
            </Typo>
          </ListManipulation.CheckableOption>
        ))}
      <hr className={'text-divider'} />
      <ListManipulation.CheckableOption checked={checkedNull} onChange={handleChangeNull}>
        <Typo variant={'body1'} className={'pr-4'}>
          {nullOptionValue}
        </Typo>
      </ListManipulation.CheckableOption>
      {props.type === 'highlight' && (
        <>
          <hr className={'text-divider'} />
          <ListManipulation.ItemOption disabled={!expressions.length} onClick={() => setField('color')}>
            Farbe auswählen
          </ListManipulation.ItemOption>
        </>
      )}
    </ListManipulation.UnitOptions>
  )
}

export const VehicleLocationChip = (
  props: LMChipProps<'Vehicle'> & {
    field: 'storage_location_id' | 'delivery_location_id' | 'dms_assigned_location_id' | 'seller_location_id'
    expressions?: Vehicle_Bool_Exp[]
  }
) => {
  const { field: locationField } = props
  const { getAll, deleteAll, deleteExpressions } = props.operations
  const { setField, field } = useListManipilationContext<'Vehicle'>()

  const storageLocationsQuery = useStorageLocationSelectDataQuery()

  const locations = useMemo(() => {
    if (locationField === 'delivery_location_id' || locationField === 'storage_location_id') {
      return storageLocationsQuery?.data?.storage_locations ?? []
    } else if (locationField === 'dms_assigned_location_id' || locationField === 'seller_location_id') {
      return storageLocationsQuery?.data?.locations ?? []
    }
  }, [locationField, storageLocationsQuery?.data?.locations, storageLocationsQuery?.data?.storage_locations])

  const { fieldLabelPlural, fieldLabel, nullOptionValue } = useMemo(() => {
    switch (locationField) {
      case 'storage_location_id':
        return { fieldLabel: 'Lagerort', fieldLabelPlural: 'Lagerorte', nullOptionValue: 'Kein Lagerort' }
      case 'delivery_location_id':
        return { fieldLabel: 'Lieferort', fieldLabelPlural: 'Lieferorte', nullOptionValue: 'Kein Lieferort' }
      case 'dms_assigned_location_id':
        return {
          fieldLabel: 'Betreuende Betriebsstätte',
          fieldLabelPlural: 'Betreuende Betriebsstätten',
          nullOptionValue: 'Keine Betriebsstätte',
        }
      case 'seller_location_id':
        return {
          fieldLabel: 'Verkaufende Betriebsstätte',
          fieldLabelPlural: 'Verkaufende Betriebsstätten',
          nullOptionValue: 'Keine Betriebsstätte',
        }
      default:
        return { fieldLabel: '', fieldLabelPlural: '', nullOptionValue: '' }
    }
  }, [locationField])

  const expressions = useMemo(() => props.expressions ?? getAll(locationField, props.highlightIndex), [
    getAll,
    locationField,
    props.expressions,
    props.highlightIndex,
  ])

  const label = useMemo(
    () =>
      expressions.length === 1
        ? `${fieldLabel}: ${
            expressions[0][locationField]._is_null
              ? nullOptionValue
              : // @ts-ignore
                locations?.find((sl) => sl.id === expressions[0][locationField]?._eq)?.name
          }`
        : `${expressions.length} ${fieldLabelPlural} ausgewählt`,
    [expressions, fieldLabel, fieldLabelPlural, locationField, locations, nullOptionValue]
  )

  const handleDelete = useCallback(() => {
    props.expressions ? deleteExpressions(props.expressions) : deleteAll(locationField, props.highlightIndex)
  }, [deleteAll, deleteExpressions, locationField, props.expressions, props.highlightIndex])
  if (!Boolean(expressions.length)) return null
  return (
    <ListManipulation.Group
      variant={props.type !== 'highlight' || props.color ? 'filled' : 'outlined'}
      color={props.type === 'highlight' ? props.color : 'default'}
      onDelete={handleDelete}
    >
      <ListManipulation.GroupUnit
        onClick={() => {
          props.onClick && props.onClick()
          setField(locationField)
        }}
        label={label}
      >
        {(field === 'storage_location_id' ||
          field === 'delivery_location_id' ||
          field === 'dms_assigned_location_id' ||
          field === 'seller_location_id') && (
          <VehicleLocationOptions
            type={props.type === 'highlight' ? 'highlight' : 'filter'}
            operations={props.operations}
            highlightIndex={props.highlightIndex}
          />
        )}
        {field === 'color' && <HighlightsColorOptions />}
      </ListManipulation.GroupUnit>
    </ListManipulation.Group>
  )
}
