import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { cancelTimeout, requestTimeout, TimeoutID } from 'utils/timer'

export interface ScrollWindowProps {
  className?: string
  style?: React.CSSProperties
}

export const ScrollWindow: React.FC<ScrollWindowProps> = (props) => {
  const handlers = useRef<ScrollHandlerFn[]>([])
  const [ref] = useScrollWindowHandler<HTMLDivElement>(handlers)

  const registerScrollHandler: RegisterScrollHandlerFn = useCallback((handler) => {
    handlers.current.push(handler)
    return () => {
      handlers.current = handlers.current.filter((h) => h !== handler)
    }
  }, [])

  const contextValue = useMemo(
    () => ({
      registerScrollHandler,
      scrollWindow: ref,
    }),
    [ref, registerScrollHandler]
  )

  return (
    <ScrollContext.Provider value={contextValue}>
      <div ref={ref} style={{ overflow: 'auto', ...(props.style ?? {}) }} className={props.className}>
        {props.children}
      </div>
    </ScrollContext.Provider>
  )
}

const ScrollContext = React.createContext({
  registerScrollHandler: (() => () => {}) as RegisterScrollHandlerFn,
  scrollWindow: { current: null } as React.RefObject<Element>,
})

export const useScrollWindow = () => {
  const ctx = useContext(ScrollContext)
  return ctx?.scrollWindow
}

type ScrollHandlerFn = <T extends Element>(args: {
  scrollOffset: { top: number; left: number }
  scrollWindow: T
}) => void

type UnsubscribeFn = () => void
type RegisterScrollHandlerFn = (handler: ScrollHandlerFn) => UnsubscribeFn

const useScrollWindowHandler = <T extends Element>(scrollHandler: React.RefObject<ScrollHandlerFn[]>) => {
  const ref = useRef<T>()

  const handleScroll = useCallback(
    (evt) => {
      const node = ref.current
      const newScrollOffset = getScrollOffset(node)

      scrollHandler.current.forEach((handler) =>
        handler({
          scrollOffset: newScrollOffset,
          scrollWindow: node,
        })
      )
    },
    [scrollHandler]
  )

  useEffect(() => {
    const node = ref.current
    if (node) {
      node.addEventListener('scroll', handleScroll)
    }
    return () => node && node.removeEventListener('scroll', handleScroll)
  }, [handleScroll, ref])

  return [ref, { handleScroll }] as const
}

export const useVirtualizedWindow = <T extends Element>(givenRef?: React.MutableRefObject<T>) => {
  const [state, setState] = useState({
    isScrolling: false,
    scrollLeft: 0,
    scrollTop: 0,
    width: 0,
    height: 0,
  })
  const ref = useRef<T>()
  const { registerScrollHandler, scrollWindow } = useContext(ScrollContext)

  const update = useCallback((node: Element, sWindow: Element) => {
    const window = sWindow || node
    const scrollOffset = getScrollOffset(window)
    const positionOffset = getPositionOffset(node, window)
    const scrollLeft = Math.max(0, scrollOffset.left - positionOffset.left)
    const scrollTop = Math.max(0, scrollOffset.top - positionOffset.top)
    const dimensions = getDimensions(window)
    setState((prev) => {
      if (
        prev.scrollTop === scrollTop &&
        prev.scrollLeft === scrollLeft &&
        prev.width === dimensions.width &&
        prev.height === dimensions.height
      ) {
        return prev
      }
      return {
        width: dimensions.width,
        height: dimensions.height,
        scrollLeft,
        scrollTop,
        isScrolling: true,
      }
    })

    if (resetScrollTimeout.current) {
      cancelTimeout(resetScrollTimeout.current)
    }
    resetScrollTimeout.current = requestTimeout(
      () => setState((prev) => ({ ...prev, isScrolling: false })),
      IS_SCROLLING_DEBOUNCE_INTERVAL
    )
  }, [])

  useEffect(() => {
    update(ref.current, scrollWindow.current)
  }, [scrollWindow, update])

  const resetScrollTimeout = useRef<TimeoutID>()
  useEffect(() => {
    const node = ref.current
    const handler = ({ scrollWindow }) => {
      update(node, scrollWindow)
    }
    const unsubscribe = registerScrollHandler(handler)
    return unsubscribe
  }, [registerScrollHandler, update])

  const registerRef = useCallback(
    (node: T) => {
      ref.current = node
      if (givenRef) {
        givenRef.current = node
      }
    },
    [givenRef]
  )

  return [registerRef, state] as const
}

const IS_SCROLLING_DEBOUNCE_INTERVAL = 75

const isWindow = (element) => element === window

const getBoundingBox = (element) => element.getBoundingClientRect()

function getScrollOffset(element) {
  if (isWindow(element) && document.documentElement) {
    return {
      top: 'scrollY' in window ? window.scrollY : document.documentElement.scrollTop,
      left: 'scrollX' in window ? window.scrollX : document.documentElement.scrollLeft,
    }
  } else {
    return {
      top: element.scrollTop,
      left: element.scrollLeft,
    }
  }
}

const getDimensions = (scrollElement): { height: number; width: number } => {
  if (isWindow(scrollElement)) {
    const { innerHeight, innerWidth } = window
    return {
      height: typeof innerHeight === 'number' ? innerHeight : 0,
      width: typeof innerWidth === 'number' ? innerWidth : 0,
    }
  } else {
    return getBoundingBox(scrollElement)
  }
}

function getPositionOffset(element, container) {
  if (isWindow(container) && document.documentElement) {
    const containerElement = document.documentElement
    const elementRect = getBoundingBox(element)
    const containerRect = getBoundingBox(containerElement)
    return {
      top: elementRect.top - containerRect.top,
      left: elementRect.left - containerRect.left,
    }
  } else {
    const scrollOffset = getScrollOffset(container)
    const elementRect = getBoundingBox(element)
    const containerRect = getBoundingBox(container)
    return {
      top: elementRect.top + scrollOffset.top - containerRect.top,
      left: elementRect.left + scrollOffset.left - containerRect.left,
    }
  }
}
