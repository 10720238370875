import React, { CSSProperties } from 'react'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import styled from './Typo.module.css'

interface TypoProps<C extends React.ElementType> {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'body1'
    | 'body2'
    | 'subtitle1'
    | 'subtitle2'
    | 'button'
    | 'caption'
    | 'overline'
  component?: C
  decorator?: boolean
  children?: React.ReactNode
  className?: string
  style?: CSSProperties
}

export const Typo = <C extends React.ElementType>(props: TypoProps<C>) => {
  const { decorator, ...rest } = props

  return <Typography {...rest} className={clsx(props.className, decorator && styled.decorator)} />
}
