import { Card, List, ListItem, Spinner } from 'components'
import { StatusLight } from 'containers/StatusLight'
import { useLocosoftSyncStatesQuery } from 'gql'
import React, { useContext, useMemo } from 'react'
import { MonitoringFilterContext } from './MonitoringFilter'

export const AdminMonitoringEntitiesList = () => {
  const { state, dispatch } = useContext(MonitoringFilterContext)

  const { data, loading } = useLocosoftSyncStatesQuery()
  const entities = useMemo(() => data?.locosoft_sync_states, [data?.locosoft_sync_states])
  if (loading)
    return (
      <Card className="relative" style={{ minHeight: 375 }}>
        <Spinner centered />
      </Card>
    )
  return (
    <Card>
      <List style={{ minHeight: 325 }}>
        {entities?.map((entity, idx) => (
          <ListItem
            key={`entitiy-${idx}-${entity.name}`}
            selected={entity.syncEntity === state.selectedSyncEntity?.syncEntity}
            onClick={() => dispatch({ type: 'selected-entity', payload: { entity } })}
          >
            <StatusLight status={entity.status as 'success' | 'warning' | 'error'} />
            <span className="ml-2">{entity.name}</span>
          </ListItem>
        ))}
      </List>
    </Card>
  )
}
