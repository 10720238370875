export * from './hooks'
export * from './components'
export * from './events'
export * from './EzAuthContext'
export * from './types'

export interface User {
  id: string
  email: string
  roles: UserRole[]
  getIdToken: () => Promise<string>
  defaultLocationId?: string
  showPreview?: boolean
}

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
  SALES = 'sales',
  SALES_MANAGER = 'sales_manager',
  VEHICLE_DISPATCHER = 'vehicle_dispatcher',
}
