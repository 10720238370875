import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import styled from './Grid.module.css'
interface GridProps {
  cols?: 2 | 3 | 5
  spacing?: 2
  className?: string
  style?: CSSProperties
}

export const Grid: React.FC<GridProps> = (props) => {
  return (
    <div
      className={clsx(styled.root, styled[`col-${props.cols}`], styled[`gap-${props.spacing ?? 2}`], props.className)}
      style={props.style}
    >
      {props.children}
    </div>
  )
}

Grid.defaultProps = {
  cols: 2,
}
