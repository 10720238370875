import React, { CSSProperties } from 'react'
import MuiLink from '@mui/material/Link'

export interface PhoneNoLinkProps {
  phoneNo: string
  className?: string
  style?: CSSProperties
}

export const PhoneNoLink: React.FC<PhoneNoLinkProps> = React.forwardRef((props, ref) => {
  const { phoneNo, ...rest } = props

  const href = `tel:${phoneNo}`
  return <MuiLink href={href} {...rest} ref={ref as any} className={props.className} />
})
