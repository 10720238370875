import React, { useCallback, useMemo } from 'react'
import { DealerVehicleTypeSelect } from '../../DealerVehicleTypeSelect'
import { DealerVehicleTypeChip } from '../../DealerVehicleType/chip'
import { useSalesReportGeneralFormValues } from './index'
import { SalesReport } from '../SalesReportContext'
import { TextField } from '../../../components'
import { useFormatter } from '../../../hooks'
import { UserSelect } from '../../UserSelect'
import { SalesTypeSelect } from 'containers/SalesTypeSelect'
import { CustomerTypeSelect } from '../../CustomerTypeSelect'
import { useSalesReportGeneralValidationSchema } from './useValidationSchema'
import { DatePicker } from '../../../components/DatePicker'
import { useTranslation } from 'react-i18next'
import { BrandSelect } from '../../BrandSelect'

export const useSalesReportGeneralFormular = (onSave: (data: SalesReport) => Promise<void>, data?: SalesReport) => {
  const { bindings, values, setValue } = useSalesReportGeneralFormValues(data)
  const { formatNumber, formatDateString } = useFormatter()
  const { t } = useTranslation()
  const { validate, errors } = useSalesReportGeneralValidationSchema(values)

  const onSubmit = useCallback(async () => {
    const { isValid } = await validate({ silent: false })
    if (!isValid) {
      throw new Error('Form invalid')
    }
    await onSave(values)
  }, [onSave, validate, values])

  return useMemo(
    () => ({
      sections: [
        {
          name: 'vehicle',
          label: 'Fahrzeug',
          column: 1,
          fields: [
            {
              name: 'type',
              label: 'Typ',
              required: true,
              placeholder: data?.vehicle_type ? <DealerVehicleTypeChip type={data.vehicle_type} /> : '-',
              FormComponent: (
                <DealerVehicleTypeSelect
                  value={values.vehicle_type}
                  onSelect={(val) => setValue('vehicle_type', val)}
                />
              ),
            },
            {
              name: 'brand',
              label: 'Marke',
              required: true,
              placeholder: data?.brand?.name ?? '-',
              FormComponent: (
                <BrandSelect onChange={(value) => setValue('brand_id', value[0])} brandId={values.brand_id} />
              ),
            },
            {
              name: 'model_name',
              label: 'Modell',
              required: true,
              placeholder: data?.model_name ?? '-',
              FormComponent: (
                <TextField
                  {...bindings.model_name}
                  onChange={({ currentTarget }) => setValue('model_name', currentTarget.value)}
                />
              ),
            },
            {
              name: 'vin',
              label: 'FIN',
              required: true,
              placeholder: data?.vin ?? '-',
              FormComponent: (
                <TextField {...bindings.vin} onChange={({ currentTarget }) => setValue('vin', currentTarget.value)} />
              ),
            },
            {
              name: 'job_number',
              label: 'Auftragsnummer',
              placeholder: data?.job_number ?? '-',
              FormComponent: (
                <TextField
                  {...bindings.job_number}
                  onChange={({ currentTarget }) => setValue('job_number', currentTarget.value)}
                />
              ),
            },
            {
              name: 'list_price',
              label: 'Listenpreis',
              required: true,
              placeholder: formatNumber(data?.list_price, { style: 'currency', currency: 'EUR' }),
              FormComponent: (
                <TextField
                  min={0}
                  type={'number'}
                  {...bindings.list_price}
                  onChange={({ currentTarget }) =>
                    setValue('list_price', currentTarget.value ? Number(currentTarget.value) : null)
                  }
                />
              ),
            },
            {
              name: 'selling_price',
              label: 'Verkaufspreis',
              required: true,
              placeholder: formatNumber(data?.selling_price, { style: 'currency', currency: 'EUR' }),
              FormComponent: (
                <TextField
                  min={0}
                  type={'number'}
                  {...bindings.selling_price}
                  onChange={({ currentTarget }) =>
                    setValue('selling_price', currentTarget.value ? Number(currentTarget.value) : null)
                  }
                />
              ),
            },
            {
              name: 'price_discussed_with',
              label: 'Besprochen mit',
              placeholder: data?.price_discussed_with,
              FormComponent: (
                <TextField
                  {...bindings.price_discussed_with}
                  onChange={({ currentTarget }) => setValue('price_discussed_with', currentTarget.value)}
                />
              ),
            },
            {
              name: 'expected_selling_price',
              label: 'Voraussichtlicher Verkaufspreis',
              required: true,
              placeholder: formatNumber(data?.expected_sales_price, { style: 'currency', currency: 'EUR' }),
              FormComponent: (
                <TextField
                  min={0}
                  type={'number'}
                  {...bindings.expected_sales_price}
                  onChange={({ currentTarget }) =>
                    setValue('expected_sales_price', currentTarget.value ? Number(currentTarget.value) : null)
                  }
                />
              ),
            },
            {
              name: 'handover_planned_date',
              label: 'Auslieferungsdatum',
              placeholder: formatDateString(data?.handover_planned_date),
              FormComponent: (
                <DatePicker
                  value={values.handover_planned_date}
                  onChange={(value) => setValue('handover_planned_date', value)}
                />
              ),
            },
          ],
        },
        {
          name: 'exchange',
          label: 'Eintausch',
          column: 1,
          fields: [
            {
              name: 'brand',
              label: 'Marke',
              placeholder: data?.exchange_brand?.name ?? '-',
              FormComponent: (
                <BrandSelect
                  brandId={values.exchange_brand_id}
                  onChange={(value) => setValue('exchange_brand_id', value[0])}
                />
              ),
            },
            {
              name: 'model',
              label: 'Modell',
              placeholder: data?.exchange_model_name ?? '-',
              FormComponent: (
                <TextField
                  {...bindings.exchange_model_name}
                  onChange={({ currentTarget }) => setValue('exchange_model_name', currentTarget.value)}
                />
              ),
            },
            {
              name: 'vin',
              label: 'FIN',
              placeholder: data?.exchange_vin ?? '-',
              FormComponent: (
                <TextField
                  {...bindings.exchange_vin}
                  onChange={({ currentTarget }) => setValue('exchange_vin', currentTarget.value)}
                />
              ),
            },
            {
              name: 'exchange_price',
              label: 'Eintauschpreis',
              placeholder: data?.exchange_price ?? '-',
              FormComponent: (
                <TextField
                  min={0}
                  type={'number'}
                  {...bindings.exchange_price}
                  onChange={({ currentTarget }) =>
                    setValue('exchange_price', currentTarget.value ? Number(currentTarget.value) : null)
                  }
                />
              ),
            },
          ],
        },
        {
          name: 'general',
          label: 'Allgemein',
          column: 2,
          fields: [
            {
              name: 'seller_id',
              label: 'Verkäufer',
              required: true,
              placeholder: data?.seller?.full_name || data?.seller?.email || '-',
              FormComponent: (
                <UserSelect value={values.seller_id} onSelect={(user) => setValue('seller_id', user.id)} />
              ),
            },
            {
              name: 'sales_type',
              label: 'Geschäftstyp',
              required: true,
              placeholder: data?.sales_type ? t(`SalesType.${data?.sales_type}`) : '-',
              FormComponent: (
                <SalesTypeSelect value={values.sales_type} onSelect={(type) => setValue('sales_type', type)} />
              ),
            },
          ],
        },
        {
          name: 'customer',
          label: 'Kunde',
          column: 2,
          fields: [
            {
              name: 'customer_type',
              label: 'Kundentyp',
              required: true,
              placeholder: data?.customer_type ? t(`CustomerType.${data?.customer_type}`) : '-',
              FormComponent: (
                <CustomerTypeSelect value={values.customer_type} onSelect={(type) => setValue('customer_type', type)} />
              ),
            },
            {
              name: 'customer_name',
              label: 'Name',
              required: true,
              placeholder: data?.customer_name ?? '-',
              FormComponent: (
                <TextField
                  {...bindings.customer_name}
                  onChange={({ currentTarget }) => setValue('customer_name', currentTarget.value)}
                />
              ),
            },
            {
              name: 'zip_code',
              label: 'PLZ',
              required: true,
              placeholder: data?.zip_code ?? '-',
              FormComponent: (
                <TextField
                  {...bindings.zip_code}
                  onChange={({ currentTarget }) => setValue('zip_code', currentTarget.value)}
                />
              ),
            },
            {
              name: 'customer_number',
              label: 'Kundennummer',
              placeholder: data?.customer_number ?? '-',
              FormComponent: (
                <TextField
                  {...bindings.customer_number}
                  onChange={({ currentTarget }) => setValue('customer_number', currentTarget.value)}
                />
              ),
            },
          ],
        },
      ],
      onSubmit,
      errors,
    }),
    [t, formatDateString, errors, onSubmit, data, setValue, values, bindings, formatNumber]
  )
}
