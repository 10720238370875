import clsx from 'clsx'
import React from 'react'
import { CSSProperties } from 'react'

type FormRowProps = {
  className?: string
  style?: CSSProperties
  label?: string
  required?: boolean
  error?: string
}

export const FormRow: React.FC<FormRowProps> = (props) => {
  return (
    <div className={clsx(props.className, { error: !!props.error })} style={props.style}>
      <div className="flex space-x-4 py-1 items-center">
        <div className="w-48 text-sm">
          {props.label}
          {props.required && <span className="text-error-main text-xl leading-none">*</span>}
        </div>
        <div className="flex-1">
          <div>{props.children}</div>
          {props.error && <div className="text-error-main">{props.error}</div>}
        </div>
      </div>
    </div>
  )
}
