import React, { useEffect, useMemo } from 'react'
import { ApolloProvider as Provider, ApolloClient, InMemoryCache } from '@apollo/client'

import { useConfig } from 'config'
import { ezAuthEventHub, EzAuthEventType } from '@lib/ezauth'
import { LinkRegistry, LinkFactoryParams } from './links'
import { offsetLimitPagination } from '@apollo/client/utilities'

export interface ApolloProviderProps {
  children: React.ReactNode
}

export const ApolloProvider: React.FC<ApolloProviderProps> = (props) => {
  const linkFactoryParams = useLinkFactoryParams()
  const linkUiEnahncer = LinkRegistry.createComponent(linkFactoryParams)
  const client = useApolloClient(linkFactoryParams)
  useResetOnLogout(client)

  return (
    <>
      <Provider client={client} {...props} />
      {linkUiEnahncer}
    </>
  )
}

const useApolloClient = (linkFactoryParams: LinkFactoryParams[]) => {
  return useMemo(() => {
    const link = LinkRegistry.createLink(linkFactoryParams)

    return new ApolloClient({
      link,
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              customer_vehicles: offsetLimitPagination(['where']),
              after_sales_potentials: offsetLimitPagination(['where']),
            },
          },
          TaskType: {
            keyFields: ['code'],
          },
          Vehicle: {
            keyFields: ['id', 'version', 'business_case_number'],
          },
        },
      }),
    })
  }, [linkFactoryParams])
}

const useResetOnLogout = (client: ApolloClient<any>) => {
  useEffect(() => {
    const subscriber = ezAuthEventHub.subscribe((event) => {
      switch (event.type) {
        case EzAuthEventType.SIGNED_OUT:
          client.resetStore()
      }
    })
    return () => subscriber.unsubscribe()
  }, [client])
}

const useLinkFactoryParams = () => {
  const { apollo } = useConfig()
  return useMemo(() => {
    return apollo.links
      .map<LinkFactoryParams>((conf) => ({
        key: conf.protocol,
        config: conf.options,
      }))
      .concat([
        {
          key: 'error',
        },
        {
          key: 'retry',
        },
      ])
  }, [apollo.links])
}
