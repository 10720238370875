import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { useEzAuth, useEzAuthUserHasRoles, UserRole } from '@lib/ezauth'
import { Button, Card, Icon, IconButton, TextField, Tooltip, Typo } from 'components'
import { SplitButton } from 'components/SplitButton'
import {
  useAddVehicleNoteMutation,
  useDeleteVehicleNoteMutation,
  useSetVehicleNoteStickyStateMutation,
  useArchiveVehicleNoteMutation,
  VehicleNoteFieldsFragment,
  useVehicleNotesSubscription,
} from 'gql'
import { useFormatter } from 'hooks'
import React, { useState, useContext, useMemo, useCallback } from 'react'
import { VehicleDetailContext } from '..'
import * as L from 'layouts'
import clsx from 'clsx'

export const Notes = () => {
  const { data } = useContext(VehicleDetailContext)

  const businessCaseNumber = useMemo(() => data.business_case_number || data.min_non_imported_business_case, [
    data.business_case_number,
    data.min_non_imported_business_case,
  ])

  const notesSubscription = useVehicleNotesSubscription({
    variables: {
      vehicleId: data.id,
      businessCaseNumber,
    },
  })

  const [note, setNote] = useState('')
  const [splitButtonIndex, setSplitButtonIndex] = useState<0 | 1>(0)
  const splitButtonOptions = ['Senden', 'Dispo Info senden']
  const [authData] = useEzAuth()

  const canAddHighPriorityNotes = useEzAuthUserHasRoles([
    UserRole.SALES_MANAGER,
    UserRole.VEHICLE_DISPATCHER,
    UserRole.ADMIN,
  ])

  const [addNote, addNoteResponse] = useAddVehicleNoteMutation()
  const handleAddNote = async () => {
    try {
      switch (splitButtonIndex) {
        case 0:
          await addNote({
            variables: {
              note: {
                vehicle_id: data.id,
                user_id: authData.user.id,
                note,
                vehicle_version: data.max_version,
                vehicle_business_case_number: businessCaseNumber,
              },
            },
          })
          break

        case 1:
          await addNote({
            variables: {
              note: {
                vehicle_id: data.id,
                user_id: authData.user.id,
                note,
                priority: true,
                vehicle_version: data.max_version,
                vehicle_business_case_number: businessCaseNumber,
              },
            },
          })
          break
      }
      setNote('')
    } catch {}
  }

  const [deleteNote] = useDeleteVehicleNoteMutation()
  const handleDelete = (noteId: string) => {
    deleteNote({ variables: { noteId: noteId } })
    // TODO: optimistic update
  }

  const [setStickyState] = useSetVehicleNoteStickyStateMutation()
  const handleSetStickyState = (noteId: string, sticky: boolean) => {
    setStickyState({ variables: { noteId, sticky } })
  }

  const [archive] = useArchiveVehicleNoteMutation()

  const handleArchive = (noteId: string) => {
    archive({ variables: { noteId: noteId } })
    // TODO: optimistic update
  }

  const [showArchived, setShowArchived] = useState(false)

  const notes = useMemo(() => notesSubscription.data?.notes?.filter((note) => showArchived || !note.archived) ?? [], [
    notesSubscription.data?.notes,
    showArchived,
  ])

  return (
    <>
      <Timeline position="right">
        <Button
          variant="text"
          className="absolute top-0 right-0 pr-4 text-xs cursor-pointer hover:text-primary"
          onClick={() => setShowArchived((prev) => !prev)}
        >
          {showArchived ? 'Weniger anzeigen' : 'Alle anzeigen'}
        </Button>
        {notes.map((note) => (
          <Note
            note={note}
            key={note.id}
            onStickyStateChange={(sticky: boolean) => handleSetStickyState(note.id, sticky)}
            onDelete={() => handleDelete(note.id)}
            onArchive={() => handleArchive(note.id)}
            color={note.priority ? 'warning' : undefined}
          />
        ))}
        {notes.length === 0 && <div className="text-center">Keine Notizen verfügbar.</div>}
      </Timeline>
      <Card className="flex flex-col">
        <L.Horizontal className="flex-1" spacing={0}>
          <TextField
            multiline
            variant="outlined"
            className="flex-1"
            value={note}
            onChange={(evt) => setNote(evt.currentTarget.value)}
          />
        </L.Horizontal>
      </Card>
      {canAddHighPriorityNotes ? (
        <SplitButton
          className="float-right mt-2"
          variant="contained"
          endIcon={<Icon name="send" />}
          options={splitButtonOptions}
          onClick={handleAddNote}
          aria-label="send note"
          selectedIndex={splitButtonIndex}
          setSelectedIndex={setSplitButtonIndex}
        />
      ) : (
        <Button
          className="float-right mt-2"
          variant="contained"
          color="primary"
          endIcon={<Icon name="send" />}
          onClick={handleAddNote}
          loading={addNoteResponse.loading}
        >
          Senden
        </Button>
      )}
    </>
  )
}

interface NoteProps {
  note: VehicleNoteFieldsFragment
  color?: 'warning'
  noSeparator?: boolean
  onArchive: () => void
  onDelete: () => void
  onStickyStateChange: (sticky: boolean) => void
}

const Note = (props: NoteProps) => {
  const { note } = props
  const { formatDateTimeString } = useFormatter()

  const canChangeStickyState = useEzAuthUserHasRoles([
    UserRole.SALES_MANAGER,
    UserRole.VEHICLE_DISPATCHER,
    UserRole.ADMIN,
  ])
  const isAdmin = useEzAuthUserHasRoles(UserRole.ADMIN)
  const [authState] = useEzAuth()

  const canArchiveOrDelete = useCallback(
    (note: VehicleNoteFieldsFragment) => {
      return note.user?.id === authState.user.id || isAdmin
    },
    [authState.user.id, isAdmin]
  )

  return (
    <TimelineItem
      position="right"
      sx={{ ':before': { content: 'none' }, minHeight: props.noSeparator ? 'unset' : '70px' }}
      className="group"
    >
      <TimelineSeparator style={{ minWidth: 30 }}>
        <TimelineDot className="self-center" color={props.color} />
        {!props.noSeparator && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent style={{ opacity: note.archived ? 0.5 : 1 }}>
        <div className="flex flex-row">
          <div className="flex-1">
            <Typo variant="body1">
              {note.note}
              {note.archived && <>{' (Archiviert)'}</>}
            </Typo>
            <Typo variant="body2">
              {formatDateTimeString(note.created_at)}
              {' - '}
              {note.user?.full_name ||
                `${note.user?.abbreviation ? `${note.user?.abbreviation}, ` : ''}${note.user?.email ?? ''}`}
            </Typo>
          </div>
          <div className="flex items-center justify-end" style={{ minWidth: 85 }}>
            <Tooltip content={note.sticky ? 'Loslösen' : 'Anheften'}>
              {canChangeStickyState ? (
                <IconButton
                  aria-label="set sticky state of note"
                  size="small"
                  onClick={() => props.onStickyStateChange(!note.sticky)}
                  className={clsx(!note.sticky && 'group-hover:flex hidden')}
                >
                  <Icon name="pin" fontSize="small" className={clsx(note.sticky ? 'text-primary-main' : '')} />
                </IconButton>
              ) : (
                note.sticky && <Icon name="pin" fontSize="small" className="text-primary-main" />
              )}
            </Tooltip>
            <Tooltip content="Löschen">
              {canArchiveOrDelete(note) && (
                <IconButton aria-label="delete" size="small" onClick={props.onDelete}>
                  <Icon name="delete" fontSize="small" />
                </IconButton>
              )}
            </Tooltip>
            {!note.archived && (
              <Tooltip content="Archivieren">
                {canArchiveOrDelete(note) && (
                  <IconButton aria-label="archive" size="small" onClick={props.onArchive}>
                    <Icon name="archive" fontSize="small" />
                  </IconButton>
                )}
              </Tooltip>
            )}
          </div>
        </div>
      </TimelineContent>{' '}
    </TimelineItem>
  )
}
