import { After_Sales_Status_Enum, useSetAfterSalesPotentialStatusMutation } from 'gql'
import { useCallback } from 'react'

export const useSetAfterSalesStatus = (status: After_Sales_Status_Enum) => {
  const [setStatus] = useSetAfterSalesPotentialStatusMutation()

  return useCallback(
    async (potentialIds: string[]) => {
      await setStatus({
        variables: {
          ids: potentialIds,
          status,
        },
        optimisticResponse: {
          // __typename: 'Mutation',
          update_after_sales_potentials: {
            affected_rows: potentialIds.length,
            returning: potentialIds.map((id) => ({
              id,
              status,
            })),
          },
        },
      })
    },
    [setStatus, status]
  )
}
