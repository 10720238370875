import React, { useEffect, useMemo, useRef } from 'react'
import { ButtonGroup } from 'components'
import { useLocationWithGroupsLazyQuery } from 'gql'

export interface LocationGroupBtnGrpProps {
  locationId: string
  locationGroupId?: string
  onChange?: (locationGroupId: string) => void
}

export const LocationGroupBtnGrp: React.FC<LocationGroupBtnGrpProps> = (props) => {
  const [query, res] = useLocationWithGroupsLazyQuery()
  useEffect(() => {
    if (props.locationId) {
      query({ variables: { id: props.locationId } })
    }
  }, [props.locationId, query])

  const locationGroups = useMemo(() => res.data?.location?.locationGroups ?? [], [res.data])

  const handleChangeRef = useRef<LocationGroupBtnGrpProps['onChange']>(() => {})
  handleChangeRef.current = props.onChange ?? (() => {})

  useEffect(() => {
    if (locationGroups.length) {
      if (locationGroups.map((lg) => lg.id).indexOf(props.locationGroupId) < 0) {
        handleChangeRef.current('')
      }
    }
  }, [locationGroups, props.locationGroupId])

  if (!res.data?.location) {
    return null
  }

  return (
    <ButtonGroup value={props.locationGroupId} onChange={handleChangeRef.current}>
      <ButtonGroup.Button value="">Alle</ButtonGroup.Button>
      {locationGroups.map((lg) => (
        <ButtonGroup.Button value={lg.id} key={lg.id}>
          {lg.name}
        </ButtonGroup.Button>
      ))}
    </ButtonGroup>
  )
}
