import { Avatar } from 'components'
import { Dealer_Vehicle_State_Enum } from 'gql'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface DealerVehicleStateAvatarProps {
  state: Dealer_Vehicle_State_Enum
  size?: 'small' | 'default'
}

export const DealerVehicleStateAvatar = (props: DealerVehicleStateAvatarProps) => {
  const { t } = useTranslation()
  const bindings = useBindings(props)
  return <Avatar {...bindings} size={props.size} alt={t(`DealerVehicleState.${props.state}`)} />
}

const useBindings = (props: DealerVehicleStateAvatarProps): { children: ReactNode; className: string } => {
  switch (props.state) {
    case Dealer_Vehicle_State_Enum.Available:
      return { children: '1', className: 'bg-success-main' }
    case Dealer_Vehicle_State_Enum.OnSiteNeed:
      return { children: 'X', className: 'bg-grey-700' }
    case Dealer_Vehicle_State_Enum.Other:
      return { children: 'S', className: 'bg-primary-main' }
    case Dealer_Vehicle_State_Enum.Reserved:
      return { children: 'R', className: 'bg-warning-main' }
    case Dealer_Vehicle_State_Enum.Sold:
      return { children: 'V', className: 'bg-error-main' }
    case Dealer_Vehicle_State_Enum.SoldByBroker:
      return { children: 'V', className: 'bg-error-main' }
    case Dealer_Vehicle_State_Enum.SoldByPartner:
      return { children: 'V', className: 'bg-error-main' }
    case Dealer_Vehicle_State_Enum.SoldToPartner:
      return { children: 'P', className: 'bg-warning-dark' }
    default:
      return { children: '-', className: '' }
  }
}
