import { createContext } from 'react'
import { SalesReportDetailQueryResult } from '../../gql'

export type SalesReport = SalesReportDetailQueryResult['data']['sales_reports_by_pk']

export const SalesReportContext = createContext<{
  data?: SalesReport
  loading?: boolean
}>({
  data: null,
  loading: false,
})
