import React, { useEffect } from 'react'
import { useVehiclesStore } from '../../../../containers/ListConfig/Vehicles/hooks/useData'
import { SiteMetadata } from '../../../../components'
import { VehiclesListConfig, VehiclesListType } from '../../../../containers/ListConfig/Vehicles/List/Config'
import { VehiclesList } from '../../../../containers/ListConfig/Vehicles/List'

export const MarkedForDeleteVehicles = () => {
  const { reset } = useVehiclesStore()
  useEffect(() => reset(), [reset])
  return (
    <>
      <SiteMetadata title="Datenqualität - Fahrzeuge die zum Löschen markiert sind" />
      <VehiclesListConfig type={VehiclesListType.MARKED_FOR_DELETE}>
        <VehiclesList />
      </VehiclesListConfig>
    </>
  )
}
