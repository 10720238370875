import React, { CSSProperties } from 'react'
import { Customer_Type_Enum } from '../../gql'
import { useTranslation } from 'react-i18next'
import Select from 'components/Select'

interface CustomerTypeSelectProps {
  value: Customer_Type_Enum
  onSelect: (type: Customer_Type_Enum) => void
  name?: string
  label?: string
  disabled?: boolean
  style?: CSSProperties
}

export const CustomerTypeSelect: React.FC<CustomerTypeSelectProps> = (props) => {
  const { value, onSelect, name, label, style, disabled } = props
  const { t } = useTranslation()

  return (
    <Select
      name={name ?? 'customerTypeSelect'}
      label={label}
      value={value}
      onChange={(evt) => {
        evt.stopPropagation()
        onSelect(evt.target.value as Customer_Type_Enum)
      }}
      renderSelectValueItem={(type) => t(`CustomerType.${type}`)}
      disabled={disabled}
      style={style}
    >
      {Object.keys(Customer_Type_Enum).map((type) => (
        <Select.Option key={`customer_type_select_option_${type}`} value={Customer_Type_Enum[type]}>
          {t(`CustomerType.${Customer_Type_Enum[type]}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
