import { HttpLink, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities/graphql/getFromAST'
import { Config, LinkRegistry } from '../ApolloLinkRegistry'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { CloseCode, createClient } from 'graphql-ws'
import { Hub } from '@aws-amplify/core'
import { ezAuthEventHub, EzAuthEventType, User } from '@lib/ezauth'

LinkRegistry.add('http', {
  createLink: (config) =>
    split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink(config, getWsUrl),
      authLink.concat(new HttpLink(config))
    ),
  createComponent: () => null,
  order: 41,
})

LinkRegistry.add('https', {
  createLink: (config) => {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink(config, getWssUrl),
      authLink.concat(new HttpLink(config))
    )
  },
  createComponent: () => null,
  order: 40,
})

const wsLink = (config: Config, generateUrl: (uri: string) => string) => {
  return new GraphQLWsLink(
    createClient({
      url: generateUrl(config.uri),
      on: {
        connected: (socket: any) => {
          Hub.listen('auth', (data) => {
            switch (data.payload.event) {
              case 'tokenRefresh':
                if (socket.readyState === WebSocket.OPEN) {
                  socket.close(CloseCode.Forbidden, 'Forbidden')
                }
            }
          })
        },
      },
      connectionParams: async () => {
        const token = (await user?.getIdToken()) ?? null
        return {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        }
      },
    })
  )
}

let user: User | null = null
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = (await user?.getIdToken()) ?? null
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const getWsUrl = (uri: string) => {
  const url = new URL(uri)
  return `ws://${url.host}${url.pathname}`
}

const getWssUrl = (uri: string) => {
  const url = new URL(uri)
  return `wss://${url.host}${url.pathname}`
}

ezAuthEventHub.subscribe((event) => {
  switch (event.type) {
    case EzAuthEventType.INITIALIZED:
    case EzAuthEventType.SIGNED_IN:
      if (event.user) {
        user = event.user ?? null
      }
      break
    case EzAuthEventType.SIGNED_OUT:
      user = null
      break
  }
})
