import React, { FormEventHandler } from 'react'
import * as L from 'layouts'
import clsx from 'clsx'
import { Button } from 'components/Button'
import { ErrorBlock } from 'components/ErrorBlock'

export interface FormProps {
  onSubmit?: FormEventHandler<HTMLFormElement>
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

export const Form = ({ onSubmit, ...props }: FormProps) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit && onSubmit(e)
      }}
      method="POST"
      style={props.style}
      className={props.className}
    >
      {props.children}
    </form>
  )
}

type FormBodyProps = BaseProps & {}

export const FormBody: React.FC<FormBodyProps> = (props) => {
  return (
    <div className={props.className} style={props.style}>
      {props.children}
    </div>
  )
}

Form.Body = FormBody

type FormActionsProps = BaseProps & {
  errors?: string[]
  onCancel: () => void
  onSave: () => void
}
export const FormActions = (props: FormActionsProps) => {
  return (
    <FormActionBar className={clsx(props.className, 'z-10')} style={props.style}>
      <ErrorBlock errors={props.errors} />
      <Button onClick={props.onCancel}>Abbrechen</Button>
      <Button onClick={props.onSave} variant="contained" disabled={props.errors?.length > 0}>
        Speichern
      </Button>
    </FormActionBar>
  )
}
Form.Actions = FormActions

type FormActionBarProps = BaseProps & {
  children?: React.ReactNode
}

export const FormActionBar = (props: FormActionBarProps) => {
  return (
    <L.Horizontal
      className={clsx('sticky bottom-0 bg-white px-6 py-4 justify-center', props.className)}
      style={props.style}
    >
      {props.children}
    </L.Horizontal>
  )
}
Form.ActionBar = FormActionBar
