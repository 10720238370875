import { useFormFields } from '@w2solutions/react-hooks'
import { v4 as uuid } from 'uuid'
import { Button, Icon, IconButton, MultiSelect, Spinner, TextField, Typo } from 'components'
import {
  AdminSubDealerDetailsQuery,
  Brand,
  DmsReference,
  Dms_Type_Enum,
  useAdminSubDealerDetailsLazyQuery,
  useBrandsQuery,
  useCreateSubDealerMutation,
  useUpdateSubdealerMutation,
} from 'gql'
import React from 'react'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { removeTypename } from 'utils'
import * as L from 'layouts'
import { DmsReferenceFormSection } from '../DmsReferenceFormSection'
import { DetailWindow } from 'components/DetailWindow'

interface AdminSubDealerDetailProps {
  subDealerId?: string
  onClose: () => void
  title: string
}

const initialData = {
  name: '',
  addressId: '',
  street: '',
  streetNo: '',
  city: '',
  zipCode: '',
  customerDmsReferences: [] as DmsReference[],
  brandIds: [] as string[],
}

type FormData = typeof initialData

const subDealerToFormData = (subDealer?: AdminSubDealerDetailsQuery['subdealer']): FormData => {
  if (!subDealer) {
    return { ...initialData, addressId: uuid() }
  }

  return {
    name: subDealer.name ?? '',
    addressId: subDealer.address?.id ?? '',
    street: subDealer.address?.street ?? '',
    streetNo: subDealer.address?.streetNo ?? '',
    city: subDealer.address?.city ?? '',
    zipCode: subDealer.address?.zipCode ?? '',
    customerDmsReferences: removeTypename(subDealer.customerDmsReferences) ?? [],
    brandIds: subDealer.brands.map((brand) => brand.id),
  }
}

export const AdminSubDealerDetail: React.FC<AdminSubDealerDetailProps> = (props) => {
  const [load, loadResponse] = useAdminSubDealerDetailsLazyQuery()
  const query = useBrandsQuery()
  const allBrands = query.data?.brands ?? []

  useEffect(() => {
    if (props.subDealerId) {
      load({
        variables: {
          id: props.subDealerId,
        },
      })
    }
  }, [load, props.subDealerId])

  const initialValues = useMemo(() => {
    return subDealerToFormData(loadResponse.data?.subdealer)
  }, [loadResponse.data?.subdealer])

  const { bindings, values, setValue } = useFormFields(initialValues, { resetOnInitialValueChange: true })

  const [save] = useSave(props.subDealerId)
  const submit = async () => {
    await save(values)
    props.onClose()
  }

  if (loadResponse.loading || query?.loading) {
    return <Spinner />
  }

  return (
    <DetailWindow variant="persistent" onClose={props.onClose} width={700} title={props.title}>
      <L.Vertical spacing={6} className={'p-4'}>
        <L.Grid>
          <TextField label="Name" {...bindings.name} />
        </L.Grid>
        <L.Vertical>
          <Typo>Adresse</Typo>
          <L.Grid>
            <TextField label="Straße" {...bindings.street} />
            <TextField label="Nr." {...bindings.streetNo} />
            <TextField label="Ort" {...bindings.city} />
            <TextField label="PLZ" {...bindings.zipCode} />
          </L.Grid>
        </L.Vertical>
        <DmsReferenceFormSection
          title={'DMS Kunden Nummern'}
          dmsReferences={values.customerDmsReferences}
          onAdd={(blank) => setValue('customerDmsReferences', [...values.customerDmsReferences, blank])}
          onRemove={(_, ref) => {
            setValue(
              'customerDmsReferences',
              values.customerDmsReferences.filter((r) => r.id !== ref.id)
            )
          }}
          onChange={(_, ref) => {
            setValue(
              'customerDmsReferences',
              values.customerDmsReferences.map((r) => {
                return r.id === ref.id ? ref : r
              })
            )
          }}
        />
        <BrandsForm
          brands={allBrands}
          selectedBrandIds={values.brandIds}
          onSelect={(ids) => setValue('brandIds', ids)}
          onDelete={() => setValue('brandIds', [])}
        />
        <L.Horizontal spacing={2}>
          <Button color={'primary'} variant={'contained'} onClick={submit}>
            {'Speichern'}
          </Button>
          <Button variant={'outlined'} onClick={props.onClose}>
            {'Abbrechen'}
          </Button>
        </L.Horizontal>
      </L.Vertical>
    </DetailWindow>
  )
}

const useSave = (id?: string) => {
  const [create, createResponse] = useCreateSubDealerMutation()
  const [update, updateResponse] = useUpdateSubdealerMutation()
  const save = async (data: FormData) => {
    if (id) {
      await update({
        variables: {
          subdealearId: id,
          addressId: data.addressId,
          refIds: data.customerDmsReferences.map((ref) => ref.id),
          brandIds: data.brandIds,
          subdealer: {
            name: data.name,
          },
          address: {
            street: data.street,
            streetNo: data.streetNo,
            city: data.city,
            zipCode: data.zipCode,
          },
          references: data.customerDmsReferences.map((ref) => ({
            id: ref.id,
            name: ref.name,
            reference: ref.reference,
            type: Dms_Type_Enum.Locosoft,
            subdealer_customer_id: id,
          })),
          brandReferences: data.brandIds.map((bId) => ({
            subdealer_id: id,
            brand_id: bId,
          })),
        },
      })
    } else {
      const subdealerId = uuid()
      await create({
        variables: {
          object: {
            id: subdealerId,
            name: data.name,
            address: {
              data: {
                id: data.addressId,
                street: data.street,
                streetNo: data.streetNo,
                city: data.city,
                zipCode: data.zipCode,
              },
            },
            customerDmsReferences: {
              data: data.customerDmsReferences.map((ref) => ({
                id: ref.id,
                name: ref.name,
                reference: ref.reference,
                type: Dms_Type_Enum.Locosoft,
              })),
            },
            subdealer_brands: {
              data: data.brandIds.map((brandId) => ({
                brand_id: brandId,
              })),
            },
          },
        },
      })
    }
  }
  const state = useMemo(
    () => ({
      loading: createResponse.loading || updateResponse.loading,
    }),
    [createResponse.loading, updateResponse.loading]
  )
  return [save, state] as const
}

interface BrandsProps {
  brands: Pick<Brand, 'id' | 'name' | 'is_house_brand'>[]
  selectedBrandIds: string[]
  onSelect: (ids: string[]) => void
  onDelete: () => void
}

const BrandsForm = (props: BrandsProps) => {
  const { selectedBrandIds, brands, onSelect, onDelete } = props

  if (!brands) {
    return null
  }

  return (
    <L.Vertical spacing={0}>
      <Typo>{'Marken zuordnen'}</Typo>
      <MultiSelect
        name="brandIds"
        value={selectedBrandIds}
        onChange={(evt) => onSelect(evt.value)}
        renderValueItem={(brandId) => brands.find((brand) => brand.id === brandId)?.name ?? ''}
        label={'Marken'}
      >
        {brands.map((brand) => (
          <MultiSelect.Option key={brand.id} value={brand.id}>
            {brand.name}
          </MultiSelect.Option>
        ))}
      </MultiSelect>
      {!!selectedBrandIds?.length && (
        <L.Horizontal>
          <div style={{ flexGrow: 1 }} />
          <IconButton aria-label="delete" onClick={onDelete} color="secondary" size="large">
            <Icon name="delete" />
          </IconButton>
        </L.Horizontal>
      )}
    </L.Vertical>
  )
}
