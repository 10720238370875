import React from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// import { DefaultPageLayout } from "../containers/DefaultPageLayout";
import { DefaultPageLayout } from 'containers'

import { NotFoundPage } from './404'
import { LoginPage } from './login'
import { HomePage } from './Home'
import { AfterSalesPage } from './after-sales'
import {
  AdminBrandsPage,
  AdminLocationsPage,
  AdminTaskTypePage,
  MonitoringLocosoftSyncPage,
  UsersPage,
  VehiclesImportJobs,
} from './admin'
import routes from 'config/routes'
import { EzAuthRequired, useEzAuthUserHasRoles, UserRole } from '@lib/ezauth'
// import { CustomerVehiclesPage } from './after-sales/customer-vehicles'
// import { CommuncationAfterSalesPage } from '../../__communcation__/after-sales'
// import { CommuncationAfterSalesDetailsPage } from '../../__communcation__/after-sales/month'
import { AfterSalesPotentialsPage } from './after-sales/customer-vehciles'
import { AdminSuppliersPage } from './admin/suppliers'
import { AdminsubDealersPage } from './admin/subDealers'
import { DealerVehicleImport } from './disposition/dealer-vehicle-import'
import { DealerVehiclesPage } from './disposition/dealer-vehicles'
import { AssetLinksPage } from './admin/asset-links'
import { VehiclesWithSyncErrors } from './data-quality/dealer-vehicles/sync-errors'
import { withErrorBoundary } from '../components/ErrorBoundary'
import { ErrorFallback } from '../containers/ErrorFallback'
import { TicketTemplatesPage } from './admin/automation/vehicles'
import { AdminEmbeddedPagesPage } from './admin/embedded-pages'
import { ForgotPasswordPage } from './forgot-password'
import { VehiclesWithMixedUpHistories } from './data-quality/dealer-vehicles/mixed-up-histories'
import { MarkedForDeleteVehicles } from './data-quality/dealer-vehicles/marked-for-delete'
import { SalesReportsPage } from './disposition/sales-reports'

const Structure = () => {
  const isAdmin = useEzAuthUserHasRoles(UserRole.ADMIN)
  const isSalesManagerOrVehicleDispatcher = useEzAuthUserHasRoles([UserRole.SALES_MANAGER, UserRole.VEHICLE_DISPATCHER])
  const isSales = useEzAuthUserHasRoles(UserRole.SALES)
  return (
    <Router>
      <Switch>
        <Route exact path={routes.forgotPassword()}>
          <ForgotPasswordPage />
        </Route>
      </Switch>
      <EzAuthRequired fallback={<LoginPage />}>
        <DefaultPageLayout>
          <Switch>
            <Route exact path={routes.home()}>
              <HomePage />
            </Route>
            {/* <Route path={routes.afterSales.customerVehicles()}>
              <CustomerVehiclesPage />
            </Route> */}
            <Route path={routes.afterSales.customerVehicles()}>
              <AfterSalesPotentialsPage />
            </Route>
            <Route path={routes.afterSales()}>
              <AfterSalesPage />
            </Route>
            {/* <Route path={routes.communcation.afterSales.details()}>
              <CommuncationAfterSalesDetailsPage />
            </Route> */}
            {(isSalesManagerOrVehicleDispatcher || isAdmin) && (
              <Route exact path={routes.disposition.dealerVehicleImport()}>
                <DealerVehicleImport />
              </Route>
            )}
            {(isSalesManagerOrVehicleDispatcher || isAdmin || isSales) && (
              <Route exact path={routes.disposition.dealerVehicles()}>
                <DealerVehiclesPage />
              </Route>
            )}
            {(isSalesManagerOrVehicleDispatcher || isAdmin || isSales) && (
              <Route exact path={routes.disposition.salesReports()}>
                <SalesReportsPage />
              </Route>
            )}
            {/* <Route path={routes.communcation.afterSales()}>
              <CommuncationAfterSalesPage />
            </Route> */}

            {isAdmin && (
              <Route exact path={routes.dataQualityVehicles.syncErrors()}>
                <VehiclesWithSyncErrors />
              </Route>
            )}
            {isAdmin && (
              <Route exact path={routes.dataQualityVehicles.mixedUpHistories()}>
                <VehiclesWithMixedUpHistories />
              </Route>
            )}
            {isAdmin && (
              <Route exact path={routes.dataQualityVehicles.markedForDelete()}>
                <MarkedForDeleteVehicles />
              </Route>
            )}

            {/* TODO: limit to salesmanager or vehicle dispatcher or admin*/}
            <Route exact path={routes.admin.brands()}>
              <AdminBrandsPage />
            </Route>
            <Route exact path={routes.admin.locations()}>
              <AdminLocationsPage />
            </Route>
            <Route exact path={routes.admin.supplier()}>
              <AdminSuppliersPage />
            </Route>
            <Route exact path={routes.admin.subDealer()}>
              <AdminsubDealersPage />
            </Route>
            {/* end: limit to salesmanager or vehicle dispatcher or admin*/}

            {/* TODO: limit to admin */}
            <Route exact path={routes.admin.taskTypes()}>
              <AdminTaskTypePage />
            </Route>
            <Route exact path={routes.admin.embeddedPages()}>
              <AdminEmbeddedPagesPage />
            </Route>
            <Route exact path={routes.admin.assetLinks()}>
              <AssetLinksPage />
            </Route>
            <Route exact path={routes.automation.vehicles()}>
              <TicketTemplatesPage context={{ entity: 'vehicle' }} />
            </Route>
            <Route exact path={routes.monitoring.locosoftSync()}>
              <MonitoringLocosoftSyncPage />
            </Route>
            <Route exact path={routes.admin.users()}>
              <UsersPage />
            </Route>
            <Route exact path={routes.importJobs.vehicles()}>
              <VehiclesImportJobs />
            </Route>
            {/* TODO: end limit to admin */}

            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </DefaultPageLayout>
      </EzAuthRequired>
    </Router>
  )
}

export const PageStructure = withErrorBoundary(Structure, { FallbackComponent: ErrorFallback })

export { NotFoundPage }
