import { LinkRegistry } from '../ApolloLinkRegistry'
import { RetryLink } from '@apollo/client/link/retry'

LinkRegistry.add('retry', {
  createLink: () =>
    new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: 5,
      },
    }),
  createComponent: () => null,
  order: 5,
})
