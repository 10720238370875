import React from 'react'
import { SiteMetadata } from '../../../../components'
import { DataQualityMixedUpInvoicesList } from '../../../../containers/DataQualityMixedUpInvoicesList'

export const VehiclesWithMixedUpHistories = () => {
  return (
    <>
      <SiteMetadata title="Datenqualität - Fahrzeuge mit fehlerhaften Rechnungen" />
      <DataQualityMixedUpInvoicesList />
    </>
  )
}
