import React from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { DateFns } from 'utils'
import { PhoneNoLink } from 'components'
import { ModelReleaseYear } from '../containers/ModelGroupReleaseYearSelect'

export const useFormatter = () => {
  const { t } = useTranslation()

  const formatModelReleaseYear = (releaseYear: number) =>
    releaseYear === ModelReleaseYear.UNKNOWN ? 'Unbekannt' : (releaseYear || '-')

  const formatNumber = useCallback(
    (n: number, formatOptions?: Intl.NumberFormatOptions) => {
      return n && !Number.isNaN(n) ? n.toLocaleString('de-DE', formatOptions) : t('N/A')
    },
    [t]
  )

  const formatDateString = useCallback(
    (date: string | Date) => {
      const newDate = new Date(date)
      return date
        ? DateFns.serializeDate(newDate) === '1970-01-01'
          ? t('N/A')
          : DateFns.format(newDate, 'dd.MM.yyyy')
        : t('N/A')
    },
    [t]
  )

  const formatDateTimeString = useCallback(
    (date: string) => {
      const newDate = new Date(date)
      return date
        ? DateFns.serializeDate(newDate) === '1970-01-01'
          ? t('N/A')
          : DateFns.format(newDate, 'dd.MM.yyyy hh:mm')
        : t('N/A')
    },
    [t]
  )

  const formatCO2Measurement = (value: number) => (value ? `${value} (g/km)` : '-')

  const formatDetailedDateTimeString = useCallback(
    (date: string) => {
      const newDate = new Date(date)
      return date
        ? DateFns.serializeDate(newDate) === '1970-01-01'
          ? t('N/A')
          : DateFns.format(newDate, 'dd.MM.yyyy hh:mm:ss.SSS O')
        : t('N/A')
    },
    [t]
  )

  const formatPhoneNo = useCallback(
    (phoneNo: string) => {
      return <>{phoneNo.length > 0 ? <PhoneNoLink phoneNo={phoneNo}>{phoneNo}</PhoneNoLink> : <>{t('N/A')}</>}</>
    },
    [t]
  )

  return {
    formatNumber,
    formatDateString,
    formatDateTimeString,
    formatPhoneNo,
    formatDetailedDateTimeString,
    formatCO2Measurement,
    formatModelReleaseYear,
  }
}
