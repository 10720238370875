import { Select } from '../../components'
import React, { CSSProperties } from 'react'
import { AdminModelGroupListQueryResult } from '../../gql'

interface ModelGroupSelectProps {
  groups: AdminModelGroupListQueryResult['data']['model_groups']
  value: string
  onSelect: (id: string) => void
  name?: string
  hideLabel?: boolean
  label?: string
  disabled?: boolean
  style?: CSSProperties
}

export const ModelGroupSelect = (props: ModelGroupSelectProps) => {
  return (
    <Select
      value={props.value}
      disabled={props.disabled}
      onChange={(evt) => props.onSelect(evt.target.value)}
      name={props.name ?? 'modelGroupSelect'}
      label={props.hideLabel ? null : props.label ?? 'Modell Gruppe'}
      placeholder="Model Gruppe wählen..."
      style={props.style}
    >
      {props.groups.map((g) => (
        <Select.Option value={g.id} key={g.id}>
          {g.name}
        </Select.Option>
      ))}
    </Select>
  )
}
