// import { DeprecatedThemeOptions } from '@mui/material/styles'
import { transparentize } from 'polished'
import { blue, grey, red, teal, yellow } from './colors'

export const defaultOptions = {
  typography: {
    fontSize: 12,
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.4rem',
    },
    h4: {
      fontSize: '1.2rem',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '0.9rem',
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: blue[500],
      // main: '#2196f3',
    },
    secondary: {
      main: teal[500],
    },
    background: {
      default: grey[50],
      paper: 'white',
      // paper: transparentize(0.15, '#ffffff')
    },
    error: {
      main: red[500],
    },
    success: {
      main: teal[500],
    },
    warning: {
      main: yellow[500],
    },
    info: {
      main: teal[500],
    },
    grey,
    action: {
      // selected: transparentize(0.82, teal[500]),
      selected: teal[50],
      // hover: 'white'
      hover: transparentize(0.5, grey[50]),
    },
  },
}
