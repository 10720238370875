import clsx from 'clsx'
import { Icon } from 'components/Icon'
import { IconButton } from 'components/IconButton'
import styles from './DataValue.module.css'
import React from 'react'
import { CSSProperties } from 'react'
import { Avatar } from 'components/Avatar'

export enum DataSource {
  LOCOSOFT = 'LOCOSOFT',
  PCORE = 'PCORE',
}

export type DataValueProps = {
  label: string
  style?: CSSProperties
  className?: string
  onEdit?: () => void
  dataSource?: DataSource
}

const SEPARATOR = '1px solid rgba(0,0,0,0.15)'
export const DataValue: React.FC<DataValueProps> = (props) => {
  return (
    <div
      className={clsx(styles.value, 'relative flex space-x-4 py-1 items-center', props.className)}
      style={{ borderTop: SEPARATOR }}
    >
      <div className="w-48 text-sm">
        {props?.dataSource === DataSource.LOCOSOFT && (
          <>
            <img src="/static/images/locosoft-logo.png" alt="Locosoft logo" width={20} height={20} className="inline" />{' '}
          </>
        )}
        {props?.dataSource === DataSource.PCORE && (
          <>
            <Avatar
              alt={'Datasource: Pulse'}
              className="w-5 h-5 inline-flex bg-background-default text-primary-main text-sm"
            >
              {'P'}
            </Avatar>{' '}
          </>
        )}
        {props.label}:
      </div>

      <div className="font-semibold flex-1">{props.children}</div>
      {props.onEdit && (
        <IconButton
          size="small"
          onClick={() => props.onEdit()}
          aria-label="edit"
          className={clsx(styles.value_edit, 'relative right-0')}
        >
          <Icon name="edit" fontSize="small" />
        </IconButton>
      )}
    </div>
  )
}
