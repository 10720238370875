import React, { useState } from 'react'
import { SiteMetadata } from 'components'
import { VehiclesListConfig, VehiclesListType } from 'containers/ListConfig/Vehicles/List/Config'
import { VehiclesList } from '../../../containers/ListConfig/Vehicles/List'
import { BookmarkCollapsable } from '../../../components/Collapsable/Bookmark'
import { useVehicleListConfigs } from '../../../containers/ListConfig/Vehicles/List/list-configs'
import { ListPreviewCard } from '../../../containers/ListConfig/Vehicles/List/PreviewCard'
import { useVehiclesStore } from '../../../containers/ListConfig/Vehicles/hooks/useData'
import { BrandTabBar } from '../../../containers/BrandSelect/TabBar'
import * as L from 'layouts'

export const DealerVehicleImport = () => {
  const [listType, setListType] = useState<VehiclesListType>()
  const { reset } = useVehiclesStore()
  const types = [VehiclesListType.IMPORT_NEW, VehiclesListType.IMPORT_USED]
  const vehicleListConfigs = useVehicleListConfigs()
  const options = types.map((type) => ({ title: vehicleListConfigs[type].name, value: type }))
  const handleSelect = (listType: VehiclesListType) => {
    reset()
    setListType(listType)
  }
  return (
    <>
      <SiteMetadata title={vehicleListConfigs[listType]?.name} />
      {!listType && (
        <div className={'relative top-16 flex flex-row flex-wrap gap-4 justify-center'}>
          {types.map((type) => (
            <ListPreviewCard
              key={`preview-card-${type}`}
              name={vehicleListConfigs[type].name}
              description={vehicleListConfigs[type].description}
              onClick={() => handleSelect(type)}
            />
          ))}
        </div>
      )}
      <VehiclesListConfig type={listType}>
        <L.Horizontal className="absolute top-0 left-0 align-top z-drawer" style={{ alignItems: 'flex-start' }}>
          {listType && (
            <>
              <BookmarkCollapsable
                selected={listType}
                title={'Listen'}
                options={options}
                onSelect={(listType: VehiclesListType) => handleSelect(listType)}
                suppressBrands
              />
              <BrandTabBar onlyHouseBrands />
            </>
          )}
        </L.Horizontal>
        {listType && <VehiclesList className={'mt-8'} />}
      </VehiclesListConfig>
    </>
  )
}
