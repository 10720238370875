import { Chip, ChipProps } from 'components/Chip'
import { Dealer_Vehicle_Type_Enum } from 'gql/generated'
import React from 'react'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

export interface DealerVehicleTypeChipProps {
  type: Dealer_Vehicle_Type_Enum
  className?: string
  style?: CSSProperties
}

export const DealerVehicleTypeChip = (props: DealerVehicleTypeChipProps) => {
  const { t } = useTranslation()
  const color = useColor(props)
  if (!props.type) {
    return <>{'-'}</>
  }
  return <Chip color={color}>{t(`DealerVehicleType.${props.type}`)}</Chip>
}

const useColor = (props: DealerVehicleTypeChipProps): ChipProps['color'] => {
  switch (props.type) {
    case Dealer_Vehicle_Type_Enum.NewCar:
    case Dealer_Vehicle_Type_Enum.DemonstrationCar:
    case Dealer_Vehicle_Type_Enum.UsedCar:
      return 'default'
  }
}
