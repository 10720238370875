import { useCallback, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'

export const useSearchQuery = <T extends {}>() => {
  const history = useHistory()

  const searchParams = new URLSearchParams(history.location.search)
  const searchParamsRef = useRef(searchParams)
  searchParamsRef.current = searchParams
  const setValue = useCallback(
    (key: string, value: string) => {
      searchParamsRef.current.set(key, value)
      history.push({
        pathname: history.location.pathname,
        search: `?${searchParamsRef.current.toString()}`,
      })
    },
    [history]
  )

  const actions = useMemo(
    () => ({
      setValue,
    }),
    [setValue]
  )

  const queryObject = Object.fromEntries(searchParams) as T

  return [queryObject, actions] as const
}
