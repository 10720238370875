import { Card } from 'components/Card'
import { Tabs } from 'components/Tabs'
import { useAdminBrandDetailsQuery } from 'gql'
import React from 'react'
import { BrandDetails } from './01-details'
import { Badge } from 'components/Badge'
import { AdminModelGroups } from './02-model-groups'
import { AdminModels, AdminModelsVariant } from './03-all-models'
import { AdminBrandEmbeddedPages } from './06-embedded_pages'

interface AdminBrandDetailProps {
  brandId: string
}

export const AdminBrandDetail = (props: AdminBrandDetailProps) => {
  const query = useAdminBrandDetailsQuery({ variables: { id: props.brandId } })
  // const unassignedModelCount = query.data?.brand?.metadata?.unassignedActiveModelCount ?? 0
  const unassignedModelCount = 0

  return (
    <Tabs>
      <Tabs.TabList>
        <Tabs.Tab label="Details" />
        <Tabs.Tab label="Gruppen" />
        <Tabs.Tab label="Alle Modelle" />
        <Tabs.Tab
          icon={
            <Badge content={unassignedModelCount} color="danger" max={99}>
              <span style={{ padding: '0 4px' }}>Lagernde Modelle</span>
            </Badge>
          }
        />
        <Tabs.Tab
          icon={
            <Badge content={unassignedModelCount} color="danger" max={99}>
              <span style={{ padding: '0 4px' }}>Neue Modelle</span>
            </Badge>
          }
        />
        <Tabs.Tab label={'Externe Links'} />
      </Tabs.TabList>
      <Card className="p-4">
        <Tabs.TabPanes>
          <Tabs.TabPane>
            <BrandDetails brand={query.data?.brand} loading={query.loading} />
          </Tabs.TabPane>
          <Tabs.TabPane>
            <AdminModelGroups brandId={props.brandId} />
          </Tabs.TabPane>
          <Tabs.TabPane>
            <AdminModels brandId={props.brandId} />
          </Tabs.TabPane>
          <Tabs.TabPane>
            <AdminModels variant={AdminModelsVariant.IN_STOCK} brandId={props.brandId} />
          </Tabs.TabPane>
          <Tabs.TabPane>
            <AdminModels variant={AdminModelsVariant.NEW} brandId={props.brandId} />
          </Tabs.TabPane>
          <Tabs.TabPane>
            <AdminBrandEmbeddedPages brandId={props.brandId} />
          </Tabs.TabPane>
        </Tabs.TabPanes>
      </Card>
    </Tabs>
  )
}
