import create from 'zustand'
import { useCallback } from 'react'
import { ezAuthEventHub, EzAuthEventType, useEzAuth } from '@lib/ezauth'

interface UserSettings {
  locationId?: string
}

const defaultAppSettings: UserSettings = {
  locationId: null,
}

const useStore = create<UserSettings & { set: (UserSettings) => void }>((set) => ({
  ...defaultAppSettings,
  set,
}))

ezAuthEventHub.subscribe((event) => {
  switch (event.type) {
    case EzAuthEventType.INITIALIZED:
    case EzAuthEventType.SIGNED_IN:
      if (event.user) {
        const locationId = getCachedLocationId(event.user.id) || event.user.defaultLocationId
        useStore.setState({ locationId: locationId || '' })
      }
      break
    case EzAuthEventType.SIGNED_OUT:
      useStore.setState(defaultAppSettings)
      break
  }
})

export const useClientState = useStore

export const useAppLocationId = () => {
  const [auth] = useEzAuth()
  const updateLocationId = useCallback(
    (locationId: string) => {
      if (auth.authenticated) {
        const userId = auth.user.id
        cacheLocationId(userId, locationId)
      }
      useStore.setState({ locationId: locationId })
    },
    [auth.authenticated, auth.user.id]
  )

  const [locationId] = useStore((state) => [state.locationId, (locationId: string) => state.set({ locationId })])
  return [locationId, updateLocationId] as const
}

const CACHE_KEY = 'APP_LOCATION'
const cacheLocationId = (userId: string, locationId: string) => {
  localStorage.setItem(`${userId}#${CACHE_KEY}`, locationId)
}
const getCachedLocationId = (userId: string) => {
  return localStorage.getItem(`${userId}#${CACHE_KEY}`)
}
