import clsx from 'clsx'
import React, { CSSProperties, useContext, useEffect, useRef } from 'react'
import { ScrollSyncContext } from '.'
import styles from './ScrollSync.module.css'

interface ScrollSyncProps {
  group?: string
  attachTo?: HTMLDivElement
  className?: string
  style?: CSSProperties
}

export const ScrollSyncPane: React.FC<ScrollSyncProps> = (props) => {
  const { group = 'default' } = props
  const ref = useRef<HTMLDivElement>()
  const { registerPane, unregisterPane } = useContext(ScrollSyncContext)

  useEffect(() => {
    const node = ref.current
    registerPane(props.attachTo || node, [group])
    return () => {
      unregisterPane(props.attachTo || node, [group])
    }
  }, [group, props.attachTo, registerPane, unregisterPane])

  return (
    <div ref={ref} className={clsx(styles.hideScrollbar, props.className)} style={props.style}>
      {props.children}
    </div>
  )
}
