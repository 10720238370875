import { isEqual, remove, uniqBy, uniqWith } from 'lodash'
import { useCallback, useContext, useMemo } from 'react'
import { ListConfigContext, RowHighlight } from '../ListConfig'
import { useListManipilationContext } from './unit'
import { Ticket_Bool_Exp, Vehicle_Bool_Exp } from '../../gql'

export type General_Bool_Exp<T extends EntityType> = EntityBoolExpMap[T]
export type EntityBoolExpMap = {
  Vehicle: Vehicle_Bool_Exp
  Ticket: Ticket_Bool_Exp
}
export type EntityType = keyof EntityBoolExpMap

export const useFilterOperations = <T extends EntityType>() => {
  const operations = useOperations<T>()
  const { filter, setFilter } = useContext(ListConfigContext)
  const getChildren = useCallback(
    (field: string, parentExpression: General_Bool_Exp<T>) => operations.getChildren(filter, field, parentExpression),
    [filter, operations]
  )
  const getAll = useCallback((field: string) => operations.getAll(filter, field), [filter, operations])
  const getStandalone = useCallback((field: string) => operations.getStandalone(filter, field), [filter, operations])
  const setStandalone = useCallback(
    (expression: General_Bool_Exp<T>) => setFilter(operations.setStandalone(filter, expression)),
    [filter, operations, setFilter]
  )
  const setStandalones = useCallback(
    (expressions: General_Bool_Exp<T>[]) => setFilter(operations.setStandalones(filter, expressions)),
    [filter, operations, setFilter]
  )
  const setChild = useCallback(
    (expression: General_Bool_Exp<T>, parentExpression: General_Bool_Exp<T>) =>
      setFilter(operations.setChild(filter, expression, parentExpression)),
    [filter, operations, setFilter]
  )

  const deleteChild = useCallback(
    (expression: General_Bool_Exp<T>, parentExpression: General_Bool_Exp<T>) =>
      setFilter(operations.deleteChild(filter, expression, parentExpression)),
    [filter, operations, setFilter]
  )
  const deleteChildren = useCallback(
    (field: string, parentExpression: General_Bool_Exp<T>, operator?: '_gt' | '_lt') =>
      setFilter(operations.deleteChildren(filter, field, parentExpression, operator)),
    [filter, operations, setFilter]
  )
  const deleteExpression = useCallback(
    (expression: General_Bool_Exp<T>) => {
      setFilter(operations.deleteExpression(filter, expression))
    },
    [filter, operations, setFilter]
  )
  const deleteExpressions = useCallback(
    (expressions: General_Bool_Exp<T>[]) => setFilter(operations.deleteExpressions(filter, expressions)),
    [filter, operations, setFilter]
  )
  const deleteAll = useCallback(
    (field: string) => setFilter(operations.deleteAll(filter, field)),
    [filter, operations, setFilter]
  )
  const deleteStandalone = useCallback(
    (expression: General_Bool_Exp<T>) => setFilter(operations.deleteStandalone(filter, expression)),
    [filter, operations, setFilter]
  )
  const deleteAllStandalones = useCallback(
    (field: string) => setFilter(operations.deleteAllStandalones(filter, field)),
    [filter, operations, setFilter]
  )
  const cleanSetChildren = useCallback(
    (expressions: General_Bool_Exp<T>[], parentExpression: General_Bool_Exp<T>, operator?: '_gt' | '_lt') =>
      setFilter(operations.cleanSetChildren(filter, expressions, parentExpression, operator)),
    [filter, operations, setFilter]
  )
  const cleanSetStandalones = useCallback(
    (expressions: General_Bool_Exp<T>[]) => setFilter(operations.cleanSetStandalones(filter, expressions)),
    [filter, operations, setFilter]
  )
  const setStandalonesWithDelete = useCallback(
    (expressions: General_Bool_Exp<T>[], expressionsToDelete: General_Bool_Exp<T>[]) =>
      setFilter(operations.setStandalonesWithDelete(filter, expressions, expressionsToDelete)),
    [filter, operations, setFilter]
  )
  const setAlternativeExpression = useCallback(
    (expression: General_Bool_Exp<T>, leadingExpression: General_Bool_Exp<T>) =>
      setFilter(operations.setAlternativeExpression(filter, expression, leadingExpression)),
    [filter, operations, setFilter]
  )
  const setAlternativeExpressions = useCallback(
    (expressions: General_Bool_Exp<T>[], leadingExpression: General_Bool_Exp<T>) =>
      setFilter(operations.setAlternativeExpressions(filter, expressions, leadingExpression)),
    [filter, operations, setFilter]
  )
  return useMemo(
    () => ({
      cleanSetStandalones,
      setStandalonesWithDelete,
      cleanSetChildren,
      getChildren,
      getAll,
      getStandalone,
      setStandalone,
      setStandalones,
      setChild,
      setAlternativeExpression,
      setAlternativeExpressions,
      deleteChild,
      deleteChildren,
      deleteExpression,
      deleteExpressions,
      deleteAll,
      deleteStandalone,
      deleteAllStandalones,
    }),
    [
      cleanSetStandalones,
      setStandalonesWithDelete,
      cleanSetChildren,
      getChildren,
      getAll,
      getStandalone,
      setStandalone,
      setStandalones,
      setChild,
      setAlternativeExpression,
      setAlternativeExpressions,
      deleteChild,
      deleteChildren,
      deleteExpression,
      deleteExpressions,
      deleteAll,
      deleteStandalone,
      deleteAllStandalones,
    ]
  )
}

export interface useHighlightOperationsI<T extends EntityType> {
  (): {
    cleanSetStandalones: (expressions: General_Bool_Exp<T>[]) => void
    cleanSetChildren: (
      expressions: General_Bool_Exp<T>[],
      parentExpression: General_Bool_Exp<T>,
      operator?: '_gt' | '_lt'
    ) => void
    setStandalonesWithDelete: (expressions: General_Bool_Exp<T>[], expressionsToDelete: General_Bool_Exp<T>[]) => void
    deleteExpression: (expression: General_Bool_Exp<T>) => void
    deleteExpressions: (expressions: General_Bool_Exp<T>[]) => void
    setStandalone: (expression: General_Bool_Exp<T>) => void
    setStandalones: (expressions: General_Bool_Exp<T>[]) => void
    setAlternativeExpression: (expression: General_Bool_Exp<T>, leadingExpression: General_Bool_Exp<T>) => void
    setAlternativeExpressions: (expressions: General_Bool_Exp<T>[], leadingExpression: General_Bool_Exp<T>) => void
    getAll: (field: string, highlightIdx?: number) => General_Bool_Exp<T>[]
    getChildren: (field: string, parentExpression: General_Bool_Exp<T>, highlightIdx?: number) => General_Bool_Exp<T>[]
    getStandalone: (field: string, highlightIdx?: number) => General_Bool_Exp<T>[]
    setChild: (expression: General_Bool_Exp<T>, parentExpression: General_Bool_Exp<T>) => void
    deleteChild: (expression: General_Bool_Exp<T>, parentExpression: General_Bool_Exp<T>) => void
    deleteChildren: (entryField: string, parentExpression: General_Bool_Exp<T>, operator?: '_gt' | '_lt') => void
    deleteAll: (field: string, highlightIndex?: number) => void
    deleteStandalone: (expression: General_Bool_Exp<T>) => void
    deleteAllStandalones: (field: string) => void
  }
}

export function deriveFilterFromHasuraRepresentation<T extends EntityType>(hasuraRepresentation: string) {
  return (item: any): boolean => {
    const where: General_Bool_Exp<T> = JSON.parse(hasuraRepresentation ?? '{}')
    if (!where._and || !where._and.length) return true
    return (where._and as any).every((expression) => {
      if (!expression._or || !expression._or.length) return true
      return expression._or.some((exp) => {
        if (!exp._and || !exp._and.length) return true
        return exp._and.every((e) => {
          const field = Object.keys(e)[0]
          const operator = Object.keys(e[field])[0]
          if (operator === '_eq') {
            return item[field] === e[field][operator]
          } else if (operator === '_gt') {
            return item[field] > e[field][operator]
          } else if (operator === '_lt') {
            return item[field] < e[field][operator]
          } else if (operator === '_is_null') {
            return !item[field]
          } else {
            return false
          }
        })
      })
    })
  }
}

export const useHighlightOperations = <T extends EntityType>() => {
  const { setHighlights, highlights } = useContext(ListConfigContext)
  const { highlightIndex } = useListManipilationContext()
  const operations = useOperations<T>()

  const defaultHighlight = (entryField: string) => ({
    filter: () => false,
    filterHasuraRepresentation: undefined,
    entryField,
  })

  const setWrapper = useCallback(
    (expression: General_Bool_Exp<T> | string, cb: (highlight: RowHighlight<T>) => void) => {
      const value = Object.assign([], highlights ?? [])
      const entryField = typeof expression === 'string' ? expression : Object.keys(expression)[0]
      const highlight = value.splice(highlightIndex, 1)[0] ?? defaultHighlight(entryField)
      cb(highlight)
      highlight.filter = deriveFilterFromHasuraRepresentation(highlight.filterHasuraRepresentation)
      value.splice(highlightIndex, 0, highlight)
      setHighlights(value)
    },
    [highlightIndex, highlights, setHighlights]
  )

  const setStandalone = useCallback(
    (expression: General_Bool_Exp<T>) =>
      setWrapper(
        expression,
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.setStandalone(
            highlight.filterHasuraRepresentation,
            expression
          ))
      ),
    [operations, setWrapper]
  )
  const setStandalones = useCallback(
    (expressions: General_Bool_Exp<T>[]) =>
      setWrapper(
        expressions[0],
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.setStandalones(
            highlight.filterHasuraRepresentation,
            expressions
          ))
      ),
    [operations, setWrapper]
  )

  const setChild = useCallback(
    (expression: General_Bool_Exp<T>, parentExpression: General_Bool_Exp<T>) =>
      setWrapper(
        expression,
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.setChild(
            highlight.filterHasuraRepresentation,
            expression,
            parentExpression
          ))
      ),
    [operations, setWrapper]
  )
  const setAlternativeExpression = useCallback(
    (expression: General_Bool_Exp<T>, leadingExpression: General_Bool_Exp<T>) => {
      setWrapper(
        expression,
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.setAlternativeExpression(
            highlight.filterHasuraRepresentation,
            expression,
            leadingExpression
          ))
      )
    },
    [operations, setWrapper]
  )

  const setAlternativeExpressions = useCallback(
    (expressions: General_Bool_Exp<T>[], leadingExpression: General_Bool_Exp<T>) => {
      setWrapper(
        expressions[0],
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.setAlternativeExpressions(
            highlight.filterHasuraRepresentation,
            expressions,
            leadingExpression
          ))
      )
    },
    [operations, setWrapper]
  )

  const deleteExpression = useCallback(
    (expression: General_Bool_Exp<T>) =>
      setWrapper(
        expression,
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.deleteExpression(
            highlight.filterHasuraRepresentation,
            expression
          ))
      ),
    [operations, setWrapper]
  )
  const deleteExpressions = useCallback(
    (expressions: General_Bool_Exp<T>[]) =>
      setWrapper(
        expressions[0],
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.deleteExpressions(
            highlight.filterHasuraRepresentation,
            expressions
          ))
      ),
    [operations, setWrapper]
  )

  const deleteAll = useCallback(
    (field: string, highlightIndex?: number) => {
      const value = Object.assign([], highlights)
      value.splice(highlightIndex, 1)
      setHighlights(value)
    },
    [highlights, setHighlights]
  )
  const deleteStandalone = useCallback(
    (expression: General_Bool_Exp<T>) =>
      setWrapper(
        expression,
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.deleteStandalone(
            highlight.filterHasuraRepresentation,
            expression
          ))
      ),
    [operations, setWrapper]
  )

  const deleteChild = useCallback(
    (expression: General_Bool_Exp<T>, parentExpression: General_Bool_Exp<T>) =>
      setWrapper(
        expression,
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.deleteChild(
            highlight.filterHasuraRepresentation,
            expression,
            parentExpression
          ))
      ),
    [operations, setWrapper]
  )

  const deleteChildren = useCallback(
    (entryField: string, parentExpression: General_Bool_Exp<T>, operator?: '_gt' | '_lt') =>
      setWrapper(
        entryField,
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.deleteChildren(
            highlight.filterHasuraRepresentation,
            entryField,
            parentExpression,
            operator
          ))
      ),
    [operations, setWrapper]
  )

  const cleanSetChildren = useCallback(
    (expressions: General_Bool_Exp<T>[], parentExpression: General_Bool_Exp<T>, operator?: '_gt' | '_lt') =>
      setWrapper(
        expressions[0],
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.cleanSetChildren(
            highlight.filterHasuraRepresentation,
            expressions,
            parentExpression,
            operator
          ))
      ),
    [operations, setWrapper]
  )

  const cleanSetStandalones = useCallback(
    (expressions: General_Bool_Exp<T>[]) =>
      setWrapper(
        expressions[0],
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.cleanSetStandalones(
            highlight.filterHasuraRepresentation,
            expressions
          ))
      ),
    [operations, setWrapper]
  )

  const setStandalonesWithDelete = useCallback(
    (expressions: General_Bool_Exp<T>[], expressionsToDelete: General_Bool_Exp<T>[]) =>
      setWrapper(
        expressions[0],
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.setStandalonesWithDelete(
            highlight.filterHasuraRepresentation,
            expressions,
            expressionsToDelete
          ))
      ),
    [operations, setWrapper]
  )

  const getAll = useCallback(
    (field: string, highlightIdx?: number) => {
      const highlight = highlights?.slice(highlightIdx, highlightIdx + 1)?.[0] ?? defaultHighlight(field)
      return operations.getAll(highlight.filterHasuraRepresentation, field)
    },
    [highlights, operations]
  )

  const getChildren = useCallback(
    (field: string, parentExpression: General_Bool_Exp<T>, highlightIdx?: number) => {
      const highlight = highlights?.slice(highlightIdx, highlightIdx + 1)?.[0] ?? defaultHighlight(field)
      return operations.getChildren(highlight.filterHasuraRepresentation, field, parentExpression)
    },
    [highlights, operations]
  )

  const getStandalone = useCallback(
    (field: string, highlightIdx?: number) => {
      const highlight = highlights?.slice(highlightIdx, highlightIdx + 1)?.[0] ?? defaultHighlight(field)
      return operations.getStandalone(highlight.filterHasuraRepresentation, field)
    },
    [highlights, operations]
  )

  const deleteAllStandalones = useCallback(
    (field: string) =>
      setWrapper(
        field,
        (highlight) =>
          (highlight.filterHasuraRepresentation = operations.deleteAllStandalones(
            highlight.filterHasuraRepresentation,
            field
          ))
      ),
    [operations, setWrapper]
  )

  return useMemo(
    () => ({
      cleanSetStandalones,
      setStandalonesWithDelete,
      cleanSetChildren,
      deleteExpression,
      deleteExpressions,
      setStandalone,
      setStandalones,
      setAlternativeExpression,
      setAlternativeExpressions,
      getAll,
      getChildren,
      getStandalone,
      setChild,
      deleteChild,
      deleteChildren,
      deleteAll,
      deleteStandalone,
      deleteAllStandalones,
    }),
    [
      cleanSetStandalones,
      setStandalonesWithDelete,
      cleanSetChildren,
      deleteExpression,
      deleteExpressions,
      setStandalone,
      setStandalones,
      setAlternativeExpression,
      setAlternativeExpressions,
      getAll,
      getChildren,
      getStandalone,
      setChild,
      deleteChild,
      deleteChildren,
      deleteAll,
      deleteStandalone,
      deleteAllStandalones,
    ]
  )
}
export const useOperations = <T extends EntityType>() => {
  const getChildren = (filter: string, field: string, parentExpression: General_Bool_Exp<T>) => {
    const parentField = Object.keys(parentExpression)[0]

    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const isolatedExpressions = where?._and
      ?.map((expressions) => {
        if (!expressions._or || !expressions._or.length) return undefined
        return expressions._or
          .map((andExpressions) => {
            if (!andExpressions._and || !andExpressions._and.length) {
              return undefined
            }
            if (
              andExpressions._and.length > 1 &&
              andExpressions._and.some((exp) => exp?.[field]) &&
              isEqual(andExpressions._and[0], parentExpression)
            ) {
              return andExpressions._and.filter((exp) => exp?.[field])
            } else {
              return undefined
            }
          })
          .flat()
          .filter((exp) => !!exp)
      })
      .flat()
      .filter((exp) => !!exp)
    return uniqWith(
      isolatedExpressions,
      (expA, expB) => expA[field] === expB[field] && expA[parentField] === expB[parentField]
    )
  }

  const getAll = (filter: string, field: string) => {
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const isolatedExpressions = where?._and
      ?.map((expressions) => {
        if (!expressions._or || !expressions._or.length) return undefined
        return expressions._or
          .map((andExpressions) => {
            if (!andExpressions._and || !andExpressions._and.length) return undefined
            return andExpressions._and
              .map((expression) => (expression?.[field] ? expression : undefined))
              .filter((exp) => !!exp)
          })
          .flat()
          .filter((exp) => !!exp)
      })
      .flat()
      .filter((exp) => !!exp)
    return (
      uniqBy(
        isolatedExpressions,
        (e) => e?.[field]?._eq || e?.[field]?._is_null || e?.[field]?._gt || e?.[field]?._lt
      ) ?? []
    )
  }

  const getStandalone = (filter: string, field: string) => {
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const isolatedExpressions = where?._and
      ?.map((expressions) => {
        if (!expressions._or || !expressions._or.length) return undefined
        return expressions._or
          .map((andExpressions) => {
            if (!andExpressions._and || !andExpressions._and.length) {
              return undefined
            }
            if (andExpressions._and.length >= 1 && andExpressions._and[0]?.[field]) {
              return andExpressions._and.filter((exp) => exp?.[field])
            } else {
              return undefined
            }
          })
          .flat()
          .filter((exp) => !!exp)
      })
      .flat()
      .filter((exp) => !!exp)
    return (
      uniqBy(
        isolatedExpressions,
        (e) => e?.[field]?._eq || e?.[field]?._is_null || e?.[field]?._gt || e?.[field]?._lt
      ) ?? []
    )
  }

  const setStandalones = (filter: string, expressions: General_Bool_Exp<T>[]) => {
    const field = Object.keys(expressions[0])[0]
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const andExpressions = (where?._and as General_Bool_Exp<T>[]) ?? []
    const isolatedExpressions = remove(andExpressions, (andExpression) => {
      if (!andExpression?._or || !andExpression?._or?.length) return false
      return (andExpression._or as any).every((orExpression) => {
        if (!orExpression?._and || !orExpression._and?.length) return false
        return !!orExpression._and[0]?.[field]
      })
    })
    if (isolatedExpressions.length === 1) {
      isolatedExpressions[0]._or.push({ _and: expressions as any })
    } else if (!isolatedExpressions.length) {
      isolatedExpressions.push({ _or: [{ _and: expressions }] } as any)
    }
    const modifiedExpressions = andExpressions.concat(isolatedExpressions)
    return JSON.stringify({ _and: modifiedExpressions })
  }

  const setStandalone = (filter: string, expression: General_Bool_Exp<T>) => {
    const field = Object.keys(expression)[0]
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const andExpressions = (where?._and as General_Bool_Exp<T>[]) ?? []
    const isolatedExpressions = remove(andExpressions, (andExpression) => {
      if (!andExpression?._or || !andExpression?._or?.length) return false
      return (andExpression._or as any).every((orExpression) => {
        if (!orExpression?._and || !orExpression._and?.length) return false
        return !!orExpression._and[0]?.[field]
      })
    })
    if (isolatedExpressions.length === 1) {
      isolatedExpressions[0]._or.push({ _and: [expression as any] })
    } else if (!isolatedExpressions.length) {
      isolatedExpressions.push({ _or: [{ _and: [expression] }] } as any)
    }
    const modifiedExpressions = andExpressions.concat(isolatedExpressions)
    return JSON.stringify({ _and: modifiedExpressions })
  }

  const setChild = (filter: string, expression: General_Bool_Exp<T>, parentExpression: General_Bool_Exp<T>) => {
    const parentField = Object.keys(parentExpression)[0]
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const andExpressions = (where?._and as General_Bool_Exp<T>[]) ?? []

    const isolatedExpressions = remove(andExpressions, (andExpression) => {
      if (!andExpression?._or || !andExpression?._or?.length) return false
      return (andExpression._or as any).every((orExpression) => {
        if (!orExpression?._and || !orExpression._and?.length) return false
        return orExpression._and[0]?.[parentField]
      })
    })
    if (isolatedExpressions.length === 1) {
      // 1. remove standalone expression if exists
      // 2. check if a (parent, child) tuple exists
      // 3. if not add it

      remove(isolatedExpressions[0]._or as General_Bool_Exp<T>[], (orExp) => {
        return (orExp._and as any).every((exp) => isEqual(exp, parentExpression))
      })
      if (
        !(isolatedExpressions[0]._or as any).find(
          (exp) => exp._and.some((e) => isEqual(e, expression)) && exp._and.some((e) => isEqual(e, parentExpression))
        )
      ) {
        isolatedExpressions[0]._or.push({ _and: [parentExpression as any, expression as any] })
      }
    }

    const modifiedExpressions = andExpressions.concat(isolatedExpressions)
    return JSON.stringify({ _and: modifiedExpressions })
  }

  const setAlternativeExpression = (
    filter: string,
    expression: General_Bool_Exp<T>,
    leadingExpression: General_Bool_Exp<T>
  ) => {
    const leadingField = Object.keys(leadingExpression)[0]
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const andExpressions = (where?._and as General_Bool_Exp<T>[]) ?? []
    const isolatedExpressions = remove(andExpressions, (andExpression) => {
      if (!andExpression?._or || !andExpression?._or?.length) return false
      return andExpression._or[0]?._and?.[0]?.[leadingField]
    })
    if (isolatedExpressions.length >= 1) {
      if (!isolatedExpressions[0]._or.some((orExpression) => isEqual(orExpression, expression))) {
        isolatedExpressions[0]._or.push({ _and: [expression as any] })
      }
    }
    const modifiedExpressions = andExpressions.concat(isolatedExpressions)
    return JSON.stringify({ _and: modifiedExpressions })
  }

  const setAlternativeExpressions = (
    filter: string,
    expressions: General_Bool_Exp<T>[],
    leadingExpression: General_Bool_Exp<T>
  ) => {
    const leadingField = Object.keys(leadingExpression)[0]
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const andExpressions = (where?._and as General_Bool_Exp<T>[]) ?? []
    const isolatedExpressions = remove(andExpressions, (andExpression) => {
      if (!andExpression?._or || !andExpression?._or?.length) return false
      return andExpression._or[0]?._and?.[0]?.[leadingField]
    })
    if (isolatedExpressions.length >= 1) {
      for (const expression of expressions) {
        if (!isolatedExpressions[0]._or.some((orExpression) => isEqual(orExpression, expression))) {
          isolatedExpressions[0]._or.push({ _and: [expression as any] })
        }
      }
    }
    const modifiedExpressions = andExpressions.concat(isolatedExpressions)
    return JSON.stringify({ _and: modifiedExpressions })
  }

  const setChildren = (filter: string, expressions: General_Bool_Exp<T>[], parentExpression: General_Bool_Exp<T>) => {
    const parentField = Object.keys(parentExpression)[0]
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const andExpressions = (where?._and as General_Bool_Exp<T>[]) ?? []

    const isolatedExpressions = remove(andExpressions, (andExpression) => {
      if (!andExpression?._or || !andExpression?._or?.length) return false
      return (andExpression._or as any).every((orExpression) => {
        if (!orExpression?._and || !orExpression._and?.length) return false
        return orExpression._and[0]?.[parentField]
      })
    })
    if (isolatedExpressions.length === 1) {
      // 1. remove standalone expression if exists
      // 2. check if a (parent, child) tuple exists
      // 3. if not add it

      remove(isolatedExpressions[0]._or as General_Bool_Exp<T>[], (orExp) => {
        return (orExp._and as any).every((exp) => isEqual(exp, parentExpression))
      })
      if (
        !(isolatedExpressions[0]._or as any).find(
          (exp) =>
            exp._and.some((e) => expressions.some((expression) => isEqual(e, expression))) &&
            exp._and.some((e) => isEqual(e, parentExpression))
        )
      ) {
        isolatedExpressions[0]._or.push({ _and: [parentExpression as any, ...(expressions as any)] })
      }
    }

    const modifiedExpressions = andExpressions.concat(isolatedExpressions)
    return JSON.stringify({ _and: modifiedExpressions })
  }

  const cleanSetChildren = (
    filter: string,
    expressions: General_Bool_Exp<T>[],
    parentExpression: General_Bool_Exp<T>,
    operator?: '_gt' | '_lt'
  ) => {
    const field = Object.keys(expressions[0])[0]
    const interFilter = deleteChildren(filter, field, parentExpression, operator)
    return setChildren(interFilter, expressions, parentExpression)
  }

  const cleanSetStandalones = (filter: string, expressions: General_Bool_Exp<T>[]) => {
    const field = Object.keys(expressions[0])[0]
    const interFilter = deleteAllStandalones(filter, field)
    return setStandalones(interFilter, expressions)
  }

  const setStandalonesWithDelete = (
    filter: string,
    expressions: General_Bool_Exp<T>[],
    expressionsToDelete: General_Bool_Exp<T>[]
  ) => {
    const interFilter = expressionsToDelete.length ? deleteExpressions(filter, expressionsToDelete) : filter
    return setStandalones(interFilter, expressions)
  }

  const deleteChild = (filter: string, expression: General_Bool_Exp<T>, parentExpression: General_Bool_Exp<T>) => {
    const parentField = Object.keys(parentExpression)[0]
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const andExpressions = (where?._and as General_Bool_Exp<T>[]) ?? []

    const isolatedExpressions = remove(andExpressions, (andExpression) => {
      if (!andExpression?._or || !andExpression?._or?.length) return false
      return (andExpression._or as any).every((orExpression) => {
        if (!orExpression?._and || !orExpression._and?.length) return false
        return orExpression._and[0]?.[parentField]
      })
    })

    if (isolatedExpressions.length === 1) {
      const neededExpressions = remove(isolatedExpressions[0]._or as General_Bool_Exp<T>[], (exp) => {
        return exp._and.some((e) => isEqual(e, expression)) && exp._and.some((e) => isEqual(e, parentExpression))
      })
      if (neededExpressions.length === 1) {
        remove(neededExpressions[0]._and as General_Bool_Exp<T>[], (andExp) => isEqual(andExp, expression))
      }
      isolatedExpressions[0]._or.push(...(neededExpressions as any))
    }

    const modifiedExpressions = andExpressions.concat(isolatedExpressions)
    return JSON.stringify({ _and: modifiedExpressions })
  }

  const deleteChildren = (
    filter: string,
    field: string,
    parentExpression: General_Bool_Exp<T>,
    operator?: '_gt' | '_lt'
  ) => {
    const parentField = Object.keys(parentExpression)[0]
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const andExpressions = (where?._and as General_Bool_Exp<T>[]) ?? []

    const isolatedExpressions = remove(andExpressions, (andExpression) => {
      if (!andExpression?._or || !andExpression?._or?.length) return false
      return (andExpression._or as any).every((orExpression) => {
        if (!orExpression?._and || !orExpression._and?.length) return false
        return orExpression._and[0]?.[parentField]
      })
    })
    if (isolatedExpressions.length === 1) {
      const neededExpressions = remove(isolatedExpressions[0]._or as General_Bool_Exp<T>[], (exp) => {
        return exp._and.some((e) => e?.[field]) && exp._and.some((e) => isEqual(e, parentExpression))
      })
      for (let exp of neededExpressions) {
        remove(
          exp._and as General_Bool_Exp<T>[],
          (andExp) => (!operator && andExp?.[field]) || (!!operator && andExp?.[field]?.[operator])
        )
      }
      isolatedExpressions[0]._or.push(...(neededExpressions as any))
    }
    const modifiedExpressions = andExpressions.concat(isolatedExpressions)
    return JSON.stringify({ _and: modifiedExpressions })
  }

  const deleteExpressions = (filter: string, expressions: General_Bool_Exp<T>[]) => {
    const field = Object.keys(expressions[0])[0]
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const expressionsToDelete = remove(where?._and as General_Bool_Exp<T>[], (exp) =>
      exp?._or?.some((nestedExp) => nestedExp?._and?.some((e) => e?.[field]))
    )
    if (!expressionsToDelete.length) return filter
    const filteredExp = expressionsToDelete
      .map((exp) => {
        const _or = (exp?._or as any)?.filter(
          (exp) => !exp?._and?.some((e) => expressions.some((expression) => isEqual(e, expression)))
        )
        if (!_or.length) return undefined
        return { _or }
      })
      .filter((exp) => !!exp)
    const updatedFilter = (where?._and as any)?.concat(filteredExp)
    return JSON.stringify({ _and: updatedFilter })
  }

  const deleteExpression = (filter: string, expression: General_Bool_Exp<T>) => {
    const field = Object.keys(expression)[0]
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const expressionsToDelete = remove(where?._and as General_Bool_Exp<T>[], (exp) =>
      exp?._or?.some((nestedExp) => nestedExp?._and?.some((e) => e?.[field]))
    )
    if (!expressionsToDelete.length) return filter
    const filteredExp = expressionsToDelete
      .map((exp) => {
        const _or = (exp?._or as any)?.filter((exp) => !exp?._and?.some((e) => isEqual(e, expression)))
        if (!_or.length) return undefined
        return { _or }
      })
      .filter((exp) => !!exp)
    const updatedFilter = (where?._and as any)?.concat(filteredExp)
    return JSON.stringify({ _and: updatedFilter })
  }

  const deleteAll = (filter: string, field: string) => {
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    remove(where?._and as General_Bool_Exp<T>[], (exp) => exp?._or?.some((nestedExp) => nestedExp?._and?.[0]?.[field]))
    return JSON.stringify(where)
  }

  const deleteStandalone = (filter: string, expression: General_Bool_Exp<T>) => {
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    const expressionsToDelete = remove(where?._and as General_Bool_Exp<T>[], (exp) =>
      exp?._or?.some((nestedExp) => nestedExp?._and?.length === 1 && isEqual(nestedExp?._and[0], expression))
    )
    if (!expressionsToDelete.length || expressionsToDelete.length > 1) return filter
    remove(
      expressionsToDelete[0]._or as General_Bool_Exp<T>[],
      (exp) => exp?._and?.length === 1 && isEqual(exp._and[0], expression)
    )
    if (expressionsToDelete[0]._or.length) {
      where._and.push(...(expressionsToDelete as any))
    }
    return JSON.stringify(where)
  }

  const deleteAllStandalones = (filter: string, field: string) => {
    const where: General_Bool_Exp<T> = JSON.parse(filter ?? '{}')
    remove(where?._and as General_Bool_Exp<T>[], (exp) => {
      if (!exp._or || !exp?._or?.length) return false
      return (exp._or as any).every((andExpressions) => {
        if (!andExpressions._and || !andExpressions?._and?.length) return false
        return andExpressions._and.length >= 1 && andExpressions._and[0]?.[field]
      })
    })
    return JSON.stringify(where)
  }

  return {
    cleanSetStandalones,
    setStandalonesWithDelete,
    cleanSetChildren,
    getAll,
    getStandalone,
    getChildren,
    deleteAll,
    setStandalone,
    setStandalones,
    setChild,
    setAlternativeExpression,
    setAlternativeExpressions,
    deleteExpression,
    deleteExpressions,
    deleteStandalone,
    deleteChild,
    deleteAllStandalones,
    deleteChildren,
  }
}
