import { Chip } from 'components'
import { Role_Enum } from 'gql'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface UserRoleChipProps {
  role: Role_Enum
  isPrimary?: boolean
}

export const UserRoleChip = (props: UserRoleChipProps) => {
  const { t } = useTranslation()
  if (!props.role) return null;
  return <Chip color={props.isPrimary ? 'danger' : 'primary'}>{t(`UserRole.${props.role}`)}</Chip>
}
