import { ListConfigInterface } from '../../index'
import { Dealer_Vehicle_State_Enum, In_Buy_Channel_Enum, Order_State_Enum, Vehicle } from '../../../../gql'
import { DateFns } from '../../../../utils'
import { useMemo } from 'react'
import { useEzAuth } from '@lib/ezauth'

export const useVehicleListConfigs = (): { [key: string]: ListConfigInterface<Vehicle> } => {
  const [state] = useEzAuth()
  const userId = state.user.id
  return useMemo(
    () => ({
      importNew: {
        name: 'Import: neue Fahrzeuge',
        description: 'Liste der neuen Fahrzeuge, die noch nicht in Lagerbestand aufgenommen wurden.',
        id: '0',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { imported: { _is_null: true } },
            { archived: { _is_null: true } },
            { business_case_number: { _eq: 0 } },
            {
              _or: [{ _and: [{ in_buy_channel: { _eq: In_Buy_Channel_Enum.Import } }] }],
            },
          ],
        }),
        columns: [
          'importState',
          'invalidity',
          'markedForDelete',
          'jobNumber',
          'vin',
          'model',
          'state',
          'deliveryLocation',
          'type',
          'registrationState',
          'color',
          'quickActions',
        ],
        grouping: undefined,
        highlights: [],
      },
      importUsed: {
        name: 'Import: gebrauchte Fahrzeuge',
        description:
          'Liste der gebrauchten Fahrzeuge, die noch nicht in Lagerbestand aufgenommen wurden. Die Liste beinhaltet Fahrzeuge mit folgenden Zukaufskanälen: Komission, freier Zukauf, Rückkauf, Gebrauchtwagen Eintausch, Unbekannter Gebrauchtwagen Import.',
        id: '1',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { imported: { _is_null: true } },
            { archived: { _is_null: true } },
            { business_case_number: { _eq: 0 } },
            {
              _or: [
                { _and: [{ in_buy_channel: { _eq: In_Buy_Channel_Enum.Commission } }] },
                { _and: [{ in_buy_channel: { _eq: In_Buy_Channel_Enum.UnknownUsedCarBuyIn } }] },
                { _and: [{ in_buy_channel: { _eq: In_Buy_Channel_Enum.Buyback } }] },
                { _and: [{ in_buy_channel: { _eq: In_Buy_Channel_Enum.Exchange } }] },
                { _and: [{ in_buy_channel: { _eq: In_Buy_Channel_Enum.FreePurchase } }] },
                { _and: [{ in_buy_channel: { _is_null: true } }] },
              ],
            },
          ],
        }),
        columns: [
          'importState',
          'invalidity',
          'markedForDelete',
          'vin',
          'inBuyChannel',
          'purchasedBy',
          'owner',
          'previousOwner',
          'brand',
          'modelUsedCar',
          'firstRegistration',
          'color',
          'co2',
          'deliveryDate',
          'keysCount',
          'quickActions',
        ],
        grouping: undefined,
        highlights: [],
      },
      dataQuality: {
        name: 'Datenqualität - Lagerfahrzeuge',
        id: '2',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { archived: { _is_null: true } },
            { business_case_number: { _gt: 0 } },
            { sync_error: { _eq: true } },
          ],
        }),
        columns: ['importState', 'invalidity', 'markedForDelete', 'jobNumber', 'vin', 'model', 'error'],
        grouping: undefined,
        highlights: [],
      },
      new: {
        name: 'Lagerfahrzeuge - Neuwagen',
        description: 'Liste aller Neuwagen',
        id: '3',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { imported: { _is_null: false } },
            { archived: { _is_null: true } },
            { business_case_number: { _gt: 0 } },
            { in_buy_channel: { _eq: In_Buy_Channel_Enum.Import } },
          ],
        }),
        columns: [
          'state',
          'invalidity',
          'markedForDelete',
          'deliveryLocation',
          'jobNumberAdvanced',
          'type',
          'model',
          'specs',
          'color',
          'firstRegistration',
          'registrationState',
          'licensePlate',
          'note',
          'soldBy',
          'customer',
          'listPrice',
          'cashPrice',
          'boni',
          'quickActions',
        ],
        grouping: 'modelGroupName',
        highlights: [],
      },
      lastSells: {
        name: 'Letzte Verkäufe',
        description: 'Liste der Fahrzeuge, die in den letzten 6 Monaten verkauft wurden.',
        id: '4',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { imported: { _is_null: false } },
            { archived: { _is_null: true } },
            { business_case_number: { _gt: 0 } },
            {
              _or: [
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.Sold } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.SoldToPartner } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.SoldByPartner } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.SoldByBroker } }] },
              ],
            },
            { _or: [{ _and: [{ sold_at: { _gt: DateFns.subMonths(DateFns.startOfDay(new Date()), 6) } }] }] },
          ],
        }),
        columns: [
          'state',
          'invalidity',
          'markedForDelete',
          'deliveryLocation',
          'jobNumberAdvanced',
          'type',
          'model',
          'specs',
          'color',
          'firstRegistration',
          'licensePlate',
          'note',
          'soldBy',
          'customer',
          'quickActions',
        ],
        grouping: 'modelGroupName',
        highlights: [],
      },
      mySells: {
        name: 'Meine Verkäufe',
        description: 'Liste der Fahrzeuge, die ich verkauft habe.',
        id: '5',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { imported: { _is_null: false } },
            { archived: { _is_null: true } },
            { business_case_number: { _gt: 0 } },
            {
              _or: [
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.Sold } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.SoldToPartner } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.SoldByPartner } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.SoldByBroker } }] },
              ],
            },
            {
              _or: [{ _and: [{ sold_by_id: { _eq: userId } }] }],
            },
          ],
        }),
        columns: [
          'state',
          'invalidity',
          'markedForDelete',
          'deliveryLocation',
          'handoverDate',
          'jobNumberAdvanced',
          'type',
          'model',
          'specs',
          'color',
          'firstRegistration',
          'licensePlate',
          'note',
          'soldBy',
          'customer',
          'quickActions',
        ],
        grouping: 'modelGroupName',
        highlights: [],
      },
      myReservations: {
        name: 'Meine Reservierungen',
        description: 'Liste der Fahrzeuge, die ich reserviert habe.',
        id: '6',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { imported: { _is_null: false } },
            { archived: { _is_null: true } },
            { business_case_number: { _gt: 0 } },
            {
              _or: [{ _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.Reserved } }] }],
            },
            {
              _or: [{ _and: [{ reserved_by_id: { _eq: userId } }] }],
            },
          ],
        }),
        columns: [
          'state',
          'invalidity',
          'markedForDelete',
          'deliveryLocation',
          'jobNumberAdvanced',
          'type',
          'model',
          'specs',
          'color',
          'firstRegistration',
          'licensePlate',
          'note',
          'soldBy',
          'customer',
          'quickActions',
        ],
        grouping: 'modelGroupName',
        highlights: [],
      },
      myHandedOver: {
        name: 'Meine Ausgelieferten',
        description: 'Liste der Fahrzeuge, die ich verkauft habe und die bereits ausgeliefert sind.',
        id: '7',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { imported: { _is_null: false } },
            { archived: { _is_null: true } },
            { business_case_number: { _gt: 0 } },
            {
              _or: [{ _and: [{ handover_by_id: { _eq: userId } }] }],
            },
            {
              _or: [{ _and: [{ order_state: { _eq: Order_State_Enum.Handover } }] }],
            },
          ],
        }),
        columns: [
          'state',
          'invalidity',
          'markedForDelete',
          'deliveryLocation',
          'jobNumberAdvanced',
          'type',
          'model',
          'specs',
          'color',
          'firstRegistration',
          'licensePlate',
          'note',
          'soldBy',
          'customer',
          'quickActions',
        ],
        grouping: 'modelGroupName',
        highlights: [],
      },
      sortedByDaysSincePurchase: {
        name: 'Neuwagen sortiert nach Standtage (Rechnungsdatum)',
        description:
          'Neuwagen sind nach Standtage sortiert basierend auf Rechnungsdatum. Die Liste beinhaltet auch Fahrzeuge, die in den letzten 7 Tagen verkauft wurden.',
        id: '8',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { imported: { _is_null: false } },
            { archived: { _is_null: true } },
            { business_case_number: { _gt: 0 } },
            { in_buy_channel: { _eq: In_Buy_Channel_Enum.Import } },
            {
              _or: [
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.Available } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.Reserved } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.OnSiteNeed } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.Other } }] },
                { _and: [{ state: { _is_null: true } }] },
                {
                  _and: [
                    {
                      state: { _eq: Dealer_Vehicle_State_Enum.Sold },
                    },
                    {
                      sold_at: { _gt: DateFns.subWeeks(DateFns.startOfDay(new Date()), 1) },
                    },
                  ],
                },
                {
                  _and: [
                    {
                      state: { _eq: Dealer_Vehicle_State_Enum.SoldByPartner },
                    },
                    {
                      sold_at: { _gt: DateFns.subWeeks(DateFns.startOfDay(new Date()), 1) },
                    },
                  ],
                },
                {
                  _and: [
                    {
                      state: { _eq: Dealer_Vehicle_State_Enum.SoldByBroker },
                    },
                    {
                      sold_at: { _gt: DateFns.subWeeks(DateFns.startOfDay(new Date()), 1) },
                    },
                  ],
                },
                {
                  _and: [
                    {
                      state: { _eq: Dealer_Vehicle_State_Enum.SoldToPartner },
                    },
                    {
                      sold_at: { _gt: DateFns.subWeeks(DateFns.startOfDay(new Date()), 1) },
                    },
                  ],
                },
              ],
            },
          ],
        }),
        columns: [
          'state',
          'invalidity',
          'markedForDelete',
          'deliveryLocation',
          'jobNumberAdvanced',
          'type',
          'model',
          'daysSincePurchase',
          'specs',
          'color',
          'firstRegistration',
          'licensePlate',
          'note',
          'soldBy',
          'customer',
          'quickActions',
        ],
        grouping: undefined,
        highlights: [],
        order: { col: 'days_since_purchase', direction: 'asc' },
      },
      myPreparations: {
        name: 'Meine Aufbereitungen',
        description: 'Liste der Fahrzeuge, für die ich eine Aufbereitungsaufgabe erstellt habe.',
        id: '9',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { imported: { _is_null: false } },
            { archived: { _is_null: true } },
            { business_case_number: { _gt: 0 } },
            { tickets: { created_by_id: { _eq: userId }, title: { _ilike: '%aufbereitung%' } } },
          ],
        }),
        columns: [
          'state',
          'invalidity',
          'markedForDelete',
          'deliveryLocation',
          'jobNumberAdvanced',
          'type',
          'model',
          'specs',
          'color',
          'firstRegistration',
          'licensePlate',
          'note',
          'soldBy',
          'customer',
          'quickActions',
        ],
        grouping: 'modelGroupName',
        highlights: [],
      },
      myOpenHandedOver: {
        name: 'Meine offenen Auslieferungen',
        description: 'Liste der Fahrzeuge, die ich verkauft habe und die noch nicht ausgeliefert sind.',
        id: '10',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { imported: { _is_null: false } },
            { archived: { _is_null: true } },
            { business_case_number: { _gt: 0 } },
            {
              _or: [
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.Sold } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.SoldToPartner } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.SoldByPartner } }] },
                { _and: [{ state: { _eq: Dealer_Vehicle_State_Enum.SoldByBroker } }] },
              ],
            },
            {
              _or: [
                { _and: [{ order_state: { _eq: Order_State_Enum.InTransport } }] },
                { _and: [{ order_state: { _eq: Order_State_Enum.InInternalTransport } }] },
                { _and: [{ order_state: { _eq: Order_State_Enum.InStock } }] },
                { _and: [{ order_state: { _is_null: true } }] },
              ],
            },
            {
              _or: [{ _and: [{ sold_by_id: { _eq: userId } }] }],
            },
          ],
        }),
        columns: [
          'state',
          'invalidity',
          'markedForDelete',
          'deliveryLocation',
          'jobNumberAdvanced',
          'type',
          'model',
          'specs',
          'color',
          'firstRegistration',
          'licensePlate',
          'note',
          'soldBy',
          'customer',
          'quickActions',
        ],
        grouping: 'modelGroupName',
        highlights: [],
      },
      markedForDelete: {
        name: 'Zum Löschen markierte Fahrzeuge',
        description: 'Liste der Fahrzeuge, die in dem ursprunglichen Datensystem fehlen',
        id: '10',
        filter: JSON.stringify({
          _and: [
            { version: { _eq: 0 } },
            { archived: { _is_null: true } },
            { marked_for_delete: { _is_null: false } },
            { business_case_number: { _eq: 0 } },
          ],
        }),
        columns: ['invalidity', 'jobNumberAdvanced', 'vin', 'model', 'firstRegistration', 'licensePlate'],
        grouping: undefined,
        highlights: [],
      },
    }),
    [userId]
  )
}
