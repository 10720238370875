import React from 'react'
import { DataSource, DataValue } from '../../components/DataValue'
import { DataBlock } from '../../components/DataBlock'
import { FormRow } from '../../components/FormRow'
import { Notes } from '../VehicleForm/01_GeneralData/notes'
import * as L from 'layouts'
import clsx from 'clsx'

interface GenericField {
  name: string
  label: string
  source?: DataSource
  FormComponent: React.ReactNode
  onEdit?: () => void
  disabled?: boolean
  required?: boolean
  error?: string
  placeholder?: string | number | React.ReactNode
}

interface GenericSection {
  label?: string
  name: string
  fields: (GenericField | undefined)[]
  column?: number
  fullWidth?: boolean
}

interface PulseFormProps {
  sections: (GenericSection | undefined)[]
  disableNotes?: boolean
  editMode?: boolean
  handleEdit?: () => void
}

export const PulseForm = (props: PulseFormProps) => {
  const { sections = [], disableNotes, ...bindings } = props

  return (
    <L.Vertical spacing={5} className="p-6 pb-8">
      <div className={'flex flex-row flex-wrap'}>
        <PulseFormColumn sections={sections.filter((section) => section.fullWidth)} fullWidth {...bindings} />
        <PulseFormColumn sections={sections.filter((section) => section.column === 1)} {...bindings} />
        <PulseFormColumn sections={sections.filter((section) => section.column === 2)} {...bindings}>
          {!props.disableNotes && (
            <DataBlock className={'relative'} title="Notizen">
              <Notes />
            </DataBlock>
          )}
        </PulseFormColumn>
      </div>
    </L.Vertical>
  )
}

interface PulseFormColumnProps {
  sections: (GenericSection | undefined)[]
  fullWidth?: boolean
  editMode?: boolean
  handleEdit?: () => void
}

const PulseFormColumn: React.FC<PulseFormColumnProps> = (props) => {
  return (
    <div className={clsx('flex flex-col gap-4 px-2', props.fullWidth ? 'w-full' : 'w-1/2')}>
      {props.sections.map((section) => (
        <div key={`vehicle-form-section-${section?.name}`}>
          {section && (
            <DataBlock title={section.label}>
              {section?.fields?.map((field, idx) => (
                <div key={`field-${field?.name ?? idx}`}>
                  {field && (
                    <>
                      {!props.editMode ? (
                        <DataValue
                          dataSource={field.source}
                          label={field.label}
                          onEdit={!field.disabled ? (field.onEdit ? field.onEdit : props.handleEdit) : undefined}
                        >
                          {field.placeholder}
                        </DataValue>
                      ) : (
                        <FormRow label={field.label} required={field.required} error={field.error}>
                          {field.FormComponent}
                        </FormRow>
                      )}
                    </>
                  )}
                </div>
              ))}
            </DataBlock>
          )}
        </div>
      ))}
      {props.children}
    </div>
  )
}
