import { Ticket_Bool_Exp } from '../../../../gql'
import { useListManipilationContext } from '../../unit'
import { useFilterOperations } from '../../useFilterOperations'
import { useCallback } from 'react'
import React from 'react'
import { Menu } from 'components/Menu'
import { Icon } from '../../../../components'
import { TicketDefaultOptions } from '../Chips/TicketDefaultOptions'
import { Chips } from './Chips'
import { TicketTeamOptions } from '../Chips/TeamChip'
import { UserOptions } from 'containers/ListManipulation/Chips/UserChip'
import { DateOptions } from 'containers/ListManipulation/Chips/DateChip'
import { EnumOptions } from '../../Chips/EnumChip'

export const TicketsFilter = () => {
  return (
    <div className={'flex gap-x-2 items-center'} style={{ flex: '0 1 100%' }}>
      <Chips />
      <TicketsNewFilterButton>Filter Hinzufügen</TicketsNewFilterButton>
    </div>
  )
}

interface TicketsNewFilterButtonProps {
  onClick?: () => void
  expressions?: Ticket_Bool_Exp[]
}

export const TicketsNewFilterButton: React.FC<TicketsNewFilterButtonProps> = (props) => {
  const { field, setField } = useListManipilationContext<'Ticket'>()
  const operations = useFilterOperations<'Ticket'>()

  const handleAddFilter = useCallback(() => {
    setField(undefined)
    props.onClick && props.onClick()
  }, [props, setField])

  return (
    <Menu>
      <Menu.Button
        onClick={handleAddFilter}
        className={'whitespace-nowrap'}
        variant={'text'}
        startIcon={<Icon name={'add'} />}
      >
        {props.children}
      </Menu.Button>
      {!field && <TicketDefaultOptions />}
      {(field === 'state' || field === 'priority') && <EnumOptions<'Ticket'> operations={operations} />}
      {(field === 'created_at' || field === 'updated_at' || field === 'due_to') && (
        <DateOptions<'Ticket'> operations={operations} />
      )}
      {field === 'assignee_id' && <UserOptions<'Ticket'> operations={operations} />}
      {field === 'team' && <TicketTeamOptions operations={operations} />}
    </Menu>
  )
}
