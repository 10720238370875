import React, { useContext, useMemo } from 'react'
import { ListConfigContext } from '../../../ListConfig'
import { useTranslation } from 'react-i18next'
import { Order_By } from '../../../../gql'
import Select from '../../../../components/Select'

export const TicketsGroupBySelect = () => {
  const { groupBy, setGroupBy } = useContext(ListConfigContext)

  return (
    <Select
      label="Gruppieren nach"
      style={{ maxWidth: '50%' }}
      className="m-2"
      value={groupBy}
      name="Group by"
      onChange={(evt) => setGroupBy(evt.target.value)}
    >
      <Select.Option key={`group_by-null`} value={undefined}>
        Keine Gruppierung
      </Select.Option>
      <Select.Option key={`group_by-state`} value={'state'}>
        Nach Status
      </Select.Option>
    </Select>
  )
}

export const useTicketsGroupBy = () => {
  const { groupBy } = useContext(ListConfigContext)
  const { t } = useTranslation()

  const defined = (groupName: string) => groupName !== 'null' && groupName !== '' && groupName !== 'undefined'

  return useMemo(() => {
    switch (groupBy) {
      case 'state':
        return {
          render: (groupName) => (defined(groupName) ? t(`TicketState.${groupName}`) : 'Status fehlt'),
          orderBy: { state: Order_By.Desc },
          iteratee: (item: any) => item?.state,
        }
      default:
        return { render: () => 'Aufgaben', orderBy: { id: Order_By.Asc }, iteratee: undefined }
    }
  }, [groupBy, t])
}
