import Auth from '@aws-amplify/auth'
import { execNewPasswordChallenge, execForgotPassword } from '../auth-utils'

import { Authenticator } from '../Authenticator'
import { User, UserRole } from '@lib/ezauth'

interface CognitoConfig {
  region: string
  userPoolId: string
  userPoolWebClientId: string
}

export class CognitoAuthenticator implements Authenticator {
  constructor(config: CognitoConfig) {
    Auth.configure(config)
  }

  public async init() {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
      return mapCognitoUserToDomain(user)
    } catch {
      return null
    }
  }

  public async signIn(username, password) {
    let user = await Auth.signIn(username, password)
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const { password } = await execNewPasswordChallenge({ user })

      user = await Auth.completeNewPassword(user, password)
    }

    return mapCognitoUserToDomain(user)
  }
  public async signOut() {
    await Auth.signOut()
  }

  public async forgotPassword(username: string) {
    await Auth.forgotPassword(username)
    const { otp, newPassword } = await execForgotPassword({ username })
    await Auth.forgotPasswordSubmit(username, otp, newPassword)
    return
  }
}

interface CognitoUser {
  username: string
  signInUserSession: {
    accessToken: {
      jwtToken: string
    }
    idToken: {
      payload: {
        email: string
        sub: string
        'custom:locationId': string
        'cognito:groups': UserRole[]
      }
    }
  }
}

const mapCognitoUserToDomain = (user: CognitoUser): User => {
  const email = user.signInUserSession.idToken.payload.email
  const previewEmails = [/^[A-Za-z0-9._%+-]+@do\.vision$/, /^[A-Za-z0-9._%+-]+@w2solutions\.at$/, /^benedikt.trawoeger@automobile-swoboda.at$/]
  const showPreview = previewEmails.some((exp) => exp.test(email))

  return {
    id: user.signInUserSession.idToken.payload.sub,
    email,
    roles: user.signInUserSession.idToken.payload['cognito:groups'] ?? [],
    defaultLocationId: user.signInUserSession.idToken.payload['custom:locationId'],
    showPreview,
    getIdToken: async () => {
      return (await Auth.currentSession()).getIdToken().getJwtToken()
    },
  }
}
