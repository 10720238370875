import { SiteMetadata, Tabs } from 'components'
import { DepartmentsList } from 'containers/AdminUser/DepartmentsList'
import { UsersList } from 'containers/AdminUser/UsersList'
import { useUsersListSubscription } from 'gql'
import React, { useMemo } from 'react'

export const UsersPage = () => {
  const { data } = useUsersListSubscription()
  const users = useMemo(() => data?.users, [data?.users])
  return (
    <>
      <SiteMetadata title="Users" />
      <Tabs>
        <Tabs.TabList>
          <Tabs.Tab label={'Users'} />
          <Tabs.Tab label={'Bereiche'} />
        </Tabs.TabList>
        <Tabs.TabPanes>
          <Tabs.TabPane>
            <UsersList users={users} />
          </Tabs.TabPane>
          <Tabs.TabPane>
            <DepartmentsList />
          </Tabs.TabPane>
        </Tabs.TabPanes>
      </Tabs>
    </>
  )
}
