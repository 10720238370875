import React, { useMemo, useState } from 'react'
import * as L from 'layouts'
import { Icon, IconButton, Typo } from '../../components'
import { DmsReferenceFormSection } from '../DmsReferenceFormSection'
import { DmsReference, DmsReference_Insert_Input, Dms_Type_Enum, useSetLocationDmsReferencesMutation } from '../../gql'
import { useFormFields } from '@w2solutions/react-hooks'

export interface LocationDmsReferencesProps {
  locationId: string
  dmsReferences: DmsReference[]
  customerDmsReferences: DmsReference[]
  supplierDmsReferences: DmsReference[]
}

export const LocationDmsReferences = (props: LocationDmsReferencesProps) => {
  const [editMode, setEditMode] = useState(false)
  const defaultValues = useMemo(() => {
    return {
      dmsReferences: props.dmsReferences ?? initalFormData.dmsReferences,
      customerDmsReferences: props.customerDmsReferences ?? initalFormData.customerDmsReferences,
      supplierDmsReferences: props.supplierDmsReferences ?? initalFormData.supplierDmsReferences,
    }
  }, [props.dmsReferences, props.customerDmsReferences, props.supplierDmsReferences])
  const { values, setValue, reset } = useFormFields(defaultValues, { resetOnInitialValueChange: true })

  const [save] = useSetLocationDmsReferencesMutation()
  const handleSave = async () => {
    const objects: DmsReference_Insert_Input[] = []
      .concat(
        values.dmsReferences.map((ref) => ({
          id: ref.id,
          location_id: props.locationId,
          name: ref.name,
          reference: ref.reference,
          type: Dms_Type_Enum.Locosoft,
        }))
      )
      .concat(
        values.customerDmsReferences.map((ref) => ({
          id: ref.id,
          location_customer_id: props.locationId,
          name: ref.name,
          reference: ref.reference,
          type: Dms_Type_Enum.Locosoft,
        }))
      )
      .concat(
        values.supplierDmsReferences.map((ref) => ({
          id: ref.id,
          location_supplier_id: props.locationId,
          name: ref.name,
          reference: ref.reference,
          type: Dms_Type_Enum.Locosoft,
        }))
      )
    try {
      await save({
        variables: {
          locationId: props.locationId,
          refIds: objects.map((refs) => refs.id),
          objects,
        },
      })
      setEditMode(false)
    } catch {}
  }

  const handleCancel = () => {
    reset()
    setEditMode(false)
  }

  return <>
    <L.Horizontal>
      <Typo variant={'h3'}>{'DMS Nummern'}</Typo>
      <div>
        {!editMode && (
          <IconButton aria-label="edit" onClick={() => setEditMode(true)} size={'small'}>
            <Icon name="edit" />
          </IconButton>
        )}
      </div>
    </L.Horizontal>
    <DmsReferenceFormSection
      title={'DMS Betriebsnummer'}
      dmsReferences={values.dmsReferences}
      onAdd={(blank) => setValue('dmsReferences', [...values.dmsReferences, blank])}
      onRemove={(index) => {
        const newValue = [...values.dmsReferences]
        newValue.splice(index, 1)
        setValue('dmsReferences', newValue)
      }}
      onChange={(index, data) => {
        const newValue = [...values.dmsReferences]
        newValue[index] = data
        setValue('dmsReferences', newValue)
      }}
      readonly={!editMode}
    />
    <DmsReferenceFormSection
      title={'DMS Lieferantennummern'}
      dmsReferences={values.supplierDmsReferences}
      onAdd={(blank) => setValue('supplierDmsReferences', [...values.supplierDmsReferences, blank])}
      onRemove={(index) => {
        const newValue = [...values.supplierDmsReferences]
        newValue.splice(index, 1)
        setValue('supplierDmsReferences', newValue)
      }}
      onChange={(index, data) => {
        const newValue = [...values.supplierDmsReferences]
        newValue[index] = data
        setValue('supplierDmsReferences', newValue)
      }}
      readonly={!editMode}
    />
    <DmsReferenceFormSection
      title={'DMS Kundennummern'}
      dmsReferences={values.customerDmsReferences}
      onAdd={(blank) => setValue('customerDmsReferences', [...values.customerDmsReferences, blank])}
      onRemove={(index) => {
        const newValue = [...values.customerDmsReferences]
        newValue.splice(index, 1)
        setValue('customerDmsReferences', newValue)
      }}
      onChange={(index, data) => {
        const newValue = [...values.customerDmsReferences]
        newValue[index] = data
        setValue('customerDmsReferences', newValue)
      }}
      readonly={!editMode}
    />
    {editMode && (
      <L.Horizontal spacing={1}>
        <IconButton
          aria-label="save"
          type="submit"
          color="primary"
          onClick={handleSave}
          size="large">
          <Icon name="check" />
        </IconButton>
        <IconButton aria-label="cancel" onClick={handleCancel} size="large">
          <Icon name="close" />
        </IconButton>
      </L.Horizontal>
    )}
  </>;
}

const initalFormData = {
  dmsReferences: [] as DmsReference[],
  customerDmsReferences: [] as DmsReference[],
  supplierDmsReferences: [] as DmsReference[],
}
