import Select from 'components/Select'
import { Brand, useBrandsQuery } from 'gql'
import React, { ChangeEvent, CSSProperties } from 'react'
import { useMemo } from 'react'

interface BrandSelectProps {
  label?: string
  name?: string
  brandId?: string
  onChange: (ids: string[]) => void
  onlyHouseBrands?: boolean
  className?: string
  style?: CSSProperties
  disabled?: boolean
  filter?: (brandId: string) => boolean
}

export type BrandType = Pick<Brand, 'id' | 'name' | 'is_house_brand'>

export const BrandSelect = (props: BrandSelectProps) => {
  const query = useBrandsQuery()
  const data = useMemo(() => {
    let result = query.data?.brands ?? []
    if (props.onlyHouseBrands) {
      result = result.filter((b) => b.is_house_brand)
    }
    return result
  }, [props.onlyHouseBrands, query.data?.brands])

  const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const value = evt.target.value
    props.onChange([value])
  }
  const filter = (brand: BrandType) =>
    props.filter ? props.filter(brand.id) : () => true

  return (
    <Select
      disabled={props.disabled}
      loading={query.loading}
      name={props.name}
      label={props.label ?? 'Marken'}
      value={props.brandId}
      onChange={handleChange as any}
      renderSelectValueItem={(id) => data.find((brand) => brand.id === id)?.name ?? ''}
      style={props.style}
      className={props.className}
      onReset={() => props.onChange([])}
    >
      {data.filter(filter).map((brand) => (
        <Select.Option key={brand.id} value={brand.id}>
          {brand.name}
        </Select.Option>
      ))}
    </Select>
  )
}
