import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { DataSource } from '../../../components/DataValue'
import { SyncErrorState, useVehicleValidationSchema, VehicleDetailContext, VehicleFormValues } from '../index'
import { getErrorState, useVehicleFormValues } from './index'
import { DealerVehicleStateChip } from '../../DealerVehicleState/chip'
import { TextField } from '../../../components'
import { DealerVehicleStateSelect } from '../../DealerVehicleStateSelect'
import {
  Dealer_Vehicle_State_Enum,
  Dealer_Vehicle_Type_Enum,
  Import_Type_Enum,
  In_Buy_Channel_Enum,
  Order_State_Enum,
  Registration_State_Enum,
  useAdminModelGroupListLazyQuery,
} from '../../../gql'
import { OrderStateChip } from '../../OrderState'
import { OrderStateSelect } from '../../OrderStateSelect'
import { StorageLocationSelect } from '../../StorageLocationSelect'
import { InBuyChannelChip } from '../../InBuyChannel/Chip'
import { InBuyChannelSelect } from '../../InBuyChannel/Select'
import { useEzAuthUserHasRoles, UserRole } from '@lib/ezauth'
import { DealerVehicleTypeChip } from '../../DealerVehicleType/chip'
import { DealerVehicleTypeSelect } from '../../DealerVehicleTypeSelect'
import { useFormatter } from '../../../hooks'
import { DatePicker } from '../../../components/DatePicker'
import { DateFns } from '../../../utils'
import { RegistrationStateSelect } from '../../RegistrationStateSelect'
import { useTranslation } from 'react-i18next'
import { UserSelect } from '../../UserSelect'
import { LocationSelect } from '../../LocationSelect'
import { CustomerTypeSelect } from 'containers/CustomerTypeSelect'
import { DetailViewEnum, useDetailView } from '../../../components/DetailWindow/useDetailView'
import { ModelGroupSelect } from '../../ModelGroupSelect'
import { ModelGroupReleaseYearSelect, ModelReleaseYear } from '../../ModelGroupReleaseYearSelect'
import * as L from 'layouts'
import { SalesModelCodeInput } from '../../AdminBrandDetail/model-details/SalesModelCodeInput'

export const useVehicleFormular = (
  disableValidationOnSave: boolean,
  onSave: (data: VehicleFormValues) => Promise<void>
) => {
  const { data, metadata } = useContext(VehicleDetailContext)
  const usedCar = useMemo(() => data?.in_buy_channel !== In_Buy_Channel_Enum.Import, [data?.in_buy_channel])
  const { t } = useTranslation()
  const errorState = useMemo(() => getErrorState(data), [data])
  const [groupsQuery, groupsQueryResponse] = useAdminModelGroupListLazyQuery()
  useEffect(() => {
    if (!data?.brand) {
      return
    }
    groupsQuery({ variables: { brandId: data?.brand?.id } })
  }, [data?.brand, groupsQuery])
  const groups = useMemo(() => groupsQueryResponse.data?.model_groups ?? [], [groupsQueryResponse.data?.model_groups])
  const [{ bindings, values, setValue }, initialValues] = useVehicleFormValues(data)
  const selectedGroup = groups.find((g) => g.id === values.model?.modelGroup?.id)
  const { validate, errors } = useVehicleValidationSchema(values, data?.brand?.id, metadata)
  const isAdmin = useEzAuthUserHasRoles(UserRole.ADMIN)
  const { formatDateString, formatNumber, formatCO2Measurement, formatModelReleaseYear } = useFormatter()
  const invalidModel =
    !data?.model?.modelGroup?.id || !data?.model?.sales_model_code || !data?.model?.model_group_release_year

  const onSubmit = useCallback(async () => {
    const { isValid } = await validate({ silent: disableValidationOnSave ?? false })
    if (isValid || disableValidationOnSave) {
      await onSave(values)
    }
  }, [disableValidationOnSave, onSave, validate, values])

  const { openNestedDetailView, closeDetailView } = useDetailView()
  const openStateModal = useCallback(() => {
    openNestedDetailView(DetailViewEnum.VehicleChangeState, {
      data,
      onSave: async (data) => {
        await onSave(data)
        closeDetailView(DetailViewEnum.VehicleChangeState)
      },
      onClose: () => closeDetailView(DetailViewEnum.VehicleChangeState),
    })
  }, [closeDetailView, data, onSave, openNestedDetailView])

  const openModelDetail = useCallback(() => {
    openNestedDetailView(DetailViewEnum.ModelDetail, {
      usedCar,
      brandId: data.brand?.id,
      modelId: data.model?.id,
      modal: true,
      onClose: () => closeDetailView(DetailViewEnum.ModelDetail),
    })
  }, [closeDetailView, data?.brand?.id, data?.model?.id, openNestedDetailView, usedCar])

  useEffect(() => {
    if (!initialValues.state) {
      setValue('state', Dealer_Vehicle_State_Enum.Available)
    }
    if (!initialValues.order_state) {
      setValue('order_state', Order_State_Enum.InTransport)
    }
    if (!initialValues.type) {
      if (data?.mileage < 15) {
        setValue('type', Dealer_Vehicle_Type_Enum.NewCar)
      } else {
        setValue('type', Dealer_Vehicle_Type_Enum.UsedCar)
      }
    }
    metadata?.metafields?.forEach((meta) => {
      meta?.default_value &&
        !initialValues[meta?.vehicle_metafield?.column_name] &&
        setValue(meta.vehicle_metafield.column_name as any, meta.default_value)
    })
  }, [data?.mileage, initialValues, metadata?.metafields, setValue])

  const copyBrandModelCodeToSalesModelCode = useCallback(() => {
    setValue('model', {
      ...values.model,
      sales_model_code: data?.model?.model_code,
    })
  }, [data?.model?.model_code, setValue, values.model])

  return useMemo(
    () => ({
      sections: [
        {
          name: 'general',
          label: 'Identifikation',
          column: 1,
          fields: [
            usedCar
              ? undefined
              : {
                  source: DataSource.LOCOSOFT,
                  name: 'job_number',
                  label: 'Bestellnummer',
                  FormComponent: <TextField value={data?.job_number ?? '-'} disabled />,
                  disabled: true,
                  placeholder: data?.job_number,
                },
            {
              source: DataSource.LOCOSOFT,
              name: 'vin',
              label: 'FIN',
              FormComponent: <TextField value={data?.vin} disabled />,
              disabled: true,
              placeholder: data?.vin,
            },
          ],
        },
        {
          name: 'state',
          label: 'Status',
          column: 1,
          fields: [
            {
              source: DataSource.PCORE,
              label: 'Status',
              name: 'state',
              required: metadata.importType === Import_Type_Enum.Full,
              error: errors.state as string,
              placeholder: data?.state ? <DealerVehicleStateChip state={data.state} /> : '-',
              FormComponent: (
                <DealerVehicleStateSelect value={values.state} onSelect={(state) => setValue('state', state)} />
              ),
              onEdit: errorState !== SyncErrorState.UNRESOLVED ? () => openStateModal() : undefined,
            },
            {
              source: DataSource.PCORE,
              name: 'order_state',
              label: 'Transportstatus',
              placeholder: data?.order_state ? <OrderStateChip state={data.order_state} /> : '-',
              required: metadata.importType === Import_Type_Enum.Full,
              error: errors.order_state as string,
              FormComponent: (
                <OrderStateSelect value={values.order_state} onSelect={(state) => setValue('order_state', state)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'handover_planned_date',
              label: 'Auslieferungsdatum',
              placeholder: formatDateString(data?.handover_planned_date),
              FormComponent: (
                <DatePicker
                  value={values?.handover_planned_date}
                  onChange={(date) => setValue('handover_planned_date', date.toISOString())}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'handover_date',
              label: 'Erfassungsdatum',
              placeholder: formatDateString(data?.handover_date),
              FormComponent: (
                <DatePicker
                  value={values?.handover_date}
                  onChange={(date) => setValue('handover_date', date.toISOString())}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'handover_by',
              label: 'Ausgeliefert von',
              placeholder: data?.handover_by?.full_name || data?.handover_by?.email || '-',
              FormComponent: (
                <UserSelect value={values.handover_by_id} onSelect={(user) => setValue('handover_by_id', user.id)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'delivery_date_str',
              label: 'Lieferdatum',
              placeholder: data?.delivery_date_str,
              required: metadata.importType === Import_Type_Enum.Full,
              error: errors.delivery_date_str as string,
              FormComponent: <TextField {...bindings.delivery_date_str} />,
            },
            {
              source: DataSource.PCORE,
              name: 'storage_location',
              label: 'Lagerort',
              placeholder: data?.storage_location?.name,
              required:
                values.order_state === Order_State_Enum.InStock && metadata.importType === Import_Type_Enum.Full,
              error: errors.storage_location_id as string,
              FormComponent: (
                <StorageLocationSelect
                  value={values.storage_location_id}
                  onSelect={(id, { default_note }) => {
                    setValue('storage_location_id', id)
                    setValue('storage_location_note', default_note ?? '')
                  }}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'storage_location_note',
              label: 'Lagerort Notiz',
              placeholder: data?.storage_location_note,
              FormComponent: <TextField {...bindings.storage_location_note} />,
            },
            {
              source: DataSource.PCORE,
              name: 'delivery_location',
              label: 'Lieferort',
              placeholder: data?.delivery_location?.name,
              required: metadata.importType === Import_Type_Enum.Full,
              error: errors.delivery_location_id as string,
              FormComponent: (
                <StorageLocationSelect
                  value={values.delivery_location_id}
                  onSelect={(id) => setValue('delivery_location_id', id)}
                />
              ),
            },
            {
              source: DataSource.LOCOSOFT,
              name: 'dms_assigned_location',
              label: 'Betreuende Betriebsstätte',
              placeholder: data?.dms_assigned_location?.name,
              disabled: true,
              FormComponent: <TextField value={data?.dms_assigned_location?.name} disabled></TextField>,
            },
            {
              source: DataSource.PCORE,
              name: 'seller_location',
              label: 'Verkaufende Betriebsstätte',
              placeholder: data?.seller_location?.name,
              FormComponent: (
                <LocationSelect
                  hideLabel
                  value={values.seller_location_id}
                  onSelect={(id) => setValue('seller_location_id', id)}
                />
              ),
            },
            {
              source: DataSource.LOCOSOFT,
              name: 'days_since_purchase',
              label: 'Standtage',
              placeholder: data?.days_since_purchase,
              disabled: true,
              FormComponent: (
                <div className="flex space-x-4 items-center">
                  <TextField className={'flex-1'} type={'number'} value={data?.days_since_purchase} disabled />
                  <div style={{ flex: 2 }}>{`Rechnungsdatum: ${formatDateString(data?.invoice_date)}`}</div>
                </div>
              ),
            },
          ],
        },
        {
          name: 'info',
          label: 'Fahrzeuginformation',
          column: 1,
          fields: [
            {
              source: DataSource.LOCOSOFT,
              name: 'model_name',
              label: 'Modell',
              disabled: true,
              placeholder: data?.model_name,
              FormComponent: <TextField value={data?.model_name} disabled />,
            },
            {
              source: DataSource.PCORE,
              name: 'modelGroup',
              label: 'Modell Gruppe',
              placeholder: `${data?.model?.modelGroup?.name ?? '-'} (${formatModelReleaseYear(
                data?.model?.model_group_release_year
              )})`,
              onEdit:
                errorState === SyncErrorState.UNRESOLVED
                  ? undefined
                  : invalidModel
                  ? () => openModelDetail()
                  : undefined,
              required: metadata.importType === Import_Type_Enum.Full,
              FormComponent: (
                <>
                  {data?.brand && (
                    <L.Vertical spacing={2}>
                      <ModelGroupSelect
                        groups={groups}
                        value={values.model?.modelGroup?.id}
                        onSelect={(id) =>
                          setValue('model', {
                            ...values.model,
                            modelGroup: { ...values.model?.modelGroup, id },
                            model_group_release_year: ModelReleaseYear.UNKNOWN,
                          })
                        }
                      />
                      <ModelGroupReleaseYearSelect
                        group={selectedGroup}
                        value={values?.model?.model_group_release_year}
                        onSelect={(model_group_release_year) =>
                          setValue('model', {
                            ...values.model,
                            model_group_release_year,
                          })
                        }
                      />
                    </L.Vertical>
                  )}
                </>
              ),
            },
            usedCar
              ? undefined
              : {
                  source: DataSource.PCORE,
                  name: 'sales_model_code',
                  label: 'Modell Code',
                  placeholder: data?.model?.sales_model_code,
                  disabled: true,
                  required: metadata.importType === Import_Type_Enum.Full,
                  FormComponent: (
                    <SalesModelCodeInput
                      onClick={copyBrandModelCodeToSalesModelCode}
                      value={values?.model?.sales_model_code}
                      onChange={({ currentTarget }) =>
                        setValue('model', {
                          ...values.model,
                          sales_model_code: currentTarget.value,
                        })
                      }
                    />
                  ),
                },
            {
              source: DataSource.LOCOSOFT,
              name: 'exterior',
              label: 'Exterior',
              placeholder: `${data?.color_exterior_name || '-'} (${data?.color_exterior_code || '-'})`,
              disabled: true,
              required: metadata.importType === Import_Type_Enum.Full,
              FormComponent: (
                <TextField
                  value={`${data?.color_exterior_name || '-'} (${data?.color_exterior_code || '-'})`}
                  disabled
                />
              ),
            },
            {
              source: DataSource.LOCOSOFT,
              name: 'interior',
              label: 'Interior',
              placeholder: `${data?.color_interior_name || '-'} (${data?.color_interior_code || '-'})`,
              disabled: true,
              required: metadata.importType === Import_Type_Enum.Full,
              FormComponent: (
                <TextField
                  value={`${data?.color_interior_name || '-'} (${data?.color_interior_code || '-'})`}
                  disabled
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'specs',
              label: 'Ausstattung',
              placeholder: data?.specs,
              FormComponent: <TextField value={data?.specs} multiline {...bindings.specs} maxRows={3} minRows={3} />,
            },
            {
              source: DataSource.PCORE,
              name: 'spec_level',
              label: 'Ausstattungslevel',
              placeholder: data?.spec_level,
              FormComponent: <TextField value={data?.spec_level} {...bindings.spec_level} />,
            },
            {
              source: DataSource.PCORE,
              name: 'type',
              label: 'Typ',
              placeholder: data?.type ? <DealerVehicleTypeChip type={data.type} /> : '-',
              required: metadata.importType === Import_Type_Enum.Full,
              FormComponent: <DealerVehicleTypeSelect value={values.type} onSelect={(val) => setValue('type', val)} />,
            },
            {
              source: DataSource.LOCOSOFT,
              name: 'first_registration_date',
              label: 'Erstzulassung',
              placeholder: formatDateString(data?.first_registration_date),
              disabled: true,
              FormComponent: <DatePicker value={data?.first_registration_date} disabled />,
            },
            {
              source: DataSource.LOCOSOFT,
              name: 'warranty_start',
              label: 'Garantiestart',
              placeholder: formatDateString(data?.warranty_start),
              disabled: true,
              FormComponent: <DatePicker value={data?.warranty_start} disabled />,
            },
            {
              source: DataSource.LOCOSOFT,
              name: 'mileage',
              label: 'Kilometer',
              placeholder: formatNumber(data?.mileage),
              disabled: true,
              FormComponent: <TextField type="number" value={data?.mileage} disabled />,
            },
            {
              source: DataSource.LOCOSOFT,
              name: 'mileage_date',
              label: 'Ablesedatum KM',
              placeholder: formatDateString(data?.mileage_date),
              disabled: true,
              FormComponent: <DatePicker value={data?.mileage_date} disabled />,
            },
            {
              source: DataSource.PCORE,
              name: 'min_holding_period',
              label: 'Haltefrist',
              placeholder: data?.min_holding_period
                ? data?.first_registration_date
                  ? `${formatDateString(
                      DateFns.addDays(new Date(data.first_registration_date), data.min_holding_period ?? 0)
                    )} (${data.min_holding_period})`
                  : `${data.min_holding_period} Tage`
                : '-',
              FormComponent: (
                <div className="flex space-x-4 items-center">
                  <TextField
                    {...bindings.min_holding_period}
                    className="flex-1"
                    type="number"
                    onChange={({ currentTarget }) => {
                      setValue('min_holding_period', currentTarget.value ? Number(currentTarget.value) : null)
                    }}
                  />
                  {data?.first_registration_date && (
                    <div className="flex-1">
                      Datum:{' '}
                      {formatDateString(
                        DateFns.addDays(new Date(data.first_registration_date), values.min_holding_period ?? 0)
                      )}
                    </div>
                  )}
                </div>
              ),
            },
            {
              source: DataSource.LOCOSOFT,
              name: 'license_plate_number',
              label: 'Kennzeichen',
              placeholder: data?.license_plate_number,
              disabled: true,
              FormComponent: <TextField value={data?.license_plate_number} disabled />,
            },
            {
              source: DataSource.PCORE,
              name: 'registration_state',
              label: 'Zulassungs Status',
              placeholder: data?.registration_state ? t(`RegistrationState.${data.registration_state}`) : '-',
              error: errors.registration_state as string,
              required: metadata.importType === Import_Type_Enum.Full,
              FormComponent: (
                <RegistrationStateSelect
                  value={values.registration_state}
                  onSelect={(state) => setValue('registration_state', state)}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'registration_owner',
              label: 'ZL Besitzer',
              placeholder: data?.registration_owner,
              error: errors.registration_owner as string,
              required:
                values.registration_state === Registration_State_Enum.Registered &&
                metadata.importType === Import_Type_Enum.Full,
              FormComponent: <TextField {...bindings.registration_owner} />,
            },
            {
              source: DataSource.PCORE,
              name: 'key_kabinet_number',
              label: 'Schlüsselschrank Nr',
              placeholder: data?.key_kabinet_number,
              FormComponent: <TextField {...bindings.key_kabinet_number} />,
            },
            usedCar
              ? {
                  source: DataSource.PCORE,
                  name: 'keys_count',
                  label: 'Anzahl der Schlüssel',
                  placeholder: data?.keys_count,
                  FormComponent: (
                    <TextField
                      {...bindings.keys_count}
                      type="number"
                      min={0}
                      onChange={({ currentTarget }) => {
                        setValue('keys_count', currentTarget.value ? Number(currentTarget.value) : null)
                      }}
                    />
                  ),
                }
              : undefined,
            usedCar
              ? {
                  source: DataSource.PCORE,
                  name: 'co2_nefz',
                  label: 'CO2 NEFZ (NEDC)',
                  placeholder: formatCO2Measurement(data?.co2_nefz),
                  FormComponent: (
                    <TextField
                      {...bindings.co2_nefz}
                      type="number"
                      min={0}
                      onChange={({ currentTarget }) => {
                        setValue('co2_nefz', currentTarget.value ? Number(currentTarget.value) : null)
                      }}
                    />
                  ),
                }
              : undefined,
            usedCar
              ? {
                  source: DataSource.PCORE,
                  name: 'co2_wltp',
                  label: 'CO2 WLTP (WLTC)',
                  placeholder: formatCO2Measurement(data?.co2_wltp),
                  FormComponent: (
                    <TextField
                      {...bindings.co2_wltp}
                      type="number"
                      min={0}
                      onChange={({ currentTarget }) => {
                        setValue('co2_wltp', currentTarget.value ? Number(currentTarget.value) : null)
                      }}
                    />
                  ),
                }
              : undefined,
          ],
        },
        {
          name: 'sell',
          label: 'Verkauf',
          column: 2,
          fields: [
            {
              source: DataSource.PCORE,
              name: 'sold_by',
              label: 'Verkäufer',
              placeholder: data?.sold_by?.full_name || data?.sold_by?.email || '-',
              required: metadata.importType === Import_Type_Enum.Partial,
              FormComponent: (
                <UserSelect value={values.sold_by_id} onSelect={(user) => setValue('sold_by_id', user.id)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'customer',
              label: 'Kunde',
              placeholder: data?.customer,
              FormComponent: <TextField {...bindings.customer} />,
            },
            {
              source: DataSource.PCORE,
              name: 'customer_type',
              label: 'Kundentyp',
              placeholder: data?.customer_type ? t(`CustomerType.${data?.customer_type}`) : '-',
              FormComponent: (
                <CustomerTypeSelect value={values.customer_type} onSelect={(type) => setValue('customer_type', type)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'sold_at',
              label: 'Verkaufsdatum',
              placeholder: formatDateString(data?.sold_at),
              required: metadata.importType === Import_Type_Enum.Partial,
              FormComponent: (
                <DatePicker value={values.sold_at} onChange={(date) => setValue('sold_at', date.toISOString())} />
              ),
            },
          ],
        },
        {
          name: 'reservation',
          column: 2,
          label: 'Reservierung',
          fields: [
            {
              source: DataSource.PCORE,
              name: 'reserved_by',
              label: 'Reserviert von',
              placeholder: data?.reserved_by?.full_name || data?.reserved_by?.email || '-',
              FormComponent: (
                <UserSelect value={values.reserved_by_id} onSelect={(user) => setValue('reserved_by_id', user.id)} />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'reserved_for',
              label: 'Reserviert für',
              placeholder: data?.reserved_for,
              FormComponent: <TextField {...bindings.reserved_for} />,
            },
            {
              source: DataSource.PCORE,
              name: 'reserved_for_customer_type',
              label: 'Reserviert für (Typ)',
              placeholder: data?.reserved_for_customer_type
                ? t(`CustomerType.${data?.reserved_for_customer_type}`)
                : '-',
              FormComponent: (
                <CustomerTypeSelect
                  value={values.reserved_for_customer_type}
                  onSelect={(type) => setValue('reserved_for_customer_type', type)}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'reserved_at',
              label: 'Reserviert am',
              placeholder: formatDateString(data?.reserved_at),
              FormComponent: (
                <DatePicker
                  value={values.reserved_at}
                  onChange={(date) => setValue('reserved_at', date.toISOString())}
                />
              ),
            },
            {
              source: DataSource.PCORE,
              name: 'reserved_until',
              label: 'Reserviert bis',
              placeholder: formatDateString(data?.reserved_until),
              FormComponent: (
                <DatePicker
                  value={values.reserved_until}
                  onChange={(date) => setValue('reserved_until', date.toISOString())}
                />
              ),
            },
          ],
        },
        {
          name: 'purchase',
          column: 2,
          label: 'Zukauf',
          fields: usedCar
            ? [
                {
                  source: DataSource.PCORE,
                  name: 'in_buy_channel',
                  label: 'Zukaufs Kanal',
                  placeholder: <InBuyChannelChip channel={data?.in_buy_channel} />,
                  onEdit: !isAdmin && undefined,
                  required: metadata.importType === Import_Type_Enum.Partial,
                  error: errors.in_buy_channel as string,
                  FormComponent: (
                    <InBuyChannelSelect
                      value={values.in_buy_channel}
                      onSelect={(val) => setValue('in_buy_channel', val)}
                      disabled={!isAdmin}
                    />
                  ),
                },
                {
                  source: DataSource.PCORE,
                  name: 'purchased_by_id',
                  label: 'Zukauf durch',
                  placeholder: data?.purchased_by_id,
                  FormComponent: (
                    <UserSelect
                      value={values.purchased_by_id}
                      onSelect={(user) => setValue('purchased_by_id', user.id)}
                    />
                  ),
                },
                {
                  source: DataSource.PCORE,
                  name: 'in_buy_contract_date',
                  label: 'Einkaufsvertragsdatum',
                  placeholder: data?.in_buy_contract_date,
                  FormComponent: (
                    <DatePicker
                      value={values.in_buy_contract_date}
                      onChange={(date) => setValue('in_buy_contract_date', date.toISOString())}
                    />
                  ),
                },
              ]
            : [
                {
                  source: DataSource.PCORE,
                  name: 'in_buy_channel',
                  label: 'Zukaufs Kanal',
                  placeholder: <InBuyChannelChip channel={data?.in_buy_channel} />,
                  onEdit: !isAdmin && undefined,
                  required: metadata.importType === Import_Type_Enum.Partial,
                  error: errors.in_buy_channel as string,
                  FormComponent: (
                    <InBuyChannelSelect
                      value={values.in_buy_channel}
                      onSelect={(val) => setValue('in_buy_channel', val)}
                      disabled={!isAdmin}
                    />
                  ),
                },
                {
                  source: DataSource.PCORE,
                  name: 'in_buy_contract_date',
                  label: 'Auftragsdatum',
                  placeholder: data?.in_buy_contract_date,
                  FormComponent: (
                    <DatePicker
                      value={values.in_buy_contract_date}
                      onChange={(date) => setValue('in_buy_contract_date', date.toISOString())}
                    />
                  ),
                },
              ],
        },
        {
          name: 'metafields',
          column: 2,
          label: 'Markenspeizifische Informationen',
          fields: metadata?.metafields?.map((metafield) => ({
            source: DataSource.PCORE,
            name: metafield.vehicle_metafield.column_name,
            label: metafield.vehicle_metafield.title,
            placeholder:
              !!data?.[metafield.vehicle_metafield.column_name] ||
              data?.[metafield.vehicle_metafield.column_name] === false
                ? data[metafield.vehicle_metafield.column_name]
                : '-',
            required: metafield.required_for_import && metadata.importType === Import_Type_Enum.Full,
            error: errors[metafield.vehicle_metafield.column_name] as string,
            FormComponent: (
              <TextField
                {...bindings[metafield.vehicle_metafield.column_name]}
                onChange={(evt) => setValue(metafield.vehicle_metafield.column_name as any, evt.target.value)}
              />
            ),
          })),
        },
      ],
      onSubmit,
      errors,
    }),
    [
      formatModelReleaseYear,
      copyBrandModelCodeToSalesModelCode,
      usedCar,
      data,
      metadata,
      errors,
      values,
      errorState,
      bindings,
      formatDateString,
      groups,
      selectedGroup,
      formatNumber,
      t,
      formatCO2Measurement,
      isAdmin,
      invalidModel,
      onSubmit,
      setValue,
      openStateModal,
      openModelDetail,
    ]
  )
}
