import clsx from 'clsx'
import { Button, Card, Icon, Spinner, Typo } from 'components'
import { LogLevel, SyncEntity, SyncPhase, SyncType, useCloudwatchLogsLazyQuery } from 'gql'
import * as L from 'layouts'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DateFns } from 'utils'
import { MonitoringFilterContext } from './MonitoringFilter'

const syncStateName = {
  success: 'Fehlerfrei',
  warning: 'In Bearbeitung',
  error: 'Fehlgeschlagen',
}

export const AdminMonitoringProgressCard = () => {
  const { state } = useContext(MonitoringFilterContext)

  const [latestInfoLogs, setLatestInfoLogs] = useState<{ message: string; timestamp: string }[]>([])
  const [latestFullInfoLogs, setLatestFullInfoLogs] = useState<{ message: string; timestamp: string }[]>([])

  const [loading, setLoading] = useState<boolean>(false)

  const [loadInfoLogs] = useCloudwatchLogsLazyQuery({ fetchPolicy: 'no-cache' })

  const loadLatestInfoLogs = useCallback(
    async (all: boolean = true) => {
      setLoading(true)
      const latestLogsResult = await loadInfoLogs({
        variables: {
          input: {
            end: new Date().toISOString(),
            start: DateFns.subHours(new Date(), 24).toISOString(),
            entity: state.selectedSyncEntity?.syncEntity as SyncEntity,
            level: LogLevel.Info as LogLevel,
            limit: 10,
            phase: state.selectedSyncEntity?.phases === 2 ? SyncPhase.Sync : undefined,
          },
        },
      })
      if (all) {
        const latestFullLogsResult = await loadInfoLogs({
          variables: {
            input: {
              end: new Date().toISOString(),
              start: DateFns.subHours(new Date(), 24).toISOString(),
              entity: state.selectedSyncEntity?.syncEntity as SyncEntity,
              level: LogLevel.Info as LogLevel,
              limit: 10,
              syncType: SyncType.Full,
              phase: state.selectedSyncEntity?.phases === 2 ? SyncPhase.Sync : undefined,
            },
          },
        })
        setLatestFullInfoLogs(latestFullLogsResult.data?.cloudwatch_logs?.results ?? [])
      }

      setLatestInfoLogs(latestLogsResult.data?.cloudwatch_logs?.results ?? [])

      setLoading(false)
    },
    [loadInfoLogs, state.selectedSyncEntity?.phases, state.selectedSyncEntity?.syncEntity]
  )

  useEffect(() => {
    if (!!state.selectedSyncEntity && state.selectedSyncEntity?.status !== 'error') {
      loadLatestInfoLogs()
    }
  }, [loadLatestInfoLogs, state.selectedSyncEntity])

  const missingDailySync = useMemo(() => {
    const latestFullProgressLog = latestFullInfoLogs.find((log) => JSON.parse(log.message)?.currentCount !== undefined)
    if (!latestFullProgressLog) {
      return true
    } else {
      return false
    }
  }, [latestFullInfoLogs])

  const progress = useMemo(() => {
    const latestProgressLog = latestInfoLogs.find((log) => JSON.parse(log.message)?.currentCount !== undefined)
    if (!!latestProgressLog) {
      const logMessage = JSON.parse(latestProgressLog.message)
      if (logMessage.totalCount === 0) {
        return 100
      } else {
        return (logMessage.currentCount / logMessage.totalCount) * 100
      }
    }
  }, [latestInfoLogs])
  if (!state.selectedSyncEntity) return null

  return (
    <Card className="p-4 text-center" style={{ width: '100%', minHeight: '150px' }}>
      <L.Vertical>
        <L.Horizontal className="justify-evenly" style={{ height: 118 }}>
          <L.Vertical>
            <Typo variant="h4" component="p">
              Datenbank - status
            </Typo>
            <Typo
              component="p"
              variant="h3"
              className={clsx('font-bold', {
                'text-success-main': state.selectedSyncEntity.status === 'success',
                'text-warning-main': state.selectedSyncEntity.status === 'warning',
                'text-error-main': state.selectedSyncEntity.status === 'error',
              })}
            >
              {syncStateName[state.selectedSyncEntity.status]}
            </Typo>
          </L.Vertical>
          {state.selectedSyncEntity.status !== 'error' && (
            <L.Vertical className="relative">
              <Typo variant="h4" component="p">
                Synchronisations - fortschritt
              </Typo>
              {loading && <Spinner />}
              {!loading && (
                <Typo variant="h3" component="p" className="font-bold">
                  {`${progress ?? 0}%`}
                </Typo>
              )}
            </L.Vertical>
          )}
        </L.Horizontal>
        {missingDailySync && !loading && (
          <Typo variant="body1" className="text-left">
            <Icon name="info" />
            {` Tägliche Synchronisation wurde in den letzten 24 Stunden nicht gestartet`}
          </Typo>
        )}
        {state.selectedSyncEntity.status === 'warning' && (
          <L.Horizontal>
            <div className="flex-1"></div>
            <Button onClick={() => loadLatestInfoLogs(false)} disabled={loading}>
              Aktualisieren
            </Button>
          </L.Horizontal>
        )}
      </L.Vertical>
    </Card>
  )
}
