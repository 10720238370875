import { Card } from 'components/Card'
import { Tabs } from 'components/Tabs'
import React, { CSSProperties, useContext, useEffect, useMemo, useState } from 'react'
import { ExcelImportContext, ExcelImportEntity } from '.'
import { Import_Job_Executable_Status_Enum, Import_Job_Vehicle_Executables, Import_Job_Status_Enum } from '../../gql'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { LinearProgress } from '../../components/Progress'
import { uniqBy } from 'lodash'
import { Yalc2, Yalc2RowDefinition } from 'components/Yalc2'

export interface ExcelImportLogsProps {
  mappingKeys: { key: keyof ExcelImportEntity; value: string }[]
  entityNamePlural: string
  style?: CSSProperties
}

const PAGE_SIZE = 50

export const ExcelImportLogs = (props: ExcelImportLogsProps) => {
  const { state, dispatch, importJob } = useContext(ExcelImportContext)

  const [validationLogsFrom, setValidationLogsFrom] = useState(0)
  const [validationLogsTo, setValidationLogsTo] = useState(0)
  const [validationLogsPage, setValidationLogsPage] = useState<
    (Partial<Import_Job_Vehicle_Executables> & { id: string })[]
  >([])

  const [updateErrorLogsFrom, setUpdateErrorLogsFrom] = useState(0)
  const [updateErrorLogsTo, setUpdateErrorLogsTo] = useState(0)
  const [updateErrorLogsPage, setUpdateErrorLogsPage] = useState<
    (Partial<Import_Job_Vehicle_Executables> & { id: string })[]
  >([])

  const validationLogs = useMemo(
    () =>
      importJob?.executables?.filter(
        (exec) => exec.executable_status === Import_Job_Executable_Status_Enum.PreviewFailed
      ) ?? [],
    [importJob?.executables]
  )
  const updateErrorLogs = useMemo(
    () =>
      importJob?.executables?.filter(
        (exec) => exec.executable_status === Import_Job_Executable_Status_Enum.ImportFailed
      ) ?? [],
    [importJob?.executables]
  )
  const validExecutables = useMemo(
    () =>
      importJob?.executables?.filter(
        (exec) =>
          exec.executable_status === Import_Job_Executable_Status_Enum.ImportSucceeded ||
          exec.executable_status === Import_Job_Executable_Status_Enum.ImportFailed
      ) ?? [],
    [importJob?.executables]
  )
  const succeededExecutables = useMemo(
    () =>
      importJob?.executables?.filter(
        (exec) => exec.executable_status === Import_Job_Executable_Status_Enum.ImportSucceeded
      ) ?? [],
    [importJob?.executables]
  )
  useEffect(() => {
    if (validationLogs.length === 0) {
      setValidationLogsFrom(0)
      setValidationLogsTo(0)
      setValidationLogsPage([])
    } else {
      setValidationLogsFrom(0)
      setValidationLogsTo(validationLogs.length >= PAGE_SIZE ? PAGE_SIZE : validationLogs.length)
      setValidationLogsPage(validationLogs.slice(0, PAGE_SIZE))
    }
  }, [validationLogs])

  useEffect(() => {
    if (updateErrorLogs.length === 0) {
      setUpdateErrorLogsFrom(0)
      setUpdateErrorLogsTo(0)
      setUpdateErrorLogsPage([])
    } else {
      setUpdateErrorLogsFrom(0)
      setUpdateErrorLogsTo(updateErrorLogs.length >= PAGE_SIZE ? PAGE_SIZE : updateErrorLogs.length)
      setUpdateErrorLogsPage(updateErrorLogs.slice(0, PAGE_SIZE))
    }
  }, [updateErrorLogs])

  return (
    <Card className="m-4 px-4" style={{ overflow: 'scroll', ...props.style }}>
      <Tabs activeIndex={state.activeLogTab} onChange={(idx) => dispatch({ type: 'change-log-tab', payload: { idx } })}>
        <Tabs.TabList>
          <Tabs.Tab label="Validierungen" />
          <Tabs.Tab label="Import Logs" />
        </Tabs.TabList>
        <Tabs.TabPanes>
          <Tabs.TabPane>
            <div className="mt-4">
              <div>{`Es wurden ${validationLogs.length} Validierungsfehler gefunden.`}</div>
              <LogList
                mappingKeys={props.mappingKeys}
                data={validationLogsPage}
                from={validationLogsFrom}
                to={validationLogsTo}
                logs={validationLogs}
                setPage={setValidationLogsPage}
                setTo={setValidationLogsTo}
                setFrom={setValidationLogsFrom}
                type={'Validation'}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane>
            <div className="mt-4">
              {importJob?.status === Import_Job_Status_Enum.ExecutionInProgress ? (
                <LinearProgress value={(validExecutables.length / importJob?.executables.length) * 100} />
              ) : (
                <>
                  {importJob?.status === Import_Job_Status_Enum.Done && (
                    <>
                      <div>{`${validExecutables?.length ?? 0} Excel Einträge wurden verarbeitet.`}</div>
                      <div>{`${uniqBy(validExecutables, importJob?.matching_field)?.length} eindeutige ${
                        props.entityNamePlural
                      } wurden laut ihren ${
                        props.mappingKeys.find((mk) => mk.key === importJob?.matching_field)?.value ?? ''
                      } in der .xlsx Datei gefunden.`}</div>
                      <div>{`${succeededExecutables.length} Updates wurden durchgeführt.`}</div>
                    </>
                  )}
                  <LogList
                    mappingKeys={props.mappingKeys}
                    data={updateErrorLogsPage}
                    from={updateErrorLogsFrom}
                    to={updateErrorLogsTo}
                    logs={updateErrorLogs}
                    setPage={setUpdateErrorLogsPage}
                    setTo={setUpdateErrorLogsTo}
                    setFrom={setUpdateErrorLogsFrom}
                    type={'Import'}
                  />
                </>
              )}
            </div>
          </Tabs.TabPane>
        </Tabs.TabPanes>
      </Tabs>
    </Card>
  )
}

interface LogListProps {
  mappingKeys: { key: keyof ExcelImportEntity; value: string }[]
  data: (Partial<Import_Job_Vehicle_Executables> & { id: string })[]
  from: number
  to: number
  logs: Partial<Import_Job_Vehicle_Executables>[]
  setPage: (value: React.SetStateAction<Partial<Import_Job_Vehicle_Executables>[]>) => void
  setFrom: (value: React.SetStateAction<number>) => void
  setTo: (value: React.SetStateAction<number>) => void
  type: 'Validation' | 'Import'
}

const LogList: React.FC<LogListProps> = (props) => {
  const { from, to, logs, setPage, setFrom, setTo } = props
  const rowDef = useRowDefinition(props)
  return (
    <div className="mt-4">
      <Yalc2<Partial<Import_Job_Vehicle_Executables> & { id: string }>
        data={props.data}
        rowDefinition={rowDef}
        paginationNavigation={{
          content: { from: from + 1, to, total: logs.length },
          fetchPreviousPage: () => {
            setPage(logs.slice(from - PAGE_SIZE, to - PAGE_SIZE))
            setFrom((prev) => prev - PAGE_SIZE)
            setTo((prev) => prev - PAGE_SIZE)
          },
          fetchNextPage: () => {
            setPage(logs.slice(from + PAGE_SIZE, to + PAGE_SIZE))
            setFrom((prev) => prev + PAGE_SIZE)
            setTo((prev) => prev + PAGE_SIZE)
          },
          hasNextPage: to < logs.length,
          hasPreviousPage: from > 0,
        }}
      />
    </div>
  )
}

const useRowDefinition = (
  props: LogListProps
): Yalc2RowDefinition<Partial<Import_Job_Vehicle_Executables> & { id: string }> => {
  const { mappingKeys, type } = props
  const { importJob } = useContext(ExcelImportContext)
  const identifierTitle = mappingKeys.find((mk) => mk.key === importJob?.matching_field)?.value ?? ''
  return useMemo(() => {
    return {
      columns: [
        {
          width: 50,
          title: 'Zeile',
          cell: function Row(props) {
            return <Yalc2Cell.SimpleCell>{props.item.row_number}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 100,
          title: identifierTitle,
          cell: function identifier(props) {
            return <Yalc2Cell.SimpleCell>{props.item[importJob?.matching_field]}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 500,
          title: 'Error',
          cell: function Error(props) {
            return (
              <Yalc2Cell.MaxWidthCell>
                {type === 'Validation' ? props.item.validation_error : props.item.import_error}
              </Yalc2Cell.MaxWidthCell>
            )
          },
        },
      ],
    }
  }, [identifierTitle, importJob?.matching_field, type])
}
