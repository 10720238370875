import { useEzAuthUserHasRoles, UserRole } from '@lib/ezauth'
import { Select } from 'components'
import { In_Buy_Channel_Enum } from 'gql'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

interface InBuyChannelSelectProps {
  value: In_Buy_Channel_Enum
  onSelect: (value: In_Buy_Channel_Enum) => void
  className?: string
  style?: CSSProperties
  disabled?: boolean
}

export const InBuyChannelSelect = (props: InBuyChannelSelectProps) => {
  const { t } = useTranslation()
  const isAdmin = useEzAuthUserHasRoles(UserRole.ADMIN)
  const options = Object.values(In_Buy_Channel_Enum).filter(
    (o) => isAdmin || o !== In_Buy_Channel_Enum.UnknownUsedCarBuyIn
  )
  const handleChange = async (evt) => props.onSelect(evt.target.value)
  options.sort((a, b) => (a < b ? -1 : 1))
  return (
    <Select
      renderSelectValueItem={(val) => t(`InBuyChannel.${val}`)}
      value={props.value}
      onChange={handleChange}
      className={props.className}
      style={props.style}
      disabled={props.disabled}
    >
      {options.map((o, idx) => (
        <Select.Option key={`in-buy-channel-select-option-${idx}`} value={o}>
          {t(`InBuyChannel.${o}`)}
        </Select.Option>
      ))}
    </Select>
  )
}
