import { AdminBrandEmbeddedPagesListItem } from 'containers/AdminBrandDetail/06-embedded_pages'
import { useEmbeddedPagesSelectQuery } from 'gql'
import React, { CSSProperties, useMemo } from 'react'
import { Autocomplete } from '../../components/Autocomplete'

interface EmbeddedPageSelectProps {
  value: string
  onChange: (name: string) => void
  brandId?: string
  embeddedPage?: AdminBrandEmbeddedPagesListItem
  filter?: (name: string) => boolean
  className?: string
  style?: CSSProperties
}

export const EmbeddedPageSelect = (props: EmbeddedPageSelectProps) => {
  const distinctPagesQuery = useEmbeddedPagesSelectQuery({ fetchPolicy: 'network-only' })

  const distinctPages = useMemo(() => distinctPagesQuery.data?.embedded_pages ?? [], [
    distinctPagesQuery.data?.embedded_pages,
  ])

  const options = useMemo(
    () =>
      distinctPages
        .filter((page) => {
          if (props.filter) {
            return props.filter(page.name)
          } else {
            return true
          }
        })
        .map((option) => ({
          value: option.name,
        })),
    [distinctPages, props]
  )
  const handleChange = (value) => props.onChange(value)
  return (
    <Autocomplete
      className={props.className}
      style={props.style}
      value={props.value}
      onChange={handleChange}
      options={options}
    />
  )
}
