import { init, captureException as capture, withScope, configureScope } from '@sentry/react'
import { User, ezAuthEventHub, EzAuthEventType } from '@lib/ezauth'
import { useConfig } from 'config'
import { useCallback } from 'react'

export const useSentry = () => {
  const { sentry } = useConfig()
  init({
    ...sentry,
    normalizeDepth: 8,
  })
}

type ExtraInformation = {
  [key: string]: any
}

export const captureException = (error: Error, extraInfo?: ExtraInformation) => {
  withScope((scope) => {
    Object.keys(extraInfo).forEach((key) => {
      scope.setExtra(key, extraInfo[key])
    })
    capture(error)
  })
}

export const useSentryLog = () => {
  const log = useCallback((error: Error, extraInfo: ExtraInformation = {}) => {
    captureException(error, extraInfo)
  }, [])

  return [log] as [typeof log]
}

const setSentryUser = (user: User) => {
  configureScope((scope) => {
    scope.setUser(user)
  })
}

ezAuthEventHub.subscribe((event) => {
  if (event.type === EzAuthEventType.SIGNED_IN) {
    setSentryUser(event.user)
  } else if (event.type === EzAuthEventType.SIGNED_OUT) {
    setSentryUser(null)
  }
})
