import React, { CSSProperties } from 'react'

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import InfoIcon from '@mui/icons-material/Info'
import PhoneIcon from '@mui/icons-material/Phone'
import PhoneIphone from '@mui/icons-material/PhoneIphone'
import VisibiltyIcon from '@mui/icons-material/Visibility'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import ScheduleIcon from '@mui/icons-material/Schedule'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import CommentIcon from '@mui/icons-material/Comment'
import ReplayIcon from '@mui/icons-material/Replay'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import MailIcon from '@mui/icons-material/Mail'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ClearIcon from '@mui/icons-material/Clear'
import SendIcon from '@mui/icons-material/Send'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import NotificationsIcon from '@mui/icons-material/Notifications'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import PushPinIcon from '@mui/icons-material/PushPin'
import PersonIcon from '@mui/icons-material/Person'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import SyncIcon from '@mui/icons-material/Sync';
import SellIcon from '@mui/icons-material/Sell';
import { Tooltip } from 'components'

import clsx from 'clsx'
import styled from './Icon.module.css'

const icons = {
  warehouse: WarehouseIcon,
  person: PersonIcon,
  localShipping: LocalShippingIcon,
  power: PowerSettingsNewIcon,
  none: PanoramaFishEyeIcon,
  addCircle: AddCircleIcon,
  add: AddIcon,
  delete: DeleteForeverIcon,
  clear: ClearIcon,
  remove: RemoveIcon,
  edit: EditIcon,
  close: CloseIcon,
  check: CheckIcon,
  options: MoreVertIcon,
  next: NavigateNextIcon,
  before: NavigateBeforeIcon,
  info: InfoIcon,
  phone: PhoneIcon,
  mobilePhone: PhoneIphone,
  view: VisibiltyIcon,
  save: SaveAltIcon,
  schedule: ScheduleIcon,
  copy: FileCopyIcon,
  comment: CommentIcon,
  undo: ReplayIcon,
  email: AlternateEmailIcon,
  letter: MailIcon,
  down: ExpandMoreIcon,
  up: ExpandLessIcon,
  send: SendIcon,
  archive: ArchiveIcon,
  unarchive: UnarchiveIcon,
  notifications: NotificationsIcon,
  dropdown: ArrowDropDownIcon,
  pin: PushPinIcon,
  exclamationMark: PriorityHighIcon,
  assign: AssignmentIndIcon,
  upload: FileUploadIcon,
  download: FileDownloadIcon,
  sync: SyncIcon,
  sell: SellIcon
}

export interface IconProps {
  name: keyof typeof icons
  color?: 'inherit' | 'primary' | 'secondary' | 'warning' | 'disabled'
  fontSize?: 'small' | 'large'
  tooltip?: string
  className?: string
  style?: CSSProperties
}

export const Icon: React.FC<IconProps> = (props) => {
  const { name, tooltip, color, ...rest } = props

  const IconComp = icons[name]
  if (!IconComp) {
    console.warn(`invalid icon name '${name}'`)
    return null
  }

  let icon = <IconComp {...rest} className={clsx(styled[color], props.className)} />

  if (tooltip) {
    icon = <Tooltip content={tooltip}>{icon}</Tooltip>
  }

  return icon
}
