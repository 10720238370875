import React, { useEffect, useMemo, useState } from 'react'
import { Role_Enum, User } from 'gql'
import { Avatar, Menu, Tooltip } from 'components'
import { UserRoleChip } from './UserRoleChip'
import { StatusLight } from 'containers/StatusLight'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { Yalc2, Yalc2RowDefinition } from '../../components/Yalc2'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'

interface UsersListProps {
  users: Omit<User, 'teams' | 'teams_aggregate'>[]
}

export const UsersList = (props: UsersListProps) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const userToEdit = selectedIds?.length === 1 ? selectedIds[0] : null
  const {openDetailView} = useDetailView()
  const rowDef = useRowDefinition()
  useEffect(() => {
    if (userToEdit) {
      openDetailView(DetailViewEnum.UserDetail, {
        title: 'User bearbeiten',
        onClose: () => setSelectedIds([]),
        userId: userToEdit,
      })
    }
  }, [openDetailView, userToEdit])
  return (
    <>
      <Yalc2<Omit<User, 'teams' | 'teams_aggregate'>>
        data={props.users ?? []}
        rowDefinition={rowDef}
        onSelectionChanged={setSelectedIds}
        selectedIds={selectedIds}
        options={
          <Menu.Item onClick={() => openDetailView(DetailViewEnum.UserDetail, { title: 'Neuer User' })}>
            Neuer User
          </Menu.Item>
        }
      />
    </>
  )
}

const useRowDefinition = (): Yalc2RowDefinition<Omit<User, 'teams' | 'teams_aggregate'>> => {
  return useMemo(
    () => ({
      columns: [
        {
          width: 30,
          title: '',
          cell: function StateCell(props) {
            return (
              <Tooltip content={`Account ist ${props.item.isActive ? 'aktiviert' : 'deaktiviert'}.`}>
                <div>
                  <StatusLight variant="filled" status={props.item.isActive ? 'success' : 'error'}></StatusLight>
                </div>
              </Tooltip>
            )
          },
        },
        {
          width: 60,
          title: 'Abk.',
          cell: function AbbreviationCell(props) {
            const abbreviation = deriveUserAbbreviation(props.item)
            return (
              <div>
                <Avatar alt={`Abkürzung: ${abbreviation}`} className="bg-primary-main">
                  {abbreviation}
                </Avatar>
              </div>
            )
          },
        },
        {
          width: 250,
          title: 'Email',
          cell: function EmailCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.email}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 250,
          title: 'Name',
          cell: function NameCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.full_name}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 100,
          title: 'Primäre Rolle',
          cell: function RoleCell(props) {
            return (
              <div>
                <UserRoleChip role={props.item.primary_role as Role_Enum} />
              </div>
            )
          },
        },
      ],
    }),
    []
  )
}

export const deriveUserAbbreviation = (user?: { email?: string; abbreviation?: string }) => {
  return (
    user?.abbreviation ||
    user?.email
      .split('@')[0]
      .split('.')
      .map((part) => part[0])
      .join()
      .toLocaleUpperCase()
  )
}
