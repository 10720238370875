import React, { useEffect, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import * as L from 'layouts'
import { Button, Spinner, TextField, Typo } from '../../components'
import { useFormFields } from '@w2solutions/react-hooks'
import {
  AdminSupplierDetailQuery,
  DmsReference,
  Dms_Type_Enum,
  useAdminSupplierDetailLazyQuery,
  useCreateSupplierMutation,
  useUpdateSupplierMutation,
} from '../../gql'
import { removeTypename } from '../../utils'
import { DmsReferenceFormSection } from '../DmsReferenceFormSection'
import { DetailWindow } from 'components/DetailWindow'

interface AdminSupplierDetailProps {
  supplierId?: string
  onClose: () => void
  title: string
}

const initialData = {
  name: '',
  addressId: '',
  street: '',
  streetNo: '',
  city: '',
  zipCode: '',
  active: true,
  dmsReferences: [] as DmsReference[],
  customerDmsReferences: [] as DmsReference[],
}
type FormData = typeof initialData

const supplierToFormData = (supplier?: AdminSupplierDetailQuery['supplier']): FormData => {
  if (!supplier) {
    return { ...initialData, addressId: uuid() }
  }
  return {
    name: supplier.name ?? '',
    addressId: supplier.address?.id,
    street: supplier.address?.street ?? '',
    streetNo: supplier.address?.streetNo ?? '',
    city: supplier.address?.city ?? '',
    zipCode: supplier.address?.zipCode ?? '',
    active: supplier.active,
    dmsReferences: removeTypename(supplier.dmsReferences) ?? [],
    customerDmsReferences: removeTypename(supplier.customerDmsReferences) ?? [],
  }
}

export const AdminSupplierDetail = (props: AdminSupplierDetailProps) => {
  const [load, loadResponse] = useAdminSupplierDetailLazyQuery()
  useEffect(() => {
    if (props.supplierId) {
      load({ variables: { id: props.supplierId } })
    }
  }, [props.supplierId, load])

  const initialValues = useMemo(() => {
    return supplierToFormData(loadResponse.data?.supplier)
  }, [loadResponse.data])
  const { bindings, values, setValue } = useFormFields(initialValues, {
    resetOnInitialValueChange: true,
  })
  const [save] = useSave(props.supplierId)

  const submit = async () => {
    await save(values)
    props.onClose()
  }

  if (loadResponse.loading) {
    return <Spinner />
  }
  return (
    <DetailWindow onClose={props.onClose} width={700} title={props.title}>
      <L.Vertical className={'p-4'} spacing={6}>
        <L.Grid>
          <TextField label="Name" {...bindings.name} />
        </L.Grid>
        <L.Vertical>
          <Typo>Adresse</Typo>
          <L.Grid>
            <TextField label="Straße" {...bindings.street} />
            <TextField label="Nr." {...bindings.streetNo} />
            <TextField label="Ort" {...bindings.city} />
            <TextField label="PLZ" {...bindings.zipCode} />
          </L.Grid>
        </L.Vertical>
        <DmsReferenceFormSection
          title={'DMS Lieferanten Nummern'}
          dmsReferences={values.dmsReferences}
          onAdd={(blank) => setValue('dmsReferences', [...values.dmsReferences, blank])}
          onRemove={(index) => {
            const newValue = [...values.dmsReferences]
            newValue.splice(index, 1)
            setValue('dmsReferences', newValue)
          }}
          onChange={(index, data) => {
            const newValue = [...values.dmsReferences]
            newValue[index] = data
            setValue('dmsReferences', newValue)
          }}
        />
        <DmsReferenceFormSection
          title={'DMS Kunden Nummern'}
          dmsReferences={values.customerDmsReferences}
          onAdd={(blank) => setValue('customerDmsReferences', [...values.customerDmsReferences, blank])}
          onRemove={(index) => {
            const newValue = [...values.customerDmsReferences]
            newValue.splice(index, 1)
            setValue('customerDmsReferences', newValue)
          }}
          onChange={(index, data) => {
            const newValue = [...values.customerDmsReferences]
            newValue[index] = data
            setValue('customerDmsReferences', newValue)
          }}
        />
        <L.Horizontal spacing={2}>
          <Button color={'primary'} variant={'contained'} onClick={submit}>
            Speichern
          </Button>
          <Button variant={'outlined'} onClick={props.onClose}>
            Abbrechen
          </Button>
        </L.Horizontal>
      </L.Vertical>
    </DetailWindow>
  )
}

const useSave = (id?: string) => {
  const [create, createResponse] = useCreateSupplierMutation()
  const [update, updateResponse] = useUpdateSupplierMutation()
  const save = async (data: FormData) => {
    if (id) {
      await update({
        variables: {
          supplierId: id,
          addressId: data.addressId,
          refIds: [...data.dmsReferences, ...data.customerDmsReferences].map((ref) => ref.id),
          supplier: {
            id,
            name: data.name,
            active: data.active,
          },
          address: {
            street: data.street,
            streetNo: data.streetNo,
            city: data.city,
            zipCode: data.zipCode,
          },
          references: [
            ...data.dmsReferences.map((ref) => ({
              id: ref.id,
              name: ref.name,
              reference: ref.reference,
              type: Dms_Type_Enum.Locosoft,
              supplier_id: id,
            })),
            ...data.customerDmsReferences.map((ref) => ({
              id: ref.id,
              name: ref.name,
              reference: ref.reference,
              type: Dms_Type_Enum.Locosoft,
              supplier_customer_id: id,
            })),
          ],
        },
      })
    } else {
      const supplierId = uuid()
      await create({
        variables: {
          object: {
            id: supplierId,
            name: data.name,
            active: true,
            address: {
              data: {
                id: data.addressId,
                street: data.street,
                streetNo: data.streetNo,
                city: data.city,
                zipCode: data.zipCode,
              },
            },
            dmsReferences: {
              data: data.dmsReferences.map((ref) => ({
                id: ref.id,
                name: ref.name,
                reference: ref.reference,
                type: Dms_Type_Enum.Locosoft,
              })),
            },
            customerDmsReferences: {
              data: data.customerDmsReferences.map((ref) => ({
                id: ref.id,
                name: ref.name,
                reference: ref.reference,
                type: Dms_Type_Enum.Locosoft,
              })),
            },
          },
        },
      })
    }
  }
  const state = useMemo(
    () => ({
      loading: createResponse.loading || updateResponse.loading,
    }),
    [createResponse.loading, updateResponse.loading]
  )
  return [save, state] as const
}
