import { Spinner } from 'components'
import {useAdminModelListLazyQuery, useAdminModelsInStockListLazyQuery, useAdminNewModelsListLazyQuery} from 'gql'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as L from 'layouts'
import { useDebounce } from '../../hooks'
import { AdminBrandModelList } from './model-details/model-list'
import { ActionBar } from './model-details/action-bar'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'

const PAGE_SIZE = 30

export enum AdminModelsVariant {
  ALL = 'ALL',
  IN_STOCK = 'IN_STOCK',
  NEW = 'NEW',
}

interface AdminModelsProps {
  brandId: string
  variant?: AdminModelsVariant
}

export const AdminModels = (props: AdminModelsProps) => {
  const { variant = AdminModelsVariant.ALL } = props

  const [searchQuery, setSearchQuery] = useState('')
  const queryText = useDebounce(searchQuery, 250)
  const { modelList, loading, pageInfo, prevPage, nextPage } = useAdminModelList(variant, props.brandId, queryText)
  const [selectedModels, setSelectedModels] = useState<string[]>([])

  const { openDetailView } = useDetailView()

  const handleCloseDetailView = () => setSelectedModels([]);

  useEffect(() => {
    if (selectedModels.length === 1) {
      openDetailView(DetailViewEnum.ModelDetail, { brandId: props.brandId, modelId: selectedModels[0], onClose: handleCloseDetailView })
    } else if (selectedModels.length > 1) {
      openDetailView(DetailViewEnum.ModelBulkEdit, { brandId: props.brandId, modelIds: selectedModels, onClose: handleCloseDetailView })
    }
  }, [openDetailView, props.brandId, selectedModels])

  const handleFetchPreviousPage = () => {
    setSelectedModels([])
    prevPage()
  }

  const handleFetchNextPage = () => {
    setSelectedModels([])
    nextPage()
  }

  return (
    <L.Vertical spacing={3}>
      {loading && <Spinner size={'1rem'} />}
      <AdminBrandModelList
        actionBar={
          <ActionBar
            searchQuery={searchQuery}
            onSearchQueryChange={(query) => setSearchQuery(query)}
            menuDisabled={selectedModels?.length < 2}
          />
        }
        key={pageInfo.pageIndex}
        data={modelList}
        selectedModelIds={selectedModels}
        onSelectionChanged={setSelectedModels}
        paginationNavigation={{
          hasNextPage: pageInfo?.hasNextPage,
          hasPreviousPage: pageInfo?.hasPreviousPage,
          fetchNextPage: handleFetchNextPage,
          fetchPreviousPage: handleFetchPreviousPage,
        }}
      />
    </L.Vertical>
  )
}

const useAdminModelList = (variant: AdminModelsVariant, brandId: string, queryText: string) => {
  const [pageIndex, setPageIndex] = useState(0)
  const offset = pageIndex * PAGE_SIZE

  const [queryModels, ModelsResponse] = useAdminModelListLazyQuery()
  const [queryModelsInStock, modelsInStockResponse] = useAdminModelsInStockListLazyQuery()
  const [queryNewModels, newModelsResponse] = useAdminNewModelsListLazyQuery()

  useEffect(() => {
    const baseOptions: any = {
      variables: {
        limit: PAGE_SIZE,
        offset,
        brandId: brandId,
        searchText: `%${queryText || ''}%`,
      },
    }

    switch (variant) {
      case AdminModelsVariant.ALL:
        void queryModels(baseOptions)
        break
      case AdminModelsVariant.IN_STOCK:
        void queryModelsInStock(baseOptions)
        break
      case AdminModelsVariant.NEW:
        void queryNewModels(baseOptions)
        break
    }
  }, [brandId, offset, queryModels, queryModelsInStock, queryNewModels, queryText, variant])

  const [modelList, totalCount] = useMemo(() => {
    let models
    let totalCount
    switch (variant) {
      case AdminModelsVariant.ALL:
        models = ModelsResponse.data?.models ?? []
        totalCount = ModelsResponse.data?.models_aggregate?.aggregate?.count ?? 0
        break
      case AdminModelsVariant.NEW:
        models = newModelsResponse.data?.models ?? []
        totalCount = newModelsResponse.data?.models_aggregate?.aggregate?.count ?? 0
        break
      case AdminModelsVariant.IN_STOCK:
        models = modelsInStockResponse.data?.models ?? []
        totalCount = modelsInStockResponse.data?.models_aggregate?.aggregate?.count ?? 0
        break
    }
    return [
      models.map((m) => ({
        id: m.id,
        brandId: brandId,
        brandModelCode: m.model_code,
        salesModelCode: m.sales_model_code,
        description: m.description,
        modelGroupName: m.modelGroup?.name,
      })),
      totalCount,
    ]
  }, [
    newModelsResponse.data?.models,
    newModelsResponse.data?.models_aggregate?.aggregate?.count,
    ModelsResponse.data?.models,
    ModelsResponse.data?.models_aggregate?.aggregate?.count,
    brandId,
    modelsInStockResponse.data?.models,
    modelsInStockResponse.data?.models_aggregate?.aggregate?.count,
    variant,
  ])

  const loading = ModelsResponse.loading || modelsInStockResponse.loading

  const pageInfo = useMemo(() => {
    return {
      pageIndex,
      hasPreviousPage: pageIndex > 0,
      hasNextPage: totalCount > offset + PAGE_SIZE,
    }
  }, [offset, pageIndex, totalCount])

  const maxPageIndex = Math.ceil(totalCount / PAGE_SIZE) - 1
  const nextPage = useCallback(() => {
    setPageIndex((prev) => Math.min(maxPageIndex, prev + 1))
  }, [maxPageIndex])
  const prevPage = useCallback(() => {
    setPageIndex((prev) => Math.max(0, prev - 1))
  }, [])

  return { modelList, pageInfo, loading, nextPage, prevPage }
}
