import React, { MouseEventHandler, useMemo, useState } from 'react'
import {
  EmbeddedPagesListSubscriptionResult,
  useDeleteEmbeddedPageMutation,
  useEmbeddedPagesListSubscription,
} from '../../../gql'
import { SiteMetadata } from 'components/SiteMetadata'
import * as L from 'layouts'
import { Yalc2, Yalc2RowDefinition } from '../../../components/Yalc2'
import { Yalc2Cell } from '../../../components/Yalc2/cells'
import { Menu } from 'components/Menu'
import { AdminEmbeddedPageDetail } from '../../../containers/AdminEmbeddedPageDetail'
import { countBy } from 'lodash'
import clsx from 'clsx'
import { Icon, IconButton } from '../../../components'

type EmbeddedPagesList = EmbeddedPagesListSubscriptionResult['data']['embedded_pages']
export type EmbeddedPagesListItem = ArrayElement<EmbeddedPagesList>
export const AdminEmbeddedPagesPage = () => {
  const subscription = useEmbeddedPagesListSubscription()
  const pages = useMemo(() => subscription.data?.embedded_pages ?? [], [subscription.data?.embedded_pages])

  const groups = useMemo(() => {
    return Object.entries(countBy(pages, 'name')).map(([title, count]) => ({
      title,
      count,
    }))
  }, [pages])

  const [showAddNewEmbeddedPage, setShowAddNewEmbeddedPage] = useState<boolean>(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const rowDefinition = useRowDefinition()

  return (
    <>
      <SiteMetadata title={'Externe Seiten'} />
      <L.Vertical>
        <Yalc2<EmbeddedPagesListItem>
          options={<Menu.Item onClick={() => setShowAddNewEmbeddedPage(true)}>Neue Externe Seite erstellen</Menu.Item>}
          data={pages}
          groups={groups}
          rowDefinition={rowDefinition}
          selectedIds={selectedIds}
          classes={{ item: 'group' }}
          onSelectionChanged={setSelectedIds}
        />
        {showAddNewEmbeddedPage && (
          <AdminEmbeddedPageDetail onClose={() => setShowAddNewEmbeddedPage(false)} title={'Neue Externe Seite'} />
        )}
        {selectedIds.length === 1 && (
          <AdminEmbeddedPageDetail
            onClose={() => setSelectedIds([])}
            title={'Externe Seite bearbeiten'}
            embeddedPage={pages.find((page) => page.id === selectedIds[0])}
          />
        )}
      </L.Vertical>
    </>
  )
}

const useRowDefinition = (): Yalc2RowDefinition<EmbeddedPagesListItem> => {
  const [deleteEmbeddedPage] = useDeleteEmbeddedPageMutation()
  return useMemo(
    () => ({
      columns: [
        {
          title: 'Brand',
          width: 300,
          cell: function BrandCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.brand?.name}</Yalc2Cell.SimpleCell>
          },
        },
        {
          title: 'Url',
          width: 300,
          cell: function UrlCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.url}</Yalc2Cell.MaxWidthCell>
          },
        },
        {
          title: '',
          width: 50,
          cell: function QuickActionsCell(props) {
            const handleDelete: MouseEventHandler<HTMLButtonElement> = async (evt) => {
              evt.stopPropagation()
              await deleteEmbeddedPage({ variables: { id: props.item.id } })
            }
            return (
              <Yalc2Cell.SimpleCell className={clsx('hidden items-end group-hover:flex')}>
                <IconButton aria-label={'delete-embedded-page'} onClick={handleDelete}>
                  <Icon name={'delete'} />
                </IconButton>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
      ],
    }),
    [deleteEmbeddedPage]
  )
}
