import { Select } from 'components'
import { User, useUserSelectDataQuery } from 'gql'
import React, { CSSProperties } from 'react'

interface UserSelectProps {
  value: string
  onSelect: (user: User) => void
  className?: string
  style?: CSSProperties
  label?: string
}

export const UserSelect = (props: UserSelectProps) => {
  const query = useUserSelectDataQuery({ fetchPolicy: 'network-only' })

  const users = query.data?.users ?? []
  const options = users.map((user) => ({ id: user.id, name: user.full_name ?? user.email }))
  const handleChange = async (evt) => {
    let id = evt.target.value
    props.onSelect(users.find((user) => user.id === id) as User)
  }
  options.sort((a, b) => (a.name < b.name ? -1 : 1))
  const loading = query.loading
  return (
    <Select
      loading={loading}
      renderSelectValueItem={(val) => options.find((u) => u.id === val)?.name}
      value={props.value}
      onChange={handleChange}
      className={props.className}
      style={props.style}
      label={props.label}
    >
      {options.map((o) => (
        <Select.Option key={o.id} value={o.id}>
          {o.name}
        </Select.Option>
      ))}
    </Select>
  )
}
