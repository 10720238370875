import React from 'react'
import MuiSwitch from '@mui/material/Switch'
import MuiFormControlLabel from '@mui/material/FormControlLabel'

interface SwitchProps {
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  label?: string
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top'
  disabled?: boolean
  className?: string
}

export const Switch: React.FC<SwitchProps> = (props) => {
  return (
    <MuiFormControlLabel
      control={<MuiSwitch checked={props.checked} onChange={props.onChange} name={props.name} />}
      labelPlacement={props.labelPlacement}
      label={props.label}
      className={props.className}
      disabled={props.disabled}
    />
  )
}
