import React, { useEffect, useMemo, useState } from 'react'
import { Yalc2, Yalc2RowDefinition } from '../../components/Yalc2'
import { Yalc2Cell } from '../../components/Yalc2/cells'
import { Chip, Menu } from '../../components'
import { UserRoleChip } from './UserRoleChip'
import { InBuyChannelChip } from '../InBuyChannel/Chip'
import { UserTeamsSubscriptionResult, useUserTeamsSubscription } from '../../gql'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'

interface TeamsListProps {
  userId: string
}

export type UserTeams = UserTeamsSubscriptionResult['data']['team_assignments']

export const TeamsList = (props: TeamsListProps) => {
  const rowDef = useRowDefinition()
  const subscription = useUserTeamsSubscription({ variables: { userId: props.userId } })
  const teams = useMemo(() => subscription.data?.team_assignments ?? [], [subscription.data?.team_assignments])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const teamToEdit = useMemo(() => (selectedIds?.length === 1 ? selectedIds[0] : null), [selectedIds])
  const { openNestedDetailView } = useDetailView()
  useEffect(() => {
    if (teamToEdit) {
      openNestedDetailView(DetailViewEnum.TeamDetail, {
        onClose: () => setSelectedIds([]),
        teamId: teamToEdit,
        userId: props.userId,
        title: 'Team bearbeiten',
      })
    }
  }, [openNestedDetailView, props.userId, teamToEdit])
  return (
    <>
      <Yalc2<ArrayElement<UserTeams>>
        onSelectionChanged={setSelectedIds}
        selectedIds={selectedIds}
        data={teams}
        rowDefinition={rowDef}
        options={
          <Menu.Item
            onClick={() =>
              openNestedDetailView(DetailViewEnum.TeamDetail, {
                title: 'Neues Team',
                userId: props.userId,
              })
            }
          >
            Neues Team
          </Menu.Item>
        }
      />
    </>
  )
}

const useRowDefinition: () => Yalc2RowDefinition<ArrayElement<UserTeams>> = () => {
  return useMemo(
    () => ({
      columns: [
        {
          width: 100,
          title: 'Bereich',
          cell: function DepartmentCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.department_id ? <Chip>{props.item.department?.name}</Chip> : '-'}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          width: 70,
          title: 'Rolle',
          cell: function RoleCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <UserRoleChip isPrimary={props.item.is_primary} role={props.item.role} />
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          width: 100,
          title: 'Standort',
          cell: function LocationCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.location_id ? <Chip>{props.item.location?.short_name}</Chip> : '-'}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          width: 100,
          title: 'Marke',
          cell: function BrandCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.brand?.name ?? '-'}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 200,
          title: 'Zukaufs Kanal',
          cell: function InBuyChannelCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <InBuyChannelChip channel={props.item.in_buy_channel} />
              </Yalc2Cell.SimpleCell>
            )
          },
        },
      ],
    }),
    []
  )
}
