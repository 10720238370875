import { ClickOutsideListener } from '../../components/ClickOutsideListener'
import React, { useContext, useEffect, useMemo } from 'react'
import { DetailWindow } from 'components/DetailWindow'
import { useAdminVehicleImportJobQuery } from 'gql'
import * as L from 'layouts'
import { DataBlock } from '../../components/DataBlock'
import { DataValue } from '../../components/DataValue'
import { useFormatter } from '../../hooks'
import { Spinner } from '../../components'
import { useTranslation } from 'react-i18next'
import { Link } from '@mui/material'
import { AssignmentsList } from '../ExcelImport/assignments/template-detail'
import { uniqBy } from 'lodash'
import { ExcelImport, ExcelImportContext } from '../ExcelImport'
import { ImportJobStatusChip } from '../ImportJobStatusChip'

interface AdminVehicleImportJobDetailProps {
  id: string
  onClose: () => void
}

export const AdminVehicleImportJobDetail = React.forwardRef<HTMLDivElement, AdminVehicleImportJobDetailProps>(
  (props, ref) => {
    const { setVariant, setImportJob } = useContext(ExcelImportContext)
    const i18nPrefix = 'DealerVehicle'
    useEffect(() => setVariant('vehicle'), [setVariant])

    const importJobQuery = useAdminVehicleImportJobQuery({
      variables: {
        id: props.id,
      },
    })
    useEffect(() => setImportJob(importJobQuery.data?.importJob?.import_job), [
      importJobQuery.data?.importJob,
      setImportJob,
    ])

    const { formatDateTimeString } = useFormatter()
    const { t } = useTranslation()

    const importJob = useMemo(() => importJobQuery.data?.importJob, [importJobQuery.data?.importJob])
    const assignments = useMemo(() => {
      const localAssignments = importJob?.import_job?.import_template?.assignments ?? []
      const parentAssignments = importJob?.import_job?.import_template?.parent_template?.assignments ?? []
      const filteredParentAssignments = parentAssignments.map(
        (parentAssignment) =>
          localAssignments.find((assignment) => assignment.key === parentAssignment.key) ?? parentAssignment
      )
      const mergedAssignments = localAssignments.concat(filteredParentAssignments)
      return uniqBy(mergedAssignments, 'key')
    }, [
      importJob?.import_job?.import_template?.assignments,
      importJob?.import_job?.import_template?.parent_template?.assignments,
    ])

    return (
      <ExcelImport>
        <ClickOutsideListener onClickOutside={props.onClose}>
          <DetailWindow
            ref={ref}
            width={976}
            onClose={props.onClose}
            title={'Fahrzeug Excel Import Details'}
          >
            {importJob ? (
              <L.Vertical spacing={4} className={'w-full'}>
                <L.Horizontal spacing={4} className={'relative p-4'} style={{ alignItems: 'flex-start' }}>
                  <L.Vertical className={'flex-1'}>
                    <DataBlock title={'Allgemein'}>
                      <DataValue label={'Erstellt am'}>
                        {formatDateTimeString(importJob.import_job?.created_at)}
                      </DataValue>
                      <DataValue label={'Status'}>
                        <ImportJobStatusChip state={importJob.import_job?.status} />
                      </DataValue>
                    </DataBlock>
                    <DataBlock title={'Datei'}>
                      <DataValue label={'Name'}>{importJob.import_job?.upload_file_name}</DataValue>
                      <DataValue label={'Url'}>
                        <Link className={'line-clamp-3'} target="_blank" href={importJob.url}>
                          {importJob.url}
                        </Link>
                      </DataValue>
                      <DataValue label={'Spalten Namen'}>
                        {importJob.import_job?.upload_file_excel_definition
                          ?.filter((col) => col !== 'EMPTY')
                          ?.join(', ')}
                      </DataValue>
                    </DataBlock>
                  </L.Vertical>
                  <L.Vertical className={'flex-1'}>
                    <DataBlock title={'Abbildung'}>
                      <DataValue label={'Zuordnungs Feld'}>
                        {t(`${i18nPrefix}.${importJob.import_job?.matching_field}`)}
                      </DataValue>
                      {assignments.length > 0 && (
                        <AssignmentsList i18nPrefix={i18nPrefix} className={'mt-4'} data={assignments} />
                      )}
                    </DataBlock>
                  </L.Vertical>
                </L.Horizontal>
                <ExcelImport.Logs
                  entityNamePlural={'Fahrzeuge'}
                  mappingKeys={[
                    { key: 'vin', value: 'FIN' },
                    { key: 'job_number', value: 'Bestellnummer' },
                  ]}
                />
              </L.Vertical>
            ) : (
              <Spinner />
            )}
          </DetailWindow>
        </ClickOutsideListener>
      </ExcelImport>
    )
  }
)
