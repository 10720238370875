import React, { useCallback, useMemo } from 'react'
import { Dealer_Vehicle_State_Enum, useUserSelectDataQuery, Vehicle_Bool_Exp } from '../../../../gql'
import { DealerVehicleStateChip } from '../../../DealerVehicleState/chip'
import { useTranslation } from 'react-i18next'
import { DateOptions } from '../../Chips/DateChip'
import { HighlightsColorOptions } from '../../ColorOptions'
import { LMChipProps, LMOptionsProps } from '../Filter/Chips'
import { Typo } from '../../../../components'
import { UserOptions } from '../../Chips/UserChip'
import { VehicleIntOptions } from './IntChip'
import { ListManipulation, useListManipilationContext } from '../../unit'
import { getDateChipLabel } from 'containers/ListManipulation/getDateChipLabel'
import { useEzAuth } from '@lib/ezauth'

export const VehicleStateOptions = (props: LMOptionsProps<'Vehicle'> & { expressions?: Vehicle_Bool_Exp[] }) => {
  const { setStandalones, setAlternativeExpressions, deleteExpressions, getAll } = props.operations
  const { setField, setParentExpression, leadingExpression } = useListManipilationContext<'Vehicle'>()

  const expressions = useMemo(() => props.expressions ?? getAll('state', props.highlightIndex), [
    getAll,
    props.expressions,
    props.highlightIndex,
  ])
  const checked = useCallback(
    (states: Dealer_Vehicle_State_Enum[]) =>
      states.every((state) => expressions?.some((exp) => exp?.state?._eq === state)),
    [expressions]
  )
  const checkedNull = useMemo(() => expressions?.some((exp) => exp?.state?._is_null), [expressions])

  const disabled = useCallback(
    (states: Dealer_Vehicle_State_Enum[]) => !expressions?.some((s) => states.some((state) => s?.state?._eq === state)),
    [expressions]
  )
  const handleChange = useCallback(
    (states: Dealer_Vehicle_State_Enum[]) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) deleteExpressions(states.map((_eq) => ({ state: { _eq } })))
      if (checked) {
        if (leadingExpression) {
          setAlternativeExpressions(
            states.map((_eq) => ({ state: { _eq } })),
            leadingExpression
          )
        } else {
          setStandalones(states.map((_eq) => ({ state: { _eq } })))
        }
      }
    },
    [deleteExpressions, leadingExpression, setAlternativeExpressions, setStandalones]
  )
  const handleChangeNull = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) deleteExpressions([{ state: { _is_null: true } }])
      if (checked) setStandalones([{ state: { _is_null: true } }])
    },
    [deleteExpressions, setStandalones]
  )

  return (
    <ListManipulation.UnitOptions label={'Status'}>
      <ListManipulation.CheckableOption
        checked={checked([Dealer_Vehicle_State_Enum.Available])}
        onChange={handleChange([Dealer_Vehicle_State_Enum.Available])}
      >
        <DealerVehicleStateChip state={Dealer_Vehicle_State_Enum.Available} />
      </ListManipulation.CheckableOption>
      <ListManipulation.ItemOption
        onClick={() => {
          setField('days_since_purchase')
          setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Available } })
        }}
        disabled={disabled([Dealer_Vehicle_State_Enum.Available])}
      >
        Standtage
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption
        onClick={() => {
          setField('invoice_date')
          setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Available } })
        }}
        disabled={disabled([Dealer_Vehicle_State_Enum.Available])}
      >
        Rechnungsdatum
      </ListManipulation.ItemOption>
      <hr className={'text-divider'} />
      <ListManipulation.CheckableOption
        checked={checked([Dealer_Vehicle_State_Enum.Reserved])}
        onChange={handleChange([Dealer_Vehicle_State_Enum.Reserved])}
      >
        <DealerVehicleStateChip state={Dealer_Vehicle_State_Enum.Reserved} />
      </ListManipulation.CheckableOption>
      <ListManipulation.ItemOption
        onClick={() => {
          setField('reserved_at')
          setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Reserved } })
        }}
        disabled={disabled([Dealer_Vehicle_State_Enum.Reserved])}
      >
        Reserviert am
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption
        onClick={() => {
          setField('reserved_until')
          setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Reserved } })
        }}
        disabled={disabled([Dealer_Vehicle_State_Enum.Reserved])}
      >
        Reserviert bis
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption
        onClick={() => {
          setField('reserved_by_id')
          setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Reserved } })
        }}
        disabled={disabled([Dealer_Vehicle_State_Enum.Reserved])}
      >
        Reserviert von
      </ListManipulation.ItemOption>
      <hr className={'text-divider'} />
      <ListManipulation.CheckableOption
        checked={checked([Dealer_Vehicle_State_Enum.OnSiteNeed])}
        onChange={handleChange([Dealer_Vehicle_State_Enum.OnSiteNeed])}
      >
        <DealerVehicleStateChip state={Dealer_Vehicle_State_Enum.OnSiteNeed} />
      </ListManipulation.CheckableOption>
      <ListManipulation.CheckableOption
        checked={checked([Dealer_Vehicle_State_Enum.Other])}
        onChange={handleChange([Dealer_Vehicle_State_Enum.Other])}
      >
        <DealerVehicleStateChip state={Dealer_Vehicle_State_Enum.Other} />
      </ListManipulation.CheckableOption>
      <hr className={'text-divider'} />
      <ListManipulation.CheckableOption
        checked={checked([
          Dealer_Vehicle_State_Enum.Sold,
          Dealer_Vehicle_State_Enum.SoldToPartner,
          Dealer_Vehicle_State_Enum.SoldByPartner,
          Dealer_Vehicle_State_Enum.SoldByBroker,
        ])}
        onChange={handleChange([
          Dealer_Vehicle_State_Enum.Sold,
          Dealer_Vehicle_State_Enum.SoldToPartner,
          Dealer_Vehicle_State_Enum.SoldByPartner,
          Dealer_Vehicle_State_Enum.SoldByBroker,
        ])}
      />
      <ListManipulation.CheckableOption
        checked={checked([Dealer_Vehicle_State_Enum.Sold])}
        onChange={handleChange([Dealer_Vehicle_State_Enum.Sold])}
      >
        <DealerVehicleStateChip state={Dealer_Vehicle_State_Enum.Sold} />
      </ListManipulation.CheckableOption>
      <ListManipulation.CheckableOption
        checked={checked([Dealer_Vehicle_State_Enum.SoldToPartner])}
        onChange={handleChange([Dealer_Vehicle_State_Enum.SoldToPartner])}
      >
        <DealerVehicleStateChip state={Dealer_Vehicle_State_Enum.SoldToPartner} />
      </ListManipulation.CheckableOption>
      <ListManipulation.CheckableOption
        checked={checked([Dealer_Vehicle_State_Enum.SoldByPartner])}
        onChange={handleChange([Dealer_Vehicle_State_Enum.SoldByPartner])}
      >
        <DealerVehicleStateChip state={Dealer_Vehicle_State_Enum.SoldByPartner} />
      </ListManipulation.CheckableOption>
      <ListManipulation.CheckableOption
        checked={checked([Dealer_Vehicle_State_Enum.SoldByBroker])}
        onChange={handleChange([Dealer_Vehicle_State_Enum.SoldByBroker])}
      >
        <DealerVehicleStateChip state={Dealer_Vehicle_State_Enum.SoldByBroker} />
      </ListManipulation.CheckableOption>
      <ListManipulation.ItemOption
        onClick={() => {
          setField('sold_at')
          setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Sold } })
        }}
        disabled={disabled([
          Dealer_Vehicle_State_Enum.Sold,
          Dealer_Vehicle_State_Enum.SoldToPartner,
          Dealer_Vehicle_State_Enum.SoldByPartner,
          Dealer_Vehicle_State_Enum.SoldByBroker,
        ])}
      >
        Verkauft am
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption
        onClick={() => {
          setField('sold_by_id')
          setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Sold } })
        }}
        disabled={disabled([
          Dealer_Vehicle_State_Enum.Sold,
          Dealer_Vehicle_State_Enum.SoldToPartner,
          Dealer_Vehicle_State_Enum.SoldByPartner,
          Dealer_Vehicle_State_Enum.SoldByBroker,
        ])}
      >
        Verkauft von
      </ListManipulation.ItemOption>
      <hr className={'text-divider'} />
      <ListManipulation.CheckableOption checked={checkedNull} onChange={handleChangeNull}>
        <Typo variant={'body1'} className={'pr-4'}>
          Kein Status
        </Typo>
      </ListManipulation.CheckableOption>
      {props.type === 'highlight' && (
        <>
          <hr className={'text-divider'} />
          <ListManipulation.ItemOption disabled={!expressions.length} onClick={() => setField('color')}>
            Farbe auswählen
          </ListManipulation.ItemOption>
        </>
      )}
    </ListManipulation.UnitOptions>
  )
}

export const VehicleStatusChip = (props: LMChipProps<'Vehicle'> & { expressions?: Vehicle_Bool_Exp[] }) => {
  const { getAll, getChildren, deleteExpressions, deleteAll } = props.operations
  const { t } = useTranslation()
  const { setField, field, setParentExpression } = useListManipilationContext<'Vehicle'>()

  const [state] = useEzAuth()
  const me = useMemo(() => state.user, [state.user])

  const usersQuery = useUserSelectDataQuery()
  const users = useMemo(() => usersQuery.data?.users?.filter((user) => user.id !== me.id) ?? [], [
    me.id,
    usersQuery.data?.users,
  ])
  const expressions = useMemo(
    () =>
      props.expressions ??
      getAll('state', props.highlightIndex).filter((exp) => {
        if (props.type === 'sold') return exp.state._eq?.startsWith('SOLD')
        else if (props.type === 'reserved') return exp.state._eq?.startsWith('RESERVED')
        else if (props.type === 'available') return exp.state._eq?.startsWith('AVAILABLE')
        else if (props.type === 'rest')
          return (
            !exp.state._eq?.startsWith('SOLD') &&
            !exp.state._eq?.startsWith('RESERVED') &&
            !exp.state._eq?.startsWith('AVAILABLE')
          )
        return true
      }),
    [getAll, props.expressions, props.highlightIndex, props.type]
  )

  const soldAtExpressions = useMemo(
    () => getChildren('sold_at', { state: { _eq: Dealer_Vehicle_State_Enum.Sold } }, props.highlightIndex),
    [getChildren, props.highlightIndex]
  )

  const invoiceDateExpressions = useMemo(
    () => getChildren('invoice_date', { state: { _eq: Dealer_Vehicle_State_Enum.Available } }, props.highlightIndex),
    [getChildren, props.highlightIndex]
  )
  const daysSincePurchaseExpressions = useMemo(
    () =>
      getChildren('days_since_purchase', { state: { _eq: Dealer_Vehicle_State_Enum.Available } }, props.highlightIndex),
    [getChildren, props.highlightIndex]
  )
  const soldByExpressions = useMemo(
    () => getChildren('sold_by_id', { state: { _eq: Dealer_Vehicle_State_Enum.Sold } }, props.highlightIndex),
    [getChildren, props.highlightIndex]
  )
  const soldByLabel = useMemo(
    () =>
      soldByExpressions.length === 1
        ? `Verkäufer: ${
            soldByExpressions[0]?.sold_by_id._is_null
              ? 'Kein Verkäufer'
              : soldByExpressions[0]?.sold_by_id?._eq === me.id
              ? 'Ich'
              : users?.find((u) => u.id === soldByExpressions[0]?.sold_by_id?._eq)?.full_name ??
                users?.find((u) => u.id === soldByExpressions[0]?.sold_by_id?._eq)?.email
          }`
        : `${soldByExpressions.length} Verkäufer ausgewählt`,
    [me.id, soldByExpressions, users]
  )
  const daysSincePurchaseLabel = useMemo(() => {
    if (daysSincePurchaseExpressions.length === 1) {
      const operator = Object.keys(daysSincePurchaseExpressions[0].days_since_purchase)[0] ?? ''
      return `Standtage: ${operator === '_lt' ? '<' : operator === '_gt' ? '>' : ''} ${
        daysSincePurchaseExpressions[0].days_since_purchase[operator]
      } Tage`
    } else {
      return ''
    }
  }, [daysSincePurchaseExpressions])
  const reservedAtExpressions = useMemo(
    () => getChildren('reserved_at', { state: { _eq: Dealer_Vehicle_State_Enum.Reserved } }),
    [getChildren]
  )
  const reservedUntilExpressions = useMemo(
    () => getChildren('reserved_until', { state: { _eq: Dealer_Vehicle_State_Enum.Reserved } }),
    [getChildren]
  )

  const reservedByExpressions = useMemo(
    () => getChildren('reserved_by_id', { state: { _eq: Dealer_Vehicle_State_Enum.Reserved } }),
    [getChildren]
  )
  const reservedByLabel = useMemo(
    () =>
      reservedByExpressions.length === 1
        ? `Von: ${
            reservedByExpressions[0]?.reserved_by_id._is_null
              ? 'Keinem'
              : reservedByExpressions[0]?.reserved_by_id?._eq === me.id
              ? 'Mir'
              : users?.find((u) => u.id === reservedByExpressions[0]?.reserved_by_id?._eq)?.full_name ??
                users?.find((u) => u.id === reservedByExpressions[0]?.reserved_by_id?._eq)?.email
          }`
        : `${reservedByExpressions.length} Reserviert von ausgewählt`,
    [me.id, reservedByExpressions, users]
  )

  const label = useMemo(
    () =>
      expressions.length === 1
        ? `Status: ${
            expressions[0].state._is_null ? 'Kein Status' : t(`DealerVehicleState.${expressions[0].state._eq}`)
          }`
        : `${expressions.length}${props.type === 'sold' ? ' "Verkauft"' : ''} Status ausgewählt`,
    [expressions, props.type, t]
  )

  const handleDelete = useCallback(() => {
    if (props.expressions) {
      deleteExpressions(props.expressions)
      return
    }
    if (props.type === 'sold') {
      deleteExpressions([
        { state: { _eq: Dealer_Vehicle_State_Enum.Sold } },
        { state: { _eq: Dealer_Vehicle_State_Enum.SoldByBroker } },
        { state: { _eq: Dealer_Vehicle_State_Enum.SoldByPartner } },
        { state: { _eq: Dealer_Vehicle_State_Enum.SoldToPartner } },
      ])
    } else if (props.type === 'rest') {
      deleteExpressions([
        { state: { _eq: Dealer_Vehicle_State_Enum.Other } },
        { state: { _eq: Dealer_Vehicle_State_Enum.OnSiteNeed } },
        { state: { _is_null: true } },
      ])
    } else if (props.type === 'reserved') {
      deleteExpressions([{ state: { _eq: Dealer_Vehicle_State_Enum.Reserved } }])
    } else if (props.type === 'available') {
      deleteExpressions([{ state: { _eq: Dealer_Vehicle_State_Enum.Available } }])
    } else {
      deleteAll('state', props.highlightIndex)
    }
  }, [deleteAll, deleteExpressions, props.highlightIndex, props.expressions, props.type])
  if (!Boolean(expressions.length)) return null
  return (
    <ListManipulation.Group
      variant={
        props.type === 'sold' || props.type === 'reserved' || props.type === 'available' || props.color
          ? 'filled'
          : 'outlined'
      }
      color={
        props.type === 'sold'
          ? 'danger'
          : props.type === 'reserved'
          ? 'warning'
          : props.type === 'available'
          ? 'success'
          : props.color
      }
      onDelete={handleDelete}
    >
      <ListManipulation.GroupUnit
        onClick={() => {
          props.onClick && props.onClick()
          setField('state')
        }}
        label={label}
      >
        {field === 'state' && (
          <VehicleStateOptions
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
            highlightIndex={props.highlightIndex}
          />
        )}
        {field === 'sold_at' && (
          <DateOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        )}
        {field === 'color' && <HighlightsColorOptions />}
        {field === 'sold_by_id' && (
          <UserOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        )}
        {field === 'reserved_at' && (
          <DateOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        )}
        {field === 'reserved_until' && (
          <DateOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        )}
        {field === 'reserved_by_id' && (
          <UserOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        )}
        {field === 'invoice_date' && (
          <DateOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        )}
        {field === 'days_since_purchase' && (
          <VehicleIntOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        )}
      </ListManipulation.GroupUnit>
      {(props.type === 'available' || props.type === 'highlight') && Boolean(invoiceDateExpressions.length) && (
        <ListManipulation.GroupUnit
          label={`Rechnungsdatum: ${getDateChipLabel(invoiceDateExpressions)}`}
          onClick={() => {
            props.onClick && props.onClick()
            setField('invoice_date')
            setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Available } })
          }}
        >
          <DateOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        </ListManipulation.GroupUnit>
      )}
      {(props.type === 'available' || props.type === 'highlight') && Boolean(daysSincePurchaseExpressions.length) && (
        <ListManipulation.GroupUnit
          label={daysSincePurchaseLabel}
          onClick={() => {
            props.onClick && props.onClick()
            setField('days_since_purchase')
            setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Available } })
          }}
        >
          <VehicleIntOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        </ListManipulation.GroupUnit>
      )}
      {(props.type === 'sold' || props.type === 'highlight') && Boolean(soldAtExpressions.length) && (
        <ListManipulation.GroupUnit
          label={getDateChipLabel(soldAtExpressions)}
          onClick={() => {
            props.onClick && props.onClick()
            setField('sold_at')
            setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Sold } })
          }}
        >
          {field === 'sold_at' && (
            <DateOptions
              highlightIndex={props.highlightIndex}
              type={props.type === 'highlight' ? props.type : 'filter'}
              operations={props.operations}
            />
          )}
          {field === 'color' && <HighlightsColorOptions />}
        </ListManipulation.GroupUnit>
      )}
      {(props.type === 'sold' || props.type === 'highlight') && Boolean(soldByExpressions.length) && (
        <ListManipulation.GroupUnit
          label={soldByLabel}
          onClick={() => {
            props.onClick && props.onClick()
            setField('sold_by_id')
            setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Sold } })
          }}
        >
          <UserOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        </ListManipulation.GroupUnit>
      )}
      {(props.type === 'reserved' || props.type === 'highlight') && Boolean(reservedByExpressions.length) && (
        <ListManipulation.GroupUnit
          label={reservedByLabel}
          onClick={() => {
            props.onClick && props.onClick()
            setField('reserved_by_id')
            setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Reserved } })
          }}
        >
          <UserOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        </ListManipulation.GroupUnit>
      )}
      {(props.type === 'reserved' || props.type === 'highlight') && Boolean(reservedAtExpressions.length) && (
        <ListManipulation.GroupUnit
          label={`Am: ${getDateChipLabel(reservedAtExpressions)}`}
          onClick={() => {
            props.onClick && props.onClick()
            setField('reserved_at')
            setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Reserved } })
          }}
        >
          <DateOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        </ListManipulation.GroupUnit>
      )}
      {(props.type === 'reserved' || props.type === 'highlight') && Boolean(reservedUntilExpressions.length) && (
        <ListManipulation.GroupUnit
          label={`Bis: ${getDateChipLabel(reservedUntilExpressions)}`}
          onClick={() => {
            props.onClick && props.onClick()
            setField('reserved_until')
            setParentExpression({ state: { _eq: Dealer_Vehicle_State_Enum.Reserved } })
          }}
        >
          <DateOptions
            highlightIndex={props.highlightIndex}
            type={props.type === 'highlight' ? props.type : 'filter'}
            operations={props.operations}
          />
        </ListManipulation.GroupUnit>
      )}
    </ListManipulation.Group>
  )
}
