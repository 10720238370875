import React, { CSSProperties } from 'react'

import MuiAppBar from '@mui/material/AppBar'

export interface AppBarProps {
  position?: 'fixed' | 'static' | 'absolute' | 'relative' | 'sticky'
  className?: string
  style?: CSSProperties
}

export const AppBar: React.FC<AppBarProps> = (props) => {
  return (
    <MuiAppBar {...props} position={props.position ?? 'relative'} />
  )
}