import React, { useEffect, useMemo } from 'react'
import { DetailWindow } from '../../components/DetailWindow'
import { Tabs } from '../../components'
import { GeneralData } from './01_GeneralData'
import { useSalesReportDetailLazyQuery } from '../../gql'
import { SalesReportContext } from './SalesReportContext'

interface SalesReportDetailProps {
  onClose: () => void
  id?: string
}

export const SalesReportEditView = React.forwardRef<HTMLDivElement, SalesReportDetailProps>(
  (props: SalesReportDetailProps, ref) => {
    const [query, queryResponse] = useSalesReportDetailLazyQuery()
    useEffect(() => {
      if (!props.id) {
        return
      }
      query({ variables: { id: props.id } })
    }, [props.id, query])
    const { data, loading } = useMemo(
      () =>
        props.id
          ? { data: queryResponse.data?.sales_reports_by_pk, loading: queryResponse.loading }
          : { data: null, loading: false },
      [props.id, queryResponse.data?.sales_reports_by_pk, queryResponse.loading]
    )

    return (
      <SalesReportContext.Provider value={{ data, loading }}>
        <DetailWindow
          ref={ref}
          width={976}
          onClose={props.onClose}
          title={props.id ? 'Verkaufsbericht' : 'Neuer Verkaufsbericht'}
          variant={'persistent'}
        >
          <Tabs>
            <Tabs.TabList>
              <Tabs.Tab label={'Grunddaten'} />
              <Tabs.Tab label={'Risikomanagement'} disabled={!props.id} />
              <Tabs.Tab label={'Zubehör'} disabled={!props.id} />
              <Tabs.Tab label={'Provisionen'} disabled />
            </Tabs.TabList>
            <Tabs.TabPanes>
              {withinTabPane(<GeneralData onClose={props.onClose} />)}
              {withinTabPane()}
              {withinTabPane()}
              {withinTabPane()}
            </Tabs.TabPanes>
          </Tabs>
        </DetailWindow>
      </SalesReportContext.Provider>
    )
  }
)

const withinTabPane = (node?: React.ReactNode): JSX.Element => {
  return <Tabs.TabPane>{node}</Tabs.TabPane>
}
