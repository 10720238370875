import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Dealer_Vehicle_State_Enum,
  Dealer_Vehicle_Type_Enum,
  Import_Job_Status_Enum,
  Import_Job_Vehicle_Executables,
  Order_State_Enum,
  Registration_State_Enum,
  useImportJobPreviewItemsLazyQuery,
} from 'gql'
import { ExcelImportContext } from 'containers/ExcelImport'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { useFormatter } from '../../hooks'
import { Yalc2, Yalc2RowDefinition } from '../../components/Yalc2'

export interface VehicleImportPreviewListItem {
  rowNumber: number
  id: string
  vin: string
  job_number: string
  type: Dealer_Vehicle_Type_Enum
  state: Dealer_Vehicle_State_Enum
  order_state: Order_State_Enum
  // sold_by_id: string
  sold_at: string
  customer: string
  min_holding_period: number
  key_kabinet_number: string
  registration_owner: string
  registration_state: Registration_State_Enum
  delivery_date_str: string
  specs: string
  spec_level: string
}

const PREVIEW_LIST_PAGE_SIZE = 50

export const DVImportPreviewList: React.FC = () => {
  const rowDef = useRowDefinition()
  const { importJob } = useContext(ExcelImportContext)
  const [previewItemsQuery, previewItemsQueryResult] = useImportJobPreviewItemsLazyQuery()

  const [offset, setOffset] = useState(0)

  useEffect(() => {
    if (importJob.status !== Import_Job_Status_Enum.PreviewDone) return
    previewItemsQuery({ variables: { id: importJob.id, vehicle_variant: true, offset, limit: PREVIEW_LIST_PAGE_SIZE } })
  }, [importJob.id, importJob.status, offset, previewItemsQuery])

  const totalCount = useMemo(
    () => previewItemsQueryResult.data?.import_jobs_by_pk?.preview_items_aggregate?.aggregate?.count ?? 0,
    [previewItemsQueryResult.data?.import_jobs_by_pk?.preview_items_aggregate?.aggregate?.count]
  )

  const data = previewItemsQueryResult?.data?.import_jobs_by_pk?.preview_items ?? []
  return (
    <div>
      <Yalc2<Partial<Import_Job_Vehicle_Executables> & { id: string }>
        data={data}
        rowDefinition={rowDef}
        title={'Vorschau'}
        paginationNavigation={{
          fetchNextPage: () => setOffset((prev) => (prev += PREVIEW_LIST_PAGE_SIZE)),
          fetchPreviousPage: () =>
            setOffset((prev) => (prev - PREVIEW_LIST_PAGE_SIZE >= 0 ? (prev -= PREVIEW_LIST_PAGE_SIZE) : 0)),
          content: {
            from: offset,
            to: offset + data.length,
            total: totalCount,
          },
          hasPreviousPage: offset > 0,
          hasNextPage: totalCount > offset + PREVIEW_LIST_PAGE_SIZE,
        }}
      />
    </div>
  )
}

const useRowDefinition = (): Yalc2RowDefinition<Partial<Import_Job_Vehicle_Executables> & { id: string }> => {
  const { formatDateString } = useFormatter()
  return useMemo(() => {
    return {
      columns: [
        {
          width: 50,
          title: 'Zeile',
          cell: function RowNumber(props) {
            return <Yalc2Cell.SimpleCell>{props.item.row_number}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'FIN',
          cell: function Fin(props) {
            return <Yalc2Cell.SimpleCell>{props.item.vin}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Bestellnummer',
          cell: function LatestJobNumber(props) {
            return <Yalc2Cell.SimpleCell>{props.item.job_number}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Typ',
          cell: function Type(props) {
            return <Yalc2Cell.SimpleCell>{props.item.type}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Status',
          cell: function State(props) {
            return <Yalc2Cell.SimpleCell>{props.item.state}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Transportstatus',
          cell: function OrderState(props) {
            return <Yalc2Cell.SimpleCell>{props.item.order_state}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Verkauft am',
          cell: function SoldAt(props) {
            return <Yalc2Cell.SimpleCell>{formatDateString(props.item.sold_at)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Kunde',
          cell: function Customer(props) {
            return <Yalc2Cell.SimpleCell>{props.item.customer}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Haltedauer',
          cell: function HoldingPeriod(props) {
            return <Yalc2Cell.SimpleCell>{props.item.min_holding_period}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Schlüsselschrank Nr',
          cell: function KeyCabinatNumber(props) {
            return <Yalc2Cell.SimpleCell>{props.item.key_kabinet_number}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'ZL Besitzer',
          cell: function RegistrationOwner(props) {
            return <Yalc2Cell.SimpleCell>{props.item.registration_owner}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Zulassungs Status',
          cell: function RegistrationState(props) {
            return <Yalc2Cell.SimpleCell>{props.item.registration_state}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Lieferdatum',
          cell: function Deliverydate(props) {
            return <Yalc2Cell.SimpleCell>{props.item.delivery_date_str}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Auslieferungsdatum',
          cell: function HandoverPlannedDate(props) {
            return <Yalc2Cell.SimpleCell>{formatDateString(props.item.handover_planned_date)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Erfassungsdatum',
          cell: function HandoverDate(props) {
            return <Yalc2Cell.SimpleCell>{formatDateString(props.item.handover_date)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Auftragsdatum',
          cell: function InBuyContractDate(props) {
            return <Yalc2Cell.SimpleCell>{formatDateString(props.item.in_buy_contract_date)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Ausstattung',
          cell: function SpecsCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.specs}</Yalc2Cell.SimpleCell>
          },
        },
        {
          width: 180,
          title: 'Ausstattungs Level',
          cell: function SpecsLevelCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.spec_level}</Yalc2Cell.SimpleCell>
          },
        },
      ],
    }
  }, [formatDateString])
}
