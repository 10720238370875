import React, { useCallback, useContext } from 'react'
import { Icon, Menu } from '../../../../components'
import { VehicleDefaultOptions } from '../Chips/VehicleDefaultOptions'
import { HighlightsColorOptions } from '../../ColorOptions'
import { VehicleStateOptions } from '../Chips/StateChip'
import { ListConfigContext } from '../../../ListConfig'
import { VehicleHighlightsChips } from './Chips'
import { EnumOptions } from '../../Chips/EnumChip'
import { VehicleLocationOptions } from '../Chips/LocationsChip'
import { DateOptions } from '../../Chips/DateChip'
import { UserOptions } from '../../Chips/UserChip'
import { VehicleIntOptions } from '../Chips/IntChip'
import { useListManipilationContext } from '../../unit'
import { useHighlightOperations } from 'containers/ListManipulation/useFilterOperations'

export const VehiclesHighlights = () => {
  const { setField, field, setHighlightIndex, highlightIndex } = useListManipilationContext<'Vehicle'>()
  const { highlights } = useContext(ListConfigContext)

  const handleAddHighlight = useCallback(() => {
    setField(undefined)
    setHighlightIndex(highlights?.length ?? 0)
  }, [highlights?.length, setField, setHighlightIndex])
  const operations = useHighlightOperations<'Vehicle'>()

  return (
    <div className={'flex gap-x-2 item-center'} style={{ flex: '0 1 100%' }}>
      <VehicleHighlightsChips />
      <Menu>
        <Menu.Button
          onClick={handleAddHighlight}
          className={'whitespace-nowrap'}
          variant={'text'}
          startIcon={<Icon name={'add'} />}
        >
          Markierung Hinzufügen
        </Menu.Button>
        {!field && <VehicleDefaultOptions />}
        {field === 'color' && <HighlightsColorOptions />}
        {field === 'state' && (
          <VehicleStateOptions highlightIndex={highlightIndex} type={'highlight'} operations={operations} />
        )}
        {(field === 'type' ||
          field === 'registration_state' ||
          field === 'order_state' ||
          field === 'customer_type' ||
          field === 'reserved_for_customer_type') && (
          <EnumOptions<'Vehicle'> operations={operations} type={'highlight'} highlightIndex={highlightIndex} />
        )}
        {(field === 'storage_location_id' ||
          field === 'delivery_location_id' ||
          field === 'dms_assigned_location_id' ||
          field === 'seller_location_id') && (
          <VehicleLocationOptions operations={operations} type={'highlight'} highlightIndex={highlightIndex} />
        )}
        {(field === 'sold_at' ||
          field === 'first_registration_date' ||
          field === 'updated_at' ||
          field === 'warranty_start' ||
          field === 'warranty_end' ||
          field === 'reserved_at' ||
          field === 'reserved_until' ||
          field === 'invoice_date' ||
          field === 'handover_date' ||
          field === 'handover_planned_date') && (
          <DateOptions<'Vehicle'> operations={operations} type={'highlight'} highlightIndex={highlightIndex} />
        )}
        {(field === 'sold_by_id' || field === 'reserved_by_id' || field === 'handover_by_id') && (
          <UserOptions<'Vehicle'> operations={operations} type={'highlight'} highlightIndex={highlightIndex} />
        )}
        {field === 'days_since_purchase' && (
          <VehicleIntOptions operations={operations} type={'highlight'} highlightIndex={highlightIndex} />
        )}
      </Menu>
    </div>
  )
}
