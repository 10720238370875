import React from 'react'
import { PageStructure } from './pages'
import { ApolloProvider } from 'services/apollo'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import { ConfigProvider } from 'config'
import { lightTheme } from 'themes'
import { AlertProvider } from 'components'
import { AuthProvider } from 'services/auth'

import 'clientState'
import 'sanitize.css'
import { DetailViewProvider } from './components/DetailWindow/useDetailView'

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

function App() {
  return (
    <ConfigProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={lightTheme}>
          {/* <CssBaseline /> */}
          <AlertProvider>
            <DetailViewProvider>
              <AuthProvider>
                <ApolloProvider>
                  <PageStructure />
                </ApolloProvider>
              </AuthProvider>
            </DetailViewProvider>
          </AlertProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ConfigProvider>
  )
}

export default App
