import { Order_By, Ticket, Ticket_Bool_Exp, useTicketsListSubscription } from '../../../../gql'
import { useContext, useMemo } from 'react'
import { useTicketsGroupBy } from '../../../ListManipulation/Tickets/GroupBy/Options'
import { ListConfigContext, ListConfigContextProps } from '../../index'
import { countBy } from 'lodash'

export const useData = () => {
  const { filter, groupBy, order } = useContext<ListConfigContextProps<Ticket>>(ListConfigContext)
  const where: Ticket_Bool_Exp = useMemo(() => (filter ? JSON.parse(filter) : {}), [filter])

  const { render: renderGroupName, orderBy, iteratee } = useTicketsGroupBy()
  const order_by = useMemo(() => {
    if (groupBy) {
      return [orderBy]
    }
    if (order && order?.col) {
      return [{ [order.col]: order.direction }]
    }
    return [{ id: Order_By.Asc }]
  }, [groupBy, order, orderBy])

  const subscription = useTicketsListSubscription({ variables: { where, order_by } })
  const tickets = useMemo(() => subscription.data?.tickets ?? [], [subscription.data?.tickets])
  const groups = useMemo(
    () =>
      iteratee
        ? Object.entries(countBy(tickets, iteratee)).map(([title, count]) => ({
            title: renderGroupName(title),
            count,
          }))
        : [{ title: 'Aufgaben', count: tickets?.length }],
    [iteratee, renderGroupName, tickets]
  )
  return { tickets, groups }
}
