import React, { useContext, useState, useEffect } from 'react'
import { TableElement, TableElementType } from './types'
import MuiTablePagination from '@mui/material/TablePagination'
import { TableContext } from '.'

export interface TablePaginationProps {
  pageSize?: number[]
}

export const TablePagination: TableElement<TablePaginationProps> = (props) => {
  const { data, setPagination } = useContext(TableContext)
  const [page, setPage] = useState(0)
  const initialPageSize = props.pageSize[0]
  const [rowsPerPage, setRowsPerPage] = useState(initialPageSize)

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value))
    setPage(0)
  }

  const paginationEnabled = data.length > initialPageSize
  useEffect(() => {
    const pagination = paginationEnabled
      ? {
          pageSize: rowsPerPage,
          page: page,
        }
      : undefined
    setPagination(pagination)
  }, [paginationEnabled, rowsPerPage, page, setPagination])

  if (!paginationEnabled) {
    return null
  }

  return (
    <MuiTablePagination
      page={page}
      count={data.length}
      rowsPerPage={rowsPerPage}
      onPageChange={(_e, newPage) => setPage(newPage)}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  )
}

TablePagination.elementType = TableElementType.Pagination

TablePagination.defaultProps = {
  pageSize: [10, 15, 25],
}
