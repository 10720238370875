import { Typo } from 'components/Typo'
import React, { CSSProperties } from 'react'

export type DataBlockProps = {
  title?: string
  style?: CSSProperties
  className?: string
}

export const DataBlock: React.FC<DataBlockProps> = (props) => {
  return (
    <div
      className={props.className}
      style={{
        ...(props.style || {}),
      }}
    >
      {props.title && (
        <Typo variant="h6" className="mb-2">
          {props.title}
        </Typo>
      )}
      {props.children}
    </div>
  )
}
