import React, { useState, CSSProperties } from 'react'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { Chip } from 'components'
import MenuItem from '@mui/material/MenuItem'
import styled from './MultiSelect.module.css'
import * as L from 'layouts'
import OutlinedInput from '@mui/material/OutlinedInput'

interface MultiSelectProps {
  label?: string
  value: string[]
  onChange: (evt: React.ChangeEvent<HTMLInputElement> & { value: string[] }) => void
  name?: string
  renderValueItem?: (value: string) => React.ReactNode
  children?: React.ReactNode
  className?: string
  style?: CSSProperties
  classes?: {
    valueWrapper?: string
  }
  margin?: boolean
}

export const MultiSelect = (props: MultiSelectProps) => {
  const [id] = useState(uuid())

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    const value = typeof target.value === 'string' ? target.value.split(',') : target.value
    props.onChange({ ...event, value })
  }

  return (
    <FormControl className={clsx(styled.formControl, props.className)} style={props.style}>
      {props.label && (
        <InputLabel id={`${id}-label`} shrink>
          {props.label}
        </InputLabel>
      )}
      <Select
        labelId={`${id}-label`}
        id={id}
        multiple
        value={props.value}
        name={props.name}
        onChange={handleChange}
        input={<OutlinedInput id={`${id}-input`} label={props.label} notched />}
        renderValue={(selected) => (
          <L.Horizontal spacing={0.5} className={clsx('flex-wrap', props.classes?.valueWrapper)}>
            {(selected as string[]).map((value) => (
              <Chip key={value}>{props.renderValueItem?.call(null, value) ?? value}</Chip>
            ))}
          </L.Horizontal>
        )}
        size="small"
        margin={props.margin ? 'dense' : 'none'}
        fullWidth
      >
        {props.children}
      </Select>
    </FormControl>
  )
}

interface MultiSelectOptionProps {
  value: string
  children?: React.ReactNode
  className?: string
}

const MultiSelectOption = React.forwardRef((props: MultiSelectOptionProps, ref: any) => {
  // additional props are injected by the <Select> parent, so its important that all props are applied (e.g. with {...props})
  return <MenuItem {...props} className={clsx(styled.option, props.className)} ref={ref} />
})
;(MultiSelectOption as any).muiName = (MenuItem as any).muiName
MultiSelect.Option = MultiSelectOption
