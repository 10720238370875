import { Authenticator } from '../Authenticator'
import { delay } from 'utils'
import { User, UserRole } from '@lib/ezauth'

// import { LocationStore } from 'mocks/locations'

const defaultUserMock: User = {
  id: 'MOCKED_USER_ID',
  email: 'example@domain.com',
  roles: [UserRole.USER],
  // defaultLocationId: LocationStore.locations[Math.floor(Math.random() * LocationStore.locations.length)].id,
  defaultLocationId: 'fa95338c-5679-4e95-bddf-5a7ced114113',
  showPreview: true,
  getIdToken: () => Promise.resolve('MOCKED_TOKEN'),
}

export class MockedAuthenticator implements Authenticator {
  public init() {
    return defaultUserMock
  }

  public signIn(username, _password) {
    if (username === 'invalid') {
      throw new Error('invalid user')
    }
    return delay(() => defaultUserMock, 250)
  }

  public signOut() {}

  forgotPassword(username: string): Promise<void> {
    return Promise.resolve(undefined)
  }
}
