import { useContext } from 'react'
import { ListConfigContext } from '../../index'
import { Ticket_Bool_Exp } from '../../../../gql'

export const useSearch = () => {
  const { setFilter } = useContext(ListConfigContext)

  const set = (queryText: string) => {
    setFilter((prev) => {
      const filter: Ticket_Bool_Exp = prev ? JSON.parse(prev) : {}
      const boolExpArray = filter._and ?? []
      const boolExpArrayWoSearchQuery = boolExpArray.filter(
        (exp) => !exp._or?.some((nestedExp) => nestedExp?.vehicle?.vin)
      )

      const searchQueryExp = queryText
        ? {
            _or: [
              { vehicle: { vin: { _ilike: `%${queryText}%` } } },
              { title: { _ilike: `%${queryText}%` } },
              { vehicle: { job_number: { _ilike: `%${queryText}%` } } },
            ],
          }
        : undefined
      searchQueryExp && boolExpArrayWoSearchQuery.push(searchQueryExp)
      return JSON.stringify({ _and: boolExpArrayWoSearchQuery })
    })
  }
  return { set }
}
