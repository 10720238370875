import React, { CSSProperties } from 'react'
import MuiContainer, { ContainerProps as MuiContainerProps } from '@mui/material/Container'
import clsx from 'clsx'

interface ContainerProps {
  fixed?: boolean
  maxWidth?: MuiContainerProps['maxWidth']
  disableGutters?: boolean
  component?: React.ElementType
  className?: string
  style?: CSSProperties
  children: React.ReactNode
  noPadding?: boolean
}

export const Container: React.FC<ContainerProps> = (props) => {
  return <MuiContainer {...props} className={clsx({ 'p-6': !props.noPadding }, props.className)} />
}
