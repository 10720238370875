import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Button, Card, Select, Spinner, TextField, Typo } from 'components'
import { DateTimePicker } from 'components/DateTimePicker'
import { Yalc2Cell } from 'components/Yalc2/cells'
import { LogLevel, SyncEntity, SyncPhase, SyncType, useCloudwatchLogsLazyQuery } from 'gql'
import { useFormatter } from 'hooks/useFormatter'
import * as L from 'layouts'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { MonitoringFilterContext } from './MonitoringFilter'
import { Yalc2, Yalc2RowDefinition } from '../../components/Yalc2'
import { DetailViewEnum, useDetailView } from '../../components/DetailWindow/useDetailView'

export const AdminMonitoringLogEntriesList = () => {
  const { state, dispatch } = useContext(MonitoringFilterContext)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [selectedLog, setSelectedLog] = useState<{ timestamp: string; logObject: any }>()
  const [limit, setLimit] = useState<number>(25)

  const logEntriesRef = useRef<HTMLDivElement>()

  const [loadLogs, loadLogsResponse] = useCloudwatchLogsLazyQuery()

  const invalidLogsFilter = useMemo(() => state.logsShown || !state.logsDateFrom || !state.logsDateUntil, [
    state.logsDateFrom,
    state.logsDateUntil,
    state.logsShown,
  ])

  const getLogs = useCallback(() => {
    if (!!state.selectedSyncEntity) {
      loadLogs({
        variables: {
          input: {
            start: state.logsDateFrom.toISOString(),
            end: state.logsDateUntil.toISOString(),
            entity: state.selectedSyncEntity?.syncEntity as SyncEntity,
            level: state.logsLevel as LogLevel,
            syncType: state.logsSyncType as SyncType,
            phase: state.logsPhase as SyncPhase,
            limit,
          },
        },
      })
      dispatch({ type: 'fetching-logs' })
    }
  }, [
    dispatch,
    loadLogs,
    state.logsDateFrom,
    state.logsDateUntil,
    state.logsLevel,
    state.logsSyncType,
    state.selectedSyncEntity,
    state.logsPhase,
    limit,
  ])

  const { logs, totalCount } = useMemo(() => {
    const cloudwatchLogs = loadLogsResponse.data?.cloudwatch_logs
    if (!!cloudwatchLogs) dispatch({ type: 'fetched-logs' })
    return {
      logs: cloudwatchLogs?.results?.map((log, idx) => ({ ...log, id: `log-${idx}` })),
      totalCount: cloudwatchLogs?.recordsMatched,
    }
  }, [dispatch, loadLogsResponse.data?.cloudwatch_logs])

  const rowDefinition = useRowDefinition()

  const handleEntrySelect = useCallback(
    (ids: string[]) => {
      setSelectedIds(ids)
      const log = logs?.find((log) => log.id === ids[0])
      const logObject = JSON.parse(log?.message)
      setSelectedLog({ timestamp: log?.timestamp, logObject })
    },
    [logs]
  )

  const {openNestedDetailView, closeDetailView} = useDetailView()

  useEffect(() => {
    if (selectedIds.length === 1 && selectedLog) {
      openNestedDetailView(DetailViewEnum.MonitoringLogDetail, { log: selectedLog, onClose: () => setSelectedIds([]) })
    }
  }, [openNestedDetailView, selectedIds.length, selectedLog])

  const handleClickOutsideSelection = useCallback(
    (evt: React.MouseEvent<Element>) => {
      const clickedInside = (ref: React.MutableRefObject<Element>, evt: React.MouseEvent) => {
        return ref.current?.contains(evt.target as any)
      }
      if (!clickedInside(logEntriesRef, evt) && document.activeElement.tagName === 'BODY') {
        setSelectedIds([])
        closeDetailView(DetailViewEnum.MonitoringLogDetail)
      }
    },
    [closeDetailView]
  )
  if (!state.selectedSyncEntity) return null
  return (
    <L.Vertical spacing={2}>
      <Typo variant="h2" decorator>
        {'Log Einträge'}
      </Typo>
      <L.Horizontal spacing={2} className="w-full">
        <Select
          placeholder="Log Level wählen"
          label="Log Level"
          value={state.logsLevel}
          onChange={(evt) =>
            dispatch({ type: 'selected-logs-level', payload: { level: evt.target.value as LogLevel } })
          }
        >
          {logLevels.map((level, idx) => (
            <Select.Option value={level.value} key={`log-level-${idx}`}>
              {level.label}
            </Select.Option>
          ))}
        </Select>
        <Select
          label="Sync Typ"
          value={state.logsSyncType}
          onChange={(evt) =>
            dispatch({ type: 'selected-logs-sync-type', payload: { syncType: evt.target.value as SyncType } })
          }
        >
          <Select.Option value={null} key={`logs-sync-type-null`}>
            -
          </Select.Option>
          <Select.Option value={SyncType.Delta} key={`logs-sync-type-delta`}>
            Delta Synchronisation
          </Select.Option>
          <Select.Option value={SyncType.Full} key={`logs-sync-type-full`}>
            Tägliche Synchronisation
          </Select.Option>
        </Select>
        <DateTimePicker
          required
          maxDateTime={state.logsDateUntil ?? new Date()}
          value={state.logsDateFrom}
          onChange={(date) => dispatch({ type: 'selected-logs-date-from', payload: { date } })}
          label={'Von'}
        />
        <DateTimePicker
          required
          minDateTime={state.logsDateFrom}
          maxDateTime={new Date()}
          value={state.logsDateUntil}
          onChange={(date) => dispatch({ type: 'selected-logs-date-until', payload: { date } })}
          label={'Bis'}
        />
        <ToggleButtonGroup
          value={state.logsPreset}
          onChange={(_, minutes) => dispatch({ type: 'selected-logs-preset', payload: { minutes } })}
          exclusive
        >
          <ToggleButton value={5} className="whitespace-nowrap">
            5 Min
          </ToggleButton>
          <ToggleButton value={30} className="whitespace-nowrap">
            30 Min
          </ToggleButton>
          <ToggleButton value={60} className="whitespace-nowrap">
            1 Std
          </ToggleButton>
          <ToggleButton value={180} className="whitespace-nowrap">
            3 Std
          </ToggleButton>
          <ToggleButton value={720} className="whitespace-nowrap">
            12 Std
          </ToggleButton>
        </ToggleButtonGroup>
      </L.Horizontal>
      <L.Horizontal className="justify-between">
        <L.Horizontal spacing={2} className="w-full">
          <TextField
            label="Limit"
            value={limit.toString()}
            variant="outlined"
            type="number"
            onChange={(evt) => {
              const value = Number(evt.currentTarget.value)
              value <= 100 && setLimit(value)
            }}
            className="w-32"
            min={0}
            max={100}
          />
          {state.selectedSyncEntity.phases === 2 && (
            <Select
              className="w-48"
              label="Sync Phase"
              value={state.logsPhase}
              onChange={(evt) =>
                dispatch({ type: 'selected-logs-phase', payload: { phase: evt.target.value as SyncPhase } })
              }
            >
              <Select.Option value={null} key={`logs-phase-null`}>
                -
              </Select.Option>
              <Select.Option value={SyncPhase.Prep} key={`logs-phase-prep`}>
                Vorbereitungsphase
              </Select.Option>
              <Select.Option value={SyncPhase.Sync} key={`logs-phase-sync`}>
                Synchronisationsphase
              </Select.Option>
            </Select>
          )}
        </L.Horizontal>
        <Button
          variant="contained"
          className="whitespace-nowrap"
          onClick={getLogs}
          disabled={invalidLogsFilter || state.logsLoading}
        >
          Logs anzeigen
        </Button>
      </L.Horizontal>
      <Card className="relative" style={{ minHeight: 300 }}>
        {state.logsLoading && <Spinner centered />}
        {state.logsShown && (
          <>
            <Typo
              variant="body1"
              className="text-right p-2"
            >{`${logs.length} von ${totalCount} Einträgen werden angezeigt`}</Typo>
            <Yalc2<{ id: string; message: string; timestamp: string }>
              data={state.logsShown ? logs || [] : []}
              rowDefinition={rowDefinition}
              onSelectionChanged={handleEntrySelect}
              selectedIds={selectedIds}
              onClickOutsideList={handleClickOutsideSelection}
            />
          </>
        )}
      </Card>
    </L.Vertical>
  )
}

const useRowDefinition = (): Yalc2RowDefinition<{ id: string; message: string; timestamp: string }> => {
  return useMemo(() => {
    return {
      columns: [
        {
          title: 'Zeitstempel',
          width: 250,
          cell: function TimestampCell(props) {
            const { formatDetailedDateTimeString } = useFormatter()
            return <Yalc2Cell.SimpleCell>{formatDetailedDateTimeString(props.item.timestamp)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          title: 'Log level',
          width: 100,
          cell: function LogLevelCell(props) {
            const logLevel: LogLevel = JSON.parse(props.item.message).level
            return <Yalc2Cell.SimpleCell>{logLevel}</Yalc2Cell.SimpleCell>
          },
        },
        {
          title: 'Inhalt',
          width: 600,
          cell: function MessageCell(props) {
            const message: string = JSON.parse(props.item.message).message
            return <Yalc2Cell.MaxWidthCell>{message}</Yalc2Cell.MaxWidthCell>
          },
        },
      ],
    }
  }, [])
}

const logLevels = [
  { label: '-', value: null },
  {
    label: 'All',
    value: LogLevel.All,
  },
  {
    label: 'Debug',
    value: LogLevel.Debug,
  },
  {
    label: 'Info',
    value: LogLevel.Info,
  },
  {
    label: 'Warn',
    value: LogLevel.Warn,
  },
  {
    label: 'Error',
    value: LogLevel.Error,
  },
  {
    label: 'Fatal',
    value: LogLevel.Fatal,
  },
]
