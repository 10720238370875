export const selectFile = async ({ multiple, accept }: { multiple: boolean; accept?: string }): Promise<FileList> => {
  return new Promise((resolve, reject) => {
    const fileSelector = document.createElement('input')
    fileSelector.setAttribute('type', 'file')
    if (multiple) {
      fileSelector.setAttribute('multiple', 'multiple')
    }
    if (accept) {
      fileSelector.setAttribute('accept', accept)
    }
    fileSelector.onchange = ({ target }) => {
      if (target) {
        const files = (target as HTMLInputElement).files
        if (files && files.length > 0) {
          resolve(files as FileList)
        } else {
          reject('no files selected')
        }
      }
    }
    fileSelector.click()
  })
}
