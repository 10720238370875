import clsx from 'clsx'
import { Icon } from 'components/Icon'
import { Typo } from 'components/Typo'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'

interface CollapsableProps {
  title: string
  className?: string
  style?: CSSProperties
}

export const Collapsable: React.FC<CollapsableProps> = (props) => {
  const { ref, state, dispatcher } = useCollapsable()

  return (
    <div
      className={clsx('block cursor-pointer', props.className)}
      style={props.style}
      onClick={() => dispatcher.setIsCollapsed((prev) => !prev)}
    >
      <div className="flex justify-between ">
        <Typo variant="body1">{props.title}</Typo>
        <Icon name={state.collapsed ? 'down' : 'up'} />
      </div>
      <div
        ref={ref}
        className={clsx(
          'transition-all duration-500 ease-in-out flex-full h-0 overflow-hidden ',
          !state.collapsed && 'mt-4'
        )}
        style={{ height: state.bodyHeight }}
      >
        <div>{props.children}</div>
      </div>
    </div>
  )
}

export const useCollapsable = (collapsedInitially = true) => {
  const collabsableBodyRef = useRef<HTMLDivElement>(null)
  const [isCollapsed, setIsCollapsed] = useState(collapsedInitially)
  const [bodyHeight, setBodyHeight] = useState(0)

  useEffect(() => {
    const refHeight = collabsableBodyRef.current && collabsableBodyRef.current?.scrollHeight
    if (!refHeight || !collabsableBodyRef.current || isCollapsed) {
      setBodyHeight(0)
      return undefined
    }
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      let maxHeight = 0
      entries.forEach((entry) => {
        maxHeight = entry.target?.children[0]?.scrollHeight ?? 0
      })

      if (collabsableBodyRef.current) {
        setBodyHeight(maxHeight)
      }
    })
    observer.observe(collabsableBodyRef.current)
    return () => {
      observer.disconnect()
    }
  }, [isCollapsed])

  return {
    ref: collabsableBodyRef,
    state: { collapsed: isCollapsed, bodyHeight },
    dispatcher: { setIsCollapsed, setBodyHeight },
  }
}
