import React, { CSSProperties, useState, useContext, MouseEventHandler } from 'react'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'

import { IconButton, IconButtonProps } from '../IconButton'
import { wrapEvent } from 'utils'
import { Button, ButtonProps } from 'components/Button'

const Ctx = React.createContext({
  anchorElement: null as HTMLElement | null,
  setAnchorElement: (() => {}) as (el: HTMLElement | null) => void,
})

interface MenuProps {
  children: React.ReactNode
}

export const Menu = (props: MenuProps) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)

  return <Ctx.Provider value={{ anchorElement, setAnchorElement }}>{props.children}</Ctx.Provider>
}

interface MenuIconButtonProps {
  'aria-label': string
  style?: CSSProperties
  className?: string
  color?: 'primary' | 'secondary' | 'inherit'
  onClick?: IconButtonProps['onClick']
}

export const MenuIconButton: React.FC<MenuIconButtonProps> = (props) => {
  const { setAnchorElement } = useContext(Ctx)
  return (
    <IconButton
      aria-label={props['aria-label']}
      style={props.style}
      className={props.className}
      onClick={(evt) => {
        props.onClick?.call(null, evt)
        setAnchorElement(evt.currentTarget)
        evt.stopPropagation()
      }}
      size="large"
      color={props.color}
    >
      {props.children}
    </IconButton>
  )
}
Menu.IconButton = MenuIconButton

type MenuButtonProps = {
  className?: string
  style?: CSSProperties
  onClick?: ButtonProps['onClick']
  variant?: ButtonProps['variant']
  startIcon?: ButtonProps['startIcon']
  endIcon?: ButtonProps['endIcon']
  disabled?: boolean
}

export const MenuButton: React.FC<MenuButtonProps> = (props) => {
  const { setAnchorElement } = useContext(Ctx)
  const handleClick: MouseEventHandler<HTMLButtonElement> = (evt) => {
    props.onClick?.call(null, evt)
    setAnchorElement(evt.currentTarget)
    evt.stopPropagation()
  }
  return <Button {...props} onClick={handleClick} />
}
Menu.Button = MenuButton

interface MenuUnstyledButtonProps {
  className?: string
  style?: CSSProperties
  onClick?: () => void
}

export const MenuUnstyledButton: React.FC<MenuUnstyledButtonProps> = (props) => {
  const { setAnchorElement } = useContext(Ctx)
  const handleClick: MouseEventHandler<HTMLDivElement> = (evt) => {
    setAnchorElement(evt.currentTarget)
    props.onClick && props.onClick()
    evt.stopPropagation()
  }
  return <div style={props.style} className={props.className} onClick={handleClick} children={props.children} />
}

Menu.UnstyledButton = MenuUnstyledButton

interface MenuItemListProps {
  className?: string
  style?: CSSProperties
  onClose?: () => void
}

export const MenuItemList: React.FC<MenuItemListProps> = (props) => {
  const { anchorElement, setAnchorElement } = useContext(Ctx)
  // TODO: replace MuiMenu with Popper & MenuList to get flexibility to set transformOrigin (placement)
  return (
    <MuiMenu
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      keepMounted
      onClose={() => {
        props.onClose && props.onClose()
        setAnchorElement(null)
      }}
      onClick={(evt) => evt.stopPropagation()}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </MuiMenu>
  )
}
Menu.ItemList = MenuItemList

interface MenuItemProps {
  disabled?: boolean
  onClick?: () => void
  className?: string
  style?: CSSProperties
  suppressCloseOnClick?: boolean
}

export const MenuItem: React.FC<MenuItemProps> = React.forwardRef((props, ref: any) => {
  const { setAnchorElement } = useContext(Ctx)
  const handleClose = () => {
    if (props.suppressCloseOnClick) return
    setAnchorElement(null)
  }
  return (
    <MuiMenuItem
      className={props.className}
      style={props.style}
      onClick={wrapEvent(props.onClick, handleClose)}
      ref={ref}
      disabled={props.disabled}
    >
      {props.children}
    </MuiMenuItem>
  )
})
Menu.Item = MenuItem
