import React from 'react'
import { Ticket_Priority_Enum } from '../../gql'
import { Icon } from 'components/Icon'
import { StatusLight } from '../StatusLight'
import { range } from 'lodash'

interface PriorityIndicatorProps {
  priority: Ticket_Priority_Enum
}

export const PriorityIndicator = (props: PriorityIndicatorProps) => {
  const dotsCount = useDotsCount(props)
  if (props.priority === Ticket_Priority_Enum.Urgent) {
    return <Icon name={'exclamationMark'} color={'warning'}></Icon>
  }
  return (
    <div className={'flex flex-row gap-x-1 items-center'}>
      {range(dotsCount).map((val, idx) => (
        <StatusLight key={`prio-${idx}-dot`} status={'primary'} variant={'filled'} />
      ))}
    </div>
  )
}

const useDotsCount = (props: PriorityIndicatorProps) => {
  switch (props.priority) {
    case Ticket_Priority_Enum.Low:
      return 1
    case Ticket_Priority_Enum.Medium:
      return 2
    case Ticket_Priority_Enum.High:
      return 3
    default:
      return 0
  }
}
