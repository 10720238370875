import React from 'react'
import { onError } from '@apollo/link-error'
import Observable from 'zen-observable'
import { useAlert } from 'components'
import { useEffect } from 'react'
import { LinkRegistry } from '../ApolloLinkRegistry'
import { User, ezAuthEventHub, EzAuthEventType } from '@lib/ezauth'
import { NextLink, Operation } from '@apollo/client'

let user: User | null = null

let emitError: (error: Error) => void
const errorObserver = new Observable<Error>((observer) => {
  emitError = (msg) => observer.next(msg)
})

export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      // The error with code 'ivalid-jwt' occurs while validating JWT
      // https://hasura.io/blog/handling-graphql-hasura-errors-with-react/
      if (extensions?.code === 'invalid-jwt') {
        refreshToken(operation, forward)
      }
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
    graphQLErrors.forEach((error) => emitError(error))
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    emitError(networkError)
  }
})

export const ApolloErrorAlert = () => {
  const [alert] = useAlert()
  useEffect(() => {
    const subscriber = errorObserver.subscribe((error) => {
      alert.error('Leider ist ein Fehler bei der Datenübertragung aufgetreten.', error)
    })

    return () => subscriber.unsubscribe()
  }, [alert])
  return null
}

LinkRegistry.add('error', {
  createLink: () => errorLink as any,
  createComponent: () => <ApolloErrorAlert />,
  order: 10,
})

const refreshToken = (operation: Operation, forward: NextLink) => {
  user
    ?.getIdToken()
    .then((token) => {
      const oldHeaders = operation.getContext().headers
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: token ? `Bearer ${token}` : '',
        },
      })
      forward(operation)
    })
    .catch((error) => {
      console.log('Error refreshin token when connection init failed.', error)
    })
}

ezAuthEventHub.subscribe((event) => {
  switch (event.type) {
    case EzAuthEventType.INITIALIZED:
    case EzAuthEventType.SIGNED_IN:
      if (event.user) {
        user = event.user ?? null
      }
      break
    case EzAuthEventType.SIGNED_OUT:
      user = null
      break
  }
})
