import { useFormFields } from '@w2solutions/react-hooks'
import { Form } from 'components/Form'
import { FormRow } from 'components/FormRow'
import { TextField } from 'components/TextField'
import { AdminBrandEmbeddedPagesListItem } from 'containers/AdminBrandDetail/06-embedded_pages'
import {
  useAdminEmbeddedPageDetailLazyQuery,
  useCreateNewEmbeddedPageMutation,
  useEmbeddedPagesQuery,
  useUpdateEmbeddedPageMutation,
} from 'gql'
import { useJoi } from 'hooks/useJoi'
import Joi from 'joi'
import React, { useCallback, useEffect, useMemo } from 'react'
import { DetailWindow } from '../../components/DetailWindow'
import { EmbeddedPageSelect } from '../EmbeddedPageSelect'
import { BrandSelect } from '../BrandSelect'

interface AdminEmbeddedPageDetailProps {
  onClose: () => void
  title: string
  brandId?: string
  embeddedPage?: AdminBrandEmbeddedPagesListItem
}

export const AdminEmbeddedPageDetail = (props: AdminEmbeddedPageDetailProps) => {
  const [query, queryResponse] = useAdminEmbeddedPageDetailLazyQuery()
  const [create] = useCreateNewEmbeddedPageMutation()
  const [update] = useUpdateEmbeddedPageMutation()

  const id = props.embeddedPage?.id
  useEffect(() => {
    if (!id) return
    query({ variables: { id } })
  }, [id, query])

  const initialValue = useMemo(
    () =>
      queryResponse.data?.embedded_pages_by_pk
        ? queryResponse.data.embedded_pages_by_pk
        : {
            name: '',
            url: '',
            brand_id: props.brandId,
          },
    [props.brandId, queryResponse?.data?.embedded_pages_by_pk]
  )

  const { values, bindings, setValue } = useFormFields(initialValue, { resetOnInitialValueChange: true })
  const { isValid, errors, validate } = useJoi(validationSchema, values)

  const handleSave = useCallback(async () => {
    await validate()
    if (!isValid) return
    if (id) {
      await update({
        variables: {
          id,
          _set: {
            name: values.name,
            brand_id: values.brand_id,
            url: values.url,
          },
        },
      })
    } else {
      await create({
        variables: {
          object: {
            name: values.name,
            brand_id: values.brand_id,
            url: values.url,
          },
        },
      })
    }
    props.onClose()
  }, [validate, isValid, id, update, values, create, props])

  const pagesQuery = useEmbeddedPagesQuery({ fetchPolicy: 'network-only' })
  const embeddedPages = useMemo(() => pagesQuery.data?.embedded_pages ?? [], [pagesQuery.data?.embedded_pages])
  const brandFilter = useCallback(
    (brandId: string) => {
      if (!values.name) return true
      if (props.embeddedPage?.brand_id === brandId && values.name === props.embeddedPage?.name) return true
      return !embeddedPages.some((page) => page.brand_id === brandId && page.name === values.name)
    },
    [embeddedPages, props.embeddedPage, values.name]
  )
  const embeddedPageFilter = useCallback(
    (name: string) => {
      if (!values.brand_id) return true
      if (props.embeddedPage?.name === name && values.brand_id === props.embeddedPage?.brand_id) return true
      return !embeddedPages.some((page) => page.brand_id === values.brand_id && page.name === name)
    },
    [embeddedPages, props.embeddedPage, values.brand_id]
  )

  return (
    <DetailWindow onClose={props.onClose} width={700} title={props.title}>
      <Form>
        <div className={'p-6'}>
          <FormRow label={'Name'} required error={errors.name}>
            <EmbeddedPageSelect
              value={values.name}
              onChange={(name) => setValue('name', name)}
              brandId={props.brandId || values.brand_id}
              embeddedPage={props.embeddedPage}
              filter={embeddedPageFilter}
            />
          </FormRow>
          <FormRow label={'Marke'}>
            <BrandSelect
              filter={brandFilter}
              disabled={!!props.brandId}
              brandId={values.brand_id}
              onChange={(brandId) => setValue('brand_id', brandId[0])}
              onlyHouseBrands
            />
          </FormRow>
          <FormRow label={'Url'} required error={errors.url}>
            <TextField {...bindings.url} />
          </FormRow>
        </div>
        <Form.Actions onCancel={props.onClose} onSave={handleSave}></Form.Actions>
      </Form>
    </DetailWindow>
  )
}

const validationSchema = Joi.object<{ name: string; url: string }>({
  name: Joi.string().required().label('Name'),
  url: Joi.string().required().label('Url'),
})
