import React from 'react'
import * as L from 'layouts'
import { TicketsListConfig, TicketsListType } from '../../ListConfig/Tickets/List/Config'
import { TicketsList } from 'containers/ListConfig/Tickets/List'

export const TicketsData: React.FC = () => {
  return (
    <L.Vertical spacing={5} className="p-6 pb-8">
      <TicketsListConfig type={TicketsListType.VEHICLE}>
        <TicketsList />
      </TicketsListConfig>
    </L.Vertical>
  )
}
