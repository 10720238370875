import React, { useContext } from 'react'
import { TableHead as MuiTableHead, TableRow as MuiTableRow, TableCell as MuiTableCell } from '@mui/material'

import { TableContext } from './index'

const TableHeaderRow: React.FC = () => {
  const { columnsMetadata } = useContext(TableContext)

  return (
    <MuiTableHead>
      <MuiTableRow>
        {columnsMetadata.map((col, idx) => {
          return (
            <MuiTableCell className="whitespace-nowrap sticky top-0 bg-background-paper" key={`header-${idx}`}>
              {col.title || ''}
            </MuiTableCell>
          )
        })}
      </MuiTableRow>
    </MuiTableHead>
  )
}

export { TableHeaderRow }
