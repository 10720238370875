import React, { useEffect, useMemo, useState } from 'react'
import {
  In_Buy_Channel_Enum,
  Role_Enum,
  useDeleteTeamAssignmentMutation,
  useInsertTeamAssignmentMutation,
  useUpdateTeamAssignmentMutation,
  useUserTeamDetailLazyQuery,
} from '../../gql'
import { mergeDeepWith } from 'ramda'
import { PulseForm } from '../PulseForm'
import { DepartmentSelect } from '../DepartmentSelect'
import { useFormFields } from '@w2solutions/react-hooks'
import { Button, Chip, Form, Icon } from '../../components'
import { UserRoleSelect } from './UserRoleSelect'
import { UserRoleChip } from './UserRoleChip'
import { LocationSelect } from '../LocationSelect'
import { BrandSelect } from '../BrandSelect'
import { InBuyChannelChip } from '../InBuyChannel/Chip'
import { InBuyChannelSelect } from '../InBuyChannel/Select'
import { Switch } from '@mui/material'
import { UserTeams } from './TeamsList'
import { useConfirm } from '../../components/Confirm'
import { DetailWindow } from 'components/DetailWindow'

interface TeamDetailProps {
  title: string
  teamId?: string
  userId: string
  onClose?: () => void
}

export const TeamDetail = (props: TeamDetailProps) => {
  const [query, queryResponse] = useUserTeamDetailLazyQuery()
  useEffect(() => {
    if (!props.teamId) {
      return
    }
    query({ variables: { id: props.teamId } })
  }, [props.teamId, query])
  const team = useMemo(() => queryResponse.data?.team_assignment, [queryResponse.data?.team_assignment])
  const [editMode, setEditMode] = useState(false)

  const [deleting, setDeleting] = useState(false)

  const [insert] = useInsertTeamAssignmentMutation()
  const [update] = useUpdateTeamAssignmentMutation()
  const [deleteTeam] = useDeleteTeamAssignmentMutation()
  const save = async (values) => {
    if (team?.id) {
      await update({
        variables: {
          id: team.id,
          _set: {
            department_id: values.departmentId,
            location_id: values.locationId,
            is_primary: values.isPrimary,
            role: values.role,
            brand_id: values.brandId,
            in_buy_channel: values.inBuyChannel,
          },
        },
      })
    } else {
      await insert({
        variables: {
          object: {
            user_id: props.userId,
            department_id: values.departmentId,
            location_id: values.locationId,
            is_primary: values.isPrimary,
            role: values.role,
            brand_id: values.brandId,
            in_buy_channel: values.inBuyChannel,
          },
        },
      })
    }
  }

  const [ConfirmDialog, confirm] = useConfirm()

  const handleDelete = async () => {
    const { hide } = await confirm()
    setDeleting(true)
    await deleteTeam({ variables: { id: team.id } })
    setDeleting(false)
    hide()
    props.onClose()
  }

  const formConfig = useTeamFormular(save, props.userId, team)
  return (
    <DetailWindow onClose={props.onClose} width={500} title={props.title}>
      <div className={'relative h-full'}>
        <PulseForm
          editMode={editMode}
          handleEdit={() => setEditMode(true)}
          disableNotes={true}
          sections={formConfig.sections}
        />
        {editMode && (
          <Form.ActionBar>
            <Button variant={'outlined'} onClick={props.onClose}>
              Abbrechen
            </Button>
            <Button
              variant={'contained'}
              onClick={async () => {
                await formConfig.onSubmit()
                props.onClose()
              }}
            >
              Speichern
            </Button>
          </Form.ActionBar>
        )}
        {props.teamId && (
          <Form.ActionBar style={{ position: 'absolute', bottom: 0, width: '100%' }}>
            <Button variant={'contained'} color={'error'} onClick={handleDelete}>
              Löschen
            </Button>
          </Form.ActionBar>
        )}
        <ConfirmDialog loading={deleting} confirmButtonText="Löschen">
          Mit dieser Aktion wird das Team unwiderrufflich gelöscht
        </ConfirmDialog>
      </div>
    </DetailWindow>
  )
}

const initialData = {
  locationId: null,
  isPrimary: false,
  departmentId: null,
  role: Role_Enum.User,
  brandId: null,
  inBuyChannel: null as In_Buy_Channel_Enum,
}
type FormData = typeof initialData
const useTeamFormular = (save: (values: FormData) => Promise<void>, userId: string, team?: ArrayElement<UserTeams>) => {
  const teamToFormData = (team?: ArrayElement<UserTeams>): FormData => {
    if (!team) return initialData
    return mergeDeepWith(
      (a, b) => a ?? b,
      {
        locationId: team?.location_id,
        isPrimary: team?.is_primary,
        departmentId: team?.department_id,
        role: team?.role,
        brandId: team?.brand_id,
        inBuyChannel: team?.in_buy_channel,
      },
      initialData
    )
  }
  const initialValues = useMemo(() => teamToFormData(team), [team])

  const { values, setValue } = useFormFields(initialValues, { resetOnInitialValueChange: true })

  return useMemo(
    () => ({
      onSubmit: () => save(values),
      sections: [
        {
          name: 'team',
          fullWidth: true,
          fields: [
            {
              name: 'department_id',
              label: 'Bereich',
              placeholder: team?.department_id ? <Chip>{team.department?.name}</Chip> : '-',
              FormComponent: (
                <DepartmentSelect
                  value={values.departmentId}
                  onSelect={(department) => {
                    setValue('departmentId', department.id)
                    setValue('role', Role_Enum.User)
                    setValue('isPrimary', false)
                  }}
                />
              ),
            },
            {
              name: 'role',
              label: 'Rolle',
              required: true,
              placeholder: <UserRoleChip role={team?.role} />,
              FormComponent: (
                <UserRoleSelect
                  departmentId={values.departmentId}
                  value={values.role}
                  onSelect={(role) => setValue('role', role)}
                />
              ),
            },
            {
              name: 'is_primary',
              label: 'Primär',
              required: true,
              placeholder: team?.is_primary ? <Icon name={'check'} /> : <Icon name={'close'} />,
              FormComponent: (
                <Switch checked={values.isPrimary} onChange={(evt) => setValue('isPrimary', evt.target.checked)} />
              ),
            },
            {
              name: 'location_id',
              label: 'Standort',
              placeholder: team?.location_id ? <Chip>{team.location?.short_name}</Chip> : '-',
              FormComponent: (
                <LocationSelect value={values.locationId} onSelect={(val) => setValue('locationId', val)} />
              ),
            },
            {
              name: 'brand_id',
              label: 'Marke',
              placeholder: team?.brand?.name ?? '-',
              FormComponent: <BrandSelect onChange={(val) => setValue('brandId', val[0])} brandId={values.brandId} />,
            },
            {
              name: 'in_buy_channel',
              label: 'Zukaufs Kanal',
              placeholder: <InBuyChannelChip channel={team?.in_buy_channel} />,
              FormComponent: (
                <InBuyChannelSelect
                  value={values.inBuyChannel}
                  onSelect={(channel) => setValue('inBuyChannel', channel)}
                />
              ),
            },
          ],
        },
      ],
    }),
    [
      save,
      setValue,
      team?.brand?.name,
      team?.department?.name,
      team?.department_id,
      team?.in_buy_channel,
      team?.is_primary,
      team?.location?.short_name,
      team?.location_id,
      team?.role,
      values,
    ]
  )
}
