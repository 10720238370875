import React, { useState } from 'react'
import Helmet from 'react-helmet'

import { AlertFeed, Hidden, IconButton, Icon, Typo } from 'components'
import * as L from 'layouts'

import { AppBar, Toolbar, IconButton as MuiIconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useEzAuth } from '@lib/ezauth'
import { AppMenu } from 'containers/AppMenu'
import { useConfig } from 'config'
import { AppLocationSelect } from './AppLocationSelect'
import { AppNotifications } from './AppNotifications'
import { DetailViews } from 'components/DetailWindow/useDetailView'

interface DefaultPageLayoutProps {
  children?: React.ReactNode
}

export const DefaultPageLayout: React.FC<DefaultPageLayoutProps> = (props) => {
  const config = useConfig()
  const [title, setTitle] = useState(config.app.title)

  const [, { signOut }] = useEzAuth()

  const [appMenuOpen, setAppMenuOpen] = useState(false)

  return (
    <div className="flex">
      <Helmet
        onChangeClientState={(newState) => {
          const newTitle = newState.metaTags.find(({ name }) => name === 'custom:subtitle')?.content || config.app.title
          setTitle(newTitle)
        }}
      />
      <Hidden>
        <AppMenu variant="temporary" isOpen={appMenuOpen} onClose={() => setAppMenuOpen(false)} />
      </Hidden>
      <div className="h-screen flex flex-col flex-grow overflow-hidden bg-background-default ">
        <AppBar position="static" className="bg-background-paper text-text-primary z-appBar">
          <Toolbar>
            <Hidden>
              <MuiIconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setAppMenuOpen((prev) => !prev)}
              >
                <MenuIcon />
              </MuiIconButton>
            </Hidden>
            <div>
              <Typo variant="h1" style={{ fontSize: '2rem' }}>
                {title}
              </Typo>
            </div>
            <div style={{ flex: 1 }} />
            <L.Horizontal spacing={2}>
              <AppLocationSelect />
              <AppNotifications />
              <IconButton aria-label="Logout" onClick={() => signOut()} size="large">
                <Icon name="power" />
              </IconButton>
            </L.Horizontal>
          </Toolbar>
        </AppBar>
        <AlertFeed />
        <DetailViews />
        <div style={{ flexGrow: 1, position: 'relative', zIndex: 0 }}>
          <main className="absolute inset-0 overflow-auto h-full">
            <div className="p-8 h-full">{props.children}</div>
          </main>
        </div>
      </div>
    </div>
  )
}
