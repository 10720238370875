import React, { createContext, useRef } from 'react'

interface ScrollSyncContextProps {
  registerPane: (node: HTMLDivElement, groups: string[]) => void
  unregisterPane: (node: HTMLDivElement, groups: string[]) => void
}

export const ScrollSyncContext = createContext<ScrollSyncContextProps>({
  registerPane: () => {},
  unregisterPane: () => {},
})

export const ScrollSync: React.FC = (props) => {
  let panes: { [key: string]: HTMLDivElement[] } = {}

  const findPane = (node: HTMLDivElement, group: string) => {
    if (!panes[group]) {
      return false
    }
    return panes[group].find((pane) => pane === node)
  }

  const scrollHandler = useRef<() => void>()

  const syncScrollPosition = (scrolledPane: HTMLDivElement, pane: HTMLDivElement) => {
    const scrollTopOffset = scrolledPane.scrollHeight - scrolledPane.clientHeight
    const scrollLeftOffset = scrolledPane.scrollWidth - scrolledPane.clientWidth
    const paneHeight = pane.scrollHeight - scrolledPane.clientHeight
    const paneWidth = pane.scrollWidth - scrolledPane.clientWidth

    if (scrollTopOffset > 0) {
      pane.scrollTop = (paneHeight * scrolledPane.scrollTop) / scrollTopOffset
    }
    if (scrollLeftOffset > 0) {
      pane.scrollLeft = (paneWidth * scrolledPane.scrollLeft) / scrollLeftOffset
    }
  }

  const removeEvents = (node: HTMLDivElement) => {
    node.onscroll = null
  }

  const syncScrollPositions = (scrolledPane: HTMLDivElement, groups: string[]) => {
    groups.forEach((group) => {
      panes[group].forEach((pane) => {
        if (scrolledPane !== pane) {
          removeEvents(pane)
          syncScrollPosition(scrolledPane, pane)
          window.requestAnimationFrame(() => {
            addEvents(pane, groups)
          })
        }
      })
    })
  }

  const handlePaneScroll = (node: HTMLDivElement, groups: string[]) => {
    window.requestAnimationFrame(() => {
      syncScrollPositions(node, groups)
    })
  }

  const addEvents = (node: HTMLDivElement, groups: string[]) => {
    scrollHandler.current = () => handlePaneScroll(node, groups)
    node.onscroll = scrollHandler.current
  }

  const registerPane = (node: HTMLDivElement, groups: string[]) => {
    groups.forEach((group) => {
      if (!panes[group]) {
        panes[group] = []
      }
      if (!findPane(node, group)) {
        if (panes[group].length > 0) {
          syncScrollPosition(panes[group][0], node)
        }
        panes[group].push(node)
      }
    })
    addEvents(node, groups)
  }

  const unregisterPane = (node: HTMLDivElement, groups: string[]) => {
    groups.forEach((group) => {
      if (findPane(node, group)) {
        removeEvents(node)
        panes[group].splice(panes[group].indexOf(node), 1)
      }
    })
  }

  return (
    <ScrollSyncContext.Provider value={{ registerPane, unregisterPane }}>{props.children}</ScrollSyncContext.Provider>
  )
}
