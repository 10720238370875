import { Icon, Menu } from '../../components'
import React, { useMemo } from 'react'
import { useCreateTicketFromTemplateMutation, useManualTicketTemplatesQuery } from '../../gql'
import { VehicleActions } from 'containers/VehicleOptions/VehicleActions'
import { VehicleItem } from '../ListConfig/Vehicles/List'

interface VehicleTaskOptionsProps {
  vehicle: VehicleItem
}

export const VehicleOptions = (props: VehicleTaskOptionsProps) => {
  return (
    <Menu>
      <Menu.IconButton aria-label={'create a manual task for vehicle'}>
        <Icon name={'options'} />
      </Menu.IconButton>
      <Menu.ItemList>
        <VehicleActions vehicle={props.vehicle} />
        <hr className={'text-divider'} />
        <VehicleTasksOptionsDropdown vehicle={props.vehicle} />
      </Menu.ItemList>
    </Menu>
  )
}

interface VehicleTasksOptionsDropdownProps {
  vehicle: {id: string; business_case_number: number}
}

export const VehicleTasksOptionsDropdown = (props: VehicleTasksOptionsDropdownProps) => {
  const ticketTemplatesQuery = useManualTicketTemplatesQuery()

  const ticketTemplates = useMemo(() => ticketTemplatesQuery.data?.ticket_templates ?? [], [
    ticketTemplatesQuery.data?.ticket_templates,
  ])
  const [createTicket] = useCreateTicketFromTemplateMutation()

  const handleCreateTicket = async (ticketTemplateId: string) => {
    await createTicket({
      variables: {
        input: {
          ticketTemplateId,
          vehicle: {
            id: props.vehicle.id,
            businessCaseNumber: props.vehicle.business_case_number,
          },
        },
      },
    })
  }
  return (
    <>
      {ticketTemplates.map((ticketTemplate) => (
        <Menu.Item
          onClick={() => handleCreateTicket(ticketTemplate.id)}
          key={`manual-ticket-template-${ticketTemplate.id}`}
        >
          <span>
            Aufgabe erstellen: <span className={'font-bold'}>{ticketTemplate.ticket_title}</span>
          </span>
        </Menu.Item>
      ))}
    </>
  )
}
