import React, { useState } from 'react'

import * as L from 'layouts'
import { AlertBox, Button, Form, TextField, Typo } from 'components'
import { useFormFields } from '@w2solutions/react-hooks'
import useYup from '@w2solutions/use-yup'
import * as yup from 'yup'

export interface NewPwFormProps {
  submit: (data: { password: string }) => void
}

export const NewPwForm = (props: NewPwFormProps) => {
  const { bindings, values } = useFormFields({ password: '', passwordRepeat: '' })
  const validation = useYup(values, validationSchema)

  const [error, setError] = useState<string | null>(null)
  const handleSubmit = async () => {
    if (values.password !== values.passwordRepeat) {
      setError('Passwords do not match.')
      return
    }
    const { isValid, errors } = await validation.validate()

    if (!isValid) {
      setError(errors.password || errors.passwordRepeat || 'Invalid password')
      return
    }

    setError(null)
    props.submit({ password: values.password })
  }
  return (
    <Form onSubmit={handleSubmit}>
      <L.Vertical spacing={6}>
        <div>
          <Typo variant="h3">New Password required</Typo>
          <Typo variant="subtitle1">Lorem ipsum dolor sit amet.</Typo>
        </div>
        {error && (
          <AlertBox type="error" noTitle style={{ width: '100%' }}>
            {error}
          </AlertBox>
        )}
        <L.Vertical spacing={2}>
          <TextField label="Passwort" type="password" {...bindings.password} />
          <TextField label="Repeat Passwort" type="password" {...bindings.passwordRepeat} />
          <Button variant="contained" color="primary" type="submit">
            Set Password
          </Button>
        </L.Vertical>
      </L.Vertical>
    </Form>
  )
}

export const newPasswordSchema = yup
  .string()
  .min(8, 'Minimum 8 characters')
  .matches(/[a-z]/, 'At least one lower case character')
  .matches(/[A-Z]/, 'At least one upper case character')
  .matches(/\d/, 'At least one number')

const validationSchema = yup.object({
  password: newPasswordSchema,
})
