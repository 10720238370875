import React, { useCallback, useContext } from 'react'
import { DetailView as ModelGroupDetail } from '../../containers/AdminBrandDetail/02-model-groups'
import { AdminAssetLinkDetail } from '../../containers/AdminAssetLinkDetail'
import { ModelDetailView } from '../../containers/AdminBrandDetail/model-details/model-detail-view'
import { ModelBulkEdit } from '../../containers/AdminBrandDetail/model-details/model-bulk-edit'
import { AssignModelGroupDetail } from '../../containers/AdminBrandDetail/model-details/assign-model-group-detail'
import { AdminDepartmentDetail } from '../../containers/AdminDepartmentDetail'
import { AdminMonitoringLogDetail } from '../../containers/AdminMonitoring/LogDetail'
import { AdminSubDealerDetail } from '../../containers/AdminSubDealerDetail'
import { AdminSupplierDetail } from '../../containers/AdminSupplierDetail'
import { AdminTicketTemplateDetail } from '../../containers/AdminTicketTemplateDetail'
import { TeamDetail } from '../../containers/AdminUser/TeamDetail'
import { UserDetail } from '../../containers/AdminUser/UserDetail'
import { AdminVehicleImportJobDetail } from '../../containers/AdminVehicleImportJobDetail'
import { AfterSalesPotentialDetail } from '../../containers/AfterSalesPotentialsList/AfterSalesPotentialDetail'
import { ExcelImportTemplatesList } from '../../containers/ExcelImport/assignments/templates-list'
import { ExcelImportTemplateDetail } from '../../containers/ExcelImport/assignments/template-detail'
import { TicketDetail } from '../../containers/ListConfig/Tickets/DetailViews'
import { DealerVehicleDetails } from '../../containers/ListConfig/Vehicles/DetailViews'
import { VehiclesBulkEditView } from '../../containers/ListConfig/Vehicles/DetailViews/BulkEdit'
import { ImportVehiclesBulkEditView } from '../../containers/ListConfig/Vehicles/DetailViews/ImportBulkEdit'
import { DealerVehicleImportDetailView } from '../../containers/ListConfig/Vehicles/DetailViews/import'
import { ChangeStateModal } from '../../containers/VehicleForm/01_GeneralData/state-modal'
import { CreateNewStorageLocation } from '../../containers/StorageLocationSelect'
import { ChangeOrderStateModal } from '../../containers/VehicleForm/01_GeneralData/order-state-modal'
import {SalesReportEditView} from "../../containers/SalesReportEditView";

export enum DetailViewEnum {
  ModelGroupDetail = 'ModelGroupDetail',
  ModelDetail = 'ModelDetail',
  ModelBulkEdit = 'ModelBulkEdit',
  AssetLinkDetail = 'AssetLinkDetail',
  AssignModelGroupDetail = 'AssignModelGroupDetail',
  DepartmentDetail = 'DepartmentDetail',
  MonitoringLogDetail = 'MonitoringLogDetail',
  SubdealerDetail = 'SubdealerDetail',
  SupplierDetail = 'SupplierDetail',
  TicketTemplateDetail = 'TicketTemplateDetail',
  TeamDetail = 'TeamDetail',
  UserDetail = 'UserDetail',
  VehicleImportJobDetail = 'VehicleImportJobDetail',
  AfterSalesPotentialDetail = 'AfterSalesPotentialDetail',
  ExcelImportTemplates = 'ExcelImportTemplates',
  ExcelImportTemplateDetail = 'ExcelImportTemplateDetail',
  TicketDetail = 'TicketDetail',
  VehicleDetail = 'VehicleDetail',
  VehiclesBulkEdit = 'VehiclesBulkEdit',
  ImportVehiclesBulkEdit = 'ImportVehiclesBulkEdit',
  ImportVehicleDetail = 'ImportVehicleDetail',
  VehicleChangeState = 'VehicleChangeState',
  NewStorageLocation = 'NewStorageLocation',
  VehicleChangeOrderState = 'VehicleChangeOrderState',
  SalesReportEditView = 'SalesReportEditView',
}

const getDetailView = (key: DetailViewEnum, props?: any): DetailView => {
  switch (key) {
    case DetailViewEnum.ModelGroupDetail:
      return { Comp: ModelGroupDetail, props, key }
    case DetailViewEnum.AssetLinkDetail:
      return { Comp: AdminAssetLinkDetail, props, key }
    case DetailViewEnum.ModelDetail:
      return { Comp: ModelDetailView, props, key }
    case DetailViewEnum.ModelBulkEdit:
      return { Comp: ModelBulkEdit, props, key }
    case DetailViewEnum.AssignModelGroupDetail:
      return { Comp: AssignModelGroupDetail, props, key }
    case DetailViewEnum.DepartmentDetail:
      return { Comp: AdminDepartmentDetail, props, key }
    case DetailViewEnum.MonitoringLogDetail:
      return { Comp: AdminMonitoringLogDetail, props, key }
    case DetailViewEnum.SubdealerDetail:
      return { Comp: AdminSubDealerDetail, props, key }
    case DetailViewEnum.SupplierDetail:
      return { Comp: AdminSupplierDetail, props, key }
    case DetailViewEnum.TicketTemplateDetail:
      return { Comp: AdminTicketTemplateDetail, props, key }
    case DetailViewEnum.TeamDetail:
      return { Comp: TeamDetail, props, key }
    case DetailViewEnum.UserDetail:
      return { Comp: UserDetail, props, key }
    case DetailViewEnum.VehicleImportJobDetail:
      return { Comp: AdminVehicleImportJobDetail, props, key }
    case DetailViewEnum.AfterSalesPotentialDetail:
      return { Comp: AfterSalesPotentialDetail, props, key }
    case DetailViewEnum.ExcelImportTemplates:
      return { Comp: ExcelImportTemplatesList, props, key }
    case DetailViewEnum.ExcelImportTemplateDetail:
      return { Comp: ExcelImportTemplateDetail, props, key }
    case DetailViewEnum.TicketDetail:
      return { Comp: TicketDetail, props, key }
    case DetailViewEnum.VehicleDetail:
      return { Comp: DealerVehicleDetails, props, key }
    case DetailViewEnum.VehiclesBulkEdit:
      return { Comp: VehiclesBulkEditView, props, key }
    case DetailViewEnum.ImportVehiclesBulkEdit:
      return { Comp: ImportVehiclesBulkEditView, props, key }
    case DetailViewEnum.ImportVehicleDetail:
      return { Comp: DealerVehicleImportDetailView, props, key }
    case DetailViewEnum.VehicleChangeState:
      return { Comp: ChangeStateModal, props, key }
    case DetailViewEnum.NewStorageLocation:
      return { Comp: CreateNewStorageLocation, props, key }
    case DetailViewEnum.VehicleChangeOrderState:
      return { Comp: ChangeOrderStateModal, props, key }
    case DetailViewEnum.SalesReportEditView:
      return { Comp: SalesReportEditView, props, key }
  }
}

export const useDetailView = () => {
  const { dispatch } = useContext(DetailViewCtx)

  const closeDetailView = useCallback(
    (key: DetailViewEnum) => dispatch({ type: 'remove', payload: { key } }),
    [dispatch]
  )

  const openDetailView = useCallback(
    (key: DetailViewEnum, options?: any) => {
      const detailView = getDetailView(key, options)
      dispatch({ type: 'add', payload: detailView })
    },
    [dispatch]
  )

  const openNestedDetailView = useCallback(
    (key: DetailViewEnum, options?: any) => {
      const detailView = getDetailView(key, options)
      dispatch({ type: 'add-nested', payload: detailView })
    },
    [dispatch]
  )

  return { openDetailView, closeDetailView, openNestedDetailView }
}

type DetailView = {
  key: DetailViewEnum
  Comp: React.FC<any>
  props: any
}

type AddDetailViewAction = {
  type: 'add'
  payload: DetailView
}

type RemoveDetailViewAction = {
  type: 'remove'
  payload: { key: DetailViewEnum }
}

type AddNestedDetailViewAction = {
  type: 'add-nested'
  payload: DetailView
}

type DetailViewReducerAction = AddDetailViewAction | RemoveDetailViewAction | AddNestedDetailViewAction

const initialDetailViewState = {
  detailViews: [] as DetailView[],
}

const DetailViewCtx = React.createContext({
  state: initialDetailViewState,
  dispatch: (() => {}) as React.Dispatch<DetailViewReducerAction>,
})

const detailViewReducer = (state: typeof initialDetailViewState, action: DetailViewReducerAction) => {
  switch (action.type) {
    case 'add':
      return {
        detailViews: [action.payload],
      }
    case 'remove':
      return {
        detailViews: state.detailViews.filter((detailView) => detailView.key !== action.payload.key),
      }
    case 'add-nested':
      return {
        detailViews: [
          ...state.detailViews.filter((detailView) => detailView.key !== action.payload.key),
          action.payload,
        ],
      }
  }
}

export const DetailViewProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(detailViewReducer, {
    detailViews: [] as DetailView[],
  })

  return <DetailViewCtx.Provider value={{ state, dispatch }}>{children}</DetailViewCtx.Provider>
}

export const DetailViews = () => {
  const {
    state: { detailViews },
    dispatch,
  } = useContext(DetailViewCtx)
  if (!detailViews.length) {
    return null
  }

  return (
    <>
      {detailViews.map(({ Comp, props, key }) => (
        <Comp
          {...props}
          key={key}
          onClose={() => {
            props?.onClose && props.onClose()
            dispatch({ type: 'remove', payload: { key } })
          }}
        />
      ))}
    </>
  )
}
