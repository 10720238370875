import React, { useEffect, useState } from 'react'
import * as L from 'layouts'
import { Menu, SiteMetadata, Spinner } from '../../../components'
import { useAdminSupplierListQuery } from '../../../gql'
import { Yalc2, Yalc2RowDefinition } from 'components/Yalc2'
import { DetailViewEnum, useDetailView } from '../../../components/DetailWindow/useDetailView'

export const AdminSuppliersPage = () => {
  const suppliersConnection = useAdminSupplierListQuery()
  const suppliers =
    suppliersConnection.data?.suppliers?.map((node) => ({
      id: node.id,
      name: node.name,
      address: node.address.fullAddress,
    })) ?? []

  const {openDetailView} = useDetailView()
  return (
    <>
      <SiteMetadata title="Lieferanten Verwalten" />
      <L.Vertical>
        <L.Horizontal>
          <div style={{ flexGrow: 1 }}></div>
          {suppliersConnection.loading && <Spinner />}
        </L.Horizontal>
        <SupplierList
          suppliers={suppliers}
          onAdd={() =>
            openDetailView(DetailViewEnum.SupplierDetail, {
              title: 'Neuer Lieferant',
              onClose: () => suppliersConnection.refetch(),
            })
          }
        />
      </L.Vertical>
    </>
  )
}

interface SupplierListProps {
  suppliers: SupplierListElement[]
  onAdd: () => void
}

interface SupplierListElement {
  id: string
  name: string
  address: string
}

const SupplierList = (props: SupplierListProps) => {
  const [selectedIds, setSelectedIds] = useState([])
  const supplierToEdit = selectedIds.length === 1 ? selectedIds[0] : null

  const {openDetailView} = useDetailView()
  useEffect(() => {
    if (supplierToEdit) {
      openDetailView(DetailViewEnum.SupplierDetail, {
        title: 'Lieferant bearbeiten',
        onClose: () => setSelectedIds([]),
        supplierId: supplierToEdit,
      })
    }
  }, [openDetailView, supplierToEdit])

  return (
    <div>
      <Yalc2<SupplierListElement>
        data={props.suppliers}
        rowDefinition={rowDefinitions}
        onSelectionChanged={setSelectedIds}
        selectedIds={selectedIds}
        options={<Menu.Item onClick={props.onAdd}>Neuer Lieferant</Menu.Item>}
      />
    </div>
  )
}

const rowDefinitions: Yalc2RowDefinition<SupplierListElement> = {
  columns: [
    {
      title: 'Name',
      width: 260,
      cell: ({ item }) => <>{item.name}</>,
    },
    {
      title: 'Adresse',
      width: 260,
      cell: ({ item }) => <>{item.address}</>,
    },
  ],
}
