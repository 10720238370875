import React from 'react'
import { ApolloLink } from '@apollo/client'

export interface Config {
  [key: string]: any
}

type LinkFactoryFn = (config?: Config) => ApolloLink
type ComponentFactoryFn = (config?: Config) => React.ReactNode
interface Creator {
  key: string
  createLink: LinkFactoryFn
  createComponent: ComponentFactoryFn
  order: number
}

export interface LinkFactoryParams {
  key: string
  config?: Config
}

class ApolloLinkRegistry {
  creators: { [key: string]: Creator } = {}

  public add(key: string, creator: Omit<Creator, 'key'>) {
    this.creators[key] = { key, ...creator }
  }

  public get(key: string[]): Creator[]
  public get(key: string): Creator
  public get(keys: string | string[]): Creator | Creator[] {
    if (!Array.isArray(keys)) {
      const key = keys
      const creator = this.creators[key]
      if (!creator) {
        throw new Error(`ApolloLinkRegistry was unable to find link for key '${key}'`)
      }
      return creator
    }

    return keys.map((key) => this.get(key)).sort((a, b) => a.order - b.order)
  }

  public createLink(params: LinkFactoryParams[]): ApolloLink
  public createLink(param: LinkFactoryParams): ApolloLink
  public createLink(param: LinkFactoryParams[] | LinkFactoryParams) {
    const params = Array.isArray(param) ? param : [param]
    const creators = this.get(params.map((p) => p.key))
    return ApolloLink.from(
      creators.map((creator) => {
        const config = params.find((p) => p.key === creator.key)?.config
        return creator.createLink(config)
      })
    )
  }

  public createComponent(params: LinkFactoryParams[]): React.ReactNode
  public createComponent(param: LinkFactoryParams): React.ReactNode
  public createComponent(param: LinkFactoryParams[] | LinkFactoryParams): React.ReactNode {
    const params = Array.isArray(param) ? param : [param]
    const creators = this.get(params.map((p) => p.key))
    return (
      <>
        {creators.map((creator) => {
          const config = params.find((p) => p.key === creator.key)?.config
          return <React.Fragment key={creator.key}>{creator.createComponent(config)}</React.Fragment>
        })}
      </>
    )
  }
}

export const LinkRegistry = new ApolloLinkRegistry()
