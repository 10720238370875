import React, { ChangeEventHandler, CSSProperties, useMemo } from 'react'
import { Select } from '../../components'
import { AdminModelGroupListQueryResult } from '../../gql'
import {useFormatter} from "../../hooks";

export enum ModelReleaseYear {
  UNKNOWN = -1,
}

interface ModelGroupReleaseYearSelectProps {
  value: number
  onSelect: (year: number) => void
  label?: string
  hideLabel?: boolean
  group?: ArrayElement<AdminModelGroupListQueryResult['data']['model_groups']>
  name?: string
  style?: CSSProperties
}

export const ModelGroupReleaseYearSelect = (props: ModelGroupReleaseYearSelectProps) => {
  const options = useMemo(
    () =>
      props.group
        ? props.group.releaseYears.map(({ release_year: year }) => ({
            key: year.toString(),
            value: year.toString(),
            label: year !== ModelReleaseYear.UNKNOWN ? year.toString() : 'Unbekannt',
          }))
        : [{ key: 'null-option', value: '', label: 'Bitte wähle eine Modell Gruppe aus...', disabled: true }],
    [props.group]
  )
  const handleChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    props.onSelect(Number(evt.target.value))
  }

  const {formatModelReleaseYear} = useFormatter()

  return (
    <Select
      renderSelectValueItem={formatModelReleaseYear}
      value={props.value}
      name={props.name ?? 'modelGroupReleaseYearSelect'}
      onChange={handleChange}
      placeholder="Model Gruppe wählen..."
      style={props.style}
      label={props.hideLabel ? null : props.label ?? 'Release Jahr'}
    >
      {options.map((o) => (
        <Select.Option key={o.key} value={o.value} disabled={o.disabled}>
          {o.label}
        </Select.Option>
      ))}
    </Select>
  )
}
