import { LinkRegistry } from '../ApolloLinkRegistry'
import { MockDataLink } from './MockDataLink'

// Error: You are calling concat on a terminating link, which will have no effect
// https://www.apollographql.com/docs/react/api/link/introduction/
LinkRegistry.add('mock', {
  createLink: () => new MockDataLink(),
  createComponent: () => null,
  order: 50,
})

export { MockDataLink }
