import { Yalc2RowDefinition } from '../../../../components/Yalc2'
import {
  Dealer_Vehicle_Type_Enum,
  Import_Type_Enum,
  Ticket_State_Enum,
  TicketsListSubscriptionResult,
  useAssignTicketMutation,
  useMarkTicketAsDoneMutation,
  VehiclesMetafieldsAllBrandsQueryResult,
} from '../../../../gql'
import React, { useContext, useMemo } from 'react'
import { ListConfigContext } from '../../index'
import { useVehicleValidationSchema } from '../../../VehicleForm'
import { StatusLight } from '../../../StatusLight'
import { Yalc2Cell } from '../../../../components/Yalc2/cells'
import { Avatar, Chip, Icon, IconButton, MaxWidthText, Tooltip, Typo } from '../../../../components'
import { clipboard } from '../../../../utils/clipboard'
import { DealerVehicleStateAvatar } from '../../../DealerVehicleState/avatar'
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../../../hooks'
import { DateFns } from '../../../../utils'
import { PriorityIndicator } from '../../../PriorityIndicator'
import { useEzAuth } from '@lib/ezauth'
import clsx from 'clsx'

export type TicketItemList = TicketsListSubscriptionResult['data']['tickets']
export type TicketItem = ArrayElement<TicketItemList>
export const useRowDefinition = (
  columns: string[],
  metadata: { metafields: VehiclesMetafieldsAllBrandsQueryResult['data']['brand_vehicle_metafield'] }
): Yalc2RowDefinition<TicketItem> => {
  const { highlights } = useContext(ListConfigContext)
  const [assignTicket] = useAssignTicketMutation()
  const [markAsDone] = useMarkTicketAsDoneMutation()
  const [authState] = useEzAuth()
  const allColumns: Yalc2RowDefinition<TicketItem> = useMemo(() => {
    const metafields = metadata.metafields
    return {
      columns: [
        {
          key: 'vehicleImportState',
          width: 25,
          title: '',
          cell: function ImportState(props) {
            const vehicle = props.item.vehicle
            const { isValid } = useVehicleValidationSchema(
              props.item as any,
              vehicle.brand.id,
              { metafields },
              { autoRun: true }
            )
            const { isValid: isPartiallyValid } = useVehicleValidationSchema(
              props.item as any,
              vehicle.brand.id,
              { metafields, importType: Import_Type_Enum.Partial },
              { autoRun: true }
            )
            return (
              <StatusLight variant="filled" status={isValid ? 'success' : isPartiallyValid ? 'warning' : 'error'} />
            )
          },
        },
        {
          key: 'vehicleMarkedForDelete',
          width: 0,
          title: '',
          cell: function MarkedForDelete(props) {
            if (props.item.vehicle.marked_for_delete)
              return <div className="bg-background-default opacity-60 absolute left-0 h-full w-full"></div>
            return null
          },
        },
        {
          key: 'vehicleJobNumberAdvanced',
          title: 'Auftrags-Nr.',
          width: 120,
          cell: function JobNumberAdvanced(props) {
            const vehicle = props.item.vehicle
            return (
              <Yalc2Cell.SimpleCell>
                <MaxWidthText>{vehicle.job_number ?? '-'}</MaxWidthText>
                <Yalc2Cell.SubCell>
                  {Yalc2Cell.horizontal(
                    <>
                      <span style={{ marginRight: 6 }}>{`...${vehicle.vin.slice(-7)}`}</span>
                      <IconButton
                        aria-label="copy vin"
                        onClick={(event) => {
                          event.stopPropagation()
                          clipboard.write(vehicle.vin)
                        }}
                        size="small"
                        tooltip="FIN kopieren"
                        style={{ margin: -4 }}
                      >
                        <Icon name="copy" fontSize="small" style={{ height: 12 }} />
                      </IconButton>
                    </>
                  )}
                </Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'vehicleBrand',
          title: 'Marke',
          width: 100,
          cell: function BrandCell(props) {
            return <Yalc2Cell.SimpleCell>{props.item.vehicle.brand.name}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'vehicleModel',
          width: 230,
          title: 'Modell',
          cell: function ModellDescription(props) {
            const vehicle = props.item.vehicle
            const TooltipContent = (
              <>
                <p>{`Modell Gruppe: ${vehicle.model.modelGroup?.name ?? '-'}`}</p>
                <p>{`Modell Code: ${vehicle.model.sales_model_code}`}</p>
                <p>{`Modell Release Jahr: ${vehicle.model.model_group_release_year}`}</p>
              </>
            )
            return (
              <Tooltip content={TooltipContent}>
                <Yalc2Cell.SimpleCell>
                  <div className={'overflow-hidden overflow-ellipsis whitespace-nowrap'}>{vehicle.model_name}</div>
                  <Yalc2Cell.SubCell>{vehicle.model.sales_model_code}</Yalc2Cell.SubCell>
                </Yalc2Cell.SimpleCell>
              </Tooltip>
            )
          },
        },
        {
          key: 'vehicleState',
          title: 'Status',
          width: 80,
          cell: function StatusCell(props) {
            return (
              <div>
                <DealerVehicleStateAvatar state={props.item.vehicle.state} />
              </div>
            )
          },
        },
        {
          key: 'vehicleType',
          title: 'Typ',
          width: 60,
          cell: function VehicleType(props) {
            const vehicle = props.item.vehicle
            const abbr = useMemo(() => {
              switch (vehicle.type) {
                case Dealer_Vehicle_Type_Enum.DemonstrationCar:
                  return 'VFW'
                case Dealer_Vehicle_Type_Enum.NewCar:
                  return 'NW'
                case Dealer_Vehicle_Type_Enum.UsedCar:
                  return 'GW'
              }
            }, [vehicle.type])
            return (
              <div>
                <Typo variant="h6">{abbr}</Typo>
              </div>
            )
          },
        },
        {
          key: 'vehicleRegistrationState',
          title: 'Zulassungs Status',
          width: 150,
          cell: function RegistrationState(props) {
            const { t } = useTranslation()
            const vehicle = props.item.vehicle
            return (
              <Yalc2Cell.SimpleCell>
                {vehicle.registration_state ? t(`RegistrationState.${vehicle.registration_state}`) : '-'}
                <Yalc2Cell.SubCell>
                  <MaxWidthText>{`ZL Besitzer: ${vehicle.registration_owner ?? '-'}`}</MaxWidthText>
                </Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'vehicleFirstRegistration',
          title: 'EZL',
          width: 120,
          cell: function VehicleDatesCell(props) {
            const { formatDateString, formatNumber } = useFormatter()
            const vehicle = props.item.vehicle
            return (
              <Yalc2Cell.SimpleCell>
                {Yalc2Cell.horizontal(
                  <>
                    <span>{formatDateString(vehicle.first_registration_date)}</span>
                    <Icon
                      name="info"
                      color="inherit"
                      tooltip={`Garantiebeginn: ${vehicle.warranty_start}`}
                      fontSize="small"
                      style={{ height: 12 }}
                    />
                  </>
                )}
                <Yalc2Cell.SubCell>{formatNumber(vehicle.mileage)} km</Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'vehicleLicensePlate',
          title: 'KZ',
          width: 100,
          cell: function LicencePlateCell(props) {
            const { formatDateString } = useFormatter()
            const vehicle = props.item.vehicle
            return (
              <Yalc2Cell.SimpleCell>
                {vehicle.license_plate_number || '-'}
                <Yalc2Cell.SubCell>
                  {' '}
                  {vehicle.min_holding_period
                    ? formatDateString(
                        DateFns.addDays(new Date(vehicle.first_registration_date), vehicle.min_holding_period ?? 0)
                      )
                    : '-'}
                </Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'vehicleColor',
          title: 'Farbe',
          width: 200,
          cell: function ModelCell(props) {
            const vehicle = props.item.vehicle
            return (
              <Yalc2Cell.SimpleCell>
                {`${vehicle.color_exterior_name || '-'} (${vehicle.color_exterior_code || '-'})`}
                <Yalc2Cell.SubCell>
                  {`${vehicle.color_interior_name || '-'} (${vehicle.color_interior_code || '-'})`}
                </Yalc2Cell.SubCell>
              </Yalc2Cell.SimpleCell>
            )
          },
        },

        {
          key: 'priority',
          title: 'Priorität',
          sortColumn: 'priority',
          width: 100,
          cell: function PriorityCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                <PriorityIndicator priority={props.item.priority} />
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'state',
          title: 'Status',
          sortColumn: 'state',
          width: 150,
          cell: function StateCell(props) {
            const { t } = useTranslation()
            return <Yalc2Cell.SimpleCell>{t(`TicketState.${props.item.state}`)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'title',
          title: 'Titel',
          sortColumn: 'title',
          width: 150,
          cell: function TitleCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.title}</Yalc2Cell.MaxWidthCell>
          },
        },
        {
          key: 'description',
          title: 'Beschreibung',
          sortColumn: 'description',
          width: 200,
          cell: function DescriptionCell(props) {
            return <Yalc2Cell.MaxWidthCell>{props.item.description}</Yalc2Cell.MaxWidthCell>
          },
        },
        {
          key: 'department',
          title: 'Bereich',
          sortColumn: 'department_id',
          width: 200,
          cell: function DepartmentCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.department ? <Chip>{props.item.department?.name}</Chip> : '-'}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'location',
          title: 'Standort',
          sortColumn: 'location_id',
          width: 200,
          cell: function LocationCell(props) {
            return (
              <Yalc2Cell.SimpleCell>
                {props.item.location ? <Chip>{props.item.location?.short_name}</Chip> : '-'}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'createdAt',
          title: 'Erstellt am',
          sortColumn: 'created_at',
          width: 150,
          cell: function CreatedAtCell(props) {
            const { formatDateTimeString } = useFormatter()
            return <Yalc2Cell.SimpleCell>{formatDateTimeString(props.item.created_at)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'assignedTo',
          title: 'Zugewiesen zu',
          sortColumn: 'assignee_id',
          width: 150,
          cell: function AssigneeCell(props) {
            const handleAssignToMe = async () => {
              await assignTicket({ variables: { ticketId: props.item.id, assigneeId: authState.user.id } })
            }
            const abbreviationFallback = props.item.assignee?.email
              .split('@')[0]
              .split('.')
              .map((part) => part[0])
              .join()
              .toLocaleUpperCase()

            return (
              <Yalc2Cell.SimpleCell>
                {Yalc2Cell.horizontal(
                  <>
                    {props.item.assignee ? (
                      <Avatar
                        alt={`Abkürzung: ${props.item.assignee?.abbreviation || abbreviationFallback}`}
                        className="bg-primary-main"
                      >
                        {props.item.assignee?.abbreviation || abbreviationFallback}
                      </Avatar>
                    ) : (
                      '-'
                    )}
                    <IconButton
                      tooltip={'Mir zuweisen'}
                      aria-label={'assign ticket to me'}
                      size={'small'}
                      color={'inherit'}
                      onClick={handleAssignToMe}
                    >
                      <Icon name={'assign'} />
                    </IconButton>
                  </>
                )}
              </Yalc2Cell.SimpleCell>
            )
          },
        },
        {
          key: 'dueTo',
          title: 'Zu erledigen bis',
          sortColumn: 'due_to',
          width: 150,
          cell: function DueToCell(props) {
            const { formatDateString } = useFormatter()
            return <Yalc2Cell.SimpleCell>{formatDateString(props.item.due_to)}</Yalc2Cell.SimpleCell>
          },
        },
        {
          key: 'quickActions',
          title: 'Optionen',
          width: 100,
          cell: function MarkAsDoneCell(props) {
            if (props.item.state === Ticket_State_Enum.Done) return null
            const handleClick: React.MouseEventHandler<HTMLButtonElement> = async (evt) => {
              evt.stopPropagation()
              await markAsDone({ variables: { id: props.item.id } })
            }
            return (
              <Yalc2Cell.SimpleCell className={clsx('hidden items-end group-hover:flex')}>
                <IconButton
                  aria-label={'Mark ticket as done'}
                  onClick={handleClick}
                  tooltip={'Markiere die Aufgabe als "Erledigt"'}
                >
                  <Icon name={'check'}></Icon>
                </IconButton>
              </Yalc2Cell.SimpleCell>
            )
          },
        },
      ],
    }
  }, [assignTicket, authState.user.id, markAsDone, metadata.metafields])
  return useMemo(
    () => ({
      columns: allColumns.columns.filter((col) => columns?.includes(col.key)),
      highlights,
    }),
    [allColumns.columns, columns, highlights]
  )
}
