import { useEffect, useState } from 'react'

export const useDebounce = <T>(value: T, delay: number = 150) => {
  const [debounced, setDebounced] = useState<T>(value)

  useEffect(() => {
    let cancelled = false
    setTimeout(() => {
      if (!cancelled) {
        setDebounced(value)
      }
    }, delay)
    return () => (cancelled = true)
  }, [delay, value])

  return debounced
}
