interface GraphQLResultObject {
  __typename?: string
}
type OmitTypename<T extends GraphQLResultObject> = Omit<T, '__typename'>

export function removeTypename<T extends GraphQLResultObject>(array: T[], depth?: number): OmitTypename<T>[]
export function removeTypename<T extends GraphQLResultObject>(obj: T, depth?: number): OmitTypename<T>
export function removeTypename<T extends GraphQLResultObject>(
  objOrArray: T | T[],
  depth: number = 5
): OmitTypename<T> | OmitTypename<T>[] {
  if (depth < 0) {
    return objOrArray
  }
  if (Array.isArray(objOrArray)) {
    return objOrArray.map((item) => removeTypename(item, depth - 1)) as T[]
  } else {
    const { __typename, ...withoutTypename } = objOrArray

    Object.keys(withoutTypename).forEach((key) => {
      if (
        (withoutTypename[key] &&
          typeof withoutTypename[key] === 'object' &&
          // important check so it does not get triggered with `File` types or something like that
          Object.prototype.toString.call(withoutTypename[key]) === '[object Object]') ||
        Array.isArray(withoutTypename[key])
      ) {
        withoutTypename[key] = removeTypename(withoutTypename[key], depth - 1)
      }
    })
    return withoutTypename
  }
}
