import React, { useState } from 'react'
import {
  useAdminBrandListSubscription,
  Brand_Bool_Exp,
  AdminBrandListSubscriptionResult
} from 'gql'
import { Typo, List, ListItem, SiteMetadata } from 'components'
import * as L from 'layouts'
import { AdminBrandDetail } from 'containers/AdminBrandDetail'
import { Badge } from 'components/Badge'
import { sortBy } from 'ramda'

export const AdminBrandsPage = () => {
  const [brandId, setBrandId] = useState<string>()

  const handleBrandClick = (brandId: string) => {
    setBrandId(brandId)
  }

  return (
    <>
      <SiteMetadata title="Marken Verwalten" />
      <div style={{ display: 'flex', flexDirection: 'row', gap: 24 }}>
        <div style={{ width: 240 }}>
          <L.Vertical>
            <div>
              <Typo variant="h3" decorator>
                {'Hausmarken'}
              </Typo>
              <BrandList
                selectedBrandId={brandId}
                onSelect={handleBrandClick}
                filter={{ is_house_brand: { _eq: true } }}
              />
            </div>
            <div>
              <Typo variant="h3" decorator>
                {'Fremdmarken'}
              </Typo>
              <BrandList
                selectedBrandId={brandId}
                onSelect={handleBrandClick}
                filter={{ is_house_brand: { _eq: false } }}
              />
            </div>
          </L.Vertical>
        </div>
        <div style={{ flexGrow: 1 }}>
          <div>
            <Typo variant="h3" decorator>
              {'Details'}
            </Typo>
            {brandId && <AdminBrandDetail brandId={brandId} />}
          </div>
        </div>
      </div>
    </>
  )
}

interface BrandListProps {
  selectedBrandId: string
  onSelect: (brandId: string) => void
  filter?: Brand_Bool_Exp
}

type BrandArray = AdminBrandListSubscriptionResult['data']['brands']

const BrandList: React.FC<BrandListProps> = (props) => {
  const query = useAdminBrandListSubscription({ variables: { where: props.filter } })
  const brands = sortBy<ArrayElement<BrandArray>>((b) => b.name, query.data?.brands ?? [])
  const loading = query.loading

  const selectedBrandIndex = props.selectedBrandId

  return (
    <List loading={loading} style={{ minHeight: 260 }}>
      {brands.map((brand) => {
        // TODO: Add unassignedActiveModelCount to the brand schema
        const unassignedModels = 0
        return (
          <ListItem selected={brand.id === selectedBrandIndex} onClick={() => props.onSelect(brand.id)} key={brand.id}>
            <div style={{ flexGrow: 1 }}>{brand.name}</div>
            <Badge content={unassignedModels} color="danger" max={99}>
              <div style={{ width: 36 }} />
            </Badge>
            <div></div>
          </ListItem>
        )
      })}
    </List>
  )
}
