import { Button, RadioGroup, Tooltip, Typo, useAlert } from 'components'
import {
  Boolean_Comparison_Exp,
  Date_Comparison_Exp,
  Import_Job_Status_Enum,
  Int_Comparison_Exp,
  String_Comparison_Exp,
  Timestamptz_Comparison_Exp,
  useCreateVehicleImportJobMutation,
  useImportJobUploadUrlQuery,
  Vehicle_Bool_Exp,
} from 'gql'
import * as L from 'layouts'
import React, { useContext, useEffect, useState } from 'react'
import { selectFile } from 'utils/selectFile'
import { ExcelImportContext, ExcelImportEntity } from '.'
import { ListConfigContext } from '../ListConfig'
import { uploadToTmp } from '../../utils/uploadToTmp'

export interface ChooseFileProps {
  mappingKeys: { key: keyof ExcelImportEntity; value: string }[]
  createImportJob: ReturnType<typeof useCreateVehicleImportJobMutation>
  entityNamePlural: string
}

export const ChooseFile = (props: ChooseFileProps) => {
  const [matching_field, setMatchingField] = useState<string>()

  const uploadUrlQuery = useImportJobUploadUrlQuery()
  const [createImportJob] = props.createImportJob
  const { state, dispatch, importJob } = useContext(ExcelImportContext)
  const { filter } = useContext(ListConfigContext)

  useEffect(() => {
    dispatch({ type: 'reset' })
    uploadUrlQuery.refetch()
    // eslint-disable-next-line
  }, [dispatch, filter])

  const [alert] = useAlert()

  useEffect(() => {
    if (props.mappingKeys.length === 1 && !importJob?.matching_field) {
      setMatchingField(props.mappingKeys[0].key)
    }
  }, [importJob?.matching_field, props.mappingKeys])

  const handleFileUpload = async () => {
    const responseData = uploadUrlQuery.data?.importJobUploadUrl
    if (!responseData) {
      alert.error('Something went wrong while uploading an .xlsx file', new Error('Upload error'))
      return
    }
    const files = await selectFile({ multiple: false, accept: '.xlsx,.xls' })
    const file = files[0]
    await uploadToTmp(responseData.uploadUrl, file)

    await createImportJob({
      variables: {
        id: state.importJobId,
        fileKey: responseData.key,
        matching_field,
        filter: JSON.stringify(prismaFilterPipe(filter)),
        templateId: state.importTemplateId,
        fileName: file.name,
      },
    })
  }

  return (
    <L.Vertical>
      <Typo variant={'h2'}>{'1. Datei auswählen'}</Typo>
      {props.mappingKeys.length > 1 && (
        <RadioGroup
          value={matching_field || ''}
          onChange={(evt) => setMatchingField(evt.target.value)}
          name="mapping-key"
          label={`Aktualisiere die ${props.entityNamePlural} mit Hilfe eines .xlsx Files. Du kannst unten auswählen nach welchen Option die bestehenden ${props.entityNamePlural} eindeutig zugeordnet werden.`}
          aria-label="choose matching key"
        >
          {props.mappingKeys.map((mk) => (
            <RadioGroup.Option
              key={`mk-${mk.key}`}
              disabled={!!importJob?.upload_file_name}
              value={mk.key}
              label={`Aktualisiere ${props.entityNamePlural} laut ihren ${mk.value}`}
            />
          ))}
        </RadioGroup>
      )}

      <L.Horizontal spacing={1}>
        <Button disabled={!matching_field || !!importJob?.upload_file_name} onClick={handleFileUpload}>
          {'Datei auswählen'}
        </Button>
        <div>{importJob?.upload_file_name}</div>
      </L.Horizontal>
      <L.Horizontal>
        <Tooltip content={'Andere Datei auswählen, oder andere Zuordnungsoption.'}>
          <Button
            disabled={
              !importJob?.upload_file_name ||
              importJob.status === Import_Job_Status_Enum.PreviewInProgress ||
              importJob.status === Import_Job_Status_Enum.ExecutionInProgress
            }
            onClick={() => dispatch({ type: 'reset' })}
          >
            Abbrechen
          </Button>
        </Tooltip>
      </L.Horizontal>
    </L.Vertical>
  )
}

export const prismaFilterPipe = (filterStr: string) => {
  const filter: Vehicle_Bool_Exp = JSON.parse(filterStr)
  const AND = filter._and.map((expression) => {
    if (!expression._or) {
      const field = Object.keys(expression)[0]
      return { [field]: mapComparisonExpression(expression[field]) }
    } else {
      const OR = expression._or.map((exp) => {
        if (!exp._and) {
          const field = Object.keys(exp)[0]
          return { [field]: mapComparisonExpression(exp[field]) }
        } else {
          const AND = exp._and.map((e) => {
            const field = Object.keys(e)[0]
            return { [field]: mapComparisonExpression(e[field]) }
          })
          return { AND }
        }
      })
      return { OR }
    }
  })
  return { AND }
}

const mapComparisonExpression = (
  expression:
    | Date_Comparison_Exp
    | String_Comparison_Exp
    | Timestamptz_Comparison_Exp
    | Boolean_Comparison_Exp
    | Int_Comparison_Exp
) => {
  const operator = Object.keys(expression)[0]
  if (operator === '_is_null') {
    return expression[operator] ? { equals: null } : { not: { equals: null } }
  } else {
    return { [operatorRepresentationMap[operator]]: expression[operator] }
  }
}

const operatorRepresentationMap = {
  _eq: 'equals',
  _gt: 'gt',
  _lt: 'lt',
}
