import React, { createContext, useEffect, useMemo, useState } from 'react'
import { ListManipulationUnitGroupProps } from '../ListManipulation/unit'

export interface RowHighlight<T> {
  filter: (item: T) => boolean
  filterHasuraRepresentation: string
  color?: ListManipulationUnitGroupProps['color']
  entryField: string
}

export interface ListConfigContextProps<T extends { id: string }> {
  filter: string
  setFilter: React.Dispatch<React.SetStateAction<string>>
  order: { col: keyof T; direction: 'asc' | 'desc' }
  setOrder: React.Dispatch<React.SetStateAction<{ col: keyof T; direction: 'asc' | 'desc' }>>
  highlights: RowHighlight<T>[]
  setHighlights: React.Dispatch<React.SetStateAction<RowHighlight<T>[]>>
  groupBy: string
  setGroupBy: React.Dispatch<React.SetStateAction<string>>
  columns: string[]
  type: string
}

export const ListConfigContext = createContext<ListConfigContextProps<any>>({
  filter: '',
  setFilter: () => {},
  order: null,
  setOrder: () => {},
  highlights: [],
  setHighlights: () => {},
  groupBy: '',
  setGroupBy: () => {},
  columns: [],
  type: '',
})

export interface ListConfigInterface<T extends { id: string }> {
  name: string
  description?: string
  id: string
  filter: string
  columns: string[]
  grouping: string
  order?: { col: keyof T; direction: 'asc' | 'desc' }
  highlights: RowHighlight<T>[] | undefined
}

export const ListConfig = <T extends { id: string }>(
  props: Pick<ListConfigInterface<T>, 'filter' | 'columns' | 'grouping' | 'highlights' | 'order'> & {
    type: string
    children: React.ReactNode
  }
) => {
  const [filter, setFilter] = useState<string>(props.filter)
  const [highlights, setHighlights] = useState<RowHighlight<T>[]>(props.highlights)
  const [groupBy, setGroupBy] = useState<string>(props.grouping)
  const [order, setOrder] = useState<{ col: keyof T; direction: 'asc' | 'desc' }>()

  useEffect(() => {
    setFilter(props.filter)
    setHighlights(props.highlights)
    setGroupBy(props.grouping)
    setOrder(props.order)
  }, [props.filter, props.grouping, props.highlights, props.type, props.order])

  const value = useMemo(
    () => ({
      filter,
      setFilter,
      highlights,
      setHighlights,
      groupBy,
      setGroupBy,
      order,
      setOrder,
      columns: props.columns,
      type: props.type,
    }),
    [filter, groupBy, highlights, props.columns, props.type, order]
  )

  return <ListConfigContext.Provider value={value}>{props.children}</ListConfigContext.Provider>
}
