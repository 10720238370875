import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
}

/** columns and relationships of "addresses" */
export interface Address {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  /** A computed field, executes function "address_full_address" */
  fullAddress?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  street?: Maybe<Scalars['String']>;
  streetNo?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
}

/** aggregated selection of "addresses" */
export interface Address_Aggregate {
  __typename?: 'Address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
}

/** aggregate fields of "addresses" */
export interface Address_Aggregate_Fields {
  __typename?: 'Address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
}


/** aggregate fields of "addresses" */
export interface Address_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Address_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "addresses". All fields are combined with a logical 'AND'. */
export interface Address_Bool_Exp {
  _and?: Maybe<Array<Address_Bool_Exp>>;
  _not?: Maybe<Address_Bool_Exp>;
  _or?: Maybe<Array<Address_Bool_Exp>>;
  city?: Maybe<String_Comparison_Exp>;
  countryCode?: Maybe<String_Comparison_Exp>;
  fullAddress?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  street?: Maybe<String_Comparison_Exp>;
  streetNo?: Maybe<String_Comparison_Exp>;
  zipCode?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "addresses" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddresssesPkey = 'addressses_pkey'
}

/** input type for inserting data into table "addresses" */
export interface Address_Insert_Input {
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  street?: Maybe<Scalars['String']>;
  streetNo?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Address_Max_Fields {
  __typename?: 'Address_max_fields';
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  /** A computed field, executes function "address_full_address" */
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  street?: Maybe<Scalars['String']>;
  streetNo?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Address_Min_Fields {
  __typename?: 'Address_min_fields';
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  /** A computed field, executes function "address_full_address" */
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  street?: Maybe<Scalars['String']>;
  streetNo?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "addresses" */
export interface Address_Mutation_Response {
  __typename?: 'Address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
}

/** input type for inserting object relation for remote table "addresses" */
export interface Address_Obj_Rel_Insert_Input {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Address_On_Conflict>;
}

/** on_conflict condition type for table "addresses" */
export interface Address_On_Conflict {
  constraint: Address_Constraint;
  update_columns: Array<Address_Update_Column>;
  where?: Maybe<Address_Bool_Exp>;
}

/** Ordering options when selecting data from "addresses". */
export interface Address_Order_By {
  city?: Maybe<Order_By>;
  countryCode?: Maybe<Order_By>;
  fullAddress?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
  streetNo?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
}

/** primary key columns input for table: addresses */
export interface Address_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "addresses" */
export enum Address_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  Id = 'id',
  /** column name */
  Street = 'street',
  /** column name */
  StreetNo = 'streetNo',
  /** column name */
  ZipCode = 'zipCode'
}

/** input type for updating data in table "addresses" */
export interface Address_Set_Input {
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  street?: Maybe<Scalars['String']>;
  streetNo?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "Address" */
export interface Address_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Address_Stream_Cursor_Value_Input {
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  street?: Maybe<Scalars['String']>;
  streetNo?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
}

/** update columns of table "addresses" */
export enum Address_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  Id = 'id',
  /** column name */
  Street = 'street',
  /** column name */
  StreetNo = 'streetNo',
  /** column name */
  ZipCode = 'zipCode'
}

export interface Address_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Bool_Exp;
}

/** columns and relationships of "after_sales_potentials" */
export interface AfterSalesPotential {
  __typename?: 'AfterSalesPotential';
  /** An array relationship */
  contact_notes: Array<ContactNote>;
  /** An aggregate relationship */
  contact_notes_aggregate: ContactNote_Aggregate;
  /** An object relationship */
  customer: Customer;
  customer_city?: Maybe<Scalars['String']>;
  customer_id: Scalars['uuid'];
  customer_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  customer_vehicle?: Maybe<CustomerVehicle>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  customer_zip_code?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  last_inspection_sticker_date?: Maybe<Scalars['date']>;
  /** A computed field, executes function "last_after_sales_potential_note" */
  last_note?: Maybe<Scalars['String']>;
  last_service_date?: Maybe<Scalars['date']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location_id: Scalars['uuid'];
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  month: Scalars['Int'];
  status: After_Sales_Status_Enum;
  task_category: Task_Category_Enum;
  /** An object relationship */
  vehicle?: Maybe<Vehicle>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
  year: Scalars['Int'];
}


/** columns and relationships of "after_sales_potentials" */
export interface AfterSalesPotentialContact_NotesArgs {
  distinct_on?: Maybe<Array<ContactNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactNote_Order_By>>;
  where?: Maybe<ContactNote_Bool_Exp>;
}


/** columns and relationships of "after_sales_potentials" */
export interface AfterSalesPotentialContact_Notes_AggregateArgs {
  distinct_on?: Maybe<Array<ContactNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactNote_Order_By>>;
  where?: Maybe<ContactNote_Bool_Exp>;
}

/** aggregated selection of "after_sales_potentials" */
export interface AfterSalesPotential_Aggregate {
  __typename?: 'AfterSalesPotential_aggregate';
  aggregate?: Maybe<AfterSalesPotential_Aggregate_Fields>;
  nodes: Array<AfterSalesPotential>;
}

export interface AfterSalesPotential_Aggregate_Bool_Exp {
  count?: Maybe<AfterSalesPotential_Aggregate_Bool_Exp_Count>;
}

export interface AfterSalesPotential_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<AfterSalesPotential_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<AfterSalesPotential_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "after_sales_potentials" */
export interface AfterSalesPotential_Aggregate_Fields {
  __typename?: 'AfterSalesPotential_aggregate_fields';
  avg?: Maybe<AfterSalesPotential_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AfterSalesPotential_Max_Fields>;
  min?: Maybe<AfterSalesPotential_Min_Fields>;
  stddev?: Maybe<AfterSalesPotential_Stddev_Fields>;
  stddev_pop?: Maybe<AfterSalesPotential_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AfterSalesPotential_Stddev_Samp_Fields>;
  sum?: Maybe<AfterSalesPotential_Sum_Fields>;
  var_pop?: Maybe<AfterSalesPotential_Var_Pop_Fields>;
  var_samp?: Maybe<AfterSalesPotential_Var_Samp_Fields>;
  variance?: Maybe<AfterSalesPotential_Variance_Fields>;
}


/** aggregate fields of "after_sales_potentials" */
export interface AfterSalesPotential_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<AfterSalesPotential_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "after_sales_potentials" */
export interface AfterSalesPotential_Aggregate_Order_By {
  avg?: Maybe<AfterSalesPotential_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<AfterSalesPotential_Max_Order_By>;
  min?: Maybe<AfterSalesPotential_Min_Order_By>;
  stddev?: Maybe<AfterSalesPotential_Stddev_Order_By>;
  stddev_pop?: Maybe<AfterSalesPotential_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<AfterSalesPotential_Stddev_Samp_Order_By>;
  sum?: Maybe<AfterSalesPotential_Sum_Order_By>;
  var_pop?: Maybe<AfterSalesPotential_Var_Pop_Order_By>;
  var_samp?: Maybe<AfterSalesPotential_Var_Samp_Order_By>;
  variance?: Maybe<AfterSalesPotential_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "after_sales_potentials" */
export interface AfterSalesPotential_Arr_Rel_Insert_Input {
  data: Array<AfterSalesPotential_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<AfterSalesPotential_On_Conflict>;
}

/** aggregate avg on columns */
export interface AfterSalesPotential_Avg_Fields {
  __typename?: 'AfterSalesPotential_avg_fields';
  mileage?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "after_sales_potentials" */
export interface AfterSalesPotential_Avg_Order_By {
  mileage?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "after_sales_potentials". All fields are combined with a logical 'AND'. */
export interface AfterSalesPotential_Bool_Exp {
  _and?: Maybe<Array<AfterSalesPotential_Bool_Exp>>;
  _not?: Maybe<AfterSalesPotential_Bool_Exp>;
  _or?: Maybe<Array<AfterSalesPotential_Bool_Exp>>;
  contact_notes?: Maybe<ContactNote_Bool_Exp>;
  contact_notes_aggregate?: Maybe<ContactNote_Aggregate_Bool_Exp>;
  customer?: Maybe<Customer_Bool_Exp>;
  customer_city?: Maybe<String_Comparison_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  customer_name?: Maybe<String_Comparison_Exp>;
  customer_vehicle?: Maybe<CustomerVehicle_Bool_Exp>;
  customer_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  customer_zip_code?: Maybe<String_Comparison_Exp>;
  first_registration_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_inspection_sticker_date?: Maybe<Date_Comparison_Exp>;
  last_note?: Maybe<String_Comparison_Exp>;
  last_service_date?: Maybe<Date_Comparison_Exp>;
  license_plate_number?: Maybe<String_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  mileage?: Maybe<Int_Comparison_Exp>;
  mileage_date?: Maybe<Date_Comparison_Exp>;
  month?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<After_Sales_Status_Enum_Comparison_Exp>;
  task_category?: Maybe<Task_Category_Enum_Comparison_Exp>;
  vehicle?: Maybe<Vehicle_Bool_Exp>;
  vehicle_business_case_number?: Maybe<Int_Comparison_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_version?: Maybe<Int_Comparison_Exp>;
  warranty_end?: Maybe<Date_Comparison_Exp>;
  warranty_start?: Maybe<Date_Comparison_Exp>;
  year?: Maybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "after_sales_potentials" */
export enum AfterSalesPotential_Constraint {
  /** unique or primary key constraint on columns "id" */
  AfterSalesPotentialPkey = 'after_sales_potential_pkey'
}

/** input type for incrementing numeric columns in table "after_sales_potentials" */
export interface AfterSalesPotential_Inc_Input {
  mileage?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "after_sales_potentials" */
export interface AfterSalesPotential_Insert_Input {
  contact_notes?: Maybe<ContactNote_Arr_Rel_Insert_Input>;
  customer?: Maybe<Customer_Obj_Rel_Insert_Input>;
  customer_city?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_vehicle?: Maybe<CustomerVehicle_Obj_Rel_Insert_Input>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  customer_zip_code?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  last_inspection_sticker_date?: Maybe<Scalars['date']>;
  last_service_date?: Maybe<Scalars['date']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  month?: Maybe<Scalars['Int']>;
  status?: Maybe<After_Sales_Status_Enum>;
  task_category?: Maybe<Task_Category_Enum>;
  vehicle?: Maybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
  year?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface AfterSalesPotential_Max_Fields {
  __typename?: 'AfterSalesPotential_max_fields';
  customer_city?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  customer_zip_code?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  last_inspection_sticker_date?: Maybe<Scalars['date']>;
  /** A computed field, executes function "last_after_sales_potential_note" */
  last_note?: Maybe<Scalars['String']>;
  last_service_date?: Maybe<Scalars['date']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  month?: Maybe<Scalars['Int']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
  year?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "after_sales_potentials" */
export interface AfterSalesPotential_Max_Order_By {
  customer_city?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_name?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  customer_zip_code?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_inspection_sticker_date?: Maybe<Order_By>;
  last_service_date?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface AfterSalesPotential_Min_Fields {
  __typename?: 'AfterSalesPotential_min_fields';
  customer_city?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  customer_zip_code?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  last_inspection_sticker_date?: Maybe<Scalars['date']>;
  /** A computed field, executes function "last_after_sales_potential_note" */
  last_note?: Maybe<Scalars['String']>;
  last_service_date?: Maybe<Scalars['date']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  month?: Maybe<Scalars['Int']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
  year?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "after_sales_potentials" */
export interface AfterSalesPotential_Min_Order_By {
  customer_city?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_name?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  customer_zip_code?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_inspection_sticker_date?: Maybe<Order_By>;
  last_service_date?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

/** response of any mutation on the table "after_sales_potentials" */
export interface AfterSalesPotential_Mutation_Response {
  __typename?: 'AfterSalesPotential_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AfterSalesPotential>;
}

/** on_conflict condition type for table "after_sales_potentials" */
export interface AfterSalesPotential_On_Conflict {
  constraint: AfterSalesPotential_Constraint;
  update_columns: Array<AfterSalesPotential_Update_Column>;
  where?: Maybe<AfterSalesPotential_Bool_Exp>;
}

/** Ordering options when selecting data from "after_sales_potentials". */
export interface AfterSalesPotential_Order_By {
  contact_notes_aggregate?: Maybe<ContactNote_Aggregate_Order_By>;
  customer?: Maybe<Customer_Order_By>;
  customer_city?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_name?: Maybe<Order_By>;
  customer_vehicle?: Maybe<CustomerVehicle_Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  customer_zip_code?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_inspection_sticker_date?: Maybe<Order_By>;
  last_note?: Maybe<Order_By>;
  last_service_date?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  task_category?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicle_Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

/** primary key columns input for table: after_sales_potentials */
export interface AfterSalesPotential_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "after_sales_potentials" */
export enum AfterSalesPotential_Select_Column {
  /** column name */
  CustomerCity = 'customer_city',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  CustomerZipCode = 'customer_zip_code',
  /** column name */
  FirstRegistrationDate = 'first_registration_date',
  /** column name */
  Id = 'id',
  /** column name */
  LastInspectionStickerDate = 'last_inspection_sticker_date',
  /** column name */
  LastServiceDate = 'last_service_date',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  Month = 'month',
  /** column name */
  Status = 'status',
  /** column name */
  TaskCategory = 'task_category',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version',
  /** column name */
  WarrantyEnd = 'warranty_end',
  /** column name */
  WarrantyStart = 'warranty_start',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "after_sales_potentials" */
export interface AfterSalesPotential_Set_Input {
  customer_city?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  customer_zip_code?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  last_inspection_sticker_date?: Maybe<Scalars['date']>;
  last_service_date?: Maybe<Scalars['date']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  month?: Maybe<Scalars['Int']>;
  status?: Maybe<After_Sales_Status_Enum>;
  task_category?: Maybe<Task_Category_Enum>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
  year?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface AfterSalesPotential_Stddev_Fields {
  __typename?: 'AfterSalesPotential_stddev_fields';
  mileage?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "after_sales_potentials" */
export interface AfterSalesPotential_Stddev_Order_By {
  mileage?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface AfterSalesPotential_Stddev_Pop_Fields {
  __typename?: 'AfterSalesPotential_stddev_pop_fields';
  mileage?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "after_sales_potentials" */
export interface AfterSalesPotential_Stddev_Pop_Order_By {
  mileage?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface AfterSalesPotential_Stddev_Samp_Fields {
  __typename?: 'AfterSalesPotential_stddev_samp_fields';
  mileage?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "after_sales_potentials" */
export interface AfterSalesPotential_Stddev_Samp_Order_By {
  mileage?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

/** Streaming cursor of the table "AfterSalesPotential" */
export interface AfterSalesPotential_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: AfterSalesPotential_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface AfterSalesPotential_Stream_Cursor_Value_Input {
  customer_city?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  customer_zip_code?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  last_inspection_sticker_date?: Maybe<Scalars['date']>;
  last_service_date?: Maybe<Scalars['date']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  month?: Maybe<Scalars['Int']>;
  status?: Maybe<After_Sales_Status_Enum>;
  task_category?: Maybe<Task_Category_Enum>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
  year?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface AfterSalesPotential_Sum_Fields {
  __typename?: 'AfterSalesPotential_sum_fields';
  mileage?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "after_sales_potentials" */
export interface AfterSalesPotential_Sum_Order_By {
  mileage?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

/** update columns of table "after_sales_potentials" */
export enum AfterSalesPotential_Update_Column {
  /** column name */
  CustomerCity = 'customer_city',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  CustomerZipCode = 'customer_zip_code',
  /** column name */
  FirstRegistrationDate = 'first_registration_date',
  /** column name */
  Id = 'id',
  /** column name */
  LastInspectionStickerDate = 'last_inspection_sticker_date',
  /** column name */
  LastServiceDate = 'last_service_date',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  Month = 'month',
  /** column name */
  Status = 'status',
  /** column name */
  TaskCategory = 'task_category',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version',
  /** column name */
  WarrantyEnd = 'warranty_end',
  /** column name */
  WarrantyStart = 'warranty_start',
  /** column name */
  Year = 'year'
}

export interface AfterSalesPotential_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<AfterSalesPotential_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AfterSalesPotential_Set_Input>;
  /** filter the rows which have to be updated */
  where: AfterSalesPotential_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface AfterSalesPotential_Var_Pop_Fields {
  __typename?: 'AfterSalesPotential_var_pop_fields';
  mileage?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "after_sales_potentials" */
export interface AfterSalesPotential_Var_Pop_Order_By {
  mileage?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface AfterSalesPotential_Var_Samp_Fields {
  __typename?: 'AfterSalesPotential_var_samp_fields';
  mileage?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "after_sales_potentials" */
export interface AfterSalesPotential_Var_Samp_Order_By {
  mileage?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface AfterSalesPotential_Variance_Fields {
  __typename?: 'AfterSalesPotential_variance_fields';
  mileage?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "after_sales_potentials" */
export interface AfterSalesPotential_Variance_Order_By {
  mileage?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
}

export enum AfterSalesPotentialsExportType {
  LetterList = 'LETTER_LIST',
  LetterListV2 = 'LETTER_LIST_V2'
}

/** columns and relationships of "customer_appointments" */
export interface Appointment {
  __typename?: 'Appointment';
  bring_at?: Maybe<Scalars['date']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  return_at?: Maybe<Scalars['date']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "customer_appointments" */
export interface Appointment_Aggregate {
  __typename?: 'Appointment_aggregate';
  aggregate?: Maybe<Appointment_Aggregate_Fields>;
  nodes: Array<Appointment>;
}

export interface Appointment_Aggregate_Bool_Exp {
  count?: Maybe<Appointment_Aggregate_Bool_Exp_Count>;
}

export interface Appointment_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Appointment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Appointment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "customer_appointments" */
export interface Appointment_Aggregate_Fields {
  __typename?: 'Appointment_aggregate_fields';
  avg?: Maybe<Appointment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Appointment_Max_Fields>;
  min?: Maybe<Appointment_Min_Fields>;
  stddev?: Maybe<Appointment_Stddev_Fields>;
  stddev_pop?: Maybe<Appointment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Appointment_Stddev_Samp_Fields>;
  sum?: Maybe<Appointment_Sum_Fields>;
  var_pop?: Maybe<Appointment_Var_Pop_Fields>;
  var_samp?: Maybe<Appointment_Var_Samp_Fields>;
  variance?: Maybe<Appointment_Variance_Fields>;
}


/** aggregate fields of "customer_appointments" */
export interface Appointment_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Appointment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "customer_appointments" */
export interface Appointment_Aggregate_Order_By {
  avg?: Maybe<Appointment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Appointment_Max_Order_By>;
  min?: Maybe<Appointment_Min_Order_By>;
  stddev?: Maybe<Appointment_Stddev_Order_By>;
  stddev_pop?: Maybe<Appointment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Appointment_Stddev_Samp_Order_By>;
  sum?: Maybe<Appointment_Sum_Order_By>;
  var_pop?: Maybe<Appointment_Var_Pop_Order_By>;
  var_samp?: Maybe<Appointment_Var_Samp_Order_By>;
  variance?: Maybe<Appointment_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "customer_appointments" */
export interface Appointment_Arr_Rel_Insert_Input {
  data: Array<Appointment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Appointment_On_Conflict>;
}

/** aggregate avg on columns */
export interface Appointment_Avg_Fields {
  __typename?: 'Appointment_avg_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "customer_appointments" */
export interface Appointment_Avg_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "customer_appointments". All fields are combined with a logical 'AND'. */
export interface Appointment_Bool_Exp {
  _and?: Maybe<Array<Appointment_Bool_Exp>>;
  _not?: Maybe<Appointment_Bool_Exp>;
  _or?: Maybe<Array<Appointment_Bool_Exp>>;
  bring_at?: Maybe<Date_Comparison_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  customer_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  return_at?: Maybe<Date_Comparison_Exp>;
  vehicle_business_case_number?: Maybe<Int_Comparison_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_version?: Maybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "customer_appointments" */
export enum Appointment_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppointmentsPkey = 'appointments_pkey'
}

/** input type for incrementing numeric columns in table "customer_appointments" */
export interface Appointment_Inc_Input {
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "customer_appointments" */
export interface Appointment_Insert_Input {
  bring_at?: Maybe<Scalars['date']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  return_at?: Maybe<Scalars['date']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface Appointment_Max_Fields {
  __typename?: 'Appointment_max_fields';
  bring_at?: Maybe<Scalars['date']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  return_at?: Maybe<Scalars['date']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "customer_appointments" */
export interface Appointment_Max_Order_By {
  bring_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  return_at?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Appointment_Min_Fields {
  __typename?: 'Appointment_min_fields';
  bring_at?: Maybe<Scalars['date']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  return_at?: Maybe<Scalars['date']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "customer_appointments" */
export interface Appointment_Min_Order_By {
  bring_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  return_at?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** response of any mutation on the table "customer_appointments" */
export interface Appointment_Mutation_Response {
  __typename?: 'Appointment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment>;
}

/** on_conflict condition type for table "customer_appointments" */
export interface Appointment_On_Conflict {
  constraint: Appointment_Constraint;
  update_columns: Array<Appointment_Update_Column>;
  where?: Maybe<Appointment_Bool_Exp>;
}

/** Ordering options when selecting data from "customer_appointments". */
export interface Appointment_Order_By {
  bring_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  return_at?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** primary key columns input for table: customer_appointments */
export interface Appointment_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "customer_appointments" */
export enum Appointment_Select_Column {
  /** column name */
  BringAt = 'bring_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ReturnAt = 'return_at',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

/** input type for updating data in table "customer_appointments" */
export interface Appointment_Set_Input {
  bring_at?: Maybe<Scalars['date']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  return_at?: Maybe<Scalars['date']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface Appointment_Stddev_Fields {
  __typename?: 'Appointment_stddev_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "customer_appointments" */
export interface Appointment_Stddev_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Appointment_Stddev_Pop_Fields {
  __typename?: 'Appointment_stddev_pop_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "customer_appointments" */
export interface Appointment_Stddev_Pop_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Appointment_Stddev_Samp_Fields {
  __typename?: 'Appointment_stddev_samp_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "customer_appointments" */
export interface Appointment_Stddev_Samp_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Streaming cursor of the table "Appointment" */
export interface Appointment_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Appointment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Appointment_Stream_Cursor_Value_Input {
  bring_at?: Maybe<Scalars['date']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  return_at?: Maybe<Scalars['date']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface Appointment_Sum_Fields {
  __typename?: 'Appointment_sum_fields';
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "customer_appointments" */
export interface Appointment_Sum_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** update columns of table "customer_appointments" */
export enum Appointment_Update_Column {
  /** column name */
  BringAt = 'bring_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ReturnAt = 'return_at',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

export interface Appointment_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Appointment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Appointment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointment_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Appointment_Var_Pop_Fields {
  __typename?: 'Appointment_var_pop_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "customer_appointments" */
export interface Appointment_Var_Pop_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Appointment_Var_Samp_Fields {
  __typename?: 'Appointment_var_samp_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "customer_appointments" */
export interface Appointment_Var_Samp_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Appointment_Variance_Fields {
  __typename?: 'Appointment_variance_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "customer_appointments" */
export interface Appointment_Variance_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

export interface AssetWatchdogPayload {
  __typename?: 'AssetWatchdogPayload';
  completed: Scalars['Boolean'];
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface Boolean_Comparison_Exp {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
}

/** columns and relationships of "brands" */
export interface Brand {
  __typename?: 'Brand';
  /** A computed field, executes function "after_sales_suppliers" */
  afterSalesSuppliers?: Maybe<Array<Supplier>>;
  id: Scalars['uuid'];
  /** A computed field, executes function "is_house_brand" */
  is_house_brand?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** A computed field, executes function "sales_suppliers" */
  salesSuppliers?: Maybe<Array<Supplier>>;
  /** An array relationship */
  vehicle_metafields: Array<Brand_Vehicle_Metafield>;
  /** An aggregate relationship */
  vehicle_metafields_aggregate: Brand_Vehicle_Metafield_Aggregate;
}


/** columns and relationships of "brands" */
export interface BrandAfterSalesSuppliersArgs {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
}


/** columns and relationships of "brands" */
export interface BrandSalesSuppliersArgs {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
}


/** columns and relationships of "brands" */
export interface BrandVehicle_MetafieldsArgs {
  distinct_on?: Maybe<Array<Brand_Vehicle_Metafield_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Vehicle_Metafield_Order_By>>;
  where?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
}


/** columns and relationships of "brands" */
export interface BrandVehicle_Metafields_AggregateArgs {
  distinct_on?: Maybe<Array<Brand_Vehicle_Metafield_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Vehicle_Metafield_Order_By>>;
  where?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
}

/** aggregated selection of "brands" */
export interface Brand_Aggregate {
  __typename?: 'Brand_aggregate';
  aggregate?: Maybe<Brand_Aggregate_Fields>;
  nodes: Array<Brand>;
}

/** aggregate fields of "brands" */
export interface Brand_Aggregate_Fields {
  __typename?: 'Brand_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Brand_Max_Fields>;
  min?: Maybe<Brand_Min_Fields>;
}


/** aggregate fields of "brands" */
export interface Brand_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Brand_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "brands". All fields are combined with a logical 'AND'. */
export interface Brand_Bool_Exp {
  _and?: Maybe<Array<Brand_Bool_Exp>>;
  _not?: Maybe<Brand_Bool_Exp>;
  _or?: Maybe<Array<Brand_Bool_Exp>>;
  afterSalesSuppliers?: Maybe<Supplier_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_house_brand?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  salesSuppliers?: Maybe<Supplier_Bool_Exp>;
  vehicle_metafields?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
  vehicle_metafields_aggregate?: Maybe<Brand_Vehicle_Metafield_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "brands" */
export enum Brand_Constraint {
  /** unique or primary key constraint on columns "id" */
  BrandsPkey = 'brands_pkey'
}

/** input type for inserting data into table "brands" */
export interface Brand_Insert_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  vehicle_metafields?: Maybe<Brand_Vehicle_Metafield_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface Brand_Max_Fields {
  __typename?: 'Brand_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Brand_Min_Fields {
  __typename?: 'Brand_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "brands" */
export interface Brand_Mutation_Response {
  __typename?: 'Brand_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Brand>;
}

/** input type for inserting object relation for remote table "brands" */
export interface Brand_Obj_Rel_Insert_Input {
  data: Brand_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Brand_On_Conflict>;
}

/** on_conflict condition type for table "brands" */
export interface Brand_On_Conflict {
  constraint: Brand_Constraint;
  update_columns: Array<Brand_Update_Column>;
  where?: Maybe<Brand_Bool_Exp>;
}

/** Ordering options when selecting data from "brands". */
export interface Brand_Order_By {
  afterSalesSuppliers_aggregate?: Maybe<Supplier_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  is_house_brand?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  salesSuppliers_aggregate?: Maybe<Supplier_Aggregate_Order_By>;
  vehicle_metafields_aggregate?: Maybe<Brand_Vehicle_Metafield_Aggregate_Order_By>;
}

/** primary key columns input for table: brands */
export interface Brand_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "brands" */
export enum Brand_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "brands" */
export interface Brand_Set_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "Brand" */
export interface Brand_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Brand_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Brand_Stream_Cursor_Value_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "brands" */
export enum Brand_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Brand_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Brand_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brand_Bool_Exp;
}

export interface CheckImportJobTemplateCompatibilityInput {
  importJobId: Scalars['uuid'];
  vehicleTemplateId: Scalars['uuid'];
}

export interface CheckImportJobTemplateCompatibilityPayload {
  __typename?: 'CheckImportJobTemplateCompatibilityPayload';
  status: ResponseStatus;
}

export interface CloudwatchLogs {
  __typename?: 'CloudwatchLogs';
  message: Scalars['String'];
  timestamp: Scalars['String'];
}

export interface CloudwatchLogsInput {
  end: Scalars['date'];
  entity: SyncEntity;
  level?: Maybe<LogLevel>;
  limit?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  phase?: Maybe<SyncPhase>;
  start: Scalars['date'];
  syncType?: Maybe<SyncType>;
}

export interface CloudwatchLogsPayload {
  __typename?: 'CloudwatchLogsPayload';
  recordsMatched: Scalars['Int'];
  results: Array<CloudwatchLogs>;
}

/** columns and relationships of "contact_notes" */
export interface ContactNote {
  __typename?: 'ContactNote';
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['uuid'];
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  type?: Maybe<Contact_Note_Type_Enum>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "contact_notes" */
export interface ContactNote_Aggregate {
  __typename?: 'ContactNote_aggregate';
  aggregate?: Maybe<ContactNote_Aggregate_Fields>;
  nodes: Array<ContactNote>;
}

export interface ContactNote_Aggregate_Bool_Exp {
  count?: Maybe<ContactNote_Aggregate_Bool_Exp_Count>;
}

export interface ContactNote_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<ContactNote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ContactNote_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "contact_notes" */
export interface ContactNote_Aggregate_Fields {
  __typename?: 'ContactNote_aggregate_fields';
  avg?: Maybe<ContactNote_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContactNote_Max_Fields>;
  min?: Maybe<ContactNote_Min_Fields>;
  stddev?: Maybe<ContactNote_Stddev_Fields>;
  stddev_pop?: Maybe<ContactNote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContactNote_Stddev_Samp_Fields>;
  sum?: Maybe<ContactNote_Sum_Fields>;
  var_pop?: Maybe<ContactNote_Var_Pop_Fields>;
  var_samp?: Maybe<ContactNote_Var_Samp_Fields>;
  variance?: Maybe<ContactNote_Variance_Fields>;
}


/** aggregate fields of "contact_notes" */
export interface ContactNote_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<ContactNote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "contact_notes" */
export interface ContactNote_Aggregate_Order_By {
  avg?: Maybe<ContactNote_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ContactNote_Max_Order_By>;
  min?: Maybe<ContactNote_Min_Order_By>;
  stddev?: Maybe<ContactNote_Stddev_Order_By>;
  stddev_pop?: Maybe<ContactNote_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ContactNote_Stddev_Samp_Order_By>;
  sum?: Maybe<ContactNote_Sum_Order_By>;
  var_pop?: Maybe<ContactNote_Var_Pop_Order_By>;
  var_samp?: Maybe<ContactNote_Var_Samp_Order_By>;
  variance?: Maybe<ContactNote_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "contact_notes" */
export interface ContactNote_Arr_Rel_Insert_Input {
  data: Array<ContactNote_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<ContactNote_On_Conflict>;
}

/** aggregate avg on columns */
export interface ContactNote_Avg_Fields {
  __typename?: 'ContactNote_avg_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "contact_notes" */
export interface ContactNote_Avg_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "contact_notes". All fields are combined with a logical 'AND'. */
export interface ContactNote_Bool_Exp {
  _and?: Maybe<Array<ContactNote_Bool_Exp>>;
  _not?: Maybe<ContactNote_Bool_Exp>;
  _or?: Maybe<Array<ContactNote_Bool_Exp>>;
  after_sales_potential_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  customer_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Contact_Note_Type_Enum_Comparison_Exp>;
  vehicle_business_case_number?: Maybe<Int_Comparison_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_version?: Maybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "contact_notes" */
export enum ContactNote_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactNotePkey = 'contact_note_pkey'
}

/** input type for incrementing numeric columns in table "contact_notes" */
export interface ContactNote_Inc_Input {
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "contact_notes" */
export interface ContactNote_Insert_Input {
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  type?: Maybe<Contact_Note_Type_Enum>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface ContactNote_Max_Fields {
  __typename?: 'ContactNote_max_fields';
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "contact_notes" */
export interface ContactNote_Max_Order_By {
  after_sales_potential_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface ContactNote_Min_Fields {
  __typename?: 'ContactNote_min_fields';
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "contact_notes" */
export interface ContactNote_Min_Order_By {
  after_sales_potential_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** response of any mutation on the table "contact_notes" */
export interface ContactNote_Mutation_Response {
  __typename?: 'ContactNote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContactNote>;
}

/** on_conflict condition type for table "contact_notes" */
export interface ContactNote_On_Conflict {
  constraint: ContactNote_Constraint;
  update_columns: Array<ContactNote_Update_Column>;
  where?: Maybe<ContactNote_Bool_Exp>;
}

/** Ordering options when selecting data from "contact_notes". */
export interface ContactNote_Order_By {
  after_sales_potential_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** primary key columns input for table: contact_notes */
export interface ContactNote_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "contact_notes" */
export enum ContactNote_Select_Column {
  /** column name */
  AfterSalesPotentialId = 'after_sales_potential_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Type = 'type',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

/** input type for updating data in table "contact_notes" */
export interface ContactNote_Set_Input {
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  type?: Maybe<Contact_Note_Type_Enum>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface ContactNote_Stddev_Fields {
  __typename?: 'ContactNote_stddev_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "contact_notes" */
export interface ContactNote_Stddev_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface ContactNote_Stddev_Pop_Fields {
  __typename?: 'ContactNote_stddev_pop_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "contact_notes" */
export interface ContactNote_Stddev_Pop_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface ContactNote_Stddev_Samp_Fields {
  __typename?: 'ContactNote_stddev_samp_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "contact_notes" */
export interface ContactNote_Stddev_Samp_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Streaming cursor of the table "ContactNote" */
export interface ContactNote_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: ContactNote_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface ContactNote_Stream_Cursor_Value_Input {
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  type?: Maybe<Contact_Note_Type_Enum>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface ContactNote_Sum_Fields {
  __typename?: 'ContactNote_sum_fields';
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "contact_notes" */
export interface ContactNote_Sum_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** update columns of table "contact_notes" */
export enum ContactNote_Update_Column {
  /** column name */
  AfterSalesPotentialId = 'after_sales_potential_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Type = 'type',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

export interface ContactNote_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ContactNote_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ContactNote_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContactNote_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface ContactNote_Var_Pop_Fields {
  __typename?: 'ContactNote_var_pop_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "contact_notes" */
export interface ContactNote_Var_Pop_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface ContactNote_Var_Samp_Fields {
  __typename?: 'ContactNote_var_samp_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "contact_notes" */
export interface ContactNote_Var_Samp_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface ContactNote_Variance_Fields {
  __typename?: 'ContactNote_variance_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "contact_notes" */
export interface ContactNote_Variance_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

export interface CreateTicketFromTemplateInput {
  ticketTemplateId: Scalars['String'];
  vehicle: VehicleComposedId;
}

export interface CreateTicketFromTemplatePayload {
  __typename?: 'CreateTicketFromTemplatePayload';
  ticketId: Scalars['String'];
}

export interface CreateUserInput {
  abbreviation?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  last_name?: Maybe<Scalars['String']>;
  role: Scalars['String'];
}

export interface CreateUserPayload {
  __typename?: 'CreateUserPayload';
  id: Scalars['String'];
}

/** columns and relationships of "customers" */
export interface Customer {
  __typename?: 'Customer';
  /** An array relationship */
  after_sales_potentials: Array<AfterSalesPotential>;
  /** An aggregate relationship */
  after_sales_potentials_aggregate: AfterSalesPotential_Aggregate;
  /** An array relationship */
  appointments: Array<Appointment>;
  /** An aggregate relationship */
  appointments_aggregate: Appointment_Aggregate;
  birthday?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  /** An array relationship */
  contact_notes: Array<ContactNote>;
  /** An aggregate relationship */
  contact_notes_aggregate: ContactNote_Aggregate;
  country_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  customer_no?: Maybe<Scalars['String']>;
  /** An array relationship */
  customer_tags: Array<Customer_Customer_Tag>;
  /** An aggregate relationship */
  customer_tags_aggregate: Customer_Customer_Tag_Aggregate;
  email?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  gdpr_infos: Array<CustomerGdprInfo>;
  /** An aggregate relationship */
  gdpr_infos_aggregate: CustomerGdprInfo_Aggregate;
  id: Scalars['uuid'];
  is_natural_person: Scalars['Boolean'];
  /** A computed field, executes function "customer_name" */
  name?: Maybe<Scalars['String']>;
  name_postfix?: Maybe<Scalars['String']>;
  name_prefix?: Maybe<Scalars['String']>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  /** An array relationship */
  phone_numbers: Array<PhoneNumber>;
  /** An aggregate relationship */
  phone_numbers_aggregate: PhoneNumber_Aggregate;
  /** A computed field, executes function "qualified_for_email" */
  qualified_for_email?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "qualified_for_letter" */
  qualified_for_letter?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "qualified_for_sms" */
  qualified_for_sms?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "salutation" */
  salutation?: Maybe<Scalars['String']>;
  /** An array relationship */
  salutations: Array<Customer_Salutation>;
  /** An aggregate relationship */
  salutations_aggregate: Customer_Salutation_Aggregate;
  street?: Maybe<Scalars['String']>;
  /** An array relationship */
  vehicles: Array<CustomerVehicle>;
  /** An aggregate relationship */
  vehicles_aggregate: CustomerVehicle_Aggregate;
  zip_code?: Maybe<Scalars['String']>;
}


/** columns and relationships of "customers" */
export interface CustomerAfter_Sales_PotentialsArgs {
  distinct_on?: Maybe<Array<AfterSalesPotential_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AfterSalesPotential_Order_By>>;
  where?: Maybe<AfterSalesPotential_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerAfter_Sales_Potentials_AggregateArgs {
  distinct_on?: Maybe<Array<AfterSalesPotential_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AfterSalesPotential_Order_By>>;
  where?: Maybe<AfterSalesPotential_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerAppointmentsArgs {
  distinct_on?: Maybe<Array<Appointment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointment_Order_By>>;
  where?: Maybe<Appointment_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerAppointments_AggregateArgs {
  distinct_on?: Maybe<Array<Appointment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointment_Order_By>>;
  where?: Maybe<Appointment_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerContact_NotesArgs {
  distinct_on?: Maybe<Array<ContactNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactNote_Order_By>>;
  where?: Maybe<ContactNote_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerContact_Notes_AggregateArgs {
  distinct_on?: Maybe<Array<ContactNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactNote_Order_By>>;
  where?: Maybe<ContactNote_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerCustomer_TagsArgs {
  distinct_on?: Maybe<Array<Customer_Customer_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Customer_Tag_Order_By>>;
  where?: Maybe<Customer_Customer_Tag_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerCustomer_Tags_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Customer_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Customer_Tag_Order_By>>;
  where?: Maybe<Customer_Customer_Tag_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerGdpr_InfosArgs {
  distinct_on?: Maybe<Array<CustomerGdprInfo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerGdprInfo_Order_By>>;
  where?: Maybe<CustomerGdprInfo_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerGdpr_Infos_AggregateArgs {
  distinct_on?: Maybe<Array<CustomerGdprInfo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerGdprInfo_Order_By>>;
  where?: Maybe<CustomerGdprInfo_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerNotesArgs {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerNotes_AggregateArgs {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerPhone_NumbersArgs {
  distinct_on?: Maybe<Array<PhoneNumber_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PhoneNumber_Order_By>>;
  where?: Maybe<PhoneNumber_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerPhone_Numbers_AggregateArgs {
  distinct_on?: Maybe<Array<PhoneNumber_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PhoneNumber_Order_By>>;
  where?: Maybe<PhoneNumber_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerSalutationArgs {
  args?: Maybe<Salutation_Customer_Args>;
}


/** columns and relationships of "customers" */
export interface CustomerSalutationsArgs {
  distinct_on?: Maybe<Array<Customer_Salutation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Salutation_Order_By>>;
  where?: Maybe<Customer_Salutation_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerSalutations_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Salutation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Salutation_Order_By>>;
  where?: Maybe<Customer_Salutation_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerVehiclesArgs {
  distinct_on?: Maybe<Array<CustomerVehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerVehicle_Order_By>>;
  where?: Maybe<CustomerVehicle_Bool_Exp>;
}


/** columns and relationships of "customers" */
export interface CustomerVehicles_AggregateArgs {
  distinct_on?: Maybe<Array<CustomerVehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerVehicle_Order_By>>;
  where?: Maybe<CustomerVehicle_Bool_Exp>;
}

/** columns and relationships of "customer_gdpr_info" */
export interface CustomerGdprInfo {
  __typename?: 'CustomerGdprInfo';
  customer_id: Scalars['uuid'];
  email: Scalars['Boolean'];
  id: Scalars['uuid'];
  mail: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  signed_at?: Maybe<Scalars['date']>;
  sms: Scalars['Boolean'];
}

/** aggregated selection of "customer_gdpr_info" */
export interface CustomerGdprInfo_Aggregate {
  __typename?: 'CustomerGdprInfo_aggregate';
  aggregate?: Maybe<CustomerGdprInfo_Aggregate_Fields>;
  nodes: Array<CustomerGdprInfo>;
}

export interface CustomerGdprInfo_Aggregate_Bool_Exp {
  bool_and?: Maybe<CustomerGdprInfo_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<CustomerGdprInfo_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<CustomerGdprInfo_Aggregate_Bool_Exp_Count>;
}

export interface CustomerGdprInfo_Aggregate_Bool_Exp_Bool_And {
  arguments: CustomerGdprInfo_Select_Column_CustomerGdprInfo_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CustomerGdprInfo_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface CustomerGdprInfo_Aggregate_Bool_Exp_Bool_Or {
  arguments: CustomerGdprInfo_Select_Column_CustomerGdprInfo_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CustomerGdprInfo_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface CustomerGdprInfo_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<CustomerGdprInfo_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CustomerGdprInfo_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "customer_gdpr_info" */
export interface CustomerGdprInfo_Aggregate_Fields {
  __typename?: 'CustomerGdprInfo_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomerGdprInfo_Max_Fields>;
  min?: Maybe<CustomerGdprInfo_Min_Fields>;
}


/** aggregate fields of "customer_gdpr_info" */
export interface CustomerGdprInfo_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<CustomerGdprInfo_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "customer_gdpr_info" */
export interface CustomerGdprInfo_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<CustomerGdprInfo_Max_Order_By>;
  min?: Maybe<CustomerGdprInfo_Min_Order_By>;
}

/** input type for inserting array relation for remote table "customer_gdpr_info" */
export interface CustomerGdprInfo_Arr_Rel_Insert_Input {
  data: Array<CustomerGdprInfo_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<CustomerGdprInfo_On_Conflict>;
}

/** Boolean expression to filter rows from the table "customer_gdpr_info". All fields are combined with a logical 'AND'. */
export interface CustomerGdprInfo_Bool_Exp {
  _and?: Maybe<Array<CustomerGdprInfo_Bool_Exp>>;
  _not?: Maybe<CustomerGdprInfo_Bool_Exp>;
  _or?: Maybe<Array<CustomerGdprInfo_Bool_Exp>>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  email?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mail?: Maybe<Boolean_Comparison_Exp>;
  phone?: Maybe<Boolean_Comparison_Exp>;
  signed_at?: Maybe<Date_Comparison_Exp>;
  sms?: Maybe<Boolean_Comparison_Exp>;
}

/** unique or primary key constraints on table "customer_gdpr_info" */
export enum CustomerGdprInfo_Constraint {
  /** unique or primary key constraint on columns "customer_id" */
  CustomerGdprInfoPkey = 'customer_gdpr_info_pkey'
}

/** input type for inserting data into table "customer_gdpr_info" */
export interface CustomerGdprInfo_Insert_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  mail?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Boolean']>;
  signed_at?: Maybe<Scalars['date']>;
  sms?: Maybe<Scalars['Boolean']>;
}

/** aggregate max on columns */
export interface CustomerGdprInfo_Max_Fields {
  __typename?: 'CustomerGdprInfo_max_fields';
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  signed_at?: Maybe<Scalars['date']>;
}

/** order by max() on columns of table "customer_gdpr_info" */
export interface CustomerGdprInfo_Max_Order_By {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  signed_at?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface CustomerGdprInfo_Min_Fields {
  __typename?: 'CustomerGdprInfo_min_fields';
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  signed_at?: Maybe<Scalars['date']>;
}

/** order by min() on columns of table "customer_gdpr_info" */
export interface CustomerGdprInfo_Min_Order_By {
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  signed_at?: Maybe<Order_By>;
}

/** response of any mutation on the table "customer_gdpr_info" */
export interface CustomerGdprInfo_Mutation_Response {
  __typename?: 'CustomerGdprInfo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerGdprInfo>;
}

/** on_conflict condition type for table "customer_gdpr_info" */
export interface CustomerGdprInfo_On_Conflict {
  constraint: CustomerGdprInfo_Constraint;
  update_columns: Array<CustomerGdprInfo_Update_Column>;
  where?: Maybe<CustomerGdprInfo_Bool_Exp>;
}

/** Ordering options when selecting data from "customer_gdpr_info". */
export interface CustomerGdprInfo_Order_By {
  customer_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mail?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  signed_at?: Maybe<Order_By>;
  sms?: Maybe<Order_By>;
}

/** primary key columns input for table: customer_gdpr_info */
export interface CustomerGdprInfo_Pk_Columns_Input {
  customer_id: Scalars['uuid'];
}

/** select columns of table "customer_gdpr_info" */
export enum CustomerGdprInfo_Select_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Mail = 'mail',
  /** column name */
  Phone = 'phone',
  /** column name */
  SignedAt = 'signed_at',
  /** column name */
  Sms = 'sms'
}

/** select "CustomerGdprInfo_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer_gdpr_info" */
export enum CustomerGdprInfo_Select_Column_CustomerGdprInfo_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Email = 'email',
  /** column name */
  Mail = 'mail',
  /** column name */
  Phone = 'phone',
  /** column name */
  Sms = 'sms'
}

/** select "CustomerGdprInfo_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer_gdpr_info" */
export enum CustomerGdprInfo_Select_Column_CustomerGdprInfo_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Email = 'email',
  /** column name */
  Mail = 'mail',
  /** column name */
  Phone = 'phone',
  /** column name */
  Sms = 'sms'
}

/** input type for updating data in table "customer_gdpr_info" */
export interface CustomerGdprInfo_Set_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  mail?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Boolean']>;
  signed_at?: Maybe<Scalars['date']>;
  sms?: Maybe<Scalars['Boolean']>;
}

/** Streaming cursor of the table "CustomerGdprInfo" */
export interface CustomerGdprInfo_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: CustomerGdprInfo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface CustomerGdprInfo_Stream_Cursor_Value_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  mail?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Boolean']>;
  signed_at?: Maybe<Scalars['date']>;
  sms?: Maybe<Scalars['Boolean']>;
}

/** update columns of table "customer_gdpr_info" */
export enum CustomerGdprInfo_Update_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Mail = 'mail',
  /** column name */
  Phone = 'phone',
  /** column name */
  SignedAt = 'signed_at',
  /** column name */
  Sms = 'sms'
}

export interface CustomerGdprInfo_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CustomerGdprInfo_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerGdprInfo_Bool_Exp;
}

/** columns and relationships of "customer_vehicles" */
export interface CustomerVehicle {
  __typename?: 'CustomerVehicle';
  /** An object relationship */
  brand: Brand;
  brand_id: Scalars['uuid'];
  color_exterior_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  customer: Customer;
  customer_id: Scalars['uuid'];
  first_registration_date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  license_plate_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  model?: Maybe<Model>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
  /** An array relationship */
  work_orders: Array<Work_Orders>;
  /** An aggregate relationship */
  work_orders_aggregate: Work_Orders_Aggregate;
}


/** columns and relationships of "customer_vehicles" */
export interface CustomerVehicleWork_OrdersArgs {
  distinct_on?: Maybe<Array<Work_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Orders_Order_By>>;
  where?: Maybe<Work_Orders_Bool_Exp>;
}


/** columns and relationships of "customer_vehicles" */
export interface CustomerVehicleWork_Orders_AggregateArgs {
  distinct_on?: Maybe<Array<Work_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Orders_Order_By>>;
  where?: Maybe<Work_Orders_Bool_Exp>;
}

/** aggregated selection of "customer_vehicles" */
export interface CustomerVehicle_Aggregate {
  __typename?: 'CustomerVehicle_aggregate';
  aggregate?: Maybe<CustomerVehicle_Aggregate_Fields>;
  nodes: Array<CustomerVehicle>;
}

export interface CustomerVehicle_Aggregate_Bool_Exp {
  count?: Maybe<CustomerVehicle_Aggregate_Bool_Exp_Count>;
}

export interface CustomerVehicle_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<CustomerVehicle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CustomerVehicle_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "customer_vehicles" */
export interface CustomerVehicle_Aggregate_Fields {
  __typename?: 'CustomerVehicle_aggregate_fields';
  avg?: Maybe<CustomerVehicle_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CustomerVehicle_Max_Fields>;
  min?: Maybe<CustomerVehicle_Min_Fields>;
  stddev?: Maybe<CustomerVehicle_Stddev_Fields>;
  stddev_pop?: Maybe<CustomerVehicle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CustomerVehicle_Stddev_Samp_Fields>;
  sum?: Maybe<CustomerVehicle_Sum_Fields>;
  var_pop?: Maybe<CustomerVehicle_Var_Pop_Fields>;
  var_samp?: Maybe<CustomerVehicle_Var_Samp_Fields>;
  variance?: Maybe<CustomerVehicle_Variance_Fields>;
}


/** aggregate fields of "customer_vehicles" */
export interface CustomerVehicle_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<CustomerVehicle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "customer_vehicles" */
export interface CustomerVehicle_Aggregate_Order_By {
  avg?: Maybe<CustomerVehicle_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CustomerVehicle_Max_Order_By>;
  min?: Maybe<CustomerVehicle_Min_Order_By>;
  stddev?: Maybe<CustomerVehicle_Stddev_Order_By>;
  stddev_pop?: Maybe<CustomerVehicle_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CustomerVehicle_Stddev_Samp_Order_By>;
  sum?: Maybe<CustomerVehicle_Sum_Order_By>;
  var_pop?: Maybe<CustomerVehicle_Var_Pop_Order_By>;
  var_samp?: Maybe<CustomerVehicle_Var_Samp_Order_By>;
  variance?: Maybe<CustomerVehicle_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "customer_vehicles" */
export interface CustomerVehicle_Arr_Rel_Insert_Input {
  data: Array<CustomerVehicle_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<CustomerVehicle_On_Conflict>;
}

/** aggregate avg on columns */
export interface CustomerVehicle_Avg_Fields {
  __typename?: 'CustomerVehicle_avg_fields';
  mileage?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "customer_vehicles" */
export interface CustomerVehicle_Avg_Order_By {
  mileage?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "customer_vehicles". All fields are combined with a logical 'AND'. */
export interface CustomerVehicle_Bool_Exp {
  _and?: Maybe<Array<CustomerVehicle_Bool_Exp>>;
  _not?: Maybe<CustomerVehicle_Bool_Exp>;
  _or?: Maybe<Array<CustomerVehicle_Bool_Exp>>;
  brand?: Maybe<Brand_Bool_Exp>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  color_exterior_name?: Maybe<String_Comparison_Exp>;
  customer?: Maybe<Customer_Bool_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  first_registration_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  license_plate_number?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  mileage?: Maybe<Int_Comparison_Exp>;
  mileage_date?: Maybe<Date_Comparison_Exp>;
  model?: Maybe<Model_Bool_Exp>;
  model_description?: Maybe<String_Comparison_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
  vin?: Maybe<String_Comparison_Exp>;
  warranty_end?: Maybe<Date_Comparison_Exp>;
  warranty_start?: Maybe<Date_Comparison_Exp>;
  work_orders?: Maybe<Work_Orders_Bool_Exp>;
  work_orders_aggregate?: Maybe<Work_Orders_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "customer_vehicles" */
export enum CustomerVehicle_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerVehiclesPkey = 'customer_vehicles_pkey'
}

/** input type for incrementing numeric columns in table "customer_vehicles" */
export interface CustomerVehicle_Inc_Input {
  mileage?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "customer_vehicles" */
export interface CustomerVehicle_Insert_Input {
  brand?: Maybe<Brand_Obj_Rel_Insert_Input>;
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['uuid']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  model?: Maybe<Model_Obj_Rel_Insert_Input>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
  work_orders?: Maybe<Work_Orders_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface CustomerVehicle_Max_Fields {
  __typename?: 'CustomerVehicle_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** order by max() on columns of table "customer_vehicles" */
export interface CustomerVehicle_Max_Order_By {
  brand_id?: Maybe<Order_By>;
  color_exterior_name?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  model_description?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface CustomerVehicle_Min_Fields {
  __typename?: 'CustomerVehicle_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** order by min() on columns of table "customer_vehicles" */
export interface CustomerVehicle_Min_Order_By {
  brand_id?: Maybe<Order_By>;
  color_exterior_name?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  model_description?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
}

/** response of any mutation on the table "customer_vehicles" */
export interface CustomerVehicle_Mutation_Response {
  __typename?: 'CustomerVehicle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerVehicle>;
}

/** input type for inserting object relation for remote table "customer_vehicles" */
export interface CustomerVehicle_Obj_Rel_Insert_Input {
  data: CustomerVehicle_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<CustomerVehicle_On_Conflict>;
}

/** on_conflict condition type for table "customer_vehicles" */
export interface CustomerVehicle_On_Conflict {
  constraint: CustomerVehicle_Constraint;
  update_columns: Array<CustomerVehicle_Update_Column>;
  where?: Maybe<CustomerVehicle_Bool_Exp>;
}

/** Ordering options when selecting data from "customer_vehicles". */
export interface CustomerVehicle_Order_By {
  brand?: Maybe<Brand_Order_By>;
  brand_id?: Maybe<Order_By>;
  color_exterior_name?: Maybe<Order_By>;
  customer?: Maybe<Customer_Order_By>;
  customer_id?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_id?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  model?: Maybe<Model_Order_By>;
  model_description?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
  work_orders_aggregate?: Maybe<Work_Orders_Aggregate_Order_By>;
}

/** primary key columns input for table: customer_vehicles */
export interface CustomerVehicle_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "customer_vehicles" */
export enum CustomerVehicle_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  ColorExteriorName = 'color_exterior_name',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  FirstRegistrationDate = 'first_registration_date',
  /** column name */
  Id = 'id',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  ModelDescription = 'model_description',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  Vin = 'vin',
  /** column name */
  WarrantyEnd = 'warranty_end',
  /** column name */
  WarrantyStart = 'warranty_start'
}

/** input type for updating data in table "customer_vehicles" */
export interface CustomerVehicle_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate stddev on columns */
export interface CustomerVehicle_Stddev_Fields {
  __typename?: 'CustomerVehicle_stddev_fields';
  mileage?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "customer_vehicles" */
export interface CustomerVehicle_Stddev_Order_By {
  mileage?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface CustomerVehicle_Stddev_Pop_Fields {
  __typename?: 'CustomerVehicle_stddev_pop_fields';
  mileage?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "customer_vehicles" */
export interface CustomerVehicle_Stddev_Pop_Order_By {
  mileage?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface CustomerVehicle_Stddev_Samp_Fields {
  __typename?: 'CustomerVehicle_stddev_samp_fields';
  mileage?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "customer_vehicles" */
export interface CustomerVehicle_Stddev_Samp_Order_By {
  mileage?: Maybe<Order_By>;
}

/** Streaming cursor of the table "CustomerVehicle" */
export interface CustomerVehicle_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: CustomerVehicle_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface CustomerVehicle_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate sum on columns */
export interface CustomerVehicle_Sum_Fields {
  __typename?: 'CustomerVehicle_sum_fields';
  mileage?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "customer_vehicles" */
export interface CustomerVehicle_Sum_Order_By {
  mileage?: Maybe<Order_By>;
}

/** update columns of table "customer_vehicles" */
export enum CustomerVehicle_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  ColorExteriorName = 'color_exterior_name',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  FirstRegistrationDate = 'first_registration_date',
  /** column name */
  Id = 'id',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  ModelDescription = 'model_description',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  Vin = 'vin',
  /** column name */
  WarrantyEnd = 'warranty_end',
  /** column name */
  WarrantyStart = 'warranty_start'
}

export interface CustomerVehicle_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<CustomerVehicle_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CustomerVehicle_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerVehicle_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface CustomerVehicle_Var_Pop_Fields {
  __typename?: 'CustomerVehicle_var_pop_fields';
  mileage?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "customer_vehicles" */
export interface CustomerVehicle_Var_Pop_Order_By {
  mileage?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface CustomerVehicle_Var_Samp_Fields {
  __typename?: 'CustomerVehicle_var_samp_fields';
  mileage?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "customer_vehicles" */
export interface CustomerVehicle_Var_Samp_Order_By {
  mileage?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface CustomerVehicle_Variance_Fields {
  __typename?: 'CustomerVehicle_variance_fields';
  mileage?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "customer_vehicles" */
export interface CustomerVehicle_Variance_Order_By {
  mileage?: Maybe<Order_By>;
}

/** aggregated selection of "customers" */
export interface Customer_Aggregate {
  __typename?: 'Customer_aggregate';
  aggregate?: Maybe<Customer_Aggregate_Fields>;
  nodes: Array<Customer>;
}

/** aggregate fields of "customers" */
export interface Customer_Aggregate_Fields {
  __typename?: 'Customer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Max_Fields>;
  min?: Maybe<Customer_Min_Fields>;
}


/** aggregate fields of "customers" */
export interface Customer_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Customer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "customers". All fields are combined with a logical 'AND'. */
export interface Customer_Bool_Exp {
  _and?: Maybe<Array<Customer_Bool_Exp>>;
  _not?: Maybe<Customer_Bool_Exp>;
  _or?: Maybe<Array<Customer_Bool_Exp>>;
  after_sales_potentials?: Maybe<AfterSalesPotential_Bool_Exp>;
  after_sales_potentials_aggregate?: Maybe<AfterSalesPotential_Aggregate_Bool_Exp>;
  appointments?: Maybe<Appointment_Bool_Exp>;
  appointments_aggregate?: Maybe<Appointment_Aggregate_Bool_Exp>;
  birthday?: Maybe<Date_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  contact_notes?: Maybe<ContactNote_Bool_Exp>;
  contact_notes_aggregate?: Maybe<ContactNote_Aggregate_Bool_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_no?: Maybe<String_Comparison_Exp>;
  customer_tags?: Maybe<Customer_Customer_Tag_Bool_Exp>;
  customer_tags_aggregate?: Maybe<Customer_Customer_Tag_Aggregate_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  family_name?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  gdpr_infos?: Maybe<CustomerGdprInfo_Bool_Exp>;
  gdpr_infos_aggregate?: Maybe<CustomerGdprInfo_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_natural_person?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  name_postfix?: Maybe<String_Comparison_Exp>;
  name_prefix?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<Notes_Bool_Exp>;
  notes_aggregate?: Maybe<Notes_Aggregate_Bool_Exp>;
  phone_numbers?: Maybe<PhoneNumber_Bool_Exp>;
  phone_numbers_aggregate?: Maybe<PhoneNumber_Aggregate_Bool_Exp>;
  qualified_for_email?: Maybe<Boolean_Comparison_Exp>;
  qualified_for_letter?: Maybe<Boolean_Comparison_Exp>;
  qualified_for_sms?: Maybe<Boolean_Comparison_Exp>;
  salutations?: Maybe<Customer_Salutation_Bool_Exp>;
  salutations_aggregate?: Maybe<Customer_Salutation_Aggregate_Bool_Exp>;
  street?: Maybe<String_Comparison_Exp>;
  vehicles?: Maybe<CustomerVehicle_Bool_Exp>;
  vehicles_aggregate?: Maybe<CustomerVehicle_Aggregate_Bool_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "customers" */
export enum Customer_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomersPkey = 'customers_pkey'
}

/** input type for inserting data into table "customers" */
export interface Customer_Insert_Input {
  after_sales_potentials?: Maybe<AfterSalesPotential_Arr_Rel_Insert_Input>;
  appointments?: Maybe<Appointment_Arr_Rel_Insert_Input>;
  birthday?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  contact_notes?: Maybe<ContactNote_Arr_Rel_Insert_Input>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_no?: Maybe<Scalars['String']>;
  customer_tags?: Maybe<Customer_Customer_Tag_Arr_Rel_Insert_Input>;
  email?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gdpr_infos?: Maybe<CustomerGdprInfo_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  is_natural_person?: Maybe<Scalars['Boolean']>;
  name_postfix?: Maybe<Scalars['String']>;
  name_prefix?: Maybe<Scalars['String']>;
  notes?: Maybe<Notes_Arr_Rel_Insert_Input>;
  phone_numbers?: Maybe<PhoneNumber_Arr_Rel_Insert_Input>;
  salutations?: Maybe<Customer_Salutation_Arr_Rel_Insert_Input>;
  street?: Maybe<Scalars['String']>;
  vehicles?: Maybe<CustomerVehicle_Arr_Rel_Insert_Input>;
  zip_code?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Customer_Max_Fields {
  __typename?: 'Customer_max_fields';
  birthday?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_no?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "customer_name" */
  name?: Maybe<Scalars['String']>;
  name_postfix?: Maybe<Scalars['String']>;
  name_prefix?: Maybe<Scalars['String']>;
  /** A computed field, executes function "salutation" */
  salutation?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
}


/** aggregate max on columns */
export interface Customer_Max_FieldsSalutationArgs {
  args?: Maybe<Salutation_Customer_Args>;
}

/** aggregate min on columns */
export interface Customer_Min_Fields {
  __typename?: 'Customer_min_fields';
  birthday?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_no?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "customer_name" */
  name?: Maybe<Scalars['String']>;
  name_postfix?: Maybe<Scalars['String']>;
  name_prefix?: Maybe<Scalars['String']>;
  /** A computed field, executes function "salutation" */
  salutation?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
}


/** aggregate min on columns */
export interface Customer_Min_FieldsSalutationArgs {
  args?: Maybe<Salutation_Customer_Args>;
}

/** response of any mutation on the table "customers" */
export interface Customer_Mutation_Response {
  __typename?: 'Customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer>;
}

/** input type for inserting object relation for remote table "customers" */
export interface Customer_Obj_Rel_Insert_Input {
  data: Customer_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Customer_On_Conflict>;
}

/** on_conflict condition type for table "customers" */
export interface Customer_On_Conflict {
  constraint: Customer_Constraint;
  update_columns: Array<Customer_Update_Column>;
  where?: Maybe<Customer_Bool_Exp>;
}

/** Ordering options when selecting data from "customers". */
export interface Customer_Order_By {
  after_sales_potentials_aggregate?: Maybe<AfterSalesPotential_Aggregate_Order_By>;
  appointments_aggregate?: Maybe<Appointment_Aggregate_Order_By>;
  birthday?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  contact_notes_aggregate?: Maybe<ContactNote_Aggregate_Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_no?: Maybe<Order_By>;
  customer_tags_aggregate?: Maybe<Customer_Customer_Tag_Aggregate_Order_By>;
  email?: Maybe<Order_By>;
  family_name?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gdpr_infos_aggregate?: Maybe<CustomerGdprInfo_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  is_natural_person?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_postfix?: Maybe<Order_By>;
  name_prefix?: Maybe<Order_By>;
  notes_aggregate?: Maybe<Notes_Aggregate_Order_By>;
  phone_numbers_aggregate?: Maybe<PhoneNumber_Aggregate_Order_By>;
  qualified_for_email?: Maybe<Order_By>;
  qualified_for_letter?: Maybe<Order_By>;
  qualified_for_sms?: Maybe<Order_By>;
  salutations_aggregate?: Maybe<Customer_Salutation_Aggregate_Order_By>;
  street?: Maybe<Order_By>;
  vehicles_aggregate?: Maybe<CustomerVehicle_Aggregate_Order_By>;
  zip_code?: Maybe<Order_By>;
}

/** primary key columns input for table: customers */
export interface Customer_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "customers" */
export enum Customer_Select_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerNo = 'customer_no',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'family_name',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsNaturalPerson = 'is_natural_person',
  /** column name */
  NamePostfix = 'name_postfix',
  /** column name */
  NamePrefix = 'name_prefix',
  /** column name */
  Street = 'street',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "customers" */
export interface Customer_Set_Input {
  birthday?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_no?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_natural_person?: Maybe<Scalars['Boolean']>;
  name_postfix?: Maybe<Scalars['String']>;
  name_prefix?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "Customer" */
export interface Customer_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Customer_Stream_Cursor_Value_Input {
  birthday?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_no?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_natural_person?: Maybe<Scalars['Boolean']>;
  name_postfix?: Maybe<Scalars['String']>;
  name_prefix?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
}

/** update columns of table "customers" */
export enum Customer_Update_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerNo = 'customer_no',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'family_name',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsNaturalPerson = 'is_natural_person',
  /** column name */
  NamePostfix = 'name_postfix',
  /** column name */
  NamePrefix = 'name_prefix',
  /** column name */
  Street = 'street',
  /** column name */
  ZipCode = 'zip_code'
}

export interface Customer_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Bool_Exp;
}

/** columns and relationships of "dealer_vehicles" */
export interface DealerVehicle {
  __typename?: 'DealerVehicle';
  /** An object relationship */
  brand: Brand;
  brand_id: Scalars['uuid'];
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  customer?: Maybe<Scalars['String']>;
  /** An array relationship */
  dealer_vehicle_owner_information: Array<Vehicle_Owner_Informations>;
  /** An aggregate relationship */
  dealer_vehicle_owner_information_aggregate: Vehicle_Owner_Informations_Aggregate;
  delivery_date_str?: Maybe<Scalars['String']>;
  /** An object relationship */
  delivery_location?: Maybe<StorageLocation>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number: Scalars['String'];
  first_registration_date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  job_number: Scalars['String'];
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage: Scalars['Int'];
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  /** An object relationship */
  model: Model;
  model_description?: Maybe<Scalars['String']>;
  model_id: Scalars['uuid'];
  /** An array relationship */
  notes: Array<DealerVehicleNote>;
  /** An aggregate relationship */
  notes_aggregate: DealerVehicleNote_Aggregate;
  order_state: Order_State_Enum;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state: Registration_State_Enum;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state: Dealer_Vehicle_State_Enum;
  /** An object relationship */
  storage_location?: Maybe<StorageLocation>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  type: Dealer_Vehicle_Type_Enum;
  updated_at: Scalars['timestamptz'];
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}


/** columns and relationships of "dealer_vehicles" */
export interface DealerVehicleDealer_Vehicle_Owner_InformationArgs {
  distinct_on?: Maybe<Array<Vehicle_Owner_Informations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Owner_Informations_Order_By>>;
  where?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
}


/** columns and relationships of "dealer_vehicles" */
export interface DealerVehicleDealer_Vehicle_Owner_Information_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Owner_Informations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Owner_Informations_Order_By>>;
  where?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
}


/** columns and relationships of "dealer_vehicles" */
export interface DealerVehicleNotesArgs {
  distinct_on?: Maybe<Array<DealerVehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicleNote_Order_By>>;
  where?: Maybe<DealerVehicleNote_Bool_Exp>;
}


/** columns and relationships of "dealer_vehicles" */
export interface DealerVehicleNotes_AggregateArgs {
  distinct_on?: Maybe<Array<DealerVehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicleNote_Order_By>>;
  where?: Maybe<DealerVehicleNote_Bool_Exp>;
}

export interface DealerVehicleBrandSyncStatusInput {
  id: Scalars['String'];
}

export interface DealerVehicleBrandSyncStatusPayload {
  __typename?: 'DealerVehicleBrandSyncStatusPayload';
  enabled?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  status: ResponseStatus;
}

/** columns and relationships of "dealer_vehicle_inbox" */
export interface DealerVehicleInbox {
  __typename?: 'DealerVehicleInbox';
  /** An object relationship */
  brand: Brand;
  brand_id: Scalars['uuid'];
  color_exterior_code: Scalars['String'];
  color_exterior_name: Scalars['String'];
  color_interior_code: Scalars['String'];
  color_interior_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  customer?: Maybe<Scalars['String']>;
  /** An array relationship */
  dealer_vehicle_inbox_owner_information: Array<Vehicle_Owner_Informations>;
  /** An aggregate relationship */
  dealer_vehicle_inbox_owner_information_aggregate: Vehicle_Owner_Informations_Aggregate;
  delivery_date_str?: Maybe<Scalars['String']>;
  /** An object relationship */
  delivery_location?: Maybe<StorageLocation>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number: Scalars['String'];
  first_registration_date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  job_number: Scalars['String'];
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage: Scalars['Int'];
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  /** An object relationship */
  model: Model;
  model_description?: Maybe<Scalars['String']>;
  model_id: Scalars['uuid'];
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  /** An object relationship */
  storage_location?: Maybe<StorageLocation>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at: Scalars['timestamptz'];
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}


/** columns and relationships of "dealer_vehicle_inbox" */
export interface DealerVehicleInboxDealer_Vehicle_Inbox_Owner_InformationArgs {
  distinct_on?: Maybe<Array<Vehicle_Owner_Informations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Owner_Informations_Order_By>>;
  where?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
}


/** columns and relationships of "dealer_vehicle_inbox" */
export interface DealerVehicleInboxDealer_Vehicle_Inbox_Owner_Information_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Owner_Informations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Owner_Informations_Order_By>>;
  where?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
}

/** aggregated selection of "dealer_vehicle_inbox" */
export interface DealerVehicleInbox_Aggregate {
  __typename?: 'DealerVehicleInbox_aggregate';
  aggregate?: Maybe<DealerVehicleInbox_Aggregate_Fields>;
  nodes: Array<DealerVehicleInbox>;
}

/** aggregate fields of "dealer_vehicle_inbox" */
export interface DealerVehicleInbox_Aggregate_Fields {
  __typename?: 'DealerVehicleInbox_aggregate_fields';
  avg?: Maybe<DealerVehicleInbox_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<DealerVehicleInbox_Max_Fields>;
  min?: Maybe<DealerVehicleInbox_Min_Fields>;
  stddev?: Maybe<DealerVehicleInbox_Stddev_Fields>;
  stddev_pop?: Maybe<DealerVehicleInbox_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealerVehicleInbox_Stddev_Samp_Fields>;
  sum?: Maybe<DealerVehicleInbox_Sum_Fields>;
  var_pop?: Maybe<DealerVehicleInbox_Var_Pop_Fields>;
  var_samp?: Maybe<DealerVehicleInbox_Var_Samp_Fields>;
  variance?: Maybe<DealerVehicleInbox_Variance_Fields>;
}


/** aggregate fields of "dealer_vehicle_inbox" */
export interface DealerVehicleInbox_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<DealerVehicleInbox_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface DealerVehicleInbox_Avg_Fields {
  __typename?: 'DealerVehicleInbox_avg_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "dealer_vehicle_inbox". All fields are combined with a logical 'AND'. */
export interface DealerVehicleInbox_Bool_Exp {
  _and?: Maybe<Array<DealerVehicleInbox_Bool_Exp>>;
  _not?: Maybe<DealerVehicleInbox_Bool_Exp>;
  _or?: Maybe<Array<DealerVehicleInbox_Bool_Exp>>;
  brand?: Maybe<Brand_Bool_Exp>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  color_exterior_code?: Maybe<String_Comparison_Exp>;
  color_exterior_name?: Maybe<String_Comparison_Exp>;
  color_interior_code?: Maybe<String_Comparison_Exp>;
  color_interior_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<String_Comparison_Exp>;
  dealer_vehicle_inbox_owner_information?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
  dealer_vehicle_inbox_owner_information_aggregate?: Maybe<Vehicle_Owner_Informations_Aggregate_Bool_Exp>;
  delivery_date_str?: Maybe<String_Comparison_Exp>;
  delivery_location?: Maybe<StorageLocation_Bool_Exp>;
  delivery_location_id?: Maybe<Uuid_Comparison_Exp>;
  engine_number?: Maybe<String_Comparison_Exp>;
  first_registration_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  job_number?: Maybe<String_Comparison_Exp>;
  key_cabinet_number?: Maybe<String_Comparison_Exp>;
  license_plate_number?: Maybe<String_Comparison_Exp>;
  mileage?: Maybe<Int_Comparison_Exp>;
  mileage_date?: Maybe<Date_Comparison_Exp>;
  min_holding_period?: Maybe<Int_Comparison_Exp>;
  model?: Maybe<Model_Bool_Exp>;
  model_description?: Maybe<String_Comparison_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
  order_state?: Maybe<Order_State_Enum_Comparison_Exp>;
  registration_owner?: Maybe<String_Comparison_Exp>;
  registration_state?: Maybe<Registration_State_Enum_Comparison_Exp>;
  sold_at?: Maybe<Timestamptz_Comparison_Exp>;
  sold_by?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Dealer_Vehicle_State_Enum_Comparison_Exp>;
  storage_location?: Maybe<StorageLocation_Bool_Exp>;
  storage_location_id?: Maybe<Uuid_Comparison_Exp>;
  storage_location_note?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Dealer_Vehicle_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vin?: Maybe<String_Comparison_Exp>;
  warranty_end?: Maybe<Date_Comparison_Exp>;
  warranty_start?: Maybe<Date_Comparison_Exp>;
}

/** unique or primary key constraints on table "dealer_vehicle_inbox" */
export enum DealerVehicleInbox_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealerVehicleInboxPkey = 'dealer_vehicle_inbox_pkey'
}

/** input type for incrementing numeric columns in table "dealer_vehicle_inbox" */
export interface DealerVehicleInbox_Inc_Input {
  mileage?: Maybe<Scalars['Int']>;
  min_holding_period?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "dealer_vehicle_inbox" */
export interface DealerVehicleInbox_Insert_Input {
  brand?: Maybe<Brand_Obj_Rel_Insert_Input>;
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  dealer_vehicle_inbox_owner_information?: Maybe<Vehicle_Owner_Informations_Arr_Rel_Insert_Input>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location?: Maybe<StorageLocation_Obj_Rel_Insert_Input>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  job_number?: Maybe<Scalars['String']>;
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model?: Maybe<Model_Obj_Rel_Insert_Input>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  storage_location?: Maybe<StorageLocation_Obj_Rel_Insert_Input>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate max on columns */
export interface DealerVehicleInbox_Max_Fields {
  __typename?: 'DealerVehicleInbox_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  job_number?: Maybe<Scalars['String']>;
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  registration_owner?: Maybe<Scalars['String']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate min on columns */
export interface DealerVehicleInbox_Min_Fields {
  __typename?: 'DealerVehicleInbox_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  job_number?: Maybe<Scalars['String']>;
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  registration_owner?: Maybe<Scalars['String']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** response of any mutation on the table "dealer_vehicle_inbox" */
export interface DealerVehicleInbox_Mutation_Response {
  __typename?: 'DealerVehicleInbox_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DealerVehicleInbox>;
}

/** on_conflict condition type for table "dealer_vehicle_inbox" */
export interface DealerVehicleInbox_On_Conflict {
  constraint: DealerVehicleInbox_Constraint;
  update_columns: Array<DealerVehicleInbox_Update_Column>;
  where?: Maybe<DealerVehicleInbox_Bool_Exp>;
}

/** Ordering options when selecting data from "dealer_vehicle_inbox". */
export interface DealerVehicleInbox_Order_By {
  brand?: Maybe<Brand_Order_By>;
  brand_id?: Maybe<Order_By>;
  color_exterior_code?: Maybe<Order_By>;
  color_exterior_name?: Maybe<Order_By>;
  color_interior_code?: Maybe<Order_By>;
  color_interior_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  dealer_vehicle_inbox_owner_information_aggregate?: Maybe<Vehicle_Owner_Informations_Aggregate_Order_By>;
  delivery_date_str?: Maybe<Order_By>;
  delivery_location?: Maybe<StorageLocation_Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  engine_number?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  key_cabinet_number?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  model?: Maybe<Model_Order_By>;
  model_description?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  order_state?: Maybe<Order_By>;
  registration_owner?: Maybe<Order_By>;
  registration_state?: Maybe<Order_By>;
  sold_at?: Maybe<Order_By>;
  sold_by?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  storage_location?: Maybe<StorageLocation_Order_By>;
  storage_location_id?: Maybe<Order_By>;
  storage_location_note?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
}

/** primary key columns input for table: dealer_vehicle_inbox */
export interface DealerVehicleInbox_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "dealer_vehicle_inbox" */
export enum DealerVehicleInbox_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  ColorExteriorCode = 'color_exterior_code',
  /** column name */
  ColorExteriorName = 'color_exterior_name',
  /** column name */
  ColorInteriorCode = 'color_interior_code',
  /** column name */
  ColorInteriorName = 'color_interior_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customer = 'customer',
  /** column name */
  DeliveryDateStr = 'delivery_date_str',
  /** column name */
  DeliveryLocationId = 'delivery_location_id',
  /** column name */
  EngineNumber = 'engine_number',
  /** column name */
  FirstRegistrationDate = 'first_registration_date',
  /** column name */
  Id = 'id',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  KeyCabinetNumber = 'key_cabinet_number',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  MinHoldingPeriod = 'min_holding_period',
  /** column name */
  ModelDescription = 'model_description',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  OrderState = 'order_state',
  /** column name */
  RegistrationOwner = 'registration_owner',
  /** column name */
  RegistrationState = 'registration_state',
  /** column name */
  SoldAt = 'sold_at',
  /** column name */
  SoldBy = 'sold_by',
  /** column name */
  State = 'state',
  /** column name */
  StorageLocationId = 'storage_location_id',
  /** column name */
  StorageLocationNote = 'storage_location_note',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vin = 'vin',
  /** column name */
  WarrantyEnd = 'warranty_end',
  /** column name */
  WarrantyStart = 'warranty_start'
}

/** input type for updating data in table "dealer_vehicle_inbox" */
export interface DealerVehicleInbox_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  job_number?: Maybe<Scalars['String']>;
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate stddev on columns */
export interface DealerVehicleInbox_Stddev_Fields {
  __typename?: 'DealerVehicleInbox_stddev_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface DealerVehicleInbox_Stddev_Pop_Fields {
  __typename?: 'DealerVehicleInbox_stddev_pop_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface DealerVehicleInbox_Stddev_Samp_Fields {
  __typename?: 'DealerVehicleInbox_stddev_samp_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "DealerVehicleInbox" */
export interface DealerVehicleInbox_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: DealerVehicleInbox_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface DealerVehicleInbox_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  job_number?: Maybe<Scalars['String']>;
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate sum on columns */
export interface DealerVehicleInbox_Sum_Fields {
  __typename?: 'DealerVehicleInbox_sum_fields';
  mileage?: Maybe<Scalars['Int']>;
  min_holding_period?: Maybe<Scalars['Int']>;
}

/** update columns of table "dealer_vehicle_inbox" */
export enum DealerVehicleInbox_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  ColorExteriorCode = 'color_exterior_code',
  /** column name */
  ColorExteriorName = 'color_exterior_name',
  /** column name */
  ColorInteriorCode = 'color_interior_code',
  /** column name */
  ColorInteriorName = 'color_interior_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customer = 'customer',
  /** column name */
  DeliveryDateStr = 'delivery_date_str',
  /** column name */
  DeliveryLocationId = 'delivery_location_id',
  /** column name */
  EngineNumber = 'engine_number',
  /** column name */
  FirstRegistrationDate = 'first_registration_date',
  /** column name */
  Id = 'id',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  KeyCabinetNumber = 'key_cabinet_number',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  MinHoldingPeriod = 'min_holding_period',
  /** column name */
  ModelDescription = 'model_description',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  OrderState = 'order_state',
  /** column name */
  RegistrationOwner = 'registration_owner',
  /** column name */
  RegistrationState = 'registration_state',
  /** column name */
  SoldAt = 'sold_at',
  /** column name */
  SoldBy = 'sold_by',
  /** column name */
  State = 'state',
  /** column name */
  StorageLocationId = 'storage_location_id',
  /** column name */
  StorageLocationNote = 'storage_location_note',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vin = 'vin',
  /** column name */
  WarrantyEnd = 'warranty_end',
  /** column name */
  WarrantyStart = 'warranty_start'
}

export interface DealerVehicleInbox_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<DealerVehicleInbox_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<DealerVehicleInbox_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealerVehicleInbox_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface DealerVehicleInbox_Var_Pop_Fields {
  __typename?: 'DealerVehicleInbox_var_pop_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface DealerVehicleInbox_Var_Samp_Fields {
  __typename?: 'DealerVehicleInbox_var_samp_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface DealerVehicleInbox_Variance_Fields {
  __typename?: 'DealerVehicleInbox_variance_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "dealer_vehicle_notes" */
export interface DealerVehicleNote {
  __typename?: 'DealerVehicleNote';
  archived: Scalars['Boolean'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  dealer_vehicle: DealerVehicle;
  dealer_vehicle_id: Scalars['uuid'];
  id: Scalars['uuid'];
  note: Scalars['String'];
  priority: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "dealer_vehicle_notes" */
export interface DealerVehicleNote_Aggregate {
  __typename?: 'DealerVehicleNote_aggregate';
  aggregate?: Maybe<DealerVehicleNote_Aggregate_Fields>;
  nodes: Array<DealerVehicleNote>;
}

export interface DealerVehicleNote_Aggregate_Bool_Exp {
  bool_and?: Maybe<DealerVehicleNote_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<DealerVehicleNote_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<DealerVehicleNote_Aggregate_Bool_Exp_Count>;
}

export interface DealerVehicleNote_Aggregate_Bool_Exp_Bool_And {
  arguments: DealerVehicleNote_Select_Column_DealerVehicleNote_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<DealerVehicleNote_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface DealerVehicleNote_Aggregate_Bool_Exp_Bool_Or {
  arguments: DealerVehicleNote_Select_Column_DealerVehicleNote_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<DealerVehicleNote_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface DealerVehicleNote_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<DealerVehicleNote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<DealerVehicleNote_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "dealer_vehicle_notes" */
export interface DealerVehicleNote_Aggregate_Fields {
  __typename?: 'DealerVehicleNote_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DealerVehicleNote_Max_Fields>;
  min?: Maybe<DealerVehicleNote_Min_Fields>;
}


/** aggregate fields of "dealer_vehicle_notes" */
export interface DealerVehicleNote_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<DealerVehicleNote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "dealer_vehicle_notes" */
export interface DealerVehicleNote_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<DealerVehicleNote_Max_Order_By>;
  min?: Maybe<DealerVehicleNote_Min_Order_By>;
}

/** input type for inserting array relation for remote table "dealer_vehicle_notes" */
export interface DealerVehicleNote_Arr_Rel_Insert_Input {
  data: Array<DealerVehicleNote_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<DealerVehicleNote_On_Conflict>;
}

/** Boolean expression to filter rows from the table "dealer_vehicle_notes". All fields are combined with a logical 'AND'. */
export interface DealerVehicleNote_Bool_Exp {
  _and?: Maybe<Array<DealerVehicleNote_Bool_Exp>>;
  _not?: Maybe<DealerVehicleNote_Bool_Exp>;
  _or?: Maybe<Array<DealerVehicleNote_Bool_Exp>>;
  archived?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dealer_vehicle?: Maybe<DealerVehicle_Bool_Exp>;
  dealer_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  priority?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "dealer_vehicle_notes" */
export enum DealerVehicleNote_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealerVehicleNotesPkey = 'dealer_vehicle_notes_pkey'
}

/** input type for inserting data into table "dealer_vehicle_notes" */
export interface DealerVehicleNote_Insert_Input {
  archived?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dealer_vehicle?: Maybe<DealerVehicle_Obj_Rel_Insert_Input>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface DealerVehicleNote_Max_Fields {
  __typename?: 'DealerVehicleNote_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "dealer_vehicle_notes" */
export interface DealerVehicleNote_Max_Order_By {
  created_at?: Maybe<Order_By>;
  dealer_vehicle_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface DealerVehicleNote_Min_Fields {
  __typename?: 'DealerVehicleNote_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "dealer_vehicle_notes" */
export interface DealerVehicleNote_Min_Order_By {
  created_at?: Maybe<Order_By>;
  dealer_vehicle_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "dealer_vehicle_notes" */
export interface DealerVehicleNote_Mutation_Response {
  __typename?: 'DealerVehicleNote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DealerVehicleNote>;
}

/** on_conflict condition type for table "dealer_vehicle_notes" */
export interface DealerVehicleNote_On_Conflict {
  constraint: DealerVehicleNote_Constraint;
  update_columns: Array<DealerVehicleNote_Update_Column>;
  where?: Maybe<DealerVehicleNote_Bool_Exp>;
}

/** Ordering options when selecting data from "dealer_vehicle_notes". */
export interface DealerVehicleNote_Order_By {
  archived?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dealer_vehicle?: Maybe<DealerVehicle_Order_By>;
  dealer_vehicle_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
}

/** primary key columns input for table: dealer_vehicle_notes */
export interface DealerVehicleNote_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "dealer_vehicle_notes" */
export enum DealerVehicleNote_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealerVehicleId = 'dealer_vehicle_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Priority = 'priority',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "DealerVehicleNote_aggregate_bool_exp_bool_and_arguments_columns" columns of table "dealer_vehicle_notes" */
export enum DealerVehicleNote_Select_Column_DealerVehicleNote_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Priority = 'priority'
}

/** select "DealerVehicleNote_aggregate_bool_exp_bool_or_arguments_columns" columns of table "dealer_vehicle_notes" */
export enum DealerVehicleNote_Select_Column_DealerVehicleNote_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Priority = 'priority'
}

/** input type for updating data in table "dealer_vehicle_notes" */
export interface DealerVehicleNote_Set_Input {
  archived?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "DealerVehicleNote" */
export interface DealerVehicleNote_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: DealerVehicleNote_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface DealerVehicleNote_Stream_Cursor_Value_Input {
  archived?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "dealer_vehicle_notes" */
export enum DealerVehicleNote_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealerVehicleId = 'dealer_vehicle_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Priority = 'priority',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export interface DealerVehicleNote_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<DealerVehicleNote_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealerVehicleNote_Bool_Exp;
}

/** aggregated selection of "dealer_vehicles" */
export interface DealerVehicle_Aggregate {
  __typename?: 'DealerVehicle_aggregate';
  aggregate?: Maybe<DealerVehicle_Aggregate_Fields>;
  nodes: Array<DealerVehicle>;
}

/** aggregate fields of "dealer_vehicles" */
export interface DealerVehicle_Aggregate_Fields {
  __typename?: 'DealerVehicle_aggregate_fields';
  avg?: Maybe<DealerVehicle_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<DealerVehicle_Max_Fields>;
  min?: Maybe<DealerVehicle_Min_Fields>;
  stddev?: Maybe<DealerVehicle_Stddev_Fields>;
  stddev_pop?: Maybe<DealerVehicle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DealerVehicle_Stddev_Samp_Fields>;
  sum?: Maybe<DealerVehicle_Sum_Fields>;
  var_pop?: Maybe<DealerVehicle_Var_Pop_Fields>;
  var_samp?: Maybe<DealerVehicle_Var_Samp_Fields>;
  variance?: Maybe<DealerVehicle_Variance_Fields>;
}


/** aggregate fields of "dealer_vehicles" */
export interface DealerVehicle_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<DealerVehicle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface DealerVehicle_Avg_Fields {
  __typename?: 'DealerVehicle_avg_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "dealer_vehicles". All fields are combined with a logical 'AND'. */
export interface DealerVehicle_Bool_Exp {
  _and?: Maybe<Array<DealerVehicle_Bool_Exp>>;
  _not?: Maybe<DealerVehicle_Bool_Exp>;
  _or?: Maybe<Array<DealerVehicle_Bool_Exp>>;
  brand?: Maybe<Brand_Bool_Exp>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  color_exterior_code?: Maybe<String_Comparison_Exp>;
  color_exterior_name?: Maybe<String_Comparison_Exp>;
  color_interior_code?: Maybe<String_Comparison_Exp>;
  color_interior_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<String_Comparison_Exp>;
  dealer_vehicle_owner_information?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
  dealer_vehicle_owner_information_aggregate?: Maybe<Vehicle_Owner_Informations_Aggregate_Bool_Exp>;
  delivery_date_str?: Maybe<String_Comparison_Exp>;
  delivery_location?: Maybe<StorageLocation_Bool_Exp>;
  delivery_location_id?: Maybe<Uuid_Comparison_Exp>;
  engine_number?: Maybe<String_Comparison_Exp>;
  first_registration_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  job_number?: Maybe<String_Comparison_Exp>;
  key_cabinet_number?: Maybe<String_Comparison_Exp>;
  license_plate_number?: Maybe<String_Comparison_Exp>;
  mileage?: Maybe<Int_Comparison_Exp>;
  mileage_date?: Maybe<Date_Comparison_Exp>;
  min_holding_period?: Maybe<Int_Comparison_Exp>;
  model?: Maybe<Model_Bool_Exp>;
  model_description?: Maybe<String_Comparison_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<DealerVehicleNote_Bool_Exp>;
  notes_aggregate?: Maybe<DealerVehicleNote_Aggregate_Bool_Exp>;
  order_state?: Maybe<Order_State_Enum_Comparison_Exp>;
  registration_owner?: Maybe<String_Comparison_Exp>;
  registration_state?: Maybe<Registration_State_Enum_Comparison_Exp>;
  sold_at?: Maybe<Timestamptz_Comparison_Exp>;
  sold_by?: Maybe<String_Comparison_Exp>;
  spec_level?: Maybe<String_Comparison_Exp>;
  specs?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Dealer_Vehicle_State_Enum_Comparison_Exp>;
  storage_location?: Maybe<StorageLocation_Bool_Exp>;
  storage_location_id?: Maybe<Uuid_Comparison_Exp>;
  storage_location_note?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Dealer_Vehicle_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vin?: Maybe<String_Comparison_Exp>;
  warranty_end?: Maybe<Date_Comparison_Exp>;
  warranty_start?: Maybe<Date_Comparison_Exp>;
}

/** unique or primary key constraints on table "dealer_vehicles" */
export enum DealerVehicle_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealerVehiclePkey = 'dealer_vehicle_pkey'
}

/** input type for incrementing numeric columns in table "dealer_vehicles" */
export interface DealerVehicle_Inc_Input {
  mileage?: Maybe<Scalars['Int']>;
  min_holding_period?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "dealer_vehicles" */
export interface DealerVehicle_Insert_Input {
  brand?: Maybe<Brand_Obj_Rel_Insert_Input>;
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  dealer_vehicle_owner_information?: Maybe<Vehicle_Owner_Informations_Arr_Rel_Insert_Input>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location?: Maybe<StorageLocation_Obj_Rel_Insert_Input>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  job_number?: Maybe<Scalars['String']>;
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model?: Maybe<Model_Obj_Rel_Insert_Input>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<DealerVehicleNote_Arr_Rel_Insert_Input>;
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  storage_location?: Maybe<StorageLocation_Obj_Rel_Insert_Input>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate max on columns */
export interface DealerVehicle_Max_Fields {
  __typename?: 'DealerVehicle_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  job_number?: Maybe<Scalars['String']>;
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  registration_owner?: Maybe<Scalars['String']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate min on columns */
export interface DealerVehicle_Min_Fields {
  __typename?: 'DealerVehicle_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  job_number?: Maybe<Scalars['String']>;
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  registration_owner?: Maybe<Scalars['String']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** response of any mutation on the table "dealer_vehicles" */
export interface DealerVehicle_Mutation_Response {
  __typename?: 'DealerVehicle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DealerVehicle>;
}

/** input type for inserting object relation for remote table "dealer_vehicles" */
export interface DealerVehicle_Obj_Rel_Insert_Input {
  data: DealerVehicle_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<DealerVehicle_On_Conflict>;
}

/** on_conflict condition type for table "dealer_vehicles" */
export interface DealerVehicle_On_Conflict {
  constraint: DealerVehicle_Constraint;
  update_columns: Array<DealerVehicle_Update_Column>;
  where?: Maybe<DealerVehicle_Bool_Exp>;
}

/** Ordering options when selecting data from "dealer_vehicles". */
export interface DealerVehicle_Order_By {
  brand?: Maybe<Brand_Order_By>;
  brand_id?: Maybe<Order_By>;
  color_exterior_code?: Maybe<Order_By>;
  color_exterior_name?: Maybe<Order_By>;
  color_interior_code?: Maybe<Order_By>;
  color_interior_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  dealer_vehicle_owner_information_aggregate?: Maybe<Vehicle_Owner_Informations_Aggregate_Order_By>;
  delivery_date_str?: Maybe<Order_By>;
  delivery_location?: Maybe<StorageLocation_Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  engine_number?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  key_cabinet_number?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  model?: Maybe<Model_Order_By>;
  model_description?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  notes_aggregate?: Maybe<DealerVehicleNote_Aggregate_Order_By>;
  order_state?: Maybe<Order_By>;
  registration_owner?: Maybe<Order_By>;
  registration_state?: Maybe<Order_By>;
  sold_at?: Maybe<Order_By>;
  sold_by?: Maybe<Order_By>;
  spec_level?: Maybe<Order_By>;
  specs?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  storage_location?: Maybe<StorageLocation_Order_By>;
  storage_location_id?: Maybe<Order_By>;
  storage_location_note?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
}

/** primary key columns input for table: dealer_vehicles */
export interface DealerVehicle_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "dealer_vehicles" */
export enum DealerVehicle_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  ColorExteriorCode = 'color_exterior_code',
  /** column name */
  ColorExteriorName = 'color_exterior_name',
  /** column name */
  ColorInteriorCode = 'color_interior_code',
  /** column name */
  ColorInteriorName = 'color_interior_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customer = 'customer',
  /** column name */
  DeliveryDateStr = 'delivery_date_str',
  /** column name */
  DeliveryLocationId = 'delivery_location_id',
  /** column name */
  EngineNumber = 'engine_number',
  /** column name */
  FirstRegistrationDate = 'first_registration_date',
  /** column name */
  Id = 'id',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  KeyCabinetNumber = 'key_cabinet_number',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  MinHoldingPeriod = 'min_holding_period',
  /** column name */
  ModelDescription = 'model_description',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  OrderState = 'order_state',
  /** column name */
  RegistrationOwner = 'registration_owner',
  /** column name */
  RegistrationState = 'registration_state',
  /** column name */
  SoldAt = 'sold_at',
  /** column name */
  SoldBy = 'sold_by',
  /** column name */
  SpecLevel = 'spec_level',
  /** column name */
  Specs = 'specs',
  /** column name */
  State = 'state',
  /** column name */
  StorageLocationId = 'storage_location_id',
  /** column name */
  StorageLocationNote = 'storage_location_note',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vin = 'vin',
  /** column name */
  WarrantyEnd = 'warranty_end',
  /** column name */
  WarrantyStart = 'warranty_start'
}

/** input type for updating data in table "dealer_vehicles" */
export interface DealerVehicle_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  job_number?: Maybe<Scalars['String']>;
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate stddev on columns */
export interface DealerVehicle_Stddev_Fields {
  __typename?: 'DealerVehicle_stddev_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface DealerVehicle_Stddev_Pop_Fields {
  __typename?: 'DealerVehicle_stddev_pop_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface DealerVehicle_Stddev_Samp_Fields {
  __typename?: 'DealerVehicle_stddev_samp_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "DealerVehicle" */
export interface DealerVehicle_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: DealerVehicle_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface DealerVehicle_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  job_number?: Maybe<Scalars['String']>;
  key_cabinet_number?: Maybe<Scalars['String']>;
  license_plate_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<Scalars['String']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate sum on columns */
export interface DealerVehicle_Sum_Fields {
  __typename?: 'DealerVehicle_sum_fields';
  mileage?: Maybe<Scalars['Int']>;
  min_holding_period?: Maybe<Scalars['Int']>;
}

/** update columns of table "dealer_vehicles" */
export enum DealerVehicle_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  ColorExteriorCode = 'color_exterior_code',
  /** column name */
  ColorExteriorName = 'color_exterior_name',
  /** column name */
  ColorInteriorCode = 'color_interior_code',
  /** column name */
  ColorInteriorName = 'color_interior_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customer = 'customer',
  /** column name */
  DeliveryDateStr = 'delivery_date_str',
  /** column name */
  DeliveryLocationId = 'delivery_location_id',
  /** column name */
  EngineNumber = 'engine_number',
  /** column name */
  FirstRegistrationDate = 'first_registration_date',
  /** column name */
  Id = 'id',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  KeyCabinetNumber = 'key_cabinet_number',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  MinHoldingPeriod = 'min_holding_period',
  /** column name */
  ModelDescription = 'model_description',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  OrderState = 'order_state',
  /** column name */
  RegistrationOwner = 'registration_owner',
  /** column name */
  RegistrationState = 'registration_state',
  /** column name */
  SoldAt = 'sold_at',
  /** column name */
  SoldBy = 'sold_by',
  /** column name */
  SpecLevel = 'spec_level',
  /** column name */
  Specs = 'specs',
  /** column name */
  State = 'state',
  /** column name */
  StorageLocationId = 'storage_location_id',
  /** column name */
  StorageLocationNote = 'storage_location_note',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vin = 'vin',
  /** column name */
  WarrantyEnd = 'warranty_end',
  /** column name */
  WarrantyStart = 'warranty_start'
}

export interface DealerVehicle_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<DealerVehicle_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<DealerVehicle_Set_Input>;
  /** filter the rows which have to be updated */
  where: DealerVehicle_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface DealerVehicle_Var_Pop_Fields {
  __typename?: 'DealerVehicle_var_pop_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface DealerVehicle_Var_Samp_Fields {
  __typename?: 'DealerVehicle_var_samp_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface DealerVehicle_Variance_Fields {
  __typename?: 'DealerVehicle_variance_fields';
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "departments" */
export interface Department {
  __typename?: 'Department';
  description: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  roles: Array<Department_Role>;
  /** An aggregate relationship */
  roles_aggregate: Department_Role_Aggregate;
}


/** columns and relationships of "departments" */
export interface DepartmentRolesArgs {
  distinct_on?: Maybe<Array<Department_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Department_Role_Order_By>>;
  where?: Maybe<Department_Role_Bool_Exp>;
}


/** columns and relationships of "departments" */
export interface DepartmentRoles_AggregateArgs {
  distinct_on?: Maybe<Array<Department_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Department_Role_Order_By>>;
  where?: Maybe<Department_Role_Bool_Exp>;
}

/** aggregated selection of "departments" */
export interface Department_Aggregate {
  __typename?: 'Department_aggregate';
  aggregate?: Maybe<Department_Aggregate_Fields>;
  nodes: Array<Department>;
}

/** aggregate fields of "departments" */
export interface Department_Aggregate_Fields {
  __typename?: 'Department_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Department_Max_Fields>;
  min?: Maybe<Department_Min_Fields>;
}


/** aggregate fields of "departments" */
export interface Department_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Department_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "departments". All fields are combined with a logical 'AND'. */
export interface Department_Bool_Exp {
  _and?: Maybe<Array<Department_Bool_Exp>>;
  _not?: Maybe<Department_Bool_Exp>;
  _or?: Maybe<Array<Department_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  roles?: Maybe<Department_Role_Bool_Exp>;
  roles_aggregate?: Maybe<Department_Role_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "departments" */
export enum Department_Constraint {
  /** unique or primary key constraint on columns "id" */
  DepartmentsPkey = 'departments_pkey'
}

/** input type for inserting data into table "departments" */
export interface Department_Insert_Input {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Department_Role_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface Department_Max_Fields {
  __typename?: 'Department_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Department_Min_Fields {
  __typename?: 'Department_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "departments" */
export interface Department_Mutation_Response {
  __typename?: 'Department_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Department>;
}

/** input type for inserting object relation for remote table "departments" */
export interface Department_Obj_Rel_Insert_Input {
  data: Department_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Department_On_Conflict>;
}

/** on_conflict condition type for table "departments" */
export interface Department_On_Conflict {
  constraint: Department_Constraint;
  update_columns: Array<Department_Update_Column>;
  where?: Maybe<Department_Bool_Exp>;
}

/** Ordering options when selecting data from "departments". */
export interface Department_Order_By {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  roles_aggregate?: Maybe<Department_Role_Aggregate_Order_By>;
}

/** primary key columns input for table: departments */
export interface Department_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "departments" */
export enum Department_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "departments" */
export interface Department_Set_Input {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "Department" */
export interface Department_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Department_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Department_Stream_Cursor_Value_Input {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "departments" */
export enum Department_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Department_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Department_Set_Input>;
  /** filter the rows which have to be updated */
  where: Department_Bool_Exp;
}

/** columns and relationships of "dms_reference" */
export interface DmsReference {
  __typename?: 'DmsReference';
  id: Scalars['uuid'];
  location_customer_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  location_supplier_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  reference: Scalars['String'];
  subdealer_customer_id?: Maybe<Scalars['uuid']>;
  supplier_customer_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
  type: Dms_Type_Enum;
}

/** aggregated selection of "dms_reference" */
export interface DmsReference_Aggregate {
  __typename?: 'DmsReference_aggregate';
  aggregate?: Maybe<DmsReference_Aggregate_Fields>;
  nodes: Array<DmsReference>;
}

export interface DmsReference_Aggregate_Bool_Exp {
  count?: Maybe<DmsReference_Aggregate_Bool_Exp_Count>;
}

export interface DmsReference_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<DmsReference_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<DmsReference_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "dms_reference" */
export interface DmsReference_Aggregate_Fields {
  __typename?: 'DmsReference_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DmsReference_Max_Fields>;
  min?: Maybe<DmsReference_Min_Fields>;
}


/** aggregate fields of "dms_reference" */
export interface DmsReference_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<DmsReference_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "dms_reference" */
export interface DmsReference_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<DmsReference_Max_Order_By>;
  min?: Maybe<DmsReference_Min_Order_By>;
}

/** input type for inserting array relation for remote table "dms_reference" */
export interface DmsReference_Arr_Rel_Insert_Input {
  data: Array<DmsReference_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<DmsReference_On_Conflict>;
}

/** Boolean expression to filter rows from the table "dms_reference". All fields are combined with a logical 'AND'. */
export interface DmsReference_Bool_Exp {
  _and?: Maybe<Array<DmsReference_Bool_Exp>>;
  _not?: Maybe<DmsReference_Bool_Exp>;
  _or?: Maybe<Array<DmsReference_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location_customer_id?: Maybe<Uuid_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  location_supplier_id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  subdealer_customer_id?: Maybe<Uuid_Comparison_Exp>;
  supplier_customer_id?: Maybe<Uuid_Comparison_Exp>;
  supplier_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<Dms_Type_Enum_Comparison_Exp>;
}

/** unique or primary key constraints on table "dms_reference" */
export enum DmsReference_Constraint {
  /** unique or primary key constraint on columns "id" */
  DmsReferencePkey = 'dms_reference_pkey'
}

/** input type for inserting data into table "dms_reference" */
export interface DmsReference_Insert_Input {
  id?: Maybe<Scalars['uuid']>;
  location_customer_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  location_supplier_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  subdealer_customer_id?: Maybe<Scalars['uuid']>;
  supplier_customer_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Dms_Type_Enum>;
}

/** aggregate max on columns */
export interface DmsReference_Max_Fields {
  __typename?: 'DmsReference_max_fields';
  id?: Maybe<Scalars['uuid']>;
  location_customer_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  location_supplier_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  subdealer_customer_id?: Maybe<Scalars['uuid']>;
  supplier_customer_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "dms_reference" */
export interface DmsReference_Max_Order_By {
  id?: Maybe<Order_By>;
  location_customer_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  location_supplier_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  subdealer_customer_id?: Maybe<Order_By>;
  supplier_customer_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface DmsReference_Min_Fields {
  __typename?: 'DmsReference_min_fields';
  id?: Maybe<Scalars['uuid']>;
  location_customer_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  location_supplier_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  subdealer_customer_id?: Maybe<Scalars['uuid']>;
  supplier_customer_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "dms_reference" */
export interface DmsReference_Min_Order_By {
  id?: Maybe<Order_By>;
  location_customer_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  location_supplier_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  subdealer_customer_id?: Maybe<Order_By>;
  supplier_customer_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "dms_reference" */
export interface DmsReference_Mutation_Response {
  __typename?: 'DmsReference_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DmsReference>;
}

/** on_conflict condition type for table "dms_reference" */
export interface DmsReference_On_Conflict {
  constraint: DmsReference_Constraint;
  update_columns: Array<DmsReference_Update_Column>;
  where?: Maybe<DmsReference_Bool_Exp>;
}

/** Ordering options when selecting data from "dms_reference". */
export interface DmsReference_Order_By {
  id?: Maybe<Order_By>;
  location_customer_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  location_supplier_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  subdealer_customer_id?: Maybe<Order_By>;
  supplier_customer_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
}

/** primary key columns input for table: dms_reference */
export interface DmsReference_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "dms_reference" */
export enum DmsReference_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationCustomerId = 'location_customer_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LocationSupplierId = 'location_supplier_id',
  /** column name */
  Name = 'name',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubdealerCustomerId = 'subdealer_customer_id',
  /** column name */
  SupplierCustomerId = 'supplier_customer_id',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "dms_reference" */
export interface DmsReference_Set_Input {
  id?: Maybe<Scalars['uuid']>;
  location_customer_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  location_supplier_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  subdealer_customer_id?: Maybe<Scalars['uuid']>;
  supplier_customer_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Dms_Type_Enum>;
}

/** Streaming cursor of the table "DmsReference" */
export interface DmsReference_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: DmsReference_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface DmsReference_Stream_Cursor_Value_Input {
  id?: Maybe<Scalars['uuid']>;
  location_customer_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  location_supplier_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  subdealer_customer_id?: Maybe<Scalars['uuid']>;
  supplier_customer_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Dms_Type_Enum>;
}

/** update columns of table "dms_reference" */
export enum DmsReference_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationCustomerId = 'location_customer_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LocationSupplierId = 'location_supplier_id',
  /** column name */
  Name = 'name',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubdealerCustomerId = 'subdealer_customer_id',
  /** column name */
  SupplierCustomerId = 'supplier_customer_id',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  Type = 'type'
}

export interface DmsReference_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<DmsReference_Set_Input>;
  /** filter the rows which have to be updated */
  where: DmsReference_Bool_Exp;
}

export interface ExecuteImportJobInput {
  id: Scalars['uuid'];
}

export interface ExecuteImportJobPayload {
  __typename?: 'ExecuteImportJobPayload';
  status: ResponseStatus;
}

export interface ExportAfterSalesPotentialsInput {
  brandIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  locationId?: Maybe<Scalars['uuid']>;
  month?: Maybe<Scalars['Int']>;
  taskCategory: Scalars['String'];
  type: AfterSalesPotentialsExportType;
  year: Scalars['Int'];
}

export interface ExportAfterSalesPotentialsOutput {
  __typename?: 'ExportAfterSalesPotentialsOutput';
  download_url: Scalars['String'];
}

export enum ExportDealerVehiclesVariant {
  Hyundai = 'HYUNDAI',
  Import = 'IMPORT',
  Mitsubishi = 'MITSUBISHI'
}

export interface ExportVehiclesAddressBoolExp {
  _and?: Maybe<Array<ExportVehiclesAddressBoolExp>>;
  _not?: Maybe<ExportVehiclesAddressBoolExp>;
  _or?: Maybe<Array<ExportVehiclesAddressBoolExp>>;
  city?: Maybe<ExportVehiclesStringComparisonExp>;
  countryCode?: Maybe<ExportVehiclesStringComparisonExp>;
  fullAddress?: Maybe<ExportVehiclesStringComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  street?: Maybe<ExportVehiclesStringComparisonExp>;
  streetNo?: Maybe<ExportVehiclesStringComparisonExp>;
  zipCode?: Maybe<ExportVehiclesStringComparisonExp>;
}

export enum ExportVehiclesAfterSalesStatusEnum {
  AlreadyDone = 'ALREADY_DONE',
  AppointmentBooked = 'APPOINTMENT_BOOKED',
  CustomerNotReached = 'CUSTOMER_NOT_REACHED',
  CustomerWillCallBack = 'CUSTOMER_WILL_CALL_BACK',
  Done = 'DONE',
  NotRequired = 'NOT_REQUIRED',
  Open = 'OPEN'
}

export interface ExportVehiclesAfterSalesStatusEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesAfterSalesStatusEnum>;
  _in?: Maybe<Array<ExportVehiclesAfterSalesStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesAfterSalesStatusEnum>;
  _nin?: Maybe<Array<ExportVehiclesAfterSalesStatusEnum>>;
}

export interface ExportVehiclesAftersalespotentialBoolExp {
  _and?: Maybe<Array<ExportVehiclesAftersalespotentialBoolExp>>;
  _not?: Maybe<ExportVehiclesAftersalespotentialBoolExp>;
  _or?: Maybe<Array<ExportVehiclesAftersalespotentialBoolExp>>;
  contact_notes?: Maybe<ExportVehiclesContactnoteBoolExp>;
  customer?: Maybe<ExportVehiclesCustomerBoolExp>;
  customer_city?: Maybe<ExportVehiclesStringComparisonExp>;
  customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  customer_name?: Maybe<ExportVehiclesStringComparisonExp>;
  customer_vehicle?: Maybe<ExportVehiclesCustomervehicleBoolExp>;
  customer_vehicle_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  customer_zip_code?: Maybe<ExportVehiclesStringComparisonExp>;
  first_registration_date?: Maybe<ExportVehiclesDateComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  last_inspection_sticker_date?: Maybe<ExportVehiclesDateComparisonExp>;
  last_note?: Maybe<ExportVehiclesStringComparisonExp>;
  last_service_date?: Maybe<ExportVehiclesDateComparisonExp>;
  license_plate_number?: Maybe<ExportVehiclesStringComparisonExp>;
  location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  mileage?: Maybe<ExportVehiclesIntComparisonExp>;
  mileage_date?: Maybe<ExportVehiclesDateComparisonExp>;
  month?: Maybe<ExportVehiclesIntComparisonExp>;
  status?: Maybe<ExportVehiclesAfterSalesStatusEnumComparisonExp>;
  task_category?: Maybe<ExportVehiclesTaskCategoryEnumComparisonExp>;
  warranty_end?: Maybe<ExportVehiclesDateComparisonExp>;
  warranty_start?: Maybe<ExportVehiclesDateComparisonExp>;
  year?: Maybe<ExportVehiclesIntComparisonExp>;
}

export interface ExportVehiclesAppointmentBoolExp {
  _and?: Maybe<Array<ExportVehiclesAppointmentBoolExp>>;
  _not?: Maybe<ExportVehiclesAppointmentBoolExp>;
  _or?: Maybe<Array<ExportVehiclesAppointmentBoolExp>>;
  bring_at?: Maybe<ExportVehiclesDateComparisonExp>;
  customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  customer_vehicle_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  description?: Maybe<ExportVehiclesStringComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  return_at?: Maybe<ExportVehiclesDateComparisonExp>;
}

export interface ExportVehiclesBooleanComparisonExp {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
}

export interface ExportVehiclesBrandBoolExp {
  _and?: Maybe<Array<ExportVehiclesBrandBoolExp>>;
  _not?: Maybe<ExportVehiclesBrandBoolExp>;
  _or?: Maybe<Array<ExportVehiclesBrandBoolExp>>;
  afterSalesSuppliers?: Maybe<ExportVehiclesSupplierBoolExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  is_house_brand?: Maybe<ExportVehiclesBooleanComparisonExp>;
  name?: Maybe<ExportVehiclesStringComparisonExp>;
  salesSuppliers?: Maybe<ExportVehiclesSupplierBoolExp>;
  vehicle_metafields?: Maybe<ExportVehiclesBrandVehicleMetafieldBoolExp>;
}

export interface ExportVehiclesBrandVehicleMetafieldBoolExp {
  _and?: Maybe<Array<ExportVehiclesBrandVehicleMetafieldBoolExp>>;
  _not?: Maybe<ExportVehiclesBrandVehicleMetafieldBoolExp>;
  _or?: Maybe<Array<ExportVehiclesBrandVehicleMetafieldBoolExp>>;
  brand_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  default_value?: Maybe<ExportVehiclesStringComparisonExp>;
  required_for_import?: Maybe<ExportVehiclesBooleanComparisonExp>;
  vehicle_metafield?: Maybe<ExportVehiclesVehicleMetafieldsBoolExp>;
  vehicle_metafield_name?: Maybe<ExportVehiclesVehicleMetafieldsEnumComparisonExp>;
}

export enum ExportVehiclesContactNoteTypeEnum {
  CustomerNotReached = 'CUSTOMER_NOT_REACHED',
  CustomerWillCallBack = 'CUSTOMER_WILL_CALL_BACK',
  Generic = 'GENERIC'
}

export interface ExportVehiclesContactNoteTypeEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesContactNoteTypeEnum>;
  _in?: Maybe<Array<ExportVehiclesContactNoteTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesContactNoteTypeEnum>;
  _nin?: Maybe<Array<ExportVehiclesContactNoteTypeEnum>>;
}

export interface ExportVehiclesContactnoteBoolExp {
  _and?: Maybe<Array<ExportVehiclesContactnoteBoolExp>>;
  _not?: Maybe<ExportVehiclesContactnoteBoolExp>;
  _or?: Maybe<Array<ExportVehiclesContactnoteBoolExp>>;
  after_sales_potential_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  created_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  customer_vehicle_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  note?: Maybe<ExportVehiclesStringComparisonExp>;
  type?: Maybe<ExportVehiclesContactNoteTypeEnumComparisonExp>;
}

export interface ExportVehiclesCustomerBoolExp {
  _and?: Maybe<Array<ExportVehiclesCustomerBoolExp>>;
  _not?: Maybe<ExportVehiclesCustomerBoolExp>;
  _or?: Maybe<Array<ExportVehiclesCustomerBoolExp>>;
  after_sales_potentials?: Maybe<ExportVehiclesAftersalespotentialBoolExp>;
  appointments?: Maybe<ExportVehiclesAppointmentBoolExp>;
  birthday?: Maybe<ExportVehiclesDateComparisonExp>;
  city?: Maybe<ExportVehiclesStringComparisonExp>;
  contact_notes?: Maybe<ExportVehiclesContactnoteBoolExp>;
  country_code?: Maybe<ExportVehiclesStringComparisonExp>;
  created_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  customer_no?: Maybe<ExportVehiclesStringComparisonExp>;
  customer_tags?: Maybe<ExportVehiclesCustomerCustomerTagBoolExp>;
  email?: Maybe<ExportVehiclesStringComparisonExp>;
  family_name?: Maybe<ExportVehiclesStringComparisonExp>;
  first_name?: Maybe<ExportVehiclesStringComparisonExp>;
  gdpr_infos?: Maybe<ExportVehiclesCustomergdprinfoBoolExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  is_natural_person?: Maybe<ExportVehiclesBooleanComparisonExp>;
  name?: Maybe<ExportVehiclesStringComparisonExp>;
  name_postfix?: Maybe<ExportVehiclesStringComparisonExp>;
  name_prefix?: Maybe<ExportVehiclesStringComparisonExp>;
  phone_numbers?: Maybe<ExportVehiclesPhonenumberBoolExp>;
  qualified_for_email?: Maybe<ExportVehiclesBooleanComparisonExp>;
  qualified_for_letter?: Maybe<ExportVehiclesBooleanComparisonExp>;
  qualified_for_sms?: Maybe<ExportVehiclesBooleanComparisonExp>;
  salutations?: Maybe<ExportVehiclesCustomerSalutationBoolExp>;
  street?: Maybe<ExportVehiclesStringComparisonExp>;
  vehicles?: Maybe<ExportVehiclesCustomervehicleBoolExp>;
  zip_code?: Maybe<ExportVehiclesStringComparisonExp>;
}

export interface ExportVehiclesCustomerCustomerTagBoolExp {
  _and?: Maybe<Array<ExportVehiclesCustomerCustomerTagBoolExp>>;
  _not?: Maybe<ExportVehiclesCustomerCustomerTagBoolExp>;
  _or?: Maybe<Array<ExportVehiclesCustomerCustomerTagBoolExp>>;
  customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  customer_tag_value?: Maybe<ExportVehiclesCustomerTagEnumComparisonExp>;
}

export interface ExportVehiclesCustomerSalutationBoolExp {
  _and?: Maybe<Array<ExportVehiclesCustomerSalutationBoolExp>>;
  _not?: Maybe<ExportVehiclesCustomerSalutationBoolExp>;
  _or?: Maybe<Array<ExportVehiclesCustomerSalutationBoolExp>>;
  customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  salutation_type_value?: Maybe<ExportVehiclesSalutationTypeEnumComparisonExp>;
  text?: Maybe<ExportVehiclesStringComparisonExp>;
}

export enum ExportVehiclesCustomerTagEnum {
  CarDealer = 'CAR_DEALER',
  Cross = 'CROSS',
  Deceased = 'DECEASED',
  Duplicate = 'DUPLICATE',
  Insurance = 'INSURANCE',
  Leasing = 'LEASING',
  LostCustomer = 'LOST_CUSTOMER',
  Moved = 'MOVED',
  NoAds = 'NO_ADS',
  NoContact = 'NO_CONTACT',
  NoNewsletter = 'NO_NEWSLETTER',
  NoSelection = 'NO_SELECTION',
  NoService = 'NO_SERVICE',
  PotentialCustomer = 'POTENTIAL_CUSTOMER',
  SubDealer = 'SUB_DEALER'
}

export interface ExportVehiclesCustomerTagEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesCustomerTagEnum>;
  _in?: Maybe<Array<ExportVehiclesCustomerTagEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesCustomerTagEnum>;
  _nin?: Maybe<Array<ExportVehiclesCustomerTagEnum>>;
}

export interface ExportVehiclesCustomergdprinfoBoolExp {
  _and?: Maybe<Array<ExportVehiclesCustomergdprinfoBoolExp>>;
  _not?: Maybe<ExportVehiclesCustomergdprinfoBoolExp>;
  _or?: Maybe<Array<ExportVehiclesCustomergdprinfoBoolExp>>;
  customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  email?: Maybe<ExportVehiclesBooleanComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  mail?: Maybe<ExportVehiclesBooleanComparisonExp>;
  phone?: Maybe<ExportVehiclesBooleanComparisonExp>;
  signed_at?: Maybe<ExportVehiclesDateComparisonExp>;
  sms?: Maybe<ExportVehiclesBooleanComparisonExp>;
}

export interface ExportVehiclesCustomervehicleBoolExp {
  _and?: Maybe<Array<ExportVehiclesCustomervehicleBoolExp>>;
  _not?: Maybe<ExportVehiclesCustomervehicleBoolExp>;
  _or?: Maybe<Array<ExportVehiclesCustomervehicleBoolExp>>;
  brand?: Maybe<ExportVehiclesBrandBoolExp>;
  brand_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  color_exterior_name?: Maybe<ExportVehiclesStringComparisonExp>;
  customer?: Maybe<ExportVehiclesCustomerBoolExp>;
  customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  first_registration_date?: Maybe<ExportVehiclesDateComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  license_plate_number?: Maybe<ExportVehiclesStringComparisonExp>;
  location?: Maybe<ExportVehiclesLocationBoolExp>;
  location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  mileage?: Maybe<ExportVehiclesIntComparisonExp>;
  mileage_date?: Maybe<ExportVehiclesDateComparisonExp>;
  model?: Maybe<ExportVehiclesModelBoolExp>;
  model_description?: Maybe<ExportVehiclesStringComparisonExp>;
  model_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  vin?: Maybe<ExportVehiclesStringComparisonExp>;
  warranty_end?: Maybe<ExportVehiclesDateComparisonExp>;
  warranty_start?: Maybe<ExportVehiclesDateComparisonExp>;
  work_orders?: Maybe<ExportVehiclesWorkOrdersBoolExp>;
}

export interface ExportVehiclesDateComparisonExp {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
}

export enum ExportVehiclesDealerVehicleStateEnum {
  Available = 'AVAILABLE',
  OnSiteNeed = 'ON_SITE_NEED',
  Other = 'OTHER',
  Reserved = 'RESERVED',
  Sold = 'SOLD',
  SoldByBroker = 'SOLD_BY_BROKER',
  SoldByPartner = 'SOLD_BY_PARTNER',
  SoldToPartner = 'SOLD_TO_PARTNER'
}

export interface ExportVehiclesDealerVehicleStateEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesDealerVehicleStateEnum>;
  _in?: Maybe<Array<ExportVehiclesDealerVehicleStateEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesDealerVehicleStateEnum>;
  _nin?: Maybe<Array<ExportVehiclesDealerVehicleStateEnum>>;
}

export enum ExportVehiclesDealerVehicleTypeEnum {
  DemonstrationCar = 'DEMONSTRATION_CAR',
  NewCar = 'NEW_CAR',
  Unknown = 'UNKNOWN',
  UsedCar = 'USED_CAR'
}

export interface ExportVehiclesDealerVehicleTypeEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesDealerVehicleTypeEnum>;
  _in?: Maybe<Array<ExportVehiclesDealerVehicleTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesDealerVehicleTypeEnum>;
  _nin?: Maybe<Array<ExportVehiclesDealerVehicleTypeEnum>>;
}

export enum ExportVehiclesDmsTypeEnum {
  Locosoft = 'LOCOSOFT'
}

export interface ExportVehiclesDmsTypeEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesDmsTypeEnum>;
  _in?: Maybe<Array<ExportVehiclesDmsTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesDmsTypeEnum>;
  _nin?: Maybe<Array<ExportVehiclesDmsTypeEnum>>;
}

export interface ExportVehiclesDmsreferenceBoolExp {
  _and?: Maybe<Array<ExportVehiclesDmsreferenceBoolExp>>;
  _not?: Maybe<ExportVehiclesDmsreferenceBoolExp>;
  _or?: Maybe<Array<ExportVehiclesDmsreferenceBoolExp>>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  location_customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  location_supplier_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  name?: Maybe<ExportVehiclesStringComparisonExp>;
  reference?: Maybe<ExportVehiclesStringComparisonExp>;
  subdealer_customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  supplier_customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  supplier_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  type?: Maybe<ExportVehiclesDmsTypeEnumComparisonExp>;
}

export enum ExportVehiclesImportTypeEnum {
  Full = 'FULL',
  Partial = 'PARTIAL'
}

export interface ExportVehiclesImportTypeEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesImportTypeEnum>;
  _in?: Maybe<Array<ExportVehiclesImportTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesImportTypeEnum>;
  _nin?: Maybe<Array<ExportVehiclesImportTypeEnum>>;
}

export enum ExportVehiclesInBuyChannelEnum {
  Buyback = 'BUYBACK',
  Commission = 'COMMISSION',
  Exchange = 'EXCHANGE',
  FreePurchase = 'FREE_PURCHASE',
  Import = 'IMPORT',
  UnknownUsedCarBuyIn = 'UNKNOWN_USED_CAR_BUY_IN'
}

export interface ExportVehiclesInBuyChannelEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesInBuyChannelEnum>;
  _in?: Maybe<Array<ExportVehiclesInBuyChannelEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesInBuyChannelEnum>;
  _nin?: Maybe<Array<ExportVehiclesInBuyChannelEnum>>;
}

export interface ExportVehiclesIntComparisonExp {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
}

export interface ExportVehiclesLocationBoolExp {
  _and?: Maybe<Array<ExportVehiclesLocationBoolExp>>;
  _not?: Maybe<ExportVehiclesLocationBoolExp>;
  _or?: Maybe<Array<ExportVehiclesLocationBoolExp>>;
  brandReferences?: Maybe<ExportVehiclesLocationbrandreferenceBoolExp>;
  customerDmsReferences?: Maybe<ExportVehiclesDmsreferenceBoolExp>;
  dmsReferences?: Maybe<ExportVehiclesDmsreferenceBoolExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  locationGroups?: Maybe<ExportVehiclesLocationgroupBoolExp>;
  name?: Maybe<ExportVehiclesStringComparisonExp>;
  short_name?: Maybe<ExportVehiclesStringComparisonExp>;
  supplierDmsReferences?: Maybe<ExportVehiclesDmsreferenceBoolExp>;
}

export interface ExportVehiclesLocationGroupBrandBoolExp {
  _and?: Maybe<Array<ExportVehiclesLocationGroupBrandBoolExp>>;
  _not?: Maybe<ExportVehiclesLocationGroupBrandBoolExp>;
  _or?: Maybe<Array<ExportVehiclesLocationGroupBrandBoolExp>>;
  brand_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  location_group_id?: Maybe<ExportVehiclesUuidComparisonExp>;
}

export interface ExportVehiclesLocationGroupBrandsViewBoolExp {
  _and?: Maybe<Array<ExportVehiclesLocationGroupBrandsViewBoolExp>>;
  _not?: Maybe<ExportVehiclesLocationGroupBrandsViewBoolExp>;
  _or?: Maybe<Array<ExportVehiclesLocationGroupBrandsViewBoolExp>>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  location_group_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  name?: Maybe<ExportVehiclesStringComparisonExp>;
}

export interface ExportVehiclesLocationbrandreferenceBoolExp {
  _and?: Maybe<Array<ExportVehiclesLocationbrandreferenceBoolExp>>;
  _not?: Maybe<ExportVehiclesLocationbrandreferenceBoolExp>;
  _or?: Maybe<Array<ExportVehiclesLocationbrandreferenceBoolExp>>;
  after_sales?: Maybe<ExportVehiclesBooleanComparisonExp>;
  brand?: Maybe<ExportVehiclesBrandBoolExp>;
  brand_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  isPrimaryDealer?: Maybe<ExportVehiclesBooleanComparisonExp>;
  location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  sales?: Maybe<ExportVehiclesBooleanComparisonExp>;
}

export interface ExportVehiclesLocationgroupBoolExp {
  _and?: Maybe<Array<ExportVehiclesLocationgroupBoolExp>>;
  _not?: Maybe<ExportVehiclesLocationgroupBoolExp>;
  _or?: Maybe<Array<ExportVehiclesLocationgroupBoolExp>>;
  brands?: Maybe<ExportVehiclesLocationGroupBrandsViewBoolExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  location_group_brands?: Maybe<ExportVehiclesLocationGroupBrandBoolExp>;
  location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  name?: Maybe<ExportVehiclesStringComparisonExp>;
}

export interface ExportVehiclesModelBoolExp {
  _and?: Maybe<Array<ExportVehiclesModelBoolExp>>;
  _not?: Maybe<ExportVehiclesModelBoolExp>;
  _or?: Maybe<Array<ExportVehiclesModelBoolExp>>;
  brand_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  created_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  description?: Maybe<ExportVehiclesStringComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  modelGroup?: Maybe<ExportVehiclesModelgroupBoolExp>;
  model_code?: Maybe<ExportVehiclesStringComparisonExp>;
  model_group_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  model_group_release_year?: Maybe<ExportVehiclesIntComparisonExp>;
  sales_model_code?: Maybe<ExportVehiclesStringComparisonExp>;
  updated_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
}

export interface ExportVehiclesModelGroupReleaseYearsBoolExp {
  _and?: Maybe<Array<ExportVehiclesModelGroupReleaseYearsBoolExp>>;
  _not?: Maybe<ExportVehiclesModelGroupReleaseYearsBoolExp>;
  _or?: Maybe<Array<ExportVehiclesModelGroupReleaseYearsBoolExp>>;
  model_group_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  release_year?: Maybe<ExportVehiclesIntComparisonExp>;
}

export interface ExportVehiclesModelgroupBoolExp {
  _and?: Maybe<Array<ExportVehiclesModelgroupBoolExp>>;
  _not?: Maybe<ExportVehiclesModelgroupBoolExp>;
  _or?: Maybe<Array<ExportVehiclesModelgroupBoolExp>>;
  brand_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  default_release_year?: Maybe<ExportVehiclesIntComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  models?: Maybe<ExportVehiclesModelBoolExp>;
  name?: Maybe<ExportVehiclesStringComparisonExp>;
  releaseYears?: Maybe<ExportVehiclesModelGroupReleaseYearsBoolExp>;
}

export enum ExportVehiclesOrderStateEnum {
  InStock = 'IN_STOCK',
  InTransport = 'IN_TRANSPORT'
}

export interface ExportVehiclesOrderStateEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesOrderStateEnum>;
  _in?: Maybe<Array<ExportVehiclesOrderStateEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesOrderStateEnum>;
  _nin?: Maybe<Array<ExportVehiclesOrderStateEnum>>;
}

export interface ExportVehiclesOutput {
  __typename?: 'ExportVehiclesOutput';
  download_url: Scalars['String'];
}

export enum ExportVehiclesPhoneNumberTypeEnum {
  Landline = 'LANDLINE',
  Mobile = 'MOBILE'
}

export interface ExportVehiclesPhoneNumberTypeEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesPhoneNumberTypeEnum>;
  _in?: Maybe<Array<ExportVehiclesPhoneNumberTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesPhoneNumberTypeEnum>;
  _nin?: Maybe<Array<ExportVehiclesPhoneNumberTypeEnum>>;
}

export interface ExportVehiclesPhonenumberBoolExp {
  _and?: Maybe<Array<ExportVehiclesPhonenumberBoolExp>>;
  _not?: Maybe<ExportVehiclesPhonenumberBoolExp>;
  _or?: Maybe<Array<ExportVehiclesPhonenumberBoolExp>>;
  customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  description?: Maybe<ExportVehiclesStringComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  number?: Maybe<ExportVehiclesStringComparisonExp>;
  sms_enabled?: Maybe<ExportVehiclesBooleanComparisonExp>;
  type?: Maybe<ExportVehiclesPhoneNumberTypeEnumComparisonExp>;
}

export enum ExportVehiclesRegistrationStateEnum {
  Deposited = 'DEPOSITED',
  None = 'NONE',
  Registered = 'REGISTERED',
  Unregistered = 'UNREGISTERED'
}

export interface ExportVehiclesRegistrationStateEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesRegistrationStateEnum>;
  _in?: Maybe<Array<ExportVehiclesRegistrationStateEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesRegistrationStateEnum>;
  _nin?: Maybe<Array<ExportVehiclesRegistrationStateEnum>>;
}

export enum ExportVehiclesSalutationTypeEnum {
  Generic = 'GENERIC',
  Greeting = 'GREETING',
  Receiver = 'RECEIVER'
}

export interface ExportVehiclesSalutationTypeEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesSalutationTypeEnum>;
  _in?: Maybe<Array<ExportVehiclesSalutationTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesSalutationTypeEnum>;
  _nin?: Maybe<Array<ExportVehiclesSalutationTypeEnum>>;
}

export interface ExportVehiclesStoragelocationBoolExp {
  _and?: Maybe<Array<ExportVehiclesStoragelocationBoolExp>>;
  _not?: Maybe<ExportVehiclesStoragelocationBoolExp>;
  _or?: Maybe<Array<ExportVehiclesStoragelocationBoolExp>>;
  default_note?: Maybe<ExportVehiclesStringComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  location?: Maybe<ExportVehiclesLocationBoolExp>;
  location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  name?: Maybe<ExportVehiclesStringComparisonExp>;
  subdealer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
}

export interface ExportVehiclesStringComparisonExp {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _niregex?: Maybe<Scalars['String']>;
  _nlike?: Maybe<Scalars['String']>;
  _nregex?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _regex?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
}

export interface ExportVehiclesSupplierBoolExp {
  _and?: Maybe<Array<ExportVehiclesSupplierBoolExp>>;
  _not?: Maybe<ExportVehiclesSupplierBoolExp>;
  _or?: Maybe<Array<ExportVehiclesSupplierBoolExp>>;
  active?: Maybe<ExportVehiclesBooleanComparisonExp>;
  address?: Maybe<ExportVehiclesAddressBoolExp>;
  address_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  customerDmsReferences?: Maybe<ExportVehiclesDmsreferenceBoolExp>;
  dmsReferences?: Maybe<ExportVehiclesDmsreferenceBoolExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  name?: Maybe<ExportVehiclesStringComparisonExp>;
}

export interface ExportVehiclesSyncErrorsBoolExp {
  _and?: Maybe<Array<ExportVehiclesSyncErrorsBoolExp>>;
  _not?: Maybe<ExportVehiclesSyncErrorsBoolExp>;
  _or?: Maybe<Array<ExportVehiclesSyncErrorsBoolExp>>;
  business_case_number?: Maybe<ExportVehiclesIntComparisonExp>;
  cause?: Maybe<ExportVehiclesStringComparisonExp>;
  first_occured?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  latest_ocurrence?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  message?: Maybe<ExportVehiclesStringComparisonExp>;
  prevailing?: Maybe<ExportVehiclesBooleanComparisonExp>;
  resolved?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  transaction_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  vehicle_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  vehicle_version?: Maybe<ExportVehiclesIntComparisonExp>;
}

export enum ExportVehiclesTaskCategoryEnum {
  InspectionSticker = 'INSPECTION_STICKER',
  Service = 'SERVICE'
}

export interface ExportVehiclesTaskCategoryEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesTaskCategoryEnum>;
  _in?: Maybe<Array<ExportVehiclesTaskCategoryEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesTaskCategoryEnum>;
  _nin?: Maybe<Array<ExportVehiclesTaskCategoryEnum>>;
}

export interface ExportVehiclesTimestamptzComparisonExp {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
}

export interface ExportVehiclesTransactionsBoolExp {
  _and?: Maybe<Array<ExportVehiclesTransactionsBoolExp>>;
  _not?: Maybe<ExportVehiclesTransactionsBoolExp>;
  _or?: Maybe<Array<ExportVehiclesTransactionsBoolExp>>;
  business_case_number?: Maybe<ExportVehiclesIntComparisonExp>;
  buyer_commision_number?: Maybe<ExportVehiclesStringComparisonExp>;
  buyer_customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  buyer_location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  buyer_supplier_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  cancelled_date?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  cancelled_transaction_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  created_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  generated_vehicle_version?: Maybe<ExportVehiclesIntComparisonExp>;
  holder_customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  holder_location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  holder_supplier_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  invoice_date?: Maybe<ExportVehiclesDateComparisonExp>;
  invoice_number?: Maybe<ExportVehiclesStringComparisonExp>;
  job_number?: Maybe<ExportVehiclesStringComparisonExp>;
  mileage?: Maybe<ExportVehiclesIntComparisonExp>;
  mileage_date?: Maybe<ExportVehiclesDateComparisonExp>;
  seller_commision_number?: Maybe<ExportVehiclesStringComparisonExp>;
  seller_customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  seller_location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  seller_supplier_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  sync_errors?: Maybe<ExportVehiclesSyncErrorsBoolExp>;
  updated_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  vehicle_id?: Maybe<ExportVehiclesUuidComparisonExp>;
}

export interface ExportVehiclesUserBoolExp {
  _and?: Maybe<Array<ExportVehiclesUserBoolExp>>;
  _not?: Maybe<ExportVehiclesUserBoolExp>;
  _or?: Maybe<Array<ExportVehiclesUserBoolExp>>;
  abbreviation?: Maybe<ExportVehiclesStringComparisonExp>;
  email?: Maybe<ExportVehiclesStringComparisonExp>;
  first_name?: Maybe<ExportVehiclesStringComparisonExp>;
  full_name?: Maybe<ExportVehiclesStringComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  isActive?: Maybe<ExportVehiclesBooleanComparisonExp>;
  last_login?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  last_name?: Maybe<ExportVehiclesStringComparisonExp>;
  role?: Maybe<ExportVehiclesUserRoleEnumComparisonExp>;
}

export enum ExportVehiclesUserRoleEnum {
  Admin = 'admin',
  Sales = 'sales',
  SalesManager = 'sales_manager',
  User = 'user',
  VehicleDispatcher = 'vehicle_dispatcher'
}

export interface ExportVehiclesUserRoleEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesUserRoleEnum>;
  _in?: Maybe<Array<ExportVehiclesUserRoleEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesUserRoleEnum>;
  _nin?: Maybe<Array<ExportVehiclesUserRoleEnum>>;
}

export interface ExportVehiclesUuidComparisonExp {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
}

export interface ExportVehiclesVehicleBoolExp {
  _and?: Maybe<Array<ExportVehiclesVehicleBoolExp>>;
  _not?: Maybe<ExportVehiclesVehicleBoolExp>;
  _or?: Maybe<Array<ExportVehiclesVehicleBoolExp>>;
  accessories_mounted?: Maybe<ExportVehiclesBooleanComparisonExp>;
  archived?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  brand?: Maybe<ExportVehiclesBrandBoolExp>;
  brand_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  business_case_number?: Maybe<ExportVehiclesIntComparisonExp>;
  color_exterior_code?: Maybe<ExportVehiclesStringComparisonExp>;
  color_exterior_name?: Maybe<ExportVehiclesStringComparisonExp>;
  color_interior_code?: Maybe<ExportVehiclesStringComparisonExp>;
  color_interior_name?: Maybe<ExportVehiclesStringComparisonExp>;
  control_number?: Maybe<ExportVehiclesStringComparisonExp>;
  created_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  customer?: Maybe<ExportVehiclesStringComparisonExp>;
  delivery_date_str?: Maybe<ExportVehiclesStringComparisonExp>;
  delivery_location?: Maybe<ExportVehiclesStoragelocationBoolExp>;
  delivery_location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  engine_number?: Maybe<ExportVehiclesStringComparisonExp>;
  first_registration_date?: Maybe<ExportVehiclesDateComparisonExp>;
  generated_by?: Maybe<ExportVehiclesTransactionsBoolExp>;
  history?: Maybe<ExportVehiclesVehicleBoolExp>;
  holder_customer?: Maybe<ExportVehiclesCustomerBoolExp>;
  holder_customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  holder_location?: Maybe<ExportVehiclesLocationBoolExp>;
  holder_location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  holder_supplier?: Maybe<ExportVehiclesSupplierBoolExp>;
  holder_supplier_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  import_type?: Maybe<ExportVehiclesImportTypeEnumComparisonExp>;
  imported?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  in_buy_channel?: Maybe<ExportVehiclesInBuyChannelEnumComparisonExp>;
  job_number?: Maybe<ExportVehiclesStringComparisonExp>;
  key_kabinet_number?: Maybe<ExportVehiclesStringComparisonExp>;
  license_plate_number?: Maybe<ExportVehiclesStringComparisonExp>;
  marked_for_delete?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  mileage?: Maybe<ExportVehiclesIntComparisonExp>;
  mileage_date?: Maybe<ExportVehiclesDateComparisonExp>;
  min_holding_period?: Maybe<ExportVehiclesIntComparisonExp>;
  model?: Maybe<ExportVehiclesModelBoolExp>;
  model_description?: Maybe<ExportVehiclesStringComparisonExp>;
  model_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  model_name?: Maybe<ExportVehiclesStringComparisonExp>;
  notes?: Maybe<ExportVehiclesVehiclenoteBoolExp>;
  order_code?: Maybe<ExportVehiclesStringComparisonExp>;
  order_state?: Maybe<ExportVehiclesOrderStateEnumComparisonExp>;
  owner_customer?: Maybe<ExportVehiclesCustomerBoolExp>;
  owner_customer_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  owner_location?: Maybe<ExportVehiclesLocationBoolExp>;
  owner_location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  owner_supplier?: Maybe<ExportVehiclesSupplierBoolExp>;
  owner_supplier_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  registration_owner?: Maybe<ExportVehiclesStringComparisonExp>;
  registration_state?: Maybe<ExportVehiclesRegistrationStateEnumComparisonExp>;
  sold_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  sold_by?: Maybe<ExportVehiclesUserBoolExp>;
  sold_by_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  spec_level?: Maybe<ExportVehiclesStringComparisonExp>;
  specs?: Maybe<ExportVehiclesStringComparisonExp>;
  state?: Maybe<ExportVehiclesDealerVehicleStateEnumComparisonExp>;
  storage_location?: Maybe<ExportVehiclesStoragelocationBoolExp>;
  storage_location_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  storage_location_note?: Maybe<ExportVehiclesStringComparisonExp>;
  sync_error?: Maybe<ExportVehiclesBooleanComparisonExp>;
  sync_errors?: Maybe<ExportVehiclesSyncErrorsBoolExp>;
  type?: Maybe<ExportVehiclesDealerVehicleTypeEnumComparisonExp>;
  updated_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  version?: Maybe<ExportVehiclesIntComparisonExp>;
  vin?: Maybe<ExportVehiclesStringComparisonExp>;
  warranty_end?: Maybe<ExportVehiclesDateComparisonExp>;
  warranty_start?: Maybe<ExportVehiclesDateComparisonExp>;
}

export interface ExportVehiclesVehicleMetafieldsBoolExp {
  _and?: Maybe<Array<ExportVehiclesVehicleMetafieldsBoolExp>>;
  _not?: Maybe<ExportVehiclesVehicleMetafieldsBoolExp>;
  _or?: Maybe<Array<ExportVehiclesVehicleMetafieldsBoolExp>>;
  column_name?: Maybe<ExportVehiclesStringComparisonExp>;
  title?: Maybe<ExportVehiclesStringComparisonExp>;
}

export enum ExportVehiclesVehicleMetafieldsEnum {
  /** Zubehör montiert */
  AccessoriesMounted = 'accessories_mounted',
  /** Kontrollnummer */
  ControlNumber = 'control_number',
  /** Bestellcode */
  OrderCode = 'order_code'
}

export interface ExportVehiclesVehicleMetafieldsEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesVehicleMetafieldsEnum>;
  _in?: Maybe<Array<ExportVehiclesVehicleMetafieldsEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesVehicleMetafieldsEnum>;
  _nin?: Maybe<Array<ExportVehiclesVehicleMetafieldsEnum>>;
}

export interface ExportVehiclesVehiclenoteBoolExp {
  _and?: Maybe<Array<ExportVehiclesVehiclenoteBoolExp>>;
  _not?: Maybe<ExportVehiclesVehiclenoteBoolExp>;
  _or?: Maybe<Array<ExportVehiclesVehiclenoteBoolExp>>;
  archived?: Maybe<ExportVehiclesBooleanComparisonExp>;
  business_case_number?: Maybe<ExportVehiclesIntComparisonExp>;
  created_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  note?: Maybe<ExportVehiclesStringComparisonExp>;
  priority?: Maybe<ExportVehiclesBooleanComparisonExp>;
  sticky?: Maybe<ExportVehiclesBooleanComparisonExp>;
  updated_at?: Maybe<ExportVehiclesTimestamptzComparisonExp>;
  user?: Maybe<ExportVehiclesUserBoolExp>;
  user_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  vehicle?: Maybe<ExportVehiclesVehicleBoolExp>;
  vehicle_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  vehicle_version?: Maybe<ExportVehiclesIntComparisonExp>;
}

export enum ExportVehiclesWorkOrderStatusEnum {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export interface ExportVehiclesWorkOrderStatusEnumComparisonExp {
  _eq?: Maybe<ExportVehiclesWorkOrderStatusEnum>;
  _in?: Maybe<Array<ExportVehiclesWorkOrderStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ExportVehiclesWorkOrderStatusEnum>;
  _nin?: Maybe<Array<ExportVehiclesWorkOrderStatusEnum>>;
}

export interface ExportVehiclesWorkOrderTaskCategoryBoolExp {
  _and?: Maybe<Array<ExportVehiclesWorkOrderTaskCategoryBoolExp>>;
  _not?: Maybe<ExportVehiclesWorkOrderTaskCategoryBoolExp>;
  _or?: Maybe<Array<ExportVehiclesWorkOrderTaskCategoryBoolExp>>;
  task_category?: Maybe<ExportVehiclesTaskCategoryEnumComparisonExp>;
  work_order_id?: Maybe<ExportVehiclesUuidComparisonExp>;
}

export interface ExportVehiclesWorkOrdersBoolExp {
  _and?: Maybe<Array<ExportVehiclesWorkOrdersBoolExp>>;
  _not?: Maybe<ExportVehiclesWorkOrdersBoolExp>;
  _or?: Maybe<Array<ExportVehiclesWorkOrdersBoolExp>>;
  customer_vehicle?: Maybe<ExportVehiclesCustomervehicleBoolExp>;
  customer_vehicle_id?: Maybe<ExportVehiclesUuidComparisonExp>;
  date?: Maybe<ExportVehiclesDateComparisonExp>;
  id?: Maybe<ExportVehiclesUuidComparisonExp>;
  order_no?: Maybe<ExportVehiclesStringComparisonExp>;
  status?: Maybe<ExportVehiclesWorkOrderStatusEnumComparisonExp>;
  task_categories_edges?: Maybe<ExportVehiclesWorkOrderTaskCategoryBoolExp>;
}

export interface ImportDealerVehiclesInput {
  ids: Array<Scalars['uuid']>;
}

export interface ImportDealerVehiclesOutput {
  __typename?: 'ImportDealerVehiclesOutput';
  affected_ids: Array<Scalars['uuid']>;
}

export interface ImportJobInput {
  id: Scalars['uuid'];
  vehicle_variant: Scalars['Boolean'];
}

export interface ImportJobPayload {
  __typename?: 'ImportJobPayload';
  id: Scalars['uuid'];
  import_job?: Maybe<Import_Jobs>;
  url: Scalars['String'];
}

export interface ImportJobUploadUrlPayload {
  __typename?: 'ImportJobUploadUrlPayload';
  key: Scalars['String'];
  uploadUrl: Scalars['String'];
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface Int_Comparison_Exp {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
}

/** columns and relationships of "locations" */
export interface Location {
  __typename?: 'Location';
  /** An array relationship */
  brandReferences: Array<LocationBrandReference>;
  /** An aggregate relationship */
  brandReferences_aggregate: LocationBrandReference_Aggregate;
  /** An array relationship */
  customerDmsReferences: Array<DmsReference>;
  /** An aggregate relationship */
  customerDmsReferences_aggregate: DmsReference_Aggregate;
  /** An array relationship */
  dmsReferences: Array<DmsReference>;
  /** An aggregate relationship */
  dmsReferences_aggregate: DmsReference_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  locationGroups: Array<LocationGroup>;
  /** An aggregate relationship */
  locationGroups_aggregate: LocationGroup_Aggregate;
  name: Scalars['String'];
  short_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  supplierDmsReferences: Array<DmsReference>;
  /** An aggregate relationship */
  supplierDmsReferences_aggregate: DmsReference_Aggregate;
}


/** columns and relationships of "locations" */
export interface LocationBrandReferencesArgs {
  distinct_on?: Maybe<Array<LocationBrandReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationBrandReference_Order_By>>;
  where?: Maybe<LocationBrandReference_Bool_Exp>;
}


/** columns and relationships of "locations" */
export interface LocationBrandReferences_AggregateArgs {
  distinct_on?: Maybe<Array<LocationBrandReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationBrandReference_Order_By>>;
  where?: Maybe<LocationBrandReference_Bool_Exp>;
}


/** columns and relationships of "locations" */
export interface LocationCustomerDmsReferencesArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


/** columns and relationships of "locations" */
export interface LocationCustomerDmsReferences_AggregateArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


/** columns and relationships of "locations" */
export interface LocationDmsReferencesArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


/** columns and relationships of "locations" */
export interface LocationDmsReferences_AggregateArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


/** columns and relationships of "locations" */
export interface LocationLocationGroupsArgs {
  distinct_on?: Maybe<Array<LocationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationGroup_Order_By>>;
  where?: Maybe<LocationGroup_Bool_Exp>;
}


/** columns and relationships of "locations" */
export interface LocationLocationGroups_AggregateArgs {
  distinct_on?: Maybe<Array<LocationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationGroup_Order_By>>;
  where?: Maybe<LocationGroup_Bool_Exp>;
}


/** columns and relationships of "locations" */
export interface LocationSupplierDmsReferencesArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


/** columns and relationships of "locations" */
export interface LocationSupplierDmsReferences_AggregateArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}

/** columns and relationships of "location_brand" */
export interface LocationBrandReference {
  __typename?: 'LocationBrandReference';
  after_sales: Scalars['Boolean'];
  /** An object relationship */
  brand: Brand;
  brand_id: Scalars['uuid'];
  isPrimaryDealer: Scalars['Boolean'];
  location_id: Scalars['uuid'];
  sales: Scalars['Boolean'];
}

/** aggregated selection of "location_brand" */
export interface LocationBrandReference_Aggregate {
  __typename?: 'LocationBrandReference_aggregate';
  aggregate?: Maybe<LocationBrandReference_Aggregate_Fields>;
  nodes: Array<LocationBrandReference>;
}

export interface LocationBrandReference_Aggregate_Bool_Exp {
  bool_and?: Maybe<LocationBrandReference_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<LocationBrandReference_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<LocationBrandReference_Aggregate_Bool_Exp_Count>;
}

export interface LocationBrandReference_Aggregate_Bool_Exp_Bool_And {
  arguments: LocationBrandReference_Select_Column_LocationBrandReference_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<LocationBrandReference_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface LocationBrandReference_Aggregate_Bool_Exp_Bool_Or {
  arguments: LocationBrandReference_Select_Column_LocationBrandReference_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<LocationBrandReference_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface LocationBrandReference_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<LocationBrandReference_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<LocationBrandReference_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "location_brand" */
export interface LocationBrandReference_Aggregate_Fields {
  __typename?: 'LocationBrandReference_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LocationBrandReference_Max_Fields>;
  min?: Maybe<LocationBrandReference_Min_Fields>;
}


/** aggregate fields of "location_brand" */
export interface LocationBrandReference_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<LocationBrandReference_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "location_brand" */
export interface LocationBrandReference_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<LocationBrandReference_Max_Order_By>;
  min?: Maybe<LocationBrandReference_Min_Order_By>;
}

/** input type for inserting array relation for remote table "location_brand" */
export interface LocationBrandReference_Arr_Rel_Insert_Input {
  data: Array<LocationBrandReference_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<LocationBrandReference_On_Conflict>;
}

/** Boolean expression to filter rows from the table "location_brand". All fields are combined with a logical 'AND'. */
export interface LocationBrandReference_Bool_Exp {
  _and?: Maybe<Array<LocationBrandReference_Bool_Exp>>;
  _not?: Maybe<LocationBrandReference_Bool_Exp>;
  _or?: Maybe<Array<LocationBrandReference_Bool_Exp>>;
  after_sales?: Maybe<Boolean_Comparison_Exp>;
  brand?: Maybe<Brand_Bool_Exp>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  isPrimaryDealer?: Maybe<Boolean_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  sales?: Maybe<Boolean_Comparison_Exp>;
}

/** unique or primary key constraints on table "location_brand" */
export enum LocationBrandReference_Constraint {
  /** unique or primary key constraint on columns "brand_id", "location_id" */
  LocationBrandPkey = 'location_brand_pkey'
}

/** input type for inserting data into table "location_brand" */
export interface LocationBrandReference_Insert_Input {
  after_sales?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Brand_Obj_Rel_Insert_Input>;
  brand_id?: Maybe<Scalars['uuid']>;
  isPrimaryDealer?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  sales?: Maybe<Scalars['Boolean']>;
}

/** aggregate max on columns */
export interface LocationBrandReference_Max_Fields {
  __typename?: 'LocationBrandReference_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "location_brand" */
export interface LocationBrandReference_Max_Order_By {
  brand_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface LocationBrandReference_Min_Fields {
  __typename?: 'LocationBrandReference_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "location_brand" */
export interface LocationBrandReference_Min_Order_By {
  brand_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "location_brand" */
export interface LocationBrandReference_Mutation_Response {
  __typename?: 'LocationBrandReference_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LocationBrandReference>;
}

/** on_conflict condition type for table "location_brand" */
export interface LocationBrandReference_On_Conflict {
  constraint: LocationBrandReference_Constraint;
  update_columns: Array<LocationBrandReference_Update_Column>;
  where?: Maybe<LocationBrandReference_Bool_Exp>;
}

/** Ordering options when selecting data from "location_brand". */
export interface LocationBrandReference_Order_By {
  after_sales?: Maybe<Order_By>;
  brand?: Maybe<Brand_Order_By>;
  brand_id?: Maybe<Order_By>;
  isPrimaryDealer?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  sales?: Maybe<Order_By>;
}

/** primary key columns input for table: location_brand */
export interface LocationBrandReference_Pk_Columns_Input {
  brand_id: Scalars['uuid'];
  location_id: Scalars['uuid'];
}

/** select columns of table "location_brand" */
export enum LocationBrandReference_Select_Column {
  /** column name */
  AfterSales = 'after_sales',
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  IsPrimaryDealer = 'isPrimaryDealer',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Sales = 'sales'
}

/** select "LocationBrandReference_aggregate_bool_exp_bool_and_arguments_columns" columns of table "location_brand" */
export enum LocationBrandReference_Select_Column_LocationBrandReference_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AfterSales = 'after_sales',
  /** column name */
  IsPrimaryDealer = 'isPrimaryDealer',
  /** column name */
  Sales = 'sales'
}

/** select "LocationBrandReference_aggregate_bool_exp_bool_or_arguments_columns" columns of table "location_brand" */
export enum LocationBrandReference_Select_Column_LocationBrandReference_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AfterSales = 'after_sales',
  /** column name */
  IsPrimaryDealer = 'isPrimaryDealer',
  /** column name */
  Sales = 'sales'
}

/** input type for updating data in table "location_brand" */
export interface LocationBrandReference_Set_Input {
  after_sales?: Maybe<Scalars['Boolean']>;
  brand_id?: Maybe<Scalars['uuid']>;
  isPrimaryDealer?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  sales?: Maybe<Scalars['Boolean']>;
}

/** Streaming cursor of the table "LocationBrandReference" */
export interface LocationBrandReference_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: LocationBrandReference_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface LocationBrandReference_Stream_Cursor_Value_Input {
  after_sales?: Maybe<Scalars['Boolean']>;
  brand_id?: Maybe<Scalars['uuid']>;
  isPrimaryDealer?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  sales?: Maybe<Scalars['Boolean']>;
}

/** update columns of table "location_brand" */
export enum LocationBrandReference_Update_Column {
  /** column name */
  AfterSales = 'after_sales',
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  IsPrimaryDealer = 'isPrimaryDealer',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Sales = 'sales'
}

export interface LocationBrandReference_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<LocationBrandReference_Set_Input>;
  /** filter the rows which have to be updated */
  where: LocationBrandReference_Bool_Exp;
}

/** columns and relationships of "location_groups" */
export interface LocationGroup {
  __typename?: 'LocationGroup';
  /** An array relationship */
  brands: Array<Location_Group_Brands_View>;
  /** An aggregate relationship */
  brands_aggregate: Location_Group_Brands_View_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  location_group_brands: Array<Location_Group_Brand>;
  /** An aggregate relationship */
  location_group_brands_aggregate: Location_Group_Brand_Aggregate;
  location_id: Scalars['uuid'];
  name: Scalars['String'];
}


/** columns and relationships of "location_groups" */
export interface LocationGroupBrandsArgs {
  distinct_on?: Maybe<Array<Location_Group_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brands_View_Order_By>>;
  where?: Maybe<Location_Group_Brands_View_Bool_Exp>;
}


/** columns and relationships of "location_groups" */
export interface LocationGroupBrands_AggregateArgs {
  distinct_on?: Maybe<Array<Location_Group_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brands_View_Order_By>>;
  where?: Maybe<Location_Group_Brands_View_Bool_Exp>;
}


/** columns and relationships of "location_groups" */
export interface LocationGroupLocation_Group_BrandsArgs {
  distinct_on?: Maybe<Array<Location_Group_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brand_Order_By>>;
  where?: Maybe<Location_Group_Brand_Bool_Exp>;
}


/** columns and relationships of "location_groups" */
export interface LocationGroupLocation_Group_Brands_AggregateArgs {
  distinct_on?: Maybe<Array<Location_Group_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brand_Order_By>>;
  where?: Maybe<Location_Group_Brand_Bool_Exp>;
}

/** aggregated selection of "location_groups" */
export interface LocationGroup_Aggregate {
  __typename?: 'LocationGroup_aggregate';
  aggregate?: Maybe<LocationGroup_Aggregate_Fields>;
  nodes: Array<LocationGroup>;
}

export interface LocationGroup_Aggregate_Bool_Exp {
  count?: Maybe<LocationGroup_Aggregate_Bool_Exp_Count>;
}

export interface LocationGroup_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<LocationGroup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<LocationGroup_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "location_groups" */
export interface LocationGroup_Aggregate_Fields {
  __typename?: 'LocationGroup_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LocationGroup_Max_Fields>;
  min?: Maybe<LocationGroup_Min_Fields>;
}


/** aggregate fields of "location_groups" */
export interface LocationGroup_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<LocationGroup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "location_groups" */
export interface LocationGroup_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<LocationGroup_Max_Order_By>;
  min?: Maybe<LocationGroup_Min_Order_By>;
}

/** input type for inserting array relation for remote table "location_groups" */
export interface LocationGroup_Arr_Rel_Insert_Input {
  data: Array<LocationGroup_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<LocationGroup_On_Conflict>;
}

/** Boolean expression to filter rows from the table "location_groups". All fields are combined with a logical 'AND'. */
export interface LocationGroup_Bool_Exp {
  _and?: Maybe<Array<LocationGroup_Bool_Exp>>;
  _not?: Maybe<LocationGroup_Bool_Exp>;
  _or?: Maybe<Array<LocationGroup_Bool_Exp>>;
  brands?: Maybe<Location_Group_Brands_View_Bool_Exp>;
  brands_aggregate?: Maybe<Location_Group_Brands_View_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location_group_brands?: Maybe<Location_Group_Brand_Bool_Exp>;
  location_group_brands_aggregate?: Maybe<Location_Group_Brand_Aggregate_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "location_groups" */
export enum LocationGroup_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationGroupsPkey = 'location_groups_pkey'
}

/** input type for inserting data into table "location_groups" */
export interface LocationGroup_Insert_Input {
  brands?: Maybe<Location_Group_Brands_View_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  location_group_brands?: Maybe<Location_Group_Brand_Arr_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface LocationGroup_Max_Fields {
  __typename?: 'LocationGroup_max_fields';
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "location_groups" */
export interface LocationGroup_Max_Order_By {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface LocationGroup_Min_Fields {
  __typename?: 'LocationGroup_min_fields';
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "location_groups" */
export interface LocationGroup_Min_Order_By {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** response of any mutation on the table "location_groups" */
export interface LocationGroup_Mutation_Response {
  __typename?: 'LocationGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LocationGroup>;
}

/** on_conflict condition type for table "location_groups" */
export interface LocationGroup_On_Conflict {
  constraint: LocationGroup_Constraint;
  update_columns: Array<LocationGroup_Update_Column>;
  where?: Maybe<LocationGroup_Bool_Exp>;
}

/** Ordering options when selecting data from "location_groups". */
export interface LocationGroup_Order_By {
  brands_aggregate?: Maybe<Location_Group_Brands_View_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  location_group_brands_aggregate?: Maybe<Location_Group_Brand_Aggregate_Order_By>;
  location_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** primary key columns input for table: location_groups */
export interface LocationGroup_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "location_groups" */
export enum LocationGroup_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "location_groups" */
export interface LocationGroup_Set_Input {
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "LocationGroup" */
export interface LocationGroup_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: LocationGroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface LocationGroup_Stream_Cursor_Value_Input {
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "location_groups" */
export enum LocationGroup_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name'
}

export interface LocationGroup_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<LocationGroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: LocationGroup_Bool_Exp;
}

/** aggregated selection of "locations" */
export interface Location_Aggregate {
  __typename?: 'Location_aggregate';
  aggregate?: Maybe<Location_Aggregate_Fields>;
  nodes: Array<Location>;
}

/** aggregate fields of "locations" */
export interface Location_Aggregate_Fields {
  __typename?: 'Location_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Location_Max_Fields>;
  min?: Maybe<Location_Min_Fields>;
}


/** aggregate fields of "locations" */
export interface Location_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Location_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export interface Location_Bool_Exp {
  _and?: Maybe<Array<Location_Bool_Exp>>;
  _not?: Maybe<Location_Bool_Exp>;
  _or?: Maybe<Array<Location_Bool_Exp>>;
  brandReferences?: Maybe<LocationBrandReference_Bool_Exp>;
  brandReferences_aggregate?: Maybe<LocationBrandReference_Aggregate_Bool_Exp>;
  customerDmsReferences?: Maybe<DmsReference_Bool_Exp>;
  customerDmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Bool_Exp>;
  dmsReferences?: Maybe<DmsReference_Bool_Exp>;
  dmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locationGroups?: Maybe<LocationGroup_Bool_Exp>;
  locationGroups_aggregate?: Maybe<LocationGroup_Aggregate_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  short_name?: Maybe<String_Comparison_Exp>;
  supplierDmsReferences?: Maybe<DmsReference_Bool_Exp>;
  supplierDmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "locations" */
export enum Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = 'location_pkey'
}

/** input type for inserting data into table "locations" */
export interface Location_Insert_Input {
  brandReferences?: Maybe<LocationBrandReference_Arr_Rel_Insert_Input>;
  customerDmsReferences?: Maybe<DmsReference_Arr_Rel_Insert_Input>;
  dmsReferences?: Maybe<DmsReference_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  locationGroups?: Maybe<LocationGroup_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  supplierDmsReferences?: Maybe<DmsReference_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface Location_Max_Fields {
  __typename?: 'Location_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Location_Min_Fields {
  __typename?: 'Location_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "locations" */
export interface Location_Mutation_Response {
  __typename?: 'Location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Location>;
}

/** input type for inserting object relation for remote table "locations" */
export interface Location_Obj_Rel_Insert_Input {
  data: Location_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Location_On_Conflict>;
}

/** on_conflict condition type for table "locations" */
export interface Location_On_Conflict {
  constraint: Location_Constraint;
  update_columns: Array<Location_Update_Column>;
  where?: Maybe<Location_Bool_Exp>;
}

/** Ordering options when selecting data from "locations". */
export interface Location_Order_By {
  brandReferences_aggregate?: Maybe<LocationBrandReference_Aggregate_Order_By>;
  customerDmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Order_By>;
  dmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  locationGroups_aggregate?: Maybe<LocationGroup_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  short_name?: Maybe<Order_By>;
  supplierDmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Order_By>;
}

/** primary key columns input for table: locations */
export interface Location_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "locations" */
export enum Location_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShortName = 'short_name'
}

/** input type for updating data in table "locations" */
export interface Location_Set_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "Location" */
export interface Location_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Location_Stream_Cursor_Value_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
}

/** update columns of table "locations" */
export enum Location_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShortName = 'short_name'
}

export interface Location_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Location_Bool_Exp;
}

export interface LocosoftSyncStatesPayload {
  __typename?: 'LocosoftSyncStatesPayload';
  name: Scalars['String'];
  phases: Scalars['Int'];
  status: Scalars['String'];
  syncEntity: SyncEntity;
}

export enum LogLevel {
  All = 'ALL',
  Debug = 'DEBUG',
  Error = 'ERROR',
  Fatal = 'FATAL',
  Info = 'INFO',
  Trace = 'TRACE',
  Warn = 'WARN'
}

export interface MetricDataInput {
  end: Scalars['date'];
  entity: SyncEntity;
  metricType: MetricType;
  period?: Maybe<Scalars['Int']>;
  phase?: Maybe<SyncPhase>;
  start: Scalars['date'];
  syncType: SyncType;
}

export interface MetricDataPayload {
  __typename?: 'MetricDataPayload';
  label?: Maybe<Scalars['String']>;
  x?: Maybe<Array<Maybe<Scalars['date']>>>;
  y?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export enum MetricType {
  ErrorCount = 'ERROR_COUNT',
  InsertsCount = 'INSERTS_COUNT',
  Progress = 'PROGRESS',
  SuccessRate = 'SUCCESS_RATE',
  TotalCount = 'TOTAL_COUNT',
  UpdatesCount = 'UPDATES_COUNT'
}

/** columns and relationships of "models" */
export interface Model {
  __typename?: 'Model';
  brand_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  modelGroup?: Maybe<ModelGroup>;
  model_code: Scalars['String'];
  model_group_id?: Maybe<Scalars['uuid']>;
  model_group_release_year?: Maybe<Scalars['Int']>;
  sales_model_code?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
}

/** columns and relationships of "model_groups" */
export interface ModelGroup {
  __typename?: 'ModelGroup';
  brand_id: Scalars['uuid'];
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** An array relationship */
  models: Array<Model>;
  /** An aggregate relationship */
  models_aggregate: Model_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  releaseYears: Array<Model_Group_Release_Years>;
  /** An aggregate relationship */
  releaseYears_aggregate: Model_Group_Release_Years_Aggregate;
}


/** columns and relationships of "model_groups" */
export interface ModelGroupModelsArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


/** columns and relationships of "model_groups" */
export interface ModelGroupModels_AggregateArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


/** columns and relationships of "model_groups" */
export interface ModelGroupReleaseYearsArgs {
  distinct_on?: Maybe<Array<Model_Group_Release_Years_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Group_Release_Years_Order_By>>;
  where?: Maybe<Model_Group_Release_Years_Bool_Exp>;
}


/** columns and relationships of "model_groups" */
export interface ModelGroupReleaseYears_AggregateArgs {
  distinct_on?: Maybe<Array<Model_Group_Release_Years_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Group_Release_Years_Order_By>>;
  where?: Maybe<Model_Group_Release_Years_Bool_Exp>;
}

/** aggregated selection of "model_groups" */
export interface ModelGroup_Aggregate {
  __typename?: 'ModelGroup_aggregate';
  aggregate?: Maybe<ModelGroup_Aggregate_Fields>;
  nodes: Array<ModelGroup>;
}

/** aggregate fields of "model_groups" */
export interface ModelGroup_Aggregate_Fields {
  __typename?: 'ModelGroup_aggregate_fields';
  avg?: Maybe<ModelGroup_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ModelGroup_Max_Fields>;
  min?: Maybe<ModelGroup_Min_Fields>;
  stddev?: Maybe<ModelGroup_Stddev_Fields>;
  stddev_pop?: Maybe<ModelGroup_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ModelGroup_Stddev_Samp_Fields>;
  sum?: Maybe<ModelGroup_Sum_Fields>;
  var_pop?: Maybe<ModelGroup_Var_Pop_Fields>;
  var_samp?: Maybe<ModelGroup_Var_Samp_Fields>;
  variance?: Maybe<ModelGroup_Variance_Fields>;
}


/** aggregate fields of "model_groups" */
export interface ModelGroup_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<ModelGroup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ModelGroup_Avg_Fields {
  __typename?: 'ModelGroup_avg_fields';
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
}

/** Boolean expression to filter rows from the table "model_groups". All fields are combined with a logical 'AND'. */
export interface ModelGroup_Bool_Exp {
  _and?: Maybe<Array<ModelGroup_Bool_Exp>>;
  _not?: Maybe<ModelGroup_Bool_Exp>;
  _or?: Maybe<Array<ModelGroup_Bool_Exp>>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  default_release_year?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  models?: Maybe<Model_Bool_Exp>;
  models_aggregate?: Maybe<Model_Aggregate_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  releaseYears?: Maybe<Model_Group_Release_Years_Bool_Exp>;
  releaseYears_aggregate?: Maybe<Model_Group_Release_Years_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "model_groups" */
export enum ModelGroup_Constraint {
  /** unique or primary key constraint on columns "id" */
  ModelGroupPkey = 'model_group_pkey'
}

/** input type for inserting data into table "model_groups" */
export interface ModelGroup_Insert_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  models?: Maybe<Model_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  releaseYears?: Maybe<Model_Group_Release_Years_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface ModelGroup_Max_Fields {
  __typename?: 'ModelGroup_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface ModelGroup_Min_Fields {
  __typename?: 'ModelGroup_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "model_groups" */
export interface ModelGroup_Mutation_Response {
  __typename?: 'ModelGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ModelGroup>;
}

/** input type for inserting object relation for remote table "model_groups" */
export interface ModelGroup_Obj_Rel_Insert_Input {
  data: ModelGroup_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<ModelGroup_On_Conflict>;
}

/** on_conflict condition type for table "model_groups" */
export interface ModelGroup_On_Conflict {
  constraint: ModelGroup_Constraint;
  update_columns: Array<ModelGroup_Update_Column>;
  where?: Maybe<ModelGroup_Bool_Exp>;
}

/** Ordering options when selecting data from "model_groups". */
export interface ModelGroup_Order_By {
  brand_id?: Maybe<Order_By>;
  default_release_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  models_aggregate?: Maybe<Model_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  releaseYears_aggregate?: Maybe<Model_Group_Release_Years_Aggregate_Order_By>;
}

/** primary key columns input for table: model_groups */
export interface ModelGroup_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "model_groups" */
export enum ModelGroup_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "model_groups" */
export interface ModelGroup_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface ModelGroup_Stddev_Fields {
  __typename?: 'ModelGroup_stddev_fields';
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
}

/** aggregate stddev_pop on columns */
export interface ModelGroup_Stddev_Pop_Fields {
  __typename?: 'ModelGroup_stddev_pop_fields';
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
}

/** aggregate stddev_samp on columns */
export interface ModelGroup_Stddev_Samp_Fields {
  __typename?: 'ModelGroup_stddev_samp_fields';
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
}

/** Streaming cursor of the table "ModelGroup" */
export interface ModelGroup_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: ModelGroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface ModelGroup_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface ModelGroup_Sum_Fields {
  __typename?: 'ModelGroup_sum_fields';
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
}

/** update columns of table "model_groups" */
export enum ModelGroup_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface ModelGroup_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ModelGroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: ModelGroup_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface ModelGroup_Var_Pop_Fields {
  __typename?: 'ModelGroup_var_pop_fields';
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
}

/** aggregate var_samp on columns */
export interface ModelGroup_Var_Samp_Fields {
  __typename?: 'ModelGroup_var_samp_fields';
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
}

/** aggregate variance on columns */
export interface ModelGroup_Variance_Fields {
  __typename?: 'ModelGroup_variance_fields';
  /** A computed field, executes function "model_group_default_release_year" */
  default_release_year?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "models" */
export interface Model_Aggregate {
  __typename?: 'Model_aggregate';
  aggregate?: Maybe<Model_Aggregate_Fields>;
  nodes: Array<Model>;
}

export interface Model_Aggregate_Bool_Exp {
  count?: Maybe<Model_Aggregate_Bool_Exp_Count>;
}

export interface Model_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Model_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Model_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "models" */
export interface Model_Aggregate_Fields {
  __typename?: 'Model_aggregate_fields';
  avg?: Maybe<Model_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Model_Max_Fields>;
  min?: Maybe<Model_Min_Fields>;
  stddev?: Maybe<Model_Stddev_Fields>;
  stddev_pop?: Maybe<Model_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Model_Stddev_Samp_Fields>;
  sum?: Maybe<Model_Sum_Fields>;
  var_pop?: Maybe<Model_Var_Pop_Fields>;
  var_samp?: Maybe<Model_Var_Samp_Fields>;
  variance?: Maybe<Model_Variance_Fields>;
}


/** aggregate fields of "models" */
export interface Model_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Model_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "models" */
export interface Model_Aggregate_Order_By {
  avg?: Maybe<Model_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Model_Max_Order_By>;
  min?: Maybe<Model_Min_Order_By>;
  stddev?: Maybe<Model_Stddev_Order_By>;
  stddev_pop?: Maybe<Model_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Model_Stddev_Samp_Order_By>;
  sum?: Maybe<Model_Sum_Order_By>;
  var_pop?: Maybe<Model_Var_Pop_Order_By>;
  var_samp?: Maybe<Model_Var_Samp_Order_By>;
  variance?: Maybe<Model_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "models" */
export interface Model_Arr_Rel_Insert_Input {
  data: Array<Model_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Model_On_Conflict>;
}

/** aggregate avg on columns */
export interface Model_Avg_Fields {
  __typename?: 'Model_avg_fields';
  model_group_release_year?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "models" */
export interface Model_Avg_Order_By {
  model_group_release_year?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "models". All fields are combined with a logical 'AND'. */
export interface Model_Bool_Exp {
  _and?: Maybe<Array<Model_Bool_Exp>>;
  _not?: Maybe<Model_Bool_Exp>;
  _or?: Maybe<Array<Model_Bool_Exp>>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  modelGroup?: Maybe<ModelGroup_Bool_Exp>;
  model_code?: Maybe<String_Comparison_Exp>;
  model_group_id?: Maybe<Uuid_Comparison_Exp>;
  model_group_release_year?: Maybe<Int_Comparison_Exp>;
  sales_model_code?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "models" */
export enum Model_Constraint {
  /** unique or primary key constraint on columns "id" */
  ModelPkey = 'model_pkey'
}

/** input type for incrementing numeric columns in table "models" */
export interface Model_Inc_Input {
  model_group_release_year?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "models" */
export interface Model_Insert_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  modelGroup?: Maybe<ModelGroup_Obj_Rel_Insert_Input>;
  model_code?: Maybe<Scalars['String']>;
  model_group_id?: Maybe<Scalars['uuid']>;
  model_group_release_year?: Maybe<Scalars['Int']>;
  sales_model_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Model_Max_Fields {
  __typename?: 'Model_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  model_code?: Maybe<Scalars['String']>;
  model_group_id?: Maybe<Scalars['uuid']>;
  model_group_release_year?: Maybe<Scalars['Int']>;
  sales_model_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "models" */
export interface Model_Max_Order_By {
  brand_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  model_code?: Maybe<Order_By>;
  model_group_id?: Maybe<Order_By>;
  model_group_release_year?: Maybe<Order_By>;
  sales_model_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Model_Min_Fields {
  __typename?: 'Model_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  model_code?: Maybe<Scalars['String']>;
  model_group_id?: Maybe<Scalars['uuid']>;
  model_group_release_year?: Maybe<Scalars['Int']>;
  sales_model_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "models" */
export interface Model_Min_Order_By {
  brand_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  model_code?: Maybe<Order_By>;
  model_group_id?: Maybe<Order_By>;
  model_group_release_year?: Maybe<Order_By>;
  sales_model_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** response of any mutation on the table "models" */
export interface Model_Mutation_Response {
  __typename?: 'Model_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Model>;
}

/** input type for inserting object relation for remote table "models" */
export interface Model_Obj_Rel_Insert_Input {
  data: Model_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Model_On_Conflict>;
}

/** on_conflict condition type for table "models" */
export interface Model_On_Conflict {
  constraint: Model_Constraint;
  update_columns: Array<Model_Update_Column>;
  where?: Maybe<Model_Bool_Exp>;
}

/** Ordering options when selecting data from "models". */
export interface Model_Order_By {
  brand_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modelGroup?: Maybe<ModelGroup_Order_By>;
  model_code?: Maybe<Order_By>;
  model_group_id?: Maybe<Order_By>;
  model_group_release_year?: Maybe<Order_By>;
  sales_model_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** primary key columns input for table: models */
export interface Model_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "models" */
export enum Model_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ModelCode = 'model_code',
  /** column name */
  ModelGroupId = 'model_group_id',
  /** column name */
  ModelGroupReleaseYear = 'model_group_release_year',
  /** column name */
  SalesModelCode = 'sales_model_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "models" */
export interface Model_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  model_code?: Maybe<Scalars['String']>;
  model_group_id?: Maybe<Scalars['uuid']>;
  model_group_release_year?: Maybe<Scalars['Int']>;
  sales_model_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface Model_Stddev_Fields {
  __typename?: 'Model_stddev_fields';
  model_group_release_year?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "models" */
export interface Model_Stddev_Order_By {
  model_group_release_year?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Model_Stddev_Pop_Fields {
  __typename?: 'Model_stddev_pop_fields';
  model_group_release_year?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "models" */
export interface Model_Stddev_Pop_Order_By {
  model_group_release_year?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Model_Stddev_Samp_Fields {
  __typename?: 'Model_stddev_samp_fields';
  model_group_release_year?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "models" */
export interface Model_Stddev_Samp_Order_By {
  model_group_release_year?: Maybe<Order_By>;
}

/** Streaming cursor of the table "Model" */
export interface Model_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Model_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Model_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  model_code?: Maybe<Scalars['String']>;
  model_group_id?: Maybe<Scalars['uuid']>;
  model_group_release_year?: Maybe<Scalars['Int']>;
  sales_model_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate sum on columns */
export interface Model_Sum_Fields {
  __typename?: 'Model_sum_fields';
  model_group_release_year?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "models" */
export interface Model_Sum_Order_By {
  model_group_release_year?: Maybe<Order_By>;
}

/** update columns of table "models" */
export enum Model_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ModelCode = 'model_code',
  /** column name */
  ModelGroupId = 'model_group_id',
  /** column name */
  ModelGroupReleaseYear = 'model_group_release_year',
  /** column name */
  SalesModelCode = 'sales_model_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Model_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Model_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Model_Set_Input>;
  /** filter the rows which have to be updated */
  where: Model_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Model_Var_Pop_Fields {
  __typename?: 'Model_var_pop_fields';
  model_group_release_year?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "models" */
export interface Model_Var_Pop_Order_By {
  model_group_release_year?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Model_Var_Samp_Fields {
  __typename?: 'Model_var_samp_fields';
  model_group_release_year?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "models" */
export interface Model_Var_Samp_Order_By {
  model_group_release_year?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Model_Variance_Fields {
  __typename?: 'Model_variance_fields';
  model_group_release_year?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "models" */
export interface Model_Variance_Order_By {
  model_group_release_year?: Maybe<Order_By>;
}

/** columns and relationships of "notes" */
export interface Notes {
  __typename?: 'Notes';
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  archived: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  priority: Scalars['Boolean'];
  sticky: Scalars['Boolean'];
  type?: Maybe<Contact_Note_Type_Enum>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "notes" */
export interface Notes_Aggregate {
  __typename?: 'Notes_aggregate';
  aggregate?: Maybe<Notes_Aggregate_Fields>;
  nodes: Array<Notes>;
}

export interface Notes_Aggregate_Bool_Exp {
  bool_and?: Maybe<Notes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Notes_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Notes_Aggregate_Bool_Exp_Count>;
}

export interface Notes_Aggregate_Bool_Exp_Bool_And {
  arguments: Notes_Select_Column_Notes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Notes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Notes_Aggregate_Bool_Exp_Bool_Or {
  arguments: Notes_Select_Column_Notes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Notes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Notes_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Notes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Notes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "notes" */
export interface Notes_Aggregate_Fields {
  __typename?: 'Notes_aggregate_fields';
  avg?: Maybe<Notes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Notes_Max_Fields>;
  min?: Maybe<Notes_Min_Fields>;
  stddev?: Maybe<Notes_Stddev_Fields>;
  stddev_pop?: Maybe<Notes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Notes_Stddev_Samp_Fields>;
  sum?: Maybe<Notes_Sum_Fields>;
  var_pop?: Maybe<Notes_Var_Pop_Fields>;
  var_samp?: Maybe<Notes_Var_Samp_Fields>;
  variance?: Maybe<Notes_Variance_Fields>;
}


/** aggregate fields of "notes" */
export interface Notes_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Notes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "notes" */
export interface Notes_Aggregate_Order_By {
  avg?: Maybe<Notes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Notes_Max_Order_By>;
  min?: Maybe<Notes_Min_Order_By>;
  stddev?: Maybe<Notes_Stddev_Order_By>;
  stddev_pop?: Maybe<Notes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Notes_Stddev_Samp_Order_By>;
  sum?: Maybe<Notes_Sum_Order_By>;
  var_pop?: Maybe<Notes_Var_Pop_Order_By>;
  var_samp?: Maybe<Notes_Var_Samp_Order_By>;
  variance?: Maybe<Notes_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "notes" */
export interface Notes_Arr_Rel_Insert_Input {
  data: Array<Notes_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Notes_On_Conflict>;
}

/** aggregate avg on columns */
export interface Notes_Avg_Fields {
  __typename?: 'Notes_avg_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "notes" */
export interface Notes_Avg_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "notes". All fields are combined with a logical 'AND'. */
export interface Notes_Bool_Exp {
  _and?: Maybe<Array<Notes_Bool_Exp>>;
  _not?: Maybe<Notes_Bool_Exp>;
  _or?: Maybe<Array<Notes_Bool_Exp>>;
  after_sales_potential_id?: Maybe<Uuid_Comparison_Exp>;
  archived?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  customer_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  priority?: Maybe<Boolean_Comparison_Exp>;
  sticky?: Maybe<Boolean_Comparison_Exp>;
  type?: Maybe<Contact_Note_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_business_case_number?: Maybe<Int_Comparison_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_version?: Maybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "notes" */
export enum Notes_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotesPkey = 'notes_pkey'
}

/** input type for incrementing numeric columns in table "notes" */
export interface Notes_Inc_Input {
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "notes" */
export interface Notes_Insert_Input {
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  archived?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Boolean']>;
  sticky?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Contact_Note_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface Notes_Max_Fields {
  __typename?: 'Notes_max_fields';
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "notes" */
export interface Notes_Max_Order_By {
  after_sales_potential_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Notes_Min_Fields {
  __typename?: 'Notes_min_fields';
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "notes" */
export interface Notes_Min_Order_By {
  after_sales_potential_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** response of any mutation on the table "notes" */
export interface Notes_Mutation_Response {
  __typename?: 'Notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notes>;
}

/** on_conflict condition type for table "notes" */
export interface Notes_On_Conflict {
  constraint: Notes_Constraint;
  update_columns: Array<Notes_Update_Column>;
  where?: Maybe<Notes_Bool_Exp>;
}

/** Ordering options when selecting data from "notes". */
export interface Notes_Order_By {
  after_sales_potential_id?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  sticky?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** primary key columns input for table: notes */
export interface Notes_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "notes" */
export enum Notes_Select_Column {
  /** column name */
  AfterSalesPotentialId = 'after_sales_potential_id',
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Priority = 'priority',
  /** column name */
  Sticky = 'sticky',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

/** select "Notes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notes" */
export enum Notes_Select_Column_Notes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Priority = 'priority',
  /** column name */
  Sticky = 'sticky'
}

/** select "Notes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notes" */
export enum Notes_Select_Column_Notes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Priority = 'priority',
  /** column name */
  Sticky = 'sticky'
}

/** input type for updating data in table "notes" */
export interface Notes_Set_Input {
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  archived?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Boolean']>;
  sticky?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Contact_Note_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface Notes_Stddev_Fields {
  __typename?: 'Notes_stddev_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "notes" */
export interface Notes_Stddev_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Notes_Stddev_Pop_Fields {
  __typename?: 'Notes_stddev_pop_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "notes" */
export interface Notes_Stddev_Pop_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Notes_Stddev_Samp_Fields {
  __typename?: 'Notes_stddev_samp_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "notes" */
export interface Notes_Stddev_Samp_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Streaming cursor of the table "Notes" */
export interface Notes_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Notes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Notes_Stream_Cursor_Value_Input {
  after_sales_potential_id?: Maybe<Scalars['uuid']>;
  archived?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Boolean']>;
  sticky?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Contact_Note_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface Notes_Sum_Fields {
  __typename?: 'Notes_sum_fields';
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "notes" */
export interface Notes_Sum_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** update columns of table "notes" */
export enum Notes_Update_Column {
  /** column name */
  AfterSalesPotentialId = 'after_sales_potential_id',
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Priority = 'priority',
  /** column name */
  Sticky = 'sticky',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

export interface Notes_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Notes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notes_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Notes_Var_Pop_Fields {
  __typename?: 'Notes_var_pop_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "notes" */
export interface Notes_Var_Pop_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Notes_Var_Samp_Fields {
  __typename?: 'Notes_var_samp_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "notes" */
export interface Notes_Var_Samp_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Notes_Variance_Fields {
  __typename?: 'Notes_variance_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "notes" */
export interface Notes_Variance_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

export interface PaginationCursor {
  __typename?: 'PaginationCursor';
  hasNextPage: Scalars['Boolean'];
  next: Scalars['Int'];
}

/** columns and relationships of "phone_numbers" */
export interface PhoneNumber {
  __typename?: 'PhoneNumber';
  customer_id: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  number: Scalars['String'];
  sms_enabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Phone_Number_Type_Enum>;
}

/** aggregated selection of "phone_numbers" */
export interface PhoneNumber_Aggregate {
  __typename?: 'PhoneNumber_aggregate';
  aggregate?: Maybe<PhoneNumber_Aggregate_Fields>;
  nodes: Array<PhoneNumber>;
}

export interface PhoneNumber_Aggregate_Bool_Exp {
  bool_and?: Maybe<PhoneNumber_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<PhoneNumber_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<PhoneNumber_Aggregate_Bool_Exp_Count>;
}

export interface PhoneNumber_Aggregate_Bool_Exp_Bool_And {
  arguments: PhoneNumber_Select_Column_PhoneNumber_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<PhoneNumber_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface PhoneNumber_Aggregate_Bool_Exp_Bool_Or {
  arguments: PhoneNumber_Select_Column_PhoneNumber_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<PhoneNumber_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface PhoneNumber_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<PhoneNumber_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<PhoneNumber_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "phone_numbers" */
export interface PhoneNumber_Aggregate_Fields {
  __typename?: 'PhoneNumber_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PhoneNumber_Max_Fields>;
  min?: Maybe<PhoneNumber_Min_Fields>;
}


/** aggregate fields of "phone_numbers" */
export interface PhoneNumber_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<PhoneNumber_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "phone_numbers" */
export interface PhoneNumber_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<PhoneNumber_Max_Order_By>;
  min?: Maybe<PhoneNumber_Min_Order_By>;
}

/** input type for inserting array relation for remote table "phone_numbers" */
export interface PhoneNumber_Arr_Rel_Insert_Input {
  data: Array<PhoneNumber_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<PhoneNumber_On_Conflict>;
}

/** Boolean expression to filter rows from the table "phone_numbers". All fields are combined with a logical 'AND'. */
export interface PhoneNumber_Bool_Exp {
  _and?: Maybe<Array<PhoneNumber_Bool_Exp>>;
  _not?: Maybe<PhoneNumber_Bool_Exp>;
  _or?: Maybe<Array<PhoneNumber_Bool_Exp>>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  number?: Maybe<String_Comparison_Exp>;
  sms_enabled?: Maybe<Boolean_Comparison_Exp>;
  type?: Maybe<Phone_Number_Type_Enum_Comparison_Exp>;
}

/** unique or primary key constraints on table "phone_numbers" */
export enum PhoneNumber_Constraint {
  /** unique or primary key constraint on columns "id" */
  PhoneNumbersPkey = 'phone_numbers_pkey'
}

/** input type for inserting data into table "phone_numbers" */
export interface PhoneNumber_Insert_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['String']>;
  sms_enabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Phone_Number_Type_Enum>;
}

/** aggregate max on columns */
export interface PhoneNumber_Max_Fields {
  __typename?: 'PhoneNumber_max_fields';
  customer_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "phone_numbers" */
export interface PhoneNumber_Max_Order_By {
  customer_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface PhoneNumber_Min_Fields {
  __typename?: 'PhoneNumber_min_fields';
  customer_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "phone_numbers" */
export interface PhoneNumber_Min_Order_By {
  customer_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
}

/** response of any mutation on the table "phone_numbers" */
export interface PhoneNumber_Mutation_Response {
  __typename?: 'PhoneNumber_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PhoneNumber>;
}

/** on_conflict condition type for table "phone_numbers" */
export interface PhoneNumber_On_Conflict {
  constraint: PhoneNumber_Constraint;
  update_columns: Array<PhoneNumber_Update_Column>;
  where?: Maybe<PhoneNumber_Bool_Exp>;
}

/** Ordering options when selecting data from "phone_numbers". */
export interface PhoneNumber_Order_By {
  customer_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  sms_enabled?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
}

/** primary key columns input for table: phone_numbers */
export interface PhoneNumber_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "phone_numbers" */
export enum PhoneNumber_Select_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  SmsEnabled = 'sms_enabled',
  /** column name */
  Type = 'type'
}

/** select "PhoneNumber_aggregate_bool_exp_bool_and_arguments_columns" columns of table "phone_numbers" */
export enum PhoneNumber_Select_Column_PhoneNumber_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SmsEnabled = 'sms_enabled'
}

/** select "PhoneNumber_aggregate_bool_exp_bool_or_arguments_columns" columns of table "phone_numbers" */
export enum PhoneNumber_Select_Column_PhoneNumber_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SmsEnabled = 'sms_enabled'
}

/** input type for updating data in table "phone_numbers" */
export interface PhoneNumber_Set_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['String']>;
  sms_enabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Phone_Number_Type_Enum>;
}

/** Streaming cursor of the table "PhoneNumber" */
export interface PhoneNumber_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: PhoneNumber_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface PhoneNumber_Stream_Cursor_Value_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['String']>;
  sms_enabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Phone_Number_Type_Enum>;
}

/** update columns of table "phone_numbers" */
export enum PhoneNumber_Update_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  SmsEnabled = 'sms_enabled',
  /** column name */
  Type = 'type'
}

export interface PhoneNumber_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<PhoneNumber_Set_Input>;
  /** filter the rows which have to be updated */
  where: PhoneNumber_Bool_Exp;
}

export interface PreviewImportJobInput {
  id: Scalars['uuid'];
}

export interface PreviewImportJobPayload {
  __typename?: 'PreviewImportJobPayload';
  status: ResponseStatus;
}

export interface RecoverVehiclesHistoriesInput {
  vehicleIds: Array<Scalars['String']>;
}

export interface RecoverVehiclesHistoriesPayload {
  __typename?: 'RecoverVehiclesHistoriesPayload';
  message: Scalars['String'];
  status: RecoverVehiclesHistoriesStatus;
}

export enum RecoverVehiclesHistoriesStatus {
  Error = 'Error',
  Ok = 'Ok'
}

export interface ResolveVehicleSyncErrorInput {
  transactionId: Scalars['String'];
  vehicleId: Scalars['String'];
}

export interface ResolveVehicleSyncErrorPayload {
  __typename?: 'ResolveVehicleSyncErrorPayload';
  errorMessage?: Maybe<Scalars['String']>;
  status: ResponseStatus;
}

export enum ResponseStatus {
  Error = 'ERROR',
  Success = 'SUCCESS'
}

/** columns and relationships of "storage_locations" */
export interface StorageLocation {
  __typename?: 'StorageLocation';
  default_note?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "storage_locations" */
export interface StorageLocation_Aggregate {
  __typename?: 'StorageLocation_aggregate';
  aggregate?: Maybe<StorageLocation_Aggregate_Fields>;
  nodes: Array<StorageLocation>;
}

/** aggregate fields of "storage_locations" */
export interface StorageLocation_Aggregate_Fields {
  __typename?: 'StorageLocation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<StorageLocation_Max_Fields>;
  min?: Maybe<StorageLocation_Min_Fields>;
}


/** aggregate fields of "storage_locations" */
export interface StorageLocation_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<StorageLocation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "storage_locations". All fields are combined with a logical 'AND'. */
export interface StorageLocation_Bool_Exp {
  _and?: Maybe<Array<StorageLocation_Bool_Exp>>;
  _not?: Maybe<StorageLocation_Bool_Exp>;
  _or?: Maybe<Array<StorageLocation_Bool_Exp>>;
  default_note?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  subdealer_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "storage_locations" */
export enum StorageLocation_Constraint {
  /** unique or primary key constraint on columns "id" */
  StorageLocationsPkey = 'storage_locations_pkey'
}

/** input type for inserting data into table "storage_locations" */
export interface StorageLocation_Insert_Input {
  default_note?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface StorageLocation_Max_Fields {
  __typename?: 'StorageLocation_max_fields';
  default_note?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface StorageLocation_Min_Fields {
  __typename?: 'StorageLocation_min_fields';
  default_note?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "storage_locations" */
export interface StorageLocation_Mutation_Response {
  __typename?: 'StorageLocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StorageLocation>;
}

/** input type for inserting object relation for remote table "storage_locations" */
export interface StorageLocation_Obj_Rel_Insert_Input {
  data: StorageLocation_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<StorageLocation_On_Conflict>;
}

/** on_conflict condition type for table "storage_locations" */
export interface StorageLocation_On_Conflict {
  constraint: StorageLocation_Constraint;
  update_columns: Array<StorageLocation_Update_Column>;
  where?: Maybe<StorageLocation_Bool_Exp>;
}

/** Ordering options when selecting data from "storage_locations". */
export interface StorageLocation_Order_By {
  default_note?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  subdealer_id?: Maybe<Order_By>;
}

/** primary key columns input for table: storage_locations */
export interface StorageLocation_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "storage_locations" */
export enum StorageLocation_Select_Column {
  /** column name */
  DefaultNote = 'default_note',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  SubdealerId = 'subdealer_id'
}

/** input type for updating data in table "storage_locations" */
export interface StorageLocation_Set_Input {
  default_note?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "StorageLocation" */
export interface StorageLocation_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: StorageLocation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface StorageLocation_Stream_Cursor_Value_Input {
  default_note?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "storage_locations" */
export enum StorageLocation_Update_Column {
  /** column name */
  DefaultNote = 'default_note',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  SubdealerId = 'subdealer_id'
}

export interface StorageLocation_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<StorageLocation_Set_Input>;
  /** filter the rows which have to be updated */
  where: StorageLocation_Bool_Exp;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface String_Array_Comparison_Exp {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars['String']>>;
  _eq?: Maybe<Array<Scalars['String']>>;
  _gt?: Maybe<Array<Scalars['String']>>;
  _gte?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Array<Scalars['String']>>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Array<Scalars['String']>>;
  _lte?: Maybe<Array<Scalars['String']>>;
  _neq?: Maybe<Array<Scalars['String']>>;
  _nin?: Maybe<Array<Array<Scalars['String']>>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface String_Comparison_Exp {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
}

/** columns and relationships of "subdealers" */
export interface Subdealer {
  __typename?: 'Subdealer';
  /** An object relationship */
  address: Address;
  address_id: Scalars['uuid'];
  /** An array relationship */
  brands: Array<Subdealer_Brands_View>;
  /** An aggregate relationship */
  brands_aggregate: Subdealer_Brands_View_Aggregate;
  /** An array relationship */
  customerDmsReferences: Array<DmsReference>;
  /** An aggregate relationship */
  customerDmsReferences_aggregate: DmsReference_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  subdealer_brands: Array<Subdealer_Brand>;
  /** An aggregate relationship */
  subdealer_brands_aggregate: Subdealer_Brand_Aggregate;
}


/** columns and relationships of "subdealers" */
export interface SubdealerBrandsArgs {
  distinct_on?: Maybe<Array<Subdealer_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brands_View_Order_By>>;
  where?: Maybe<Subdealer_Brands_View_Bool_Exp>;
}


/** columns and relationships of "subdealers" */
export interface SubdealerBrands_AggregateArgs {
  distinct_on?: Maybe<Array<Subdealer_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brands_View_Order_By>>;
  where?: Maybe<Subdealer_Brands_View_Bool_Exp>;
}


/** columns and relationships of "subdealers" */
export interface SubdealerCustomerDmsReferencesArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


/** columns and relationships of "subdealers" */
export interface SubdealerCustomerDmsReferences_AggregateArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


/** columns and relationships of "subdealers" */
export interface SubdealerSubdealer_BrandsArgs {
  distinct_on?: Maybe<Array<Subdealer_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brand_Order_By>>;
  where?: Maybe<Subdealer_Brand_Bool_Exp>;
}


/** columns and relationships of "subdealers" */
export interface SubdealerSubdealer_Brands_AggregateArgs {
  distinct_on?: Maybe<Array<Subdealer_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brand_Order_By>>;
  where?: Maybe<Subdealer_Brand_Bool_Exp>;
}

/** aggregated selection of "subdealers" */
export interface Subdealer_Aggregate {
  __typename?: 'Subdealer_aggregate';
  aggregate?: Maybe<Subdealer_Aggregate_Fields>;
  nodes: Array<Subdealer>;
}

/** aggregate fields of "subdealers" */
export interface Subdealer_Aggregate_Fields {
  __typename?: 'Subdealer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Subdealer_Max_Fields>;
  min?: Maybe<Subdealer_Min_Fields>;
}


/** aggregate fields of "subdealers" */
export interface Subdealer_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Subdealer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "subdealers". All fields are combined with a logical 'AND'. */
export interface Subdealer_Bool_Exp {
  _and?: Maybe<Array<Subdealer_Bool_Exp>>;
  _not?: Maybe<Subdealer_Bool_Exp>;
  _or?: Maybe<Array<Subdealer_Bool_Exp>>;
  address?: Maybe<Address_Bool_Exp>;
  address_id?: Maybe<Uuid_Comparison_Exp>;
  brands?: Maybe<Subdealer_Brands_View_Bool_Exp>;
  brands_aggregate?: Maybe<Subdealer_Brands_View_Aggregate_Bool_Exp>;
  customerDmsReferences?: Maybe<DmsReference_Bool_Exp>;
  customerDmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  subdealer_brands?: Maybe<Subdealer_Brand_Bool_Exp>;
  subdealer_brands_aggregate?: Maybe<Subdealer_Brand_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "subdealers" */
export enum Subdealer_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubdealerPkey = 'subdealer_pkey'
}

/** input type for inserting data into table "subdealers" */
export interface Subdealer_Insert_Input {
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars['uuid']>;
  brands?: Maybe<Subdealer_Brands_View_Arr_Rel_Insert_Input>;
  customerDmsReferences?: Maybe<DmsReference_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_brands?: Maybe<Subdealer_Brand_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface Subdealer_Max_Fields {
  __typename?: 'Subdealer_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Subdealer_Min_Fields {
  __typename?: 'Subdealer_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "subdealers" */
export interface Subdealer_Mutation_Response {
  __typename?: 'Subdealer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subdealer>;
}

/** on_conflict condition type for table "subdealers" */
export interface Subdealer_On_Conflict {
  constraint: Subdealer_Constraint;
  update_columns: Array<Subdealer_Update_Column>;
  where?: Maybe<Subdealer_Bool_Exp>;
}

/** Ordering options when selecting data from "subdealers". */
export interface Subdealer_Order_By {
  address?: Maybe<Address_Order_By>;
  address_id?: Maybe<Order_By>;
  brands_aggregate?: Maybe<Subdealer_Brands_View_Aggregate_Order_By>;
  customerDmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  subdealer_brands_aggregate?: Maybe<Subdealer_Brand_Aggregate_Order_By>;
}

/** primary key columns input for table: subdealers */
export interface Subdealer_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "subdealers" */
export enum Subdealer_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "subdealers" */
export interface Subdealer_Set_Input {
  address_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "Subdealer" */
export interface Subdealer_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Subdealer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Subdealer_Stream_Cursor_Value_Input {
  address_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "subdealers" */
export enum Subdealer_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Subdealer_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Subdealer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subdealer_Bool_Exp;
}

/** columns and relationships of "suppliers" */
export interface Supplier {
  __typename?: 'Supplier';
  active: Scalars['Boolean'];
  /** An object relationship */
  address: Address;
  address_id: Scalars['uuid'];
  /** An array relationship */
  customerDmsReferences: Array<DmsReference>;
  /** An aggregate relationship */
  customerDmsReferences_aggregate: DmsReference_Aggregate;
  /** An array relationship */
  dmsReferences: Array<DmsReference>;
  /** An aggregate relationship */
  dmsReferences_aggregate: DmsReference_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
}


/** columns and relationships of "suppliers" */
export interface SupplierCustomerDmsReferencesArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


/** columns and relationships of "suppliers" */
export interface SupplierCustomerDmsReferences_AggregateArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


/** columns and relationships of "suppliers" */
export interface SupplierDmsReferencesArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


/** columns and relationships of "suppliers" */
export interface SupplierDmsReferences_AggregateArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}

/** aggregated selection of "suppliers" */
export interface Supplier_Aggregate {
  __typename?: 'Supplier_aggregate';
  aggregate?: Maybe<Supplier_Aggregate_Fields>;
  nodes: Array<Supplier>;
}

/** aggregate fields of "suppliers" */
export interface Supplier_Aggregate_Fields {
  __typename?: 'Supplier_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Supplier_Max_Fields>;
  min?: Maybe<Supplier_Min_Fields>;
}


/** aggregate fields of "suppliers" */
export interface Supplier_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Supplier_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "suppliers" */
export interface Supplier_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Supplier_Max_Order_By>;
  min?: Maybe<Supplier_Min_Order_By>;
}

/** Boolean expression to filter rows from the table "suppliers". All fields are combined with a logical 'AND'. */
export interface Supplier_Bool_Exp {
  _and?: Maybe<Array<Supplier_Bool_Exp>>;
  _not?: Maybe<Supplier_Bool_Exp>;
  _or?: Maybe<Array<Supplier_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  address?: Maybe<Address_Bool_Exp>;
  address_id?: Maybe<Uuid_Comparison_Exp>;
  customerDmsReferences?: Maybe<DmsReference_Bool_Exp>;
  customerDmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Bool_Exp>;
  dmsReferences?: Maybe<DmsReference_Bool_Exp>;
  dmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "suppliers" */
export enum Supplier_Constraint {
  /** unique or primary key constraint on columns "id" */
  SuppliersPkey = 'suppliers_pkey'
}

/** input type for inserting data into table "suppliers" */
export interface Supplier_Insert_Input {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars['uuid']>;
  customerDmsReferences?: Maybe<DmsReference_Arr_Rel_Insert_Input>;
  dmsReferences?: Maybe<DmsReference_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Supplier_Max_Fields {
  __typename?: 'Supplier_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "suppliers" */
export interface Supplier_Max_Order_By {
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Supplier_Min_Fields {
  __typename?: 'Supplier_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "suppliers" */
export interface Supplier_Min_Order_By {
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** response of any mutation on the table "suppliers" */
export interface Supplier_Mutation_Response {
  __typename?: 'Supplier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Supplier>;
}

/** input type for inserting object relation for remote table "suppliers" */
export interface Supplier_Obj_Rel_Insert_Input {
  data: Supplier_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Supplier_On_Conflict>;
}

/** on_conflict condition type for table "suppliers" */
export interface Supplier_On_Conflict {
  constraint: Supplier_Constraint;
  update_columns: Array<Supplier_Update_Column>;
  where?: Maybe<Supplier_Bool_Exp>;
}

/** Ordering options when selecting data from "suppliers". */
export interface Supplier_Order_By {
  active?: Maybe<Order_By>;
  address?: Maybe<Address_Order_By>;
  address_id?: Maybe<Order_By>;
  customerDmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Order_By>;
  dmsReferences_aggregate?: Maybe<DmsReference_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** primary key columns input for table: suppliers */
export interface Supplier_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "suppliers" */
export enum Supplier_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "suppliers" */
export interface Supplier_Set_Input {
  active?: Maybe<Scalars['Boolean']>;
  address_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "Supplier" */
export interface Supplier_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Supplier_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Supplier_Stream_Cursor_Value_Input {
  active?: Maybe<Scalars['Boolean']>;
  address_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "suppliers" */
export enum Supplier_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Supplier_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Supplier_Set_Input>;
  /** filter the rows which have to be updated */
  where: Supplier_Bool_Exp;
}

export enum SyncEntity {
  Appointments = 'APPOINTMENTS',
  Brands = 'BRANDS',
  Customers = 'CUSTOMERS',
  CustomerVehicles = 'CUSTOMER_VEHICLES',
  LaborTypes = 'LABOR_TYPES',
  Locations = 'LOCATIONS',
  Models = 'MODELS',
  Orders = 'ORDERS',
  Vehicles = 'VEHICLES'
}

export enum SyncPhase {
  Prep = 'PREP',
  Sync = 'SYNC'
}

export enum SyncType {
  Delta = 'DELTA',
  Full = 'FULL'
}

/** columns and relationships of "task_types" */
export interface TaskType {
  __typename?: 'TaskType';
  /** An array relationship */
  categoryReferences: Array<Task_Type_Task_Category>;
  /** An aggregate relationship */
  categoryReferences_aggregate: Task_Type_Task_Category_Aggregate;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
}


/** columns and relationships of "task_types" */
export interface TaskTypeCategoryReferencesArgs {
  distinct_on?: Maybe<Array<Task_Type_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Type_Task_Category_Order_By>>;
  where?: Maybe<Task_Type_Task_Category_Bool_Exp>;
}


/** columns and relationships of "task_types" */
export interface TaskTypeCategoryReferences_AggregateArgs {
  distinct_on?: Maybe<Array<Task_Type_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Type_Task_Category_Order_By>>;
  where?: Maybe<Task_Type_Task_Category_Bool_Exp>;
}

/** aggregated selection of "task_types" */
export interface TaskType_Aggregate {
  __typename?: 'TaskType_aggregate';
  aggregate?: Maybe<TaskType_Aggregate_Fields>;
  nodes: Array<TaskType>;
}

/** aggregate fields of "task_types" */
export interface TaskType_Aggregate_Fields {
  __typename?: 'TaskType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TaskType_Max_Fields>;
  min?: Maybe<TaskType_Min_Fields>;
}


/** aggregate fields of "task_types" */
export interface TaskType_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<TaskType_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "task_types". All fields are combined with a logical 'AND'. */
export interface TaskType_Bool_Exp {
  _and?: Maybe<Array<TaskType_Bool_Exp>>;
  _not?: Maybe<TaskType_Bool_Exp>;
  _or?: Maybe<Array<TaskType_Bool_Exp>>;
  categoryReferences?: Maybe<Task_Type_Task_Category_Bool_Exp>;
  categoryReferences_aggregate?: Maybe<Task_Type_Task_Category_Aggregate_Bool_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  group?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "task_types" */
export enum TaskType_Constraint {
  /** unique or primary key constraint on columns "code" */
  TaskTypesPkey = 'task_types_pkey'
}

/** input type for inserting data into table "task_types" */
export interface TaskType_Insert_Input {
  categoryReferences?: Maybe<Task_Type_Task_Category_Arr_Rel_Insert_Input>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface TaskType_Max_Fields {
  __typename?: 'TaskType_max_fields';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface TaskType_Min_Fields {
  __typename?: 'TaskType_min_fields';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "task_types" */
export interface TaskType_Mutation_Response {
  __typename?: 'TaskType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskType>;
}

/** input type for inserting object relation for remote table "task_types" */
export interface TaskType_Obj_Rel_Insert_Input {
  data: TaskType_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<TaskType_On_Conflict>;
}

/** on_conflict condition type for table "task_types" */
export interface TaskType_On_Conflict {
  constraint: TaskType_Constraint;
  update_columns: Array<TaskType_Update_Column>;
  where?: Maybe<TaskType_Bool_Exp>;
}

/** Ordering options when selecting data from "task_types". */
export interface TaskType_Order_By {
  categoryReferences_aggregate?: Maybe<Task_Type_Task_Category_Aggregate_Order_By>;
  code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  group?: Maybe<Order_By>;
}

/** primary key columns input for table: task_types */
export interface TaskType_Pk_Columns_Input {
  code: Scalars['String'];
}

/** select columns of table "task_types" */
export enum TaskType_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
  /** column name */
  Group = 'group'
}

/** input type for updating data in table "task_types" */
export interface TaskType_Set_Input {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "TaskType" */
export interface TaskType_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: TaskType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface TaskType_Stream_Cursor_Value_Input {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
}

/** update columns of table "task_types" */
export enum TaskType_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
  /** column name */
  Group = 'group'
}

export interface TaskType_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TaskType_Set_Input>;
  /** filter the rows which have to be updated */
  where: TaskType_Bool_Exp;
}

/** columns and relationships of "team_assignments" */
export interface TeamAssignment {
  __typename?: 'TeamAssignment';
  /** An object relationship */
  brand?: Maybe<Brand>;
  brand_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  department?: Maybe<Department>;
  department_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  in_buy_channel?: Maybe<In_Buy_Channel_Enum>;
  is_primary?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['uuid']>;
  role: Role_Enum;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "team_assignments" */
export interface TeamAssignment_Aggregate {
  __typename?: 'TeamAssignment_aggregate';
  aggregate?: Maybe<TeamAssignment_Aggregate_Fields>;
  nodes: Array<TeamAssignment>;
}

export interface TeamAssignment_Aggregate_Bool_Exp {
  bool_and?: Maybe<TeamAssignment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<TeamAssignment_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<TeamAssignment_Aggregate_Bool_Exp_Count>;
}

export interface TeamAssignment_Aggregate_Bool_Exp_Bool_And {
  arguments: TeamAssignment_Select_Column_TeamAssignment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TeamAssignment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface TeamAssignment_Aggregate_Bool_Exp_Bool_Or {
  arguments: TeamAssignment_Select_Column_TeamAssignment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TeamAssignment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface TeamAssignment_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<TeamAssignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TeamAssignment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "team_assignments" */
export interface TeamAssignment_Aggregate_Fields {
  __typename?: 'TeamAssignment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TeamAssignment_Max_Fields>;
  min?: Maybe<TeamAssignment_Min_Fields>;
}


/** aggregate fields of "team_assignments" */
export interface TeamAssignment_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<TeamAssignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "team_assignments" */
export interface TeamAssignment_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<TeamAssignment_Max_Order_By>;
  min?: Maybe<TeamAssignment_Min_Order_By>;
}

/** input type for inserting array relation for remote table "team_assignments" */
export interface TeamAssignment_Arr_Rel_Insert_Input {
  data: Array<TeamAssignment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<TeamAssignment_On_Conflict>;
}

/** Boolean expression to filter rows from the table "team_assignments". All fields are combined with a logical 'AND'. */
export interface TeamAssignment_Bool_Exp {
  _and?: Maybe<Array<TeamAssignment_Bool_Exp>>;
  _not?: Maybe<TeamAssignment_Bool_Exp>;
  _or?: Maybe<Array<TeamAssignment_Bool_Exp>>;
  brand?: Maybe<Brand_Bool_Exp>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  department?: Maybe<Department_Bool_Exp>;
  department_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  in_buy_channel?: Maybe<In_Buy_Channel_Enum_Comparison_Exp>;
  is_primary?: Maybe<Boolean_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<Role_Enum_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "team_assignments" */
export enum TeamAssignment_Constraint {
  /** unique or primary key constraint on columns "user_id", "is_primary" */
  OnePrimaryTeamPerUser = 'one_primary_team_per_user',
  /** unique or primary key constraint on columns "id" */
  TeamAssignmentsPkey = 'team_assignments_pkey'
}

/** input type for inserting data into table "team_assignments" */
export interface TeamAssignment_Insert_Input {
  brand?: Maybe<Brand_Obj_Rel_Insert_Input>;
  brand_id?: Maybe<Scalars['uuid']>;
  department?: Maybe<Department_Obj_Rel_Insert_Input>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  in_buy_channel?: Maybe<In_Buy_Channel_Enum>;
  is_primary?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Role_Enum>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface TeamAssignment_Max_Fields {
  __typename?: 'TeamAssignment_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "team_assignments" */
export interface TeamAssignment_Max_Order_By {
  brand_id?: Maybe<Order_By>;
  department_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface TeamAssignment_Min_Fields {
  __typename?: 'TeamAssignment_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "team_assignments" */
export interface TeamAssignment_Min_Order_By {
  brand_id?: Maybe<Order_By>;
  department_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "team_assignments" */
export interface TeamAssignment_Mutation_Response {
  __typename?: 'TeamAssignment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TeamAssignment>;
}

/** on_conflict condition type for table "team_assignments" */
export interface TeamAssignment_On_Conflict {
  constraint: TeamAssignment_Constraint;
  update_columns: Array<TeamAssignment_Update_Column>;
  where?: Maybe<TeamAssignment_Bool_Exp>;
}

/** Ordering options when selecting data from "team_assignments". */
export interface TeamAssignment_Order_By {
  brand?: Maybe<Brand_Order_By>;
  brand_id?: Maybe<Order_By>;
  department?: Maybe<Department_Order_By>;
  department_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  in_buy_channel?: Maybe<Order_By>;
  is_primary?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** primary key columns input for table: team_assignments */
export interface TeamAssignment_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "team_assignments" */
export enum TeamAssignment_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Id = 'id',
  /** column name */
  InBuyChannel = 'in_buy_channel',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'user_id'
}

/** select "TeamAssignment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "team_assignments" */
export enum TeamAssignment_Select_Column_TeamAssignment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPrimary = 'is_primary'
}

/** select "TeamAssignment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "team_assignments" */
export enum TeamAssignment_Select_Column_TeamAssignment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPrimary = 'is_primary'
}

/** input type for updating data in table "team_assignments" */
export interface TeamAssignment_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  in_buy_channel?: Maybe<In_Buy_Channel_Enum>;
  is_primary?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Role_Enum>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "TeamAssignment" */
export interface TeamAssignment_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: TeamAssignment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface TeamAssignment_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  in_buy_channel?: Maybe<In_Buy_Channel_Enum>;
  is_primary?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Role_Enum>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "team_assignments" */
export enum TeamAssignment_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Id = 'id',
  /** column name */
  InBuyChannel = 'in_buy_channel',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'user_id'
}

export interface TeamAssignment_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TeamAssignment_Set_Input>;
  /** filter the rows which have to be updated */
  where: TeamAssignment_Bool_Exp;
}

/** columns and relationships of "tickets" */
export interface Ticket {
  __typename?: 'Ticket';
  /** An object relationship */
  assignee?: Maybe<User>;
  assignee_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  department?: Maybe<Department>;
  department_id?: Maybe<Scalars['uuid']>;
  description: Scalars['String'];
  due_to?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  notes: Array<TicketNote>;
  /** An aggregate relationship */
  notes_aggregate: TicketNote_Aggregate;
  priority?: Maybe<Ticket_Priority_Enum>;
  state: Ticket_State_Enum;
  template_id: Scalars['uuid'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle?: Maybe<Vehicle>;
  vehicle_business_case_number: Scalars['Int'];
  vehicle_id: Scalars['uuid'];
  vehicle_version: Scalars['Int'];
}


/** columns and relationships of "tickets" */
export interface TicketNotesArgs {
  distinct_on?: Maybe<Array<TicketNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TicketNote_Order_By>>;
  where?: Maybe<TicketNote_Bool_Exp>;
}


/** columns and relationships of "tickets" */
export interface TicketNotes_AggregateArgs {
  distinct_on?: Maybe<Array<TicketNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TicketNote_Order_By>>;
  where?: Maybe<TicketNote_Bool_Exp>;
}

/** columns and relationships of "ticket_notes" */
export interface TicketNote {
  __typename?: 'TicketNote';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  note: Scalars['String'];
  ticket_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "ticket_notes" */
export interface TicketNote_Aggregate {
  __typename?: 'TicketNote_aggregate';
  aggregate?: Maybe<TicketNote_Aggregate_Fields>;
  nodes: Array<TicketNote>;
}

export interface TicketNote_Aggregate_Bool_Exp {
  count?: Maybe<TicketNote_Aggregate_Bool_Exp_Count>;
}

export interface TicketNote_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<TicketNote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TicketNote_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "ticket_notes" */
export interface TicketNote_Aggregate_Fields {
  __typename?: 'TicketNote_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TicketNote_Max_Fields>;
  min?: Maybe<TicketNote_Min_Fields>;
}


/** aggregate fields of "ticket_notes" */
export interface TicketNote_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<TicketNote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "ticket_notes" */
export interface TicketNote_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<TicketNote_Max_Order_By>;
  min?: Maybe<TicketNote_Min_Order_By>;
}

/** input type for inserting array relation for remote table "ticket_notes" */
export interface TicketNote_Arr_Rel_Insert_Input {
  data: Array<TicketNote_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<TicketNote_On_Conflict>;
}

/** Boolean expression to filter rows from the table "ticket_notes". All fields are combined with a logical 'AND'. */
export interface TicketNote_Bool_Exp {
  _and?: Maybe<Array<TicketNote_Bool_Exp>>;
  _not?: Maybe<TicketNote_Bool_Exp>;
  _or?: Maybe<Array<TicketNote_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  ticket_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "ticket_notes" */
export enum TicketNote_Constraint {
  /** unique or primary key constraint on columns "id" */
  TicketNotesPkey = 'ticket_notes_pkey'
}

/** input type for inserting data into table "ticket_notes" */
export interface TicketNote_Insert_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface TicketNote_Max_Fields {
  __typename?: 'TicketNote_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "ticket_notes" */
export interface TicketNote_Max_Order_By {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  ticket_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface TicketNote_Min_Fields {
  __typename?: 'TicketNote_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "ticket_notes" */
export interface TicketNote_Min_Order_By {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  ticket_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "ticket_notes" */
export interface TicketNote_Mutation_Response {
  __typename?: 'TicketNote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TicketNote>;
}

/** on_conflict condition type for table "ticket_notes" */
export interface TicketNote_On_Conflict {
  constraint: TicketNote_Constraint;
  update_columns: Array<TicketNote_Update_Column>;
  where?: Maybe<TicketNote_Bool_Exp>;
}

/** Ordering options when selecting data from "ticket_notes". */
export interface TicketNote_Order_By {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  ticket_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
}

/** primary key columns input for table: ticket_notes */
export interface TicketNote_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "ticket_notes" */
export enum TicketNote_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "ticket_notes" */
export interface TicketNote_Set_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "TicketNote" */
export interface TicketNote_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: TicketNote_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface TicketNote_Stream_Cursor_Value_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "ticket_notes" */
export enum TicketNote_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export interface TicketNote_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TicketNote_Set_Input>;
  /** filter the rows which have to be updated */
  where: TicketNote_Bool_Exp;
}

/** columns and relationships of "ticket_templates" */
export interface TicketTemplate {
  __typename?: 'TicketTemplate';
  assign_to?: Maybe<Ticket_Template_Assignee_Ref_Enum>;
  automated_task: Scalars['Boolean'];
  /** An object relationship */
  department?: Maybe<Department>;
  department_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  location?: Maybe<Ticket_Template_Location_Ref_Enum>;
  priority?: Maybe<Ticket_Priority_Enum>;
  template_title: Scalars['String'];
  ticket_description: Scalars['String'];
  ticket_title: Scalars['String'];
  trigger_filter?: Maybe<Scalars['json']>;
}


/** columns and relationships of "ticket_templates" */
export interface TicketTemplateTrigger_FilterArgs {
  path?: Maybe<Scalars['String']>;
}

/** aggregated selection of "ticket_templates" */
export interface TicketTemplate_Aggregate {
  __typename?: 'TicketTemplate_aggregate';
  aggregate?: Maybe<TicketTemplate_Aggregate_Fields>;
  nodes: Array<TicketTemplate>;
}

/** aggregate fields of "ticket_templates" */
export interface TicketTemplate_Aggregate_Fields {
  __typename?: 'TicketTemplate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TicketTemplate_Max_Fields>;
  min?: Maybe<TicketTemplate_Min_Fields>;
}


/** aggregate fields of "ticket_templates" */
export interface TicketTemplate_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<TicketTemplate_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "ticket_templates". All fields are combined with a logical 'AND'. */
export interface TicketTemplate_Bool_Exp {
  _and?: Maybe<Array<TicketTemplate_Bool_Exp>>;
  _not?: Maybe<TicketTemplate_Bool_Exp>;
  _or?: Maybe<Array<TicketTemplate_Bool_Exp>>;
  assign_to?: Maybe<Ticket_Template_Assignee_Ref_Enum_Comparison_Exp>;
  automated_task?: Maybe<Boolean_Comparison_Exp>;
  department?: Maybe<Department_Bool_Exp>;
  department_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Ticket_Template_Location_Ref_Enum_Comparison_Exp>;
  priority?: Maybe<Ticket_Priority_Enum_Comparison_Exp>;
  template_title?: Maybe<String_Comparison_Exp>;
  ticket_description?: Maybe<String_Comparison_Exp>;
  ticket_title?: Maybe<String_Comparison_Exp>;
  trigger_filter?: Maybe<Json_Comparison_Exp>;
}

/** unique or primary key constraints on table "ticket_templates" */
export enum TicketTemplate_Constraint {
  /** unique or primary key constraint on columns "id" */
  TicketTemplatesPkey = 'ticket_templates_pkey'
}

/** input type for inserting data into table "ticket_templates" */
export interface TicketTemplate_Insert_Input {
  assign_to?: Maybe<Ticket_Template_Assignee_Ref_Enum>;
  automated_task?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Department_Obj_Rel_Insert_Input>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Ticket_Template_Location_Ref_Enum>;
  priority?: Maybe<Ticket_Priority_Enum>;
  template_title?: Maybe<Scalars['String']>;
  ticket_description?: Maybe<Scalars['String']>;
  ticket_title?: Maybe<Scalars['String']>;
  trigger_filter?: Maybe<Scalars['json']>;
}

/** aggregate max on columns */
export interface TicketTemplate_Max_Fields {
  __typename?: 'TicketTemplate_max_fields';
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  template_title?: Maybe<Scalars['String']>;
  ticket_description?: Maybe<Scalars['String']>;
  ticket_title?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface TicketTemplate_Min_Fields {
  __typename?: 'TicketTemplate_min_fields';
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  template_title?: Maybe<Scalars['String']>;
  ticket_description?: Maybe<Scalars['String']>;
  ticket_title?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "ticket_templates" */
export interface TicketTemplate_Mutation_Response {
  __typename?: 'TicketTemplate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TicketTemplate>;
}

/** on_conflict condition type for table "ticket_templates" */
export interface TicketTemplate_On_Conflict {
  constraint: TicketTemplate_Constraint;
  update_columns: Array<TicketTemplate_Update_Column>;
  where?: Maybe<TicketTemplate_Bool_Exp>;
}

/** Ordering options when selecting data from "ticket_templates". */
export interface TicketTemplate_Order_By {
  assign_to?: Maybe<Order_By>;
  automated_task?: Maybe<Order_By>;
  department?: Maybe<Department_Order_By>;
  department_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  template_title?: Maybe<Order_By>;
  ticket_description?: Maybe<Order_By>;
  ticket_title?: Maybe<Order_By>;
  trigger_filter?: Maybe<Order_By>;
}

/** primary key columns input for table: ticket_templates */
export interface TicketTemplate_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "ticket_templates" */
export enum TicketTemplate_Select_Column {
  /** column name */
  AssignTo = 'assign_to',
  /** column name */
  AutomatedTask = 'automated_task',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Priority = 'priority',
  /** column name */
  TemplateTitle = 'template_title',
  /** column name */
  TicketDescription = 'ticket_description',
  /** column name */
  TicketTitle = 'ticket_title',
  /** column name */
  TriggerFilter = 'trigger_filter'
}

/** input type for updating data in table "ticket_templates" */
export interface TicketTemplate_Set_Input {
  assign_to?: Maybe<Ticket_Template_Assignee_Ref_Enum>;
  automated_task?: Maybe<Scalars['Boolean']>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Ticket_Template_Location_Ref_Enum>;
  priority?: Maybe<Ticket_Priority_Enum>;
  template_title?: Maybe<Scalars['String']>;
  ticket_description?: Maybe<Scalars['String']>;
  ticket_title?: Maybe<Scalars['String']>;
  trigger_filter?: Maybe<Scalars['json']>;
}

/** Streaming cursor of the table "TicketTemplate" */
export interface TicketTemplate_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: TicketTemplate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface TicketTemplate_Stream_Cursor_Value_Input {
  assign_to?: Maybe<Ticket_Template_Assignee_Ref_Enum>;
  automated_task?: Maybe<Scalars['Boolean']>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Ticket_Template_Location_Ref_Enum>;
  priority?: Maybe<Ticket_Priority_Enum>;
  template_title?: Maybe<Scalars['String']>;
  ticket_description?: Maybe<Scalars['String']>;
  ticket_title?: Maybe<Scalars['String']>;
  trigger_filter?: Maybe<Scalars['json']>;
}

/** update columns of table "ticket_templates" */
export enum TicketTemplate_Update_Column {
  /** column name */
  AssignTo = 'assign_to',
  /** column name */
  AutomatedTask = 'automated_task',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Priority = 'priority',
  /** column name */
  TemplateTitle = 'template_title',
  /** column name */
  TicketDescription = 'ticket_description',
  /** column name */
  TicketTitle = 'ticket_title',
  /** column name */
  TriggerFilter = 'trigger_filter'
}

export interface TicketTemplate_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TicketTemplate_Set_Input>;
  /** filter the rows which have to be updated */
  where: TicketTemplate_Bool_Exp;
}

/** aggregated selection of "tickets" */
export interface Ticket_Aggregate {
  __typename?: 'Ticket_aggregate';
  aggregate?: Maybe<Ticket_Aggregate_Fields>;
  nodes: Array<Ticket>;
}

export interface Ticket_Aggregate_Bool_Exp {
  count?: Maybe<Ticket_Aggregate_Bool_Exp_Count>;
}

export interface Ticket_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Ticket_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Ticket_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "tickets" */
export interface Ticket_Aggregate_Fields {
  __typename?: 'Ticket_aggregate_fields';
  avg?: Maybe<Ticket_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ticket_Max_Fields>;
  min?: Maybe<Ticket_Min_Fields>;
  stddev?: Maybe<Ticket_Stddev_Fields>;
  stddev_pop?: Maybe<Ticket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ticket_Stddev_Samp_Fields>;
  sum?: Maybe<Ticket_Sum_Fields>;
  var_pop?: Maybe<Ticket_Var_Pop_Fields>;
  var_samp?: Maybe<Ticket_Var_Samp_Fields>;
  variance?: Maybe<Ticket_Variance_Fields>;
}


/** aggregate fields of "tickets" */
export interface Ticket_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Ticket_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "tickets" */
export interface Ticket_Aggregate_Order_By {
  avg?: Maybe<Ticket_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ticket_Max_Order_By>;
  min?: Maybe<Ticket_Min_Order_By>;
  stddev?: Maybe<Ticket_Stddev_Order_By>;
  stddev_pop?: Maybe<Ticket_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ticket_Stddev_Samp_Order_By>;
  sum?: Maybe<Ticket_Sum_Order_By>;
  var_pop?: Maybe<Ticket_Var_Pop_Order_By>;
  var_samp?: Maybe<Ticket_Var_Samp_Order_By>;
  variance?: Maybe<Ticket_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "tickets" */
export interface Ticket_Arr_Rel_Insert_Input {
  data: Array<Ticket_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Ticket_On_Conflict>;
}

/** aggregate avg on columns */
export interface Ticket_Avg_Fields {
  __typename?: 'Ticket_avg_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "tickets" */
export interface Ticket_Avg_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "tickets". All fields are combined with a logical 'AND'. */
export interface Ticket_Bool_Exp {
  _and?: Maybe<Array<Ticket_Bool_Exp>>;
  _not?: Maybe<Ticket_Bool_Exp>;
  _or?: Maybe<Array<Ticket_Bool_Exp>>;
  assignee?: Maybe<User_Bool_Exp>;
  assignee_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Bool_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  department?: Maybe<Department_Bool_Exp>;
  department_id?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  due_to?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<TicketNote_Bool_Exp>;
  notes_aggregate?: Maybe<TicketNote_Aggregate_Bool_Exp>;
  priority?: Maybe<Ticket_Priority_Enum_Comparison_Exp>;
  state?: Maybe<Ticket_State_Enum_Comparison_Exp>;
  template_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle?: Maybe<Vehicle_Bool_Exp>;
  vehicle_business_case_number?: Maybe<Int_Comparison_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_version?: Maybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "tickets" */
export enum Ticket_Constraint {
  /** unique or primary key constraint on columns "id" */
  TicketsPkey = 'tickets_pkey'
}

/** input type for incrementing numeric columns in table "tickets" */
export interface Ticket_Inc_Input {
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "tickets" */
export interface Ticket_Insert_Input {
  assignee?: Maybe<User_Obj_Rel_Insert_Input>;
  assignee_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['uuid']>;
  department?: Maybe<Department_Obj_Rel_Insert_Input>;
  department_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  due_to?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<TicketNote_Arr_Rel_Insert_Input>;
  priority?: Maybe<Ticket_Priority_Enum>;
  state?: Maybe<Ticket_State_Enum>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle?: Maybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface Ticket_Max_Fields {
  __typename?: 'Ticket_max_fields';
  assignee_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  department_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  due_to?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "tickets" */
export interface Ticket_Max_Order_By {
  assignee_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  department_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  due_to?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Ticket_Min_Fields {
  __typename?: 'Ticket_min_fields';
  assignee_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  department_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  due_to?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "tickets" */
export interface Ticket_Min_Order_By {
  assignee_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  department_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  due_to?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** response of any mutation on the table "tickets" */
export interface Ticket_Mutation_Response {
  __typename?: 'Ticket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ticket>;
}

/** on_conflict condition type for table "tickets" */
export interface Ticket_On_Conflict {
  constraint: Ticket_Constraint;
  update_columns: Array<Ticket_Update_Column>;
  where?: Maybe<Ticket_Bool_Exp>;
}

/** Ordering options when selecting data from "tickets". */
export interface Ticket_Order_By {
  assignee?: Maybe<User_Order_By>;
  assignee_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Order_By>;
  created_by_id?: Maybe<Order_By>;
  department?: Maybe<Department_Order_By>;
  department_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  due_to?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_id?: Maybe<Order_By>;
  notes_aggregate?: Maybe<TicketNote_Aggregate_Order_By>;
  priority?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicle_Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** primary key columns input for table: tickets */
export interface Ticket_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "tickets" */
export enum Ticket_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Description = 'description',
  /** column name */
  DueTo = 'due_to',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  State = 'state',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

/** input type for updating data in table "tickets" */
export interface Ticket_Set_Input {
  assignee_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  department_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  due_to?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Ticket_Priority_Enum>;
  state?: Maybe<Ticket_State_Enum>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface Ticket_Stddev_Fields {
  __typename?: 'Ticket_stddev_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "tickets" */
export interface Ticket_Stddev_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Ticket_Stddev_Pop_Fields {
  __typename?: 'Ticket_stddev_pop_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "tickets" */
export interface Ticket_Stddev_Pop_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Ticket_Stddev_Samp_Fields {
  __typename?: 'Ticket_stddev_samp_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "tickets" */
export interface Ticket_Stddev_Samp_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Streaming cursor of the table "Ticket" */
export interface Ticket_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Ticket_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Ticket_Stream_Cursor_Value_Input {
  assignee_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  department_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  due_to?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Ticket_Priority_Enum>;
  state?: Maybe<Ticket_State_Enum>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface Ticket_Sum_Fields {
  __typename?: 'Ticket_sum_fields';
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "tickets" */
export interface Ticket_Sum_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** update columns of table "tickets" */
export enum Ticket_Update_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Description = 'description',
  /** column name */
  DueTo = 'due_to',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  State = 'state',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

export interface Ticket_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ticket_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ticket_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ticket_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Ticket_Var_Pop_Fields {
  __typename?: 'Ticket_var_pop_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "tickets" */
export interface Ticket_Var_Pop_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Ticket_Var_Samp_Fields {
  __typename?: 'Ticket_var_samp_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "tickets" */
export interface Ticket_Var_Samp_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Ticket_Variance_Fields {
  __typename?: 'Ticket_variance_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "tickets" */
export interface Ticket_Variance_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

export interface ToggleBrandForDealerVehicleSyncInput {
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
}

export interface ToggleBrandForDealerVehicleSyncPayload {
  __typename?: 'ToggleBrandForDealerVehicleSyncPayload';
  errorMessage?: Maybe<Scalars['String']>;
  status: ResponseStatus;
}

/** columns and relationships of "users" */
export interface User {
  __typename?: 'User';
  abbreviation?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "primary_role" */
  primary_role?: Maybe<Scalars['String']>;
  /** An array relationship */
  teams: Array<TeamAssignment>;
  /** An aggregate relationship */
  teams_aggregate: TeamAssignment_Aggregate;
}


/** columns and relationships of "users" */
export interface UserTeamsArgs {
  distinct_on?: Maybe<Array<TeamAssignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamAssignment_Order_By>>;
  where?: Maybe<TeamAssignment_Bool_Exp>;
}


/** columns and relationships of "users" */
export interface UserTeams_AggregateArgs {
  distinct_on?: Maybe<Array<TeamAssignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamAssignment_Order_By>>;
  where?: Maybe<TeamAssignment_Bool_Exp>;
}

/** aggregated selection of "users" */
export interface User_Aggregate {
  __typename?: 'User_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
}

/** aggregate fields of "users" */
export interface User_Aggregate_Fields {
  __typename?: 'User_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
}


/** aggregate fields of "users" */
export interface User_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export interface User_Bool_Exp {
  _and?: Maybe<Array<User_Bool_Exp>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<User_Bool_Exp>>;
  abbreviation?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isActive?: Maybe<Boolean_Comparison_Exp>;
  last_login?: Maybe<Timestamptz_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  primary_role?: Maybe<String_Comparison_Exp>;
  teams?: Maybe<TeamAssignment_Bool_Exp>;
  teams_aggregate?: Maybe<TeamAssignment_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "users" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export interface User_Insert_Input {
  abbreviation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  teams?: Maybe<TeamAssignment_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface User_Max_Fields {
  __typename?: 'User_max_fields';
  abbreviation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "primary_role" */
  primary_role?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface User_Min_Fields {
  __typename?: 'User_min_fields';
  abbreviation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "primary_role" */
  primary_role?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "users" */
export interface User_Mutation_Response {
  __typename?: 'User_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
}

/** input type for inserting object relation for remote table "users" */
export interface User_Obj_Rel_Insert_Input {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<User_On_Conflict>;
}

/** on_conflict condition type for table "users" */
export interface User_On_Conflict {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
}

/** Ordering options when selecting data from "users". */
export interface User_Order_By {
  abbreviation?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isActive?: Maybe<Order_By>;
  last_login?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  primary_role?: Maybe<Order_By>;
  teams_aggregate?: Maybe<TeamAssignment_Aggregate_Order_By>;
}

/** primary key columns input for table: users */
export interface User_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "users" */
export enum User_Select_Column {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  LastName = 'last_name'
}

/** input type for updating data in table "users" */
export interface User_Set_Input {
  abbreviation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "User" */
export interface User_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface User_Stream_Cursor_Value_Input {
  abbreviation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
}

/** update columns of table "users" */
export enum User_Update_Column {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  LastName = 'last_name'
}

export interface User_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
}

/** columns and relationships of "vehicles" */
export interface Vehicle {
  __typename?: 'Vehicle';
  accessories_mounted?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  archived?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  boni: Array<Vehicle_Boni>;
  /** An aggregate relationship */
  boni_aggregate: Vehicle_Boni_Aggregate;
  /** An object relationship */
  brand: Brand;
  brand_id: Scalars['uuid'];
  business_case_number: Scalars['Int'];
  cash_price?: Maybe<Scalars['numeric']>;
  co2_nefz?: Maybe<Scalars['Int']>;
  co2_wltp?: Maybe<Scalars['Int']>;
  color_exterior_code: Scalars['String'];
  color_exterior_name: Scalars['String'];
  color_interior_code: Scalars['String'];
  color_interior_name: Scalars['String'];
  control_number?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  customer?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Customer_Type_Enum>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  /** An object relationship */
  delivery_location?: Maybe<StorageLocation>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  dms_assigned_location?: Maybe<Location>;
  dms_assigned_location_id?: Maybe<Scalars['uuid']>;
  engine_number: Scalars['String'];
  first_registration_date?: Maybe<Scalars['date']>;
  /** An array relationship */
  generated_by: Array<Transactions>;
  /** An aggregate relationship */
  generated_by_aggregate: Transactions_Aggregate;
  /** An object relationship */
  handover_by?: Maybe<User>;
  handover_by_id?: Maybe<Scalars['uuid']>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  /** A computed field, executes function "get_vehicle_history" */
  history?: Maybe<Array<Vehicle>>;
  /** An object relationship */
  holder_customer?: Maybe<Customer>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  holder_location?: Maybe<Location>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  holder_supplier?: Maybe<Supplier>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  import_type?: Maybe<Import_Type_Enum>;
  imported?: Maybe<Scalars['timestamptz']>;
  in_buy_channel?: Maybe<In_Buy_Channel_Enum>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  invoice_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  keys_count?: Maybe<Scalars['Int']>;
  last_updated_by_id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  marked_for_delete?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage: Scalars['Int'];
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  /** An object relationship */
  model: Model;
  model_description?: Maybe<Scalars['String']>;
  model_id: Scalars['uuid'];
  /** A computed field, executes function "vehicle_model_name" */
  model_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  notes: Array<VehicleNote>;
  /** An aggregate relationship */
  notes_aggregate: VehicleNote_Aggregate;
  order_code?: Maybe<Scalars['String']>;
  order_state?: Maybe<Order_State_Enum>;
  /** An object relationship */
  owner_customer?: Maybe<Customer>;
  owner_customer_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  owner_location?: Maybe<Location>;
  owner_location_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  owner_supplier?: Maybe<Supplier>;
  owner_supplier_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  previous_customer?: Maybe<Customer>;
  /** An object relationship */
  previous_location?: Maybe<Location>;
  previous_owner_customer_id?: Maybe<Scalars['uuid']>;
  previous_owner_location_id?: Maybe<Scalars['uuid']>;
  previous_owner_supplier_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  previous_supplier?: Maybe<Supplier>;
  /** An object relationship */
  purchased_by?: Maybe<User>;
  purchased_by_id?: Maybe<Scalars['uuid']>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  reserved_at?: Maybe<Scalars['date']>;
  /** An object relationship */
  reserved_by?: Maybe<User>;
  reserved_by_id?: Maybe<Scalars['uuid']>;
  reserved_for?: Maybe<Scalars['String']>;
  reserved_for_customer_type?: Maybe<Customer_Type_Enum>;
  reserved_until?: Maybe<Scalars['date']>;
  /** An object relationship */
  seller_location?: Maybe<Location>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  sold_by?: Maybe<User>;
  sold_by_id?: Maybe<Scalars['uuid']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  /** An object relationship */
  storage_location?: Maybe<StorageLocation>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  sync_error: Scalars['Boolean'];
  /** An array relationship */
  sync_errors: Array<Sync_Errors>;
  /** An aggregate relationship */
  sync_errors_aggregate: Sync_Errors_Aggregate;
  /** An array relationship */
  tickets: Array<Ticket>;
  /** An aggregate relationship */
  tickets_aggregate: Ticket_Aggregate;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at: Scalars['timestamptz'];
  version: Scalars['Int'];
  vin: Scalars['String'];
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}


/** columns and relationships of "vehicles" */
export interface VehicleBoniArgs {
  distinct_on?: Maybe<Array<Vehicle_Boni_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Boni_Order_By>>;
  where?: Maybe<Vehicle_Boni_Bool_Exp>;
}


/** columns and relationships of "vehicles" */
export interface VehicleBoni_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Boni_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Boni_Order_By>>;
  where?: Maybe<Vehicle_Boni_Bool_Exp>;
}


/** columns and relationships of "vehicles" */
export interface VehicleGenerated_ByArgs {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
}


/** columns and relationships of "vehicles" */
export interface VehicleGenerated_By_AggregateArgs {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
}


/** columns and relationships of "vehicles" */
export interface VehicleHistoryArgs {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
}


/** columns and relationships of "vehicles" */
export interface VehicleNotesArgs {
  distinct_on?: Maybe<Array<VehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VehicleNote_Order_By>>;
  where?: Maybe<VehicleNote_Bool_Exp>;
}


/** columns and relationships of "vehicles" */
export interface VehicleNotes_AggregateArgs {
  distinct_on?: Maybe<Array<VehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VehicleNote_Order_By>>;
  where?: Maybe<VehicleNote_Bool_Exp>;
}


/** columns and relationships of "vehicles" */
export interface VehicleSync_ErrorsArgs {
  distinct_on?: Maybe<Array<Sync_Errors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Errors_Order_By>>;
  where?: Maybe<Sync_Errors_Bool_Exp>;
}


/** columns and relationships of "vehicles" */
export interface VehicleSync_Errors_AggregateArgs {
  distinct_on?: Maybe<Array<Sync_Errors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Errors_Order_By>>;
  where?: Maybe<Sync_Errors_Bool_Exp>;
}


/** columns and relationships of "vehicles" */
export interface VehicleTicketsArgs {
  distinct_on?: Maybe<Array<Ticket_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Order_By>>;
  where?: Maybe<Ticket_Bool_Exp>;
}


/** columns and relationships of "vehicles" */
export interface VehicleTickets_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Order_By>>;
  where?: Maybe<Ticket_Bool_Exp>;
}

export interface VehicleComposedId {
  businessCaseNumber: Scalars['Int'];
  id: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
}

/** columns and relationships of "vehicle_notes" */
export interface VehicleNote {
  __typename?: 'VehicleNote';
  archived: Scalars['Boolean'];
  business_case_number: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  note: Scalars['String'];
  priority: Scalars['Boolean'];
  sticky?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
  /** An object relationship */
  vehicle?: Maybe<Vehicle>;
  vehicle_id: Scalars['uuid'];
  vehicle_version: Scalars['Int'];
}

/** aggregated selection of "vehicle_notes" */
export interface VehicleNote_Aggregate {
  __typename?: 'VehicleNote_aggregate';
  aggregate?: Maybe<VehicleNote_Aggregate_Fields>;
  nodes: Array<VehicleNote>;
}

export interface VehicleNote_Aggregate_Bool_Exp {
  bool_and?: Maybe<VehicleNote_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<VehicleNote_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<VehicleNote_Aggregate_Bool_Exp_Count>;
}

export interface VehicleNote_Aggregate_Bool_Exp_Bool_And {
  arguments: VehicleNote_Select_Column_VehicleNote_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<VehicleNote_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface VehicleNote_Aggregate_Bool_Exp_Bool_Or {
  arguments: VehicleNote_Select_Column_VehicleNote_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<VehicleNote_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface VehicleNote_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<VehicleNote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<VehicleNote_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "vehicle_notes" */
export interface VehicleNote_Aggregate_Fields {
  __typename?: 'VehicleNote_aggregate_fields';
  avg?: Maybe<VehicleNote_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VehicleNote_Max_Fields>;
  min?: Maybe<VehicleNote_Min_Fields>;
  stddev?: Maybe<VehicleNote_Stddev_Fields>;
  stddev_pop?: Maybe<VehicleNote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VehicleNote_Stddev_Samp_Fields>;
  sum?: Maybe<VehicleNote_Sum_Fields>;
  var_pop?: Maybe<VehicleNote_Var_Pop_Fields>;
  var_samp?: Maybe<VehicleNote_Var_Samp_Fields>;
  variance?: Maybe<VehicleNote_Variance_Fields>;
}


/** aggregate fields of "vehicle_notes" */
export interface VehicleNote_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<VehicleNote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "vehicle_notes" */
export interface VehicleNote_Aggregate_Order_By {
  avg?: Maybe<VehicleNote_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VehicleNote_Max_Order_By>;
  min?: Maybe<VehicleNote_Min_Order_By>;
  stddev?: Maybe<VehicleNote_Stddev_Order_By>;
  stddev_pop?: Maybe<VehicleNote_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VehicleNote_Stddev_Samp_Order_By>;
  sum?: Maybe<VehicleNote_Sum_Order_By>;
  var_pop?: Maybe<VehicleNote_Var_Pop_Order_By>;
  var_samp?: Maybe<VehicleNote_Var_Samp_Order_By>;
  variance?: Maybe<VehicleNote_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "vehicle_notes" */
export interface VehicleNote_Arr_Rel_Insert_Input {
  data: Array<VehicleNote_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<VehicleNote_On_Conflict>;
}

/** aggregate avg on columns */
export interface VehicleNote_Avg_Fields {
  __typename?: 'VehicleNote_avg_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "vehicle_notes" */
export interface VehicleNote_Avg_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "vehicle_notes". All fields are combined with a logical 'AND'. */
export interface VehicleNote_Bool_Exp {
  _and?: Maybe<Array<VehicleNote_Bool_Exp>>;
  _not?: Maybe<VehicleNote_Bool_Exp>;
  _or?: Maybe<Array<VehicleNote_Bool_Exp>>;
  archived?: Maybe<Boolean_Comparison_Exp>;
  business_case_number?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  priority?: Maybe<Boolean_Comparison_Exp>;
  sticky?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle?: Maybe<Vehicle_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_version?: Maybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "vehicle_notes" */
export enum VehicleNote_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehicleNotesPkey = 'vehicle_notes_pkey'
}

/** input type for incrementing numeric columns in table "vehicle_notes" */
export interface VehicleNote_Inc_Input {
  business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "vehicle_notes" */
export interface VehicleNote_Insert_Input {
  archived?: Maybe<Scalars['Boolean']>;
  business_case_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Boolean']>;
  sticky?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle?: Maybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface VehicleNote_Max_Fields {
  __typename?: 'VehicleNote_max_fields';
  business_case_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "vehicle_notes" */
export interface VehicleNote_Max_Order_By {
  business_case_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface VehicleNote_Min_Fields {
  __typename?: 'VehicleNote_min_fields';
  business_case_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "vehicle_notes" */
export interface VehicleNote_Min_Order_By {
  business_case_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** response of any mutation on the table "vehicle_notes" */
export interface VehicleNote_Mutation_Response {
  __typename?: 'VehicleNote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VehicleNote>;
}

/** on_conflict condition type for table "vehicle_notes" */
export interface VehicleNote_On_Conflict {
  constraint: VehicleNote_Constraint;
  update_columns: Array<VehicleNote_Update_Column>;
  where?: Maybe<VehicleNote_Bool_Exp>;
}

/** Ordering options when selecting data from "vehicle_notes". */
export interface VehicleNote_Order_By {
  archived?: Maybe<Order_By>;
  business_case_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  sticky?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicle_Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** primary key columns input for table: vehicle_notes */
export interface VehicleNote_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "vehicle_notes" */
export enum VehicleNote_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  BusinessCaseNumber = 'business_case_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Priority = 'priority',
  /** column name */
  Sticky = 'sticky',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

/** select "VehicleNote_aggregate_bool_exp_bool_and_arguments_columns" columns of table "vehicle_notes" */
export enum VehicleNote_Select_Column_VehicleNote_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Priority = 'priority',
  /** column name */
  Sticky = 'sticky'
}

/** select "VehicleNote_aggregate_bool_exp_bool_or_arguments_columns" columns of table "vehicle_notes" */
export enum VehicleNote_Select_Column_VehicleNote_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Priority = 'priority',
  /** column name */
  Sticky = 'sticky'
}

/** input type for updating data in table "vehicle_notes" */
export interface VehicleNote_Set_Input {
  archived?: Maybe<Scalars['Boolean']>;
  business_case_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Boolean']>;
  sticky?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface VehicleNote_Stddev_Fields {
  __typename?: 'VehicleNote_stddev_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "vehicle_notes" */
export interface VehicleNote_Stddev_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface VehicleNote_Stddev_Pop_Fields {
  __typename?: 'VehicleNote_stddev_pop_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "vehicle_notes" */
export interface VehicleNote_Stddev_Pop_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface VehicleNote_Stddev_Samp_Fields {
  __typename?: 'VehicleNote_stddev_samp_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "vehicle_notes" */
export interface VehicleNote_Stddev_Samp_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Streaming cursor of the table "VehicleNote" */
export interface VehicleNote_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: VehicleNote_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface VehicleNote_Stream_Cursor_Value_Input {
  archived?: Maybe<Scalars['Boolean']>;
  business_case_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Boolean']>;
  sticky?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface VehicleNote_Sum_Fields {
  __typename?: 'VehicleNote_sum_fields';
  business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "vehicle_notes" */
export interface VehicleNote_Sum_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** update columns of table "vehicle_notes" */
export enum VehicleNote_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  BusinessCaseNumber = 'business_case_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Priority = 'priority',
  /** column name */
  Sticky = 'sticky',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

export interface VehicleNote_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<VehicleNote_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<VehicleNote_Set_Input>;
  /** filter the rows which have to be updated */
  where: VehicleNote_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface VehicleNote_Var_Pop_Fields {
  __typename?: 'VehicleNote_var_pop_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "vehicle_notes" */
export interface VehicleNote_Var_Pop_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface VehicleNote_Var_Samp_Fields {
  __typename?: 'VehicleNote_var_samp_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "vehicle_notes" */
export interface VehicleNote_Var_Samp_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface VehicleNote_Variance_Fields {
  __typename?: 'VehicleNote_variance_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "vehicle_notes" */
export interface VehicleNote_Variance_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

export interface VehicleWithMixedUpInvoice {
  __typename?: 'VehicleWithMixedUpInvoice';
  id: Scalars['uuid'];
  vin: Scalars['String'];
}

/** aggregated selection of "vehicles" */
export interface Vehicle_Aggregate {
  __typename?: 'Vehicle_aggregate';
  aggregate?: Maybe<Vehicle_Aggregate_Fields>;
  nodes: Array<Vehicle>;
}

/** aggregate fields of "vehicles" */
export interface Vehicle_Aggregate_Fields {
  __typename?: 'Vehicle_aggregate_fields';
  avg?: Maybe<Vehicle_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Max_Fields>;
  min?: Maybe<Vehicle_Min_Fields>;
  stddev?: Maybe<Vehicle_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Variance_Fields>;
}


/** aggregate fields of "vehicles" */
export interface Vehicle_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Vehicle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "vehicles" */
export interface Vehicle_Aggregate_Order_By {
  avg?: Maybe<Vehicle_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicle_Max_Order_By>;
  min?: Maybe<Vehicle_Min_Order_By>;
  stddev?: Maybe<Vehicle_Stddev_Order_By>;
  stddev_pop?: Maybe<Vehicle_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Vehicle_Stddev_Samp_Order_By>;
  sum?: Maybe<Vehicle_Sum_Order_By>;
  var_pop?: Maybe<Vehicle_Var_Pop_Order_By>;
  var_samp?: Maybe<Vehicle_Var_Samp_Order_By>;
  variance?: Maybe<Vehicle_Variance_Order_By>;
}

/** aggregate avg on columns */
export interface Vehicle_Avg_Fields {
  __typename?: 'Vehicle_avg_fields';
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  business_case_number?: Maybe<Scalars['Float']>;
  cash_price?: Maybe<Scalars['Float']>;
  co2_nefz?: Maybe<Scalars['Float']>;
  co2_wltp?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  keys_count?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "vehicles" */
export interface Vehicle_Avg_Order_By {
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "vehicles". All fields are combined with a logical 'AND'. */
export interface Vehicle_Bool_Exp {
  _and?: Maybe<Array<Vehicle_Bool_Exp>>;
  _not?: Maybe<Vehicle_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Bool_Exp>>;
  accessories_mounted?: Maybe<Boolean_Comparison_Exp>;
  advertising_price?: Maybe<Numeric_Comparison_Exp>;
  archived?: Maybe<Timestamptz_Comparison_Exp>;
  boni?: Maybe<Vehicle_Boni_Bool_Exp>;
  boni_aggregate?: Maybe<Vehicle_Boni_Aggregate_Bool_Exp>;
  brand?: Maybe<Brand_Bool_Exp>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  business_case_number?: Maybe<Int_Comparison_Exp>;
  cash_price?: Maybe<Numeric_Comparison_Exp>;
  co2_nefz?: Maybe<Int_Comparison_Exp>;
  co2_wltp?: Maybe<Int_Comparison_Exp>;
  color_exterior_code?: Maybe<String_Comparison_Exp>;
  color_exterior_name?: Maybe<String_Comparison_Exp>;
  color_interior_code?: Maybe<String_Comparison_Exp>;
  color_interior_name?: Maybe<String_Comparison_Exp>;
  control_number?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<String_Comparison_Exp>;
  customer_type?: Maybe<Customer_Type_Enum_Comparison_Exp>;
  customer_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  days_since_purchase?: Maybe<Int_Comparison_Exp>;
  delivery_date_str?: Maybe<String_Comparison_Exp>;
  delivery_location?: Maybe<StorageLocation_Bool_Exp>;
  delivery_location_id?: Maybe<Uuid_Comparison_Exp>;
  dms_assigned_location?: Maybe<Location_Bool_Exp>;
  dms_assigned_location_id?: Maybe<Uuid_Comparison_Exp>;
  engine_number?: Maybe<String_Comparison_Exp>;
  first_registration_date?: Maybe<Date_Comparison_Exp>;
  generated_by?: Maybe<Transactions_Bool_Exp>;
  generated_by_aggregate?: Maybe<Transactions_Aggregate_Bool_Exp>;
  handover_by?: Maybe<User_Bool_Exp>;
  handover_by_id?: Maybe<Uuid_Comparison_Exp>;
  handover_date?: Maybe<Date_Comparison_Exp>;
  handover_planned_date?: Maybe<Date_Comparison_Exp>;
  history?: Maybe<Vehicle_Bool_Exp>;
  holder_customer?: Maybe<Customer_Bool_Exp>;
  holder_customer_id?: Maybe<Uuid_Comparison_Exp>;
  holder_location?: Maybe<Location_Bool_Exp>;
  holder_location_id?: Maybe<Uuid_Comparison_Exp>;
  holder_supplier?: Maybe<Supplier_Bool_Exp>;
  holder_supplier_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  import_type?: Maybe<Import_Type_Enum_Comparison_Exp>;
  imported?: Maybe<Timestamptz_Comparison_Exp>;
  in_buy_channel?: Maybe<In_Buy_Channel_Enum_Comparison_Exp>;
  in_buy_contract_date?: Maybe<Date_Comparison_Exp>;
  invoice_date?: Maybe<Date_Comparison_Exp>;
  job_number?: Maybe<String_Comparison_Exp>;
  key_kabinet_number?: Maybe<String_Comparison_Exp>;
  keys_count?: Maybe<Int_Comparison_Exp>;
  last_updated_by_id?: Maybe<Uuid_Comparison_Exp>;
  license_plate_number?: Maybe<String_Comparison_Exp>;
  list_price?: Maybe<Numeric_Comparison_Exp>;
  marked_for_delete?: Maybe<Timestamptz_Comparison_Exp>;
  max_version?: Maybe<Int_Comparison_Exp>;
  mileage?: Maybe<Int_Comparison_Exp>;
  mileage_date?: Maybe<Date_Comparison_Exp>;
  min_holding_period?: Maybe<Int_Comparison_Exp>;
  min_non_imported_business_case?: Maybe<Int_Comparison_Exp>;
  model?: Maybe<Model_Bool_Exp>;
  model_description?: Maybe<String_Comparison_Exp>;
  model_id?: Maybe<Uuid_Comparison_Exp>;
  model_name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<VehicleNote_Bool_Exp>;
  notes_aggregate?: Maybe<VehicleNote_Aggregate_Bool_Exp>;
  order_code?: Maybe<String_Comparison_Exp>;
  order_state?: Maybe<Order_State_Enum_Comparison_Exp>;
  owner_customer?: Maybe<Customer_Bool_Exp>;
  owner_customer_id?: Maybe<Uuid_Comparison_Exp>;
  owner_location?: Maybe<Location_Bool_Exp>;
  owner_location_id?: Maybe<Uuid_Comparison_Exp>;
  owner_supplier?: Maybe<Supplier_Bool_Exp>;
  owner_supplier_id?: Maybe<Uuid_Comparison_Exp>;
  previous_customer?: Maybe<Customer_Bool_Exp>;
  previous_location?: Maybe<Location_Bool_Exp>;
  previous_owner_customer_id?: Maybe<Uuid_Comparison_Exp>;
  previous_owner_location_id?: Maybe<Uuid_Comparison_Exp>;
  previous_owner_supplier_id?: Maybe<Uuid_Comparison_Exp>;
  previous_supplier?: Maybe<Supplier_Bool_Exp>;
  purchased_by?: Maybe<User_Bool_Exp>;
  purchased_by_id?: Maybe<Uuid_Comparison_Exp>;
  registration_owner?: Maybe<String_Comparison_Exp>;
  registration_state?: Maybe<Registration_State_Enum_Comparison_Exp>;
  reserved_at?: Maybe<Date_Comparison_Exp>;
  reserved_by?: Maybe<User_Bool_Exp>;
  reserved_by_id?: Maybe<Uuid_Comparison_Exp>;
  reserved_for?: Maybe<String_Comparison_Exp>;
  reserved_for_customer_type?: Maybe<Customer_Type_Enum_Comparison_Exp>;
  reserved_until?: Maybe<Date_Comparison_Exp>;
  seller_location?: Maybe<Location_Bool_Exp>;
  seller_location_id?: Maybe<Uuid_Comparison_Exp>;
  sold_at?: Maybe<Timestamptz_Comparison_Exp>;
  sold_by?: Maybe<User_Bool_Exp>;
  sold_by_id?: Maybe<Uuid_Comparison_Exp>;
  spec_level?: Maybe<String_Comparison_Exp>;
  specs?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Dealer_Vehicle_State_Enum_Comparison_Exp>;
  storage_location?: Maybe<StorageLocation_Bool_Exp>;
  storage_location_id?: Maybe<Uuid_Comparison_Exp>;
  storage_location_note?: Maybe<String_Comparison_Exp>;
  sync_error?: Maybe<Boolean_Comparison_Exp>;
  sync_errors?: Maybe<Sync_Errors_Bool_Exp>;
  sync_errors_aggregate?: Maybe<Sync_Errors_Aggregate_Bool_Exp>;
  tickets?: Maybe<Ticket_Bool_Exp>;
  tickets_aggregate?: Maybe<Ticket_Aggregate_Bool_Exp>;
  type?: Maybe<Dealer_Vehicle_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  version?: Maybe<Int_Comparison_Exp>;
  vin?: Maybe<String_Comparison_Exp>;
  warranty_end?: Maybe<Date_Comparison_Exp>;
  warranty_start?: Maybe<Date_Comparison_Exp>;
}

/** unique or primary key constraints on table "vehicles" */
export enum Vehicle_Constraint {
  /** unique or primary key constraint on columns "id", "business_case_number", "version" */
  VehiclesPkey = 'vehicles_pkey'
}

/** input type for incrementing numeric columns in table "vehicles" */
export interface Vehicle_Inc_Input {
  business_case_number?: Maybe<Scalars['Int']>;
  cash_price?: Maybe<Scalars['numeric']>;
  co2_nefz?: Maybe<Scalars['Int']>;
  co2_wltp?: Maybe<Scalars['Int']>;
  keys_count?: Maybe<Scalars['Int']>;
  list_price?: Maybe<Scalars['numeric']>;
  mileage?: Maybe<Scalars['Int']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "vehicles" */
export interface Vehicle_Insert_Input {
  accessories_mounted?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['timestamptz']>;
  boni?: Maybe<Vehicle_Boni_Arr_Rel_Insert_Input>;
  brand?: Maybe<Brand_Obj_Rel_Insert_Input>;
  brand_id?: Maybe<Scalars['uuid']>;
  business_case_number?: Maybe<Scalars['Int']>;
  cash_price?: Maybe<Scalars['numeric']>;
  co2_nefz?: Maybe<Scalars['Int']>;
  co2_wltp?: Maybe<Scalars['Int']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  control_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Customer_Type_Enum>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location?: Maybe<StorageLocation_Obj_Rel_Insert_Input>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  dms_assigned_location?: Maybe<Location_Obj_Rel_Insert_Input>;
  dms_assigned_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  generated_by?: Maybe<Transactions_Arr_Rel_Insert_Input>;
  handover_by?: Maybe<User_Obj_Rel_Insert_Input>;
  handover_by_id?: Maybe<Scalars['uuid']>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  holder_customer?: Maybe<Customer_Obj_Rel_Insert_Input>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location?: Maybe<Location_Obj_Rel_Insert_Input>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier?: Maybe<Supplier_Obj_Rel_Insert_Input>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  import_type?: Maybe<Import_Type_Enum>;
  imported?: Maybe<Scalars['timestamptz']>;
  in_buy_channel?: Maybe<In_Buy_Channel_Enum>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  invoice_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  keys_count?: Maybe<Scalars['Int']>;
  last_updated_by_id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  marked_for_delete?: Maybe<Scalars['timestamptz']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model?: Maybe<Model_Obj_Rel_Insert_Input>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<VehicleNote_Arr_Rel_Insert_Input>;
  order_code?: Maybe<Scalars['String']>;
  order_state?: Maybe<Order_State_Enum>;
  owner_customer?: Maybe<Customer_Obj_Rel_Insert_Input>;
  owner_customer_id?: Maybe<Scalars['uuid']>;
  owner_location?: Maybe<Location_Obj_Rel_Insert_Input>;
  owner_location_id?: Maybe<Scalars['uuid']>;
  owner_supplier?: Maybe<Supplier_Obj_Rel_Insert_Input>;
  owner_supplier_id?: Maybe<Scalars['uuid']>;
  previous_customer?: Maybe<Customer_Obj_Rel_Insert_Input>;
  previous_location?: Maybe<Location_Obj_Rel_Insert_Input>;
  previous_owner_customer_id?: Maybe<Scalars['uuid']>;
  previous_owner_location_id?: Maybe<Scalars['uuid']>;
  previous_owner_supplier_id?: Maybe<Scalars['uuid']>;
  previous_supplier?: Maybe<Supplier_Obj_Rel_Insert_Input>;
  purchased_by?: Maybe<User_Obj_Rel_Insert_Input>;
  purchased_by_id?: Maybe<Scalars['uuid']>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  reserved_at?: Maybe<Scalars['date']>;
  reserved_by?: Maybe<User_Obj_Rel_Insert_Input>;
  reserved_by_id?: Maybe<Scalars['uuid']>;
  reserved_for?: Maybe<Scalars['String']>;
  reserved_for_customer_type?: Maybe<Customer_Type_Enum>;
  reserved_until?: Maybe<Scalars['date']>;
  seller_location?: Maybe<Location_Obj_Rel_Insert_Input>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by?: Maybe<User_Obj_Rel_Insert_Input>;
  sold_by_id?: Maybe<Scalars['uuid']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  storage_location?: Maybe<StorageLocation_Obj_Rel_Insert_Input>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  sync_error?: Maybe<Scalars['Boolean']>;
  sync_errors?: Maybe<Sync_Errors_Arr_Rel_Insert_Input>;
  tickets?: Maybe<Ticket_Arr_Rel_Insert_Input>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate max on columns */
export interface Vehicle_Max_Fields {
  __typename?: 'Vehicle_max_fields';
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  archived?: Maybe<Scalars['timestamptz']>;
  brand_id?: Maybe<Scalars['uuid']>;
  business_case_number?: Maybe<Scalars['Int']>;
  cash_price?: Maybe<Scalars['numeric']>;
  co2_nefz?: Maybe<Scalars['Int']>;
  co2_wltp?: Maybe<Scalars['Int']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  control_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  dms_assigned_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  handover_by_id?: Maybe<Scalars['uuid']>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  imported?: Maybe<Scalars['timestamptz']>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  invoice_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  keys_count?: Maybe<Scalars['Int']>;
  last_updated_by_id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  marked_for_delete?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "vehicle_model_name" */
  model_name?: Maybe<Scalars['String']>;
  order_code?: Maybe<Scalars['String']>;
  owner_customer_id?: Maybe<Scalars['uuid']>;
  owner_location_id?: Maybe<Scalars['uuid']>;
  owner_supplier_id?: Maybe<Scalars['uuid']>;
  previous_owner_customer_id?: Maybe<Scalars['uuid']>;
  previous_owner_location_id?: Maybe<Scalars['uuid']>;
  previous_owner_supplier_id?: Maybe<Scalars['uuid']>;
  purchased_by_id?: Maybe<Scalars['uuid']>;
  registration_owner?: Maybe<Scalars['String']>;
  reserved_at?: Maybe<Scalars['date']>;
  reserved_by_id?: Maybe<Scalars['uuid']>;
  reserved_for?: Maybe<Scalars['String']>;
  reserved_until?: Maybe<Scalars['date']>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by_id?: Maybe<Scalars['uuid']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** order by max() on columns of table "vehicles" */
export interface Vehicle_Max_Order_By {
  archived?: Maybe<Order_By>;
  brand_id?: Maybe<Order_By>;
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  color_exterior_code?: Maybe<Order_By>;
  color_exterior_name?: Maybe<Order_By>;
  color_interior_code?: Maybe<Order_By>;
  color_interior_name?: Maybe<Order_By>;
  control_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  delivery_date_str?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dms_assigned_location_id?: Maybe<Order_By>;
  engine_number?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  handover_by_id?: Maybe<Order_By>;
  handover_date?: Maybe<Order_By>;
  handover_planned_date?: Maybe<Order_By>;
  holder_customer_id?: Maybe<Order_By>;
  holder_location_id?: Maybe<Order_By>;
  holder_supplier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  imported?: Maybe<Order_By>;
  in_buy_contract_date?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  key_kabinet_number?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  last_updated_by_id?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  marked_for_delete?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  model_description?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  order_code?: Maybe<Order_By>;
  owner_customer_id?: Maybe<Order_By>;
  owner_location_id?: Maybe<Order_By>;
  owner_supplier_id?: Maybe<Order_By>;
  previous_owner_customer_id?: Maybe<Order_By>;
  previous_owner_location_id?: Maybe<Order_By>;
  previous_owner_supplier_id?: Maybe<Order_By>;
  purchased_by_id?: Maybe<Order_By>;
  registration_owner?: Maybe<Order_By>;
  reserved_at?: Maybe<Order_By>;
  reserved_by_id?: Maybe<Order_By>;
  reserved_for?: Maybe<Order_By>;
  reserved_until?: Maybe<Order_By>;
  seller_location_id?: Maybe<Order_By>;
  sold_at?: Maybe<Order_By>;
  sold_by_id?: Maybe<Order_By>;
  spec_level?: Maybe<Order_By>;
  specs?: Maybe<Order_By>;
  storage_location_id?: Maybe<Order_By>;
  storage_location_note?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Vehicle_Min_Fields {
  __typename?: 'Vehicle_min_fields';
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  archived?: Maybe<Scalars['timestamptz']>;
  brand_id?: Maybe<Scalars['uuid']>;
  business_case_number?: Maybe<Scalars['Int']>;
  cash_price?: Maybe<Scalars['numeric']>;
  co2_nefz?: Maybe<Scalars['Int']>;
  co2_wltp?: Maybe<Scalars['Int']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  control_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  dms_assigned_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  handover_by_id?: Maybe<Scalars['uuid']>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  imported?: Maybe<Scalars['timestamptz']>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  invoice_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  keys_count?: Maybe<Scalars['Int']>;
  last_updated_by_id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  marked_for_delete?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "vehicle_model_name" */
  model_name?: Maybe<Scalars['String']>;
  order_code?: Maybe<Scalars['String']>;
  owner_customer_id?: Maybe<Scalars['uuid']>;
  owner_location_id?: Maybe<Scalars['uuid']>;
  owner_supplier_id?: Maybe<Scalars['uuid']>;
  previous_owner_customer_id?: Maybe<Scalars['uuid']>;
  previous_owner_location_id?: Maybe<Scalars['uuid']>;
  previous_owner_supplier_id?: Maybe<Scalars['uuid']>;
  purchased_by_id?: Maybe<Scalars['uuid']>;
  registration_owner?: Maybe<Scalars['String']>;
  reserved_at?: Maybe<Scalars['date']>;
  reserved_by_id?: Maybe<Scalars['uuid']>;
  reserved_for?: Maybe<Scalars['String']>;
  reserved_until?: Maybe<Scalars['date']>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by_id?: Maybe<Scalars['uuid']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** order by min() on columns of table "vehicles" */
export interface Vehicle_Min_Order_By {
  archived?: Maybe<Order_By>;
  brand_id?: Maybe<Order_By>;
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  color_exterior_code?: Maybe<Order_By>;
  color_exterior_name?: Maybe<Order_By>;
  color_interior_code?: Maybe<Order_By>;
  color_interior_name?: Maybe<Order_By>;
  control_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  delivery_date_str?: Maybe<Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dms_assigned_location_id?: Maybe<Order_By>;
  engine_number?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  handover_by_id?: Maybe<Order_By>;
  handover_date?: Maybe<Order_By>;
  handover_planned_date?: Maybe<Order_By>;
  holder_customer_id?: Maybe<Order_By>;
  holder_location_id?: Maybe<Order_By>;
  holder_supplier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  imported?: Maybe<Order_By>;
  in_buy_contract_date?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  key_kabinet_number?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  last_updated_by_id?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  marked_for_delete?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  model_description?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  order_code?: Maybe<Order_By>;
  owner_customer_id?: Maybe<Order_By>;
  owner_location_id?: Maybe<Order_By>;
  owner_supplier_id?: Maybe<Order_By>;
  previous_owner_customer_id?: Maybe<Order_By>;
  previous_owner_location_id?: Maybe<Order_By>;
  previous_owner_supplier_id?: Maybe<Order_By>;
  purchased_by_id?: Maybe<Order_By>;
  registration_owner?: Maybe<Order_By>;
  reserved_at?: Maybe<Order_By>;
  reserved_by_id?: Maybe<Order_By>;
  reserved_for?: Maybe<Order_By>;
  reserved_until?: Maybe<Order_By>;
  seller_location_id?: Maybe<Order_By>;
  sold_at?: Maybe<Order_By>;
  sold_by_id?: Maybe<Order_By>;
  spec_level?: Maybe<Order_By>;
  specs?: Maybe<Order_By>;
  storage_location_id?: Maybe<Order_By>;
  storage_location_note?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
}

/** response of any mutation on the table "vehicles" */
export interface Vehicle_Mutation_Response {
  __typename?: 'Vehicle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle>;
}

/** input type for inserting object relation for remote table "vehicles" */
export interface Vehicle_Obj_Rel_Insert_Input {
  data: Vehicle_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_On_Conflict>;
}

/** on_conflict condition type for table "vehicles" */
export interface Vehicle_On_Conflict {
  constraint: Vehicle_Constraint;
  update_columns: Array<Vehicle_Update_Column>;
  where?: Maybe<Vehicle_Bool_Exp>;
}

/** Ordering options when selecting data from "vehicles". */
export interface Vehicle_Order_By {
  accessories_mounted?: Maybe<Order_By>;
  advertising_price?: Maybe<Order_By>;
  archived?: Maybe<Order_By>;
  boni_aggregate?: Maybe<Vehicle_Boni_Aggregate_Order_By>;
  brand?: Maybe<Brand_Order_By>;
  brand_id?: Maybe<Order_By>;
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  color_exterior_code?: Maybe<Order_By>;
  color_exterior_name?: Maybe<Order_By>;
  color_interior_code?: Maybe<Order_By>;
  color_interior_name?: Maybe<Order_By>;
  control_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  customer_type?: Maybe<Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  days_since_purchase?: Maybe<Order_By>;
  delivery_date_str?: Maybe<Order_By>;
  delivery_location?: Maybe<StorageLocation_Order_By>;
  delivery_location_id?: Maybe<Order_By>;
  dms_assigned_location?: Maybe<Location_Order_By>;
  dms_assigned_location_id?: Maybe<Order_By>;
  engine_number?: Maybe<Order_By>;
  first_registration_date?: Maybe<Order_By>;
  generated_by_aggregate?: Maybe<Transactions_Aggregate_Order_By>;
  handover_by?: Maybe<User_Order_By>;
  handover_by_id?: Maybe<Order_By>;
  handover_date?: Maybe<Order_By>;
  handover_planned_date?: Maybe<Order_By>;
  history_aggregate?: Maybe<Vehicle_Aggregate_Order_By>;
  holder_customer?: Maybe<Customer_Order_By>;
  holder_customer_id?: Maybe<Order_By>;
  holder_location?: Maybe<Location_Order_By>;
  holder_location_id?: Maybe<Order_By>;
  holder_supplier?: Maybe<Supplier_Order_By>;
  holder_supplier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  import_type?: Maybe<Order_By>;
  imported?: Maybe<Order_By>;
  in_buy_channel?: Maybe<Order_By>;
  in_buy_contract_date?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  key_kabinet_number?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  last_updated_by_id?: Maybe<Order_By>;
  license_plate_number?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  marked_for_delete?: Maybe<Order_By>;
  max_version?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  min_non_imported_business_case?: Maybe<Order_By>;
  model?: Maybe<Model_Order_By>;
  model_description?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  model_name?: Maybe<Order_By>;
  notes_aggregate?: Maybe<VehicleNote_Aggregate_Order_By>;
  order_code?: Maybe<Order_By>;
  order_state?: Maybe<Order_By>;
  owner_customer?: Maybe<Customer_Order_By>;
  owner_customer_id?: Maybe<Order_By>;
  owner_location?: Maybe<Location_Order_By>;
  owner_location_id?: Maybe<Order_By>;
  owner_supplier?: Maybe<Supplier_Order_By>;
  owner_supplier_id?: Maybe<Order_By>;
  previous_customer?: Maybe<Customer_Order_By>;
  previous_location?: Maybe<Location_Order_By>;
  previous_owner_customer_id?: Maybe<Order_By>;
  previous_owner_location_id?: Maybe<Order_By>;
  previous_owner_supplier_id?: Maybe<Order_By>;
  previous_supplier?: Maybe<Supplier_Order_By>;
  purchased_by?: Maybe<User_Order_By>;
  purchased_by_id?: Maybe<Order_By>;
  registration_owner?: Maybe<Order_By>;
  registration_state?: Maybe<Order_By>;
  reserved_at?: Maybe<Order_By>;
  reserved_by?: Maybe<User_Order_By>;
  reserved_by_id?: Maybe<Order_By>;
  reserved_for?: Maybe<Order_By>;
  reserved_for_customer_type?: Maybe<Order_By>;
  reserved_until?: Maybe<Order_By>;
  seller_location?: Maybe<Location_Order_By>;
  seller_location_id?: Maybe<Order_By>;
  sold_at?: Maybe<Order_By>;
  sold_by?: Maybe<User_Order_By>;
  sold_by_id?: Maybe<Order_By>;
  spec_level?: Maybe<Order_By>;
  specs?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  storage_location?: Maybe<StorageLocation_Order_By>;
  storage_location_id?: Maybe<Order_By>;
  storage_location_note?: Maybe<Order_By>;
  sync_error?: Maybe<Order_By>;
  sync_errors_aggregate?: Maybe<Sync_Errors_Aggregate_Order_By>;
  tickets_aggregate?: Maybe<Ticket_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  warranty_end?: Maybe<Order_By>;
  warranty_start?: Maybe<Order_By>;
}

/** primary key columns input for table: vehicles */
export interface Vehicle_Pk_Columns_Input {
  business_case_number: Scalars['Int'];
  id: Scalars['uuid'];
  version: Scalars['Int'];
}

/** select columns of table "vehicles" */
export enum Vehicle_Select_Column {
  /** column name */
  AccessoriesMounted = 'accessories_mounted',
  /** column name */
  Archived = 'archived',
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  BusinessCaseNumber = 'business_case_number',
  /** column name */
  CashPrice = 'cash_price',
  /** column name */
  Co2Nefz = 'co2_nefz',
  /** column name */
  Co2Wltp = 'co2_wltp',
  /** column name */
  ColorExteriorCode = 'color_exterior_code',
  /** column name */
  ColorExteriorName = 'color_exterior_name',
  /** column name */
  ColorInteriorCode = 'color_interior_code',
  /** column name */
  ColorInteriorName = 'color_interior_name',
  /** column name */
  ControlNumber = 'control_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customer = 'customer',
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  DeliveryDateStr = 'delivery_date_str',
  /** column name */
  DeliveryLocationId = 'delivery_location_id',
  /** column name */
  DmsAssignedLocationId = 'dms_assigned_location_id',
  /** column name */
  EngineNumber = 'engine_number',
  /** column name */
  FirstRegistrationDate = 'first_registration_date',
  /** column name */
  HandoverById = 'handover_by_id',
  /** column name */
  HandoverDate = 'handover_date',
  /** column name */
  HandoverPlannedDate = 'handover_planned_date',
  /** column name */
  HolderCustomerId = 'holder_customer_id',
  /** column name */
  HolderLocationId = 'holder_location_id',
  /** column name */
  HolderSupplierId = 'holder_supplier_id',
  /** column name */
  Id = 'id',
  /** column name */
  ImportType = 'import_type',
  /** column name */
  Imported = 'imported',
  /** column name */
  InBuyChannel = 'in_buy_channel',
  /** column name */
  InBuyContractDate = 'in_buy_contract_date',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  KeyKabinetNumber = 'key_kabinet_number',
  /** column name */
  KeysCount = 'keys_count',
  /** column name */
  LastUpdatedById = 'last_updated_by_id',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  ListPrice = 'list_price',
  /** column name */
  MarkedForDelete = 'marked_for_delete',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  MinHoldingPeriod = 'min_holding_period',
  /** column name */
  ModelDescription = 'model_description',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  OrderCode = 'order_code',
  /** column name */
  OrderState = 'order_state',
  /** column name */
  OwnerCustomerId = 'owner_customer_id',
  /** column name */
  OwnerLocationId = 'owner_location_id',
  /** column name */
  OwnerSupplierId = 'owner_supplier_id',
  /** column name */
  PreviousOwnerCustomerId = 'previous_owner_customer_id',
  /** column name */
  PreviousOwnerLocationId = 'previous_owner_location_id',
  /** column name */
  PreviousOwnerSupplierId = 'previous_owner_supplier_id',
  /** column name */
  PurchasedById = 'purchased_by_id',
  /** column name */
  RegistrationOwner = 'registration_owner',
  /** column name */
  RegistrationState = 'registration_state',
  /** column name */
  ReservedAt = 'reserved_at',
  /** column name */
  ReservedById = 'reserved_by_id',
  /** column name */
  ReservedFor = 'reserved_for',
  /** column name */
  ReservedForCustomerType = 'reserved_for_customer_type',
  /** column name */
  ReservedUntil = 'reserved_until',
  /** column name */
  SellerLocationId = 'seller_location_id',
  /** column name */
  SoldAt = 'sold_at',
  /** column name */
  SoldById = 'sold_by_id',
  /** column name */
  SpecLevel = 'spec_level',
  /** column name */
  Specs = 'specs',
  /** column name */
  State = 'state',
  /** column name */
  StorageLocationId = 'storage_location_id',
  /** column name */
  StorageLocationNote = 'storage_location_note',
  /** column name */
  SyncError = 'sync_error',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
  /** column name */
  Vin = 'vin',
  /** column name */
  WarrantyEnd = 'warranty_end',
  /** column name */
  WarrantyStart = 'warranty_start'
}

/** input type for updating data in table "vehicles" */
export interface Vehicle_Set_Input {
  accessories_mounted?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['timestamptz']>;
  brand_id?: Maybe<Scalars['uuid']>;
  business_case_number?: Maybe<Scalars['Int']>;
  cash_price?: Maybe<Scalars['numeric']>;
  co2_nefz?: Maybe<Scalars['Int']>;
  co2_wltp?: Maybe<Scalars['Int']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  control_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Customer_Type_Enum>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  dms_assigned_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  handover_by_id?: Maybe<Scalars['uuid']>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  import_type?: Maybe<Import_Type_Enum>;
  imported?: Maybe<Scalars['timestamptz']>;
  in_buy_channel?: Maybe<In_Buy_Channel_Enum>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  invoice_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  keys_count?: Maybe<Scalars['Int']>;
  last_updated_by_id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  marked_for_delete?: Maybe<Scalars['timestamptz']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  order_code?: Maybe<Scalars['String']>;
  order_state?: Maybe<Order_State_Enum>;
  owner_customer_id?: Maybe<Scalars['uuid']>;
  owner_location_id?: Maybe<Scalars['uuid']>;
  owner_supplier_id?: Maybe<Scalars['uuid']>;
  previous_owner_customer_id?: Maybe<Scalars['uuid']>;
  previous_owner_location_id?: Maybe<Scalars['uuid']>;
  previous_owner_supplier_id?: Maybe<Scalars['uuid']>;
  purchased_by_id?: Maybe<Scalars['uuid']>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  reserved_at?: Maybe<Scalars['date']>;
  reserved_by_id?: Maybe<Scalars['uuid']>;
  reserved_for?: Maybe<Scalars['String']>;
  reserved_for_customer_type?: Maybe<Customer_Type_Enum>;
  reserved_until?: Maybe<Scalars['date']>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by_id?: Maybe<Scalars['uuid']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  sync_error?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate stddev on columns */
export interface Vehicle_Stddev_Fields {
  __typename?: 'Vehicle_stddev_fields';
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  business_case_number?: Maybe<Scalars['Float']>;
  cash_price?: Maybe<Scalars['Float']>;
  co2_nefz?: Maybe<Scalars['Float']>;
  co2_wltp?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  keys_count?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "vehicles" */
export interface Vehicle_Stddev_Order_By {
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Vehicle_Stddev_Pop_Fields {
  __typename?: 'Vehicle_stddev_pop_fields';
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  business_case_number?: Maybe<Scalars['Float']>;
  cash_price?: Maybe<Scalars['Float']>;
  co2_nefz?: Maybe<Scalars['Float']>;
  co2_wltp?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  keys_count?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "vehicles" */
export interface Vehicle_Stddev_Pop_Order_By {
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Vehicle_Stddev_Samp_Fields {
  __typename?: 'Vehicle_stddev_samp_fields';
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  business_case_number?: Maybe<Scalars['Float']>;
  cash_price?: Maybe<Scalars['Float']>;
  co2_nefz?: Maybe<Scalars['Float']>;
  co2_wltp?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  keys_count?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "vehicles" */
export interface Vehicle_Stddev_Samp_Order_By {
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
}

/** Streaming cursor of the table "Vehicle" */
export interface Vehicle_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vehicle_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vehicle_Stream_Cursor_Value_Input {
  accessories_mounted?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['timestamptz']>;
  brand_id?: Maybe<Scalars['uuid']>;
  business_case_number?: Maybe<Scalars['Int']>;
  cash_price?: Maybe<Scalars['numeric']>;
  co2_nefz?: Maybe<Scalars['Int']>;
  co2_wltp?: Maybe<Scalars['Int']>;
  color_exterior_code?: Maybe<Scalars['String']>;
  color_exterior_name?: Maybe<Scalars['String']>;
  color_interior_code?: Maybe<Scalars['String']>;
  color_interior_name?: Maybe<Scalars['String']>;
  control_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Customer_Type_Enum>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  delivery_location_id?: Maybe<Scalars['uuid']>;
  dms_assigned_location_id?: Maybe<Scalars['uuid']>;
  engine_number?: Maybe<Scalars['String']>;
  first_registration_date?: Maybe<Scalars['date']>;
  handover_by_id?: Maybe<Scalars['uuid']>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  import_type?: Maybe<Import_Type_Enum>;
  imported?: Maybe<Scalars['timestamptz']>;
  in_buy_channel?: Maybe<In_Buy_Channel_Enum>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  invoice_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  keys_count?: Maybe<Scalars['Int']>;
  last_updated_by_id?: Maybe<Scalars['uuid']>;
  license_plate_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  marked_for_delete?: Maybe<Scalars['timestamptz']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  model_description?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['uuid']>;
  order_code?: Maybe<Scalars['String']>;
  order_state?: Maybe<Order_State_Enum>;
  owner_customer_id?: Maybe<Scalars['uuid']>;
  owner_location_id?: Maybe<Scalars['uuid']>;
  owner_supplier_id?: Maybe<Scalars['uuid']>;
  previous_owner_customer_id?: Maybe<Scalars['uuid']>;
  previous_owner_location_id?: Maybe<Scalars['uuid']>;
  previous_owner_supplier_id?: Maybe<Scalars['uuid']>;
  purchased_by_id?: Maybe<Scalars['uuid']>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  reserved_at?: Maybe<Scalars['date']>;
  reserved_by_id?: Maybe<Scalars['uuid']>;
  reserved_for?: Maybe<Scalars['String']>;
  reserved_for_customer_type?: Maybe<Customer_Type_Enum>;
  reserved_until?: Maybe<Scalars['date']>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  sold_by_id?: Maybe<Scalars['uuid']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  storage_location_id?: Maybe<Scalars['uuid']>;
  storage_location_note?: Maybe<Scalars['String']>;
  sync_error?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['date']>;
  warranty_start?: Maybe<Scalars['date']>;
}

/** aggregate sum on columns */
export interface Vehicle_Sum_Fields {
  __typename?: 'Vehicle_sum_fields';
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  business_case_number?: Maybe<Scalars['Int']>;
  cash_price?: Maybe<Scalars['numeric']>;
  co2_nefz?: Maybe<Scalars['Int']>;
  co2_wltp?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  keys_count?: Maybe<Scalars['Int']>;
  list_price?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Int']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "vehicles" */
export interface Vehicle_Sum_Order_By {
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
}

/** update columns of table "vehicles" */
export enum Vehicle_Update_Column {
  /** column name */
  AccessoriesMounted = 'accessories_mounted',
  /** column name */
  Archived = 'archived',
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  BusinessCaseNumber = 'business_case_number',
  /** column name */
  CashPrice = 'cash_price',
  /** column name */
  Co2Nefz = 'co2_nefz',
  /** column name */
  Co2Wltp = 'co2_wltp',
  /** column name */
  ColorExteriorCode = 'color_exterior_code',
  /** column name */
  ColorExteriorName = 'color_exterior_name',
  /** column name */
  ColorInteriorCode = 'color_interior_code',
  /** column name */
  ColorInteriorName = 'color_interior_name',
  /** column name */
  ControlNumber = 'control_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customer = 'customer',
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  DeliveryDateStr = 'delivery_date_str',
  /** column name */
  DeliveryLocationId = 'delivery_location_id',
  /** column name */
  DmsAssignedLocationId = 'dms_assigned_location_id',
  /** column name */
  EngineNumber = 'engine_number',
  /** column name */
  FirstRegistrationDate = 'first_registration_date',
  /** column name */
  HandoverById = 'handover_by_id',
  /** column name */
  HandoverDate = 'handover_date',
  /** column name */
  HandoverPlannedDate = 'handover_planned_date',
  /** column name */
  HolderCustomerId = 'holder_customer_id',
  /** column name */
  HolderLocationId = 'holder_location_id',
  /** column name */
  HolderSupplierId = 'holder_supplier_id',
  /** column name */
  Id = 'id',
  /** column name */
  ImportType = 'import_type',
  /** column name */
  Imported = 'imported',
  /** column name */
  InBuyChannel = 'in_buy_channel',
  /** column name */
  InBuyContractDate = 'in_buy_contract_date',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  KeyKabinetNumber = 'key_kabinet_number',
  /** column name */
  KeysCount = 'keys_count',
  /** column name */
  LastUpdatedById = 'last_updated_by_id',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  ListPrice = 'list_price',
  /** column name */
  MarkedForDelete = 'marked_for_delete',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  MinHoldingPeriod = 'min_holding_period',
  /** column name */
  ModelDescription = 'model_description',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  OrderCode = 'order_code',
  /** column name */
  OrderState = 'order_state',
  /** column name */
  OwnerCustomerId = 'owner_customer_id',
  /** column name */
  OwnerLocationId = 'owner_location_id',
  /** column name */
  OwnerSupplierId = 'owner_supplier_id',
  /** column name */
  PreviousOwnerCustomerId = 'previous_owner_customer_id',
  /** column name */
  PreviousOwnerLocationId = 'previous_owner_location_id',
  /** column name */
  PreviousOwnerSupplierId = 'previous_owner_supplier_id',
  /** column name */
  PurchasedById = 'purchased_by_id',
  /** column name */
  RegistrationOwner = 'registration_owner',
  /** column name */
  RegistrationState = 'registration_state',
  /** column name */
  ReservedAt = 'reserved_at',
  /** column name */
  ReservedById = 'reserved_by_id',
  /** column name */
  ReservedFor = 'reserved_for',
  /** column name */
  ReservedForCustomerType = 'reserved_for_customer_type',
  /** column name */
  ReservedUntil = 'reserved_until',
  /** column name */
  SellerLocationId = 'seller_location_id',
  /** column name */
  SoldAt = 'sold_at',
  /** column name */
  SoldById = 'sold_by_id',
  /** column name */
  SpecLevel = 'spec_level',
  /** column name */
  Specs = 'specs',
  /** column name */
  State = 'state',
  /** column name */
  StorageLocationId = 'storage_location_id',
  /** column name */
  StorageLocationNote = 'storage_location_note',
  /** column name */
  SyncError = 'sync_error',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
  /** column name */
  Vin = 'vin',
  /** column name */
  WarrantyEnd = 'warranty_end',
  /** column name */
  WarrantyStart = 'warranty_start'
}

export interface Vehicle_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Vehicle_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Vehicle_Var_Pop_Fields {
  __typename?: 'Vehicle_var_pop_fields';
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  business_case_number?: Maybe<Scalars['Float']>;
  cash_price?: Maybe<Scalars['Float']>;
  co2_nefz?: Maybe<Scalars['Float']>;
  co2_wltp?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  keys_count?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "vehicles" */
export interface Vehicle_Var_Pop_Order_By {
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Vehicle_Var_Samp_Fields {
  __typename?: 'Vehicle_var_samp_fields';
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  business_case_number?: Maybe<Scalars['Float']>;
  cash_price?: Maybe<Scalars['Float']>;
  co2_nefz?: Maybe<Scalars['Float']>;
  co2_wltp?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  keys_count?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "vehicles" */
export interface Vehicle_Var_Samp_Order_By {
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Vehicle_Variance_Fields {
  __typename?: 'Vehicle_variance_fields';
  /** A computed field, executes function "advertising_price" */
  advertising_price?: Maybe<Scalars['numeric']>;
  business_case_number?: Maybe<Scalars['Float']>;
  cash_price?: Maybe<Scalars['Float']>;
  co2_nefz?: Maybe<Scalars['Float']>;
  co2_wltp?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_days_since_purchased" */
  days_since_purchase?: Maybe<Scalars['Int']>;
  keys_count?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "vehicle_max_version" */
  max_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Float']>;
  min_holding_period?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "min_non_imported_bc" */
  min_non_imported_business_case?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "vehicles" */
export interface Vehicle_Variance_Order_By {
  business_case_number?: Maybe<Order_By>;
  cash_price?: Maybe<Order_By>;
  co2_nefz?: Maybe<Order_By>;
  co2_wltp?: Maybe<Order_By>;
  keys_count?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
}

export interface VehiclesWithMixedUpInvoicesInput {
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}

export interface VehiclesWithMixedUpInvoicesPayload {
  __typename?: 'VehiclesWithMixedUpInvoicesPayload';
  cursor: PaginationCursor;
  vehicles: Array<VehicleWithMixedUpInvoice>;
}

/** columns and relationships of "after_sales_status" */
export interface After_Sales_Status {
  __typename?: 'after_sales_status';
  comment: Scalars['String'];
  value: Scalars['String'];
}

/** aggregated selection of "after_sales_status" */
export interface After_Sales_Status_Aggregate {
  __typename?: 'after_sales_status_aggregate';
  aggregate?: Maybe<After_Sales_Status_Aggregate_Fields>;
  nodes: Array<After_Sales_Status>;
}

/** aggregate fields of "after_sales_status" */
export interface After_Sales_Status_Aggregate_Fields {
  __typename?: 'after_sales_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<After_Sales_Status_Max_Fields>;
  min?: Maybe<After_Sales_Status_Min_Fields>;
}


/** aggregate fields of "after_sales_status" */
export interface After_Sales_Status_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<After_Sales_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "after_sales_status". All fields are combined with a logical 'AND'. */
export interface After_Sales_Status_Bool_Exp {
  _and?: Maybe<Array<After_Sales_Status_Bool_Exp>>;
  _not?: Maybe<After_Sales_Status_Bool_Exp>;
  _or?: Maybe<Array<After_Sales_Status_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "after_sales_status" */
export enum After_Sales_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  AfterSalesStatusPkey = 'after_sales_status_pkey'
}

export enum After_Sales_Status_Enum {
  AlreadyDone = 'ALREADY_DONE',
  AppointmentBooked = 'APPOINTMENT_BOOKED',
  CustomerNotReached = 'CUSTOMER_NOT_REACHED',
  CustomerWillCallBack = 'CUSTOMER_WILL_CALL_BACK',
  Done = 'DONE',
  NotRequired = 'NOT_REQUIRED',
  Open = 'OPEN'
}

/** Boolean expression to compare columns of type "after_sales_status_enum". All fields are combined with logical 'AND'. */
export interface After_Sales_Status_Enum_Comparison_Exp {
  _eq?: Maybe<After_Sales_Status_Enum>;
  _in?: Maybe<Array<After_Sales_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<After_Sales_Status_Enum>;
  _nin?: Maybe<Array<After_Sales_Status_Enum>>;
}

/** input type for inserting data into table "after_sales_status" */
export interface After_Sales_Status_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface After_Sales_Status_Max_Fields {
  __typename?: 'after_sales_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface After_Sales_Status_Min_Fields {
  __typename?: 'after_sales_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "after_sales_status" */
export interface After_Sales_Status_Mutation_Response {
  __typename?: 'after_sales_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<After_Sales_Status>;
}

/** on_conflict condition type for table "after_sales_status" */
export interface After_Sales_Status_On_Conflict {
  constraint: After_Sales_Status_Constraint;
  update_columns: Array<After_Sales_Status_Update_Column>;
  where?: Maybe<After_Sales_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "after_sales_status". */
export interface After_Sales_Status_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: after_sales_status */
export interface After_Sales_Status_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "after_sales_status" */
export enum After_Sales_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "after_sales_status" */
export interface After_Sales_Status_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "after_sales_status" */
export interface After_Sales_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: After_Sales_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface After_Sales_Status_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "after_sales_status" */
export enum After_Sales_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface After_Sales_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<After_Sales_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: After_Sales_Status_Bool_Exp;
}

/** columns and relationships of "asset_link_family_notifications" */
export interface Asset_Link_Family_Notifications {
  __typename?: 'asset_link_family_notifications';
  asset_watchdog_link_id?: Maybe<Scalars['uuid']>;
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  status: Notification_Status_Enum;
  title: Scalars['String'];
  type: Notification_Type_Enum;
  user_id: Scalars['uuid'];
}

/** aggregated selection of "asset_link_family_notifications" */
export interface Asset_Link_Family_Notifications_Aggregate {
  __typename?: 'asset_link_family_notifications_aggregate';
  aggregate?: Maybe<Asset_Link_Family_Notifications_Aggregate_Fields>;
  nodes: Array<Asset_Link_Family_Notifications>;
}

/** aggregate fields of "asset_link_family_notifications" */
export interface Asset_Link_Family_Notifications_Aggregate_Fields {
  __typename?: 'asset_link_family_notifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Asset_Link_Family_Notifications_Max_Fields>;
  min?: Maybe<Asset_Link_Family_Notifications_Min_Fields>;
}


/** aggregate fields of "asset_link_family_notifications" */
export interface Asset_Link_Family_Notifications_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Asset_Link_Family_Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "asset_link_family_notifications". All fields are combined with a logical 'AND'. */
export interface Asset_Link_Family_Notifications_Bool_Exp {
  _and?: Maybe<Array<Asset_Link_Family_Notifications_Bool_Exp>>;
  _not?: Maybe<Asset_Link_Family_Notifications_Bool_Exp>;
  _or?: Maybe<Array<Asset_Link_Family_Notifications_Bool_Exp>>;
  asset_watchdog_link_id?: Maybe<Uuid_Comparison_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<Notification_Status_Enum_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Notification_Type_Enum_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "asset_link_family_notifications" */
export enum Asset_Link_Family_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationTypeFamilyAssetLinkPkey = 'notification_type_family_asset_link_pkey'
}

/** input type for inserting data into table "asset_link_family_notifications" */
export interface Asset_Link_Family_Notifications_Insert_Input {
  asset_watchdog_link_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Notification_Status_Enum>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type_Enum>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Asset_Link_Family_Notifications_Max_Fields {
  __typename?: 'asset_link_family_notifications_max_fields';
  asset_watchdog_link_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface Asset_Link_Family_Notifications_Min_Fields {
  __typename?: 'asset_link_family_notifications_min_fields';
  asset_watchdog_link_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "asset_link_family_notifications" */
export interface Asset_Link_Family_Notifications_Mutation_Response {
  __typename?: 'asset_link_family_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Asset_Link_Family_Notifications>;
}

/** on_conflict condition type for table "asset_link_family_notifications" */
export interface Asset_Link_Family_Notifications_On_Conflict {
  constraint: Asset_Link_Family_Notifications_Constraint;
  update_columns: Array<Asset_Link_Family_Notifications_Update_Column>;
  where?: Maybe<Asset_Link_Family_Notifications_Bool_Exp>;
}

/** Ordering options when selecting data from "asset_link_family_notifications". */
export interface Asset_Link_Family_Notifications_Order_By {
  asset_watchdog_link_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** primary key columns input for table: asset_link_family_notifications */
export interface Asset_Link_Family_Notifications_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "asset_link_family_notifications" */
export enum Asset_Link_Family_Notifications_Select_Column {
  /** column name */
  AssetWatchdogLinkId = 'asset_watchdog_link_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "asset_link_family_notifications" */
export interface Asset_Link_Family_Notifications_Set_Input {
  asset_watchdog_link_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Notification_Status_Enum>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type_Enum>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "asset_link_family_notifications" */
export interface Asset_Link_Family_Notifications_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Asset_Link_Family_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Asset_Link_Family_Notifications_Stream_Cursor_Value_Input {
  asset_watchdog_link_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Notification_Status_Enum>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type_Enum>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "asset_link_family_notifications" */
export enum Asset_Link_Family_Notifications_Update_Column {
  /** column name */
  AssetWatchdogLinkId = 'asset_watchdog_link_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export interface Asset_Link_Family_Notifications_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Asset_Link_Family_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Asset_Link_Family_Notifications_Bool_Exp;
}

/** columns and relationships of "asset_watchdog_link_status" */
export interface Asset_Watchdog_Link_Status {
  __typename?: 'asset_watchdog_link_status';
  value: Scalars['String'];
}

/** aggregated selection of "asset_watchdog_link_status" */
export interface Asset_Watchdog_Link_Status_Aggregate {
  __typename?: 'asset_watchdog_link_status_aggregate';
  aggregate?: Maybe<Asset_Watchdog_Link_Status_Aggregate_Fields>;
  nodes: Array<Asset_Watchdog_Link_Status>;
}

/** aggregate fields of "asset_watchdog_link_status" */
export interface Asset_Watchdog_Link_Status_Aggregate_Fields {
  __typename?: 'asset_watchdog_link_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Asset_Watchdog_Link_Status_Max_Fields>;
  min?: Maybe<Asset_Watchdog_Link_Status_Min_Fields>;
}


/** aggregate fields of "asset_watchdog_link_status" */
export interface Asset_Watchdog_Link_Status_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Asset_Watchdog_Link_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "asset_watchdog_link_status". All fields are combined with a logical 'AND'. */
export interface Asset_Watchdog_Link_Status_Bool_Exp {
  _and?: Maybe<Array<Asset_Watchdog_Link_Status_Bool_Exp>>;
  _not?: Maybe<Asset_Watchdog_Link_Status_Bool_Exp>;
  _or?: Maybe<Array<Asset_Watchdog_Link_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "asset_watchdog_link_status" */
export enum Asset_Watchdog_Link_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  AssetWatchdogAssetLinkStatusPkey = 'asset_watchdog_asset_link_status_pkey'
}

export enum Asset_Watchdog_Link_Status_Enum {
  Dead = 'DEAD',
  Ok = 'OK',
  Updated = 'UPDATED'
}

/** Boolean expression to compare columns of type "asset_watchdog_link_status_enum". All fields are combined with logical 'AND'. */
export interface Asset_Watchdog_Link_Status_Enum_Comparison_Exp {
  _eq?: Maybe<Asset_Watchdog_Link_Status_Enum>;
  _in?: Maybe<Array<Asset_Watchdog_Link_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Asset_Watchdog_Link_Status_Enum>;
  _nin?: Maybe<Array<Asset_Watchdog_Link_Status_Enum>>;
}

/** input type for inserting data into table "asset_watchdog_link_status" */
export interface Asset_Watchdog_Link_Status_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Asset_Watchdog_Link_Status_Max_Fields {
  __typename?: 'asset_watchdog_link_status_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Asset_Watchdog_Link_Status_Min_Fields {
  __typename?: 'asset_watchdog_link_status_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "asset_watchdog_link_status" */
export interface Asset_Watchdog_Link_Status_Mutation_Response {
  __typename?: 'asset_watchdog_link_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Asset_Watchdog_Link_Status>;
}

/** on_conflict condition type for table "asset_watchdog_link_status" */
export interface Asset_Watchdog_Link_Status_On_Conflict {
  constraint: Asset_Watchdog_Link_Status_Constraint;
  update_columns: Array<Asset_Watchdog_Link_Status_Update_Column>;
  where?: Maybe<Asset_Watchdog_Link_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "asset_watchdog_link_status". */
export interface Asset_Watchdog_Link_Status_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: asset_watchdog_link_status */
export interface Asset_Watchdog_Link_Status_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "asset_watchdog_link_status" */
export enum Asset_Watchdog_Link_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "asset_watchdog_link_status" */
export interface Asset_Watchdog_Link_Status_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "asset_watchdog_link_status" */
export interface Asset_Watchdog_Link_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Asset_Watchdog_Link_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Asset_Watchdog_Link_Status_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "asset_watchdog_link_status" */
export enum Asset_Watchdog_Link_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Asset_Watchdog_Link_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Asset_Watchdog_Link_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Asset_Watchdog_Link_Status_Bool_Exp;
}

/** columns and relationships of "asset_watchdog_links" */
export interface Asset_Watchdog_Links {
  __typename?: 'asset_watchdog_links';
  hash?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  source_url: Scalars['String'];
  status?: Maybe<Asset_Watchdog_Link_Status_Enum>;
  url: Scalars['String'];
}

/** aggregated selection of "asset_watchdog_links" */
export interface Asset_Watchdog_Links_Aggregate {
  __typename?: 'asset_watchdog_links_aggregate';
  aggregate?: Maybe<Asset_Watchdog_Links_Aggregate_Fields>;
  nodes: Array<Asset_Watchdog_Links>;
}

/** aggregate fields of "asset_watchdog_links" */
export interface Asset_Watchdog_Links_Aggregate_Fields {
  __typename?: 'asset_watchdog_links_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Asset_Watchdog_Links_Max_Fields>;
  min?: Maybe<Asset_Watchdog_Links_Min_Fields>;
}


/** aggregate fields of "asset_watchdog_links" */
export interface Asset_Watchdog_Links_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Asset_Watchdog_Links_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "asset_watchdog_links". All fields are combined with a logical 'AND'. */
export interface Asset_Watchdog_Links_Bool_Exp {
  _and?: Maybe<Array<Asset_Watchdog_Links_Bool_Exp>>;
  _not?: Maybe<Asset_Watchdog_Links_Bool_Exp>;
  _or?: Maybe<Array<Asset_Watchdog_Links_Bool_Exp>>;
  hash?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  source_url?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Asset_Watchdog_Link_Status_Enum_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "asset_watchdog_links" */
export enum Asset_Watchdog_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssetLinksPkey = 'asset_links_pkey'
}

/** input type for inserting data into table "asset_watchdog_links" */
export interface Asset_Watchdog_Links_Insert_Input {
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  source_url?: Maybe<Scalars['String']>;
  status?: Maybe<Asset_Watchdog_Link_Status_Enum>;
  url?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Asset_Watchdog_Links_Max_Fields {
  __typename?: 'asset_watchdog_links_max_fields';
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  source_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Asset_Watchdog_Links_Min_Fields {
  __typename?: 'asset_watchdog_links_min_fields';
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  source_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "asset_watchdog_links" */
export interface Asset_Watchdog_Links_Mutation_Response {
  __typename?: 'asset_watchdog_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Asset_Watchdog_Links>;
}

/** on_conflict condition type for table "asset_watchdog_links" */
export interface Asset_Watchdog_Links_On_Conflict {
  constraint: Asset_Watchdog_Links_Constraint;
  update_columns: Array<Asset_Watchdog_Links_Update_Column>;
  where?: Maybe<Asset_Watchdog_Links_Bool_Exp>;
}

/** Ordering options when selecting data from "asset_watchdog_links". */
export interface Asset_Watchdog_Links_Order_By {
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  source_url?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
}

/** primary key columns input for table: asset_watchdog_links */
export interface Asset_Watchdog_Links_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "asset_watchdog_links" */
export enum Asset_Watchdog_Links_Select_Column {
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SourceUrl = 'source_url',
  /** column name */
  Status = 'status',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "asset_watchdog_links" */
export interface Asset_Watchdog_Links_Set_Input {
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  source_url?: Maybe<Scalars['String']>;
  status?: Maybe<Asset_Watchdog_Link_Status_Enum>;
  url?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "asset_watchdog_links" */
export interface Asset_Watchdog_Links_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Asset_Watchdog_Links_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Asset_Watchdog_Links_Stream_Cursor_Value_Input {
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  source_url?: Maybe<Scalars['String']>;
  status?: Maybe<Asset_Watchdog_Link_Status_Enum>;
  url?: Maybe<Scalars['String']>;
}

/** update columns of table "asset_watchdog_links" */
export enum Asset_Watchdog_Links_Update_Column {
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SourceUrl = 'source_url',
  /** column name */
  Status = 'status',
  /** column name */
  Url = 'url'
}

export interface Asset_Watchdog_Links_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Asset_Watchdog_Links_Set_Input>;
  /** filter the rows which have to be updated */
  where: Asset_Watchdog_Links_Bool_Exp;
}

/** columns and relationships of "brand_boni_types" */
export interface Brand_Boni_Types {
  __typename?: 'brand_boni_types';
  brand_id: Scalars['uuid'];
  type: Scalars['String'];
}

/** aggregated selection of "brand_boni_types" */
export interface Brand_Boni_Types_Aggregate {
  __typename?: 'brand_boni_types_aggregate';
  aggregate?: Maybe<Brand_Boni_Types_Aggregate_Fields>;
  nodes: Array<Brand_Boni_Types>;
}

/** aggregate fields of "brand_boni_types" */
export interface Brand_Boni_Types_Aggregate_Fields {
  __typename?: 'brand_boni_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Brand_Boni_Types_Max_Fields>;
  min?: Maybe<Brand_Boni_Types_Min_Fields>;
}


/** aggregate fields of "brand_boni_types" */
export interface Brand_Boni_Types_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Brand_Boni_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "brand_boni_types". All fields are combined with a logical 'AND'. */
export interface Brand_Boni_Types_Bool_Exp {
  _and?: Maybe<Array<Brand_Boni_Types_Bool_Exp>>;
  _not?: Maybe<Brand_Boni_Types_Bool_Exp>;
  _or?: Maybe<Array<Brand_Boni_Types_Bool_Exp>>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "brand_boni_types" */
export enum Brand_Boni_Types_Constraint {
  /** unique or primary key constraint on columns "brand_id", "type" */
  BrandBoniTypesPkey = 'brand_boni_types_pkey'
}

/** input type for inserting data into table "brand_boni_types" */
export interface Brand_Boni_Types_Insert_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Brand_Boni_Types_Max_Fields {
  __typename?: 'brand_boni_types_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Brand_Boni_Types_Min_Fields {
  __typename?: 'brand_boni_types_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "brand_boni_types" */
export interface Brand_Boni_Types_Mutation_Response {
  __typename?: 'brand_boni_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Brand_Boni_Types>;
}

/** on_conflict condition type for table "brand_boni_types" */
export interface Brand_Boni_Types_On_Conflict {
  constraint: Brand_Boni_Types_Constraint;
  update_columns: Array<Brand_Boni_Types_Update_Column>;
  where?: Maybe<Brand_Boni_Types_Bool_Exp>;
}

/** Ordering options when selecting data from "brand_boni_types". */
export interface Brand_Boni_Types_Order_By {
  brand_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
}

/** primary key columns input for table: brand_boni_types */
export interface Brand_Boni_Types_Pk_Columns_Input {
  brand_id: Scalars['uuid'];
  type: Scalars['String'];
}

/** select columns of table "brand_boni_types" */
export enum Brand_Boni_Types_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "brand_boni_types" */
export interface Brand_Boni_Types_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "brand_boni_types" */
export interface Brand_Boni_Types_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Brand_Boni_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Brand_Boni_Types_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
}

/** update columns of table "brand_boni_types" */
export enum Brand_Boni_Types_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  Type = 'type'
}

export interface Brand_Boni_Types_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Brand_Boni_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brand_Boni_Types_Bool_Exp;
}

/** columns and relationships of "brand_supplier" */
export interface Brand_Suppliers {
  __typename?: 'brand_suppliers';
  brand_id: Scalars['uuid'];
  is_after_sales_supplier: Scalars['Boolean'];
  is_sales_supplier: Scalars['Boolean'];
  supplier_id: Scalars['uuid'];
}

/** aggregated selection of "brand_supplier" */
export interface Brand_Suppliers_Aggregate {
  __typename?: 'brand_suppliers_aggregate';
  aggregate?: Maybe<Brand_Suppliers_Aggregate_Fields>;
  nodes: Array<Brand_Suppliers>;
}

/** aggregate fields of "brand_supplier" */
export interface Brand_Suppliers_Aggregate_Fields {
  __typename?: 'brand_suppliers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Brand_Suppliers_Max_Fields>;
  min?: Maybe<Brand_Suppliers_Min_Fields>;
}


/** aggregate fields of "brand_supplier" */
export interface Brand_Suppliers_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Brand_Suppliers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "brand_supplier". All fields are combined with a logical 'AND'. */
export interface Brand_Suppliers_Bool_Exp {
  _and?: Maybe<Array<Brand_Suppliers_Bool_Exp>>;
  _not?: Maybe<Brand_Suppliers_Bool_Exp>;
  _or?: Maybe<Array<Brand_Suppliers_Bool_Exp>>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  is_after_sales_supplier?: Maybe<Boolean_Comparison_Exp>;
  is_sales_supplier?: Maybe<Boolean_Comparison_Exp>;
  supplier_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "brand_supplier" */
export enum Brand_Suppliers_Constraint {
  /** unique or primary key constraint on columns "brand_id", "supplier_id" */
  BrandSupplierPkey = 'brand_supplier_pkey'
}

/** input type for inserting data into table "brand_supplier" */
export interface Brand_Suppliers_Insert_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  is_after_sales_supplier?: Maybe<Scalars['Boolean']>;
  is_sales_supplier?: Maybe<Scalars['Boolean']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Brand_Suppliers_Max_Fields {
  __typename?: 'brand_suppliers_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface Brand_Suppliers_Min_Fields {
  __typename?: 'brand_suppliers_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "brand_supplier" */
export interface Brand_Suppliers_Mutation_Response {
  __typename?: 'brand_suppliers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Brand_Suppliers>;
}

/** on_conflict condition type for table "brand_supplier" */
export interface Brand_Suppliers_On_Conflict {
  constraint: Brand_Suppliers_Constraint;
  update_columns: Array<Brand_Suppliers_Update_Column>;
  where?: Maybe<Brand_Suppliers_Bool_Exp>;
}

/** Ordering options when selecting data from "brand_supplier". */
export interface Brand_Suppliers_Order_By {
  brand_id?: Maybe<Order_By>;
  is_after_sales_supplier?: Maybe<Order_By>;
  is_sales_supplier?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
}

/** primary key columns input for table: brand_supplier */
export interface Brand_Suppliers_Pk_Columns_Input {
  brand_id: Scalars['uuid'];
  supplier_id: Scalars['uuid'];
}

/** select columns of table "brand_supplier" */
export enum Brand_Suppliers_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  IsAfterSalesSupplier = 'is_after_sales_supplier',
  /** column name */
  IsSalesSupplier = 'is_sales_supplier',
  /** column name */
  SupplierId = 'supplier_id'
}

/** input type for updating data in table "brand_supplier" */
export interface Brand_Suppliers_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  is_after_sales_supplier?: Maybe<Scalars['Boolean']>;
  is_sales_supplier?: Maybe<Scalars['Boolean']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "brand_suppliers" */
export interface Brand_Suppliers_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Brand_Suppliers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Brand_Suppliers_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  is_after_sales_supplier?: Maybe<Scalars['Boolean']>;
  is_sales_supplier?: Maybe<Scalars['Boolean']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "brand_supplier" */
export enum Brand_Suppliers_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  IsAfterSalesSupplier = 'is_after_sales_supplier',
  /** column name */
  IsSalesSupplier = 'is_sales_supplier',
  /** column name */
  SupplierId = 'supplier_id'
}

export interface Brand_Suppliers_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Brand_Suppliers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brand_Suppliers_Bool_Exp;
}

/** columns and relationships of "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield {
  __typename?: 'brand_vehicle_metafield';
  brand_id: Scalars['uuid'];
  default_value?: Maybe<Scalars['String']>;
  required_for_import?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  vehicle_metafield: Vehicle_Metafields;
  vehicle_metafield_name: Vehicle_Metafields_Enum;
}

/** aggregated selection of "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Aggregate {
  __typename?: 'brand_vehicle_metafield_aggregate';
  aggregate?: Maybe<Brand_Vehicle_Metafield_Aggregate_Fields>;
  nodes: Array<Brand_Vehicle_Metafield>;
}

export interface Brand_Vehicle_Metafield_Aggregate_Bool_Exp {
  bool_and?: Maybe<Brand_Vehicle_Metafield_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Brand_Vehicle_Metafield_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Brand_Vehicle_Metafield_Aggregate_Bool_Exp_Count>;
}

export interface Brand_Vehicle_Metafield_Aggregate_Bool_Exp_Bool_And {
  arguments: Brand_Vehicle_Metafield_Select_Column_Brand_Vehicle_Metafield_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Brand_Vehicle_Metafield_Aggregate_Bool_Exp_Bool_Or {
  arguments: Brand_Vehicle_Metafield_Select_Column_Brand_Vehicle_Metafield_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Brand_Vehicle_Metafield_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Brand_Vehicle_Metafield_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Aggregate_Fields {
  __typename?: 'brand_vehicle_metafield_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Brand_Vehicle_Metafield_Max_Fields>;
  min?: Maybe<Brand_Vehicle_Metafield_Min_Fields>;
}


/** aggregate fields of "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Brand_Vehicle_Metafield_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Brand_Vehicle_Metafield_Max_Order_By>;
  min?: Maybe<Brand_Vehicle_Metafield_Min_Order_By>;
}

/** input type for inserting array relation for remote table "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Arr_Rel_Insert_Input {
  data: Array<Brand_Vehicle_Metafield_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Brand_Vehicle_Metafield_On_Conflict>;
}

/** Boolean expression to filter rows from the table "brand_vehicle_metafield". All fields are combined with a logical 'AND'. */
export interface Brand_Vehicle_Metafield_Bool_Exp {
  _and?: Maybe<Array<Brand_Vehicle_Metafield_Bool_Exp>>;
  _not?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
  _or?: Maybe<Array<Brand_Vehicle_Metafield_Bool_Exp>>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  default_value?: Maybe<String_Comparison_Exp>;
  required_for_import?: Maybe<Boolean_Comparison_Exp>;
  vehicle_metafield?: Maybe<Vehicle_Metafields_Bool_Exp>;
  vehicle_metafield_name?: Maybe<Vehicle_Metafields_Enum_Comparison_Exp>;
}

/** unique or primary key constraints on table "brand_vehicle_metafield" */
export enum Brand_Vehicle_Metafield_Constraint {
  /** unique or primary key constraint on columns "brand_id", "vehicle_metafield_name" */
  BrandVehicleMetafieldPkey = 'brand_vehicle_metafield_pkey'
}

/** input type for inserting data into table "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Insert_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  default_value?: Maybe<Scalars['String']>;
  required_for_import?: Maybe<Scalars['Boolean']>;
  vehicle_metafield?: Maybe<Vehicle_Metafields_Obj_Rel_Insert_Input>;
  vehicle_metafield_name?: Maybe<Vehicle_Metafields_Enum>;
}

/** aggregate max on columns */
export interface Brand_Vehicle_Metafield_Max_Fields {
  __typename?: 'brand_vehicle_metafield_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  default_value?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Max_Order_By {
  brand_id?: Maybe<Order_By>;
  default_value?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Brand_Vehicle_Metafield_Min_Fields {
  __typename?: 'brand_vehicle_metafield_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  default_value?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Min_Order_By {
  brand_id?: Maybe<Order_By>;
  default_value?: Maybe<Order_By>;
}

/** response of any mutation on the table "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Mutation_Response {
  __typename?: 'brand_vehicle_metafield_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Brand_Vehicle_Metafield>;
}

/** on_conflict condition type for table "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_On_Conflict {
  constraint: Brand_Vehicle_Metafield_Constraint;
  update_columns: Array<Brand_Vehicle_Metafield_Update_Column>;
  where?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
}

/** Ordering options when selecting data from "brand_vehicle_metafield". */
export interface Brand_Vehicle_Metafield_Order_By {
  brand_id?: Maybe<Order_By>;
  default_value?: Maybe<Order_By>;
  required_for_import?: Maybe<Order_By>;
  vehicle_metafield?: Maybe<Vehicle_Metafields_Order_By>;
  vehicle_metafield_name?: Maybe<Order_By>;
}

/** primary key columns input for table: brand_vehicle_metafield */
export interface Brand_Vehicle_Metafield_Pk_Columns_Input {
  brand_id: Scalars['uuid'];
  vehicle_metafield_name: Vehicle_Metafields_Enum;
}

/** select columns of table "brand_vehicle_metafield" */
export enum Brand_Vehicle_Metafield_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  DefaultValue = 'default_value',
  /** column name */
  RequiredForImport = 'required_for_import',
  /** column name */
  VehicleMetafieldName = 'vehicle_metafield_name'
}

/** select "brand_vehicle_metafield_aggregate_bool_exp_bool_and_arguments_columns" columns of table "brand_vehicle_metafield" */
export enum Brand_Vehicle_Metafield_Select_Column_Brand_Vehicle_Metafield_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  RequiredForImport = 'required_for_import'
}

/** select "brand_vehicle_metafield_aggregate_bool_exp_bool_or_arguments_columns" columns of table "brand_vehicle_metafield" */
export enum Brand_Vehicle_Metafield_Select_Column_Brand_Vehicle_Metafield_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  RequiredForImport = 'required_for_import'
}

/** input type for updating data in table "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  default_value?: Maybe<Scalars['String']>;
  required_for_import?: Maybe<Scalars['Boolean']>;
  vehicle_metafield_name?: Maybe<Vehicle_Metafields_Enum>;
}

/** Streaming cursor of the table "brand_vehicle_metafield" */
export interface Brand_Vehicle_Metafield_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Brand_Vehicle_Metafield_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Brand_Vehicle_Metafield_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  default_value?: Maybe<Scalars['String']>;
  required_for_import?: Maybe<Scalars['Boolean']>;
  vehicle_metafield_name?: Maybe<Vehicle_Metafields_Enum>;
}

/** update columns of table "brand_vehicle_metafield" */
export enum Brand_Vehicle_Metafield_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  DefaultValue = 'default_value',
  /** column name */
  RequiredForImport = 'required_for_import',
  /** column name */
  VehicleMetafieldName = 'vehicle_metafield_name'
}

export interface Brand_Vehicle_Metafield_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Brand_Vehicle_Metafield_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brand_Vehicle_Metafield_Bool_Exp;
}

/** columns and relationships of "contact_note_type" */
export interface Contact_Note_Type {
  __typename?: 'contact_note_type';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
}

/** aggregated selection of "contact_note_type" */
export interface Contact_Note_Type_Aggregate {
  __typename?: 'contact_note_type_aggregate';
  aggregate?: Maybe<Contact_Note_Type_Aggregate_Fields>;
  nodes: Array<Contact_Note_Type>;
}

/** aggregate fields of "contact_note_type" */
export interface Contact_Note_Type_Aggregate_Fields {
  __typename?: 'contact_note_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contact_Note_Type_Max_Fields>;
  min?: Maybe<Contact_Note_Type_Min_Fields>;
}


/** aggregate fields of "contact_note_type" */
export interface Contact_Note_Type_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Contact_Note_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "contact_note_type". All fields are combined with a logical 'AND'. */
export interface Contact_Note_Type_Bool_Exp {
  _and?: Maybe<Array<Contact_Note_Type_Bool_Exp>>;
  _not?: Maybe<Contact_Note_Type_Bool_Exp>;
  _or?: Maybe<Array<Contact_Note_Type_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "contact_note_type" */
export enum Contact_Note_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ContactNoteTypePkey = 'contact_note_type_pkey'
}

export enum Contact_Note_Type_Enum {
  CustomerNotReached = 'CUSTOMER_NOT_REACHED',
  CustomerWillCallBack = 'CUSTOMER_WILL_CALL_BACK',
  Generic = 'GENERIC'
}

/** Boolean expression to compare columns of type "contact_note_type_enum". All fields are combined with logical 'AND'. */
export interface Contact_Note_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Contact_Note_Type_Enum>;
  _in?: Maybe<Array<Contact_Note_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Contact_Note_Type_Enum>;
  _nin?: Maybe<Array<Contact_Note_Type_Enum>>;
}

/** input type for inserting data into table "contact_note_type" */
export interface Contact_Note_Type_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Contact_Note_Type_Max_Fields {
  __typename?: 'contact_note_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Contact_Note_Type_Min_Fields {
  __typename?: 'contact_note_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "contact_note_type" */
export interface Contact_Note_Type_Mutation_Response {
  __typename?: 'contact_note_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Note_Type>;
}

/** on_conflict condition type for table "contact_note_type" */
export interface Contact_Note_Type_On_Conflict {
  constraint: Contact_Note_Type_Constraint;
  update_columns: Array<Contact_Note_Type_Update_Column>;
  where?: Maybe<Contact_Note_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "contact_note_type". */
export interface Contact_Note_Type_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: contact_note_type */
export interface Contact_Note_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "contact_note_type" */
export enum Contact_Note_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "contact_note_type" */
export interface Contact_Note_Type_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "contact_note_type" */
export interface Contact_Note_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Contact_Note_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Contact_Note_Type_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "contact_note_type" */
export enum Contact_Note_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Contact_Note_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Note_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Note_Type_Bool_Exp;
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customer_customer_tag" */
export interface Customer_Customer_Tag {
  __typename?: 'customer_customer_tag';
  customer_id: Scalars['uuid'];
  customer_tag_value: Customer_Tag_Enum;
}

/** aggregated selection of "customer_customer_tag" */
export interface Customer_Customer_Tag_Aggregate {
  __typename?: 'customer_customer_tag_aggregate';
  aggregate?: Maybe<Customer_Customer_Tag_Aggregate_Fields>;
  nodes: Array<Customer_Customer_Tag>;
}

export interface Customer_Customer_Tag_Aggregate_Bool_Exp {
  count?: Maybe<Customer_Customer_Tag_Aggregate_Bool_Exp_Count>;
}

export interface Customer_Customer_Tag_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Customer_Customer_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customer_Customer_Tag_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "customer_customer_tag" */
export interface Customer_Customer_Tag_Aggregate_Fields {
  __typename?: 'customer_customer_tag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Customer_Tag_Max_Fields>;
  min?: Maybe<Customer_Customer_Tag_Min_Fields>;
}


/** aggregate fields of "customer_customer_tag" */
export interface Customer_Customer_Tag_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Customer_Customer_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "customer_customer_tag" */
export interface Customer_Customer_Tag_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Customer_Customer_Tag_Max_Order_By>;
  min?: Maybe<Customer_Customer_Tag_Min_Order_By>;
}

/** input type for inserting array relation for remote table "customer_customer_tag" */
export interface Customer_Customer_Tag_Arr_Rel_Insert_Input {
  data: Array<Customer_Customer_Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Customer_Customer_Tag_On_Conflict>;
}

/** Boolean expression to filter rows from the table "customer_customer_tag". All fields are combined with a logical 'AND'. */
export interface Customer_Customer_Tag_Bool_Exp {
  _and?: Maybe<Array<Customer_Customer_Tag_Bool_Exp>>;
  _not?: Maybe<Customer_Customer_Tag_Bool_Exp>;
  _or?: Maybe<Array<Customer_Customer_Tag_Bool_Exp>>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  customer_tag_value?: Maybe<Customer_Tag_Enum_Comparison_Exp>;
}

/** unique or primary key constraints on table "customer_customer_tag" */
export enum Customer_Customer_Tag_Constraint {
  /** unique or primary key constraint on columns "customer_id", "customer_tag_value" */
  CustomerCustomerTagPkey = 'customer_customer_tag_pkey'
}

/** input type for inserting data into table "customer_customer_tag" */
export interface Customer_Customer_Tag_Insert_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  customer_tag_value?: Maybe<Customer_Tag_Enum>;
}

/** aggregate max on columns */
export interface Customer_Customer_Tag_Max_Fields {
  __typename?: 'customer_customer_tag_max_fields';
  customer_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "customer_customer_tag" */
export interface Customer_Customer_Tag_Max_Order_By {
  customer_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Customer_Customer_Tag_Min_Fields {
  __typename?: 'customer_customer_tag_min_fields';
  customer_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "customer_customer_tag" */
export interface Customer_Customer_Tag_Min_Order_By {
  customer_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "customer_customer_tag" */
export interface Customer_Customer_Tag_Mutation_Response {
  __typename?: 'customer_customer_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Customer_Tag>;
}

/** on_conflict condition type for table "customer_customer_tag" */
export interface Customer_Customer_Tag_On_Conflict {
  constraint: Customer_Customer_Tag_Constraint;
  update_columns: Array<Customer_Customer_Tag_Update_Column>;
  where?: Maybe<Customer_Customer_Tag_Bool_Exp>;
}

/** Ordering options when selecting data from "customer_customer_tag". */
export interface Customer_Customer_Tag_Order_By {
  customer_id?: Maybe<Order_By>;
  customer_tag_value?: Maybe<Order_By>;
}

/** primary key columns input for table: customer_customer_tag */
export interface Customer_Customer_Tag_Pk_Columns_Input {
  customer_id: Scalars['uuid'];
  customer_tag_value: Customer_Tag_Enum;
}

/** select columns of table "customer_customer_tag" */
export enum Customer_Customer_Tag_Select_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerTagValue = 'customer_tag_value'
}

/** input type for updating data in table "customer_customer_tag" */
export interface Customer_Customer_Tag_Set_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  customer_tag_value?: Maybe<Customer_Tag_Enum>;
}

/** Streaming cursor of the table "customer_customer_tag" */
export interface Customer_Customer_Tag_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Customer_Customer_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Customer_Customer_Tag_Stream_Cursor_Value_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  customer_tag_value?: Maybe<Customer_Tag_Enum>;
}

/** update columns of table "customer_customer_tag" */
export enum Customer_Customer_Tag_Update_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerTagValue = 'customer_tag_value'
}

export interface Customer_Customer_Tag_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customer_Customer_Tag_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Customer_Tag_Bool_Exp;
}

/** columns and relationships of "customer_salutation" */
export interface Customer_Salutation {
  __typename?: 'customer_salutation';
  customer_id: Scalars['uuid'];
  salutation_type_value: Salutation_Type_Enum;
  text: Scalars['String'];
}

/** aggregated selection of "customer_salutation" */
export interface Customer_Salutation_Aggregate {
  __typename?: 'customer_salutation_aggregate';
  aggregate?: Maybe<Customer_Salutation_Aggregate_Fields>;
  nodes: Array<Customer_Salutation>;
}

export interface Customer_Salutation_Aggregate_Bool_Exp {
  count?: Maybe<Customer_Salutation_Aggregate_Bool_Exp_Count>;
}

export interface Customer_Salutation_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Customer_Salutation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Customer_Salutation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "customer_salutation" */
export interface Customer_Salutation_Aggregate_Fields {
  __typename?: 'customer_salutation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Salutation_Max_Fields>;
  min?: Maybe<Customer_Salutation_Min_Fields>;
}


/** aggregate fields of "customer_salutation" */
export interface Customer_Salutation_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Customer_Salutation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "customer_salutation" */
export interface Customer_Salutation_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Customer_Salutation_Max_Order_By>;
  min?: Maybe<Customer_Salutation_Min_Order_By>;
}

/** input type for inserting array relation for remote table "customer_salutation" */
export interface Customer_Salutation_Arr_Rel_Insert_Input {
  data: Array<Customer_Salutation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Customer_Salutation_On_Conflict>;
}

/** Boolean expression to filter rows from the table "customer_salutation". All fields are combined with a logical 'AND'. */
export interface Customer_Salutation_Bool_Exp {
  _and?: Maybe<Array<Customer_Salutation_Bool_Exp>>;
  _not?: Maybe<Customer_Salutation_Bool_Exp>;
  _or?: Maybe<Array<Customer_Salutation_Bool_Exp>>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  salutation_type_value?: Maybe<Salutation_Type_Enum_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "customer_salutation" */
export enum Customer_Salutation_Constraint {
  /** unique or primary key constraint on columns "customer_id", "salutation_type_value" */
  CustomerSalutationTypePkey = 'customer_salutation_type_pkey'
}

/** input type for inserting data into table "customer_salutation" */
export interface Customer_Salutation_Insert_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  salutation_type_value?: Maybe<Salutation_Type_Enum>;
  text?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Customer_Salutation_Max_Fields {
  __typename?: 'customer_salutation_max_fields';
  customer_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "customer_salutation" */
export interface Customer_Salutation_Max_Order_By {
  customer_id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Customer_Salutation_Min_Fields {
  __typename?: 'customer_salutation_min_fields';
  customer_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "customer_salutation" */
export interface Customer_Salutation_Min_Order_By {
  customer_id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
}

/** response of any mutation on the table "customer_salutation" */
export interface Customer_Salutation_Mutation_Response {
  __typename?: 'customer_salutation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Salutation>;
}

/** on_conflict condition type for table "customer_salutation" */
export interface Customer_Salutation_On_Conflict {
  constraint: Customer_Salutation_Constraint;
  update_columns: Array<Customer_Salutation_Update_Column>;
  where?: Maybe<Customer_Salutation_Bool_Exp>;
}

/** Ordering options when selecting data from "customer_salutation". */
export interface Customer_Salutation_Order_By {
  customer_id?: Maybe<Order_By>;
  salutation_type_value?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
}

/** primary key columns input for table: customer_salutation */
export interface Customer_Salutation_Pk_Columns_Input {
  customer_id: Scalars['uuid'];
  salutation_type_value: Salutation_Type_Enum;
}

/** select columns of table "customer_salutation" */
export enum Customer_Salutation_Select_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  SalutationTypeValue = 'salutation_type_value',
  /** column name */
  Text = 'text'
}

/** input type for updating data in table "customer_salutation" */
export interface Customer_Salutation_Set_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  salutation_type_value?: Maybe<Salutation_Type_Enum>;
  text?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "customer_salutation" */
export interface Customer_Salutation_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Customer_Salutation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Customer_Salutation_Stream_Cursor_Value_Input {
  customer_id?: Maybe<Scalars['uuid']>;
  salutation_type_value?: Maybe<Salutation_Type_Enum>;
  text?: Maybe<Scalars['String']>;
}

/** update columns of table "customer_salutation" */
export enum Customer_Salutation_Update_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  SalutationTypeValue = 'salutation_type_value',
  /** column name */
  Text = 'text'
}

export interface Customer_Salutation_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customer_Salutation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Salutation_Bool_Exp;
}

/** columns and relationships of "customer_tag" */
export interface Customer_Tag {
  __typename?: 'customer_tag';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
}

/** aggregated selection of "customer_tag" */
export interface Customer_Tag_Aggregate {
  __typename?: 'customer_tag_aggregate';
  aggregate?: Maybe<Customer_Tag_Aggregate_Fields>;
  nodes: Array<Customer_Tag>;
}

/** aggregate fields of "customer_tag" */
export interface Customer_Tag_Aggregate_Fields {
  __typename?: 'customer_tag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Tag_Max_Fields>;
  min?: Maybe<Customer_Tag_Min_Fields>;
}


/** aggregate fields of "customer_tag" */
export interface Customer_Tag_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Customer_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "customer_tag". All fields are combined with a logical 'AND'. */
export interface Customer_Tag_Bool_Exp {
  _and?: Maybe<Array<Customer_Tag_Bool_Exp>>;
  _not?: Maybe<Customer_Tag_Bool_Exp>;
  _or?: Maybe<Array<Customer_Tag_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "customer_tag" */
export enum Customer_Tag_Constraint {
  /** unique or primary key constraint on columns "value" */
  CustomerTagPkey = 'customer_tag_pkey'
}

export enum Customer_Tag_Enum {
  CarDealer = 'CAR_DEALER',
  Cross = 'CROSS',
  Deceased = 'DECEASED',
  Duplicate = 'DUPLICATE',
  Insurance = 'INSURANCE',
  Leasing = 'LEASING',
  LostCustomer = 'LOST_CUSTOMER',
  Moved = 'MOVED',
  NoAds = 'NO_ADS',
  NoContact = 'NO_CONTACT',
  NoNewsletter = 'NO_NEWSLETTER',
  NoSelection = 'NO_SELECTION',
  NoService = 'NO_SERVICE',
  PotentialCustomer = 'POTENTIAL_CUSTOMER',
  SubDealer = 'SUB_DEALER'
}

/** Boolean expression to compare columns of type "customer_tag_enum". All fields are combined with logical 'AND'. */
export interface Customer_Tag_Enum_Comparison_Exp {
  _eq?: Maybe<Customer_Tag_Enum>;
  _in?: Maybe<Array<Customer_Tag_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Customer_Tag_Enum>;
  _nin?: Maybe<Array<Customer_Tag_Enum>>;
}

/** input type for inserting data into table "customer_tag" */
export interface Customer_Tag_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Customer_Tag_Max_Fields {
  __typename?: 'customer_tag_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Customer_Tag_Min_Fields {
  __typename?: 'customer_tag_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "customer_tag" */
export interface Customer_Tag_Mutation_Response {
  __typename?: 'customer_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Tag>;
}

/** on_conflict condition type for table "customer_tag" */
export interface Customer_Tag_On_Conflict {
  constraint: Customer_Tag_Constraint;
  update_columns: Array<Customer_Tag_Update_Column>;
  where?: Maybe<Customer_Tag_Bool_Exp>;
}

/** Ordering options when selecting data from "customer_tag". */
export interface Customer_Tag_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: customer_tag */
export interface Customer_Tag_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "customer_tag" */
export enum Customer_Tag_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "customer_tag" */
export interface Customer_Tag_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "customer_tag" */
export interface Customer_Tag_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Customer_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Customer_Tag_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "customer_tag" */
export enum Customer_Tag_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Customer_Tag_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customer_Tag_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Tag_Bool_Exp;
}

/** columns and relationships of "customer_type" */
export interface Customer_Type {
  __typename?: 'customer_type';
  value: Scalars['String'];
}

/** aggregated selection of "customer_type" */
export interface Customer_Type_Aggregate {
  __typename?: 'customer_type_aggregate';
  aggregate?: Maybe<Customer_Type_Aggregate_Fields>;
  nodes: Array<Customer_Type>;
}

/** aggregate fields of "customer_type" */
export interface Customer_Type_Aggregate_Fields {
  __typename?: 'customer_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Type_Max_Fields>;
  min?: Maybe<Customer_Type_Min_Fields>;
}


/** aggregate fields of "customer_type" */
export interface Customer_Type_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Customer_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "customer_type". All fields are combined with a logical 'AND'. */
export interface Customer_Type_Bool_Exp {
  _and?: Maybe<Array<Customer_Type_Bool_Exp>>;
  _not?: Maybe<Customer_Type_Bool_Exp>;
  _or?: Maybe<Array<Customer_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "customer_type" */
export enum Customer_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  CustomerTypePkey = 'customer_type_pkey'
}

export enum Customer_Type_Enum {
  Business = 'BUSINESS',
  Private = 'PRIVATE',
  Unknown = 'UNKNOWN'
}

/** Boolean expression to compare columns of type "customer_type_enum". All fields are combined with logical 'AND'. */
export interface Customer_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Customer_Type_Enum>;
  _in?: Maybe<Array<Customer_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Customer_Type_Enum>;
  _nin?: Maybe<Array<Customer_Type_Enum>>;
}

/** input type for inserting data into table "customer_type" */
export interface Customer_Type_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Customer_Type_Max_Fields {
  __typename?: 'customer_type_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Customer_Type_Min_Fields {
  __typename?: 'customer_type_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "customer_type" */
export interface Customer_Type_Mutation_Response {
  __typename?: 'customer_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Type>;
}

/** on_conflict condition type for table "customer_type" */
export interface Customer_Type_On_Conflict {
  constraint: Customer_Type_Constraint;
  update_columns: Array<Customer_Type_Update_Column>;
  where?: Maybe<Customer_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "customer_type". */
export interface Customer_Type_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: customer_type */
export interface Customer_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "customer_type" */
export enum Customer_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "customer_type" */
export interface Customer_Type_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "customer_type" */
export interface Customer_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Customer_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Customer_Type_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "customer_type" */
export enum Customer_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Customer_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customer_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Type_Bool_Exp;
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export interface Date_Comparison_Exp {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
}

/** columns and relationships of "dealer_vehicle_import_templates" */
export interface Dealer_Vehicle_Import_Templates {
  __typename?: 'dealer_vehicle_import_templates';
  id: Scalars['uuid'];
  name: Scalars['String'];
}

/** aggregated selection of "dealer_vehicle_import_templates" */
export interface Dealer_Vehicle_Import_Templates_Aggregate {
  __typename?: 'dealer_vehicle_import_templates_aggregate';
  aggregate?: Maybe<Dealer_Vehicle_Import_Templates_Aggregate_Fields>;
  nodes: Array<Dealer_Vehicle_Import_Templates>;
}

/** aggregate fields of "dealer_vehicle_import_templates" */
export interface Dealer_Vehicle_Import_Templates_Aggregate_Fields {
  __typename?: 'dealer_vehicle_import_templates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dealer_Vehicle_Import_Templates_Max_Fields>;
  min?: Maybe<Dealer_Vehicle_Import_Templates_Min_Fields>;
}


/** aggregate fields of "dealer_vehicle_import_templates" */
export interface Dealer_Vehicle_Import_Templates_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Dealer_Vehicle_Import_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "dealer_vehicle_import_templates". All fields are combined with a logical 'AND'. */
export interface Dealer_Vehicle_Import_Templates_Bool_Exp {
  _and?: Maybe<Array<Dealer_Vehicle_Import_Templates_Bool_Exp>>;
  _not?: Maybe<Dealer_Vehicle_Import_Templates_Bool_Exp>;
  _or?: Maybe<Array<Dealer_Vehicle_Import_Templates_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "dealer_vehicle_import_templates" */
export enum Dealer_Vehicle_Import_Templates_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealerVehicleImportTemplatesPkey = 'dealer_vehicle_import_templates_pkey'
}

/** input type for inserting data into table "dealer_vehicle_import_templates" */
export interface Dealer_Vehicle_Import_Templates_Insert_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Dealer_Vehicle_Import_Templates_Max_Fields {
  __typename?: 'dealer_vehicle_import_templates_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Dealer_Vehicle_Import_Templates_Min_Fields {
  __typename?: 'dealer_vehicle_import_templates_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "dealer_vehicle_import_templates" */
export interface Dealer_Vehicle_Import_Templates_Mutation_Response {
  __typename?: 'dealer_vehicle_import_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dealer_Vehicle_Import_Templates>;
}

/** on_conflict condition type for table "dealer_vehicle_import_templates" */
export interface Dealer_Vehicle_Import_Templates_On_Conflict {
  constraint: Dealer_Vehicle_Import_Templates_Constraint;
  update_columns: Array<Dealer_Vehicle_Import_Templates_Update_Column>;
  where?: Maybe<Dealer_Vehicle_Import_Templates_Bool_Exp>;
}

/** Ordering options when selecting data from "dealer_vehicle_import_templates". */
export interface Dealer_Vehicle_Import_Templates_Order_By {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** primary key columns input for table: dealer_vehicle_import_templates */
export interface Dealer_Vehicle_Import_Templates_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "dealer_vehicle_import_templates" */
export enum Dealer_Vehicle_Import_Templates_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "dealer_vehicle_import_templates" */
export interface Dealer_Vehicle_Import_Templates_Set_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "dealer_vehicle_import_templates" */
export interface Dealer_Vehicle_Import_Templates_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Dealer_Vehicle_Import_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Dealer_Vehicle_Import_Templates_Stream_Cursor_Value_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "dealer_vehicle_import_templates" */
export enum Dealer_Vehicle_Import_Templates_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Dealer_Vehicle_Import_Templates_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Dealer_Vehicle_Import_Templates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dealer_Vehicle_Import_Templates_Bool_Exp;
}

/** columns and relationships of "dealer_vehicle_inbox_import_templates" */
export interface Dealer_Vehicle_Inbox_Import_Templates {
  __typename?: 'dealer_vehicle_inbox_import_templates';
  id: Scalars['uuid'];
  name: Scalars['String'];
}

/** aggregated selection of "dealer_vehicle_inbox_import_templates" */
export interface Dealer_Vehicle_Inbox_Import_Templates_Aggregate {
  __typename?: 'dealer_vehicle_inbox_import_templates_aggregate';
  aggregate?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Aggregate_Fields>;
  nodes: Array<Dealer_Vehicle_Inbox_Import_Templates>;
}

/** aggregate fields of "dealer_vehicle_inbox_import_templates" */
export interface Dealer_Vehicle_Inbox_Import_Templates_Aggregate_Fields {
  __typename?: 'dealer_vehicle_inbox_import_templates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Max_Fields>;
  min?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Min_Fields>;
}


/** aggregate fields of "dealer_vehicle_inbox_import_templates" */
export interface Dealer_Vehicle_Inbox_Import_Templates_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "dealer_vehicle_inbox_import_templates". All fields are combined with a logical 'AND'. */
export interface Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp {
  _and?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp>>;
  _not?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp>;
  _or?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "dealer_vehicle_inbox_import_templates" */
export enum Dealer_Vehicle_Inbox_Import_Templates_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealerVehicleInboxImportTemplatesPkey = 'dealer_vehicle_inbox_import_templates_pkey'
}

/** input type for inserting data into table "dealer_vehicle_inbox_import_templates" */
export interface Dealer_Vehicle_Inbox_Import_Templates_Insert_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Dealer_Vehicle_Inbox_Import_Templates_Max_Fields {
  __typename?: 'dealer_vehicle_inbox_import_templates_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Dealer_Vehicle_Inbox_Import_Templates_Min_Fields {
  __typename?: 'dealer_vehicle_inbox_import_templates_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "dealer_vehicle_inbox_import_templates" */
export interface Dealer_Vehicle_Inbox_Import_Templates_Mutation_Response {
  __typename?: 'dealer_vehicle_inbox_import_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dealer_Vehicle_Inbox_Import_Templates>;
}

/** on_conflict condition type for table "dealer_vehicle_inbox_import_templates" */
export interface Dealer_Vehicle_Inbox_Import_Templates_On_Conflict {
  constraint: Dealer_Vehicle_Inbox_Import_Templates_Constraint;
  update_columns: Array<Dealer_Vehicle_Inbox_Import_Templates_Update_Column>;
  where?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp>;
}

/** Ordering options when selecting data from "dealer_vehicle_inbox_import_templates". */
export interface Dealer_Vehicle_Inbox_Import_Templates_Order_By {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** primary key columns input for table: dealer_vehicle_inbox_import_templates */
export interface Dealer_Vehicle_Inbox_Import_Templates_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "dealer_vehicle_inbox_import_templates" */
export enum Dealer_Vehicle_Inbox_Import_Templates_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "dealer_vehicle_inbox_import_templates" */
export interface Dealer_Vehicle_Inbox_Import_Templates_Set_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "dealer_vehicle_inbox_import_templates" */
export interface Dealer_Vehicle_Inbox_Import_Templates_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Dealer_Vehicle_Inbox_Import_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Dealer_Vehicle_Inbox_Import_Templates_Stream_Cursor_Value_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "dealer_vehicle_inbox_import_templates" */
export enum Dealer_Vehicle_Inbox_Import_Templates_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Dealer_Vehicle_Inbox_Import_Templates_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp;
}

/** columns and relationships of "dealer_vehicle_state" */
export interface Dealer_Vehicle_State {
  __typename?: 'dealer_vehicle_state';
  comment: Scalars['String'];
  value: Scalars['String'];
}

/** aggregated selection of "dealer_vehicle_state" */
export interface Dealer_Vehicle_State_Aggregate {
  __typename?: 'dealer_vehicle_state_aggregate';
  aggregate?: Maybe<Dealer_Vehicle_State_Aggregate_Fields>;
  nodes: Array<Dealer_Vehicle_State>;
}

/** aggregate fields of "dealer_vehicle_state" */
export interface Dealer_Vehicle_State_Aggregate_Fields {
  __typename?: 'dealer_vehicle_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dealer_Vehicle_State_Max_Fields>;
  min?: Maybe<Dealer_Vehicle_State_Min_Fields>;
}


/** aggregate fields of "dealer_vehicle_state" */
export interface Dealer_Vehicle_State_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Dealer_Vehicle_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "dealer_vehicle_state". All fields are combined with a logical 'AND'. */
export interface Dealer_Vehicle_State_Bool_Exp {
  _and?: Maybe<Array<Dealer_Vehicle_State_Bool_Exp>>;
  _not?: Maybe<Dealer_Vehicle_State_Bool_Exp>;
  _or?: Maybe<Array<Dealer_Vehicle_State_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "dealer_vehicle_state" */
export enum Dealer_Vehicle_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  DealerVehicleStatePkey = 'dealer_vehicle_state_pkey'
}

export enum Dealer_Vehicle_State_Enum {
  Available = 'AVAILABLE',
  OnSiteNeed = 'ON_SITE_NEED',
  Other = 'OTHER',
  Reserved = 'RESERVED',
  Sold = 'SOLD',
  SoldByBroker = 'SOLD_BY_BROKER',
  SoldByPartner = 'SOLD_BY_PARTNER',
  SoldToPartner = 'SOLD_TO_PARTNER'
}

/** Boolean expression to compare columns of type "dealer_vehicle_state_enum". All fields are combined with logical 'AND'. */
export interface Dealer_Vehicle_State_Enum_Comparison_Exp {
  _eq?: Maybe<Dealer_Vehicle_State_Enum>;
  _in?: Maybe<Array<Dealer_Vehicle_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Dealer_Vehicle_State_Enum>;
  _nin?: Maybe<Array<Dealer_Vehicle_State_Enum>>;
}

/** input type for inserting data into table "dealer_vehicle_state" */
export interface Dealer_Vehicle_State_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Dealer_Vehicle_State_Max_Fields {
  __typename?: 'dealer_vehicle_state_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Dealer_Vehicle_State_Min_Fields {
  __typename?: 'dealer_vehicle_state_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "dealer_vehicle_state" */
export interface Dealer_Vehicle_State_Mutation_Response {
  __typename?: 'dealer_vehicle_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dealer_Vehicle_State>;
}

/** on_conflict condition type for table "dealer_vehicle_state" */
export interface Dealer_Vehicle_State_On_Conflict {
  constraint: Dealer_Vehicle_State_Constraint;
  update_columns: Array<Dealer_Vehicle_State_Update_Column>;
  where?: Maybe<Dealer_Vehicle_State_Bool_Exp>;
}

/** Ordering options when selecting data from "dealer_vehicle_state". */
export interface Dealer_Vehicle_State_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: dealer_vehicle_state */
export interface Dealer_Vehicle_State_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "dealer_vehicle_state" */
export enum Dealer_Vehicle_State_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "dealer_vehicle_state" */
export interface Dealer_Vehicle_State_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "dealer_vehicle_state" */
export interface Dealer_Vehicle_State_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Dealer_Vehicle_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Dealer_Vehicle_State_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "dealer_vehicle_state" */
export enum Dealer_Vehicle_State_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Dealer_Vehicle_State_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Dealer_Vehicle_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dealer_Vehicle_State_Bool_Exp;
}

/** columns and relationships of "dealer_vehicle_type" */
export interface Dealer_Vehicle_Type {
  __typename?: 'dealer_vehicle_type';
  comment: Scalars['String'];
  value: Scalars['String'];
}

/** aggregated selection of "dealer_vehicle_type" */
export interface Dealer_Vehicle_Type_Aggregate {
  __typename?: 'dealer_vehicle_type_aggregate';
  aggregate?: Maybe<Dealer_Vehicle_Type_Aggregate_Fields>;
  nodes: Array<Dealer_Vehicle_Type>;
}

/** aggregate fields of "dealer_vehicle_type" */
export interface Dealer_Vehicle_Type_Aggregate_Fields {
  __typename?: 'dealer_vehicle_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dealer_Vehicle_Type_Max_Fields>;
  min?: Maybe<Dealer_Vehicle_Type_Min_Fields>;
}


/** aggregate fields of "dealer_vehicle_type" */
export interface Dealer_Vehicle_Type_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Dealer_Vehicle_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "dealer_vehicle_type". All fields are combined with a logical 'AND'. */
export interface Dealer_Vehicle_Type_Bool_Exp {
  _and?: Maybe<Array<Dealer_Vehicle_Type_Bool_Exp>>;
  _not?: Maybe<Dealer_Vehicle_Type_Bool_Exp>;
  _or?: Maybe<Array<Dealer_Vehicle_Type_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "dealer_vehicle_type" */
export enum Dealer_Vehicle_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  DealerVehicleTypePkey = 'dealer_vehicle_type_pkey'
}

export enum Dealer_Vehicle_Type_Enum {
  DemonstrationCar = 'DEMONSTRATION_CAR',
  NewCar = 'NEW_CAR',
  Unknown = 'UNKNOWN',
  UsedCar = 'USED_CAR'
}

/** Boolean expression to compare columns of type "dealer_vehicle_type_enum". All fields are combined with logical 'AND'. */
export interface Dealer_Vehicle_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Dealer_Vehicle_Type_Enum>;
  _in?: Maybe<Array<Dealer_Vehicle_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Dealer_Vehicle_Type_Enum>;
  _nin?: Maybe<Array<Dealer_Vehicle_Type_Enum>>;
}

/** input type for inserting data into table "dealer_vehicle_type" */
export interface Dealer_Vehicle_Type_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Dealer_Vehicle_Type_Max_Fields {
  __typename?: 'dealer_vehicle_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Dealer_Vehicle_Type_Min_Fields {
  __typename?: 'dealer_vehicle_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "dealer_vehicle_type" */
export interface Dealer_Vehicle_Type_Mutation_Response {
  __typename?: 'dealer_vehicle_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dealer_Vehicle_Type>;
}

/** on_conflict condition type for table "dealer_vehicle_type" */
export interface Dealer_Vehicle_Type_On_Conflict {
  constraint: Dealer_Vehicle_Type_Constraint;
  update_columns: Array<Dealer_Vehicle_Type_Update_Column>;
  where?: Maybe<Dealer_Vehicle_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "dealer_vehicle_type". */
export interface Dealer_Vehicle_Type_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: dealer_vehicle_type */
export interface Dealer_Vehicle_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "dealer_vehicle_type" */
export enum Dealer_Vehicle_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "dealer_vehicle_type" */
export interface Dealer_Vehicle_Type_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "dealer_vehicle_type" */
export interface Dealer_Vehicle_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Dealer_Vehicle_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Dealer_Vehicle_Type_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "dealer_vehicle_type" */
export enum Dealer_Vehicle_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Dealer_Vehicle_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Dealer_Vehicle_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dealer_Vehicle_Type_Bool_Exp;
}

/** columns and relationships of "department_role" */
export interface Department_Role {
  __typename?: 'department_role';
  department_id: Scalars['uuid'];
  role: Role_Enum;
}

/** aggregated selection of "department_role" */
export interface Department_Role_Aggregate {
  __typename?: 'department_role_aggregate';
  aggregate?: Maybe<Department_Role_Aggregate_Fields>;
  nodes: Array<Department_Role>;
}

export interface Department_Role_Aggregate_Bool_Exp {
  count?: Maybe<Department_Role_Aggregate_Bool_Exp_Count>;
}

export interface Department_Role_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Department_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Department_Role_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "department_role" */
export interface Department_Role_Aggregate_Fields {
  __typename?: 'department_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Department_Role_Max_Fields>;
  min?: Maybe<Department_Role_Min_Fields>;
}


/** aggregate fields of "department_role" */
export interface Department_Role_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Department_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "department_role" */
export interface Department_Role_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Department_Role_Max_Order_By>;
  min?: Maybe<Department_Role_Min_Order_By>;
}

/** input type for inserting array relation for remote table "department_role" */
export interface Department_Role_Arr_Rel_Insert_Input {
  data: Array<Department_Role_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Department_Role_On_Conflict>;
}

/** Boolean expression to filter rows from the table "department_role". All fields are combined with a logical 'AND'. */
export interface Department_Role_Bool_Exp {
  _and?: Maybe<Array<Department_Role_Bool_Exp>>;
  _not?: Maybe<Department_Role_Bool_Exp>;
  _or?: Maybe<Array<Department_Role_Bool_Exp>>;
  department_id?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<Role_Enum_Comparison_Exp>;
}

/** unique or primary key constraints on table "department_role" */
export enum Department_Role_Constraint {
  /** unique or primary key constraint on columns "department_id", "role" */
  DepartmentRolePkey = 'department_role_pkey'
}

/** input type for inserting data into table "department_role" */
export interface Department_Role_Insert_Input {
  department_id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Role_Enum>;
}

/** aggregate max on columns */
export interface Department_Role_Max_Fields {
  __typename?: 'department_role_max_fields';
  department_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "department_role" */
export interface Department_Role_Max_Order_By {
  department_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Department_Role_Min_Fields {
  __typename?: 'department_role_min_fields';
  department_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "department_role" */
export interface Department_Role_Min_Order_By {
  department_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "department_role" */
export interface Department_Role_Mutation_Response {
  __typename?: 'department_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Department_Role>;
}

/** on_conflict condition type for table "department_role" */
export interface Department_Role_On_Conflict {
  constraint: Department_Role_Constraint;
  update_columns: Array<Department_Role_Update_Column>;
  where?: Maybe<Department_Role_Bool_Exp>;
}

/** Ordering options when selecting data from "department_role". */
export interface Department_Role_Order_By {
  department_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
}

/** primary key columns input for table: department_role */
export interface Department_Role_Pk_Columns_Input {
  department_id: Scalars['uuid'];
  role: Role_Enum;
}

/** select columns of table "department_role" */
export enum Department_Role_Select_Column {
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "department_role" */
export interface Department_Role_Set_Input {
  department_id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Role_Enum>;
}

/** Streaming cursor of the table "department_role" */
export interface Department_Role_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Department_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Department_Role_Stream_Cursor_Value_Input {
  department_id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Role_Enum>;
}

/** update columns of table "department_role" */
export enum Department_Role_Update_Column {
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Role = 'role'
}

export interface Department_Role_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Department_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Department_Role_Bool_Exp;
}

/** columns and relationships of "dms_type" */
export interface Dms_Type {
  __typename?: 'dms_type';
  comment: Scalars['String'];
  value: Scalars['String'];
}

/** aggregated selection of "dms_type" */
export interface Dms_Type_Aggregate {
  __typename?: 'dms_type_aggregate';
  aggregate?: Maybe<Dms_Type_Aggregate_Fields>;
  nodes: Array<Dms_Type>;
}

/** aggregate fields of "dms_type" */
export interface Dms_Type_Aggregate_Fields {
  __typename?: 'dms_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dms_Type_Max_Fields>;
  min?: Maybe<Dms_Type_Min_Fields>;
}


/** aggregate fields of "dms_type" */
export interface Dms_Type_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Dms_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "dms_type". All fields are combined with a logical 'AND'. */
export interface Dms_Type_Bool_Exp {
  _and?: Maybe<Array<Dms_Type_Bool_Exp>>;
  _not?: Maybe<Dms_Type_Bool_Exp>;
  _or?: Maybe<Array<Dms_Type_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "dms_type" */
export enum Dms_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  DmsTypePkey = 'dms_type_pkey'
}

export enum Dms_Type_Enum {
  Locosoft = 'LOCOSOFT'
}

/** Boolean expression to compare columns of type "dms_type_enum". All fields are combined with logical 'AND'. */
export interface Dms_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Dms_Type_Enum>;
  _in?: Maybe<Array<Dms_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Dms_Type_Enum>;
  _nin?: Maybe<Array<Dms_Type_Enum>>;
}

/** input type for inserting data into table "dms_type" */
export interface Dms_Type_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Dms_Type_Max_Fields {
  __typename?: 'dms_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Dms_Type_Min_Fields {
  __typename?: 'dms_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "dms_type" */
export interface Dms_Type_Mutation_Response {
  __typename?: 'dms_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Type>;
}

/** on_conflict condition type for table "dms_type" */
export interface Dms_Type_On_Conflict {
  constraint: Dms_Type_Constraint;
  update_columns: Array<Dms_Type_Update_Column>;
  where?: Maybe<Dms_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "dms_type". */
export interface Dms_Type_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: dms_type */
export interface Dms_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "dms_type" */
export enum Dms_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "dms_type" */
export interface Dms_Type_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "dms_type" */
export interface Dms_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Dms_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Dms_Type_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "dms_type" */
export enum Dms_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Dms_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Dms_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Type_Bool_Exp;
}

/** Table contains embedded page URLs */
export interface Embedded_Pages {
  __typename?: 'embedded_pages';
  /** An object relationship */
  brand?: Maybe<Brand>;
  brand_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
}

/** aggregated selection of "embedded_pages" */
export interface Embedded_Pages_Aggregate {
  __typename?: 'embedded_pages_aggregate';
  aggregate?: Maybe<Embedded_Pages_Aggregate_Fields>;
  nodes: Array<Embedded_Pages>;
}

/** aggregate fields of "embedded_pages" */
export interface Embedded_Pages_Aggregate_Fields {
  __typename?: 'embedded_pages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Embedded_Pages_Max_Fields>;
  min?: Maybe<Embedded_Pages_Min_Fields>;
}


/** aggregate fields of "embedded_pages" */
export interface Embedded_Pages_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Embedded_Pages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "embedded_pages". All fields are combined with a logical 'AND'. */
export interface Embedded_Pages_Bool_Exp {
  _and?: Maybe<Array<Embedded_Pages_Bool_Exp>>;
  _not?: Maybe<Embedded_Pages_Bool_Exp>;
  _or?: Maybe<Array<Embedded_Pages_Bool_Exp>>;
  brand?: Maybe<Brand_Bool_Exp>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "embedded_pages" */
export enum Embedded_Pages_Constraint {
  /** unique or primary key constraint on columns "brand_id", "name" */
  EmbeddedPagesNameBrandIdKey = 'embedded_pages_name_brand_id_key',
  /** unique or primary key constraint on columns "id" */
  EmbeddedPagesPkey = 'embedded_pages_pkey'
}

/** input type for inserting data into table "embedded_pages" */
export interface Embedded_Pages_Insert_Input {
  brand?: Maybe<Brand_Obj_Rel_Insert_Input>;
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Embedded_Pages_Max_Fields {
  __typename?: 'embedded_pages_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Embedded_Pages_Min_Fields {
  __typename?: 'embedded_pages_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "embedded_pages" */
export interface Embedded_Pages_Mutation_Response {
  __typename?: 'embedded_pages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Embedded_Pages>;
}

/** on_conflict condition type for table "embedded_pages" */
export interface Embedded_Pages_On_Conflict {
  constraint: Embedded_Pages_Constraint;
  update_columns: Array<Embedded_Pages_Update_Column>;
  where?: Maybe<Embedded_Pages_Bool_Exp>;
}

/** Ordering options when selecting data from "embedded_pages". */
export interface Embedded_Pages_Order_By {
  brand?: Maybe<Brand_Order_By>;
  brand_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
}

/** primary key columns input for table: embedded_pages */
export interface Embedded_Pages_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "embedded_pages" */
export enum Embedded_Pages_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "embedded_pages" */
export interface Embedded_Pages_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "embedded_pages" */
export interface Embedded_Pages_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Embedded_Pages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Embedded_Pages_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
}

/** update columns of table "embedded_pages" */
export enum Embedded_Pages_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export interface Embedded_Pages_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Embedded_Pages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Embedded_Pages_Bool_Exp;
}

/** columns and relationships of "excel_import_assignments" */
export interface Excel_Import_Assignments {
  __typename?: 'excel_import_assignments';
  dealer_vehicle_import_template_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_import_template_id?: Maybe<Scalars['uuid']>;
  header_cell: Scalars['String'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "excel_import_assignments" */
export interface Excel_Import_Assignments_Aggregate {
  __typename?: 'excel_import_assignments_aggregate';
  aggregate?: Maybe<Excel_Import_Assignments_Aggregate_Fields>;
  nodes: Array<Excel_Import_Assignments>;
}

export interface Excel_Import_Assignments_Aggregate_Bool_Exp {
  count?: Maybe<Excel_Import_Assignments_Aggregate_Bool_Exp_Count>;
}

export interface Excel_Import_Assignments_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Excel_Import_Assignments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Excel_Import_Assignments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "excel_import_assignments" */
export interface Excel_Import_Assignments_Aggregate_Fields {
  __typename?: 'excel_import_assignments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Excel_Import_Assignments_Max_Fields>;
  min?: Maybe<Excel_Import_Assignments_Min_Fields>;
}


/** aggregate fields of "excel_import_assignments" */
export interface Excel_Import_Assignments_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Excel_Import_Assignments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "excel_import_assignments" */
export interface Excel_Import_Assignments_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Excel_Import_Assignments_Max_Order_By>;
  min?: Maybe<Excel_Import_Assignments_Min_Order_By>;
}

/** input type for inserting array relation for remote table "excel_import_assignments" */
export interface Excel_Import_Assignments_Arr_Rel_Insert_Input {
  data: Array<Excel_Import_Assignments_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Excel_Import_Assignments_On_Conflict>;
}

/** Boolean expression to filter rows from the table "excel_import_assignments". All fields are combined with a logical 'AND'. */
export interface Excel_Import_Assignments_Bool_Exp {
  _and?: Maybe<Array<Excel_Import_Assignments_Bool_Exp>>;
  _not?: Maybe<Excel_Import_Assignments_Bool_Exp>;
  _or?: Maybe<Array<Excel_Import_Assignments_Bool_Exp>>;
  dealer_vehicle_import_template_id?: Maybe<Uuid_Comparison_Exp>;
  dealer_vehicle_inbox_import_template_id?: Maybe<Uuid_Comparison_Exp>;
  header_cell?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  vehicle_import_template_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "excel_import_assignments" */
export enum Excel_Import_Assignments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExcelImportAssignmentsPkey = 'excel_import_assignments_pkey'
}

/** input type for inserting data into table "excel_import_assignments" */
export interface Excel_Import_Assignments_Insert_Input {
  dealer_vehicle_import_template_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_import_template_id?: Maybe<Scalars['uuid']>;
  header_cell?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Excel_Import_Assignments_Max_Fields {
  __typename?: 'excel_import_assignments_max_fields';
  dealer_vehicle_import_template_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_import_template_id?: Maybe<Scalars['uuid']>;
  header_cell?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "excel_import_assignments" */
export interface Excel_Import_Assignments_Max_Order_By {
  dealer_vehicle_import_template_id?: Maybe<Order_By>;
  dealer_vehicle_inbox_import_template_id?: Maybe<Order_By>;
  header_cell?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  vehicle_import_template_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Excel_Import_Assignments_Min_Fields {
  __typename?: 'excel_import_assignments_min_fields';
  dealer_vehicle_import_template_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_import_template_id?: Maybe<Scalars['uuid']>;
  header_cell?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "excel_import_assignments" */
export interface Excel_Import_Assignments_Min_Order_By {
  dealer_vehicle_import_template_id?: Maybe<Order_By>;
  dealer_vehicle_inbox_import_template_id?: Maybe<Order_By>;
  header_cell?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  vehicle_import_template_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "excel_import_assignments" */
export interface Excel_Import_Assignments_Mutation_Response {
  __typename?: 'excel_import_assignments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Excel_Import_Assignments>;
}

/** on_conflict condition type for table "excel_import_assignments" */
export interface Excel_Import_Assignments_On_Conflict {
  constraint: Excel_Import_Assignments_Constraint;
  update_columns: Array<Excel_Import_Assignments_Update_Column>;
  where?: Maybe<Excel_Import_Assignments_Bool_Exp>;
}

/** Ordering options when selecting data from "excel_import_assignments". */
export interface Excel_Import_Assignments_Order_By {
  dealer_vehicle_import_template_id?: Maybe<Order_By>;
  dealer_vehicle_inbox_import_template_id?: Maybe<Order_By>;
  header_cell?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  vehicle_import_template_id?: Maybe<Order_By>;
}

/** primary key columns input for table: excel_import_assignments */
export interface Excel_Import_Assignments_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "excel_import_assignments" */
export enum Excel_Import_Assignments_Select_Column {
  /** column name */
  DealerVehicleImportTemplateId = 'dealer_vehicle_import_template_id',
  /** column name */
  DealerVehicleInboxImportTemplateId = 'dealer_vehicle_inbox_import_template_id',
  /** column name */
  HeaderCell = 'header_cell',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  VehicleImportTemplateId = 'vehicle_import_template_id'
}

/** input type for updating data in table "excel_import_assignments" */
export interface Excel_Import_Assignments_Set_Input {
  dealer_vehicle_import_template_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_import_template_id?: Maybe<Scalars['uuid']>;
  header_cell?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "excel_import_assignments" */
export interface Excel_Import_Assignments_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Excel_Import_Assignments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Excel_Import_Assignments_Stream_Cursor_Value_Input {
  dealer_vehicle_import_template_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_import_template_id?: Maybe<Scalars['uuid']>;
  header_cell?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "excel_import_assignments" */
export enum Excel_Import_Assignments_Update_Column {
  /** column name */
  DealerVehicleImportTemplateId = 'dealer_vehicle_import_template_id',
  /** column name */
  DealerVehicleInboxImportTemplateId = 'dealer_vehicle_inbox_import_template_id',
  /** column name */
  HeaderCell = 'header_cell',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  VehicleImportTemplateId = 'vehicle_import_template_id'
}

export interface Excel_Import_Assignments_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Excel_Import_Assignments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Excel_Import_Assignments_Bool_Exp;
}

/** columns and relationships of "financing_type" */
export interface Financing_Type {
  __typename?: 'financing_type';
  value: Scalars['String'];
}

/** aggregated selection of "financing_type" */
export interface Financing_Type_Aggregate {
  __typename?: 'financing_type_aggregate';
  aggregate?: Maybe<Financing_Type_Aggregate_Fields>;
  nodes: Array<Financing_Type>;
}

/** aggregate fields of "financing_type" */
export interface Financing_Type_Aggregate_Fields {
  __typename?: 'financing_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Financing_Type_Max_Fields>;
  min?: Maybe<Financing_Type_Min_Fields>;
}


/** aggregate fields of "financing_type" */
export interface Financing_Type_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Financing_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "financing_type". All fields are combined with a logical 'AND'. */
export interface Financing_Type_Bool_Exp {
  _and?: Maybe<Array<Financing_Type_Bool_Exp>>;
  _not?: Maybe<Financing_Type_Bool_Exp>;
  _or?: Maybe<Array<Financing_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "financing_type" */
export enum Financing_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  FinancingTypePkey = 'financing_type_pkey'
}

export enum Financing_Type_Enum {
  Credit = 'CREDIT',
  Leasing = 'LEASING',
  Unknown = 'UNKNOWN'
}

/** Boolean expression to compare columns of type "financing_type_enum". All fields are combined with logical 'AND'. */
export interface Financing_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Financing_Type_Enum>;
  _in?: Maybe<Array<Financing_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Financing_Type_Enum>;
  _nin?: Maybe<Array<Financing_Type_Enum>>;
}

/** input type for inserting data into table "financing_type" */
export interface Financing_Type_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Financing_Type_Max_Fields {
  __typename?: 'financing_type_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Financing_Type_Min_Fields {
  __typename?: 'financing_type_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "financing_type" */
export interface Financing_Type_Mutation_Response {
  __typename?: 'financing_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Financing_Type>;
}

/** on_conflict condition type for table "financing_type" */
export interface Financing_Type_On_Conflict {
  constraint: Financing_Type_Constraint;
  update_columns: Array<Financing_Type_Update_Column>;
  where?: Maybe<Financing_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "financing_type". */
export interface Financing_Type_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: financing_type */
export interface Financing_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "financing_type" */
export enum Financing_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "financing_type" */
export interface Financing_Type_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "financing_type" */
export interface Financing_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Financing_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Financing_Type_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "financing_type" */
export enum Financing_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Financing_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Financing_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Financing_Type_Bool_Exp;
}

export interface House_Brands_Args {
  loc_id?: Maybe<Scalars['uuid']>;
}

/** columns and relationships of "import_job_executable_status" */
export interface Import_Job_Executable_Status {
  __typename?: 'import_job_executable_status';
  value: Scalars['String'];
}

/** aggregated selection of "import_job_executable_status" */
export interface Import_Job_Executable_Status_Aggregate {
  __typename?: 'import_job_executable_status_aggregate';
  aggregate?: Maybe<Import_Job_Executable_Status_Aggregate_Fields>;
  nodes: Array<Import_Job_Executable_Status>;
}

/** aggregate fields of "import_job_executable_status" */
export interface Import_Job_Executable_Status_Aggregate_Fields {
  __typename?: 'import_job_executable_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Import_Job_Executable_Status_Max_Fields>;
  min?: Maybe<Import_Job_Executable_Status_Min_Fields>;
}


/** aggregate fields of "import_job_executable_status" */
export interface Import_Job_Executable_Status_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Import_Job_Executable_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "import_job_executable_status". All fields are combined with a logical 'AND'. */
export interface Import_Job_Executable_Status_Bool_Exp {
  _and?: Maybe<Array<Import_Job_Executable_Status_Bool_Exp>>;
  _not?: Maybe<Import_Job_Executable_Status_Bool_Exp>;
  _or?: Maybe<Array<Import_Job_Executable_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "import_job_executable_status" */
export enum Import_Job_Executable_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ImportJobExecutableStatusPkey = 'import_job_executable_status_pkey'
}

export enum Import_Job_Executable_Status_Enum {
  ImportFailed = 'ImportFailed',
  ImportSucceeded = 'ImportSucceeded',
  PreviewFailed = 'PreviewFailed',
  PreviewSucceeded = 'PreviewSucceeded'
}

/** Boolean expression to compare columns of type "import_job_executable_status_enum". All fields are combined with logical 'AND'. */
export interface Import_Job_Executable_Status_Enum_Comparison_Exp {
  _eq?: Maybe<Import_Job_Executable_Status_Enum>;
  _in?: Maybe<Array<Import_Job_Executable_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Import_Job_Executable_Status_Enum>;
  _nin?: Maybe<Array<Import_Job_Executable_Status_Enum>>;
}

/** input type for inserting data into table "import_job_executable_status" */
export interface Import_Job_Executable_Status_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Import_Job_Executable_Status_Max_Fields {
  __typename?: 'import_job_executable_status_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Import_Job_Executable_Status_Min_Fields {
  __typename?: 'import_job_executable_status_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "import_job_executable_status" */
export interface Import_Job_Executable_Status_Mutation_Response {
  __typename?: 'import_job_executable_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Import_Job_Executable_Status>;
}

/** on_conflict condition type for table "import_job_executable_status" */
export interface Import_Job_Executable_Status_On_Conflict {
  constraint: Import_Job_Executable_Status_Constraint;
  update_columns: Array<Import_Job_Executable_Status_Update_Column>;
  where?: Maybe<Import_Job_Executable_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "import_job_executable_status". */
export interface Import_Job_Executable_Status_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: import_job_executable_status */
export interface Import_Job_Executable_Status_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "import_job_executable_status" */
export enum Import_Job_Executable_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "import_job_executable_status" */
export interface Import_Job_Executable_Status_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "import_job_executable_status" */
export interface Import_Job_Executable_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Import_Job_Executable_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Import_Job_Executable_Status_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "import_job_executable_status" */
export enum Import_Job_Executable_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Import_Job_Executable_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Import_Job_Executable_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Import_Job_Executable_Status_Bool_Exp;
}

/** columns and relationships of "import_job_status" */
export interface Import_Job_Status {
  __typename?: 'import_job_status';
  value: Scalars['String'];
}

/** aggregated selection of "import_job_status" */
export interface Import_Job_Status_Aggregate {
  __typename?: 'import_job_status_aggregate';
  aggregate?: Maybe<Import_Job_Status_Aggregate_Fields>;
  nodes: Array<Import_Job_Status>;
}

/** aggregate fields of "import_job_status" */
export interface Import_Job_Status_Aggregate_Fields {
  __typename?: 'import_job_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Import_Job_Status_Max_Fields>;
  min?: Maybe<Import_Job_Status_Min_Fields>;
}


/** aggregate fields of "import_job_status" */
export interface Import_Job_Status_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Import_Job_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "import_job_status". All fields are combined with a logical 'AND'. */
export interface Import_Job_Status_Bool_Exp {
  _and?: Maybe<Array<Import_Job_Status_Bool_Exp>>;
  _not?: Maybe<Import_Job_Status_Bool_Exp>;
  _or?: Maybe<Array<Import_Job_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "import_job_status" */
export enum Import_Job_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ImportJobStatusPkey = 'import_job_status_pkey'
}

export enum Import_Job_Status_Enum {
  Done = 'Done',
  ExecutionInProgress = 'ExecutionInProgress',
  InProgress = 'InProgress',
  Initialized = 'Initialized',
  PreviewDone = 'PreviewDone',
  PreviewInProgress = 'PreviewInProgress'
}

/** Boolean expression to compare columns of type "import_job_status_enum". All fields are combined with logical 'AND'. */
export interface Import_Job_Status_Enum_Comparison_Exp {
  _eq?: Maybe<Import_Job_Status_Enum>;
  _in?: Maybe<Array<Import_Job_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Import_Job_Status_Enum>;
  _nin?: Maybe<Array<Import_Job_Status_Enum>>;
}

/** input type for inserting data into table "import_job_status" */
export interface Import_Job_Status_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Import_Job_Status_Max_Fields {
  __typename?: 'import_job_status_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Import_Job_Status_Min_Fields {
  __typename?: 'import_job_status_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "import_job_status" */
export interface Import_Job_Status_Mutation_Response {
  __typename?: 'import_job_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Import_Job_Status>;
}

/** on_conflict condition type for table "import_job_status" */
export interface Import_Job_Status_On_Conflict {
  constraint: Import_Job_Status_Constraint;
  update_columns: Array<Import_Job_Status_Update_Column>;
  where?: Maybe<Import_Job_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "import_job_status". */
export interface Import_Job_Status_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: import_job_status */
export interface Import_Job_Status_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "import_job_status" */
export enum Import_Job_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "import_job_status" */
export interface Import_Job_Status_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "import_job_status" */
export interface Import_Job_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Import_Job_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Import_Job_Status_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "import_job_status" */
export enum Import_Job_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Import_Job_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Import_Job_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Import_Job_Status_Bool_Exp;
}

/** columns and relationships of "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables {
  __typename?: 'import_job_vehicle_executables';
  created_at: Scalars['timestamptz'];
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  executable_status: Import_Job_Executable_Status_Enum;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  import_error?: Maybe<Scalars['String']>;
  import_job_id: Scalars['uuid'];
  in_buy_contract_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  row_number: Scalars['Int'];
  sold_at?: Maybe<Scalars['timestamptz']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at: Scalars['timestamptz'];
  validation_error?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
}

/** aggregated selection of "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Aggregate {
  __typename?: 'import_job_vehicle_executables_aggregate';
  aggregate?: Maybe<Import_Job_Vehicle_Executables_Aggregate_Fields>;
  nodes: Array<Import_Job_Vehicle_Executables>;
}

export interface Import_Job_Vehicle_Executables_Aggregate_Bool_Exp {
  count?: Maybe<Import_Job_Vehicle_Executables_Aggregate_Bool_Exp_Count>;
}

export interface Import_Job_Vehicle_Executables_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Import_Job_Vehicle_Executables_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Aggregate_Fields {
  __typename?: 'import_job_vehicle_executables_aggregate_fields';
  avg?: Maybe<Import_Job_Vehicle_Executables_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Import_Job_Vehicle_Executables_Max_Fields>;
  min?: Maybe<Import_Job_Vehicle_Executables_Min_Fields>;
  stddev?: Maybe<Import_Job_Vehicle_Executables_Stddev_Fields>;
  stddev_pop?: Maybe<Import_Job_Vehicle_Executables_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Import_Job_Vehicle_Executables_Stddev_Samp_Fields>;
  sum?: Maybe<Import_Job_Vehicle_Executables_Sum_Fields>;
  var_pop?: Maybe<Import_Job_Vehicle_Executables_Var_Pop_Fields>;
  var_samp?: Maybe<Import_Job_Vehicle_Executables_Var_Samp_Fields>;
  variance?: Maybe<Import_Job_Vehicle_Executables_Variance_Fields>;
}


/** aggregate fields of "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Import_Job_Vehicle_Executables_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Aggregate_Order_By {
  avg?: Maybe<Import_Job_Vehicle_Executables_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Import_Job_Vehicle_Executables_Max_Order_By>;
  min?: Maybe<Import_Job_Vehicle_Executables_Min_Order_By>;
  stddev?: Maybe<Import_Job_Vehicle_Executables_Stddev_Order_By>;
  stddev_pop?: Maybe<Import_Job_Vehicle_Executables_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Import_Job_Vehicle_Executables_Stddev_Samp_Order_By>;
  sum?: Maybe<Import_Job_Vehicle_Executables_Sum_Order_By>;
  var_pop?: Maybe<Import_Job_Vehicle_Executables_Var_Pop_Order_By>;
  var_samp?: Maybe<Import_Job_Vehicle_Executables_Var_Samp_Order_By>;
  variance?: Maybe<Import_Job_Vehicle_Executables_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Arr_Rel_Insert_Input {
  data: Array<Import_Job_Vehicle_Executables_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Import_Job_Vehicle_Executables_On_Conflict>;
}

/** aggregate avg on columns */
export interface Import_Job_Vehicle_Executables_Avg_Fields {
  __typename?: 'import_job_vehicle_executables_avg_fields';
  min_holding_period?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Avg_Order_By {
  min_holding_period?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "import_job_vehicle_executables". All fields are combined with a logical 'AND'. */
export interface Import_Job_Vehicle_Executables_Bool_Exp {
  _and?: Maybe<Array<Import_Job_Vehicle_Executables_Bool_Exp>>;
  _not?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
  _or?: Maybe<Array<Import_Job_Vehicle_Executables_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer?: Maybe<String_Comparison_Exp>;
  delivery_date_str?: Maybe<String_Comparison_Exp>;
  executable_status?: Maybe<Import_Job_Executable_Status_Enum_Comparison_Exp>;
  handover_date?: Maybe<Date_Comparison_Exp>;
  handover_planned_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  import_error?: Maybe<String_Comparison_Exp>;
  import_job_id?: Maybe<Uuid_Comparison_Exp>;
  in_buy_contract_date?: Maybe<Date_Comparison_Exp>;
  job_number?: Maybe<String_Comparison_Exp>;
  key_kabinet_number?: Maybe<String_Comparison_Exp>;
  min_holding_period?: Maybe<Int_Comparison_Exp>;
  order_state?: Maybe<Order_State_Enum_Comparison_Exp>;
  registration_owner?: Maybe<String_Comparison_Exp>;
  registration_state?: Maybe<Registration_State_Enum_Comparison_Exp>;
  row_number?: Maybe<Int_Comparison_Exp>;
  sold_at?: Maybe<Timestamptz_Comparison_Exp>;
  spec_level?: Maybe<String_Comparison_Exp>;
  specs?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Dealer_Vehicle_State_Enum_Comparison_Exp>;
  type?: Maybe<Dealer_Vehicle_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  validation_error?: Maybe<String_Comparison_Exp>;
  vin?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "import_job_vehicle_executables" */
export enum Import_Job_Vehicle_Executables_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImportJobVehicleExecutablesPkey = 'import_job_vehicle_executables_pkey'
}

/** input type for incrementing numeric columns in table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Inc_Input {
  min_holding_period?: Maybe<Scalars['Int']>;
  row_number?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Insert_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  executable_status?: Maybe<Import_Job_Executable_Status_Enum>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  import_error?: Maybe<Scalars['String']>;
  import_job_id?: Maybe<Scalars['uuid']>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  row_number?: Maybe<Scalars['Int']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  validation_error?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Import_Job_Vehicle_Executables_Max_Fields {
  __typename?: 'import_job_vehicle_executables_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  import_error?: Maybe<Scalars['String']>;
  import_job_id?: Maybe<Scalars['uuid']>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  registration_owner?: Maybe<Scalars['String']>;
  row_number?: Maybe<Scalars['Int']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  validation_error?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Max_Order_By {
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  delivery_date_str?: Maybe<Order_By>;
  handover_date?: Maybe<Order_By>;
  handover_planned_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  import_error?: Maybe<Order_By>;
  import_job_id?: Maybe<Order_By>;
  in_buy_contract_date?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  key_kabinet_number?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  registration_owner?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  sold_at?: Maybe<Order_By>;
  spec_level?: Maybe<Order_By>;
  specs?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  validation_error?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Import_Job_Vehicle_Executables_Min_Fields {
  __typename?: 'import_job_vehicle_executables_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  import_error?: Maybe<Scalars['String']>;
  import_job_id?: Maybe<Scalars['uuid']>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  registration_owner?: Maybe<Scalars['String']>;
  row_number?: Maybe<Scalars['Int']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  validation_error?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Min_Order_By {
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  delivery_date_str?: Maybe<Order_By>;
  handover_date?: Maybe<Order_By>;
  handover_planned_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  import_error?: Maybe<Order_By>;
  import_job_id?: Maybe<Order_By>;
  in_buy_contract_date?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  key_kabinet_number?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  registration_owner?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  sold_at?: Maybe<Order_By>;
  spec_level?: Maybe<Order_By>;
  specs?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  validation_error?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
}

/** response of any mutation on the table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Mutation_Response {
  __typename?: 'import_job_vehicle_executables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Import_Job_Vehicle_Executables>;
}

/** on_conflict condition type for table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_On_Conflict {
  constraint: Import_Job_Vehicle_Executables_Constraint;
  update_columns: Array<Import_Job_Vehicle_Executables_Update_Column>;
  where?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
}

/** Ordering options when selecting data from "import_job_vehicle_executables". */
export interface Import_Job_Vehicle_Executables_Order_By {
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  delivery_date_str?: Maybe<Order_By>;
  executable_status?: Maybe<Order_By>;
  handover_date?: Maybe<Order_By>;
  handover_planned_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  import_error?: Maybe<Order_By>;
  import_job_id?: Maybe<Order_By>;
  in_buy_contract_date?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  key_kabinet_number?: Maybe<Order_By>;
  min_holding_period?: Maybe<Order_By>;
  order_state?: Maybe<Order_By>;
  registration_owner?: Maybe<Order_By>;
  registration_state?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  sold_at?: Maybe<Order_By>;
  spec_level?: Maybe<Order_By>;
  specs?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  validation_error?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
}

/** primary key columns input for table: import_job_vehicle_executables */
export interface Import_Job_Vehicle_Executables_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "import_job_vehicle_executables" */
export enum Import_Job_Vehicle_Executables_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customer = 'customer',
  /** column name */
  DeliveryDateStr = 'delivery_date_str',
  /** column name */
  ExecutableStatus = 'executable_status',
  /** column name */
  HandoverDate = 'handover_date',
  /** column name */
  HandoverPlannedDate = 'handover_planned_date',
  /** column name */
  Id = 'id',
  /** column name */
  ImportError = 'import_error',
  /** column name */
  ImportJobId = 'import_job_id',
  /** column name */
  InBuyContractDate = 'in_buy_contract_date',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  KeyKabinetNumber = 'key_kabinet_number',
  /** column name */
  MinHoldingPeriod = 'min_holding_period',
  /** column name */
  OrderState = 'order_state',
  /** column name */
  RegistrationOwner = 'registration_owner',
  /** column name */
  RegistrationState = 'registration_state',
  /** column name */
  RowNumber = 'row_number',
  /** column name */
  SoldAt = 'sold_at',
  /** column name */
  SpecLevel = 'spec_level',
  /** column name */
  Specs = 'specs',
  /** column name */
  State = 'state',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidationError = 'validation_error',
  /** column name */
  Vin = 'vin'
}

/** input type for updating data in table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Set_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  executable_status?: Maybe<Import_Job_Executable_Status_Enum>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  import_error?: Maybe<Scalars['String']>;
  import_job_id?: Maybe<Scalars['uuid']>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  row_number?: Maybe<Scalars['Int']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  validation_error?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface Import_Job_Vehicle_Executables_Stddev_Fields {
  __typename?: 'import_job_vehicle_executables_stddev_fields';
  min_holding_period?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Stddev_Order_By {
  min_holding_period?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Import_Job_Vehicle_Executables_Stddev_Pop_Fields {
  __typename?: 'import_job_vehicle_executables_stddev_pop_fields';
  min_holding_period?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Stddev_Pop_Order_By {
  min_holding_period?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Import_Job_Vehicle_Executables_Stddev_Samp_Fields {
  __typename?: 'import_job_vehicle_executables_stddev_samp_fields';
  min_holding_period?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Stddev_Samp_Order_By {
  min_holding_period?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
}

/** Streaming cursor of the table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Import_Job_Vehicle_Executables_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Import_Job_Vehicle_Executables_Stream_Cursor_Value_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<Scalars['String']>;
  delivery_date_str?: Maybe<Scalars['String']>;
  executable_status?: Maybe<Import_Job_Executable_Status_Enum>;
  handover_date?: Maybe<Scalars['date']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  import_error?: Maybe<Scalars['String']>;
  import_job_id?: Maybe<Scalars['uuid']>;
  in_buy_contract_date?: Maybe<Scalars['date']>;
  job_number?: Maybe<Scalars['String']>;
  key_kabinet_number?: Maybe<Scalars['String']>;
  min_holding_period?: Maybe<Scalars['Int']>;
  order_state?: Maybe<Order_State_Enum>;
  registration_owner?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Registration_State_Enum>;
  row_number?: Maybe<Scalars['Int']>;
  sold_at?: Maybe<Scalars['timestamptz']>;
  spec_level?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  state?: Maybe<Dealer_Vehicle_State_Enum>;
  type?: Maybe<Dealer_Vehicle_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  validation_error?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Import_Job_Vehicle_Executables_Sum_Fields {
  __typename?: 'import_job_vehicle_executables_sum_fields';
  min_holding_period?: Maybe<Scalars['Int']>;
  row_number?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Sum_Order_By {
  min_holding_period?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
}

/** update columns of table "import_job_vehicle_executables" */
export enum Import_Job_Vehicle_Executables_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customer = 'customer',
  /** column name */
  DeliveryDateStr = 'delivery_date_str',
  /** column name */
  ExecutableStatus = 'executable_status',
  /** column name */
  HandoverDate = 'handover_date',
  /** column name */
  HandoverPlannedDate = 'handover_planned_date',
  /** column name */
  Id = 'id',
  /** column name */
  ImportError = 'import_error',
  /** column name */
  ImportJobId = 'import_job_id',
  /** column name */
  InBuyContractDate = 'in_buy_contract_date',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  KeyKabinetNumber = 'key_kabinet_number',
  /** column name */
  MinHoldingPeriod = 'min_holding_period',
  /** column name */
  OrderState = 'order_state',
  /** column name */
  RegistrationOwner = 'registration_owner',
  /** column name */
  RegistrationState = 'registration_state',
  /** column name */
  RowNumber = 'row_number',
  /** column name */
  SoldAt = 'sold_at',
  /** column name */
  SpecLevel = 'spec_level',
  /** column name */
  Specs = 'specs',
  /** column name */
  State = 'state',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidationError = 'validation_error',
  /** column name */
  Vin = 'vin'
}

export interface Import_Job_Vehicle_Executables_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Import_Job_Vehicle_Executables_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Import_Job_Vehicle_Executables_Set_Input>;
  /** filter the rows which have to be updated */
  where: Import_Job_Vehicle_Executables_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Import_Job_Vehicle_Executables_Var_Pop_Fields {
  __typename?: 'import_job_vehicle_executables_var_pop_fields';
  min_holding_period?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Var_Pop_Order_By {
  min_holding_period?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Import_Job_Vehicle_Executables_Var_Samp_Fields {
  __typename?: 'import_job_vehicle_executables_var_samp_fields';
  min_holding_period?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Var_Samp_Order_By {
  min_holding_period?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Import_Job_Vehicle_Executables_Variance_Fields {
  __typename?: 'import_job_vehicle_executables_variance_fields';
  min_holding_period?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "import_job_vehicle_executables" */
export interface Import_Job_Vehicle_Executables_Variance_Order_By {
  min_holding_period?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
}

/** columns and relationships of "import_jobs" */
export interface Import_Jobs {
  __typename?: 'import_jobs';
  created_at: Scalars['timestamptz'];
  file_key?: Maybe<Scalars['String']>;
  filter: Scalars['String'];
  id: Scalars['uuid'];
  matching_field: Scalars['String'];
  status: Import_Job_Status_Enum;
  updated_at: Scalars['timestamptz'];
  upload_file_excel_definition?: Maybe<Array<Scalars['String']>>;
  upload_file_name: Scalars['String'];
  upload_file_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  vehicle_executables: Array<Import_Job_Vehicle_Executables>;
  /** An aggregate relationship */
  vehicle_executables_aggregate: Import_Job_Vehicle_Executables_Aggregate;
  /** An object relationship */
  vehicle_import_template?: Maybe<Vehicle_Import_Templates>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}


/** columns and relationships of "import_jobs" */
export interface Import_JobsVehicle_ExecutablesArgs {
  distinct_on?: Maybe<Array<Import_Job_Vehicle_Executables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Vehicle_Executables_Order_By>>;
  where?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
}


/** columns and relationships of "import_jobs" */
export interface Import_JobsVehicle_Executables_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Job_Vehicle_Executables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Vehicle_Executables_Order_By>>;
  where?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
}

/** aggregated selection of "import_jobs" */
export interface Import_Jobs_Aggregate {
  __typename?: 'import_jobs_aggregate';
  aggregate?: Maybe<Import_Jobs_Aggregate_Fields>;
  nodes: Array<Import_Jobs>;
}

/** aggregate fields of "import_jobs" */
export interface Import_Jobs_Aggregate_Fields {
  __typename?: 'import_jobs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Import_Jobs_Max_Fields>;
  min?: Maybe<Import_Jobs_Min_Fields>;
}


/** aggregate fields of "import_jobs" */
export interface Import_Jobs_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Import_Jobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "import_jobs". All fields are combined with a logical 'AND'. */
export interface Import_Jobs_Bool_Exp {
  _and?: Maybe<Array<Import_Jobs_Bool_Exp>>;
  _not?: Maybe<Import_Jobs_Bool_Exp>;
  _or?: Maybe<Array<Import_Jobs_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_key?: Maybe<String_Comparison_Exp>;
  filter?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  matching_field?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Import_Job_Status_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  upload_file_excel_definition?: Maybe<String_Array_Comparison_Exp>;
  upload_file_name?: Maybe<String_Comparison_Exp>;
  upload_file_url?: Maybe<String_Comparison_Exp>;
  vehicle_executables?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
  vehicle_executables_aggregate?: Maybe<Import_Job_Vehicle_Executables_Aggregate_Bool_Exp>;
  vehicle_import_template?: Maybe<Vehicle_Import_Templates_Bool_Exp>;
  vehicle_import_template_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "import_jobs" */
export enum Import_Jobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImportJobsPkey = 'import_jobs_pkey'
}

/** input type for inserting data into table "import_jobs" */
export interface Import_Jobs_Insert_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_key?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  matching_field?: Maybe<Scalars['String']>;
  status?: Maybe<Import_Job_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_file_excel_definition?: Maybe<Array<Scalars['String']>>;
  upload_file_name?: Maybe<Scalars['String']>;
  upload_file_url?: Maybe<Scalars['String']>;
  vehicle_executables?: Maybe<Import_Job_Vehicle_Executables_Arr_Rel_Insert_Input>;
  vehicle_import_template?: Maybe<Vehicle_Import_Templates_Obj_Rel_Insert_Input>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Import_Jobs_Max_Fields {
  __typename?: 'import_jobs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_key?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  matching_field?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_file_excel_definition?: Maybe<Array<Scalars['String']>>;
  upload_file_name?: Maybe<Scalars['String']>;
  upload_file_url?: Maybe<Scalars['String']>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface Import_Jobs_Min_Fields {
  __typename?: 'import_jobs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_key?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  matching_field?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_file_excel_definition?: Maybe<Array<Scalars['String']>>;
  upload_file_name?: Maybe<Scalars['String']>;
  upload_file_url?: Maybe<Scalars['String']>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "import_jobs" */
export interface Import_Jobs_Mutation_Response {
  __typename?: 'import_jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Import_Jobs>;
}

/** on_conflict condition type for table "import_jobs" */
export interface Import_Jobs_On_Conflict {
  constraint: Import_Jobs_Constraint;
  update_columns: Array<Import_Jobs_Update_Column>;
  where?: Maybe<Import_Jobs_Bool_Exp>;
}

/** Ordering options when selecting data from "import_jobs". */
export interface Import_Jobs_Order_By {
  created_at?: Maybe<Order_By>;
  file_key?: Maybe<Order_By>;
  filter?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  matching_field?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  upload_file_excel_definition?: Maybe<Order_By>;
  upload_file_name?: Maybe<Order_By>;
  upload_file_url?: Maybe<Order_By>;
  vehicle_executables_aggregate?: Maybe<Import_Job_Vehicle_Executables_Aggregate_Order_By>;
  vehicle_import_template?: Maybe<Vehicle_Import_Templates_Order_By>;
  vehicle_import_template_id?: Maybe<Order_By>;
}

/** primary key columns input for table: import_jobs */
export interface Import_Jobs_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "import_jobs" */
export enum Import_Jobs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileKey = 'file_key',
  /** column name */
  Filter = 'filter',
  /** column name */
  Id = 'id',
  /** column name */
  MatchingField = 'matching_field',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadFileExcelDefinition = 'upload_file_excel_definition',
  /** column name */
  UploadFileName = 'upload_file_name',
  /** column name */
  UploadFileUrl = 'upload_file_url',
  /** column name */
  VehicleImportTemplateId = 'vehicle_import_template_id'
}

/** input type for updating data in table "import_jobs" */
export interface Import_Jobs_Set_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_key?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  matching_field?: Maybe<Scalars['String']>;
  status?: Maybe<Import_Job_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_file_excel_definition?: Maybe<Array<Scalars['String']>>;
  upload_file_name?: Maybe<Scalars['String']>;
  upload_file_url?: Maybe<Scalars['String']>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "import_jobs" */
export interface Import_Jobs_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Import_Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Import_Jobs_Stream_Cursor_Value_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_key?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  matching_field?: Maybe<Scalars['String']>;
  status?: Maybe<Import_Job_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_file_excel_definition?: Maybe<Array<Scalars['String']>>;
  upload_file_name?: Maybe<Scalars['String']>;
  upload_file_url?: Maybe<Scalars['String']>;
  vehicle_import_template_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "import_jobs" */
export enum Import_Jobs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileKey = 'file_key',
  /** column name */
  Filter = 'filter',
  /** column name */
  Id = 'id',
  /** column name */
  MatchingField = 'matching_field',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadFileExcelDefinition = 'upload_file_excel_definition',
  /** column name */
  UploadFileName = 'upload_file_name',
  /** column name */
  UploadFileUrl = 'upload_file_url',
  /** column name */
  VehicleImportTemplateId = 'vehicle_import_template_id'
}

export interface Import_Jobs_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Import_Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Import_Jobs_Bool_Exp;
}

/** columns and relationships of "import_type" */
export interface Import_Type {
  __typename?: 'import_type';
  value: Scalars['String'];
}

/** aggregated selection of "import_type" */
export interface Import_Type_Aggregate {
  __typename?: 'import_type_aggregate';
  aggregate?: Maybe<Import_Type_Aggregate_Fields>;
  nodes: Array<Import_Type>;
}

/** aggregate fields of "import_type" */
export interface Import_Type_Aggregate_Fields {
  __typename?: 'import_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Import_Type_Max_Fields>;
  min?: Maybe<Import_Type_Min_Fields>;
}


/** aggregate fields of "import_type" */
export interface Import_Type_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Import_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "import_type". All fields are combined with a logical 'AND'. */
export interface Import_Type_Bool_Exp {
  _and?: Maybe<Array<Import_Type_Bool_Exp>>;
  _not?: Maybe<Import_Type_Bool_Exp>;
  _or?: Maybe<Array<Import_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "import_type" */
export enum Import_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ImportTypePkey = 'import_type_pkey'
}

export enum Import_Type_Enum {
  Full = 'FULL',
  Partial = 'PARTIAL'
}

/** Boolean expression to compare columns of type "import_type_enum". All fields are combined with logical 'AND'. */
export interface Import_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Import_Type_Enum>;
  _in?: Maybe<Array<Import_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Import_Type_Enum>;
  _nin?: Maybe<Array<Import_Type_Enum>>;
}

/** input type for inserting data into table "import_type" */
export interface Import_Type_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Import_Type_Max_Fields {
  __typename?: 'import_type_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Import_Type_Min_Fields {
  __typename?: 'import_type_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "import_type" */
export interface Import_Type_Mutation_Response {
  __typename?: 'import_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Import_Type>;
}

/** on_conflict condition type for table "import_type" */
export interface Import_Type_On_Conflict {
  constraint: Import_Type_Constraint;
  update_columns: Array<Import_Type_Update_Column>;
  where?: Maybe<Import_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "import_type". */
export interface Import_Type_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: import_type */
export interface Import_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "import_type" */
export enum Import_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "import_type" */
export interface Import_Type_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "import_type" */
export interface Import_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Import_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Import_Type_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "import_type" */
export enum Import_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Import_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Import_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Import_Type_Bool_Exp;
}

/** columns and relationships of "in_buy_channel" */
export interface In_Buy_Channel {
  __typename?: 'in_buy_channel';
  value: Scalars['String'];
}

/** aggregated selection of "in_buy_channel" */
export interface In_Buy_Channel_Aggregate {
  __typename?: 'in_buy_channel_aggregate';
  aggregate?: Maybe<In_Buy_Channel_Aggregate_Fields>;
  nodes: Array<In_Buy_Channel>;
}

/** aggregate fields of "in_buy_channel" */
export interface In_Buy_Channel_Aggregate_Fields {
  __typename?: 'in_buy_channel_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<In_Buy_Channel_Max_Fields>;
  min?: Maybe<In_Buy_Channel_Min_Fields>;
}


/** aggregate fields of "in_buy_channel" */
export interface In_Buy_Channel_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<In_Buy_Channel_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "in_buy_channel". All fields are combined with a logical 'AND'. */
export interface In_Buy_Channel_Bool_Exp {
  _and?: Maybe<Array<In_Buy_Channel_Bool_Exp>>;
  _not?: Maybe<In_Buy_Channel_Bool_Exp>;
  _or?: Maybe<Array<In_Buy_Channel_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "in_buy_channel" */
export enum In_Buy_Channel_Constraint {
  /** unique or primary key constraint on columns "value" */
  InBuyChannelPkey = 'in_buy_channel_pkey'
}

export enum In_Buy_Channel_Enum {
  Buyback = 'BUYBACK',
  Commission = 'COMMISSION',
  Exchange = 'EXCHANGE',
  FreePurchase = 'FREE_PURCHASE',
  Import = 'IMPORT',
  UnknownUsedCarBuyIn = 'UNKNOWN_USED_CAR_BUY_IN'
}

/** Boolean expression to compare columns of type "in_buy_channel_enum". All fields are combined with logical 'AND'. */
export interface In_Buy_Channel_Enum_Comparison_Exp {
  _eq?: Maybe<In_Buy_Channel_Enum>;
  _in?: Maybe<Array<In_Buy_Channel_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<In_Buy_Channel_Enum>;
  _nin?: Maybe<Array<In_Buy_Channel_Enum>>;
}

/** input type for inserting data into table "in_buy_channel" */
export interface In_Buy_Channel_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface In_Buy_Channel_Max_Fields {
  __typename?: 'in_buy_channel_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface In_Buy_Channel_Min_Fields {
  __typename?: 'in_buy_channel_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "in_buy_channel" */
export interface In_Buy_Channel_Mutation_Response {
  __typename?: 'in_buy_channel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<In_Buy_Channel>;
}

/** on_conflict condition type for table "in_buy_channel" */
export interface In_Buy_Channel_On_Conflict {
  constraint: In_Buy_Channel_Constraint;
  update_columns: Array<In_Buy_Channel_Update_Column>;
  where?: Maybe<In_Buy_Channel_Bool_Exp>;
}

/** Ordering options when selecting data from "in_buy_channel". */
export interface In_Buy_Channel_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: in_buy_channel */
export interface In_Buy_Channel_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "in_buy_channel" */
export enum In_Buy_Channel_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "in_buy_channel" */
export interface In_Buy_Channel_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "in_buy_channel" */
export interface In_Buy_Channel_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: In_Buy_Channel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface In_Buy_Channel_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "in_buy_channel" */
export enum In_Buy_Channel_Update_Column {
  /** column name */
  Value = 'value'
}

export interface In_Buy_Channel_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<In_Buy_Channel_Set_Input>;
  /** filter the rows which have to be updated */
  where: In_Buy_Channel_Bool_Exp;
}


/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export interface Json_Comparison_Exp {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
}


export interface Jsonb_Cast_Exp {
  String?: Maybe<String_Comparison_Exp>;
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface Jsonb_Comparison_Exp {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
}

/** columns and relationships of "location_group_brand" */
export interface Location_Group_Brand {
  __typename?: 'location_group_brand';
  brand_id: Scalars['uuid'];
  location_group_id: Scalars['uuid'];
}

/** aggregated selection of "location_group_brand" */
export interface Location_Group_Brand_Aggregate {
  __typename?: 'location_group_brand_aggregate';
  aggregate?: Maybe<Location_Group_Brand_Aggregate_Fields>;
  nodes: Array<Location_Group_Brand>;
}

export interface Location_Group_Brand_Aggregate_Bool_Exp {
  count?: Maybe<Location_Group_Brand_Aggregate_Bool_Exp_Count>;
}

export interface Location_Group_Brand_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Location_Group_Brand_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Location_Group_Brand_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "location_group_brand" */
export interface Location_Group_Brand_Aggregate_Fields {
  __typename?: 'location_group_brand_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Location_Group_Brand_Max_Fields>;
  min?: Maybe<Location_Group_Brand_Min_Fields>;
}


/** aggregate fields of "location_group_brand" */
export interface Location_Group_Brand_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Location_Group_Brand_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "location_group_brand" */
export interface Location_Group_Brand_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Location_Group_Brand_Max_Order_By>;
  min?: Maybe<Location_Group_Brand_Min_Order_By>;
}

/** input type for inserting array relation for remote table "location_group_brand" */
export interface Location_Group_Brand_Arr_Rel_Insert_Input {
  data: Array<Location_Group_Brand_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Location_Group_Brand_On_Conflict>;
}

/** Boolean expression to filter rows from the table "location_group_brand". All fields are combined with a logical 'AND'. */
export interface Location_Group_Brand_Bool_Exp {
  _and?: Maybe<Array<Location_Group_Brand_Bool_Exp>>;
  _not?: Maybe<Location_Group_Brand_Bool_Exp>;
  _or?: Maybe<Array<Location_Group_Brand_Bool_Exp>>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  location_group_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "location_group_brand" */
export enum Location_Group_Brand_Constraint {
  /** unique or primary key constraint on columns "location_group_id", "brand_id" */
  LocationGroupBrandPkey = 'location_group_brand_pkey'
}

/** input type for inserting data into table "location_group_brand" */
export interface Location_Group_Brand_Insert_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  location_group_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Location_Group_Brand_Max_Fields {
  __typename?: 'location_group_brand_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  location_group_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "location_group_brand" */
export interface Location_Group_Brand_Max_Order_By {
  brand_id?: Maybe<Order_By>;
  location_group_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Location_Group_Brand_Min_Fields {
  __typename?: 'location_group_brand_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  location_group_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "location_group_brand" */
export interface Location_Group_Brand_Min_Order_By {
  brand_id?: Maybe<Order_By>;
  location_group_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "location_group_brand" */
export interface Location_Group_Brand_Mutation_Response {
  __typename?: 'location_group_brand_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Location_Group_Brand>;
}

/** on_conflict condition type for table "location_group_brand" */
export interface Location_Group_Brand_On_Conflict {
  constraint: Location_Group_Brand_Constraint;
  update_columns: Array<Location_Group_Brand_Update_Column>;
  where?: Maybe<Location_Group_Brand_Bool_Exp>;
}

/** Ordering options when selecting data from "location_group_brand". */
export interface Location_Group_Brand_Order_By {
  brand_id?: Maybe<Order_By>;
  location_group_id?: Maybe<Order_By>;
}

/** primary key columns input for table: location_group_brand */
export interface Location_Group_Brand_Pk_Columns_Input {
  brand_id: Scalars['uuid'];
  location_group_id: Scalars['uuid'];
}

/** select columns of table "location_group_brand" */
export enum Location_Group_Brand_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  LocationGroupId = 'location_group_id'
}

/** input type for updating data in table "location_group_brand" */
export interface Location_Group_Brand_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  location_group_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "location_group_brand" */
export interface Location_Group_Brand_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Location_Group_Brand_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Location_Group_Brand_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  location_group_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "location_group_brand" */
export enum Location_Group_Brand_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  LocationGroupId = 'location_group_id'
}

export interface Location_Group_Brand_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Location_Group_Brand_Set_Input>;
  /** filter the rows which have to be updated */
  where: Location_Group_Brand_Bool_Exp;
}

/** columns and relationships of "location_group_brands_view" */
export interface Location_Group_Brands_View {
  __typename?: 'location_group_brands_view';
  id?: Maybe<Scalars['uuid']>;
  location_group_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregated selection of "location_group_brands_view" */
export interface Location_Group_Brands_View_Aggregate {
  __typename?: 'location_group_brands_view_aggregate';
  aggregate?: Maybe<Location_Group_Brands_View_Aggregate_Fields>;
  nodes: Array<Location_Group_Brands_View>;
}

export interface Location_Group_Brands_View_Aggregate_Bool_Exp {
  count?: Maybe<Location_Group_Brands_View_Aggregate_Bool_Exp_Count>;
}

export interface Location_Group_Brands_View_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Location_Group_Brands_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Location_Group_Brands_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "location_group_brands_view" */
export interface Location_Group_Brands_View_Aggregate_Fields {
  __typename?: 'location_group_brands_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Location_Group_Brands_View_Max_Fields>;
  min?: Maybe<Location_Group_Brands_View_Min_Fields>;
}


/** aggregate fields of "location_group_brands_view" */
export interface Location_Group_Brands_View_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Location_Group_Brands_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "location_group_brands_view" */
export interface Location_Group_Brands_View_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Location_Group_Brands_View_Max_Order_By>;
  min?: Maybe<Location_Group_Brands_View_Min_Order_By>;
}

/** input type for inserting array relation for remote table "location_group_brands_view" */
export interface Location_Group_Brands_View_Arr_Rel_Insert_Input {
  data: Array<Location_Group_Brands_View_Insert_Input>;
}

/** Boolean expression to filter rows from the table "location_group_brands_view". All fields are combined with a logical 'AND'. */
export interface Location_Group_Brands_View_Bool_Exp {
  _and?: Maybe<Array<Location_Group_Brands_View_Bool_Exp>>;
  _not?: Maybe<Location_Group_Brands_View_Bool_Exp>;
  _or?: Maybe<Array<Location_Group_Brands_View_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location_group_id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
}

/** input type for inserting data into table "location_group_brands_view" */
export interface Location_Group_Brands_View_Insert_Input {
  id?: Maybe<Scalars['uuid']>;
  location_group_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Location_Group_Brands_View_Max_Fields {
  __typename?: 'location_group_brands_view_max_fields';
  id?: Maybe<Scalars['uuid']>;
  location_group_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "location_group_brands_view" */
export interface Location_Group_Brands_View_Max_Order_By {
  id?: Maybe<Order_By>;
  location_group_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Location_Group_Brands_View_Min_Fields {
  __typename?: 'location_group_brands_view_min_fields';
  id?: Maybe<Scalars['uuid']>;
  location_group_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "location_group_brands_view" */
export interface Location_Group_Brands_View_Min_Order_By {
  id?: Maybe<Order_By>;
  location_group_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** Ordering options when selecting data from "location_group_brands_view". */
export interface Location_Group_Brands_View_Order_By {
  id?: Maybe<Order_By>;
  location_group_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** select columns of table "location_group_brands_view" */
export enum Location_Group_Brands_View_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationGroupId = 'location_group_id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "location_group_brands_view" */
export interface Location_Group_Brands_View_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Location_Group_Brands_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Location_Group_Brands_View_Stream_Cursor_Value_Input {
  id?: Maybe<Scalars['uuid']>;
  location_group_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** columns and relationships of "model_group_release_years" */
export interface Model_Group_Release_Years {
  __typename?: 'model_group_release_years';
  model_group_id: Scalars['uuid'];
  release_year: Scalars['Int'];
}

/** aggregated selection of "model_group_release_years" */
export interface Model_Group_Release_Years_Aggregate {
  __typename?: 'model_group_release_years_aggregate';
  aggregate?: Maybe<Model_Group_Release_Years_Aggregate_Fields>;
  nodes: Array<Model_Group_Release_Years>;
}

export interface Model_Group_Release_Years_Aggregate_Bool_Exp {
  count?: Maybe<Model_Group_Release_Years_Aggregate_Bool_Exp_Count>;
}

export interface Model_Group_Release_Years_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Model_Group_Release_Years_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Model_Group_Release_Years_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "model_group_release_years" */
export interface Model_Group_Release_Years_Aggregate_Fields {
  __typename?: 'model_group_release_years_aggregate_fields';
  avg?: Maybe<Model_Group_Release_Years_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Model_Group_Release_Years_Max_Fields>;
  min?: Maybe<Model_Group_Release_Years_Min_Fields>;
  stddev?: Maybe<Model_Group_Release_Years_Stddev_Fields>;
  stddev_pop?: Maybe<Model_Group_Release_Years_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Model_Group_Release_Years_Stddev_Samp_Fields>;
  sum?: Maybe<Model_Group_Release_Years_Sum_Fields>;
  var_pop?: Maybe<Model_Group_Release_Years_Var_Pop_Fields>;
  var_samp?: Maybe<Model_Group_Release_Years_Var_Samp_Fields>;
  variance?: Maybe<Model_Group_Release_Years_Variance_Fields>;
}


/** aggregate fields of "model_group_release_years" */
export interface Model_Group_Release_Years_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Model_Group_Release_Years_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "model_group_release_years" */
export interface Model_Group_Release_Years_Aggregate_Order_By {
  avg?: Maybe<Model_Group_Release_Years_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Model_Group_Release_Years_Max_Order_By>;
  min?: Maybe<Model_Group_Release_Years_Min_Order_By>;
  stddev?: Maybe<Model_Group_Release_Years_Stddev_Order_By>;
  stddev_pop?: Maybe<Model_Group_Release_Years_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Model_Group_Release_Years_Stddev_Samp_Order_By>;
  sum?: Maybe<Model_Group_Release_Years_Sum_Order_By>;
  var_pop?: Maybe<Model_Group_Release_Years_Var_Pop_Order_By>;
  var_samp?: Maybe<Model_Group_Release_Years_Var_Samp_Order_By>;
  variance?: Maybe<Model_Group_Release_Years_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "model_group_release_years" */
export interface Model_Group_Release_Years_Arr_Rel_Insert_Input {
  data: Array<Model_Group_Release_Years_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Model_Group_Release_Years_On_Conflict>;
}

/** aggregate avg on columns */
export interface Model_Group_Release_Years_Avg_Fields {
  __typename?: 'model_group_release_years_avg_fields';
  release_year?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "model_group_release_years" */
export interface Model_Group_Release_Years_Avg_Order_By {
  release_year?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "model_group_release_years". All fields are combined with a logical 'AND'. */
export interface Model_Group_Release_Years_Bool_Exp {
  _and?: Maybe<Array<Model_Group_Release_Years_Bool_Exp>>;
  _not?: Maybe<Model_Group_Release_Years_Bool_Exp>;
  _or?: Maybe<Array<Model_Group_Release_Years_Bool_Exp>>;
  model_group_id?: Maybe<Uuid_Comparison_Exp>;
  release_year?: Maybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "model_group_release_years" */
export enum Model_Group_Release_Years_Constraint {
  /** unique or primary key constraint on columns "model_group_id", "release_year" */
  ModelGroupReleaseYearsPkey = 'model_group_release_years_pkey'
}

/** input type for incrementing numeric columns in table "model_group_release_years" */
export interface Model_Group_Release_Years_Inc_Input {
  release_year?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "model_group_release_years" */
export interface Model_Group_Release_Years_Insert_Input {
  model_group_id?: Maybe<Scalars['uuid']>;
  release_year?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface Model_Group_Release_Years_Max_Fields {
  __typename?: 'model_group_release_years_max_fields';
  model_group_id?: Maybe<Scalars['uuid']>;
  release_year?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "model_group_release_years" */
export interface Model_Group_Release_Years_Max_Order_By {
  model_group_id?: Maybe<Order_By>;
  release_year?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Model_Group_Release_Years_Min_Fields {
  __typename?: 'model_group_release_years_min_fields';
  model_group_id?: Maybe<Scalars['uuid']>;
  release_year?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "model_group_release_years" */
export interface Model_Group_Release_Years_Min_Order_By {
  model_group_id?: Maybe<Order_By>;
  release_year?: Maybe<Order_By>;
}

/** response of any mutation on the table "model_group_release_years" */
export interface Model_Group_Release_Years_Mutation_Response {
  __typename?: 'model_group_release_years_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Model_Group_Release_Years>;
}

/** on_conflict condition type for table "model_group_release_years" */
export interface Model_Group_Release_Years_On_Conflict {
  constraint: Model_Group_Release_Years_Constraint;
  update_columns: Array<Model_Group_Release_Years_Update_Column>;
  where?: Maybe<Model_Group_Release_Years_Bool_Exp>;
}

/** Ordering options when selecting data from "model_group_release_years". */
export interface Model_Group_Release_Years_Order_By {
  model_group_id?: Maybe<Order_By>;
  release_year?: Maybe<Order_By>;
}

/** primary key columns input for table: model_group_release_years */
export interface Model_Group_Release_Years_Pk_Columns_Input {
  model_group_id: Scalars['uuid'];
  release_year: Scalars['Int'];
}

/** select columns of table "model_group_release_years" */
export enum Model_Group_Release_Years_Select_Column {
  /** column name */
  ModelGroupId = 'model_group_id',
  /** column name */
  ReleaseYear = 'release_year'
}

/** input type for updating data in table "model_group_release_years" */
export interface Model_Group_Release_Years_Set_Input {
  model_group_id?: Maybe<Scalars['uuid']>;
  release_year?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface Model_Group_Release_Years_Stddev_Fields {
  __typename?: 'model_group_release_years_stddev_fields';
  release_year?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "model_group_release_years" */
export interface Model_Group_Release_Years_Stddev_Order_By {
  release_year?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Model_Group_Release_Years_Stddev_Pop_Fields {
  __typename?: 'model_group_release_years_stddev_pop_fields';
  release_year?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "model_group_release_years" */
export interface Model_Group_Release_Years_Stddev_Pop_Order_By {
  release_year?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Model_Group_Release_Years_Stddev_Samp_Fields {
  __typename?: 'model_group_release_years_stddev_samp_fields';
  release_year?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "model_group_release_years" */
export interface Model_Group_Release_Years_Stddev_Samp_Order_By {
  release_year?: Maybe<Order_By>;
}

/** Streaming cursor of the table "model_group_release_years" */
export interface Model_Group_Release_Years_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Model_Group_Release_Years_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Model_Group_Release_Years_Stream_Cursor_Value_Input {
  model_group_id?: Maybe<Scalars['uuid']>;
  release_year?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface Model_Group_Release_Years_Sum_Fields {
  __typename?: 'model_group_release_years_sum_fields';
  release_year?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "model_group_release_years" */
export interface Model_Group_Release_Years_Sum_Order_By {
  release_year?: Maybe<Order_By>;
}

/** update columns of table "model_group_release_years" */
export enum Model_Group_Release_Years_Update_Column {
  /** column name */
  ModelGroupId = 'model_group_id',
  /** column name */
  ReleaseYear = 'release_year'
}

export interface Model_Group_Release_Years_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Model_Group_Release_Years_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Model_Group_Release_Years_Set_Input>;
  /** filter the rows which have to be updated */
  where: Model_Group_Release_Years_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Model_Group_Release_Years_Var_Pop_Fields {
  __typename?: 'model_group_release_years_var_pop_fields';
  release_year?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "model_group_release_years" */
export interface Model_Group_Release_Years_Var_Pop_Order_By {
  release_year?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Model_Group_Release_Years_Var_Samp_Fields {
  __typename?: 'model_group_release_years_var_samp_fields';
  release_year?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "model_group_release_years" */
export interface Model_Group_Release_Years_Var_Samp_Order_By {
  release_year?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Model_Group_Release_Years_Variance_Fields {
  __typename?: 'model_group_release_years_variance_fields';
  release_year?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "model_group_release_years" */
export interface Model_Group_Release_Years_Variance_Order_By {
  release_year?: Maybe<Order_By>;
}

/** mutation root */
export interface Mutation_Root {
  __typename?: 'mutation_root';
  checkImportJobTemplateCompatibility?: Maybe<CheckImportJobTemplateCompatibilityPayload>;
  createTicketFromTemplate: CreateTicketFromTemplatePayload;
  createUser: CreateUserPayload;
  /** delete single row from the table: "addresses" */
  delete_address?: Maybe<Address>;
  /** delete data from the table: "addresses" */
  delete_addresses?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "after_sales_potentials" */
  delete_after_sales_potential?: Maybe<AfterSalesPotential>;
  /** delete data from the table: "after_sales_potentials" */
  delete_after_sales_potentials?: Maybe<AfterSalesPotential_Mutation_Response>;
  /** delete data from the table: "after_sales_status" */
  delete_after_sales_status?: Maybe<After_Sales_Status_Mutation_Response>;
  /** delete single row from the table: "after_sales_status" */
  delete_after_sales_status_by_pk?: Maybe<After_Sales_Status>;
  /** delete single row from the table: "customer_appointments" */
  delete_appointment?: Maybe<Appointment>;
  /** delete data from the table: "customer_appointments" */
  delete_appointments?: Maybe<Appointment_Mutation_Response>;
  /** delete data from the table: "asset_link_family_notifications" */
  delete_asset_link_family_notifications?: Maybe<Asset_Link_Family_Notifications_Mutation_Response>;
  /** delete single row from the table: "asset_link_family_notifications" */
  delete_asset_link_family_notifications_by_pk?: Maybe<Asset_Link_Family_Notifications>;
  /** delete data from the table: "asset_watchdog_link_status" */
  delete_asset_watchdog_link_status?: Maybe<Asset_Watchdog_Link_Status_Mutation_Response>;
  /** delete single row from the table: "asset_watchdog_link_status" */
  delete_asset_watchdog_link_status_by_pk?: Maybe<Asset_Watchdog_Link_Status>;
  /** delete data from the table: "asset_watchdog_links" */
  delete_asset_watchdog_links?: Maybe<Asset_Watchdog_Links_Mutation_Response>;
  /** delete single row from the table: "asset_watchdog_links" */
  delete_asset_watchdog_links_by_pk?: Maybe<Asset_Watchdog_Links>;
  /** delete single row from the table: "brands" */
  delete_brand?: Maybe<Brand>;
  /** delete data from the table: "brand_boni_types" */
  delete_brand_boni_types?: Maybe<Brand_Boni_Types_Mutation_Response>;
  /** delete single row from the table: "brand_boni_types" */
  delete_brand_boni_types_by_pk?: Maybe<Brand_Boni_Types>;
  /** delete single row from the table: "brand_supplier" */
  delete_brand_supplier?: Maybe<Brand_Suppliers>;
  /** delete data from the table: "brand_supplier" */
  delete_brand_suppliers?: Maybe<Brand_Suppliers_Mutation_Response>;
  /** delete data from the table: "brand_vehicle_metafield" */
  delete_brand_vehicle_metafield?: Maybe<Brand_Vehicle_Metafield_Mutation_Response>;
  /** delete single row from the table: "brand_vehicle_metafield" */
  delete_brand_vehicle_metafield_by_pk?: Maybe<Brand_Vehicle_Metafield>;
  /** delete data from the table: "brands" */
  delete_brands?: Maybe<Brand_Mutation_Response>;
  /** delete single row from the table: "dealer_vehicle_inbox" */
  delete_by_pk_dealer_vehicle_inbox?: Maybe<DealerVehicleInbox>;
  /** delete single row from the table: "contact_notes" */
  delete_contact_note?: Maybe<ContactNote>;
  /** delete data from the table: "contact_note_type" */
  delete_contact_note_type?: Maybe<Contact_Note_Type_Mutation_Response>;
  /** delete single row from the table: "contact_note_type" */
  delete_contact_note_type_by_pk?: Maybe<Contact_Note_Type>;
  /** delete data from the table: "contact_notes" */
  delete_contact_notes?: Maybe<ContactNote_Mutation_Response>;
  /** delete single row from the table: "customers" */
  delete_customer?: Maybe<Customer>;
  /** delete data from the table: "customer_customer_tag" */
  delete_customer_customer_tag?: Maybe<Customer_Customer_Tag_Mutation_Response>;
  /** delete single row from the table: "customer_customer_tag" */
  delete_customer_customer_tag_by_pk?: Maybe<Customer_Customer_Tag>;
  /** delete single row from the table: "customer_gdpr_info" */
  delete_customer_gdpr_info?: Maybe<CustomerGdprInfo>;
  /** delete data from the table: "customer_gdpr_info" */
  delete_customer_gdpr_infos?: Maybe<CustomerGdprInfo_Mutation_Response>;
  /** delete data from the table: "customer_salutation" */
  delete_customer_salutation?: Maybe<Customer_Salutation_Mutation_Response>;
  /** delete single row from the table: "customer_salutation" */
  delete_customer_salutation_by_pk?: Maybe<Customer_Salutation>;
  /** delete data from the table: "customer_tag" */
  delete_customer_tag?: Maybe<Customer_Tag_Mutation_Response>;
  /** delete single row from the table: "customer_tag" */
  delete_customer_tag_by_pk?: Maybe<Customer_Tag>;
  /** delete data from the table: "customer_type" */
  delete_customer_type?: Maybe<Customer_Type_Mutation_Response>;
  /** delete single row from the table: "customer_type" */
  delete_customer_type_by_pk?: Maybe<Customer_Type>;
  /** delete single row from the table: "customer_vehicles" */
  delete_customer_vehicle?: Maybe<CustomerVehicle>;
  /** delete data from the table: "customer_vehicles" */
  delete_customer_vehicles?: Maybe<CustomerVehicle_Mutation_Response>;
  /** delete data from the table: "customers" */
  delete_customers?: Maybe<Customer_Mutation_Response>;
  /** delete single row from the table: "dealer_vehicles" */
  delete_dealer_vehicle?: Maybe<DealerVehicle>;
  /** delete data from the table: "dealer_vehicle_import_templates" */
  delete_dealer_vehicle_import_templates?: Maybe<Dealer_Vehicle_Import_Templates_Mutation_Response>;
  /** delete single row from the table: "dealer_vehicle_import_templates" */
  delete_dealer_vehicle_import_templates_by_pk?: Maybe<Dealer_Vehicle_Import_Templates>;
  /** delete data from the table: "dealer_vehicle_inbox" */
  delete_dealer_vehicle_inbox?: Maybe<DealerVehicleInbox_Mutation_Response>;
  /** delete data from the table: "dealer_vehicle_inbox_import_templates" */
  delete_dealer_vehicle_inbox_import_templates?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Mutation_Response>;
  /** delete single row from the table: "dealer_vehicle_inbox_import_templates" */
  delete_dealer_vehicle_inbox_import_templates_by_pk?: Maybe<Dealer_Vehicle_Inbox_Import_Templates>;
  /** delete single row from the table: "dealer_vehicle_notes" */
  delete_dealer_vehicle_note?: Maybe<DealerVehicleNote>;
  /** delete data from the table: "dealer_vehicle_notes" */
  delete_dealer_vehicle_notes?: Maybe<DealerVehicleNote_Mutation_Response>;
  /** delete data from the table: "dealer_vehicle_state" */
  delete_dealer_vehicle_state?: Maybe<Dealer_Vehicle_State_Mutation_Response>;
  /** delete single row from the table: "dealer_vehicle_state" */
  delete_dealer_vehicle_state_by_pk?: Maybe<Dealer_Vehicle_State>;
  /** delete data from the table: "dealer_vehicle_type" */
  delete_dealer_vehicle_type?: Maybe<Dealer_Vehicle_Type_Mutation_Response>;
  /** delete single row from the table: "dealer_vehicle_type" */
  delete_dealer_vehicle_type_by_pk?: Maybe<Dealer_Vehicle_Type>;
  /** delete data from the table: "dealer_vehicles" */
  delete_dealer_vehicles?: Maybe<DealerVehicle_Mutation_Response>;
  /** delete single row from the table: "departments" */
  delete_department?: Maybe<Department>;
  /** delete data from the table: "department_role" */
  delete_department_role?: Maybe<Department_Role_Mutation_Response>;
  /** delete single row from the table: "department_role" */
  delete_department_role_by_pk?: Maybe<Department_Role>;
  /** delete data from the table: "departments" */
  delete_departments?: Maybe<Department_Mutation_Response>;
  /** delete single row from the table: "dms_reference" */
  delete_dms_reference?: Maybe<DmsReference>;
  /** delete data from the table: "dms_reference" */
  delete_dms_references?: Maybe<DmsReference_Mutation_Response>;
  /** delete data from the table: "dms_type" */
  delete_dms_type?: Maybe<Dms_Type_Mutation_Response>;
  /** delete single row from the table: "dms_type" */
  delete_dms_type_by_pk?: Maybe<Dms_Type>;
  /** delete data from the table: "embedded_pages" */
  delete_embedded_pages?: Maybe<Embedded_Pages_Mutation_Response>;
  /** delete single row from the table: "embedded_pages" */
  delete_embedded_pages_by_pk?: Maybe<Embedded_Pages>;
  /** delete data from the table: "excel_import_assignments" */
  delete_excel_import_assignments?: Maybe<Excel_Import_Assignments_Mutation_Response>;
  /** delete single row from the table: "excel_import_assignments" */
  delete_excel_import_assignments_by_pk?: Maybe<Excel_Import_Assignments>;
  /** delete data from the table: "financing_type" */
  delete_financing_type?: Maybe<Financing_Type_Mutation_Response>;
  /** delete single row from the table: "financing_type" */
  delete_financing_type_by_pk?: Maybe<Financing_Type>;
  /** delete data from the table: "import_job_executable_status" */
  delete_import_job_executable_status?: Maybe<Import_Job_Executable_Status_Mutation_Response>;
  /** delete single row from the table: "import_job_executable_status" */
  delete_import_job_executable_status_by_pk?: Maybe<Import_Job_Executable_Status>;
  /** delete data from the table: "import_job_status" */
  delete_import_job_status?: Maybe<Import_Job_Status_Mutation_Response>;
  /** delete single row from the table: "import_job_status" */
  delete_import_job_status_by_pk?: Maybe<Import_Job_Status>;
  /** delete data from the table: "import_job_vehicle_executables" */
  delete_import_job_vehicle_executables?: Maybe<Import_Job_Vehicle_Executables_Mutation_Response>;
  /** delete single row from the table: "import_job_vehicle_executables" */
  delete_import_job_vehicle_executables_by_pk?: Maybe<Import_Job_Vehicle_Executables>;
  /** delete data from the table: "import_jobs" */
  delete_import_jobs?: Maybe<Import_Jobs_Mutation_Response>;
  /** delete single row from the table: "import_jobs" */
  delete_import_jobs_by_pk?: Maybe<Import_Jobs>;
  /** delete data from the table: "import_type" */
  delete_import_type?: Maybe<Import_Type_Mutation_Response>;
  /** delete single row from the table: "import_type" */
  delete_import_type_by_pk?: Maybe<Import_Type>;
  /** delete data from the table: "in_buy_channel" */
  delete_in_buy_channel?: Maybe<In_Buy_Channel_Mutation_Response>;
  /** delete single row from the table: "in_buy_channel" */
  delete_in_buy_channel_by_pk?: Maybe<In_Buy_Channel>;
  /** delete single row from the table: "locations" */
  delete_location?: Maybe<Location>;
  /** delete single row from the table: "location_brand" */
  delete_location_brand_reference?: Maybe<LocationBrandReference>;
  /** delete data from the table: "location_brand" */
  delete_location_brand_references?: Maybe<LocationBrandReference_Mutation_Response>;
  /** delete single row from the table: "location_groups" */
  delete_location_group?: Maybe<LocationGroup>;
  /** delete data from the table: "location_group_brand" */
  delete_location_group_brand?: Maybe<Location_Group_Brand_Mutation_Response>;
  /** delete single row from the table: "location_group_brand" */
  delete_location_group_brand_by_pk?: Maybe<Location_Group_Brand>;
  /** delete data from the table: "location_groups" */
  delete_location_groups?: Maybe<LocationGroup_Mutation_Response>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Location_Mutation_Response>;
  /** delete single row from the table: "models" */
  delete_model?: Maybe<Model>;
  /** delete single row from the table: "model_groups" */
  delete_model_group?: Maybe<ModelGroup>;
  /** delete data from the table: "model_group_release_years" */
  delete_model_group_release_years?: Maybe<Model_Group_Release_Years_Mutation_Response>;
  /** delete single row from the table: "model_group_release_years" */
  delete_model_group_release_years_by_pk?: Maybe<Model_Group_Release_Years>;
  /** delete data from the table: "model_groups" */
  delete_model_groups?: Maybe<ModelGroup_Mutation_Response>;
  /** delete data from the table: "models" */
  delete_models?: Maybe<Model_Mutation_Response>;
  /** delete single row from the table: "notes" */
  delete_note?: Maybe<Notes>;
  /** delete data from the table: "notes" */
  delete_notes?: Maybe<Notes_Mutation_Response>;
  /** delete data from the table: "notification_status" */
  delete_notification_status?: Maybe<Notification_Status_Mutation_Response>;
  /** delete single row from the table: "notification_status" */
  delete_notification_status_by_pk?: Maybe<Notification_Status>;
  /** delete data from the table: "notification_type" */
  delete_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** delete single row from the table: "notification_type" */
  delete_notification_type_by_pk?: Maybe<Notification_Type>;
  /** delete data from the table: "order_state" */
  delete_order_state?: Maybe<Order_State_Mutation_Response>;
  /** delete single row from the table: "order_state" */
  delete_order_state_by_pk?: Maybe<Order_State>;
  /** delete single row from the table: "phone_numbers" */
  delete_phone_number?: Maybe<PhoneNumber>;
  /** delete data from the table: "phone_number_type" */
  delete_phone_number_type?: Maybe<Phone_Number_Type_Mutation_Response>;
  /** delete single row from the table: "phone_number_type" */
  delete_phone_number_type_by_pk?: Maybe<Phone_Number_Type>;
  /** delete data from the table: "phone_numbers" */
  delete_phone_numbers?: Maybe<PhoneNumber_Mutation_Response>;
  /** delete data from the table: "registration_state" */
  delete_registration_state?: Maybe<Registration_State_Mutation_Response>;
  /** delete single row from the table: "registration_state" */
  delete_registration_state_by_pk?: Maybe<Registration_State>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "sales_reports" */
  delete_sales_reports?: Maybe<Sales_Reports_Mutation_Response>;
  /** delete single row from the table: "sales_reports" */
  delete_sales_reports_by_pk?: Maybe<Sales_Reports>;
  /** delete data from the table: "sales_type" */
  delete_sales_type?: Maybe<Sales_Type_Mutation_Response>;
  /** delete single row from the table: "sales_type" */
  delete_sales_type_by_pk?: Maybe<Sales_Type>;
  /** delete data from the table: "salutation_type" */
  delete_salutation_type?: Maybe<Salutation_Type_Mutation_Response>;
  /** delete single row from the table: "salutation_type" */
  delete_salutation_type_by_pk?: Maybe<Salutation_Type>;
  /** delete single row from the table: "storage_locations" */
  delete_storage_location?: Maybe<StorageLocation>;
  /** delete data from the table: "storage_locations" */
  delete_storage_locations?: Maybe<StorageLocation_Mutation_Response>;
  /** delete single row from the table: "subdealers" */
  delete_subdealer?: Maybe<Subdealer>;
  /** delete data from the table: "subdealer_brand" */
  delete_subdealer_brand?: Maybe<Subdealer_Brand_Mutation_Response>;
  /** delete single row from the table: "subdealer_brand" */
  delete_subdealer_brand_by_pk?: Maybe<Subdealer_Brand>;
  /** delete data from the table: "subdealers" */
  delete_subdealers?: Maybe<Subdealer_Mutation_Response>;
  /** delete single row from the table: "suppliers" */
  delete_supplier?: Maybe<Supplier>;
  /** delete data from the table: "suppliers" */
  delete_suppliers?: Maybe<Supplier_Mutation_Response>;
  /** delete data from the table: "sync_errors" */
  delete_sync_errors?: Maybe<Sync_Errors_Mutation_Response>;
  /** delete single row from the table: "sync_errors" */
  delete_sync_errors_by_pk?: Maybe<Sync_Errors>;
  /** delete data from the table: "task_category" */
  delete_task_category?: Maybe<Task_Category_Mutation_Response>;
  /** delete single row from the table: "task_category" */
  delete_task_category_by_pk?: Maybe<Task_Category>;
  /** delete single row from the table: "task_types" */
  delete_task_type?: Maybe<TaskType>;
  /** delete data from the table: "task_type_task_category" */
  delete_task_type_task_category?: Maybe<Task_Type_Task_Category_Mutation_Response>;
  /** delete single row from the table: "task_type_task_category" */
  delete_task_type_task_category_by_pk?: Maybe<Task_Type_Task_Category>;
  /** delete data from the table: "task_types" */
  delete_task_types?: Maybe<TaskType_Mutation_Response>;
  /** delete single row from the table: "team_assignments" */
  delete_team_assignment?: Maybe<TeamAssignment>;
  /** delete data from the table: "team_assignments" */
  delete_team_assignments?: Maybe<TeamAssignment_Mutation_Response>;
  /** delete single row from the table: "tickets" */
  delete_ticket?: Maybe<Ticket>;
  /** delete data from the table: "ticket_family_notifications" */
  delete_ticket_family_notifications?: Maybe<Ticket_Family_Notifications_Mutation_Response>;
  /** delete single row from the table: "ticket_family_notifications" */
  delete_ticket_family_notifications_by_pk?: Maybe<Ticket_Family_Notifications>;
  /** delete single row from the table: "ticket_notes" */
  delete_ticket_note?: Maybe<TicketNote>;
  /** delete data from the table: "ticket_notes" */
  delete_ticket_notes?: Maybe<TicketNote_Mutation_Response>;
  /** delete data from the table: "ticket_priority" */
  delete_ticket_priority?: Maybe<Ticket_Priority_Mutation_Response>;
  /** delete single row from the table: "ticket_priority" */
  delete_ticket_priority_by_pk?: Maybe<Ticket_Priority>;
  /** delete data from the table: "ticket_state" */
  delete_ticket_state?: Maybe<Ticket_State_Mutation_Response>;
  /** delete single row from the table: "ticket_state" */
  delete_ticket_state_by_pk?: Maybe<Ticket_State>;
  /** delete single row from the table: "ticket_templates" */
  delete_ticket_template?: Maybe<TicketTemplate>;
  /** delete data from the table: "ticket_template_assignee_ref" */
  delete_ticket_template_assignee_ref?: Maybe<Ticket_Template_Assignee_Ref_Mutation_Response>;
  /** delete single row from the table: "ticket_template_assignee_ref" */
  delete_ticket_template_assignee_ref_by_pk?: Maybe<Ticket_Template_Assignee_Ref>;
  /** delete data from the table: "ticket_template_location_ref" */
  delete_ticket_template_location_ref?: Maybe<Ticket_Template_Location_Ref_Mutation_Response>;
  /** delete single row from the table: "ticket_template_location_ref" */
  delete_ticket_template_location_ref_by_pk?: Maybe<Ticket_Template_Location_Ref>;
  /** delete data from the table: "ticket_templates" */
  delete_ticket_templates?: Maybe<TicketTemplate_Mutation_Response>;
  /** delete data from the table: "tickets" */
  delete_tickets?: Maybe<Ticket_Mutation_Response>;
  /** delete data from the table: "transactions" */
  delete_transactions?: Maybe<Transactions_Mutation_Response>;
  /** delete single row from the table: "transactions" */
  delete_transactions_by_pk?: Maybe<Transactions>;
  /** delete single row from the table: "users" */
  delete_user?: Maybe<User>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "vehicles" */
  delete_vehicle?: Maybe<Vehicle>;
  /** delete data from the table: "vehicle_boni" */
  delete_vehicle_boni?: Maybe<Vehicle_Boni_Mutation_Response>;
  /** delete single row from the table: "vehicle_boni" */
  delete_vehicle_boni_by_pk?: Maybe<Vehicle_Boni>;
  /** delete data from the table: "vehicle_import_templates" */
  delete_vehicle_import_templates?: Maybe<Vehicle_Import_Templates_Mutation_Response>;
  /** delete single row from the table: "vehicle_import_templates" */
  delete_vehicle_import_templates_by_pk?: Maybe<Vehicle_Import_Templates>;
  /** delete data from the table: "vehicle_metafields" */
  delete_vehicle_metafields?: Maybe<Vehicle_Metafields_Mutation_Response>;
  /** delete single row from the table: "vehicle_metafields" */
  delete_vehicle_metafields_by_pk?: Maybe<Vehicle_Metafields>;
  /** delete single row from the table: "vehicle_notes" */
  delete_vehicle_note?: Maybe<VehicleNote>;
  /** delete data from the table: "vehicle_notes" */
  delete_vehicle_notes?: Maybe<VehicleNote_Mutation_Response>;
  /** delete data from the table: "vehicle_owner_informations" */
  delete_vehicle_owner_informations?: Maybe<Vehicle_Owner_Informations_Mutation_Response>;
  /** delete single row from the table: "vehicle_owner_informations" */
  delete_vehicle_owner_informations_by_pk?: Maybe<Vehicle_Owner_Informations>;
  /** delete data from the table: "vehicles" */
  delete_vehicles?: Maybe<Vehicle_Mutation_Response>;
  /** delete data from the table: "work_order_status" */
  delete_work_order_status?: Maybe<Work_Order_Status_Mutation_Response>;
  /** delete single row from the table: "work_order_status" */
  delete_work_order_status_by_pk?: Maybe<Work_Order_Status>;
  /** delete data from the table: "work_order_task_category" */
  delete_work_order_task_category?: Maybe<Work_Order_Task_Category_Mutation_Response>;
  /** delete single row from the table: "work_order_task_category" */
  delete_work_order_task_category_by_pk?: Maybe<Work_Order_Task_Category>;
  /** delete data from the table: "work_orders" */
  delete_work_orders?: Maybe<Work_Orders_Mutation_Response>;
  /** delete single row from the table: "work_orders" */
  delete_work_orders_by_pk?: Maybe<Work_Orders>;
  executeImportJob?: Maybe<ExecuteImportJobPayload>;
  importDealerVehicles?: Maybe<ImportDealerVehiclesOutput>;
  /** insert a single row into the table: "addresses" */
  insert_address?: Maybe<Address>;
  /** insert data into the table: "addresses" */
  insert_addresses?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "after_sales_potentials" */
  insert_after_sales_potential?: Maybe<AfterSalesPotential>;
  /** insert data into the table: "after_sales_potentials" */
  insert_after_sales_potentials?: Maybe<AfterSalesPotential_Mutation_Response>;
  /** insert data into the table: "after_sales_status" */
  insert_after_sales_status?: Maybe<After_Sales_Status_Mutation_Response>;
  /** insert a single row into the table: "after_sales_status" */
  insert_after_sales_status_one?: Maybe<After_Sales_Status>;
  /** insert a single row into the table: "customer_appointments" */
  insert_appointment?: Maybe<Appointment>;
  /** insert data into the table: "customer_appointments" */
  insert_appointments?: Maybe<Appointment_Mutation_Response>;
  /** insert data into the table: "asset_link_family_notifications" */
  insert_asset_link_family_notifications?: Maybe<Asset_Link_Family_Notifications_Mutation_Response>;
  /** insert a single row into the table: "asset_link_family_notifications" */
  insert_asset_link_family_notifications_one?: Maybe<Asset_Link_Family_Notifications>;
  /** insert data into the table: "asset_watchdog_link_status" */
  insert_asset_watchdog_link_status?: Maybe<Asset_Watchdog_Link_Status_Mutation_Response>;
  /** insert a single row into the table: "asset_watchdog_link_status" */
  insert_asset_watchdog_link_status_one?: Maybe<Asset_Watchdog_Link_Status>;
  /** insert data into the table: "asset_watchdog_links" */
  insert_asset_watchdog_links?: Maybe<Asset_Watchdog_Links_Mutation_Response>;
  /** insert a single row into the table: "asset_watchdog_links" */
  insert_asset_watchdog_links_one?: Maybe<Asset_Watchdog_Links>;
  /** insert a single row into the table: "brands" */
  insert_brand?: Maybe<Brand>;
  /** insert data into the table: "brand_boni_types" */
  insert_brand_boni_types?: Maybe<Brand_Boni_Types_Mutation_Response>;
  /** insert a single row into the table: "brand_boni_types" */
  insert_brand_boni_types_one?: Maybe<Brand_Boni_Types>;
  /** insert a single row into the table: "brand_supplier" */
  insert_brand_supplier?: Maybe<Brand_Suppliers>;
  /** insert data into the table: "brand_supplier" */
  insert_brand_suppliers?: Maybe<Brand_Suppliers_Mutation_Response>;
  /** insert data into the table: "brand_vehicle_metafield" */
  insert_brand_vehicle_metafield?: Maybe<Brand_Vehicle_Metafield_Mutation_Response>;
  /** insert a single row into the table: "brand_vehicle_metafield" */
  insert_brand_vehicle_metafield_one?: Maybe<Brand_Vehicle_Metafield>;
  /** insert data into the table: "brands" */
  insert_brands?: Maybe<Brand_Mutation_Response>;
  /** insert a single row into the table: "contact_notes" */
  insert_contact_note?: Maybe<ContactNote>;
  /** insert data into the table: "contact_note_type" */
  insert_contact_note_type?: Maybe<Contact_Note_Type_Mutation_Response>;
  /** insert a single row into the table: "contact_note_type" */
  insert_contact_note_type_one?: Maybe<Contact_Note_Type>;
  /** insert data into the table: "contact_notes" */
  insert_contact_notes?: Maybe<ContactNote_Mutation_Response>;
  /** insert a single row into the table: "customers" */
  insert_customer?: Maybe<Customer>;
  /** insert data into the table: "customer_customer_tag" */
  insert_customer_customer_tag?: Maybe<Customer_Customer_Tag_Mutation_Response>;
  /** insert a single row into the table: "customer_customer_tag" */
  insert_customer_customer_tag_one?: Maybe<Customer_Customer_Tag>;
  /** insert a single row into the table: "customer_gdpr_info" */
  insert_customer_gdpr_info?: Maybe<CustomerGdprInfo>;
  /** insert data into the table: "customer_gdpr_info" */
  insert_customer_gdpr_infos?: Maybe<CustomerGdprInfo_Mutation_Response>;
  /** insert data into the table: "customer_salutation" */
  insert_customer_salutation?: Maybe<Customer_Salutation_Mutation_Response>;
  /** insert a single row into the table: "customer_salutation" */
  insert_customer_salutation_one?: Maybe<Customer_Salutation>;
  /** insert data into the table: "customer_tag" */
  insert_customer_tag?: Maybe<Customer_Tag_Mutation_Response>;
  /** insert a single row into the table: "customer_tag" */
  insert_customer_tag_one?: Maybe<Customer_Tag>;
  /** insert data into the table: "customer_type" */
  insert_customer_type?: Maybe<Customer_Type_Mutation_Response>;
  /** insert a single row into the table: "customer_type" */
  insert_customer_type_one?: Maybe<Customer_Type>;
  /** insert a single row into the table: "customer_vehicles" */
  insert_customer_vehicle?: Maybe<CustomerVehicle>;
  /** insert data into the table: "customer_vehicles" */
  insert_customer_vehicles?: Maybe<CustomerVehicle_Mutation_Response>;
  /** insert data into the table: "customers" */
  insert_customers?: Maybe<Customer_Mutation_Response>;
  /** insert a single row into the table: "dealer_vehicles" */
  insert_dealer_vehicle?: Maybe<DealerVehicle>;
  /** insert data into the table: "dealer_vehicle_import_templates" */
  insert_dealer_vehicle_import_templates?: Maybe<Dealer_Vehicle_Import_Templates_Mutation_Response>;
  /** insert a single row into the table: "dealer_vehicle_import_templates" */
  insert_dealer_vehicle_import_templates_one?: Maybe<Dealer_Vehicle_Import_Templates>;
  /** insert data into the table: "dealer_vehicle_inbox" */
  insert_dealer_vehicle_inbox?: Maybe<DealerVehicleInbox_Mutation_Response>;
  /** insert data into the table: "dealer_vehicle_inbox_import_templates" */
  insert_dealer_vehicle_inbox_import_templates?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Mutation_Response>;
  /** insert a single row into the table: "dealer_vehicle_inbox_import_templates" */
  insert_dealer_vehicle_inbox_import_templates_one?: Maybe<Dealer_Vehicle_Inbox_Import_Templates>;
  /** insert a single row into the table: "dealer_vehicle_inbox" */
  insert_dealer_vehicle_inbox_one?: Maybe<DealerVehicleInbox>;
  /** insert a single row into the table: "dealer_vehicle_notes" */
  insert_dealer_vehicle_note?: Maybe<DealerVehicleNote>;
  /** insert data into the table: "dealer_vehicle_notes" */
  insert_dealer_vehicle_notes?: Maybe<DealerVehicleNote_Mutation_Response>;
  /** insert data into the table: "dealer_vehicle_state" */
  insert_dealer_vehicle_state?: Maybe<Dealer_Vehicle_State_Mutation_Response>;
  /** insert a single row into the table: "dealer_vehicle_state" */
  insert_dealer_vehicle_state_one?: Maybe<Dealer_Vehicle_State>;
  /** insert data into the table: "dealer_vehicle_type" */
  insert_dealer_vehicle_type?: Maybe<Dealer_Vehicle_Type_Mutation_Response>;
  /** insert a single row into the table: "dealer_vehicle_type" */
  insert_dealer_vehicle_type_one?: Maybe<Dealer_Vehicle_Type>;
  /** insert data into the table: "dealer_vehicles" */
  insert_dealer_vehicles?: Maybe<DealerVehicle_Mutation_Response>;
  /** insert a single row into the table: "departments" */
  insert_department?: Maybe<Department>;
  /** insert data into the table: "department_role" */
  insert_department_role?: Maybe<Department_Role_Mutation_Response>;
  /** insert a single row into the table: "department_role" */
  insert_department_role_one?: Maybe<Department_Role>;
  /** insert data into the table: "departments" */
  insert_departments?: Maybe<Department_Mutation_Response>;
  /** insert a single row into the table: "dms_reference" */
  insert_dms_reference?: Maybe<DmsReference>;
  /** insert data into the table: "dms_reference" */
  insert_dms_references?: Maybe<DmsReference_Mutation_Response>;
  /** insert data into the table: "dms_type" */
  insert_dms_type?: Maybe<Dms_Type_Mutation_Response>;
  /** insert a single row into the table: "dms_type" */
  insert_dms_type_one?: Maybe<Dms_Type>;
  /** insert data into the table: "embedded_pages" */
  insert_embedded_pages?: Maybe<Embedded_Pages_Mutation_Response>;
  /** insert a single row into the table: "embedded_pages" */
  insert_embedded_pages_one?: Maybe<Embedded_Pages>;
  /** insert data into the table: "excel_import_assignments" */
  insert_excel_import_assignments?: Maybe<Excel_Import_Assignments_Mutation_Response>;
  /** insert a single row into the table: "excel_import_assignments" */
  insert_excel_import_assignments_one?: Maybe<Excel_Import_Assignments>;
  /** insert data into the table: "financing_type" */
  insert_financing_type?: Maybe<Financing_Type_Mutation_Response>;
  /** insert a single row into the table: "financing_type" */
  insert_financing_type_one?: Maybe<Financing_Type>;
  /** insert data into the table: "import_job_executable_status" */
  insert_import_job_executable_status?: Maybe<Import_Job_Executable_Status_Mutation_Response>;
  /** insert a single row into the table: "import_job_executable_status" */
  insert_import_job_executable_status_one?: Maybe<Import_Job_Executable_Status>;
  /** insert data into the table: "import_job_status" */
  insert_import_job_status?: Maybe<Import_Job_Status_Mutation_Response>;
  /** insert a single row into the table: "import_job_status" */
  insert_import_job_status_one?: Maybe<Import_Job_Status>;
  /** insert data into the table: "import_job_vehicle_executables" */
  insert_import_job_vehicle_executables?: Maybe<Import_Job_Vehicle_Executables_Mutation_Response>;
  /** insert a single row into the table: "import_job_vehicle_executables" */
  insert_import_job_vehicle_executables_one?: Maybe<Import_Job_Vehicle_Executables>;
  /** insert data into the table: "import_jobs" */
  insert_import_jobs?: Maybe<Import_Jobs_Mutation_Response>;
  /** insert a single row into the table: "import_jobs" */
  insert_import_jobs_one?: Maybe<Import_Jobs>;
  /** insert data into the table: "import_type" */
  insert_import_type?: Maybe<Import_Type_Mutation_Response>;
  /** insert a single row into the table: "import_type" */
  insert_import_type_one?: Maybe<Import_Type>;
  /** insert data into the table: "in_buy_channel" */
  insert_in_buy_channel?: Maybe<In_Buy_Channel_Mutation_Response>;
  /** insert a single row into the table: "in_buy_channel" */
  insert_in_buy_channel_one?: Maybe<In_Buy_Channel>;
  /** insert a single row into the table: "locations" */
  insert_location?: Maybe<Location>;
  /** insert a single row into the table: "location_brand" */
  insert_location_brand_reference?: Maybe<LocationBrandReference>;
  /** insert data into the table: "location_brand" */
  insert_location_brand_references?: Maybe<LocationBrandReference_Mutation_Response>;
  /** insert a single row into the table: "location_groups" */
  insert_location_group?: Maybe<LocationGroup>;
  /** insert data into the table: "location_group_brand" */
  insert_location_group_brand?: Maybe<Location_Group_Brand_Mutation_Response>;
  /** insert a single row into the table: "location_group_brand" */
  insert_location_group_brand_one?: Maybe<Location_Group_Brand>;
  /** insert data into the table: "location_groups" */
  insert_location_groups?: Maybe<LocationGroup_Mutation_Response>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Location_Mutation_Response>;
  /** insert a single row into the table: "models" */
  insert_model?: Maybe<Model>;
  /** insert a single row into the table: "model_groups" */
  insert_model_group?: Maybe<ModelGroup>;
  /** insert data into the table: "model_group_release_years" */
  insert_model_group_release_years?: Maybe<Model_Group_Release_Years_Mutation_Response>;
  /** insert a single row into the table: "model_group_release_years" */
  insert_model_group_release_years_one?: Maybe<Model_Group_Release_Years>;
  /** insert data into the table: "model_groups" */
  insert_model_groups?: Maybe<ModelGroup_Mutation_Response>;
  /** insert data into the table: "models" */
  insert_models?: Maybe<Model_Mutation_Response>;
  /** insert a single row into the table: "notes" */
  insert_note?: Maybe<Notes>;
  /** insert data into the table: "notes" */
  insert_notes?: Maybe<Notes_Mutation_Response>;
  /** insert data into the table: "notification_status" */
  insert_notification_status?: Maybe<Notification_Status_Mutation_Response>;
  /** insert a single row into the table: "notification_status" */
  insert_notification_status_one?: Maybe<Notification_Status>;
  /** insert data into the table: "notification_type" */
  insert_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** insert a single row into the table: "notification_type" */
  insert_notification_type_one?: Maybe<Notification_Type>;
  /** insert data into the table: "order_state" */
  insert_order_state?: Maybe<Order_State_Mutation_Response>;
  /** insert a single row into the table: "order_state" */
  insert_order_state_one?: Maybe<Order_State>;
  /** insert a single row into the table: "phone_numbers" */
  insert_phone_number?: Maybe<PhoneNumber>;
  /** insert data into the table: "phone_number_type" */
  insert_phone_number_type?: Maybe<Phone_Number_Type_Mutation_Response>;
  /** insert a single row into the table: "phone_number_type" */
  insert_phone_number_type_one?: Maybe<Phone_Number_Type>;
  /** insert data into the table: "phone_numbers" */
  insert_phone_numbers?: Maybe<PhoneNumber_Mutation_Response>;
  /** insert data into the table: "registration_state" */
  insert_registration_state?: Maybe<Registration_State_Mutation_Response>;
  /** insert a single row into the table: "registration_state" */
  insert_registration_state_one?: Maybe<Registration_State>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "sales_reports" */
  insert_sales_reports?: Maybe<Sales_Reports_Mutation_Response>;
  /** insert a single row into the table: "sales_reports" */
  insert_sales_reports_one?: Maybe<Sales_Reports>;
  /** insert data into the table: "sales_type" */
  insert_sales_type?: Maybe<Sales_Type_Mutation_Response>;
  /** insert a single row into the table: "sales_type" */
  insert_sales_type_one?: Maybe<Sales_Type>;
  /** insert data into the table: "salutation_type" */
  insert_salutation_type?: Maybe<Salutation_Type_Mutation_Response>;
  /** insert a single row into the table: "salutation_type" */
  insert_salutation_type_one?: Maybe<Salutation_Type>;
  /** insert a single row into the table: "storage_locations" */
  insert_storage_location?: Maybe<StorageLocation>;
  /** insert data into the table: "storage_locations" */
  insert_storage_locations?: Maybe<StorageLocation_Mutation_Response>;
  /** insert a single row into the table: "subdealers" */
  insert_subdealer?: Maybe<Subdealer>;
  /** insert data into the table: "subdealer_brand" */
  insert_subdealer_brand?: Maybe<Subdealer_Brand_Mutation_Response>;
  /** insert a single row into the table: "subdealer_brand" */
  insert_subdealer_brand_one?: Maybe<Subdealer_Brand>;
  /** insert data into the table: "subdealers" */
  insert_subdealers?: Maybe<Subdealer_Mutation_Response>;
  /** insert a single row into the table: "suppliers" */
  insert_supplier?: Maybe<Supplier>;
  /** insert data into the table: "suppliers" */
  insert_suppliers?: Maybe<Supplier_Mutation_Response>;
  /** insert data into the table: "sync_errors" */
  insert_sync_errors?: Maybe<Sync_Errors_Mutation_Response>;
  /** insert a single row into the table: "sync_errors" */
  insert_sync_errors_one?: Maybe<Sync_Errors>;
  /** insert data into the table: "task_category" */
  insert_task_category?: Maybe<Task_Category_Mutation_Response>;
  /** insert a single row into the table: "task_category" */
  insert_task_category_one?: Maybe<Task_Category>;
  /** insert a single row into the table: "task_types" */
  insert_task_type?: Maybe<TaskType>;
  /** insert data into the table: "task_type_task_category" */
  insert_task_type_task_category?: Maybe<Task_Type_Task_Category_Mutation_Response>;
  /** insert a single row into the table: "task_type_task_category" */
  insert_task_type_task_category_one?: Maybe<Task_Type_Task_Category>;
  /** insert data into the table: "task_types" */
  insert_task_types?: Maybe<TaskType_Mutation_Response>;
  /** insert a single row into the table: "team_assignments" */
  insert_team_assignment?: Maybe<TeamAssignment>;
  /** insert data into the table: "team_assignments" */
  insert_team_assignments?: Maybe<TeamAssignment_Mutation_Response>;
  /** insert a single row into the table: "tickets" */
  insert_ticket?: Maybe<Ticket>;
  /** insert data into the table: "ticket_family_notifications" */
  insert_ticket_family_notifications?: Maybe<Ticket_Family_Notifications_Mutation_Response>;
  /** insert a single row into the table: "ticket_family_notifications" */
  insert_ticket_family_notifications_one?: Maybe<Ticket_Family_Notifications>;
  /** insert a single row into the table: "ticket_notes" */
  insert_ticket_note?: Maybe<TicketNote>;
  /** insert data into the table: "ticket_notes" */
  insert_ticket_notes?: Maybe<TicketNote_Mutation_Response>;
  /** insert data into the table: "ticket_priority" */
  insert_ticket_priority?: Maybe<Ticket_Priority_Mutation_Response>;
  /** insert a single row into the table: "ticket_priority" */
  insert_ticket_priority_one?: Maybe<Ticket_Priority>;
  /** insert data into the table: "ticket_state" */
  insert_ticket_state?: Maybe<Ticket_State_Mutation_Response>;
  /** insert a single row into the table: "ticket_state" */
  insert_ticket_state_one?: Maybe<Ticket_State>;
  /** insert a single row into the table: "ticket_templates" */
  insert_ticket_template?: Maybe<TicketTemplate>;
  /** insert data into the table: "ticket_template_assignee_ref" */
  insert_ticket_template_assignee_ref?: Maybe<Ticket_Template_Assignee_Ref_Mutation_Response>;
  /** insert a single row into the table: "ticket_template_assignee_ref" */
  insert_ticket_template_assignee_ref_one?: Maybe<Ticket_Template_Assignee_Ref>;
  /** insert data into the table: "ticket_template_location_ref" */
  insert_ticket_template_location_ref?: Maybe<Ticket_Template_Location_Ref_Mutation_Response>;
  /** insert a single row into the table: "ticket_template_location_ref" */
  insert_ticket_template_location_ref_one?: Maybe<Ticket_Template_Location_Ref>;
  /** insert data into the table: "ticket_templates" */
  insert_ticket_templates?: Maybe<TicketTemplate_Mutation_Response>;
  /** insert data into the table: "tickets" */
  insert_tickets?: Maybe<Ticket_Mutation_Response>;
  /** insert data into the table: "transactions" */
  insert_transactions?: Maybe<Transactions_Mutation_Response>;
  /** insert a single row into the table: "transactions" */
  insert_transactions_one?: Maybe<Transactions>;
  /** insert a single row into the table: "users" */
  insert_user?: Maybe<User>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "vehicles" */
  insert_vehicle?: Maybe<Vehicle>;
  /** insert data into the table: "vehicle_boni" */
  insert_vehicle_boni?: Maybe<Vehicle_Boni_Mutation_Response>;
  /** insert a single row into the table: "vehicle_boni" */
  insert_vehicle_boni_one?: Maybe<Vehicle_Boni>;
  /** insert data into the table: "vehicle_import_templates" */
  insert_vehicle_import_templates?: Maybe<Vehicle_Import_Templates_Mutation_Response>;
  /** insert a single row into the table: "vehicle_import_templates" */
  insert_vehicle_import_templates_one?: Maybe<Vehicle_Import_Templates>;
  /** insert data into the table: "vehicle_metafields" */
  insert_vehicle_metafields?: Maybe<Vehicle_Metafields_Mutation_Response>;
  /** insert a single row into the table: "vehicle_metafields" */
  insert_vehicle_metafields_one?: Maybe<Vehicle_Metafields>;
  /** insert a single row into the table: "vehicle_notes" */
  insert_vehicle_note?: Maybe<VehicleNote>;
  /** insert data into the table: "vehicle_notes" */
  insert_vehicle_notes?: Maybe<VehicleNote_Mutation_Response>;
  /** insert data into the table: "vehicle_owner_informations" */
  insert_vehicle_owner_informations?: Maybe<Vehicle_Owner_Informations_Mutation_Response>;
  /** insert a single row into the table: "vehicle_owner_informations" */
  insert_vehicle_owner_informations_one?: Maybe<Vehicle_Owner_Informations>;
  /** insert data into the table: "vehicles" */
  insert_vehicles?: Maybe<Vehicle_Mutation_Response>;
  /** insert data into the table: "work_order_status" */
  insert_work_order_status?: Maybe<Work_Order_Status_Mutation_Response>;
  /** insert a single row into the table: "work_order_status" */
  insert_work_order_status_one?: Maybe<Work_Order_Status>;
  /** insert data into the table: "work_order_task_category" */
  insert_work_order_task_category?: Maybe<Work_Order_Task_Category_Mutation_Response>;
  /** insert a single row into the table: "work_order_task_category" */
  insert_work_order_task_category_one?: Maybe<Work_Order_Task_Category>;
  /** insert data into the table: "work_orders" */
  insert_work_orders?: Maybe<Work_Orders_Mutation_Response>;
  /** insert a single row into the table: "work_orders" */
  insert_work_orders_one?: Maybe<Work_Orders>;
  previewImportJob: PreviewImportJobPayload;
  recoverVehiclesHistories: RecoverVehiclesHistoriesPayload;
  resolveVehicleSyncError: ResolveVehicleSyncErrorPayload;
  toggleBrandForDealerVehicleSync: ToggleBrandForDealerVehicleSyncPayload;
  /** update data of the table: "customer_vehicles" */
  upadte_customer_vehicles?: Maybe<CustomerVehicle_Mutation_Response>;
  /** update multiples rows of table: "addresses" */
  update_Address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update multiples rows of table: "after_sales_potentials" */
  update_AfterSalesPotential_many?: Maybe<Array<Maybe<AfterSalesPotential_Mutation_Response>>>;
  /** update multiples rows of table: "customer_appointments" */
  update_Appointment_many?: Maybe<Array<Maybe<Appointment_Mutation_Response>>>;
  /** update multiples rows of table: "brands" */
  update_Brand_many?: Maybe<Array<Maybe<Brand_Mutation_Response>>>;
  /** update multiples rows of table: "contact_notes" */
  update_ContactNote_many?: Maybe<Array<Maybe<ContactNote_Mutation_Response>>>;
  /** update multiples rows of table: "customer_gdpr_info" */
  update_CustomerGdprInfo_many?: Maybe<Array<Maybe<CustomerGdprInfo_Mutation_Response>>>;
  /** update multiples rows of table: "customer_vehicles" */
  update_CustomerVehicle_many?: Maybe<Array<Maybe<CustomerVehicle_Mutation_Response>>>;
  /** update multiples rows of table: "customers" */
  update_Customer_many?: Maybe<Array<Maybe<Customer_Mutation_Response>>>;
  /** update multiples rows of table: "dealer_vehicle_inbox" */
  update_DealerVehicleInbox_many?: Maybe<Array<Maybe<DealerVehicleInbox_Mutation_Response>>>;
  /** update multiples rows of table: "dealer_vehicle_notes" */
  update_DealerVehicleNote_many?: Maybe<Array<Maybe<DealerVehicleNote_Mutation_Response>>>;
  /** update multiples rows of table: "dealer_vehicles" */
  update_DealerVehicle_many?: Maybe<Array<Maybe<DealerVehicle_Mutation_Response>>>;
  /** update multiples rows of table: "departments" */
  update_Department_many?: Maybe<Array<Maybe<Department_Mutation_Response>>>;
  /** update multiples rows of table: "dms_reference" */
  update_DmsReference_many?: Maybe<Array<Maybe<DmsReference_Mutation_Response>>>;
  /** update multiples rows of table: "location_brand" */
  update_LocationBrandReference_many?: Maybe<Array<Maybe<LocationBrandReference_Mutation_Response>>>;
  /** update multiples rows of table: "location_groups" */
  update_LocationGroup_many?: Maybe<Array<Maybe<LocationGroup_Mutation_Response>>>;
  /** update multiples rows of table: "locations" */
  update_Location_many?: Maybe<Array<Maybe<Location_Mutation_Response>>>;
  /** update multiples rows of table: "model_groups" */
  update_ModelGroup_many?: Maybe<Array<Maybe<ModelGroup_Mutation_Response>>>;
  /** update multiples rows of table: "models" */
  update_Model_many?: Maybe<Array<Maybe<Model_Mutation_Response>>>;
  /** update multiples rows of table: "phone_numbers" */
  update_PhoneNumber_many?: Maybe<Array<Maybe<PhoneNumber_Mutation_Response>>>;
  /** update multiples rows of table: "storage_locations" */
  update_StorageLocation_many?: Maybe<Array<Maybe<StorageLocation_Mutation_Response>>>;
  /** update multiples rows of table: "subdealers" */
  update_Subdealer_many?: Maybe<Array<Maybe<Subdealer_Mutation_Response>>>;
  /** update multiples rows of table: "suppliers" */
  update_Supplier_many?: Maybe<Array<Maybe<Supplier_Mutation_Response>>>;
  /** update multiples rows of table: "task_types" */
  update_TaskType_many?: Maybe<Array<Maybe<TaskType_Mutation_Response>>>;
  /** update multiples rows of table: "team_assignments" */
  update_TeamAssignment_many?: Maybe<Array<Maybe<TeamAssignment_Mutation_Response>>>;
  /** update multiples rows of table: "ticket_notes" */
  update_TicketNote_many?: Maybe<Array<Maybe<TicketNote_Mutation_Response>>>;
  /** update multiples rows of table: "ticket_templates" */
  update_TicketTemplate_many?: Maybe<Array<Maybe<TicketTemplate_Mutation_Response>>>;
  /** update multiples rows of table: "tickets" */
  update_Ticket_many?: Maybe<Array<Maybe<Ticket_Mutation_Response>>>;
  /** update multiples rows of table: "users" */
  update_User_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update multiples rows of table: "vehicle_notes" */
  update_VehicleNote_many?: Maybe<Array<Maybe<VehicleNote_Mutation_Response>>>;
  /** update multiples rows of table: "vehicles" */
  update_Vehicle_many?: Maybe<Array<Maybe<Vehicle_Mutation_Response>>>;
  /** update single row of the table: "addresses" */
  update_address?: Maybe<Address>;
  /** update data of the table: "addresses" */
  update_addresses?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "after_sales_potentials" */
  update_after_sales_potential?: Maybe<AfterSalesPotential>;
  /** update data of the table: "after_sales_potentials" */
  update_after_sales_potentials?: Maybe<AfterSalesPotential_Mutation_Response>;
  /** update data of the table: "after_sales_status" */
  update_after_sales_status?: Maybe<After_Sales_Status_Mutation_Response>;
  /** update single row of the table: "after_sales_status" */
  update_after_sales_status_by_pk?: Maybe<After_Sales_Status>;
  /** update multiples rows of table: "after_sales_status" */
  update_after_sales_status_many?: Maybe<Array<Maybe<After_Sales_Status_Mutation_Response>>>;
  /** update single row of the table: "customer_appointments" */
  update_appointment?: Maybe<Appointment>;
  /** update data of the table: "customer_appointments" */
  update_appointments?: Maybe<Appointment_Mutation_Response>;
  /** update data of the table: "asset_link_family_notifications" */
  update_asset_link_family_notifications?: Maybe<Asset_Link_Family_Notifications_Mutation_Response>;
  /** update single row of the table: "asset_link_family_notifications" */
  update_asset_link_family_notifications_by_pk?: Maybe<Asset_Link_Family_Notifications>;
  /** update multiples rows of table: "asset_link_family_notifications" */
  update_asset_link_family_notifications_many?: Maybe<Array<Maybe<Asset_Link_Family_Notifications_Mutation_Response>>>;
  /** update data of the table: "asset_watchdog_link_status" */
  update_asset_watchdog_link_status?: Maybe<Asset_Watchdog_Link_Status_Mutation_Response>;
  /** update single row of the table: "asset_watchdog_link_status" */
  update_asset_watchdog_link_status_by_pk?: Maybe<Asset_Watchdog_Link_Status>;
  /** update multiples rows of table: "asset_watchdog_link_status" */
  update_asset_watchdog_link_status_many?: Maybe<Array<Maybe<Asset_Watchdog_Link_Status_Mutation_Response>>>;
  /** update data of the table: "asset_watchdog_links" */
  update_asset_watchdog_links?: Maybe<Asset_Watchdog_Links_Mutation_Response>;
  /** update single row of the table: "asset_watchdog_links" */
  update_asset_watchdog_links_by_pk?: Maybe<Asset_Watchdog_Links>;
  /** update multiples rows of table: "asset_watchdog_links" */
  update_asset_watchdog_links_many?: Maybe<Array<Maybe<Asset_Watchdog_Links_Mutation_Response>>>;
  /** update single row of the table: "brands" */
  update_brand?: Maybe<Brand>;
  /** update data of the table: "brand_boni_types" */
  update_brand_boni_types?: Maybe<Brand_Boni_Types_Mutation_Response>;
  /** update single row of the table: "brand_boni_types" */
  update_brand_boni_types_by_pk?: Maybe<Brand_Boni_Types>;
  /** update multiples rows of table: "brand_boni_types" */
  update_brand_boni_types_many?: Maybe<Array<Maybe<Brand_Boni_Types_Mutation_Response>>>;
  /** update single row of the table: "brand_supplier" */
  update_brand_supplier?: Maybe<Brand_Suppliers>;
  /** update data of the table: "brand_supplier" */
  update_brand_suppliers?: Maybe<Brand_Suppliers_Mutation_Response>;
  /** update multiples rows of table: "brand_supplier" */
  update_brand_suppliers_many?: Maybe<Array<Maybe<Brand_Suppliers_Mutation_Response>>>;
  /** update data of the table: "brand_vehicle_metafield" */
  update_brand_vehicle_metafield?: Maybe<Brand_Vehicle_Metafield_Mutation_Response>;
  /** update single row of the table: "brand_vehicle_metafield" */
  update_brand_vehicle_metafield_by_pk?: Maybe<Brand_Vehicle_Metafield>;
  /** update multiples rows of table: "brand_vehicle_metafield" */
  update_brand_vehicle_metafield_many?: Maybe<Array<Maybe<Brand_Vehicle_Metafield_Mutation_Response>>>;
  /** update data of the table: "brands" */
  update_brands?: Maybe<Brand_Mutation_Response>;
  /** update single row of the table: "dealer_vehicle_inbox" */
  update_by_pk_dealer_vehicle_inbox?: Maybe<DealerVehicleInbox>;
  /** update single row of the table: "contact_notes" */
  update_contact_note?: Maybe<ContactNote>;
  /** update data of the table: "contact_note_type" */
  update_contact_note_type?: Maybe<Contact_Note_Type_Mutation_Response>;
  /** update single row of the table: "contact_note_type" */
  update_contact_note_type_by_pk?: Maybe<Contact_Note_Type>;
  /** update multiples rows of table: "contact_note_type" */
  update_contact_note_type_many?: Maybe<Array<Maybe<Contact_Note_Type_Mutation_Response>>>;
  /** update data of the table: "contact_notes" */
  update_contact_notes?: Maybe<ContactNote_Mutation_Response>;
  /** update single row of the table: "customers" */
  update_customer?: Maybe<Customer>;
  /** update data of the table: "customer_customer_tag" */
  update_customer_customer_tag?: Maybe<Customer_Customer_Tag_Mutation_Response>;
  /** update single row of the table: "customer_customer_tag" */
  update_customer_customer_tag_by_pk?: Maybe<Customer_Customer_Tag>;
  /** update multiples rows of table: "customer_customer_tag" */
  update_customer_customer_tag_many?: Maybe<Array<Maybe<Customer_Customer_Tag_Mutation_Response>>>;
  /** update single row of the table: "customer_gdpr_info" */
  update_customer_gdpr_info?: Maybe<CustomerGdprInfo>;
  /** update data of the table: "customer_gdpr_info" */
  update_customer_gdpr_infos?: Maybe<CustomerGdprInfo_Mutation_Response>;
  /** update data of the table: "customer_salutation" */
  update_customer_salutation?: Maybe<Customer_Salutation_Mutation_Response>;
  /** update single row of the table: "customer_salutation" */
  update_customer_salutation_by_pk?: Maybe<Customer_Salutation>;
  /** update multiples rows of table: "customer_salutation" */
  update_customer_salutation_many?: Maybe<Array<Maybe<Customer_Salutation_Mutation_Response>>>;
  /** update data of the table: "customer_tag" */
  update_customer_tag?: Maybe<Customer_Tag_Mutation_Response>;
  /** update single row of the table: "customer_tag" */
  update_customer_tag_by_pk?: Maybe<Customer_Tag>;
  /** update multiples rows of table: "customer_tag" */
  update_customer_tag_many?: Maybe<Array<Maybe<Customer_Tag_Mutation_Response>>>;
  /** update data of the table: "customer_type" */
  update_customer_type?: Maybe<Customer_Type_Mutation_Response>;
  /** update single row of the table: "customer_type" */
  update_customer_type_by_pk?: Maybe<Customer_Type>;
  /** update multiples rows of table: "customer_type" */
  update_customer_type_many?: Maybe<Array<Maybe<Customer_Type_Mutation_Response>>>;
  /** update single row of the table: "customer_vehicles" */
  update_customer_vehicle?: Maybe<CustomerVehicle>;
  /** update data of the table: "customers" */
  update_customers?: Maybe<Customer_Mutation_Response>;
  /** update single row of the table: "dealer_vehicles" */
  update_dealer_vehicle?: Maybe<DealerVehicle>;
  /** update data of the table: "dealer_vehicle_import_templates" */
  update_dealer_vehicle_import_templates?: Maybe<Dealer_Vehicle_Import_Templates_Mutation_Response>;
  /** update single row of the table: "dealer_vehicle_import_templates" */
  update_dealer_vehicle_import_templates_by_pk?: Maybe<Dealer_Vehicle_Import_Templates>;
  /** update multiples rows of table: "dealer_vehicle_import_templates" */
  update_dealer_vehicle_import_templates_many?: Maybe<Array<Maybe<Dealer_Vehicle_Import_Templates_Mutation_Response>>>;
  /** update data of the table: "dealer_vehicle_inbox" */
  update_dealer_vehicle_inbox?: Maybe<DealerVehicleInbox_Mutation_Response>;
  /** update data of the table: "dealer_vehicle_inbox_import_templates" */
  update_dealer_vehicle_inbox_import_templates?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Mutation_Response>;
  /** update single row of the table: "dealer_vehicle_inbox_import_templates" */
  update_dealer_vehicle_inbox_import_templates_by_pk?: Maybe<Dealer_Vehicle_Inbox_Import_Templates>;
  /** update multiples rows of table: "dealer_vehicle_inbox_import_templates" */
  update_dealer_vehicle_inbox_import_templates_many?: Maybe<Array<Maybe<Dealer_Vehicle_Inbox_Import_Templates_Mutation_Response>>>;
  /** update single row of the table: "dealer_vehicle_notes" */
  update_dealer_vehicle_note?: Maybe<DealerVehicleNote>;
  /** update data of the table: "dealer_vehicle_notes" */
  update_dealer_vehicle_notes?: Maybe<DealerVehicleNote_Mutation_Response>;
  /** update data of the table: "dealer_vehicle_state" */
  update_dealer_vehicle_state?: Maybe<Dealer_Vehicle_State_Mutation_Response>;
  /** update single row of the table: "dealer_vehicle_state" */
  update_dealer_vehicle_state_by_pk?: Maybe<Dealer_Vehicle_State>;
  /** update multiples rows of table: "dealer_vehicle_state" */
  update_dealer_vehicle_state_many?: Maybe<Array<Maybe<Dealer_Vehicle_State_Mutation_Response>>>;
  /** update data of the table: "dealer_vehicle_type" */
  update_dealer_vehicle_type?: Maybe<Dealer_Vehicle_Type_Mutation_Response>;
  /** update single row of the table: "dealer_vehicle_type" */
  update_dealer_vehicle_type_by_pk?: Maybe<Dealer_Vehicle_Type>;
  /** update multiples rows of table: "dealer_vehicle_type" */
  update_dealer_vehicle_type_many?: Maybe<Array<Maybe<Dealer_Vehicle_Type_Mutation_Response>>>;
  /** update data of the table: "dealer_vehicles" */
  update_dealer_vehicles?: Maybe<DealerVehicle_Mutation_Response>;
  /** update single row of the table: "departments" */
  update_department?: Maybe<Department>;
  /** update data of the table: "department_role" */
  update_department_role?: Maybe<Department_Role_Mutation_Response>;
  /** update single row of the table: "department_role" */
  update_department_role_by_pk?: Maybe<Department_Role>;
  /** update multiples rows of table: "department_role" */
  update_department_role_many?: Maybe<Array<Maybe<Department_Role_Mutation_Response>>>;
  /** update data of the table: "departments" */
  update_departments?: Maybe<Department_Mutation_Response>;
  /** update single row of the table: "dms_reference" */
  update_dms_reference?: Maybe<DmsReference>;
  /** update data of the table: "dms_reference" */
  update_dms_references?: Maybe<DmsReference_Mutation_Response>;
  /** update data of the table: "dms_type" */
  update_dms_type?: Maybe<Dms_Type_Mutation_Response>;
  /** update single row of the table: "dms_type" */
  update_dms_type_by_pk?: Maybe<Dms_Type>;
  /** update multiples rows of table: "dms_type" */
  update_dms_type_many?: Maybe<Array<Maybe<Dms_Type_Mutation_Response>>>;
  /** update data of the table: "embedded_pages" */
  update_embedded_pages?: Maybe<Embedded_Pages_Mutation_Response>;
  /** update single row of the table: "embedded_pages" */
  update_embedded_pages_by_pk?: Maybe<Embedded_Pages>;
  /** update multiples rows of table: "embedded_pages" */
  update_embedded_pages_many?: Maybe<Array<Maybe<Embedded_Pages_Mutation_Response>>>;
  /** update data of the table: "excel_import_assignments" */
  update_excel_import_assignments?: Maybe<Excel_Import_Assignments_Mutation_Response>;
  /** update single row of the table: "excel_import_assignments" */
  update_excel_import_assignments_by_pk?: Maybe<Excel_Import_Assignments>;
  /** update multiples rows of table: "excel_import_assignments" */
  update_excel_import_assignments_many?: Maybe<Array<Maybe<Excel_Import_Assignments_Mutation_Response>>>;
  /** update data of the table: "financing_type" */
  update_financing_type?: Maybe<Financing_Type_Mutation_Response>;
  /** update single row of the table: "financing_type" */
  update_financing_type_by_pk?: Maybe<Financing_Type>;
  /** update multiples rows of table: "financing_type" */
  update_financing_type_many?: Maybe<Array<Maybe<Financing_Type_Mutation_Response>>>;
  /** update data of the table: "import_job_executable_status" */
  update_import_job_executable_status?: Maybe<Import_Job_Executable_Status_Mutation_Response>;
  /** update single row of the table: "import_job_executable_status" */
  update_import_job_executable_status_by_pk?: Maybe<Import_Job_Executable_Status>;
  /** update multiples rows of table: "import_job_executable_status" */
  update_import_job_executable_status_many?: Maybe<Array<Maybe<Import_Job_Executable_Status_Mutation_Response>>>;
  /** update data of the table: "import_job_status" */
  update_import_job_status?: Maybe<Import_Job_Status_Mutation_Response>;
  /** update single row of the table: "import_job_status" */
  update_import_job_status_by_pk?: Maybe<Import_Job_Status>;
  /** update multiples rows of table: "import_job_status" */
  update_import_job_status_many?: Maybe<Array<Maybe<Import_Job_Status_Mutation_Response>>>;
  /** update data of the table: "import_job_vehicle_executables" */
  update_import_job_vehicle_executables?: Maybe<Import_Job_Vehicle_Executables_Mutation_Response>;
  /** update single row of the table: "import_job_vehicle_executables" */
  update_import_job_vehicle_executables_by_pk?: Maybe<Import_Job_Vehicle_Executables>;
  /** update multiples rows of table: "import_job_vehicle_executables" */
  update_import_job_vehicle_executables_many?: Maybe<Array<Maybe<Import_Job_Vehicle_Executables_Mutation_Response>>>;
  /** update data of the table: "import_jobs" */
  update_import_jobs?: Maybe<Import_Jobs_Mutation_Response>;
  /** update single row of the table: "import_jobs" */
  update_import_jobs_by_pk?: Maybe<Import_Jobs>;
  /** update multiples rows of table: "import_jobs" */
  update_import_jobs_many?: Maybe<Array<Maybe<Import_Jobs_Mutation_Response>>>;
  /** update data of the table: "import_type" */
  update_import_type?: Maybe<Import_Type_Mutation_Response>;
  /** update single row of the table: "import_type" */
  update_import_type_by_pk?: Maybe<Import_Type>;
  /** update multiples rows of table: "import_type" */
  update_import_type_many?: Maybe<Array<Maybe<Import_Type_Mutation_Response>>>;
  /** update data of the table: "in_buy_channel" */
  update_in_buy_channel?: Maybe<In_Buy_Channel_Mutation_Response>;
  /** update single row of the table: "in_buy_channel" */
  update_in_buy_channel_by_pk?: Maybe<In_Buy_Channel>;
  /** update multiples rows of table: "in_buy_channel" */
  update_in_buy_channel_many?: Maybe<Array<Maybe<In_Buy_Channel_Mutation_Response>>>;
  /** update single row of the table: "locations" */
  update_location?: Maybe<Location>;
  /** update single row of the table: "location_brand" */
  update_location_brand_reference?: Maybe<LocationBrandReference>;
  /** update data of the table: "location_brand" */
  update_location_brand_references?: Maybe<LocationBrandReference_Mutation_Response>;
  /** update single row of the table: "location_groups" */
  update_location_group?: Maybe<LocationGroup>;
  /** update data of the table: "location_group_brand" */
  update_location_group_brand?: Maybe<Location_Group_Brand_Mutation_Response>;
  /** update single row of the table: "location_group_brand" */
  update_location_group_brand_by_pk?: Maybe<Location_Group_Brand>;
  /** update multiples rows of table: "location_group_brand" */
  update_location_group_brand_many?: Maybe<Array<Maybe<Location_Group_Brand_Mutation_Response>>>;
  /** update data of the table: "location_groups" */
  update_location_groups?: Maybe<LocationGroup_Mutation_Response>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Location_Mutation_Response>;
  /** update multiples rows of table: "notes" */
  update_many_notes?: Maybe<Array<Maybe<Notes_Mutation_Response>>>;
  /** update single row of the table: "models" */
  update_model?: Maybe<Model>;
  /** update single row of the table: "model_groups" */
  update_model_group?: Maybe<ModelGroup>;
  /** update data of the table: "model_group_release_years" */
  update_model_group_release_years?: Maybe<Model_Group_Release_Years_Mutation_Response>;
  /** update single row of the table: "model_group_release_years" */
  update_model_group_release_years_by_pk?: Maybe<Model_Group_Release_Years>;
  /** update multiples rows of table: "model_group_release_years" */
  update_model_group_release_years_many?: Maybe<Array<Maybe<Model_Group_Release_Years_Mutation_Response>>>;
  /** update data of the table: "model_groups" */
  update_model_groups?: Maybe<ModelGroup_Mutation_Response>;
  /** update data of the table: "models" */
  update_models?: Maybe<Model_Mutation_Response>;
  /** update single row of the table: "notes" */
  update_note?: Maybe<Notes>;
  /** update data of the table: "notes" */
  update_notes?: Maybe<Notes_Mutation_Response>;
  /** update data of the table: "notification_status" */
  update_notification_status?: Maybe<Notification_Status_Mutation_Response>;
  /** update single row of the table: "notification_status" */
  update_notification_status_by_pk?: Maybe<Notification_Status>;
  /** update multiples rows of table: "notification_status" */
  update_notification_status_many?: Maybe<Array<Maybe<Notification_Status_Mutation_Response>>>;
  /** update data of the table: "notification_type" */
  update_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** update single row of the table: "notification_type" */
  update_notification_type_by_pk?: Maybe<Notification_Type>;
  /** update multiples rows of table: "notification_type" */
  update_notification_type_many?: Maybe<Array<Maybe<Notification_Type_Mutation_Response>>>;
  /** update data of the table: "order_state" */
  update_order_state?: Maybe<Order_State_Mutation_Response>;
  /** update single row of the table: "order_state" */
  update_order_state_by_pk?: Maybe<Order_State>;
  /** update multiples rows of table: "order_state" */
  update_order_state_many?: Maybe<Array<Maybe<Order_State_Mutation_Response>>>;
  /** update single row of the table: "phone_numbers" */
  update_phone_number?: Maybe<PhoneNumber>;
  /** update data of the table: "phone_number_type" */
  update_phone_number_type?: Maybe<Phone_Number_Type_Mutation_Response>;
  /** update single row of the table: "phone_number_type" */
  update_phone_number_type_by_pk?: Maybe<Phone_Number_Type>;
  /** update multiples rows of table: "phone_number_type" */
  update_phone_number_type_many?: Maybe<Array<Maybe<Phone_Number_Type_Mutation_Response>>>;
  /** update data of the table: "phone_numbers" */
  update_phone_numbers?: Maybe<PhoneNumber_Mutation_Response>;
  /** update data of the table: "registration_state" */
  update_registration_state?: Maybe<Registration_State_Mutation_Response>;
  /** update single row of the table: "registration_state" */
  update_registration_state_by_pk?: Maybe<Registration_State>;
  /** update multiples rows of table: "registration_state" */
  update_registration_state_many?: Maybe<Array<Maybe<Registration_State_Mutation_Response>>>;
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>;
  /** update data of the table: "sales_reports" */
  update_sales_reports?: Maybe<Sales_Reports_Mutation_Response>;
  /** update single row of the table: "sales_reports" */
  update_sales_reports_by_pk?: Maybe<Sales_Reports>;
  /** update multiples rows of table: "sales_reports" */
  update_sales_reports_many?: Maybe<Array<Maybe<Sales_Reports_Mutation_Response>>>;
  /** update data of the table: "sales_type" */
  update_sales_type?: Maybe<Sales_Type_Mutation_Response>;
  /** update single row of the table: "sales_type" */
  update_sales_type_by_pk?: Maybe<Sales_Type>;
  /** update multiples rows of table: "sales_type" */
  update_sales_type_many?: Maybe<Array<Maybe<Sales_Type_Mutation_Response>>>;
  /** update data of the table: "salutation_type" */
  update_salutation_type?: Maybe<Salutation_Type_Mutation_Response>;
  /** update single row of the table: "salutation_type" */
  update_salutation_type_by_pk?: Maybe<Salutation_Type>;
  /** update multiples rows of table: "salutation_type" */
  update_salutation_type_many?: Maybe<Array<Maybe<Salutation_Type_Mutation_Response>>>;
  /** update single row of the table: "storage_locations" */
  update_storage_location?: Maybe<StorageLocation>;
  /** update data of the table: "storage_locations" */
  update_storage_locations?: Maybe<StorageLocation_Mutation_Response>;
  /** update single row of the table: "subdealers" */
  update_subdealer?: Maybe<Subdealer>;
  /** update data of the table: "subdealer_brand" */
  update_subdealer_brand?: Maybe<Subdealer_Brand_Mutation_Response>;
  /** update single row of the table: "subdealer_brand" */
  update_subdealer_brand_by_pk?: Maybe<Subdealer_Brand>;
  /** update multiples rows of table: "subdealer_brand" */
  update_subdealer_brand_many?: Maybe<Array<Maybe<Subdealer_Brand_Mutation_Response>>>;
  /** update data of the table: "subdealers" */
  update_subdealers?: Maybe<Subdealer_Mutation_Response>;
  /** update single row of the table: "suppliers" */
  update_supplier?: Maybe<Supplier>;
  /** update data of the table: "suppliers" */
  update_suppliers?: Maybe<Supplier_Mutation_Response>;
  /** update data of the table: "sync_errors" */
  update_sync_errors?: Maybe<Sync_Errors_Mutation_Response>;
  /** update single row of the table: "sync_errors" */
  update_sync_errors_by_pk?: Maybe<Sync_Errors>;
  /** update multiples rows of table: "sync_errors" */
  update_sync_errors_many?: Maybe<Array<Maybe<Sync_Errors_Mutation_Response>>>;
  /** update data of the table: "task_category" */
  update_task_category?: Maybe<Task_Category_Mutation_Response>;
  /** update single row of the table: "task_category" */
  update_task_category_by_pk?: Maybe<Task_Category>;
  /** update multiples rows of table: "task_category" */
  update_task_category_many?: Maybe<Array<Maybe<Task_Category_Mutation_Response>>>;
  /** update single row of the table: "task_types" */
  update_task_type?: Maybe<TaskType>;
  /** update data of the table: "task_type_task_category" */
  update_task_type_task_category?: Maybe<Task_Type_Task_Category_Mutation_Response>;
  /** update single row of the table: "task_type_task_category" */
  update_task_type_task_category_by_pk?: Maybe<Task_Type_Task_Category>;
  /** update multiples rows of table: "task_type_task_category" */
  update_task_type_task_category_many?: Maybe<Array<Maybe<Task_Type_Task_Category_Mutation_Response>>>;
  /** update data of the table: "task_types" */
  update_task_types?: Maybe<TaskType_Mutation_Response>;
  /** update single row of the table: "team_assignments" */
  update_team_assignment?: Maybe<TeamAssignment>;
  /** update data of the table: "team_assignments" */
  update_team_assignments?: Maybe<TeamAssignment_Mutation_Response>;
  /** update single row of the table: "tickets" */
  update_ticket?: Maybe<Ticket>;
  /** update data of the table: "ticket_family_notifications" */
  update_ticket_family_notifications?: Maybe<Ticket_Family_Notifications_Mutation_Response>;
  /** update single row of the table: "ticket_family_notifications" */
  update_ticket_family_notifications_by_pk?: Maybe<Ticket_Family_Notifications>;
  /** update multiples rows of table: "ticket_family_notifications" */
  update_ticket_family_notifications_many?: Maybe<Array<Maybe<Ticket_Family_Notifications_Mutation_Response>>>;
  /** update single row of the table: "ticket_notes" */
  update_ticket_note?: Maybe<TicketNote>;
  /** update data of the table: "ticket_notes" */
  update_ticket_notes?: Maybe<TicketNote_Mutation_Response>;
  /** update data of the table: "ticket_priority" */
  update_ticket_priority?: Maybe<Ticket_Priority_Mutation_Response>;
  /** update single row of the table: "ticket_priority" */
  update_ticket_priority_by_pk?: Maybe<Ticket_Priority>;
  /** update multiples rows of table: "ticket_priority" */
  update_ticket_priority_many?: Maybe<Array<Maybe<Ticket_Priority_Mutation_Response>>>;
  /** update data of the table: "ticket_state" */
  update_ticket_state?: Maybe<Ticket_State_Mutation_Response>;
  /** update single row of the table: "ticket_state" */
  update_ticket_state_by_pk?: Maybe<Ticket_State>;
  /** update multiples rows of table: "ticket_state" */
  update_ticket_state_many?: Maybe<Array<Maybe<Ticket_State_Mutation_Response>>>;
  /** update single row of the table: "ticket_templates" */
  update_ticket_template?: Maybe<TicketTemplate>;
  /** update data of the table: "ticket_template_assignee_ref" */
  update_ticket_template_assignee_ref?: Maybe<Ticket_Template_Assignee_Ref_Mutation_Response>;
  /** update single row of the table: "ticket_template_assignee_ref" */
  update_ticket_template_assignee_ref_by_pk?: Maybe<Ticket_Template_Assignee_Ref>;
  /** update multiples rows of table: "ticket_template_assignee_ref" */
  update_ticket_template_assignee_ref_many?: Maybe<Array<Maybe<Ticket_Template_Assignee_Ref_Mutation_Response>>>;
  /** update data of the table: "ticket_template_location_ref" */
  update_ticket_template_location_ref?: Maybe<Ticket_Template_Location_Ref_Mutation_Response>;
  /** update single row of the table: "ticket_template_location_ref" */
  update_ticket_template_location_ref_by_pk?: Maybe<Ticket_Template_Location_Ref>;
  /** update multiples rows of table: "ticket_template_location_ref" */
  update_ticket_template_location_ref_many?: Maybe<Array<Maybe<Ticket_Template_Location_Ref_Mutation_Response>>>;
  /** update data of the table: "ticket_templates" */
  update_ticket_templates?: Maybe<TicketTemplate_Mutation_Response>;
  /** update data of the table: "tickets" */
  update_tickets?: Maybe<Ticket_Mutation_Response>;
  /** update data of the table: "transactions" */
  update_transactions?: Maybe<Transactions_Mutation_Response>;
  /** update single row of the table: "transactions" */
  update_transactions_by_pk?: Maybe<Transactions>;
  /** update multiples rows of table: "transactions" */
  update_transactions_many?: Maybe<Array<Maybe<Transactions_Mutation_Response>>>;
  /** update single row of the table: "users" */
  update_user?: Maybe<User>;
  /** update data of the table: "users" */
  update_users?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "vehicles" */
  update_vehicle?: Maybe<Vehicle>;
  /** update data of the table: "vehicle_boni" */
  update_vehicle_boni?: Maybe<Vehicle_Boni_Mutation_Response>;
  /** update single row of the table: "vehicle_boni" */
  update_vehicle_boni_by_pk?: Maybe<Vehicle_Boni>;
  /** update multiples rows of table: "vehicle_boni" */
  update_vehicle_boni_many?: Maybe<Array<Maybe<Vehicle_Boni_Mutation_Response>>>;
  /** update data of the table: "vehicle_import_templates" */
  update_vehicle_import_templates?: Maybe<Vehicle_Import_Templates_Mutation_Response>;
  /** update single row of the table: "vehicle_import_templates" */
  update_vehicle_import_templates_by_pk?: Maybe<Vehicle_Import_Templates>;
  /** update multiples rows of table: "vehicle_import_templates" */
  update_vehicle_import_templates_many?: Maybe<Array<Maybe<Vehicle_Import_Templates_Mutation_Response>>>;
  /** update data of the table: "vehicle_metafields" */
  update_vehicle_metafields?: Maybe<Vehicle_Metafields_Mutation_Response>;
  /** update single row of the table: "vehicle_metafields" */
  update_vehicle_metafields_by_pk?: Maybe<Vehicle_Metafields>;
  /** update multiples rows of table: "vehicle_metafields" */
  update_vehicle_metafields_many?: Maybe<Array<Maybe<Vehicle_Metafields_Mutation_Response>>>;
  /** update single row of the table: "vehicle_notes" */
  update_vehicle_note?: Maybe<VehicleNote>;
  /** update data of the table: "vehicle_notes" */
  update_vehicle_notes?: Maybe<VehicleNote_Mutation_Response>;
  /** update data of the table: "vehicle_owner_informations" */
  update_vehicle_owner_informations?: Maybe<Vehicle_Owner_Informations_Mutation_Response>;
  /** update single row of the table: "vehicle_owner_informations" */
  update_vehicle_owner_informations_by_pk?: Maybe<Vehicle_Owner_Informations>;
  /** update multiples rows of table: "vehicle_owner_informations" */
  update_vehicle_owner_informations_many?: Maybe<Array<Maybe<Vehicle_Owner_Informations_Mutation_Response>>>;
  /** update data of the table: "vehicles" */
  update_vehicles?: Maybe<Vehicle_Mutation_Response>;
  /** update data of the table: "work_order_status" */
  update_work_order_status?: Maybe<Work_Order_Status_Mutation_Response>;
  /** update single row of the table: "work_order_status" */
  update_work_order_status_by_pk?: Maybe<Work_Order_Status>;
  /** update multiples rows of table: "work_order_status" */
  update_work_order_status_many?: Maybe<Array<Maybe<Work_Order_Status_Mutation_Response>>>;
  /** update data of the table: "work_order_task_category" */
  update_work_order_task_category?: Maybe<Work_Order_Task_Category_Mutation_Response>;
  /** update single row of the table: "work_order_task_category" */
  update_work_order_task_category_by_pk?: Maybe<Work_Order_Task_Category>;
  /** update multiples rows of table: "work_order_task_category" */
  update_work_order_task_category_many?: Maybe<Array<Maybe<Work_Order_Task_Category_Mutation_Response>>>;
  /** update data of the table: "work_orders" */
  update_work_orders?: Maybe<Work_Orders_Mutation_Response>;
  /** update single row of the table: "work_orders" */
  update_work_orders_by_pk?: Maybe<Work_Orders>;
  /** update multiples rows of table: "work_orders" */
  update_work_orders_many?: Maybe<Array<Maybe<Work_Orders_Mutation_Response>>>;
}


/** mutation root */
export interface Mutation_RootCheckImportJobTemplateCompatibilityArgs {
  input: CheckImportJobTemplateCompatibilityInput;
}


/** mutation root */
export interface Mutation_RootCreateTicketFromTemplateArgs {
  input: CreateTicketFromTemplateInput;
}


/** mutation root */
export interface Mutation_RootCreateUserArgs {
  input: CreateUserInput;
}


/** mutation root */
export interface Mutation_RootDelete_AddressArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_AddressesArgs {
  where: Address_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_After_Sales_PotentialArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_After_Sales_PotentialsArgs {
  where: AfterSalesPotential_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_After_Sales_StatusArgs {
  where: After_Sales_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_After_Sales_Status_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_AppointmentArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_AppointmentsArgs {
  where: Appointment_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Asset_Link_Family_NotificationsArgs {
  where: Asset_Link_Family_Notifications_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Asset_Link_Family_Notifications_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Asset_Watchdog_Link_StatusArgs {
  where: Asset_Watchdog_Link_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Asset_Watchdog_Link_Status_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Asset_Watchdog_LinksArgs {
  where: Asset_Watchdog_Links_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Asset_Watchdog_Links_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_BrandArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Brand_Boni_TypesArgs {
  where: Brand_Boni_Types_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Brand_Boni_Types_By_PkArgs {
  brand_id: Scalars['uuid'];
  type: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Brand_SupplierArgs {
  brand_id: Scalars['uuid'];
  supplier_id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Brand_SuppliersArgs {
  where: Brand_Suppliers_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Brand_Vehicle_MetafieldArgs {
  where: Brand_Vehicle_Metafield_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Brand_Vehicle_Metafield_By_PkArgs {
  brand_id: Scalars['uuid'];
  vehicle_metafield_name: Vehicle_Metafields_Enum;
}


/** mutation root */
export interface Mutation_RootDelete_BrandsArgs {
  where: Brand_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_By_Pk_Dealer_Vehicle_InboxArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Contact_NoteArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Contact_Note_TypeArgs {
  where: Contact_Note_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Contact_Note_Type_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Contact_NotesArgs {
  where: ContactNote_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_CustomerArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Customer_Customer_TagArgs {
  where: Customer_Customer_Tag_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Customer_Customer_Tag_By_PkArgs {
  customer_id: Scalars['uuid'];
  customer_tag_value: Customer_Tag_Enum;
}


/** mutation root */
export interface Mutation_RootDelete_Customer_Gdpr_InfoArgs {
  customer_id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Customer_Gdpr_InfosArgs {
  where: CustomerGdprInfo_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Customer_SalutationArgs {
  where: Customer_Salutation_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Customer_Salutation_By_PkArgs {
  customer_id: Scalars['uuid'];
  salutation_type_value: Salutation_Type_Enum;
}


/** mutation root */
export interface Mutation_RootDelete_Customer_TagArgs {
  where: Customer_Tag_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Customer_Tag_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Customer_TypeArgs {
  where: Customer_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Customer_Type_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Customer_VehicleArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Customer_VehiclesArgs {
  where: CustomerVehicle_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_CustomersArgs {
  where: Customer_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_VehicleArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_Import_TemplatesArgs {
  where: Dealer_Vehicle_Import_Templates_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_Import_Templates_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_InboxArgs {
  where: DealerVehicleInbox_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_Inbox_Import_TemplatesArgs {
  where: Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_Inbox_Import_Templates_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_NoteArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_NotesArgs {
  where: DealerVehicleNote_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_StateArgs {
  where: Dealer_Vehicle_State_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_State_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_TypeArgs {
  where: Dealer_Vehicle_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_Vehicle_Type_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Dealer_VehiclesArgs {
  where: DealerVehicle_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_DepartmentArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Department_RoleArgs {
  where: Department_Role_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Department_Role_By_PkArgs {
  department_id: Scalars['uuid'];
  role: Role_Enum;
}


/** mutation root */
export interface Mutation_RootDelete_DepartmentsArgs {
  where: Department_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Dms_ReferenceArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Dms_ReferencesArgs {
  where: DmsReference_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Dms_TypeArgs {
  where: Dms_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Dms_Type_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Embedded_PagesArgs {
  where: Embedded_Pages_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Embedded_Pages_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Excel_Import_AssignmentsArgs {
  where: Excel_Import_Assignments_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Excel_Import_Assignments_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Financing_TypeArgs {
  where: Financing_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Financing_Type_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Import_Job_Executable_StatusArgs {
  where: Import_Job_Executable_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Import_Job_Executable_Status_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Import_Job_StatusArgs {
  where: Import_Job_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Import_Job_Status_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Import_Job_Vehicle_ExecutablesArgs {
  where: Import_Job_Vehicle_Executables_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Import_Job_Vehicle_Executables_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Import_JobsArgs {
  where: Import_Jobs_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Import_Jobs_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Import_TypeArgs {
  where: Import_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Import_Type_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_In_Buy_ChannelArgs {
  where: In_Buy_Channel_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_In_Buy_Channel_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_LocationArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Location_Brand_ReferenceArgs {
  brand_id: Scalars['uuid'];
  location_id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Location_Brand_ReferencesArgs {
  where: LocationBrandReference_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Location_GroupArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Location_Group_BrandArgs {
  where: Location_Group_Brand_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Location_Group_Brand_By_PkArgs {
  brand_id: Scalars['uuid'];
  location_group_id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Location_GroupsArgs {
  where: LocationGroup_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_LocationsArgs {
  where: Location_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_ModelArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Model_GroupArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Model_Group_Release_YearsArgs {
  where: Model_Group_Release_Years_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Model_Group_Release_Years_By_PkArgs {
  model_group_id: Scalars['uuid'];
  release_year: Scalars['Int'];
}


/** mutation root */
export interface Mutation_RootDelete_Model_GroupsArgs {
  where: ModelGroup_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_ModelsArgs {
  where: Model_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_NoteArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_NotesArgs {
  where: Notes_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Notification_StatusArgs {
  where: Notification_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Notification_Status_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Notification_TypeArgs {
  where: Notification_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Notification_Type_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Order_StateArgs {
  where: Order_State_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Order_State_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Phone_NumberArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Phone_Number_TypeArgs {
  where: Phone_Number_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Phone_Number_Type_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Phone_NumbersArgs {
  where: PhoneNumber_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Registration_StateArgs {
  where: Registration_State_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Registration_State_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_RoleArgs {
  where: Role_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Role_By_PkArgs {
  role: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Sales_ReportsArgs {
  where: Sales_Reports_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Sales_Reports_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Sales_TypeArgs {
  where: Sales_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Sales_Type_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Salutation_TypeArgs {
  where: Salutation_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Salutation_Type_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Storage_LocationArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Storage_LocationsArgs {
  where: StorageLocation_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_SubdealerArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Subdealer_BrandArgs {
  where: Subdealer_Brand_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Subdealer_Brand_By_PkArgs {
  brand_id: Scalars['uuid'];
  subdealer_id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_SubdealersArgs {
  where: Subdealer_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_SupplierArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_SuppliersArgs {
  where: Supplier_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Sync_ErrorsArgs {
  where: Sync_Errors_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Sync_Errors_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Task_CategoryArgs {
  where: Task_Category_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Task_Category_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Task_TypeArgs {
  code: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Task_Type_Task_CategoryArgs {
  where: Task_Type_Task_Category_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Task_Type_Task_Category_By_PkArgs {
  task_category_value: Task_Category_Enum;
  task_type_code: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Task_TypesArgs {
  where: TaskType_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Team_AssignmentArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Team_AssignmentsArgs {
  where: TeamAssignment_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_TicketArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_Family_NotificationsArgs {
  where: Ticket_Family_Notifications_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_Family_Notifications_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_NoteArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_NotesArgs {
  where: TicketNote_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_PriorityArgs {
  where: Ticket_Priority_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_Priority_By_PkArgs {
  name: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_StateArgs {
  where: Ticket_State_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_State_By_PkArgs {
  name: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_TemplateArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_Template_Assignee_RefArgs {
  where: Ticket_Template_Assignee_Ref_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_Template_Assignee_Ref_By_PkArgs {
  name: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_Template_Location_RefArgs {
  where: Ticket_Template_Location_Ref_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_Template_Location_Ref_By_PkArgs {
  name: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Ticket_TemplatesArgs {
  where: TicketTemplate_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_TicketsArgs {
  where: Ticket_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_TransactionsArgs {
  where: Transactions_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Transactions_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_UserArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_UsersArgs {
  where: User_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_VehicleArgs {
  business_case_number: Scalars['Int'];
  id: Scalars['uuid'];
  version: Scalars['Int'];
}


/** mutation root */
export interface Mutation_RootDelete_Vehicle_BoniArgs {
  where: Vehicle_Boni_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Vehicle_Boni_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Vehicle_Import_TemplatesArgs {
  where: Vehicle_Import_Templates_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Vehicle_Import_Templates_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Vehicle_MetafieldsArgs {
  where: Vehicle_Metafields_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Vehicle_Metafields_By_PkArgs {
  column_name: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Vehicle_NoteArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Vehicle_NotesArgs {
  where: VehicleNote_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Vehicle_Owner_InformationsArgs {
  where: Vehicle_Owner_Informations_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Vehicle_Owner_Informations_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_VehiclesArgs {
  where: Vehicle_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Work_Order_StatusArgs {
  where: Work_Order_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Work_Order_Status_By_PkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface Mutation_RootDelete_Work_Order_Task_CategoryArgs {
  where: Work_Order_Task_Category_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Work_Order_Task_Category_By_PkArgs {
  task_category: Task_Category_Enum;
  work_order_id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootDelete_Work_OrdersArgs {
  where: Work_Orders_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Work_Orders_By_PkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface Mutation_RootExecuteImportJobArgs {
  input: ExecuteImportJobInput;
}


/** mutation root */
export interface Mutation_RootImportDealerVehiclesArgs {
  input: ImportDealerVehiclesInput;
}


/** mutation root */
export interface Mutation_RootInsert_AddressArgs {
  object: Address_Insert_Input;
  on_conflict?: Maybe<Address_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_AddressesArgs {
  objects: Array<Address_Insert_Input>;
  on_conflict?: Maybe<Address_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_After_Sales_PotentialArgs {
  object: AfterSalesPotential_Insert_Input;
  on_conflict?: Maybe<AfterSalesPotential_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_After_Sales_PotentialsArgs {
  objects: Array<AfterSalesPotential_Insert_Input>;
  on_conflict?: Maybe<AfterSalesPotential_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_After_Sales_StatusArgs {
  objects: Array<After_Sales_Status_Insert_Input>;
  on_conflict?: Maybe<After_Sales_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_After_Sales_Status_OneArgs {
  object: After_Sales_Status_Insert_Input;
  on_conflict?: Maybe<After_Sales_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_AppointmentArgs {
  object: Appointment_Insert_Input;
  on_conflict?: Maybe<Appointment_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_AppointmentsArgs {
  objects: Array<Appointment_Insert_Input>;
  on_conflict?: Maybe<Appointment_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Asset_Link_Family_NotificationsArgs {
  objects: Array<Asset_Link_Family_Notifications_Insert_Input>;
  on_conflict?: Maybe<Asset_Link_Family_Notifications_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Asset_Link_Family_Notifications_OneArgs {
  object: Asset_Link_Family_Notifications_Insert_Input;
  on_conflict?: Maybe<Asset_Link_Family_Notifications_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Asset_Watchdog_Link_StatusArgs {
  objects: Array<Asset_Watchdog_Link_Status_Insert_Input>;
  on_conflict?: Maybe<Asset_Watchdog_Link_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Asset_Watchdog_Link_Status_OneArgs {
  object: Asset_Watchdog_Link_Status_Insert_Input;
  on_conflict?: Maybe<Asset_Watchdog_Link_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Asset_Watchdog_LinksArgs {
  objects: Array<Asset_Watchdog_Links_Insert_Input>;
  on_conflict?: Maybe<Asset_Watchdog_Links_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Asset_Watchdog_Links_OneArgs {
  object: Asset_Watchdog_Links_Insert_Input;
  on_conflict?: Maybe<Asset_Watchdog_Links_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_BrandArgs {
  object: Brand_Insert_Input;
  on_conflict?: Maybe<Brand_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Brand_Boni_TypesArgs {
  objects: Array<Brand_Boni_Types_Insert_Input>;
  on_conflict?: Maybe<Brand_Boni_Types_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Brand_Boni_Types_OneArgs {
  object: Brand_Boni_Types_Insert_Input;
  on_conflict?: Maybe<Brand_Boni_Types_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Brand_SupplierArgs {
  object: Brand_Suppliers_Insert_Input;
  on_conflict?: Maybe<Brand_Suppliers_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Brand_SuppliersArgs {
  objects: Array<Brand_Suppliers_Insert_Input>;
  on_conflict?: Maybe<Brand_Suppliers_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Brand_Vehicle_MetafieldArgs {
  objects: Array<Brand_Vehicle_Metafield_Insert_Input>;
  on_conflict?: Maybe<Brand_Vehicle_Metafield_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Brand_Vehicle_Metafield_OneArgs {
  object: Brand_Vehicle_Metafield_Insert_Input;
  on_conflict?: Maybe<Brand_Vehicle_Metafield_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_BrandsArgs {
  objects: Array<Brand_Insert_Input>;
  on_conflict?: Maybe<Brand_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Contact_NoteArgs {
  object: ContactNote_Insert_Input;
  on_conflict?: Maybe<ContactNote_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Contact_Note_TypeArgs {
  objects: Array<Contact_Note_Type_Insert_Input>;
  on_conflict?: Maybe<Contact_Note_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Contact_Note_Type_OneArgs {
  object: Contact_Note_Type_Insert_Input;
  on_conflict?: Maybe<Contact_Note_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Contact_NotesArgs {
  objects: Array<ContactNote_Insert_Input>;
  on_conflict?: Maybe<ContactNote_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_CustomerArgs {
  object: Customer_Insert_Input;
  on_conflict?: Maybe<Customer_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_Customer_TagArgs {
  objects: Array<Customer_Customer_Tag_Insert_Input>;
  on_conflict?: Maybe<Customer_Customer_Tag_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_Customer_Tag_OneArgs {
  object: Customer_Customer_Tag_Insert_Input;
  on_conflict?: Maybe<Customer_Customer_Tag_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_Gdpr_InfoArgs {
  object: CustomerGdprInfo_Insert_Input;
  on_conflict?: Maybe<CustomerGdprInfo_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_Gdpr_InfosArgs {
  objects: Array<CustomerGdprInfo_Insert_Input>;
  on_conflict?: Maybe<CustomerGdprInfo_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_SalutationArgs {
  objects: Array<Customer_Salutation_Insert_Input>;
  on_conflict?: Maybe<Customer_Salutation_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_Salutation_OneArgs {
  object: Customer_Salutation_Insert_Input;
  on_conflict?: Maybe<Customer_Salutation_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_TagArgs {
  objects: Array<Customer_Tag_Insert_Input>;
  on_conflict?: Maybe<Customer_Tag_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_Tag_OneArgs {
  object: Customer_Tag_Insert_Input;
  on_conflict?: Maybe<Customer_Tag_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_TypeArgs {
  objects: Array<Customer_Type_Insert_Input>;
  on_conflict?: Maybe<Customer_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_Type_OneArgs {
  object: Customer_Type_Insert_Input;
  on_conflict?: Maybe<Customer_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_VehicleArgs {
  object: CustomerVehicle_Insert_Input;
  on_conflict?: Maybe<CustomerVehicle_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Customer_VehiclesArgs {
  objects: Array<CustomerVehicle_Insert_Input>;
  on_conflict?: Maybe<CustomerVehicle_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_CustomersArgs {
  objects: Array<Customer_Insert_Input>;
  on_conflict?: Maybe<Customer_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_VehicleArgs {
  object: DealerVehicle_Insert_Input;
  on_conflict?: Maybe<DealerVehicle_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_Import_TemplatesArgs {
  objects: Array<Dealer_Vehicle_Import_Templates_Insert_Input>;
  on_conflict?: Maybe<Dealer_Vehicle_Import_Templates_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_Import_Templates_OneArgs {
  object: Dealer_Vehicle_Import_Templates_Insert_Input;
  on_conflict?: Maybe<Dealer_Vehicle_Import_Templates_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_InboxArgs {
  objects: Array<DealerVehicleInbox_Insert_Input>;
  on_conflict?: Maybe<DealerVehicleInbox_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_Inbox_Import_TemplatesArgs {
  objects: Array<Dealer_Vehicle_Inbox_Import_Templates_Insert_Input>;
  on_conflict?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_Inbox_Import_Templates_OneArgs {
  object: Dealer_Vehicle_Inbox_Import_Templates_Insert_Input;
  on_conflict?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_Inbox_OneArgs {
  object: DealerVehicleInbox_Insert_Input;
  on_conflict?: Maybe<DealerVehicleInbox_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_NoteArgs {
  object: DealerVehicleNote_Insert_Input;
  on_conflict?: Maybe<DealerVehicleNote_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_NotesArgs {
  objects: Array<DealerVehicleNote_Insert_Input>;
  on_conflict?: Maybe<DealerVehicleNote_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_StateArgs {
  objects: Array<Dealer_Vehicle_State_Insert_Input>;
  on_conflict?: Maybe<Dealer_Vehicle_State_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_State_OneArgs {
  object: Dealer_Vehicle_State_Insert_Input;
  on_conflict?: Maybe<Dealer_Vehicle_State_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_TypeArgs {
  objects: Array<Dealer_Vehicle_Type_Insert_Input>;
  on_conflict?: Maybe<Dealer_Vehicle_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_Vehicle_Type_OneArgs {
  object: Dealer_Vehicle_Type_Insert_Input;
  on_conflict?: Maybe<Dealer_Vehicle_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dealer_VehiclesArgs {
  objects: Array<DealerVehicle_Insert_Input>;
  on_conflict?: Maybe<DealerVehicle_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_DepartmentArgs {
  object: Department_Insert_Input;
  on_conflict?: Maybe<Department_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Department_RoleArgs {
  objects: Array<Department_Role_Insert_Input>;
  on_conflict?: Maybe<Department_Role_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Department_Role_OneArgs {
  object: Department_Role_Insert_Input;
  on_conflict?: Maybe<Department_Role_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_DepartmentsArgs {
  objects: Array<Department_Insert_Input>;
  on_conflict?: Maybe<Department_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dms_ReferenceArgs {
  object: DmsReference_Insert_Input;
  on_conflict?: Maybe<DmsReference_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dms_ReferencesArgs {
  objects: Array<DmsReference_Insert_Input>;
  on_conflict?: Maybe<DmsReference_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dms_TypeArgs {
  objects: Array<Dms_Type_Insert_Input>;
  on_conflict?: Maybe<Dms_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Dms_Type_OneArgs {
  object: Dms_Type_Insert_Input;
  on_conflict?: Maybe<Dms_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Embedded_PagesArgs {
  objects: Array<Embedded_Pages_Insert_Input>;
  on_conflict?: Maybe<Embedded_Pages_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Embedded_Pages_OneArgs {
  object: Embedded_Pages_Insert_Input;
  on_conflict?: Maybe<Embedded_Pages_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Excel_Import_AssignmentsArgs {
  objects: Array<Excel_Import_Assignments_Insert_Input>;
  on_conflict?: Maybe<Excel_Import_Assignments_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Excel_Import_Assignments_OneArgs {
  object: Excel_Import_Assignments_Insert_Input;
  on_conflict?: Maybe<Excel_Import_Assignments_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Financing_TypeArgs {
  objects: Array<Financing_Type_Insert_Input>;
  on_conflict?: Maybe<Financing_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Financing_Type_OneArgs {
  object: Financing_Type_Insert_Input;
  on_conflict?: Maybe<Financing_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Import_Job_Executable_StatusArgs {
  objects: Array<Import_Job_Executable_Status_Insert_Input>;
  on_conflict?: Maybe<Import_Job_Executable_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Import_Job_Executable_Status_OneArgs {
  object: Import_Job_Executable_Status_Insert_Input;
  on_conflict?: Maybe<Import_Job_Executable_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Import_Job_StatusArgs {
  objects: Array<Import_Job_Status_Insert_Input>;
  on_conflict?: Maybe<Import_Job_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Import_Job_Status_OneArgs {
  object: Import_Job_Status_Insert_Input;
  on_conflict?: Maybe<Import_Job_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Import_Job_Vehicle_ExecutablesArgs {
  objects: Array<Import_Job_Vehicle_Executables_Insert_Input>;
  on_conflict?: Maybe<Import_Job_Vehicle_Executables_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Import_Job_Vehicle_Executables_OneArgs {
  object: Import_Job_Vehicle_Executables_Insert_Input;
  on_conflict?: Maybe<Import_Job_Vehicle_Executables_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Import_JobsArgs {
  objects: Array<Import_Jobs_Insert_Input>;
  on_conflict?: Maybe<Import_Jobs_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Import_Jobs_OneArgs {
  object: Import_Jobs_Insert_Input;
  on_conflict?: Maybe<Import_Jobs_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Import_TypeArgs {
  objects: Array<Import_Type_Insert_Input>;
  on_conflict?: Maybe<Import_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Import_Type_OneArgs {
  object: Import_Type_Insert_Input;
  on_conflict?: Maybe<Import_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_In_Buy_ChannelArgs {
  objects: Array<In_Buy_Channel_Insert_Input>;
  on_conflict?: Maybe<In_Buy_Channel_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_In_Buy_Channel_OneArgs {
  object: In_Buy_Channel_Insert_Input;
  on_conflict?: Maybe<In_Buy_Channel_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_LocationArgs {
  object: Location_Insert_Input;
  on_conflict?: Maybe<Location_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Location_Brand_ReferenceArgs {
  object: LocationBrandReference_Insert_Input;
  on_conflict?: Maybe<LocationBrandReference_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Location_Brand_ReferencesArgs {
  objects: Array<LocationBrandReference_Insert_Input>;
  on_conflict?: Maybe<LocationBrandReference_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Location_GroupArgs {
  object: LocationGroup_Insert_Input;
  on_conflict?: Maybe<LocationGroup_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Location_Group_BrandArgs {
  objects: Array<Location_Group_Brand_Insert_Input>;
  on_conflict?: Maybe<Location_Group_Brand_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Location_Group_Brand_OneArgs {
  object: Location_Group_Brand_Insert_Input;
  on_conflict?: Maybe<Location_Group_Brand_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Location_GroupsArgs {
  objects: Array<LocationGroup_Insert_Input>;
  on_conflict?: Maybe<LocationGroup_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_LocationsArgs {
  objects: Array<Location_Insert_Input>;
  on_conflict?: Maybe<Location_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_ModelArgs {
  object: Model_Insert_Input;
  on_conflict?: Maybe<Model_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Model_GroupArgs {
  object: ModelGroup_Insert_Input;
  on_conflict?: Maybe<ModelGroup_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Model_Group_Release_YearsArgs {
  objects: Array<Model_Group_Release_Years_Insert_Input>;
  on_conflict?: Maybe<Model_Group_Release_Years_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Model_Group_Release_Years_OneArgs {
  object: Model_Group_Release_Years_Insert_Input;
  on_conflict?: Maybe<Model_Group_Release_Years_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Model_GroupsArgs {
  objects: Array<ModelGroup_Insert_Input>;
  on_conflict?: Maybe<ModelGroup_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_ModelsArgs {
  objects: Array<Model_Insert_Input>;
  on_conflict?: Maybe<Model_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_NoteArgs {
  object: Notes_Insert_Input;
  on_conflict?: Maybe<Notes_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_NotesArgs {
  objects: Array<Notes_Insert_Input>;
  on_conflict?: Maybe<Notes_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Notification_StatusArgs {
  objects: Array<Notification_Status_Insert_Input>;
  on_conflict?: Maybe<Notification_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Notification_Status_OneArgs {
  object: Notification_Status_Insert_Input;
  on_conflict?: Maybe<Notification_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Notification_TypeArgs {
  objects: Array<Notification_Type_Insert_Input>;
  on_conflict?: Maybe<Notification_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Notification_Type_OneArgs {
  object: Notification_Type_Insert_Input;
  on_conflict?: Maybe<Notification_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Order_StateArgs {
  objects: Array<Order_State_Insert_Input>;
  on_conflict?: Maybe<Order_State_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Order_State_OneArgs {
  object: Order_State_Insert_Input;
  on_conflict?: Maybe<Order_State_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Phone_NumberArgs {
  object: PhoneNumber_Insert_Input;
  on_conflict?: Maybe<PhoneNumber_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Phone_Number_TypeArgs {
  objects: Array<Phone_Number_Type_Insert_Input>;
  on_conflict?: Maybe<Phone_Number_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Phone_Number_Type_OneArgs {
  object: Phone_Number_Type_Insert_Input;
  on_conflict?: Maybe<Phone_Number_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Phone_NumbersArgs {
  objects: Array<PhoneNumber_Insert_Input>;
  on_conflict?: Maybe<PhoneNumber_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Registration_StateArgs {
  objects: Array<Registration_State_Insert_Input>;
  on_conflict?: Maybe<Registration_State_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Registration_State_OneArgs {
  object: Registration_State_Insert_Input;
  on_conflict?: Maybe<Registration_State_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_RoleArgs {
  objects: Array<Role_Insert_Input>;
  on_conflict?: Maybe<Role_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Role_OneArgs {
  object: Role_Insert_Input;
  on_conflict?: Maybe<Role_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Sales_ReportsArgs {
  objects: Array<Sales_Reports_Insert_Input>;
  on_conflict?: Maybe<Sales_Reports_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Sales_Reports_OneArgs {
  object: Sales_Reports_Insert_Input;
  on_conflict?: Maybe<Sales_Reports_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Sales_TypeArgs {
  objects: Array<Sales_Type_Insert_Input>;
  on_conflict?: Maybe<Sales_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Sales_Type_OneArgs {
  object: Sales_Type_Insert_Input;
  on_conflict?: Maybe<Sales_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Salutation_TypeArgs {
  objects: Array<Salutation_Type_Insert_Input>;
  on_conflict?: Maybe<Salutation_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Salutation_Type_OneArgs {
  object: Salutation_Type_Insert_Input;
  on_conflict?: Maybe<Salutation_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Storage_LocationArgs {
  object: StorageLocation_Insert_Input;
  on_conflict?: Maybe<StorageLocation_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Storage_LocationsArgs {
  objects: Array<StorageLocation_Insert_Input>;
  on_conflict?: Maybe<StorageLocation_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SubdealerArgs {
  object: Subdealer_Insert_Input;
  on_conflict?: Maybe<Subdealer_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Subdealer_BrandArgs {
  objects: Array<Subdealer_Brand_Insert_Input>;
  on_conflict?: Maybe<Subdealer_Brand_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Subdealer_Brand_OneArgs {
  object: Subdealer_Brand_Insert_Input;
  on_conflict?: Maybe<Subdealer_Brand_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SubdealersArgs {
  objects: Array<Subdealer_Insert_Input>;
  on_conflict?: Maybe<Subdealer_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SupplierArgs {
  object: Supplier_Insert_Input;
  on_conflict?: Maybe<Supplier_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SuppliersArgs {
  objects: Array<Supplier_Insert_Input>;
  on_conflict?: Maybe<Supplier_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Sync_ErrorsArgs {
  objects: Array<Sync_Errors_Insert_Input>;
  on_conflict?: Maybe<Sync_Errors_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Sync_Errors_OneArgs {
  object: Sync_Errors_Insert_Input;
  on_conflict?: Maybe<Sync_Errors_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Task_CategoryArgs {
  objects: Array<Task_Category_Insert_Input>;
  on_conflict?: Maybe<Task_Category_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Task_Category_OneArgs {
  object: Task_Category_Insert_Input;
  on_conflict?: Maybe<Task_Category_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Task_TypeArgs {
  object: TaskType_Insert_Input;
  on_conflict?: Maybe<TaskType_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Task_Type_Task_CategoryArgs {
  objects: Array<Task_Type_Task_Category_Insert_Input>;
  on_conflict?: Maybe<Task_Type_Task_Category_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Task_Type_Task_Category_OneArgs {
  object: Task_Type_Task_Category_Insert_Input;
  on_conflict?: Maybe<Task_Type_Task_Category_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Task_TypesArgs {
  objects: Array<TaskType_Insert_Input>;
  on_conflict?: Maybe<TaskType_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Team_AssignmentArgs {
  object: TeamAssignment_Insert_Input;
  on_conflict?: Maybe<TeamAssignment_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Team_AssignmentsArgs {
  objects: Array<TeamAssignment_Insert_Input>;
  on_conflict?: Maybe<TeamAssignment_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_TicketArgs {
  object: Ticket_Insert_Input;
  on_conflict?: Maybe<Ticket_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_Family_NotificationsArgs {
  objects: Array<Ticket_Family_Notifications_Insert_Input>;
  on_conflict?: Maybe<Ticket_Family_Notifications_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_Family_Notifications_OneArgs {
  object: Ticket_Family_Notifications_Insert_Input;
  on_conflict?: Maybe<Ticket_Family_Notifications_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_NoteArgs {
  object: TicketNote_Insert_Input;
  on_conflict?: Maybe<TicketNote_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_NotesArgs {
  objects: Array<TicketNote_Insert_Input>;
  on_conflict?: Maybe<TicketNote_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_PriorityArgs {
  objects: Array<Ticket_Priority_Insert_Input>;
  on_conflict?: Maybe<Ticket_Priority_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_Priority_OneArgs {
  object: Ticket_Priority_Insert_Input;
  on_conflict?: Maybe<Ticket_Priority_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_StateArgs {
  objects: Array<Ticket_State_Insert_Input>;
  on_conflict?: Maybe<Ticket_State_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_State_OneArgs {
  object: Ticket_State_Insert_Input;
  on_conflict?: Maybe<Ticket_State_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_TemplateArgs {
  object: TicketTemplate_Insert_Input;
  on_conflict?: Maybe<TicketTemplate_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_Template_Assignee_RefArgs {
  objects: Array<Ticket_Template_Assignee_Ref_Insert_Input>;
  on_conflict?: Maybe<Ticket_Template_Assignee_Ref_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_Template_Assignee_Ref_OneArgs {
  object: Ticket_Template_Assignee_Ref_Insert_Input;
  on_conflict?: Maybe<Ticket_Template_Assignee_Ref_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_Template_Location_RefArgs {
  objects: Array<Ticket_Template_Location_Ref_Insert_Input>;
  on_conflict?: Maybe<Ticket_Template_Location_Ref_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_Template_Location_Ref_OneArgs {
  object: Ticket_Template_Location_Ref_Insert_Input;
  on_conflict?: Maybe<Ticket_Template_Location_Ref_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Ticket_TemplatesArgs {
  objects: Array<TicketTemplate_Insert_Input>;
  on_conflict?: Maybe<TicketTemplate_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_TicketsArgs {
  objects: Array<Ticket_Insert_Input>;
  on_conflict?: Maybe<Ticket_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_TransactionsArgs {
  objects: Array<Transactions_Insert_Input>;
  on_conflict?: Maybe<Transactions_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Transactions_OneArgs {
  object: Transactions_Insert_Input;
  on_conflict?: Maybe<Transactions_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_UserArgs {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_UsersArgs {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_VehicleArgs {
  object: Vehicle_Insert_Input;
  on_conflict?: Maybe<Vehicle_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Vehicle_BoniArgs {
  objects: Array<Vehicle_Boni_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Boni_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Vehicle_Boni_OneArgs {
  object: Vehicle_Boni_Insert_Input;
  on_conflict?: Maybe<Vehicle_Boni_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Vehicle_Import_TemplatesArgs {
  objects: Array<Vehicle_Import_Templates_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Import_Templates_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Vehicle_Import_Templates_OneArgs {
  object: Vehicle_Import_Templates_Insert_Input;
  on_conflict?: Maybe<Vehicle_Import_Templates_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Vehicle_MetafieldsArgs {
  objects: Array<Vehicle_Metafields_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Metafields_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Vehicle_Metafields_OneArgs {
  object: Vehicle_Metafields_Insert_Input;
  on_conflict?: Maybe<Vehicle_Metafields_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Vehicle_NoteArgs {
  object: VehicleNote_Insert_Input;
  on_conflict?: Maybe<VehicleNote_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Vehicle_NotesArgs {
  objects: Array<VehicleNote_Insert_Input>;
  on_conflict?: Maybe<VehicleNote_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Vehicle_Owner_InformationsArgs {
  objects: Array<Vehicle_Owner_Informations_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Owner_Informations_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Vehicle_Owner_Informations_OneArgs {
  object: Vehicle_Owner_Informations_Insert_Input;
  on_conflict?: Maybe<Vehicle_Owner_Informations_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_VehiclesArgs {
  objects: Array<Vehicle_Insert_Input>;
  on_conflict?: Maybe<Vehicle_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Work_Order_StatusArgs {
  objects: Array<Work_Order_Status_Insert_Input>;
  on_conflict?: Maybe<Work_Order_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Work_Order_Status_OneArgs {
  object: Work_Order_Status_Insert_Input;
  on_conflict?: Maybe<Work_Order_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Work_Order_Task_CategoryArgs {
  objects: Array<Work_Order_Task_Category_Insert_Input>;
  on_conflict?: Maybe<Work_Order_Task_Category_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Work_Order_Task_Category_OneArgs {
  object: Work_Order_Task_Category_Insert_Input;
  on_conflict?: Maybe<Work_Order_Task_Category_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Work_OrdersArgs {
  objects: Array<Work_Orders_Insert_Input>;
  on_conflict?: Maybe<Work_Orders_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Work_Orders_OneArgs {
  object: Work_Orders_Insert_Input;
  on_conflict?: Maybe<Work_Orders_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootPreviewImportJobArgs {
  input: PreviewImportJobInput;
}


/** mutation root */
export interface Mutation_RootRecoverVehiclesHistoriesArgs {
  input: RecoverVehiclesHistoriesInput;
}


/** mutation root */
export interface Mutation_RootResolveVehicleSyncErrorArgs {
  input: ResolveVehicleSyncErrorInput;
}


/** mutation root */
export interface Mutation_RootToggleBrandForDealerVehicleSyncArgs {
  input: ToggleBrandForDealerVehicleSyncInput;
}


/** mutation root */
export interface Mutation_RootUpadte_Customer_VehiclesArgs {
  _inc?: Maybe<CustomerVehicle_Inc_Input>;
  _set?: Maybe<CustomerVehicle_Set_Input>;
  where: CustomerVehicle_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Address_ManyArgs {
  updates: Array<Address_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_AfterSalesPotential_ManyArgs {
  updates: Array<AfterSalesPotential_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Appointment_ManyArgs {
  updates: Array<Appointment_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Brand_ManyArgs {
  updates: Array<Brand_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_ContactNote_ManyArgs {
  updates: Array<ContactNote_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_CustomerGdprInfo_ManyArgs {
  updates: Array<CustomerGdprInfo_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_CustomerVehicle_ManyArgs {
  updates: Array<CustomerVehicle_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_ManyArgs {
  updates: Array<Customer_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_DealerVehicleInbox_ManyArgs {
  updates: Array<DealerVehicleInbox_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_DealerVehicleNote_ManyArgs {
  updates: Array<DealerVehicleNote_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_DealerVehicle_ManyArgs {
  updates: Array<DealerVehicle_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Department_ManyArgs {
  updates: Array<Department_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_DmsReference_ManyArgs {
  updates: Array<DmsReference_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_LocationBrandReference_ManyArgs {
  updates: Array<LocationBrandReference_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_LocationGroup_ManyArgs {
  updates: Array<LocationGroup_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Location_ManyArgs {
  updates: Array<Location_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_ModelGroup_ManyArgs {
  updates: Array<ModelGroup_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Model_ManyArgs {
  updates: Array<Model_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_PhoneNumber_ManyArgs {
  updates: Array<PhoneNumber_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_StorageLocation_ManyArgs {
  updates: Array<StorageLocation_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Subdealer_ManyArgs {
  updates: Array<Subdealer_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Supplier_ManyArgs {
  updates: Array<Supplier_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_TaskType_ManyArgs {
  updates: Array<TaskType_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_TeamAssignment_ManyArgs {
  updates: Array<TeamAssignment_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_TicketNote_ManyArgs {
  updates: Array<TicketNote_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_TicketTemplate_ManyArgs {
  updates: Array<TicketTemplate_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_ManyArgs {
  updates: Array<Ticket_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_User_ManyArgs {
  updates: Array<User_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_VehicleNote_ManyArgs {
  updates: Array<VehicleNote_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_ManyArgs {
  updates: Array<Vehicle_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_AddressArgs {
  _set?: Maybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_AddressesArgs {
  _set?: Maybe<Address_Set_Input>;
  where: Address_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_After_Sales_PotentialArgs {
  _inc?: Maybe<AfterSalesPotential_Inc_Input>;
  _set?: Maybe<AfterSalesPotential_Set_Input>;
  pk_columns: AfterSalesPotential_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_After_Sales_PotentialsArgs {
  _inc?: Maybe<AfterSalesPotential_Inc_Input>;
  _set?: Maybe<AfterSalesPotential_Set_Input>;
  where: AfterSalesPotential_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_After_Sales_StatusArgs {
  _set?: Maybe<After_Sales_Status_Set_Input>;
  where: After_Sales_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_After_Sales_Status_By_PkArgs {
  _set?: Maybe<After_Sales_Status_Set_Input>;
  pk_columns: After_Sales_Status_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_After_Sales_Status_ManyArgs {
  updates: Array<After_Sales_Status_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_AppointmentArgs {
  _inc?: Maybe<Appointment_Inc_Input>;
  _set?: Maybe<Appointment_Set_Input>;
  pk_columns: Appointment_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_AppointmentsArgs {
  _inc?: Maybe<Appointment_Inc_Input>;
  _set?: Maybe<Appointment_Set_Input>;
  where: Appointment_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Asset_Link_Family_NotificationsArgs {
  _set?: Maybe<Asset_Link_Family_Notifications_Set_Input>;
  where: Asset_Link_Family_Notifications_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Asset_Link_Family_Notifications_By_PkArgs {
  _set?: Maybe<Asset_Link_Family_Notifications_Set_Input>;
  pk_columns: Asset_Link_Family_Notifications_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Asset_Link_Family_Notifications_ManyArgs {
  updates: Array<Asset_Link_Family_Notifications_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Asset_Watchdog_Link_StatusArgs {
  _set?: Maybe<Asset_Watchdog_Link_Status_Set_Input>;
  where: Asset_Watchdog_Link_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Asset_Watchdog_Link_Status_By_PkArgs {
  _set?: Maybe<Asset_Watchdog_Link_Status_Set_Input>;
  pk_columns: Asset_Watchdog_Link_Status_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Asset_Watchdog_Link_Status_ManyArgs {
  updates: Array<Asset_Watchdog_Link_Status_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Asset_Watchdog_LinksArgs {
  _set?: Maybe<Asset_Watchdog_Links_Set_Input>;
  where: Asset_Watchdog_Links_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Asset_Watchdog_Links_By_PkArgs {
  _set?: Maybe<Asset_Watchdog_Links_Set_Input>;
  pk_columns: Asset_Watchdog_Links_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Asset_Watchdog_Links_ManyArgs {
  updates: Array<Asset_Watchdog_Links_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_BrandArgs {
  _set?: Maybe<Brand_Set_Input>;
  pk_columns: Brand_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Brand_Boni_TypesArgs {
  _set?: Maybe<Brand_Boni_Types_Set_Input>;
  where: Brand_Boni_Types_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Brand_Boni_Types_By_PkArgs {
  _set?: Maybe<Brand_Boni_Types_Set_Input>;
  pk_columns: Brand_Boni_Types_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Brand_Boni_Types_ManyArgs {
  updates: Array<Brand_Boni_Types_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Brand_SupplierArgs {
  _set?: Maybe<Brand_Suppliers_Set_Input>;
  pk_columns: Brand_Suppliers_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Brand_SuppliersArgs {
  _set?: Maybe<Brand_Suppliers_Set_Input>;
  where: Brand_Suppliers_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Brand_Suppliers_ManyArgs {
  updates: Array<Brand_Suppliers_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Brand_Vehicle_MetafieldArgs {
  _set?: Maybe<Brand_Vehicle_Metafield_Set_Input>;
  where: Brand_Vehicle_Metafield_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Brand_Vehicle_Metafield_By_PkArgs {
  _set?: Maybe<Brand_Vehicle_Metafield_Set_Input>;
  pk_columns: Brand_Vehicle_Metafield_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Brand_Vehicle_Metafield_ManyArgs {
  updates: Array<Brand_Vehicle_Metafield_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_BrandsArgs {
  _set?: Maybe<Brand_Set_Input>;
  where: Brand_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_By_Pk_Dealer_Vehicle_InboxArgs {
  _inc?: Maybe<DealerVehicleInbox_Inc_Input>;
  _set?: Maybe<DealerVehicleInbox_Set_Input>;
  pk_columns: DealerVehicleInbox_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Contact_NoteArgs {
  _inc?: Maybe<ContactNote_Inc_Input>;
  _set?: Maybe<ContactNote_Set_Input>;
  pk_columns: ContactNote_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Contact_Note_TypeArgs {
  _set?: Maybe<Contact_Note_Type_Set_Input>;
  where: Contact_Note_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Contact_Note_Type_By_PkArgs {
  _set?: Maybe<Contact_Note_Type_Set_Input>;
  pk_columns: Contact_Note_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Contact_Note_Type_ManyArgs {
  updates: Array<Contact_Note_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Contact_NotesArgs {
  _inc?: Maybe<ContactNote_Inc_Input>;
  _set?: Maybe<ContactNote_Set_Input>;
  where: ContactNote_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_CustomerArgs {
  _set?: Maybe<Customer_Set_Input>;
  pk_columns: Customer_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Customer_TagArgs {
  _set?: Maybe<Customer_Customer_Tag_Set_Input>;
  where: Customer_Customer_Tag_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Customer_Tag_By_PkArgs {
  _set?: Maybe<Customer_Customer_Tag_Set_Input>;
  pk_columns: Customer_Customer_Tag_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Customer_Tag_ManyArgs {
  updates: Array<Customer_Customer_Tag_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Gdpr_InfoArgs {
  _set?: Maybe<CustomerGdprInfo_Set_Input>;
  pk_columns: CustomerGdprInfo_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Gdpr_InfosArgs {
  _set?: Maybe<CustomerGdprInfo_Set_Input>;
  where: CustomerGdprInfo_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_SalutationArgs {
  _set?: Maybe<Customer_Salutation_Set_Input>;
  where: Customer_Salutation_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Salutation_By_PkArgs {
  _set?: Maybe<Customer_Salutation_Set_Input>;
  pk_columns: Customer_Salutation_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Salutation_ManyArgs {
  updates: Array<Customer_Salutation_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_TagArgs {
  _set?: Maybe<Customer_Tag_Set_Input>;
  where: Customer_Tag_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Tag_By_PkArgs {
  _set?: Maybe<Customer_Tag_Set_Input>;
  pk_columns: Customer_Tag_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Tag_ManyArgs {
  updates: Array<Customer_Tag_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_TypeArgs {
  _set?: Maybe<Customer_Type_Set_Input>;
  where: Customer_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Type_By_PkArgs {
  _set?: Maybe<Customer_Type_Set_Input>;
  pk_columns: Customer_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_Type_ManyArgs {
  updates: Array<Customer_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Customer_VehicleArgs {
  _inc?: Maybe<CustomerVehicle_Inc_Input>;
  _set?: Maybe<CustomerVehicle_Set_Input>;
  pk_columns: CustomerVehicle_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_CustomersArgs {
  _set?: Maybe<Customer_Set_Input>;
  where: Customer_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_VehicleArgs {
  _inc?: Maybe<DealerVehicle_Inc_Input>;
  _set?: Maybe<DealerVehicle_Set_Input>;
  pk_columns: DealerVehicle_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_Import_TemplatesArgs {
  _set?: Maybe<Dealer_Vehicle_Import_Templates_Set_Input>;
  where: Dealer_Vehicle_Import_Templates_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_Import_Templates_By_PkArgs {
  _set?: Maybe<Dealer_Vehicle_Import_Templates_Set_Input>;
  pk_columns: Dealer_Vehicle_Import_Templates_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_Import_Templates_ManyArgs {
  updates: Array<Dealer_Vehicle_Import_Templates_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_InboxArgs {
  _inc?: Maybe<DealerVehicleInbox_Inc_Input>;
  _set?: Maybe<DealerVehicleInbox_Set_Input>;
  where: DealerVehicleInbox_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_Inbox_Import_TemplatesArgs {
  _set?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Set_Input>;
  where: Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_Inbox_Import_Templates_By_PkArgs {
  _set?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Set_Input>;
  pk_columns: Dealer_Vehicle_Inbox_Import_Templates_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_Inbox_Import_Templates_ManyArgs {
  updates: Array<Dealer_Vehicle_Inbox_Import_Templates_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_NoteArgs {
  _set?: Maybe<DealerVehicleNote_Set_Input>;
  pk_columns: DealerVehicleNote_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_NotesArgs {
  _set?: Maybe<DealerVehicleNote_Set_Input>;
  where: DealerVehicleNote_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_StateArgs {
  _set?: Maybe<Dealer_Vehicle_State_Set_Input>;
  where: Dealer_Vehicle_State_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_State_By_PkArgs {
  _set?: Maybe<Dealer_Vehicle_State_Set_Input>;
  pk_columns: Dealer_Vehicle_State_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_State_ManyArgs {
  updates: Array<Dealer_Vehicle_State_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_TypeArgs {
  _set?: Maybe<Dealer_Vehicle_Type_Set_Input>;
  where: Dealer_Vehicle_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_Type_By_PkArgs {
  _set?: Maybe<Dealer_Vehicle_Type_Set_Input>;
  pk_columns: Dealer_Vehicle_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_Vehicle_Type_ManyArgs {
  updates: Array<Dealer_Vehicle_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Dealer_VehiclesArgs {
  _inc?: Maybe<DealerVehicle_Inc_Input>;
  _set?: Maybe<DealerVehicle_Set_Input>;
  where: DealerVehicle_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_DepartmentArgs {
  _set?: Maybe<Department_Set_Input>;
  pk_columns: Department_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Department_RoleArgs {
  _set?: Maybe<Department_Role_Set_Input>;
  where: Department_Role_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Department_Role_By_PkArgs {
  _set?: Maybe<Department_Role_Set_Input>;
  pk_columns: Department_Role_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Department_Role_ManyArgs {
  updates: Array<Department_Role_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_DepartmentsArgs {
  _set?: Maybe<Department_Set_Input>;
  where: Department_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Dms_ReferenceArgs {
  _set?: Maybe<DmsReference_Set_Input>;
  pk_columns: DmsReference_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Dms_ReferencesArgs {
  _set?: Maybe<DmsReference_Set_Input>;
  where: DmsReference_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Dms_TypeArgs {
  _set?: Maybe<Dms_Type_Set_Input>;
  where: Dms_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Dms_Type_By_PkArgs {
  _set?: Maybe<Dms_Type_Set_Input>;
  pk_columns: Dms_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Dms_Type_ManyArgs {
  updates: Array<Dms_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Embedded_PagesArgs {
  _set?: Maybe<Embedded_Pages_Set_Input>;
  where: Embedded_Pages_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Embedded_Pages_By_PkArgs {
  _set?: Maybe<Embedded_Pages_Set_Input>;
  pk_columns: Embedded_Pages_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Embedded_Pages_ManyArgs {
  updates: Array<Embedded_Pages_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Excel_Import_AssignmentsArgs {
  _set?: Maybe<Excel_Import_Assignments_Set_Input>;
  where: Excel_Import_Assignments_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Excel_Import_Assignments_By_PkArgs {
  _set?: Maybe<Excel_Import_Assignments_Set_Input>;
  pk_columns: Excel_Import_Assignments_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Excel_Import_Assignments_ManyArgs {
  updates: Array<Excel_Import_Assignments_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Financing_TypeArgs {
  _set?: Maybe<Financing_Type_Set_Input>;
  where: Financing_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Financing_Type_By_PkArgs {
  _set?: Maybe<Financing_Type_Set_Input>;
  pk_columns: Financing_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Financing_Type_ManyArgs {
  updates: Array<Financing_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Job_Executable_StatusArgs {
  _set?: Maybe<Import_Job_Executable_Status_Set_Input>;
  where: Import_Job_Executable_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Job_Executable_Status_By_PkArgs {
  _set?: Maybe<Import_Job_Executable_Status_Set_Input>;
  pk_columns: Import_Job_Executable_Status_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Job_Executable_Status_ManyArgs {
  updates: Array<Import_Job_Executable_Status_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Job_StatusArgs {
  _set?: Maybe<Import_Job_Status_Set_Input>;
  where: Import_Job_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Job_Status_By_PkArgs {
  _set?: Maybe<Import_Job_Status_Set_Input>;
  pk_columns: Import_Job_Status_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Job_Status_ManyArgs {
  updates: Array<Import_Job_Status_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Job_Vehicle_ExecutablesArgs {
  _inc?: Maybe<Import_Job_Vehicle_Executables_Inc_Input>;
  _set?: Maybe<Import_Job_Vehicle_Executables_Set_Input>;
  where: Import_Job_Vehicle_Executables_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Job_Vehicle_Executables_By_PkArgs {
  _inc?: Maybe<Import_Job_Vehicle_Executables_Inc_Input>;
  _set?: Maybe<Import_Job_Vehicle_Executables_Set_Input>;
  pk_columns: Import_Job_Vehicle_Executables_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Job_Vehicle_Executables_ManyArgs {
  updates: Array<Import_Job_Vehicle_Executables_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_JobsArgs {
  _set?: Maybe<Import_Jobs_Set_Input>;
  where: Import_Jobs_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Jobs_By_PkArgs {
  _set?: Maybe<Import_Jobs_Set_Input>;
  pk_columns: Import_Jobs_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Jobs_ManyArgs {
  updates: Array<Import_Jobs_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_TypeArgs {
  _set?: Maybe<Import_Type_Set_Input>;
  where: Import_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Type_By_PkArgs {
  _set?: Maybe<Import_Type_Set_Input>;
  pk_columns: Import_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Import_Type_ManyArgs {
  updates: Array<Import_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_In_Buy_ChannelArgs {
  _set?: Maybe<In_Buy_Channel_Set_Input>;
  where: In_Buy_Channel_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_In_Buy_Channel_By_PkArgs {
  _set?: Maybe<In_Buy_Channel_Set_Input>;
  pk_columns: In_Buy_Channel_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_In_Buy_Channel_ManyArgs {
  updates: Array<In_Buy_Channel_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_LocationArgs {
  _set?: Maybe<Location_Set_Input>;
  pk_columns: Location_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Location_Brand_ReferenceArgs {
  _set?: Maybe<LocationBrandReference_Set_Input>;
  pk_columns: LocationBrandReference_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Location_Brand_ReferencesArgs {
  _set?: Maybe<LocationBrandReference_Set_Input>;
  where: LocationBrandReference_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Location_GroupArgs {
  _set?: Maybe<LocationGroup_Set_Input>;
  pk_columns: LocationGroup_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Location_Group_BrandArgs {
  _set?: Maybe<Location_Group_Brand_Set_Input>;
  where: Location_Group_Brand_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Location_Group_Brand_By_PkArgs {
  _set?: Maybe<Location_Group_Brand_Set_Input>;
  pk_columns: Location_Group_Brand_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Location_Group_Brand_ManyArgs {
  updates: Array<Location_Group_Brand_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Location_GroupsArgs {
  _set?: Maybe<LocationGroup_Set_Input>;
  where: LocationGroup_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_LocationsArgs {
  _set?: Maybe<Location_Set_Input>;
  where: Location_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Many_NotesArgs {
  updates: Array<Notes_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_ModelArgs {
  _inc?: Maybe<Model_Inc_Input>;
  _set?: Maybe<Model_Set_Input>;
  pk_columns: Model_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Model_GroupArgs {
  _set?: Maybe<ModelGroup_Set_Input>;
  pk_columns: ModelGroup_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Model_Group_Release_YearsArgs {
  _inc?: Maybe<Model_Group_Release_Years_Inc_Input>;
  _set?: Maybe<Model_Group_Release_Years_Set_Input>;
  where: Model_Group_Release_Years_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Model_Group_Release_Years_By_PkArgs {
  _inc?: Maybe<Model_Group_Release_Years_Inc_Input>;
  _set?: Maybe<Model_Group_Release_Years_Set_Input>;
  pk_columns: Model_Group_Release_Years_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Model_Group_Release_Years_ManyArgs {
  updates: Array<Model_Group_Release_Years_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Model_GroupsArgs {
  _set?: Maybe<ModelGroup_Set_Input>;
  where: ModelGroup_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_ModelsArgs {
  _inc?: Maybe<Model_Inc_Input>;
  _set?: Maybe<Model_Set_Input>;
  where: Model_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_NoteArgs {
  _inc?: Maybe<Notes_Inc_Input>;
  _set?: Maybe<Notes_Set_Input>;
  pk_columns: Notes_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_NotesArgs {
  _inc?: Maybe<Notes_Inc_Input>;
  _set?: Maybe<Notes_Set_Input>;
  where: Notes_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Notification_StatusArgs {
  _set?: Maybe<Notification_Status_Set_Input>;
  where: Notification_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Notification_Status_By_PkArgs {
  _set?: Maybe<Notification_Status_Set_Input>;
  pk_columns: Notification_Status_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Notification_Status_ManyArgs {
  updates: Array<Notification_Status_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Notification_TypeArgs {
  _set?: Maybe<Notification_Type_Set_Input>;
  where: Notification_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Notification_Type_By_PkArgs {
  _set?: Maybe<Notification_Type_Set_Input>;
  pk_columns: Notification_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Notification_Type_ManyArgs {
  updates: Array<Notification_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Order_StateArgs {
  _set?: Maybe<Order_State_Set_Input>;
  where: Order_State_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Order_State_By_PkArgs {
  _set?: Maybe<Order_State_Set_Input>;
  pk_columns: Order_State_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Order_State_ManyArgs {
  updates: Array<Order_State_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Phone_NumberArgs {
  _set?: Maybe<PhoneNumber_Set_Input>;
  pk_columns: PhoneNumber_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Phone_Number_TypeArgs {
  _set?: Maybe<Phone_Number_Type_Set_Input>;
  where: Phone_Number_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Phone_Number_Type_By_PkArgs {
  _set?: Maybe<Phone_Number_Type_Set_Input>;
  pk_columns: Phone_Number_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Phone_Number_Type_ManyArgs {
  updates: Array<Phone_Number_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Phone_NumbersArgs {
  _set?: Maybe<PhoneNumber_Set_Input>;
  where: PhoneNumber_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Registration_StateArgs {
  _set?: Maybe<Registration_State_Set_Input>;
  where: Registration_State_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Registration_State_By_PkArgs {
  _set?: Maybe<Registration_State_Set_Input>;
  pk_columns: Registration_State_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Registration_State_ManyArgs {
  updates: Array<Registration_State_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_RoleArgs {
  _set?: Maybe<Role_Set_Input>;
  where: Role_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Role_By_PkArgs {
  _set?: Maybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Role_ManyArgs {
  updates: Array<Role_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Sales_ReportsArgs {
  _append?: Maybe<Sales_Reports_Append_Input>;
  _delete_at_path?: Maybe<Sales_Reports_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sales_Reports_Delete_Elem_Input>;
  _delete_key?: Maybe<Sales_Reports_Delete_Key_Input>;
  _inc?: Maybe<Sales_Reports_Inc_Input>;
  _prepend?: Maybe<Sales_Reports_Prepend_Input>;
  _set?: Maybe<Sales_Reports_Set_Input>;
  where: Sales_Reports_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Sales_Reports_By_PkArgs {
  _append?: Maybe<Sales_Reports_Append_Input>;
  _delete_at_path?: Maybe<Sales_Reports_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sales_Reports_Delete_Elem_Input>;
  _delete_key?: Maybe<Sales_Reports_Delete_Key_Input>;
  _inc?: Maybe<Sales_Reports_Inc_Input>;
  _prepend?: Maybe<Sales_Reports_Prepend_Input>;
  _set?: Maybe<Sales_Reports_Set_Input>;
  pk_columns: Sales_Reports_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Sales_Reports_ManyArgs {
  updates: Array<Sales_Reports_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Sales_TypeArgs {
  _set?: Maybe<Sales_Type_Set_Input>;
  where: Sales_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Sales_Type_By_PkArgs {
  _set?: Maybe<Sales_Type_Set_Input>;
  pk_columns: Sales_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Sales_Type_ManyArgs {
  updates: Array<Sales_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Salutation_TypeArgs {
  _set?: Maybe<Salutation_Type_Set_Input>;
  where: Salutation_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Salutation_Type_By_PkArgs {
  _set?: Maybe<Salutation_Type_Set_Input>;
  pk_columns: Salutation_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Salutation_Type_ManyArgs {
  updates: Array<Salutation_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Storage_LocationArgs {
  _set?: Maybe<StorageLocation_Set_Input>;
  pk_columns: StorageLocation_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Storage_LocationsArgs {
  _set?: Maybe<StorageLocation_Set_Input>;
  where: StorageLocation_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_SubdealerArgs {
  _set?: Maybe<Subdealer_Set_Input>;
  pk_columns: Subdealer_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Subdealer_BrandArgs {
  _set?: Maybe<Subdealer_Brand_Set_Input>;
  where: Subdealer_Brand_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Subdealer_Brand_By_PkArgs {
  _set?: Maybe<Subdealer_Brand_Set_Input>;
  pk_columns: Subdealer_Brand_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Subdealer_Brand_ManyArgs {
  updates: Array<Subdealer_Brand_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_SubdealersArgs {
  _set?: Maybe<Subdealer_Set_Input>;
  where: Subdealer_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_SupplierArgs {
  _set?: Maybe<Supplier_Set_Input>;
  pk_columns: Supplier_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_SuppliersArgs {
  _set?: Maybe<Supplier_Set_Input>;
  where: Supplier_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Sync_ErrorsArgs {
  _inc?: Maybe<Sync_Errors_Inc_Input>;
  _set?: Maybe<Sync_Errors_Set_Input>;
  where: Sync_Errors_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Sync_Errors_By_PkArgs {
  _inc?: Maybe<Sync_Errors_Inc_Input>;
  _set?: Maybe<Sync_Errors_Set_Input>;
  pk_columns: Sync_Errors_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Sync_Errors_ManyArgs {
  updates: Array<Sync_Errors_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Task_CategoryArgs {
  _set?: Maybe<Task_Category_Set_Input>;
  where: Task_Category_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Task_Category_By_PkArgs {
  _set?: Maybe<Task_Category_Set_Input>;
  pk_columns: Task_Category_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Task_Category_ManyArgs {
  updates: Array<Task_Category_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Task_TypeArgs {
  _set?: Maybe<TaskType_Set_Input>;
  pk_columns: TaskType_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Task_Type_Task_CategoryArgs {
  _set?: Maybe<Task_Type_Task_Category_Set_Input>;
  where: Task_Type_Task_Category_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Task_Type_Task_Category_By_PkArgs {
  _set?: Maybe<Task_Type_Task_Category_Set_Input>;
  pk_columns: Task_Type_Task_Category_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Task_Type_Task_Category_ManyArgs {
  updates: Array<Task_Type_Task_Category_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Task_TypesArgs {
  _set?: Maybe<TaskType_Set_Input>;
  where: TaskType_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Team_AssignmentArgs {
  _set?: Maybe<TeamAssignment_Set_Input>;
  pk_columns: TeamAssignment_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Team_AssignmentsArgs {
  _set?: Maybe<TeamAssignment_Set_Input>;
  where: TeamAssignment_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_TicketArgs {
  _inc?: Maybe<Ticket_Inc_Input>;
  _set?: Maybe<Ticket_Set_Input>;
  pk_columns: Ticket_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Family_NotificationsArgs {
  _set?: Maybe<Ticket_Family_Notifications_Set_Input>;
  where: Ticket_Family_Notifications_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Family_Notifications_By_PkArgs {
  _set?: Maybe<Ticket_Family_Notifications_Set_Input>;
  pk_columns: Ticket_Family_Notifications_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Family_Notifications_ManyArgs {
  updates: Array<Ticket_Family_Notifications_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_NoteArgs {
  _set?: Maybe<TicketNote_Set_Input>;
  pk_columns: TicketNote_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_NotesArgs {
  _set?: Maybe<TicketNote_Set_Input>;
  where: TicketNote_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_PriorityArgs {
  _set?: Maybe<Ticket_Priority_Set_Input>;
  where: Ticket_Priority_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Priority_By_PkArgs {
  _set?: Maybe<Ticket_Priority_Set_Input>;
  pk_columns: Ticket_Priority_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Priority_ManyArgs {
  updates: Array<Ticket_Priority_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_StateArgs {
  _set?: Maybe<Ticket_State_Set_Input>;
  where: Ticket_State_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_State_By_PkArgs {
  _set?: Maybe<Ticket_State_Set_Input>;
  pk_columns: Ticket_State_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_State_ManyArgs {
  updates: Array<Ticket_State_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_TemplateArgs {
  _set?: Maybe<TicketTemplate_Set_Input>;
  pk_columns: TicketTemplate_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Template_Assignee_RefArgs {
  _set?: Maybe<Ticket_Template_Assignee_Ref_Set_Input>;
  where: Ticket_Template_Assignee_Ref_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Template_Assignee_Ref_By_PkArgs {
  _set?: Maybe<Ticket_Template_Assignee_Ref_Set_Input>;
  pk_columns: Ticket_Template_Assignee_Ref_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Template_Assignee_Ref_ManyArgs {
  updates: Array<Ticket_Template_Assignee_Ref_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Template_Location_RefArgs {
  _set?: Maybe<Ticket_Template_Location_Ref_Set_Input>;
  where: Ticket_Template_Location_Ref_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Template_Location_Ref_By_PkArgs {
  _set?: Maybe<Ticket_Template_Location_Ref_Set_Input>;
  pk_columns: Ticket_Template_Location_Ref_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_Template_Location_Ref_ManyArgs {
  updates: Array<Ticket_Template_Location_Ref_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Ticket_TemplatesArgs {
  _set?: Maybe<TicketTemplate_Set_Input>;
  where: TicketTemplate_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_TicketsArgs {
  _inc?: Maybe<Ticket_Inc_Input>;
  _set?: Maybe<Ticket_Set_Input>;
  where: Ticket_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_TransactionsArgs {
  _inc?: Maybe<Transactions_Inc_Input>;
  _set?: Maybe<Transactions_Set_Input>;
  where: Transactions_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Transactions_By_PkArgs {
  _inc?: Maybe<Transactions_Inc_Input>;
  _set?: Maybe<Transactions_Set_Input>;
  pk_columns: Transactions_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Transactions_ManyArgs {
  updates: Array<Transactions_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_UserArgs {
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_UsersArgs {
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_VehicleArgs {
  _inc?: Maybe<Vehicle_Inc_Input>;
  _set?: Maybe<Vehicle_Set_Input>;
  pk_columns: Vehicle_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_BoniArgs {
  _inc?: Maybe<Vehicle_Boni_Inc_Input>;
  _set?: Maybe<Vehicle_Boni_Set_Input>;
  where: Vehicle_Boni_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_Boni_By_PkArgs {
  _inc?: Maybe<Vehicle_Boni_Inc_Input>;
  _set?: Maybe<Vehicle_Boni_Set_Input>;
  pk_columns: Vehicle_Boni_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_Boni_ManyArgs {
  updates: Array<Vehicle_Boni_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_Import_TemplatesArgs {
  _set?: Maybe<Vehicle_Import_Templates_Set_Input>;
  where: Vehicle_Import_Templates_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_Import_Templates_By_PkArgs {
  _set?: Maybe<Vehicle_Import_Templates_Set_Input>;
  pk_columns: Vehicle_Import_Templates_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_Import_Templates_ManyArgs {
  updates: Array<Vehicle_Import_Templates_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_MetafieldsArgs {
  _set?: Maybe<Vehicle_Metafields_Set_Input>;
  where: Vehicle_Metafields_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_Metafields_By_PkArgs {
  _set?: Maybe<Vehicle_Metafields_Set_Input>;
  pk_columns: Vehicle_Metafields_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_Metafields_ManyArgs {
  updates: Array<Vehicle_Metafields_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_NoteArgs {
  _inc?: Maybe<VehicleNote_Inc_Input>;
  _set?: Maybe<VehicleNote_Set_Input>;
  pk_columns: VehicleNote_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_NotesArgs {
  _inc?: Maybe<VehicleNote_Inc_Input>;
  _set?: Maybe<VehicleNote_Set_Input>;
  where: VehicleNote_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_Owner_InformationsArgs {
  _set?: Maybe<Vehicle_Owner_Informations_Set_Input>;
  where: Vehicle_Owner_Informations_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_Owner_Informations_By_PkArgs {
  _set?: Maybe<Vehicle_Owner_Informations_Set_Input>;
  pk_columns: Vehicle_Owner_Informations_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Vehicle_Owner_Informations_ManyArgs {
  updates: Array<Vehicle_Owner_Informations_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_VehiclesArgs {
  _inc?: Maybe<Vehicle_Inc_Input>;
  _set?: Maybe<Vehicle_Set_Input>;
  where: Vehicle_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Work_Order_StatusArgs {
  _set?: Maybe<Work_Order_Status_Set_Input>;
  where: Work_Order_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Work_Order_Status_By_PkArgs {
  _set?: Maybe<Work_Order_Status_Set_Input>;
  pk_columns: Work_Order_Status_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Work_Order_Status_ManyArgs {
  updates: Array<Work_Order_Status_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Work_Order_Task_CategoryArgs {
  _set?: Maybe<Work_Order_Task_Category_Set_Input>;
  where: Work_Order_Task_Category_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Work_Order_Task_Category_By_PkArgs {
  _set?: Maybe<Work_Order_Task_Category_Set_Input>;
  pk_columns: Work_Order_Task_Category_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Work_Order_Task_Category_ManyArgs {
  updates: Array<Work_Order_Task_Category_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Work_OrdersArgs {
  _inc?: Maybe<Work_Orders_Inc_Input>;
  _set?: Maybe<Work_Orders_Set_Input>;
  where: Work_Orders_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Work_Orders_By_PkArgs {
  _inc?: Maybe<Work_Orders_Inc_Input>;
  _set?: Maybe<Work_Orders_Set_Input>;
  pk_columns: Work_Orders_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Work_Orders_ManyArgs {
  updates: Array<Work_Orders_Updates>;
}

/** columns and relationships of "notification_status" */
export interface Notification_Status {
  __typename?: 'notification_status';
  value: Scalars['String'];
}

/** aggregated selection of "notification_status" */
export interface Notification_Status_Aggregate {
  __typename?: 'notification_status_aggregate';
  aggregate?: Maybe<Notification_Status_Aggregate_Fields>;
  nodes: Array<Notification_Status>;
}

/** aggregate fields of "notification_status" */
export interface Notification_Status_Aggregate_Fields {
  __typename?: 'notification_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Status_Max_Fields>;
  min?: Maybe<Notification_Status_Min_Fields>;
}


/** aggregate fields of "notification_status" */
export interface Notification_Status_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Notification_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "notification_status". All fields are combined with a logical 'AND'. */
export interface Notification_Status_Bool_Exp {
  _and?: Maybe<Array<Notification_Status_Bool_Exp>>;
  _not?: Maybe<Notification_Status_Bool_Exp>;
  _or?: Maybe<Array<Notification_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "notification_status" */
export enum Notification_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotificationStatusPkey = 'notification_status_pkey'
}

export enum Notification_Status_Enum {
  Read = 'READ',
  Unread = 'UNREAD'
}

/** Boolean expression to compare columns of type "notification_status_enum". All fields are combined with logical 'AND'. */
export interface Notification_Status_Enum_Comparison_Exp {
  _eq?: Maybe<Notification_Status_Enum>;
  _in?: Maybe<Array<Notification_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Notification_Status_Enum>;
  _nin?: Maybe<Array<Notification_Status_Enum>>;
}

/** input type for inserting data into table "notification_status" */
export interface Notification_Status_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Notification_Status_Max_Fields {
  __typename?: 'notification_status_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Notification_Status_Min_Fields {
  __typename?: 'notification_status_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "notification_status" */
export interface Notification_Status_Mutation_Response {
  __typename?: 'notification_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Status>;
}

/** on_conflict condition type for table "notification_status" */
export interface Notification_Status_On_Conflict {
  constraint: Notification_Status_Constraint;
  update_columns: Array<Notification_Status_Update_Column>;
  where?: Maybe<Notification_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "notification_status". */
export interface Notification_Status_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: notification_status */
export interface Notification_Status_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "notification_status" */
export enum Notification_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "notification_status" */
export interface Notification_Status_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "notification_status" */
export interface Notification_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Notification_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Notification_Status_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "notification_status" */
export enum Notification_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Notification_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Status_Bool_Exp;
}

/** columns and relationships of "notification_type" */
export interface Notification_Type {
  __typename?: 'notification_type';
  value: Scalars['String'];
}

/** aggregated selection of "notification_type" */
export interface Notification_Type_Aggregate {
  __typename?: 'notification_type_aggregate';
  aggregate?: Maybe<Notification_Type_Aggregate_Fields>;
  nodes: Array<Notification_Type>;
}

/** aggregate fields of "notification_type" */
export interface Notification_Type_Aggregate_Fields {
  __typename?: 'notification_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Type_Max_Fields>;
  min?: Maybe<Notification_Type_Min_Fields>;
}


/** aggregate fields of "notification_type" */
export interface Notification_Type_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Notification_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "notification_type". All fields are combined with a logical 'AND'. */
export interface Notification_Type_Bool_Exp {
  _and?: Maybe<Array<Notification_Type_Bool_Exp>>;
  _not?: Maybe<Notification_Type_Bool_Exp>;
  _or?: Maybe<Array<Notification_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "notification_type" */
export enum Notification_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotificationTypePkey = 'notification_type_pkey'
}

export enum Notification_Type_Enum {
  AssetWatchdogLinkUpdated = 'ASSET_WATCHDOG_LINK_UPDATED',
  TicketAssigneeUpdated = 'TICKET_ASSIGNEE_UPDATED',
  TicketDepartmentUpdated = 'TICKET_DEPARTMENT_UPDATED',
  TicketInserted = 'TICKET_INSERTED',
  TicketLocationUpdated = 'TICKET_LOCATION_UPDATED'
}

/** Boolean expression to compare columns of type "notification_type_enum". All fields are combined with logical 'AND'. */
export interface Notification_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Notification_Type_Enum>;
  _in?: Maybe<Array<Notification_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Notification_Type_Enum>;
  _nin?: Maybe<Array<Notification_Type_Enum>>;
}

/** input type for inserting data into table "notification_type" */
export interface Notification_Type_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Notification_Type_Max_Fields {
  __typename?: 'notification_type_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Notification_Type_Min_Fields {
  __typename?: 'notification_type_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "notification_type" */
export interface Notification_Type_Mutation_Response {
  __typename?: 'notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Type>;
}

/** on_conflict condition type for table "notification_type" */
export interface Notification_Type_On_Conflict {
  constraint: Notification_Type_Constraint;
  update_columns: Array<Notification_Type_Update_Column>;
  where?: Maybe<Notification_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "notification_type". */
export interface Notification_Type_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: notification_type */
export interface Notification_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "notification_type" */
export enum Notification_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "notification_type" */
export interface Notification_Type_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "notification_type" */
export interface Notification_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Notification_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Notification_Type_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "notification_type" */
export enum Notification_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Notification_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Type_Bool_Exp;
}

/** columns and relationships of "notifications" */
export interface Notifications {
  __typename?: 'notifications';
  /** An object relationship */
  asset_watchdog_link?: Maybe<Asset_Watchdog_Links>;
  asset_watchdog_link_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  ticket?: Maybe<Ticket>;
  ticket_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "notifications" */
export interface Notifications_Aggregate {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
}

/** aggregate fields of "notifications" */
export interface Notifications_Aggregate_Fields {
  __typename?: 'notifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
}


/** aggregate fields of "notifications" */
export interface Notifications_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export interface Notifications_Bool_Exp {
  _and?: Maybe<Array<Notifications_Bool_Exp>>;
  _not?: Maybe<Notifications_Bool_Exp>;
  _or?: Maybe<Array<Notifications_Bool_Exp>>;
  asset_watchdog_link?: Maybe<Asset_Watchdog_Links_Bool_Exp>;
  asset_watchdog_link_id?: Maybe<Uuid_Comparison_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  ticket?: Maybe<Ticket_Bool_Exp>;
  ticket_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Notifications_Max_Fields {
  __typename?: 'notifications_max_fields';
  asset_watchdog_link_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface Notifications_Min_Fields {
  __typename?: 'notifications_min_fields';
  asset_watchdog_link_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "notifications". */
export interface Notifications_Order_By {
  asset_watchdog_link?: Maybe<Asset_Watchdog_Links_Order_By>;
  asset_watchdog_link_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  ticket?: Maybe<Ticket_Order_By>;
  ticket_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  AssetWatchdogLinkId = 'asset_watchdog_link_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "notifications" */
export interface Notifications_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Notifications_Stream_Cursor_Value_Input {
  asset_watchdog_link_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface Numeric_Comparison_Exp {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "order_state" */
export interface Order_State {
  __typename?: 'order_state';
  comment: Scalars['String'];
  value: Scalars['String'];
}

/** aggregated selection of "order_state" */
export interface Order_State_Aggregate {
  __typename?: 'order_state_aggregate';
  aggregate?: Maybe<Order_State_Aggregate_Fields>;
  nodes: Array<Order_State>;
}

/** aggregate fields of "order_state" */
export interface Order_State_Aggregate_Fields {
  __typename?: 'order_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Order_State_Max_Fields>;
  min?: Maybe<Order_State_Min_Fields>;
}


/** aggregate fields of "order_state" */
export interface Order_State_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Order_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "order_state". All fields are combined with a logical 'AND'. */
export interface Order_State_Bool_Exp {
  _and?: Maybe<Array<Order_State_Bool_Exp>>;
  _not?: Maybe<Order_State_Bool_Exp>;
  _or?: Maybe<Array<Order_State_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "order_state" */
export enum Order_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  OrderStatePkey = 'order_state_pkey'
}

export enum Order_State_Enum {
  Handover = 'HANDOVER',
  InInternalTransport = 'IN_INTERNAL_TRANSPORT',
  InStock = 'IN_STOCK',
  InTransport = 'IN_TRANSPORT'
}

/** Boolean expression to compare columns of type "order_state_enum". All fields are combined with logical 'AND'. */
export interface Order_State_Enum_Comparison_Exp {
  _eq?: Maybe<Order_State_Enum>;
  _in?: Maybe<Array<Order_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Order_State_Enum>;
  _nin?: Maybe<Array<Order_State_Enum>>;
}

/** input type for inserting data into table "order_state" */
export interface Order_State_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Order_State_Max_Fields {
  __typename?: 'order_state_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Order_State_Min_Fields {
  __typename?: 'order_state_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "order_state" */
export interface Order_State_Mutation_Response {
  __typename?: 'order_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_State>;
}

/** on_conflict condition type for table "order_state" */
export interface Order_State_On_Conflict {
  constraint: Order_State_Constraint;
  update_columns: Array<Order_State_Update_Column>;
  where?: Maybe<Order_State_Bool_Exp>;
}

/** Ordering options when selecting data from "order_state". */
export interface Order_State_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: order_state */
export interface Order_State_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "order_state" */
export enum Order_State_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "order_state" */
export interface Order_State_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "order_state" */
export interface Order_State_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Order_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Order_State_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "order_state" */
export enum Order_State_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Order_State_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Order_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_State_Bool_Exp;
}

/** columns and relationships of "phone_number_type" */
export interface Phone_Number_Type {
  __typename?: 'phone_number_type';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
}

/** aggregated selection of "phone_number_type" */
export interface Phone_Number_Type_Aggregate {
  __typename?: 'phone_number_type_aggregate';
  aggregate?: Maybe<Phone_Number_Type_Aggregate_Fields>;
  nodes: Array<Phone_Number_Type>;
}

/** aggregate fields of "phone_number_type" */
export interface Phone_Number_Type_Aggregate_Fields {
  __typename?: 'phone_number_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Phone_Number_Type_Max_Fields>;
  min?: Maybe<Phone_Number_Type_Min_Fields>;
}


/** aggregate fields of "phone_number_type" */
export interface Phone_Number_Type_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Phone_Number_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "phone_number_type". All fields are combined with a logical 'AND'. */
export interface Phone_Number_Type_Bool_Exp {
  _and?: Maybe<Array<Phone_Number_Type_Bool_Exp>>;
  _not?: Maybe<Phone_Number_Type_Bool_Exp>;
  _or?: Maybe<Array<Phone_Number_Type_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "phone_number_type" */
export enum Phone_Number_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  PhoneNumberTypePkey = 'phone_number_type_pkey'
}

export enum Phone_Number_Type_Enum {
  Landline = 'LANDLINE',
  Mobile = 'MOBILE'
}

/** Boolean expression to compare columns of type "phone_number_type_enum". All fields are combined with logical 'AND'. */
export interface Phone_Number_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Phone_Number_Type_Enum>;
  _in?: Maybe<Array<Phone_Number_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Phone_Number_Type_Enum>;
  _nin?: Maybe<Array<Phone_Number_Type_Enum>>;
}

/** input type for inserting data into table "phone_number_type" */
export interface Phone_Number_Type_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Phone_Number_Type_Max_Fields {
  __typename?: 'phone_number_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Phone_Number_Type_Min_Fields {
  __typename?: 'phone_number_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "phone_number_type" */
export interface Phone_Number_Type_Mutation_Response {
  __typename?: 'phone_number_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Phone_Number_Type>;
}

/** on_conflict condition type for table "phone_number_type" */
export interface Phone_Number_Type_On_Conflict {
  constraint: Phone_Number_Type_Constraint;
  update_columns: Array<Phone_Number_Type_Update_Column>;
  where?: Maybe<Phone_Number_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "phone_number_type". */
export interface Phone_Number_Type_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: phone_number_type */
export interface Phone_Number_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "phone_number_type" */
export enum Phone_Number_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "phone_number_type" */
export interface Phone_Number_Type_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "phone_number_type" */
export interface Phone_Number_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Phone_Number_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Phone_Number_Type_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "phone_number_type" */
export enum Phone_Number_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Phone_Number_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Phone_Number_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Phone_Number_Type_Bool_Exp;
}

export interface Query_Root {
  __typename?: 'query_root';
  /** fetch aggregated fields from the table: "notes" */
  Notes_aggregate: Notes_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  address?: Maybe<Address>;
  /** fetch data from the table: "addresses" */
  addresses: Array<Address>;
  /** fetch aggregated fields from the table: "addresses" */
  addresses_aggregate: Address_Aggregate;
  /** fetch data from the table: "after_sales_potentials" using primary key columns */
  after_sales_potential?: Maybe<AfterSalesPotential>;
  /** An array relationship */
  after_sales_potentials: Array<AfterSalesPotential>;
  /** An aggregate relationship */
  after_sales_potentials_aggregate: AfterSalesPotential_Aggregate;
  /** fetch data from the table: "after_sales_status" */
  after_sales_status: Array<After_Sales_Status>;
  /** fetch aggregated fields from the table: "after_sales_status" */
  after_sales_status_aggregate: After_Sales_Status_Aggregate;
  /** fetch data from the table: "after_sales_status" using primary key columns */
  after_sales_status_by_pk?: Maybe<After_Sales_Status>;
  /** fetch data from the table: "customer_appointments" using primary key columns */
  appointment?: Maybe<Appointment>;
  /** An array relationship */
  appointments: Array<Appointment>;
  /** An aggregate relationship */
  appointments_aggregate: Appointment_Aggregate;
  /** fetch data from the table: "asset_link_family_notifications" */
  asset_link_family_notifications: Array<Asset_Link_Family_Notifications>;
  /** fetch aggregated fields from the table: "asset_link_family_notifications" */
  asset_link_family_notifications_aggregate: Asset_Link_Family_Notifications_Aggregate;
  /** fetch data from the table: "asset_link_family_notifications" using primary key columns */
  asset_link_family_notifications_by_pk?: Maybe<Asset_Link_Family_Notifications>;
  /** fetch data from the table: "asset_watchdog_link_status" */
  asset_watchdog_link_status: Array<Asset_Watchdog_Link_Status>;
  /** fetch aggregated fields from the table: "asset_watchdog_link_status" */
  asset_watchdog_link_status_aggregate: Asset_Watchdog_Link_Status_Aggregate;
  /** fetch data from the table: "asset_watchdog_link_status" using primary key columns */
  asset_watchdog_link_status_by_pk?: Maybe<Asset_Watchdog_Link_Status>;
  /** fetch data from the table: "asset_watchdog_links" */
  asset_watchdog_links: Array<Asset_Watchdog_Links>;
  /** fetch aggregated fields from the table: "asset_watchdog_links" */
  asset_watchdog_links_aggregate: Asset_Watchdog_Links_Aggregate;
  /** fetch data from the table: "asset_watchdog_links" using primary key columns */
  asset_watchdog_links_by_pk?: Maybe<Asset_Watchdog_Links>;
  /** fetch data from the table: "brands" using primary key columns */
  brand?: Maybe<Brand>;
  /** fetch data from the table: "brand_boni_types" */
  brand_boni_types: Array<Brand_Boni_Types>;
  /** fetch aggregated fields from the table: "brand_boni_types" */
  brand_boni_types_aggregate: Brand_Boni_Types_Aggregate;
  /** fetch data from the table: "brand_boni_types" using primary key columns */
  brand_boni_types_by_pk?: Maybe<Brand_Boni_Types>;
  /** fetch data from the table: "brand_supplier" using primary key columns */
  brand_supplier?: Maybe<Brand_Suppliers>;
  /** fetch data from the table: "brand_supplier" */
  brand_suppliers: Array<Brand_Suppliers>;
  /** fetch aggregated fields from the table: "brand_supplier" */
  brand_suppliers_aggregate: Brand_Suppliers_Aggregate;
  /** fetch data from the table: "brand_vehicle_metafield" */
  brand_vehicle_metafield: Array<Brand_Vehicle_Metafield>;
  /** fetch aggregated fields from the table: "brand_vehicle_metafield" */
  brand_vehicle_metafield_aggregate: Brand_Vehicle_Metafield_Aggregate;
  /** fetch data from the table: "brand_vehicle_metafield" using primary key columns */
  brand_vehicle_metafield_by_pk?: Maybe<Brand_Vehicle_Metafield>;
  /** fetch data from the table: "brands" */
  brands: Array<Brand>;
  /** fetch aggregated fields from the table: "brands" */
  brands_aggregate: Brand_Aggregate;
  cloudwatch_logs: CloudwatchLogsPayload;
  /** fetch data from the table: "contact_notes" using primary key columns */
  contact_note?: Maybe<ContactNote>;
  /** fetch data from the table: "contact_note_type" */
  contact_note_type: Array<Contact_Note_Type>;
  /** fetch aggregated fields from the table: "contact_note_type" */
  contact_note_type_aggregate: Contact_Note_Type_Aggregate;
  /** fetch data from the table: "contact_note_type" using primary key columns */
  contact_note_type_by_pk?: Maybe<Contact_Note_Type>;
  /** An array relationship */
  contact_notes: Array<ContactNote>;
  /** An aggregate relationship */
  contact_notes_aggregate: ContactNote_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customer?: Maybe<Customer>;
  /** fetch data from the table: "customer_customer_tag" */
  customer_customer_tag: Array<Customer_Customer_Tag>;
  /** fetch aggregated fields from the table: "customer_customer_tag" */
  customer_customer_tag_aggregate: Customer_Customer_Tag_Aggregate;
  /** fetch data from the table: "customer_customer_tag" using primary key columns */
  customer_customer_tag_by_pk?: Maybe<Customer_Customer_Tag>;
  /** fetch data from the table: "customer_gdpr_info" using primary key columns */
  customer_gdpr_info?: Maybe<CustomerGdprInfo>;
  /** fetch data from the table: "customer_gdpr_info" */
  customer_gdpr_infos: Array<CustomerGdprInfo>;
  /** fetch aggregated fields from the table: "customer_gdpr_info" */
  customer_gdpr_infos_aggregate: CustomerGdprInfo_Aggregate;
  /** fetch data from the table: "customer_salutation" */
  customer_salutation: Array<Customer_Salutation>;
  /** fetch aggregated fields from the table: "customer_salutation" */
  customer_salutation_aggregate: Customer_Salutation_Aggregate;
  /** fetch data from the table: "customer_salutation" using primary key columns */
  customer_salutation_by_pk?: Maybe<Customer_Salutation>;
  /** fetch data from the table: "customer_tag" */
  customer_tag: Array<Customer_Tag>;
  /** fetch aggregated fields from the table: "customer_tag" */
  customer_tag_aggregate: Customer_Tag_Aggregate;
  /** fetch data from the table: "customer_tag" using primary key columns */
  customer_tag_by_pk?: Maybe<Customer_Tag>;
  /** fetch data from the table: "customer_type" */
  customer_type: Array<Customer_Type>;
  /** fetch aggregated fields from the table: "customer_type" */
  customer_type_aggregate: Customer_Type_Aggregate;
  /** fetch data from the table: "customer_type" using primary key columns */
  customer_type_by_pk?: Maybe<Customer_Type>;
  /** fetch data from the table: "customer_vehicles" using primary key columns */
  customer_vehicle?: Maybe<CustomerVehicle>;
  /** fetch data from the table: "customer_vehicles" */
  customer_vehicles: Array<CustomerVehicle>;
  /** fetch aggregated fields from the table: "customer_vehicles" */
  customer_vehicles_aggregate: CustomerVehicle_Aggregate;
  /** fetch data from the table: "customers" */
  customers: Array<Customer>;
  /** fetch aggregated fields from the table: "customers" */
  customers_aggregate: Customer_Aggregate;
  dealerVehicleBrandSyncStatus: DealerVehicleBrandSyncStatusPayload;
  /** fetch data from the table: "dealer_vehicles" using primary key columns */
  dealer_vehicle?: Maybe<DealerVehicle>;
  /** fetch data from the table: "dealer_vehicle_import_templates" */
  dealer_vehicle_import_templates: Array<Dealer_Vehicle_Import_Templates>;
  /** fetch aggregated fields from the table: "dealer_vehicle_import_templates" */
  dealer_vehicle_import_templates_aggregate: Dealer_Vehicle_Import_Templates_Aggregate;
  /** fetch data from the table: "dealer_vehicle_import_templates" using primary key columns */
  dealer_vehicle_import_templates_by_pk?: Maybe<Dealer_Vehicle_Import_Templates>;
  /** fetch data from the table: "dealer_vehicle_inbox" */
  dealer_vehicle_inbox: Array<DealerVehicleInbox>;
  /** fetch aggregated fields from the table: "dealer_vehicle_inbox" */
  dealer_vehicle_inbox_aggregate: DealerVehicleInbox_Aggregate;
  /** fetch data from the table: "dealer_vehicle_inbox" using primary key columns */
  dealer_vehicle_inbox_by_pk?: Maybe<DealerVehicleInbox>;
  /** fetch data from the table: "dealer_vehicle_inbox_import_templates" */
  dealer_vehicle_inbox_import_templates: Array<Dealer_Vehicle_Inbox_Import_Templates>;
  /** fetch aggregated fields from the table: "dealer_vehicle_inbox_import_templates" */
  dealer_vehicle_inbox_import_templates_aggregate: Dealer_Vehicle_Inbox_Import_Templates_Aggregate;
  /** fetch data from the table: "dealer_vehicle_inbox_import_templates" using primary key columns */
  dealer_vehicle_inbox_import_templates_by_pk?: Maybe<Dealer_Vehicle_Inbox_Import_Templates>;
  /** fetch data from the table: "dealer_vehicle_notes" using primary key columns */
  dealer_vehicle_note?: Maybe<DealerVehicleNote>;
  /** fetch data from the table: "dealer_vehicle_notes" */
  dealer_vehicle_notes: Array<DealerVehicleNote>;
  /** fetch aggregated fields from the table: "dealer_vehicle_notes" */
  dealer_vehicle_notes_aggregate: DealerVehicleNote_Aggregate;
  /** fetch data from the table: "dealer_vehicle_state" */
  dealer_vehicle_state: Array<Dealer_Vehicle_State>;
  /** fetch aggregated fields from the table: "dealer_vehicle_state" */
  dealer_vehicle_state_aggregate: Dealer_Vehicle_State_Aggregate;
  /** fetch data from the table: "dealer_vehicle_state" using primary key columns */
  dealer_vehicle_state_by_pk?: Maybe<Dealer_Vehicle_State>;
  /** fetch data from the table: "dealer_vehicle_type" */
  dealer_vehicle_type: Array<Dealer_Vehicle_Type>;
  /** fetch aggregated fields from the table: "dealer_vehicle_type" */
  dealer_vehicle_type_aggregate: Dealer_Vehicle_Type_Aggregate;
  /** fetch data from the table: "dealer_vehicle_type" using primary key columns */
  dealer_vehicle_type_by_pk?: Maybe<Dealer_Vehicle_Type>;
  /** fetch data from the table: "dealer_vehicles" */
  dealer_vehicles: Array<DealerVehicle>;
  /** fetch aggregated fields from the table: "dealer_vehicles" */
  dealer_vehicles_aggregate: DealerVehicle_Aggregate;
  /** fetch data from the table: "departments" using primary key columns */
  department?: Maybe<Department>;
  /** fetch data from the table: "department_role" */
  department_role: Array<Department_Role>;
  /** fetch aggregated fields from the table: "department_role" */
  department_role_aggregate: Department_Role_Aggregate;
  /** fetch data from the table: "department_role" using primary key columns */
  department_role_by_pk?: Maybe<Department_Role>;
  /** fetch data from the table: "departments" */
  departments: Array<Department>;
  /** fetch aggregated fields from the table: "departments" */
  departments_aggregate: Department_Aggregate;
  /** fetch data from the table: "dms_reference" using primary key columns */
  dms_reference?: Maybe<DmsReference>;
  /** fetch data from the table: "dms_reference" */
  dms_references: Array<DmsReference>;
  /** fetch aggregated fields from the table: "dms_reference" */
  dms_references_aggregate: DmsReference_Aggregate;
  /** fetch data from the table: "dms_type" */
  dms_type: Array<Dms_Type>;
  /** fetch aggregated fields from the table: "dms_type" */
  dms_type_aggregate: Dms_Type_Aggregate;
  /** fetch data from the table: "dms_type" using primary key columns */
  dms_type_by_pk?: Maybe<Dms_Type>;
  /** fetch data from the table: "embedded_pages" */
  embedded_pages: Array<Embedded_Pages>;
  /** fetch aggregated fields from the table: "embedded_pages" */
  embedded_pages_aggregate: Embedded_Pages_Aggregate;
  /** fetch data from the table: "embedded_pages" using primary key columns */
  embedded_pages_by_pk?: Maybe<Embedded_Pages>;
  /** fetch data from the table: "excel_import_assignments" */
  excel_import_assignments: Array<Excel_Import_Assignments>;
  /** fetch aggregated fields from the table: "excel_import_assignments" */
  excel_import_assignments_aggregate: Excel_Import_Assignments_Aggregate;
  /** fetch data from the table: "excel_import_assignments" using primary key columns */
  excel_import_assignments_by_pk?: Maybe<Excel_Import_Assignments>;
  exportAfterSalesPotentials?: Maybe<ExportAfterSalesPotentialsOutput>;
  exportVehicles?: Maybe<ExportVehiclesOutput>;
  /** fetch data from the table: "financing_type" */
  financing_type: Array<Financing_Type>;
  /** fetch aggregated fields from the table: "financing_type" */
  financing_type_aggregate: Financing_Type_Aggregate;
  /** fetch data from the table: "financing_type" using primary key columns */
  financing_type_by_pk?: Maybe<Financing_Type>;
  /** execute function "house_brands" which returns "brands" */
  house_brands: Array<Brand>;
  /** execute function "house_brands" and query aggregates on result of table type "brands" */
  house_brands_aggregate: Brand_Aggregate;
  importJob: ImportJobPayload;
  importJobUploadUrl: ImportJobUploadUrlPayload;
  /** fetch data from the table: "import_job_executable_status" */
  import_job_executable_status: Array<Import_Job_Executable_Status>;
  /** fetch aggregated fields from the table: "import_job_executable_status" */
  import_job_executable_status_aggregate: Import_Job_Executable_Status_Aggregate;
  /** fetch data from the table: "import_job_executable_status" using primary key columns */
  import_job_executable_status_by_pk?: Maybe<Import_Job_Executable_Status>;
  /** fetch data from the table: "import_job_status" */
  import_job_status: Array<Import_Job_Status>;
  /** fetch aggregated fields from the table: "import_job_status" */
  import_job_status_aggregate: Import_Job_Status_Aggregate;
  /** fetch data from the table: "import_job_status" using primary key columns */
  import_job_status_by_pk?: Maybe<Import_Job_Status>;
  /** fetch data from the table: "import_job_vehicle_executables" */
  import_job_vehicle_executables: Array<Import_Job_Vehicle_Executables>;
  /** fetch aggregated fields from the table: "import_job_vehicle_executables" */
  import_job_vehicle_executables_aggregate: Import_Job_Vehicle_Executables_Aggregate;
  /** fetch data from the table: "import_job_vehicle_executables" using primary key columns */
  import_job_vehicle_executables_by_pk?: Maybe<Import_Job_Vehicle_Executables>;
  /** fetch data from the table: "import_jobs" */
  import_jobs: Array<Import_Jobs>;
  /** fetch aggregated fields from the table: "import_jobs" */
  import_jobs_aggregate: Import_Jobs_Aggregate;
  /** fetch data from the table: "import_jobs" using primary key columns */
  import_jobs_by_pk?: Maybe<Import_Jobs>;
  /** fetch data from the table: "import_type" */
  import_type: Array<Import_Type>;
  /** fetch aggregated fields from the table: "import_type" */
  import_type_aggregate: Import_Type_Aggregate;
  /** fetch data from the table: "import_type" using primary key columns */
  import_type_by_pk?: Maybe<Import_Type>;
  /** fetch data from the table: "in_buy_channel" */
  in_buy_channel: Array<In_Buy_Channel>;
  /** fetch aggregated fields from the table: "in_buy_channel" */
  in_buy_channel_aggregate: In_Buy_Channel_Aggregate;
  /** fetch data from the table: "in_buy_channel" using primary key columns */
  in_buy_channel_by_pk?: Maybe<In_Buy_Channel>;
  /** fetch data from the table: "locations" using primary key columns */
  location?: Maybe<Location>;
  /** fetch data from the table: "location_brand" using primary key columns */
  location_brand_reference?: Maybe<LocationBrandReference>;
  /** fetch data from the table: "location_brand" */
  location_brand_references: Array<LocationBrandReference>;
  /** fetch aggregated fields from the table: "location_brand" */
  location_brand_references_aggregate: LocationBrandReference_Aggregate;
  /** fetch data from the table: "location_groups" using primary key columns */
  location_group?: Maybe<LocationGroup>;
  /** fetch data from the table: "location_group_brand" */
  location_group_brand: Array<Location_Group_Brand>;
  /** fetch aggregated fields from the table: "location_group_brand" */
  location_group_brand_aggregate: Location_Group_Brand_Aggregate;
  /** fetch data from the table: "location_group_brand" using primary key columns */
  location_group_brand_by_pk?: Maybe<Location_Group_Brand>;
  /** fetch data from the table: "location_group_brands_view" */
  location_group_brands_view: Array<Location_Group_Brands_View>;
  /** fetch aggregated fields from the table: "location_group_brands_view" */
  location_group_brands_view_aggregate: Location_Group_Brands_View_Aggregate;
  /** fetch data from the table: "location_groups" */
  location_groups: Array<LocationGroup>;
  /** fetch aggregated fields from the table: "location_groups" */
  location_groups_aggregate: LocationGroup_Aggregate;
  /** fetch data from the table: "locations" */
  locations: Array<Location>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Location_Aggregate;
  locosoft_sync_states?: Maybe<Array<LocosoftSyncStatesPayload>>;
  metric_data: MetricDataPayload;
  /** fetch data from the table: "models" using primary key columns */
  model?: Maybe<Model>;
  /** fetch data from the table: "model_groups" using primary key columns */
  model_group?: Maybe<ModelGroup>;
  /** fetch data from the table: "model_group_release_years" */
  model_group_release_years: Array<Model_Group_Release_Years>;
  /** fetch aggregated fields from the table: "model_group_release_years" */
  model_group_release_years_aggregate: Model_Group_Release_Years_Aggregate;
  /** fetch data from the table: "model_group_release_years" using primary key columns */
  model_group_release_years_by_pk?: Maybe<Model_Group_Release_Years>;
  /** fetch data from the table: "model_groups" */
  model_groups: Array<ModelGroup>;
  /** fetch aggregated fields from the table: "model_groups" */
  model_groups_aggregate: ModelGroup_Aggregate;
  /** An array relationship */
  models: Array<Model>;
  /** An aggregate relationship */
  models_aggregate: Model_Aggregate;
  /** execute function "models_in_stock" which returns "models" */
  models_in_stock: Array<Model>;
  /** execute function "models_in_stock" and query aggregates on result of table type "models" */
  models_in_stock_aggregate: Model_Aggregate;
  /** execute function "new_models" which returns "models" */
  new_models: Array<Model>;
  /** execute function "new_models" and query aggregates on result of table type "models" */
  new_models_aggregate: Model_Aggregate;
  /** fetch data from the table: "notes" using primary key columns */
  note?: Maybe<Notes>;
  /** An array relationship */
  notes: Array<Notes>;
  /** fetch data from the table: "notification_status" */
  notification_status: Array<Notification_Status>;
  /** fetch aggregated fields from the table: "notification_status" */
  notification_status_aggregate: Notification_Status_Aggregate;
  /** fetch data from the table: "notification_status" using primary key columns */
  notification_status_by_pk?: Maybe<Notification_Status>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<Notification_Type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: Notification_Type_Aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<Notification_Type>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "order_state" */
  order_state: Array<Order_State>;
  /** fetch aggregated fields from the table: "order_state" */
  order_state_aggregate: Order_State_Aggregate;
  /** fetch data from the table: "order_state" using primary key columns */
  order_state_by_pk?: Maybe<Order_State>;
  /** fetch data from the table: "phone_numbers" using primary key columns */
  phone_number?: Maybe<PhoneNumber>;
  /** fetch data from the table: "phone_number_type" */
  phone_number_type: Array<Phone_Number_Type>;
  /** fetch aggregated fields from the table: "phone_number_type" */
  phone_number_type_aggregate: Phone_Number_Type_Aggregate;
  /** fetch data from the table: "phone_number_type" using primary key columns */
  phone_number_type_by_pk?: Maybe<Phone_Number_Type>;
  /** An array relationship */
  phone_numbers: Array<PhoneNumber>;
  /** An aggregate relationship */
  phone_numbers_aggregate: PhoneNumber_Aggregate;
  /** fetch data from the table: "registration_state" */
  registration_state: Array<Registration_State>;
  /** fetch aggregated fields from the table: "registration_state" */
  registration_state_aggregate: Registration_State_Aggregate;
  /** fetch data from the table: "registration_state" using primary key columns */
  registration_state_by_pk?: Maybe<Registration_State>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  runAssetWatchdog?: Maybe<AssetWatchdogPayload>;
  /** fetch data from the table: "sales_reports" */
  sales_reports: Array<Sales_Reports>;
  /** fetch aggregated fields from the table: "sales_reports" */
  sales_reports_aggregate: Sales_Reports_Aggregate;
  /** fetch data from the table: "sales_reports" using primary key columns */
  sales_reports_by_pk?: Maybe<Sales_Reports>;
  /** fetch data from the table: "sales_type" */
  sales_type: Array<Sales_Type>;
  /** fetch aggregated fields from the table: "sales_type" */
  sales_type_aggregate: Sales_Type_Aggregate;
  /** fetch data from the table: "sales_type" using primary key columns */
  sales_type_by_pk?: Maybe<Sales_Type>;
  /** fetch data from the table: "salutation_type" */
  salutation_type: Array<Salutation_Type>;
  /** fetch aggregated fields from the table: "salutation_type" */
  salutation_type_aggregate: Salutation_Type_Aggregate;
  /** fetch data from the table: "salutation_type" using primary key columns */
  salutation_type_by_pk?: Maybe<Salutation_Type>;
  /** fetch data from the table: "storage_locations" using primary key columns */
  storage_location?: Maybe<StorageLocation>;
  /** fetch data from the table: "storage_locations" */
  storage_locations: Array<StorageLocation>;
  /** fetch aggregated fields from the table: "storage_locations" */
  storage_locations_aggregate: StorageLocation_Aggregate;
  /** fetch data from the table: "subdealers" using primary key columns */
  subdealer?: Maybe<Subdealer>;
  /** fetch data from the table: "subdealer_brand" */
  subdealer_brand: Array<Subdealer_Brand>;
  /** fetch aggregated fields from the table: "subdealer_brand" */
  subdealer_brand_aggregate: Subdealer_Brand_Aggregate;
  /** fetch data from the table: "subdealer_brand" using primary key columns */
  subdealer_brand_by_pk?: Maybe<Subdealer_Brand>;
  /** fetch data from the table: "subdealer_brands_view" */
  subdealer_brands_view: Array<Subdealer_Brands_View>;
  /** fetch aggregated fields from the table: "subdealer_brands_view" */
  subdealer_brands_view_aggregate: Subdealer_Brands_View_Aggregate;
  /** fetch data from the table: "subdealers" */
  subdealers: Array<Subdealer>;
  /** fetch aggregated fields from the table: "subdealers" */
  subdealers_aggregate: Subdealer_Aggregate;
  /** fetch data from the table: "suppliers" using primary key columns */
  supplier?: Maybe<Supplier>;
  /** fetch data from the table: "suppliers" */
  suppliers: Array<Supplier>;
  /** fetch aggregated fields from the table: "suppliers" */
  suppliers_aggregate: Supplier_Aggregate;
  /** An array relationship */
  sync_errors: Array<Sync_Errors>;
  /** An aggregate relationship */
  sync_errors_aggregate: Sync_Errors_Aggregate;
  /** fetch data from the table: "sync_errors" using primary key columns */
  sync_errors_by_pk?: Maybe<Sync_Errors>;
  /** fetch data from the table: "task_category" */
  task_category: Array<Task_Category>;
  /** fetch aggregated fields from the table: "task_category" */
  task_category_aggregate: Task_Category_Aggregate;
  /** fetch data from the table: "task_category" using primary key columns */
  task_category_by_pk?: Maybe<Task_Category>;
  /** fetch data from the table: "task_types" using primary key columns */
  task_type?: Maybe<TaskType>;
  /** fetch data from the table: "task_type_task_category" */
  task_type_task_category: Array<Task_Type_Task_Category>;
  /** fetch aggregated fields from the table: "task_type_task_category" */
  task_type_task_category_aggregate: Task_Type_Task_Category_Aggregate;
  /** fetch data from the table: "task_type_task_category" using primary key columns */
  task_type_task_category_by_pk?: Maybe<Task_Type_Task_Category>;
  /** fetch data from the table: "task_types" */
  task_types: Array<TaskType>;
  /** fetch aggregated fields from the table: "task_types" */
  task_types_aggregate: TaskType_Aggregate;
  /** fetch data from the table: "team_assignments" using primary key columns */
  team_assignment?: Maybe<TeamAssignment>;
  /** fetch data from the table: "team_assignments" */
  team_assignments: Array<TeamAssignment>;
  /** fetch aggregated fields from the table: "team_assignments" */
  team_assignments_aggregate: TeamAssignment_Aggregate;
  /** fetch data from the table: "tickets" using primary key columns */
  ticket?: Maybe<Ticket>;
  /** fetch data from the table: "ticket_family_notifications" */
  ticket_family_notifications: Array<Ticket_Family_Notifications>;
  /** fetch aggregated fields from the table: "ticket_family_notifications" */
  ticket_family_notifications_aggregate: Ticket_Family_Notifications_Aggregate;
  /** fetch data from the table: "ticket_family_notifications" using primary key columns */
  ticket_family_notifications_by_pk?: Maybe<Ticket_Family_Notifications>;
  /** fetch data from the table: "ticket_notes" using primary key columns */
  ticket_note?: Maybe<TicketNote>;
  /** fetch data from the table: "ticket_notes" */
  ticket_notes: Array<TicketNote>;
  /** fetch aggregated fields from the table: "ticket_notes" */
  ticket_notes_aggregate: TicketNote_Aggregate;
  /** fetch data from the table: "ticket_priority" */
  ticket_priority: Array<Ticket_Priority>;
  /** fetch aggregated fields from the table: "ticket_priority" */
  ticket_priority_aggregate: Ticket_Priority_Aggregate;
  /** fetch data from the table: "ticket_priority" using primary key columns */
  ticket_priority_by_pk?: Maybe<Ticket_Priority>;
  /** fetch data from the table: "ticket_state" */
  ticket_state: Array<Ticket_State>;
  /** fetch aggregated fields from the table: "ticket_state" */
  ticket_state_aggregate: Ticket_State_Aggregate;
  /** fetch data from the table: "ticket_state" using primary key columns */
  ticket_state_by_pk?: Maybe<Ticket_State>;
  /** fetch data from the table: "ticket_templates" using primary key columns */
  ticket_template?: Maybe<TicketTemplate>;
  /** fetch data from the table: "ticket_template_assignee_ref" */
  ticket_template_assignee_ref: Array<Ticket_Template_Assignee_Ref>;
  /** fetch aggregated fields from the table: "ticket_template_assignee_ref" */
  ticket_template_assignee_ref_aggregate: Ticket_Template_Assignee_Ref_Aggregate;
  /** fetch data from the table: "ticket_template_assignee_ref" using primary key columns */
  ticket_template_assignee_ref_by_pk?: Maybe<Ticket_Template_Assignee_Ref>;
  /** fetch data from the table: "ticket_template_location_ref" */
  ticket_template_location_ref: Array<Ticket_Template_Location_Ref>;
  /** fetch aggregated fields from the table: "ticket_template_location_ref" */
  ticket_template_location_ref_aggregate: Ticket_Template_Location_Ref_Aggregate;
  /** fetch data from the table: "ticket_template_location_ref" using primary key columns */
  ticket_template_location_ref_by_pk?: Maybe<Ticket_Template_Location_Ref>;
  /** fetch data from the table: "ticket_templates" */
  ticket_templates: Array<TicketTemplate>;
  /** fetch aggregated fields from the table: "ticket_templates" */
  ticket_templates_aggregate: TicketTemplate_Aggregate;
  /** An array relationship */
  tickets: Array<Ticket>;
  /** An aggregate relationship */
  tickets_aggregate: Ticket_Aggregate;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table: "users" using primary key columns */
  user?: Maybe<User>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "users" */
  users: Array<User>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: User_Aggregate;
  /** fetch data from the table: "vehicles" using primary key columns */
  vehicle?: Maybe<Vehicle>;
  /** fetch data from the table: "vehicle_boni" */
  vehicle_boni: Array<Vehicle_Boni>;
  /** fetch aggregated fields from the table: "vehicle_boni" */
  vehicle_boni_aggregate: Vehicle_Boni_Aggregate;
  /** fetch data from the table: "vehicle_boni" using primary key columns */
  vehicle_boni_by_pk?: Maybe<Vehicle_Boni>;
  /** fetch data from the table: "vehicle_import_templates" */
  vehicle_import_templates: Array<Vehicle_Import_Templates>;
  /** fetch aggregated fields from the table: "vehicle_import_templates" */
  vehicle_import_templates_aggregate: Vehicle_Import_Templates_Aggregate;
  /** fetch data from the table: "vehicle_import_templates" using primary key columns */
  vehicle_import_templates_by_pk?: Maybe<Vehicle_Import_Templates>;
  /** fetch data from the table: "vehicle_metafields" */
  vehicle_metafields: Array<Vehicle_Metafields>;
  /** fetch aggregated fields from the table: "vehicle_metafields" */
  vehicle_metafields_aggregate: Vehicle_Metafields_Aggregate;
  /** fetch data from the table: "vehicle_metafields" using primary key columns */
  vehicle_metafields_by_pk?: Maybe<Vehicle_Metafields>;
  /** fetch data from the table: "vehicle_notes" using primary key columns */
  vehicle_note?: Maybe<VehicleNote>;
  /** fetch data from the table: "vehicle_notes" */
  vehicle_notes: Array<VehicleNote>;
  /** fetch aggregated fields from the table: "vehicle_notes" */
  vehicle_notes_aggregate: VehicleNote_Aggregate;
  /** fetch data from the table: "vehicle_owner_informations" */
  vehicle_owner_informations: Array<Vehicle_Owner_Informations>;
  /** fetch aggregated fields from the table: "vehicle_owner_informations" */
  vehicle_owner_informations_aggregate: Vehicle_Owner_Informations_Aggregate;
  /** fetch data from the table: "vehicle_owner_informations" using primary key columns */
  vehicle_owner_informations_by_pk?: Maybe<Vehicle_Owner_Informations>;
  /** fetch data from the table: "vehicles" */
  vehicles: Array<Vehicle>;
  vehiclesWithMixedUpInvoices: VehiclesWithMixedUpInvoicesPayload;
  /** fetch aggregated fields from the table: "vehicles" */
  vehicles_aggregate: Vehicle_Aggregate;
  /** fetch data from the table: "work_order_status" */
  work_order_status: Array<Work_Order_Status>;
  /** fetch aggregated fields from the table: "work_order_status" */
  work_order_status_aggregate: Work_Order_Status_Aggregate;
  /** fetch data from the table: "work_order_status" using primary key columns */
  work_order_status_by_pk?: Maybe<Work_Order_Status>;
  /** fetch data from the table: "work_order_task_category" */
  work_order_task_category: Array<Work_Order_Task_Category>;
  /** fetch aggregated fields from the table: "work_order_task_category" */
  work_order_task_category_aggregate: Work_Order_Task_Category_Aggregate;
  /** fetch data from the table: "work_order_task_category" using primary key columns */
  work_order_task_category_by_pk?: Maybe<Work_Order_Task_Category>;
  /** An array relationship */
  work_orders: Array<Work_Orders>;
  /** An aggregate relationship */
  work_orders_aggregate: Work_Orders_Aggregate;
  /** fetch data from the table: "work_orders" using primary key columns */
  work_orders_by_pk?: Maybe<Work_Orders>;
}


export interface Query_RootNotes_AggregateArgs {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
}


export interface Query_RootAddressArgs {
  id: Scalars['uuid'];
}


export interface Query_RootAddressesArgs {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
}


export interface Query_RootAddresses_AggregateArgs {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
}


export interface Query_RootAfter_Sales_PotentialArgs {
  id: Scalars['uuid'];
}


export interface Query_RootAfter_Sales_PotentialsArgs {
  distinct_on?: Maybe<Array<AfterSalesPotential_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AfterSalesPotential_Order_By>>;
  where?: Maybe<AfterSalesPotential_Bool_Exp>;
}


export interface Query_RootAfter_Sales_Potentials_AggregateArgs {
  distinct_on?: Maybe<Array<AfterSalesPotential_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AfterSalesPotential_Order_By>>;
  where?: Maybe<AfterSalesPotential_Bool_Exp>;
}


export interface Query_RootAfter_Sales_StatusArgs {
  distinct_on?: Maybe<Array<After_Sales_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<After_Sales_Status_Order_By>>;
  where?: Maybe<After_Sales_Status_Bool_Exp>;
}


export interface Query_RootAfter_Sales_Status_AggregateArgs {
  distinct_on?: Maybe<Array<After_Sales_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<After_Sales_Status_Order_By>>;
  where?: Maybe<After_Sales_Status_Bool_Exp>;
}


export interface Query_RootAfter_Sales_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootAppointmentArgs {
  id: Scalars['uuid'];
}


export interface Query_RootAppointmentsArgs {
  distinct_on?: Maybe<Array<Appointment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointment_Order_By>>;
  where?: Maybe<Appointment_Bool_Exp>;
}


export interface Query_RootAppointments_AggregateArgs {
  distinct_on?: Maybe<Array<Appointment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointment_Order_By>>;
  where?: Maybe<Appointment_Bool_Exp>;
}


export interface Query_RootAsset_Link_Family_NotificationsArgs {
  distinct_on?: Maybe<Array<Asset_Link_Family_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Link_Family_Notifications_Order_By>>;
  where?: Maybe<Asset_Link_Family_Notifications_Bool_Exp>;
}


export interface Query_RootAsset_Link_Family_Notifications_AggregateArgs {
  distinct_on?: Maybe<Array<Asset_Link_Family_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Link_Family_Notifications_Order_By>>;
  where?: Maybe<Asset_Link_Family_Notifications_Bool_Exp>;
}


export interface Query_RootAsset_Link_Family_Notifications_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootAsset_Watchdog_Link_StatusArgs {
  distinct_on?: Maybe<Array<Asset_Watchdog_Link_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Watchdog_Link_Status_Order_By>>;
  where?: Maybe<Asset_Watchdog_Link_Status_Bool_Exp>;
}


export interface Query_RootAsset_Watchdog_Link_Status_AggregateArgs {
  distinct_on?: Maybe<Array<Asset_Watchdog_Link_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Watchdog_Link_Status_Order_By>>;
  where?: Maybe<Asset_Watchdog_Link_Status_Bool_Exp>;
}


export interface Query_RootAsset_Watchdog_Link_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootAsset_Watchdog_LinksArgs {
  distinct_on?: Maybe<Array<Asset_Watchdog_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Watchdog_Links_Order_By>>;
  where?: Maybe<Asset_Watchdog_Links_Bool_Exp>;
}


export interface Query_RootAsset_Watchdog_Links_AggregateArgs {
  distinct_on?: Maybe<Array<Asset_Watchdog_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Watchdog_Links_Order_By>>;
  where?: Maybe<Asset_Watchdog_Links_Bool_Exp>;
}


export interface Query_RootAsset_Watchdog_Links_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootBrandArgs {
  id: Scalars['uuid'];
}


export interface Query_RootBrand_Boni_TypesArgs {
  distinct_on?: Maybe<Array<Brand_Boni_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Boni_Types_Order_By>>;
  where?: Maybe<Brand_Boni_Types_Bool_Exp>;
}


export interface Query_RootBrand_Boni_Types_AggregateArgs {
  distinct_on?: Maybe<Array<Brand_Boni_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Boni_Types_Order_By>>;
  where?: Maybe<Brand_Boni_Types_Bool_Exp>;
}


export interface Query_RootBrand_Boni_Types_By_PkArgs {
  brand_id: Scalars['uuid'];
  type: Scalars['String'];
}


export interface Query_RootBrand_SupplierArgs {
  brand_id: Scalars['uuid'];
  supplier_id: Scalars['uuid'];
}


export interface Query_RootBrand_SuppliersArgs {
  distinct_on?: Maybe<Array<Brand_Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Suppliers_Order_By>>;
  where?: Maybe<Brand_Suppliers_Bool_Exp>;
}


export interface Query_RootBrand_Suppliers_AggregateArgs {
  distinct_on?: Maybe<Array<Brand_Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Suppliers_Order_By>>;
  where?: Maybe<Brand_Suppliers_Bool_Exp>;
}


export interface Query_RootBrand_Vehicle_MetafieldArgs {
  distinct_on?: Maybe<Array<Brand_Vehicle_Metafield_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Vehicle_Metafield_Order_By>>;
  where?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
}


export interface Query_RootBrand_Vehicle_Metafield_AggregateArgs {
  distinct_on?: Maybe<Array<Brand_Vehicle_Metafield_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Vehicle_Metafield_Order_By>>;
  where?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
}


export interface Query_RootBrand_Vehicle_Metafield_By_PkArgs {
  brand_id: Scalars['uuid'];
  vehicle_metafield_name: Vehicle_Metafields_Enum;
}


export interface Query_RootBrandsArgs {
  distinct_on?: Maybe<Array<Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Order_By>>;
  where?: Maybe<Brand_Bool_Exp>;
}


export interface Query_RootBrands_AggregateArgs {
  distinct_on?: Maybe<Array<Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Order_By>>;
  where?: Maybe<Brand_Bool_Exp>;
}


export interface Query_RootCloudwatch_LogsArgs {
  input: CloudwatchLogsInput;
}


export interface Query_RootContact_NoteArgs {
  id: Scalars['uuid'];
}


export interface Query_RootContact_Note_TypeArgs {
  distinct_on?: Maybe<Array<Contact_Note_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Note_Type_Order_By>>;
  where?: Maybe<Contact_Note_Type_Bool_Exp>;
}


export interface Query_RootContact_Note_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Contact_Note_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Note_Type_Order_By>>;
  where?: Maybe<Contact_Note_Type_Bool_Exp>;
}


export interface Query_RootContact_Note_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootContact_NotesArgs {
  distinct_on?: Maybe<Array<ContactNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactNote_Order_By>>;
  where?: Maybe<ContactNote_Bool_Exp>;
}


export interface Query_RootContact_Notes_AggregateArgs {
  distinct_on?: Maybe<Array<ContactNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactNote_Order_By>>;
  where?: Maybe<ContactNote_Bool_Exp>;
}


export interface Query_RootCustomerArgs {
  id: Scalars['uuid'];
}


export interface Query_RootCustomer_Customer_TagArgs {
  distinct_on?: Maybe<Array<Customer_Customer_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Customer_Tag_Order_By>>;
  where?: Maybe<Customer_Customer_Tag_Bool_Exp>;
}


export interface Query_RootCustomer_Customer_Tag_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Customer_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Customer_Tag_Order_By>>;
  where?: Maybe<Customer_Customer_Tag_Bool_Exp>;
}


export interface Query_RootCustomer_Customer_Tag_By_PkArgs {
  customer_id: Scalars['uuid'];
  customer_tag_value: Customer_Tag_Enum;
}


export interface Query_RootCustomer_Gdpr_InfoArgs {
  customer_id: Scalars['uuid'];
}


export interface Query_RootCustomer_Gdpr_InfosArgs {
  distinct_on?: Maybe<Array<CustomerGdprInfo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerGdprInfo_Order_By>>;
  where?: Maybe<CustomerGdprInfo_Bool_Exp>;
}


export interface Query_RootCustomer_Gdpr_Infos_AggregateArgs {
  distinct_on?: Maybe<Array<CustomerGdprInfo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerGdprInfo_Order_By>>;
  where?: Maybe<CustomerGdprInfo_Bool_Exp>;
}


export interface Query_RootCustomer_SalutationArgs {
  distinct_on?: Maybe<Array<Customer_Salutation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Salutation_Order_By>>;
  where?: Maybe<Customer_Salutation_Bool_Exp>;
}


export interface Query_RootCustomer_Salutation_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Salutation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Salutation_Order_By>>;
  where?: Maybe<Customer_Salutation_Bool_Exp>;
}


export interface Query_RootCustomer_Salutation_By_PkArgs {
  customer_id: Scalars['uuid'];
  salutation_type_value: Salutation_Type_Enum;
}


export interface Query_RootCustomer_TagArgs {
  distinct_on?: Maybe<Array<Customer_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Tag_Order_By>>;
  where?: Maybe<Customer_Tag_Bool_Exp>;
}


export interface Query_RootCustomer_Tag_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Tag_Order_By>>;
  where?: Maybe<Customer_Tag_Bool_Exp>;
}


export interface Query_RootCustomer_Tag_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootCustomer_TypeArgs {
  distinct_on?: Maybe<Array<Customer_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Type_Order_By>>;
  where?: Maybe<Customer_Type_Bool_Exp>;
}


export interface Query_RootCustomer_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Type_Order_By>>;
  where?: Maybe<Customer_Type_Bool_Exp>;
}


export interface Query_RootCustomer_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootCustomer_VehicleArgs {
  id: Scalars['uuid'];
}


export interface Query_RootCustomer_VehiclesArgs {
  distinct_on?: Maybe<Array<CustomerVehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerVehicle_Order_By>>;
  where?: Maybe<CustomerVehicle_Bool_Exp>;
}


export interface Query_RootCustomer_Vehicles_AggregateArgs {
  distinct_on?: Maybe<Array<CustomerVehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerVehicle_Order_By>>;
  where?: Maybe<CustomerVehicle_Bool_Exp>;
}


export interface Query_RootCustomersArgs {
  distinct_on?: Maybe<Array<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
}


export interface Query_RootCustomers_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
}


export interface Query_RootDealerVehicleBrandSyncStatusArgs {
  input: DealerVehicleBrandSyncStatusInput;
}


export interface Query_RootDealer_VehicleArgs {
  id: Scalars['uuid'];
}


export interface Query_RootDealer_Vehicle_Import_TemplatesArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Import_Templates_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Import_Templates_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_Import_Templates_AggregateArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Import_Templates_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Import_Templates_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_Import_Templates_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootDealer_Vehicle_InboxArgs {
  distinct_on?: Maybe<Array<DealerVehicleInbox_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicleInbox_Order_By>>;
  where?: Maybe<DealerVehicleInbox_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_Inbox_AggregateArgs {
  distinct_on?: Maybe<Array<DealerVehicleInbox_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicleInbox_Order_By>>;
  where?: Maybe<DealerVehicleInbox_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_Inbox_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootDealer_Vehicle_Inbox_Import_TemplatesArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_Inbox_Import_Templates_AggregateArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_Inbox_Import_Templates_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootDealer_Vehicle_NoteArgs {
  id: Scalars['uuid'];
}


export interface Query_RootDealer_Vehicle_NotesArgs {
  distinct_on?: Maybe<Array<DealerVehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicleNote_Order_By>>;
  where?: Maybe<DealerVehicleNote_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_Notes_AggregateArgs {
  distinct_on?: Maybe<Array<DealerVehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicleNote_Order_By>>;
  where?: Maybe<DealerVehicleNote_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_StateArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_State_Order_By>>;
  where?: Maybe<Dealer_Vehicle_State_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_State_AggregateArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_State_Order_By>>;
  where?: Maybe<Dealer_Vehicle_State_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_State_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootDealer_Vehicle_TypeArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Type_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Type_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Type_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Type_Bool_Exp>;
}


export interface Query_RootDealer_Vehicle_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootDealer_VehiclesArgs {
  distinct_on?: Maybe<Array<DealerVehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicle_Order_By>>;
  where?: Maybe<DealerVehicle_Bool_Exp>;
}


export interface Query_RootDealer_Vehicles_AggregateArgs {
  distinct_on?: Maybe<Array<DealerVehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicle_Order_By>>;
  where?: Maybe<DealerVehicle_Bool_Exp>;
}


export interface Query_RootDepartmentArgs {
  id: Scalars['uuid'];
}


export interface Query_RootDepartment_RoleArgs {
  distinct_on?: Maybe<Array<Department_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Department_Role_Order_By>>;
  where?: Maybe<Department_Role_Bool_Exp>;
}


export interface Query_RootDepartment_Role_AggregateArgs {
  distinct_on?: Maybe<Array<Department_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Department_Role_Order_By>>;
  where?: Maybe<Department_Role_Bool_Exp>;
}


export interface Query_RootDepartment_Role_By_PkArgs {
  department_id: Scalars['uuid'];
  role: Role_Enum;
}


export interface Query_RootDepartmentsArgs {
  distinct_on?: Maybe<Array<Department_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Department_Order_By>>;
  where?: Maybe<Department_Bool_Exp>;
}


export interface Query_RootDepartments_AggregateArgs {
  distinct_on?: Maybe<Array<Department_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Department_Order_By>>;
  where?: Maybe<Department_Bool_Exp>;
}


export interface Query_RootDms_ReferenceArgs {
  id: Scalars['uuid'];
}


export interface Query_RootDms_ReferencesArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


export interface Query_RootDms_References_AggregateArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


export interface Query_RootDms_TypeArgs {
  distinct_on?: Maybe<Array<Dms_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dms_Type_Order_By>>;
  where?: Maybe<Dms_Type_Bool_Exp>;
}


export interface Query_RootDms_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Dms_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dms_Type_Order_By>>;
  where?: Maybe<Dms_Type_Bool_Exp>;
}


export interface Query_RootDms_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootEmbedded_PagesArgs {
  distinct_on?: Maybe<Array<Embedded_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Embedded_Pages_Order_By>>;
  where?: Maybe<Embedded_Pages_Bool_Exp>;
}


export interface Query_RootEmbedded_Pages_AggregateArgs {
  distinct_on?: Maybe<Array<Embedded_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Embedded_Pages_Order_By>>;
  where?: Maybe<Embedded_Pages_Bool_Exp>;
}


export interface Query_RootEmbedded_Pages_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootExcel_Import_AssignmentsArgs {
  distinct_on?: Maybe<Array<Excel_Import_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Excel_Import_Assignments_Order_By>>;
  where?: Maybe<Excel_Import_Assignments_Bool_Exp>;
}


export interface Query_RootExcel_Import_Assignments_AggregateArgs {
  distinct_on?: Maybe<Array<Excel_Import_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Excel_Import_Assignments_Order_By>>;
  where?: Maybe<Excel_Import_Assignments_Bool_Exp>;
}


export interface Query_RootExcel_Import_Assignments_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootExportAfterSalesPotentialsArgs {
  input: ExportAfterSalesPotentialsInput;
}


export interface Query_RootExportVehiclesArgs {
  variant: ExportDealerVehiclesVariant;
  where: ExportVehiclesVehicleBoolExp;
}


export interface Query_RootFinancing_TypeArgs {
  distinct_on?: Maybe<Array<Financing_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Type_Order_By>>;
  where?: Maybe<Financing_Type_Bool_Exp>;
}


export interface Query_RootFinancing_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Financing_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Type_Order_By>>;
  where?: Maybe<Financing_Type_Bool_Exp>;
}


export interface Query_RootFinancing_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootHouse_BrandsArgs {
  args?: Maybe<House_Brands_Args>;
  distinct_on?: Maybe<Array<Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Order_By>>;
  where?: Maybe<Brand_Bool_Exp>;
}


export interface Query_RootHouse_Brands_AggregateArgs {
  args?: Maybe<House_Brands_Args>;
  distinct_on?: Maybe<Array<Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Order_By>>;
  where?: Maybe<Brand_Bool_Exp>;
}


export interface Query_RootImportJobArgs {
  input: ImportJobInput;
}


export interface Query_RootImport_Job_Executable_StatusArgs {
  distinct_on?: Maybe<Array<Import_Job_Executable_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Executable_Status_Order_By>>;
  where?: Maybe<Import_Job_Executable_Status_Bool_Exp>;
}


export interface Query_RootImport_Job_Executable_Status_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Job_Executable_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Executable_Status_Order_By>>;
  where?: Maybe<Import_Job_Executable_Status_Bool_Exp>;
}


export interface Query_RootImport_Job_Executable_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootImport_Job_StatusArgs {
  distinct_on?: Maybe<Array<Import_Job_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Status_Order_By>>;
  where?: Maybe<Import_Job_Status_Bool_Exp>;
}


export interface Query_RootImport_Job_Status_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Job_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Status_Order_By>>;
  where?: Maybe<Import_Job_Status_Bool_Exp>;
}


export interface Query_RootImport_Job_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootImport_Job_Vehicle_ExecutablesArgs {
  distinct_on?: Maybe<Array<Import_Job_Vehicle_Executables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Vehicle_Executables_Order_By>>;
  where?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
}


export interface Query_RootImport_Job_Vehicle_Executables_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Job_Vehicle_Executables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Vehicle_Executables_Order_By>>;
  where?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
}


export interface Query_RootImport_Job_Vehicle_Executables_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootImport_JobsArgs {
  distinct_on?: Maybe<Array<Import_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Jobs_Order_By>>;
  where?: Maybe<Import_Jobs_Bool_Exp>;
}


export interface Query_RootImport_Jobs_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Jobs_Order_By>>;
  where?: Maybe<Import_Jobs_Bool_Exp>;
}


export interface Query_RootImport_Jobs_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootImport_TypeArgs {
  distinct_on?: Maybe<Array<Import_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Type_Order_By>>;
  where?: Maybe<Import_Type_Bool_Exp>;
}


export interface Query_RootImport_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Type_Order_By>>;
  where?: Maybe<Import_Type_Bool_Exp>;
}


export interface Query_RootImport_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootIn_Buy_ChannelArgs {
  distinct_on?: Maybe<Array<In_Buy_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<In_Buy_Channel_Order_By>>;
  where?: Maybe<In_Buy_Channel_Bool_Exp>;
}


export interface Query_RootIn_Buy_Channel_AggregateArgs {
  distinct_on?: Maybe<Array<In_Buy_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<In_Buy_Channel_Order_By>>;
  where?: Maybe<In_Buy_Channel_Bool_Exp>;
}


export interface Query_RootIn_Buy_Channel_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootLocationArgs {
  id: Scalars['uuid'];
}


export interface Query_RootLocation_Brand_ReferenceArgs {
  brand_id: Scalars['uuid'];
  location_id: Scalars['uuid'];
}


export interface Query_RootLocation_Brand_ReferencesArgs {
  distinct_on?: Maybe<Array<LocationBrandReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationBrandReference_Order_By>>;
  where?: Maybe<LocationBrandReference_Bool_Exp>;
}


export interface Query_RootLocation_Brand_References_AggregateArgs {
  distinct_on?: Maybe<Array<LocationBrandReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationBrandReference_Order_By>>;
  where?: Maybe<LocationBrandReference_Bool_Exp>;
}


export interface Query_RootLocation_GroupArgs {
  id: Scalars['uuid'];
}


export interface Query_RootLocation_Group_BrandArgs {
  distinct_on?: Maybe<Array<Location_Group_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brand_Order_By>>;
  where?: Maybe<Location_Group_Brand_Bool_Exp>;
}


export interface Query_RootLocation_Group_Brand_AggregateArgs {
  distinct_on?: Maybe<Array<Location_Group_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brand_Order_By>>;
  where?: Maybe<Location_Group_Brand_Bool_Exp>;
}


export interface Query_RootLocation_Group_Brand_By_PkArgs {
  brand_id: Scalars['uuid'];
  location_group_id: Scalars['uuid'];
}


export interface Query_RootLocation_Group_Brands_ViewArgs {
  distinct_on?: Maybe<Array<Location_Group_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brands_View_Order_By>>;
  where?: Maybe<Location_Group_Brands_View_Bool_Exp>;
}


export interface Query_RootLocation_Group_Brands_View_AggregateArgs {
  distinct_on?: Maybe<Array<Location_Group_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brands_View_Order_By>>;
  where?: Maybe<Location_Group_Brands_View_Bool_Exp>;
}


export interface Query_RootLocation_GroupsArgs {
  distinct_on?: Maybe<Array<LocationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationGroup_Order_By>>;
  where?: Maybe<LocationGroup_Bool_Exp>;
}


export interface Query_RootLocation_Groups_AggregateArgs {
  distinct_on?: Maybe<Array<LocationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationGroup_Order_By>>;
  where?: Maybe<LocationGroup_Bool_Exp>;
}


export interface Query_RootLocationsArgs {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
}


export interface Query_RootLocations_AggregateArgs {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
}


export interface Query_RootMetric_DataArgs {
  input: MetricDataInput;
}


export interface Query_RootModelArgs {
  id: Scalars['uuid'];
}


export interface Query_RootModel_GroupArgs {
  id: Scalars['uuid'];
}


export interface Query_RootModel_Group_Release_YearsArgs {
  distinct_on?: Maybe<Array<Model_Group_Release_Years_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Group_Release_Years_Order_By>>;
  where?: Maybe<Model_Group_Release_Years_Bool_Exp>;
}


export interface Query_RootModel_Group_Release_Years_AggregateArgs {
  distinct_on?: Maybe<Array<Model_Group_Release_Years_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Group_Release_Years_Order_By>>;
  where?: Maybe<Model_Group_Release_Years_Bool_Exp>;
}


export interface Query_RootModel_Group_Release_Years_By_PkArgs {
  model_group_id: Scalars['uuid'];
  release_year: Scalars['Int'];
}


export interface Query_RootModel_GroupsArgs {
  distinct_on?: Maybe<Array<ModelGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModelGroup_Order_By>>;
  where?: Maybe<ModelGroup_Bool_Exp>;
}


export interface Query_RootModel_Groups_AggregateArgs {
  distinct_on?: Maybe<Array<ModelGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModelGroup_Order_By>>;
  where?: Maybe<ModelGroup_Bool_Exp>;
}


export interface Query_RootModelsArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Query_RootModels_AggregateArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Query_RootModels_In_StockArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Query_RootModels_In_Stock_AggregateArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Query_RootNew_ModelsArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Query_RootNew_Models_AggregateArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Query_RootNoteArgs {
  id: Scalars['uuid'];
}


export interface Query_RootNotesArgs {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
}


export interface Query_RootNotification_StatusArgs {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Status_Order_By>>;
  where?: Maybe<Notification_Status_Bool_Exp>;
}


export interface Query_RootNotification_Status_AggregateArgs {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Status_Order_By>>;
  where?: Maybe<Notification_Status_Bool_Exp>;
}


export interface Query_RootNotification_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootNotification_TypeArgs {
  distinct_on?: Maybe<Array<Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Type_Order_By>>;
  where?: Maybe<Notification_Type_Bool_Exp>;
}


export interface Query_RootNotification_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Type_Order_By>>;
  where?: Maybe<Notification_Type_Bool_Exp>;
}


export interface Query_RootNotification_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootNotificationsArgs {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
}


export interface Query_RootNotifications_AggregateArgs {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
}


export interface Query_RootOrder_StateArgs {
  distinct_on?: Maybe<Array<Order_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_State_Order_By>>;
  where?: Maybe<Order_State_Bool_Exp>;
}


export interface Query_RootOrder_State_AggregateArgs {
  distinct_on?: Maybe<Array<Order_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_State_Order_By>>;
  where?: Maybe<Order_State_Bool_Exp>;
}


export interface Query_RootOrder_State_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootPhone_NumberArgs {
  id: Scalars['uuid'];
}


export interface Query_RootPhone_Number_TypeArgs {
  distinct_on?: Maybe<Array<Phone_Number_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Number_Type_Order_By>>;
  where?: Maybe<Phone_Number_Type_Bool_Exp>;
}


export interface Query_RootPhone_Number_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Phone_Number_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Number_Type_Order_By>>;
  where?: Maybe<Phone_Number_Type_Bool_Exp>;
}


export interface Query_RootPhone_Number_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootPhone_NumbersArgs {
  distinct_on?: Maybe<Array<PhoneNumber_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PhoneNumber_Order_By>>;
  where?: Maybe<PhoneNumber_Bool_Exp>;
}


export interface Query_RootPhone_Numbers_AggregateArgs {
  distinct_on?: Maybe<Array<PhoneNumber_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PhoneNumber_Order_By>>;
  where?: Maybe<PhoneNumber_Bool_Exp>;
}


export interface Query_RootRegistration_StateArgs {
  distinct_on?: Maybe<Array<Registration_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_State_Order_By>>;
  where?: Maybe<Registration_State_Bool_Exp>;
}


export interface Query_RootRegistration_State_AggregateArgs {
  distinct_on?: Maybe<Array<Registration_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_State_Order_By>>;
  where?: Maybe<Registration_State_Bool_Exp>;
}


export interface Query_RootRegistration_State_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootRoleArgs {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
}


export interface Query_RootRole_AggregateArgs {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
}


export interface Query_RootRole_By_PkArgs {
  role: Scalars['String'];
}


export interface Query_RootSales_ReportsArgs {
  distinct_on?: Maybe<Array<Sales_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sales_Reports_Order_By>>;
  where?: Maybe<Sales_Reports_Bool_Exp>;
}


export interface Query_RootSales_Reports_AggregateArgs {
  distinct_on?: Maybe<Array<Sales_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sales_Reports_Order_By>>;
  where?: Maybe<Sales_Reports_Bool_Exp>;
}


export interface Query_RootSales_Reports_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootSales_TypeArgs {
  distinct_on?: Maybe<Array<Sales_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sales_Type_Order_By>>;
  where?: Maybe<Sales_Type_Bool_Exp>;
}


export interface Query_RootSales_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Sales_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sales_Type_Order_By>>;
  where?: Maybe<Sales_Type_Bool_Exp>;
}


export interface Query_RootSales_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootSalutation_TypeArgs {
  distinct_on?: Maybe<Array<Salutation_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salutation_Type_Order_By>>;
  where?: Maybe<Salutation_Type_Bool_Exp>;
}


export interface Query_RootSalutation_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Salutation_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salutation_Type_Order_By>>;
  where?: Maybe<Salutation_Type_Bool_Exp>;
}


export interface Query_RootSalutation_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootStorage_LocationArgs {
  id: Scalars['uuid'];
}


export interface Query_RootStorage_LocationsArgs {
  distinct_on?: Maybe<Array<StorageLocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StorageLocation_Order_By>>;
  where?: Maybe<StorageLocation_Bool_Exp>;
}


export interface Query_RootStorage_Locations_AggregateArgs {
  distinct_on?: Maybe<Array<StorageLocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StorageLocation_Order_By>>;
  where?: Maybe<StorageLocation_Bool_Exp>;
}


export interface Query_RootSubdealerArgs {
  id: Scalars['uuid'];
}


export interface Query_RootSubdealer_BrandArgs {
  distinct_on?: Maybe<Array<Subdealer_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brand_Order_By>>;
  where?: Maybe<Subdealer_Brand_Bool_Exp>;
}


export interface Query_RootSubdealer_Brand_AggregateArgs {
  distinct_on?: Maybe<Array<Subdealer_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brand_Order_By>>;
  where?: Maybe<Subdealer_Brand_Bool_Exp>;
}


export interface Query_RootSubdealer_Brand_By_PkArgs {
  brand_id: Scalars['uuid'];
  subdealer_id: Scalars['uuid'];
}


export interface Query_RootSubdealer_Brands_ViewArgs {
  distinct_on?: Maybe<Array<Subdealer_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brands_View_Order_By>>;
  where?: Maybe<Subdealer_Brands_View_Bool_Exp>;
}


export interface Query_RootSubdealer_Brands_View_AggregateArgs {
  distinct_on?: Maybe<Array<Subdealer_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brands_View_Order_By>>;
  where?: Maybe<Subdealer_Brands_View_Bool_Exp>;
}


export interface Query_RootSubdealersArgs {
  distinct_on?: Maybe<Array<Subdealer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Order_By>>;
  where?: Maybe<Subdealer_Bool_Exp>;
}


export interface Query_RootSubdealers_AggregateArgs {
  distinct_on?: Maybe<Array<Subdealer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Order_By>>;
  where?: Maybe<Subdealer_Bool_Exp>;
}


export interface Query_RootSupplierArgs {
  id: Scalars['uuid'];
}


export interface Query_RootSuppliersArgs {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
}


export interface Query_RootSuppliers_AggregateArgs {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
}


export interface Query_RootSync_ErrorsArgs {
  distinct_on?: Maybe<Array<Sync_Errors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Errors_Order_By>>;
  where?: Maybe<Sync_Errors_Bool_Exp>;
}


export interface Query_RootSync_Errors_AggregateArgs {
  distinct_on?: Maybe<Array<Sync_Errors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Errors_Order_By>>;
  where?: Maybe<Sync_Errors_Bool_Exp>;
}


export interface Query_RootSync_Errors_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootTask_CategoryArgs {
  distinct_on?: Maybe<Array<Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Category_Order_By>>;
  where?: Maybe<Task_Category_Bool_Exp>;
}


export interface Query_RootTask_Category_AggregateArgs {
  distinct_on?: Maybe<Array<Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Category_Order_By>>;
  where?: Maybe<Task_Category_Bool_Exp>;
}


export interface Query_RootTask_Category_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootTask_TypeArgs {
  code: Scalars['String'];
}


export interface Query_RootTask_Type_Task_CategoryArgs {
  distinct_on?: Maybe<Array<Task_Type_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Type_Task_Category_Order_By>>;
  where?: Maybe<Task_Type_Task_Category_Bool_Exp>;
}


export interface Query_RootTask_Type_Task_Category_AggregateArgs {
  distinct_on?: Maybe<Array<Task_Type_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Type_Task_Category_Order_By>>;
  where?: Maybe<Task_Type_Task_Category_Bool_Exp>;
}


export interface Query_RootTask_Type_Task_Category_By_PkArgs {
  task_category_value: Task_Category_Enum;
  task_type_code: Scalars['String'];
}


export interface Query_RootTask_TypesArgs {
  distinct_on?: Maybe<Array<TaskType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TaskType_Order_By>>;
  where?: Maybe<TaskType_Bool_Exp>;
}


export interface Query_RootTask_Types_AggregateArgs {
  distinct_on?: Maybe<Array<TaskType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TaskType_Order_By>>;
  where?: Maybe<TaskType_Bool_Exp>;
}


export interface Query_RootTeam_AssignmentArgs {
  id: Scalars['uuid'];
}


export interface Query_RootTeam_AssignmentsArgs {
  distinct_on?: Maybe<Array<TeamAssignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamAssignment_Order_By>>;
  where?: Maybe<TeamAssignment_Bool_Exp>;
}


export interface Query_RootTeam_Assignments_AggregateArgs {
  distinct_on?: Maybe<Array<TeamAssignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamAssignment_Order_By>>;
  where?: Maybe<TeamAssignment_Bool_Exp>;
}


export interface Query_RootTicketArgs {
  id: Scalars['uuid'];
}


export interface Query_RootTicket_Family_NotificationsArgs {
  distinct_on?: Maybe<Array<Ticket_Family_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Family_Notifications_Order_By>>;
  where?: Maybe<Ticket_Family_Notifications_Bool_Exp>;
}


export interface Query_RootTicket_Family_Notifications_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Family_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Family_Notifications_Order_By>>;
  where?: Maybe<Ticket_Family_Notifications_Bool_Exp>;
}


export interface Query_RootTicket_Family_Notifications_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootTicket_NoteArgs {
  id: Scalars['uuid'];
}


export interface Query_RootTicket_NotesArgs {
  distinct_on?: Maybe<Array<TicketNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TicketNote_Order_By>>;
  where?: Maybe<TicketNote_Bool_Exp>;
}


export interface Query_RootTicket_Notes_AggregateArgs {
  distinct_on?: Maybe<Array<TicketNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TicketNote_Order_By>>;
  where?: Maybe<TicketNote_Bool_Exp>;
}


export interface Query_RootTicket_PriorityArgs {
  distinct_on?: Maybe<Array<Ticket_Priority_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Priority_Order_By>>;
  where?: Maybe<Ticket_Priority_Bool_Exp>;
}


export interface Query_RootTicket_Priority_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Priority_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Priority_Order_By>>;
  where?: Maybe<Ticket_Priority_Bool_Exp>;
}


export interface Query_RootTicket_Priority_By_PkArgs {
  name: Scalars['String'];
}


export interface Query_RootTicket_StateArgs {
  distinct_on?: Maybe<Array<Ticket_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_State_Order_By>>;
  where?: Maybe<Ticket_State_Bool_Exp>;
}


export interface Query_RootTicket_State_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_State_Order_By>>;
  where?: Maybe<Ticket_State_Bool_Exp>;
}


export interface Query_RootTicket_State_By_PkArgs {
  name: Scalars['String'];
}


export interface Query_RootTicket_TemplateArgs {
  id: Scalars['uuid'];
}


export interface Query_RootTicket_Template_Assignee_RefArgs {
  distinct_on?: Maybe<Array<Ticket_Template_Assignee_Ref_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Template_Assignee_Ref_Order_By>>;
  where?: Maybe<Ticket_Template_Assignee_Ref_Bool_Exp>;
}


export interface Query_RootTicket_Template_Assignee_Ref_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Template_Assignee_Ref_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Template_Assignee_Ref_Order_By>>;
  where?: Maybe<Ticket_Template_Assignee_Ref_Bool_Exp>;
}


export interface Query_RootTicket_Template_Assignee_Ref_By_PkArgs {
  name: Scalars['String'];
}


export interface Query_RootTicket_Template_Location_RefArgs {
  distinct_on?: Maybe<Array<Ticket_Template_Location_Ref_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Template_Location_Ref_Order_By>>;
  where?: Maybe<Ticket_Template_Location_Ref_Bool_Exp>;
}


export interface Query_RootTicket_Template_Location_Ref_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Template_Location_Ref_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Template_Location_Ref_Order_By>>;
  where?: Maybe<Ticket_Template_Location_Ref_Bool_Exp>;
}


export interface Query_RootTicket_Template_Location_Ref_By_PkArgs {
  name: Scalars['String'];
}


export interface Query_RootTicket_TemplatesArgs {
  distinct_on?: Maybe<Array<TicketTemplate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TicketTemplate_Order_By>>;
  where?: Maybe<TicketTemplate_Bool_Exp>;
}


export interface Query_RootTicket_Templates_AggregateArgs {
  distinct_on?: Maybe<Array<TicketTemplate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TicketTemplate_Order_By>>;
  where?: Maybe<TicketTemplate_Bool_Exp>;
}


export interface Query_RootTicketsArgs {
  distinct_on?: Maybe<Array<Ticket_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Order_By>>;
  where?: Maybe<Ticket_Bool_Exp>;
}


export interface Query_RootTickets_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Order_By>>;
  where?: Maybe<Ticket_Bool_Exp>;
}


export interface Query_RootTransactionsArgs {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
}


export interface Query_RootTransactions_AggregateArgs {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
}


export interface Query_RootTransactions_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootUserArgs {
  id: Scalars['uuid'];
}


export interface Query_RootUser_RolesArgs {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
}


export interface Query_RootUser_Roles_AggregateArgs {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
}


export interface Query_RootUsersArgs {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
}


export interface Query_RootUsers_AggregateArgs {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
}


export interface Query_RootVehicleArgs {
  business_case_number: Scalars['Int'];
  id: Scalars['uuid'];
  version: Scalars['Int'];
}


export interface Query_RootVehicle_BoniArgs {
  distinct_on?: Maybe<Array<Vehicle_Boni_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Boni_Order_By>>;
  where?: Maybe<Vehicle_Boni_Bool_Exp>;
}


export interface Query_RootVehicle_Boni_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Boni_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Boni_Order_By>>;
  where?: Maybe<Vehicle_Boni_Bool_Exp>;
}


export interface Query_RootVehicle_Boni_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootVehicle_Import_TemplatesArgs {
  distinct_on?: Maybe<Array<Vehicle_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Import_Templates_Order_By>>;
  where?: Maybe<Vehicle_Import_Templates_Bool_Exp>;
}


export interface Query_RootVehicle_Import_Templates_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Import_Templates_Order_By>>;
  where?: Maybe<Vehicle_Import_Templates_Bool_Exp>;
}


export interface Query_RootVehicle_Import_Templates_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootVehicle_MetafieldsArgs {
  distinct_on?: Maybe<Array<Vehicle_Metafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Metafields_Order_By>>;
  where?: Maybe<Vehicle_Metafields_Bool_Exp>;
}


export interface Query_RootVehicle_Metafields_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Metafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Metafields_Order_By>>;
  where?: Maybe<Vehicle_Metafields_Bool_Exp>;
}


export interface Query_RootVehicle_Metafields_By_PkArgs {
  column_name: Scalars['String'];
}


export interface Query_RootVehicle_NoteArgs {
  id: Scalars['uuid'];
}


export interface Query_RootVehicle_NotesArgs {
  distinct_on?: Maybe<Array<VehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VehicleNote_Order_By>>;
  where?: Maybe<VehicleNote_Bool_Exp>;
}


export interface Query_RootVehicle_Notes_AggregateArgs {
  distinct_on?: Maybe<Array<VehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VehicleNote_Order_By>>;
  where?: Maybe<VehicleNote_Bool_Exp>;
}


export interface Query_RootVehicle_Owner_InformationsArgs {
  distinct_on?: Maybe<Array<Vehicle_Owner_Informations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Owner_Informations_Order_By>>;
  where?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
}


export interface Query_RootVehicle_Owner_Informations_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Owner_Informations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Owner_Informations_Order_By>>;
  where?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
}


export interface Query_RootVehicle_Owner_Informations_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Query_RootVehiclesArgs {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
}


export interface Query_RootVehiclesWithMixedUpInvoicesArgs {
  input?: Maybe<VehiclesWithMixedUpInvoicesInput>;
}


export interface Query_RootVehicles_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
}


export interface Query_RootWork_Order_StatusArgs {
  distinct_on?: Maybe<Array<Work_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Order_Status_Order_By>>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
}


export interface Query_RootWork_Order_Status_AggregateArgs {
  distinct_on?: Maybe<Array<Work_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Order_Status_Order_By>>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
}


export interface Query_RootWork_Order_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Query_RootWork_Order_Task_CategoryArgs {
  distinct_on?: Maybe<Array<Work_Order_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Order_Task_Category_Order_By>>;
  where?: Maybe<Work_Order_Task_Category_Bool_Exp>;
}


export interface Query_RootWork_Order_Task_Category_AggregateArgs {
  distinct_on?: Maybe<Array<Work_Order_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Order_Task_Category_Order_By>>;
  where?: Maybe<Work_Order_Task_Category_Bool_Exp>;
}


export interface Query_RootWork_Order_Task_Category_By_PkArgs {
  task_category: Task_Category_Enum;
  work_order_id: Scalars['uuid'];
}


export interface Query_RootWork_OrdersArgs {
  distinct_on?: Maybe<Array<Work_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Orders_Order_By>>;
  where?: Maybe<Work_Orders_Bool_Exp>;
}


export interface Query_RootWork_Orders_AggregateArgs {
  distinct_on?: Maybe<Array<Work_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Orders_Order_By>>;
  where?: Maybe<Work_Orders_Bool_Exp>;
}


export interface Query_RootWork_Orders_By_PkArgs {
  id: Scalars['uuid'];
}

/** columns and relationships of "registration_state" */
export interface Registration_State {
  __typename?: 'registration_state';
  comment: Scalars['String'];
  value: Scalars['String'];
}

/** aggregated selection of "registration_state" */
export interface Registration_State_Aggregate {
  __typename?: 'registration_state_aggregate';
  aggregate?: Maybe<Registration_State_Aggregate_Fields>;
  nodes: Array<Registration_State>;
}

/** aggregate fields of "registration_state" */
export interface Registration_State_Aggregate_Fields {
  __typename?: 'registration_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Registration_State_Max_Fields>;
  min?: Maybe<Registration_State_Min_Fields>;
}


/** aggregate fields of "registration_state" */
export interface Registration_State_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Registration_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "registration_state". All fields are combined with a logical 'AND'. */
export interface Registration_State_Bool_Exp {
  _and?: Maybe<Array<Registration_State_Bool_Exp>>;
  _not?: Maybe<Registration_State_Bool_Exp>;
  _or?: Maybe<Array<Registration_State_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "registration_state" */
export enum Registration_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  RegistrationStatePkey = 'registration_state_pkey'
}

export enum Registration_State_Enum {
  Deposited = 'DEPOSITED',
  None = 'NONE',
  Registered = 'REGISTERED',
  Unregistered = 'UNREGISTERED'
}

/** Boolean expression to compare columns of type "registration_state_enum". All fields are combined with logical 'AND'. */
export interface Registration_State_Enum_Comparison_Exp {
  _eq?: Maybe<Registration_State_Enum>;
  _in?: Maybe<Array<Registration_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Registration_State_Enum>;
  _nin?: Maybe<Array<Registration_State_Enum>>;
}

/** input type for inserting data into table "registration_state" */
export interface Registration_State_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Registration_State_Max_Fields {
  __typename?: 'registration_state_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Registration_State_Min_Fields {
  __typename?: 'registration_state_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "registration_state" */
export interface Registration_State_Mutation_Response {
  __typename?: 'registration_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Registration_State>;
}

/** on_conflict condition type for table "registration_state" */
export interface Registration_State_On_Conflict {
  constraint: Registration_State_Constraint;
  update_columns: Array<Registration_State_Update_Column>;
  where?: Maybe<Registration_State_Bool_Exp>;
}

/** Ordering options when selecting data from "registration_state". */
export interface Registration_State_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: registration_state */
export interface Registration_State_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "registration_state" */
export enum Registration_State_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "registration_state" */
export interface Registration_State_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "registration_state" */
export interface Registration_State_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Registration_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Registration_State_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "registration_state" */
export enum Registration_State_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Registration_State_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Registration_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Registration_State_Bool_Exp;
}

/** columns and relationships of "role" */
export interface Role {
  __typename?: 'role';
  role: Scalars['String'];
}

/** aggregated selection of "role" */
export interface Role_Aggregate {
  __typename?: 'role_aggregate';
  aggregate?: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
}

/** aggregate fields of "role" */
export interface Role_Aggregate_Fields {
  __typename?: 'role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Max_Fields>;
  min?: Maybe<Role_Min_Fields>;
}


/** aggregate fields of "role" */
export interface Role_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export interface Role_Bool_Exp {
  _and?: Maybe<Array<Role_Bool_Exp>>;
  _not?: Maybe<Role_Bool_Exp>;
  _or?: Maybe<Array<Role_Bool_Exp>>;
  role?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "role" */
  UserRolePkey = 'user_role_pkey'
}

export enum Role_Enum {
  Admin = 'admin',
  Sales = 'sales',
  SalesManager = 'sales_manager',
  User = 'user',
  VehicleDispatcher = 'vehicle_dispatcher'
}

/** Boolean expression to compare columns of type "role_enum". All fields are combined with logical 'AND'. */
export interface Role_Enum_Comparison_Exp {
  _eq?: Maybe<Role_Enum>;
  _in?: Maybe<Array<Role_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Role_Enum>;
  _nin?: Maybe<Array<Role_Enum>>;
}

/** input type for inserting data into table "role" */
export interface Role_Insert_Input {
  role?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Role_Max_Fields {
  __typename?: 'role_max_fields';
  role?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Role_Min_Fields {
  __typename?: 'role_min_fields';
  role?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "role" */
export interface Role_Mutation_Response {
  __typename?: 'role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
}

/** on_conflict condition type for table "role" */
export interface Role_On_Conflict {
  constraint: Role_Constraint;
  update_columns: Array<Role_Update_Column>;
  where?: Maybe<Role_Bool_Exp>;
}

/** Ordering options when selecting data from "role". */
export interface Role_Order_By {
  role?: Maybe<Order_By>;
}

/** primary key columns input for table: role */
export interface Role_Pk_Columns_Input {
  role: Scalars['String'];
}

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "role" */
export interface Role_Set_Input {
  role?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "role" */
export interface Role_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Role_Stream_Cursor_Value_Input {
  role?: Maybe<Scalars['String']>;
}

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  Role = 'role'
}

export interface Role_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Bool_Exp;
}

/** columns and relationships of "sales_reports" */
export interface Sales_Reports {
  __typename?: 'sales_reports';
  aid?: Maybe<Scalars['String']>;
  /** An object relationship */
  brand: Brand;
  brand_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  customer_name: Scalars['String'];
  customer_number?: Maybe<Scalars['String']>;
  customer_type: Customer_Type_Enum;
  date: Scalars['date'];
  /** An object relationship */
  exchange_brand?: Maybe<Brand>;
  exchange_brand_id?: Maybe<Scalars['uuid']>;
  exchange_model_name?: Maybe<Scalars['String']>;
  exchange_price?: Maybe<Scalars['numeric']>;
  exchange_vin?: Maybe<Scalars['String']>;
  expected_sales_price: Scalars['numeric'];
  extras?: Maybe<Scalars['jsonb']>;
  financing_bank_name?: Maybe<Scalars['String']>;
  financing_product?: Maybe<Scalars['String']>;
  financing_type?: Maybe<Financing_Type_Enum>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  insurance_product_name?: Maybe<Scalars['String']>;
  insurance_provider_name?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  list_price: Scalars['numeric'];
  model_name: Scalars['String'];
  price_discussed_with?: Maybe<Scalars['String']>;
  sales_type: Sales_Type_Enum;
  /** An object relationship */
  seller: User;
  seller_id: Scalars['uuid'];
  selling_price: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  vehicle_type: Dealer_Vehicle_Type_Enum;
  vin: Scalars['String'];
  warranty_insurance_years?: Maybe<Scalars['Int']>;
  winter_tires?: Maybe<Scalars['Boolean']>;
  winter_tires_price?: Maybe<Scalars['numeric']>;
  zip_code: Scalars['String'];
}


/** columns and relationships of "sales_reports" */
export interface Sales_ReportsExtrasArgs {
  path?: Maybe<Scalars['String']>;
}

/** aggregated selection of "sales_reports" */
export interface Sales_Reports_Aggregate {
  __typename?: 'sales_reports_aggregate';
  aggregate?: Maybe<Sales_Reports_Aggregate_Fields>;
  nodes: Array<Sales_Reports>;
}

/** aggregate fields of "sales_reports" */
export interface Sales_Reports_Aggregate_Fields {
  __typename?: 'sales_reports_aggregate_fields';
  avg?: Maybe<Sales_Reports_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sales_Reports_Max_Fields>;
  min?: Maybe<Sales_Reports_Min_Fields>;
  stddev?: Maybe<Sales_Reports_Stddev_Fields>;
  stddev_pop?: Maybe<Sales_Reports_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sales_Reports_Stddev_Samp_Fields>;
  sum?: Maybe<Sales_Reports_Sum_Fields>;
  var_pop?: Maybe<Sales_Reports_Var_Pop_Fields>;
  var_samp?: Maybe<Sales_Reports_Var_Samp_Fields>;
  variance?: Maybe<Sales_Reports_Variance_Fields>;
}


/** aggregate fields of "sales_reports" */
export interface Sales_Reports_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Sales_Reports_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Sales_Reports_Append_Input {
  extras?: Maybe<Scalars['jsonb']>;
}

/** aggregate avg on columns */
export interface Sales_Reports_Avg_Fields {
  __typename?: 'sales_reports_avg_fields';
  exchange_price?: Maybe<Scalars['Float']>;
  expected_sales_price?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  selling_price?: Maybe<Scalars['Float']>;
  warranty_insurance_years?: Maybe<Scalars['Float']>;
  winter_tires_price?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "sales_reports". All fields are combined with a logical 'AND'. */
export interface Sales_Reports_Bool_Exp {
  _and?: Maybe<Array<Sales_Reports_Bool_Exp>>;
  _not?: Maybe<Sales_Reports_Bool_Exp>;
  _or?: Maybe<Array<Sales_Reports_Bool_Exp>>;
  aid?: Maybe<String_Comparison_Exp>;
  brand?: Maybe<Brand_Bool_Exp>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_name?: Maybe<String_Comparison_Exp>;
  customer_number?: Maybe<String_Comparison_Exp>;
  customer_type?: Maybe<Customer_Type_Enum_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  exchange_brand?: Maybe<Brand_Bool_Exp>;
  exchange_brand_id?: Maybe<Uuid_Comparison_Exp>;
  exchange_model_name?: Maybe<String_Comparison_Exp>;
  exchange_price?: Maybe<Numeric_Comparison_Exp>;
  exchange_vin?: Maybe<String_Comparison_Exp>;
  expected_sales_price?: Maybe<Numeric_Comparison_Exp>;
  extras?: Maybe<Jsonb_Comparison_Exp>;
  financing_bank_name?: Maybe<String_Comparison_Exp>;
  financing_product?: Maybe<String_Comparison_Exp>;
  financing_type?: Maybe<Financing_Type_Enum_Comparison_Exp>;
  handover_planned_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  insurance_product_name?: Maybe<String_Comparison_Exp>;
  insurance_provider_name?: Maybe<String_Comparison_Exp>;
  job_number?: Maybe<String_Comparison_Exp>;
  list_price?: Maybe<Numeric_Comparison_Exp>;
  model_name?: Maybe<String_Comparison_Exp>;
  price_discussed_with?: Maybe<String_Comparison_Exp>;
  sales_type?: Maybe<Sales_Type_Enum_Comparison_Exp>;
  seller?: Maybe<User_Bool_Exp>;
  seller_id?: Maybe<Uuid_Comparison_Exp>;
  selling_price?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle_type?: Maybe<Dealer_Vehicle_Type_Enum_Comparison_Exp>;
  vin?: Maybe<String_Comparison_Exp>;
  warranty_insurance_years?: Maybe<Int_Comparison_Exp>;
  winter_tires?: Maybe<Boolean_Comparison_Exp>;
  winter_tires_price?: Maybe<Numeric_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "sales_reports" */
export enum Sales_Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  SalesReportsPkey = 'sales_reports_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Sales_Reports_Delete_At_Path_Input {
  extras?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Sales_Reports_Delete_Elem_Input {
  extras?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Sales_Reports_Delete_Key_Input {
  extras?: Maybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "sales_reports" */
export interface Sales_Reports_Inc_Input {
  exchange_price?: Maybe<Scalars['numeric']>;
  expected_sales_price?: Maybe<Scalars['numeric']>;
  list_price?: Maybe<Scalars['numeric']>;
  selling_price?: Maybe<Scalars['numeric']>;
  warranty_insurance_years?: Maybe<Scalars['Int']>;
  winter_tires_price?: Maybe<Scalars['numeric']>;
}

/** input type for inserting data into table "sales_reports" */
export interface Sales_Reports_Insert_Input {
  aid?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand_Obj_Rel_Insert_Input>;
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_number?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Customer_Type_Enum>;
  date?: Maybe<Scalars['date']>;
  exchange_brand?: Maybe<Brand_Obj_Rel_Insert_Input>;
  exchange_brand_id?: Maybe<Scalars['uuid']>;
  exchange_model_name?: Maybe<Scalars['String']>;
  exchange_price?: Maybe<Scalars['numeric']>;
  exchange_vin?: Maybe<Scalars['String']>;
  expected_sales_price?: Maybe<Scalars['numeric']>;
  extras?: Maybe<Scalars['jsonb']>;
  financing_bank_name?: Maybe<Scalars['String']>;
  financing_product?: Maybe<Scalars['String']>;
  financing_type?: Maybe<Financing_Type_Enum>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_product_name?: Maybe<Scalars['String']>;
  insurance_provider_name?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  model_name?: Maybe<Scalars['String']>;
  price_discussed_with?: Maybe<Scalars['String']>;
  sales_type?: Maybe<Sales_Type_Enum>;
  seller?: Maybe<User_Obj_Rel_Insert_Input>;
  seller_id?: Maybe<Scalars['uuid']>;
  selling_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_type?: Maybe<Dealer_Vehicle_Type_Enum>;
  vin?: Maybe<Scalars['String']>;
  warranty_insurance_years?: Maybe<Scalars['Int']>;
  winter_tires?: Maybe<Scalars['Boolean']>;
  winter_tires_price?: Maybe<Scalars['numeric']>;
  zip_code?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Sales_Reports_Max_Fields {
  __typename?: 'sales_reports_max_fields';
  aid?: Maybe<Scalars['String']>;
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_number?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  exchange_brand_id?: Maybe<Scalars['uuid']>;
  exchange_model_name?: Maybe<Scalars['String']>;
  exchange_price?: Maybe<Scalars['numeric']>;
  exchange_vin?: Maybe<Scalars['String']>;
  expected_sales_price?: Maybe<Scalars['numeric']>;
  financing_bank_name?: Maybe<Scalars['String']>;
  financing_product?: Maybe<Scalars['String']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_product_name?: Maybe<Scalars['String']>;
  insurance_provider_name?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  model_name?: Maybe<Scalars['String']>;
  price_discussed_with?: Maybe<Scalars['String']>;
  seller_id?: Maybe<Scalars['uuid']>;
  selling_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_insurance_years?: Maybe<Scalars['Int']>;
  winter_tires_price?: Maybe<Scalars['numeric']>;
  zip_code?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Sales_Reports_Min_Fields {
  __typename?: 'sales_reports_min_fields';
  aid?: Maybe<Scalars['String']>;
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_number?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  exchange_brand_id?: Maybe<Scalars['uuid']>;
  exchange_model_name?: Maybe<Scalars['String']>;
  exchange_price?: Maybe<Scalars['numeric']>;
  exchange_vin?: Maybe<Scalars['String']>;
  expected_sales_price?: Maybe<Scalars['numeric']>;
  financing_bank_name?: Maybe<Scalars['String']>;
  financing_product?: Maybe<Scalars['String']>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_product_name?: Maybe<Scalars['String']>;
  insurance_provider_name?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  model_name?: Maybe<Scalars['String']>;
  price_discussed_with?: Maybe<Scalars['String']>;
  seller_id?: Maybe<Scalars['uuid']>;
  selling_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  warranty_insurance_years?: Maybe<Scalars['Int']>;
  winter_tires_price?: Maybe<Scalars['numeric']>;
  zip_code?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "sales_reports" */
export interface Sales_Reports_Mutation_Response {
  __typename?: 'sales_reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sales_Reports>;
}

/** on_conflict condition type for table "sales_reports" */
export interface Sales_Reports_On_Conflict {
  constraint: Sales_Reports_Constraint;
  update_columns: Array<Sales_Reports_Update_Column>;
  where?: Maybe<Sales_Reports_Bool_Exp>;
}

/** Ordering options when selecting data from "sales_reports". */
export interface Sales_Reports_Order_By {
  aid?: Maybe<Order_By>;
  brand?: Maybe<Brand_Order_By>;
  brand_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_name?: Maybe<Order_By>;
  customer_number?: Maybe<Order_By>;
  customer_type?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  exchange_brand?: Maybe<Brand_Order_By>;
  exchange_brand_id?: Maybe<Order_By>;
  exchange_model_name?: Maybe<Order_By>;
  exchange_price?: Maybe<Order_By>;
  exchange_vin?: Maybe<Order_By>;
  expected_sales_price?: Maybe<Order_By>;
  extras?: Maybe<Order_By>;
  financing_bank_name?: Maybe<Order_By>;
  financing_product?: Maybe<Order_By>;
  financing_type?: Maybe<Order_By>;
  handover_planned_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_product_name?: Maybe<Order_By>;
  insurance_provider_name?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  list_price?: Maybe<Order_By>;
  model_name?: Maybe<Order_By>;
  price_discussed_with?: Maybe<Order_By>;
  sales_type?: Maybe<Order_By>;
  seller?: Maybe<User_Order_By>;
  seller_id?: Maybe<Order_By>;
  selling_price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_type?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  warranty_insurance_years?: Maybe<Order_By>;
  winter_tires?: Maybe<Order_By>;
  winter_tires_price?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
}

/** primary key columns input for table: sales_reports */
export interface Sales_Reports_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Sales_Reports_Prepend_Input {
  extras?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "sales_reports" */
export enum Sales_Reports_Select_Column {
  /** column name */
  Aid = 'aid',
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  CustomerNumber = 'customer_number',
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  Date = 'date',
  /** column name */
  ExchangeBrandId = 'exchange_brand_id',
  /** column name */
  ExchangeModelName = 'exchange_model_name',
  /** column name */
  ExchangePrice = 'exchange_price',
  /** column name */
  ExchangeVin = 'exchange_vin',
  /** column name */
  ExpectedSalesPrice = 'expected_sales_price',
  /** column name */
  Extras = 'extras',
  /** column name */
  FinancingBankName = 'financing_bank_name',
  /** column name */
  FinancingProduct = 'financing_product',
  /** column name */
  FinancingType = 'financing_type',
  /** column name */
  HandoverPlannedDate = 'handover_planned_date',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceProductName = 'insurance_product_name',
  /** column name */
  InsuranceProviderName = 'insurance_provider_name',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  ListPrice = 'list_price',
  /** column name */
  ModelName = 'model_name',
  /** column name */
  PriceDiscussedWith = 'price_discussed_with',
  /** column name */
  SalesType = 'sales_type',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  SellingPrice = 'selling_price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleType = 'vehicle_type',
  /** column name */
  Vin = 'vin',
  /** column name */
  WarrantyInsuranceYears = 'warranty_insurance_years',
  /** column name */
  WinterTires = 'winter_tires',
  /** column name */
  WinterTiresPrice = 'winter_tires_price',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "sales_reports" */
export interface Sales_Reports_Set_Input {
  aid?: Maybe<Scalars['String']>;
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_number?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Customer_Type_Enum>;
  date?: Maybe<Scalars['date']>;
  exchange_brand_id?: Maybe<Scalars['uuid']>;
  exchange_model_name?: Maybe<Scalars['String']>;
  exchange_price?: Maybe<Scalars['numeric']>;
  exchange_vin?: Maybe<Scalars['String']>;
  expected_sales_price?: Maybe<Scalars['numeric']>;
  extras?: Maybe<Scalars['jsonb']>;
  financing_bank_name?: Maybe<Scalars['String']>;
  financing_product?: Maybe<Scalars['String']>;
  financing_type?: Maybe<Financing_Type_Enum>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_product_name?: Maybe<Scalars['String']>;
  insurance_provider_name?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  model_name?: Maybe<Scalars['String']>;
  price_discussed_with?: Maybe<Scalars['String']>;
  sales_type?: Maybe<Sales_Type_Enum>;
  seller_id?: Maybe<Scalars['uuid']>;
  selling_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_type?: Maybe<Dealer_Vehicle_Type_Enum>;
  vin?: Maybe<Scalars['String']>;
  warranty_insurance_years?: Maybe<Scalars['Int']>;
  winter_tires?: Maybe<Scalars['Boolean']>;
  winter_tires_price?: Maybe<Scalars['numeric']>;
  zip_code?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface Sales_Reports_Stddev_Fields {
  __typename?: 'sales_reports_stddev_fields';
  exchange_price?: Maybe<Scalars['Float']>;
  expected_sales_price?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  selling_price?: Maybe<Scalars['Float']>;
  warranty_insurance_years?: Maybe<Scalars['Float']>;
  winter_tires_price?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Sales_Reports_Stddev_Pop_Fields {
  __typename?: 'sales_reports_stddev_pop_fields';
  exchange_price?: Maybe<Scalars['Float']>;
  expected_sales_price?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  selling_price?: Maybe<Scalars['Float']>;
  warranty_insurance_years?: Maybe<Scalars['Float']>;
  winter_tires_price?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Sales_Reports_Stddev_Samp_Fields {
  __typename?: 'sales_reports_stddev_samp_fields';
  exchange_price?: Maybe<Scalars['Float']>;
  expected_sales_price?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  selling_price?: Maybe<Scalars['Float']>;
  warranty_insurance_years?: Maybe<Scalars['Float']>;
  winter_tires_price?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "sales_reports" */
export interface Sales_Reports_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Sales_Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Sales_Reports_Stream_Cursor_Value_Input {
  aid?: Maybe<Scalars['String']>;
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_number?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Customer_Type_Enum>;
  date?: Maybe<Scalars['date']>;
  exchange_brand_id?: Maybe<Scalars['uuid']>;
  exchange_model_name?: Maybe<Scalars['String']>;
  exchange_price?: Maybe<Scalars['numeric']>;
  exchange_vin?: Maybe<Scalars['String']>;
  expected_sales_price?: Maybe<Scalars['numeric']>;
  extras?: Maybe<Scalars['jsonb']>;
  financing_bank_name?: Maybe<Scalars['String']>;
  financing_product?: Maybe<Scalars['String']>;
  financing_type?: Maybe<Financing_Type_Enum>;
  handover_planned_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_product_name?: Maybe<Scalars['String']>;
  insurance_provider_name?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  list_price?: Maybe<Scalars['numeric']>;
  model_name?: Maybe<Scalars['String']>;
  price_discussed_with?: Maybe<Scalars['String']>;
  sales_type?: Maybe<Sales_Type_Enum>;
  seller_id?: Maybe<Scalars['uuid']>;
  selling_price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_type?: Maybe<Dealer_Vehicle_Type_Enum>;
  vin?: Maybe<Scalars['String']>;
  warranty_insurance_years?: Maybe<Scalars['Int']>;
  winter_tires?: Maybe<Scalars['Boolean']>;
  winter_tires_price?: Maybe<Scalars['numeric']>;
  zip_code?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Sales_Reports_Sum_Fields {
  __typename?: 'sales_reports_sum_fields';
  exchange_price?: Maybe<Scalars['numeric']>;
  expected_sales_price?: Maybe<Scalars['numeric']>;
  list_price?: Maybe<Scalars['numeric']>;
  selling_price?: Maybe<Scalars['numeric']>;
  warranty_insurance_years?: Maybe<Scalars['Int']>;
  winter_tires_price?: Maybe<Scalars['numeric']>;
}

/** update columns of table "sales_reports" */
export enum Sales_Reports_Update_Column {
  /** column name */
  Aid = 'aid',
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  CustomerNumber = 'customer_number',
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  Date = 'date',
  /** column name */
  ExchangeBrandId = 'exchange_brand_id',
  /** column name */
  ExchangeModelName = 'exchange_model_name',
  /** column name */
  ExchangePrice = 'exchange_price',
  /** column name */
  ExchangeVin = 'exchange_vin',
  /** column name */
  ExpectedSalesPrice = 'expected_sales_price',
  /** column name */
  Extras = 'extras',
  /** column name */
  FinancingBankName = 'financing_bank_name',
  /** column name */
  FinancingProduct = 'financing_product',
  /** column name */
  FinancingType = 'financing_type',
  /** column name */
  HandoverPlannedDate = 'handover_planned_date',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceProductName = 'insurance_product_name',
  /** column name */
  InsuranceProviderName = 'insurance_provider_name',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  ListPrice = 'list_price',
  /** column name */
  ModelName = 'model_name',
  /** column name */
  PriceDiscussedWith = 'price_discussed_with',
  /** column name */
  SalesType = 'sales_type',
  /** column name */
  SellerId = 'seller_id',
  /** column name */
  SellingPrice = 'selling_price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleType = 'vehicle_type',
  /** column name */
  Vin = 'vin',
  /** column name */
  WarrantyInsuranceYears = 'warranty_insurance_years',
  /** column name */
  WinterTires = 'winter_tires',
  /** column name */
  WinterTiresPrice = 'winter_tires_price',
  /** column name */
  ZipCode = 'zip_code'
}

export interface Sales_Reports_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Sales_Reports_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Sales_Reports_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Sales_Reports_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Sales_Reports_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Sales_Reports_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Sales_Reports_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Sales_Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sales_Reports_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Sales_Reports_Var_Pop_Fields {
  __typename?: 'sales_reports_var_pop_fields';
  exchange_price?: Maybe<Scalars['Float']>;
  expected_sales_price?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  selling_price?: Maybe<Scalars['Float']>;
  warranty_insurance_years?: Maybe<Scalars['Float']>;
  winter_tires_price?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Sales_Reports_Var_Samp_Fields {
  __typename?: 'sales_reports_var_samp_fields';
  exchange_price?: Maybe<Scalars['Float']>;
  expected_sales_price?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  selling_price?: Maybe<Scalars['Float']>;
  warranty_insurance_years?: Maybe<Scalars['Float']>;
  winter_tires_price?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Sales_Reports_Variance_Fields {
  __typename?: 'sales_reports_variance_fields';
  exchange_price?: Maybe<Scalars['Float']>;
  expected_sales_price?: Maybe<Scalars['Float']>;
  list_price?: Maybe<Scalars['Float']>;
  selling_price?: Maybe<Scalars['Float']>;
  warranty_insurance_years?: Maybe<Scalars['Float']>;
  winter_tires_price?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "sales_type" */
export interface Sales_Type {
  __typename?: 'sales_type';
  value: Scalars['String'];
}

/** aggregated selection of "sales_type" */
export interface Sales_Type_Aggregate {
  __typename?: 'sales_type_aggregate';
  aggregate?: Maybe<Sales_Type_Aggregate_Fields>;
  nodes: Array<Sales_Type>;
}

/** aggregate fields of "sales_type" */
export interface Sales_Type_Aggregate_Fields {
  __typename?: 'sales_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sales_Type_Max_Fields>;
  min?: Maybe<Sales_Type_Min_Fields>;
}


/** aggregate fields of "sales_type" */
export interface Sales_Type_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Sales_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "sales_type". All fields are combined with a logical 'AND'. */
export interface Sales_Type_Bool_Exp {
  _and?: Maybe<Array<Sales_Type_Bool_Exp>>;
  _not?: Maybe<Sales_Type_Bool_Exp>;
  _or?: Maybe<Array<Sales_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "sales_type" */
export enum Sales_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  SalesTypePkey = 'sales_type_pkey'
}

export enum Sales_Type_Enum {
  Abonement = 'ABONEMENT',
  Purchase = 'PURCHASE',
  Sell = 'SELL',
  Unknown = 'UNKNOWN'
}

/** Boolean expression to compare columns of type "sales_type_enum". All fields are combined with logical 'AND'. */
export interface Sales_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Sales_Type_Enum>;
  _in?: Maybe<Array<Sales_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Sales_Type_Enum>;
  _nin?: Maybe<Array<Sales_Type_Enum>>;
}

/** input type for inserting data into table "sales_type" */
export interface Sales_Type_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Sales_Type_Max_Fields {
  __typename?: 'sales_type_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Sales_Type_Min_Fields {
  __typename?: 'sales_type_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "sales_type" */
export interface Sales_Type_Mutation_Response {
  __typename?: 'sales_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sales_Type>;
}

/** on_conflict condition type for table "sales_type" */
export interface Sales_Type_On_Conflict {
  constraint: Sales_Type_Constraint;
  update_columns: Array<Sales_Type_Update_Column>;
  where?: Maybe<Sales_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "sales_type". */
export interface Sales_Type_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: sales_type */
export interface Sales_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "sales_type" */
export enum Sales_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "sales_type" */
export interface Sales_Type_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "sales_type" */
export interface Sales_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Sales_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Sales_Type_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "sales_type" */
export enum Sales_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Sales_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Sales_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sales_Type_Bool_Exp;
}

export interface Salutation_Customer_Args {
  salutation_type?: Maybe<Scalars['String']>;
}

/** columns and relationships of "salutation_type" */
export interface Salutation_Type {
  __typename?: 'salutation_type';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
}

/** aggregated selection of "salutation_type" */
export interface Salutation_Type_Aggregate {
  __typename?: 'salutation_type_aggregate';
  aggregate?: Maybe<Salutation_Type_Aggregate_Fields>;
  nodes: Array<Salutation_Type>;
}

/** aggregate fields of "salutation_type" */
export interface Salutation_Type_Aggregate_Fields {
  __typename?: 'salutation_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Salutation_Type_Max_Fields>;
  min?: Maybe<Salutation_Type_Min_Fields>;
}


/** aggregate fields of "salutation_type" */
export interface Salutation_Type_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Salutation_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "salutation_type". All fields are combined with a logical 'AND'. */
export interface Salutation_Type_Bool_Exp {
  _and?: Maybe<Array<Salutation_Type_Bool_Exp>>;
  _not?: Maybe<Salutation_Type_Bool_Exp>;
  _or?: Maybe<Array<Salutation_Type_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "salutation_type" */
export enum Salutation_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  SalutationTypePkey = 'salutation_type_pkey'
}

export enum Salutation_Type_Enum {
  Generic = 'GENERIC',
  Greeting = 'GREETING',
  Receiver = 'RECEIVER'
}

/** Boolean expression to compare columns of type "salutation_type_enum". All fields are combined with logical 'AND'. */
export interface Salutation_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Salutation_Type_Enum>;
  _in?: Maybe<Array<Salutation_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Salutation_Type_Enum>;
  _nin?: Maybe<Array<Salutation_Type_Enum>>;
}

/** input type for inserting data into table "salutation_type" */
export interface Salutation_Type_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Salutation_Type_Max_Fields {
  __typename?: 'salutation_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Salutation_Type_Min_Fields {
  __typename?: 'salutation_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "salutation_type" */
export interface Salutation_Type_Mutation_Response {
  __typename?: 'salutation_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salutation_Type>;
}

/** on_conflict condition type for table "salutation_type" */
export interface Salutation_Type_On_Conflict {
  constraint: Salutation_Type_Constraint;
  update_columns: Array<Salutation_Type_Update_Column>;
  where?: Maybe<Salutation_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "salutation_type". */
export interface Salutation_Type_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: salutation_type */
export interface Salutation_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "salutation_type" */
export enum Salutation_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "salutation_type" */
export interface Salutation_Type_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "salutation_type" */
export interface Salutation_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Salutation_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Salutation_Type_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "salutation_type" */
export enum Salutation_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Salutation_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Salutation_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salutation_Type_Bool_Exp;
}

/** columns and relationships of "subdealer_brand" */
export interface Subdealer_Brand {
  __typename?: 'subdealer_brand';
  brand_id: Scalars['uuid'];
  subdealer_id: Scalars['uuid'];
}

/** aggregated selection of "subdealer_brand" */
export interface Subdealer_Brand_Aggregate {
  __typename?: 'subdealer_brand_aggregate';
  aggregate?: Maybe<Subdealer_Brand_Aggregate_Fields>;
  nodes: Array<Subdealer_Brand>;
}

export interface Subdealer_Brand_Aggregate_Bool_Exp {
  count?: Maybe<Subdealer_Brand_Aggregate_Bool_Exp_Count>;
}

export interface Subdealer_Brand_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Subdealer_Brand_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Subdealer_Brand_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "subdealer_brand" */
export interface Subdealer_Brand_Aggregate_Fields {
  __typename?: 'subdealer_brand_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Subdealer_Brand_Max_Fields>;
  min?: Maybe<Subdealer_Brand_Min_Fields>;
}


/** aggregate fields of "subdealer_brand" */
export interface Subdealer_Brand_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Subdealer_Brand_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "subdealer_brand" */
export interface Subdealer_Brand_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Subdealer_Brand_Max_Order_By>;
  min?: Maybe<Subdealer_Brand_Min_Order_By>;
}

/** input type for inserting array relation for remote table "subdealer_brand" */
export interface Subdealer_Brand_Arr_Rel_Insert_Input {
  data: Array<Subdealer_Brand_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Subdealer_Brand_On_Conflict>;
}

/** Boolean expression to filter rows from the table "subdealer_brand". All fields are combined with a logical 'AND'. */
export interface Subdealer_Brand_Bool_Exp {
  _and?: Maybe<Array<Subdealer_Brand_Bool_Exp>>;
  _not?: Maybe<Subdealer_Brand_Bool_Exp>;
  _or?: Maybe<Array<Subdealer_Brand_Bool_Exp>>;
  brand_id?: Maybe<Uuid_Comparison_Exp>;
  subdealer_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "subdealer_brand" */
export enum Subdealer_Brand_Constraint {
  /** unique or primary key constraint on columns "subdealer_id", "brand_id" */
  SubdealerBrandPkey = 'subdealer_brand_pkey'
}

/** input type for inserting data into table "subdealer_brand" */
export interface Subdealer_Brand_Insert_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Subdealer_Brand_Max_Fields {
  __typename?: 'subdealer_brand_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "subdealer_brand" */
export interface Subdealer_Brand_Max_Order_By {
  brand_id?: Maybe<Order_By>;
  subdealer_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Subdealer_Brand_Min_Fields {
  __typename?: 'subdealer_brand_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "subdealer_brand" */
export interface Subdealer_Brand_Min_Order_By {
  brand_id?: Maybe<Order_By>;
  subdealer_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "subdealer_brand" */
export interface Subdealer_Brand_Mutation_Response {
  __typename?: 'subdealer_brand_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subdealer_Brand>;
}

/** on_conflict condition type for table "subdealer_brand" */
export interface Subdealer_Brand_On_Conflict {
  constraint: Subdealer_Brand_Constraint;
  update_columns: Array<Subdealer_Brand_Update_Column>;
  where?: Maybe<Subdealer_Brand_Bool_Exp>;
}

/** Ordering options when selecting data from "subdealer_brand". */
export interface Subdealer_Brand_Order_By {
  brand_id?: Maybe<Order_By>;
  subdealer_id?: Maybe<Order_By>;
}

/** primary key columns input for table: subdealer_brand */
export interface Subdealer_Brand_Pk_Columns_Input {
  brand_id: Scalars['uuid'];
  subdealer_id: Scalars['uuid'];
}

/** select columns of table "subdealer_brand" */
export enum Subdealer_Brand_Select_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  SubdealerId = 'subdealer_id'
}

/** input type for updating data in table "subdealer_brand" */
export interface Subdealer_Brand_Set_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "subdealer_brand" */
export interface Subdealer_Brand_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Subdealer_Brand_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Subdealer_Brand_Stream_Cursor_Value_Input {
  brand_id?: Maybe<Scalars['uuid']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "subdealer_brand" */
export enum Subdealer_Brand_Update_Column {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  SubdealerId = 'subdealer_id'
}

export interface Subdealer_Brand_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Subdealer_Brand_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subdealer_Brand_Bool_Exp;
}

/** columns and relationships of "subdealer_brands_view" */
export interface Subdealer_Brands_View {
  __typename?: 'subdealer_brands_view';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "subdealer_brands_view" */
export interface Subdealer_Brands_View_Aggregate {
  __typename?: 'subdealer_brands_view_aggregate';
  aggregate?: Maybe<Subdealer_Brands_View_Aggregate_Fields>;
  nodes: Array<Subdealer_Brands_View>;
}

export interface Subdealer_Brands_View_Aggregate_Bool_Exp {
  count?: Maybe<Subdealer_Brands_View_Aggregate_Bool_Exp_Count>;
}

export interface Subdealer_Brands_View_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Subdealer_Brands_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Subdealer_Brands_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "subdealer_brands_view" */
export interface Subdealer_Brands_View_Aggregate_Fields {
  __typename?: 'subdealer_brands_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Subdealer_Brands_View_Max_Fields>;
  min?: Maybe<Subdealer_Brands_View_Min_Fields>;
}


/** aggregate fields of "subdealer_brands_view" */
export interface Subdealer_Brands_View_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Subdealer_Brands_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "subdealer_brands_view" */
export interface Subdealer_Brands_View_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Subdealer_Brands_View_Max_Order_By>;
  min?: Maybe<Subdealer_Brands_View_Min_Order_By>;
}

/** input type for inserting array relation for remote table "subdealer_brands_view" */
export interface Subdealer_Brands_View_Arr_Rel_Insert_Input {
  data: Array<Subdealer_Brands_View_Insert_Input>;
}

/** Boolean expression to filter rows from the table "subdealer_brands_view". All fields are combined with a logical 'AND'. */
export interface Subdealer_Brands_View_Bool_Exp {
  _and?: Maybe<Array<Subdealer_Brands_View_Bool_Exp>>;
  _not?: Maybe<Subdealer_Brands_View_Bool_Exp>;
  _or?: Maybe<Array<Subdealer_Brands_View_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  subdealer_id?: Maybe<Uuid_Comparison_Exp>;
}

/** input type for inserting data into table "subdealer_brands_view" */
export interface Subdealer_Brands_View_Insert_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Subdealer_Brands_View_Max_Fields {
  __typename?: 'subdealer_brands_view_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "subdealer_brands_view" */
export interface Subdealer_Brands_View_Max_Order_By {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  subdealer_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Subdealer_Brands_View_Min_Fields {
  __typename?: 'subdealer_brands_view_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "subdealer_brands_view" */
export interface Subdealer_Brands_View_Min_Order_By {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  subdealer_id?: Maybe<Order_By>;
}

/** Ordering options when selecting data from "subdealer_brands_view". */
export interface Subdealer_Brands_View_Order_By {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  subdealer_id?: Maybe<Order_By>;
}

/** select columns of table "subdealer_brands_view" */
export enum Subdealer_Brands_View_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SubdealerId = 'subdealer_id'
}

/** Streaming cursor of the table "subdealer_brands_view" */
export interface Subdealer_Brands_View_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Subdealer_Brands_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Subdealer_Brands_View_Stream_Cursor_Value_Input {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subdealer_id?: Maybe<Scalars['uuid']>;
}

export interface Subscription_Root {
  __typename?: 'subscription_root';
  /** fetch data from the table in a streaming manner: "addresses" */
  Address_stream: Array<Address>;
  /** fetch data from the table in a streaming manner: "after_sales_potentials" */
  AfterSalesPotential_stream: Array<AfterSalesPotential>;
  /** fetch data from the table in a streaming manner: "customer_appointments" */
  Appointment_stream: Array<Appointment>;
  /** fetch data from the table in a streaming manner: "brands" */
  Brand_stream: Array<Brand>;
  /** fetch data from the table in a streaming manner: "contact_notes" */
  ContactNote_stream: Array<ContactNote>;
  /** fetch data from the table in a streaming manner: "customer_gdpr_info" */
  CustomerGdprInfo_stream: Array<CustomerGdprInfo>;
  /** fetch data from the table in a streaming manner: "customer_vehicles" */
  CustomerVehicle_stream: Array<CustomerVehicle>;
  /** fetch data from the table in a streaming manner: "customers" */
  Customer_stream: Array<Customer>;
  /** fetch data from the table in a streaming manner: "dealer_vehicle_inbox" */
  DealerVehicleInbox_stream: Array<DealerVehicleInbox>;
  /** fetch data from the table in a streaming manner: "dealer_vehicle_notes" */
  DealerVehicleNote_stream: Array<DealerVehicleNote>;
  /** fetch data from the table in a streaming manner: "dealer_vehicles" */
  DealerVehicle_stream: Array<DealerVehicle>;
  /** fetch data from the table in a streaming manner: "departments" */
  Department_stream: Array<Department>;
  /** fetch data from the table in a streaming manner: "dms_reference" */
  DmsReference_stream: Array<DmsReference>;
  /** fetch data from the table in a streaming manner: "location_brand" */
  LocationBrandReference_stream: Array<LocationBrandReference>;
  /** fetch data from the table in a streaming manner: "location_groups" */
  LocationGroup_stream: Array<LocationGroup>;
  /** fetch data from the table in a streaming manner: "locations" */
  Location_stream: Array<Location>;
  /** fetch data from the table in a streaming manner: "model_groups" */
  ModelGroup_stream: Array<ModelGroup>;
  /** fetch data from the table in a streaming manner: "models" */
  Model_stream: Array<Model>;
  /** fetch aggregated fields from the table: "notes" */
  Notes_aggregate: Notes_Aggregate;
  /** fetch data from the table in a streaming manner: "notes" */
  Notes_stream: Array<Notes>;
  /** fetch data from the table in a streaming manner: "phone_numbers" */
  PhoneNumber_stream: Array<PhoneNumber>;
  /** fetch data from the table in a streaming manner: "storage_locations" */
  StorageLocation_stream: Array<StorageLocation>;
  /** fetch data from the table in a streaming manner: "subdealers" */
  Subdealer_stream: Array<Subdealer>;
  /** fetch data from the table in a streaming manner: "suppliers" */
  Supplier_stream: Array<Supplier>;
  /** fetch data from the table in a streaming manner: "task_types" */
  TaskType_stream: Array<TaskType>;
  /** fetch data from the table in a streaming manner: "ticket_notes" */
  TicketNote_stream: Array<TicketNote>;
  /** fetch data from the table in a streaming manner: "ticket_templates" */
  TicketTemplate_stream: Array<TicketTemplate>;
  /** fetch data from the table in a streaming manner: "tickets" */
  Ticket_stream: Array<Ticket>;
  /** fetch data from the table in a streaming manner: "users" */
  User_stream: Array<User>;
  /** fetch data from the table in a streaming manner: "vehicle_notes" */
  VehicleNote_stream: Array<VehicleNote>;
  /** fetch data from the table in a streaming manner: "vehicles" */
  Vehicle_stream: Array<Vehicle>;
  /** fetch data from the table: "addresses" using primary key columns */
  address?: Maybe<Address>;
  /** fetch data from the table: "addresses" */
  addresses: Array<Address>;
  /** fetch aggregated fields from the table: "addresses" */
  addresses_aggregate: Address_Aggregate;
  /** fetch data from the table: "after_sales_potentials" using primary key columns */
  after_sales_potential?: Maybe<AfterSalesPotential>;
  /** An array relationship */
  after_sales_potentials: Array<AfterSalesPotential>;
  /** An aggregate relationship */
  after_sales_potentials_aggregate: AfterSalesPotential_Aggregate;
  /** fetch data from the table: "after_sales_status" */
  after_sales_status: Array<After_Sales_Status>;
  /** fetch aggregated fields from the table: "after_sales_status" */
  after_sales_status_aggregate: After_Sales_Status_Aggregate;
  /** fetch data from the table: "after_sales_status" using primary key columns */
  after_sales_status_by_pk?: Maybe<After_Sales_Status>;
  /** fetch data from the table in a streaming manner: "after_sales_status" */
  after_sales_status_stream: Array<After_Sales_Status>;
  /** fetch data from the table: "customer_appointments" using primary key columns */
  appointment?: Maybe<Appointment>;
  /** An array relationship */
  appointments: Array<Appointment>;
  /** An aggregate relationship */
  appointments_aggregate: Appointment_Aggregate;
  /** fetch data from the table: "asset_link_family_notifications" */
  asset_link_family_notifications: Array<Asset_Link_Family_Notifications>;
  /** fetch aggregated fields from the table: "asset_link_family_notifications" */
  asset_link_family_notifications_aggregate: Asset_Link_Family_Notifications_Aggregate;
  /** fetch data from the table: "asset_link_family_notifications" using primary key columns */
  asset_link_family_notifications_by_pk?: Maybe<Asset_Link_Family_Notifications>;
  /** fetch data from the table in a streaming manner: "asset_link_family_notifications" */
  asset_link_family_notifications_stream: Array<Asset_Link_Family_Notifications>;
  /** fetch data from the table: "asset_watchdog_link_status" */
  asset_watchdog_link_status: Array<Asset_Watchdog_Link_Status>;
  /** fetch aggregated fields from the table: "asset_watchdog_link_status" */
  asset_watchdog_link_status_aggregate: Asset_Watchdog_Link_Status_Aggregate;
  /** fetch data from the table: "asset_watchdog_link_status" using primary key columns */
  asset_watchdog_link_status_by_pk?: Maybe<Asset_Watchdog_Link_Status>;
  /** fetch data from the table in a streaming manner: "asset_watchdog_link_status" */
  asset_watchdog_link_status_stream: Array<Asset_Watchdog_Link_Status>;
  /** fetch data from the table: "asset_watchdog_links" */
  asset_watchdog_links: Array<Asset_Watchdog_Links>;
  /** fetch aggregated fields from the table: "asset_watchdog_links" */
  asset_watchdog_links_aggregate: Asset_Watchdog_Links_Aggregate;
  /** fetch data from the table: "asset_watchdog_links" using primary key columns */
  asset_watchdog_links_by_pk?: Maybe<Asset_Watchdog_Links>;
  /** fetch data from the table in a streaming manner: "asset_watchdog_links" */
  asset_watchdog_links_stream: Array<Asset_Watchdog_Links>;
  /** fetch data from the table: "brands" using primary key columns */
  brand?: Maybe<Brand>;
  /** fetch data from the table: "brand_boni_types" */
  brand_boni_types: Array<Brand_Boni_Types>;
  /** fetch aggregated fields from the table: "brand_boni_types" */
  brand_boni_types_aggregate: Brand_Boni_Types_Aggregate;
  /** fetch data from the table: "brand_boni_types" using primary key columns */
  brand_boni_types_by_pk?: Maybe<Brand_Boni_Types>;
  /** fetch data from the table in a streaming manner: "brand_boni_types" */
  brand_boni_types_stream: Array<Brand_Boni_Types>;
  /** fetch data from the table: "brand_supplier" using primary key columns */
  brand_supplier?: Maybe<Brand_Suppliers>;
  /** fetch data from the table: "brand_supplier" */
  brand_suppliers: Array<Brand_Suppliers>;
  /** fetch aggregated fields from the table: "brand_supplier" */
  brand_suppliers_aggregate: Brand_Suppliers_Aggregate;
  /** fetch data from the table in a streaming manner: "brand_supplier" */
  brand_suppliers_stream: Array<Brand_Suppliers>;
  /** fetch data from the table: "brand_vehicle_metafield" */
  brand_vehicle_metafield: Array<Brand_Vehicle_Metafield>;
  /** fetch aggregated fields from the table: "brand_vehicle_metafield" */
  brand_vehicle_metafield_aggregate: Brand_Vehicle_Metafield_Aggregate;
  /** fetch data from the table: "brand_vehicle_metafield" using primary key columns */
  brand_vehicle_metafield_by_pk?: Maybe<Brand_Vehicle_Metafield>;
  /** fetch data from the table in a streaming manner: "brand_vehicle_metafield" */
  brand_vehicle_metafield_stream: Array<Brand_Vehicle_Metafield>;
  /** fetch data from the table: "brands" */
  brands: Array<Brand>;
  /** fetch aggregated fields from the table: "brands" */
  brands_aggregate: Brand_Aggregate;
  /** fetch data from the table: "contact_notes" using primary key columns */
  contact_note?: Maybe<ContactNote>;
  /** fetch data from the table: "contact_note_type" */
  contact_note_type: Array<Contact_Note_Type>;
  /** fetch aggregated fields from the table: "contact_note_type" */
  contact_note_type_aggregate: Contact_Note_Type_Aggregate;
  /** fetch data from the table: "contact_note_type" using primary key columns */
  contact_note_type_by_pk?: Maybe<Contact_Note_Type>;
  /** fetch data from the table in a streaming manner: "contact_note_type" */
  contact_note_type_stream: Array<Contact_Note_Type>;
  /** An array relationship */
  contact_notes: Array<ContactNote>;
  /** An aggregate relationship */
  contact_notes_aggregate: ContactNote_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customer?: Maybe<Customer>;
  /** fetch data from the table: "customer_customer_tag" */
  customer_customer_tag: Array<Customer_Customer_Tag>;
  /** fetch aggregated fields from the table: "customer_customer_tag" */
  customer_customer_tag_aggregate: Customer_Customer_Tag_Aggregate;
  /** fetch data from the table: "customer_customer_tag" using primary key columns */
  customer_customer_tag_by_pk?: Maybe<Customer_Customer_Tag>;
  /** fetch data from the table in a streaming manner: "customer_customer_tag" */
  customer_customer_tag_stream: Array<Customer_Customer_Tag>;
  /** fetch data from the table: "customer_gdpr_info" using primary key columns */
  customer_gdpr_info?: Maybe<CustomerGdprInfo>;
  /** fetch data from the table: "customer_gdpr_info" */
  customer_gdpr_infos: Array<CustomerGdprInfo>;
  /** fetch aggregated fields from the table: "customer_gdpr_info" */
  customer_gdpr_infos_aggregate: CustomerGdprInfo_Aggregate;
  /** fetch data from the table: "customer_salutation" */
  customer_salutation: Array<Customer_Salutation>;
  /** fetch aggregated fields from the table: "customer_salutation" */
  customer_salutation_aggregate: Customer_Salutation_Aggregate;
  /** fetch data from the table: "customer_salutation" using primary key columns */
  customer_salutation_by_pk?: Maybe<Customer_Salutation>;
  /** fetch data from the table in a streaming manner: "customer_salutation" */
  customer_salutation_stream: Array<Customer_Salutation>;
  /** fetch data from the table: "customer_tag" */
  customer_tag: Array<Customer_Tag>;
  /** fetch aggregated fields from the table: "customer_tag" */
  customer_tag_aggregate: Customer_Tag_Aggregate;
  /** fetch data from the table: "customer_tag" using primary key columns */
  customer_tag_by_pk?: Maybe<Customer_Tag>;
  /** fetch data from the table in a streaming manner: "customer_tag" */
  customer_tag_stream: Array<Customer_Tag>;
  /** fetch data from the table: "customer_type" */
  customer_type: Array<Customer_Type>;
  /** fetch aggregated fields from the table: "customer_type" */
  customer_type_aggregate: Customer_Type_Aggregate;
  /** fetch data from the table: "customer_type" using primary key columns */
  customer_type_by_pk?: Maybe<Customer_Type>;
  /** fetch data from the table in a streaming manner: "customer_type" */
  customer_type_stream: Array<Customer_Type>;
  /** fetch data from the table: "customer_vehicles" using primary key columns */
  customer_vehicle?: Maybe<CustomerVehicle>;
  /** fetch data from the table: "customer_vehicles" */
  customer_vehicles: Array<CustomerVehicle>;
  /** fetch aggregated fields from the table: "customer_vehicles" */
  customer_vehicles_aggregate: CustomerVehicle_Aggregate;
  /** fetch data from the table: "customers" */
  customers: Array<Customer>;
  /** fetch aggregated fields from the table: "customers" */
  customers_aggregate: Customer_Aggregate;
  /** fetch data from the table: "dealer_vehicles" using primary key columns */
  dealer_vehicle?: Maybe<DealerVehicle>;
  /** fetch data from the table: "dealer_vehicle_import_templates" */
  dealer_vehicle_import_templates: Array<Dealer_Vehicle_Import_Templates>;
  /** fetch aggregated fields from the table: "dealer_vehicle_import_templates" */
  dealer_vehicle_import_templates_aggregate: Dealer_Vehicle_Import_Templates_Aggregate;
  /** fetch data from the table: "dealer_vehicle_import_templates" using primary key columns */
  dealer_vehicle_import_templates_by_pk?: Maybe<Dealer_Vehicle_Import_Templates>;
  /** fetch data from the table in a streaming manner: "dealer_vehicle_import_templates" */
  dealer_vehicle_import_templates_stream: Array<Dealer_Vehicle_Import_Templates>;
  /** fetch data from the table: "dealer_vehicle_inbox" */
  dealer_vehicle_inbox: Array<DealerVehicleInbox>;
  /** fetch aggregated fields from the table: "dealer_vehicle_inbox" */
  dealer_vehicle_inbox_aggregate: DealerVehicleInbox_Aggregate;
  /** fetch data from the table: "dealer_vehicle_inbox" using primary key columns */
  dealer_vehicle_inbox_by_pk?: Maybe<DealerVehicleInbox>;
  /** fetch data from the table: "dealer_vehicle_inbox_import_templates" */
  dealer_vehicle_inbox_import_templates: Array<Dealer_Vehicle_Inbox_Import_Templates>;
  /** fetch aggregated fields from the table: "dealer_vehicle_inbox_import_templates" */
  dealer_vehicle_inbox_import_templates_aggregate: Dealer_Vehicle_Inbox_Import_Templates_Aggregate;
  /** fetch data from the table: "dealer_vehicle_inbox_import_templates" using primary key columns */
  dealer_vehicle_inbox_import_templates_by_pk?: Maybe<Dealer_Vehicle_Inbox_Import_Templates>;
  /** fetch data from the table in a streaming manner: "dealer_vehicle_inbox_import_templates" */
  dealer_vehicle_inbox_import_templates_stream: Array<Dealer_Vehicle_Inbox_Import_Templates>;
  /** fetch data from the table: "dealer_vehicle_notes" using primary key columns */
  dealer_vehicle_note?: Maybe<DealerVehicleNote>;
  /** fetch data from the table: "dealer_vehicle_notes" */
  dealer_vehicle_notes: Array<DealerVehicleNote>;
  /** fetch aggregated fields from the table: "dealer_vehicle_notes" */
  dealer_vehicle_notes_aggregate: DealerVehicleNote_Aggregate;
  /** fetch data from the table: "dealer_vehicle_state" */
  dealer_vehicle_state: Array<Dealer_Vehicle_State>;
  /** fetch aggregated fields from the table: "dealer_vehicle_state" */
  dealer_vehicle_state_aggregate: Dealer_Vehicle_State_Aggregate;
  /** fetch data from the table: "dealer_vehicle_state" using primary key columns */
  dealer_vehicle_state_by_pk?: Maybe<Dealer_Vehicle_State>;
  /** fetch data from the table in a streaming manner: "dealer_vehicle_state" */
  dealer_vehicle_state_stream: Array<Dealer_Vehicle_State>;
  /** fetch data from the table: "dealer_vehicle_type" */
  dealer_vehicle_type: Array<Dealer_Vehicle_Type>;
  /** fetch aggregated fields from the table: "dealer_vehicle_type" */
  dealer_vehicle_type_aggregate: Dealer_Vehicle_Type_Aggregate;
  /** fetch data from the table: "dealer_vehicle_type" using primary key columns */
  dealer_vehicle_type_by_pk?: Maybe<Dealer_Vehicle_Type>;
  /** fetch data from the table in a streaming manner: "dealer_vehicle_type" */
  dealer_vehicle_type_stream: Array<Dealer_Vehicle_Type>;
  /** fetch data from the table: "dealer_vehicles" */
  dealer_vehicles: Array<DealerVehicle>;
  /** fetch aggregated fields from the table: "dealer_vehicles" */
  dealer_vehicles_aggregate: DealerVehicle_Aggregate;
  /** fetch data from the table: "departments" using primary key columns */
  department?: Maybe<Department>;
  /** fetch data from the table: "department_role" */
  department_role: Array<Department_Role>;
  /** fetch aggregated fields from the table: "department_role" */
  department_role_aggregate: Department_Role_Aggregate;
  /** fetch data from the table: "department_role" using primary key columns */
  department_role_by_pk?: Maybe<Department_Role>;
  /** fetch data from the table in a streaming manner: "department_role" */
  department_role_stream: Array<Department_Role>;
  /** fetch data from the table: "departments" */
  departments: Array<Department>;
  /** fetch aggregated fields from the table: "departments" */
  departments_aggregate: Department_Aggregate;
  /** fetch data from the table: "dms_reference" using primary key columns */
  dms_reference?: Maybe<DmsReference>;
  /** fetch data from the table: "dms_reference" */
  dms_references: Array<DmsReference>;
  /** fetch aggregated fields from the table: "dms_reference" */
  dms_references_aggregate: DmsReference_Aggregate;
  /** fetch data from the table: "dms_type" */
  dms_type: Array<Dms_Type>;
  /** fetch aggregated fields from the table: "dms_type" */
  dms_type_aggregate: Dms_Type_Aggregate;
  /** fetch data from the table: "dms_type" using primary key columns */
  dms_type_by_pk?: Maybe<Dms_Type>;
  /** fetch data from the table in a streaming manner: "dms_type" */
  dms_type_stream: Array<Dms_Type>;
  /** fetch data from the table: "embedded_pages" */
  embedded_pages: Array<Embedded_Pages>;
  /** fetch aggregated fields from the table: "embedded_pages" */
  embedded_pages_aggregate: Embedded_Pages_Aggregate;
  /** fetch data from the table: "embedded_pages" using primary key columns */
  embedded_pages_by_pk?: Maybe<Embedded_Pages>;
  /** fetch data from the table in a streaming manner: "embedded_pages" */
  embedded_pages_stream: Array<Embedded_Pages>;
  /** fetch data from the table: "excel_import_assignments" */
  excel_import_assignments: Array<Excel_Import_Assignments>;
  /** fetch aggregated fields from the table: "excel_import_assignments" */
  excel_import_assignments_aggregate: Excel_Import_Assignments_Aggregate;
  /** fetch data from the table: "excel_import_assignments" using primary key columns */
  excel_import_assignments_by_pk?: Maybe<Excel_Import_Assignments>;
  /** fetch data from the table in a streaming manner: "excel_import_assignments" */
  excel_import_assignments_stream: Array<Excel_Import_Assignments>;
  /** fetch data from the table: "financing_type" */
  financing_type: Array<Financing_Type>;
  /** fetch aggregated fields from the table: "financing_type" */
  financing_type_aggregate: Financing_Type_Aggregate;
  /** fetch data from the table: "financing_type" using primary key columns */
  financing_type_by_pk?: Maybe<Financing_Type>;
  /** fetch data from the table in a streaming manner: "financing_type" */
  financing_type_stream: Array<Financing_Type>;
  /** execute function "house_brands" which returns "brands" */
  house_brands: Array<Brand>;
  /** execute function "house_brands" and query aggregates on result of table type "brands" */
  house_brands_aggregate: Brand_Aggregate;
  /** fetch data from the table: "import_job_executable_status" */
  import_job_executable_status: Array<Import_Job_Executable_Status>;
  /** fetch aggregated fields from the table: "import_job_executable_status" */
  import_job_executable_status_aggregate: Import_Job_Executable_Status_Aggregate;
  /** fetch data from the table: "import_job_executable_status" using primary key columns */
  import_job_executable_status_by_pk?: Maybe<Import_Job_Executable_Status>;
  /** fetch data from the table in a streaming manner: "import_job_executable_status" */
  import_job_executable_status_stream: Array<Import_Job_Executable_Status>;
  /** fetch data from the table: "import_job_status" */
  import_job_status: Array<Import_Job_Status>;
  /** fetch aggregated fields from the table: "import_job_status" */
  import_job_status_aggregate: Import_Job_Status_Aggregate;
  /** fetch data from the table: "import_job_status" using primary key columns */
  import_job_status_by_pk?: Maybe<Import_Job_Status>;
  /** fetch data from the table in a streaming manner: "import_job_status" */
  import_job_status_stream: Array<Import_Job_Status>;
  /** fetch data from the table: "import_job_vehicle_executables" */
  import_job_vehicle_executables: Array<Import_Job_Vehicle_Executables>;
  /** fetch aggregated fields from the table: "import_job_vehicle_executables" */
  import_job_vehicle_executables_aggregate: Import_Job_Vehicle_Executables_Aggregate;
  /** fetch data from the table: "import_job_vehicle_executables" using primary key columns */
  import_job_vehicle_executables_by_pk?: Maybe<Import_Job_Vehicle_Executables>;
  /** fetch data from the table in a streaming manner: "import_job_vehicle_executables" */
  import_job_vehicle_executables_stream: Array<Import_Job_Vehicle_Executables>;
  /** fetch data from the table: "import_jobs" */
  import_jobs: Array<Import_Jobs>;
  /** fetch aggregated fields from the table: "import_jobs" */
  import_jobs_aggregate: Import_Jobs_Aggregate;
  /** fetch data from the table: "import_jobs" using primary key columns */
  import_jobs_by_pk?: Maybe<Import_Jobs>;
  /** fetch data from the table in a streaming manner: "import_jobs" */
  import_jobs_stream: Array<Import_Jobs>;
  /** fetch data from the table: "import_type" */
  import_type: Array<Import_Type>;
  /** fetch aggregated fields from the table: "import_type" */
  import_type_aggregate: Import_Type_Aggregate;
  /** fetch data from the table: "import_type" using primary key columns */
  import_type_by_pk?: Maybe<Import_Type>;
  /** fetch data from the table in a streaming manner: "import_type" */
  import_type_stream: Array<Import_Type>;
  /** fetch data from the table: "in_buy_channel" */
  in_buy_channel: Array<In_Buy_Channel>;
  /** fetch aggregated fields from the table: "in_buy_channel" */
  in_buy_channel_aggregate: In_Buy_Channel_Aggregate;
  /** fetch data from the table: "in_buy_channel" using primary key columns */
  in_buy_channel_by_pk?: Maybe<In_Buy_Channel>;
  /** fetch data from the table in a streaming manner: "in_buy_channel" */
  in_buy_channel_stream: Array<In_Buy_Channel>;
  /** fetch data from the table: "locations" using primary key columns */
  location?: Maybe<Location>;
  /** fetch data from the table: "location_brand" using primary key columns */
  location_brand_reference?: Maybe<LocationBrandReference>;
  /** fetch data from the table: "location_brand" */
  location_brand_references: Array<LocationBrandReference>;
  /** fetch aggregated fields from the table: "location_brand" */
  location_brand_references_aggregate: LocationBrandReference_Aggregate;
  /** fetch data from the table: "location_groups" using primary key columns */
  location_group?: Maybe<LocationGroup>;
  /** fetch data from the table: "location_group_brand" */
  location_group_brand: Array<Location_Group_Brand>;
  /** fetch aggregated fields from the table: "location_group_brand" */
  location_group_brand_aggregate: Location_Group_Brand_Aggregate;
  /** fetch data from the table: "location_group_brand" using primary key columns */
  location_group_brand_by_pk?: Maybe<Location_Group_Brand>;
  /** fetch data from the table in a streaming manner: "location_group_brand" */
  location_group_brand_stream: Array<Location_Group_Brand>;
  /** fetch data from the table: "location_group_brands_view" */
  location_group_brands_view: Array<Location_Group_Brands_View>;
  /** fetch aggregated fields from the table: "location_group_brands_view" */
  location_group_brands_view_aggregate: Location_Group_Brands_View_Aggregate;
  /** fetch data from the table in a streaming manner: "location_group_brands_view" */
  location_group_brands_view_stream: Array<Location_Group_Brands_View>;
  /** fetch data from the table: "location_groups" */
  location_groups: Array<LocationGroup>;
  /** fetch aggregated fields from the table: "location_groups" */
  location_groups_aggregate: LocationGroup_Aggregate;
  /** fetch data from the table: "locations" */
  locations: Array<Location>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Location_Aggregate;
  /** fetch data from the table: "models" using primary key columns */
  model?: Maybe<Model>;
  /** fetch data from the table: "model_groups" using primary key columns */
  model_group?: Maybe<ModelGroup>;
  /** fetch data from the table: "model_group_release_years" */
  model_group_release_years: Array<Model_Group_Release_Years>;
  /** fetch aggregated fields from the table: "model_group_release_years" */
  model_group_release_years_aggregate: Model_Group_Release_Years_Aggregate;
  /** fetch data from the table: "model_group_release_years" using primary key columns */
  model_group_release_years_by_pk?: Maybe<Model_Group_Release_Years>;
  /** fetch data from the table in a streaming manner: "model_group_release_years" */
  model_group_release_years_stream: Array<Model_Group_Release_Years>;
  /** fetch data from the table: "model_groups" */
  model_groups: Array<ModelGroup>;
  /** fetch aggregated fields from the table: "model_groups" */
  model_groups_aggregate: ModelGroup_Aggregate;
  /** An array relationship */
  models: Array<Model>;
  /** An aggregate relationship */
  models_aggregate: Model_Aggregate;
  /** execute function "models_in_stock" which returns "models" */
  models_in_stock: Array<Model>;
  /** execute function "models_in_stock" and query aggregates on result of table type "models" */
  models_in_stock_aggregate: Model_Aggregate;
  /** execute function "new_models" which returns "models" */
  new_models: Array<Model>;
  /** execute function "new_models" and query aggregates on result of table type "models" */
  new_models_aggregate: Model_Aggregate;
  /** fetch data from the table: "notes" using primary key columns */
  note?: Maybe<Notes>;
  /** An array relationship */
  notes: Array<Notes>;
  /** fetch data from the table: "notification_status" */
  notification_status: Array<Notification_Status>;
  /** fetch aggregated fields from the table: "notification_status" */
  notification_status_aggregate: Notification_Status_Aggregate;
  /** fetch data from the table: "notification_status" using primary key columns */
  notification_status_by_pk?: Maybe<Notification_Status>;
  /** fetch data from the table in a streaming manner: "notification_status" */
  notification_status_stream: Array<Notification_Status>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<Notification_Type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: Notification_Type_Aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<Notification_Type>;
  /** fetch data from the table in a streaming manner: "notification_type" */
  notification_type_stream: Array<Notification_Type>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** fetch data from the table: "order_state" */
  order_state: Array<Order_State>;
  /** fetch aggregated fields from the table: "order_state" */
  order_state_aggregate: Order_State_Aggregate;
  /** fetch data from the table: "order_state" using primary key columns */
  order_state_by_pk?: Maybe<Order_State>;
  /** fetch data from the table in a streaming manner: "order_state" */
  order_state_stream: Array<Order_State>;
  /** fetch data from the table: "phone_numbers" using primary key columns */
  phone_number?: Maybe<PhoneNumber>;
  /** fetch data from the table: "phone_number_type" */
  phone_number_type: Array<Phone_Number_Type>;
  /** fetch aggregated fields from the table: "phone_number_type" */
  phone_number_type_aggregate: Phone_Number_Type_Aggregate;
  /** fetch data from the table: "phone_number_type" using primary key columns */
  phone_number_type_by_pk?: Maybe<Phone_Number_Type>;
  /** fetch data from the table in a streaming manner: "phone_number_type" */
  phone_number_type_stream: Array<Phone_Number_Type>;
  /** An array relationship */
  phone_numbers: Array<PhoneNumber>;
  /** An aggregate relationship */
  phone_numbers_aggregate: PhoneNumber_Aggregate;
  /** fetch data from the table: "registration_state" */
  registration_state: Array<Registration_State>;
  /** fetch aggregated fields from the table: "registration_state" */
  registration_state_aggregate: Registration_State_Aggregate;
  /** fetch data from the table: "registration_state" using primary key columns */
  registration_state_by_pk?: Maybe<Registration_State>;
  /** fetch data from the table in a streaming manner: "registration_state" */
  registration_state_stream: Array<Registration_State>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>;
  /** fetch data from the table: "sales_reports" */
  sales_reports: Array<Sales_Reports>;
  /** fetch aggregated fields from the table: "sales_reports" */
  sales_reports_aggregate: Sales_Reports_Aggregate;
  /** fetch data from the table: "sales_reports" using primary key columns */
  sales_reports_by_pk?: Maybe<Sales_Reports>;
  /** fetch data from the table in a streaming manner: "sales_reports" */
  sales_reports_stream: Array<Sales_Reports>;
  /** fetch data from the table: "sales_type" */
  sales_type: Array<Sales_Type>;
  /** fetch aggregated fields from the table: "sales_type" */
  sales_type_aggregate: Sales_Type_Aggregate;
  /** fetch data from the table: "sales_type" using primary key columns */
  sales_type_by_pk?: Maybe<Sales_Type>;
  /** fetch data from the table in a streaming manner: "sales_type" */
  sales_type_stream: Array<Sales_Type>;
  /** fetch data from the table: "salutation_type" */
  salutation_type: Array<Salutation_Type>;
  /** fetch aggregated fields from the table: "salutation_type" */
  salutation_type_aggregate: Salutation_Type_Aggregate;
  /** fetch data from the table: "salutation_type" using primary key columns */
  salutation_type_by_pk?: Maybe<Salutation_Type>;
  /** fetch data from the table in a streaming manner: "salutation_type" */
  salutation_type_stream: Array<Salutation_Type>;
  /** fetch data from the table: "storage_locations" using primary key columns */
  storage_location?: Maybe<StorageLocation>;
  /** fetch data from the table: "storage_locations" */
  storage_locations: Array<StorageLocation>;
  /** fetch aggregated fields from the table: "storage_locations" */
  storage_locations_aggregate: StorageLocation_Aggregate;
  /** fetch data from the table: "subdealers" using primary key columns */
  subdealer?: Maybe<Subdealer>;
  /** fetch data from the table: "subdealer_brand" */
  subdealer_brand: Array<Subdealer_Brand>;
  /** fetch aggregated fields from the table: "subdealer_brand" */
  subdealer_brand_aggregate: Subdealer_Brand_Aggregate;
  /** fetch data from the table: "subdealer_brand" using primary key columns */
  subdealer_brand_by_pk?: Maybe<Subdealer_Brand>;
  /** fetch data from the table in a streaming manner: "subdealer_brand" */
  subdealer_brand_stream: Array<Subdealer_Brand>;
  /** fetch data from the table: "subdealer_brands_view" */
  subdealer_brands_view: Array<Subdealer_Brands_View>;
  /** fetch aggregated fields from the table: "subdealer_brands_view" */
  subdealer_brands_view_aggregate: Subdealer_Brands_View_Aggregate;
  /** fetch data from the table in a streaming manner: "subdealer_brands_view" */
  subdealer_brands_view_stream: Array<Subdealer_Brands_View>;
  /** fetch data from the table: "subdealers" */
  subdealers: Array<Subdealer>;
  /** fetch aggregated fields from the table: "subdealers" */
  subdealers_aggregate: Subdealer_Aggregate;
  /** fetch data from the table: "suppliers" using primary key columns */
  supplier?: Maybe<Supplier>;
  /** fetch data from the table: "suppliers" */
  suppliers: Array<Supplier>;
  /** fetch aggregated fields from the table: "suppliers" */
  suppliers_aggregate: Supplier_Aggregate;
  /** An array relationship */
  sync_errors: Array<Sync_Errors>;
  /** An aggregate relationship */
  sync_errors_aggregate: Sync_Errors_Aggregate;
  /** fetch data from the table: "sync_errors" using primary key columns */
  sync_errors_by_pk?: Maybe<Sync_Errors>;
  /** fetch data from the table in a streaming manner: "sync_errors" */
  sync_errors_stream: Array<Sync_Errors>;
  /** fetch data from the table: "task_category" */
  task_category: Array<Task_Category>;
  /** fetch aggregated fields from the table: "task_category" */
  task_category_aggregate: Task_Category_Aggregate;
  /** fetch data from the table: "task_category" using primary key columns */
  task_category_by_pk?: Maybe<Task_Category>;
  /** fetch data from the table in a streaming manner: "task_category" */
  task_category_stream: Array<Task_Category>;
  /** fetch data from the table: "task_types" using primary key columns */
  task_type?: Maybe<TaskType>;
  /** fetch data from the table: "task_type_task_category" */
  task_type_task_category: Array<Task_Type_Task_Category>;
  /** fetch aggregated fields from the table: "task_type_task_category" */
  task_type_task_category_aggregate: Task_Type_Task_Category_Aggregate;
  /** fetch data from the table: "task_type_task_category" using primary key columns */
  task_type_task_category_by_pk?: Maybe<Task_Type_Task_Category>;
  /** fetch data from the table in a streaming manner: "task_type_task_category" */
  task_type_task_category_stream: Array<Task_Type_Task_Category>;
  /** fetch data from the table: "task_types" */
  task_types: Array<TaskType>;
  /** fetch aggregated fields from the table: "task_types" */
  task_types_aggregate: TaskType_Aggregate;
  /** fetch data from the table: "team_assignments" using primary key columns */
  team_assignment?: Maybe<TeamAssignment>;
  /** fetch data from the table: "team_assignments" */
  team_assignments: Array<TeamAssignment>;
  /** fetch aggregated fields from the table: "team_assignments" */
  team_assignments_aggregate: TeamAssignment_Aggregate;
  /** fetch data from the table in a streaming manner: "team_assignments" */
  team_assignments_stream: Array<TeamAssignment>;
  /** fetch data from the table: "tickets" using primary key columns */
  ticket?: Maybe<Ticket>;
  /** fetch data from the table: "ticket_family_notifications" */
  ticket_family_notifications: Array<Ticket_Family_Notifications>;
  /** fetch aggregated fields from the table: "ticket_family_notifications" */
  ticket_family_notifications_aggregate: Ticket_Family_Notifications_Aggregate;
  /** fetch data from the table: "ticket_family_notifications" using primary key columns */
  ticket_family_notifications_by_pk?: Maybe<Ticket_Family_Notifications>;
  /** fetch data from the table in a streaming manner: "ticket_family_notifications" */
  ticket_family_notifications_stream: Array<Ticket_Family_Notifications>;
  /** fetch data from the table: "ticket_notes" using primary key columns */
  ticket_note?: Maybe<TicketNote>;
  /** fetch data from the table: "ticket_notes" */
  ticket_notes: Array<TicketNote>;
  /** fetch aggregated fields from the table: "ticket_notes" */
  ticket_notes_aggregate: TicketNote_Aggregate;
  /** fetch data from the table: "ticket_priority" */
  ticket_priority: Array<Ticket_Priority>;
  /** fetch aggregated fields from the table: "ticket_priority" */
  ticket_priority_aggregate: Ticket_Priority_Aggregate;
  /** fetch data from the table: "ticket_priority" using primary key columns */
  ticket_priority_by_pk?: Maybe<Ticket_Priority>;
  /** fetch data from the table in a streaming manner: "ticket_priority" */
  ticket_priority_stream: Array<Ticket_Priority>;
  /** fetch data from the table: "ticket_state" */
  ticket_state: Array<Ticket_State>;
  /** fetch aggregated fields from the table: "ticket_state" */
  ticket_state_aggregate: Ticket_State_Aggregate;
  /** fetch data from the table: "ticket_state" using primary key columns */
  ticket_state_by_pk?: Maybe<Ticket_State>;
  /** fetch data from the table in a streaming manner: "ticket_state" */
  ticket_state_stream: Array<Ticket_State>;
  /** fetch data from the table: "ticket_templates" using primary key columns */
  ticket_template?: Maybe<TicketTemplate>;
  /** fetch data from the table: "ticket_template_assignee_ref" */
  ticket_template_assignee_ref: Array<Ticket_Template_Assignee_Ref>;
  /** fetch aggregated fields from the table: "ticket_template_assignee_ref" */
  ticket_template_assignee_ref_aggregate: Ticket_Template_Assignee_Ref_Aggregate;
  /** fetch data from the table: "ticket_template_assignee_ref" using primary key columns */
  ticket_template_assignee_ref_by_pk?: Maybe<Ticket_Template_Assignee_Ref>;
  /** fetch data from the table in a streaming manner: "ticket_template_assignee_ref" */
  ticket_template_assignee_ref_stream: Array<Ticket_Template_Assignee_Ref>;
  /** fetch data from the table: "ticket_template_location_ref" */
  ticket_template_location_ref: Array<Ticket_Template_Location_Ref>;
  /** fetch aggregated fields from the table: "ticket_template_location_ref" */
  ticket_template_location_ref_aggregate: Ticket_Template_Location_Ref_Aggregate;
  /** fetch data from the table: "ticket_template_location_ref" using primary key columns */
  ticket_template_location_ref_by_pk?: Maybe<Ticket_Template_Location_Ref>;
  /** fetch data from the table in a streaming manner: "ticket_template_location_ref" */
  ticket_template_location_ref_stream: Array<Ticket_Template_Location_Ref>;
  /** fetch data from the table: "ticket_templates" */
  ticket_templates: Array<TicketTemplate>;
  /** fetch aggregated fields from the table: "ticket_templates" */
  ticket_templates_aggregate: TicketTemplate_Aggregate;
  /** An array relationship */
  tickets: Array<Ticket>;
  /** An aggregate relationship */
  tickets_aggregate: Ticket_Aggregate;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table in a streaming manner: "transactions" */
  transactions_stream: Array<Transactions>;
  /** fetch data from the table: "users" using primary key columns */
  user?: Maybe<User>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table in a streaming manner: "user_roles" */
  user_roles_stream: Array<User_Roles>;
  /** fetch data from the table: "users" */
  users: Array<User>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: User_Aggregate;
  /** fetch data from the table: "vehicles" using primary key columns */
  vehicle?: Maybe<Vehicle>;
  /** fetch data from the table: "vehicle_boni" */
  vehicle_boni: Array<Vehicle_Boni>;
  /** fetch aggregated fields from the table: "vehicle_boni" */
  vehicle_boni_aggregate: Vehicle_Boni_Aggregate;
  /** fetch data from the table: "vehicle_boni" using primary key columns */
  vehicle_boni_by_pk?: Maybe<Vehicle_Boni>;
  /** fetch data from the table in a streaming manner: "vehicle_boni" */
  vehicle_boni_stream: Array<Vehicle_Boni>;
  /** fetch data from the table: "vehicle_import_templates" */
  vehicle_import_templates: Array<Vehicle_Import_Templates>;
  /** fetch aggregated fields from the table: "vehicle_import_templates" */
  vehicle_import_templates_aggregate: Vehicle_Import_Templates_Aggregate;
  /** fetch data from the table: "vehicle_import_templates" using primary key columns */
  vehicle_import_templates_by_pk?: Maybe<Vehicle_Import_Templates>;
  /** fetch data from the table in a streaming manner: "vehicle_import_templates" */
  vehicle_import_templates_stream: Array<Vehicle_Import_Templates>;
  /** fetch data from the table: "vehicle_metafields" */
  vehicle_metafields: Array<Vehicle_Metafields>;
  /** fetch aggregated fields from the table: "vehicle_metafields" */
  vehicle_metafields_aggregate: Vehicle_Metafields_Aggregate;
  /** fetch data from the table: "vehicle_metafields" using primary key columns */
  vehicle_metafields_by_pk?: Maybe<Vehicle_Metafields>;
  /** fetch data from the table in a streaming manner: "vehicle_metafields" */
  vehicle_metafields_stream: Array<Vehicle_Metafields>;
  /** fetch data from the table: "vehicle_notes" using primary key columns */
  vehicle_note?: Maybe<VehicleNote>;
  /** fetch data from the table: "vehicle_notes" */
  vehicle_notes: Array<VehicleNote>;
  /** fetch aggregated fields from the table: "vehicle_notes" */
  vehicle_notes_aggregate: VehicleNote_Aggregate;
  /** fetch data from the table: "vehicle_owner_informations" */
  vehicle_owner_informations: Array<Vehicle_Owner_Informations>;
  /** fetch aggregated fields from the table: "vehicle_owner_informations" */
  vehicle_owner_informations_aggregate: Vehicle_Owner_Informations_Aggregate;
  /** fetch data from the table: "vehicle_owner_informations" using primary key columns */
  vehicle_owner_informations_by_pk?: Maybe<Vehicle_Owner_Informations>;
  /** fetch data from the table in a streaming manner: "vehicle_owner_informations" */
  vehicle_owner_informations_stream: Array<Vehicle_Owner_Informations>;
  /** fetch data from the table: "vehicles" */
  vehicles: Array<Vehicle>;
  /** fetch aggregated fields from the table: "vehicles" */
  vehicles_aggregate: Vehicle_Aggregate;
  /** fetch data from the table: "work_order_status" */
  work_order_status: Array<Work_Order_Status>;
  /** fetch aggregated fields from the table: "work_order_status" */
  work_order_status_aggregate: Work_Order_Status_Aggregate;
  /** fetch data from the table: "work_order_status" using primary key columns */
  work_order_status_by_pk?: Maybe<Work_Order_Status>;
  /** fetch data from the table in a streaming manner: "work_order_status" */
  work_order_status_stream: Array<Work_Order_Status>;
  /** fetch data from the table: "work_order_task_category" */
  work_order_task_category: Array<Work_Order_Task_Category>;
  /** fetch aggregated fields from the table: "work_order_task_category" */
  work_order_task_category_aggregate: Work_Order_Task_Category_Aggregate;
  /** fetch data from the table: "work_order_task_category" using primary key columns */
  work_order_task_category_by_pk?: Maybe<Work_Order_Task_Category>;
  /** fetch data from the table in a streaming manner: "work_order_task_category" */
  work_order_task_category_stream: Array<Work_Order_Task_Category>;
  /** An array relationship */
  work_orders: Array<Work_Orders>;
  /** An aggregate relationship */
  work_orders_aggregate: Work_Orders_Aggregate;
  /** fetch data from the table: "work_orders" using primary key columns */
  work_orders_by_pk?: Maybe<Work_Orders>;
  /** fetch data from the table in a streaming manner: "work_orders" */
  work_orders_stream: Array<Work_Orders>;
}


export interface Subscription_RootAddress_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Address_Stream_Cursor_Input>>;
  where?: Maybe<Address_Bool_Exp>;
}


export interface Subscription_RootAfterSalesPotential_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<AfterSalesPotential_Stream_Cursor_Input>>;
  where?: Maybe<AfterSalesPotential_Bool_Exp>;
}


export interface Subscription_RootAppointment_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Appointment_Stream_Cursor_Input>>;
  where?: Maybe<Appointment_Bool_Exp>;
}


export interface Subscription_RootBrand_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Brand_Stream_Cursor_Input>>;
  where?: Maybe<Brand_Bool_Exp>;
}


export interface Subscription_RootContactNote_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ContactNote_Stream_Cursor_Input>>;
  where?: Maybe<ContactNote_Bool_Exp>;
}


export interface Subscription_RootCustomerGdprInfo_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CustomerGdprInfo_Stream_Cursor_Input>>;
  where?: Maybe<CustomerGdprInfo_Bool_Exp>;
}


export interface Subscription_RootCustomerVehicle_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<CustomerVehicle_Stream_Cursor_Input>>;
  where?: Maybe<CustomerVehicle_Bool_Exp>;
}


export interface Subscription_RootCustomer_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Bool_Exp>;
}


export interface Subscription_RootDealerVehicleInbox_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<DealerVehicleInbox_Stream_Cursor_Input>>;
  where?: Maybe<DealerVehicleInbox_Bool_Exp>;
}


export interface Subscription_RootDealerVehicleNote_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<DealerVehicleNote_Stream_Cursor_Input>>;
  where?: Maybe<DealerVehicleNote_Bool_Exp>;
}


export interface Subscription_RootDealerVehicle_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<DealerVehicle_Stream_Cursor_Input>>;
  where?: Maybe<DealerVehicle_Bool_Exp>;
}


export interface Subscription_RootDepartment_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Department_Stream_Cursor_Input>>;
  where?: Maybe<Department_Bool_Exp>;
}


export interface Subscription_RootDmsReference_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<DmsReference_Stream_Cursor_Input>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


export interface Subscription_RootLocationBrandReference_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<LocationBrandReference_Stream_Cursor_Input>>;
  where?: Maybe<LocationBrandReference_Bool_Exp>;
}


export interface Subscription_RootLocationGroup_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<LocationGroup_Stream_Cursor_Input>>;
  where?: Maybe<LocationGroup_Bool_Exp>;
}


export interface Subscription_RootLocation_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Location_Stream_Cursor_Input>>;
  where?: Maybe<Location_Bool_Exp>;
}


export interface Subscription_RootModelGroup_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<ModelGroup_Stream_Cursor_Input>>;
  where?: Maybe<ModelGroup_Bool_Exp>;
}


export interface Subscription_RootModel_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Model_Stream_Cursor_Input>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Subscription_RootNotes_AggregateArgs {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
}


export interface Subscription_RootNotes_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Notes_Stream_Cursor_Input>>;
  where?: Maybe<Notes_Bool_Exp>;
}


export interface Subscription_RootPhoneNumber_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<PhoneNumber_Stream_Cursor_Input>>;
  where?: Maybe<PhoneNumber_Bool_Exp>;
}


export interface Subscription_RootStorageLocation_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<StorageLocation_Stream_Cursor_Input>>;
  where?: Maybe<StorageLocation_Bool_Exp>;
}


export interface Subscription_RootSubdealer_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Subdealer_Stream_Cursor_Input>>;
  where?: Maybe<Subdealer_Bool_Exp>;
}


export interface Subscription_RootSupplier_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Supplier_Stream_Cursor_Input>>;
  where?: Maybe<Supplier_Bool_Exp>;
}


export interface Subscription_RootTaskType_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<TaskType_Stream_Cursor_Input>>;
  where?: Maybe<TaskType_Bool_Exp>;
}


export interface Subscription_RootTicketNote_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<TicketNote_Stream_Cursor_Input>>;
  where?: Maybe<TicketNote_Bool_Exp>;
}


export interface Subscription_RootTicketTemplate_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<TicketTemplate_Stream_Cursor_Input>>;
  where?: Maybe<TicketTemplate_Bool_Exp>;
}


export interface Subscription_RootTicket_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ticket_Stream_Cursor_Input>>;
  where?: Maybe<Ticket_Bool_Exp>;
}


export interface Subscription_RootUser_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Stream_Cursor_Input>>;
  where?: Maybe<User_Bool_Exp>;
}


export interface Subscription_RootVehicleNote_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<VehicleNote_Stream_Cursor_Input>>;
  where?: Maybe<VehicleNote_Bool_Exp>;
}


export interface Subscription_RootVehicle_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Bool_Exp>;
}


export interface Subscription_RootAddressArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootAddressesArgs {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
}


export interface Subscription_RootAddresses_AggregateArgs {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
}


export interface Subscription_RootAfter_Sales_PotentialArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootAfter_Sales_PotentialsArgs {
  distinct_on?: Maybe<Array<AfterSalesPotential_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AfterSalesPotential_Order_By>>;
  where?: Maybe<AfterSalesPotential_Bool_Exp>;
}


export interface Subscription_RootAfter_Sales_Potentials_AggregateArgs {
  distinct_on?: Maybe<Array<AfterSalesPotential_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AfterSalesPotential_Order_By>>;
  where?: Maybe<AfterSalesPotential_Bool_Exp>;
}


export interface Subscription_RootAfter_Sales_StatusArgs {
  distinct_on?: Maybe<Array<After_Sales_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<After_Sales_Status_Order_By>>;
  where?: Maybe<After_Sales_Status_Bool_Exp>;
}


export interface Subscription_RootAfter_Sales_Status_AggregateArgs {
  distinct_on?: Maybe<Array<After_Sales_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<After_Sales_Status_Order_By>>;
  where?: Maybe<After_Sales_Status_Bool_Exp>;
}


export interface Subscription_RootAfter_Sales_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootAfter_Sales_Status_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<After_Sales_Status_Stream_Cursor_Input>>;
  where?: Maybe<After_Sales_Status_Bool_Exp>;
}


export interface Subscription_RootAppointmentArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootAppointmentsArgs {
  distinct_on?: Maybe<Array<Appointment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointment_Order_By>>;
  where?: Maybe<Appointment_Bool_Exp>;
}


export interface Subscription_RootAppointments_AggregateArgs {
  distinct_on?: Maybe<Array<Appointment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointment_Order_By>>;
  where?: Maybe<Appointment_Bool_Exp>;
}


export interface Subscription_RootAsset_Link_Family_NotificationsArgs {
  distinct_on?: Maybe<Array<Asset_Link_Family_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Link_Family_Notifications_Order_By>>;
  where?: Maybe<Asset_Link_Family_Notifications_Bool_Exp>;
}


export interface Subscription_RootAsset_Link_Family_Notifications_AggregateArgs {
  distinct_on?: Maybe<Array<Asset_Link_Family_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Link_Family_Notifications_Order_By>>;
  where?: Maybe<Asset_Link_Family_Notifications_Bool_Exp>;
}


export interface Subscription_RootAsset_Link_Family_Notifications_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootAsset_Link_Family_Notifications_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Asset_Link_Family_Notifications_Stream_Cursor_Input>>;
  where?: Maybe<Asset_Link_Family_Notifications_Bool_Exp>;
}


export interface Subscription_RootAsset_Watchdog_Link_StatusArgs {
  distinct_on?: Maybe<Array<Asset_Watchdog_Link_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Watchdog_Link_Status_Order_By>>;
  where?: Maybe<Asset_Watchdog_Link_Status_Bool_Exp>;
}


export interface Subscription_RootAsset_Watchdog_Link_Status_AggregateArgs {
  distinct_on?: Maybe<Array<Asset_Watchdog_Link_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Watchdog_Link_Status_Order_By>>;
  where?: Maybe<Asset_Watchdog_Link_Status_Bool_Exp>;
}


export interface Subscription_RootAsset_Watchdog_Link_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootAsset_Watchdog_Link_Status_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Asset_Watchdog_Link_Status_Stream_Cursor_Input>>;
  where?: Maybe<Asset_Watchdog_Link_Status_Bool_Exp>;
}


export interface Subscription_RootAsset_Watchdog_LinksArgs {
  distinct_on?: Maybe<Array<Asset_Watchdog_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Watchdog_Links_Order_By>>;
  where?: Maybe<Asset_Watchdog_Links_Bool_Exp>;
}


export interface Subscription_RootAsset_Watchdog_Links_AggregateArgs {
  distinct_on?: Maybe<Array<Asset_Watchdog_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Watchdog_Links_Order_By>>;
  where?: Maybe<Asset_Watchdog_Links_Bool_Exp>;
}


export interface Subscription_RootAsset_Watchdog_Links_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootAsset_Watchdog_Links_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Asset_Watchdog_Links_Stream_Cursor_Input>>;
  where?: Maybe<Asset_Watchdog_Links_Bool_Exp>;
}


export interface Subscription_RootBrandArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootBrand_Boni_TypesArgs {
  distinct_on?: Maybe<Array<Brand_Boni_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Boni_Types_Order_By>>;
  where?: Maybe<Brand_Boni_Types_Bool_Exp>;
}


export interface Subscription_RootBrand_Boni_Types_AggregateArgs {
  distinct_on?: Maybe<Array<Brand_Boni_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Boni_Types_Order_By>>;
  where?: Maybe<Brand_Boni_Types_Bool_Exp>;
}


export interface Subscription_RootBrand_Boni_Types_By_PkArgs {
  brand_id: Scalars['uuid'];
  type: Scalars['String'];
}


export interface Subscription_RootBrand_Boni_Types_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Brand_Boni_Types_Stream_Cursor_Input>>;
  where?: Maybe<Brand_Boni_Types_Bool_Exp>;
}


export interface Subscription_RootBrand_SupplierArgs {
  brand_id: Scalars['uuid'];
  supplier_id: Scalars['uuid'];
}


export interface Subscription_RootBrand_SuppliersArgs {
  distinct_on?: Maybe<Array<Brand_Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Suppliers_Order_By>>;
  where?: Maybe<Brand_Suppliers_Bool_Exp>;
}


export interface Subscription_RootBrand_Suppliers_AggregateArgs {
  distinct_on?: Maybe<Array<Brand_Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Suppliers_Order_By>>;
  where?: Maybe<Brand_Suppliers_Bool_Exp>;
}


export interface Subscription_RootBrand_Suppliers_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Brand_Suppliers_Stream_Cursor_Input>>;
  where?: Maybe<Brand_Suppliers_Bool_Exp>;
}


export interface Subscription_RootBrand_Vehicle_MetafieldArgs {
  distinct_on?: Maybe<Array<Brand_Vehicle_Metafield_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Vehicle_Metafield_Order_By>>;
  where?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
}


export interface Subscription_RootBrand_Vehicle_Metafield_AggregateArgs {
  distinct_on?: Maybe<Array<Brand_Vehicle_Metafield_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Vehicle_Metafield_Order_By>>;
  where?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
}


export interface Subscription_RootBrand_Vehicle_Metafield_By_PkArgs {
  brand_id: Scalars['uuid'];
  vehicle_metafield_name: Vehicle_Metafields_Enum;
}


export interface Subscription_RootBrand_Vehicle_Metafield_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Brand_Vehicle_Metafield_Stream_Cursor_Input>>;
  where?: Maybe<Brand_Vehicle_Metafield_Bool_Exp>;
}


export interface Subscription_RootBrandsArgs {
  distinct_on?: Maybe<Array<Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Order_By>>;
  where?: Maybe<Brand_Bool_Exp>;
}


export interface Subscription_RootBrands_AggregateArgs {
  distinct_on?: Maybe<Array<Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Order_By>>;
  where?: Maybe<Brand_Bool_Exp>;
}


export interface Subscription_RootContact_NoteArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootContact_Note_TypeArgs {
  distinct_on?: Maybe<Array<Contact_Note_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Note_Type_Order_By>>;
  where?: Maybe<Contact_Note_Type_Bool_Exp>;
}


export interface Subscription_RootContact_Note_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Contact_Note_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Note_Type_Order_By>>;
  where?: Maybe<Contact_Note_Type_Bool_Exp>;
}


export interface Subscription_RootContact_Note_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootContact_Note_Type_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Contact_Note_Type_Stream_Cursor_Input>>;
  where?: Maybe<Contact_Note_Type_Bool_Exp>;
}


export interface Subscription_RootContact_NotesArgs {
  distinct_on?: Maybe<Array<ContactNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactNote_Order_By>>;
  where?: Maybe<ContactNote_Bool_Exp>;
}


export interface Subscription_RootContact_Notes_AggregateArgs {
  distinct_on?: Maybe<Array<ContactNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactNote_Order_By>>;
  where?: Maybe<ContactNote_Bool_Exp>;
}


export interface Subscription_RootCustomerArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootCustomer_Customer_TagArgs {
  distinct_on?: Maybe<Array<Customer_Customer_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Customer_Tag_Order_By>>;
  where?: Maybe<Customer_Customer_Tag_Bool_Exp>;
}


export interface Subscription_RootCustomer_Customer_Tag_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Customer_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Customer_Tag_Order_By>>;
  where?: Maybe<Customer_Customer_Tag_Bool_Exp>;
}


export interface Subscription_RootCustomer_Customer_Tag_By_PkArgs {
  customer_id: Scalars['uuid'];
  customer_tag_value: Customer_Tag_Enum;
}


export interface Subscription_RootCustomer_Customer_Tag_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Customer_Tag_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Customer_Tag_Bool_Exp>;
}


export interface Subscription_RootCustomer_Gdpr_InfoArgs {
  customer_id: Scalars['uuid'];
}


export interface Subscription_RootCustomer_Gdpr_InfosArgs {
  distinct_on?: Maybe<Array<CustomerGdprInfo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerGdprInfo_Order_By>>;
  where?: Maybe<CustomerGdprInfo_Bool_Exp>;
}


export interface Subscription_RootCustomer_Gdpr_Infos_AggregateArgs {
  distinct_on?: Maybe<Array<CustomerGdprInfo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerGdprInfo_Order_By>>;
  where?: Maybe<CustomerGdprInfo_Bool_Exp>;
}


export interface Subscription_RootCustomer_SalutationArgs {
  distinct_on?: Maybe<Array<Customer_Salutation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Salutation_Order_By>>;
  where?: Maybe<Customer_Salutation_Bool_Exp>;
}


export interface Subscription_RootCustomer_Salutation_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Salutation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Salutation_Order_By>>;
  where?: Maybe<Customer_Salutation_Bool_Exp>;
}


export interface Subscription_RootCustomer_Salutation_By_PkArgs {
  customer_id: Scalars['uuid'];
  salutation_type_value: Salutation_Type_Enum;
}


export interface Subscription_RootCustomer_Salutation_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Salutation_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Salutation_Bool_Exp>;
}


export interface Subscription_RootCustomer_TagArgs {
  distinct_on?: Maybe<Array<Customer_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Tag_Order_By>>;
  where?: Maybe<Customer_Tag_Bool_Exp>;
}


export interface Subscription_RootCustomer_Tag_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Tag_Order_By>>;
  where?: Maybe<Customer_Tag_Bool_Exp>;
}


export interface Subscription_RootCustomer_Tag_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootCustomer_Tag_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Tag_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Tag_Bool_Exp>;
}


export interface Subscription_RootCustomer_TypeArgs {
  distinct_on?: Maybe<Array<Customer_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Type_Order_By>>;
  where?: Maybe<Customer_Type_Bool_Exp>;
}


export interface Subscription_RootCustomer_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Type_Order_By>>;
  where?: Maybe<Customer_Type_Bool_Exp>;
}


export interface Subscription_RootCustomer_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootCustomer_Type_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Type_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Type_Bool_Exp>;
}


export interface Subscription_RootCustomer_VehicleArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootCustomer_VehiclesArgs {
  distinct_on?: Maybe<Array<CustomerVehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerVehicle_Order_By>>;
  where?: Maybe<CustomerVehicle_Bool_Exp>;
}


export interface Subscription_RootCustomer_Vehicles_AggregateArgs {
  distinct_on?: Maybe<Array<CustomerVehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CustomerVehicle_Order_By>>;
  where?: Maybe<CustomerVehicle_Bool_Exp>;
}


export interface Subscription_RootCustomersArgs {
  distinct_on?: Maybe<Array<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
}


export interface Subscription_RootCustomers_AggregateArgs {
  distinct_on?: Maybe<Array<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
}


export interface Subscription_RootDealer_VehicleArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootDealer_Vehicle_Import_TemplatesArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Import_Templates_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Import_Templates_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_Import_Templates_AggregateArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Import_Templates_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Import_Templates_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_Import_Templates_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootDealer_Vehicle_Import_Templates_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Dealer_Vehicle_Import_Templates_Stream_Cursor_Input>>;
  where?: Maybe<Dealer_Vehicle_Import_Templates_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_InboxArgs {
  distinct_on?: Maybe<Array<DealerVehicleInbox_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicleInbox_Order_By>>;
  where?: Maybe<DealerVehicleInbox_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_Inbox_AggregateArgs {
  distinct_on?: Maybe<Array<DealerVehicleInbox_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicleInbox_Order_By>>;
  where?: Maybe<DealerVehicleInbox_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_Inbox_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootDealer_Vehicle_Inbox_Import_TemplatesArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_Inbox_Import_Templates_AggregateArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Inbox_Import_Templates_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_Inbox_Import_Templates_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootDealer_Vehicle_Inbox_Import_Templates_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Dealer_Vehicle_Inbox_Import_Templates_Stream_Cursor_Input>>;
  where?: Maybe<Dealer_Vehicle_Inbox_Import_Templates_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_NoteArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootDealer_Vehicle_NotesArgs {
  distinct_on?: Maybe<Array<DealerVehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicleNote_Order_By>>;
  where?: Maybe<DealerVehicleNote_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_Notes_AggregateArgs {
  distinct_on?: Maybe<Array<DealerVehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicleNote_Order_By>>;
  where?: Maybe<DealerVehicleNote_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_StateArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_State_Order_By>>;
  where?: Maybe<Dealer_Vehicle_State_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_State_AggregateArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_State_Order_By>>;
  where?: Maybe<Dealer_Vehicle_State_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_State_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootDealer_Vehicle_State_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Dealer_Vehicle_State_Stream_Cursor_Input>>;
  where?: Maybe<Dealer_Vehicle_State_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_TypeArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Type_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Type_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Dealer_Vehicle_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dealer_Vehicle_Type_Order_By>>;
  where?: Maybe<Dealer_Vehicle_Type_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicle_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootDealer_Vehicle_Type_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Dealer_Vehicle_Type_Stream_Cursor_Input>>;
  where?: Maybe<Dealer_Vehicle_Type_Bool_Exp>;
}


export interface Subscription_RootDealer_VehiclesArgs {
  distinct_on?: Maybe<Array<DealerVehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicle_Order_By>>;
  where?: Maybe<DealerVehicle_Bool_Exp>;
}


export interface Subscription_RootDealer_Vehicles_AggregateArgs {
  distinct_on?: Maybe<Array<DealerVehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DealerVehicle_Order_By>>;
  where?: Maybe<DealerVehicle_Bool_Exp>;
}


export interface Subscription_RootDepartmentArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootDepartment_RoleArgs {
  distinct_on?: Maybe<Array<Department_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Department_Role_Order_By>>;
  where?: Maybe<Department_Role_Bool_Exp>;
}


export interface Subscription_RootDepartment_Role_AggregateArgs {
  distinct_on?: Maybe<Array<Department_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Department_Role_Order_By>>;
  where?: Maybe<Department_Role_Bool_Exp>;
}


export interface Subscription_RootDepartment_Role_By_PkArgs {
  department_id: Scalars['uuid'];
  role: Role_Enum;
}


export interface Subscription_RootDepartment_Role_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Department_Role_Stream_Cursor_Input>>;
  where?: Maybe<Department_Role_Bool_Exp>;
}


export interface Subscription_RootDepartmentsArgs {
  distinct_on?: Maybe<Array<Department_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Department_Order_By>>;
  where?: Maybe<Department_Bool_Exp>;
}


export interface Subscription_RootDepartments_AggregateArgs {
  distinct_on?: Maybe<Array<Department_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Department_Order_By>>;
  where?: Maybe<Department_Bool_Exp>;
}


export interface Subscription_RootDms_ReferenceArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootDms_ReferencesArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


export interface Subscription_RootDms_References_AggregateArgs {
  distinct_on?: Maybe<Array<DmsReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DmsReference_Order_By>>;
  where?: Maybe<DmsReference_Bool_Exp>;
}


export interface Subscription_RootDms_TypeArgs {
  distinct_on?: Maybe<Array<Dms_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dms_Type_Order_By>>;
  where?: Maybe<Dms_Type_Bool_Exp>;
}


export interface Subscription_RootDms_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Dms_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dms_Type_Order_By>>;
  where?: Maybe<Dms_Type_Bool_Exp>;
}


export interface Subscription_RootDms_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootDms_Type_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Dms_Type_Stream_Cursor_Input>>;
  where?: Maybe<Dms_Type_Bool_Exp>;
}


export interface Subscription_RootEmbedded_PagesArgs {
  distinct_on?: Maybe<Array<Embedded_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Embedded_Pages_Order_By>>;
  where?: Maybe<Embedded_Pages_Bool_Exp>;
}


export interface Subscription_RootEmbedded_Pages_AggregateArgs {
  distinct_on?: Maybe<Array<Embedded_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Embedded_Pages_Order_By>>;
  where?: Maybe<Embedded_Pages_Bool_Exp>;
}


export interface Subscription_RootEmbedded_Pages_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootEmbedded_Pages_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Embedded_Pages_Stream_Cursor_Input>>;
  where?: Maybe<Embedded_Pages_Bool_Exp>;
}


export interface Subscription_RootExcel_Import_AssignmentsArgs {
  distinct_on?: Maybe<Array<Excel_Import_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Excel_Import_Assignments_Order_By>>;
  where?: Maybe<Excel_Import_Assignments_Bool_Exp>;
}


export interface Subscription_RootExcel_Import_Assignments_AggregateArgs {
  distinct_on?: Maybe<Array<Excel_Import_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Excel_Import_Assignments_Order_By>>;
  where?: Maybe<Excel_Import_Assignments_Bool_Exp>;
}


export interface Subscription_RootExcel_Import_Assignments_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootExcel_Import_Assignments_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Excel_Import_Assignments_Stream_Cursor_Input>>;
  where?: Maybe<Excel_Import_Assignments_Bool_Exp>;
}


export interface Subscription_RootFinancing_TypeArgs {
  distinct_on?: Maybe<Array<Financing_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Type_Order_By>>;
  where?: Maybe<Financing_Type_Bool_Exp>;
}


export interface Subscription_RootFinancing_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Financing_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Financing_Type_Order_By>>;
  where?: Maybe<Financing_Type_Bool_Exp>;
}


export interface Subscription_RootFinancing_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootFinancing_Type_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Financing_Type_Stream_Cursor_Input>>;
  where?: Maybe<Financing_Type_Bool_Exp>;
}


export interface Subscription_RootHouse_BrandsArgs {
  args?: Maybe<House_Brands_Args>;
  distinct_on?: Maybe<Array<Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Order_By>>;
  where?: Maybe<Brand_Bool_Exp>;
}


export interface Subscription_RootHouse_Brands_AggregateArgs {
  args?: Maybe<House_Brands_Args>;
  distinct_on?: Maybe<Array<Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Brand_Order_By>>;
  where?: Maybe<Brand_Bool_Exp>;
}


export interface Subscription_RootImport_Job_Executable_StatusArgs {
  distinct_on?: Maybe<Array<Import_Job_Executable_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Executable_Status_Order_By>>;
  where?: Maybe<Import_Job_Executable_Status_Bool_Exp>;
}


export interface Subscription_RootImport_Job_Executable_Status_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Job_Executable_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Executable_Status_Order_By>>;
  where?: Maybe<Import_Job_Executable_Status_Bool_Exp>;
}


export interface Subscription_RootImport_Job_Executable_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootImport_Job_Executable_Status_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Import_Job_Executable_Status_Stream_Cursor_Input>>;
  where?: Maybe<Import_Job_Executable_Status_Bool_Exp>;
}


export interface Subscription_RootImport_Job_StatusArgs {
  distinct_on?: Maybe<Array<Import_Job_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Status_Order_By>>;
  where?: Maybe<Import_Job_Status_Bool_Exp>;
}


export interface Subscription_RootImport_Job_Status_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Job_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Status_Order_By>>;
  where?: Maybe<Import_Job_Status_Bool_Exp>;
}


export interface Subscription_RootImport_Job_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootImport_Job_Status_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Import_Job_Status_Stream_Cursor_Input>>;
  where?: Maybe<Import_Job_Status_Bool_Exp>;
}


export interface Subscription_RootImport_Job_Vehicle_ExecutablesArgs {
  distinct_on?: Maybe<Array<Import_Job_Vehicle_Executables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Vehicle_Executables_Order_By>>;
  where?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
}


export interface Subscription_RootImport_Job_Vehicle_Executables_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Job_Vehicle_Executables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Job_Vehicle_Executables_Order_By>>;
  where?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
}


export interface Subscription_RootImport_Job_Vehicle_Executables_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootImport_Job_Vehicle_Executables_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Import_Job_Vehicle_Executables_Stream_Cursor_Input>>;
  where?: Maybe<Import_Job_Vehicle_Executables_Bool_Exp>;
}


export interface Subscription_RootImport_JobsArgs {
  distinct_on?: Maybe<Array<Import_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Jobs_Order_By>>;
  where?: Maybe<Import_Jobs_Bool_Exp>;
}


export interface Subscription_RootImport_Jobs_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Jobs_Order_By>>;
  where?: Maybe<Import_Jobs_Bool_Exp>;
}


export interface Subscription_RootImport_Jobs_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootImport_Jobs_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Import_Jobs_Stream_Cursor_Input>>;
  where?: Maybe<Import_Jobs_Bool_Exp>;
}


export interface Subscription_RootImport_TypeArgs {
  distinct_on?: Maybe<Array<Import_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Type_Order_By>>;
  where?: Maybe<Import_Type_Bool_Exp>;
}


export interface Subscription_RootImport_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Import_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Import_Type_Order_By>>;
  where?: Maybe<Import_Type_Bool_Exp>;
}


export interface Subscription_RootImport_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootImport_Type_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Import_Type_Stream_Cursor_Input>>;
  where?: Maybe<Import_Type_Bool_Exp>;
}


export interface Subscription_RootIn_Buy_ChannelArgs {
  distinct_on?: Maybe<Array<In_Buy_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<In_Buy_Channel_Order_By>>;
  where?: Maybe<In_Buy_Channel_Bool_Exp>;
}


export interface Subscription_RootIn_Buy_Channel_AggregateArgs {
  distinct_on?: Maybe<Array<In_Buy_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<In_Buy_Channel_Order_By>>;
  where?: Maybe<In_Buy_Channel_Bool_Exp>;
}


export interface Subscription_RootIn_Buy_Channel_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootIn_Buy_Channel_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<In_Buy_Channel_Stream_Cursor_Input>>;
  where?: Maybe<In_Buy_Channel_Bool_Exp>;
}


export interface Subscription_RootLocationArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootLocation_Brand_ReferenceArgs {
  brand_id: Scalars['uuid'];
  location_id: Scalars['uuid'];
}


export interface Subscription_RootLocation_Brand_ReferencesArgs {
  distinct_on?: Maybe<Array<LocationBrandReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationBrandReference_Order_By>>;
  where?: Maybe<LocationBrandReference_Bool_Exp>;
}


export interface Subscription_RootLocation_Brand_References_AggregateArgs {
  distinct_on?: Maybe<Array<LocationBrandReference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationBrandReference_Order_By>>;
  where?: Maybe<LocationBrandReference_Bool_Exp>;
}


export interface Subscription_RootLocation_GroupArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootLocation_Group_BrandArgs {
  distinct_on?: Maybe<Array<Location_Group_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brand_Order_By>>;
  where?: Maybe<Location_Group_Brand_Bool_Exp>;
}


export interface Subscription_RootLocation_Group_Brand_AggregateArgs {
  distinct_on?: Maybe<Array<Location_Group_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brand_Order_By>>;
  where?: Maybe<Location_Group_Brand_Bool_Exp>;
}


export interface Subscription_RootLocation_Group_Brand_By_PkArgs {
  brand_id: Scalars['uuid'];
  location_group_id: Scalars['uuid'];
}


export interface Subscription_RootLocation_Group_Brand_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Location_Group_Brand_Stream_Cursor_Input>>;
  where?: Maybe<Location_Group_Brand_Bool_Exp>;
}


export interface Subscription_RootLocation_Group_Brands_ViewArgs {
  distinct_on?: Maybe<Array<Location_Group_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brands_View_Order_By>>;
  where?: Maybe<Location_Group_Brands_View_Bool_Exp>;
}


export interface Subscription_RootLocation_Group_Brands_View_AggregateArgs {
  distinct_on?: Maybe<Array<Location_Group_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Group_Brands_View_Order_By>>;
  where?: Maybe<Location_Group_Brands_View_Bool_Exp>;
}


export interface Subscription_RootLocation_Group_Brands_View_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Location_Group_Brands_View_Stream_Cursor_Input>>;
  where?: Maybe<Location_Group_Brands_View_Bool_Exp>;
}


export interface Subscription_RootLocation_GroupsArgs {
  distinct_on?: Maybe<Array<LocationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationGroup_Order_By>>;
  where?: Maybe<LocationGroup_Bool_Exp>;
}


export interface Subscription_RootLocation_Groups_AggregateArgs {
  distinct_on?: Maybe<Array<LocationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LocationGroup_Order_By>>;
  where?: Maybe<LocationGroup_Bool_Exp>;
}


export interface Subscription_RootLocationsArgs {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
}


export interface Subscription_RootLocations_AggregateArgs {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
}


export interface Subscription_RootModelArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootModel_GroupArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootModel_Group_Release_YearsArgs {
  distinct_on?: Maybe<Array<Model_Group_Release_Years_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Group_Release_Years_Order_By>>;
  where?: Maybe<Model_Group_Release_Years_Bool_Exp>;
}


export interface Subscription_RootModel_Group_Release_Years_AggregateArgs {
  distinct_on?: Maybe<Array<Model_Group_Release_Years_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Group_Release_Years_Order_By>>;
  where?: Maybe<Model_Group_Release_Years_Bool_Exp>;
}


export interface Subscription_RootModel_Group_Release_Years_By_PkArgs {
  model_group_id: Scalars['uuid'];
  release_year: Scalars['Int'];
}


export interface Subscription_RootModel_Group_Release_Years_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Model_Group_Release_Years_Stream_Cursor_Input>>;
  where?: Maybe<Model_Group_Release_Years_Bool_Exp>;
}


export interface Subscription_RootModel_GroupsArgs {
  distinct_on?: Maybe<Array<ModelGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModelGroup_Order_By>>;
  where?: Maybe<ModelGroup_Bool_Exp>;
}


export interface Subscription_RootModel_Groups_AggregateArgs {
  distinct_on?: Maybe<Array<ModelGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ModelGroup_Order_By>>;
  where?: Maybe<ModelGroup_Bool_Exp>;
}


export interface Subscription_RootModelsArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Subscription_RootModels_AggregateArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Subscription_RootModels_In_StockArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Subscription_RootModels_In_Stock_AggregateArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Subscription_RootNew_ModelsArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Subscription_RootNew_Models_AggregateArgs {
  distinct_on?: Maybe<Array<Model_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Model_Order_By>>;
  where?: Maybe<Model_Bool_Exp>;
}


export interface Subscription_RootNoteArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootNotesArgs {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
}


export interface Subscription_RootNotification_StatusArgs {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Status_Order_By>>;
  where?: Maybe<Notification_Status_Bool_Exp>;
}


export interface Subscription_RootNotification_Status_AggregateArgs {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Status_Order_By>>;
  where?: Maybe<Notification_Status_Bool_Exp>;
}


export interface Subscription_RootNotification_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootNotification_Status_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Notification_Status_Stream_Cursor_Input>>;
  where?: Maybe<Notification_Status_Bool_Exp>;
}


export interface Subscription_RootNotification_TypeArgs {
  distinct_on?: Maybe<Array<Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Type_Order_By>>;
  where?: Maybe<Notification_Type_Bool_Exp>;
}


export interface Subscription_RootNotification_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Type_Order_By>>;
  where?: Maybe<Notification_Type_Bool_Exp>;
}


export interface Subscription_RootNotification_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootNotification_Type_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Notification_Type_Stream_Cursor_Input>>;
  where?: Maybe<Notification_Type_Bool_Exp>;
}


export interface Subscription_RootNotificationsArgs {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
}


export interface Subscription_RootNotifications_AggregateArgs {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
}


export interface Subscription_RootNotifications_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Notifications_Stream_Cursor_Input>>;
  where?: Maybe<Notifications_Bool_Exp>;
}


export interface Subscription_RootOrder_StateArgs {
  distinct_on?: Maybe<Array<Order_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_State_Order_By>>;
  where?: Maybe<Order_State_Bool_Exp>;
}


export interface Subscription_RootOrder_State_AggregateArgs {
  distinct_on?: Maybe<Array<Order_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_State_Order_By>>;
  where?: Maybe<Order_State_Bool_Exp>;
}


export interface Subscription_RootOrder_State_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootOrder_State_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_State_Stream_Cursor_Input>>;
  where?: Maybe<Order_State_Bool_Exp>;
}


export interface Subscription_RootPhone_NumberArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootPhone_Number_TypeArgs {
  distinct_on?: Maybe<Array<Phone_Number_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Number_Type_Order_By>>;
  where?: Maybe<Phone_Number_Type_Bool_Exp>;
}


export interface Subscription_RootPhone_Number_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Phone_Number_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Number_Type_Order_By>>;
  where?: Maybe<Phone_Number_Type_Bool_Exp>;
}


export interface Subscription_RootPhone_Number_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootPhone_Number_Type_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Phone_Number_Type_Stream_Cursor_Input>>;
  where?: Maybe<Phone_Number_Type_Bool_Exp>;
}


export interface Subscription_RootPhone_NumbersArgs {
  distinct_on?: Maybe<Array<PhoneNumber_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PhoneNumber_Order_By>>;
  where?: Maybe<PhoneNumber_Bool_Exp>;
}


export interface Subscription_RootPhone_Numbers_AggregateArgs {
  distinct_on?: Maybe<Array<PhoneNumber_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PhoneNumber_Order_By>>;
  where?: Maybe<PhoneNumber_Bool_Exp>;
}


export interface Subscription_RootRegistration_StateArgs {
  distinct_on?: Maybe<Array<Registration_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_State_Order_By>>;
  where?: Maybe<Registration_State_Bool_Exp>;
}


export interface Subscription_RootRegistration_State_AggregateArgs {
  distinct_on?: Maybe<Array<Registration_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_State_Order_By>>;
  where?: Maybe<Registration_State_Bool_Exp>;
}


export interface Subscription_RootRegistration_State_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootRegistration_State_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Registration_State_Stream_Cursor_Input>>;
  where?: Maybe<Registration_State_Bool_Exp>;
}


export interface Subscription_RootRoleArgs {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
}


export interface Subscription_RootRole_AggregateArgs {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
}


export interface Subscription_RootRole_By_PkArgs {
  role: Scalars['String'];
}


export interface Subscription_RootRole_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Role_Stream_Cursor_Input>>;
  where?: Maybe<Role_Bool_Exp>;
}


export interface Subscription_RootSales_ReportsArgs {
  distinct_on?: Maybe<Array<Sales_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sales_Reports_Order_By>>;
  where?: Maybe<Sales_Reports_Bool_Exp>;
}


export interface Subscription_RootSales_Reports_AggregateArgs {
  distinct_on?: Maybe<Array<Sales_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sales_Reports_Order_By>>;
  where?: Maybe<Sales_Reports_Bool_Exp>;
}


export interface Subscription_RootSales_Reports_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootSales_Reports_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Sales_Reports_Stream_Cursor_Input>>;
  where?: Maybe<Sales_Reports_Bool_Exp>;
}


export interface Subscription_RootSales_TypeArgs {
  distinct_on?: Maybe<Array<Sales_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sales_Type_Order_By>>;
  where?: Maybe<Sales_Type_Bool_Exp>;
}


export interface Subscription_RootSales_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Sales_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sales_Type_Order_By>>;
  where?: Maybe<Sales_Type_Bool_Exp>;
}


export interface Subscription_RootSales_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootSales_Type_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Sales_Type_Stream_Cursor_Input>>;
  where?: Maybe<Sales_Type_Bool_Exp>;
}


export interface Subscription_RootSalutation_TypeArgs {
  distinct_on?: Maybe<Array<Salutation_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salutation_Type_Order_By>>;
  where?: Maybe<Salutation_Type_Bool_Exp>;
}


export interface Subscription_RootSalutation_Type_AggregateArgs {
  distinct_on?: Maybe<Array<Salutation_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Salutation_Type_Order_By>>;
  where?: Maybe<Salutation_Type_Bool_Exp>;
}


export interface Subscription_RootSalutation_Type_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootSalutation_Type_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Salutation_Type_Stream_Cursor_Input>>;
  where?: Maybe<Salutation_Type_Bool_Exp>;
}


export interface Subscription_RootStorage_LocationArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootStorage_LocationsArgs {
  distinct_on?: Maybe<Array<StorageLocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StorageLocation_Order_By>>;
  where?: Maybe<StorageLocation_Bool_Exp>;
}


export interface Subscription_RootStorage_Locations_AggregateArgs {
  distinct_on?: Maybe<Array<StorageLocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StorageLocation_Order_By>>;
  where?: Maybe<StorageLocation_Bool_Exp>;
}


export interface Subscription_RootSubdealerArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootSubdealer_BrandArgs {
  distinct_on?: Maybe<Array<Subdealer_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brand_Order_By>>;
  where?: Maybe<Subdealer_Brand_Bool_Exp>;
}


export interface Subscription_RootSubdealer_Brand_AggregateArgs {
  distinct_on?: Maybe<Array<Subdealer_Brand_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brand_Order_By>>;
  where?: Maybe<Subdealer_Brand_Bool_Exp>;
}


export interface Subscription_RootSubdealer_Brand_By_PkArgs {
  brand_id: Scalars['uuid'];
  subdealer_id: Scalars['uuid'];
}


export interface Subscription_RootSubdealer_Brand_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Subdealer_Brand_Stream_Cursor_Input>>;
  where?: Maybe<Subdealer_Brand_Bool_Exp>;
}


export interface Subscription_RootSubdealer_Brands_ViewArgs {
  distinct_on?: Maybe<Array<Subdealer_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brands_View_Order_By>>;
  where?: Maybe<Subdealer_Brands_View_Bool_Exp>;
}


export interface Subscription_RootSubdealer_Brands_View_AggregateArgs {
  distinct_on?: Maybe<Array<Subdealer_Brands_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Brands_View_Order_By>>;
  where?: Maybe<Subdealer_Brands_View_Bool_Exp>;
}


export interface Subscription_RootSubdealer_Brands_View_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Subdealer_Brands_View_Stream_Cursor_Input>>;
  where?: Maybe<Subdealer_Brands_View_Bool_Exp>;
}


export interface Subscription_RootSubdealersArgs {
  distinct_on?: Maybe<Array<Subdealer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Order_By>>;
  where?: Maybe<Subdealer_Bool_Exp>;
}


export interface Subscription_RootSubdealers_AggregateArgs {
  distinct_on?: Maybe<Array<Subdealer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subdealer_Order_By>>;
  where?: Maybe<Subdealer_Bool_Exp>;
}


export interface Subscription_RootSupplierArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootSuppliersArgs {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
}


export interface Subscription_RootSuppliers_AggregateArgs {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
}


export interface Subscription_RootSync_ErrorsArgs {
  distinct_on?: Maybe<Array<Sync_Errors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Errors_Order_By>>;
  where?: Maybe<Sync_Errors_Bool_Exp>;
}


export interface Subscription_RootSync_Errors_AggregateArgs {
  distinct_on?: Maybe<Array<Sync_Errors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Errors_Order_By>>;
  where?: Maybe<Sync_Errors_Bool_Exp>;
}


export interface Subscription_RootSync_Errors_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootSync_Errors_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Sync_Errors_Stream_Cursor_Input>>;
  where?: Maybe<Sync_Errors_Bool_Exp>;
}


export interface Subscription_RootTask_CategoryArgs {
  distinct_on?: Maybe<Array<Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Category_Order_By>>;
  where?: Maybe<Task_Category_Bool_Exp>;
}


export interface Subscription_RootTask_Category_AggregateArgs {
  distinct_on?: Maybe<Array<Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Category_Order_By>>;
  where?: Maybe<Task_Category_Bool_Exp>;
}


export interface Subscription_RootTask_Category_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootTask_Category_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Category_Stream_Cursor_Input>>;
  where?: Maybe<Task_Category_Bool_Exp>;
}


export interface Subscription_RootTask_TypeArgs {
  code: Scalars['String'];
}


export interface Subscription_RootTask_Type_Task_CategoryArgs {
  distinct_on?: Maybe<Array<Task_Type_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Type_Task_Category_Order_By>>;
  where?: Maybe<Task_Type_Task_Category_Bool_Exp>;
}


export interface Subscription_RootTask_Type_Task_Category_AggregateArgs {
  distinct_on?: Maybe<Array<Task_Type_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Task_Type_Task_Category_Order_By>>;
  where?: Maybe<Task_Type_Task_Category_Bool_Exp>;
}


export interface Subscription_RootTask_Type_Task_Category_By_PkArgs {
  task_category_value: Task_Category_Enum;
  task_type_code: Scalars['String'];
}


export interface Subscription_RootTask_Type_Task_Category_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Task_Type_Task_Category_Stream_Cursor_Input>>;
  where?: Maybe<Task_Type_Task_Category_Bool_Exp>;
}


export interface Subscription_RootTask_TypesArgs {
  distinct_on?: Maybe<Array<TaskType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TaskType_Order_By>>;
  where?: Maybe<TaskType_Bool_Exp>;
}


export interface Subscription_RootTask_Types_AggregateArgs {
  distinct_on?: Maybe<Array<TaskType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TaskType_Order_By>>;
  where?: Maybe<TaskType_Bool_Exp>;
}


export interface Subscription_RootTeam_AssignmentArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootTeam_AssignmentsArgs {
  distinct_on?: Maybe<Array<TeamAssignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamAssignment_Order_By>>;
  where?: Maybe<TeamAssignment_Bool_Exp>;
}


export interface Subscription_RootTeam_Assignments_AggregateArgs {
  distinct_on?: Maybe<Array<TeamAssignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamAssignment_Order_By>>;
  where?: Maybe<TeamAssignment_Bool_Exp>;
}


export interface Subscription_RootTeam_Assignments_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<TeamAssignment_Stream_Cursor_Input>>;
  where?: Maybe<TeamAssignment_Bool_Exp>;
}


export interface Subscription_RootTicketArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootTicket_Family_NotificationsArgs {
  distinct_on?: Maybe<Array<Ticket_Family_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Family_Notifications_Order_By>>;
  where?: Maybe<Ticket_Family_Notifications_Bool_Exp>;
}


export interface Subscription_RootTicket_Family_Notifications_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Family_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Family_Notifications_Order_By>>;
  where?: Maybe<Ticket_Family_Notifications_Bool_Exp>;
}


export interface Subscription_RootTicket_Family_Notifications_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootTicket_Family_Notifications_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ticket_Family_Notifications_Stream_Cursor_Input>>;
  where?: Maybe<Ticket_Family_Notifications_Bool_Exp>;
}


export interface Subscription_RootTicket_NoteArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootTicket_NotesArgs {
  distinct_on?: Maybe<Array<TicketNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TicketNote_Order_By>>;
  where?: Maybe<TicketNote_Bool_Exp>;
}


export interface Subscription_RootTicket_Notes_AggregateArgs {
  distinct_on?: Maybe<Array<TicketNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TicketNote_Order_By>>;
  where?: Maybe<TicketNote_Bool_Exp>;
}


export interface Subscription_RootTicket_PriorityArgs {
  distinct_on?: Maybe<Array<Ticket_Priority_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Priority_Order_By>>;
  where?: Maybe<Ticket_Priority_Bool_Exp>;
}


export interface Subscription_RootTicket_Priority_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Priority_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Priority_Order_By>>;
  where?: Maybe<Ticket_Priority_Bool_Exp>;
}


export interface Subscription_RootTicket_Priority_By_PkArgs {
  name: Scalars['String'];
}


export interface Subscription_RootTicket_Priority_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ticket_Priority_Stream_Cursor_Input>>;
  where?: Maybe<Ticket_Priority_Bool_Exp>;
}


export interface Subscription_RootTicket_StateArgs {
  distinct_on?: Maybe<Array<Ticket_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_State_Order_By>>;
  where?: Maybe<Ticket_State_Bool_Exp>;
}


export interface Subscription_RootTicket_State_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_State_Order_By>>;
  where?: Maybe<Ticket_State_Bool_Exp>;
}


export interface Subscription_RootTicket_State_By_PkArgs {
  name: Scalars['String'];
}


export interface Subscription_RootTicket_State_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ticket_State_Stream_Cursor_Input>>;
  where?: Maybe<Ticket_State_Bool_Exp>;
}


export interface Subscription_RootTicket_TemplateArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootTicket_Template_Assignee_RefArgs {
  distinct_on?: Maybe<Array<Ticket_Template_Assignee_Ref_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Template_Assignee_Ref_Order_By>>;
  where?: Maybe<Ticket_Template_Assignee_Ref_Bool_Exp>;
}


export interface Subscription_RootTicket_Template_Assignee_Ref_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Template_Assignee_Ref_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Template_Assignee_Ref_Order_By>>;
  where?: Maybe<Ticket_Template_Assignee_Ref_Bool_Exp>;
}


export interface Subscription_RootTicket_Template_Assignee_Ref_By_PkArgs {
  name: Scalars['String'];
}


export interface Subscription_RootTicket_Template_Assignee_Ref_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ticket_Template_Assignee_Ref_Stream_Cursor_Input>>;
  where?: Maybe<Ticket_Template_Assignee_Ref_Bool_Exp>;
}


export interface Subscription_RootTicket_Template_Location_RefArgs {
  distinct_on?: Maybe<Array<Ticket_Template_Location_Ref_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Template_Location_Ref_Order_By>>;
  where?: Maybe<Ticket_Template_Location_Ref_Bool_Exp>;
}


export interface Subscription_RootTicket_Template_Location_Ref_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Template_Location_Ref_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Template_Location_Ref_Order_By>>;
  where?: Maybe<Ticket_Template_Location_Ref_Bool_Exp>;
}


export interface Subscription_RootTicket_Template_Location_Ref_By_PkArgs {
  name: Scalars['String'];
}


export interface Subscription_RootTicket_Template_Location_Ref_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ticket_Template_Location_Ref_Stream_Cursor_Input>>;
  where?: Maybe<Ticket_Template_Location_Ref_Bool_Exp>;
}


export interface Subscription_RootTicket_TemplatesArgs {
  distinct_on?: Maybe<Array<TicketTemplate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TicketTemplate_Order_By>>;
  where?: Maybe<TicketTemplate_Bool_Exp>;
}


export interface Subscription_RootTicket_Templates_AggregateArgs {
  distinct_on?: Maybe<Array<TicketTemplate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TicketTemplate_Order_By>>;
  where?: Maybe<TicketTemplate_Bool_Exp>;
}


export interface Subscription_RootTicketsArgs {
  distinct_on?: Maybe<Array<Ticket_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Order_By>>;
  where?: Maybe<Ticket_Bool_Exp>;
}


export interface Subscription_RootTickets_AggregateArgs {
  distinct_on?: Maybe<Array<Ticket_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ticket_Order_By>>;
  where?: Maybe<Ticket_Bool_Exp>;
}


export interface Subscription_RootTransactionsArgs {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
}


export interface Subscription_RootTransactions_AggregateArgs {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
}


export interface Subscription_RootTransactions_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootTransactions_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Transactions_Stream_Cursor_Input>>;
  where?: Maybe<Transactions_Bool_Exp>;
}


export interface Subscription_RootUserArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootUser_RolesArgs {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
}


export interface Subscription_RootUser_Roles_AggregateArgs {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
}


export interface Subscription_RootUser_Roles_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Roles_Stream_Cursor_Input>>;
  where?: Maybe<User_Roles_Bool_Exp>;
}


export interface Subscription_RootUsersArgs {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
}


export interface Subscription_RootUsers_AggregateArgs {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
}


export interface Subscription_RootVehicleArgs {
  business_case_number: Scalars['Int'];
  id: Scalars['uuid'];
  version: Scalars['Int'];
}


export interface Subscription_RootVehicle_BoniArgs {
  distinct_on?: Maybe<Array<Vehicle_Boni_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Boni_Order_By>>;
  where?: Maybe<Vehicle_Boni_Bool_Exp>;
}


export interface Subscription_RootVehicle_Boni_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Boni_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Boni_Order_By>>;
  where?: Maybe<Vehicle_Boni_Bool_Exp>;
}


export interface Subscription_RootVehicle_Boni_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootVehicle_Boni_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Boni_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Boni_Bool_Exp>;
}


export interface Subscription_RootVehicle_Import_TemplatesArgs {
  distinct_on?: Maybe<Array<Vehicle_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Import_Templates_Order_By>>;
  where?: Maybe<Vehicle_Import_Templates_Bool_Exp>;
}


export interface Subscription_RootVehicle_Import_Templates_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Import_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Import_Templates_Order_By>>;
  where?: Maybe<Vehicle_Import_Templates_Bool_Exp>;
}


export interface Subscription_RootVehicle_Import_Templates_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootVehicle_Import_Templates_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Import_Templates_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Import_Templates_Bool_Exp>;
}


export interface Subscription_RootVehicle_MetafieldsArgs {
  distinct_on?: Maybe<Array<Vehicle_Metafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Metafields_Order_By>>;
  where?: Maybe<Vehicle_Metafields_Bool_Exp>;
}


export interface Subscription_RootVehicle_Metafields_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Metafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Metafields_Order_By>>;
  where?: Maybe<Vehicle_Metafields_Bool_Exp>;
}


export interface Subscription_RootVehicle_Metafields_By_PkArgs {
  column_name: Scalars['String'];
}


export interface Subscription_RootVehicle_Metafields_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Metafields_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Metafields_Bool_Exp>;
}


export interface Subscription_RootVehicle_NoteArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootVehicle_NotesArgs {
  distinct_on?: Maybe<Array<VehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VehicleNote_Order_By>>;
  where?: Maybe<VehicleNote_Bool_Exp>;
}


export interface Subscription_RootVehicle_Notes_AggregateArgs {
  distinct_on?: Maybe<Array<VehicleNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VehicleNote_Order_By>>;
  where?: Maybe<VehicleNote_Bool_Exp>;
}


export interface Subscription_RootVehicle_Owner_InformationsArgs {
  distinct_on?: Maybe<Array<Vehicle_Owner_Informations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Owner_Informations_Order_By>>;
  where?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
}


export interface Subscription_RootVehicle_Owner_Informations_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Owner_Informations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Owner_Informations_Order_By>>;
  where?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
}


export interface Subscription_RootVehicle_Owner_Informations_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootVehicle_Owner_Informations_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Owner_Informations_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
}


export interface Subscription_RootVehiclesArgs {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
}


export interface Subscription_RootVehicles_AggregateArgs {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
}


export interface Subscription_RootWork_Order_StatusArgs {
  distinct_on?: Maybe<Array<Work_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Order_Status_Order_By>>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
}


export interface Subscription_RootWork_Order_Status_AggregateArgs {
  distinct_on?: Maybe<Array<Work_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Order_Status_Order_By>>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
}


export interface Subscription_RootWork_Order_Status_By_PkArgs {
  value: Scalars['String'];
}


export interface Subscription_RootWork_Order_Status_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Work_Order_Status_Stream_Cursor_Input>>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
}


export interface Subscription_RootWork_Order_Task_CategoryArgs {
  distinct_on?: Maybe<Array<Work_Order_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Order_Task_Category_Order_By>>;
  where?: Maybe<Work_Order_Task_Category_Bool_Exp>;
}


export interface Subscription_RootWork_Order_Task_Category_AggregateArgs {
  distinct_on?: Maybe<Array<Work_Order_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Order_Task_Category_Order_By>>;
  where?: Maybe<Work_Order_Task_Category_Bool_Exp>;
}


export interface Subscription_RootWork_Order_Task_Category_By_PkArgs {
  task_category: Task_Category_Enum;
  work_order_id: Scalars['uuid'];
}


export interface Subscription_RootWork_Order_Task_Category_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Work_Order_Task_Category_Stream_Cursor_Input>>;
  where?: Maybe<Work_Order_Task_Category_Bool_Exp>;
}


export interface Subscription_RootWork_OrdersArgs {
  distinct_on?: Maybe<Array<Work_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Orders_Order_By>>;
  where?: Maybe<Work_Orders_Bool_Exp>;
}


export interface Subscription_RootWork_Orders_AggregateArgs {
  distinct_on?: Maybe<Array<Work_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Orders_Order_By>>;
  where?: Maybe<Work_Orders_Bool_Exp>;
}


export interface Subscription_RootWork_Orders_By_PkArgs {
  id: Scalars['uuid'];
}


export interface Subscription_RootWork_Orders_StreamArgs {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Work_Orders_Stream_Cursor_Input>>;
  where?: Maybe<Work_Orders_Bool_Exp>;
}

/** columns and relationships of "sync_errors" */
export interface Sync_Errors {
  __typename?: 'sync_errors';
  business_case_number?: Maybe<Scalars['Int']>;
  cause?: Maybe<Scalars['String']>;
  first_occured: Scalars['timestamptz'];
  id: Scalars['uuid'];
  latest_ocurrence: Scalars['timestamptz'];
  message: Scalars['String'];
  prevailing: Scalars['Boolean'];
  resolved?: Maybe<Scalars['timestamptz']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "sync_errors" */
export interface Sync_Errors_Aggregate {
  __typename?: 'sync_errors_aggregate';
  aggregate?: Maybe<Sync_Errors_Aggregate_Fields>;
  nodes: Array<Sync_Errors>;
}

export interface Sync_Errors_Aggregate_Bool_Exp {
  bool_and?: Maybe<Sync_Errors_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Sync_Errors_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Sync_Errors_Aggregate_Bool_Exp_Count>;
}

export interface Sync_Errors_Aggregate_Bool_Exp_Bool_And {
  arguments: Sync_Errors_Select_Column_Sync_Errors_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Sync_Errors_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Sync_Errors_Aggregate_Bool_Exp_Bool_Or {
  arguments: Sync_Errors_Select_Column_Sync_Errors_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Sync_Errors_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Sync_Errors_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Sync_Errors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Sync_Errors_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "sync_errors" */
export interface Sync_Errors_Aggregate_Fields {
  __typename?: 'sync_errors_aggregate_fields';
  avg?: Maybe<Sync_Errors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sync_Errors_Max_Fields>;
  min?: Maybe<Sync_Errors_Min_Fields>;
  stddev?: Maybe<Sync_Errors_Stddev_Fields>;
  stddev_pop?: Maybe<Sync_Errors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sync_Errors_Stddev_Samp_Fields>;
  sum?: Maybe<Sync_Errors_Sum_Fields>;
  var_pop?: Maybe<Sync_Errors_Var_Pop_Fields>;
  var_samp?: Maybe<Sync_Errors_Var_Samp_Fields>;
  variance?: Maybe<Sync_Errors_Variance_Fields>;
}


/** aggregate fields of "sync_errors" */
export interface Sync_Errors_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Sync_Errors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "sync_errors" */
export interface Sync_Errors_Aggregate_Order_By {
  avg?: Maybe<Sync_Errors_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sync_Errors_Max_Order_By>;
  min?: Maybe<Sync_Errors_Min_Order_By>;
  stddev?: Maybe<Sync_Errors_Stddev_Order_By>;
  stddev_pop?: Maybe<Sync_Errors_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sync_Errors_Stddev_Samp_Order_By>;
  sum?: Maybe<Sync_Errors_Sum_Order_By>;
  var_pop?: Maybe<Sync_Errors_Var_Pop_Order_By>;
  var_samp?: Maybe<Sync_Errors_Var_Samp_Order_By>;
  variance?: Maybe<Sync_Errors_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "sync_errors" */
export interface Sync_Errors_Arr_Rel_Insert_Input {
  data: Array<Sync_Errors_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sync_Errors_On_Conflict>;
}

/** aggregate avg on columns */
export interface Sync_Errors_Avg_Fields {
  __typename?: 'sync_errors_avg_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "sync_errors" */
export interface Sync_Errors_Avg_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "sync_errors". All fields are combined with a logical 'AND'. */
export interface Sync_Errors_Bool_Exp {
  _and?: Maybe<Array<Sync_Errors_Bool_Exp>>;
  _not?: Maybe<Sync_Errors_Bool_Exp>;
  _or?: Maybe<Array<Sync_Errors_Bool_Exp>>;
  business_case_number?: Maybe<Int_Comparison_Exp>;
  cause?: Maybe<String_Comparison_Exp>;
  first_occured?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  latest_ocurrence?: Maybe<Timestamptz_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  prevailing?: Maybe<Boolean_Comparison_Exp>;
  resolved?: Maybe<Timestamptz_Comparison_Exp>;
  transaction_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_version?: Maybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "sync_errors" */
export enum Sync_Errors_Constraint {
  /** unique or primary key constraint on columns "id" */
  SyncErrorsPkey = 'sync_errors_pkey'
}

/** input type for incrementing numeric columns in table "sync_errors" */
export interface Sync_Errors_Inc_Input {
  business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "sync_errors" */
export interface Sync_Errors_Insert_Input {
  business_case_number?: Maybe<Scalars['Int']>;
  cause?: Maybe<Scalars['String']>;
  first_occured?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  latest_ocurrence?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  prevailing?: Maybe<Scalars['Boolean']>;
  resolved?: Maybe<Scalars['timestamptz']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface Sync_Errors_Max_Fields {
  __typename?: 'sync_errors_max_fields';
  business_case_number?: Maybe<Scalars['Int']>;
  cause?: Maybe<Scalars['String']>;
  first_occured?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  latest_ocurrence?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['timestamptz']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "sync_errors" */
export interface Sync_Errors_Max_Order_By {
  business_case_number?: Maybe<Order_By>;
  cause?: Maybe<Order_By>;
  first_occured?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_ocurrence?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  resolved?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Sync_Errors_Min_Fields {
  __typename?: 'sync_errors_min_fields';
  business_case_number?: Maybe<Scalars['Int']>;
  cause?: Maybe<Scalars['String']>;
  first_occured?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  latest_ocurrence?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['timestamptz']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "sync_errors" */
export interface Sync_Errors_Min_Order_By {
  business_case_number?: Maybe<Order_By>;
  cause?: Maybe<Order_By>;
  first_occured?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_ocurrence?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  resolved?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** response of any mutation on the table "sync_errors" */
export interface Sync_Errors_Mutation_Response {
  __typename?: 'sync_errors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sync_Errors>;
}

/** on_conflict condition type for table "sync_errors" */
export interface Sync_Errors_On_Conflict {
  constraint: Sync_Errors_Constraint;
  update_columns: Array<Sync_Errors_Update_Column>;
  where?: Maybe<Sync_Errors_Bool_Exp>;
}

/** Ordering options when selecting data from "sync_errors". */
export interface Sync_Errors_Order_By {
  business_case_number?: Maybe<Order_By>;
  cause?: Maybe<Order_By>;
  first_occured?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_ocurrence?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  prevailing?: Maybe<Order_By>;
  resolved?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** primary key columns input for table: sync_errors */
export interface Sync_Errors_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "sync_errors" */
export enum Sync_Errors_Select_Column {
  /** column name */
  BusinessCaseNumber = 'business_case_number',
  /** column name */
  Cause = 'cause',
  /** column name */
  FirstOccured = 'first_occured',
  /** column name */
  Id = 'id',
  /** column name */
  LatestOcurrence = 'latest_ocurrence',
  /** column name */
  Message = 'message',
  /** column name */
  Prevailing = 'prevailing',
  /** column name */
  Resolved = 'resolved',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

/** select "sync_errors_aggregate_bool_exp_bool_and_arguments_columns" columns of table "sync_errors" */
export enum Sync_Errors_Select_Column_Sync_Errors_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Prevailing = 'prevailing'
}

/** select "sync_errors_aggregate_bool_exp_bool_or_arguments_columns" columns of table "sync_errors" */
export enum Sync_Errors_Select_Column_Sync_Errors_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Prevailing = 'prevailing'
}

/** input type for updating data in table "sync_errors" */
export interface Sync_Errors_Set_Input {
  business_case_number?: Maybe<Scalars['Int']>;
  cause?: Maybe<Scalars['String']>;
  first_occured?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  latest_ocurrence?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  prevailing?: Maybe<Scalars['Boolean']>;
  resolved?: Maybe<Scalars['timestamptz']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface Sync_Errors_Stddev_Fields {
  __typename?: 'sync_errors_stddev_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "sync_errors" */
export interface Sync_Errors_Stddev_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Sync_Errors_Stddev_Pop_Fields {
  __typename?: 'sync_errors_stddev_pop_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "sync_errors" */
export interface Sync_Errors_Stddev_Pop_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Sync_Errors_Stddev_Samp_Fields {
  __typename?: 'sync_errors_stddev_samp_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "sync_errors" */
export interface Sync_Errors_Stddev_Samp_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Streaming cursor of the table "sync_errors" */
export interface Sync_Errors_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Sync_Errors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Sync_Errors_Stream_Cursor_Value_Input {
  business_case_number?: Maybe<Scalars['Int']>;
  cause?: Maybe<Scalars['String']>;
  first_occured?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  latest_ocurrence?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  prevailing?: Maybe<Scalars['Boolean']>;
  resolved?: Maybe<Scalars['timestamptz']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface Sync_Errors_Sum_Fields {
  __typename?: 'sync_errors_sum_fields';
  business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "sync_errors" */
export interface Sync_Errors_Sum_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** update columns of table "sync_errors" */
export enum Sync_Errors_Update_Column {
  /** column name */
  BusinessCaseNumber = 'business_case_number',
  /** column name */
  Cause = 'cause',
  /** column name */
  FirstOccured = 'first_occured',
  /** column name */
  Id = 'id',
  /** column name */
  LatestOcurrence = 'latest_ocurrence',
  /** column name */
  Message = 'message',
  /** column name */
  Prevailing = 'prevailing',
  /** column name */
  Resolved = 'resolved',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

export interface Sync_Errors_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Sync_Errors_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Sync_Errors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sync_Errors_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Sync_Errors_Var_Pop_Fields {
  __typename?: 'sync_errors_var_pop_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "sync_errors" */
export interface Sync_Errors_Var_Pop_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Sync_Errors_Var_Samp_Fields {
  __typename?: 'sync_errors_var_samp_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "sync_errors" */
export interface Sync_Errors_Var_Samp_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Sync_Errors_Variance_Fields {
  __typename?: 'sync_errors_variance_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "sync_errors" */
export interface Sync_Errors_Variance_Order_By {
  business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** columns and relationships of "task_category" */
export interface Task_Category {
  __typename?: 'task_category';
  comment: Scalars['String'];
  value: Scalars['String'];
}

/** aggregated selection of "task_category" */
export interface Task_Category_Aggregate {
  __typename?: 'task_category_aggregate';
  aggregate?: Maybe<Task_Category_Aggregate_Fields>;
  nodes: Array<Task_Category>;
}

/** aggregate fields of "task_category" */
export interface Task_Category_Aggregate_Fields {
  __typename?: 'task_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Category_Max_Fields>;
  min?: Maybe<Task_Category_Min_Fields>;
}


/** aggregate fields of "task_category" */
export interface Task_Category_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Task_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "task_category". All fields are combined with a logical 'AND'. */
export interface Task_Category_Bool_Exp {
  _and?: Maybe<Array<Task_Category_Bool_Exp>>;
  _not?: Maybe<Task_Category_Bool_Exp>;
  _or?: Maybe<Array<Task_Category_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "task_category" */
export enum Task_Category_Constraint {
  /** unique or primary key constraint on columns "value" */
  TaskCategoryPkey = 'task_category_pkey'
}

export enum Task_Category_Enum {
  InspectionSticker = 'INSPECTION_STICKER',
  Service = 'SERVICE'
}

/** Boolean expression to compare columns of type "task_category_enum". All fields are combined with logical 'AND'. */
export interface Task_Category_Enum_Comparison_Exp {
  _eq?: Maybe<Task_Category_Enum>;
  _in?: Maybe<Array<Task_Category_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Task_Category_Enum>;
  _nin?: Maybe<Array<Task_Category_Enum>>;
}

/** input type for inserting data into table "task_category" */
export interface Task_Category_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Task_Category_Max_Fields {
  __typename?: 'task_category_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Task_Category_Min_Fields {
  __typename?: 'task_category_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "task_category" */
export interface Task_Category_Mutation_Response {
  __typename?: 'task_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Category>;
}

/** on_conflict condition type for table "task_category" */
export interface Task_Category_On_Conflict {
  constraint: Task_Category_Constraint;
  update_columns: Array<Task_Category_Update_Column>;
  where?: Maybe<Task_Category_Bool_Exp>;
}

/** Ordering options when selecting data from "task_category". */
export interface Task_Category_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: task_category */
export interface Task_Category_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "task_category" */
export enum Task_Category_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "task_category" */
export interface Task_Category_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "task_category" */
export interface Task_Category_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Task_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Task_Category_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "task_category" */
export enum Task_Category_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Task_Category_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Task_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Category_Bool_Exp;
}

/** columns and relationships of "task_type_task_category" */
export interface Task_Type_Task_Category {
  __typename?: 'task_type_task_category';
  task_category_value: Task_Category_Enum;
  /** An object relationship */
  task_type: TaskType;
  task_type_code: Scalars['String'];
}

/** aggregated selection of "task_type_task_category" */
export interface Task_Type_Task_Category_Aggregate {
  __typename?: 'task_type_task_category_aggregate';
  aggregate?: Maybe<Task_Type_Task_Category_Aggregate_Fields>;
  nodes: Array<Task_Type_Task_Category>;
}

export interface Task_Type_Task_Category_Aggregate_Bool_Exp {
  count?: Maybe<Task_Type_Task_Category_Aggregate_Bool_Exp_Count>;
}

export interface Task_Type_Task_Category_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Task_Type_Task_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Task_Type_Task_Category_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "task_type_task_category" */
export interface Task_Type_Task_Category_Aggregate_Fields {
  __typename?: 'task_type_task_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Type_Task_Category_Max_Fields>;
  min?: Maybe<Task_Type_Task_Category_Min_Fields>;
}


/** aggregate fields of "task_type_task_category" */
export interface Task_Type_Task_Category_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Task_Type_Task_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "task_type_task_category" */
export interface Task_Type_Task_Category_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Task_Type_Task_Category_Max_Order_By>;
  min?: Maybe<Task_Type_Task_Category_Min_Order_By>;
}

/** input type for inserting array relation for remote table "task_type_task_category" */
export interface Task_Type_Task_Category_Arr_Rel_Insert_Input {
  data: Array<Task_Type_Task_Category_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Task_Type_Task_Category_On_Conflict>;
}

/** Boolean expression to filter rows from the table "task_type_task_category". All fields are combined with a logical 'AND'. */
export interface Task_Type_Task_Category_Bool_Exp {
  _and?: Maybe<Array<Task_Type_Task_Category_Bool_Exp>>;
  _not?: Maybe<Task_Type_Task_Category_Bool_Exp>;
  _or?: Maybe<Array<Task_Type_Task_Category_Bool_Exp>>;
  task_category_value?: Maybe<Task_Category_Enum_Comparison_Exp>;
  task_type?: Maybe<TaskType_Bool_Exp>;
  task_type_code?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "task_type_task_category" */
export enum Task_Type_Task_Category_Constraint {
  /** unique or primary key constraint on columns "task_category_value", "task_type_code" */
  TaskTypeTaskCategoryPkey = 'task_type_task_category_pkey'
}

/** input type for inserting data into table "task_type_task_category" */
export interface Task_Type_Task_Category_Insert_Input {
  task_category_value?: Maybe<Task_Category_Enum>;
  task_type?: Maybe<TaskType_Obj_Rel_Insert_Input>;
  task_type_code?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Task_Type_Task_Category_Max_Fields {
  __typename?: 'task_type_task_category_max_fields';
  task_type_code?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "task_type_task_category" */
export interface Task_Type_Task_Category_Max_Order_By {
  task_type_code?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Task_Type_Task_Category_Min_Fields {
  __typename?: 'task_type_task_category_min_fields';
  task_type_code?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "task_type_task_category" */
export interface Task_Type_Task_Category_Min_Order_By {
  task_type_code?: Maybe<Order_By>;
}

/** response of any mutation on the table "task_type_task_category" */
export interface Task_Type_Task_Category_Mutation_Response {
  __typename?: 'task_type_task_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Type_Task_Category>;
}

/** on_conflict condition type for table "task_type_task_category" */
export interface Task_Type_Task_Category_On_Conflict {
  constraint: Task_Type_Task_Category_Constraint;
  update_columns: Array<Task_Type_Task_Category_Update_Column>;
  where?: Maybe<Task_Type_Task_Category_Bool_Exp>;
}

/** Ordering options when selecting data from "task_type_task_category". */
export interface Task_Type_Task_Category_Order_By {
  task_category_value?: Maybe<Order_By>;
  task_type?: Maybe<TaskType_Order_By>;
  task_type_code?: Maybe<Order_By>;
}

/** primary key columns input for table: task_type_task_category */
export interface Task_Type_Task_Category_Pk_Columns_Input {
  task_category_value: Task_Category_Enum;
  task_type_code: Scalars['String'];
}

/** select columns of table "task_type_task_category" */
export enum Task_Type_Task_Category_Select_Column {
  /** column name */
  TaskCategoryValue = 'task_category_value',
  /** column name */
  TaskTypeCode = 'task_type_code'
}

/** input type for updating data in table "task_type_task_category" */
export interface Task_Type_Task_Category_Set_Input {
  task_category_value?: Maybe<Task_Category_Enum>;
  task_type_code?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "task_type_task_category" */
export interface Task_Type_Task_Category_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Task_Type_Task_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Task_Type_Task_Category_Stream_Cursor_Value_Input {
  task_category_value?: Maybe<Task_Category_Enum>;
  task_type_code?: Maybe<Scalars['String']>;
}

/** update columns of table "task_type_task_category" */
export enum Task_Type_Task_Category_Update_Column {
  /** column name */
  TaskCategoryValue = 'task_category_value',
  /** column name */
  TaskTypeCode = 'task_type_code'
}

export interface Task_Type_Task_Category_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Task_Type_Task_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Type_Task_Category_Bool_Exp;
}

/** columns and relationships of "ticket_family_notifications" */
export interface Ticket_Family_Notifications {
  __typename?: 'ticket_family_notifications';
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  status: Notification_Status_Enum;
  ticket_id: Scalars['uuid'];
  title: Scalars['String'];
  type: Notification_Type_Enum;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
}

/** aggregated selection of "ticket_family_notifications" */
export interface Ticket_Family_Notifications_Aggregate {
  __typename?: 'ticket_family_notifications_aggregate';
  aggregate?: Maybe<Ticket_Family_Notifications_Aggregate_Fields>;
  nodes: Array<Ticket_Family_Notifications>;
}

/** aggregate fields of "ticket_family_notifications" */
export interface Ticket_Family_Notifications_Aggregate_Fields {
  __typename?: 'ticket_family_notifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ticket_Family_Notifications_Max_Fields>;
  min?: Maybe<Ticket_Family_Notifications_Min_Fields>;
}


/** aggregate fields of "ticket_family_notifications" */
export interface Ticket_Family_Notifications_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Ticket_Family_Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "ticket_family_notifications". All fields are combined with a logical 'AND'. */
export interface Ticket_Family_Notifications_Bool_Exp {
  _and?: Maybe<Array<Ticket_Family_Notifications_Bool_Exp>>;
  _not?: Maybe<Ticket_Family_Notifications_Bool_Exp>;
  _or?: Maybe<Array<Ticket_Family_Notifications_Bool_Exp>>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<Notification_Status_Enum_Comparison_Exp>;
  ticket_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Notification_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "ticket_family_notifications" */
export enum Ticket_Family_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  TicketFamilyNotificationsPkey = 'ticket_family_notifications_pkey'
}

/** input type for inserting data into table "ticket_family_notifications" */
export interface Ticket_Family_Notifications_Insert_Input {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Notification_Status_Enum>;
  ticket_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Ticket_Family_Notifications_Max_Fields {
  __typename?: 'ticket_family_notifications_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface Ticket_Family_Notifications_Min_Fields {
  __typename?: 'ticket_family_notifications_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "ticket_family_notifications" */
export interface Ticket_Family_Notifications_Mutation_Response {
  __typename?: 'ticket_family_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ticket_Family_Notifications>;
}

/** on_conflict condition type for table "ticket_family_notifications" */
export interface Ticket_Family_Notifications_On_Conflict {
  constraint: Ticket_Family_Notifications_Constraint;
  update_columns: Array<Ticket_Family_Notifications_Update_Column>;
  where?: Maybe<Ticket_Family_Notifications_Bool_Exp>;
}

/** Ordering options when selecting data from "ticket_family_notifications". */
export interface Ticket_Family_Notifications_Order_By {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  ticket_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** primary key columns input for table: ticket_family_notifications */
export interface Ticket_Family_Notifications_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "ticket_family_notifications" */
export enum Ticket_Family_Notifications_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "ticket_family_notifications" */
export interface Ticket_Family_Notifications_Set_Input {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Notification_Status_Enum>;
  ticket_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "ticket_family_notifications" */
export interface Ticket_Family_Notifications_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Ticket_Family_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Ticket_Family_Notifications_Stream_Cursor_Value_Input {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Notification_Status_Enum>;
  ticket_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "ticket_family_notifications" */
export enum Ticket_Family_Notifications_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export interface Ticket_Family_Notifications_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ticket_Family_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ticket_Family_Notifications_Bool_Exp;
}

/** columns and relationships of "ticket_priority" */
export interface Ticket_Priority {
  __typename?: 'ticket_priority';
  name: Scalars['String'];
}

/** aggregated selection of "ticket_priority" */
export interface Ticket_Priority_Aggregate {
  __typename?: 'ticket_priority_aggregate';
  aggregate?: Maybe<Ticket_Priority_Aggregate_Fields>;
  nodes: Array<Ticket_Priority>;
}

/** aggregate fields of "ticket_priority" */
export interface Ticket_Priority_Aggregate_Fields {
  __typename?: 'ticket_priority_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ticket_Priority_Max_Fields>;
  min?: Maybe<Ticket_Priority_Min_Fields>;
}


/** aggregate fields of "ticket_priority" */
export interface Ticket_Priority_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Ticket_Priority_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "ticket_priority". All fields are combined with a logical 'AND'. */
export interface Ticket_Priority_Bool_Exp {
  _and?: Maybe<Array<Ticket_Priority_Bool_Exp>>;
  _not?: Maybe<Ticket_Priority_Bool_Exp>;
  _or?: Maybe<Array<Ticket_Priority_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "ticket_priority" */
export enum Ticket_Priority_Constraint {
  /** unique or primary key constraint on columns "name" */
  TicketPriorityPkey = 'ticket_priority_pkey'
}

export enum Ticket_Priority_Enum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Urgent = 'URGENT'
}

/** Boolean expression to compare columns of type "ticket_priority_enum". All fields are combined with logical 'AND'. */
export interface Ticket_Priority_Enum_Comparison_Exp {
  _eq?: Maybe<Ticket_Priority_Enum>;
  _in?: Maybe<Array<Ticket_Priority_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Ticket_Priority_Enum>;
  _nin?: Maybe<Array<Ticket_Priority_Enum>>;
}

/** input type for inserting data into table "ticket_priority" */
export interface Ticket_Priority_Insert_Input {
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Ticket_Priority_Max_Fields {
  __typename?: 'ticket_priority_max_fields';
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Ticket_Priority_Min_Fields {
  __typename?: 'ticket_priority_min_fields';
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "ticket_priority" */
export interface Ticket_Priority_Mutation_Response {
  __typename?: 'ticket_priority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ticket_Priority>;
}

/** on_conflict condition type for table "ticket_priority" */
export interface Ticket_Priority_On_Conflict {
  constraint: Ticket_Priority_Constraint;
  update_columns: Array<Ticket_Priority_Update_Column>;
  where?: Maybe<Ticket_Priority_Bool_Exp>;
}

/** Ordering options when selecting data from "ticket_priority". */
export interface Ticket_Priority_Order_By {
  name?: Maybe<Order_By>;
}

/** primary key columns input for table: ticket_priority */
export interface Ticket_Priority_Pk_Columns_Input {
  name: Scalars['String'];
}

/** select columns of table "ticket_priority" */
export enum Ticket_Priority_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ticket_priority" */
export interface Ticket_Priority_Set_Input {
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "ticket_priority" */
export interface Ticket_Priority_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Ticket_Priority_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Ticket_Priority_Stream_Cursor_Value_Input {
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "ticket_priority" */
export enum Ticket_Priority_Update_Column {
  /** column name */
  Name = 'name'
}

export interface Ticket_Priority_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ticket_Priority_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ticket_Priority_Bool_Exp;
}

/** columns and relationships of "ticket_state" */
export interface Ticket_State {
  __typename?: 'ticket_state';
  name: Scalars['String'];
}

/** aggregated selection of "ticket_state" */
export interface Ticket_State_Aggregate {
  __typename?: 'ticket_state_aggregate';
  aggregate?: Maybe<Ticket_State_Aggregate_Fields>;
  nodes: Array<Ticket_State>;
}

/** aggregate fields of "ticket_state" */
export interface Ticket_State_Aggregate_Fields {
  __typename?: 'ticket_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ticket_State_Max_Fields>;
  min?: Maybe<Ticket_State_Min_Fields>;
}


/** aggregate fields of "ticket_state" */
export interface Ticket_State_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Ticket_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "ticket_state". All fields are combined with a logical 'AND'. */
export interface Ticket_State_Bool_Exp {
  _and?: Maybe<Array<Ticket_State_Bool_Exp>>;
  _not?: Maybe<Ticket_State_Bool_Exp>;
  _or?: Maybe<Array<Ticket_State_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "ticket_state" */
export enum Ticket_State_Constraint {
  /** unique or primary key constraint on columns "name" */
  TicketStatePkey = 'ticket_state_pkey'
}

export enum Ticket_State_Enum {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Todo = 'TODO'
}

/** Boolean expression to compare columns of type "ticket_state_enum". All fields are combined with logical 'AND'. */
export interface Ticket_State_Enum_Comparison_Exp {
  _eq?: Maybe<Ticket_State_Enum>;
  _in?: Maybe<Array<Ticket_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Ticket_State_Enum>;
  _nin?: Maybe<Array<Ticket_State_Enum>>;
}

/** input type for inserting data into table "ticket_state" */
export interface Ticket_State_Insert_Input {
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Ticket_State_Max_Fields {
  __typename?: 'ticket_state_max_fields';
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Ticket_State_Min_Fields {
  __typename?: 'ticket_state_min_fields';
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "ticket_state" */
export interface Ticket_State_Mutation_Response {
  __typename?: 'ticket_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ticket_State>;
}

/** on_conflict condition type for table "ticket_state" */
export interface Ticket_State_On_Conflict {
  constraint: Ticket_State_Constraint;
  update_columns: Array<Ticket_State_Update_Column>;
  where?: Maybe<Ticket_State_Bool_Exp>;
}

/** Ordering options when selecting data from "ticket_state". */
export interface Ticket_State_Order_By {
  name?: Maybe<Order_By>;
}

/** primary key columns input for table: ticket_state */
export interface Ticket_State_Pk_Columns_Input {
  name: Scalars['String'];
}

/** select columns of table "ticket_state" */
export enum Ticket_State_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ticket_state" */
export interface Ticket_State_Set_Input {
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "ticket_state" */
export interface Ticket_State_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Ticket_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Ticket_State_Stream_Cursor_Value_Input {
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "ticket_state" */
export enum Ticket_State_Update_Column {
  /** column name */
  Name = 'name'
}

export interface Ticket_State_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ticket_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ticket_State_Bool_Exp;
}

/** columns and relationships of "ticket_template_assignee_ref" */
export interface Ticket_Template_Assignee_Ref {
  __typename?: 'ticket_template_assignee_ref';
  code_reference_field?: Maybe<Scalars['String']>;
  name: Scalars['String'];
}

/** aggregated selection of "ticket_template_assignee_ref" */
export interface Ticket_Template_Assignee_Ref_Aggregate {
  __typename?: 'ticket_template_assignee_ref_aggregate';
  aggregate?: Maybe<Ticket_Template_Assignee_Ref_Aggregate_Fields>;
  nodes: Array<Ticket_Template_Assignee_Ref>;
}

/** aggregate fields of "ticket_template_assignee_ref" */
export interface Ticket_Template_Assignee_Ref_Aggregate_Fields {
  __typename?: 'ticket_template_assignee_ref_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ticket_Template_Assignee_Ref_Max_Fields>;
  min?: Maybe<Ticket_Template_Assignee_Ref_Min_Fields>;
}


/** aggregate fields of "ticket_template_assignee_ref" */
export interface Ticket_Template_Assignee_Ref_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Ticket_Template_Assignee_Ref_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "ticket_template_assignee_ref". All fields are combined with a logical 'AND'. */
export interface Ticket_Template_Assignee_Ref_Bool_Exp {
  _and?: Maybe<Array<Ticket_Template_Assignee_Ref_Bool_Exp>>;
  _not?: Maybe<Ticket_Template_Assignee_Ref_Bool_Exp>;
  _or?: Maybe<Array<Ticket_Template_Assignee_Ref_Bool_Exp>>;
  code_reference_field?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "ticket_template_assignee_ref" */
export enum Ticket_Template_Assignee_Ref_Constraint {
  /** unique or primary key constraint on columns "name" */
  TicketTemplateAssigneeRefPkey = 'ticket_template_assignee_ref_pkey'
}

export enum Ticket_Template_Assignee_Ref_Enum {
  /** last_updated_by */
  VehicleCauser = 'VEHICLE_CAUSER',
  /** reserved_by */
  VehicleReservedBy = 'VEHICLE_RESERVED_BY',
  /** sold_by */
  VehicleSoldBy = 'VEHICLE_SOLD_BY'
}

/** Boolean expression to compare columns of type "ticket_template_assignee_ref_enum". All fields are combined with logical 'AND'. */
export interface Ticket_Template_Assignee_Ref_Enum_Comparison_Exp {
  _eq?: Maybe<Ticket_Template_Assignee_Ref_Enum>;
  _in?: Maybe<Array<Ticket_Template_Assignee_Ref_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Ticket_Template_Assignee_Ref_Enum>;
  _nin?: Maybe<Array<Ticket_Template_Assignee_Ref_Enum>>;
}

/** input type for inserting data into table "ticket_template_assignee_ref" */
export interface Ticket_Template_Assignee_Ref_Insert_Input {
  code_reference_field?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Ticket_Template_Assignee_Ref_Max_Fields {
  __typename?: 'ticket_template_assignee_ref_max_fields';
  code_reference_field?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Ticket_Template_Assignee_Ref_Min_Fields {
  __typename?: 'ticket_template_assignee_ref_min_fields';
  code_reference_field?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "ticket_template_assignee_ref" */
export interface Ticket_Template_Assignee_Ref_Mutation_Response {
  __typename?: 'ticket_template_assignee_ref_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ticket_Template_Assignee_Ref>;
}

/** on_conflict condition type for table "ticket_template_assignee_ref" */
export interface Ticket_Template_Assignee_Ref_On_Conflict {
  constraint: Ticket_Template_Assignee_Ref_Constraint;
  update_columns: Array<Ticket_Template_Assignee_Ref_Update_Column>;
  where?: Maybe<Ticket_Template_Assignee_Ref_Bool_Exp>;
}

/** Ordering options when selecting data from "ticket_template_assignee_ref". */
export interface Ticket_Template_Assignee_Ref_Order_By {
  code_reference_field?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** primary key columns input for table: ticket_template_assignee_ref */
export interface Ticket_Template_Assignee_Ref_Pk_Columns_Input {
  name: Scalars['String'];
}

/** select columns of table "ticket_template_assignee_ref" */
export enum Ticket_Template_Assignee_Ref_Select_Column {
  /** column name */
  CodeReferenceField = 'code_reference_field',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ticket_template_assignee_ref" */
export interface Ticket_Template_Assignee_Ref_Set_Input {
  code_reference_field?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "ticket_template_assignee_ref" */
export interface Ticket_Template_Assignee_Ref_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Ticket_Template_Assignee_Ref_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Ticket_Template_Assignee_Ref_Stream_Cursor_Value_Input {
  code_reference_field?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "ticket_template_assignee_ref" */
export enum Ticket_Template_Assignee_Ref_Update_Column {
  /** column name */
  CodeReferenceField = 'code_reference_field',
  /** column name */
  Name = 'name'
}

export interface Ticket_Template_Assignee_Ref_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ticket_Template_Assignee_Ref_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ticket_Template_Assignee_Ref_Bool_Exp;
}

/** columns and relationships of "ticket_template_location_ref" */
export interface Ticket_Template_Location_Ref {
  __typename?: 'ticket_template_location_ref';
  code_reference_field?: Maybe<Scalars['String']>;
  name: Scalars['String'];
}

/** aggregated selection of "ticket_template_location_ref" */
export interface Ticket_Template_Location_Ref_Aggregate {
  __typename?: 'ticket_template_location_ref_aggregate';
  aggregate?: Maybe<Ticket_Template_Location_Ref_Aggregate_Fields>;
  nodes: Array<Ticket_Template_Location_Ref>;
}

/** aggregate fields of "ticket_template_location_ref" */
export interface Ticket_Template_Location_Ref_Aggregate_Fields {
  __typename?: 'ticket_template_location_ref_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ticket_Template_Location_Ref_Max_Fields>;
  min?: Maybe<Ticket_Template_Location_Ref_Min_Fields>;
}


/** aggregate fields of "ticket_template_location_ref" */
export interface Ticket_Template_Location_Ref_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Ticket_Template_Location_Ref_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "ticket_template_location_ref". All fields are combined with a logical 'AND'. */
export interface Ticket_Template_Location_Ref_Bool_Exp {
  _and?: Maybe<Array<Ticket_Template_Location_Ref_Bool_Exp>>;
  _not?: Maybe<Ticket_Template_Location_Ref_Bool_Exp>;
  _or?: Maybe<Array<Ticket_Template_Location_Ref_Bool_Exp>>;
  code_reference_field?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "ticket_template_location_ref" */
export enum Ticket_Template_Location_Ref_Constraint {
  /** unique or primary key constraint on columns "name" */
  TicketTemplateLocationRefPkey = 'ticket_template_location_ref_pkey'
}

export enum Ticket_Template_Location_Ref_Enum {
  /** dms_assigned_location */
  VehicleDmsAssignedLocation = 'VEHICLE_DMS_ASSIGNED_LOCATION',
  /** holder_location */
  VehicleHolderLocation = 'VEHICLE_HOLDER_LOCATION',
  /** owner_location */
  VehicleOwnerLocation = 'VEHICLE_OWNER_LOCATION',
  /** seller_location */
  VehicleSellerLocation = 'VEHICLE_SELLER_LOCATION'
}

/** Boolean expression to compare columns of type "ticket_template_location_ref_enum". All fields are combined with logical 'AND'. */
export interface Ticket_Template_Location_Ref_Enum_Comparison_Exp {
  _eq?: Maybe<Ticket_Template_Location_Ref_Enum>;
  _in?: Maybe<Array<Ticket_Template_Location_Ref_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Ticket_Template_Location_Ref_Enum>;
  _nin?: Maybe<Array<Ticket_Template_Location_Ref_Enum>>;
}

/** input type for inserting data into table "ticket_template_location_ref" */
export interface Ticket_Template_Location_Ref_Insert_Input {
  code_reference_field?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Ticket_Template_Location_Ref_Max_Fields {
  __typename?: 'ticket_template_location_ref_max_fields';
  code_reference_field?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Ticket_Template_Location_Ref_Min_Fields {
  __typename?: 'ticket_template_location_ref_min_fields';
  code_reference_field?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "ticket_template_location_ref" */
export interface Ticket_Template_Location_Ref_Mutation_Response {
  __typename?: 'ticket_template_location_ref_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ticket_Template_Location_Ref>;
}

/** on_conflict condition type for table "ticket_template_location_ref" */
export interface Ticket_Template_Location_Ref_On_Conflict {
  constraint: Ticket_Template_Location_Ref_Constraint;
  update_columns: Array<Ticket_Template_Location_Ref_Update_Column>;
  where?: Maybe<Ticket_Template_Location_Ref_Bool_Exp>;
}

/** Ordering options when selecting data from "ticket_template_location_ref". */
export interface Ticket_Template_Location_Ref_Order_By {
  code_reference_field?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** primary key columns input for table: ticket_template_location_ref */
export interface Ticket_Template_Location_Ref_Pk_Columns_Input {
  name: Scalars['String'];
}

/** select columns of table "ticket_template_location_ref" */
export enum Ticket_Template_Location_Ref_Select_Column {
  /** column name */
  CodeReferenceField = 'code_reference_field',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ticket_template_location_ref" */
export interface Ticket_Template_Location_Ref_Set_Input {
  code_reference_field?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "ticket_template_location_ref" */
export interface Ticket_Template_Location_Ref_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Ticket_Template_Location_Ref_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Ticket_Template_Location_Ref_Stream_Cursor_Value_Input {
  code_reference_field?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "ticket_template_location_ref" */
export enum Ticket_Template_Location_Ref_Update_Column {
  /** column name */
  CodeReferenceField = 'code_reference_field',
  /** column name */
  Name = 'name'
}

export interface Ticket_Template_Location_Ref_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ticket_Template_Location_Ref_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ticket_Template_Location_Ref_Bool_Exp;
}


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface Timestamptz_Comparison_Exp {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
}

/** columns and relationships of "transactions" */
export interface Transactions {
  __typename?: 'transactions';
  business_case_number: Scalars['Int'];
  buyer_commision_number?: Maybe<Scalars['String']>;
  buyer_customer_id?: Maybe<Scalars['uuid']>;
  buyer_location_id?: Maybe<Scalars['uuid']>;
  buyer_supplier_id?: Maybe<Scalars['uuid']>;
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  cancelled_transaction_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  generated_vehicle_version: Scalars['Int'];
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  mileage: Scalars['Int'];
  mileage_date?: Maybe<Scalars['date']>;
  seller_commision_number?: Maybe<Scalars['String']>;
  seller_customer_id?: Maybe<Scalars['uuid']>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  seller_supplier_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  sync_errors: Array<Sync_Errors>;
  /** An aggregate relationship */
  sync_errors_aggregate: Sync_Errors_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  valid_transaction?: Maybe<Transactions>;
  vehicle_id: Scalars['uuid'];
}


/** columns and relationships of "transactions" */
export interface TransactionsSync_ErrorsArgs {
  distinct_on?: Maybe<Array<Sync_Errors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Errors_Order_By>>;
  where?: Maybe<Sync_Errors_Bool_Exp>;
}


/** columns and relationships of "transactions" */
export interface TransactionsSync_Errors_AggregateArgs {
  distinct_on?: Maybe<Array<Sync_Errors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Errors_Order_By>>;
  where?: Maybe<Sync_Errors_Bool_Exp>;
}

/** aggregated selection of "transactions" */
export interface Transactions_Aggregate {
  __typename?: 'transactions_aggregate';
  aggregate?: Maybe<Transactions_Aggregate_Fields>;
  nodes: Array<Transactions>;
}

export interface Transactions_Aggregate_Bool_Exp {
  count?: Maybe<Transactions_Aggregate_Bool_Exp_Count>;
}

export interface Transactions_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Transactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "transactions" */
export interface Transactions_Aggregate_Fields {
  __typename?: 'transactions_aggregate_fields';
  avg?: Maybe<Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transactions_Max_Fields>;
  min?: Maybe<Transactions_Min_Fields>;
  stddev?: Maybe<Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Transactions_Sum_Fields>;
  var_pop?: Maybe<Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Transactions_Var_Samp_Fields>;
  variance?: Maybe<Transactions_Variance_Fields>;
}


/** aggregate fields of "transactions" */
export interface Transactions_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Transactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "transactions" */
export interface Transactions_Aggregate_Order_By {
  avg?: Maybe<Transactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Transactions_Max_Order_By>;
  min?: Maybe<Transactions_Min_Order_By>;
  stddev?: Maybe<Transactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Transactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Transactions_Sum_Order_By>;
  var_pop?: Maybe<Transactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Transactions_Var_Samp_Order_By>;
  variance?: Maybe<Transactions_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "transactions" */
export interface Transactions_Arr_Rel_Insert_Input {
  data: Array<Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Transactions_On_Conflict>;
}

/** aggregate avg on columns */
export interface Transactions_Avg_Fields {
  __typename?: 'transactions_avg_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  generated_vehicle_version?: Maybe<Scalars['Float']>;
  mileage?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "transactions" */
export interface Transactions_Avg_Order_By {
  business_case_number?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export interface Transactions_Bool_Exp {
  _and?: Maybe<Array<Transactions_Bool_Exp>>;
  _not?: Maybe<Transactions_Bool_Exp>;
  _or?: Maybe<Array<Transactions_Bool_Exp>>;
  business_case_number?: Maybe<Int_Comparison_Exp>;
  buyer_commision_number?: Maybe<String_Comparison_Exp>;
  buyer_customer_id?: Maybe<Uuid_Comparison_Exp>;
  buyer_location_id?: Maybe<Uuid_Comparison_Exp>;
  buyer_supplier_id?: Maybe<Uuid_Comparison_Exp>;
  cancelled_date?: Maybe<Timestamptz_Comparison_Exp>;
  cancelled_transaction_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  generated_vehicle_version?: Maybe<Int_Comparison_Exp>;
  holder_customer_id?: Maybe<Uuid_Comparison_Exp>;
  holder_location_id?: Maybe<Uuid_Comparison_Exp>;
  holder_supplier_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invoice_date?: Maybe<Date_Comparison_Exp>;
  invoice_number?: Maybe<String_Comparison_Exp>;
  job_number?: Maybe<String_Comparison_Exp>;
  mileage?: Maybe<Int_Comparison_Exp>;
  mileage_date?: Maybe<Date_Comparison_Exp>;
  seller_commision_number?: Maybe<String_Comparison_Exp>;
  seller_customer_id?: Maybe<Uuid_Comparison_Exp>;
  seller_location_id?: Maybe<Uuid_Comparison_Exp>;
  seller_supplier_id?: Maybe<Uuid_Comparison_Exp>;
  sync_errors?: Maybe<Sync_Errors_Bool_Exp>;
  sync_errors_aggregate?: Maybe<Sync_Errors_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  valid_transaction?: Maybe<Transactions_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "transactions" */
export enum Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionsPkey = 'transactions_pkey'
}

/** input type for incrementing numeric columns in table "transactions" */
export interface Transactions_Inc_Input {
  business_case_number?: Maybe<Scalars['Int']>;
  generated_vehicle_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "transactions" */
export interface Transactions_Insert_Input {
  business_case_number?: Maybe<Scalars['Int']>;
  buyer_commision_number?: Maybe<Scalars['String']>;
  buyer_customer_id?: Maybe<Scalars['uuid']>;
  buyer_location_id?: Maybe<Scalars['uuid']>;
  buyer_supplier_id?: Maybe<Scalars['uuid']>;
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  cancelled_transaction_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  generated_vehicle_version?: Maybe<Scalars['Int']>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  seller_commision_number?: Maybe<Scalars['String']>;
  seller_customer_id?: Maybe<Scalars['uuid']>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  seller_supplier_id?: Maybe<Scalars['uuid']>;
  sync_errors?: Maybe<Sync_Errors_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_transaction?: Maybe<Transactions_Obj_Rel_Insert_Input>;
  vehicle_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Transactions_Max_Fields {
  __typename?: 'transactions_max_fields';
  business_case_number?: Maybe<Scalars['Int']>;
  buyer_commision_number?: Maybe<Scalars['String']>;
  buyer_customer_id?: Maybe<Scalars['uuid']>;
  buyer_location_id?: Maybe<Scalars['uuid']>;
  buyer_supplier_id?: Maybe<Scalars['uuid']>;
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  cancelled_transaction_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  generated_vehicle_version?: Maybe<Scalars['Int']>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  seller_commision_number?: Maybe<Scalars['String']>;
  seller_customer_id?: Maybe<Scalars['uuid']>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  seller_supplier_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "transactions" */
export interface Transactions_Max_Order_By {
  business_case_number?: Maybe<Order_By>;
  buyer_commision_number?: Maybe<Order_By>;
  buyer_customer_id?: Maybe<Order_By>;
  buyer_location_id?: Maybe<Order_By>;
  buyer_supplier_id?: Maybe<Order_By>;
  cancelled_date?: Maybe<Order_By>;
  cancelled_transaction_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  holder_customer_id?: Maybe<Order_By>;
  holder_location_id?: Maybe<Order_By>;
  holder_supplier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  seller_commision_number?: Maybe<Order_By>;
  seller_customer_id?: Maybe<Order_By>;
  seller_location_id?: Maybe<Order_By>;
  seller_supplier_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Transactions_Min_Fields {
  __typename?: 'transactions_min_fields';
  business_case_number?: Maybe<Scalars['Int']>;
  buyer_commision_number?: Maybe<Scalars['String']>;
  buyer_customer_id?: Maybe<Scalars['uuid']>;
  buyer_location_id?: Maybe<Scalars['uuid']>;
  buyer_supplier_id?: Maybe<Scalars['uuid']>;
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  cancelled_transaction_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  generated_vehicle_version?: Maybe<Scalars['Int']>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  seller_commision_number?: Maybe<Scalars['String']>;
  seller_customer_id?: Maybe<Scalars['uuid']>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  seller_supplier_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "transactions" */
export interface Transactions_Min_Order_By {
  business_case_number?: Maybe<Order_By>;
  buyer_commision_number?: Maybe<Order_By>;
  buyer_customer_id?: Maybe<Order_By>;
  buyer_location_id?: Maybe<Order_By>;
  buyer_supplier_id?: Maybe<Order_By>;
  cancelled_date?: Maybe<Order_By>;
  cancelled_transaction_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  holder_customer_id?: Maybe<Order_By>;
  holder_location_id?: Maybe<Order_By>;
  holder_supplier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  seller_commision_number?: Maybe<Order_By>;
  seller_customer_id?: Maybe<Order_By>;
  seller_location_id?: Maybe<Order_By>;
  seller_supplier_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "transactions" */
export interface Transactions_Mutation_Response {
  __typename?: 'transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transactions>;
}

/** input type for inserting object relation for remote table "transactions" */
export interface Transactions_Obj_Rel_Insert_Input {
  data: Transactions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Transactions_On_Conflict>;
}

/** on_conflict condition type for table "transactions" */
export interface Transactions_On_Conflict {
  constraint: Transactions_Constraint;
  update_columns: Array<Transactions_Update_Column>;
  where?: Maybe<Transactions_Bool_Exp>;
}

/** Ordering options when selecting data from "transactions". */
export interface Transactions_Order_By {
  business_case_number?: Maybe<Order_By>;
  buyer_commision_number?: Maybe<Order_By>;
  buyer_customer_id?: Maybe<Order_By>;
  buyer_location_id?: Maybe<Order_By>;
  buyer_supplier_id?: Maybe<Order_By>;
  cancelled_date?: Maybe<Order_By>;
  cancelled_transaction_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  holder_customer_id?: Maybe<Order_By>;
  holder_location_id?: Maybe<Order_By>;
  holder_supplier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_date?: Maybe<Order_By>;
  invoice_number?: Maybe<Order_By>;
  job_number?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
  mileage_date?: Maybe<Order_By>;
  seller_commision_number?: Maybe<Order_By>;
  seller_customer_id?: Maybe<Order_By>;
  seller_location_id?: Maybe<Order_By>;
  seller_supplier_id?: Maybe<Order_By>;
  sync_errors_aggregate?: Maybe<Sync_Errors_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  valid_transaction?: Maybe<Transactions_Order_By>;
  vehicle_id?: Maybe<Order_By>;
}

/** primary key columns input for table: transactions */
export interface Transactions_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "transactions" */
export enum Transactions_Select_Column {
  /** column name */
  BusinessCaseNumber = 'business_case_number',
  /** column name */
  BuyerCommisionNumber = 'buyer_commision_number',
  /** column name */
  BuyerCustomerId = 'buyer_customer_id',
  /** column name */
  BuyerLocationId = 'buyer_location_id',
  /** column name */
  BuyerSupplierId = 'buyer_supplier_id',
  /** column name */
  CancelledDate = 'cancelled_date',
  /** column name */
  CancelledTransactionId = 'cancelled_transaction_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GeneratedVehicleVersion = 'generated_vehicle_version',
  /** column name */
  HolderCustomerId = 'holder_customer_id',
  /** column name */
  HolderLocationId = 'holder_location_id',
  /** column name */
  HolderSupplierId = 'holder_supplier_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  SellerCommisionNumber = 'seller_commision_number',
  /** column name */
  SellerCustomerId = 'seller_customer_id',
  /** column name */
  SellerLocationId = 'seller_location_id',
  /** column name */
  SellerSupplierId = 'seller_supplier_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** input type for updating data in table "transactions" */
export interface Transactions_Set_Input {
  business_case_number?: Maybe<Scalars['Int']>;
  buyer_commision_number?: Maybe<Scalars['String']>;
  buyer_customer_id?: Maybe<Scalars['uuid']>;
  buyer_location_id?: Maybe<Scalars['uuid']>;
  buyer_supplier_id?: Maybe<Scalars['uuid']>;
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  cancelled_transaction_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  generated_vehicle_version?: Maybe<Scalars['Int']>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  seller_commision_number?: Maybe<Scalars['String']>;
  seller_customer_id?: Maybe<Scalars['uuid']>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  seller_supplier_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
}

/** aggregate stddev on columns */
export interface Transactions_Stddev_Fields {
  __typename?: 'transactions_stddev_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  generated_vehicle_version?: Maybe<Scalars['Float']>;
  mileage?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "transactions" */
export interface Transactions_Stddev_Order_By {
  business_case_number?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Transactions_Stddev_Pop_Fields {
  __typename?: 'transactions_stddev_pop_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  generated_vehicle_version?: Maybe<Scalars['Float']>;
  mileage?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "transactions" */
export interface Transactions_Stddev_Pop_Order_By {
  business_case_number?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Transactions_Stddev_Samp_Fields {
  __typename?: 'transactions_stddev_samp_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  generated_vehicle_version?: Maybe<Scalars['Float']>;
  mileage?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "transactions" */
export interface Transactions_Stddev_Samp_Order_By {
  business_case_number?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
}

/** Streaming cursor of the table "transactions" */
export interface Transactions_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Transactions_Stream_Cursor_Value_Input {
  business_case_number?: Maybe<Scalars['Int']>;
  buyer_commision_number?: Maybe<Scalars['String']>;
  buyer_customer_id?: Maybe<Scalars['uuid']>;
  buyer_location_id?: Maybe<Scalars['uuid']>;
  buyer_supplier_id?: Maybe<Scalars['uuid']>;
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  cancelled_transaction_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  generated_vehicle_version?: Maybe<Scalars['Int']>;
  holder_customer_id?: Maybe<Scalars['uuid']>;
  holder_location_id?: Maybe<Scalars['uuid']>;
  holder_supplier_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_number?: Maybe<Scalars['String']>;
  job_number?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  mileage_date?: Maybe<Scalars['date']>;
  seller_commision_number?: Maybe<Scalars['String']>;
  seller_customer_id?: Maybe<Scalars['uuid']>;
  seller_location_id?: Maybe<Scalars['uuid']>;
  seller_supplier_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
}

/** aggregate sum on columns */
export interface Transactions_Sum_Fields {
  __typename?: 'transactions_sum_fields';
  business_case_number?: Maybe<Scalars['Int']>;
  generated_vehicle_version?: Maybe<Scalars['Int']>;
  mileage?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "transactions" */
export interface Transactions_Sum_Order_By {
  business_case_number?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
}

/** update columns of table "transactions" */
export enum Transactions_Update_Column {
  /** column name */
  BusinessCaseNumber = 'business_case_number',
  /** column name */
  BuyerCommisionNumber = 'buyer_commision_number',
  /** column name */
  BuyerCustomerId = 'buyer_customer_id',
  /** column name */
  BuyerLocationId = 'buyer_location_id',
  /** column name */
  BuyerSupplierId = 'buyer_supplier_id',
  /** column name */
  CancelledDate = 'cancelled_date',
  /** column name */
  CancelledTransactionId = 'cancelled_transaction_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GeneratedVehicleVersion = 'generated_vehicle_version',
  /** column name */
  HolderCustomerId = 'holder_customer_id',
  /** column name */
  HolderLocationId = 'holder_location_id',
  /** column name */
  HolderSupplierId = 'holder_supplier_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  Mileage = 'mileage',
  /** column name */
  MileageDate = 'mileage_date',
  /** column name */
  SellerCommisionNumber = 'seller_commision_number',
  /** column name */
  SellerCustomerId = 'seller_customer_id',
  /** column name */
  SellerLocationId = 'seller_location_id',
  /** column name */
  SellerSupplierId = 'seller_supplier_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id'
}

export interface Transactions_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transactions_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Transactions_Var_Pop_Fields {
  __typename?: 'transactions_var_pop_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  generated_vehicle_version?: Maybe<Scalars['Float']>;
  mileage?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "transactions" */
export interface Transactions_Var_Pop_Order_By {
  business_case_number?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Transactions_Var_Samp_Fields {
  __typename?: 'transactions_var_samp_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  generated_vehicle_version?: Maybe<Scalars['Float']>;
  mileage?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "transactions" */
export interface Transactions_Var_Samp_Order_By {
  business_case_number?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Transactions_Variance_Fields {
  __typename?: 'transactions_variance_fields';
  business_case_number?: Maybe<Scalars['Float']>;
  generated_vehicle_version?: Maybe<Scalars['Float']>;
  mileage?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "transactions" */
export interface Transactions_Variance_Order_By {
  business_case_number?: Maybe<Order_By>;
  generated_vehicle_version?: Maybe<Order_By>;
  mileage?: Maybe<Order_By>;
}

/** columns and relationships of "user_roles" */
export interface User_Roles {
  __typename?: 'user_roles';
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "user_roles" */
export interface User_Roles_Aggregate {
  __typename?: 'user_roles_aggregate';
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
}

/** aggregate fields of "user_roles" */
export interface User_Roles_Aggregate_Fields {
  __typename?: 'user_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
}


/** aggregate fields of "user_roles" */
export interface User_Roles_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<User_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export interface User_Roles_Bool_Exp {
  _and?: Maybe<Array<User_Roles_Bool_Exp>>;
  _not?: Maybe<User_Roles_Bool_Exp>;
  _or?: Maybe<Array<User_Roles_Bool_Exp>>;
  role?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
}

/** aggregate max on columns */
export interface User_Roles_Max_Fields {
  __typename?: 'user_roles_max_fields';
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface User_Roles_Min_Fields {
  __typename?: 'user_roles_min_fields';
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}

/** Ordering options when selecting data from "user_roles". */
export interface User_Roles_Order_By {
  role?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "user_roles" */
export interface User_Roles_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: User_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface User_Roles_Stream_Cursor_Value_Input {
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface Uuid_Comparison_Exp {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
}

/** columns and relationships of "vehicle_boni" */
export interface Vehicle_Boni {
  __typename?: 'vehicle_boni';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['numeric'];
  vehicle_business_case_number: Scalars['Int'];
  vehicle_id: Scalars['uuid'];
  vehicle_version: Scalars['Int'];
}

/** aggregated selection of "vehicle_boni" */
export interface Vehicle_Boni_Aggregate {
  __typename?: 'vehicle_boni_aggregate';
  aggregate?: Maybe<Vehicle_Boni_Aggregate_Fields>;
  nodes: Array<Vehicle_Boni>;
}

export interface Vehicle_Boni_Aggregate_Bool_Exp {
  count?: Maybe<Vehicle_Boni_Aggregate_Bool_Exp_Count>;
}

export interface Vehicle_Boni_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Vehicle_Boni_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicle_Boni_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "vehicle_boni" */
export interface Vehicle_Boni_Aggregate_Fields {
  __typename?: 'vehicle_boni_aggregate_fields';
  avg?: Maybe<Vehicle_Boni_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Boni_Max_Fields>;
  min?: Maybe<Vehicle_Boni_Min_Fields>;
  stddev?: Maybe<Vehicle_Boni_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Boni_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Boni_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Boni_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Boni_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Boni_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Boni_Variance_Fields>;
}


/** aggregate fields of "vehicle_boni" */
export interface Vehicle_Boni_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Vehicle_Boni_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "vehicle_boni" */
export interface Vehicle_Boni_Aggregate_Order_By {
  avg?: Maybe<Vehicle_Boni_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicle_Boni_Max_Order_By>;
  min?: Maybe<Vehicle_Boni_Min_Order_By>;
  stddev?: Maybe<Vehicle_Boni_Stddev_Order_By>;
  stddev_pop?: Maybe<Vehicle_Boni_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Vehicle_Boni_Stddev_Samp_Order_By>;
  sum?: Maybe<Vehicle_Boni_Sum_Order_By>;
  var_pop?: Maybe<Vehicle_Boni_Var_Pop_Order_By>;
  var_samp?: Maybe<Vehicle_Boni_Var_Samp_Order_By>;
  variance?: Maybe<Vehicle_Boni_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "vehicle_boni" */
export interface Vehicle_Boni_Arr_Rel_Insert_Input {
  data: Array<Vehicle_Boni_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Boni_On_Conflict>;
}

/** aggregate avg on columns */
export interface Vehicle_Boni_Avg_Fields {
  __typename?: 'vehicle_boni_avg_fields';
  value?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "vehicle_boni" */
export interface Vehicle_Boni_Avg_Order_By {
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "vehicle_boni". All fields are combined with a logical 'AND'. */
export interface Vehicle_Boni_Bool_Exp {
  _and?: Maybe<Array<Vehicle_Boni_Bool_Exp>>;
  _not?: Maybe<Vehicle_Boni_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Boni_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<Numeric_Comparison_Exp>;
  vehicle_business_case_number?: Maybe<Int_Comparison_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_version?: Maybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "vehicle_boni" */
export enum Vehicle_Boni_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehicleBoniPkey = 'vehicle_boni_pkey',
  /** unique or primary key constraint on columns "vehicle_version", "vehicle_business_case_number", "vehicle_id", "type" */
  VehicleBoniTypeVehicleIdVersionBcNumberKey = 'vehicle_boni_type_vehicle_id_version_bc_number_key'
}

/** input type for incrementing numeric columns in table "vehicle_boni" */
export interface Vehicle_Boni_Inc_Input {
  value?: Maybe<Scalars['numeric']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "vehicle_boni" */
export interface Vehicle_Boni_Insert_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface Vehicle_Boni_Max_Fields {
  __typename?: 'vehicle_boni_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "vehicle_boni" */
export interface Vehicle_Boni_Max_Order_By {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Vehicle_Boni_Min_Fields {
  __typename?: 'vehicle_boni_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "vehicle_boni" */
export interface Vehicle_Boni_Min_Order_By {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** response of any mutation on the table "vehicle_boni" */
export interface Vehicle_Boni_Mutation_Response {
  __typename?: 'vehicle_boni_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Boni>;
}

/** on_conflict condition type for table "vehicle_boni" */
export interface Vehicle_Boni_On_Conflict {
  constraint: Vehicle_Boni_Constraint;
  update_columns: Array<Vehicle_Boni_Update_Column>;
  where?: Maybe<Vehicle_Boni_Bool_Exp>;
}

/** Ordering options when selecting data from "vehicle_boni". */
export interface Vehicle_Boni_Order_By {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** primary key columns input for table: vehicle_boni */
export interface Vehicle_Boni_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "vehicle_boni" */
export enum Vehicle_Boni_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

/** input type for updating data in table "vehicle_boni" */
export interface Vehicle_Boni_Set_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface Vehicle_Boni_Stddev_Fields {
  __typename?: 'vehicle_boni_stddev_fields';
  value?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "vehicle_boni" */
export interface Vehicle_Boni_Stddev_Order_By {
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Vehicle_Boni_Stddev_Pop_Fields {
  __typename?: 'vehicle_boni_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "vehicle_boni" */
export interface Vehicle_Boni_Stddev_Pop_Order_By {
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Vehicle_Boni_Stddev_Samp_Fields {
  __typename?: 'vehicle_boni_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "vehicle_boni" */
export interface Vehicle_Boni_Stddev_Samp_Order_By {
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Streaming cursor of the table "vehicle_boni" */
export interface Vehicle_Boni_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vehicle_Boni_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vehicle_Boni_Stream_Cursor_Value_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface Vehicle_Boni_Sum_Fields {
  __typename?: 'vehicle_boni_sum_fields';
  value?: Maybe<Scalars['numeric']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "vehicle_boni" */
export interface Vehicle_Boni_Sum_Order_By {
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** update columns of table "vehicle_boni" */
export enum Vehicle_Boni_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

export interface Vehicle_Boni_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Vehicle_Boni_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Boni_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Boni_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Vehicle_Boni_Var_Pop_Fields {
  __typename?: 'vehicle_boni_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "vehicle_boni" */
export interface Vehicle_Boni_Var_Pop_Order_By {
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Vehicle_Boni_Var_Samp_Fields {
  __typename?: 'vehicle_boni_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "vehicle_boni" */
export interface Vehicle_Boni_Var_Samp_Order_By {
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Vehicle_Boni_Variance_Fields {
  __typename?: 'vehicle_boni_variance_fields';
  value?: Maybe<Scalars['Float']>;
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "vehicle_boni" */
export interface Vehicle_Boni_Variance_Order_By {
  value?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** columns and relationships of "vehicle_import_templates" */
export interface Vehicle_Import_Templates {
  __typename?: 'vehicle_import_templates';
  /** An array relationship */
  assignments: Array<Excel_Import_Assignments>;
  /** An aggregate relationship */
  assignments_aggregate: Excel_Import_Assignments_Aggregate;
  excel_definition?: Maybe<Array<Scalars['String']>>;
  id: Scalars['uuid'];
  inherited_from?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** An object relationship */
  parent_template?: Maybe<Vehicle_Import_Templates>;
  strict?: Maybe<Scalars['Boolean']>;
  tmp: Scalars['Boolean'];
}


/** columns and relationships of "vehicle_import_templates" */
export interface Vehicle_Import_TemplatesAssignmentsArgs {
  distinct_on?: Maybe<Array<Excel_Import_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Excel_Import_Assignments_Order_By>>;
  where?: Maybe<Excel_Import_Assignments_Bool_Exp>;
}


/** columns and relationships of "vehicle_import_templates" */
export interface Vehicle_Import_TemplatesAssignments_AggregateArgs {
  distinct_on?: Maybe<Array<Excel_Import_Assignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Excel_Import_Assignments_Order_By>>;
  where?: Maybe<Excel_Import_Assignments_Bool_Exp>;
}

/** aggregated selection of "vehicle_import_templates" */
export interface Vehicle_Import_Templates_Aggregate {
  __typename?: 'vehicle_import_templates_aggregate';
  aggregate?: Maybe<Vehicle_Import_Templates_Aggregate_Fields>;
  nodes: Array<Vehicle_Import_Templates>;
}

/** aggregate fields of "vehicle_import_templates" */
export interface Vehicle_Import_Templates_Aggregate_Fields {
  __typename?: 'vehicle_import_templates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Import_Templates_Max_Fields>;
  min?: Maybe<Vehicle_Import_Templates_Min_Fields>;
}


/** aggregate fields of "vehicle_import_templates" */
export interface Vehicle_Import_Templates_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Vehicle_Import_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "vehicle_import_templates". All fields are combined with a logical 'AND'. */
export interface Vehicle_Import_Templates_Bool_Exp {
  _and?: Maybe<Array<Vehicle_Import_Templates_Bool_Exp>>;
  _not?: Maybe<Vehicle_Import_Templates_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Import_Templates_Bool_Exp>>;
  assignments?: Maybe<Excel_Import_Assignments_Bool_Exp>;
  assignments_aggregate?: Maybe<Excel_Import_Assignments_Aggregate_Bool_Exp>;
  excel_definition?: Maybe<String_Array_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  inherited_from?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  parent_template?: Maybe<Vehicle_Import_Templates_Bool_Exp>;
  strict?: Maybe<Boolean_Comparison_Exp>;
  tmp?: Maybe<Boolean_Comparison_Exp>;
}

/** unique or primary key constraints on table "vehicle_import_templates" */
export enum Vehicle_Import_Templates_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehicleImportTemplatesPkey = 'vehicle_import_templates_pkey'
}

/** input type for inserting data into table "vehicle_import_templates" */
export interface Vehicle_Import_Templates_Insert_Input {
  assignments?: Maybe<Excel_Import_Assignments_Arr_Rel_Insert_Input>;
  excel_definition?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['uuid']>;
  inherited_from?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_template?: Maybe<Vehicle_Import_Templates_Obj_Rel_Insert_Input>;
  strict?: Maybe<Scalars['Boolean']>;
  tmp?: Maybe<Scalars['Boolean']>;
}

/** aggregate max on columns */
export interface Vehicle_Import_Templates_Max_Fields {
  __typename?: 'vehicle_import_templates_max_fields';
  excel_definition?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['uuid']>;
  inherited_from?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Vehicle_Import_Templates_Min_Fields {
  __typename?: 'vehicle_import_templates_min_fields';
  excel_definition?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['uuid']>;
  inherited_from?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "vehicle_import_templates" */
export interface Vehicle_Import_Templates_Mutation_Response {
  __typename?: 'vehicle_import_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Import_Templates>;
}

/** input type for inserting object relation for remote table "vehicle_import_templates" */
export interface Vehicle_Import_Templates_Obj_Rel_Insert_Input {
  data: Vehicle_Import_Templates_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Import_Templates_On_Conflict>;
}

/** on_conflict condition type for table "vehicle_import_templates" */
export interface Vehicle_Import_Templates_On_Conflict {
  constraint: Vehicle_Import_Templates_Constraint;
  update_columns: Array<Vehicle_Import_Templates_Update_Column>;
  where?: Maybe<Vehicle_Import_Templates_Bool_Exp>;
}

/** Ordering options when selecting data from "vehicle_import_templates". */
export interface Vehicle_Import_Templates_Order_By {
  assignments_aggregate?: Maybe<Excel_Import_Assignments_Aggregate_Order_By>;
  excel_definition?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inherited_from?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_template?: Maybe<Vehicle_Import_Templates_Order_By>;
  strict?: Maybe<Order_By>;
  tmp?: Maybe<Order_By>;
}

/** primary key columns input for table: vehicle_import_templates */
export interface Vehicle_Import_Templates_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "vehicle_import_templates" */
export enum Vehicle_Import_Templates_Select_Column {
  /** column name */
  ExcelDefinition = 'excel_definition',
  /** column name */
  Id = 'id',
  /** column name */
  InheritedFrom = 'inherited_from',
  /** column name */
  Name = 'name',
  /** column name */
  Strict = 'strict',
  /** column name */
  Tmp = 'tmp'
}

/** input type for updating data in table "vehicle_import_templates" */
export interface Vehicle_Import_Templates_Set_Input {
  excel_definition?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['uuid']>;
  inherited_from?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  strict?: Maybe<Scalars['Boolean']>;
  tmp?: Maybe<Scalars['Boolean']>;
}

/** Streaming cursor of the table "vehicle_import_templates" */
export interface Vehicle_Import_Templates_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vehicle_Import_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vehicle_Import_Templates_Stream_Cursor_Value_Input {
  excel_definition?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['uuid']>;
  inherited_from?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  strict?: Maybe<Scalars['Boolean']>;
  tmp?: Maybe<Scalars['Boolean']>;
}

/** update columns of table "vehicle_import_templates" */
export enum Vehicle_Import_Templates_Update_Column {
  /** column name */
  ExcelDefinition = 'excel_definition',
  /** column name */
  Id = 'id',
  /** column name */
  InheritedFrom = 'inherited_from',
  /** column name */
  Name = 'name',
  /** column name */
  Strict = 'strict',
  /** column name */
  Tmp = 'tmp'
}

export interface Vehicle_Import_Templates_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Import_Templates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Import_Templates_Bool_Exp;
}

/** columns and relationships of "vehicle_metafields" */
export interface Vehicle_Metafields {
  __typename?: 'vehicle_metafields';
  column_name: Scalars['String'];
  title: Scalars['String'];
}

/** aggregated selection of "vehicle_metafields" */
export interface Vehicle_Metafields_Aggregate {
  __typename?: 'vehicle_metafields_aggregate';
  aggregate?: Maybe<Vehicle_Metafields_Aggregate_Fields>;
  nodes: Array<Vehicle_Metafields>;
}

/** aggregate fields of "vehicle_metafields" */
export interface Vehicle_Metafields_Aggregate_Fields {
  __typename?: 'vehicle_metafields_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Metafields_Max_Fields>;
  min?: Maybe<Vehicle_Metafields_Min_Fields>;
}


/** aggregate fields of "vehicle_metafields" */
export interface Vehicle_Metafields_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Vehicle_Metafields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "vehicle_metafields". All fields are combined with a logical 'AND'. */
export interface Vehicle_Metafields_Bool_Exp {
  _and?: Maybe<Array<Vehicle_Metafields_Bool_Exp>>;
  _not?: Maybe<Vehicle_Metafields_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Metafields_Bool_Exp>>;
  column_name?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "vehicle_metafields" */
export enum Vehicle_Metafields_Constraint {
  /** unique or primary key constraint on columns "column_name" */
  VehicleMetafieldsPkey = 'vehicle_metafields_pkey'
}

export enum Vehicle_Metafields_Enum {
  /** Zubehör montiert */
  AccessoriesMounted = 'accessories_mounted',
  /** Kontrollnummer */
  ControlNumber = 'control_number',
  /** Bestellcode */
  OrderCode = 'order_code'
}

/** Boolean expression to compare columns of type "vehicle_metafields_enum". All fields are combined with logical 'AND'. */
export interface Vehicle_Metafields_Enum_Comparison_Exp {
  _eq?: Maybe<Vehicle_Metafields_Enum>;
  _in?: Maybe<Array<Vehicle_Metafields_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Vehicle_Metafields_Enum>;
  _nin?: Maybe<Array<Vehicle_Metafields_Enum>>;
}

/** input type for inserting data into table "vehicle_metafields" */
export interface Vehicle_Metafields_Insert_Input {
  column_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Vehicle_Metafields_Max_Fields {
  __typename?: 'vehicle_metafields_max_fields';
  column_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Vehicle_Metafields_Min_Fields {
  __typename?: 'vehicle_metafields_min_fields';
  column_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "vehicle_metafields" */
export interface Vehicle_Metafields_Mutation_Response {
  __typename?: 'vehicle_metafields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Metafields>;
}

/** input type for inserting object relation for remote table "vehicle_metafields" */
export interface Vehicle_Metafields_Obj_Rel_Insert_Input {
  data: Vehicle_Metafields_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Metafields_On_Conflict>;
}

/** on_conflict condition type for table "vehicle_metafields" */
export interface Vehicle_Metafields_On_Conflict {
  constraint: Vehicle_Metafields_Constraint;
  update_columns: Array<Vehicle_Metafields_Update_Column>;
  where?: Maybe<Vehicle_Metafields_Bool_Exp>;
}

/** Ordering options when selecting data from "vehicle_metafields". */
export interface Vehicle_Metafields_Order_By {
  column_name?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
}

/** primary key columns input for table: vehicle_metafields */
export interface Vehicle_Metafields_Pk_Columns_Input {
  column_name: Scalars['String'];
}

/** select columns of table "vehicle_metafields" */
export enum Vehicle_Metafields_Select_Column {
  /** column name */
  ColumnName = 'column_name',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "vehicle_metafields" */
export interface Vehicle_Metafields_Set_Input {
  column_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "vehicle_metafields" */
export interface Vehicle_Metafields_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vehicle_Metafields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vehicle_Metafields_Stream_Cursor_Value_Input {
  column_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

/** update columns of table "vehicle_metafields" */
export enum Vehicle_Metafields_Update_Column {
  /** column name */
  ColumnName = 'column_name',
  /** column name */
  Title = 'title'
}

export interface Vehicle_Metafields_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Metafields_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Metafields_Bool_Exp;
}

/** columns and relationships of "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations {
  __typename?: 'vehicle_owner_informations';
  created_at?: Maybe<Scalars['date']>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  supplier?: Maybe<Supplier>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** aggregated selection of "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Aggregate {
  __typename?: 'vehicle_owner_informations_aggregate';
  aggregate?: Maybe<Vehicle_Owner_Informations_Aggregate_Fields>;
  nodes: Array<Vehicle_Owner_Informations>;
}

export interface Vehicle_Owner_Informations_Aggregate_Bool_Exp {
  count?: Maybe<Vehicle_Owner_Informations_Aggregate_Bool_Exp_Count>;
}

export interface Vehicle_Owner_Informations_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Vehicle_Owner_Informations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Aggregate_Fields {
  __typename?: 'vehicle_owner_informations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Owner_Informations_Max_Fields>;
  min?: Maybe<Vehicle_Owner_Informations_Min_Fields>;
}


/** aggregate fields of "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Vehicle_Owner_Informations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicle_Owner_Informations_Max_Order_By>;
  min?: Maybe<Vehicle_Owner_Informations_Min_Order_By>;
}

/** input type for inserting array relation for remote table "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Arr_Rel_Insert_Input {
  data: Array<Vehicle_Owner_Informations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Owner_Informations_On_Conflict>;
}

/** Boolean expression to filter rows from the table "vehicle_owner_informations". All fields are combined with a logical 'AND'. */
export interface Vehicle_Owner_Informations_Bool_Exp {
  _and?: Maybe<Array<Vehicle_Owner_Informations_Bool_Exp>>;
  _not?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Owner_Informations_Bool_Exp>>;
  created_at?: Maybe<Date_Comparison_Exp>;
  dealer_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  dealer_vehicle_inbox_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  supplier?: Maybe<Supplier_Bool_Exp>;
  supplier_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "vehicle_owner_informations" */
export enum Vehicle_Owner_Informations_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehicleOwnerInformationsPkey = 'vehicle_owner_informations_pkey'
}

/** input type for inserting data into table "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Insert_Input {
  created_at?: Maybe<Scalars['date']>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  supplier?: Maybe<Supplier_Obj_Rel_Insert_Input>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Vehicle_Owner_Informations_Max_Fields {
  __typename?: 'vehicle_owner_informations_max_fields';
  created_at?: Maybe<Scalars['date']>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Max_Order_By {
  created_at?: Maybe<Order_By>;
  dealer_vehicle_id?: Maybe<Order_By>;
  dealer_vehicle_inbox_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Vehicle_Owner_Informations_Min_Fields {
  __typename?: 'vehicle_owner_informations_min_fields';
  created_at?: Maybe<Scalars['date']>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Min_Order_By {
  created_at?: Maybe<Order_By>;
  dealer_vehicle_id?: Maybe<Order_By>;
  dealer_vehicle_inbox_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Mutation_Response {
  __typename?: 'vehicle_owner_informations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Owner_Informations>;
}

/** on_conflict condition type for table "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_On_Conflict {
  constraint: Vehicle_Owner_Informations_Constraint;
  update_columns: Array<Vehicle_Owner_Informations_Update_Column>;
  where?: Maybe<Vehicle_Owner_Informations_Bool_Exp>;
}

/** Ordering options when selecting data from "vehicle_owner_informations". */
export interface Vehicle_Owner_Informations_Order_By {
  created_at?: Maybe<Order_By>;
  dealer_vehicle_id?: Maybe<Order_By>;
  dealer_vehicle_inbox_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_id?: Maybe<Order_By>;
  supplier?: Maybe<Supplier_Order_By>;
  supplier_id?: Maybe<Order_By>;
}

/** primary key columns input for table: vehicle_owner_informations */
export interface Vehicle_Owner_Informations_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "vehicle_owner_informations" */
export enum Vehicle_Owner_Informations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealerVehicleId = 'dealer_vehicle_id',
  /** column name */
  DealerVehicleInboxId = 'dealer_vehicle_inbox_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  SupplierId = 'supplier_id'
}

/** input type for updating data in table "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Set_Input {
  created_at?: Maybe<Scalars['date']>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "vehicle_owner_informations" */
export interface Vehicle_Owner_Informations_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vehicle_Owner_Informations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vehicle_Owner_Informations_Stream_Cursor_Value_Input {
  created_at?: Maybe<Scalars['date']>;
  dealer_vehicle_id?: Maybe<Scalars['uuid']>;
  dealer_vehicle_inbox_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "vehicle_owner_informations" */
export enum Vehicle_Owner_Informations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealerVehicleId = 'dealer_vehicle_id',
  /** column name */
  DealerVehicleInboxId = 'dealer_vehicle_inbox_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  SupplierId = 'supplier_id'
}

export interface Vehicle_Owner_Informations_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Owner_Informations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Owner_Informations_Bool_Exp;
}

/** columns and relationships of "work_order_status" */
export interface Work_Order_Status {
  __typename?: 'work_order_status';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
}

/** aggregated selection of "work_order_status" */
export interface Work_Order_Status_Aggregate {
  __typename?: 'work_order_status_aggregate';
  aggregate?: Maybe<Work_Order_Status_Aggregate_Fields>;
  nodes: Array<Work_Order_Status>;
}

/** aggregate fields of "work_order_status" */
export interface Work_Order_Status_Aggregate_Fields {
  __typename?: 'work_order_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Work_Order_Status_Max_Fields>;
  min?: Maybe<Work_Order_Status_Min_Fields>;
}


/** aggregate fields of "work_order_status" */
export interface Work_Order_Status_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Work_Order_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "work_order_status". All fields are combined with a logical 'AND'. */
export interface Work_Order_Status_Bool_Exp {
  _and?: Maybe<Array<Work_Order_Status_Bool_Exp>>;
  _not?: Maybe<Work_Order_Status_Bool_Exp>;
  _or?: Maybe<Array<Work_Order_Status_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "work_order_status" */
export enum Work_Order_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  WorkOrderStatusPkey = 'work_order_status_pkey'
}

export enum Work_Order_Status_Enum {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

/** Boolean expression to compare columns of type "work_order_status_enum". All fields are combined with logical 'AND'. */
export interface Work_Order_Status_Enum_Comparison_Exp {
  _eq?: Maybe<Work_Order_Status_Enum>;
  _in?: Maybe<Array<Work_Order_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Work_Order_Status_Enum>;
  _nin?: Maybe<Array<Work_Order_Status_Enum>>;
}

/** input type for inserting data into table "work_order_status" */
export interface Work_Order_Status_Insert_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Work_Order_Status_Max_Fields {
  __typename?: 'work_order_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Work_Order_Status_Min_Fields {
  __typename?: 'work_order_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "work_order_status" */
export interface Work_Order_Status_Mutation_Response {
  __typename?: 'work_order_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Order_Status>;
}

/** on_conflict condition type for table "work_order_status" */
export interface Work_Order_Status_On_Conflict {
  constraint: Work_Order_Status_Constraint;
  update_columns: Array<Work_Order_Status_Update_Column>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "work_order_status". */
export interface Work_Order_Status_Order_By {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: work_order_status */
export interface Work_Order_Status_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "work_order_status" */
export enum Work_Order_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "work_order_status" */
export interface Work_Order_Status_Set_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "work_order_status" */
export interface Work_Order_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Work_Order_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Work_Order_Status_Stream_Cursor_Value_Input {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "work_order_status" */
export enum Work_Order_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface Work_Order_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Work_Order_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Order_Status_Bool_Exp;
}

/** columns and relationships of "work_order_task_category" */
export interface Work_Order_Task_Category {
  __typename?: 'work_order_task_category';
  task_category: Task_Category_Enum;
  work_order_id: Scalars['uuid'];
}

/** aggregated selection of "work_order_task_category" */
export interface Work_Order_Task_Category_Aggregate {
  __typename?: 'work_order_task_category_aggregate';
  aggregate?: Maybe<Work_Order_Task_Category_Aggregate_Fields>;
  nodes: Array<Work_Order_Task_Category>;
}

export interface Work_Order_Task_Category_Aggregate_Bool_Exp {
  count?: Maybe<Work_Order_Task_Category_Aggregate_Bool_Exp_Count>;
}

export interface Work_Order_Task_Category_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Work_Order_Task_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Work_Order_Task_Category_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "work_order_task_category" */
export interface Work_Order_Task_Category_Aggregate_Fields {
  __typename?: 'work_order_task_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Work_Order_Task_Category_Max_Fields>;
  min?: Maybe<Work_Order_Task_Category_Min_Fields>;
}


/** aggregate fields of "work_order_task_category" */
export interface Work_Order_Task_Category_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Work_Order_Task_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "work_order_task_category" */
export interface Work_Order_Task_Category_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Work_Order_Task_Category_Max_Order_By>;
  min?: Maybe<Work_Order_Task_Category_Min_Order_By>;
}

/** input type for inserting array relation for remote table "work_order_task_category" */
export interface Work_Order_Task_Category_Arr_Rel_Insert_Input {
  data: Array<Work_Order_Task_Category_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Work_Order_Task_Category_On_Conflict>;
}

/** Boolean expression to filter rows from the table "work_order_task_category". All fields are combined with a logical 'AND'. */
export interface Work_Order_Task_Category_Bool_Exp {
  _and?: Maybe<Array<Work_Order_Task_Category_Bool_Exp>>;
  _not?: Maybe<Work_Order_Task_Category_Bool_Exp>;
  _or?: Maybe<Array<Work_Order_Task_Category_Bool_Exp>>;
  task_category?: Maybe<Task_Category_Enum_Comparison_Exp>;
  work_order_id?: Maybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "work_order_task_category" */
export enum Work_Order_Task_Category_Constraint {
  /** unique or primary key constraint on columns "work_order_id", "task_category" */
  WorkOrderTaskCategoryPkey = 'work_order_task_category_pkey'
}

/** input type for inserting data into table "work_order_task_category" */
export interface Work_Order_Task_Category_Insert_Input {
  task_category?: Maybe<Task_Category_Enum>;
  work_order_id?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface Work_Order_Task_Category_Max_Fields {
  __typename?: 'work_order_task_category_max_fields';
  work_order_id?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "work_order_task_category" */
export interface Work_Order_Task_Category_Max_Order_By {
  work_order_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Work_Order_Task_Category_Min_Fields {
  __typename?: 'work_order_task_category_min_fields';
  work_order_id?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "work_order_task_category" */
export interface Work_Order_Task_Category_Min_Order_By {
  work_order_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "work_order_task_category" */
export interface Work_Order_Task_Category_Mutation_Response {
  __typename?: 'work_order_task_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Order_Task_Category>;
}

/** on_conflict condition type for table "work_order_task_category" */
export interface Work_Order_Task_Category_On_Conflict {
  constraint: Work_Order_Task_Category_Constraint;
  update_columns: Array<Work_Order_Task_Category_Update_Column>;
  where?: Maybe<Work_Order_Task_Category_Bool_Exp>;
}

/** Ordering options when selecting data from "work_order_task_category". */
export interface Work_Order_Task_Category_Order_By {
  task_category?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
}

/** primary key columns input for table: work_order_task_category */
export interface Work_Order_Task_Category_Pk_Columns_Input {
  task_category: Task_Category_Enum;
  work_order_id: Scalars['uuid'];
}

/** select columns of table "work_order_task_category" */
export enum Work_Order_Task_Category_Select_Column {
  /** column name */
  TaskCategory = 'task_category',
  /** column name */
  WorkOrderId = 'work_order_id'
}

/** input type for updating data in table "work_order_task_category" */
export interface Work_Order_Task_Category_Set_Input {
  task_category?: Maybe<Task_Category_Enum>;
  work_order_id?: Maybe<Scalars['uuid']>;
}

/** Streaming cursor of the table "work_order_task_category" */
export interface Work_Order_Task_Category_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Work_Order_Task_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Work_Order_Task_Category_Stream_Cursor_Value_Input {
  task_category?: Maybe<Task_Category_Enum>;
  work_order_id?: Maybe<Scalars['uuid']>;
}

/** update columns of table "work_order_task_category" */
export enum Work_Order_Task_Category_Update_Column {
  /** column name */
  TaskCategory = 'task_category',
  /** column name */
  WorkOrderId = 'work_order_id'
}

export interface Work_Order_Task_Category_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Work_Order_Task_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Order_Task_Category_Bool_Exp;
}

/** columns and relationships of "work_orders" */
export interface Work_Orders {
  __typename?: 'work_orders';
  /** An object relationship */
  customer_vehicle?: Maybe<CustomerVehicle>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  date: Scalars['date'];
  id: Scalars['uuid'];
  order_no: Scalars['String'];
  status: Work_Order_Status_Enum;
  /** An array relationship */
  task_categories_edges: Array<Work_Order_Task_Category>;
  /** An aggregate relationship */
  task_categories_edges_aggregate: Work_Order_Task_Category_Aggregate;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}


/** columns and relationships of "work_orders" */
export interface Work_OrdersTask_Categories_EdgesArgs {
  distinct_on?: Maybe<Array<Work_Order_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Order_Task_Category_Order_By>>;
  where?: Maybe<Work_Order_Task_Category_Bool_Exp>;
}


/** columns and relationships of "work_orders" */
export interface Work_OrdersTask_Categories_Edges_AggregateArgs {
  distinct_on?: Maybe<Array<Work_Order_Task_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Work_Order_Task_Category_Order_By>>;
  where?: Maybe<Work_Order_Task_Category_Bool_Exp>;
}

/** aggregated selection of "work_orders" */
export interface Work_Orders_Aggregate {
  __typename?: 'work_orders_aggregate';
  aggregate?: Maybe<Work_Orders_Aggregate_Fields>;
  nodes: Array<Work_Orders>;
}

export interface Work_Orders_Aggregate_Bool_Exp {
  count?: Maybe<Work_Orders_Aggregate_Bool_Exp_Count>;
}

export interface Work_Orders_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Work_Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Work_Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "work_orders" */
export interface Work_Orders_Aggregate_Fields {
  __typename?: 'work_orders_aggregate_fields';
  avg?: Maybe<Work_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Work_Orders_Max_Fields>;
  min?: Maybe<Work_Orders_Min_Fields>;
  stddev?: Maybe<Work_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Orders_Sum_Fields>;
  var_pop?: Maybe<Work_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Orders_Var_Samp_Fields>;
  variance?: Maybe<Work_Orders_Variance_Fields>;
}


/** aggregate fields of "work_orders" */
export interface Work_Orders_Aggregate_FieldsCountArgs {
  columns?: Maybe<Array<Work_Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "work_orders" */
export interface Work_Orders_Aggregate_Order_By {
  avg?: Maybe<Work_Orders_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Work_Orders_Max_Order_By>;
  min?: Maybe<Work_Orders_Min_Order_By>;
  stddev?: Maybe<Work_Orders_Stddev_Order_By>;
  stddev_pop?: Maybe<Work_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Work_Orders_Stddev_Samp_Order_By>;
  sum?: Maybe<Work_Orders_Sum_Order_By>;
  var_pop?: Maybe<Work_Orders_Var_Pop_Order_By>;
  var_samp?: Maybe<Work_Orders_Var_Samp_Order_By>;
  variance?: Maybe<Work_Orders_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "work_orders" */
export interface Work_Orders_Arr_Rel_Insert_Input {
  data: Array<Work_Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Work_Orders_On_Conflict>;
}

/** aggregate avg on columns */
export interface Work_Orders_Avg_Fields {
  __typename?: 'work_orders_avg_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "work_orders" */
export interface Work_Orders_Avg_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "work_orders". All fields are combined with a logical 'AND'. */
export interface Work_Orders_Bool_Exp {
  _and?: Maybe<Array<Work_Orders_Bool_Exp>>;
  _not?: Maybe<Work_Orders_Bool_Exp>;
  _or?: Maybe<Array<Work_Orders_Bool_Exp>>;
  customer_vehicle?: Maybe<CustomerVehicle_Bool_Exp>;
  customer_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order_no?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Work_Order_Status_Enum_Comparison_Exp>;
  task_categories_edges?: Maybe<Work_Order_Task_Category_Bool_Exp>;
  task_categories_edges_aggregate?: Maybe<Work_Order_Task_Category_Aggregate_Bool_Exp>;
  vehicle_business_case_number?: Maybe<Int_Comparison_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_version?: Maybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "work_orders" */
export enum Work_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkOrderPkey = 'work_order_pkey'
}

/** input type for incrementing numeric columns in table "work_orders" */
export interface Work_Orders_Inc_Input {
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "work_orders" */
export interface Work_Orders_Insert_Input {
  customer_vehicle?: Maybe<CustomerVehicle_Obj_Rel_Insert_Input>;
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  order_no?: Maybe<Scalars['String']>;
  status?: Maybe<Work_Order_Status_Enum>;
  task_categories_edges?: Maybe<Work_Order_Task_Category_Arr_Rel_Insert_Input>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface Work_Orders_Max_Fields {
  __typename?: 'work_orders_max_fields';
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  order_no?: Maybe<Scalars['String']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "work_orders" */
export interface Work_Orders_Max_Order_By {
  customer_vehicle_id?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_no?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Work_Orders_Min_Fields {
  __typename?: 'work_orders_min_fields';
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  order_no?: Maybe<Scalars['String']>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "work_orders" */
export interface Work_Orders_Min_Order_By {
  customer_vehicle_id?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_no?: Maybe<Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** response of any mutation on the table "work_orders" */
export interface Work_Orders_Mutation_Response {
  __typename?: 'work_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Orders>;
}

/** on_conflict condition type for table "work_orders" */
export interface Work_Orders_On_Conflict {
  constraint: Work_Orders_Constraint;
  update_columns: Array<Work_Orders_Update_Column>;
  where?: Maybe<Work_Orders_Bool_Exp>;
}

/** Ordering options when selecting data from "work_orders". */
export interface Work_Orders_Order_By {
  customer_vehicle?: Maybe<CustomerVehicle_Order_By>;
  customer_vehicle_id?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_no?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  task_categories_edges_aggregate?: Maybe<Work_Order_Task_Category_Aggregate_Order_By>;
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** primary key columns input for table: work_orders */
export interface Work_Orders_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "work_orders" */
export enum Work_Orders_Select_Column {
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  OrderNo = 'order_no',
  /** column name */
  Status = 'status',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

/** input type for updating data in table "work_orders" */
export interface Work_Orders_Set_Input {
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  order_no?: Maybe<Scalars['String']>;
  status?: Maybe<Work_Order_Status_Enum>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface Work_Orders_Stddev_Fields {
  __typename?: 'work_orders_stddev_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "work_orders" */
export interface Work_Orders_Stddev_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Work_Orders_Stddev_Pop_Fields {
  __typename?: 'work_orders_stddev_pop_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "work_orders" */
export interface Work_Orders_Stddev_Pop_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Work_Orders_Stddev_Samp_Fields {
  __typename?: 'work_orders_stddev_samp_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "work_orders" */
export interface Work_Orders_Stddev_Samp_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** Streaming cursor of the table "work_orders" */
export interface Work_Orders_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Work_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Work_Orders_Stream_Cursor_Value_Input {
  customer_vehicle_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  order_no?: Maybe<Scalars['String']>;
  status?: Maybe<Work_Order_Status_Enum>;
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface Work_Orders_Sum_Fields {
  __typename?: 'work_orders_sum_fields';
  vehicle_business_case_number?: Maybe<Scalars['Int']>;
  vehicle_version?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "work_orders" */
export interface Work_Orders_Sum_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** update columns of table "work_orders" */
export enum Work_Orders_Update_Column {
  /** column name */
  CustomerVehicleId = 'customer_vehicle_id',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  OrderNo = 'order_no',
  /** column name */
  Status = 'status',
  /** column name */
  VehicleBusinessCaseNumber = 'vehicle_business_case_number',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleVersion = 'vehicle_version'
}

export interface Work_Orders_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Work_Orders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Work_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Orders_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Work_Orders_Var_Pop_Fields {
  __typename?: 'work_orders_var_pop_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "work_orders" */
export interface Work_Orders_Var_Pop_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Work_Orders_Var_Samp_Fields {
  __typename?: 'work_orders_var_samp_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "work_orders" */
export interface Work_Orders_Var_Samp_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Work_Orders_Variance_Fields {
  __typename?: 'work_orders_variance_fields';
  vehicle_business_case_number?: Maybe<Scalars['Float']>;
  vehicle_version?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "work_orders" */
export interface Work_Orders_Variance_Order_By {
  vehicle_business_case_number?: Maybe<Order_By>;
  vehicle_version?: Maybe<Order_By>;
}

export type AdminAssetLinkDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminAssetLinkDetailQuery = (
  { __typename?: 'query_root' }
  & { asset_watchdog_links_by_pk?: Maybe<(
    { __typename?: 'asset_watchdog_links' }
    & AssetLinkDetailFieldsFragment
  )> }
);

export type CreateAssetLinkMutationVariables = Exact<{
  object: Asset_Watchdog_Links_Insert_Input;
}>;


export type CreateAssetLinkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_asset_watchdog_links_one?: Maybe<(
    { __typename?: 'asset_watchdog_links' }
    & AssetLinkDetailFieldsFragment
  )> }
);

export type UpdateAssetLinkMutationVariables = Exact<{
  assetLinkId: Scalars['uuid'];
  assetLink?: Maybe<Asset_Watchdog_Links_Set_Input>;
}>;


export type UpdateAssetLinkMutation = (
  { __typename?: 'mutation_root' }
  & { update_asset_watchdog_links_by_pk?: Maybe<(
    { __typename?: 'asset_watchdog_links' }
    & AssetLinkDetailFieldsFragment
  )> }
);

export type DeleteAssetLinkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteAssetLinkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_asset_watchdog_links_by_pk?: Maybe<(
    { __typename?: 'asset_watchdog_links' }
    & Pick<Asset_Watchdog_Links, 'id'>
  )> }
);

export type AssetLinkDetailFieldsFragment = (
  { __typename?: 'asset_watchdog_links' }
  & Pick<Asset_Watchdog_Links, 'id' | 'name' | 'source_url' | 'url' | 'status'>
);

export type AdminModelListFragmentFragment = (
  { __typename?: 'Model' }
  & Pick<Model, 'id' | 'description' | 'model_code' | 'sales_model_code' | 'model_group_release_year'>
  & { modelGroup?: Maybe<(
    { __typename?: 'ModelGroup' }
    & Pick<ModelGroup, 'id' | 'name'>
  )> }
);

export type BrandDetailsFieldsFragment = (
  { __typename?: 'Brand' }
  & Pick<Brand, 'id' | 'name'>
  & { salesSuppliers?: Maybe<Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  )>>, afterSalesSuppliers?: Maybe<Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  )>>, vehicle_metafields: Array<(
    { __typename?: 'brand_vehicle_metafield' }
    & Pick<Brand_Vehicle_Metafield, 'required_for_import' | 'default_value'>
    & { vehicle_metafield: (
      { __typename?: 'vehicle_metafields' }
      & Pick<Vehicle_Metafields, 'title' | 'column_name'>
    ) }
  )> }
);

export type DeleteEmbeddedPageMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteEmbeddedPageMutation = (
  { __typename?: 'mutation_root' }
  & { delete_embedded_pages_by_pk?: Maybe<(
    { __typename?: 'embedded_pages' }
    & Pick<Embedded_Pages, 'id'>
  )> }
);

export type SetBrandSalesSuppliersMutationVariables = Exact<{
  brandId: Scalars['uuid'];
  supplierIds: Array<Scalars['uuid']>;
  objects: Array<Brand_Suppliers_Insert_Input>;
}>;


export type SetBrandSalesSuppliersMutation = (
  { __typename?: 'mutation_root' }
  & { insert_brand_suppliers?: Maybe<(
    { __typename?: 'brand_suppliers_mutation_response' }
    & Pick<Brand_Suppliers_Mutation_Response, 'affected_rows'>
  )>, update_brand_suppliers?: Maybe<(
    { __typename?: 'brand_suppliers_mutation_response' }
    & Pick<Brand_Suppliers_Mutation_Response, 'affected_rows'>
  )>, delete_brand_suppliers?: Maybe<(
    { __typename?: 'brand_suppliers_mutation_response' }
    & Pick<Brand_Suppliers_Mutation_Response, 'affected_rows'>
  )>, update_brand?: Maybe<(
    { __typename?: 'Brand' }
    & BrandDetailsFieldsFragment
  )> }
);

export type SetBrandAfterSalesSuppliersMutationVariables = Exact<{
  brandId: Scalars['uuid'];
  supplierIds: Array<Scalars['uuid']>;
  objects: Array<Brand_Suppliers_Insert_Input>;
}>;


export type SetBrandAfterSalesSuppliersMutation = (
  { __typename?: 'mutation_root' }
  & { insert_brand_suppliers?: Maybe<(
    { __typename?: 'brand_suppliers_mutation_response' }
    & Pick<Brand_Suppliers_Mutation_Response, 'affected_rows'>
  )>, update_brand_suppliers?: Maybe<(
    { __typename?: 'brand_suppliers_mutation_response' }
    & Pick<Brand_Suppliers_Mutation_Response, 'affected_rows'>
  )>, delete_brand_suppliers?: Maybe<(
    { __typename?: 'brand_suppliers_mutation_response' }
    & Pick<Brand_Suppliers_Mutation_Response, 'affected_rows'>
  )>, update_brand?: Maybe<(
    { __typename?: 'Brand' }
    & BrandDetailsFieldsFragment
  )> }
);

export type SetVehicleMetafieldsMutationVariables = Exact<{
  brandId: Scalars['uuid'];
  objects: Array<Brand_Vehicle_Metafield_Insert_Input>;
}>;


export type SetVehicleMetafieldsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_brand_vehicle_metafield?: Maybe<(
    { __typename?: 'brand_vehicle_metafield_mutation_response' }
    & Pick<Brand_Vehicle_Metafield_Mutation_Response, 'affected_rows'>
  )>, insert_brand_vehicle_metafield?: Maybe<(
    { __typename?: 'brand_vehicle_metafield_mutation_response' }
    & Pick<Brand_Vehicle_Metafield_Mutation_Response, 'affected_rows'>
  )> }
);

export type ToggleDealerVehiclesSyncStatusMutationVariables = Exact<{
  id: Scalars['String'];
  enabled: Scalars['Boolean'];
}>;


export type ToggleDealerVehiclesSyncStatusMutation = (
  { __typename?: 'mutation_root' }
  & { toggleBrandForDealerVehicleSync: (
    { __typename?: 'ToggleBrandForDealerVehicleSyncPayload' }
    & Pick<ToggleBrandForDealerVehicleSyncPayload, 'status' | 'errorMessage'>
  ) }
);

export type UpdateModelsMutationVariables = Exact<{
  set?: Maybe<Model_Set_Input>;
  where: Model_Bool_Exp;
}>;


export type UpdateModelsMutation = (
  { __typename?: 'mutation_root' }
  & { update_models?: Maybe<(
    { __typename?: 'Model_mutation_response' }
    & Pick<Model_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'Model' }
      & AdminModelListFragmentFragment
    )> }
  )> }
);

export type AdminBrandDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminBrandDetailsQuery = (
  { __typename?: 'query_root' }
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & BrandDetailsFieldsFragment
  )> }
);

export type AdminBrandSupplierListQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminBrandSupplierListQuery = (
  { __typename?: 'query_root' }
  & { suppliers: Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  )> }
);

export type AdminDealerVehiclesSyncStatusQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AdminDealerVehiclesSyncStatusQuery = (
  { __typename?: 'query_root' }
  & { dealerVehicleBrandSyncStatus: (
    { __typename?: 'DealerVehicleBrandSyncStatusPayload' }
    & Pick<DealerVehicleBrandSyncStatusPayload, 'status' | 'enabled' | 'errorMessage'>
  ) }
);

export type AdminModelGroupFieldsFragment = (
  { __typename?: 'ModelGroup' }
  & Pick<ModelGroup, 'id' | 'name' | 'default_release_year'>
  & { releaseYears: Array<(
    { __typename?: 'model_group_release_years' }
    & Pick<Model_Group_Release_Years, 'release_year'>
  )> }
);

export type AdminModelGroupListQueryVariables = Exact<{
  brandId: Scalars['uuid'];
}>;


export type AdminModelGroupListQuery = (
  { __typename?: 'query_root' }
  & { model_groups: Array<(
    { __typename?: 'ModelGroup' }
    & AdminModelGroupFieldsFragment
  )> }
);

export type AdminModelGroupQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminModelGroupQuery = (
  { __typename?: 'query_root' }
  & { model_group?: Maybe<(
    { __typename?: 'ModelGroup' }
    & { models: Array<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'description' | 'model_code'>
    )> }
    & AdminModelGroupFieldsFragment
  )> }
);

export type CreateModelGroupMutationVariables = Exact<{
  object: ModelGroup_Insert_Input;
}>;


export type CreateModelGroupMutation = (
  { __typename?: 'mutation_root' }
  & { insert_model_group?: Maybe<(
    { __typename?: 'ModelGroup' }
    & AdminModelGroupFieldsFragment
  )> }
);

export type UpdateModelGroupMutationVariables = Exact<{
  modelGroupId: Scalars['uuid'];
  modelGroup: ModelGroup_Set_Input;
  releaseYears: Array<Scalars['Int']>;
  releaseYearReferences: Array<Model_Group_Release_Years_Insert_Input>;
}>;


export type UpdateModelGroupMutation = (
  { __typename?: 'mutation_root' }
  & { delete_model_group_release_years?: Maybe<(
    { __typename?: 'model_group_release_years_mutation_response' }
    & Pick<Model_Group_Release_Years_Mutation_Response, 'affected_rows'>
  )>, insert_model_group_release_years?: Maybe<(
    { __typename?: 'model_group_release_years_mutation_response' }
    & Pick<Model_Group_Release_Years_Mutation_Response, 'affected_rows'>
  )>, update_model_group?: Maybe<(
    { __typename?: 'ModelGroup' }
    & AdminModelGroupFieldsFragment
  )> }
);

export type AdminModelListQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  brandId: Scalars['uuid'];
  searchText?: Maybe<Scalars['String']>;
}>;


export type AdminModelListQuery = (
  { __typename?: 'query_root' }
  & { models: Array<(
    { __typename?: 'Model' }
    & AdminModelListFragmentFragment
  )>, models_aggregate: (
    { __typename?: 'Model_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Model_aggregate_fields' }
      & Pick<Model_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type AdminModelDetailsQueryVariables = Exact<{
  modelId: Scalars['uuid'];
}>;


export type AdminModelDetailsQuery = (
  { __typename?: 'query_root' }
  & { model?: Maybe<(
    { __typename?: 'Model' }
    & AdminModelListFragmentFragment
  )> }
);

export type AdminModelsInStockListQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  brandId: Scalars['uuid'];
  searchText?: Maybe<Scalars['String']>;
}>;


export type AdminModelsInStockListQuery = (
  { __typename?: 'query_root' }
  & { models: Array<(
    { __typename?: 'Model' }
    & AdminModelListFragmentFragment
  )>, models_aggregate: (
    { __typename?: 'Model_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Model_aggregate_fields' }
      & Pick<Model_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type AdminNewModelsListQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  brandId: Scalars['uuid'];
  searchText?: Maybe<Scalars['String']>;
}>;


export type AdminNewModelsListQuery = (
  { __typename?: 'query_root' }
  & { models: Array<(
    { __typename?: 'Model' }
    & AdminModelListFragmentFragment
  )>, models_aggregate: (
    { __typename?: 'Model_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Model_aggregate_fields' }
      & Pick<Model_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type AdminVehicleMetafieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminVehicleMetafieldsQuery = (
  { __typename?: 'query_root' }
  & { vehicle_metafields: Array<(
    { __typename?: 'vehicle_metafields' }
    & Pick<Vehicle_Metafields, 'column_name' | 'title'>
  )> }
);

export type AdminBrandEmbeddedPagesSubscriptionVariables = Exact<{
  brandId: Scalars['uuid'];
}>;


export type AdminBrandEmbeddedPagesSubscription = (
  { __typename?: 'subscription_root' }
  & { embedded_pages: Array<(
    { __typename?: 'embedded_pages' }
    & Pick<Embedded_Pages, 'id' | 'url' | 'name' | 'brand_id'>
  )> }
);

export type AdminBrandListSubscriptionVariables = Exact<{
  where?: Maybe<Brand_Bool_Exp>;
}>;


export type AdminBrandListSubscription = (
  { __typename?: 'subscription_root' }
  & { brands: Array<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  )> }
);

export type CreateNewDepartmentMutationVariables = Exact<{
  input: Department_Insert_Input;
}>;


export type CreateNewDepartmentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_department?: Maybe<(
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  )> }
);

export type DepartmentDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DepartmentDetailQuery = (
  { __typename?: 'query_root' }
  & { department?: Maybe<(
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  )> }
);

export type UpdateDepartmentMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<Department_Set_Input>;
  objects: Array<Department_Role_Insert_Input>;
}>;


export type UpdateDepartmentMutation = (
  { __typename?: 'mutation_root' }
  & { update_department?: Maybe<(
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  )>, delete_department_role?: Maybe<(
    { __typename?: 'department_role_mutation_response' }
    & Pick<Department_Role_Mutation_Response, 'affected_rows'>
  )>, insert_department_role?: Maybe<(
    { __typename?: 'department_role_mutation_response' }
    & Pick<Department_Role_Mutation_Response, 'affected_rows'>
  )> }
);

export type AdminEmbeddedPageDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminEmbeddedPageDetailQuery = (
  { __typename?: 'query_root' }
  & { embedded_pages_by_pk?: Maybe<(
    { __typename?: 'embedded_pages' }
    & Pick<Embedded_Pages, 'name' | 'url' | 'brand_id'>
  )> }
);

export type CreateNewEmbeddedPageMutationVariables = Exact<{
  object: Embedded_Pages_Insert_Input;
}>;


export type CreateNewEmbeddedPageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_embedded_pages_one?: Maybe<(
    { __typename?: 'embedded_pages' }
    & Pick<Embedded_Pages, 'id'>
  )> }
);

export type UpdateEmbeddedPageMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<Embedded_Pages_Set_Input>;
}>;


export type UpdateEmbeddedPageMutation = (
  { __typename?: 'mutation_root' }
  & { update_embedded_pages_by_pk?: Maybe<(
    { __typename?: 'embedded_pages' }
    & Pick<Embedded_Pages, 'id'>
  )> }
);

export type AdminLocationDetailFieldsFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'short_name'>
  & { brandReferences: Array<(
    { __typename?: 'LocationBrandReference' }
    & Pick<LocationBrandReference, 'after_sales' | 'sales' | 'isPrimaryDealer'>
    & { brand: (
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'name'>
    ) }
  )>, locationGroups: Array<(
    { __typename?: 'LocationGroup' }
    & LocationGroupFieldsFragment
  )>, dmsReferences: Array<(
    { __typename?: 'DmsReference' }
    & Pick<DmsReference, 'id' | 'name' | 'type' | 'reference'>
  )>, supplierDmsReferences: Array<(
    { __typename?: 'DmsReference' }
    & Pick<DmsReference, 'id' | 'name' | 'type' | 'reference'>
  )>, customerDmsReferences: Array<(
    { __typename?: 'DmsReference' }
    & Pick<DmsReference, 'id' | 'name' | 'type' | 'reference'>
  )> }
);

export type AdminLocationDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminLocationDetailQuery = (
  { __typename?: 'query_root' }
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & AdminLocationDetailFieldsFragment
  )> }
);

export type AddLocationGroupMutationVariables = Exact<{
  object: LocationGroup_Insert_Input;
}>;


export type AddLocationGroupMutation = (
  { __typename?: 'mutation_root' }
  & { insert_location_group?: Maybe<(
    { __typename?: 'LocationGroup' }
    & Pick<LocationGroup, 'id' | 'name'>
    & { brands: Array<(
      { __typename?: 'location_group_brands_view' }
      & Pick<Location_Group_Brands_View, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateLocationGroupMutationVariables = Exact<{
  id: Scalars['uuid'];
  groupUpdate: LocationGroup_Set_Input;
  groupBrandRefObj: Array<Location_Group_Brand_Insert_Input>;
  brandIds: Array<Scalars['uuid']>;
}>;


export type UpdateLocationGroupMutation = (
  { __typename?: 'mutation_root' }
  & { insert_location_group_brand?: Maybe<(
    { __typename?: 'location_group_brand_mutation_response' }
    & Pick<Location_Group_Brand_Mutation_Response, 'affected_rows'>
  )>, delete_location_group_brand?: Maybe<(
    { __typename?: 'location_group_brand_mutation_response' }
    & Pick<Location_Group_Brand_Mutation_Response, 'affected_rows'>
  )>, update_location_group?: Maybe<(
    { __typename?: 'LocationGroup' }
    & Pick<LocationGroup, 'id' | 'name'>
    & { brands: Array<(
      { __typename?: 'location_group_brands_view' }
      & Pick<Location_Group_Brands_View, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveLocationGroupMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RemoveLocationGroupMutation = (
  { __typename?: 'mutation_root' }
  & { delete_location_group?: Maybe<(
    { __typename?: 'LocationGroup' }
    & Pick<LocationGroup, 'id'>
  )> }
);

export type SetLocationBrandRefsMutationVariables = Exact<{
  locationId: Scalars['uuid'];
  brandIds: Array<Scalars['uuid']>;
  objects: Array<LocationBrandReference_Insert_Input>;
}>;


export type SetLocationBrandRefsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_location_brand_references?: Maybe<(
    { __typename?: 'LocationBrandReference_mutation_response' }
    & Pick<LocationBrandReference_Mutation_Response, 'affected_rows'>
  )>, delete_location_brand_references?: Maybe<(
    { __typename?: 'LocationBrandReference_mutation_response' }
    & Pick<LocationBrandReference_Mutation_Response, 'affected_rows'>
  )> }
);

export type SetLocationDmsReferencesMutationVariables = Exact<{
  locationId: Scalars['uuid'];
  refIds: Array<Scalars['uuid']>;
  objects: Array<DmsReference_Insert_Input>;
}>;


export type SetLocationDmsReferencesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_dms_references?: Maybe<(
    { __typename?: 'DmsReference_mutation_response' }
    & Pick<DmsReference_Mutation_Response, 'affected_rows'>
  )>, insert_dms_references?: Maybe<(
    { __typename?: 'DmsReference_mutation_response' }
    & Pick<DmsReference_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateLocationShortNameMutationVariables = Exact<{
  pk_columns: Location_Pk_Columns_Input;
  _set?: Maybe<Location_Set_Input>;
}>;


export type UpdateLocationShortNameMutation = (
  { __typename?: 'mutation_root' }
  & { update_location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  )> }
);

export type CreateSubDealerMutationVariables = Exact<{
  object: Subdealer_Insert_Input;
}>;


export type CreateSubDealerMutation = (
  { __typename?: 'mutation_root' }
  & { insert_subdealer?: Maybe<(
    { __typename?: 'Subdealer' }
    & SubdealerDetailFieldsFragment
  )> }
);

export type UpdateSubdealerMutationVariables = Exact<{
  subdealearId: Scalars['uuid'];
  addressId: Scalars['uuid'];
  refIds: Array<Scalars['uuid']>;
  brandIds: Array<Scalars['uuid']>;
  subdealer: Subdealer_Set_Input;
  address: Address_Set_Input;
  references: Array<DmsReference_Insert_Input>;
  brandReferences: Array<Subdealer_Brand_Insert_Input>;
}>;


export type UpdateSubdealerMutation = (
  { __typename?: 'mutation_root' }
  & { delete_dms_references?: Maybe<(
    { __typename?: 'DmsReference_mutation_response' }
    & Pick<DmsReference_Mutation_Response, 'affected_rows'>
  )>, insert_dms_references?: Maybe<(
    { __typename?: 'DmsReference_mutation_response' }
    & Pick<DmsReference_Mutation_Response, 'affected_rows'>
  )>, update_address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id'>
  )>, delete_subdealer_brand?: Maybe<(
    { __typename?: 'subdealer_brand_mutation_response' }
    & Pick<Subdealer_Brand_Mutation_Response, 'affected_rows'>
  )>, insert_subdealer_brand?: Maybe<(
    { __typename?: 'subdealer_brand_mutation_response' }
    & Pick<Subdealer_Brand_Mutation_Response, 'affected_rows'>
  )>, update_subdealer?: Maybe<(
    { __typename?: 'Subdealer' }
    & SubdealerDetailFieldsFragment
  )> }
);

export type AdminSubDealerDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminSubDealerDetailsQuery = (
  { __typename?: 'query_root' }
  & { subdealer?: Maybe<(
    { __typename?: 'Subdealer' }
    & SubdealerDetailFieldsFragment
  )> }
);

export type SubdealerDetailFieldsFragment = (
  { __typename?: 'Subdealer' }
  & Pick<Subdealer, 'id' | 'name'>
  & { address: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'street' | 'streetNo' | 'city' | 'zipCode' | 'countryCode' | 'fullAddress'>
  ), customerDmsReferences: Array<(
    { __typename?: 'DmsReference' }
    & Pick<DmsReference, 'id' | 'name' | 'type' | 'reference'>
  )>, brands: Array<(
    { __typename?: 'subdealer_brands_view' }
    & Pick<Subdealer_Brands_View, 'id' | 'name'>
  )> }
);

export type CreateSupplierMutationVariables = Exact<{
  object: Supplier_Insert_Input;
}>;


export type CreateSupplierMutation = (
  { __typename?: 'mutation_root' }
  & { insert_supplier?: Maybe<(
    { __typename?: 'Supplier' }
    & SupplierDetailFieldsFragment
  )> }
);

export type UpdateSupplierMutationVariables = Exact<{
  supplierId: Scalars['uuid'];
  addressId: Scalars['uuid'];
  refIds: Array<Scalars['uuid']>;
  supplier: Supplier_Set_Input;
  address: Address_Set_Input;
  references: Array<DmsReference_Insert_Input>;
}>;


export type UpdateSupplierMutation = (
  { __typename?: 'mutation_root' }
  & { delete_dms_references?: Maybe<(
    { __typename?: 'DmsReference_mutation_response' }
    & Pick<DmsReference_Mutation_Response, 'affected_rows'>
  )>, insert_dms_references?: Maybe<(
    { __typename?: 'DmsReference_mutation_response' }
    & Pick<DmsReference_Mutation_Response, 'affected_rows'>
  )>, update_address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id'>
  )>, update_supplier?: Maybe<(
    { __typename?: 'Supplier' }
    & SupplierDetailFieldsFragment
  )> }
);

export type AdminSupplierDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminSupplierDetailQuery = (
  { __typename?: 'query_root' }
  & { supplier?: Maybe<(
    { __typename?: 'Supplier' }
    & SupplierDetailFieldsFragment
  )> }
);

export type SupplierDetailFieldsFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'name' | 'active'>
  & { address: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'street' | 'streetNo' | 'city' | 'zipCode' | 'countryCode' | 'fullAddress'>
  ), dmsReferences: Array<(
    { __typename?: 'DmsReference' }
    & Pick<DmsReference, 'id' | 'name' | 'type' | 'reference'>
  )>, customerDmsReferences: Array<(
    { __typename?: 'DmsReference' }
    & Pick<DmsReference, 'id' | 'name' | 'type' | 'reference'>
  )> }
);

export type TicketTemplateDetailFieldsFragment = (
  { __typename?: 'TicketTemplate' }
  & Pick<TicketTemplate, 'id' | 'priority' | 'template_title' | 'ticket_title' | 'ticket_description' | 'assign_to' | 'location' | 'department_id' | 'automated_task' | 'trigger_filter'>
  & { department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'description' | 'name'>
  )> }
);

export type CreateTicketTemplateMutationVariables = Exact<{
  input: TicketTemplate_Insert_Input;
}>;


export type CreateTicketTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_ticket_template?: Maybe<(
    { __typename?: 'TicketTemplate' }
    & TicketTemplateDetailFieldsFragment
  )> }
);

export type UpdateTicketTemplateMutationVariables = Exact<{
  id: Scalars['uuid'];
  set?: Maybe<TicketTemplate_Set_Input>;
}>;


export type UpdateTicketTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { update_ticket_template?: Maybe<(
    { __typename?: 'TicketTemplate' }
    & TicketTemplateDetailFieldsFragment
  )> }
);

export type TicketTemplateDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TicketTemplateDetailQuery = (
  { __typename?: 'query_root' }
  & { ticket_template?: Maybe<(
    { __typename?: 'TicketTemplate' }
    & TicketTemplateDetailFieldsFragment
  )> }
);

export type DeleteDepartmentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteDepartmentMutation = (
  { __typename?: 'mutation_root' }
  & { delete_department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id'>
  )> }
);

export type DepartmentsListSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type DepartmentsListSubscription = (
  { __typename?: 'subscription_root' }
  & { departments: Array<(
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  )> }
);

export type UserDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserDetailQuery = (
  { __typename?: 'query_root' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'email' | 'isActive' | 'primary_role' | 'abbreviation' | 'last_login'>
  )> }
);

export type TeamAssignmentFragmentFragment = (
  { __typename?: 'TeamAssignment' }
  & Pick<TeamAssignment, 'id' | 'user_id' | 'department_id' | 'location_id' | 'brand_id' | 'in_buy_channel' | 'is_primary' | 'role'>
  & { department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name'>
  )>, location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'short_name' | 'name'>
  )>, brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  )> }
);

export type UserTeamsSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type UserTeamsSubscription = (
  { __typename?: 'subscription_root' }
  & { team_assignments: Array<(
    { __typename?: 'TeamAssignment' }
    & TeamAssignmentFragmentFragment
  )> }
);

export type UserTeamDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserTeamDetailQuery = (
  { __typename?: 'query_root' }
  & { team_assignment?: Maybe<(
    { __typename?: 'TeamAssignment' }
    & TeamAssignmentFragmentFragment
  )> }
);

export type DepartmentRolesQueryVariables = Exact<{
  departmentId: Scalars['uuid'];
}>;


export type DepartmentRolesQuery = (
  { __typename?: 'query_root' }
  & { department_role: Array<(
    { __typename?: 'department_role' }
    & Pick<Department_Role, 'department_id' | 'role'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  _set?: Maybe<User_Set_Input>;
  id: Scalars['uuid'];
  role: Role_Enum;
}>;


export type UpdateUserMutation = (
  { __typename?: 'mutation_root' }
  & { update_user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, delete_team_assignments?: Maybe<(
    { __typename?: 'TeamAssignment_mutation_response' }
    & Pick<TeamAssignment_Mutation_Response, 'affected_rows'>
  )>, insert_team_assignment?: Maybe<(
    { __typename?: 'TeamAssignment' }
    & Pick<TeamAssignment, 'id'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'mutation_root' }
  & { createUser: (
    { __typename?: 'CreateUserPayload' }
    & Pick<CreateUserPayload, 'id'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'mutation_root' }
  & { delete_user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type InsertTeamAssignmentMutationVariables = Exact<{
  object: TeamAssignment_Insert_Input;
}>;


export type InsertTeamAssignmentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_team_assignment?: Maybe<(
    { __typename?: 'TeamAssignment' }
    & Pick<TeamAssignment, 'id'>
  )> }
);

export type UpdateTeamAssignmentMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<TeamAssignment_Set_Input>;
}>;


export type UpdateTeamAssignmentMutation = (
  { __typename?: 'mutation_root' }
  & { update_team_assignment?: Maybe<(
    { __typename?: 'TeamAssignment' }
    & Pick<TeamAssignment, 'id'>
  )> }
);

export type DeleteTeamAssignmentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteTeamAssignmentMutation = (
  { __typename?: 'mutation_root' }
  & { delete_team_assignment?: Maybe<(
    { __typename?: 'TeamAssignment' }
    & Pick<TeamAssignment, 'id'>
  )> }
);

export type AdminVehicleImportJobQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminVehicleImportJobQuery = (
  { __typename?: 'query_root' }
  & { importJob: (
    { __typename?: 'ImportJobPayload' }
    & Pick<ImportJobPayload, 'id' | 'url'>
    & { import_job?: Maybe<(
      { __typename?: 'import_jobs' }
      & Pick<Import_Jobs, 'id' | 'upload_file_excel_definition' | 'status' | 'matching_field' | 'filter' | 'upload_file_name' | 'created_at' | 'file_key'>
      & { import_template?: Maybe<(
        { __typename?: 'vehicle_import_templates' }
        & Pick<Vehicle_Import_Templates, 'id'>
        & { assignments: Array<(
          { __typename?: 'excel_import_assignments' }
          & Pick<Excel_Import_Assignments, 'id' | 'header_cell' | 'key'>
        )>, parent_template?: Maybe<(
          { __typename?: 'vehicle_import_templates' }
          & Pick<Vehicle_Import_Templates, 'id' | 'name'>
          & { assignments: Array<(
            { __typename?: 'excel_import_assignments' }
            & Pick<Excel_Import_Assignments, 'id' | 'header_cell' | 'key'>
          )> }
        )> }
      )>, executables: Array<(
        { __typename?: 'import_job_vehicle_executables' }
        & Pick<Import_Job_Vehicle_Executables, 'id' | 'row_number' | 'validation_error' | 'import_error' | 'executable_status' | 'vin' | 'job_number' | 'state' | 'order_state' | 'type' | 'min_holding_period' | 'customer' | 'sold_at' | 'key_kabinet_number' | 'registration_owner' | 'registration_state' | 'delivery_date_str' | 'specs' | 'spec_level'>
      )> }
    )> }
  ) }
);

export type AddAfterSalesPotentialNoteMutationVariables = Exact<{
  customerId: Scalars['uuid'];
  potentialId: Scalars['uuid'];
  vehicleId?: Maybe<Scalars['uuid']>;
  businessCaseNumber?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
  customerVehicleId?: Maybe<Scalars['uuid']>;
  type: Contact_Note_Type_Enum;
  note: Scalars['String'];
}>;


export type AddAfterSalesPotentialNoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_note?: Maybe<(
    { __typename?: 'Notes' }
    & Pick<Notes, 'id' | 'type' | 'note' | 'created_at'>
  )> }
);

export type AfterSalesPotentialDetailsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AfterSalesPotentialDetailsSubscription = (
  { __typename?: 'subscription_root' }
  & { after_sales_potential?: Maybe<(
    { __typename?: 'AfterSalesPotential' }
    & Pick<AfterSalesPotential, 'id' | 'year' | 'month' | 'status' | 'license_plate_number' | 'mileage' | 'mileage_date' | 'first_registration_date' | 'warranty_end' | 'warranty_start' | 'customer_name' | 'customer_city' | 'customer_zip_code' | 'last_service_date' | 'last_inspection_sticker_date'>
    & { customer_vehicle?: Maybe<(
      { __typename?: 'CustomerVehicle' }
      & Pick<CustomerVehicle, 'id' | 'vin' | 'model_description'>
      & { brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id' | 'name'>
      ), model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'description'>
      )> }
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'business_case_number' | 'version' | 'vin' | 'model_name'>
      & { brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id' | 'name'>
      ) }
    )>, customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'customer_no' | 'name' | 'salutation' | 'city' | 'zip_code' | 'street'>
      & { phone_numbers: Array<(
        { __typename?: 'PhoneNumber' }
        & Pick<PhoneNumber, 'id' | 'number' | 'type' | 'description'>
      )>, notes: Array<(
        { __typename?: 'Notes' }
        & Pick<Notes, 'id' | 'type' | 'note' | 'created_at'>
      )> }
    ) }
  )> }
);

export type PrimeMenuQueryVariables = Exact<{ [key: string]: never; }>;


export type PrimeMenuQuery = (
  { __typename?: 'query_root' }
  & { brands: Array<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  )> }
);

export type TicketTemplateAssignToSelectDataQueryVariables = Exact<{ [key: string]: never; }>;


export type TicketTemplateAssignToSelectDataQuery = (
  { __typename?: 'query_root' }
  & { ticket_template_assignee_ref: Array<(
    { __typename?: 'ticket_template_assignee_ref' }
    & TicketTemplateAssignToSelectFieldsFragment
  )> }
);

export type TicketTemplateAssignToSelectFieldsFragment = (
  { __typename?: 'ticket_template_assignee_ref' }
  & Pick<Ticket_Template_Assignee_Ref, 'name' | 'code_reference_field'>
);

export type TicketTemplateLocationSelectDataQueryVariables = Exact<{ [key: string]: never; }>;


export type TicketTemplateLocationSelectDataQuery = (
  { __typename?: 'query_root' }
  & { ticket_template_location_ref: Array<(
    { __typename?: 'ticket_template_location_ref' }
    & TicketTemplateLocationSelectFieldsFragment
  )> }
);

export type TicketTemplateLocationSelectFieldsFragment = (
  { __typename?: 'ticket_template_location_ref' }
  & Pick<Ticket_Template_Location_Ref, 'name' | 'code_reference_field'>
);

export type RecoverVehiclesHistoriesMutationVariables = Exact<{
  vehicleIds: Array<Scalars['String']>;
}>;


export type RecoverVehiclesHistoriesMutation = (
  { __typename?: 'mutation_root' }
  & { recoverVehiclesHistories: (
    { __typename?: 'RecoverVehiclesHistoriesPayload' }
    & Pick<RecoverVehiclesHistoriesPayload, 'status' | 'message'>
  ) }
);

export type VehiclesWithMixedUpInvoicesQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
}>;


export type VehiclesWithMixedUpInvoicesQuery = (
  { __typename?: 'query_root' }
  & { vehiclesWithMixedUpInvoices: (
    { __typename?: 'VehiclesWithMixedUpInvoicesPayload' }
    & { vehicles: Array<(
      { __typename?: 'VehicleWithMixedUpInvoice' }
      & Pick<VehicleWithMixedUpInvoice, 'id' | 'vin'>
    )>, cursor: (
      { __typename?: 'PaginationCursor' }
      & Pick<PaginationCursor, 'next' | 'hasNextPage'>
    ) }
  ) }
);

export type MarkNotificationAsReadMutationVariables = Exact<{
  id: Scalars['uuid'];
  assetLinkFamily: Scalars['Boolean'];
  ticketFamily: Scalars['Boolean'];
}>;


export type MarkNotificationAsReadMutation = (
  { __typename?: 'mutation_root' }
  & { update_asset_link_family_notifications_by_pk?: Maybe<(
    { __typename?: 'asset_link_family_notifications' }
    & Pick<Asset_Link_Family_Notifications, 'id' | 'status'>
  )>, update_ticket_family_notifications_by_pk?: Maybe<(
    { __typename?: 'ticket_family_notifications' }
    & Pick<Ticket_Family_Notifications, 'id' | 'status'>
  )> }
);

export type NotificationsSubscriptionVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type NotificationsSubscription = (
  { __typename?: 'subscription_root' }
  & { notifications: Array<(
    { __typename?: 'notifications' }
    & Pick<Notifications, 'id' | 'content' | 'created_at' | 'status' | 'title' | 'type'>
    & { asset_watchdog_link?: Maybe<(
      { __typename?: 'asset_watchdog_links' }
      & Pick<Asset_Watchdog_Links, 'id' | 'name' | 'source_url'>
    )>, ticket?: Maybe<(
      { __typename?: 'Ticket' }
      & Pick<Ticket, 'id' | 'title'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'business_case_number' | 'version' | 'vin' | 'job_number'>
      )> }
    )> }
  )> }
);

export type DepartmentSelectDataQueryVariables = Exact<{ [key: string]: never; }>;


export type DepartmentSelectDataQuery = (
  { __typename?: 'query_root' }
  & { departments: Array<(
    { __typename?: 'Department' }
    & DepartmentFieldsFragment
  )> }
);

export type DepartmentFieldsFragment = (
  { __typename?: 'Department' }
  & Pick<Department, 'id' | 'name' | 'description'>
  & { roles: Array<(
    { __typename?: 'department_role' }
    & Pick<Department_Role, 'role' | 'department_id'>
  )> }
);

export type EmbeddedPagesSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type EmbeddedPagesSelectQuery = (
  { __typename?: 'query_root' }
  & { embedded_pages: Array<(
    { __typename?: 'embedded_pages' }
    & Pick<Embedded_Pages, 'id' | 'name'>
  )> }
);

export type AddVehicleImportTemplateMutationVariables = Exact<{
  templateId: Scalars['uuid'];
  newTemplateId: Scalars['uuid'];
  importJobId: Scalars['uuid'];
  name: Scalars['String'];
  strict: Scalars['Boolean'];
  excelDef?: Maybe<Array<Scalars['String']>>;
}>;


export type AddVehicleImportTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_import_templates_by_pk?: Maybe<(
    { __typename?: 'vehicle_import_templates' }
    & Pick<Vehicle_Import_Templates, 'id'>
  )>, new_import_template?: Maybe<(
    { __typename?: 'vehicle_import_templates' }
    & Pick<Vehicle_Import_Templates, 'id'>
  )>, update_import_jobs_by_pk?: Maybe<(
    { __typename?: 'import_jobs' }
    & Pick<Import_Jobs, 'id'>
  )> }
);

export type AssignVehicleColumnToFieldMutationVariables = Exact<{
  templateId: Scalars['uuid'];
  key: Scalars['String'];
  col: Scalars['String'];
}>;


export type AssignVehicleColumnToFieldMutation = (
  { __typename?: 'mutation_root' }
  & { delete_excel_import_assignments?: Maybe<(
    { __typename?: 'excel_import_assignments_mutation_response' }
    & Pick<Excel_Import_Assignments_Mutation_Response, 'affected_rows'>
  )>, insert_excel_import_assignments_one?: Maybe<(
    { __typename?: 'excel_import_assignments' }
    & Pick<Excel_Import_Assignments, 'id'>
  )> }
);

export type AssignVehicleImportTemplateToImportJobMutationVariables = Exact<{
  id: Scalars['uuid'];
  template_id: Scalars['uuid'];
}>;


export type AssignVehicleImportTemplateToImportJobMutation = (
  { __typename?: 'mutation_root' }
  & { checkImportJobTemplateCompatibility?: Maybe<(
    { __typename?: 'CheckImportJobTemplateCompatibilityPayload' }
    & Pick<CheckImportJobTemplateCompatibilityPayload, 'status'>
  )> }
);

export type ClearImportJobVehicleExecutablesMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ClearImportJobVehicleExecutablesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_import_job_vehicle_executables?: Maybe<(
    { __typename?: 'import_job_vehicle_executables_mutation_response' }
    & Pick<Import_Job_Vehicle_Executables_Mutation_Response, 'affected_rows'>
  )>, update_import_jobs_by_pk?: Maybe<(
    { __typename?: 'import_jobs' }
    & Pick<Import_Jobs, 'id'>
  )> }
);

export type CreateVehicleImportJobMutationVariables = Exact<{
  id: Scalars['uuid'];
  fileKey: Scalars['String'];
  matching_field: Scalars['String'];
  filter: Scalars['String'];
  fileName: Scalars['String'];
  templateId: Scalars['uuid'];
}>;


export type CreateVehicleImportJobMutation = (
  { __typename?: 'mutation_root' }
  & { insert_import_jobs_one?: Maybe<(
    { __typename?: 'import_jobs' }
    & Pick<Import_Jobs, 'id'>
  )> }
);

export type DeleteVehicleImportTemplateMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteVehicleImportTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { delete_vehicle_import_templates_by_pk?: Maybe<(
    { __typename?: 'vehicle_import_templates' }
    & Pick<Vehicle_Import_Templates, 'id'>
  )> }
);

export type ExecuteImportJobMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ExecuteImportJobMutation = (
  { __typename?: 'mutation_root' }
  & { executeImportJob?: Maybe<(
    { __typename?: 'ExecuteImportJobPayload' }
    & Pick<ExecuteImportJobPayload, 'status'>
  )> }
);

export type InheritFromVehicleImportTemplateMutationVariables = Exact<{
  id: Scalars['uuid'];
  parentId: Scalars['uuid'];
}>;


export type InheritFromVehicleImportTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_import_templates_by_pk?: Maybe<(
    { __typename?: 'vehicle_import_templates' }
    & Pick<Vehicle_Import_Templates, 'id'>
  )> }
);

export type PreviewImportJobMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PreviewImportJobMutation = (
  { __typename?: 'mutation_root' }
  & { previewImportJob: (
    { __typename?: 'PreviewImportJobPayload' }
    & Pick<PreviewImportJobPayload, 'status'>
  ) }
);

export type RemoveParentVehicleImportTemplateMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RemoveParentVehicleImportTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_import_templates_by_pk?: Maybe<(
    { __typename?: 'vehicle_import_templates' }
    & Pick<Vehicle_Import_Templates, 'id'>
  )> }
);

export type ResetVehicleExcelAssignmentsMutationVariables = Exact<{
  templateId: Scalars['uuid'];
}>;


export type ResetVehicleExcelAssignmentsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_excel_import_assignments?: Maybe<(
    { __typename?: 'excel_import_assignments_mutation_response' }
    & Pick<Excel_Import_Assignments_Mutation_Response, 'affected_rows'>
  )> }
);

export type UnassignVehicleColumnFromFieldMutationVariables = Exact<{
  templateId: Scalars['uuid'];
  col: Scalars['String'];
}>;


export type UnassignVehicleColumnFromFieldMutation = (
  { __typename?: 'mutation_root' }
  & { delete_excel_import_assignments?: Maybe<(
    { __typename?: 'excel_import_assignments_mutation_response' }
    & Pick<Excel_Import_Assignments_Mutation_Response, 'affected_rows'>
  )> }
);

export type ExcelImportAssignmentsQueryVariables = Exact<{
  templateId: Scalars['uuid'];
  vehicle_variant: Scalars['Boolean'];
}>;


export type ExcelImportAssignmentsQuery = (
  { __typename?: 'query_root' }
  & { excel_import_assignments: Array<(
    { __typename?: 'excel_import_assignments' }
    & Pick<Excel_Import_Assignments, 'id' | 'key' | 'header_cell' | 'vehicle_import_template_id'>
  )> }
);

export type ImportJobUploadUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type ImportJobUploadUrlQuery = (
  { __typename?: 'query_root' }
  & { importJobUploadUrl: (
    { __typename?: 'ImportJobUploadUrlPayload' }
    & Pick<ImportJobUploadUrlPayload, 'uploadUrl' | 'key'>
  ) }
);

export type ImportJobPreviewItemsQueryVariables = Exact<{
  id: Scalars['uuid'];
  vehicle_variant: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
}>;


export type ImportJobPreviewItemsQuery = (
  { __typename?: 'query_root' }
  & { import_jobs_by_pk?: Maybe<(
    { __typename?: 'import_jobs' }
    & Pick<Import_Jobs, 'id'>
    & { preview_items: Array<(
      { __typename?: 'import_job_vehicle_executables' }
      & Pick<Import_Job_Vehicle_Executables, 'id' | 'row_number' | 'validation_error' | 'executable_status' | 'vin' | 'job_number' | 'state' | 'order_state' | 'type' | 'min_holding_period' | 'customer' | 'sold_at' | 'key_kabinet_number' | 'registration_owner' | 'registration_state' | 'delivery_date_str' | 'specs' | 'spec_level' | 'handover_planned_date' | 'handover_date' | 'in_buy_contract_date'>
    )>, preview_items_aggregate: (
      { __typename?: 'import_job_vehicle_executables_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'import_job_vehicle_executables_aggregate_fields' }
        & Pick<Import_Job_Vehicle_Executables_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type ImportJobSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  vehicle_variant: Scalars['Boolean'];
}>;


export type ImportJobSubscription = (
  { __typename?: 'subscription_root' }
  & { import_jobs_by_pk?: Maybe<(
    { __typename?: 'import_jobs' }
    & Pick<Import_Jobs, 'id' | 'upload_file_excel_definition' | 'status' | 'matching_field' | 'filter' | 'upload_file_name' | 'upload_file_url' | 'created_at'>
    & { import_template?: Maybe<(
      { __typename?: 'vehicle_import_templates' }
      & Pick<Vehicle_Import_Templates, 'id'>
      & { assignments: Array<(
        { __typename?: 'excel_import_assignments' }
        & Pick<Excel_Import_Assignments, 'id' | 'header_cell' | 'key'>
      )>, parent_template?: Maybe<(
        { __typename?: 'vehicle_import_templates' }
        & Pick<Vehicle_Import_Templates, 'id' | 'name'>
        & { assignments: Array<(
          { __typename?: 'excel_import_assignments' }
          & Pick<Excel_Import_Assignments, 'id' | 'header_cell' | 'key'>
        )> }
      )> }
    )>, executables: Array<(
      { __typename?: 'import_job_vehicle_executables' }
      & Pick<Import_Job_Vehicle_Executables, 'id' | 'row_number' | 'validation_error' | 'import_error' | 'executable_status' | 'vin' | 'job_number' | 'state' | 'order_state' | 'type' | 'min_holding_period' | 'customer' | 'sold_at' | 'key_kabinet_number' | 'registration_owner' | 'registration_state' | 'delivery_date_str' | 'specs' | 'spec_level'>
    )> }
  )> }
);

export type ImportTemplatesSubscriptionVariables = Exact<{
  vehicle_variant: Scalars['Boolean'];
}>;


export type ImportTemplatesSubscription = (
  { __typename?: 'subscription_root' }
  & { import_templates: Array<(
    { __typename?: 'vehicle_import_templates' }
    & Pick<Vehicle_Import_Templates, 'id' | 'name' | 'strict' | 'excel_definition'>
  )> }
);

export type TicketDetailsFieldsFragment = (
  { __typename?: 'Ticket' }
  & Pick<Ticket, 'id' | 'created_at' | 'updated_at' | 'state' | 'priority' | 'title' | 'description' | 'due_to' | 'created_by_id' | 'location_id' | 'department_id' | 'template_id' | 'assignee_id' | 'vehicle_id' | 'vehicle_version' | 'vehicle_business_case_number'>
  & { created_by?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'full_name' | 'email' | 'abbreviation'>
  )>, location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'short_name' | 'name'>
  )>, notes: Array<(
    { __typename?: 'TicketNote' }
    & Pick<TicketNote, 'id' | 'created_at' | 'updated_at' | 'note'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'full_name' | 'email'>
    ) }
  )>, department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name' | 'description'>
  )>, assignee?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'full_name' | 'email' | 'abbreviation'>
  )>, vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'version' | 'business_case_number' | 'vin' | 'license_plate_number' | 'job_number'>
  )> }
);

export type TicketsListFieldsFragment = (
  { __typename?: 'Ticket' }
  & Pick<Ticket, 'id' | 'title' | 'description' | 'created_at' | 'updated_at' | 'state' | 'priority' | 'due_to' | 'location_id' | 'assignee_id' | 'department_id'>
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'short_name' | 'name'>
  )>, assignee?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'full_name' | 'email' | 'abbreviation'>
  )>, department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name'>
  )>, vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & VehicleListFieldsFragment
  )> }
);

export type AddTicketNoteMutationVariables = Exact<{
  note: TicketNote_Insert_Input;
}>;


export type AddTicketNoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_ticket_note?: Maybe<(
    { __typename?: 'TicketNote' }
    & Pick<TicketNote, 'id'>
  )> }
);

export type AssignTicketMutationVariables = Exact<{
  ticketId: Scalars['uuid'];
  assigneeId: Scalars['uuid'];
}>;


export type AssignTicketMutation = (
  { __typename?: 'mutation_root' }
  & { update_ticket?: Maybe<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'id' | 'assignee_id'>
  )> }
);

export type DeleteTicketNoteMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteTicketNoteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_ticket_note?: Maybe<(
    { __typename?: 'TicketNote' }
    & Pick<TicketNote, 'id'>
  )> }
);

export type MarkTicketAsDoneMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type MarkTicketAsDoneMutation = (
  { __typename?: 'mutation_root' }
  & { update_ticket?: Maybe<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'id'>
  )> }
);

export type UpdateTicketMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<Ticket_Set_Input>;
}>;


export type UpdateTicketMutation = (
  { __typename?: 'mutation_root' }
  & { update_ticket?: Maybe<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'id'>
  )> }
);

export type TicketDetailsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TicketDetailsSubscription = (
  { __typename?: 'subscription_root' }
  & { ticket?: Maybe<(
    { __typename?: 'Ticket' }
    & TicketDetailsFieldsFragment
  )> }
);

export type TicketNotesSubscriptionVariables = Exact<{
  ticketId: Scalars['uuid'];
}>;


export type TicketNotesSubscription = (
  { __typename?: 'subscription_root' }
  & { ticket_notes: Array<(
    { __typename?: 'TicketNote' }
    & Pick<TicketNote, 'id' | 'created_at' | 'updated_at' | 'note' | 'user_id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'full_name' | 'abbreviation' | 'email'>
    ) }
  )> }
);

export type TicketsListSubscriptionVariables = Exact<{
  where?: Maybe<Ticket_Bool_Exp>;
  order_by?: Maybe<Array<Ticket_Order_By>>;
}>;


export type TicketsListSubscription = (
  { __typename?: 'subscription_root' }
  & { tickets: Array<(
    { __typename?: 'Ticket' }
    & TicketsListFieldsFragment
  )> }
);

export type VehicleDetailFieldsFragment = (
  { __typename?: 'Vehicle' }
  & Pick<Vehicle, 'id' | 'version' | 'max_version' | 'min_non_imported_business_case' | 'business_case_number' | 'vin' | 'model_name' | 'state' | 'order_state' | 'type' | 'min_holding_period' | 'in_buy_channel' | 'customer' | 'customer_type' | 'sold_at' | 'sold_by_id' | 'purchased_by_id' | 'in_buy_contract_date' | 'co2_nefz' | 'co2_wltp' | 'keys_count' | 'reserved_by_id' | 'reserved_at' | 'reserved_until' | 'reserved_for' | 'reserved_for_customer_type' | 'invoice_date' | 'handover_date' | 'handover_planned_date' | 'handover_by_id' | 'days_since_purchase' | 'key_kabinet_number' | 'registration_owner' | 'registration_state' | 'mileage' | 'mileage_date' | 'color_exterior_code' | 'color_exterior_name' | 'color_interior_code' | 'color_interior_name' | 'first_registration_date' | 'warranty_start' | 'license_plate_number' | 'storage_location_id' | 'storage_location_note' | 'specs' | 'spec_level' | 'order_code' | 'control_number' | 'accessories_mounted' | 'job_number' | 'sync_error' | 'dms_assigned_location_id' | 'seller_location_id' | 'delivery_location_id' | 'delivery_date_str' | 'advertising_price' | 'cash_price' | 'list_price'>
  & { sold_by?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'full_name' | 'email'>
  )>, purchased_by?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'full_name' | 'email'>
  )>, reserved_by?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'full_name' | 'email'>
  )>, handover_by?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'full_name' | 'email'>
  )>, dms_assigned_location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )>, seller_location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )>, storage_location?: Maybe<(
    { __typename?: 'StorageLocation' }
    & Pick<StorageLocation, 'id' | 'name'>
  )>, delivery_location?: Maybe<(
    { __typename?: 'StorageLocation' }
    & Pick<StorageLocation, 'id' | 'name'>
  )>, brand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  ), model: (
    { __typename?: 'Model' }
    & Pick<Model, 'id' | 'model_code' | 'sales_model_code' | 'model_group_id' | 'model_group_release_year'>
    & { modelGroup?: Maybe<(
      { __typename?: 'ModelGroup' }
      & Pick<ModelGroup, 'id' | 'name'>
    )> }
  ), sync_errors: Array<(
    { __typename?: 'sync_errors' }
    & Pick<Sync_Errors, 'id' | 'message' | 'cause' | 'first_occured' | 'latest_ocurrence' | 'transaction_id'>
  )>, boni: Array<(
    { __typename?: 'vehicle_boni' }
    & Pick<Vehicle_Boni, 'id' | 'value' | 'type'>
  )>, history?: Maybe<Array<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'version' | 'business_case_number'>
    & { holder_location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )>, holder_customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name'>
    )>, holder_supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name'>
    )>, owner_location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )>, owner_customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name'>
    )>, owner_supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name'>
    )>, generated_by: Array<(
      { __typename?: 'transactions' }
      & Pick<Transactions, 'id' | 'cancelled_date' | 'invoice_date' | 'invoice_number' | 'cancelled_transaction_id'>
      & { valid_transaction?: Maybe<(
        { __typename?: 'transactions' }
        & Pick<Transactions, 'id' | 'invoice_number' | 'invoice_date'>
      )> }
    )> }
  )>> }
);

export type VehicleListFieldsFragment = (
  { __typename?: 'Vehicle' }
  & Pick<Vehicle, 'id' | 'version' | 'business_case_number' | 'vin' | 'model_name' | 'state' | 'order_state' | 'type' | 'min_holding_period' | 'customer' | 'customer_type' | 'sold_at' | 'reserved_by_id' | 'reserved_for' | 'reserved_for_customer_type' | 'sold_by_id' | 'in_buy_contract_date' | 'days_since_purchase' | 'co2_nefz' | 'co2_wltp' | 'keys_count' | 'key_kabinet_number' | 'registration_owner' | 'registration_state' | 'delivery_date_str' | 'handover_date' | 'handover_planned_date' | 'handover_by_id' | 'delivery_location_id' | 'storage_location_id' | 'color_exterior_code' | 'color_exterior_name' | 'color_interior_code' | 'color_interior_name' | 'first_registration_date' | 'warranty_start' | 'mileage' | 'license_plate_number' | 'spec_level' | 'job_number' | 'in_buy_channel' | 'marked_for_delete' | 'sync_error' | 'owner_location_id' | 'owner_customer_id' | 'owner_supplier_id' | 'previous_owner_location_id' | 'previous_owner_customer_id' | 'previous_owner_supplier_id' | 'brand_id' | 'dms_assigned_location_id' | 'seller_location_id' | 'list_price' | 'cash_price'>
  & { sold_by?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'abbreviation' | 'full_name' | 'email'>
  )>, purchased_by?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'abbreviation' | 'full_name' | 'email'>
  )>, delivery_location?: Maybe<(
    { __typename?: 'StorageLocation' }
    & Pick<StorageLocation, 'id' | 'name'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'short_name'>
    )> }
  )>, owner_location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'short_name'>
  )>, owner_customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  )>, owner_supplier?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  )>, previous_location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'short_name'>
  )>, previous_customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  )>, previous_supplier?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  )>, storage_location?: Maybe<(
    { __typename?: 'StorageLocation' }
    & Pick<StorageLocation, 'id' | 'name'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'short_name'>
    )> }
  )>, brand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  ), model: (
    { __typename?: 'Model' }
    & Pick<Model, 'id' | 'model_group_release_year' | 'sales_model_code'>
    & { modelGroup?: Maybe<(
      { __typename?: 'ModelGroup' }
      & Pick<ModelGroup, 'id' | 'name'>
    )> }
  ), boni: Array<(
    { __typename?: 'vehicle_boni' }
    & Pick<Vehicle_Boni, 'id' | 'type' | 'value'>
  )> }
);

export type VehicleNoteFieldsFragment = (
  { __typename?: 'Notes' }
  & Pick<Notes, 'id' | 'note' | 'archived' | 'sticky' | 'created_at' | 'priority'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'full_name' | 'abbreviation' | 'email'>
  )> }
);

export type AddVehicleNoteMutationVariables = Exact<{
  note: Notes_Insert_Input;
}>;


export type AddVehicleNoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_note?: Maybe<(
    { __typename?: 'Notes' }
    & VehicleNoteFieldsFragment
  )> }
);

export type ArchiveVehicleNoteMutationVariables = Exact<{
  noteId: Scalars['uuid'];
}>;


export type ArchiveVehicleNoteMutation = (
  { __typename?: 'mutation_root' }
  & { update_note?: Maybe<(
    { __typename?: 'Notes' }
    & VehicleNoteFieldsFragment
  )> }
);

export type DeleteVehicleNoteMutationVariables = Exact<{
  noteId: Scalars['uuid'];
}>;


export type DeleteVehicleNoteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_note?: Maybe<(
    { __typename?: 'Notes' }
    & Pick<Notes, 'id'>
  )> }
);

export type ImportBusinessCasesMutationVariables = Exact<{
  ids: Array<Scalars['uuid']>;
  imported: Scalars['timestamptz'];
  importType: Import_Type_Enum;
}>;


export type ImportBusinessCasesMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicles?: Maybe<(
    { __typename?: 'Vehicle_mutation_response' }
    & Pick<Vehicle_Mutation_Response, 'affected_rows'>
  )> }
);

export type InsertPricesAndBoniMutationVariables = Exact<{
  id: Scalars['uuid'];
  business_case_number: Scalars['Int'];
  list_price?: Maybe<Scalars['numeric']>;
  cash_price?: Maybe<Scalars['numeric']>;
  boni: Array<Vehicle_Boni_Insert_Input>;
}>;


export type InsertPricesAndBoniMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'version' | 'business_case_number' | 'list_price' | 'cash_price'>
  )>, insert_vehicle_boni?: Maybe<(
    { __typename?: 'vehicle_boni_mutation_response' }
    & Pick<Vehicle_Boni_Mutation_Response, 'affected_rows'>
  )> }
);

export type SetVehicleNoteStickyStateMutationVariables = Exact<{
  noteId: Scalars['uuid'];
  sticky: Scalars['Boolean'];
}>;


export type SetVehicleNoteStickyStateMutation = (
  { __typename?: 'mutation_root' }
  & { update_note?: Maybe<(
    { __typename?: 'Notes' }
    & VehicleNoteFieldsFragment
  )> }
);

export type UnarchiveVehicleNoteMutationVariables = Exact<{
  noteId: Scalars['uuid'];
}>;


export type UnarchiveVehicleNoteMutation = (
  { __typename?: 'mutation_root' }
  & { update_note?: Maybe<(
    { __typename?: 'Notes' }
    & VehicleNoteFieldsFragment
  )> }
);

export type UpdateManyVehiclesMutationVariables = Exact<{
  updates: Array<Vehicle_Updates>;
}>;


export type UpdateManyVehiclesMutation = (
  { __typename?: 'mutation_root' }
  & { update_Vehicle_many?: Maybe<Array<Maybe<(
    { __typename?: 'Vehicle_mutation_response' }
    & Pick<Vehicle_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'Vehicle' }
      & VehicleDetailFieldsFragment
    )> }
  )>>> }
);

export type UpdateVehicleMutationVariables = Exact<{
  id: Scalars['uuid'];
  business_case_number: Scalars['Int'];
  input: Vehicle_Set_Input;
}>;


export type UpdateVehicleMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & VehicleDetailFieldsFragment
  )> }
);

export type UpdateVehiclesMutationVariables = Exact<{
  set: Vehicle_Set_Input;
  where: Vehicle_Bool_Exp;
}>;


export type UpdateVehiclesMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicles?: Maybe<(
    { __typename?: 'Vehicle_mutation_response' }
    & Pick<Vehicle_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'Vehicle' }
      & VehicleDetailFieldsFragment
    )> }
  )> }
);

export type VehiclesMetafieldsAllBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type VehiclesMetafieldsAllBrandsQuery = (
  { __typename?: 'query_root' }
  & { brand_vehicle_metafield: Array<(
    { __typename?: 'brand_vehicle_metafield' }
    & Pick<Brand_Vehicle_Metafield, 'brand_id' | 'required_for_import' | 'default_value'>
    & { vehicle_metafield: (
      { __typename?: 'vehicle_metafields' }
      & Pick<Vehicle_Metafields, 'column_name' | 'title'>
    ) }
  )> }
);

export type VehicleMetafieldsQueryVariables = Exact<{
  brandId: Scalars['uuid'];
}>;


export type VehicleMetafieldsQuery = (
  { __typename?: 'query_root' }
  & { brand_vehicle_metafield: Array<(
    { __typename?: 'brand_vehicle_metafield' }
    & Pick<Brand_Vehicle_Metafield, 'brand_id' | 'required_for_import' | 'default_value'>
    & { vehicle_metafield: (
      { __typename?: 'vehicle_metafields' }
      & Pick<Vehicle_Metafields, 'column_name' | 'title'>
    ) }
  )> }
);

export type VehiclesCountQueryVariables = Exact<{
  where?: Maybe<Vehicle_Bool_Exp>;
}>;


export type VehiclesCountQuery = (
  { __typename?: 'query_root' }
  & { vehicles_aggregate: (
    { __typename?: 'Vehicle_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Vehicle_aggregate_fields' }
      & Pick<Vehicle_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type VehiclesErrorAndImportStateQueryVariables = Exact<{
  where: Vehicle_Bool_Exp;
}>;


export type VehiclesErrorAndImportStateQuery = (
  { __typename?: 'query_root' }
  & { vehicles: Array<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'business_case_number' | 'version' | 'sync_error' | 'state' | 'order_state' | 'type' | 'delivery_location_id' | 'delivery_date_str' | 'storage_location_id' | 'registration_state' | 'registration_owner' | 'brand_id' | 'color_exterior_name' | 'color_exterior_code' | 'color_interior_name' | 'color_interior_code' | 'sold_at' | 'sold_by_id' | 'in_buy_channel'>
    & { model: (
      { __typename?: 'Model' }
      & Pick<Model, 'sales_model_code' | 'model_group_release_year'>
      & { modelGroup?: Maybe<(
        { __typename?: 'ModelGroup' }
        & Pick<ModelGroup, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type VehiclesErrorStateQueryVariables = Exact<{
  where: Vehicle_Bool_Exp;
}>;


export type VehiclesErrorStateQuery = (
  { __typename?: 'query_root' }
  & { vehicles: Array<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'business_case_number' | 'version' | 'sync_error' | 'brand_id'>
  )> }
);

export type VehiclesExportQueryVariables = Exact<{
  where: ExportVehiclesVehicleBoolExp;
  variant: ExportDealerVehiclesVariant;
}>;


export type VehiclesExportQuery = (
  { __typename?: 'query_root' }
  & { exportVehicles?: Maybe<(
    { __typename?: 'ExportVehiclesOutput' }
    & Pick<ExportVehiclesOutput, 'download_url'>
  )> }
);

export type VehiclesListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Vehicle_Bool_Exp>;
  with_sync_errors: Scalars['Boolean'];
  order_by?: Maybe<Array<Vehicle_Order_By>>;
}>;


export type VehiclesListQuery = (
  { __typename?: 'query_root' }
  & { vehicles: Array<(
    { __typename?: 'Vehicle' }
    & { sync_errors: Array<(
      { __typename?: 'sync_errors' }
      & Pick<Sync_Errors, 'id' | 'message' | 'resolved'>
    )> }
    & VehicleListFieldsFragment
  )> }
);

export type ChangedVehiclesListSubscriptionVariables = Exact<{
  updated_at: Scalars['timestamptz'];
  with_sync_errors: Scalars['Boolean'];
  batch_size: Scalars['Int'];
  where?: Maybe<Vehicle_Bool_Exp>;
}>;


export type ChangedVehiclesListSubscription = (
  { __typename?: 'subscription_root' }
  & { Vehicle_stream: Array<(
    { __typename?: 'Vehicle' }
    & { sync_errors: Array<(
      { __typename?: 'sync_errors' }
      & Pick<Sync_Errors, 'id' | 'message' | 'resolved'>
    )> }
    & VehicleListFieldsFragment
  )> }
);

export type VehicleDetailsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  business_case_number: Scalars['Int'];
}>;


export type VehicleDetailsSubscription = (
  { __typename?: 'subscription_root' }
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & VehicleDetailFieldsFragment
  )> }
);

export type VehicleNotesSubscriptionVariables = Exact<{
  vehicleId: Scalars['uuid'];
  businessCaseNumber: Scalars['Int'];
}>;


export type VehicleNotesSubscription = (
  { __typename?: 'subscription_root' }
  & { notes: Array<(
    { __typename?: 'Notes' }
    & VehicleNoteFieldsFragment
  )> }
);

export type VehiclesSubscriptionVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Vehicle_Bool_Exp>;
  with_sync_errors: Scalars['Boolean'];
  order_by?: Maybe<Array<Vehicle_Order_By>>;
}>;


export type VehiclesSubscription = (
  { __typename?: 'subscription_root' }
  & { vehicles: Array<(
    { __typename?: 'Vehicle' }
    & { sync_errors: Array<(
      { __typename?: 'sync_errors' }
      & Pick<Sync_Errors, 'id' | 'message' | 'resolved'>
    )> }
    & VehicleListFieldsFragment
  )> }
);

export type InsertSalesReportMutationVariables = Exact<{
  object: Sales_Reports_Insert_Input;
}>;


export type InsertSalesReportMutation = (
  { __typename?: 'mutation_root' }
  & { insert_sales_reports_one?: Maybe<(
    { __typename?: 'sales_reports' }
    & Pick<Sales_Reports, 'id'>
  )> }
);

export type SalesReportDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SalesReportDetailQuery = (
  { __typename?: 'query_root' }
  & { sales_reports_by_pk?: Maybe<(
    { __typename?: 'sales_reports' }
    & SalesReportsFieldsFragment
  )> }
);

export type StorageLocationSelectDataQueryVariables = Exact<{ [key: string]: never; }>;


export type StorageLocationSelectDataQuery = (
  { __typename?: 'query_root' }
  & { storage_locations: Array<(
    { __typename?: 'StorageLocation' }
    & StorageLocationSelectFieldsFragment
  )>, locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )>, subdealers: Array<(
    { __typename?: 'Subdealer' }
    & Pick<Subdealer, 'id' | 'name'>
  )> }
);

export type CreateStorageLocationMutationVariables = Exact<{
  input: StorageLocation_Insert_Input;
}>;


export type CreateStorageLocationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_storage_location?: Maybe<(
    { __typename?: 'StorageLocation' }
    & StorageLocationSelectFieldsFragment
  )> }
);

export type StorageLocationSelectFieldsFragment = (
  { __typename?: 'StorageLocation' }
  & Pick<StorageLocation, 'id' | 'name' | 'default_note' | 'location_id' | 'subdealer_id'>
);

export type UserSelectDataQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSelectDataQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'User' }
    & UserSelectFieldsFragment
  )> }
);

export type UserSelectFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'full_name' | 'email'>
);

export type BrandBoniTypesQueryVariables = Exact<{
  brand_id: Scalars['uuid'];
}>;


export type BrandBoniTypesQuery = (
  { __typename?: 'query_root' }
  & { brand_boni_types: Array<(
    { __typename?: 'brand_boni_types' }
    & Pick<Brand_Boni_Types, 'type'>
  )> }
);

export type ResolveVehicleSyncErrorMutationVariables = Exact<{
  vehicleId: Scalars['String'];
  transactionId: Scalars['String'];
}>;


export type ResolveVehicleSyncErrorMutation = (
  { __typename?: 'mutation_root' }
  & { resolveVehicleSyncError: (
    { __typename?: 'ResolveVehicleSyncErrorPayload' }
    & Pick<ResolveVehicleSyncErrorPayload, 'status' | 'errorMessage'>
  ) }
);

export type DropVehicleReservationMutationVariables = Exact<{
  id: Scalars['uuid'];
  business_case_number: Scalars['Int'];
}>;


export type DropVehicleReservationMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'version' | 'business_case_number'>
  )> }
);

export type ManualTicketTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ManualTicketTemplatesQuery = (
  { __typename?: 'query_root' }
  & { ticket_templates: Array<(
    { __typename?: 'TicketTemplate' }
    & ManualTicketTemplateListFieldsFragment
  )> }
);

export type ManualTicketTemplateListFieldsFragment = (
  { __typename?: 'TicketTemplate' }
  & Pick<TicketTemplate, 'id' | 'ticket_title' | 'ticket_description' | 'priority' | 'assign_to' | 'location' | 'department_id'>
);

export type CreateTicketFromTemplateMutationVariables = Exact<{
  input: CreateTicketFromTemplateInput;
}>;


export type CreateTicketFromTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { createTicketFromTemplate: (
    { __typename?: 'CreateTicketFromTemplatePayload' }
    & Pick<CreateTicketFromTemplatePayload, 'ticketId'>
  ) }
);

export type BrandFieldsFragment = (
  { __typename?: 'Brand' }
  & Pick<Brand, 'id' | 'name' | 'is_house_brand'>
);

export type BrandListFieldsFragment = (
  { __typename?: 'Brand' }
  & Pick<Brand, 'id' | 'name'>
);

export type BrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandsQuery = (
  { __typename?: 'query_root' }
  & { brands: Array<(
    { __typename?: 'Brand' }
    & BrandFieldsFragment
  )> }
);

export type LocationFieldsFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name'>
);

export type LocationGroupFieldsFragment = (
  { __typename?: 'LocationGroup' }
  & Pick<LocationGroup, 'id' | 'name'>
  & { brands: Array<(
    { __typename?: 'location_group_brands_view' }
    & Pick<Location_Group_Brands_View, 'id' | 'name'>
  )> }
);

export type LocationWithGroupFieldsFragment = (
  { __typename?: 'Location' }
  & { locationGroups: Array<(
    { __typename?: 'LocationGroup' }
    & LocationGroupFieldsFragment
  )> }
  & LocationFieldsFragment
);

export type LocationWithGroupsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type LocationWithGroupsQuery = (
  { __typename?: 'query_root' }
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & LocationWithGroupFieldsFragment
  )> }
);

export type LocationsListQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationsListQuery = (
  { __typename?: 'query_root' }
  & { locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )> }
);

export type LocationsWithGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationsWithGroupsQuery = (
  { __typename?: 'query_root' }
  & { locations: Array<(
    { __typename?: 'Location' }
    & LocationWithGroupFieldsFragment
  )> }
);

export type AssetsLinksListSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AssetsLinksListSubscription = (
  { __typename?: 'subscription_root' }
  & { asset_watchdog_links: Array<(
    { __typename?: 'asset_watchdog_links' }
    & Pick<Asset_Watchdog_Links, 'id' | 'name' | 'source_url' | 'url' | 'status'>
  )> }
);

export type TicketTemplateListFieldsFragment = (
  { __typename?: 'TicketTemplate' }
  & Pick<TicketTemplate, 'id' | 'priority' | 'automated_task' | 'template_title' | 'ticket_title' | 'ticket_description' | 'assign_to' | 'location' | 'trigger_filter' | 'department_id'>
  & { department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name'>
  )> }
);

export type TicketTemplatesListSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TicketTemplatesListSubscription = (
  { __typename?: 'subscription_root' }
  & { ticket_templates: Array<(
    { __typename?: 'TicketTemplate' }
    & TicketTemplateListFieldsFragment
  )> }
);

export type EmbeddedPagesListSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type EmbeddedPagesListSubscription = (
  { __typename?: 'subscription_root' }
  & { embedded_pages: Array<(
    { __typename?: 'embedded_pages' }
    & Pick<Embedded_Pages, 'id' | 'name' | 'url' | 'brand_id'>
    & { brand?: Maybe<(
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'name'>
    )> }
  )> }
);

export type VehicleImportJobsListSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type VehicleImportJobsListSubscription = (
  { __typename?: 'subscription_root' }
  & { import_jobs: Array<(
    { __typename?: 'import_jobs' }
    & Pick<Import_Jobs, 'id' | 'created_at' | 'status' | 'upload_file_name' | 'matching_field' | 'upload_file_excel_definition'>
  )> }
);

export type DeleteImportJobMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteImportJobMutation = (
  { __typename?: 'mutation_root' }
  & { delete_import_jobs_by_pk?: Maybe<(
    { __typename?: 'import_jobs' }
    & Pick<Import_Jobs, 'id'>
  )> }
);

export type LocosoftSyncStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type LocosoftSyncStatesQuery = (
  { __typename?: 'query_root' }
  & { locosoft_sync_states?: Maybe<Array<(
    { __typename?: 'LocosoftSyncStatesPayload' }
    & Pick<LocosoftSyncStatesPayload, 'name' | 'status' | 'syncEntity' | 'phases'>
  )>> }
);

export type CloudwatchLogsQueryVariables = Exact<{
  input: CloudwatchLogsInput;
}>;


export type CloudwatchLogsQuery = (
  { __typename?: 'query_root' }
  & { cloudwatch_logs: (
    { __typename?: 'CloudwatchLogsPayload' }
    & Pick<CloudwatchLogsPayload, 'recordsMatched'>
    & { results: Array<(
      { __typename?: 'CloudwatchLogs' }
      & Pick<CloudwatchLogs, 'message' | 'timestamp'>
    )> }
  ) }
);

export type CloudwatchMetricDataQueryVariables = Exact<{
  input: MetricDataInput;
}>;


export type CloudwatchMetricDataQuery = (
  { __typename?: 'query_root' }
  & { metric_data: (
    { __typename?: 'MetricDataPayload' }
    & Pick<MetricDataPayload, 'x' | 'y' | 'label'>
  ) }
);

export type AdminSubDealerListQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminSubDealerListQuery = (
  { __typename?: 'query_root' }
  & { subdealers: Array<(
    { __typename?: 'Subdealer' }
    & Pick<Subdealer, 'id' | 'name'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'fullAddress'>
    ), brands: Array<(
      { __typename?: 'subdealer_brands_view' }
      & Pick<Subdealer_Brands_View, 'id' | 'name'>
    )> }
  )> }
);

export type AdminSupplierListQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminSupplierListQuery = (
  { __typename?: 'query_root' }
  & { suppliers: Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'fullAddress'>
    ) }
  )> }
);

export type AdminTaskTypeFieldsFragment = (
  { __typename?: 'TaskType' }
  & Pick<TaskType, 'code' | 'group' | 'description'>
  & { categoryReferences: Array<(
    { __typename?: 'task_type_task_category' }
    & Pick<Task_Type_Task_Category, 'task_category_value'>
  )> }
);

export type TaskTypesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<TaskType_Bool_Exp>;
}>;


export type TaskTypesQuery = (
  { __typename?: 'query_root' }
  & { task_types: Array<(
    { __typename?: 'TaskType' }
    & AdminTaskTypeFieldsFragment
  )>, task_types_aggregate: (
    { __typename?: 'TaskType_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'TaskType_aggregate_fields' }
      & Pick<TaskType_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type AddTaskTypeCategoryMutationVariables = Exact<{
  taskTypeCategoryRefObj: Array<Task_Type_Task_Category_Insert_Input>;
}>;


export type AddTaskTypeCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { insert_task_type_task_category?: Maybe<(
    { __typename?: 'task_type_task_category_mutation_response' }
    & Pick<Task_Type_Task_Category_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'task_type_task_category' }
      & { task_type: (
        { __typename?: 'TaskType' }
        & AdminTaskTypeFieldsFragment
      ) }
    )> }
  )> }
);

export type RemoveTaskTypeCategoryMutationVariables = Exact<{
  category: Task_Category_Enum;
  codes: Array<Scalars['String']>;
}>;


export type RemoveTaskTypeCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { delete_task_type_task_category?: Maybe<(
    { __typename?: 'task_type_task_category_mutation_response' }
    & Pick<Task_Type_Task_Category_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'task_type_task_category' }
      & { task_type: (
        { __typename?: 'TaskType' }
        & AdminTaskTypeFieldsFragment
      ) }
    )> }
  )> }
);

export type UsersListSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UsersListSubscription = (
  { __typename?: 'subscription_root' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'full_name' | 'email' | 'isActive' | 'primary_role' | 'abbreviation' | 'last_login'>
  )> }
);

export type AfterSalesPotentialListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where: AfterSalesPotential_Bool_Exp;
}>;


export type AfterSalesPotentialListQuery = (
  { __typename?: 'query_root' }
  & { after_sales_potentials: Array<(
    { __typename?: 'AfterSalesPotential' }
    & AfterSalesPotentialsListFieldsFragment
  )>, after_sales_potentials_aggregate: (
    { __typename?: 'AfterSalesPotential_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'AfterSalesPotential_aggregate_fields' }
      & Pick<AfterSalesPotential_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type AfterSalesPotentialLetterListDownloadQueryVariables = Exact<{
  input: ExportAfterSalesPotentialsInput;
}>;


export type AfterSalesPotentialLetterListDownloadQuery = (
  { __typename?: 'query_root' }
  & { exportAfterSalesPotentials?: Maybe<(
    { __typename?: 'ExportAfterSalesPotentialsOutput' }
    & Pick<ExportAfterSalesPotentialsOutput, 'download_url'>
  )> }
);

export type AfterSalesPotentialsListFieldsFragment = (
  { __typename?: 'AfterSalesPotential' }
  & Pick<AfterSalesPotential, 'id' | 'year' | 'month' | 'status' | 'last_note' | 'license_plate_number' | 'mileage' | 'mileage_date' | 'first_registration_date' | 'warranty_end' | 'warranty_start' | 'customer_name' | 'customer_city' | 'customer_zip_code' | 'last_service_date' | 'last_inspection_sticker_date'>
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'version' | 'business_case_number' | 'vin' | 'model_name'>
    & { brand: (
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'name'>
    ) }
  )>, customer_vehicle?: Maybe<(
    { __typename?: 'CustomerVehicle' }
    & Pick<CustomerVehicle, 'id' | 'vin' | 'model_description'>
    & { brand: (
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'name'>
    ), model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'description'>
    )> }
  )>, customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'customer_no'>
  ) }
);

export type SetAfterSalesPotentialStatusMutationVariables = Exact<{
  ids: Array<Scalars['uuid']>;
  status: After_Sales_Status_Enum;
}>;


export type SetAfterSalesPotentialStatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_after_sales_potentials?: Maybe<(
    { __typename?: 'AfterSalesPotential_mutation_response' }
    & Pick<AfterSalesPotential_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'AfterSalesPotential' }
      & Pick<AfterSalesPotential, 'id' | 'status'>
    )> }
  )> }
);

export type EmbeddedPagesQueryVariables = Exact<{ [key: string]: never; }>;


export type EmbeddedPagesQuery = (
  { __typename?: 'query_root' }
  & { embedded_pages: Array<(
    { __typename?: 'embedded_pages' }
    & Pick<Embedded_Pages, 'id' | 'brand_id' | 'name' | 'url'>
  )> }
);

export type SalesReportsListSubscriptionVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<Sales_Reports_Bool_Exp>;
}>;


export type SalesReportsListSubscription = (
  { __typename?: 'subscription_root' }
  & { sales_reports: Array<(
    { __typename?: 'sales_reports' }
    & SalesReportsFieldsFragment
  )> }
);

export type SalesReportsFieldsFragment = (
  { __typename?: 'sales_reports' }
  & Pick<Sales_Reports, 'id' | 'created_at' | 'updated_at' | 'seller_id' | 'date' | 'customer_type' | 'customer_name' | 'customer_number' | 'zip_code' | 'sales_type' | 'list_price' | 'selling_price' | 'price_discussed_with' | 'vehicle_type' | 'job_number' | 'vin' | 'brand_id' | 'model_name' | 'handover_planned_date' | 'exchange_vin' | 'exchange_brand_id' | 'exchange_model_name' | 'exchange_price' | 'expected_sales_price' | 'financing_bank_name' | 'financing_product' | 'financing_type' | 'aid' | 'insurance_product_name' | 'insurance_provider_name' | 'warranty_insurance_years' | 'winter_tires' | 'winter_tires_price' | 'extras'>
  & { brand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  ), exchange_brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  )>, seller: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'abbreviation' | 'email' | 'full_name'>
  ) }
);

export const AssetLinkDetailFieldsFragmentDoc = gql`
    fragment AssetLinkDetailFields on asset_watchdog_links {
  id
  name
  source_url
  url
  status
}
    `;
export const AdminModelListFragmentFragmentDoc = gql`
    fragment AdminModelListFragment on Model {
  id
  description
  model_code
  sales_model_code
  modelGroup {
    id
    name
  }
  model_group_release_year
}
    `;
export const BrandDetailsFieldsFragmentDoc = gql`
    fragment BrandDetailsFields on Brand {
  id
  name
  salesSuppliers {
    id
    name
  }
  afterSalesSuppliers {
    id
    name
  }
  vehicle_metafields {
    vehicle_metafield {
      title
      column_name
    }
    required_for_import
    default_value
  }
}
    `;
export const AdminModelGroupFieldsFragmentDoc = gql`
    fragment AdminModelGroupFields on ModelGroup {
  id
  name
  releaseYears {
    release_year
  }
  default_release_year
}
    `;
export const LocationGroupFieldsFragmentDoc = gql`
    fragment LocationGroupFields on LocationGroup {
  id
  name
  brands {
    id
    name
  }
}
    `;
export const AdminLocationDetailFieldsFragmentDoc = gql`
    fragment AdminLocationDetailFields on Location {
  id
  name
  short_name
  brandReferences {
    brand {
      id
      name
    }
    after_sales
    sales
    isPrimaryDealer
  }
  locationGroups {
    ...LocationGroupFields
  }
  dmsReferences {
    id
    name
    type
    reference
  }
  supplierDmsReferences {
    id
    name
    type
    reference
  }
  customerDmsReferences {
    id
    name
    type
    reference
  }
}
    ${LocationGroupFieldsFragmentDoc}`;
export const SubdealerDetailFieldsFragmentDoc = gql`
    fragment SubdealerDetailFields on Subdealer {
  id
  name
  address {
    id
    street
    streetNo
    city
    zipCode
    countryCode
    fullAddress
  }
  customerDmsReferences {
    id
    name
    type
    reference
  }
  brands {
    id
    name
  }
}
    `;
export const SupplierDetailFieldsFragmentDoc = gql`
    fragment SupplierDetailFields on Supplier {
  id
  name
  address {
    id
    street
    streetNo
    city
    zipCode
    countryCode
    fullAddress
  }
  active
  dmsReferences {
    id
    name
    type
    reference
  }
  customerDmsReferences {
    id
    name
    type
    reference
  }
}
    `;
export const TicketTemplateDetailFieldsFragmentDoc = gql`
    fragment TicketTemplateDetailFields on TicketTemplate {
  id
  priority
  template_title
  ticket_title
  ticket_description
  assign_to
  location
  department_id
  department {
    id
    description
    name
  }
  automated_task
  trigger_filter
}
    `;
export const TeamAssignmentFragmentFragmentDoc = gql`
    fragment TeamAssignmentFragment on TeamAssignment {
  id
  user_id
  department_id
  department {
    id
    name
  }
  location_id
  location {
    id
    short_name
    name
  }
  brand_id
  brand {
    id
    name
  }
  in_buy_channel
  is_primary
  role
}
    `;
export const TicketTemplateAssignToSelectFieldsFragmentDoc = gql`
    fragment TicketTemplateAssignToSelectFields on ticket_template_assignee_ref {
  name
  code_reference_field
}
    `;
export const TicketTemplateLocationSelectFieldsFragmentDoc = gql`
    fragment TicketTemplateLocationSelectFields on ticket_template_location_ref {
  name
  code_reference_field
}
    `;
export const DepartmentFieldsFragmentDoc = gql`
    fragment DepartmentFields on Department {
  id
  name
  description
  roles {
    role
    department_id
  }
}
    `;
export const TicketDetailsFieldsFragmentDoc = gql`
    fragment TicketDetailsFields on Ticket {
  id
  created_at
  updated_at
  state
  priority
  title
  description
  due_to
  created_by_id
  created_by {
    id
    full_name
    email
    abbreviation
  }
  location_id
  location {
    id
    short_name
    name
  }
  notes {
    id
    created_at
    updated_at
    note
    user {
      id
      full_name
      email
    }
  }
  department_id
  department {
    id
    name
    description
  }
  template_id
  assignee_id
  assignee {
    id
    full_name
    email
    abbreviation
  }
  vehicle_id
  vehicle_version
  vehicle_business_case_number
  vehicle {
    id
    version
    business_case_number
    vin
    license_plate_number
    job_number
  }
}
    `;
export const VehicleListFieldsFragmentDoc = gql`
    fragment VehicleListFields on Vehicle {
  id
  version
  business_case_number
  vin
  model_name
  state
  order_state
  type
  min_holding_period
  customer
  customer_type
  sold_at
  reserved_by_id
  reserved_for
  reserved_for_customer_type
  sold_by_id
  sold_by {
    id
    abbreviation
    full_name
    email
  }
  purchased_by {
    id
    abbreviation
    full_name
    email
  }
  in_buy_contract_date
  days_since_purchase
  co2_nefz
  co2_wltp
  keys_count
  key_kabinet_number
  registration_owner
  registration_state
  delivery_date_str
  handover_date
  handover_planned_date
  handover_by_id
  delivery_location_id
  delivery_location {
    id
    name
    location {
      short_name
    }
  }
  storage_location_id
  color_exterior_code
  color_exterior_name
  color_interior_code
  color_interior_name
  first_registration_date
  warranty_start
  mileage
  license_plate_number
  spec_level
  job_number
  in_buy_channel
  marked_for_delete
  sync_error
  owner_location_id
  owner_customer_id
  owner_supplier_id
  previous_owner_location_id
  previous_owner_customer_id
  previous_owner_supplier_id
  owner_location {
    id
    name
    short_name
  }
  owner_customer {
    id
    name
  }
  owner_supplier {
    id
    name
  }
  previous_location {
    id
    name
    short_name
  }
  previous_customer {
    id
    name
  }
  previous_supplier {
    id
    name
  }
  storage_location {
    id
    name
    location {
      short_name
    }
  }
  brand_id
  brand {
    id
    name
  }
  model {
    id
    modelGroup {
      id
      name
    }
    model_group_release_year
    sales_model_code
  }
  dms_assigned_location_id
  seller_location_id
  list_price
  cash_price
  boni {
    id
    type
    value
  }
}
    `;
export const TicketsListFieldsFragmentDoc = gql`
    fragment TicketsListFields on Ticket {
  id
  title
  description
  created_at
  updated_at
  state
  priority
  due_to
  location_id
  location {
    id
    short_name
    name
  }
  assignee_id
  assignee {
    id
    full_name
    email
    abbreviation
  }
  department_id
  department {
    id
    name
  }
  vehicle {
    ...VehicleListFields
  }
}
    ${VehicleListFieldsFragmentDoc}`;
export const VehicleDetailFieldsFragmentDoc = gql`
    fragment VehicleDetailFields on Vehicle {
  id
  version
  max_version
  min_non_imported_business_case
  business_case_number
  vin
  model_name
  state
  order_state
  type
  min_holding_period
  in_buy_channel
  customer
  customer_type
  sold_at
  sold_by_id
  sold_by {
    id
    full_name
    email
  }
  purchased_by_id
  purchased_by {
    id
    full_name
    email
  }
  in_buy_contract_date
  co2_nefz
  co2_wltp
  keys_count
  reserved_by_id
  reserved_by {
    id
    full_name
    email
  }
  reserved_at
  reserved_until
  reserved_for
  reserved_for_customer_type
  invoice_date
  handover_date
  handover_planned_date
  handover_by_id
  handover_by {
    id
    full_name
    email
  }
  days_since_purchase
  key_kabinet_number
  registration_owner
  registration_state
  mileage
  mileage_date
  color_exterior_code
  color_exterior_name
  color_interior_code
  color_interior_name
  first_registration_date
  warranty_start
  license_plate_number
  storage_location_id
  storage_location_note
  specs
  spec_level
  order_code
  control_number
  accessories_mounted
  job_number
  sync_error
  dms_assigned_location_id
  dms_assigned_location {
    id
    name
  }
  seller_location_id
  seller_location {
    id
    name
  }
  storage_location {
    id
    name
  }
  delivery_location_id
  delivery_date_str
  delivery_location {
    id
    name
  }
  brand {
    id
    name
  }
  model {
    id
    model_code
    sales_model_code
    model_group_id
    model_group_release_year
    modelGroup {
      id
      name
    }
  }
  sync_errors(where: {prevailing: {_eq: true}}) {
    id
    message
    cause
    first_occured
    latest_ocurrence
    transaction_id
  }
  advertising_price
  cash_price
  list_price
  boni {
    id
    value
    type
  }
  history(
    where: {_or: [{generated_by: {id: {_is_null: false}}}, {version: {_eq: 1}}]}
  ) {
    id
    version
    business_case_number
    holder_location {
      id
      name
    }
    holder_customer {
      id
      name
    }
    holder_supplier {
      id
      name
    }
    owner_location {
      id
      name
    }
    owner_customer {
      id
      name
    }
    owner_supplier {
      id
      name
    }
    generated_by {
      id
      cancelled_date
      invoice_date
      invoice_number
      cancelled_transaction_id
      valid_transaction {
        id
        invoice_number
        invoice_date
      }
    }
  }
}
    `;
export const VehicleNoteFieldsFragmentDoc = gql`
    fragment VehicleNoteFields on Notes {
  id
  note
  archived
  sticky
  user {
    id
    full_name
    abbreviation
    email
  }
  created_at
  priority
}
    `;
export const StorageLocationSelectFieldsFragmentDoc = gql`
    fragment StorageLocationSelectFields on StorageLocation {
  id
  name
  default_note
  location_id
  subdealer_id
}
    `;
export const UserSelectFieldsFragmentDoc = gql`
    fragment UserSelectFields on User {
  id
  full_name
  email
}
    `;
export const ManualTicketTemplateListFieldsFragmentDoc = gql`
    fragment ManualTicketTemplateListFields on TicketTemplate {
  id
  ticket_title
  ticket_description
  priority
  assign_to
  location
  department_id
}
    `;
export const BrandFieldsFragmentDoc = gql`
    fragment BrandFields on Brand {
  id
  name
  is_house_brand
}
    `;
export const BrandListFieldsFragmentDoc = gql`
    fragment BrandListFields on Brand {
  id
  name
}
    `;
export const LocationFieldsFragmentDoc = gql`
    fragment LocationFields on Location {
  id
  name
}
    `;
export const LocationWithGroupFieldsFragmentDoc = gql`
    fragment LocationWithGroupFields on Location {
  ...LocationFields
  locationGroups {
    ...LocationGroupFields
  }
}
    ${LocationFieldsFragmentDoc}
${LocationGroupFieldsFragmentDoc}`;
export const TicketTemplateListFieldsFragmentDoc = gql`
    fragment TicketTemplateListFields on TicketTemplate {
  id
  priority
  automated_task
  template_title
  ticket_title
  ticket_description
  assign_to
  location
  trigger_filter
  department_id
  department {
    id
    name
  }
}
    `;
export const AdminTaskTypeFieldsFragmentDoc = gql`
    fragment AdminTaskTypeFields on TaskType {
  code
  group
  description
  categoryReferences {
    task_category_value
  }
}
    `;
export const AfterSalesPotentialsListFieldsFragmentDoc = gql`
    fragment AfterSalesPotentialsListFields on AfterSalesPotential {
  id
  year
  month
  status
  vehicle {
    id
    version
    business_case_number
    vin
    brand {
      id
      name
    }
    model_name
  }
  customer_vehicle {
    id
    vin
    brand {
      id
      name
    }
    model {
      id
      description
    }
    model_description
  }
  last_note
  customer {
    id
    customer_no
  }
  license_plate_number
  mileage
  mileage_date
  first_registration_date
  warranty_end
  warranty_start
  customer_name
  customer_city
  customer_zip_code
  last_service_date
  last_inspection_sticker_date
}
    `;
export const SalesReportsFieldsFragmentDoc = gql`
    fragment SalesReportsFields on sales_reports {
  id
  created_at
  updated_at
  seller_id
  date
  customer_type
  customer_name
  customer_number
  zip_code
  sales_type
  list_price
  selling_price
  price_discussed_with
  vehicle_type
  job_number
  vin
  brand_id
  brand {
    id
    name
  }
  model_name
  handover_planned_date
  exchange_vin
  exchange_brand_id
  exchange_brand {
    id
    name
  }
  exchange_model_name
  exchange_price
  expected_sales_price
  financing_bank_name
  financing_product
  financing_type
  aid
  insurance_product_name
  insurance_provider_name
  warranty_insurance_years
  winter_tires
  winter_tires_price
  extras
  seller {
    id
    abbreviation
    email
    full_name
  }
}
    `;
export const AdminAssetLinkDetailDocument = gql`
    query AdminAssetLinkDetail($id: uuid!) {
  asset_watchdog_links_by_pk(id: $id) {
    ...AssetLinkDetailFields
  }
}
    ${AssetLinkDetailFieldsFragmentDoc}`;

/**
 * __useAdminAssetLinkDetailQuery__
 *
 * To run a query within a React component, call `useAdminAssetLinkDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAssetLinkDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAssetLinkDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminAssetLinkDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminAssetLinkDetailQuery, AdminAssetLinkDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminAssetLinkDetailQuery, AdminAssetLinkDetailQueryVariables>(AdminAssetLinkDetailDocument, baseOptions);
      }
export function useAdminAssetLinkDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminAssetLinkDetailQuery, AdminAssetLinkDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminAssetLinkDetailQuery, AdminAssetLinkDetailQueryVariables>(AdminAssetLinkDetailDocument, baseOptions);
        }
export type AdminAssetLinkDetailQueryHookResult = ReturnType<typeof useAdminAssetLinkDetailQuery>;
export type AdminAssetLinkDetailLazyQueryHookResult = ReturnType<typeof useAdminAssetLinkDetailLazyQuery>;
export type AdminAssetLinkDetailQueryResult = ApolloReactCommon.QueryResult<AdminAssetLinkDetailQuery, AdminAssetLinkDetailQueryVariables>;
export const CreateAssetLinkDocument = gql`
    mutation CreateAssetLink($object: asset_watchdog_links_insert_input!) {
  insert_asset_watchdog_links_one(object: $object) {
    ...AssetLinkDetailFields
  }
}
    ${AssetLinkDetailFieldsFragmentDoc}`;
export type CreateAssetLinkMutationFn = ApolloReactCommon.MutationFunction<CreateAssetLinkMutation, CreateAssetLinkMutationVariables>;

/**
 * __useCreateAssetLinkMutation__
 *
 * To run a mutation, you first call `useCreateAssetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetLinkMutation, { data, loading, error }] = useCreateAssetLinkMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateAssetLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAssetLinkMutation, CreateAssetLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAssetLinkMutation, CreateAssetLinkMutationVariables>(CreateAssetLinkDocument, baseOptions);
      }
export type CreateAssetLinkMutationHookResult = ReturnType<typeof useCreateAssetLinkMutation>;
export type CreateAssetLinkMutationResult = ApolloReactCommon.MutationResult<CreateAssetLinkMutation>;
export type CreateAssetLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAssetLinkMutation, CreateAssetLinkMutationVariables>;
export const UpdateAssetLinkDocument = gql`
    mutation UpdateAssetLink($assetLinkId: uuid!, $assetLink: asset_watchdog_links_set_input) {
  update_asset_watchdog_links_by_pk(
    _set: $assetLink
    pk_columns: {id: $assetLinkId}
  ) {
    ...AssetLinkDetailFields
  }
}
    ${AssetLinkDetailFieldsFragmentDoc}`;
export type UpdateAssetLinkMutationFn = ApolloReactCommon.MutationFunction<UpdateAssetLinkMutation, UpdateAssetLinkMutationVariables>;

/**
 * __useUpdateAssetLinkMutation__
 *
 * To run a mutation, you first call `useUpdateAssetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetLinkMutation, { data, loading, error }] = useUpdateAssetLinkMutation({
 *   variables: {
 *      assetLinkId: // value for 'assetLinkId'
 *      assetLink: // value for 'assetLink'
 *   },
 * });
 */
export function useUpdateAssetLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAssetLinkMutation, UpdateAssetLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAssetLinkMutation, UpdateAssetLinkMutationVariables>(UpdateAssetLinkDocument, baseOptions);
      }
export type UpdateAssetLinkMutationHookResult = ReturnType<typeof useUpdateAssetLinkMutation>;
export type UpdateAssetLinkMutationResult = ApolloReactCommon.MutationResult<UpdateAssetLinkMutation>;
export type UpdateAssetLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAssetLinkMutation, UpdateAssetLinkMutationVariables>;
export const DeleteAssetLinkDocument = gql`
    mutation DeleteAssetLink($id: uuid!) {
  delete_asset_watchdog_links_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteAssetLinkMutationFn = ApolloReactCommon.MutationFunction<DeleteAssetLinkMutation, DeleteAssetLinkMutationVariables>;

/**
 * __useDeleteAssetLinkMutation__
 *
 * To run a mutation, you first call `useDeleteAssetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetLinkMutation, { data, loading, error }] = useDeleteAssetLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssetLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAssetLinkMutation, DeleteAssetLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAssetLinkMutation, DeleteAssetLinkMutationVariables>(DeleteAssetLinkDocument, baseOptions);
      }
export type DeleteAssetLinkMutationHookResult = ReturnType<typeof useDeleteAssetLinkMutation>;
export type DeleteAssetLinkMutationResult = ApolloReactCommon.MutationResult<DeleteAssetLinkMutation>;
export type DeleteAssetLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAssetLinkMutation, DeleteAssetLinkMutationVariables>;
export const DeleteEmbeddedPageDocument = gql`
    mutation DeleteEmbeddedPage($id: uuid!) {
  delete_embedded_pages_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteEmbeddedPageMutationFn = ApolloReactCommon.MutationFunction<DeleteEmbeddedPageMutation, DeleteEmbeddedPageMutationVariables>;

/**
 * __useDeleteEmbeddedPageMutation__
 *
 * To run a mutation, you first call `useDeleteEmbeddedPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmbeddedPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmbeddedPageMutation, { data, loading, error }] = useDeleteEmbeddedPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmbeddedPageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmbeddedPageMutation, DeleteEmbeddedPageMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmbeddedPageMutation, DeleteEmbeddedPageMutationVariables>(DeleteEmbeddedPageDocument, baseOptions);
      }
export type DeleteEmbeddedPageMutationHookResult = ReturnType<typeof useDeleteEmbeddedPageMutation>;
export type DeleteEmbeddedPageMutationResult = ApolloReactCommon.MutationResult<DeleteEmbeddedPageMutation>;
export type DeleteEmbeddedPageMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEmbeddedPageMutation, DeleteEmbeddedPageMutationVariables>;
export const SetBrandSalesSuppliersDocument = gql`
    mutation SetBrandSalesSuppliers($brandId: uuid!, $supplierIds: [uuid!]!, $objects: [brand_suppliers_insert_input!]!) {
  insert_brand_suppliers(
    objects: $objects
    on_conflict: {constraint: brand_supplier_pkey, update_columns: [is_sales_supplier]}
  ) {
    affected_rows
  }
  update_brand_suppliers(
    _set: {is_sales_supplier: false}
    where: {brand_id: {_eq: $brandId}, supplier_id: {_nin: $supplierIds}}
  ) {
    affected_rows
  }
  delete_brand_suppliers(
    where: {brand_id: {_eq: $brandId}, is_sales_supplier: {_eq: false}, is_after_sales_supplier: {_eq: false}}
  ) {
    affected_rows
  }
  update_brand(_set: {id: $brandId}, pk_columns: {id: $brandId}) {
    ...BrandDetailsFields
  }
}
    ${BrandDetailsFieldsFragmentDoc}`;
export type SetBrandSalesSuppliersMutationFn = ApolloReactCommon.MutationFunction<SetBrandSalesSuppliersMutation, SetBrandSalesSuppliersMutationVariables>;

/**
 * __useSetBrandSalesSuppliersMutation__
 *
 * To run a mutation, you first call `useSetBrandSalesSuppliersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBrandSalesSuppliersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBrandSalesSuppliersMutation, { data, loading, error }] = useSetBrandSalesSuppliersMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      supplierIds: // value for 'supplierIds'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSetBrandSalesSuppliersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetBrandSalesSuppliersMutation, SetBrandSalesSuppliersMutationVariables>) {
        return ApolloReactHooks.useMutation<SetBrandSalesSuppliersMutation, SetBrandSalesSuppliersMutationVariables>(SetBrandSalesSuppliersDocument, baseOptions);
      }
export type SetBrandSalesSuppliersMutationHookResult = ReturnType<typeof useSetBrandSalesSuppliersMutation>;
export type SetBrandSalesSuppliersMutationResult = ApolloReactCommon.MutationResult<SetBrandSalesSuppliersMutation>;
export type SetBrandSalesSuppliersMutationOptions = ApolloReactCommon.BaseMutationOptions<SetBrandSalesSuppliersMutation, SetBrandSalesSuppliersMutationVariables>;
export const SetBrandAfterSalesSuppliersDocument = gql`
    mutation SetBrandAfterSalesSuppliers($brandId: uuid!, $supplierIds: [uuid!]!, $objects: [brand_suppliers_insert_input!]!) {
  insert_brand_suppliers(
    objects: $objects
    on_conflict: {constraint: brand_supplier_pkey, update_columns: [is_after_sales_supplier]}
  ) {
    affected_rows
  }
  update_brand_suppliers(
    _set: {is_after_sales_supplier: false}
    where: {brand_id: {_eq: $brandId}, supplier_id: {_nin: $supplierIds}}
  ) {
    affected_rows
  }
  delete_brand_suppliers(
    where: {brand_id: {_eq: $brandId}, is_sales_supplier: {_eq: false}, is_after_sales_supplier: {_eq: false}}
  ) {
    affected_rows
  }
  update_brand(_set: {id: $brandId}, pk_columns: {id: $brandId}) {
    ...BrandDetailsFields
  }
}
    ${BrandDetailsFieldsFragmentDoc}`;
export type SetBrandAfterSalesSuppliersMutationFn = ApolloReactCommon.MutationFunction<SetBrandAfterSalesSuppliersMutation, SetBrandAfterSalesSuppliersMutationVariables>;

/**
 * __useSetBrandAfterSalesSuppliersMutation__
 *
 * To run a mutation, you first call `useSetBrandAfterSalesSuppliersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBrandAfterSalesSuppliersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBrandAfterSalesSuppliersMutation, { data, loading, error }] = useSetBrandAfterSalesSuppliersMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      supplierIds: // value for 'supplierIds'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSetBrandAfterSalesSuppliersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetBrandAfterSalesSuppliersMutation, SetBrandAfterSalesSuppliersMutationVariables>) {
        return ApolloReactHooks.useMutation<SetBrandAfterSalesSuppliersMutation, SetBrandAfterSalesSuppliersMutationVariables>(SetBrandAfterSalesSuppliersDocument, baseOptions);
      }
export type SetBrandAfterSalesSuppliersMutationHookResult = ReturnType<typeof useSetBrandAfterSalesSuppliersMutation>;
export type SetBrandAfterSalesSuppliersMutationResult = ApolloReactCommon.MutationResult<SetBrandAfterSalesSuppliersMutation>;
export type SetBrandAfterSalesSuppliersMutationOptions = ApolloReactCommon.BaseMutationOptions<SetBrandAfterSalesSuppliersMutation, SetBrandAfterSalesSuppliersMutationVariables>;
export const SetVehicleMetafieldsDocument = gql`
    mutation SetVehicleMetafields($brandId: uuid!, $objects: [brand_vehicle_metafield_insert_input!]!) {
  delete_brand_vehicle_metafield(where: {brand_id: {_eq: $brandId}}) {
    affected_rows
  }
  insert_brand_vehicle_metafield(
    objects: $objects
    on_conflict: {constraint: brand_vehicle_metafield_pkey, update_columns: [default_value, required_for_import]}
  ) {
    affected_rows
  }
}
    `;
export type SetVehicleMetafieldsMutationFn = ApolloReactCommon.MutationFunction<SetVehicleMetafieldsMutation, SetVehicleMetafieldsMutationVariables>;

/**
 * __useSetVehicleMetafieldsMutation__
 *
 * To run a mutation, you first call `useSetVehicleMetafieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVehicleMetafieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVehicleMetafieldsMutation, { data, loading, error }] = useSetVehicleMetafieldsMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSetVehicleMetafieldsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetVehicleMetafieldsMutation, SetVehicleMetafieldsMutationVariables>) {
        return ApolloReactHooks.useMutation<SetVehicleMetafieldsMutation, SetVehicleMetafieldsMutationVariables>(SetVehicleMetafieldsDocument, baseOptions);
      }
export type SetVehicleMetafieldsMutationHookResult = ReturnType<typeof useSetVehicleMetafieldsMutation>;
export type SetVehicleMetafieldsMutationResult = ApolloReactCommon.MutationResult<SetVehicleMetafieldsMutation>;
export type SetVehicleMetafieldsMutationOptions = ApolloReactCommon.BaseMutationOptions<SetVehicleMetafieldsMutation, SetVehicleMetafieldsMutationVariables>;
export const ToggleDealerVehiclesSyncStatusDocument = gql`
    mutation ToggleDealerVehiclesSyncStatus($id: String!, $enabled: Boolean!) {
  toggleBrandForDealerVehicleSync(input: {id: $id, enabled: $enabled}) {
    status
    errorMessage
  }
}
    `;
export type ToggleDealerVehiclesSyncStatusMutationFn = ApolloReactCommon.MutationFunction<ToggleDealerVehiclesSyncStatusMutation, ToggleDealerVehiclesSyncStatusMutationVariables>;

/**
 * __useToggleDealerVehiclesSyncStatusMutation__
 *
 * To run a mutation, you first call `useToggleDealerVehiclesSyncStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleDealerVehiclesSyncStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleDealerVehiclesSyncStatusMutation, { data, loading, error }] = useToggleDealerVehiclesSyncStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useToggleDealerVehiclesSyncStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleDealerVehiclesSyncStatusMutation, ToggleDealerVehiclesSyncStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleDealerVehiclesSyncStatusMutation, ToggleDealerVehiclesSyncStatusMutationVariables>(ToggleDealerVehiclesSyncStatusDocument, baseOptions);
      }
export type ToggleDealerVehiclesSyncStatusMutationHookResult = ReturnType<typeof useToggleDealerVehiclesSyncStatusMutation>;
export type ToggleDealerVehiclesSyncStatusMutationResult = ApolloReactCommon.MutationResult<ToggleDealerVehiclesSyncStatusMutation>;
export type ToggleDealerVehiclesSyncStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleDealerVehiclesSyncStatusMutation, ToggleDealerVehiclesSyncStatusMutationVariables>;
export const UpdateModelsDocument = gql`
    mutation UpdateModels($set: Model_set_input, $where: Model_bool_exp!) {
  update_models(_set: $set, where: $where) {
    affected_rows
    returning {
      ...AdminModelListFragment
    }
  }
}
    ${AdminModelListFragmentFragmentDoc}`;
export type UpdateModelsMutationFn = ApolloReactCommon.MutationFunction<UpdateModelsMutation, UpdateModelsMutationVariables>;

/**
 * __useUpdateModelsMutation__
 *
 * To run a mutation, you first call `useUpdateModelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelsMutation, { data, loading, error }] = useUpdateModelsMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateModelsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateModelsMutation, UpdateModelsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateModelsMutation, UpdateModelsMutationVariables>(UpdateModelsDocument, baseOptions);
      }
export type UpdateModelsMutationHookResult = ReturnType<typeof useUpdateModelsMutation>;
export type UpdateModelsMutationResult = ApolloReactCommon.MutationResult<UpdateModelsMutation>;
export type UpdateModelsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateModelsMutation, UpdateModelsMutationVariables>;
export const AdminBrandDetailsDocument = gql`
    query AdminBrandDetails($id: uuid!) {
  brand(id: $id) {
    ...BrandDetailsFields
  }
}
    ${BrandDetailsFieldsFragmentDoc}`;

/**
 * __useAdminBrandDetailsQuery__
 *
 * To run a query within a React component, call `useAdminBrandDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBrandDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBrandDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminBrandDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminBrandDetailsQuery, AdminBrandDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminBrandDetailsQuery, AdminBrandDetailsQueryVariables>(AdminBrandDetailsDocument, baseOptions);
      }
export function useAdminBrandDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminBrandDetailsQuery, AdminBrandDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminBrandDetailsQuery, AdminBrandDetailsQueryVariables>(AdminBrandDetailsDocument, baseOptions);
        }
export type AdminBrandDetailsQueryHookResult = ReturnType<typeof useAdminBrandDetailsQuery>;
export type AdminBrandDetailsLazyQueryHookResult = ReturnType<typeof useAdminBrandDetailsLazyQuery>;
export type AdminBrandDetailsQueryResult = ApolloReactCommon.QueryResult<AdminBrandDetailsQuery, AdminBrandDetailsQueryVariables>;
export const AdminBrandSupplierListDocument = gql`
    query AdminBrandSupplierList {
  suppliers {
    id
    name
  }
}
    `;

/**
 * __useAdminBrandSupplierListQuery__
 *
 * To run a query within a React component, call `useAdminBrandSupplierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBrandSupplierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBrandSupplierListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminBrandSupplierListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminBrandSupplierListQuery, AdminBrandSupplierListQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminBrandSupplierListQuery, AdminBrandSupplierListQueryVariables>(AdminBrandSupplierListDocument, baseOptions);
      }
export function useAdminBrandSupplierListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminBrandSupplierListQuery, AdminBrandSupplierListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminBrandSupplierListQuery, AdminBrandSupplierListQueryVariables>(AdminBrandSupplierListDocument, baseOptions);
        }
export type AdminBrandSupplierListQueryHookResult = ReturnType<typeof useAdminBrandSupplierListQuery>;
export type AdminBrandSupplierListLazyQueryHookResult = ReturnType<typeof useAdminBrandSupplierListLazyQuery>;
export type AdminBrandSupplierListQueryResult = ApolloReactCommon.QueryResult<AdminBrandSupplierListQuery, AdminBrandSupplierListQueryVariables>;
export const AdminDealerVehiclesSyncStatusDocument = gql`
    query AdminDealerVehiclesSyncStatus($id: String!) {
  dealerVehicleBrandSyncStatus(input: {id: $id}) {
    status
    enabled
    errorMessage
  }
}
    `;

/**
 * __useAdminDealerVehiclesSyncStatusQuery__
 *
 * To run a query within a React component, call `useAdminDealerVehiclesSyncStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDealerVehiclesSyncStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDealerVehiclesSyncStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDealerVehiclesSyncStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminDealerVehiclesSyncStatusQuery, AdminDealerVehiclesSyncStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminDealerVehiclesSyncStatusQuery, AdminDealerVehiclesSyncStatusQueryVariables>(AdminDealerVehiclesSyncStatusDocument, baseOptions);
      }
export function useAdminDealerVehiclesSyncStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminDealerVehiclesSyncStatusQuery, AdminDealerVehiclesSyncStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminDealerVehiclesSyncStatusQuery, AdminDealerVehiclesSyncStatusQueryVariables>(AdminDealerVehiclesSyncStatusDocument, baseOptions);
        }
export type AdminDealerVehiclesSyncStatusQueryHookResult = ReturnType<typeof useAdminDealerVehiclesSyncStatusQuery>;
export type AdminDealerVehiclesSyncStatusLazyQueryHookResult = ReturnType<typeof useAdminDealerVehiclesSyncStatusLazyQuery>;
export type AdminDealerVehiclesSyncStatusQueryResult = ApolloReactCommon.QueryResult<AdminDealerVehiclesSyncStatusQuery, AdminDealerVehiclesSyncStatusQueryVariables>;
export const AdminModelGroupListDocument = gql`
    query AdminModelGroupList($brandId: uuid!) {
  model_groups(where: {brand_id: {_eq: $brandId}}, order_by: {name: asc}) {
    ...AdminModelGroupFields
  }
}
    ${AdminModelGroupFieldsFragmentDoc}`;

/**
 * __useAdminModelGroupListQuery__
 *
 * To run a query within a React component, call `useAdminModelGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminModelGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminModelGroupListQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useAdminModelGroupListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminModelGroupListQuery, AdminModelGroupListQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminModelGroupListQuery, AdminModelGroupListQueryVariables>(AdminModelGroupListDocument, baseOptions);
      }
export function useAdminModelGroupListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminModelGroupListQuery, AdminModelGroupListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminModelGroupListQuery, AdminModelGroupListQueryVariables>(AdminModelGroupListDocument, baseOptions);
        }
export type AdminModelGroupListQueryHookResult = ReturnType<typeof useAdminModelGroupListQuery>;
export type AdminModelGroupListLazyQueryHookResult = ReturnType<typeof useAdminModelGroupListLazyQuery>;
export type AdminModelGroupListQueryResult = ApolloReactCommon.QueryResult<AdminModelGroupListQuery, AdminModelGroupListQueryVariables>;
export const AdminModelGroupDocument = gql`
    query AdminModelGroup($id: uuid!) {
  model_group(id: $id) {
    ...AdminModelGroupFields
    models {
      id
      description
      model_code
    }
  }
}
    ${AdminModelGroupFieldsFragmentDoc}`;

/**
 * __useAdminModelGroupQuery__
 *
 * To run a query within a React component, call `useAdminModelGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminModelGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminModelGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminModelGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminModelGroupQuery, AdminModelGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminModelGroupQuery, AdminModelGroupQueryVariables>(AdminModelGroupDocument, baseOptions);
      }
export function useAdminModelGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminModelGroupQuery, AdminModelGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminModelGroupQuery, AdminModelGroupQueryVariables>(AdminModelGroupDocument, baseOptions);
        }
export type AdminModelGroupQueryHookResult = ReturnType<typeof useAdminModelGroupQuery>;
export type AdminModelGroupLazyQueryHookResult = ReturnType<typeof useAdminModelGroupLazyQuery>;
export type AdminModelGroupQueryResult = ApolloReactCommon.QueryResult<AdminModelGroupQuery, AdminModelGroupQueryVariables>;
export const CreateModelGroupDocument = gql`
    mutation CreateModelGroup($object: ModelGroup_insert_input!) {
  insert_model_group(object: $object) {
    ...AdminModelGroupFields
  }
}
    ${AdminModelGroupFieldsFragmentDoc}`;
export type CreateModelGroupMutationFn = ApolloReactCommon.MutationFunction<CreateModelGroupMutation, CreateModelGroupMutationVariables>;

/**
 * __useCreateModelGroupMutation__
 *
 * To run a mutation, you first call `useCreateModelGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModelGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModelGroupMutation, { data, loading, error }] = useCreateModelGroupMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateModelGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateModelGroupMutation, CreateModelGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateModelGroupMutation, CreateModelGroupMutationVariables>(CreateModelGroupDocument, baseOptions);
      }
export type CreateModelGroupMutationHookResult = ReturnType<typeof useCreateModelGroupMutation>;
export type CreateModelGroupMutationResult = ApolloReactCommon.MutationResult<CreateModelGroupMutation>;
export type CreateModelGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateModelGroupMutation, CreateModelGroupMutationVariables>;
export const UpdateModelGroupDocument = gql`
    mutation UpdateModelGroup($modelGroupId: uuid!, $modelGroup: ModelGroup_set_input!, $releaseYears: [Int!]!, $releaseYearReferences: [model_group_release_years_insert_input!]!) {
  delete_model_group_release_years(
    where: {model_group_id: {_eq: $modelGroupId}, release_year: {_nin: $releaseYears}}
  ) {
    affected_rows
  }
  insert_model_group_release_years(
    objects: $releaseYearReferences
    on_conflict: {constraint: model_group_release_years_pkey, update_columns: []}
  ) {
    affected_rows
  }
  update_model_group(_set: $modelGroup, pk_columns: {id: $modelGroupId}) {
    ...AdminModelGroupFields
  }
}
    ${AdminModelGroupFieldsFragmentDoc}`;
export type UpdateModelGroupMutationFn = ApolloReactCommon.MutationFunction<UpdateModelGroupMutation, UpdateModelGroupMutationVariables>;

/**
 * __useUpdateModelGroupMutation__
 *
 * To run a mutation, you first call `useUpdateModelGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelGroupMutation, { data, loading, error }] = useUpdateModelGroupMutation({
 *   variables: {
 *      modelGroupId: // value for 'modelGroupId'
 *      modelGroup: // value for 'modelGroup'
 *      releaseYears: // value for 'releaseYears'
 *      releaseYearReferences: // value for 'releaseYearReferences'
 *   },
 * });
 */
export function useUpdateModelGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateModelGroupMutation, UpdateModelGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateModelGroupMutation, UpdateModelGroupMutationVariables>(UpdateModelGroupDocument, baseOptions);
      }
export type UpdateModelGroupMutationHookResult = ReturnType<typeof useUpdateModelGroupMutation>;
export type UpdateModelGroupMutationResult = ApolloReactCommon.MutationResult<UpdateModelGroupMutation>;
export type UpdateModelGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateModelGroupMutation, UpdateModelGroupMutationVariables>;
export const AdminModelListDocument = gql`
    query AdminModelList($limit: Int!, $offset: Int!, $brandId: uuid!, $searchText: String) {
  models(
    limit: $limit
    offset: $offset
    where: {brand_id: {_eq: $brandId}, _or: [{description: {_ilike: $searchText}}, {model_code: {_ilike: $searchText}}]}
  ) {
    ...AdminModelListFragment
  }
  models_aggregate(
    where: {brand_id: {_eq: $brandId}, _or: [{description: {_ilike: $searchText}}, {model_code: {_ilike: $searchText}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${AdminModelListFragmentFragmentDoc}`;

/**
 * __useAdminModelListQuery__
 *
 * To run a query within a React component, call `useAdminModelListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminModelListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminModelListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      brandId: // value for 'brandId'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useAdminModelListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminModelListQuery, AdminModelListQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminModelListQuery, AdminModelListQueryVariables>(AdminModelListDocument, baseOptions);
      }
export function useAdminModelListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminModelListQuery, AdminModelListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminModelListQuery, AdminModelListQueryVariables>(AdminModelListDocument, baseOptions);
        }
export type AdminModelListQueryHookResult = ReturnType<typeof useAdminModelListQuery>;
export type AdminModelListLazyQueryHookResult = ReturnType<typeof useAdminModelListLazyQuery>;
export type AdminModelListQueryResult = ApolloReactCommon.QueryResult<AdminModelListQuery, AdminModelListQueryVariables>;
export const AdminModelDetailsDocument = gql`
    query AdminModelDetails($modelId: uuid!) {
  model(id: $modelId) {
    ...AdminModelListFragment
  }
}
    ${AdminModelListFragmentFragmentDoc}`;

/**
 * __useAdminModelDetailsQuery__
 *
 * To run a query within a React component, call `useAdminModelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminModelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminModelDetailsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useAdminModelDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminModelDetailsQuery, AdminModelDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminModelDetailsQuery, AdminModelDetailsQueryVariables>(AdminModelDetailsDocument, baseOptions);
      }
export function useAdminModelDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminModelDetailsQuery, AdminModelDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminModelDetailsQuery, AdminModelDetailsQueryVariables>(AdminModelDetailsDocument, baseOptions);
        }
export type AdminModelDetailsQueryHookResult = ReturnType<typeof useAdminModelDetailsQuery>;
export type AdminModelDetailsLazyQueryHookResult = ReturnType<typeof useAdminModelDetailsLazyQuery>;
export type AdminModelDetailsQueryResult = ApolloReactCommon.QueryResult<AdminModelDetailsQuery, AdminModelDetailsQueryVariables>;
export const AdminModelsInStockListDocument = gql`
    query AdminModelsInStockList($limit: Int!, $offset: Int!, $brandId: uuid!, $searchText: String) {
  models: models_in_stock(
    limit: $limit
    offset: $offset
    where: {brand_id: {_eq: $brandId}, _or: [{description: {_ilike: $searchText}}, {model_code: {_ilike: $searchText}}]}
  ) {
    ...AdminModelListFragment
  }
  models_aggregate: models_in_stock_aggregate(
    where: {brand_id: {_eq: $brandId}, _or: [{description: {_ilike: $searchText}}, {model_code: {_ilike: $searchText}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${AdminModelListFragmentFragmentDoc}`;

/**
 * __useAdminModelsInStockListQuery__
 *
 * To run a query within a React component, call `useAdminModelsInStockListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminModelsInStockListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminModelsInStockListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      brandId: // value for 'brandId'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useAdminModelsInStockListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminModelsInStockListQuery, AdminModelsInStockListQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminModelsInStockListQuery, AdminModelsInStockListQueryVariables>(AdminModelsInStockListDocument, baseOptions);
      }
export function useAdminModelsInStockListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminModelsInStockListQuery, AdminModelsInStockListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminModelsInStockListQuery, AdminModelsInStockListQueryVariables>(AdminModelsInStockListDocument, baseOptions);
        }
export type AdminModelsInStockListQueryHookResult = ReturnType<typeof useAdminModelsInStockListQuery>;
export type AdminModelsInStockListLazyQueryHookResult = ReturnType<typeof useAdminModelsInStockListLazyQuery>;
export type AdminModelsInStockListQueryResult = ApolloReactCommon.QueryResult<AdminModelsInStockListQuery, AdminModelsInStockListQueryVariables>;
export const AdminNewModelsListDocument = gql`
    query AdminNewModelsList($limit: Int!, $offset: Int!, $brandId: uuid!, $searchText: String) {
  models: new_models(
    limit: $limit
    offset: $offset
    where: {brand_id: {_eq: $brandId}, _or: [{description: {_ilike: $searchText}}, {model_code: {_ilike: $searchText}}]}
  ) {
    ...AdminModelListFragment
  }
  models_aggregate: new_models_aggregate(
    where: {brand_id: {_eq: $brandId}, _or: [{description: {_ilike: $searchText}}, {model_code: {_ilike: $searchText}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${AdminModelListFragmentFragmentDoc}`;

/**
 * __useAdminNewModelsListQuery__
 *
 * To run a query within a React component, call `useAdminNewModelsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminNewModelsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminNewModelsListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      brandId: // value for 'brandId'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useAdminNewModelsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminNewModelsListQuery, AdminNewModelsListQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminNewModelsListQuery, AdminNewModelsListQueryVariables>(AdminNewModelsListDocument, baseOptions);
      }
export function useAdminNewModelsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminNewModelsListQuery, AdminNewModelsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminNewModelsListQuery, AdminNewModelsListQueryVariables>(AdminNewModelsListDocument, baseOptions);
        }
export type AdminNewModelsListQueryHookResult = ReturnType<typeof useAdminNewModelsListQuery>;
export type AdminNewModelsListLazyQueryHookResult = ReturnType<typeof useAdminNewModelsListLazyQuery>;
export type AdminNewModelsListQueryResult = ApolloReactCommon.QueryResult<AdminNewModelsListQuery, AdminNewModelsListQueryVariables>;
export const AdminVehicleMetafieldsDocument = gql`
    query AdminVehicleMetafields {
  vehicle_metafields {
    column_name
    title
  }
}
    `;

/**
 * __useAdminVehicleMetafieldsQuery__
 *
 * To run a query within a React component, call `useAdminVehicleMetafieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminVehicleMetafieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminVehicleMetafieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminVehicleMetafieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminVehicleMetafieldsQuery, AdminVehicleMetafieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminVehicleMetafieldsQuery, AdminVehicleMetafieldsQueryVariables>(AdminVehicleMetafieldsDocument, baseOptions);
      }
export function useAdminVehicleMetafieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminVehicleMetafieldsQuery, AdminVehicleMetafieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminVehicleMetafieldsQuery, AdminVehicleMetafieldsQueryVariables>(AdminVehicleMetafieldsDocument, baseOptions);
        }
export type AdminVehicleMetafieldsQueryHookResult = ReturnType<typeof useAdminVehicleMetafieldsQuery>;
export type AdminVehicleMetafieldsLazyQueryHookResult = ReturnType<typeof useAdminVehicleMetafieldsLazyQuery>;
export type AdminVehicleMetafieldsQueryResult = ApolloReactCommon.QueryResult<AdminVehicleMetafieldsQuery, AdminVehicleMetafieldsQueryVariables>;
export const AdminBrandEmbeddedPagesDocument = gql`
    subscription AdminBrandEmbeddedPages($brandId: uuid!) {
  embedded_pages(where: {brand_id: {_eq: $brandId}}) {
    id
    url
    name
    brand_id
  }
}
    `;

/**
 * __useAdminBrandEmbeddedPagesSubscription__
 *
 * To run a query within a React component, call `useAdminBrandEmbeddedPagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAdminBrandEmbeddedPagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBrandEmbeddedPagesSubscription({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useAdminBrandEmbeddedPagesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AdminBrandEmbeddedPagesSubscription, AdminBrandEmbeddedPagesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<AdminBrandEmbeddedPagesSubscription, AdminBrandEmbeddedPagesSubscriptionVariables>(AdminBrandEmbeddedPagesDocument, baseOptions);
      }
export type AdminBrandEmbeddedPagesSubscriptionHookResult = ReturnType<typeof useAdminBrandEmbeddedPagesSubscription>;
export type AdminBrandEmbeddedPagesSubscriptionResult = ApolloReactCommon.SubscriptionResult<AdminBrandEmbeddedPagesSubscription>;
export const AdminBrandListDocument = gql`
    subscription AdminBrandList($where: Brand_bool_exp) {
  brands(where: $where, order_by: {name: asc}) {
    id
    name
  }
}
    `;

/**
 * __useAdminBrandListSubscription__
 *
 * To run a query within a React component, call `useAdminBrandListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAdminBrandListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBrandListSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAdminBrandListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AdminBrandListSubscription, AdminBrandListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<AdminBrandListSubscription, AdminBrandListSubscriptionVariables>(AdminBrandListDocument, baseOptions);
      }
export type AdminBrandListSubscriptionHookResult = ReturnType<typeof useAdminBrandListSubscription>;
export type AdminBrandListSubscriptionResult = ApolloReactCommon.SubscriptionResult<AdminBrandListSubscription>;
export const CreateNewDepartmentDocument = gql`
    mutation CreateNewDepartment($input: Department_insert_input!) {
  insert_department(object: $input) {
    ...DepartmentFields
  }
}
    ${DepartmentFieldsFragmentDoc}`;
export type CreateNewDepartmentMutationFn = ApolloReactCommon.MutationFunction<CreateNewDepartmentMutation, CreateNewDepartmentMutationVariables>;

/**
 * __useCreateNewDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateNewDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewDepartmentMutation, { data, loading, error }] = useCreateNewDepartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewDepartmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewDepartmentMutation, CreateNewDepartmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewDepartmentMutation, CreateNewDepartmentMutationVariables>(CreateNewDepartmentDocument, baseOptions);
      }
export type CreateNewDepartmentMutationHookResult = ReturnType<typeof useCreateNewDepartmentMutation>;
export type CreateNewDepartmentMutationResult = ApolloReactCommon.MutationResult<CreateNewDepartmentMutation>;
export type CreateNewDepartmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewDepartmentMutation, CreateNewDepartmentMutationVariables>;
export const DepartmentDetailDocument = gql`
    query DepartmentDetail($id: uuid!) {
  department(id: $id) {
    ...DepartmentFields
  }
}
    ${DepartmentFieldsFragmentDoc}`;

/**
 * __useDepartmentDetailQuery__
 *
 * To run a query within a React component, call `useDepartmentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDepartmentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartmentDetailQuery, DepartmentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<DepartmentDetailQuery, DepartmentDetailQueryVariables>(DepartmentDetailDocument, baseOptions);
      }
export function useDepartmentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartmentDetailQuery, DepartmentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepartmentDetailQuery, DepartmentDetailQueryVariables>(DepartmentDetailDocument, baseOptions);
        }
export type DepartmentDetailQueryHookResult = ReturnType<typeof useDepartmentDetailQuery>;
export type DepartmentDetailLazyQueryHookResult = ReturnType<typeof useDepartmentDetailLazyQuery>;
export type DepartmentDetailQueryResult = ApolloReactCommon.QueryResult<DepartmentDetailQuery, DepartmentDetailQueryVariables>;
export const UpdateDepartmentDocument = gql`
    mutation UpdateDepartment($id: uuid!, $_set: Department_set_input, $objects: [department_role_insert_input!]!) {
  update_department(pk_columns: {id: $id}, _set: $_set) {
    ...DepartmentFields
  }
  delete_department_role(where: {department_id: {_eq: $id}}) {
    affected_rows
  }
  insert_department_role(objects: $objects) {
    affected_rows
  }
}
    ${DepartmentFieldsFragmentDoc}`;
export type UpdateDepartmentMutationFn = ApolloReactCommon.MutationFunction<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(UpdateDepartmentDocument, baseOptions);
      }
export type UpdateDepartmentMutationHookResult = ReturnType<typeof useUpdateDepartmentMutation>;
export type UpdateDepartmentMutationResult = ApolloReactCommon.MutationResult<UpdateDepartmentMutation>;
export type UpdateDepartmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;
export const AdminEmbeddedPageDetailDocument = gql`
    query AdminEmbeddedPageDetail($id: uuid!) {
  embedded_pages_by_pk(id: $id) {
    name
    url
    brand_id
  }
}
    `;

/**
 * __useAdminEmbeddedPageDetailQuery__
 *
 * To run a query within a React component, call `useAdminEmbeddedPageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminEmbeddedPageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminEmbeddedPageDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminEmbeddedPageDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminEmbeddedPageDetailQuery, AdminEmbeddedPageDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminEmbeddedPageDetailQuery, AdminEmbeddedPageDetailQueryVariables>(AdminEmbeddedPageDetailDocument, baseOptions);
      }
export function useAdminEmbeddedPageDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminEmbeddedPageDetailQuery, AdminEmbeddedPageDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminEmbeddedPageDetailQuery, AdminEmbeddedPageDetailQueryVariables>(AdminEmbeddedPageDetailDocument, baseOptions);
        }
export type AdminEmbeddedPageDetailQueryHookResult = ReturnType<typeof useAdminEmbeddedPageDetailQuery>;
export type AdminEmbeddedPageDetailLazyQueryHookResult = ReturnType<typeof useAdminEmbeddedPageDetailLazyQuery>;
export type AdminEmbeddedPageDetailQueryResult = ApolloReactCommon.QueryResult<AdminEmbeddedPageDetailQuery, AdminEmbeddedPageDetailQueryVariables>;
export const CreateNewEmbeddedPageDocument = gql`
    mutation CreateNewEmbeddedPage($object: embedded_pages_insert_input!) {
  insert_embedded_pages_one(object: $object) {
    id
  }
}
    `;
export type CreateNewEmbeddedPageMutationFn = ApolloReactCommon.MutationFunction<CreateNewEmbeddedPageMutation, CreateNewEmbeddedPageMutationVariables>;

/**
 * __useCreateNewEmbeddedPageMutation__
 *
 * To run a mutation, you first call `useCreateNewEmbeddedPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewEmbeddedPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewEmbeddedPageMutation, { data, loading, error }] = useCreateNewEmbeddedPageMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateNewEmbeddedPageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewEmbeddedPageMutation, CreateNewEmbeddedPageMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewEmbeddedPageMutation, CreateNewEmbeddedPageMutationVariables>(CreateNewEmbeddedPageDocument, baseOptions);
      }
export type CreateNewEmbeddedPageMutationHookResult = ReturnType<typeof useCreateNewEmbeddedPageMutation>;
export type CreateNewEmbeddedPageMutationResult = ApolloReactCommon.MutationResult<CreateNewEmbeddedPageMutation>;
export type CreateNewEmbeddedPageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewEmbeddedPageMutation, CreateNewEmbeddedPageMutationVariables>;
export const UpdateEmbeddedPageDocument = gql`
    mutation UpdateEmbeddedPage($id: uuid!, $_set: embedded_pages_set_input) {
  update_embedded_pages_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}
    `;
export type UpdateEmbeddedPageMutationFn = ApolloReactCommon.MutationFunction<UpdateEmbeddedPageMutation, UpdateEmbeddedPageMutationVariables>;

/**
 * __useUpdateEmbeddedPageMutation__
 *
 * To run a mutation, you first call `useUpdateEmbeddedPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmbeddedPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmbeddedPageMutation, { data, loading, error }] = useUpdateEmbeddedPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateEmbeddedPageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmbeddedPageMutation, UpdateEmbeddedPageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmbeddedPageMutation, UpdateEmbeddedPageMutationVariables>(UpdateEmbeddedPageDocument, baseOptions);
      }
export type UpdateEmbeddedPageMutationHookResult = ReturnType<typeof useUpdateEmbeddedPageMutation>;
export type UpdateEmbeddedPageMutationResult = ApolloReactCommon.MutationResult<UpdateEmbeddedPageMutation>;
export type UpdateEmbeddedPageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmbeddedPageMutation, UpdateEmbeddedPageMutationVariables>;
export const AdminLocationDetailDocument = gql`
    query AdminLocationDetail($id: uuid!) {
  location(id: $id) {
    ...AdminLocationDetailFields
  }
}
    ${AdminLocationDetailFieldsFragmentDoc}`;

/**
 * __useAdminLocationDetailQuery__
 *
 * To run a query within a React component, call `useAdminLocationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLocationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLocationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminLocationDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminLocationDetailQuery, AdminLocationDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminLocationDetailQuery, AdminLocationDetailQueryVariables>(AdminLocationDetailDocument, baseOptions);
      }
export function useAdminLocationDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminLocationDetailQuery, AdminLocationDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminLocationDetailQuery, AdminLocationDetailQueryVariables>(AdminLocationDetailDocument, baseOptions);
        }
export type AdminLocationDetailQueryHookResult = ReturnType<typeof useAdminLocationDetailQuery>;
export type AdminLocationDetailLazyQueryHookResult = ReturnType<typeof useAdminLocationDetailLazyQuery>;
export type AdminLocationDetailQueryResult = ApolloReactCommon.QueryResult<AdminLocationDetailQuery, AdminLocationDetailQueryVariables>;
export const AddLocationGroupDocument = gql`
    mutation AddLocationGroup($object: LocationGroup_insert_input!) {
  insert_location_group(object: $object) {
    id
    name
    brands {
      id
      name
    }
  }
}
    `;
export type AddLocationGroupMutationFn = ApolloReactCommon.MutationFunction<AddLocationGroupMutation, AddLocationGroupMutationVariables>;

/**
 * __useAddLocationGroupMutation__
 *
 * To run a mutation, you first call `useAddLocationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocationGroupMutation, { data, loading, error }] = useAddLocationGroupMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddLocationGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddLocationGroupMutation, AddLocationGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<AddLocationGroupMutation, AddLocationGroupMutationVariables>(AddLocationGroupDocument, baseOptions);
      }
export type AddLocationGroupMutationHookResult = ReturnType<typeof useAddLocationGroupMutation>;
export type AddLocationGroupMutationResult = ApolloReactCommon.MutationResult<AddLocationGroupMutation>;
export type AddLocationGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AddLocationGroupMutation, AddLocationGroupMutationVariables>;
export const UpdateLocationGroupDocument = gql`
    mutation UpdateLocationGroup($id: uuid!, $groupUpdate: LocationGroup_set_input!, $groupBrandRefObj: [location_group_brand_insert_input!]!, $brandIds: [uuid!]!) {
  insert_location_group_brand(
    objects: $groupBrandRefObj
    on_conflict: {constraint: location_group_brand_pkey, update_columns: []}
  ) {
    affected_rows
  }
  delete_location_group_brand(
    where: {location_group_id: {_eq: $id}, brand_id: {_nin: $brandIds}}
  ) {
    affected_rows
  }
  update_location_group(_set: $groupUpdate, pk_columns: {id: $id}) {
    id
    name
    brands {
      id
      name
    }
  }
}
    `;
export type UpdateLocationGroupMutationFn = ApolloReactCommon.MutationFunction<UpdateLocationGroupMutation, UpdateLocationGroupMutationVariables>;

/**
 * __useUpdateLocationGroupMutation__
 *
 * To run a mutation, you first call `useUpdateLocationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationGroupMutation, { data, loading, error }] = useUpdateLocationGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groupUpdate: // value for 'groupUpdate'
 *      groupBrandRefObj: // value for 'groupBrandRefObj'
 *      brandIds: // value for 'brandIds'
 *   },
 * });
 */
export function useUpdateLocationGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocationGroupMutation, UpdateLocationGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLocationGroupMutation, UpdateLocationGroupMutationVariables>(UpdateLocationGroupDocument, baseOptions);
      }
export type UpdateLocationGroupMutationHookResult = ReturnType<typeof useUpdateLocationGroupMutation>;
export type UpdateLocationGroupMutationResult = ApolloReactCommon.MutationResult<UpdateLocationGroupMutation>;
export type UpdateLocationGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLocationGroupMutation, UpdateLocationGroupMutationVariables>;
export const RemoveLocationGroupDocument = gql`
    mutation RemoveLocationGroup($id: uuid!) {
  delete_location_group(id: $id) {
    id
  }
}
    `;
export type RemoveLocationGroupMutationFn = ApolloReactCommon.MutationFunction<RemoveLocationGroupMutation, RemoveLocationGroupMutationVariables>;

/**
 * __useRemoveLocationGroupMutation__
 *
 * To run a mutation, you first call `useRemoveLocationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocationGroupMutation, { data, loading, error }] = useRemoveLocationGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveLocationGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveLocationGroupMutation, RemoveLocationGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveLocationGroupMutation, RemoveLocationGroupMutationVariables>(RemoveLocationGroupDocument, baseOptions);
      }
export type RemoveLocationGroupMutationHookResult = ReturnType<typeof useRemoveLocationGroupMutation>;
export type RemoveLocationGroupMutationResult = ApolloReactCommon.MutationResult<RemoveLocationGroupMutation>;
export type RemoveLocationGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveLocationGroupMutation, RemoveLocationGroupMutationVariables>;
export const SetLocationBrandRefsDocument = gql`
    mutation SetLocationBrandRefs($locationId: uuid!, $brandIds: [uuid!]!, $objects: [LocationBrandReference_insert_input!]!) {
  insert_location_brand_references(
    objects: $objects
    on_conflict: {constraint: location_brand_pkey, update_columns: [sales, after_sales, isPrimaryDealer]}
  ) {
    affected_rows
  }
  delete_location_brand_references(
    where: {location_id: {_eq: $locationId}, brand_id: {_nin: $brandIds}}
  ) {
    affected_rows
  }
}
    `;
export type SetLocationBrandRefsMutationFn = ApolloReactCommon.MutationFunction<SetLocationBrandRefsMutation, SetLocationBrandRefsMutationVariables>;

/**
 * __useSetLocationBrandRefsMutation__
 *
 * To run a mutation, you first call `useSetLocationBrandRefsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLocationBrandRefsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLocationBrandRefsMutation, { data, loading, error }] = useSetLocationBrandRefsMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      brandIds: // value for 'brandIds'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSetLocationBrandRefsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetLocationBrandRefsMutation, SetLocationBrandRefsMutationVariables>) {
        return ApolloReactHooks.useMutation<SetLocationBrandRefsMutation, SetLocationBrandRefsMutationVariables>(SetLocationBrandRefsDocument, baseOptions);
      }
export type SetLocationBrandRefsMutationHookResult = ReturnType<typeof useSetLocationBrandRefsMutation>;
export type SetLocationBrandRefsMutationResult = ApolloReactCommon.MutationResult<SetLocationBrandRefsMutation>;
export type SetLocationBrandRefsMutationOptions = ApolloReactCommon.BaseMutationOptions<SetLocationBrandRefsMutation, SetLocationBrandRefsMutationVariables>;
export const SetLocationDmsReferencesDocument = gql`
    mutation SetLocationDmsReferences($locationId: uuid!, $refIds: [uuid!]!, $objects: [DmsReference_insert_input!]!) {
  delete_dms_references(
    where: {id: {_nin: $refIds}, _or: [{location_id: {_eq: $locationId}}, {location_customer_id: {_eq: $locationId}}, {location_supplier_id: {_eq: $locationId}}]}
  ) {
    affected_rows
  }
  insert_dms_references(
    objects: $objects
    on_conflict: {constraint: dms_reference_pkey, update_columns: [name, reference, type]}
  ) {
    affected_rows
  }
}
    `;
export type SetLocationDmsReferencesMutationFn = ApolloReactCommon.MutationFunction<SetLocationDmsReferencesMutation, SetLocationDmsReferencesMutationVariables>;

/**
 * __useSetLocationDmsReferencesMutation__
 *
 * To run a mutation, you first call `useSetLocationDmsReferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLocationDmsReferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLocationDmsReferencesMutation, { data, loading, error }] = useSetLocationDmsReferencesMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      refIds: // value for 'refIds'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSetLocationDmsReferencesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetLocationDmsReferencesMutation, SetLocationDmsReferencesMutationVariables>) {
        return ApolloReactHooks.useMutation<SetLocationDmsReferencesMutation, SetLocationDmsReferencesMutationVariables>(SetLocationDmsReferencesDocument, baseOptions);
      }
export type SetLocationDmsReferencesMutationHookResult = ReturnType<typeof useSetLocationDmsReferencesMutation>;
export type SetLocationDmsReferencesMutationResult = ApolloReactCommon.MutationResult<SetLocationDmsReferencesMutation>;
export type SetLocationDmsReferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<SetLocationDmsReferencesMutation, SetLocationDmsReferencesMutationVariables>;
export const UpdateLocationShortNameDocument = gql`
    mutation UpdateLocationShortName($pk_columns: Location_pk_columns_input!, $_set: Location_set_input) {
  update_location(pk_columns: $pk_columns, _set: $_set) {
    id
  }
}
    `;
export type UpdateLocationShortNameMutationFn = ApolloReactCommon.MutationFunction<UpdateLocationShortNameMutation, UpdateLocationShortNameMutationVariables>;

/**
 * __useUpdateLocationShortNameMutation__
 *
 * To run a mutation, you first call `useUpdateLocationShortNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationShortNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationShortNameMutation, { data, loading, error }] = useUpdateLocationShortNameMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateLocationShortNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocationShortNameMutation, UpdateLocationShortNameMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLocationShortNameMutation, UpdateLocationShortNameMutationVariables>(UpdateLocationShortNameDocument, baseOptions);
      }
export type UpdateLocationShortNameMutationHookResult = ReturnType<typeof useUpdateLocationShortNameMutation>;
export type UpdateLocationShortNameMutationResult = ApolloReactCommon.MutationResult<UpdateLocationShortNameMutation>;
export type UpdateLocationShortNameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLocationShortNameMutation, UpdateLocationShortNameMutationVariables>;
export const CreateSubDealerDocument = gql`
    mutation CreateSubDealer($object: Subdealer_insert_input!) {
  insert_subdealer(object: $object) {
    ...SubdealerDetailFields
  }
}
    ${SubdealerDetailFieldsFragmentDoc}`;
export type CreateSubDealerMutationFn = ApolloReactCommon.MutationFunction<CreateSubDealerMutation, CreateSubDealerMutationVariables>;

/**
 * __useCreateSubDealerMutation__
 *
 * To run a mutation, you first call `useCreateSubDealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubDealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubDealerMutation, { data, loading, error }] = useCreateSubDealerMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateSubDealerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubDealerMutation, CreateSubDealerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubDealerMutation, CreateSubDealerMutationVariables>(CreateSubDealerDocument, baseOptions);
      }
export type CreateSubDealerMutationHookResult = ReturnType<typeof useCreateSubDealerMutation>;
export type CreateSubDealerMutationResult = ApolloReactCommon.MutationResult<CreateSubDealerMutation>;
export type CreateSubDealerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubDealerMutation, CreateSubDealerMutationVariables>;
export const UpdateSubdealerDocument = gql`
    mutation UpdateSubdealer($subdealearId: uuid!, $addressId: uuid!, $refIds: [uuid!]!, $brandIds: [uuid!]!, $subdealer: Subdealer_set_input!, $address: Address_set_input!, $references: [DmsReference_insert_input!]!, $brandReferences: [subdealer_brand_insert_input!]!) {
  delete_dms_references(
    where: {id: {_nin: $refIds}, subdealer_customer_id: {_eq: $subdealearId}}
  ) {
    affected_rows
  }
  insert_dms_references(
    objects: $references
    on_conflict: {constraint: dms_reference_pkey, update_columns: [name, reference, type]}
  ) {
    affected_rows
  }
  update_address(_set: $address, pk_columns: {id: $addressId}) {
    id
  }
  delete_subdealer_brand(
    where: {subdealer_id: {_eq: $subdealearId}, brand_id: {_nin: $brandIds}}
  ) {
    affected_rows
  }
  insert_subdealer_brand(
    objects: $brandReferences
    on_conflict: {constraint: subdealer_brand_pkey, update_columns: []}
  ) {
    affected_rows
  }
  update_subdealer(_set: $subdealer, pk_columns: {id: $subdealearId}) {
    ...SubdealerDetailFields
  }
}
    ${SubdealerDetailFieldsFragmentDoc}`;
export type UpdateSubdealerMutationFn = ApolloReactCommon.MutationFunction<UpdateSubdealerMutation, UpdateSubdealerMutationVariables>;

/**
 * __useUpdateSubdealerMutation__
 *
 * To run a mutation, you first call `useUpdateSubdealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubdealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubdealerMutation, { data, loading, error }] = useUpdateSubdealerMutation({
 *   variables: {
 *      subdealearId: // value for 'subdealearId'
 *      addressId: // value for 'addressId'
 *      refIds: // value for 'refIds'
 *      brandIds: // value for 'brandIds'
 *      subdealer: // value for 'subdealer'
 *      address: // value for 'address'
 *      references: // value for 'references'
 *      brandReferences: // value for 'brandReferences'
 *   },
 * });
 */
export function useUpdateSubdealerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubdealerMutation, UpdateSubdealerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubdealerMutation, UpdateSubdealerMutationVariables>(UpdateSubdealerDocument, baseOptions);
      }
export type UpdateSubdealerMutationHookResult = ReturnType<typeof useUpdateSubdealerMutation>;
export type UpdateSubdealerMutationResult = ApolloReactCommon.MutationResult<UpdateSubdealerMutation>;
export type UpdateSubdealerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubdealerMutation, UpdateSubdealerMutationVariables>;
export const AdminSubDealerDetailsDocument = gql`
    query AdminSubDealerDetails($id: uuid!) {
  subdealer(id: $id) {
    ...SubdealerDetailFields
  }
}
    ${SubdealerDetailFieldsFragmentDoc}`;

/**
 * __useAdminSubDealerDetailsQuery__
 *
 * To run a query within a React component, call `useAdminSubDealerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSubDealerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSubDealerDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminSubDealerDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminSubDealerDetailsQuery, AdminSubDealerDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminSubDealerDetailsQuery, AdminSubDealerDetailsQueryVariables>(AdminSubDealerDetailsDocument, baseOptions);
      }
export function useAdminSubDealerDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminSubDealerDetailsQuery, AdminSubDealerDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminSubDealerDetailsQuery, AdminSubDealerDetailsQueryVariables>(AdminSubDealerDetailsDocument, baseOptions);
        }
export type AdminSubDealerDetailsQueryHookResult = ReturnType<typeof useAdminSubDealerDetailsQuery>;
export type AdminSubDealerDetailsLazyQueryHookResult = ReturnType<typeof useAdminSubDealerDetailsLazyQuery>;
export type AdminSubDealerDetailsQueryResult = ApolloReactCommon.QueryResult<AdminSubDealerDetailsQuery, AdminSubDealerDetailsQueryVariables>;
export const CreateSupplierDocument = gql`
    mutation CreateSupplier($object: Supplier_insert_input!) {
  insert_supplier(object: $object) {
    ...SupplierDetailFields
  }
}
    ${SupplierDetailFieldsFragmentDoc}`;
export type CreateSupplierMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierMutation, CreateSupplierMutationVariables>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(CreateSupplierDocument, baseOptions);
      }
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = ApolloReactCommon.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierMutation, CreateSupplierMutationVariables>;
export const UpdateSupplierDocument = gql`
    mutation UpdateSupplier($supplierId: uuid!, $addressId: uuid!, $refIds: [uuid!]!, $supplier: Supplier_set_input!, $address: Address_set_input!, $references: [DmsReference_insert_input!]!) {
  delete_dms_references(
    where: {id: {_nin: $refIds}, _or: [{supplier_id: {_eq: $supplierId}}, {supplier_customer_id: {_eq: $supplierId}}]}
  ) {
    affected_rows
  }
  insert_dms_references(
    objects: $references
    on_conflict: {constraint: dms_reference_pkey, update_columns: [name, reference, type]}
  ) {
    affected_rows
  }
  update_address(_set: $address, pk_columns: {id: $addressId}) {
    id
  }
  update_supplier(_set: $supplier, pk_columns: {id: $supplierId}) {
    ...SupplierDetailFields
  }
}
    ${SupplierDetailFieldsFragmentDoc}`;
export type UpdateSupplierMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierMutation, UpdateSupplierMutationVariables>;

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      supplierId: // value for 'supplierId'
 *      addressId: // value for 'addressId'
 *      refIds: // value for 'refIds'
 *      supplier: // value for 'supplier'
 *      address: // value for 'address'
 *      references: // value for 'references'
 *   },
 * });
 */
export function useUpdateSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, baseOptions);
      }
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>;
export const AdminSupplierDetailDocument = gql`
    query AdminSupplierDetail($id: uuid!) {
  supplier(id: $id) {
    ...SupplierDetailFields
  }
}
    ${SupplierDetailFieldsFragmentDoc}`;

/**
 * __useAdminSupplierDetailQuery__
 *
 * To run a query within a React component, call `useAdminSupplierDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSupplierDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSupplierDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminSupplierDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminSupplierDetailQuery, AdminSupplierDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminSupplierDetailQuery, AdminSupplierDetailQueryVariables>(AdminSupplierDetailDocument, baseOptions);
      }
export function useAdminSupplierDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminSupplierDetailQuery, AdminSupplierDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminSupplierDetailQuery, AdminSupplierDetailQueryVariables>(AdminSupplierDetailDocument, baseOptions);
        }
export type AdminSupplierDetailQueryHookResult = ReturnType<typeof useAdminSupplierDetailQuery>;
export type AdminSupplierDetailLazyQueryHookResult = ReturnType<typeof useAdminSupplierDetailLazyQuery>;
export type AdminSupplierDetailQueryResult = ApolloReactCommon.QueryResult<AdminSupplierDetailQuery, AdminSupplierDetailQueryVariables>;
export const CreateTicketTemplateDocument = gql`
    mutation CreateTicketTemplate($input: TicketTemplate_insert_input!) {
  insert_ticket_template(object: $input) {
    ...TicketTemplateDetailFields
  }
}
    ${TicketTemplateDetailFieldsFragmentDoc}`;
export type CreateTicketTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateTicketTemplateMutation, CreateTicketTemplateMutationVariables>;

/**
 * __useCreateTicketTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTicketTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketTemplateMutation, { data, loading, error }] = useCreateTicketTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTicketTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTicketTemplateMutation, CreateTicketTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTicketTemplateMutation, CreateTicketTemplateMutationVariables>(CreateTicketTemplateDocument, baseOptions);
      }
export type CreateTicketTemplateMutationHookResult = ReturnType<typeof useCreateTicketTemplateMutation>;
export type CreateTicketTemplateMutationResult = ApolloReactCommon.MutationResult<CreateTicketTemplateMutation>;
export type CreateTicketTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTicketTemplateMutation, CreateTicketTemplateMutationVariables>;
export const UpdateTicketTemplateDocument = gql`
    mutation UpdateTicketTemplate($id: uuid!, $set: TicketTemplate_set_input) {
  update_ticket_template(pk_columns: {id: $id}, _set: $set) {
    ...TicketTemplateDetailFields
  }
}
    ${TicketTemplateDetailFieldsFragmentDoc}`;
export type UpdateTicketTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateTicketTemplateMutation, UpdateTicketTemplateMutationVariables>;

/**
 * __useUpdateTicketTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTicketTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketTemplateMutation, { data, loading, error }] = useUpdateTicketTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateTicketTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTicketTemplateMutation, UpdateTicketTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTicketTemplateMutation, UpdateTicketTemplateMutationVariables>(UpdateTicketTemplateDocument, baseOptions);
      }
export type UpdateTicketTemplateMutationHookResult = ReturnType<typeof useUpdateTicketTemplateMutation>;
export type UpdateTicketTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateTicketTemplateMutation>;
export type UpdateTicketTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTicketTemplateMutation, UpdateTicketTemplateMutationVariables>;
export const TicketTemplateDetailDocument = gql`
    query TicketTemplateDetail($id: uuid!) {
  ticket_template(id: $id) {
    ...TicketTemplateDetailFields
  }
}
    ${TicketTemplateDetailFieldsFragmentDoc}`;

/**
 * __useTicketTemplateDetailQuery__
 *
 * To run a query within a React component, call `useTicketTemplateDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketTemplateDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketTemplateDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketTemplateDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TicketTemplateDetailQuery, TicketTemplateDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<TicketTemplateDetailQuery, TicketTemplateDetailQueryVariables>(TicketTemplateDetailDocument, baseOptions);
      }
export function useTicketTemplateDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TicketTemplateDetailQuery, TicketTemplateDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TicketTemplateDetailQuery, TicketTemplateDetailQueryVariables>(TicketTemplateDetailDocument, baseOptions);
        }
export type TicketTemplateDetailQueryHookResult = ReturnType<typeof useTicketTemplateDetailQuery>;
export type TicketTemplateDetailLazyQueryHookResult = ReturnType<typeof useTicketTemplateDetailLazyQuery>;
export type TicketTemplateDetailQueryResult = ApolloReactCommon.QueryResult<TicketTemplateDetailQuery, TicketTemplateDetailQueryVariables>;
export const DeleteDepartmentDocument = gql`
    mutation DeleteDepartment($id: uuid!) {
  delete_department(id: $id) {
    id
  }
}
    `;
export type DeleteDepartmentMutationFn = ApolloReactCommon.MutationFunction<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>;

/**
 * __useDeleteDepartmentMutation__
 *
 * To run a mutation, you first call `useDeleteDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepartmentMutation, { data, loading, error }] = useDeleteDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDepartmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>(DeleteDepartmentDocument, baseOptions);
      }
export type DeleteDepartmentMutationHookResult = ReturnType<typeof useDeleteDepartmentMutation>;
export type DeleteDepartmentMutationResult = ApolloReactCommon.MutationResult<DeleteDepartmentMutation>;
export type DeleteDepartmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>;
export const DepartmentsListDocument = gql`
    subscription DepartmentsList {
  departments {
    ...DepartmentFields
  }
}
    ${DepartmentFieldsFragmentDoc}`;

/**
 * __useDepartmentsListSubscription__
 *
 * To run a query within a React component, call `useDepartmentsListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsListSubscription({
 *   variables: {
 *   },
 * });
 */
export function useDepartmentsListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<DepartmentsListSubscription, DepartmentsListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<DepartmentsListSubscription, DepartmentsListSubscriptionVariables>(DepartmentsListDocument, baseOptions);
      }
export type DepartmentsListSubscriptionHookResult = ReturnType<typeof useDepartmentsListSubscription>;
export type DepartmentsListSubscriptionResult = ApolloReactCommon.SubscriptionResult<DepartmentsListSubscription>;
export const UserDetailDocument = gql`
    query UserDetail($id: uuid!) {
  user(id: $id) {
    id
    first_name
    last_name
    email
    isActive
    primary_role
    abbreviation
    last_login
  }
}
    `;

/**
 * __useUserDetailQuery__
 *
 * To run a query within a React component, call `useUserDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserDetailQuery, UserDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, baseOptions);
      }
export function useUserDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserDetailQuery, UserDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, baseOptions);
        }
export type UserDetailQueryHookResult = ReturnType<typeof useUserDetailQuery>;
export type UserDetailLazyQueryHookResult = ReturnType<typeof useUserDetailLazyQuery>;
export type UserDetailQueryResult = ApolloReactCommon.QueryResult<UserDetailQuery, UserDetailQueryVariables>;
export const UserTeamsDocument = gql`
    subscription UserTeams($userId: uuid!) {
  team_assignments(where: {user_id: {_eq: $userId}}) {
    ...TeamAssignmentFragment
  }
}
    ${TeamAssignmentFragmentFragmentDoc}`;

/**
 * __useUserTeamsSubscription__
 *
 * To run a query within a React component, call `useUserTeamsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserTeamsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTeamsSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserTeamsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<UserTeamsSubscription, UserTeamsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<UserTeamsSubscription, UserTeamsSubscriptionVariables>(UserTeamsDocument, baseOptions);
      }
export type UserTeamsSubscriptionHookResult = ReturnType<typeof useUserTeamsSubscription>;
export type UserTeamsSubscriptionResult = ApolloReactCommon.SubscriptionResult<UserTeamsSubscription>;
export const UserTeamDetailDocument = gql`
    query UserTeamDetail($id: uuid!) {
  team_assignment(id: $id) {
    ...TeamAssignmentFragment
  }
}
    ${TeamAssignmentFragmentFragmentDoc}`;

/**
 * __useUserTeamDetailQuery__
 *
 * To run a query within a React component, call `useUserTeamDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTeamDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTeamDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTeamDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserTeamDetailQuery, UserTeamDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<UserTeamDetailQuery, UserTeamDetailQueryVariables>(UserTeamDetailDocument, baseOptions);
      }
export function useUserTeamDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserTeamDetailQuery, UserTeamDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserTeamDetailQuery, UserTeamDetailQueryVariables>(UserTeamDetailDocument, baseOptions);
        }
export type UserTeamDetailQueryHookResult = ReturnType<typeof useUserTeamDetailQuery>;
export type UserTeamDetailLazyQueryHookResult = ReturnType<typeof useUserTeamDetailLazyQuery>;
export type UserTeamDetailQueryResult = ApolloReactCommon.QueryResult<UserTeamDetailQuery, UserTeamDetailQueryVariables>;
export const DepartmentRolesDocument = gql`
    query DepartmentRoles($departmentId: uuid!) {
  department_role(where: {department_id: {_eq: $departmentId}}) {
    department_id
    role
  }
}
    `;

/**
 * __useDepartmentRolesQuery__
 *
 * To run a query within a React component, call `useDepartmentRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentRolesQuery({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useDepartmentRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartmentRolesQuery, DepartmentRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<DepartmentRolesQuery, DepartmentRolesQueryVariables>(DepartmentRolesDocument, baseOptions);
      }
export function useDepartmentRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartmentRolesQuery, DepartmentRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepartmentRolesQuery, DepartmentRolesQueryVariables>(DepartmentRolesDocument, baseOptions);
        }
export type DepartmentRolesQueryHookResult = ReturnType<typeof useDepartmentRolesQuery>;
export type DepartmentRolesLazyQueryHookResult = ReturnType<typeof useDepartmentRolesLazyQuery>;
export type DepartmentRolesQueryResult = ApolloReactCommon.QueryResult<DepartmentRolesQuery, DepartmentRolesQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($_set: User_set_input, $id: uuid!, $role: role_enum!) {
  update_user(_set: $_set, pk_columns: {id: $id}) {
    id
  }
  delete_team_assignments(where: {user_id: {_eq: $id}, is_primary: {_eq: true}}) {
    affected_rows
  }
  insert_team_assignment(object: {user_id: $id, is_primary: true, role: $role}) {
    id
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: uuid!) {
  delete_user(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const InsertTeamAssignmentDocument = gql`
    mutation InsertTeamAssignment($object: TeamAssignment_insert_input!) {
  insert_team_assignment(object: $object) {
    id
  }
}
    `;
export type InsertTeamAssignmentMutationFn = ApolloReactCommon.MutationFunction<InsertTeamAssignmentMutation, InsertTeamAssignmentMutationVariables>;

/**
 * __useInsertTeamAssignmentMutation__
 *
 * To run a mutation, you first call `useInsertTeamAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTeamAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTeamAssignmentMutation, { data, loading, error }] = useInsertTeamAssignmentMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertTeamAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertTeamAssignmentMutation, InsertTeamAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertTeamAssignmentMutation, InsertTeamAssignmentMutationVariables>(InsertTeamAssignmentDocument, baseOptions);
      }
export type InsertTeamAssignmentMutationHookResult = ReturnType<typeof useInsertTeamAssignmentMutation>;
export type InsertTeamAssignmentMutationResult = ApolloReactCommon.MutationResult<InsertTeamAssignmentMutation>;
export type InsertTeamAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertTeamAssignmentMutation, InsertTeamAssignmentMutationVariables>;
export const UpdateTeamAssignmentDocument = gql`
    mutation UpdateTeamAssignment($id: uuid!, $_set: TeamAssignment_set_input) {
  update_team_assignment(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}
    `;
export type UpdateTeamAssignmentMutationFn = ApolloReactCommon.MutationFunction<UpdateTeamAssignmentMutation, UpdateTeamAssignmentMutationVariables>;

/**
 * __useUpdateTeamAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateTeamAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamAssignmentMutation, { data, loading, error }] = useUpdateTeamAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateTeamAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamAssignmentMutation, UpdateTeamAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTeamAssignmentMutation, UpdateTeamAssignmentMutationVariables>(UpdateTeamAssignmentDocument, baseOptions);
      }
export type UpdateTeamAssignmentMutationHookResult = ReturnType<typeof useUpdateTeamAssignmentMutation>;
export type UpdateTeamAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateTeamAssignmentMutation>;
export type UpdateTeamAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeamAssignmentMutation, UpdateTeamAssignmentMutationVariables>;
export const DeleteTeamAssignmentDocument = gql`
    mutation DeleteTeamAssignment($id: uuid!) {
  delete_team_assignment(id: $id) {
    id
  }
}
    `;
export type DeleteTeamAssignmentMutationFn = ApolloReactCommon.MutationFunction<DeleteTeamAssignmentMutation, DeleteTeamAssignmentMutationVariables>;

/**
 * __useDeleteTeamAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteTeamAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamAssignmentMutation, { data, loading, error }] = useDeleteTeamAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTeamAssignmentMutation, DeleteTeamAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTeamAssignmentMutation, DeleteTeamAssignmentMutationVariables>(DeleteTeamAssignmentDocument, baseOptions);
      }
export type DeleteTeamAssignmentMutationHookResult = ReturnType<typeof useDeleteTeamAssignmentMutation>;
export type DeleteTeamAssignmentMutationResult = ApolloReactCommon.MutationResult<DeleteTeamAssignmentMutation>;
export type DeleteTeamAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTeamAssignmentMutation, DeleteTeamAssignmentMutationVariables>;
export const AdminVehicleImportJobDocument = gql`
    query AdminVehicleImportJob($id: uuid!) {
  importJob(input: {id: $id, vehicle_variant: true}) {
    id
    url
    import_job {
      id
      upload_file_excel_definition
      import_template: vehicle_import_template {
        id
        assignments {
          id
          header_cell
          key
        }
        parent_template {
          id
          name
          assignments {
            id
            header_cell
            key
          }
        }
      }
      status
      matching_field
      filter
      upload_file_name
      created_at
      file_key
      executables: vehicle_executables(order_by: {row_number: asc}) {
        id
        row_number
        validation_error
        import_error
        executable_status
        vin
        job_number
        state
        order_state
        type
        min_holding_period
        customer
        sold_at
        key_kabinet_number
        registration_owner
        registration_state
        delivery_date_str
        specs
        spec_level
      }
    }
  }
}
    `;

/**
 * __useAdminVehicleImportJobQuery__
 *
 * To run a query within a React component, call `useAdminVehicleImportJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminVehicleImportJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminVehicleImportJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminVehicleImportJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminVehicleImportJobQuery, AdminVehicleImportJobQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminVehicleImportJobQuery, AdminVehicleImportJobQueryVariables>(AdminVehicleImportJobDocument, baseOptions);
      }
export function useAdminVehicleImportJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminVehicleImportJobQuery, AdminVehicleImportJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminVehicleImportJobQuery, AdminVehicleImportJobQueryVariables>(AdminVehicleImportJobDocument, baseOptions);
        }
export type AdminVehicleImportJobQueryHookResult = ReturnType<typeof useAdminVehicleImportJobQuery>;
export type AdminVehicleImportJobLazyQueryHookResult = ReturnType<typeof useAdminVehicleImportJobLazyQuery>;
export type AdminVehicleImportJobQueryResult = ApolloReactCommon.QueryResult<AdminVehicleImportJobQuery, AdminVehicleImportJobQueryVariables>;
export const AddAfterSalesPotentialNoteDocument = gql`
    mutation AddAfterSalesPotentialNote($customerId: uuid!, $potentialId: uuid!, $vehicleId: uuid, $businessCaseNumber: Int, $version: Int, $customerVehicleId: uuid, $type: contact_note_type_enum!, $note: String!) {
  insert_note(
    object: {after_sales_potential_id: $potentialId, customer_id: $customerId, customer_vehicle_id: $customerVehicleId, vehicle_id: $vehicleId, vehicle_business_case_number: $businessCaseNumber, vehicle_version: $version, note: $note, type: $type}
  ) {
    id
    type
    note
    created_at
  }
}
    `;
export type AddAfterSalesPotentialNoteMutationFn = ApolloReactCommon.MutationFunction<AddAfterSalesPotentialNoteMutation, AddAfterSalesPotentialNoteMutationVariables>;

/**
 * __useAddAfterSalesPotentialNoteMutation__
 *
 * To run a mutation, you first call `useAddAfterSalesPotentialNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAfterSalesPotentialNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAfterSalesPotentialNoteMutation, { data, loading, error }] = useAddAfterSalesPotentialNoteMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      potentialId: // value for 'potentialId'
 *      vehicleId: // value for 'vehicleId'
 *      businessCaseNumber: // value for 'businessCaseNumber'
 *      version: // value for 'version'
 *      customerVehicleId: // value for 'customerVehicleId'
 *      type: // value for 'type'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useAddAfterSalesPotentialNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAfterSalesPotentialNoteMutation, AddAfterSalesPotentialNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAfterSalesPotentialNoteMutation, AddAfterSalesPotentialNoteMutationVariables>(AddAfterSalesPotentialNoteDocument, baseOptions);
      }
export type AddAfterSalesPotentialNoteMutationHookResult = ReturnType<typeof useAddAfterSalesPotentialNoteMutation>;
export type AddAfterSalesPotentialNoteMutationResult = ApolloReactCommon.MutationResult<AddAfterSalesPotentialNoteMutation>;
export type AddAfterSalesPotentialNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAfterSalesPotentialNoteMutation, AddAfterSalesPotentialNoteMutationVariables>;
export const AfterSalesPotentialDetailsDocument = gql`
    subscription AfterSalesPotentialDetails($id: uuid!) {
  after_sales_potential(id: $id) {
    id
    year
    month
    status
    customer_vehicle {
      id
      vin
      brand {
        id
        name
      }
      model {
        id
        description
      }
      model_description
    }
    vehicle {
      id
      business_case_number
      version
      vin
      brand {
        id
        name
      }
      model_name
    }
    customer {
      id
      customer_no
      name
      salutation(args: {salutation_type: "GENERIC"})
      city
      zip_code
      street
      phone_numbers {
        id
        number
        type
        description
      }
      notes(order_by: {created_at: asc}) {
        id
        type
        note
        created_at
      }
    }
    license_plate_number
    mileage
    mileage_date
    first_registration_date
    warranty_end
    warranty_start
    customer_name
    customer_city
    customer_zip_code
    last_service_date
    last_inspection_sticker_date
  }
}
    `;

/**
 * __useAfterSalesPotentialDetailsSubscription__
 *
 * To run a query within a React component, call `useAfterSalesPotentialDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAfterSalesPotentialDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAfterSalesPotentialDetailsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAfterSalesPotentialDetailsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AfterSalesPotentialDetailsSubscription, AfterSalesPotentialDetailsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<AfterSalesPotentialDetailsSubscription, AfterSalesPotentialDetailsSubscriptionVariables>(AfterSalesPotentialDetailsDocument, baseOptions);
      }
export type AfterSalesPotentialDetailsSubscriptionHookResult = ReturnType<typeof useAfterSalesPotentialDetailsSubscription>;
export type AfterSalesPotentialDetailsSubscriptionResult = ApolloReactCommon.SubscriptionResult<AfterSalesPotentialDetailsSubscription>;
export const PrimeMenuDocument = gql`
    query PrimeMenu {
  brands(where: {is_house_brand: {_eq: true}}) {
    id
    name
  }
}
    `;

/**
 * __usePrimeMenuQuery__
 *
 * To run a query within a React component, call `usePrimeMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrimeMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrimeMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrimeMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PrimeMenuQuery, PrimeMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<PrimeMenuQuery, PrimeMenuQueryVariables>(PrimeMenuDocument, baseOptions);
      }
export function usePrimeMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrimeMenuQuery, PrimeMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PrimeMenuQuery, PrimeMenuQueryVariables>(PrimeMenuDocument, baseOptions);
        }
export type PrimeMenuQueryHookResult = ReturnType<typeof usePrimeMenuQuery>;
export type PrimeMenuLazyQueryHookResult = ReturnType<typeof usePrimeMenuLazyQuery>;
export type PrimeMenuQueryResult = ApolloReactCommon.QueryResult<PrimeMenuQuery, PrimeMenuQueryVariables>;
export const TicketTemplateAssignToSelectDataDocument = gql`
    query TicketTemplateAssignToSelectData {
  ticket_template_assignee_ref {
    ...TicketTemplateAssignToSelectFields
  }
}
    ${TicketTemplateAssignToSelectFieldsFragmentDoc}`;

/**
 * __useTicketTemplateAssignToSelectDataQuery__
 *
 * To run a query within a React component, call `useTicketTemplateAssignToSelectDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketTemplateAssignToSelectDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketTemplateAssignToSelectDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketTemplateAssignToSelectDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TicketTemplateAssignToSelectDataQuery, TicketTemplateAssignToSelectDataQueryVariables>) {
        return ApolloReactHooks.useQuery<TicketTemplateAssignToSelectDataQuery, TicketTemplateAssignToSelectDataQueryVariables>(TicketTemplateAssignToSelectDataDocument, baseOptions);
      }
export function useTicketTemplateAssignToSelectDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TicketTemplateAssignToSelectDataQuery, TicketTemplateAssignToSelectDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TicketTemplateAssignToSelectDataQuery, TicketTemplateAssignToSelectDataQueryVariables>(TicketTemplateAssignToSelectDataDocument, baseOptions);
        }
export type TicketTemplateAssignToSelectDataQueryHookResult = ReturnType<typeof useTicketTemplateAssignToSelectDataQuery>;
export type TicketTemplateAssignToSelectDataLazyQueryHookResult = ReturnType<typeof useTicketTemplateAssignToSelectDataLazyQuery>;
export type TicketTemplateAssignToSelectDataQueryResult = ApolloReactCommon.QueryResult<TicketTemplateAssignToSelectDataQuery, TicketTemplateAssignToSelectDataQueryVariables>;
export const TicketTemplateLocationSelectDataDocument = gql`
    query TicketTemplateLocationSelectData {
  ticket_template_location_ref {
    ...TicketTemplateLocationSelectFields
  }
}
    ${TicketTemplateLocationSelectFieldsFragmentDoc}`;

/**
 * __useTicketTemplateLocationSelectDataQuery__
 *
 * To run a query within a React component, call `useTicketTemplateLocationSelectDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketTemplateLocationSelectDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketTemplateLocationSelectDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketTemplateLocationSelectDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TicketTemplateLocationSelectDataQuery, TicketTemplateLocationSelectDataQueryVariables>) {
        return ApolloReactHooks.useQuery<TicketTemplateLocationSelectDataQuery, TicketTemplateLocationSelectDataQueryVariables>(TicketTemplateLocationSelectDataDocument, baseOptions);
      }
export function useTicketTemplateLocationSelectDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TicketTemplateLocationSelectDataQuery, TicketTemplateLocationSelectDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TicketTemplateLocationSelectDataQuery, TicketTemplateLocationSelectDataQueryVariables>(TicketTemplateLocationSelectDataDocument, baseOptions);
        }
export type TicketTemplateLocationSelectDataQueryHookResult = ReturnType<typeof useTicketTemplateLocationSelectDataQuery>;
export type TicketTemplateLocationSelectDataLazyQueryHookResult = ReturnType<typeof useTicketTemplateLocationSelectDataLazyQuery>;
export type TicketTemplateLocationSelectDataQueryResult = ApolloReactCommon.QueryResult<TicketTemplateLocationSelectDataQuery, TicketTemplateLocationSelectDataQueryVariables>;
export const RecoverVehiclesHistoriesDocument = gql`
    mutation RecoverVehiclesHistories($vehicleIds: [String!]!) {
  recoverVehiclesHistories(input: {vehicleIds: $vehicleIds}) {
    status
    message
  }
}
    `;
export type RecoverVehiclesHistoriesMutationFn = ApolloReactCommon.MutationFunction<RecoverVehiclesHistoriesMutation, RecoverVehiclesHistoriesMutationVariables>;

/**
 * __useRecoverVehiclesHistoriesMutation__
 *
 * To run a mutation, you first call `useRecoverVehiclesHistoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverVehiclesHistoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverVehiclesHistoriesMutation, { data, loading, error }] = useRecoverVehiclesHistoriesMutation({
 *   variables: {
 *      vehicleIds: // value for 'vehicleIds'
 *   },
 * });
 */
export function useRecoverVehiclesHistoriesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecoverVehiclesHistoriesMutation, RecoverVehiclesHistoriesMutationVariables>) {
        return ApolloReactHooks.useMutation<RecoverVehiclesHistoriesMutation, RecoverVehiclesHistoriesMutationVariables>(RecoverVehiclesHistoriesDocument, baseOptions);
      }
export type RecoverVehiclesHistoriesMutationHookResult = ReturnType<typeof useRecoverVehiclesHistoriesMutation>;
export type RecoverVehiclesHistoriesMutationResult = ApolloReactCommon.MutationResult<RecoverVehiclesHistoriesMutation>;
export type RecoverVehiclesHistoriesMutationOptions = ApolloReactCommon.BaseMutationOptions<RecoverVehiclesHistoriesMutation, RecoverVehiclesHistoriesMutationVariables>;
export const VehiclesWithMixedUpInvoicesDocument = gql`
    query VehiclesWithMixedUpInvoices($offset: Int) {
  vehiclesWithMixedUpInvoices(input: {offset: $offset}) {
    vehicles {
      id
      vin
    }
    cursor {
      next
      hasNextPage
    }
  }
}
    `;

/**
 * __useVehiclesWithMixedUpInvoicesQuery__
 *
 * To run a query within a React component, call `useVehiclesWithMixedUpInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesWithMixedUpInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesWithMixedUpInvoicesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useVehiclesWithMixedUpInvoicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VehiclesWithMixedUpInvoicesQuery, VehiclesWithMixedUpInvoicesQueryVariables>) {
        return ApolloReactHooks.useQuery<VehiclesWithMixedUpInvoicesQuery, VehiclesWithMixedUpInvoicesQueryVariables>(VehiclesWithMixedUpInvoicesDocument, baseOptions);
      }
export function useVehiclesWithMixedUpInvoicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VehiclesWithMixedUpInvoicesQuery, VehiclesWithMixedUpInvoicesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VehiclesWithMixedUpInvoicesQuery, VehiclesWithMixedUpInvoicesQueryVariables>(VehiclesWithMixedUpInvoicesDocument, baseOptions);
        }
export type VehiclesWithMixedUpInvoicesQueryHookResult = ReturnType<typeof useVehiclesWithMixedUpInvoicesQuery>;
export type VehiclesWithMixedUpInvoicesLazyQueryHookResult = ReturnType<typeof useVehiclesWithMixedUpInvoicesLazyQuery>;
export type VehiclesWithMixedUpInvoicesQueryResult = ApolloReactCommon.QueryResult<VehiclesWithMixedUpInvoicesQuery, VehiclesWithMixedUpInvoicesQueryVariables>;
export const MarkNotificationAsReadDocument = gql`
    mutation MarkNotificationAsRead($id: uuid!, $assetLinkFamily: Boolean!, $ticketFamily: Boolean!) {
  update_asset_link_family_notifications_by_pk(
    pk_columns: {id: $id}
    _set: {status: READ}
  ) @include(if: $assetLinkFamily) {
    id
    status
  }
  update_ticket_family_notifications_by_pk(
    pk_columns: {id: $id}
    _set: {status: READ}
  ) @include(if: $ticketFamily) {
    id
    status
  }
}
    `;
export type MarkNotificationAsReadMutationFn = ApolloReactCommon.MutationFunction<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      assetLinkFamily: // value for 'assetLinkFamily'
 *      ticketFamily: // value for 'ticketFamily'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocument, baseOptions);
      }
export type MarkNotificationAsReadMutationHookResult = ReturnType<typeof useMarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationResult = ApolloReactCommon.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;
export const NotificationsDocument = gql`
    subscription Notifications($id: uuid) {
  notifications(
    where: {user_id: {_eq: $id}}
    order_by: {created_at: desc}
    limit: 5
  ) {
    id
    content
    created_at
    status
    title
    type
    asset_watchdog_link {
      id
      name
      source_url
    }
    ticket {
      id
      title
      vehicle {
        id
        business_case_number
        version
        vin
        job_number
      }
    }
  }
}
    `;

/**
 * __useNotificationsSubscription__
 *
 * To run a query within a React component, call `useNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NotificationsSubscription, NotificationsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<NotificationsSubscription, NotificationsSubscriptionVariables>(NotificationsDocument, baseOptions);
      }
export type NotificationsSubscriptionHookResult = ReturnType<typeof useNotificationsSubscription>;
export type NotificationsSubscriptionResult = ApolloReactCommon.SubscriptionResult<NotificationsSubscription>;
export const DepartmentSelectDataDocument = gql`
    query DepartmentSelectData {
  departments {
    ...DepartmentFields
  }
}
    ${DepartmentFieldsFragmentDoc}`;

/**
 * __useDepartmentSelectDataQuery__
 *
 * To run a query within a React component, call `useDepartmentSelectDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentSelectDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentSelectDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDepartmentSelectDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartmentSelectDataQuery, DepartmentSelectDataQueryVariables>) {
        return ApolloReactHooks.useQuery<DepartmentSelectDataQuery, DepartmentSelectDataQueryVariables>(DepartmentSelectDataDocument, baseOptions);
      }
export function useDepartmentSelectDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartmentSelectDataQuery, DepartmentSelectDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepartmentSelectDataQuery, DepartmentSelectDataQueryVariables>(DepartmentSelectDataDocument, baseOptions);
        }
export type DepartmentSelectDataQueryHookResult = ReturnType<typeof useDepartmentSelectDataQuery>;
export type DepartmentSelectDataLazyQueryHookResult = ReturnType<typeof useDepartmentSelectDataLazyQuery>;
export type DepartmentSelectDataQueryResult = ApolloReactCommon.QueryResult<DepartmentSelectDataQuery, DepartmentSelectDataQueryVariables>;
export const EmbeddedPagesSelectDocument = gql`
    query EmbeddedPagesSelect {
  embedded_pages(distinct_on: [name]) {
    id
    name
  }
}
    `;

/**
 * __useEmbeddedPagesSelectQuery__
 *
 * To run a query within a React component, call `useEmbeddedPagesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmbeddedPagesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmbeddedPagesSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmbeddedPagesSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmbeddedPagesSelectQuery, EmbeddedPagesSelectQueryVariables>) {
        return ApolloReactHooks.useQuery<EmbeddedPagesSelectQuery, EmbeddedPagesSelectQueryVariables>(EmbeddedPagesSelectDocument, baseOptions);
      }
export function useEmbeddedPagesSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmbeddedPagesSelectQuery, EmbeddedPagesSelectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmbeddedPagesSelectQuery, EmbeddedPagesSelectQueryVariables>(EmbeddedPagesSelectDocument, baseOptions);
        }
export type EmbeddedPagesSelectQueryHookResult = ReturnType<typeof useEmbeddedPagesSelectQuery>;
export type EmbeddedPagesSelectLazyQueryHookResult = ReturnType<typeof useEmbeddedPagesSelectLazyQuery>;
export type EmbeddedPagesSelectQueryResult = ApolloReactCommon.QueryResult<EmbeddedPagesSelectQuery, EmbeddedPagesSelectQueryVariables>;
export const AddVehicleImportTemplateDocument = gql`
    mutation AddVehicleImportTemplate($templateId: uuid!, $newTemplateId: uuid!, $importJobId: uuid!, $name: String!, $strict: Boolean!, $excelDef: [String!]) {
  update_vehicle_import_templates_by_pk(
    pk_columns: {id: $templateId}
    _set: {tmp: false, name: $name, strict: $strict, excel_definition: $excelDef}
  ) {
    id
  }
  new_import_template: insert_vehicle_import_templates_one(
    object: {name: "Import Job Template", inherited_from: $templateId, id: $newTemplateId}
  ) {
    id
  }
  update_import_jobs_by_pk(
    pk_columns: {id: $importJobId}
    _set: {vehicle_import_template_id: $newTemplateId}
  ) {
    id
  }
}
    `;
export type AddVehicleImportTemplateMutationFn = ApolloReactCommon.MutationFunction<AddVehicleImportTemplateMutation, AddVehicleImportTemplateMutationVariables>;

/**
 * __useAddVehicleImportTemplateMutation__
 *
 * To run a mutation, you first call `useAddVehicleImportTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVehicleImportTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVehicleImportTemplateMutation, { data, loading, error }] = useAddVehicleImportTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      newTemplateId: // value for 'newTemplateId'
 *      importJobId: // value for 'importJobId'
 *      name: // value for 'name'
 *      strict: // value for 'strict'
 *      excelDef: // value for 'excelDef'
 *   },
 * });
 */
export function useAddVehicleImportTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVehicleImportTemplateMutation, AddVehicleImportTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<AddVehicleImportTemplateMutation, AddVehicleImportTemplateMutationVariables>(AddVehicleImportTemplateDocument, baseOptions);
      }
export type AddVehicleImportTemplateMutationHookResult = ReturnType<typeof useAddVehicleImportTemplateMutation>;
export type AddVehicleImportTemplateMutationResult = ApolloReactCommon.MutationResult<AddVehicleImportTemplateMutation>;
export type AddVehicleImportTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<AddVehicleImportTemplateMutation, AddVehicleImportTemplateMutationVariables>;
export const AssignVehicleColumnToFieldDocument = gql`
    mutation AssignVehicleColumnToField($templateId: uuid!, $key: String!, $col: String!) {
  delete_excel_import_assignments(
    where: {vehicle_import_template_id: {_eq: $templateId}, header_cell: {_eq: $col}}
  ) {
    affected_rows
  }
  insert_excel_import_assignments_one(
    object: {key: $key, vehicle_import_template_id: $templateId, header_cell: $col}
  ) {
    id
  }
}
    `;
export type AssignVehicleColumnToFieldMutationFn = ApolloReactCommon.MutationFunction<AssignVehicleColumnToFieldMutation, AssignVehicleColumnToFieldMutationVariables>;

/**
 * __useAssignVehicleColumnToFieldMutation__
 *
 * To run a mutation, you first call `useAssignVehicleColumnToFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVehicleColumnToFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVehicleColumnToFieldMutation, { data, loading, error }] = useAssignVehicleColumnToFieldMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      key: // value for 'key'
 *      col: // value for 'col'
 *   },
 * });
 */
export function useAssignVehicleColumnToFieldMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignVehicleColumnToFieldMutation, AssignVehicleColumnToFieldMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignVehicleColumnToFieldMutation, AssignVehicleColumnToFieldMutationVariables>(AssignVehicleColumnToFieldDocument, baseOptions);
      }
export type AssignVehicleColumnToFieldMutationHookResult = ReturnType<typeof useAssignVehicleColumnToFieldMutation>;
export type AssignVehicleColumnToFieldMutationResult = ApolloReactCommon.MutationResult<AssignVehicleColumnToFieldMutation>;
export type AssignVehicleColumnToFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignVehicleColumnToFieldMutation, AssignVehicleColumnToFieldMutationVariables>;
export const AssignVehicleImportTemplateToImportJobDocument = gql`
    mutation AssignVehicleImportTemplateToImportJob($id: uuid!, $template_id: uuid!) {
  checkImportJobTemplateCompatibility(
    input: {importJobId: $id, vehicleTemplateId: $template_id}
  ) {
    status
  }
}
    `;
export type AssignVehicleImportTemplateToImportJobMutationFn = ApolloReactCommon.MutationFunction<AssignVehicleImportTemplateToImportJobMutation, AssignVehicleImportTemplateToImportJobMutationVariables>;

/**
 * __useAssignVehicleImportTemplateToImportJobMutation__
 *
 * To run a mutation, you first call `useAssignVehicleImportTemplateToImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVehicleImportTemplateToImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVehicleImportTemplateToImportJobMutation, { data, loading, error }] = useAssignVehicleImportTemplateToImportJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *      template_id: // value for 'template_id'
 *   },
 * });
 */
export function useAssignVehicleImportTemplateToImportJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignVehicleImportTemplateToImportJobMutation, AssignVehicleImportTemplateToImportJobMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignVehicleImportTemplateToImportJobMutation, AssignVehicleImportTemplateToImportJobMutationVariables>(AssignVehicleImportTemplateToImportJobDocument, baseOptions);
      }
export type AssignVehicleImportTemplateToImportJobMutationHookResult = ReturnType<typeof useAssignVehicleImportTemplateToImportJobMutation>;
export type AssignVehicleImportTemplateToImportJobMutationResult = ApolloReactCommon.MutationResult<AssignVehicleImportTemplateToImportJobMutation>;
export type AssignVehicleImportTemplateToImportJobMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignVehicleImportTemplateToImportJobMutation, AssignVehicleImportTemplateToImportJobMutationVariables>;
export const ClearImportJobVehicleExecutablesDocument = gql`
    mutation ClearImportJobVehicleExecutables($id: uuid!) {
  delete_import_job_vehicle_executables(where: {import_job_id: {_eq: $id}}) {
    affected_rows
  }
  update_import_jobs_by_pk(pk_columns: {id: $id}, _set: {status: InProgress}) {
    id
  }
}
    `;
export type ClearImportJobVehicleExecutablesMutationFn = ApolloReactCommon.MutationFunction<ClearImportJobVehicleExecutablesMutation, ClearImportJobVehicleExecutablesMutationVariables>;

/**
 * __useClearImportJobVehicleExecutablesMutation__
 *
 * To run a mutation, you first call `useClearImportJobVehicleExecutablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearImportJobVehicleExecutablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearImportJobVehicleExecutablesMutation, { data, loading, error }] = useClearImportJobVehicleExecutablesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClearImportJobVehicleExecutablesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearImportJobVehicleExecutablesMutation, ClearImportJobVehicleExecutablesMutationVariables>) {
        return ApolloReactHooks.useMutation<ClearImportJobVehicleExecutablesMutation, ClearImportJobVehicleExecutablesMutationVariables>(ClearImportJobVehicleExecutablesDocument, baseOptions);
      }
export type ClearImportJobVehicleExecutablesMutationHookResult = ReturnType<typeof useClearImportJobVehicleExecutablesMutation>;
export type ClearImportJobVehicleExecutablesMutationResult = ApolloReactCommon.MutationResult<ClearImportJobVehicleExecutablesMutation>;
export type ClearImportJobVehicleExecutablesMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearImportJobVehicleExecutablesMutation, ClearImportJobVehicleExecutablesMutationVariables>;
export const CreateVehicleImportJobDocument = gql`
    mutation CreateVehicleImportJob($id: uuid!, $fileKey: String!, $matching_field: String!, $filter: String!, $fileName: String!, $templateId: uuid!) {
  insert_import_jobs_one(
    object: {id: $id, status: Initialized, file_key: $fileKey, matching_field: $matching_field, filter: $filter, upload_file_name: $fileName, vehicle_import_template: {data: {id: $templateId, name: "Import job template"}}}
  ) {
    id
  }
}
    `;
export type CreateVehicleImportJobMutationFn = ApolloReactCommon.MutationFunction<CreateVehicleImportJobMutation, CreateVehicleImportJobMutationVariables>;

/**
 * __useCreateVehicleImportJobMutation__
 *
 * To run a mutation, you first call `useCreateVehicleImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleImportJobMutation, { data, loading, error }] = useCreateVehicleImportJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileKey: // value for 'fileKey'
 *      matching_field: // value for 'matching_field'
 *      filter: // value for 'filter'
 *      fileName: // value for 'fileName'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useCreateVehicleImportJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateVehicleImportJobMutation, CreateVehicleImportJobMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateVehicleImportJobMutation, CreateVehicleImportJobMutationVariables>(CreateVehicleImportJobDocument, baseOptions);
      }
export type CreateVehicleImportJobMutationHookResult = ReturnType<typeof useCreateVehicleImportJobMutation>;
export type CreateVehicleImportJobMutationResult = ApolloReactCommon.MutationResult<CreateVehicleImportJobMutation>;
export type CreateVehicleImportJobMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateVehicleImportJobMutation, CreateVehicleImportJobMutationVariables>;
export const DeleteVehicleImportTemplateDocument = gql`
    mutation DeleteVehicleImportTemplate($id: uuid!) {
  delete_vehicle_import_templates_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteVehicleImportTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteVehicleImportTemplateMutation, DeleteVehicleImportTemplateMutationVariables>;

/**
 * __useDeleteVehicleImportTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleImportTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleImportTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleImportTemplateMutation, { data, loading, error }] = useDeleteVehicleImportTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVehicleImportTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVehicleImportTemplateMutation, DeleteVehicleImportTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteVehicleImportTemplateMutation, DeleteVehicleImportTemplateMutationVariables>(DeleteVehicleImportTemplateDocument, baseOptions);
      }
export type DeleteVehicleImportTemplateMutationHookResult = ReturnType<typeof useDeleteVehicleImportTemplateMutation>;
export type DeleteVehicleImportTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteVehicleImportTemplateMutation>;
export type DeleteVehicleImportTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteVehicleImportTemplateMutation, DeleteVehicleImportTemplateMutationVariables>;
export const ExecuteImportJobDocument = gql`
    mutation ExecuteImportJob($id: uuid!) {
  executeImportJob(input: {id: $id}) {
    status
  }
}
    `;
export type ExecuteImportJobMutationFn = ApolloReactCommon.MutationFunction<ExecuteImportJobMutation, ExecuteImportJobMutationVariables>;

/**
 * __useExecuteImportJobMutation__
 *
 * To run a mutation, you first call `useExecuteImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeImportJobMutation, { data, loading, error }] = useExecuteImportJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExecuteImportJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExecuteImportJobMutation, ExecuteImportJobMutationVariables>) {
        return ApolloReactHooks.useMutation<ExecuteImportJobMutation, ExecuteImportJobMutationVariables>(ExecuteImportJobDocument, baseOptions);
      }
export type ExecuteImportJobMutationHookResult = ReturnType<typeof useExecuteImportJobMutation>;
export type ExecuteImportJobMutationResult = ApolloReactCommon.MutationResult<ExecuteImportJobMutation>;
export type ExecuteImportJobMutationOptions = ApolloReactCommon.BaseMutationOptions<ExecuteImportJobMutation, ExecuteImportJobMutationVariables>;
export const InheritFromVehicleImportTemplateDocument = gql`
    mutation InheritFromVehicleImportTemplate($id: uuid!, $parentId: uuid!) {
  update_vehicle_import_templates_by_pk(
    pk_columns: {id: $id}
    _set: {inherited_from: $parentId}
  ) {
    id
  }
}
    `;
export type InheritFromVehicleImportTemplateMutationFn = ApolloReactCommon.MutationFunction<InheritFromVehicleImportTemplateMutation, InheritFromVehicleImportTemplateMutationVariables>;

/**
 * __useInheritFromVehicleImportTemplateMutation__
 *
 * To run a mutation, you first call `useInheritFromVehicleImportTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInheritFromVehicleImportTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inheritFromVehicleImportTemplateMutation, { data, loading, error }] = useInheritFromVehicleImportTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useInheritFromVehicleImportTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InheritFromVehicleImportTemplateMutation, InheritFromVehicleImportTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<InheritFromVehicleImportTemplateMutation, InheritFromVehicleImportTemplateMutationVariables>(InheritFromVehicleImportTemplateDocument, baseOptions);
      }
export type InheritFromVehicleImportTemplateMutationHookResult = ReturnType<typeof useInheritFromVehicleImportTemplateMutation>;
export type InheritFromVehicleImportTemplateMutationResult = ApolloReactCommon.MutationResult<InheritFromVehicleImportTemplateMutation>;
export type InheritFromVehicleImportTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<InheritFromVehicleImportTemplateMutation, InheritFromVehicleImportTemplateMutationVariables>;
export const PreviewImportJobDocument = gql`
    mutation PreviewImportJob($id: uuid!) {
  previewImportJob(input: {id: $id}) {
    status
  }
}
    `;
export type PreviewImportJobMutationFn = ApolloReactCommon.MutationFunction<PreviewImportJobMutation, PreviewImportJobMutationVariables>;

/**
 * __usePreviewImportJobMutation__
 *
 * To run a mutation, you first call `usePreviewImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewImportJobMutation, { data, loading, error }] = usePreviewImportJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreviewImportJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PreviewImportJobMutation, PreviewImportJobMutationVariables>) {
        return ApolloReactHooks.useMutation<PreviewImportJobMutation, PreviewImportJobMutationVariables>(PreviewImportJobDocument, baseOptions);
      }
export type PreviewImportJobMutationHookResult = ReturnType<typeof usePreviewImportJobMutation>;
export type PreviewImportJobMutationResult = ApolloReactCommon.MutationResult<PreviewImportJobMutation>;
export type PreviewImportJobMutationOptions = ApolloReactCommon.BaseMutationOptions<PreviewImportJobMutation, PreviewImportJobMutationVariables>;
export const RemoveParentVehicleImportTemplateDocument = gql`
    mutation RemoveParentVehicleImportTemplate($id: uuid!) {
  update_vehicle_import_templates_by_pk(
    pk_columns: {id: $id}
    _set: {inherited_from: null}
  ) {
    id
  }
}
    `;
export type RemoveParentVehicleImportTemplateMutationFn = ApolloReactCommon.MutationFunction<RemoveParentVehicleImportTemplateMutation, RemoveParentVehicleImportTemplateMutationVariables>;

/**
 * __useRemoveParentVehicleImportTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveParentVehicleImportTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveParentVehicleImportTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeParentVehicleImportTemplateMutation, { data, loading, error }] = useRemoveParentVehicleImportTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveParentVehicleImportTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveParentVehicleImportTemplateMutation, RemoveParentVehicleImportTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveParentVehicleImportTemplateMutation, RemoveParentVehicleImportTemplateMutationVariables>(RemoveParentVehicleImportTemplateDocument, baseOptions);
      }
export type RemoveParentVehicleImportTemplateMutationHookResult = ReturnType<typeof useRemoveParentVehicleImportTemplateMutation>;
export type RemoveParentVehicleImportTemplateMutationResult = ApolloReactCommon.MutationResult<RemoveParentVehicleImportTemplateMutation>;
export type RemoveParentVehicleImportTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveParentVehicleImportTemplateMutation, RemoveParentVehicleImportTemplateMutationVariables>;
export const ResetVehicleExcelAssignmentsDocument = gql`
    mutation ResetVehicleExcelAssignments($templateId: uuid!) {
  delete_excel_import_assignments(
    where: {vehicle_import_template_id: {_eq: $templateId}}
  ) {
    affected_rows
  }
}
    `;
export type ResetVehicleExcelAssignmentsMutationFn = ApolloReactCommon.MutationFunction<ResetVehicleExcelAssignmentsMutation, ResetVehicleExcelAssignmentsMutationVariables>;

/**
 * __useResetVehicleExcelAssignmentsMutation__
 *
 * To run a mutation, you first call `useResetVehicleExcelAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetVehicleExcelAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetVehicleExcelAssignmentsMutation, { data, loading, error }] = useResetVehicleExcelAssignmentsMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useResetVehicleExcelAssignmentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetVehicleExcelAssignmentsMutation, ResetVehicleExcelAssignmentsMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetVehicleExcelAssignmentsMutation, ResetVehicleExcelAssignmentsMutationVariables>(ResetVehicleExcelAssignmentsDocument, baseOptions);
      }
export type ResetVehicleExcelAssignmentsMutationHookResult = ReturnType<typeof useResetVehicleExcelAssignmentsMutation>;
export type ResetVehicleExcelAssignmentsMutationResult = ApolloReactCommon.MutationResult<ResetVehicleExcelAssignmentsMutation>;
export type ResetVehicleExcelAssignmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetVehicleExcelAssignmentsMutation, ResetVehicleExcelAssignmentsMutationVariables>;
export const UnassignVehicleColumnFromFieldDocument = gql`
    mutation UnassignVehicleColumnFromField($templateId: uuid!, $col: String!) {
  delete_excel_import_assignments(
    where: {vehicle_import_template_id: {_eq: $templateId}, header_cell: {_eq: $col}}
  ) {
    affected_rows
  }
}
    `;
export type UnassignVehicleColumnFromFieldMutationFn = ApolloReactCommon.MutationFunction<UnassignVehicleColumnFromFieldMutation, UnassignVehicleColumnFromFieldMutationVariables>;

/**
 * __useUnassignVehicleColumnFromFieldMutation__
 *
 * To run a mutation, you first call `useUnassignVehicleColumnFromFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignVehicleColumnFromFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignVehicleColumnFromFieldMutation, { data, loading, error }] = useUnassignVehicleColumnFromFieldMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      col: // value for 'col'
 *   },
 * });
 */
export function useUnassignVehicleColumnFromFieldMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignVehicleColumnFromFieldMutation, UnassignVehicleColumnFromFieldMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignVehicleColumnFromFieldMutation, UnassignVehicleColumnFromFieldMutationVariables>(UnassignVehicleColumnFromFieldDocument, baseOptions);
      }
export type UnassignVehicleColumnFromFieldMutationHookResult = ReturnType<typeof useUnassignVehicleColumnFromFieldMutation>;
export type UnassignVehicleColumnFromFieldMutationResult = ApolloReactCommon.MutationResult<UnassignVehicleColumnFromFieldMutation>;
export type UnassignVehicleColumnFromFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignVehicleColumnFromFieldMutation, UnassignVehicleColumnFromFieldMutationVariables>;
export const ExcelImportAssignmentsDocument = gql`
    query ExcelImportAssignments($templateId: uuid!, $vehicle_variant: Boolean!) {
  excel_import_assignments(
    where: {vehicle_import_template_id: {_eq: $templateId}}
  ) @include(if: $vehicle_variant) {
    id
    key
    header_cell
    vehicle_import_template_id
  }
}
    `;

/**
 * __useExcelImportAssignmentsQuery__
 *
 * To run a query within a React component, call `useExcelImportAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExcelImportAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExcelImportAssignmentsQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      vehicle_variant: // value for 'vehicle_variant'
 *   },
 * });
 */
export function useExcelImportAssignmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExcelImportAssignmentsQuery, ExcelImportAssignmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExcelImportAssignmentsQuery, ExcelImportAssignmentsQueryVariables>(ExcelImportAssignmentsDocument, baseOptions);
      }
export function useExcelImportAssignmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExcelImportAssignmentsQuery, ExcelImportAssignmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExcelImportAssignmentsQuery, ExcelImportAssignmentsQueryVariables>(ExcelImportAssignmentsDocument, baseOptions);
        }
export type ExcelImportAssignmentsQueryHookResult = ReturnType<typeof useExcelImportAssignmentsQuery>;
export type ExcelImportAssignmentsLazyQueryHookResult = ReturnType<typeof useExcelImportAssignmentsLazyQuery>;
export type ExcelImportAssignmentsQueryResult = ApolloReactCommon.QueryResult<ExcelImportAssignmentsQuery, ExcelImportAssignmentsQueryVariables>;
export const ImportJobUploadUrlDocument = gql`
    query ImportJobUploadUrl {
  importJobUploadUrl {
    uploadUrl
    key
  }
}
    `;

/**
 * __useImportJobUploadUrlQuery__
 *
 * To run a query within a React component, call `useImportJobUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportJobUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportJobUploadUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useImportJobUploadUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ImportJobUploadUrlQuery, ImportJobUploadUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<ImportJobUploadUrlQuery, ImportJobUploadUrlQueryVariables>(ImportJobUploadUrlDocument, baseOptions);
      }
export function useImportJobUploadUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImportJobUploadUrlQuery, ImportJobUploadUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ImportJobUploadUrlQuery, ImportJobUploadUrlQueryVariables>(ImportJobUploadUrlDocument, baseOptions);
        }
export type ImportJobUploadUrlQueryHookResult = ReturnType<typeof useImportJobUploadUrlQuery>;
export type ImportJobUploadUrlLazyQueryHookResult = ReturnType<typeof useImportJobUploadUrlLazyQuery>;
export type ImportJobUploadUrlQueryResult = ApolloReactCommon.QueryResult<ImportJobUploadUrlQuery, ImportJobUploadUrlQueryVariables>;
export const ImportJobPreviewItemsDocument = gql`
    query ImportJobPreviewItems($id: uuid!, $vehicle_variant: Boolean!, $limit: Int!, $offset: Int) {
  import_jobs_by_pk(id: $id) {
    id
    preview_items: vehicle_executables(
      where: {executable_status: {_eq: PreviewSucceeded}}
      order_by: {row_number: asc}
      offset: $offset
      limit: $limit
    ) @include(if: $vehicle_variant) {
      id
      row_number
      validation_error
      executable_status
      vin
      job_number
      state
      order_state
      type
      min_holding_period
      customer
      sold_at
      key_kabinet_number
      registration_owner
      registration_state
      delivery_date_str
      specs
      spec_level
      handover_planned_date
      handover_date
      in_buy_contract_date
    }
    preview_items_aggregate: vehicle_executables_aggregate(
      where: {executable_status: {_eq: PreviewSucceeded}}
    ) @include(if: $vehicle_variant) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useImportJobPreviewItemsQuery__
 *
 * To run a query within a React component, call `useImportJobPreviewItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportJobPreviewItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportJobPreviewItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      vehicle_variant: // value for 'vehicle_variant'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useImportJobPreviewItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ImportJobPreviewItemsQuery, ImportJobPreviewItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<ImportJobPreviewItemsQuery, ImportJobPreviewItemsQueryVariables>(ImportJobPreviewItemsDocument, baseOptions);
      }
export function useImportJobPreviewItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImportJobPreviewItemsQuery, ImportJobPreviewItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ImportJobPreviewItemsQuery, ImportJobPreviewItemsQueryVariables>(ImportJobPreviewItemsDocument, baseOptions);
        }
export type ImportJobPreviewItemsQueryHookResult = ReturnType<typeof useImportJobPreviewItemsQuery>;
export type ImportJobPreviewItemsLazyQueryHookResult = ReturnType<typeof useImportJobPreviewItemsLazyQuery>;
export type ImportJobPreviewItemsQueryResult = ApolloReactCommon.QueryResult<ImportJobPreviewItemsQuery, ImportJobPreviewItemsQueryVariables>;
export const ImportJobDocument = gql`
    subscription ImportJob($id: uuid!, $vehicle_variant: Boolean!) {
  import_jobs_by_pk(id: $id) {
    id
    upload_file_excel_definition
    import_template: vehicle_import_template @include(if: $vehicle_variant) {
      id
      assignments {
        id
        header_cell
        key
      }
      parent_template {
        id
        name
        assignments {
          id
          header_cell
          key
        }
      }
    }
    status
    matching_field
    filter
    upload_file_name
    upload_file_url
    created_at
    executables: vehicle_executables(order_by: {row_number: asc}) @include(if: $vehicle_variant) {
      id
      row_number
      validation_error
      import_error
      executable_status
      vin
      job_number
      state
      order_state
      type
      min_holding_period
      customer
      sold_at
      key_kabinet_number
      registration_owner
      registration_state
      delivery_date_str
      specs
      spec_level
    }
  }
}
    `;

/**
 * __useImportJobSubscription__
 *
 * To run a query within a React component, call `useImportJobSubscription` and pass it any options that fit your needs.
 * When your component renders, `useImportJobSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportJobSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      vehicle_variant: // value for 'vehicle_variant'
 *   },
 * });
 */
export function useImportJobSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ImportJobSubscription, ImportJobSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ImportJobSubscription, ImportJobSubscriptionVariables>(ImportJobDocument, baseOptions);
      }
export type ImportJobSubscriptionHookResult = ReturnType<typeof useImportJobSubscription>;
export type ImportJobSubscriptionResult = ApolloReactCommon.SubscriptionResult<ImportJobSubscription>;
export const ImportTemplatesDocument = gql`
    subscription ImportTemplates($vehicle_variant: Boolean!) {
  import_templates: vehicle_import_templates(where: {tmp: {_eq: false}}) @include(if: $vehicle_variant) {
    id
    name
    strict
    excel_definition
  }
}
    `;

/**
 * __useImportTemplatesSubscription__
 *
 * To run a query within a React component, call `useImportTemplatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useImportTemplatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportTemplatesSubscription({
 *   variables: {
 *      vehicle_variant: // value for 'vehicle_variant'
 *   },
 * });
 */
export function useImportTemplatesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ImportTemplatesSubscription, ImportTemplatesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ImportTemplatesSubscription, ImportTemplatesSubscriptionVariables>(ImportTemplatesDocument, baseOptions);
      }
export type ImportTemplatesSubscriptionHookResult = ReturnType<typeof useImportTemplatesSubscription>;
export type ImportTemplatesSubscriptionResult = ApolloReactCommon.SubscriptionResult<ImportTemplatesSubscription>;
export const AddTicketNoteDocument = gql`
    mutation AddTicketNote($note: TicketNote_insert_input!) {
  insert_ticket_note(object: $note) {
    id
  }
}
    `;
export type AddTicketNoteMutationFn = ApolloReactCommon.MutationFunction<AddTicketNoteMutation, AddTicketNoteMutationVariables>;

/**
 * __useAddTicketNoteMutation__
 *
 * To run a mutation, you first call `useAddTicketNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTicketNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTicketNoteMutation, { data, loading, error }] = useAddTicketNoteMutation({
 *   variables: {
 *      note: // value for 'note'
 *   },
 * });
 */
export function useAddTicketNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTicketNoteMutation, AddTicketNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTicketNoteMutation, AddTicketNoteMutationVariables>(AddTicketNoteDocument, baseOptions);
      }
export type AddTicketNoteMutationHookResult = ReturnType<typeof useAddTicketNoteMutation>;
export type AddTicketNoteMutationResult = ApolloReactCommon.MutationResult<AddTicketNoteMutation>;
export type AddTicketNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTicketNoteMutation, AddTicketNoteMutationVariables>;
export const AssignTicketDocument = gql`
    mutation AssignTicket($ticketId: uuid!, $assigneeId: uuid!) {
  update_ticket(pk_columns: {id: $ticketId}, _set: {assignee_id: $assigneeId}) {
    id
    assignee_id
  }
}
    `;
export type AssignTicketMutationFn = ApolloReactCommon.MutationFunction<AssignTicketMutation, AssignTicketMutationVariables>;

/**
 * __useAssignTicketMutation__
 *
 * To run a mutation, you first call `useAssignTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTicketMutation, { data, loading, error }] = useAssignTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      assigneeId: // value for 'assigneeId'
 *   },
 * });
 */
export function useAssignTicketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignTicketMutation, AssignTicketMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignTicketMutation, AssignTicketMutationVariables>(AssignTicketDocument, baseOptions);
      }
export type AssignTicketMutationHookResult = ReturnType<typeof useAssignTicketMutation>;
export type AssignTicketMutationResult = ApolloReactCommon.MutationResult<AssignTicketMutation>;
export type AssignTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignTicketMutation, AssignTicketMutationVariables>;
export const DeleteTicketNoteDocument = gql`
    mutation DeleteTicketNote($id: uuid!) {
  delete_ticket_note(id: $id) {
    id
  }
}
    `;
export type DeleteTicketNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteTicketNoteMutation, DeleteTicketNoteMutationVariables>;

/**
 * __useDeleteTicketNoteMutation__
 *
 * To run a mutation, you first call `useDeleteTicketNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketNoteMutation, { data, loading, error }] = useDeleteTicketNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTicketNoteMutation, DeleteTicketNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTicketNoteMutation, DeleteTicketNoteMutationVariables>(DeleteTicketNoteDocument, baseOptions);
      }
export type DeleteTicketNoteMutationHookResult = ReturnType<typeof useDeleteTicketNoteMutation>;
export type DeleteTicketNoteMutationResult = ApolloReactCommon.MutationResult<DeleteTicketNoteMutation>;
export type DeleteTicketNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTicketNoteMutation, DeleteTicketNoteMutationVariables>;
export const MarkTicketAsDoneDocument = gql`
    mutation MarkTicketAsDone($id: uuid!) {
  update_ticket(pk_columns: {id: $id}, _set: {state: DONE}) {
    id
  }
}
    `;
export type MarkTicketAsDoneMutationFn = ApolloReactCommon.MutationFunction<MarkTicketAsDoneMutation, MarkTicketAsDoneMutationVariables>;

/**
 * __useMarkTicketAsDoneMutation__
 *
 * To run a mutation, you first call `useMarkTicketAsDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTicketAsDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTicketAsDoneMutation, { data, loading, error }] = useMarkTicketAsDoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkTicketAsDoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkTicketAsDoneMutation, MarkTicketAsDoneMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkTicketAsDoneMutation, MarkTicketAsDoneMutationVariables>(MarkTicketAsDoneDocument, baseOptions);
      }
export type MarkTicketAsDoneMutationHookResult = ReturnType<typeof useMarkTicketAsDoneMutation>;
export type MarkTicketAsDoneMutationResult = ApolloReactCommon.MutationResult<MarkTicketAsDoneMutation>;
export type MarkTicketAsDoneMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkTicketAsDoneMutation, MarkTicketAsDoneMutationVariables>;
export const UpdateTicketDocument = gql`
    mutation UpdateTicket($id: uuid!, $_set: Ticket_set_input) {
  update_ticket(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}
    `;
export type UpdateTicketMutationFn = ApolloReactCommon.MutationFunction<UpdateTicketMutation, UpdateTicketMutationVariables>;

/**
 * __useUpdateTicketMutation__
 *
 * To run a mutation, you first call `useUpdateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketMutation, { data, loading, error }] = useUpdateTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateTicketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTicketMutation, UpdateTicketMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTicketMutation, UpdateTicketMutationVariables>(UpdateTicketDocument, baseOptions);
      }
export type UpdateTicketMutationHookResult = ReturnType<typeof useUpdateTicketMutation>;
export type UpdateTicketMutationResult = ApolloReactCommon.MutationResult<UpdateTicketMutation>;
export type UpdateTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTicketMutation, UpdateTicketMutationVariables>;
export const TicketDetailsDocument = gql`
    subscription TicketDetails($id: uuid!) {
  ticket(id: $id) {
    ...TicketDetailsFields
  }
}
    ${TicketDetailsFieldsFragmentDoc}`;

/**
 * __useTicketDetailsSubscription__
 *
 * To run a query within a React component, call `useTicketDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTicketDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketDetailsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketDetailsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TicketDetailsSubscription, TicketDetailsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TicketDetailsSubscription, TicketDetailsSubscriptionVariables>(TicketDetailsDocument, baseOptions);
      }
export type TicketDetailsSubscriptionHookResult = ReturnType<typeof useTicketDetailsSubscription>;
export type TicketDetailsSubscriptionResult = ApolloReactCommon.SubscriptionResult<TicketDetailsSubscription>;
export const TicketNotesDocument = gql`
    subscription TicketNotes($ticketId: uuid!) {
  ticket_notes(where: {ticket_id: {_eq: $ticketId}}) {
    id
    created_at
    updated_at
    note
    user_id
    user {
      id
      full_name
      abbreviation
      email
    }
  }
}
    `;

/**
 * __useTicketNotesSubscription__
 *
 * To run a query within a React component, call `useTicketNotesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTicketNotesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketNotesSubscription({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useTicketNotesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TicketNotesSubscription, TicketNotesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TicketNotesSubscription, TicketNotesSubscriptionVariables>(TicketNotesDocument, baseOptions);
      }
export type TicketNotesSubscriptionHookResult = ReturnType<typeof useTicketNotesSubscription>;
export type TicketNotesSubscriptionResult = ApolloReactCommon.SubscriptionResult<TicketNotesSubscription>;
export const TicketsListDocument = gql`
    subscription TicketsList($where: Ticket_bool_exp, $order_by: [Ticket_order_by!]) {
  tickets(where: $where, order_by: $order_by) {
    ...TicketsListFields
  }
}
    ${TicketsListFieldsFragmentDoc}`;

/**
 * __useTicketsListSubscription__
 *
 * To run a query within a React component, call `useTicketsListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTicketsListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsListSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useTicketsListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TicketsListSubscription, TicketsListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TicketsListSubscription, TicketsListSubscriptionVariables>(TicketsListDocument, baseOptions);
      }
export type TicketsListSubscriptionHookResult = ReturnType<typeof useTicketsListSubscription>;
export type TicketsListSubscriptionResult = ApolloReactCommon.SubscriptionResult<TicketsListSubscription>;
export const AddVehicleNoteDocument = gql`
    mutation AddVehicleNote($note: Notes_insert_input!) {
  insert_note(object: $note) {
    ...VehicleNoteFields
  }
}
    ${VehicleNoteFieldsFragmentDoc}`;
export type AddVehicleNoteMutationFn = ApolloReactCommon.MutationFunction<AddVehicleNoteMutation, AddVehicleNoteMutationVariables>;

/**
 * __useAddVehicleNoteMutation__
 *
 * To run a mutation, you first call `useAddVehicleNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVehicleNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVehicleNoteMutation, { data, loading, error }] = useAddVehicleNoteMutation({
 *   variables: {
 *      note: // value for 'note'
 *   },
 * });
 */
export function useAddVehicleNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVehicleNoteMutation, AddVehicleNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddVehicleNoteMutation, AddVehicleNoteMutationVariables>(AddVehicleNoteDocument, baseOptions);
      }
export type AddVehicleNoteMutationHookResult = ReturnType<typeof useAddVehicleNoteMutation>;
export type AddVehicleNoteMutationResult = ApolloReactCommon.MutationResult<AddVehicleNoteMutation>;
export type AddVehicleNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddVehicleNoteMutation, AddVehicleNoteMutationVariables>;
export const ArchiveVehicleNoteDocument = gql`
    mutation ArchiveVehicleNote($noteId: uuid!) {
  update_note(pk_columns: {id: $noteId}, _set: {archived: true, sticky: false}) {
    ...VehicleNoteFields
  }
}
    ${VehicleNoteFieldsFragmentDoc}`;
export type ArchiveVehicleNoteMutationFn = ApolloReactCommon.MutationFunction<ArchiveVehicleNoteMutation, ArchiveVehicleNoteMutationVariables>;

/**
 * __useArchiveVehicleNoteMutation__
 *
 * To run a mutation, you first call `useArchiveVehicleNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveVehicleNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveVehicleNoteMutation, { data, loading, error }] = useArchiveVehicleNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useArchiveVehicleNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveVehicleNoteMutation, ArchiveVehicleNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<ArchiveVehicleNoteMutation, ArchiveVehicleNoteMutationVariables>(ArchiveVehicleNoteDocument, baseOptions);
      }
export type ArchiveVehicleNoteMutationHookResult = ReturnType<typeof useArchiveVehicleNoteMutation>;
export type ArchiveVehicleNoteMutationResult = ApolloReactCommon.MutationResult<ArchiveVehicleNoteMutation>;
export type ArchiveVehicleNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveVehicleNoteMutation, ArchiveVehicleNoteMutationVariables>;
export const DeleteVehicleNoteDocument = gql`
    mutation DeleteVehicleNote($noteId: uuid!) {
  delete_note(id: $noteId) {
    id
  }
}
    `;
export type DeleteVehicleNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteVehicleNoteMutation, DeleteVehicleNoteMutationVariables>;

/**
 * __useDeleteVehicleNoteMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleNoteMutation, { data, loading, error }] = useDeleteVehicleNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteVehicleNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVehicleNoteMutation, DeleteVehicleNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteVehicleNoteMutation, DeleteVehicleNoteMutationVariables>(DeleteVehicleNoteDocument, baseOptions);
      }
export type DeleteVehicleNoteMutationHookResult = ReturnType<typeof useDeleteVehicleNoteMutation>;
export type DeleteVehicleNoteMutationResult = ApolloReactCommon.MutationResult<DeleteVehicleNoteMutation>;
export type DeleteVehicleNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteVehicleNoteMutation, DeleteVehicleNoteMutationVariables>;
export const ImportBusinessCasesDocument = gql`
    mutation ImportBusinessCases($ids: [uuid!]!, $imported: timestamptz!, $importType: import_type_enum!) {
  update_vehicles(
    where: {id: {_in: $ids}, business_case_number: {_eq: 0}, version: {_eq: 0}}
    _set: {imported: $imported, import_type: $importType}
  ) {
    affected_rows
  }
}
    `;
export type ImportBusinessCasesMutationFn = ApolloReactCommon.MutationFunction<ImportBusinessCasesMutation, ImportBusinessCasesMutationVariables>;

/**
 * __useImportBusinessCasesMutation__
 *
 * To run a mutation, you first call `useImportBusinessCasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportBusinessCasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importBusinessCasesMutation, { data, loading, error }] = useImportBusinessCasesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      imported: // value for 'imported'
 *      importType: // value for 'importType'
 *   },
 * });
 */
export function useImportBusinessCasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportBusinessCasesMutation, ImportBusinessCasesMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportBusinessCasesMutation, ImportBusinessCasesMutationVariables>(ImportBusinessCasesDocument, baseOptions);
      }
export type ImportBusinessCasesMutationHookResult = ReturnType<typeof useImportBusinessCasesMutation>;
export type ImportBusinessCasesMutationResult = ApolloReactCommon.MutationResult<ImportBusinessCasesMutation>;
export type ImportBusinessCasesMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportBusinessCasesMutation, ImportBusinessCasesMutationVariables>;
export const InsertPricesAndBoniDocument = gql`
    mutation InsertPricesAndBoni($id: uuid!, $business_case_number: Int!, $list_price: numeric, $cash_price: numeric, $boni: [vehicle_boni_insert_input!]!) {
  update_vehicle(
    pk_columns: {id: $id, version: 0, business_case_number: $business_case_number}
    _set: {list_price: $list_price, cash_price: $cash_price}
  ) {
    id
    version
    business_case_number
    list_price
    cash_price
  }
  insert_vehicle_boni(objects: $boni) {
    affected_rows
  }
}
    `;
export type InsertPricesAndBoniMutationFn = ApolloReactCommon.MutationFunction<InsertPricesAndBoniMutation, InsertPricesAndBoniMutationVariables>;

/**
 * __useInsertPricesAndBoniMutation__
 *
 * To run a mutation, you first call `useInsertPricesAndBoniMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPricesAndBoniMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPricesAndBoniMutation, { data, loading, error }] = useInsertPricesAndBoniMutation({
 *   variables: {
 *      id: // value for 'id'
 *      business_case_number: // value for 'business_case_number'
 *      list_price: // value for 'list_price'
 *      cash_price: // value for 'cash_price'
 *      boni: // value for 'boni'
 *   },
 * });
 */
export function useInsertPricesAndBoniMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertPricesAndBoniMutation, InsertPricesAndBoniMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertPricesAndBoniMutation, InsertPricesAndBoniMutationVariables>(InsertPricesAndBoniDocument, baseOptions);
      }
export type InsertPricesAndBoniMutationHookResult = ReturnType<typeof useInsertPricesAndBoniMutation>;
export type InsertPricesAndBoniMutationResult = ApolloReactCommon.MutationResult<InsertPricesAndBoniMutation>;
export type InsertPricesAndBoniMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertPricesAndBoniMutation, InsertPricesAndBoniMutationVariables>;
export const SetVehicleNoteStickyStateDocument = gql`
    mutation SetVehicleNoteStickyState($noteId: uuid!, $sticky: Boolean!) {
  update_note(pk_columns: {id: $noteId}, _set: {sticky: $sticky}) {
    ...VehicleNoteFields
  }
}
    ${VehicleNoteFieldsFragmentDoc}`;
export type SetVehicleNoteStickyStateMutationFn = ApolloReactCommon.MutationFunction<SetVehicleNoteStickyStateMutation, SetVehicleNoteStickyStateMutationVariables>;

/**
 * __useSetVehicleNoteStickyStateMutation__
 *
 * To run a mutation, you first call `useSetVehicleNoteStickyStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVehicleNoteStickyStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVehicleNoteStickyStateMutation, { data, loading, error }] = useSetVehicleNoteStickyStateMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      sticky: // value for 'sticky'
 *   },
 * });
 */
export function useSetVehicleNoteStickyStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetVehicleNoteStickyStateMutation, SetVehicleNoteStickyStateMutationVariables>) {
        return ApolloReactHooks.useMutation<SetVehicleNoteStickyStateMutation, SetVehicleNoteStickyStateMutationVariables>(SetVehicleNoteStickyStateDocument, baseOptions);
      }
export type SetVehicleNoteStickyStateMutationHookResult = ReturnType<typeof useSetVehicleNoteStickyStateMutation>;
export type SetVehicleNoteStickyStateMutationResult = ApolloReactCommon.MutationResult<SetVehicleNoteStickyStateMutation>;
export type SetVehicleNoteStickyStateMutationOptions = ApolloReactCommon.BaseMutationOptions<SetVehicleNoteStickyStateMutation, SetVehicleNoteStickyStateMutationVariables>;
export const UnarchiveVehicleNoteDocument = gql`
    mutation UnarchiveVehicleNote($noteId: uuid!) {
  update_note(pk_columns: {id: $noteId}, _set: {archived: false}) {
    ...VehicleNoteFields
  }
}
    ${VehicleNoteFieldsFragmentDoc}`;
export type UnarchiveVehicleNoteMutationFn = ApolloReactCommon.MutationFunction<UnarchiveVehicleNoteMutation, UnarchiveVehicleNoteMutationVariables>;

/**
 * __useUnarchiveVehicleNoteMutation__
 *
 * To run a mutation, you first call `useUnarchiveVehicleNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveVehicleNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveVehicleNoteMutation, { data, loading, error }] = useUnarchiveVehicleNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useUnarchiveVehicleNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnarchiveVehicleNoteMutation, UnarchiveVehicleNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UnarchiveVehicleNoteMutation, UnarchiveVehicleNoteMutationVariables>(UnarchiveVehicleNoteDocument, baseOptions);
      }
export type UnarchiveVehicleNoteMutationHookResult = ReturnType<typeof useUnarchiveVehicleNoteMutation>;
export type UnarchiveVehicleNoteMutationResult = ApolloReactCommon.MutationResult<UnarchiveVehicleNoteMutation>;
export type UnarchiveVehicleNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UnarchiveVehicleNoteMutation, UnarchiveVehicleNoteMutationVariables>;
export const UpdateManyVehiclesDocument = gql`
    mutation UpdateManyVehicles($updates: [Vehicle_updates!]!) {
  update_Vehicle_many(updates: $updates) {
    affected_rows
    returning {
      ...VehicleDetailFields
    }
  }
}
    ${VehicleDetailFieldsFragmentDoc}`;
export type UpdateManyVehiclesMutationFn = ApolloReactCommon.MutationFunction<UpdateManyVehiclesMutation, UpdateManyVehiclesMutationVariables>;

/**
 * __useUpdateManyVehiclesMutation__
 *
 * To run a mutation, you first call `useUpdateManyVehiclesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyVehiclesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyVehiclesMutation, { data, loading, error }] = useUpdateManyVehiclesMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateManyVehiclesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateManyVehiclesMutation, UpdateManyVehiclesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateManyVehiclesMutation, UpdateManyVehiclesMutationVariables>(UpdateManyVehiclesDocument, baseOptions);
      }
export type UpdateManyVehiclesMutationHookResult = ReturnType<typeof useUpdateManyVehiclesMutation>;
export type UpdateManyVehiclesMutationResult = ApolloReactCommon.MutationResult<UpdateManyVehiclesMutation>;
export type UpdateManyVehiclesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateManyVehiclesMutation, UpdateManyVehiclesMutationVariables>;
export const UpdateVehicleDocument = gql`
    mutation UpdateVehicle($id: uuid!, $business_case_number: Int!, $input: Vehicle_set_input!) {
  update_vehicle(
    pk_columns: {id: $id, version: 0, business_case_number: $business_case_number}
    _set: $input
  ) {
    ...VehicleDetailFields
  }
}
    ${VehicleDetailFieldsFragmentDoc}`;
export type UpdateVehicleMutationFn = ApolloReactCommon.MutationFunction<UpdateVehicleMutation, UpdateVehicleMutationVariables>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      business_case_number: // value for 'business_case_number'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVehicleMutation, UpdateVehicleMutationVariables>(UpdateVehicleDocument, baseOptions);
      }
export type UpdateVehicleMutationHookResult = ReturnType<typeof useUpdateVehicleMutation>;
export type UpdateVehicleMutationResult = ApolloReactCommon.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>;
export const UpdateVehiclesDocument = gql`
    mutation UpdateVehicles($set: Vehicle_set_input!, $where: Vehicle_bool_exp!) {
  update_vehicles(_set: $set, where: $where) {
    affected_rows
    returning {
      ...VehicleDetailFields
    }
  }
}
    ${VehicleDetailFieldsFragmentDoc}`;
export type UpdateVehiclesMutationFn = ApolloReactCommon.MutationFunction<UpdateVehiclesMutation, UpdateVehiclesMutationVariables>;

/**
 * __useUpdateVehiclesMutation__
 *
 * To run a mutation, you first call `useUpdateVehiclesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehiclesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehiclesMutation, { data, loading, error }] = useUpdateVehiclesMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateVehiclesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVehiclesMutation, UpdateVehiclesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVehiclesMutation, UpdateVehiclesMutationVariables>(UpdateVehiclesDocument, baseOptions);
      }
export type UpdateVehiclesMutationHookResult = ReturnType<typeof useUpdateVehiclesMutation>;
export type UpdateVehiclesMutationResult = ApolloReactCommon.MutationResult<UpdateVehiclesMutation>;
export type UpdateVehiclesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVehiclesMutation, UpdateVehiclesMutationVariables>;
export const VehiclesMetafieldsAllBrandsDocument = gql`
    query VehiclesMetafieldsAllBrands {
  brand_vehicle_metafield {
    brand_id
    vehicle_metafield {
      column_name
      title
    }
    required_for_import
    default_value
  }
}
    `;

/**
 * __useVehiclesMetafieldsAllBrandsQuery__
 *
 * To run a query within a React component, call `useVehiclesMetafieldsAllBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesMetafieldsAllBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesMetafieldsAllBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehiclesMetafieldsAllBrandsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VehiclesMetafieldsAllBrandsQuery, VehiclesMetafieldsAllBrandsQueryVariables>) {
        return ApolloReactHooks.useQuery<VehiclesMetafieldsAllBrandsQuery, VehiclesMetafieldsAllBrandsQueryVariables>(VehiclesMetafieldsAllBrandsDocument, baseOptions);
      }
export function useVehiclesMetafieldsAllBrandsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VehiclesMetafieldsAllBrandsQuery, VehiclesMetafieldsAllBrandsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VehiclesMetafieldsAllBrandsQuery, VehiclesMetafieldsAllBrandsQueryVariables>(VehiclesMetafieldsAllBrandsDocument, baseOptions);
        }
export type VehiclesMetafieldsAllBrandsQueryHookResult = ReturnType<typeof useVehiclesMetafieldsAllBrandsQuery>;
export type VehiclesMetafieldsAllBrandsLazyQueryHookResult = ReturnType<typeof useVehiclesMetafieldsAllBrandsLazyQuery>;
export type VehiclesMetafieldsAllBrandsQueryResult = ApolloReactCommon.QueryResult<VehiclesMetafieldsAllBrandsQuery, VehiclesMetafieldsAllBrandsQueryVariables>;
export const VehicleMetafieldsDocument = gql`
    query VehicleMetafields($brandId: uuid!) {
  brand_vehicle_metafield(where: {brand_id: {_eq: $brandId}}) {
    brand_id
    vehicle_metafield {
      column_name
      title
    }
    required_for_import
    default_value
  }
}
    `;

/**
 * __useVehicleMetafieldsQuery__
 *
 * To run a query within a React component, call `useVehicleMetafieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleMetafieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleMetafieldsQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useVehicleMetafieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VehicleMetafieldsQuery, VehicleMetafieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<VehicleMetafieldsQuery, VehicleMetafieldsQueryVariables>(VehicleMetafieldsDocument, baseOptions);
      }
export function useVehicleMetafieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VehicleMetafieldsQuery, VehicleMetafieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VehicleMetafieldsQuery, VehicleMetafieldsQueryVariables>(VehicleMetafieldsDocument, baseOptions);
        }
export type VehicleMetafieldsQueryHookResult = ReturnType<typeof useVehicleMetafieldsQuery>;
export type VehicleMetafieldsLazyQueryHookResult = ReturnType<typeof useVehicleMetafieldsLazyQuery>;
export type VehicleMetafieldsQueryResult = ApolloReactCommon.QueryResult<VehicleMetafieldsQuery, VehicleMetafieldsQueryVariables>;
export const VehiclesCountDocument = gql`
    query VehiclesCount($where: Vehicle_bool_exp) {
  vehicles_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useVehiclesCountQuery__
 *
 * To run a query within a React component, call `useVehiclesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVehiclesCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VehiclesCountQuery, VehiclesCountQueryVariables>) {
        return ApolloReactHooks.useQuery<VehiclesCountQuery, VehiclesCountQueryVariables>(VehiclesCountDocument, baseOptions);
      }
export function useVehiclesCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VehiclesCountQuery, VehiclesCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VehiclesCountQuery, VehiclesCountQueryVariables>(VehiclesCountDocument, baseOptions);
        }
export type VehiclesCountQueryHookResult = ReturnType<typeof useVehiclesCountQuery>;
export type VehiclesCountLazyQueryHookResult = ReturnType<typeof useVehiclesCountLazyQuery>;
export type VehiclesCountQueryResult = ApolloReactCommon.QueryResult<VehiclesCountQuery, VehiclesCountQueryVariables>;
export const VehiclesErrorAndImportStateDocument = gql`
    query VehiclesErrorAndImportState($where: Vehicle_bool_exp!) {
  vehicles(where: $where) {
    id
    business_case_number
    version
    sync_error
    state
    order_state
    type
    delivery_location_id
    delivery_date_str
    storage_location_id
    registration_state
    registration_owner
    brand_id
    model {
      modelGroup {
        id
        name
      }
      sales_model_code
      model_group_release_year
    }
    color_exterior_name
    color_exterior_code
    color_interior_name
    color_interior_code
    sold_at
    sold_by_id
    in_buy_channel
  }
}
    `;

/**
 * __useVehiclesErrorAndImportStateQuery__
 *
 * To run a query within a React component, call `useVehiclesErrorAndImportStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesErrorAndImportStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesErrorAndImportStateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVehiclesErrorAndImportStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VehiclesErrorAndImportStateQuery, VehiclesErrorAndImportStateQueryVariables>) {
        return ApolloReactHooks.useQuery<VehiclesErrorAndImportStateQuery, VehiclesErrorAndImportStateQueryVariables>(VehiclesErrorAndImportStateDocument, baseOptions);
      }
export function useVehiclesErrorAndImportStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VehiclesErrorAndImportStateQuery, VehiclesErrorAndImportStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VehiclesErrorAndImportStateQuery, VehiclesErrorAndImportStateQueryVariables>(VehiclesErrorAndImportStateDocument, baseOptions);
        }
export type VehiclesErrorAndImportStateQueryHookResult = ReturnType<typeof useVehiclesErrorAndImportStateQuery>;
export type VehiclesErrorAndImportStateLazyQueryHookResult = ReturnType<typeof useVehiclesErrorAndImportStateLazyQuery>;
export type VehiclesErrorAndImportStateQueryResult = ApolloReactCommon.QueryResult<VehiclesErrorAndImportStateQuery, VehiclesErrorAndImportStateQueryVariables>;
export const VehiclesErrorStateDocument = gql`
    query VehiclesErrorState($where: Vehicle_bool_exp!) {
  vehicles(where: $where) {
    id
    business_case_number
    version
    sync_error
    brand_id
  }
}
    `;

/**
 * __useVehiclesErrorStateQuery__
 *
 * To run a query within a React component, call `useVehiclesErrorStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesErrorStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesErrorStateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVehiclesErrorStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VehiclesErrorStateQuery, VehiclesErrorStateQueryVariables>) {
        return ApolloReactHooks.useQuery<VehiclesErrorStateQuery, VehiclesErrorStateQueryVariables>(VehiclesErrorStateDocument, baseOptions);
      }
export function useVehiclesErrorStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VehiclesErrorStateQuery, VehiclesErrorStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VehiclesErrorStateQuery, VehiclesErrorStateQueryVariables>(VehiclesErrorStateDocument, baseOptions);
        }
export type VehiclesErrorStateQueryHookResult = ReturnType<typeof useVehiclesErrorStateQuery>;
export type VehiclesErrorStateLazyQueryHookResult = ReturnType<typeof useVehiclesErrorStateLazyQuery>;
export type VehiclesErrorStateQueryResult = ApolloReactCommon.QueryResult<VehiclesErrorStateQuery, VehiclesErrorStateQueryVariables>;
export const VehiclesExportDocument = gql`
    query VehiclesExport($where: ExportVehiclesVehicleBoolExp!, $variant: ExportDealerVehiclesVariant!) {
  exportVehicles(where: $where, variant: $variant) {
    download_url
  }
}
    `;

/**
 * __useVehiclesExportQuery__
 *
 * To run a query within a React component, call `useVehiclesExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesExportQuery({
 *   variables: {
 *      where: // value for 'where'
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useVehiclesExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VehiclesExportQuery, VehiclesExportQueryVariables>) {
        return ApolloReactHooks.useQuery<VehiclesExportQuery, VehiclesExportQueryVariables>(VehiclesExportDocument, baseOptions);
      }
export function useVehiclesExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VehiclesExportQuery, VehiclesExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VehiclesExportQuery, VehiclesExportQueryVariables>(VehiclesExportDocument, baseOptions);
        }
export type VehiclesExportQueryHookResult = ReturnType<typeof useVehiclesExportQuery>;
export type VehiclesExportLazyQueryHookResult = ReturnType<typeof useVehiclesExportLazyQuery>;
export type VehiclesExportQueryResult = ApolloReactCommon.QueryResult<VehiclesExportQuery, VehiclesExportQueryVariables>;
export const VehiclesListDocument = gql`
    query VehiclesList($limit: Int, $offset: Int, $where: Vehicle_bool_exp, $with_sync_errors: Boolean!, $order_by: [Vehicle_order_by!]) {
  vehicles(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    ...VehicleListFields
    sync_errors @include(if: $with_sync_errors) {
      id
      message
      resolved
    }
  }
}
    ${VehicleListFieldsFragmentDoc}`;

/**
 * __useVehiclesListQuery__
 *
 * To run a query within a React component, call `useVehiclesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      with_sync_errors: // value for 'with_sync_errors'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useVehiclesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VehiclesListQuery, VehiclesListQueryVariables>) {
        return ApolloReactHooks.useQuery<VehiclesListQuery, VehiclesListQueryVariables>(VehiclesListDocument, baseOptions);
      }
export function useVehiclesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VehiclesListQuery, VehiclesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VehiclesListQuery, VehiclesListQueryVariables>(VehiclesListDocument, baseOptions);
        }
export type VehiclesListQueryHookResult = ReturnType<typeof useVehiclesListQuery>;
export type VehiclesListLazyQueryHookResult = ReturnType<typeof useVehiclesListLazyQuery>;
export type VehiclesListQueryResult = ApolloReactCommon.QueryResult<VehiclesListQuery, VehiclesListQueryVariables>;
export const ChangedVehiclesListDocument = gql`
    subscription ChangedVehiclesList($updated_at: timestamptz!, $with_sync_errors: Boolean!, $batch_size: Int!, $where: Vehicle_bool_exp) {
  Vehicle_stream(
    cursor: {initial_value: {updated_at: $updated_at}, ordering: ASC}
    where: $where
    batch_size: $batch_size
  ) {
    ...VehicleListFields
    sync_errors @include(if: $with_sync_errors) {
      id
      message
      resolved
    }
  }
}
    ${VehicleListFieldsFragmentDoc}`;

/**
 * __useChangedVehiclesListSubscription__
 *
 * To run a query within a React component, call `useChangedVehiclesListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChangedVehiclesListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangedVehiclesListSubscription({
 *   variables: {
 *      updated_at: // value for 'updated_at'
 *      with_sync_errors: // value for 'with_sync_errors'
 *      batch_size: // value for 'batch_size'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChangedVehiclesListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ChangedVehiclesListSubscription, ChangedVehiclesListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ChangedVehiclesListSubscription, ChangedVehiclesListSubscriptionVariables>(ChangedVehiclesListDocument, baseOptions);
      }
export type ChangedVehiclesListSubscriptionHookResult = ReturnType<typeof useChangedVehiclesListSubscription>;
export type ChangedVehiclesListSubscriptionResult = ApolloReactCommon.SubscriptionResult<ChangedVehiclesListSubscription>;
export const VehicleDetailsDocument = gql`
    subscription VehicleDetails($id: uuid!, $business_case_number: Int!) {
  vehicle(id: $id, version: 0, business_case_number: $business_case_number) {
    ...VehicleDetailFields
  }
}
    ${VehicleDetailFieldsFragmentDoc}`;

/**
 * __useVehicleDetailsSubscription__
 *
 * To run a query within a React component, call `useVehicleDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVehicleDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleDetailsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      business_case_number: // value for 'business_case_number'
 *   },
 * });
 */
export function useVehicleDetailsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<VehicleDetailsSubscription, VehicleDetailsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<VehicleDetailsSubscription, VehicleDetailsSubscriptionVariables>(VehicleDetailsDocument, baseOptions);
      }
export type VehicleDetailsSubscriptionHookResult = ReturnType<typeof useVehicleDetailsSubscription>;
export type VehicleDetailsSubscriptionResult = ApolloReactCommon.SubscriptionResult<VehicleDetailsSubscription>;
export const VehicleNotesDocument = gql`
    subscription VehicleNotes($vehicleId: uuid!, $businessCaseNumber: Int!) {
  notes(
    where: {vehicle_id: {_eq: $vehicleId}, vehicle_business_case_number: {_eq: $businessCaseNumber}}
    order_by: [{sticky: desc_nulls_last}, {created_at: asc}]
  ) {
    ...VehicleNoteFields
  }
}
    ${VehicleNoteFieldsFragmentDoc}`;

/**
 * __useVehicleNotesSubscription__
 *
 * To run a query within a React component, call `useVehicleNotesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVehicleNotesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleNotesSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      businessCaseNumber: // value for 'businessCaseNumber'
 *   },
 * });
 */
export function useVehicleNotesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<VehicleNotesSubscription, VehicleNotesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<VehicleNotesSubscription, VehicleNotesSubscriptionVariables>(VehicleNotesDocument, baseOptions);
      }
export type VehicleNotesSubscriptionHookResult = ReturnType<typeof useVehicleNotesSubscription>;
export type VehicleNotesSubscriptionResult = ApolloReactCommon.SubscriptionResult<VehicleNotesSubscription>;
export const VehiclesDocument = gql`
    subscription Vehicles($limit: Int, $offset: Int, $where: Vehicle_bool_exp, $with_sync_errors: Boolean!, $order_by: [Vehicle_order_by!]) {
  vehicles(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    ...VehicleListFields
    sync_errors @include(if: $with_sync_errors) {
      id
      message
      resolved
    }
  }
}
    ${VehicleListFieldsFragmentDoc}`;

/**
 * __useVehiclesSubscription__
 *
 * To run a query within a React component, call `useVehiclesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      with_sync_errors: // value for 'with_sync_errors'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useVehiclesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<VehiclesSubscription, VehiclesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<VehiclesSubscription, VehiclesSubscriptionVariables>(VehiclesDocument, baseOptions);
      }
export type VehiclesSubscriptionHookResult = ReturnType<typeof useVehiclesSubscription>;
export type VehiclesSubscriptionResult = ApolloReactCommon.SubscriptionResult<VehiclesSubscription>;
export const InsertSalesReportDocument = gql`
    mutation InsertSalesReport($object: sales_reports_insert_input!) {
  insert_sales_reports_one(object: $object) {
    id
  }
}
    `;
export type InsertSalesReportMutationFn = ApolloReactCommon.MutationFunction<InsertSalesReportMutation, InsertSalesReportMutationVariables>;

/**
 * __useInsertSalesReportMutation__
 *
 * To run a mutation, you first call `useInsertSalesReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSalesReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSalesReportMutation, { data, loading, error }] = useInsertSalesReportMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertSalesReportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertSalesReportMutation, InsertSalesReportMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertSalesReportMutation, InsertSalesReportMutationVariables>(InsertSalesReportDocument, baseOptions);
      }
export type InsertSalesReportMutationHookResult = ReturnType<typeof useInsertSalesReportMutation>;
export type InsertSalesReportMutationResult = ApolloReactCommon.MutationResult<InsertSalesReportMutation>;
export type InsertSalesReportMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertSalesReportMutation, InsertSalesReportMutationVariables>;
export const SalesReportDetailDocument = gql`
    query SalesReportDetail($id: uuid!) {
  sales_reports_by_pk(id: $id) {
    ...SalesReportsFields
  }
}
    ${SalesReportsFieldsFragmentDoc}`;

/**
 * __useSalesReportDetailQuery__
 *
 * To run a query within a React component, call `useSalesReportDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesReportDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesReportDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSalesReportDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SalesReportDetailQuery, SalesReportDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<SalesReportDetailQuery, SalesReportDetailQueryVariables>(SalesReportDetailDocument, baseOptions);
      }
export function useSalesReportDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SalesReportDetailQuery, SalesReportDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SalesReportDetailQuery, SalesReportDetailQueryVariables>(SalesReportDetailDocument, baseOptions);
        }
export type SalesReportDetailQueryHookResult = ReturnType<typeof useSalesReportDetailQuery>;
export type SalesReportDetailLazyQueryHookResult = ReturnType<typeof useSalesReportDetailLazyQuery>;
export type SalesReportDetailQueryResult = ApolloReactCommon.QueryResult<SalesReportDetailQuery, SalesReportDetailQueryVariables>;
export const StorageLocationSelectDataDocument = gql`
    query StorageLocationSelectData {
  storage_locations {
    ...StorageLocationSelectFields
  }
  locations {
    id
    name
  }
  subdealers {
    id
    name
  }
}
    ${StorageLocationSelectFieldsFragmentDoc}`;

/**
 * __useStorageLocationSelectDataQuery__
 *
 * To run a query within a React component, call `useStorageLocationSelectDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageLocationSelectDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageLocationSelectDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useStorageLocationSelectDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StorageLocationSelectDataQuery, StorageLocationSelectDataQueryVariables>) {
        return ApolloReactHooks.useQuery<StorageLocationSelectDataQuery, StorageLocationSelectDataQueryVariables>(StorageLocationSelectDataDocument, baseOptions);
      }
export function useStorageLocationSelectDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StorageLocationSelectDataQuery, StorageLocationSelectDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StorageLocationSelectDataQuery, StorageLocationSelectDataQueryVariables>(StorageLocationSelectDataDocument, baseOptions);
        }
export type StorageLocationSelectDataQueryHookResult = ReturnType<typeof useStorageLocationSelectDataQuery>;
export type StorageLocationSelectDataLazyQueryHookResult = ReturnType<typeof useStorageLocationSelectDataLazyQuery>;
export type StorageLocationSelectDataQueryResult = ApolloReactCommon.QueryResult<StorageLocationSelectDataQuery, StorageLocationSelectDataQueryVariables>;
export const CreateStorageLocationDocument = gql`
    mutation CreateStorageLocation($input: StorageLocation_insert_input!) {
  insert_storage_location(object: $input) {
    ...StorageLocationSelectFields
  }
}
    ${StorageLocationSelectFieldsFragmentDoc}`;
export type CreateStorageLocationMutationFn = ApolloReactCommon.MutationFunction<CreateStorageLocationMutation, CreateStorageLocationMutationVariables>;

/**
 * __useCreateStorageLocationMutation__
 *
 * To run a mutation, you first call `useCreateStorageLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStorageLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStorageLocationMutation, { data, loading, error }] = useCreateStorageLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStorageLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStorageLocationMutation, CreateStorageLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStorageLocationMutation, CreateStorageLocationMutationVariables>(CreateStorageLocationDocument, baseOptions);
      }
export type CreateStorageLocationMutationHookResult = ReturnType<typeof useCreateStorageLocationMutation>;
export type CreateStorageLocationMutationResult = ApolloReactCommon.MutationResult<CreateStorageLocationMutation>;
export type CreateStorageLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStorageLocationMutation, CreateStorageLocationMutationVariables>;
export const UserSelectDataDocument = gql`
    query UserSelectData {
  users {
    ...UserSelectFields
  }
}
    ${UserSelectFieldsFragmentDoc}`;

/**
 * __useUserSelectDataQuery__
 *
 * To run a query within a React component, call `useUserSelectDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSelectDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserSelectDataQuery, UserSelectDataQueryVariables>) {
        return ApolloReactHooks.useQuery<UserSelectDataQuery, UserSelectDataQueryVariables>(UserSelectDataDocument, baseOptions);
      }
export function useUserSelectDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSelectDataQuery, UserSelectDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserSelectDataQuery, UserSelectDataQueryVariables>(UserSelectDataDocument, baseOptions);
        }
export type UserSelectDataQueryHookResult = ReturnType<typeof useUserSelectDataQuery>;
export type UserSelectDataLazyQueryHookResult = ReturnType<typeof useUserSelectDataLazyQuery>;
export type UserSelectDataQueryResult = ApolloReactCommon.QueryResult<UserSelectDataQuery, UserSelectDataQueryVariables>;
export const BrandBoniTypesDocument = gql`
    query BrandBoniTypes($brand_id: uuid!) {
  brand_boni_types(where: {brand_id: {_eq: $brand_id}}) {
    type
  }
}
    `;

/**
 * __useBrandBoniTypesQuery__
 *
 * To run a query within a React component, call `useBrandBoniTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandBoniTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandBoniTypesQuery({
 *   variables: {
 *      brand_id: // value for 'brand_id'
 *   },
 * });
 */
export function useBrandBoniTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrandBoniTypesQuery, BrandBoniTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<BrandBoniTypesQuery, BrandBoniTypesQueryVariables>(BrandBoniTypesDocument, baseOptions);
      }
export function useBrandBoniTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrandBoniTypesQuery, BrandBoniTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrandBoniTypesQuery, BrandBoniTypesQueryVariables>(BrandBoniTypesDocument, baseOptions);
        }
export type BrandBoniTypesQueryHookResult = ReturnType<typeof useBrandBoniTypesQuery>;
export type BrandBoniTypesLazyQueryHookResult = ReturnType<typeof useBrandBoniTypesLazyQuery>;
export type BrandBoniTypesQueryResult = ApolloReactCommon.QueryResult<BrandBoniTypesQuery, BrandBoniTypesQueryVariables>;
export const ResolveVehicleSyncErrorDocument = gql`
    mutation ResolveVehicleSyncError($vehicleId: String!, $transactionId: String!) {
  resolveVehicleSyncError(
    input: {vehicleId: $vehicleId, transactionId: $transactionId}
  ) {
    status
    errorMessage
  }
}
    `;
export type ResolveVehicleSyncErrorMutationFn = ApolloReactCommon.MutationFunction<ResolveVehicleSyncErrorMutation, ResolveVehicleSyncErrorMutationVariables>;

/**
 * __useResolveVehicleSyncErrorMutation__
 *
 * To run a mutation, you first call `useResolveVehicleSyncErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveVehicleSyncErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveVehicleSyncErrorMutation, { data, loading, error }] = useResolveVehicleSyncErrorMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useResolveVehicleSyncErrorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResolveVehicleSyncErrorMutation, ResolveVehicleSyncErrorMutationVariables>) {
        return ApolloReactHooks.useMutation<ResolveVehicleSyncErrorMutation, ResolveVehicleSyncErrorMutationVariables>(ResolveVehicleSyncErrorDocument, baseOptions);
      }
export type ResolveVehicleSyncErrorMutationHookResult = ReturnType<typeof useResolveVehicleSyncErrorMutation>;
export type ResolveVehicleSyncErrorMutationResult = ApolloReactCommon.MutationResult<ResolveVehicleSyncErrorMutation>;
export type ResolveVehicleSyncErrorMutationOptions = ApolloReactCommon.BaseMutationOptions<ResolveVehicleSyncErrorMutation, ResolveVehicleSyncErrorMutationVariables>;
export const DropVehicleReservationDocument = gql`
    mutation DropVehicleReservation($id: uuid!, $business_case_number: Int!) {
  update_vehicle(
    pk_columns: {id: $id, business_case_number: $business_case_number, version: 0}
    _set: {state: AVAILABLE, reserved_at: null, reserved_by_id: null, reserved_for: null, reserved_for_customer_type: null, reserved_until: null}
  ) {
    id
    version
    business_case_number
  }
}
    `;
export type DropVehicleReservationMutationFn = ApolloReactCommon.MutationFunction<DropVehicleReservationMutation, DropVehicleReservationMutationVariables>;

/**
 * __useDropVehicleReservationMutation__
 *
 * To run a mutation, you first call `useDropVehicleReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDropVehicleReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dropVehicleReservationMutation, { data, loading, error }] = useDropVehicleReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      business_case_number: // value for 'business_case_number'
 *   },
 * });
 */
export function useDropVehicleReservationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DropVehicleReservationMutation, DropVehicleReservationMutationVariables>) {
        return ApolloReactHooks.useMutation<DropVehicleReservationMutation, DropVehicleReservationMutationVariables>(DropVehicleReservationDocument, baseOptions);
      }
export type DropVehicleReservationMutationHookResult = ReturnType<typeof useDropVehicleReservationMutation>;
export type DropVehicleReservationMutationResult = ApolloReactCommon.MutationResult<DropVehicleReservationMutation>;
export type DropVehicleReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<DropVehicleReservationMutation, DropVehicleReservationMutationVariables>;
export const ManualTicketTemplatesDocument = gql`
    query ManualTicketTemplates {
  ticket_templates(where: {automated_task: {_eq: false}}) {
    ...ManualTicketTemplateListFields
  }
}
    ${ManualTicketTemplateListFieldsFragmentDoc}`;

/**
 * __useManualTicketTemplatesQuery__
 *
 * To run a query within a React component, call `useManualTicketTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualTicketTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualTicketTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useManualTicketTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ManualTicketTemplatesQuery, ManualTicketTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ManualTicketTemplatesQuery, ManualTicketTemplatesQueryVariables>(ManualTicketTemplatesDocument, baseOptions);
      }
export function useManualTicketTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ManualTicketTemplatesQuery, ManualTicketTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ManualTicketTemplatesQuery, ManualTicketTemplatesQueryVariables>(ManualTicketTemplatesDocument, baseOptions);
        }
export type ManualTicketTemplatesQueryHookResult = ReturnType<typeof useManualTicketTemplatesQuery>;
export type ManualTicketTemplatesLazyQueryHookResult = ReturnType<typeof useManualTicketTemplatesLazyQuery>;
export type ManualTicketTemplatesQueryResult = ApolloReactCommon.QueryResult<ManualTicketTemplatesQuery, ManualTicketTemplatesQueryVariables>;
export const CreateTicketFromTemplateDocument = gql`
    mutation CreateTicketFromTemplate($input: CreateTicketFromTemplateInput!) {
  createTicketFromTemplate(input: $input) {
    ticketId
  }
}
    `;
export type CreateTicketFromTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateTicketFromTemplateMutation, CreateTicketFromTemplateMutationVariables>;

/**
 * __useCreateTicketFromTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTicketFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketFromTemplateMutation, { data, loading, error }] = useCreateTicketFromTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTicketFromTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTicketFromTemplateMutation, CreateTicketFromTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTicketFromTemplateMutation, CreateTicketFromTemplateMutationVariables>(CreateTicketFromTemplateDocument, baseOptions);
      }
export type CreateTicketFromTemplateMutationHookResult = ReturnType<typeof useCreateTicketFromTemplateMutation>;
export type CreateTicketFromTemplateMutationResult = ApolloReactCommon.MutationResult<CreateTicketFromTemplateMutation>;
export type CreateTicketFromTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTicketFromTemplateMutation, CreateTicketFromTemplateMutationVariables>;
export const BrandsDocument = gql`
    query Brands {
  brands(order_by: {name: asc}) {
    ...BrandFields
  }
}
    ${BrandFieldsFragmentDoc}`;

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
        return ApolloReactHooks.useQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, baseOptions);
      }
export function useBrandsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, baseOptions);
        }
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = ApolloReactCommon.QueryResult<BrandsQuery, BrandsQueryVariables>;
export const LocationWithGroupsDocument = gql`
    query LocationWithGroups($id: uuid!) {
  location(id: $id) {
    ...LocationWithGroupFields
  }
}
    ${LocationWithGroupFieldsFragmentDoc}`;

/**
 * __useLocationWithGroupsQuery__
 *
 * To run a query within a React component, call `useLocationWithGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationWithGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationWithGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationWithGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationWithGroupsQuery, LocationWithGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationWithGroupsQuery, LocationWithGroupsQueryVariables>(LocationWithGroupsDocument, baseOptions);
      }
export function useLocationWithGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationWithGroupsQuery, LocationWithGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationWithGroupsQuery, LocationWithGroupsQueryVariables>(LocationWithGroupsDocument, baseOptions);
        }
export type LocationWithGroupsQueryHookResult = ReturnType<typeof useLocationWithGroupsQuery>;
export type LocationWithGroupsLazyQueryHookResult = ReturnType<typeof useLocationWithGroupsLazyQuery>;
export type LocationWithGroupsQueryResult = ApolloReactCommon.QueryResult<LocationWithGroupsQuery, LocationWithGroupsQueryVariables>;
export const LocationsListDocument = gql`
    query LocationsList {
  locations(order_by: {name: asc}) {
    id
    name
  }
}
    `;

/**
 * __useLocationsListQuery__
 *
 * To run a query within a React component, call `useLocationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationsListQuery, LocationsListQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationsListQuery, LocationsListQueryVariables>(LocationsListDocument, baseOptions);
      }
export function useLocationsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationsListQuery, LocationsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationsListQuery, LocationsListQueryVariables>(LocationsListDocument, baseOptions);
        }
export type LocationsListQueryHookResult = ReturnType<typeof useLocationsListQuery>;
export type LocationsListLazyQueryHookResult = ReturnType<typeof useLocationsListLazyQuery>;
export type LocationsListQueryResult = ApolloReactCommon.QueryResult<LocationsListQuery, LocationsListQueryVariables>;
export const LocationsWithGroupsDocument = gql`
    query LocationsWithGroups {
  locations(order_by: {name: asc}) {
    ...LocationWithGroupFields
  }
}
    ${LocationWithGroupFieldsFragmentDoc}`;

/**
 * __useLocationsWithGroupsQuery__
 *
 * To run a query within a React component, call `useLocationsWithGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsWithGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsWithGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsWithGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationsWithGroupsQuery, LocationsWithGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationsWithGroupsQuery, LocationsWithGroupsQueryVariables>(LocationsWithGroupsDocument, baseOptions);
      }
export function useLocationsWithGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationsWithGroupsQuery, LocationsWithGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationsWithGroupsQuery, LocationsWithGroupsQueryVariables>(LocationsWithGroupsDocument, baseOptions);
        }
export type LocationsWithGroupsQueryHookResult = ReturnType<typeof useLocationsWithGroupsQuery>;
export type LocationsWithGroupsLazyQueryHookResult = ReturnType<typeof useLocationsWithGroupsLazyQuery>;
export type LocationsWithGroupsQueryResult = ApolloReactCommon.QueryResult<LocationsWithGroupsQuery, LocationsWithGroupsQueryVariables>;
export const AssetsLinksListDocument = gql`
    subscription AssetsLinksList {
  asset_watchdog_links {
    id
    name
    source_url
    url
    status
  }
}
    `;

/**
 * __useAssetsLinksListSubscription__
 *
 * To run a query within a React component, call `useAssetsLinksListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAssetsLinksListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsLinksListSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAssetsLinksListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AssetsLinksListSubscription, AssetsLinksListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<AssetsLinksListSubscription, AssetsLinksListSubscriptionVariables>(AssetsLinksListDocument, baseOptions);
      }
export type AssetsLinksListSubscriptionHookResult = ReturnType<typeof useAssetsLinksListSubscription>;
export type AssetsLinksListSubscriptionResult = ApolloReactCommon.SubscriptionResult<AssetsLinksListSubscription>;
export const TicketTemplatesListDocument = gql`
    subscription TicketTemplatesList {
  ticket_templates {
    ...TicketTemplateListFields
  }
}
    ${TicketTemplateListFieldsFragmentDoc}`;

/**
 * __useTicketTemplatesListSubscription__
 *
 * To run a query within a React component, call `useTicketTemplatesListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTicketTemplatesListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketTemplatesListSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTicketTemplatesListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TicketTemplatesListSubscription, TicketTemplatesListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TicketTemplatesListSubscription, TicketTemplatesListSubscriptionVariables>(TicketTemplatesListDocument, baseOptions);
      }
export type TicketTemplatesListSubscriptionHookResult = ReturnType<typeof useTicketTemplatesListSubscription>;
export type TicketTemplatesListSubscriptionResult = ApolloReactCommon.SubscriptionResult<TicketTemplatesListSubscription>;
export const EmbeddedPagesListDocument = gql`
    subscription EmbeddedPagesList {
  embedded_pages(order_by: {name: asc}) {
    id
    name
    url
    brand_id
    brand {
      id
      name
    }
  }
}
    `;

/**
 * __useEmbeddedPagesListSubscription__
 *
 * To run a query within a React component, call `useEmbeddedPagesListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEmbeddedPagesListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmbeddedPagesListSubscription({
 *   variables: {
 *   },
 * });
 */
export function useEmbeddedPagesListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<EmbeddedPagesListSubscription, EmbeddedPagesListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<EmbeddedPagesListSubscription, EmbeddedPagesListSubscriptionVariables>(EmbeddedPagesListDocument, baseOptions);
      }
export type EmbeddedPagesListSubscriptionHookResult = ReturnType<typeof useEmbeddedPagesListSubscription>;
export type EmbeddedPagesListSubscriptionResult = ApolloReactCommon.SubscriptionResult<EmbeddedPagesListSubscription>;
export const VehicleImportJobsListDocument = gql`
    subscription VehicleImportJobsList {
  import_jobs(
    where: {vehicle_import_template_id: {_is_null: false}}
    order_by: {created_at: desc}
  ) {
    id
    created_at
    status
    upload_file_name
    matching_field
    upload_file_excel_definition
  }
}
    `;

/**
 * __useVehicleImportJobsListSubscription__
 *
 * To run a query within a React component, call `useVehicleImportJobsListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVehicleImportJobsListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleImportJobsListSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVehicleImportJobsListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<VehicleImportJobsListSubscription, VehicleImportJobsListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<VehicleImportJobsListSubscription, VehicleImportJobsListSubscriptionVariables>(VehicleImportJobsListDocument, baseOptions);
      }
export type VehicleImportJobsListSubscriptionHookResult = ReturnType<typeof useVehicleImportJobsListSubscription>;
export type VehicleImportJobsListSubscriptionResult = ApolloReactCommon.SubscriptionResult<VehicleImportJobsListSubscription>;
export const DeleteImportJobDocument = gql`
    mutation DeleteImportJob($id: uuid!) {
  delete_import_jobs_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteImportJobMutationFn = ApolloReactCommon.MutationFunction<DeleteImportJobMutation, DeleteImportJobMutationVariables>;

/**
 * __useDeleteImportJobMutation__
 *
 * To run a mutation, you first call `useDeleteImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteImportJobMutation, { data, loading, error }] = useDeleteImportJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteImportJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteImportJobMutation, DeleteImportJobMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteImportJobMutation, DeleteImportJobMutationVariables>(DeleteImportJobDocument, baseOptions);
      }
export type DeleteImportJobMutationHookResult = ReturnType<typeof useDeleteImportJobMutation>;
export type DeleteImportJobMutationResult = ApolloReactCommon.MutationResult<DeleteImportJobMutation>;
export type DeleteImportJobMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteImportJobMutation, DeleteImportJobMutationVariables>;
export const LocosoftSyncStatesDocument = gql`
    query LocosoftSyncStates {
  locosoft_sync_states {
    name
    status
    syncEntity
    phases
  }
}
    `;

/**
 * __useLocosoftSyncStatesQuery__
 *
 * To run a query within a React component, call `useLocosoftSyncStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocosoftSyncStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocosoftSyncStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocosoftSyncStatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocosoftSyncStatesQuery, LocosoftSyncStatesQueryVariables>) {
        return ApolloReactHooks.useQuery<LocosoftSyncStatesQuery, LocosoftSyncStatesQueryVariables>(LocosoftSyncStatesDocument, baseOptions);
      }
export function useLocosoftSyncStatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocosoftSyncStatesQuery, LocosoftSyncStatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocosoftSyncStatesQuery, LocosoftSyncStatesQueryVariables>(LocosoftSyncStatesDocument, baseOptions);
        }
export type LocosoftSyncStatesQueryHookResult = ReturnType<typeof useLocosoftSyncStatesQuery>;
export type LocosoftSyncStatesLazyQueryHookResult = ReturnType<typeof useLocosoftSyncStatesLazyQuery>;
export type LocosoftSyncStatesQueryResult = ApolloReactCommon.QueryResult<LocosoftSyncStatesQuery, LocosoftSyncStatesQueryVariables>;
export const CloudwatchLogsDocument = gql`
    query CloudwatchLogs($input: CloudwatchLogsInput!) {
  cloudwatch_logs(input: $input) {
    results {
      message
      timestamp
    }
    recordsMatched
  }
}
    `;

/**
 * __useCloudwatchLogsQuery__
 *
 * To run a query within a React component, call `useCloudwatchLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudwatchLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudwatchLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloudwatchLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CloudwatchLogsQuery, CloudwatchLogsQueryVariables>) {
        return ApolloReactHooks.useQuery<CloudwatchLogsQuery, CloudwatchLogsQueryVariables>(CloudwatchLogsDocument, baseOptions);
      }
export function useCloudwatchLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CloudwatchLogsQuery, CloudwatchLogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CloudwatchLogsQuery, CloudwatchLogsQueryVariables>(CloudwatchLogsDocument, baseOptions);
        }
export type CloudwatchLogsQueryHookResult = ReturnType<typeof useCloudwatchLogsQuery>;
export type CloudwatchLogsLazyQueryHookResult = ReturnType<typeof useCloudwatchLogsLazyQuery>;
export type CloudwatchLogsQueryResult = ApolloReactCommon.QueryResult<CloudwatchLogsQuery, CloudwatchLogsQueryVariables>;
export const CloudwatchMetricDataDocument = gql`
    query CloudwatchMetricData($input: MetricDataInput!) {
  metric_data(input: $input) {
    x
    y
    label
  }
}
    `;

/**
 * __useCloudwatchMetricDataQuery__
 *
 * To run a query within a React component, call `useCloudwatchMetricDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudwatchMetricDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudwatchMetricDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloudwatchMetricDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CloudwatchMetricDataQuery, CloudwatchMetricDataQueryVariables>) {
        return ApolloReactHooks.useQuery<CloudwatchMetricDataQuery, CloudwatchMetricDataQueryVariables>(CloudwatchMetricDataDocument, baseOptions);
      }
export function useCloudwatchMetricDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CloudwatchMetricDataQuery, CloudwatchMetricDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CloudwatchMetricDataQuery, CloudwatchMetricDataQueryVariables>(CloudwatchMetricDataDocument, baseOptions);
        }
export type CloudwatchMetricDataQueryHookResult = ReturnType<typeof useCloudwatchMetricDataQuery>;
export type CloudwatchMetricDataLazyQueryHookResult = ReturnType<typeof useCloudwatchMetricDataLazyQuery>;
export type CloudwatchMetricDataQueryResult = ApolloReactCommon.QueryResult<CloudwatchMetricDataQuery, CloudwatchMetricDataQueryVariables>;
export const AdminSubDealerListDocument = gql`
    query AdminSubDealerList {
  subdealers(order_by: {name: asc}) {
    id
    name
    address {
      fullAddress
    }
    brands {
      id
      name
    }
  }
}
    `;

/**
 * __useAdminSubDealerListQuery__
 *
 * To run a query within a React component, call `useAdminSubDealerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSubDealerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSubDealerListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminSubDealerListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminSubDealerListQuery, AdminSubDealerListQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminSubDealerListQuery, AdminSubDealerListQueryVariables>(AdminSubDealerListDocument, baseOptions);
      }
export function useAdminSubDealerListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminSubDealerListQuery, AdminSubDealerListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminSubDealerListQuery, AdminSubDealerListQueryVariables>(AdminSubDealerListDocument, baseOptions);
        }
export type AdminSubDealerListQueryHookResult = ReturnType<typeof useAdminSubDealerListQuery>;
export type AdminSubDealerListLazyQueryHookResult = ReturnType<typeof useAdminSubDealerListLazyQuery>;
export type AdminSubDealerListQueryResult = ApolloReactCommon.QueryResult<AdminSubDealerListQuery, AdminSubDealerListQueryVariables>;
export const AdminSupplierListDocument = gql`
    query AdminSupplierList {
  suppliers(order_by: {name: asc}) {
    id
    name
    address {
      fullAddress
    }
  }
}
    `;

/**
 * __useAdminSupplierListQuery__
 *
 * To run a query within a React component, call `useAdminSupplierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSupplierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSupplierListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminSupplierListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminSupplierListQuery, AdminSupplierListQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminSupplierListQuery, AdminSupplierListQueryVariables>(AdminSupplierListDocument, baseOptions);
      }
export function useAdminSupplierListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminSupplierListQuery, AdminSupplierListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminSupplierListQuery, AdminSupplierListQueryVariables>(AdminSupplierListDocument, baseOptions);
        }
export type AdminSupplierListQueryHookResult = ReturnType<typeof useAdminSupplierListQuery>;
export type AdminSupplierListLazyQueryHookResult = ReturnType<typeof useAdminSupplierListLazyQuery>;
export type AdminSupplierListQueryResult = ApolloReactCommon.QueryResult<AdminSupplierListQuery, AdminSupplierListQueryVariables>;
export const TaskTypesDocument = gql`
    query TaskTypes($limit: Int, $offset: Int, $where: TaskType_bool_exp) {
  task_types(limit: $limit, offset: $offset, where: $where) {
    ...AdminTaskTypeFields
  }
  task_types_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${AdminTaskTypeFieldsFragmentDoc}`;

/**
 * __useTaskTypesQuery__
 *
 * To run a query within a React component, call `useTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTaskTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TaskTypesQuery, TaskTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<TaskTypesQuery, TaskTypesQueryVariables>(TaskTypesDocument, baseOptions);
      }
export function useTaskTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskTypesQuery, TaskTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TaskTypesQuery, TaskTypesQueryVariables>(TaskTypesDocument, baseOptions);
        }
export type TaskTypesQueryHookResult = ReturnType<typeof useTaskTypesQuery>;
export type TaskTypesLazyQueryHookResult = ReturnType<typeof useTaskTypesLazyQuery>;
export type TaskTypesQueryResult = ApolloReactCommon.QueryResult<TaskTypesQuery, TaskTypesQueryVariables>;
export const AddTaskTypeCategoryDocument = gql`
    mutation AddTaskTypeCategory($taskTypeCategoryRefObj: [task_type_task_category_insert_input!]!) {
  insert_task_type_task_category(
    objects: $taskTypeCategoryRefObj
    on_conflict: {constraint: task_type_task_category_pkey, update_columns: []}
  ) {
    affected_rows
    returning {
      task_type {
        ...AdminTaskTypeFields
      }
    }
  }
}
    ${AdminTaskTypeFieldsFragmentDoc}`;
export type AddTaskTypeCategoryMutationFn = ApolloReactCommon.MutationFunction<AddTaskTypeCategoryMutation, AddTaskTypeCategoryMutationVariables>;

/**
 * __useAddTaskTypeCategoryMutation__
 *
 * To run a mutation, you first call `useAddTaskTypeCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskTypeCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskTypeCategoryMutation, { data, loading, error }] = useAddTaskTypeCategoryMutation({
 *   variables: {
 *      taskTypeCategoryRefObj: // value for 'taskTypeCategoryRefObj'
 *   },
 * });
 */
export function useAddTaskTypeCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTaskTypeCategoryMutation, AddTaskTypeCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTaskTypeCategoryMutation, AddTaskTypeCategoryMutationVariables>(AddTaskTypeCategoryDocument, baseOptions);
      }
export type AddTaskTypeCategoryMutationHookResult = ReturnType<typeof useAddTaskTypeCategoryMutation>;
export type AddTaskTypeCategoryMutationResult = ApolloReactCommon.MutationResult<AddTaskTypeCategoryMutation>;
export type AddTaskTypeCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTaskTypeCategoryMutation, AddTaskTypeCategoryMutationVariables>;
export const RemoveTaskTypeCategoryDocument = gql`
    mutation RemoveTaskTypeCategory($category: task_category_enum!, $codes: [String!]!) {
  delete_task_type_task_category(
    where: {_and: [{task_category_value: {_eq: $category}}, {task_type_code: {_in: $codes}}]}
  ) {
    affected_rows
    returning {
      task_type {
        ...AdminTaskTypeFields
      }
    }
  }
}
    ${AdminTaskTypeFieldsFragmentDoc}`;
export type RemoveTaskTypeCategoryMutationFn = ApolloReactCommon.MutationFunction<RemoveTaskTypeCategoryMutation, RemoveTaskTypeCategoryMutationVariables>;

/**
 * __useRemoveTaskTypeCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveTaskTypeCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskTypeCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskTypeCategoryMutation, { data, loading, error }] = useRemoveTaskTypeCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *      codes: // value for 'codes'
 *   },
 * });
 */
export function useRemoveTaskTypeCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTaskTypeCategoryMutation, RemoveTaskTypeCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTaskTypeCategoryMutation, RemoveTaskTypeCategoryMutationVariables>(RemoveTaskTypeCategoryDocument, baseOptions);
      }
export type RemoveTaskTypeCategoryMutationHookResult = ReturnType<typeof useRemoveTaskTypeCategoryMutation>;
export type RemoveTaskTypeCategoryMutationResult = ApolloReactCommon.MutationResult<RemoveTaskTypeCategoryMutation>;
export type RemoveTaskTypeCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTaskTypeCategoryMutation, RemoveTaskTypeCategoryMutationVariables>;
export const UsersListDocument = gql`
    subscription UsersList {
  users {
    id
    full_name
    email
    isActive
    primary_role
    abbreviation
    last_login
  }
}
    `;

/**
 * __useUsersListSubscription__
 *
 * To run a query within a React component, call `useUsersListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUsersListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUsersListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<UsersListSubscription, UsersListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<UsersListSubscription, UsersListSubscriptionVariables>(UsersListDocument, baseOptions);
      }
export type UsersListSubscriptionHookResult = ReturnType<typeof useUsersListSubscription>;
export type UsersListSubscriptionResult = ApolloReactCommon.SubscriptionResult<UsersListSubscription>;
export const AfterSalesPotentialListDocument = gql`
    query AfterSalesPotentialList($limit: Int, $offset: Int, $where: AfterSalesPotential_bool_exp!) {
  after_sales_potentials(limit: $limit, offset: $offset, where: $where) {
    ...AfterSalesPotentialsListFields
  }
  after_sales_potentials_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${AfterSalesPotentialsListFieldsFragmentDoc}`;

/**
 * __useAfterSalesPotentialListQuery__
 *
 * To run a query within a React component, call `useAfterSalesPotentialListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAfterSalesPotentialListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAfterSalesPotentialListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAfterSalesPotentialListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AfterSalesPotentialListQuery, AfterSalesPotentialListQueryVariables>) {
        return ApolloReactHooks.useQuery<AfterSalesPotentialListQuery, AfterSalesPotentialListQueryVariables>(AfterSalesPotentialListDocument, baseOptions);
      }
export function useAfterSalesPotentialListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AfterSalesPotentialListQuery, AfterSalesPotentialListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AfterSalesPotentialListQuery, AfterSalesPotentialListQueryVariables>(AfterSalesPotentialListDocument, baseOptions);
        }
export type AfterSalesPotentialListQueryHookResult = ReturnType<typeof useAfterSalesPotentialListQuery>;
export type AfterSalesPotentialListLazyQueryHookResult = ReturnType<typeof useAfterSalesPotentialListLazyQuery>;
export type AfterSalesPotentialListQueryResult = ApolloReactCommon.QueryResult<AfterSalesPotentialListQuery, AfterSalesPotentialListQueryVariables>;
export const AfterSalesPotentialLetterListDownloadDocument = gql`
    query AfterSalesPotentialLetterListDownload($input: ExportAfterSalesPotentialsInput!) {
  exportAfterSalesPotentials(input: $input) {
    download_url
  }
}
    `;

/**
 * __useAfterSalesPotentialLetterListDownloadQuery__
 *
 * To run a query within a React component, call `useAfterSalesPotentialLetterListDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useAfterSalesPotentialLetterListDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAfterSalesPotentialLetterListDownloadQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAfterSalesPotentialLetterListDownloadQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AfterSalesPotentialLetterListDownloadQuery, AfterSalesPotentialLetterListDownloadQueryVariables>) {
        return ApolloReactHooks.useQuery<AfterSalesPotentialLetterListDownloadQuery, AfterSalesPotentialLetterListDownloadQueryVariables>(AfterSalesPotentialLetterListDownloadDocument, baseOptions);
      }
export function useAfterSalesPotentialLetterListDownloadLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AfterSalesPotentialLetterListDownloadQuery, AfterSalesPotentialLetterListDownloadQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AfterSalesPotentialLetterListDownloadQuery, AfterSalesPotentialLetterListDownloadQueryVariables>(AfterSalesPotentialLetterListDownloadDocument, baseOptions);
        }
export type AfterSalesPotentialLetterListDownloadQueryHookResult = ReturnType<typeof useAfterSalesPotentialLetterListDownloadQuery>;
export type AfterSalesPotentialLetterListDownloadLazyQueryHookResult = ReturnType<typeof useAfterSalesPotentialLetterListDownloadLazyQuery>;
export type AfterSalesPotentialLetterListDownloadQueryResult = ApolloReactCommon.QueryResult<AfterSalesPotentialLetterListDownloadQuery, AfterSalesPotentialLetterListDownloadQueryVariables>;
export const SetAfterSalesPotentialStatusDocument = gql`
    mutation SetAfterSalesPotentialStatus($ids: [uuid!]!, $status: after_sales_status_enum!) {
  update_after_sales_potentials(where: {id: {_in: $ids}}, _set: {status: $status}) {
    affected_rows
    returning {
      id
      status
    }
  }
}
    `;
export type SetAfterSalesPotentialStatusMutationFn = ApolloReactCommon.MutationFunction<SetAfterSalesPotentialStatusMutation, SetAfterSalesPotentialStatusMutationVariables>;

/**
 * __useSetAfterSalesPotentialStatusMutation__
 *
 * To run a mutation, you first call `useSetAfterSalesPotentialStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAfterSalesPotentialStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAfterSalesPotentialStatusMutation, { data, loading, error }] = useSetAfterSalesPotentialStatusMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetAfterSalesPotentialStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetAfterSalesPotentialStatusMutation, SetAfterSalesPotentialStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<SetAfterSalesPotentialStatusMutation, SetAfterSalesPotentialStatusMutationVariables>(SetAfterSalesPotentialStatusDocument, baseOptions);
      }
export type SetAfterSalesPotentialStatusMutationHookResult = ReturnType<typeof useSetAfterSalesPotentialStatusMutation>;
export type SetAfterSalesPotentialStatusMutationResult = ApolloReactCommon.MutationResult<SetAfterSalesPotentialStatusMutation>;
export type SetAfterSalesPotentialStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<SetAfterSalesPotentialStatusMutation, SetAfterSalesPotentialStatusMutationVariables>;
export const EmbeddedPagesDocument = gql`
    query EmbeddedPages {
  embedded_pages {
    id
    brand_id
    name
    url
  }
}
    `;

/**
 * __useEmbeddedPagesQuery__
 *
 * To run a query within a React component, call `useEmbeddedPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmbeddedPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmbeddedPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmbeddedPagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmbeddedPagesQuery, EmbeddedPagesQueryVariables>) {
        return ApolloReactHooks.useQuery<EmbeddedPagesQuery, EmbeddedPagesQueryVariables>(EmbeddedPagesDocument, baseOptions);
      }
export function useEmbeddedPagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmbeddedPagesQuery, EmbeddedPagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmbeddedPagesQuery, EmbeddedPagesQueryVariables>(EmbeddedPagesDocument, baseOptions);
        }
export type EmbeddedPagesQueryHookResult = ReturnType<typeof useEmbeddedPagesQuery>;
export type EmbeddedPagesLazyQueryHookResult = ReturnType<typeof useEmbeddedPagesLazyQuery>;
export type EmbeddedPagesQueryResult = ApolloReactCommon.QueryResult<EmbeddedPagesQuery, EmbeddedPagesQueryVariables>;
export const SalesReportsListDocument = gql`
    subscription SalesReportsList($limit: Int, $offset: Int, $where: sales_reports_bool_exp) {
  sales_reports(limit: $limit, offset: $offset, where: $where) {
    ...SalesReportsFields
  }
}
    ${SalesReportsFieldsFragmentDoc}`;

/**
 * __useSalesReportsListSubscription__
 *
 * To run a query within a React component, call `useSalesReportsListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSalesReportsListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesReportsListSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSalesReportsListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<SalesReportsListSubscription, SalesReportsListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<SalesReportsListSubscription, SalesReportsListSubscriptionVariables>(SalesReportsListDocument, baseOptions);
      }
export type SalesReportsListSubscriptionHookResult = ReturnType<typeof useSalesReportsListSubscription>;
export type SalesReportsListSubscriptionResult = ApolloReactCommon.SubscriptionResult<SalesReportsListSubscription>;