import React, { CSSProperties } from 'react'
import { Typo } from 'components/Typo'
import clsx from 'clsx'

export interface NoDataTextProps {
  style?: CSSProperties
  className?: string
  size?: 'default' | 'small'
}

export const NoDataText: React.FC<NoDataTextProps> = (props) => {
  return (
    <Typo
      variant="h4"
      className={clsx('block text-center', props.size === 'small' && 'text-0.9', props.className)}
      style={props.style}
    >
      {props.children}
    </Typo>
  )
}
