import { Ticket_Bool_Exp, Vehicle_Bool_Exp } from '../../gql'
import { DateFns } from '../../utils'

export const getDateChipLabel = (expressions: (Vehicle_Bool_Exp | Ticket_Bool_Exp)[]) => {
  let prefix
  if (expressions.length === 1) {
    const field = Object.keys(expressions[0])[0]
    const operator = Object.keys(expressions[0][field])[0]
    if (operator === '_gt') {
      prefix = 'Seit'
    } else {
      prefix = 'Vor'
    }
  } else {
    prefix = 'zwischen'
  }

  const getPostfix = (value: Date) => {
    const monthsBtw = DateFns.differenceInMonths(DateFns.startOfDay(new Date()), value)
    const weeksBtw = DateFns.differenceInWeeks(DateFns.startOfDay(new Date()), value)
    const dayBtw = DateFns.differenceInDays(DateFns.startOfDay(new Date()), value)

    if (monthsBtw === 1) {
      return `vor einem Monat`
    } else if (monthsBtw === -1) {
      return `in einem Monat`
    } else if (monthsBtw > 1) {
      return `vor ${monthsBtw} Monaten`
    } else if (monthsBtw < -1) {
      return `in ${Math.abs(monthsBtw)} Monaten`
    } else if (weeksBtw === 1) {
      return `vor einer Woche`
    } else if (weeksBtw === -1) {
      return `in einer Woche`
    } else if (weeksBtw > 1) {
      return `vor ${weeksBtw} Wochen`
    } else if (weeksBtw < -1) {
      return `in ${Math.abs(weeksBtw)} Wochen`
    } else if (dayBtw === 1) {
      return `gestern`
    } else if (dayBtw === -1) {
      return `morgen`
    } else if (dayBtw === 2) {
      return `vorgestern`
    } else if (dayBtw === -2) {
      return `übermorgen`
    } else if (dayBtw > 2) {
      return `vor ${dayBtw} Tagen`
    } else {
      return `in ${Math.abs(dayBtw)} Tagen`
    }
  }

  const postfix = expressions
    .map((exp) => {
      const field = Object.keys(exp)[0]
      const operator = Object.keys(exp[field])[0]
      const value = new Date(exp[field][operator])
      return getPostfix(value)
    })
    .join(' und ')

  return `${prefix} ${postfix}`
}
