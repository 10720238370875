import { useCallback, useEffect, useMemo, useState } from 'react'
import { DateFns } from '../../../utils'
import React from 'react'
import { LMChipProps, LMOptionsProps } from '../Vehicles/Filter/Chips'
import { HighlightsColorOptions } from '../ColorOptions'
import { ListManipulation, useListManipilationContext } from '../unit'
import { getDateChipLabel } from 'containers/ListManipulation/getDateChipLabel'
import { EntityType, General_Bool_Exp } from 'containers/ListManipulation/useFilterOperations'

const simplePastOptions = [
  { label: 'gestern', date: DateFns.subDays(DateFns.startOfDay(new Date()), 1) },
  {
    label: 'vorgestern',
    date: DateFns.subDays(DateFns.startOfDay(new Date()), 2),
  },
  { label: 'vor einer Woche', date: DateFns.subWeeks(DateFns.startOfDay(new Date()), 1) },
  {
    label: 'vor 2 Wochen',
    date: DateFns.subWeeks(DateFns.startOfDay(new Date()), 2),
  },
  { label: 'vor einem Monat', date: DateFns.subMonths(DateFns.startOfDay(new Date()), 1) },
  {
    label: 'vor 3 Monaten',
    date: DateFns.subMonths(DateFns.startOfDay(new Date()), 3),
  },
  { label: 'vor einem Jahr', date: DateFns.subYears(DateFns.startOfDay(new Date()), 1) },
  {
    label: 'vor 2 Jahren',
    date: DateFns.subYears(DateFns.startOfDay(new Date()), 2),
  },
]

const simpleFutureOptions = [
  { label: 'morgen', date: DateFns.addDays(DateFns.startOfDay(new Date()), 1) },
  {
    label: 'übermorgen',
    date: DateFns.addDays(DateFns.startOfDay(new Date()), 2),
  },
  { label: 'in einer Woche', date: DateFns.addWeeks(DateFns.startOfDay(new Date()), 1) },
  {
    label: 'in 2 Wochen',
    date: DateFns.addWeeks(DateFns.startOfDay(new Date()), 2),
  },
  { label: 'in einem Monat', date: DateFns.addMonths(DateFns.startOfDay(new Date()), 1) },
  {
    label: 'in 3 Monaten',
    date: DateFns.addMonths(DateFns.startOfDay(new Date()), 3),
  },
  { label: 'in einem Jahr', date: DateFns.addYears(DateFns.startOfDay(new Date()), 1) },
  {
    label: 'in 2 Jahren',
    date: DateFns.addYears(DateFns.startOfDay(new Date()), 2),
  },
]

export const DateChip = <T extends EntityType>(
  props: LMChipProps<T> & {
    field:
      | 'sold_at'
      | 'updated_at'
      | 'warranty_start'
      | 'warranty_end'
      | 'first_registration_date'
      | 'reserved_at'
      | 'reserved_until'
      | 'invoice_date'
      | 'created_at'
      | 'due_to'
      | 'handover_date'
      | 'handover_planned_date'
    expressions?: General_Bool_Exp<T>[]
  }
) => {
  const { field: dateField } = props
  const { getStandalone, deleteAll, deleteExpressions } = props.operations
  const { setField, field } = useListManipilationContext<T>()

  const fieldLabel = useMemo(() => {
    switch (dateField) {
      case 'sold_at':
        return 'Verkauft am'
      case 'updated_at':
        return 'Zuletzt aktualisiert am'
      case 'warranty_start':
        return 'Garantiestart'
      case 'warranty_end':
        return 'Garantie Ablaufsdatum'
      case 'first_registration_date':
        return 'Erstzulassungsdatum'
      case 'reserved_at':
        return 'Reserviert am'
      case 'reserved_until':
        return 'Reserviert bis'
      case 'invoice_date':
        return 'Rechnungsdatum'
      case 'created_at':
        return 'Erstellt am'
      case 'due_to':
        return 'Zu erledigen bis'
      case 'handover_date':
        return 'Erfassungsdatum'
      case 'handover_planned_date':
        return 'Auslieferungsdatum'
    }
  }, [dateField])

  const expressions = useMemo(() => props.expressions ?? getStandalone(dateField, props.highlightIndex), [
    dateField,
    getStandalone,
    props.highlightIndex,
    props.expressions,
  ])
  const handleDelete = useCallback(() => {
    props.expressions ? deleteExpressions(props.expressions) : deleteAll(dateField, props.highlightIndex)
  }, [deleteAll, deleteExpressions, dateField, props.expressions, props.highlightIndex])
  if (!Boolean(expressions.length)) return null
  return (
    <ListManipulation.Group onDelete={handleDelete} variant={props.color ? 'filled' : 'outlined'} color={props.color}>
      <ListManipulation.GroupUnit
        label={`${fieldLabel}: ${getDateChipLabel(expressions)}`}
        onClick={() => {
          props.onClick && props.onClick()
          setField(dateField)
        }}
      >
        {field !== 'color' && (
          <DateOptions
            type={props.type === 'highlight' ? 'highlight' : 'filter'}
            operations={props.operations}
            highlightIndex={props.highlightIndex}
          />
        )}
        {field === 'color' && <HighlightsColorOptions />}
      </ListManipulation.GroupUnit>
    </ListManipulation.Group>
  )
}

export const DateOptions = <T extends EntityType>(
  props: LMOptionsProps<T> & { expressions?: General_Bool_Exp<T>[] }
) => {
  const { field, setField, parentExpression, leadingExpression } = useListManipilationContext<T>()
  const {
    cleanSetChildren,
    deleteAllStandalones,
    setAlternativeExpression,
    cleanSetStandalones,
    getStandalone,
    getChildren,
    setStandalonesWithDelete,
  } = props.operations

  const fieldLabel = useMemo(() => {
    switch (field) {
      case 'sold_at':
        return 'Verkaufsdatum'
      case 'updated_at':
        return 'Zuletzt aktualisert am'
      case 'warranty_start':
        return 'Garantiestart'
      case 'warranty_end':
        return 'Garantie Ablaufsdatum'
      case 'first_registration_date':
        return 'Erstzulassung'
      case 'reserved_at':
        return 'Reserviert am'
      case 'reserved_until':
        return 'Reserviert bis'
      case 'invoice_date':
        return 'Rechnungsdatum'
      case 'created_at':
        return 'Erstellt am'
      case 'due_to':
        return 'Zu erledigen bis'
      case 'handover_planned_date':
        return 'Auslieferungsdatum'
      case 'handover_date':
        return 'Erfassungsdatum'
    }
  }, [field])

  const [operator, setOperator] = useState<'_lt' | '_gt'>()

  const handleClick = useCallback(
    (date: Date) => () => {
      const expression = { [field]: { [operator]: date } }
      if (parentExpression) {
        cleanSetChildren([expression], parentExpression)
      } else if (leadingExpression) {
        deleteAllStandalones(field)
        setAlternativeExpression(expression, leadingExpression)
      } else {
        cleanSetStandalones([expression])
      }
    },
    [
      setAlternativeExpression,
      leadingExpression,
      deleteAllStandalones,
      cleanSetChildren,
      cleanSetStandalones,
      field,
      operator,
      parentExpression,
    ]
  )

  const handleInBetweenClick = useCallback(
    (expressions: General_Bool_Exp<T>[]) => () => {
      if (parentExpression) {
        cleanSetChildren(expressions, parentExpression)
      } else if (leadingExpression) {
        deleteAllStandalones(field)
        expressions.forEach((e) => setAlternativeExpression(e, leadingExpression))
      } else {
        cleanSetStandalones(expressions)
      }
    },
    [
      setAlternativeExpression,
      cleanSetStandalones,
      leadingExpression,
      cleanSetChildren,
      deleteAllStandalones,
      field,
      parentExpression,
    ]
  )

  const disabled = !operator
  const expressions = useMemo(
    () =>
      props.expressions
        ? props.expressions
        : parentExpression
        ? getChildren(field, parentExpression, props.highlightIndex)
        : getStandalone(field, props.highlightIndex),
    [field, getChildren, getStandalone, parentExpression, props.highlightIndex, props.expressions]
  )
  const getExpressionValue = useCallback(
    (operator: '_gt' | '_lt') =>
      expressions.find((expression) => expression?.[field]?.[operator])?.[field]?.[operator] ?? null,
    [expressions, field]
  )
  useEffect(() => {
    setLaterThan(getExpressionValue('_gt'))
    setEarlierThan(getExpressionValue('_lt'))
  }, [getExpressionValue])
  const checked = useCallback(
    (date: Date) =>
      expressions?.some((e) => DateFns.isSameDay(DateFns.startOfDay(new Date(e?.[field]?.[operator])), date)),
    [expressions, field, operator]
  )
  const checkedInBetween = useCallback(
    (btw: { _lt: Date; _gt: Date }) =>
      expressions?.some(
        (e) =>
          DateFns.isSameDay(DateFns.startOfDay(new Date(e?.[field]?._lt)), btw._lt) &&
          DateFns.isSameDay(DateFns.startOfDay(new Date(e?.[field]?._gt)), btw._gt)
      ),
    [expressions, field]
  )

  const [earlierThan, setEarlierThan] = useState<Date>()
  const [laterThan, setLaterThan] = useState<Date>()

  const handleLaterThanChange = useCallback(
    (date: Date) => {
      setLaterThan(date)
      const expression = { [field]: { _gt: date } }
      if (parentExpression) {
        cleanSetChildren([expression], parentExpression, '_gt')
      } else if (leadingExpression) {
        deleteAllStandalones(field)
        setAlternativeExpression(expression, leadingExpression)
      } else {
        const expressionsToDelete = expressions.filter((exp) => exp?.[field]?._gt)
        setStandalonesWithDelete([expression], expressionsToDelete)
      }
    },
    [
      field,
      parentExpression,
      leadingExpression,
      cleanSetChildren,
      deleteAllStandalones,
      setAlternativeExpression,
      setStandalonesWithDelete,
      expressions,
    ]
  )

  const handleEarlierThanChange = useCallback(
    (date: Date) => {
      const expression = { [field]: { _lt: date } }
      if (parentExpression) {
        cleanSetChildren([expression], parentExpression, '_lt')
      } else if (leadingExpression) {
        deleteAllStandalones(field)
        setAlternativeExpression(expression, leadingExpression)
      } else {
        const expressionsToDelete = expressions.filter((exp) => exp?.[field]?._lt)
        setStandalonesWithDelete([expression], expressionsToDelete)
      }
    },
    [
      cleanSetChildren,
      setStandalonesWithDelete,
      deleteAllStandalones,
      field,
      leadingExpression,
      parentExpression,
      setAlternativeExpression,
      expressions,
    ]
  )

  return (
    <ListManipulation.UnitOptions label={fieldLabel}>
      <ListManipulation.RadioGroupOption
        value={operator}
        options={[
          { value: '_lt', label: 'Vor' },
          { value: '_gt', label: 'Seit' },
        ]}
        onChange={(evt) => setOperator(evt.currentTarget.value as '_lt' | '_gt')}
      />
      {simplePastOptions.map((o) => (
        <ListManipulation.ItemOption onClick={handleClick(o.date)} disabled={disabled} checked={checked(o.date)}>
          {o.label}
        </ListManipulation.ItemOption>
      ))}
      {field === 'due_to' &&
        simpleFutureOptions.map((o) => (
          <ListManipulation.ItemOption onClick={handleClick(o.date)} disabled={disabled} checked={checked(o.date)}>
            {o.label}
          </ListManipulation.ItemOption>
        ))}
      <hr className={'text-divider'} />
      <ListManipulation.ItemOption
        onClick={handleInBetweenClick([
          { [field]: { _gt: DateFns.subMonths(DateFns.startOfDay(new Date()), 12) } },
          { [field]: { _lt: DateFns.subMonths(DateFns.startOfDay(new Date()), 6) } },
        ])}
        checked={checkedInBetween({
          _lt: DateFns.subMonths(DateFns.startOfDay(new Date()), 6),
          _gt: DateFns.subMonths(DateFns.startOfDay(new Date()), 12),
        })}
      >
        In den letzten 6 bis 12 Monaten
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption
        onClick={handleInBetweenClick([
          { [field]: { _gt: DateFns.subMonths(DateFns.startOfDay(new Date()), 18) } },
          { [field]: { _lt: DateFns.subMonths(DateFns.startOfDay(new Date()), 12) } },
        ])}
        checked={checkedInBetween({
          _lt: DateFns.subMonths(DateFns.startOfDay(new Date()), 12),
          _gt: DateFns.subMonths(DateFns.startOfDay(new Date()), 18),
        })}
      >
        In den letzten 12 bis 18 Monaten
      </ListManipulation.ItemOption>
      <hr className={'text-divider'} />
      <ListManipulation.DateInputOption label={'Seit'} value={laterThan} onChange={handleLaterThanChange} />
      <ListManipulation.DateInputOption label={'Vor'} value={earlierThan} onChange={handleEarlierThanChange} />
      {props.type === 'highlight' && (
        <>
          <hr className={'text-divider'} />
          <ListManipulation.ItemOption disabled={!expressions.length} onClick={() => setField('color')}>
            Farbe auswählen
          </ListManipulation.ItemOption>
        </>
      )}
    </ListManipulation.UnitOptions>
  )
}
