import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Customer_Type_Enum,
  Dealer_Vehicle_Type_Enum,
  In_Buy_Channel_Enum,
  Order_State_Enum,
  Registration_State_Enum,
  Ticket_Priority_Enum,
  Ticket_State_Enum,
} from '../../../gql'
import { Typo } from '../../../components'
import { HighlightsColorOptions } from '../ColorOptions'
import { LMChipProps, LMOptionsProps } from '../Vehicles/Filter/Chips'
import { ListManipulation, useListManipilationContext } from '../unit'
import { EntityType, General_Bool_Exp } from 'containers/ListManipulation/useFilterOperations'

export const EnumOptions = <T extends EntityType>(
  props: LMOptionsProps<T> & { expressions?: General_Bool_Exp<T>[] }
) => {
  const { field, setField, leadingExpression } = useListManipilationContext<T>()
  const { setStandalone, deleteExpression, getAll, setAlternativeExpression } = props.operations
  const { t } = useTranslation()

  const { i18nPrefix, fieldLabel, enumObject, nullOptionValue } = useMemo(() => {
    switch (field) {
      case 'customer_type':
        return {
          i18nPrefix: 'CustomerType.',
          fieldLabel: 'Kundentyp',
          enumObject: Customer_Type_Enum,
          nullOptionValue: 'Kein Kundentyp',
        }
      case 'reserved_for_customer_type':
        return {
          i18nPrefix: 'CustomerType.',
          fieldLabel: 'Reserviert für (Typ)',
          enumObject: Customer_Type_Enum,
          nullOptionValue: 'Kein Reserviert für (Typ)',
        }
      case 'type':
        return {
          i18nPrefix: 'DealerVehicleType.',
          fieldLabel: 'Typ',
          enumObject: Dealer_Vehicle_Type_Enum,
          nullOptionValue: 'Kein Typ',
        }
      case 'registration_state':
        return {
          i18nPrefix: 'RegistrationState.',
          fieldLabel: 'Zulassungs Status',
          enumObject: Registration_State_Enum,
          nullOptionValue: 'Kein Zulassungs Status',
        }
      case 'in_buy_channel':
        return {
          i18nPrefix: 'InBuyChannel.',
          fieldLabel: 'Zukaufs Kanal',
          enumObject: In_Buy_Channel_Enum,
          nullOptionValue: 'Kein Zukaufs Kanal',
        }
      case 'state':
        return {
          i18nPrefix: 'TicketState.',
          fieldLabel: 'Status',
          enumObject: Ticket_State_Enum,
          nullOptionValue: 'Kein Status',
        }
      case 'priority':
        return {
          i18nPrefix: 'TicketPriority.',
          fieldLabel: 'Priorität',
          enumObject: Ticket_Priority_Enum,
          nullOptionValue: 'Keine Priorität',
        }
      case 'order_state':
        return {
          i18nPrefix: 'OrderState.',
          fieldLabel: 'Transport Status',
          enumObject: Order_State_Enum,
          nullOptionValue: 'Kein Transport Status',
        }
      default:
        return {
          i18nPrefix: '',
          fieldLabel: '',
          enumObject: Dealer_Vehicle_Type_Enum,
          nullOptionValue: '',
        }
    }
  }, [field])

  const expressions = useMemo(() => props.expressions ?? getAll(field, props.highlightIndex), [
    field,
    getAll,
    props.expressions,
    props.highlightIndex,
  ])
  const checked = useCallback((value: string) => expressions?.some((exp) => exp?.[field]?._eq === value), [
    expressions,
    field,
  ])
  const checkedNull = useMemo(() => expressions?.some((exp) => exp?.[field]?._is_null), [expressions, field])
  const handleChange = useCallback(
    (_eq: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) deleteExpression({ [field]: { _eq } })
      if (checked) {
        if (leadingExpression) {
          setAlternativeExpression({ [field]: { _eq } }, leadingExpression)
        } else {
          setStandalone({ [field]: { _eq } })
        }
      }
    },
    [deleteExpression, field, leadingExpression, setAlternativeExpression, setStandalone]
  )
  const handleChangeNull = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) deleteExpression({ [field]: { _is_null: true } })
      if (checked) setStandalone({ [field]: { _is_null: true } })
    },
    [deleteExpression, field, setStandalone]
  )

  return (
    <ListManipulation.UnitOptions label={fieldLabel}>
      {Object.keys(enumObject).map((key) => (
        <ListManipulation.CheckableOption checked={checked(enumObject[key])} onChange={handleChange(enumObject[key])}>
          <Typo variant={'body1'} className={'pr-4'}>
            {t(`${i18nPrefix}${enumObject[key]}`)}
          </Typo>
        </ListManipulation.CheckableOption>
      ))}
      <hr className={'text-divider'} />
      <ListManipulation.CheckableOption checked={checkedNull} onChange={handleChangeNull}>
        <Typo variant={'body1'} className={'pr-4'}>
          {nullOptionValue}
        </Typo>
      </ListManipulation.CheckableOption>
      {props.type === 'highlight' && (
        <>
          <hr className={'text-divider'} />
          <ListManipulation.ItemOption disabled={!expressions.length} onClick={() => setField('color')}>
            Farbe auswählen
          </ListManipulation.ItemOption>
        </>
      )}
    </ListManipulation.UnitOptions>
  )
}

export const EnumChip = <T extends EntityType>(
  props: LMChipProps<T> & {
    field:
      | 'type'
      | 'registration_state'
      | 'in_buy_channel'
      | 'state'
      | 'priority'
      | 'order_state'
      | 'customer_type'
      | 'reserved_for_customer_type'
    expressions?: General_Bool_Exp<T>[]
  }
) => {
  const { field: enumField } = props
  const { getAll, deleteAll, deleteExpressions } = props.operations
  const { setField, field } = useListManipilationContext<T>()
  const { t } = useTranslation()

  const { i18nPrefix, fieldLabel, fieldLabelPlural, nullOptionValue } = useMemo(() => {
    switch (enumField) {
      case 'type':
        return {
          i18nPrefix: 'DealerVehicleType.',
          fieldLabel: 'Typ',
          fieldLabelPlural: 'Typen',
          nullOptionValue: 'Kein Typ',
        }
      case 'customer_type':
        return {
          i18nPrefix: 'CustomerType.',
          fieldLabel: 'Kundentyp',
          fieldLabelPlural: 'Kundentypen',
          nullOptionValue: 'Kein Kundentyp',
        }
      case 'reserved_for_customer_type':
        return {
          i18nPrefix: 'CustomerType.',
          fieldLabel: 'Reserviert für (Typ)',
          fieldLabelPlural: 'Reserviert für (Typen)',
          nullOptionValue: 'Kein Kundentyp',
        }
      case 'registration_state':
        return {
          i18nPrefix: 'RegistrationState.',
          fieldLabel: 'Zulassungs Status',
          fieldLabelPlural: 'Zulassungs Status',
          nullOptionValue: 'Kein Zulassungs Status',
        }
      case 'in_buy_channel':
        return {
          i18nPrefix: 'InBuyChannel.',
          fieldLabel: 'Zukaufs Kanal',
          fieldLabelPlural: 'Zukaufs Kanäle',
          nullOptionValue: 'Kein Zukaufs Kanal',
        }
      case 'state':
        return {
          i18nPrefix: 'TicketState.',
          fieldLabel: 'Status',
          fieldLabelPlural: 'Status',
          nullOptionValue: 'Kein Status',
        }
      case 'priority':
        return {
          i18nPrefix: 'TicketPriority.',
          fieldLabel: 'Priorität',
          fieldLabelPlural: 'Prioritäten',
          nullOptionValue: 'Keine Priorität',
        }
      case 'order_state':
        return {
          i18nPrefix: 'OrderState.',
          fieldLabel: 'Transport Status',
          fieldLabelPlural: 'Transport Status',
          nullOptionValue: 'Kein Transport Status',
        }
    }
  }, [enumField])

  const expressions = useMemo(() => props.expressions ?? getAll(enumField, props.highlightIndex), [
    enumField,
    getAll,
    props.expressions,
    props.highlightIndex,
  ])
  const label = useMemo(
    () =>
      expressions.length === 1
        ? `${fieldLabel}: ${
            expressions[0][enumField]._is_null ? nullOptionValue : t(`${i18nPrefix}${expressions[0][enumField]._eq}`)
          }`
        : `${expressions.length} ${fieldLabelPlural} ausgewählt`,
    [expressions, enumField, fieldLabel, fieldLabelPlural, i18nPrefix, nullOptionValue, t]
  )

  const handleDelete = useCallback(() => {
    props.expressions ? deleteExpressions(props.expressions) : deleteAll(enumField, props.highlightIndex)
  }, [deleteAll, deleteExpressions, enumField, props.expressions, props.highlightIndex])
  if (!Boolean(expressions.length)) return null
  return (
    <ListManipulation.Group variant={props.color ? 'filled' : 'outlined'} color={props.color} onDelete={handleDelete}>
      <ListManipulation.GroupUnit
        onClick={() => {
          props.onClick && props.onClick()
          setField(enumField)
        }}
        label={label}
      >
        {field !== 'color' && (
          <EnumOptions
            type={props.type === 'highlight' ? 'highlight' : 'filter'}
            operations={props.operations}
            highlightIndex={props.highlightIndex}
          />
        )}
        {field === 'color' && <HighlightsColorOptions />}
      </ListManipulation.GroupUnit>
    </ListManipulation.Group>
  )
}
