import React, { useCallback, useContext } from 'react'
import { EnumChip } from '../../Chips/EnumChip'
import { VehicleStatusChip } from '../Chips/StateChip'
import { VehicleLocationChip } from '../Chips/LocationsChip'
import { ListManipulationUnitGroupProps } from '../../unit'
import { VehicleIntChip } from '../Chips/IntChip'
import { ListConfigContext } from '../../../ListConfig'
import { Dealer_Vehicle_State_Enum_Comparison_Exp, Vehicle, Vehicle_Bool_Exp } from '../../../../gql'
import { EntityType, useFilterOperations, useHighlightOperationsI } from '../../useFilterOperations'
import { DateChip } from 'containers/ListManipulation/Chips/DateChip'
import { UserChip } from 'containers/ListManipulation/Chips/UserChip'

export interface LMOptionsProps<T extends EntityType> {
  operations: ReturnType<useHighlightOperationsI<T>>
  highlightIndex?: number
  type?: 'filter' | 'highlight'
}

export interface LMChipProps<T extends EntityType> {
  operations: ReturnType<useHighlightOperationsI<T>>
  type?: 'sold' | 'rest' | 'highlight' | 'available' | 'reserved'
  highlightIndex?: number
  onClick?: () => void
  color?: ListManipulationUnitGroupProps['color']
}

export const Chips = (props: { expressions?: Vehicle_Bool_Exp[] }) => {
  const operations = useFilterOperations<'Vehicle'>()
  const { type } = useContext(ListConfigContext)
  const usedCarsList = type?.toLowerCase()?.includes('used')

  const getExpressions = useCallback(
    (field: keyof Vehicle, stateCondition?: (expression: Dealer_Vehicle_State_Enum_Comparison_Exp) => boolean) => {
      if (!props.expressions) {
        return undefined
      }
      const expression = props.expressions?.[0]?.[field]
      if (!expression) {
        return []
      }
      if (!stateCondition) {
        return props.expressions?.filter((exp) => exp?.[field])
      }
      if (stateCondition(expression)) {
        return props.expressions?.filter((exp) => exp?.[field] && stateCondition(exp?.[field]))
      }
      return []
    },
    [props.expressions]
  )

  return (
    <>
      <VehicleStatusChip
        type={'available'}
        operations={operations}
        expressions={getExpressions('state', (exp) => exp._eq?.startsWith('AVAILABLE'))}
      />
      <VehicleStatusChip
        type={'reserved'}
        operations={operations}
        expressions={getExpressions('state', (exp) => exp._eq?.startsWith('RESERVED'))}
      />
      <VehicleStatusChip
        type={'rest'}
        operations={operations}
        expressions={getExpressions(
          'state',
          (exp) => !exp._eq?.startsWith('SOLD') && !exp._eq?.startsWith('RESERVED') && !exp._eq?.startsWith('AVAILABLE')
        )}
      />
      <VehicleStatusChip
        type={'sold'}
        operations={operations}
        expressions={getExpressions('state', (exp) => exp._eq?.startsWith('SOLD'))}
      />
      <EnumChip<'Vehicle'> field={'type'} operations={operations} expressions={getExpressions('type')} />
      <EnumChip<'Vehicle'> field={'order_state'} operations={operations} expressions={getExpressions('order_state')} />
      <EnumChip<'Vehicle'>
        field={'registration_state'}
        operations={operations}
        expressions={getExpressions('registration_state')}
      />
      <EnumChip<'Vehicle'>
        field={'customer_type'}
        operations={operations}
        expressions={getExpressions('customer_type')}
      />
      <EnumChip<'Vehicle'>
        field={'reserved_for_customer_type'}
        operations={operations}
        expressions={getExpressions('reserved_for_customer_type')}
      />
      <VehicleLocationChip
        field={'storage_location_id'}
        operations={operations}
        expressions={getExpressions('storage_location_id')}
      />
      <VehicleLocationChip
        field={'delivery_location_id'}
        operations={operations}
        expressions={getExpressions('delivery_location_id')}
      />
      <VehicleLocationChip
        field={'dms_assigned_location_id'}
        operations={operations}
        expressions={getExpressions('dms_assigned_location_id')}
      />
      <VehicleLocationChip
        field={'seller_location_id'}
        operations={operations}
        expressions={getExpressions('seller_location_id')}
      />
      <DateChip<'Vehicle'> field={'sold_at'} operations={operations} expressions={getExpressions('sold_at')} />
      <DateChip<'Vehicle'> field={'updated_at'} operations={operations} expressions={getExpressions('updated_at')} />
      <DateChip<'Vehicle'>
        field={'warranty_start'}
        operations={operations}
        expressions={getExpressions('warranty_start')}
      />
      <DateChip<'Vehicle'>
        field={'warranty_end'}
        operations={operations}
        expressions={getExpressions('warranty_end')}
      />
      <DateChip<'Vehicle'>
        field={'first_registration_date'}
        operations={operations}
        expressions={getExpressions('first_registration_date')}
      />
      <DateChip<'Vehicle'> field={'reserved_at'} operations={operations} expressions={getExpressions('reserved_at')} />
      <DateChip<'Vehicle'>
        field={'reserved_until'}
        operations={operations}
        expressions={getExpressions('reserved_until')}
      />
      <DateChip<'Vehicle'>
        field={'invoice_date'}
        operations={operations}
        expressions={getExpressions('invoice_date')}
      />
      <DateChip<'Vehicle'>
        field={'handover_date'}
        operations={operations}
        expressions={getExpressions('handover_date')}
      />
      <DateChip<'Vehicle'>
        field={'handover_planned_date'}
        operations={operations}
        expressions={getExpressions('handover_planned_date')}
      />
      <UserChip<'Vehicle'> field={'sold_by_id'} operations={operations} expressions={getExpressions('sold_by_id')} />
      <UserChip<'Vehicle'>
        field={'handover_by_id'}
        operations={operations}
        expressions={getExpressions('handover_by_id')}
      />
      <UserChip<'Vehicle'>
        field={'reserved_by_id'}
        operations={operations}
        expressions={getExpressions('reserved_by_id')}
      />
      <VehicleIntChip
        field={'days_since_purchase'}
        operations={operations}
        expressions={getExpressions('days_since_purchase')}
      />
      {usedCarsList && (
        <EnumChip<'Vehicle'>
          field={'in_buy_channel'}
          operations={operations}
          expressions={getExpressions('in_buy_channel')}
        />
      )}
    </>
  )
}
