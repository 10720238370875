import React, { CSSProperties, useMemo } from 'react'
import { useBrandsQuery } from '../../gql'
import clsx from 'clsx'
import { useBrandId } from '../ListConfig/Vehicles/hooks/useBrandId'

interface BrandTabBarProps {
  onlyHouseBrands?: boolean
  className?: string
  style?: CSSProperties
}

export const BrandTabBar = (props: BrandTabBarProps) => {
  const { get: getBrandId, set: setBrandId } = useBrandId()
  const query = useBrandsQuery()
  const data = useMemo(() => {
    let result = query.data?.brands ?? []
    if (props.onlyHouseBrands) {
      result = result.filter((b) => b.is_house_brand)
    }
    return result
  }, [props.onlyHouseBrands, query.data?.brands])

  return (
    <div className={clsx('flex flex-row cursor-pointer overflow-x-auto', props.className)} style={props.style}>
      <BrandTab onClick={() => setBrandId(undefined)} selected={!getBrandId()} label={'Alle'} />
      {data.map((brand) => (
        <BrandTab
          key={`brand-tab-${brand.id}`}
          onClick={() => setBrandId(brand.id)}
          selected={getBrandId() === brand.id}
          label={brand.name}
        />
      ))}
    </div>
  )
}

interface BrandTabProps {
  onClick: () => void
  selected: boolean
  label: string
  className?: string
  style?: CSSProperties
}

const BrandTab = (props: BrandTabProps) => {
  return (
    <div
      onClick={props.onClick}
      className={clsx(
        'px-4 border-primary rounded-b-md whitespace-nowrap',
        props.selected && 'border-l border-b border-r text-primary-main'
      )}
    >
      {props.label}
    </div>
  )
}
