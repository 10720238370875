import React, { useContext } from 'react'
import { VehicleStatusChip } from '../Chips/StateChip'
import { ListConfigContext, RowHighlight } from '../../../ListConfig'
import { EnumChip } from '../../Chips/EnumChip'
import { VehicleLocationChip } from '../Chips/LocationsChip'
import { Vehicle } from '../../../../gql'
import { LMChipProps } from '../Filter/Chips'
import { VehicleIntChip } from '../Chips/IntChip'
import { useHighlightOperations } from 'containers/ListManipulation/useFilterOperations'
import { useListManipilationContext } from '../../unit'
import { UserChip } from 'containers/ListManipulation/Chips/UserChip'
import { DateChip } from 'containers/ListManipulation/Chips/DateChip'

export const VehicleHighlightsChips = () => {
  const operations = useHighlightOperations<'Vehicle'>()
  const { setHighlightIndex } = useListManipilationContext<'Vehicle'>()
  const { highlights } = useContext(ListConfigContext)

  const enumFields = ['type', 'registration_state', 'customer_type', 'reserved_for_customer_type']
  const dateFields = [
    'sold_at',
    'updated_at',
    'warranty_start',
    'warranty_end',
    'first_registration_date',
    'reserved_at',
    'reserved_until',
    'invoice_date',
    'handover_date',
    'handover_planned_date',
  ]
  const userFields = ['sold_by_id', 'reserved_by_id', 'handover_by_id']
  const locationFields = [
    'storage_location_id',
    'delivery_location_id',
    'dms_assigned_location_id',
    'seller_location_id',
  ]

  const bindings = (highlight: RowHighlight<Vehicle>, idx: number): LMChipProps<'Vehicle'> & { field: any } => ({
    color: highlight.color,
    type: 'highlight',
    operations,
    onClick: () => setHighlightIndex(idx),
    highlightIndex: idx,
    field: highlight.entryField,
  })
  return (
    <>
      {React.Children.toArray(
        highlights?.map((highlight, idx) => (
          <div>
            {highlight.entryField === 'state' && <VehicleStatusChip {...bindings(highlight, idx)} />}
            {enumFields.includes(highlight.entryField) && <EnumChip<'Vehicle'> {...bindings(highlight, idx)} />}
            {locationFields.includes(highlight.entryField) && <VehicleLocationChip {...bindings(highlight, idx)} />}
            {dateFields.includes(highlight.entryField) && <DateChip<'Vehicle'> {...bindings(highlight, idx)} />}
            {userFields.includes(highlight.entryField) && <UserChip<'Vehicle'> {...bindings(highlight, idx)} />}
            {highlight.entryField === 'days_since_purchase' && <VehicleIntChip {...bindings(highlight, idx)} />}
          </div>
        ))
      )}
    </>
  )
}
