import { Button } from 'components/Button'
import { Dialog } from 'components/Dialog'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { wrapEvent } from 'utils'

interface ConfirmDialogProps {
  loading?: boolean
  onCancel?: () => void
  onConfirm?: () => void
  children?: ReactNode
  title?: string
  confirmButtonText?: string
  cancelButtonText?: string
  hideCancelButton?: boolean
}

interface ConfirmResult {
  hide: () => void
}

export const useConfirm = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const promise = useRef({
    resolve: (_res: ConfirmResult) => {},
    reject: () => {},
  })
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const ConfirmDialog = useCallback(
    (props: ConfirmDialogProps) => {
      if (!visible) {
        return null
      }

      const handleCancel = wrapEvent(props.onCancel, () => {
        promise.current.reject()
        setVisible(false)
      })

      const handleConfirm = wrapEvent(props.onConfirm, () => {
        promise.current.resolve({
          hide: () => {
            mounted.current && setVisible(false)
          },
        })
      })

      return (
        <Dialog
          onClose={handleCancel}
          onClick={(evt) => {
            evt.stopPropagation()
          }}
        >
          <Dialog.Title>{props.title}</Dialog.Title>
          <Dialog.Content>
            {props.children}
            <Dialog.Actions>
              <Button variant="contained" color="primary" onClick={handleConfirm} disabled={props.loading}>
                {props.confirmButtonText || 'Zustimmen'}
              </Button>
              {!props.hideCancelButton && (
                <Button onClick={handleCancel} disabled={props.loading}>
                  {props.hideCancelButton || 'Abbrechen'}
                </Button>
              )}
            </Dialog.Actions>
          </Dialog.Content>
        </Dialog>
      )
    },
    [visible]
  )

  const confirm = useCallback(
    () =>
      new Promise<ConfirmResult>((resolve, reject) => {
        promise.current.resolve = resolve
        promise.current.reject = reject
        setVisible(true)
      }),
    [promise]
  )

  return [ConfirmDialog, confirm] as [typeof ConfirmDialog, typeof confirm]
}
