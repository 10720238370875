import * as L from '../../layouts'
import { v4 as uuid } from 'uuid'
import { Icon, IconButton, TextField, Typo } from '../../components'
import React, { useEffect, useRef } from 'react'
import { DmsReference, Dms_Type_Enum } from '../../gql'
import { useFormFields } from '@w2solutions/react-hooks'

export interface DmsReferenceFormSectionProps {
  title?: string
  dmsReferences: DmsReference[]
  onAdd: (data: DmsReference) => void
  onRemove: (idx: number, reference: DmsReference) => void
  onChange: (idx: number, reference: DmsReference) => void
  readonly?: boolean
}

export const DmsReferenceFormSection = (props: DmsReferenceFormSectionProps) => {
  const title = props.title ?? 'DMS Nummern'
  const handleAdd = () => {
    props.onAdd({
      ...initialDmsReference,
      id: uuid(),
    })
  }
  return (
    <L.Vertical>
      <L.Horizontal>
        <Typo>{title}</Typo>
        {!props.readonly && (
          <IconButton
            aria-label={'add'}
            color={'primary'}
            tooltip={'DMS Nummer hinzufügen'}
            size={'small'}
            onClick={handleAdd}
          >
            <Icon name={'add'} />
          </IconButton>
        )}
      </L.Horizontal>
      <div>
        {props.dmsReferences.map((dmsRef, index) => (
          <L.Horizontal key={`cus-${index}`}>
            <DMSReference dmsRef={dmsRef} onChange={(data) => props.onChange(index, data)} readOnly={props.readonly} />
            {!props.readonly && (
              <IconButton
                aria-label={'remove'}
                color={'secondary'}
                onClick={() => {
                  const reference = props.dmsReferences[index]
                  props.onRemove(index, reference)
                }}
                size="large">
                <Icon name={'delete'} />
              </IconButton>
            )}
          </L.Horizontal>
        ))}
      </div>
    </L.Vertical>
  );
}

const initialDmsReference = {
  id: '',
  name: '',
  reference: '',
  type: Dms_Type_Enum.Locosoft,
}

type DmsFormData = typeof initialDmsReference

interface DmsReferenceProps {
  dmsRef: DmsFormData
  onChange: (data: DmsFormData) => void
  readOnly?: boolean
}

const DMSReference = (props: DmsReferenceProps) => {
  const { bindings, values } = useFormFields(props.dmsRef, { resetOnInitialValueChange: true })
  const changeRef = useRef(props.onChange)
  changeRef.current = props.onChange
  useEffect(() => {
    changeRef.current?.call(null, values)
  }, [values])
  return (
    <L.Grid cols={3}>
      <TextField label={'Name'} {...bindings.name} readOnly={props.readOnly} />
      <TextField label={'DMS Nummer'} {...bindings.reference} readOnly={props.readOnly} />
      <TextField label={'DMS Typ'} value={'LOCOSOFT'} readOnly />
    </L.Grid>
  )
}
