import { SchemaLink } from '@apollo/link-schema'
import { ApolloLink } from '@apollo/client'
import { makeExecutableSchema } from '@graphql-tools/schema'
import { addMocksToSchema } from '@graphql-tools/mock'
import { loader } from 'graphql.macro'

import { mocks, resolvers } from 'mocks'

const gqlImport = loader('../../../../schema.graphql')
const typeDefs = `${gqlImport.loc?.source.body}`

// Make a GraphQL schema with no resolvers
const schema = makeExecutableSchema({ typeDefs, resolvers })

// Add mocks, modifies schema in place
const schemaWithMocks = addMocksToSchema({
  schema,
  mocks,
  preserveResolvers: true,
})

export class MockDataLink extends ApolloLink {
  private schemaLink: SchemaLink
  constructor() {
    super()
    this.schemaLink = new SchemaLink({ schema: schemaWithMocks })
  }

  request(operation) {
    return this.schemaLink.request(operation) as any
  }
}
