import { MultiSelect } from 'components'
import { Role_Enum } from 'gql'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface UserRolesSelectProps {
  value: Role_Enum[]
  onChange: (role: Role_Enum[]) => void
  label?: string
  withLabel?: boolean
}

export const UserRolesSelect = (props: UserRolesSelectProps) => {
  const { t } = useTranslation()
  return (
    <MultiSelect
      label={props.label ?? 'Rollen'}
      name={'userRolesSelect'}
      value={props.value}
      onChange={(evt) => props.onChange(evt.value as Role_Enum[])}
      renderValueItem={(role) => t(`UserRole.${role}`)}
    >
      {Object.keys(Role_Enum).map((role) => (
        <MultiSelect.Option key={role} value={Role_Enum[role]}>
          {t(`UserRole.${Role_Enum[role]}`)}
        </MultiSelect.Option>
      ))}
    </MultiSelect>
  )
}
