import React from 'react'
import { Card, SiteMetadata, Typo } from '../../components'
import { FallbackProps } from 'components/ErrorBoundary'
import { DefaultPageLayout } from '../DefaultPageLayout'
import * as L from 'layouts'
import { Link } from '@mui/material'

export const ErrorFallback = (_props: FallbackProps) => {
  return (
    <DefaultPageLayout>
      <SiteMetadata title={'Etwas ist schief gegangen'} />
      <Card className={'p-4'}>
        <L.Vertical spacing={4}>
          <Typo variant={'h3'}>Leider ist am Client etwas schief gegangen. Bitte kehre um.</Typo>
          <Link href={'/'}>Zurück an den Start</Link>
        </L.Vertical>
      </Card>
    </DefaultPageLayout>
  )
}
