import React, { useEffect, useMemo, useState } from 'react'
import * as L from 'layouts'
import {
  AdminAssetLinkDetailQuery,
  Asset_Watchdog_Link_Status_Enum,
  useAdminAssetLinkDetailLazyQuery,
  useCreateAssetLinkMutation,
  useUpdateAssetLinkMutation,
} from 'gql/generated'
import { useFormFields } from '@w2solutions/react-hooks'
import { Spinner } from 'components/Spinner'
import { TextField } from 'components/TextField'
import { Button } from 'components/Button'
import { useConfirm } from 'components/Confirm'
import { AssetWatchdogStatusChip } from './AssetWatchdogLinkStatusChip'
import { DetailWindow } from 'components/DetailWindow'

interface AdminAssetLinkDetailProps {
  assetLinkId?: string
  onClose: () => void
}

const initialData = {
  name: '',
  url: '',
  sourceUrl: '',
  status: undefined,
}

type FormData = typeof initialData

const assetLinkToFormData = (assetLink?: AdminAssetLinkDetailQuery['asset_watchdog_links_by_pk']): FormData => {
  if (!assetLink) return initialData
  return {
    name: assetLink.name ?? '',
    url: assetLink.url ?? '',
    sourceUrl: assetLink.source_url ?? '',
    status: assetLink.status ?? undefined,
  }
}

export const AdminAssetLinkDetail = (props: AdminAssetLinkDetailProps) => {
  const [load, loadResponse] = useAdminAssetLinkDetailLazyQuery()
  const [ConfirmDialog, confirm] = useConfirm()
  const [saving, setSaving] = useState<boolean>(false)
  useEffect(() => {
    if (props.assetLinkId) {
      load({ variables: { id: props.assetLinkId } })
    }
  }, [props.assetLinkId, load])

  const initialValues = useMemo(() => {
    return assetLinkToFormData(loadResponse.data?.asset_watchdog_links_by_pk)
  }, [loadResponse.data])

  const { bindings, values } = useFormFields(initialValues, {
    resetOnInitialValueChange: true,
  })
  const [save] = useSave(props.assetLinkId)
  const submit = async () => {
    setSaving(true)
    await save(values)
    setSaving(false)
    props.onClose()
  }

  const setOkState = async () => {
    const { hide } = await confirm()
    setSaving(true)
    await save({ status: Asset_Watchdog_Link_Status_Enum.Ok })
    setSaving(false)
    loadResponse.refetch()
    hide()
  }
  if (loadResponse.loading || saving) {
    return <Spinner />
  }
  return (
    <DetailWindow onClose={props.onClose} width={700} title={props.assetLinkId ? 'Link bearbeiten' : 'Neuer Link'}>
      <L.Vertical spacing={6} className={'p-4'}>
        {props.assetLinkId && values.status && (
          <L.Horizontal style={{ marginBottom: 12 }} spacing={5}>
            <AssetWatchdogStatusChip status={values.status} />
            {props.assetLinkId && values.status === Asset_Watchdog_Link_Status_Enum.Updated && (
              <Button color="secondary" variant="contained" onClick={setOkState}>
                OK Status setzen
              </Button>
            )}
            <div style={{ flexGrow: 1 }}></div>
          </L.Horizontal>
        )}
        <L.Grid>
          <TextField label="Name" {...bindings.name} />
          <TextField label="Datei URL" {...bindings.url} />
          <TextField label="Quelle" {...bindings.sourceUrl} />
        </L.Grid>

        <L.Horizontal spacing={2}>
          <Button color="primary" variant="contained" onClick={submit}>
            Speichern
          </Button>
          <Button variant="outlined" onClick={props.onClose}>
            Abbrechen
          </Button>
        </L.Horizontal>
        <ConfirmDialog loading={saving} confirmButtonText="OK Status setzen">
          Mit dieser Aktion bestätigen Sie, dass Sie mit den Änderungen vertraut sind.
        </ConfirmDialog>
      </L.Vertical>
    </DetailWindow>
  )
}

const useSave = (id?: string) => {
  const [create, createResponse] = useCreateAssetLinkMutation()
  const [update, updateResponse] = useUpdateAssetLinkMutation()

  const save = async (data: Partial<FormData>) => {
    if (id) {
      await update({
        variables: {
          assetLinkId: id,
          assetLink: {
            name: data.name,
            url: data.url,
            source_url: data.sourceUrl,
            status: data.status,
          },
        },
      })
    } else {
      await create({
        variables: {
          object: {
            name: data.name,
            url: data.url,
            source_url: data.sourceUrl,
          },
        },
      })
    }
  }
  const state = useMemo(
    () => ({
      loading: createResponse.loading || updateResponse.loading,
    }),
    [createResponse.loading, updateResponse.loading]
  )
  return [save, state] as const
}
