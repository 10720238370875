import { AlertBox, Button, Form } from 'components'
import React, { useCallback, useState } from 'react'
import {
  Dealer_Vehicle_State_Enum,
  Dealer_Vehicle_Type_Enum,
  Import_Type_Enum,
  In_Buy_Channel_Enum,
  Order_State_Enum,
  Registration_State_Enum,
} from 'gql'
import { useEzAuthUserHasRoles, UserRole } from '@lib/ezauth'
import { useVehicleBulkFormular } from './useVehicleBulkFormular'
import { PulseForm } from '../../PulseForm'

interface BulkDataFormProps {
  ids: string[]
  onClose: () => void
  canImport: boolean
  canPartiallyImport?: boolean
  canEdit: boolean
  onImport?: (importType?: Import_Type_Enum) => Promise<void>
}

export const BulkDataForm = (props: BulkDataFormProps) => {
  const [saving, setSaving] = useState(false)
  const isAdmin = useEzAuthUserHasRoles(UserRole.ADMIN)

  const formConfig = useVehicleBulkFormular(props.ids)

  const handleSave = useCallback(async () => {
    setSaving(true)
    await formConfig.onSubmit()
    setSaving(false)
    props.onClose()
  }, [formConfig, props])
  const showActionBar = props.canEdit || props.canImport
  return (
    <>
      {!props.canEdit && (
        <AlertBox type="warning">Du hast ein oder mehrere Fahrzeuge ausgewählt, die nicht editierbar sind.</AlertBox>
      )}
      {!props.canImport && !props.canPartiallyImport && props.onImport && (
        <AlertBox type="info">
          Du hast ein oder mehrere Fahrzeuge ausgewählt, die nicht für den Import geeignet sind.
        </AlertBox>
      )}
      <Form>
        <PulseForm sections={formConfig?.sections} editMode disableNotes />
        {showActionBar && (
          <Form.ActionBar style={{ justifyContent: 'start' }}>
            {props.canEdit && (
              <Button loading={saving} variant="contained" onClick={handleSave}>
                Speichern
              </Button>
            )}
            {props.canImport && <Button onClick={() => props.onImport(Import_Type_Enum.Full)}>Import</Button>}
            {isAdmin && !props.canImport && props.canPartiallyImport && (
              <Button onClick={() => props.onImport(Import_Type_Enum.Partial)}>Teil-Import</Button>
            )}
          </Form.ActionBar>
        )}
      </Form>
    </>
  )
}

const initialData = {
  in_buy_channel: null as In_Buy_Channel_Enum,
  state: null as Dealer_Vehicle_State_Enum,
  order_state: null as Order_State_Enum,
  delivery_date_str: '',
  storage_location_id: '',
  storage_location_note: '',
  delivery_location_id: '',
  specs: '',
  spec_level: '',
  type: null as Dealer_Vehicle_Type_Enum,
  min_holding_period: null as number,
  registration_state: null as Registration_State_Enum,
  registration_owner: '',
  key_kabinet_number: '',
  reserved_by_id: '',
  sold_by_id: '',
  sold_at: null as string,
  reserved_at: null as string,
  reserved_until: null as string,
  customer: '',

  control_number: '',
  accessories_mounted: null as boolean,
  order_code: '',
}

export type BulkFormData = typeof initialData
