import React, { useCallback } from 'react'
import { Chips } from './Chips'
import { Icon, Menu } from '../../../../components'
import { VehicleLocationOptions } from '../Chips/LocationsChip'
import { VehicleDefaultOptions } from '../Chips/VehicleDefaultOptions'
import { EnumOptions } from '../../Chips/EnumChip'
import { VehicleStateOptions } from '../Chips/StateChip'
import { DateOptions } from '../../Chips/DateChip'
import { Vehicle_Bool_Exp } from '../../../../gql'
import { UserOptions } from '../../Chips/UserChip'
import { VehicleIntOptions } from '../Chips/IntChip'
import { useFilterOperations } from 'containers/ListManipulation/useFilterOperations'
import { useListManipilationContext } from '../../unit'

export const VehiclesFilter = () => {
  return (
    <div className={'flex gap-x-2 items-center'} style={{ flex: '0 1 100%' }}>
      <Chips />
      <VehiclesNewFilterButton>Filter Hinzufügen</VehiclesNewFilterButton>
    </div>
  )
}

interface VehiclesNewFilterButtonProps {
  onClick?: () => void
  expressions?: Vehicle_Bool_Exp[]
}

export const VehiclesNewFilterButton: React.FC<VehiclesNewFilterButtonProps> = (props) => {
  const { setField, field } = useListManipilationContext<'Vehicle'>()
  const operations = useFilterOperations<'Vehicle'>()

  const handleAddFilter = useCallback(() => {
    setField(undefined)
    props.onClick && props.onClick()
  }, [props, setField])

  return (
    <Menu>
      <Menu.Button
        onClick={handleAddFilter}
        className={'whitespace-nowrap'}
        variant={'text'}
        startIcon={<Icon name={'add'} />}
      >
        {props.children}
      </Menu.Button>
      {!field && <VehicleDefaultOptions />}
      {(field === 'type' ||
        field === 'registration_state' ||
        field === 'in_buy_channel' ||
        field === 'order_state' ||
        field === 'customer_type' ||
        field === 'reserved_for_customer_type') && (
        <EnumOptions<'Vehicle'>
          expressions={props.expressions?.filter((exp) => exp?.[field])}
          operations={operations}
        />
      )}
      {field === 'state' && (
        <VehicleStateOptions expressions={props.expressions?.filter((exp) => exp?.state)} operations={operations} />
      )}
      {(field === 'sold_at' ||
        field === 'first_registration_date' ||
        field === 'updated_at' ||
        field === 'warranty_start' ||
        field === 'warranty_end' ||
        field === 'reserved_at' ||
        field === 'reserved_until' ||
        field === 'invoice_date' ||
        field === 'handover_date' ||
        field === 'handover_planned_date') && (
        <DateOptions<'Vehicle'>
          expressions={props.expressions?.filter((exp) => exp?.[field])}
          operations={operations}
        />
      )}
      {(field === 'storage_location_id' ||
        field === 'delivery_location_id' ||
        field === 'dms_assigned_location_id' ||
        field === 'seller_location_id') && (
        <VehicleLocationOptions
          expressions={props.expressions?.filter((exp) => exp?.[field])}
          operations={operations}
        />
      )}
      {(field === 'sold_by_id' || field === 'reserved_by_id' || field === 'handover_by_id') && (
        <UserOptions<'Vehicle'>
          expressions={props.expressions?.filter((exp) => exp?.[field])}
          operations={operations}
        />
      )}
      {field === 'days_since_purchase' && (
        <VehicleIntOptions
          operations={operations}
          expressions={props.expressions?.filter((exp) => exp?.days_since_purchase)}
        />
      )}
    </Menu>
  )
}
