import { AlertBox, Button, Dialog, Spinner, Typo, useAlert } from 'components'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { ExcelImportContext, ExcelImportEntity, ExcelImportListItem, LogType } from '..'
import * as L from 'layouts'
import {
  Import_Job_Executable_Status_Enum,
  Import_Job_Status_Enum,
  useAddVehicleImportTemplateMutation,
  useAssignVehicleColumnToFieldMutation,
  useAssignVehicleImportTemplateToImportJobMutation,
  useClearImportJobVehicleExecutablesMutation,
  useDeleteVehicleImportTemplateMutation,
  useExecuteImportJobMutation,
  useImportTemplatesSubscription,
  useInheritFromVehicleImportTemplateMutation,
  usePreviewImportJobMutation,
  useRemoveParentVehicleImportTemplateMutation,
  useUnassignVehicleColumnFromFieldMutation,
  useResetVehicleExcelAssignmentsMutation,
} from 'gql'
import { useAsync, useFormFields } from '@w2solutions/react-hooks'
import { ChooseTemplate } from './choose-template'
import { ChooseAssignments } from './choose-assignments'
import { SaveTemplateDialog } from './save-template-dialog'
import { v4 as uuid } from 'uuid'

export interface LogEntry {
  row: number
  identifier: keyof ExcelImportEntity
  error: string
  type: LogType
}

type ImportTemplates = { id: string; name: string; strict?: boolean; excel_definition?: string[] }[]

export interface AssignmentsProps {
  entityName: string
  entityNamePlural: string
  i18nPrefix: string
  excelImportKey: string
  dvKeys: (keyof ExcelImportListItem)[]
  insertImportTemplate: ReturnType<typeof useAddVehicleImportTemplateMutation>
  inheritFromImportTemplate: ReturnType<typeof useInheritFromVehicleImportTemplateMutation>
  removeParentImportTemplate: ReturnType<typeof useRemoveParentVehicleImportTemplateMutation>
  deleteImportTemplate: ReturnType<typeof useDeleteVehicleImportTemplateMutation>
  assignColumnToField: ReturnType<typeof useAssignVehicleColumnToFieldMutation>
  unassignColumnFromField: ReturnType<typeof useUnassignVehicleColumnFromFieldMutation>
  assignImportTemplateToImportJob: ReturnType<typeof useAssignVehicleImportTemplateToImportJobMutation>
  resetAssignments: ReturnType<typeof useResetVehicleExcelAssignmentsMutation>
}

export const Assignments = (props: AssignmentsProps) => {
  const { state, dispatch, importJob, variant } = useContext(ExcelImportContext)

  const [preview] = usePreviewImportJobMutation()
  const [execute] = useExecuteImportJobMutation()
  const [clearExecutables] = useClearImportJobVehicleExecutablesMutation()
  const vehicle_variant = variant === 'vehicle'
  const importTemplatesSubscription = useImportTemplatesSubscription({ variables: { vehicle_variant } })

  // The following constants are uniquely derived from the subscriptions
  // Uniqueness is guaranteed by the graphql @include directives
  const importTemplates = useMemo(
    () => importTemplatesSubscription.data?.import_templates,

    [importTemplatesSubscription.data?.import_templates]
  )
  const parentAssignments = useMemo(() => importJob?.import_template?.parent_template?.assignments, [
    importJob?.import_template?.parent_template?.assignments,
  ])
  const assignments = useMemo(() => {
    return importJob?.import_template?.assignments
  }, [importJob?.import_template?.assignments])

  // TODO: implement a necessary condition for a "Save template" button to be active
  const modifiedFieldAssignments = useMemo(() => assignments?.length > 0, [assignments])

  const { bindings, values, setValue } = useFormFields({ name: '', strict: false })

  const { onTemplateChange, onTemplateSave } = useTemplate(props, values, importTemplates)

  const handlePreview = async () => {
    await preview({ variables: { id: state.importJobId } })
  }

  const handleImport = async () => {
    dispatch({ type: 'importing' })
    await execute({ variables: { id: state.importJobId } })
  }

  const validationLogs = useMemo(
    () =>
      importJob?.executables?.filter(
        (exec) => exec.executable_status === Import_Job_Executable_Status_Enum.PreviewFailed
      ),
    [importJob?.executables]
  )
  const executables = useMemo(() => importJob?.executables, [importJob?.executables])
  const validExecutables = useMemo(
    () =>
      importJob?.executables?.filter(
        (exec) => exec.executable_status === Import_Job_Executable_Status_Enum.PreviewSucceeded
      ),
    [importJob?.executables]
  )

  if (!importJob) return null
  if (importJob.status === Import_Job_Status_Enum.Initialized) {
    return <Spinner />
  }

  return (
    <>
      {importJob?.status !== Import_Job_Status_Enum.Done && (
        <L.Vertical>
          <Typo variant={'h3'}>{'2. Felder zuordnen'}</Typo>
          <Typo variant="h5" component="h4" className="ml-2">
            Vorlagen
          </Typo>
          <ChooseTemplate
            onTemplateChange={onTemplateChange}
            importTemplates={importTemplates}
            deleteImportTemplate={props.deleteImportTemplate}
            resetAssignments={props.resetAssignments}
            i18nPrefix={props.i18nPrefix}
          />
          <Typo variant="h5" component="h4" className="ml-2 mb-4">
            Zuordnungen
          </Typo>
          <ChooseAssignments
            i18nPrefix={props.i18nPrefix}
            dvKeys={props.dvKeys}
            assignColumnToField={props.assignColumnToField}
            unassignColumnFromField={props.unassignColumnFromField}
            entityName={props.entityName}
          />
          <L.Horizontal className="ml-4">
            <Button
              variant="contained"
              disabled={!modifiedFieldAssignments || importJob?.status !== Import_Job_Status_Enum.InProgress}
              onClick={() => dispatch({ type: 'template:init_saving' })}
            >
              Vorlage speichern
            </Button>
          </L.Horizontal>
          <L.Horizontal className="ml-4">
            <Button
              loading={importJob?.status === Import_Job_Status_Enum.PreviewInProgress}
              onClick={handlePreview}
              disabled={
                importJob?.status !== Import_Job_Status_Enum.InProgress ||
                (assignments?.length === 0 && !parentAssignments)
              }
            >
              Vorschau anzeigen
            </Button>
            <Button
              onClick={async () => {
                await clearExecutables({ variables: { id: state.importJobId } })
              }}
              disabled={importJob?.status !== Import_Job_Status_Enum.PreviewDone}
            >
              Editieren
            </Button>
          </L.Horizontal>

          {state.templateSaving && (
            <SaveTemplateDialog
              onSave={() => onTemplateSave()}
              nameBindings={bindings.name}
              values={values}
              setValue={setValue}
            />
          )}
          {state.templateOverwriting && (
            <Dialog
              onClose={() => dispatch({ type: 'template:cancelled_saving' })}
              onClick={(evt) => evt.stopPropagation()}
            >
              <Dialog.Content>
                <Typo variant="body1">
                  Die Vorlage mit dem Namen{' '}
                  <mark className="bg-background-darkDefault bg-opacity-30 p-1">{values.name}</mark>
                  existiert bereits, möchtest du die Vorlage überschreiben?
                </Typo>
              </Dialog.Content>
              <Dialog.Actions>
                <Button onClick={() => dispatch({ type: 'template:cancelled_saving' })}>Abbrechen</Button>
                <Button variant="contained" onClick={() => onTemplateSave(true)}>
                  Ja
                </Button>
              </Dialog.Actions>
            </Dialog>
          )}
        </L.Vertical>
      )}

      {(importJob?.status === Import_Job_Status_Enum.PreviewDone ||
        importJob?.status === Import_Job_Status_Enum.PreviewInProgress ||
        importJob?.status === Import_Job_Status_Enum.ExecutionInProgress) && (
        <L.Vertical>
          <Typo variant={'h3'}>{`3. ${props.entityNamePlural} aktualisieren`}</Typo>
          {!executables && importJob?.status === Import_Job_Status_Enum.PreviewDone && (
            <AlertBox type={'warning'}>
              <span>{'Überprüfe den Inhalt der .xlsx Datei. Möglicherweise ist die Datei leer.'}</span>
            </AlertBox>
          )}
          {validationLogs.length > 0 && validExecutables.length > 0 && (
            <AlertBox type={'warning'}>
              <span>{`Es gibt ${validationLogs.length} Validierungsprobleme. Siehe die Log-Liste oben rechts. Du kannst die gültigen ${props.entityNamePlural} trotzdem aktualisieren.`}</span>
            </AlertBox>
          )}
          {validationLogs.length > 0 && validExecutables.length === 0 && (
            <AlertBox type={'error'}>
              <span>{`Es gibt ${validationLogs.length} Validierungsprobleme. Siehe die Log-Liste oben rechts.`}</span>
            </AlertBox>
          )}
          {importJob.status === Import_Job_Status_Enum.PreviewInProgress && (
            <div className="flex flex-row gap-x-2">
              <Typo variant="body1">{`${executables.length} Einträge wurden überprüft.`}</Typo>
              <Spinner />
            </div>
          )}
          <L.Horizontal>
            <Button
              loading={importJob?.status === Import_Job_Status_Enum.ExecutionInProgress}
              onClick={handleImport}
              disabled={validExecutables.length === 0}
            >
              {'Aktualisieren'}
            </Button>
          </L.Horizontal>
        </L.Vertical>
      )}
    </>
  )
}

// ####################
// #    useTemplate   #
// ####################

const useTemplate = (
  props: AssignmentsProps,
  values: { name: string; strict: boolean },
  importTemplates?: ImportTemplates
) => {
  const { state, dispatch, importJob } = useContext(ExcelImportContext)

  const [assignParentTemplate] = props.inheritFromImportTemplate
  const [unassignParentTemplate] = props.removeParentImportTemplate
  const [insertImportTemplate, insertImportTemplateResult] = props.insertImportTemplate
  const [deleteImportTemplate] = props.deleteImportTemplate

  const handleTemplateChanged = useCallback(
    async (templateId?: string) => {
      if (!!templateId) {
        await assignParentTemplate({ variables: { id: state.importTemplateId, parentId: templateId } })
      } else {
        await unassignParentTemplate({ variables: { id: state.importTemplateId } })
      }
    },
    [assignParentTemplate, state.importTemplateId, unassignParentTemplate]
  )

  const [handleTemplateChangedCb] = useAsync(handleTemplateChanged)

  const handleSaveTemplate = useCallback(
    async (overwrite?: boolean) => {
      dispatch({ type: 'template:saving' })
      if (importTemplates.findIndex(({ name }) => name === values.name) > -1 && !overwrite) {
        dispatch({ type: 'template:overwriting' })
        return
      }
      if (overwrite) {
        const existingTemplateId = importTemplates?.find((template) => template.name === values.name)?.id
        await deleteImportTemplate({ variables: { id: existingTemplateId } })
      }
      const newTemplateId = uuid()
      await insertImportTemplate({
        variables: {
          templateId: state.importTemplateId,
          newTemplateId,
          importJobId: state.importJobId,
          name: values.name,
          strict: values.strict,
          excelDef: values.strict ? `{ ${importJob?.upload_file_excel_definition} }` : undefined,
        },
      })
    },
    [
      deleteImportTemplate,
      dispatch,
      importJob?.upload_file_excel_definition,
      importTemplates,
      insertImportTemplate,
      state.importJobId,
      state.importTemplateId,
      values.name,
      values.strict,
    ]
  )

  useEffect(() => {
    const newId = insertImportTemplateResult.data?.new_import_template?.id
    if (newId) {
      dispatch({ type: 'template:saved', payload: { id: newId } })
    }
  }, [insertImportTemplateResult.data?.new_import_template?.id, dispatch])

  const [alert] = useAlert()
  const [
    assignImportTemplateToImportJob,
    { error: assignImportTemplateToImportJobError },
  ] = props.assignImportTemplateToImportJob

  useEffect(() => {
    if (!assignImportTemplateToImportJobError) {
      return
    }
    alert.error(assignImportTemplateToImportJobError.message, assignImportTemplateToImportJobError)
    handleTemplateChangedCb()
  }, [alert, assignImportTemplateToImportJobError, handleTemplateChangedCb])

  useEffect(() => {
    const template = importTemplates?.find(({ id }) => id === state.importTemplateId)
    if (template) {
      assignImportTemplateToImportJob({
        variables: {
          id: state.importJobId,
          template_id: state.importTemplateId,
        },
      })
    }
  }, [assignImportTemplateToImportJob, importTemplates, state.importJobId, state.importTemplateId])

  return { onTemplateChange: handleTemplateChanged, onTemplateSave: handleSaveTemplate }
}
