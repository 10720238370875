import { splitVehicleId } from 'containers/ListConfig/Vehicles/List'
import { useVehicleValidationSchema, VehicleDetail } from 'containers/VehicleForm'
import { BulkDataForm } from 'containers/VehicleForm/01_GeneralData/BulkDataForm'
import {
  Import_Type_Enum,
  useImportBusinessCasesMutation,
  useVehicleMetafieldsLazyQuery,
  useVehiclesErrorAndImportStateQuery,
} from 'gql'
import { uniq } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useVehiclesStore } from '../hooks/useData'
import { useGroupName } from '../../../ListManipulation/Vehicles/GroupBy/Options'

interface ImportVehiclesBulkEditViewProps {
  ids: string[]
  onClose: () => void
}

export const ImportVehiclesBulkEditView = React.forwardRef<HTMLDivElement, ImportVehiclesBulkEditViewProps>(
  (props, ref) => {
    const { remove: removeFromVehiclesState } = useVehiclesStore()
    const { render, iteratee } = useGroupName()
    const [importBCs, importBCsRes] = useImportBusinessCasesMutation()
    const [metafieldsQuery, metafieldsQueryResponse] = useVehicleMetafieldsLazyQuery()

    const errorAndImportStateQuery = useVehiclesErrorAndImportStateQuery({
      variables: {
        where: {
          _or: props.ids.map((composedId: any) => {
            const [id, , business_case_number]: any[] = splitVehicleId(composedId)
            return {
              _and: [
                { id: { _eq: id } },
                { version: { _eq: 0 } },
                { business_case_number: { _eq: business_case_number } },
              ],
            }
          }),
        },
      },
    })

    // Find out if all selected vehicles have same brand id
    // If yes include it into the validation step
    const brandId = useMemo(() => {
      const vehicles = errorAndImportStateQuery.data?.vehicles
      if (!vehicles || !vehicles.length || vehicles.length === 0) return null
      return uniq(vehicles.map((v) => v.brand_id)).length === 1 ? vehicles[0].brand_id : null
    }, [errorAndImportStateQuery.data?.vehicles])

    useEffect(() => {
      if (!brandId) return
      metafieldsQuery({ variables: { brandId } })
    }, [brandId, metafieldsQuery])

    const metafields = useMemo(
      () => metafieldsQueryResponse.data?.brand_vehicle_metafield,
      [metafieldsQueryResponse.data?.brand_vehicle_metafield]
    )

    const { isValid: canImport } = useVehicleValidationSchema(
      errorAndImportStateQuery.data?.vehicles as any,
      brandId,
      { metafields },
      {
        autoRun: true,
      }
    )

    const { isValid: canPartiallyImport } = useVehicleValidationSchema(
      errorAndImportStateQuery.data?.vehicles as any,
      brandId,
      { metafields, importType: Import_Type_Enum.Partial },
      {
        autoRun: true,
      }
    )

    const canEdit = useMemo(
      () => errorAndImportStateQuery.data?.vehicles?.every((v) => !v.sync_error),
      [errorAndImportStateQuery.data?.vehicles]
    )

    const handleImport = async (importType: Import_Type_Enum = Import_Type_Enum.Full) => {
      if (!props.ids || !props.ids.length || props.ids.length === 0) throw new Error('no ids to import')
      if (
        (importType === Import_Type_Enum.Full && !canImport) ||
        (importType === Import_Type_Enum.Partial && !canPartiallyImport)
      )
        throw new Error('unable to import invalid vehicles')
      const ids = props.ids.map((id) => splitVehicleId(id)[0]) as string[]
      await importBCs({
        variables: { ids, imported: new Date(), importType },
        onCompleted: () => {
          removeFromVehiclesState(props.ids, render, iteratee)
        },
      })
      props.onClose()
    }

    const loading = importBCsRes.loading || metafieldsQueryResponse.loading || errorAndImportStateQuery.loading
    return (
      <VehicleDetail
        ref={ref}
        onClose={props.onClose}
        loading={loading}
        metadata={{ metafields }}
        panes={{
          general: (
            <BulkDataForm
              ids={props.ids}
              canImport={canImport}
              canPartiallyImport={canPartiallyImport}
              canEdit={canEdit}
              onImport={handleImport}
              onClose={props.onClose}
            />
          ),
        }}
      />
    )
  }
)
