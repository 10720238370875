import { AlertBox, Button, Typo } from 'components'
import { Collapsable } from 'components/Collapsable'
import { useResolveVehicleSyncErrorMutation } from 'gql'
import { useFormatter } from 'hooks'
import React, { useContext, useEffect, useState } from 'react'
import { VehicleDetailContext } from '.'

export const StatusBar = () => {
  const { data } = useContext(VehicleDetailContext)
  const { formatDateTimeString } = useFormatter()
  const [errorState, setErrorState] = useState<boolean>(false)
  const [resolve, resolveResponse] = useResolveVehicleSyncErrorMutation()
  const resolveError = async (vehicleId: string, transactionId: string) => {
    await resolve({
      variables: {
        vehicleId,
        transactionId,
      },
    })
  }
  useEffect(() => {
    resolveResponse.data?.resolveVehicleSyncError?.errorMessage && setErrorState(true)
  }, [resolveResponse.data?.resolveVehicleSyncError?.errorMessage])

  return (
    <>
      {errorState && (
        <AlertBox type={'error'} className="w-full" onClose={() => setErrorState(false)}>
          {resolveResponse.data?.resolveVehicleSyncError?.errorMessage}
        </AlertBox>
      )}
      {data?.sync_errors && data?.sync_errors.length > 0 && (
        <>
          {data.sync_errors
            .filter((error) => !error.resolved)
            .map((error) => (
              <AlertBox
                key={error.id}
                type={'error'}
                className="w-full"
                action={
                  error.transaction_id ? (
                    <Button
                      color="error"
                      variant="text"
                      onClick={() => resolveError(data.id, error.transaction_id)}
                      disabled={resolveResponse.loading}
                    >
                      Auflösen
                    </Button>
                  ) : null
                }
              >
                {error?.cause ? (
                  <Collapsable title={error.message}>
                    {
                      <div className="space-y-2">
                        <Typo variant="body2">
                          <span className="font-bold">Details: </span>
                          {error.cause}
                        </Typo>
                        <Typo variant="body2">
                          <span className="font-bold">Erschienen zum ersten mal: </span>
                          {formatDateTimeString(error.first_occured)}
                        </Typo>
                      </div>
                    }
                  </Collapsable>
                ) : (
                  error.message
                )}
              </AlertBox>
            ))}
        </>
      )}
    </>
  )
}
