import React, { useContext, useMemo } from 'react'
import { ListConfigContext } from '../../../ListConfig'
import { Order_By, useStorageLocationSelectDataQuery, useUserSelectDataQuery } from '../../../../gql'
import { useTranslation } from 'react-i18next'
import { ListManipulation } from 'containers/ListManipulation/unit'

export const VehiclesGroupByOptions = () => {
  const { setGroupBy } = useContext(ListConfigContext)

  return (
    <ListManipulation.UnitOptions>
      <ListManipulation.ItemOption onClick={() => setGroupBy('modelGroupName')}>
        Nach Modell
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setGroupBy('state')}>Nach Status</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setGroupBy('order_state')}>
        Nach Transport Status
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setGroupBy('storage_location_id')}>
        Nach Lagerort
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setGroupBy('delivery_location_id')}>
        Nach Lieferort
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setGroupBy('dms_assigned_location_id')}>
        Nach betreuenden Betriebsstätte
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setGroupBy('seller_location_id')}>
        Nach verkaufenden Betriebsstätte
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setGroupBy('registration_state')}>
        Nach Zulassungs Status
      </ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setGroupBy('type')}>Nach Typ</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setGroupBy('sold_by')}>Nach Verkäufer</ListManipulation.ItemOption>
      <ListManipulation.ItemOption onClick={() => setGroupBy('reserved_by')}>
        Nach Reserviert von
      </ListManipulation.ItemOption>
    </ListManipulation.UnitOptions>
  )
}

export const useGroupName = () => {
  const { groupBy } = useContext(ListConfigContext)
  const { t } = useTranslation()

  const storageLocationsQuery = useStorageLocationSelectDataQuery()
  const storageLocations = useMemo(() => storageLocationsQuery.data?.storage_locations ?? [], [
    storageLocationsQuery.data?.storage_locations,
  ])
  const locations = useMemo(() => storageLocationsQuery.data?.locations ?? [], [storageLocationsQuery.data?.locations])

  const usersQuery = useUserSelectDataQuery({ fetchPolicy: 'network-only' })
  const users = useMemo(() => usersQuery.data?.users ?? [], [usersQuery.data?.users])

  const exists = (groupName: string) => groupName !== 'null' && groupName !== '' && groupName !== 'undefined'

  return useMemo(() => {
    switch (groupBy) {
      case 'modelGroupName':
        return {
          render: (groupName) => (exists(groupName) ? groupName : 'Modell fehlt'),
          orderBy: { model: { modelGroup: { name: Order_By.AscNullsLast } } },
          iteratee: (item: any) => item?.model?.modelGroup?.name,
        }
      case 'state':
        return {
          render: (groupName: string) => (exists(groupName) ? t(`DealerVehicleState.${groupName}`) : 'Status fehlt'),
          orderBy: { state: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.state,
        }
      case 'order_state':
        return {
          render: (groupName) => (exists(groupName) ? t(`OrderState.${groupName}`) : 'Status fehlt'),
          orderBy: { order_state: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.order_state,
        }
      case 'storage_location_id':
        return {
          render: (groupName) => storageLocations.find((sl) => sl.id === groupName)?.name ?? 'Standort fehlt',
          orderBy: { storage_location_id: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.storage_location_id,
        }
      case 'delivery_location_id':
        return {
          render: (groupName) => storageLocations.find((sl) => sl.id === groupName)?.name ?? 'Standort fehlt',
          orderBy: { delivery_location_id: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.delivery_location_id,
        }
      case 'dms_assigned_location_id':
        return {
          render: (groupName) => locations.find((sl) => sl.id === groupName)?.name ?? 'Betriebsstätte fehlt',
          orderBy: { dms_assigned_location_id: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.dms_assigned_location_id,
        }
      case 'seller_location_id':
        return {
          render: (groupName) => locations.find((sl) => sl.id === groupName)?.name ?? 'Betriebsstätte fehlt',
          orderBy: { seller_location_id: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.seller_location_id,
        }
      case 'registration_state':
        return {
          render: (groupName) => (exists(groupName) ? t(`RegistrationState.${groupName}`) : 'Status fehlt'),
          orderBy: { registration_state: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.registration_state,
        }
      case 'type':
        return {
          render: (groupName) => (exists(groupName) ? t(`DealerVehicleType.${groupName}`) : 'Typ fehlt'),
          orderBy: { type: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.type,
        }
      case 'customer_type':
        return {
          render: (groupName) => (exists(groupName) ? t(`CustomerType.${groupName}`) : 'Kundentyp fehlt'),
          orderBy: { customer_type: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.customer_type,
        }
      case 'reserved_for_customer_type':
        return {
          render: (groupName) => (exists(groupName) ? t(`CustomerType.${groupName}`) : 'Reserviert für (Typ) fehlt'),
          orderBy: { reserved_for_customer_type: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.reserved_for_customer_type,
        }
      case 'sold_by':
        return {
          render: (groupName) => {
            const user = users.find((user) => user.id === groupName)
            return user?.full_name ?? user?.email ?? 'Verkäufer fehlt'
          },
          orderBy: { sold_by_id: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.sold_by_id,
        }
      case 'handover_by':
        return {
          render: (groupName) => {
            const user = users.find((user) => user.id === groupName)
            return user?.full_name ?? user?.email ?? 'Ausgeliefert von fehlt'
          },
          orderBy: { handover_by_id: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.handover_by_id,
        }
      case 'reserved_by':
        return {
          render: (groupName) => {
            const user = users.find((u) => u.id === groupName)
            return user?.full_name ?? user?.email ?? 'Reserviert von fehlt'
          },
          orderBy: { reserved_by_id: Order_By.AscNullsLast },
          iteratee: (item: any) => item?.reserved_by_id,
        }
      default:
        return { render: () => 'Fahrzeuge', orderBy: { id: Order_By.Asc }, iteratee: undefined }
    }
  }, [groupBy, locations, storageLocations, t, users])
}
