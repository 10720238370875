import { createTheme, adaptV4Theme } from '@mui/material/styles';

import { defaultOptions } from './defaults'
import { grey } from './colors'

export const darkTheme = createTheme(adaptV4Theme({
  ...defaultOptions,
  palette: {
    mode: 'dark',
    ...(defaultOptions.palette || {}),
    background: {
      paper: grey[900],
      default: grey[800],
    },
  },
}))
