import React, { CSSProperties, useContext, useEffect, useMemo } from 'react'
import { Typo } from '../../components'
import clsx from 'clsx'
import { VehiclesNewFilterButton } from '../ListManipulation/Vehicles/Filter'
import { Vehicle_Bool_Exp } from '../../gql'
import { ListConfig, ListConfigContext } from '../ListConfig'
import { Chips } from '../ListManipulation/Vehicles/Filter/Chips'
import { ListManipulation, useListManipilationContext } from 'containers/ListManipulation/unit'

interface TriggerFilterProps {
  filter: string
  onChange: (value: string) => void
  className?: string
  style?: CSSProperties
}

export const TriggerFilter = (props: TriggerFilterProps) => {
  return (
    <div className={clsx('bg-grey-200 p-2', props.className)} style={props.style}>
      <Typo variant={'h6'} component={'p'}>
        Die Aufgabe wird erstellt wenn:
      </Typo>
      <div className={'flex items-center'}>
        <ListManipulation>
          <ListConfig filter={props.filter} columns={[]} grouping={''} highlights={[]} type={'trigger'}>
            <FilterDestruction onChange={props.onChange} />
          </ListConfig>
        </ListManipulation>
      </div>
    </div>
  )
}

const FilterDestruction = (props: { onChange: (value: string) => void }) => {
  const { filter } = useContext(ListConfigContext)
  const { setLeadingExpression } = useListManipilationContext<'Vehicle'>()
  const andExpressions = useMemo(() => {
    const where: Vehicle_Bool_Exp = JSON.parse(filter) ?? {}
    return where?._and ?? []
  }, [filter])
  const orExpressions = useMemo(() => {
    return andExpressions?.map((andExpression) => andExpression._or)
  }, [andExpressions])
  const expressions = useMemo(() => {
    return orExpressions?.map((exp) => exp.map((orExpression) => orExpression._and))
  }, [orExpressions])
  useEffect(() => {
    props.onChange(filter)
  }, [filter, props])
  return (
    <div className={'flex flex-col gap-y-1'}>
      {andExpressions.map((andExpression, idx) => (
        <div key={`first-layer-${idx}-item`}>
          <div className={'flex flex-row gap-x-1 items-center'}>
            {orExpressions?.[idx]?.map((orExpression, orIdx) => (
              <div key={`second-layer-${orIdx}-item`} className={'flex flex-row gap-x-1 items-center'}>
                <Chips expressions={expressions?.[idx]?.[orIdx] ?? []} />
                <hr className={'h-6 w-px text-divider border border-l border-dashed inline-block'} />
              </div>
            ))}
            <VehiclesNewFilterButton
              expressions={expressions?.[idx]?.flat() ?? []}
              onClick={() => setLeadingExpression(expressions?.[idx]?.flat()?.[0])}
            >
              ODER
            </VehiclesNewFilterButton>
          </div>
          <hr className={'text-divider'} />
        </div>
      ))}
      {andExpressions.length > 0 && (
        <VehiclesNewFilterButton expressions={[]} onClick={() => setLeadingExpression(undefined)}>
          UND
        </VehiclesNewFilterButton>
      )}
      {!andExpressions.length && <VehiclesNewFilterButton>Bedienung hinzufügen</VehiclesNewFilterButton>}
    </div>
  )
}
