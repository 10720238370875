// after sales
const afterSalesRoute = () => '/after-sales'
const forgotPasswordRoute = () => '/forgot-password'
const dealerVehicles = () => `${afterSalesRoute()}/dealer-vehicles`
dealerVehicles.inspectionSticker = () => `${afterSalesRoute()}/dealer-vehicles/inspection-sticker`
dealerVehicles.serivce = () => `${afterSalesRoute()}/dealer-vehicles/service`

const customerVehicles = () => `${afterSalesRoute()}/customer-vehicles`
customerVehicles.inspectionSticker = () => `${customerVehicles()}/inspection-sticker`
customerVehicles.service = () => `${customerVehicles()}/service`

afterSalesRoute.dealerVehicles = dealerVehicles
afterSalesRoute.customerVehicles = customerVehicles

const communcationRoute = () => '/communcation'
const communcationAfterSales = () => `${communcationRoute()}/after-sales`

communcationAfterSales.details = () => `${communcationAfterSales()}/detail`
communcationRoute.afterSales = communcationAfterSales

const dispositionRoute = () => '/disposition'
dispositionRoute.dealerVehicleImport = () => `${dispositionRoute()}/dealer-vehicle-import`
dispositionRoute.dealerVehicles = () => `${dispositionRoute()}/dealer-vehicle`
dispositionRoute.salesReports = () => `${dispositionRoute()}/sales-reports`

// data-quality
const dataQualityRoute = () => '/data-quality'
const dataQualityVehicles = () => `${dataQualityRoute()}/vehicles`
dataQualityVehicles.syncErrors = () => `${dataQualityVehicles()}/sync-errros`
dataQualityVehicles.mixedUpHistories = () => `${dataQualityVehicles()}/mixed-up-histories`
dataQualityVehicles.markedForDelete = () => `${dataQualityVehicles()}/marked-for-delete`

// admin
const admin = () => '/admin'
admin.locations = () => `${admin()}/locations`
admin.brands = () => `${admin()}/brands`
admin.taskTypes = () => `${admin()}/task-types`
admin.supplier = () => `${admin()}/suppliers`
admin.subDealer = () => `${admin()}/sub-dealers`
admin.assetLinks = () => `${admin()}/asset-links`
admin.users = () => `${admin()}/users`
admin.embeddedPages = () => `${admin()}/embedded-pages`
const monitoring = () => `${admin()}/monitoring`
monitoring.locosoftSync = () => `${monitoring()}/locosoft-sync`
const importJobs = () => `${admin()}/import-jobs`
importJobs.vehicles = () => `${importJobs()}/vehicles`
const automation = () => `${admin()}/automation`
automation.vehicles = () => `${automation()}/vehicles`

export const routes = {
  home: () => `/`,
  forgotPassword: forgotPasswordRoute,
  afterSales: afterSalesRoute,
  communcation: communcationRoute,
  disposition: dispositionRoute,
  dataQuality: dataQualityRoute,
  admin,
  monitoring,
  importJobs,
  automation,
  dataQualityVehicles
}

export default routes
