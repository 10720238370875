import clsx from 'clsx'
import { Typo } from 'components'
import React from 'react'
import { CSSProperties } from 'react'

export type FormSetProps = {
  title?: string
  style?: CSSProperties
  className?: string
}

export const FormSet: React.FC<FormSetProps> = (props) => {
  return (
    <fieldset style={props.style} className={clsx("border-0 p-0", props.className)}>
      {props.title && (
        <Typo variant="h6" component="legend">
          {props.title}
        </Typo>
      )}
      {props.children}
    </fieldset>
  )
}
